import React, { useContext, useState } from "react";
import palette from "../../theme/palette";
import NormalModeIconGreen from "../../assets/images/icon/normalModeIconGreen";
import ExpandedModeIconGreen from "../../assets/images/icon/expandModeIconGreen";
import {
  Box,
  Button,
  IconButton,
  Typography,
  MenuItem,
  Menu,
} from "@mui/material";
import UserIcon from "../../assets/images/icon/UserIcon";
import { makeStyles } from "@material-ui/core";
import AddCategoryIcon from "../../assets/images/icon/AddCategoryIcon";
import SortingIcon from "../../assets/images/icon/SortingIcon";
import NormalMode from "../../assets/images/icon/NormalMode";
import { UserTypeContext } from "../../context/userStatus";
import {
  addCategoryJourneyAction,
  isExpandMode,
  isPersonalMode,
} from "../../store/actions/userTypeAction";
import ShareIcon from "../../assets/images/icon/ShareIcon";
import HTMLTooltip from "../../components/HTMLTooltip";
import { pxToRem } from "../../theme/typography";
import { trackingService } from "../../services/tracking";

const useStyles = makeStyles((theme) => ({
  modeButton: {
    borderRadius: "6px !important",
    padding: "9px 14px !important",
    border: "1.5px solid rgba(17, 115, 74, 0.4)",
    marginRight: "16px !important",
  },
  swicthButtons: {
    display: "flex",
    alignItems: "center",
    // borderRadius: "8px",
    border: "none ",
    // background: "#FFF",
  },
  swicthButton: {},
}));

interface ModeSwitchModal {
  handleClickOpen: () => void;
  handleShareModalOpen: (val) => void;
}

const ModeSwitchTab = (props: ModeSwitchModal) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { state: IUserTypeState, dispatch: userTypeDispatch } =
    useContext(UserTypeContext);
  const handleExpandClick = () => {
    const obj = {
      mixpanel_type: "EXTENDED_CLICK",
    };
    trackingService.signupCompletedBoarding(IUserTypeState.token, obj);
  };
  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    expand = false
  ) => {
    localStorage.setItem("viewType", expand ? "expand" : "default");

    setAnchorEl(event.currentTarget);
    userTypeDispatch(isExpandMode(expand));
    if (expand) {
      handleExpandClick();
    }
  };
  const renderButton = (isLeft, title, active, onClick) => (
    <Button
      id={title + "_Button"}
      variant={/* !active ?  */ "outlined" /* : "contained" */}
      sx={{
        display: "flex",
        padding: "12px",
        alignItems: "center",
        gap: "8px",
        border: active ? "1px solid var(--color-svg)" : "1px solid var(--color-new-main)",
        borderRight: !isLeft ? active ? "1px solid var(--color-svg)" : "0px" : active ? "1px solid var(--color-svg)" : "0px",
        borderLeft: isLeft ?active ? "1px solid var(--color-svg)" : "0px"  : active ? "1px solid var(--color-svg)" : "0px",
        marginBottom: "0 !important",
        boxShadow: "none",
        borderRadius: isLeft ? "40px 0 0 100px" : " 0 100px 40px 0",
        background: active ? "#fff" : "var(--color-new-main)",
        position: "relative",
        transform: "skewX(-20deg)",
        "&::after": {
          content: '""',
          position: "absolute",
          width: "0px",
          height: "52px",
          right: 0,
          bottom: 0,
        },
      }}
      onClick={onClick}
    >
      <div
        style={{
          transform: "skewX(20deg)",
        }}
      >
        <Typography
          variant="caption"
          sx={{
            color: "#000000",
            fontWeight: !active ? 400 : 700,
            fontSize: "12px !important",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            lineHeight: "100% !important" /* 12px */,
            letterSpacing: "-0.12px",
          }}
        >
          {title}
        </Typography>
      </div>
    </Button>
  );
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: IUserTypeState?.showEmpty
          ? "flex-start"
          : "space-between",
        // marginBottom: "16px",
        padding: "24px",
        background: "#FFF",
        // boxShadow: "5px 0px 40px 0px rgba(18, 18, 19, 0.05)",
        width: "100%",
        gap: IUserTypeState?.showEmpty && "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
        }}
      >
        <div className={classes.swicthButtons}>
          <HTMLTooltip
            customIcon={renderButton(
              true,
              "Compact",
              !IUserTypeState?.isExpand,
              handleClick
            )}
            title="View only numbers and icons."
            sx={{
              "& .MuiTooltip-tooltip": {
                maxWidth: "300px",
              },
            }}
            fontProps={{
              fontSize: `${pxToRem(12)} !important`,
            }}
          />
          <HTMLTooltip
            customIcon={renderButton(
              false,
              "Expanded",
              IUserTypeState?.isExpand,
              (e) => handleClick(e, true)
            )}
            title="View comments to understand entries."
            sx={{
              "& .MuiTooltip-tooltip": {
                maxWidth: "300px",
              },
            }}
            fontProps={{
              fontSize: `${pxToRem(12)} !important`,
            }}
          />
        </div>
        <div className={classes.swicthButtons}>
          <HTMLTooltip
            customIcon={renderButton(
              true,
              "Default",
              !IUserTypeState?.isPersonal,
              (event) => {
                setAnchorEl(event.currentTarget);
                if (IUserTypeState?.isPersonal) {
                  localStorage.setItem("mode", "default");
                  // userTypeDispatch(addCategoryJourneyAction([]));
                  userTypeDispatch(isPersonalMode(false));
                }
              }
            )}
            title="Metrics structured into 
          outcomes, influences, 
          and questions."
            fontProps={{
              fontSize: `${pxToRem(12)} !important`,
            }}
            sx={{
              "& .MuiTooltip-tooltip": {
                maxWidth: "300px",
              },
            }}
          />
          {}
          <HTMLTooltip
            customIcon={renderButton(
              false,
              "Personal",
              IUserTypeState?.isPersonal,
              (event) => {
                setAnchorEl(event.currentTarget);
                if (!IUserTypeState?.isPersonal) {
                  localStorage.setItem("mode", "personal");

                  userTypeDispatch(isPersonalMode(true));
                  // userTypeDispatch(addCategoryJourneyAction([]));
                }
              }
            )}
            title="Metrics structured your 
          own way."
            fontProps={{
              fontSize: `${pxToRem(12)} !important`,
            }}
            sx={{
              "& .MuiTooltip-tooltip": {
                maxWidth: "300px",
              },
            }}
          />
        </div>
      </Box>
      {/* <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={openMenu}
        onClose={handleCloseMenu}
        onClick={handleCloseMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: "40%",
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={() => {
            handleChange(0);
          }}
        >
          Normal Mode
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleChange(1);
          }}
        >
          Expanded
        </MenuItem>
      </Menu> */}
      <Box display={"flex"}>
        <HTMLTooltip
          customIcon={
            <IconButton
              sx={{ p: 0.5 }}
              id="add-category"
              onClick={props.handleClickOpen}
            >
              <AddCategoryIcon color={"var(--svg-new-color)"} size={20}/>
            </IconButton>
          }
          title="Add a category"
          fontProps={{
            fontSize: `${pxToRem(12)} !important`,
          }}
          sx={{
            "& .MuiTooltip-tooltip": {
              maxWidth: "300px",
            },
          }}
        />
        {/* <IconButton sx={{ p: 0.5, mr: -0.5 }}>
          <SortingIcon />
        </IconButton>*/}
        <IconButton
          sx={{ p: 0.5, mr: -0.5 }}
          onClick={() => props.handleShareModalOpen(true)}
          id="share-button"
        >
          <ShareIcon color={"var(--svg-new-color)"} size={20}/>
        </IconButton>
      </Box>
    </Box>
  );
};

export default ModeSwitchTab;
