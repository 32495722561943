import React, { useContext, useState } from "react";
import clsx from "clsx";
import { Tooltip, Typography } from "@mui/material";
import EditIcon from "../../assets/images/icon/EditIcon";
import useStyles from "./TrackingClasses";
import { setTrackingState } from "../../store/actions/trackerAction";
import { TrackingContext } from "../../context/userTracking";

const PersonalTrackingReflective = ({ isExpand, item }) => {
  const { dispatch: trackingDispatch } = useContext(TrackingContext);
  const [showIcon, setshowIcon] = useState(false);
  const classes = useStyles();
  return (
    <td className={clsx(classes.cell, classes.stickyLeftCell)}>
      <div
        className={isExpand ? classes.mainDivClasExpand : classes.mainDivClas}
        style={{
          borderBottom: `1px solid var(--border-color)`,
          height: "100%",
        }}
        onMouseEnter={() => setshowIcon(true)}
        onMouseLeave={() => setshowIcon(false)}
      >
        {item.description ? (
          <Tooltip
            title={
              <Typography
                sx={{
                  fontSize: "14px !important",
                  lineHeight: "1.5",
                  whiteSpace: "pre-wrap",
                }}
              >
                {item.description}
              </Typography>
            }
          >
            <div
              style={{
                width: "100%",
                height: "100%",
              }}
            >
              <Typography
                variant="h6"
                flex={7}
                title={item.question}
                className={
                  isExpand ? classes.fullTexttextClass : classes.textClass
                }
              >
                {" "}
                {item.question}
              </Typography>
            </div>
          </Tooltip>
        ) : (
          <div
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <Typography
              variant="h6"
              flex={7}
              title={item.question}
              className={
                isExpand ? classes.fullTexttextClass : classes.textClass
              }
            >
              {" "}
              {item.question}
            </Typography>
          </div>
        )}
        <div className={classes.innerDiVClass}>
          <span className={classes.textClass}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                d="M5.09766 5.04908C5.21152 4.69822 5.4201 4.38597 5.70052 4.14632C5.98094 3.90667 6.32253 3.74919 6.68685 3.69139C7.05117 3.63358 7.42415 3.6776 7.76497 3.81868C8.1058 3.95977 8.40097 4.1925 8.61784 4.49089C8.83471 4.78927 8.96459 5.14171 8.99358 5.50944C9.02257 5.87717 8.94923 6.24586 8.78179 6.57454C8.61436 6.90323 8.35958 7.17899 8.04507 7.37174C7.73056 7.5645 7.36888 7.66651 7 7.66651V8.33349M7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7C13 10.3137 10.3137 13 7 13ZM7.0332 10.3333V10.4L6.9668 10.4001V10.3333H7.0332Z"
                stroke="#A0A0A1"
                strokeWidth="0.67"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          {showIcon && (
            <span
              onClick={() =>
                trackingDispatch(
                  setTrackingState({
                    openStartModalQues: true,
                    showQues: true,
                    questionData: item,
                    influenceId: item._id,
                  })
                )
              }
              style={{
                display: "flex",
                alignItems: "center",
                height: "100%",
              }}
            >
              <EditIcon />
            </span>
          )}
        </div>
      </div>
    </td>
  );
};

export default PersonalTrackingReflective;
