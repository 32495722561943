import React from "react";
import { makeStyles } from "@material-ui/core";
import palette from "../../theme/palette";
import { SvgIcon } from "@mui/material";
import shape from "../../theme/shape";

const useStyles = makeStyles((theme) => ({
  recommText: {
    backgroundColor: palette.common.white,
    borderRadius: shape.borderRadius,
    fontSize: "16px",
    lineHeight: "36px",
    color: palette.primary.light,
    padding: "12px 16px",
    cursor: "pointer",
    marginBottom: "12px",
    wordBreak: "break-word",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
  },
  colorCode: {
    width: "36px",
    height: "36px",
    padding: "8px",
    borderRadius: shape.borderRadiusSm,
    marginRight: "12px",
    [theme.breakpoints.down("sm")]: {
      width: "25px",
      height: "25px",
      padding: "3px",
    },
  },
}));

interface AddOutComeLabelModal {
  outcomeItem: any;
  key: number;
  setOutcomeValue: (outComeValue: string) => void;
}

const AddOutComeLabel = (props: AddOutComeLabelModal) => {
  const classes = useStyles();
  return (
    <div
      className={classes.recommText}
      onClick={() => props.setOutcomeValue(props?.outcomeItem)}
    >
      <span
        className={classes.colorCode}
        style={{
          backgroundColor: props?.outcomeItem?.options[0]?.colorCode,
        }}
      >
        <SvgIcon
          color="action"
          fontSize="small"
          sx={{ verticalAlign: "middle" }}
        >
          <path d={props?.outcomeItem?.icon} fill={palette.common.white} />
        </SvgIcon>
      </span>
      {props?.outcomeItem?.name}
    </div>
  );
};
export default AddOutComeLabel;
