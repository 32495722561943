import {
  DialogContent,
  Button,
  Dialog,
  DialogActions,
  Typography,
  Box,
} from "@mui/material";
import React from "react";
import { makeStyles } from "@material-ui/core";
import palette from "../../theme/palette";
import { pxToRem } from "../../theme/typography";
import Exclamation from "../../assets/images/icon/Exclamation";
import NewButton from "../../components/NewButton";

interface IswitchInfluence {
  open: boolean;
  handleClose: any;
  handleSubmit: any;
  outcomeValueInput?: any;
}

const useStyles = makeStyles((theme) => ({
  categoryDialog: {
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "24px",
        background: palette.common.white,
        padding: 0,

        "& .MuiDialogContent-root": {
          padding: "0",
          overflowY: "unset",
        },

        "&. MuiDialogActions-root": {},
      },
    },
  },
  categoryDialogAction: {
    display: "flex !important",
    alignItems: "center !important",
    justifyContent: "center !important",
    padding: "32px !important",
    gap: "12px",
    borderTop: "1px solid #F3F3F3",
    alignSelf: "stretch",
  },
}));
const ArchiveModal = (props: IswitchInfluence) => {
  const classes = useStyles();

  return (
    <Dialog
      open={props.open}
      onClose={props?.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.categoryDialog}
    >
      <DialogContent id="alert-dialog-description">
        <Box
          sx={{
            display: "flex",
            padding: "32px",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "16px",
            alignSelf: "stretch",
          }}
        >
          <Typography
            variant="h3"
            component="span"
            lineHeight="135% !important"
            sx={{
              color: palette.common.black,
              fontSize: `${pxToRem(24)} !important`,
              lineHeight: "120% !important",
              fontWeight: `600 !important`,
              fontFamily: "'Poppins' !important",
            }}
          >
            Are you sure you want to archive {props?.outcomeValueInput}?
          </Typography>
          <Typography
            variant="h3"
            component="span"
            lineHeight="150% !important"
            sx={{
              color: palette.common.black,
              fontSize: `${pxToRem(16)} !important`,
              fontWeight: `400 !important`,
            }}
          >
            You can always restore the metric, but after archiving it you will
            not be able to track it before undoing action
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions className={classes.categoryDialogAction}>
        <NewButton
          buttonText="Do not archive"
          type="submit"
          onClick={props?.handleClose}
          variant="contained"
        />

        <NewButton
          buttonText="Yes, archive"
          onClick={props?.handleSubmit}
          variant="outlined"
        />

      </DialogActions>
    </Dialog>
  );
};

export default ArchiveModal;
