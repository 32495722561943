import React from "react";

const ThreeDotsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="4"
      viewBox="0 0 16 4"
      fill="none"
    >
      <circle cx="2" cy="2" r="1.5" fill="#11734A" />
      <circle cx="8" cy="2" r="1.5" fill="#11734A" />
      <circle cx="14" cy="2" r="1.5" fill="#11734A" />
    </svg>
  );
};

export default ThreeDotsIcon;
