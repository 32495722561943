import React, { useEffect, useContext, useState, useRef } from "react";
import { Typography, IconButton, Box } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import palette from "../../theme/palette";
import moment from "moment";
import MonthItem from "./MonthItem";
import { trackingService } from "../../services/tracking";
import { UserTypeContext } from "../../context/userStatus";
import FullpageLoader from "../../components/fullPageLoader";
import {
  addCategoryExpandAction,
  addCategoryJourneyAction,
  addJourneyAction,
  addNotesAction,
  addNotesJourneyAction,
  isEmptyState,
  pushCategoryJourneyAction,
} from "../../store/actions/userTypeAction";
import typography from "../../theme/typography";
import clsx from "clsx";
import PersonalMonthItem from "./PersonalMonthItem";
import RightCalendarIcon from "../../assets/images/icon/RightCalendarIcon";
import LeftCalendarIcon from "../../assets/images/icon/LeftCalendarIcon";

const useStyles = makeStyles((theme) => ({
  calendarItem: {
    display: "flex",
    flexDirection: "column",
  },
  monthTitle: {
    color: palette.primary.main,
    marginBottom: "0px!important",
    fontSize: "1.5rem!important",
    fontWeight: 700,
    letterSpacing: "0.02em",
    lineHeight: "100%!important",
    padding: "0 10px",
  },
  monthItem: {
    cursor: "pointer",
    // gap: "16px",
    alignItems: "flex-start",
    "& .MuiBox-root:first-child div": {
      paddingLeft: "0",
    },
  },
  iconButtonCalender: {
    // color: `${palette.primary.light}!important`,
    // fontSize: "26px!important",
    // lineHeight: "26px!important",
    // fontWeight: 600,
    // border: `1px solid #F3F3F3!important`,
    // borderRadius: "4px!important",
    // fontFamily: typography.fontFamily,
    // visibility: "hidden",
    display: 'flex!important',
    alignItems: 'center!important',
    justifyContent: "center!important",
    gap: "10px!important",
    borderRadius: "100%!important",
    border: `1px solid #F3F3F3!important`,
    padding: "6px !important"
  },
  monthControls: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '12px',

  }
}));

interface CalendarItemModal {
  month: moment.Moment[];
  today: moment.Moment;
  startDay: moment.Moment;
  endDay: moment.Moment;
  prevMonth: any;
  nextMonth: any;
  showLoader?: any;
  setShowLoader?: any;
}
let alreadyCalled = false
let interval = null
const CalendarItem = (props: CalendarItemModal) => {
  const classes = useStyles();
  const currentDateRef = useRef(null)
  const { state: outcomeState, dispatch: userTypeDispatch } =
    useContext(UserTypeContext);
  const [showLoaderPage, setShowLoaderPage] = useState(true);
  const [isCalled, setIsCalled] = useState(false);
  let userValues = JSON.parse(localStorage.getItem("userValues"));

  useEffect(() => {
    callservice();
    interval && clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.startDay?.format(), props.endDay?.format()]);
  const getPeronsltrackData = async (bodyData) => {
    alreadyCalled = true
    let data = await trackingService.getPersonalJourneyList(
      outcomeState.token,
      bodyData
    );

    userTypeDispatch(addNotesJourneyAction([]));
    userTypeDispatch(addCategoryJourneyAction([]));
    userTypeDispatch(addCategoryExpandAction([]));
    let i = 0
    let pushedCategory = []
    if (data.data.length <= 0) {
      userTypeDispatch(isEmptyState(true));
    } else {
      userTypeDispatch(isEmptyState(false));
    }
    const runLoop = (catList, index) => {
      const category = catList[index]
      const foundAddedCtagrory = (pushedCategory || []).findIndex(it => it === category.categoryId)

      if (foundAddedCtagrory < 0) {
        userTypeDispatch(pushCategoryJourneyAction([{
          ...category, expanded: true
        }]));
        pushedCategory.push(category.categoryId)
      }
      // userTypeDispatch(addCategoryJourneyAction([...(pushedCategory || []), {
      //   ...category, expanded: true
      // }]));
      // pushedCategory.push({
      //   ...category, expanded: true
      // })
      i = i + 1
    }
    currentDateRef && currentDateRef.current && currentDateRef.current.scrollIntoView()
    // const lengthOfData = data?.data.length
    // const runInterLoop = () => {
    //   if (lengthOfData <= i) {
    //   } else {
    //     // props?.setShowLoader(true)
    //     runLoop(data?.data, i)
    //     setTimeout(() => {
    //       runInterLoop()
    //     }, 300);
    //   }
    // }
    // runInterLoop()
    interval = setInterval(() => {
      if (data?.data.length <= i) {
        // props?.setShowLoader(false)
        clearInterval(interval)
      } else {
        // props?.setShowLoader(true)
        runLoop(data?.data, i)
      }
    }, 500)
    // userTypeDispatch(addCategoryJourneyAction(data?.data.map((it, index) => ({
    //   ...it, expanded: index > 2 ? false : true
    // }))));
    // userTypeDispatch(addNotesJourneyAction(data?.data));
    outcomeState?.isPersonal ? props?.setShowLoader(false) : function () { }()
    alreadyCalled = false
    setIsCalled(false)
  }
  const callservice = async () => {
    try {
      const bodyData = {
        from: moment(props.startDay).format("MM-DD-YYYY"),
        to: moment(props.endDay).format("MM-DD-YYYY"),
      };
      if (!outcomeState?.isPersonal && (!alreadyCalled || !outcomeState.journeyList)) {
        alreadyCalled = true
        let data = await trackingService.getJourneyList(
          outcomeState.token,
          bodyData
        );
        userTypeDispatch(addJourneyAction(data));
        userTypeDispatch(pushCategoryJourneyAction([{
          categoryId: "outcome", expanded: true
        },
        ]));
        userTypeDispatch(pushCategoryJourneyAction([
          {
            categoryId: "influence", expanded: true
          },
        ]));
        userTypeDispatch(pushCategoryJourneyAction([
          {
            categoryId: "reflective", expanded: true
          }]));
        userTypeDispatch(addNotesAction(data));
        props?.setShowLoader(false)
        currentDateRef && currentDateRef.current && currentDateRef.current.scrollIntoView()
        alreadyCalled = false
        outcomeState?.isPersonal ? props?.setShowLoader(false) : setShowLoaderPage(false)
      } else if (outcomeState?.isPersonal) {
        setIsCalled(true)
        /* !alreadyCalled && */ await getPeronsltrackData(bodyData)
      } else {
        outcomeState?.isPersonal ? props?.setShowLoader(false) : setShowLoaderPage(false)
      }
      setTimeout(() => {
        currentDateRef && currentDateRef.current && currentDateRef.current.scrollIntoView()
      }, 500);
    } catch (error) {
      outcomeState?.isPersonal ? props?.setShowLoader(false) : setShowLoaderPage(false)
    }
  };

  const callAndUpdateObject = (type, dataValue, parentId, id) => {
  }
  return (
    <>
      {outcomeState?.isPersonal ? props?.showLoader : showLoaderPage && <FullpageLoader />}
      <div className={clsx(classes.calendarItem, "calenderItem calenderTitle")} data-label={props.month[0] && props.month[0].format("MMMM")} /* style={!outcomeState?.isPersonal ? { width: "100%" } : {}} */>
        <Box display="flex" alignItems="center" position={"relative"} height={"70px"} sx={{ justifyContent: "space-between", background: "#FFF", width: "100%", padding: "60px 24px 27px 0", position: "sticky", top: "0px", alignSelf: "flex-start", zIndex: "100" }}>
          <Box display="flex" alignItems="center" position={"fixed"} height={"70px"} top={"12.5rem"} justifyContent={"space-between"} width={"calc(100% - 46%)"} paddingRight={"40px"} >
            <Typography
              variant="h2"
              component="h2"
              className={classes.monthTitle}
            >
              {props.month[0] && props.month[0].format("MMMM")}
            </Typography>
            <div className={classes.monthControls}>
              <IconButton
                className={classes.iconButtonCalender}
                onClick={() => {
                  interval && clearInterval(interval)
                  props.prevMonth()
                }}
                disabled={
                  !moment(userValues?.createdAt).isBefore(moment(props.startDay))
                }
              >
                <LeftCalendarIcon />
              </IconButton>

              <IconButton
                className={classes.iconButtonCalender}
                onClick={() => {
                  interval && clearInterval(interval)
                  props.nextMonth()
                }}
              >
                <RightCalendarIcon />
              </IconButton>
            </div>
          </Box>


        </Box>
        <Box display="flex" className={classes.monthItem} style={outcomeState?.isExpand ? { marginLeft: "5px" } : {marginLeft: "5px"}}>
          {props.month.map((day: moment.Moment, index: number) => {
            if (outcomeState?.isPersonal) {
              return (
                <PersonalMonthItem
                  count={index}
                  today={props.today}
                  key={index}
                  day={day}
                  month={props.month}
                  callservice={callservice}
                  callAndUpdateObject={callAndUpdateObject}
                  currentDateRef={currentDateRef}
                />
              );
            } else {
              return (
                <MonthItem
                  count={index}
                  today={props.today}
                  key={index}
                  day={day}
                  month={props.month}
                  callservice={callservice}
                  callAndUpdateObject={callAndUpdateObject}
                  currentDateRef={currentDateRef}
                />
              );
            }
          })}
        </Box>
      </div>
    </>
  );
};

export default React.memo(CalendarItem);
