import { Typography } from "@material-ui/core";
import { Grid, MenuItem, Select } from "@mui/material";

import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { theme } from "highcharts";
import { fontSize, fontWeight } from "@mui/system";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import RestoreIcon from "../../assets/images/icon/Restore";
import DeleteIcon from "../../assets/images/icon/DeleteIcon";
import BackIcon from "../../assets/images/icon/BackIcon";
import palette from "../../theme/palette";
import SideBar from "../../components/SideBar";
import { useNavigate, useSearchParams } from "react-router-dom";
import { trackingService } from "../../services/tracking";
import { UserTypeContext } from "../../context/userStatus";
import FullpageLoader from "../../components/fullPageLoader";
import SnackBarComp from "../../components/snackBar";
import RecoverHistory from "./RecoverHistory";
import DeletehistoryModal from "./DeletehistoryModal";
import InfluenceIcon from "../tracker/InfluenceIcon";

const useStyles = makeStyles((theme) => ({
  try: {
    color: "black",
    marginTop: "5px",
    fontWeight: 700,
  },
  subHeading: {
    color: `${palette.secondary.contrast}!important`,
    fontSize: "16px",
    fontWeight: 400,
  },
  container: {
    backgroundColor: "white",
    // width: "91%",
    borderLeft: "1px solid #DCE6E0",
    padding: "60px 40px",
  },
  table: {
    marginTop: "50px",
  },
  main: {
    display: "flex",
  },
}));

function Version() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { state: outcomeState } = useContext(UserTypeContext);
  const [showLoadingBtn, setShowLoadingBtn] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [showSnakbar, setShowSnakbar] = useState(false);
  const [snakbarMsg, setSnakbarMsg] = useState('');
  const [msgType, setMsgType] = useState('');
  const [openrecoverHistory, setopenrecoverHistory] =
    useState(false);
  const [openDeletehistoryModal, setopenDeletehistoryModal] = useState(false);
  const [SelectRow, setSelectRow] = useState('');
  const [ReverseValue, setReverseValue] = useState(0)
  const [isExpandMode, setIsExpandMode] = useState(localStorage.getItem("isExpandMode") === "true");

  function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  const id = getParameterByName("id");

  let versionData = {
    outcomeParentId: id,
  };

  const getVersion = () => {
    setShowLoadingBtn(true);
    trackingService
      .getVersionHistory(outcomeState.token, versionData)
      .then((data) => {
        setShowLoadingBtn(false);
        setRowData(data.response);
      })
      .catch((err) => {
        alert(err);
        setShowLoadingBtn(false);
      });
  };

  const [searchParams, setSearchParams] = useSearchParams();


  const handleRestore = (comingId, row, comingParentId) => {
    let parentId = row.outcomeId ? "outcomeParentId" : "influenceParentId"
    let childId = row.outcomeId ? "outcomeId" : "influenceId"
    const restoreData = {
      [childId]: comingId,
      [parentId]: comingParentId,
    };
    setShowLoadingBtn(true);
    trackingService
      .restoreVersionHistory(outcomeState.token, restoreData)
      .then((data) => {
        setShowLoadingBtn(false);
        getVersion()
        setShowSnakbar(true);
        setSnakbarMsg(data?.message);
        setMsgType("success");
      })
      .catch((err) => {
        alert(err);
        setShowLoadingBtn(false);
      });
  };

  const handleDelete = (comingId, row, comingParentId) => {
    let parentId = row.outcomeId ? "outcomeParentId" : "influenceParentId"
    let childId = row.outcomeId ? "outcomeId" : "influenceId"
    const deleteData = {
      [childId]: comingId,
      [parentId]: comingParentId,
    };
    setShowLoadingBtn(true);
    trackingService
      .deleteVersionHistory(outcomeState.token, deleteData)
      .then((data) => {
        setShowLoadingBtn(false);
        getVersion()
        setShowSnakbar(true);
        setSnakbarMsg(data?.message);
        setMsgType("success");
      })
      .catch((err) => {
        alert(err);
        setShowLoadingBtn(false);
      });
  };

  const handleCloseSnakbar = () => {
    setShowSnakbar(false);
  };

  useEffect(() => {
    id && getVersion();
  }, []);

  return (
    <div className={classes.main}>
      {showLoadingBtn && <FullpageLoader />}
      <SnackBarComp
        showSnakbar={showSnakbar}
        handleCloseSnakbar={handleCloseSnakbar}
        snakbarMsg={snakbarMsg}
        type={msgType ? msgType : 'info'}
      />
      <Grid
        container
        columns={16}
        // height={"100vh"}
        sx={{ /* maxHeight: '100vh', */ background: "#F7F9FA", overflow: "hidden", }}
        className="tracking_container"
      // style={{
      //   height: '100vh !important'
      // }}
      >
        <Grid
          item
          xs={(isExpandMode) ? 0.73 : 2.04}
          sx={{ borderRight: "1px solid #DCE6E0" }}
        >
          <SideBar isExpandMode={isExpandMode} setIsExpandMode={setIsExpandMode} />

        </Grid>
        <Grid
          item
          xs={(isExpandMode) ? 15.27 : 13.96}
          sx={{ flexDirection: "column", background: "transparent", maxHeight: '100vh' }}
        // className={classes.trackBodyGrid}
        >
          <Grid className={`track-title ${classes.container}`} style={{ width: "100%" }}>
            <Typography variant="h6" component="h6" className={classes.subHeading}>
              <span
                style={{ color: "#3E4240", marginRight: "30px", cursor: "pointer" }}
                onClick={() => navigate("/tracking")}
              >
                <BackIcon /> Back
              </span>{" "}
              Tracking / Metric Detail /{" "}
              <span style={{ color: "#3E4240" }}>Version history</span>
            </Typography>
            <Typography variant="h3" component="h3" className={classes.try}>
              Version history of well-being
            </Typography>
            <div className={classes.table}>
              {rowData.length == 0 ? (
                <Typography
                  className={classes.try}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  No Version history
                </Typography>
              ) : (
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{ border: "none" }}
                          className={classes.subHeading}
                        >
                          No.
                        </TableCell>
                        <TableCell
                          style={{ border: "none" }}
                          className={classes.subHeading}
                        >
                          Title
                        </TableCell>
                        <TableCell
                          style={{ border: "none" }}
                          className={classes.subHeading}
                        >
                          Logged Metric type
                        </TableCell>
                        <TableCell
                          style={{ border: "none" }}
                          className={classes.subHeading}
                        >
                          Time
                        </TableCell>
                        <TableCell
                          style={{ border: "none" }}
                          className={classes.subHeading}
                        >
                          Times tracked
                        </TableCell>
                        <TableCell style={{ border: "none" }}></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rowData.map((row, index) => (
                        <TableRow>
                          <TableCell style={{ border: "none" }}>
                            #{rowData.length - index}
                          </TableCell>
                          <TableCell style={{ border: "none" }}>
                            {row.name}
                            {row.status == "ACTIVE" ? (<button style={{ border: "none", marginLeft: "12px", borderRadius: "4px", backgroundColor: "#EBF2F1", cursor: "pointer" }} >Current</button>) : null}
                          </TableCell>
                          <TableCell style={{ border: "none", display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                            {row.outcomeId ? "outcome" : "influence"} &nbsp; <InfluenceIcon item={row} />
                          </TableCell>
                          <TableCell style={{ border: "none" }}>
                            {new Date(row.createdAt).toLocaleString()}
                          </TableCell>
                          <TableCell style={{ border: "none" }}>
                            {row.outcomeResponseCount ?? row.influenceResponseCount}
                          </TableCell>
                          <TableCell style={{ border: "none" }}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                                gap: "30px",
                              }}
                            >
                              <span
                                // onClick={() =>
                                //   handleRestore(row.outcomeId ?? row.influenceId, row, row.outcomeParentId ?? row.influenceParentId)
                                // }
                                onClick={() => {
                                  setopenrecoverHistory(true)
                                  setSelectRow(row)
                                  setReverseValue(rowData.length - index)
                                }}
                                style={{ cursor: "pointer", visibility: row.status == "ACTIVE" ? "hidden" : "visible" }}
                              >
                                <RestoreIcon />
                              </span>
                              <span
                                //  onClick={() =>
                                //   handleDelete(row.outcomeId ?? row.influenceId, row, row.outcomeParentId ?? row.influenceParentId)
                                // }
                                onClick={() => {
                                  setopenDeletehistoryModal(true)
                                  setSelectRow(row)
                                  setReverseValue(rowData.length - index)
                                }}
                                style={{ cursor: "pointer", visibility: row.status == "ACTIVE" ? "hidden" : "visible" }}
                              >
                                <DeleteIcon />
                              </span>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </div>
          </Grid>
        </Grid>
      </Grid>


      <RecoverHistory
        open={openrecoverHistory}
        setopenrecoverHistory={setopenrecoverHistory}
        SelectRow={SelectRow}
        handleRestore={handleRestore}
        ReverseValue={ReverseValue}
      />
      <DeletehistoryModal
        open={openDeletehistoryModal}
        setopenDeletehistoryModal={setopenDeletehistoryModal}
        SelectRow={SelectRow}
        handleDelete={handleDelete}
        ReverseValue={ReverseValue}
      />

    </div>
  );
}

export default Version;
