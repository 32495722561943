import { Box, Checkbox, IconButton, InputAdornment, TextField } from "@mui/material"
import React, { useEffect, useState } from "react"
import TreeView from "@material-ui/lab/TreeView";
import CommonTypo from "../../../components/CommonTypo"
import CloseButton from "../../../components/CloseButton"
import Search from "../../../assets/images/icon/Search"
import ArrowDownNew, { ArrowUpNew } from "../../../assets/images/icon/ArrowDownGreenNew";
import { styled } from "@material-ui/core";
import TreeItem from "@material-ui/lab/TreeItem";
import TabBox from "../../../components/TabBox";
import clsx from "clsx";
import InfluenceIcon from "../../../assets/images/icon/ProgressInsights/InfluenceIcon";
import OutcomeIcon from "../../../assets/images/icon/ProgressInsights/OutcomeIcon";
import { useNavigate } from "react-router-dom";
import { MonthSelection, WeekSelection } from "./SelecrtionDialogue";

const CustomCheckBox = styled(Checkbox)(({ theme }) => ({
    color: "#49454F",
    '& .MuiSvgIcon-root': {
        fontSize: 16, borderRadius: "2px",
    },
    '&.Mui-checked': {
        color: "#65558F",
    },
    "&.MuiCheckbox-root": {
        padding: "0px !important"
    }
}));

const SocialReportShareStep1Content = ({
    setSideModalVisible,
    setSelectedFocus,
    isSideModalVisible,
    isSelectedFocus,
    onClose,
    classes,
    allContacts,
    selectedNodes,
    setVisibleManageContactModal,
    handleNodeSelect,
    setVisibleAddContactModal,
    handleOutInfluSelect,
    allDefaultStates,
    allPersonalStates,
    selectedOutInflu,
    setRadioValue,
    radioValue,
    seelctedVal, setSelectedVal,
    dayToSend,
    setdayToSend,
    editRecord
}) => {
    const navigate = useNavigate()
    const [filteredContacts, setFilteredContacts] = useState([])
    const [searchContactString, setSearchContactString] = useState(null)
    const [filteredMetric, setFilteredMetric] = useState([])
    const [searchMetricString, setSearchMetricString] = useState(null)
    const [selectedTab, setSelectedTab] = useState("Personal structure")

    useEffect(() => {
        const copyOfContacts = JSON.parse(JSON.stringify(allContacts));
        if (searchContactString && searchContactString.trim()) {
            const filteredContacts = copyOfContacts.filter(it => {
                if (it.contacts && it.contacts.length) {
                    it.contacts = it.contacts.filter(ite => ite.name.toLowerCase().includes(searchContactString.toLowerCase()))
                }
                return it.contacts.length > 0
            })
            setFilteredContacts([...filteredContacts])
        } else {
            setFilteredContacts([...allContacts])
        }
    }, [allContacts, searchContactString])

    useEffect(() => {
        const arr = (selectedTab !== "Personal structure"
            ? allDefaultStates
            : allPersonalStates
        )
        const copyOfContacts = JSON.parse(JSON.stringify(arr));
        if (searchMetricString && searchMetricString.trim()) {
            const filteredContacts = copyOfContacts.filter(it => {
                if (it.items && it.items.length) {
                    it.items = it.items.filter(ite => ite.name.toLowerCase().includes(searchMetricString.toLowerCase()))
                }
                return it.items.length > 0
            })
            setFilteredMetric([...filteredContacts])
        } else {

            setFilteredMetric([...arr])
        }
    }, [selectedTab, searchMetricString])

    const selectedOutcomes = allDefaultStates && allDefaultStates.length > 0 ? allDefaultStates.find(it => it.groupName === "Outcomes").items.filter(it => selectedOutInflu.includes(it._id)) : []
    const selectedInfluence = allDefaultStates && allDefaultStates.length > 0 ? allDefaultStates.find(it => it.groupName === "Influences").items.filter(it => selectedOutInflu.includes(it._id)) : []
    const [selectedTabSchedule, setSelectedTabSchedule] = useState("No repeat")
    const [selectedTabScheduleTime, setSelectedTabScheduleTime] = useState("Last 7 days")
    const [selectedTabScheduleTimeMonth, setSelectedTabScheduleTimeMonth] = useState("Last 30 days")
    const [expandedIds, setExpandedIds] = useState([]);
    const renderTree = (nodes, key, onNodeSleect, selectedNode, isParent, showCloseIcon = false, visibleIds = [], setExpandedIds = (ids: any[]) => { }) => (
        <TreeItem
            key={nodes._id}
            nodeId={nodes._id}
            // onClick={handleExpandClick}
            style={{
                display: "flex",
                flexDirection: "column",
                // gap: "16px",

                borderBottom: isParent ? "1px solid var(--color-border)" : "none"
            }}
            className={classes.labelClass}
            label={
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        borderBottom: !isParent ? "1px solid var(--color-border)" : "none",
                        gap: "8px",
                        padding: "16px",
                    }}
                >
                    <Box display={"flex"} alignItems={"center"} gap={"8px"}>
                        <CustomCheckBox
                            checked={selectedNode.indexOf(nodes._id) !== -1}
                            tabIndex={-1}
                            disableRipple
                            sx={{
                                padding: "0px !important"
                            }}
                            icon={<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.25 3V13C14.25 13.3315 14.1183 13.6495 13.8839 13.8839C13.6495 14.1183 13.3315 14.25 13 14.25H3C2.66848 14.25 2.35054 14.1183 2.11612 13.8839C1.8817 13.6495 1.75 13.3315 1.75 13V3C1.75 2.66848 1.8817 2.35054 2.11612 2.11612C2.35054 1.8817 2.66848 1.75 3 1.75H13C13.3315 1.75 13.6495 1.8817 13.8839 2.11612C14.1183 2.35054 14.25 2.66848 14.25 3ZM12.75 3.25H3.25V12.75H12.75V3.25Z" fill="#3E4240" />
                            </svg>
                            } checkedIcon={<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.96938 9.03063C4.89961 8.96086 4.84427 8.87804 4.80651 8.78689C4.76876 8.69573 4.74932 8.59804 4.74932 8.49938C4.74932 8.40071 4.76876 8.30302 4.80651 8.21186C4.84427 8.12071 4.89961 8.03789 4.96938 7.96812C5.03914 7.89836 5.12196 7.84302 5.21311 7.80526C5.30427 7.76751 5.40196 7.74807 5.50063 7.74807C5.59929 7.74807 5.69698 7.76751 5.78814 7.80526C5.87929 7.84302 5.96211 7.89836 6.03188 7.96812L7 8.9375L9.96937 5.9675C10.1103 5.8266 10.3014 5.74745 10.5006 5.74745C10.6999 5.74745 10.891 5.8266 11.0319 5.9675C11.1728 6.1084 11.2519 6.29949 11.2519 6.49875C11.2519 6.69801 11.1728 6.8891 11.0319 7.03L7.53188 10.53C7.4622 10.5999 7.3794 10.6554 7.28824 10.6933C7.19708 10.7311 7.09934 10.7506 7.00063 10.7506C6.90191 10.7506 6.80417 10.7311 6.71301 10.6933C6.62185 10.6554 6.53905 10.5999 6.46938 10.53L4.96938 9.03063ZM14.25 3V13C14.25 13.3315 14.1183 13.6495 13.8839 13.8839C13.6495 14.1183 13.3315 14.25 13 14.25H3C2.66848 14.25 2.35054 14.1183 2.11612 13.8839C1.8817 13.6495 1.75 13.3315 1.75 13V3C1.75 2.66848 1.8817 2.35054 2.11612 2.11612C2.35054 1.8817 2.66848 1.75 3 1.75H13C13.3315 1.75 13.6495 1.8817 13.8839 2.11612C14.1183 2.35054 14.25 2.66848 14.25 3ZM12.75 3.25H3.25V12.75H12.75V3.25Z" fill="#3E4240" />
                            </svg>
                            }
                            onClick={(event) => onNodeSleect(event, nodes)}
                            disabled={isParent && (!Array.isArray(nodes[key]) || !nodes[key].length)}
                        />
                        <CommonTypo size={14} lineHeight={18.2} fontWeight={600} color="var(--color-text-primary)">
                            {nodes.groupName || nodes.name}{" "}
                            {nodes[key] ? "(" + nodes[key].length + ")" : null}
                        </CommonTypo>
                    </Box>

                    {nodes[key] && showCloseIcon && <Box onClick={() => {
                        if (visibleIds.includes(nodes._id)) {
                            const filtered = [...visibleIds].filter(it => it !== nodes._id)
                            setExpandedIds(filtered)
                        } else {
                            const filtered = [...visibleIds]
                            filtered.push(nodes._id)
                            setExpandedIds(filtered)
                        }
                    }}>{visibleIds.includes(nodes._id) ? <ArrowDownNew color="var(--color-text-primary)" /> : <ArrowUpNew color="var(--color-text-primary)" />}</Box>}
                </Box>
            }
        >
            {showCloseIcon ? (
                Array.isArray(nodes[key]) && visibleIds.includes(nodes._id)
                    ? nodes[key].map((node) =>
                        renderTree(node, key, onNodeSleect, selectedNode, false, showCloseIcon, visibleIds, setExpandedIds)
                    )
                    : null
            ) : Array.isArray(nodes[key])
                ? nodes[key].map((node) =>
                    renderTree(node, key, onNodeSleect, selectedNode, false, showCloseIcon, visibleIds, setExpandedIds)
                )
                : null}
            { }
        </TreeItem>
    );
    useEffect(() => {
        if (editRecord && radioValue) {
            if (radioValue === "INSTANT") {
                setSelectedTabSchedule("No repeat")
            }
            if (radioValue === "REGULARLY_WEEK") {
                setSelectedTabSchedule("Weekly")

            }
            if (radioValue === "REGULARLY_MONTH") {
                setSelectedTabSchedule("Monthly")
            }
        }
        if (editRecord && seelctedVal) {
            if (seelctedVal === "LAST_SEVEN_DAYS") {
                setSelectedTabScheduleTimeMonth("Last 7 days")
            }
            if (seelctedVal === "LAST_WEEK") {
                setSelectedTabScheduleTimeMonth("Last week")
            }
            if (seelctedVal === "LAST_MONTH") {
                setSelectedTabScheduleTimeMonth("Last month")
            }
            if (seelctedVal === "LAST_THIRTY_DAYS") {
                setSelectedTabScheduleTimeMonth("Last 30 days")
            }

        }
    }, [radioValue, seelctedVal, editRecord])
    return <>
        <Box
            display={"flex"}
            height={"100%"}
            borderLeft={"1px solid var(--color-border)"}
            flexDirection={"column"}
            gap={"16px"}
            alignItems={"flex-start"}
            alignSelf={"stretch"}
            padding={"32px 24px"}
            justifyContent={"flex-start"}
            sx={{
                width: "660px"
            }}>
            <Box display={"flex"} alignItems={"flex-end"} alignSelf={"stretch"} justifyContent={"flex-end"}>
                <CloseButton onClick={onClose} />
            </Box>
            {isSelectedFocus === "contact" && (
                <>
                    <TextField placeholder="Search" sx={{
                        border: "1px solid var(--color-border)",
                        alignSelf: "stretch",
                        borderRadius: "12px",
                        height: "50px",
                        marginBottom: "0px !important",
                        "& .MuiInputBase-root": {
                            borderRadius: "12px !important",
                            height: "50px",
                            "& .MuiInputBase-input": {
                                borderRadius: "12px",
                                height: "50px",
                                padding: "16px !important",
                                "&::placeholder": {
                                    color: "var(--color-text-primary)",
                                    opacity: 1,
                                    fontSize: "16px"
                                }
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                                display: "none"
                            }
                        }
                    }} onChange={e => {
                        setSearchContactString(e.target.value)
                    }} value={searchContactString} InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                // onClick={handleClickShowPassword}
                                // onMouseDown={handleMouseDownPassword}
                                // onMouseUp={handleMouseUpPassword}
                                edge="end"
                            >
                                <Search size={16} color="var(--color-text-primary)" />
                                {/* {showPassword ? <VisibilityOff /> : <Visibility />} */}
                            </IconButton>
                        </InputAdornment>
                    }} />
                    <Box
                        sx={{
                            height: "calc(100% - 46px)",
                            width: "100%",
                            overflowY: "auto",
                        }}
                        className="hide-scrollbar"
                    >
                        <TreeView
                            multiSelect
                            expanded={filteredContacts.map((it) => it._id)}
                            className={classes.treeClass}
                            // defaultCollapseIcon={<ExpandMoreIcon />}
                            // defaultExpandIcon={<ChevronRightIcon />}
                            selected={[]}

                        >
                            {filteredContacts.map((node) =>
                                renderTree(
                                    node,
                                    "contacts",
                                    (event, nodes) => handleNodeSelect(event, nodes._id),
                                    selectedNodes,
                                    true
                                )
                            )}
                        </TreeView>
                    </Box>
                    <Box
                        sx={{
                            height: "46px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-evenly",
                            alignSelf: "stretch"
                        }}
                    >
                        <Box sx={{
                            cursor: "pointer",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }} onClick={() => {
                            setVisibleManageContactModal(true)
                        }}>
                            <CommonTypo size={12} lineHeight={15} color="var(--color-text-primary)"  >Manage accounts</CommonTypo>
                        </Box>
                        <Box sx={{
                            cursor: "pointer",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }} onClick={() => {
                            setVisibleAddContactModal(true)
                        }}>
                            <CommonTypo size={12} lineHeight={15} color="var(--color-text-primary)" >Add new account</CommonTypo>
                        </Box>

                    </Box>
                </>
            )}
            {isSelectedFocus === "metric" && (
                <>
                    <Box display={"flex"} alignItems={"center"} alignSelf={"stretch"}>
                        <TabBox tabs={[
                            {
                                name: "Personal structure"
                            },
                            {
                                name: "Standard structure"
                            }
                        ]} onSelectTab={(it) => {
                            setSelectedTab(it.name)
                        }} activeTab={selectedTab} fullWidth />
                    </Box>

                    <TextField placeholder="Search" sx={{
                        border: "1px solid var(--color-border)",
                        alignSelf: "stretch",
                        borderRadius: "12px",
                        height: "50px",
                        marginBottom: "0px !important",
                        "& .MuiInputBase-root": {
                            borderRadius: "12px !important",
                            height: "50px",
                            "& .MuiInputBase-input": {
                                borderRadius: "12px",
                                height: "50px",
                                padding: "16px !important",
                                "&::placeholder": {
                                    color: "var(--color-text-primary)",
                                    opacity: 1,
                                    fontSize: "16px"
                                }
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                                display: "none"
                            }
                        }
                    }} onChange={e => {
                        setSearchMetricString(e.target.value)
                    }} value={searchMetricString} InputProps={{
                        endAdornment: <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                // onClick={handleClickShowPassword}
                                // onMouseDown={handleMouseDownPassword}
                                // onMouseUp={handleMouseUpPassword}
                                edge="end"
                            >
                                <Search size={16} color="var(--color-text-primary)" />
                                {/* {showPassword ? <VisibilityOff /> : <Visibility />} */}
                            </IconButton>
                        </InputAdornment>
                    }} />

                    <Box
                        sx={{
                            height: "calc(100% - 46px)",
                            width: "100%",
                            overflowY: "auto",
                        }}
                        className="hide-scrollbar"
                    >
                        <Box display={"flex"} alignItems={"center"} alignSelf={"stretch"} flexWrap={"wrap"} gap={"8px"}>
                            {selectedOutcomes.map(it => <OutInflueTag isOutcome={true} name={it.name} />)}
                            {selectedInfluence.map(it => <OutInflueTag isOutcome={false} name={it.name} />)}
                        </Box>
                        <TreeView
                            multiSelect
                            expanded={(selectedTab !== "Personal structure"
                                ? allDefaultStates
                                : allPersonalStates
                            ).map((it) => it._id)}
                            className={clsx(classes.treeClass, "hide-scrollbar")}
                            // defaultCollapseIcon={<ExpandMoreIcon />}
                            // defaultExpandIcon={<ChevronRightIcon />}
                            selected={[]}
                        >
                            {filteredMetric.map((node) =>
                                renderTree(
                                    node,
                                    "items",
                                    (event, nodes) => {
                                        handleOutInfluSelect(
                                            event,
                                            nodes._id,
                                            selectedTab !== "Personal structure" ? false : true
                                        );
                                    },
                                    selectedOutInflu,
                                    true, true, expandedIds, setExpandedIds
                                )
                            )}
                        </TreeView>
                    </Box>
                    <Box
                        sx={{
                            height: "46px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-evenly",
                            alignSelf: "stretch"
                        }}
                    >
                        <Box sx={{
                            cursor: "pointer",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }} onClick={() => {
                            onClose()
                            navigate("/add-category")
                        }}>
                            <CommonTypo size={12} lineHeight={15} color="var(--color-text-primary)"  >Manage personal structure</CommonTypo>
                        </Box>
                    </Box>
                </>
            )}
            {isSelectedFocus === "schedule" && (
                <>
                    <Box display={"flex"} alignSelf={"stretch"} flexDirection={"column"} gap={"8px"}>
                        <CommonTypo size={16} lineHeight={19.2} fontWeight={600} color="var(--color-text-primary)">How often do you want to share?</CommonTypo>
                        <Box display={"flex"} alignItems={"center"} alignSelf={"stretch"}>
                            <TabBox tabs={[
                                {
                                    name: "No repeat"
                                },
                                {
                                    name: "Weekly"
                                },
                                {
                                    name: "Monthly"
                                }
                            ]} onSelectTab={(it) => {
                                setSelectedTabSchedule(it.name)
                                if (it.name === "No repeat") {
                                    setRadioValue("INSTANT")
                                }
                                if (it.name === "Weekly") {
                                    setRadioValue("REGULARLY_WEEK")
                                    setSelectedVal("LAST_SEVEN_DAYS")
                                }
                                if (it.name === "Monthly") {
                                    setRadioValue("REGULARLY_MONTH")
                                    setSelectedVal("LAST_THIRTY_DAYS")
                                }
                            }} activeTab={selectedTabSchedule} fullWidth />
                        </Box>
                    </Box>
                    {selectedTabSchedule === "Weekly" && (
                        <>
                            <Box display={"flex"} alignSelf={"stretch"} flexDirection={"column"} gap={"8px"}>
                                <CommonTypo size={16} lineHeight={19.2} fontWeight={600} color="var(--color-text-primary)">Which report do you want to share?</CommonTypo>
                                <Box display={"flex"} alignItems={"center"} alignSelf={"stretch"}>
                                    <TabBox tabs={[
                                        {
                                            name: "Last 7 days"
                                        },
                                        {
                                            name: "Last week"
                                        },
                                    ]} onSelectTab={(it) => {
                                        setSelectedTabScheduleTime(it.name)
                                        if (it.name === "Last 7 days") {
                                            setSelectedVal("LAST_SEVEN_DAYS")
                                        }
                                        if (it.name === "Last week") {
                                            setSelectedVal("LAST_WEEK")
                                        }
                                    }} activeTab={selectedTabScheduleTime} fullWidth />
                                </Box>
                            </Box>
                            <Box display={"flex"} alignSelf={"stretch"} flexDirection={"column"} gap={"8px"}>
                                <CommonTypo size={16} lineHeight={19.2} fontWeight={600} color="var(--color-text-primary)">Which day to send the report?</CommonTypo>
                                <Box display={"flex"} alignItems={"center"} alignSelf={"stretch"}>
                                    <WeekSelection dayToSend={dayToSend} setdayToSend={setdayToSend} />
                                </Box>
                            </Box>
                        </>
                    )}
                    {selectedTabSchedule === "Monthly" && (
                        <>
                            <Box display={"flex"} alignSelf={"stretch"} flexDirection={"column"} gap={"8px"}>
                                <CommonTypo size={16} lineHeight={19.2} fontWeight={600} color="var(--color-text-primary)">Which report do you want to share?</CommonTypo>
                                <Box display={"flex"} alignItems={"center"} alignSelf={"stretch"}>
                                    <TabBox tabs={[
                                        {
                                            name: "Last 30 days"
                                        },
                                        {
                                            name: "Last month"
                                        },
                                    ]} onSelectTab={(it) => {
                                        setSelectedTabScheduleTimeMonth(it.name)
                                        if (it.name === "Last 30 days") {
                                            setSelectedVal("LAST_THIRTY_DAYS")
                                        }
                                        if (it.name === "Last month") {
                                            setSelectedVal("LAST_MONTH")
                                        }
                                    }} activeTab={selectedTabScheduleTimeMonth} fullWidth />
                                </Box>
                            </Box>
                            <Box display={"flex"} alignSelf={"stretch"} flexDirection={"column"} gap={"8px"}>
                                <CommonTypo size={16} lineHeight={19.2} fontWeight={600} color="var(--color-text-primary)">Which day to send the report?</CommonTypo>
                                <Box display={"flex"} alignItems={"center"} alignSelf={"stretch"}>
                                    <MonthSelection dayToSend={dayToSend} setdayToSend={setdayToSend} />
                                </Box>
                            </Box>
                        </>
                    )}
                </>
            )}
        </Box>
    </>
}

export default SocialReportShareStep1Content

const OutInflueTag = ({ name, isOutcome }) => {
    return <Box sx={{
        height: "34px",
        padding: "8px 24px",
        gap: "4px",
        display: "flex",
        alignItems: "center",
        borderRadius: "20px",
        border: "1px solid var(--color-border)",

    }}>
        {isOutcome ? <OutcomeIcon /> : <InfluenceIcon />}
        <CommonTypo size={14} lineHeight={18.2} color="var(--color-text-secondary)">{name}</CommonTypo>
    </Box>
}