import React from "react";

const ExpandIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M14.9999 12.5001L9.99988 7.50012L4.99988 12.5001" stroke="black" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ExpandIcon;
