import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, styled } from "@mui/material";
import SideBar from "../../components/SideBar";
import InsightsTitle from "./insightsTitle";
import InsightsOutcomes from "./insightsOutcomes";
import { makeStyles } from "@material-ui/core";
import OutcomeInsights from "./outcomeInsights";
import clsx from "clsx";
import InfluenceInsights from "./influence/index";
import InfluenceOverTime from "./influenceOverTime";
import NoInsightsDataAvailable from "./noInsightsData";
import { InsightTypeContext } from "../../context/userInsights";
import { UserTypeContext } from "../../context/userStatus";
import { userService } from "../../services/auth";
import { insightsService } from "../../services/insightsService";
import { setInsightData, setLeftMenuData, setRightMenuData } from "../../store/actions/insightTypeAction";
import InsightLearnMore from "../../assets/images/icon/LearnMore";
import Correlations from "../../assets/images/icon/Correlations";
import CorrelationsInsights from "./CorrelationsInsights";
import { useNavigate } from "react-router-dom";
import { TrackingContext } from "../../context/userTracking";
import { setSidebarExpand } from "../../store/actions/trackerAction";
import insightsProgressStyles from "./insightsClasses";
import InsightsHeader from "./InsightsHeader";
import CommonTypo from "../../components/CommonTypo";
import palette from "../../theme/palette";
import OutcomeIcon from "../../assets/images/icon/ProgressInsights/OutcomeIcon";
import InfluenceIcon from "../../assets/images/icon/ProgressInsights/InfluenceIcon";
import CorrelationIcon from "../../assets/images/icon/ProgressInsights/CorrelationIcon";
import DataEntry from "./DataEntry";
import Chart from "./Chart";
import PositiveNegative from "./PositiveNegative";
import InsightinformationModal from "./insightInformationModal";
import moment from "moment";
import AreasIcon from "../../assets/images/icon/ProgressInsights/AreasIcon";
import InfluenceAreas from "./areas";
import TrackHeader from "../tracker/TrackHeader";
import CongratulationsModal from "./common/congratulations";
const RootStyle = styled("div")(({ theme }) => ({
  padding: "60px 40px 0px",

  [theme.breakpoints.down("lg")]: {
    padding: "40px 20px 0px",
  },
  [theme.breakpoints.down(1001)]: {
    padding: "20px",
  },
}));

const useStyles = makeStyles((theme) => ({
  progressInfluence: {
    [theme.breakpoints.down(1001)]: {
      maxWidth: "100%!important",
    },
  },
  insightsOutcomes: {
    // marginRight: "30px!important",
  },
  ratingGraph: {
    [theme.breakpoints.down(1001)]: {
      maxWidth: "100%!important",
      marginTop: "48px!important",
    },
  },
  tabBarGrids: {
    margin: '10px 0',
    borderBottom: '1px solid #DCE5E0',
    padding: '10px 0',
  },
  tabBarTab: {
    color: 'rgba(62, 66, 64, 0.5)',
    padding: '0px 10px',
    fill: 'rgba(62, 66, 64, 0.5)',
    display: "flex",
    alignItems: "center"
  },
  tabBarTabActive: {
    display: "flex",
    color: '#3E4240',
    fill: '#3E4240',
    padding: '0px 10px',
    alignItems: "center",
    fontWeight: 600
  },
  spanAroundSpace: {
    margin: '0px 6px'
  }
}));


const InsightsView = (props) => {
  const classes = insightsProgressStyles()
  const { state: InsightState, dispatch: insightTypeDispatch } = useContext(InsightTypeContext);
  const { state: IUserTypeState, dispatch: userTypeDispatch } =
    useContext(UserTypeContext);
  let userValues = JSON.parse(localStorage.getItem("userValues"));
  const navigate = useNavigate();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const outcomeId = params.get("id");
  const [loadingOutcomes, setLoadingOutcomes] = useState(false)

  const [selectedTime, handleChangeTime] = useState("This Week");
  const [isExpandMode, setIsExpandMode] = useState(localStorage.getItem("isExpandMode") === "true");
  const [selectedTab, setSelectedTab] = useState("outcome");
  const [visibleInightInformation, setVisibleInsightInformation] = useState(false);
  const [selectOutcome, setSelectOutcome] = useState(outcomeId);
  const [outcomeCongratsModalVisible, setOutcomeCongratsModal] = useState(false);
  const [outcomeCongratsModalData, setOutcomeCongratsModalData] = useState([]);
  const getInsightDetails = (limit = null) => {
    const userId: any = {
      uid: IUserTypeState?.userId ? IUserTypeState?.userId : userValues?.uid,
      "from": "10-08-2021",
      "to": "10-08-2023"
    };
    if (limit) {
      userId.limit = limit
    }
    const classesList = [
      "wellBeingCard",
      "energyCard",
      "senseCard",
      "improveCard",
      "stressCard"
    ]
    insightsService
      .getInsights(userId, IUserTypeState.token)
      .then((data: any) => {

        if (data?.data && data?.data.length) {
          data.data = data?.data.map(it => {
            const classNameGet = classesList[Math.floor(Math.random() * classesList.length)];
            return {
              ...it,
              className: classNameGet
            }
          })
        }
        insightTypeDispatch(setInsightData(data))
        if (!selectOutcome && data.data && data.data[0].outcomeId) {
          setSelectOutcome(data.data[0].outcomeId);
          navigate("/insight-progress?id=" + data.data[0].outcomeId);
        }
        setLoadingOutcomes(false)
      })
      .catch((error) => {
        setLoadingOutcomes(false)
        console.log("User profile Error", error);
      });
  }
  const getCorrelationMenu = () => {
    const userId = {
      uid: IUserTypeState?.userId ? IUserTypeState?.userId : userValues?.uid,
      "from": "10-08-2021",
      "to": "10-08-2023"
    };
    insightsService
      .getCorrelationMenu(userId, IUserTypeState.token)
      .then((data: any) => {
        insightTypeDispatch(setLeftMenuData(data?.leftMenu))
        insightTypeDispatch(setRightMenuData(data?.rightMenu))
      })
      .catch((error) => {
        console.log("User profile Error", error);
      });
  }
  useEffect(() => {
    if (selectedTab === "outcome") {
      const userId = {};
      insightsService
        .getOutcomeCelebration(userId, IUserTypeState.token)
        .then((data: any) => {
          console.log("data", data);
          if (data.response && data.response.length) {
            setOutcomeCongratsModalData(data.response)
            setOutcomeCongratsModal(true)
          }
        })
        .catch((error) => {
          console.log("User profile Error", error);
        });
    }
  }, [])

  useEffect(() => {
    if (selectedTab === "outcome" && !InsightState.insights.data?.length) {
      setLoadingOutcomes(true)
      getInsightDetails();
    }
    if (selectedTab === "correlations" && !(InsightState.leftMenu.length || InsightState.rightMenu.length)) {
      getCorrelationMenu()
    }

  }, [selectedTime, selectedTab])
  const { state: ITrackerState, dispatch: trackingDispatch } =
    useContext(TrackingContext);
  const { isExpandable, isArchiveMode, } = ITrackerState;
  console.log("IUserTypeState", IUserTypeState);
  const { userDetails: { createdAt } } = IUserTypeState
  const diff = moment().diff(moment(createdAt), 'days')
  console.log("diff", diff);
  const foundOutcome = InsightState.insights.data?.find(it => it.outcomeId === selectOutcome)
  const { responseCount = 1 } = (foundOutcome || {})
  return (
    <Grid
      container
      columns={16}
      // height={"100vh"}
      sx={{ /* maxHeight: '100vh', */ overflow: "hidden" }}
      className="tracking_container"
    // style={{
    //   height: '100vh !important'
    // }}
    >
      <Grid
        item
        // xs={/* IUserTypeState?.isExpand ||  */ isExpandable ? 0.72 : 2.04}
        width={isExpandable ? "90px" : "220px"}
        sx={{ borderRight: "1px solid #DCE6E0", height: "100vh" }}
        position={"relative"}
      >
        <SideBar
          isExpandMode={isExpandable}
          setIsExpandMode={(val) => {
            trackingDispatch(setSidebarExpand(val));
          }}
        />
      </Grid>
      <Grid
        item
        // xs={isExpandable ? 15.27 : 13.96}
        sx={{
          flexDirection: "column",
          background: "transparent !important",
          height: "100vh",
          padding: "0px 0px",
          width: isExpandable ? "calc(100vw - 90px)" : "calc(100vw - 220px)"
        }}
        className={classes.trackBodyGrid}
      >
        <Box sx={{
          padding: "0px 0px 16px 0px",
          display: "flex",
          flexDirection: "column",
          gap: "32px"
        }}>
          <Box sx={{
            padding: "0px 0px 16px",
            borderBottom: "1px solid #EEEEEE"
          }}>
            <TrackHeader setIsArchiveMode={() => {

            }} isProgress />
          </Box>
          <Box sx={{
            padding: "0px 16px",
            display: "flex",
            flexDirection: "column",
            gap: "32px"
          }}>
            <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"}>
              <div className={classes.tabBox}>
                <Box className={classes.tabClasses} onClick={() => {
                  setSelectedTab("outcome")
                }} sx={{
                  background: selectedTab === "outcome" ? palette.common.white : "transparent",

                }}>
                  <OutcomeIcon />
                  <CommonTypo size={16} color="#3E4240" fontWeight={selectedTab === "outcome" ? 600 : 400}>Outcomes</CommonTypo>
                </Box>
                <Box className={classes.tabClasses} onClick={() => {
                  setSelectedTab("influences")
                }} sx={{
                  background: selectedTab === "influences" ? palette.common.white : "transparent"
                }}>
                  <InfluenceIcon />
                  <CommonTypo size={16} color="#3E4240" fontWeight={selectedTab === "influences" ? 600 : 400}>Influences</CommonTypo>
                </Box>
                <Box className={classes.tabClasses} onClick={() => {
                  setSelectedTab("correlations")
                }} sx={{
                  background: selectedTab === "correlations" ? palette.common.white : "transparent"
                }}>
                  <CorrelationIcon />
                  <CommonTypo size={16} color="#3E4240" fontWeight={selectedTab === "correlations" ? 600 : 400}>Correlations</CommonTypo>
                </Box>
                <Box className={classes.tabClasses} onClick={() => {
                  setSelectedTab("areas")
                }} sx={{
                  background: selectedTab === "areas" ? palette.common.white : "transparent"
                }}>
                  <AreasIcon />
                  <CommonTypo size={16} color="#3E4240" fontWeight={selectedTab === "areas" ? 600 : 400}>Areas</CommonTypo>
                </Box>
              </div>
              {responseCount >= 35 && selectedTab === "outcome" && <Box sx={{
                border: "1px solid #EEEEEE",
                padding: "16px",
                borderRadius: "12px",
                display: "flex",
                alignItems: "center",
                gap: "4px"
              }}>
                <CommonTypo fontWeight={600} size={16} color="#3E4240">{responseCount} days</CommonTypo>
                <CommonTypo fontWeight={500} size={16} color="#3E4240">tracked</CommonTypo>
              </Box>}
            </Box>

            {selectedTab === "outcome" ? (
              <>
                <CongratulationsModal type="outcome" visible={outcomeCongratsModalVisible} onClose={() => {
                  setOutcomeCongratsModal(false)
                }} data={outcomeCongratsModalData} />
                <InsightsOutcomes
                  setSelectOutcome={setSelectOutcome}
                  selectOutcome={selectOutcome}
                  outcomeData={InsightState.insights.data}
                  loadingOutcomes={loadingOutcomes}
                />
                {!loadingOutcomes && responseCount < 35 && <DataEntry
                  setSelectOutcome={setSelectOutcome}
                  selectOutcome={selectOutcome}
                  outcomeData={InsightState.insights.data}
                  completedCount={1}
                />}
                {responseCount >= 14 && (
                  <>
                    <Chart selectOutcome={selectOutcome} outcomeData={InsightState.insights.data} />
                    <PositiveNegative selectOutcome={selectOutcome} outcomeData={InsightState.insights.data} getInsightDetails={getInsightDetails} />
                    <Box sx={{
                      padding: "0px 0px 0px 54px",
                      width: "max-content",
                      display: "flex",
                      alignItems: "center",
                      gap: "8px"
                    }}>
                      <Box sx={{
                        height: "40px",
                        padding: "16px 12px 16px 16px",
                        gap: "8px",
                        borderRadius: "20px",
                        background: "#EEEEEE",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                      }}>
                        <CommonTypo size={16} color="#656565">Influences use all time data</CommonTypo>
                      </Box>
                      <Box sx={{
                        height: "40px",
                        padding: "16px 12px 16px 16px",
                        gap: "8px",
                        borderRadius: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer"
                      }} onClick={() => setVisibleInsightInformation(true)}>
                        <CommonTypo size={16} color="#656565">How does it work?</CommonTypo>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="10" cy="10" r="7.5" stroke="#656565" stroke-width="2" />
                          <circle cx="9.99992" cy="14.9997" r="0.416667" fill="#656565" stroke="#656565" />
                          <path d="M10 13.333V12.1506C10 11.3638 10.5035 10.6652 11.25 10.4163V10.4163C11.9965 10.1675 12.5 9.46893 12.5 8.68206V8.25442C12.5 6.91711 11.4159 5.83301 10.0786 5.83301H10C8.61929 5.83301 7.5 6.9523 7.5 8.33301V8.33301" stroke="#656565" stroke-width="2" />
                        </svg>

                      </Box>
                    </Box>

                  </>
                )}

              </>
            ) : selectedTab === "influences" ? <>
              <InfluenceInsights />
            </> : selectedTab === "areas" ? <>
              <InfluenceAreas />
            </> : <>


              <CorrelationsInsights selectOutcome={selectOutcome} outcomeData={InsightState.insights.data} />
            </>}
          </Box>


        </Box>
        <InsightinformationModal
          visibleInightInformation={visibleInightInformation}
          setVisibleInsightInformation={setVisibleInsightInformation}
        />
      </Grid>
    </Grid>
  );
};
export default InsightsView;
