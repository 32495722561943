import React from "react";

const SortDownIcon = () => (
    <svg width="7" height="14" viewBox="0 0 7 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.5 13.5L5.5 1.5L1 6" stroke="#B7BDB9" strokeWidth="2" strokeLinejoin="round" />
    </svg>


)

export default SortDownIcon