import React, { useContext, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import useStyles from "./TrackingClasses";
import moment from "moment";
import palette from "../../theme/palette";
import SnackBarComp from "../../components/snackBar";
import { UserTypeContext } from "../../context/userStatus";
import { TrackingContext } from "../../context/userTracking";
import {
  addCategoryJourneyAction,
  addJourneyAction,
} from "../../store/actions/userTypeAction";
import { trackingService } from "../../services/tracking";
import {
  setDayCompleteMessage,
  setTrackingState,
} from "../../store/actions/trackerAction";
import AddTrackingEventModal from "../tracker/addTrackingEventModal";
import { Typography } from "@mui/material";
import { TRACKING_CONST } from "./constants";
const IconPersonalOutcome = ({
  item: catItem,
  category = {} as any,
  style = {},
  date,
  isExpand,
  calendar,
  journyList,
  setOutcomeId,
  outcomeId,
  callservice,
  isLast = false,
}) => {
  const classes = useStyles();
  const textareaRef = useRef<HTMLTextAreaElement>();
  const { state: outcomeState, dispatch: UserTypeDispatch } =
    useContext(UserTypeContext);
  const { state: ITrackerState, dispatch: trackingDispatch } =
    useContext(TrackingContext);
  const [item, setItem] = useState<any>();

  const formatedDate = moment(date).format("MM-DD-YYYY");
  const clickCondition = moment(date).isBetween(
    moment().subtract(8, "year"),
    moment().add(1, 'day').startOf('day')
  );

  useEffect(() => {
    if (isLast && ITrackerState.showLoader) {
      trackingDispatch(
        setTrackingState({
          showLoader: false,
          isLastAlreadyLoaded: true,
        })
      );
    }
  }, [isLast, ITrackerState.showLoader]);
  const [openTrackingEventModal, setOpenTrackingEventModal] = useState(false);
  const [displayBorder, setDisplayBorder] = useState(false);
  const [outcomeIndex, setOutcomeIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState<any>();
  const [optionIndex, setOptionIndex] = useState<any>("");
  const [showInput, setShowInput] = useState(false);
  const [showNotesInput, setShowNotesInput] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputNotesValue, setNotesInputValue] = useState<any>("");
  const [showSnakbar, setShowSnakbar] = useState(false);
  const [snakbarMsg, setSnakbarMsg] = useState("");
  const [msgType, setMsgType] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const { outcomesResponses = [] } = catItem;
  const heightObj = TRACKING_CONST.EXPAND_HEIGHT;
  React.useLayoutEffect(() => {
    if (!textareaRef.current) return;
    if (textareaRef.current) {
      // Reset height - important to shrink on delete
      textareaRef.current.style.height = "inherit";
      // Set height
      textareaRef.current.style.height = `${Math.max(
        textareaRef.current.scrollHeight,
        32
      )}px`;
    }
  }, [showNotesInput, inputNotesValue]);
  React.useLayoutEffect(() => {
    if (!textareaRef.current) return;
    if (textareaRef.current) {
      textareaRef.current.focus();
      textareaRef.current.setSelectionRange(
        textareaRef.current.value.length,
        textareaRef.current.value.length
      );
    }
  }, [showNotesInput]);
  const found =
    outcomesResponses && outcomesResponses.length > 0
      ? outcomesResponses.find((journey) => journey?.date === formatedDate)
      : undefined;
  let nearValue = undefined;
  const nearestValueOutcome = (val, influenceItem) => {
    let arr = [];
    if (
      influenceItem.outcomeId &&
      influenceItem.options.length &&
      influenceItem.options[val - 1]
    ) {
      return influenceItem.options[val - 1].colorCode;
    }
    if (influenceItem?.options && influenceItem?.options.length) {
      arr = [...influenceItem?.options].map((it) => it.score);
    }
    const returnVal =
      arr.reduce(
        (p, n) => (Math.abs(p) > Math.abs(n - val) ? n - val : p),
        Infinity
      ) + val;
    if (influenceItem?.options && influenceItem?.options.length) {
      const foundIndexOfValue = influenceItem?.options.findIndex(
        (it) => it.score === returnVal
      );
      if (foundIndexOfValue > -1) {
        return influenceItem?.options[foundIndexOfValue]?.colorCode;
      }
    }
    return false;
  };
  if (found) {
    nearValue = nearestValueOutcome(found?.index, catItem);
    if (found?.type === "TYPE2") {
      nearValue = null;
    }
    if (Number(found?.score) === 0) {
      nearValue = "#C7C7C7";
    }
  }
  const renderToNextInput = (e, categoryId, idx) => {
    if (outcomeState.isPersonal) {
      if (catItem.categoryName !== "Other") {
        const foundCategory = journyList.find(
          (it) => it.categoryId === categoryId
        );
        if (
          foundCategory &&
          foundCategory.alls &&
          foundCategory.alls.length
        ) {
          const findIndexOfEl = foundCategory.alls.findIndex(
            (it) => it._id === idx
          );
          if (findIndexOfEl > -1) {
            // // down arrow handle
            if (
              (e.key === "Enter" || Number(e.keyCode) === 40) &&
              foundCategory.alls[findIndexOfEl + 1] &&
              foundCategory.alls[findIndexOfEl + 1]._id
            ) {
              console.log(
                "foundCategory.alls[findIndexOfEl + 1].skipMetric",
                foundCategory.alls[findIndexOfEl + 1].skipMetric
              );
              if (foundCategory.alls[findIndexOfEl + 1].skipMetric) {
                renderToNextInput(e, categoryId, foundCategory.alls[findIndexOfEl + 1]._id);
              } else {
                const date = moment(formatedDate, "mm-DD-yyyy").format(
                  "mm-DD-yyyy"
                );
                const foundElement = document.getElementById(
                  date + "_" + foundCategory.alls[findIndexOfEl + 1]._id
                );
                console.log("foundElement", foundElement);
                if (foundElement) {
                  foundElement.click();
                }
              }
            } else if (e.key === "Enter" || Number(e.keyCode) === 40) {
              const foundCategoryIndex = journyList.findIndex(
                (it) => it.categoryId === categoryId
              );
              if (foundCategoryIndex > -1) {
                if (journyList[foundCategoryIndex + 1].name === "Other") {
                  if (
                    journyList[foundCategoryIndex + 1].outcomes &&
                    journyList[foundCategoryIndex + 1].outcomes.length
                  ) {
                    if (
                      journyList[foundCategoryIndex + 1].outcomes[0]
                        .skipMetric
                    ) {
                      renderToNextInput(e, categoryId,
                        journyList[foundCategoryIndex + 1].outcomes[0]._id
                      );
                    } else {
                      const date = moment(formatedDate, "mm-DD-yyyy").format(
                        "mm-DD-yyyy"
                      );
                      const foundElement = document.getElementById(
                        date +
                        "_" +
                        journyList[foundCategoryIndex + 1].outcomes[0]._id
                      );
                      if (foundElement) {
                        foundElement.click();
                      }
                    }
                  } else if (
                    journyList[foundCategoryIndex + 1].influences &&
                    journyList[foundCategoryIndex + 1].influences.length
                  ) {
                    if (
                      journyList[foundCategoryIndex + 1].influences[0]
                        .skipMetric
                    ) {
                      renderToNextInput(e, categoryId,
                        journyList[foundCategoryIndex + 1].influences[0]._id
                      );
                    } else {
                      const date = moment(formatedDate, "mm-DD-yyyy").format(
                        "mm-DD-yyyy"
                      );
                      const foundElement = document.getElementById(
                        date +
                        "_" +
                        journyList[foundCategoryIndex + 1].influences[0]._id
                      );
                      if (foundElement) {
                        foundElement.click();
                      }
                    }
                  }
                } else {
                  if (
                    journyList[foundCategoryIndex + 1] &&
                    journyList[foundCategoryIndex + 1].alls &&
                    journyList[foundCategoryIndex + 1].alls.length
                  ) {
                    if (
                      journyList[foundCategoryIndex + 1].alls[0].skipMetric
                    ) {
                      renderToNextInput(e, categoryId,
                        journyList[foundCategoryIndex + 1].alls[0]._id
                      );
                    } else {
                      const date = moment(formatedDate, "mm-DD-yyyy").format(
                        "mm-DD-yyyy"
                      );
                      const foundElement = document.getElementById(
                        date +
                        "_" +
                        journyList[foundCategoryIndex + 1].alls[0]._id
                      );
                      if (foundElement) {
                        foundElement.click();
                      }
                    }
                  }
                }
              }
            }
            // // up arrow key
            if (
              Number(e.keyCode) === 38 &&
              foundCategory.alls[findIndexOfEl - 1] &&
              foundCategory.alls[findIndexOfEl - 1]._id
            ) {
              if (foundCategory.alls[findIndexOfEl - 1].skipMetric) {
                renderToNextInput(e, categoryId, foundCategory.alls[findIndexOfEl - 1]._id);
              } else {
                const date = moment(formatedDate, "mm-DD-yyyy").format(
                  "mm-DD-yyyy"
                );
                const foundElement = document.getElementById(
                  date + "_" + foundCategory.alls[findIndexOfEl - 1]._id
                );
                if (foundElement) {
                  foundElement.click();
                }
              }
            }
            //right side arrow keys
            if (Number(e.keyCode) === 39) {
              const date = moment(formatedDate, "mm-DD-yyyy")
                .add(1, "day")
                .format("mm-DD-yyyy");
              const foundElement = document.getElementById(date + "_" + idx);
              console.log("foundElement", foundElement);
              if (foundElement) {
                foundElement.click();
              }
            }
            //left side arrow keys
            if (Number(e.keyCode) === 37) {
              const date = moment(formatedDate, "mm-DD-yyyy")
                .subtract(1, "day")
                .format("mm-DD-yyyy");
              const foundElement = document.getElementById(date + "_" + idx);
              if (foundElement) {
                foundElement.click();
              }
            }
          }
        }
      } else {
        const foundOther = (journyList || []).findIndex(
          (it) => it.name === "Other"
        );
        if (foundOther > -1) {
          const outcomeFound = journyList[foundOther].outcomes.findIndex(
            (it) => it._id === idx
          );
          if (outcomeFound > -1) {
            // // down arrow handle
            if (
              (e.key === "Enter" || Number(e.keyCode) === 40) &&
              journyList[foundOther].outcomes[outcomeFound + 1] &&
              journyList[foundOther].outcomes[outcomeFound + 1]._id
            ) {
              if (
                journyList[foundOther].outcomes[outcomeFound + 1].skipMetric
              ) {
                renderToNextInput(e, categoryId,
                  journyList[foundOther].outcomes[outcomeFound + 1]._id
                );
              } else {
                const date = moment(formatedDate, "mm-DD-yyyy").format(
                  "mm-DD-yyyy"
                );
                const foundElement = document.getElementById(
                  date +
                  "_" +
                  journyList[foundOther].outcomes[outcomeFound + 1]._id
                );
                if (foundElement) {
                  foundElement.click();
                }
              }
            } else if (e.key === "Enter" || Number(e.keyCode) === 40) {
              if (journyList[foundOther].influences[0].skipMetric) {
                renderToNextInput(e, categoryId, journyList[foundOther].influences[0]._id);
              } else {
                const foundZeroInfluence =
                  journyList[foundOther].influences[0]?._id;
                if (foundZeroInfluence) {
                  const date = moment(formatedDate, "mm-DD-yyyy").format(
                    "mm-DD-yyyy"
                  );
                  const foundElement = document.getElementById(
                    date + "_" + journyList[foundOther].influences[0]._id
                  );
                  if (foundElement) {
                    foundElement.click();
                  }
                }
              }
            }
            // // up arrow key
            if (
              Number(e.keyCode) === 38 &&
              journyList[foundOther].outcomes[outcomeFound - 1] &&
              journyList[foundOther].outcomes[outcomeFound - 1]._id
            ) {
              if (
                journyList[foundOther].outcomes[outcomeFound - 1].skipMetric
              ) {
                renderToNextInput(e, categoryId,
                  journyList[foundOther].outcomes[outcomeFound - 1]._id
                );
              } else {
                const date = moment(formatedDate, "mm-DD-yyyy").format(
                  "mm-DD-yyyy"
                );
                const foundElement = document.getElementById(
                  date +
                  "_" +
                  journyList[foundOther].outcomes[outcomeFound - 1]._id
                );
                if (foundElement) {
                  foundElement.click();
                }
              }
            } else if (Number(e.keyCode) === 38) {
              const foundOther = (journyList || []).findIndex(
                (it) => it.name === "Other"
              );
              if (foundOther > -1) {
                if (
                  journyList[foundOther - 1] &&
                  journyList[foundOther - 1].alls &&
                  journyList[foundOther - 1].alls.length
                ) {
                  if (
                    journyList[foundOther - 1].alls[
                      journyList[foundOther - 1].alls.length - 1
                    ].skipMetric
                  ) {
                    renderToNextInput(e, categoryId,
                      journyList[foundOther - 1].alls[
                        journyList[foundOther - 1].alls.length - 1
                      ]._id
                    );
                  } else {
                    const date = moment(formatedDate, "mm-DD-yyyy").format(
                      "mm-DD-yyyy"
                    );
                    const foundElement = document.getElementById(
                      date +
                      "_" +
                      journyList[foundOther - 1].alls[
                        journyList[foundOther - 1].alls.length - 1
                      ]._id
                    );
                    if (foundElement) {
                      foundElement.click();
                    }
                  }
                }
              }
            }

            //right side arrow keys
            if (Number(e.keyCode) === 39) {
              const date = moment(formatedDate, "mm-DD-yyyy")
                .add(1, "day")
                .format("mm-DD-yyyy");
              const foundElement = document.getElementById(date + "_" + idx);
              if (foundElement) {
                foundElement.click();
              }
            }
            //left side arrow keys
            if (Number(e.keyCode) === 37) {
              const date = moment(formatedDate, "mm-DD-yyyy")
                .subtract(1, "day")
                .format("mm-DD-yyyy");
              const foundElement = document.getElementById(date + "_" + idx);
              if (foundElement) {
                foundElement.click();
              }
            }
          } else {
          }
        }
      }
    } else {
      // down arrow key
      if (e.key === "Enter" || Number(e.keyCode) === 40) {
        let nextId = "";
        let nextItem;
        let isLastOutcome = false;
        for (let i = 0; i < outcomeState?.outcomeList?.length; i++) {
          if (outcomeState?.outcomeList[i]._id === idx) {
            if (i === outcomeState?.outcomeList?.length - 1) {
              isLastOutcome = true;
            }
            nextId = outcomeState?.outcomeList[i + 1]?._id;
            nextItem = outcomeState?.outcomeList[i + 1];
          }
        }
        console.log(
          "isLastOutcome",
          nextItem?.skipMetric,
          nextId,
          isLastOutcome
        );
        if (nextItem?.skipMetric && nextId) {
          renderToNextInput(e, categoryId, nextId);
        } else if (nextId) {
          const foundElement = document.getElementById(
            formatedDate + "_" + nextId
          );
          console.log("foundElement", foundElement)
          if (foundElement) {
            foundElement.click();
          }
          setOutcomeId(nextId);
          setItem(nextItem);
          setShowInput(false);
          setInputValue("");
        } else {
          if (isLastOutcome) {
            const filteredWithoutSkipMetric = [
              ...outcomeState?.influenceList,
            ].filter((it) => !it.skipMetric);
            if (
              filteredWithoutSkipMetric &&
              filteredWithoutSkipMetric.length
            ) {
              const foundZeroInfluence = filteredWithoutSkipMetric[0]?._id;
              if (foundZeroInfluence) {
                const date = moment(formatedDate, "mm-DD-yyyy").format(
                  "mm-DD-yyyy"
                );
                const foundElement = document.getElementById(
                  date + "_" + filteredWithoutSkipMetric[0]._id
                );
                if (foundElement) {
                  foundElement.click();
                }
              }
            }
          } else {
            const foundZeroInfluence = outcomeState?.influenceList[0]?._id;
            if (foundZeroInfluence) {
              const date = moment(formatedDate, "mm-DD-yyyy").format(
                "mm-DD-yyyy"
              );
              const foundElement = document.getElementById(
                date + "_" + outcomeState?.influenceList[0]._id
              );
              if (foundElement) {
                foundElement.click();
              }
            }
          }
        }
      }

      // up arrow key
      if (Number(e.keyCode) === 38) {
        let prevId = "";
        let prevItem;
        for (let i = 0; i < outcomeState?.outcomeList?.length; i++) {
          if (outcomeState?.outcomeList[i]._id === idx) {
            prevId = outcomeState?.outcomeList[i - 1]?._id;
            prevItem = outcomeState?.outcomeList[i - 1];
          }
        }
        if (prevItem.skipMetric && prevId) {
          renderToNextInput(e, categoryId, prevId);
        } else if (prevId) {
          const foundElement = document.getElementById(
            formatedDate + "_" + prevId
          );
          if (foundElement) {
            foundElement.click();
          }
          setOutcomeId(prevId);
          setItem(prevItem);
          setShowInput(false);
          setInputValue("");
        }
      }

      //right side arrow keys
      if (Number(e.keyCode) === 39) {
        setOutcomeId(idx);
        setItem([...outcomeState?.outcomeList].find((it) => it._id === idx));
        const date = moment(formatedDate, "mm-DD-yyyy")
          .add(1, "day")
          .format("mm-DD-yyyy");
        const foundElement = document.getElementById(date + "_" + idx);
        if (foundElement) {
          foundElement.click();
        }
      }

      if (Number(e.keyCode) === 37) {
        setOutcomeId(idx);
        setItem([...outcomeState?.outcomeList].find((it) => it._id === idx));
        const date = moment(formatedDate, "mm-DD-yyyy")
          .subtract(1, "day")
          .format("mm-DD-yyyy");
        const foundElement = document.getElementById(date + "_" + idx);
        if (foundElement) {
          foundElement.click();
        }
      }
    }
  };
  const handleBlurInput = (id: any, e: any, mouseOut: boolean, categoryId) => {

    if (mouseOut) {
      setShowInput(false);
    }
    if (
      e.key === "Enter" ||
      Number(e.keyCode) === 37 ||
      Number(e.keyCode) === 38 ||
      Number(e.keyCode) === 39 ||
      Number(e.keyCode) === 40 ||
      mouseOut
    ) {
      if (catItem.absenceToggle) {
        if (inputValue) {
          if (parseInt(inputValue) >= 0 && parseInt(inputValue) <= 5) {
            setShowInput(false);
            outcomeCreate();
            renderToNextInput(e, categoryId, id);
          } else {
            setShowSnakbar(true);
            setMsgType("error");
            setSnakbarMsg("Please enter value between 0 to 5");
          }
        }
      } else {
        if (inputValue) {
          if (parseInt(inputValue) > 0 && parseInt(inputValue) <= 5) {
            setShowInput(false);
            outcomeCreate();
            renderToNextInput(e, categoryId, id);
          } else {
            setShowSnakbar(true);
            setMsgType("error");
            setSnakbarMsg("Please enter value between 1 to 5");
          }
        }
      }

    }
  };
  const handleBlurInputArea = (type, e: any, mouseOut, categoryId, id) => {
    if (mouseOut) {
      setShowNotesInput(false);
    }
    if (
      Number(e.keyCode) === 37 ||
      Number(e.keyCode) === 38 ||
      Number(e.keyCode) === 39 ||
      Number(e.keyCode) === 40 ||
      mouseOut
    ) {
      outcomeCreate(true);
    }
  };
  const outcomeCreate = (isHasNotes = false) => {
    const outcomeCreateData: any = {
      date: formatedDate,
      outcomeId: catItem?.outcomeId,
      note: [inputNotesValue],
    };
    if (Number(inputValue) >= 0) {
      outcomeCreateData.index = parseInt(inputValue);
      outcomeCreateData.answerId =
        Number(inputValue) === 0
          ? null
          : catItem?.options[parseInt(inputValue) - 1].answerId;
      outcomeCreateData.score =
        Number(inputValue) === 0
          ? inputValue
          : catItem?.options[parseInt(inputValue) - 1].score;
    }
    outcomeCreateData.note = outcomeCreateData.note.filter((it) => it);
    let oldData: any = {};
    if (!isHasNotes) {
      if (!outcomeState?.isPersonal) {
        const foundOutcome = (outcomeState?.outcomeList || []).find(
          (it) => it.outcomeId === outcomeCreateData.outcomeId
        );
        if (foundOutcome) {
          const journyList = { ...(outcomeState?.journeyList || {}) } as any;
          const foundDateItemIcon = journyList.outcome_data.findIndex(
            (it) =>
              it.outcomeId === outcomeCreateData.outcomeId &&
              it?.date === outcomeCreateData.date
          );
          if (foundDateItemIcon > -1) {
            oldData = { ...journyList.outcome_data[foundDateItemIcon] };
          }
        }
      } else {
        const journeyCategoryList = [...(journyList || [])];
        const foundOutcome = (journeyCategoryList || []).findIndex(
          (it) =>
            it.outcomeIds && it.outcomeIds.includes(outcomeCreateData.outcomeId)
        );
        if (foundOutcome > -1) {
          if (
            journeyCategoryList[foundOutcome].alls &&
            journeyCategoryList[foundOutcome].alls.length
          ) {
            const foundIndex = journeyCategoryList[foundOutcome].alls.findIndex(
              (it) => it.outcomeId === outcomeCreateData.outcomeId
            );
            if (foundIndex > -1) {
              if (
                journeyCategoryList[foundOutcome].alls[foundIndex]
                  .outcomesResponses &&
                journeyCategoryList[foundOutcome].alls[foundIndex]
                  .outcomesResponses.length
              ) {
                const foundDateValue = journeyCategoryList[foundOutcome].alls[
                  foundIndex
                ].outcomesResponses.findIndex(
                  (it) => it.date === outcomeCreateData.date
                );
                if (foundDateValue > -1) {
                  oldData = {
                    ...journeyCategoryList[foundOutcome].alls[foundIndex]
                      .outcomesResponses[foundDateValue],
                  };
                }
              }
            }
          }
        } else {
          const foundOther = (journeyCategoryList || []).findIndex(
            (it) => it.name === "Other"
          );
          if (foundOther > -1) {
            const outcomeFound = journeyCategoryList[
              foundOther
            ].outcomes.findIndex(
              (it) => it.outcomeId === outcomeCreateData.outcomeId
            );
            if (outcomeFound > -1) {
              if (
                journeyCategoryList[foundOther].outcomes[outcomeFound]
                  .outcomesResponses &&
                journeyCategoryList[foundOther].outcomes[outcomeFound]
                  .outcomesResponses.length
              ) {
                const foundDateValue = journeyCategoryList[foundOther].outcomes[
                  outcomeFound
                ].outcomesResponses.findIndex(
                  (it) => it.date === outcomeCreateData.date
                );
                if (foundDateValue > -1) {
                  oldData = {
                    ...journeyCategoryList[foundOther].outcomes[outcomeFound]
                      .outcomesResponses[foundDateValue],
                  };
                }
              }
            }
          }
        }
      }
      if (oldData?.note && oldData?.note.length) {
        outcomeCreateData.note = oldData?.note || [];
      }
    }
    const foundOutcomeDef = (outcomeState?.outcomeList || []).find(
      (it) => it.outcomeId === outcomeCreateData.outcomeId
    );
    if (foundOutcomeDef) {
      const journyList = { ...(outcomeState?.journeyList || {}) } as any;
      const foundDateItemIcon = journyList.outcome_data.findIndex(
        (it) =>
          it.outcomeId === outcomeCreateData.outcomeId &&
          it?.date === outcomeCreateData.date
      );
      if (foundDateItemIcon > -1) {
        journyList.outcome_data[foundDateItemIcon] = {
          ...foundOutcomeDef,
          ...outcomeCreateData,
        };
      } else {
        journyList.outcome_data.push({
          ...foundOutcomeDef,
          ...outcomeCreateData,
        } as any);
      }

      UserTypeDispatch(addJourneyAction(journyList as any));
    }
    const journeyCategoryList = [...(journyList || [])];
    const foundOutcome = (journeyCategoryList || []).findIndex(
      (it) =>
        it.outcomeIds && it.outcomeIds.includes(outcomeCreateData.outcomeId)
    );
    if (foundOutcome > -1) {
      if (
        journeyCategoryList[foundOutcome].alls &&
        journeyCategoryList[foundOutcome].alls.length
      ) {
        const foundIndex = journeyCategoryList[foundOutcome].alls.findIndex(
          (it) => it.outcomeId === outcomeCreateData.outcomeId
        );
        if (foundIndex > -1) {
          if (
            journeyCategoryList[foundOutcome].alls[foundIndex]
              .outcomesResponses &&
            journeyCategoryList[foundOutcome].alls[foundIndex].outcomesResponses
              .length
          ) {
            const foundDateValue = journeyCategoryList[foundOutcome].alls[
              foundIndex
            ].outcomesResponses.findIndex(
              (it) => it.date === outcomeCreateData.date
            );
            if (foundDateValue > -1) {
              journeyCategoryList[foundOutcome].alls[
                foundIndex
              ].outcomesResponses[foundDateValue] = {
                ...outcomeCreateData,
              };
            } else {
              journeyCategoryList[foundOutcome].alls[
                foundIndex
              ].outcomesResponses.push(outcomeCreateData);
            }
          } else {
            journeyCategoryList[foundOutcome].alls[
              foundIndex
            ].outcomesResponses = [outcomeCreateData];
          }
          UserTypeDispatch(addCategoryJourneyAction(journeyCategoryList));
        }
      }
    } else {
      const foundOther = (journeyCategoryList || []).findIndex(
        (it) => it.name === "Other"
      );
      if (foundOther > -1) {
        const outcomeFound = journeyCategoryList[foundOther].outcomes.findIndex(
          (it) => it.outcomeId === outcomeCreateData.outcomeId
        );
        if (outcomeFound > -1) {
          if (
            journeyCategoryList[foundOther].outcomes[outcomeFound]
              .outcomesResponses &&
            journeyCategoryList[foundOther].outcomes[outcomeFound]
              .outcomesResponses.length
          ) {
            const foundDateValue = journeyCategoryList[foundOther].outcomes[
              outcomeFound
            ].outcomesResponses.findIndex(
              (it) => it.date === outcomeCreateData.date
            );
            if (foundDateValue > -1) {
              journeyCategoryList[foundOther].outcomes[
                outcomeFound
              ].outcomesResponses[foundDateValue] = {
                ...outcomeCreateData,
              };
            } else {
              journeyCategoryList[foundOther].outcomes[
                outcomeFound
              ].outcomesResponses.push(outcomeCreateData);
            }
          } else {
            journeyCategoryList[foundOther].outcomes[
              outcomeFound
            ].outcomesResponses = [outcomeCreateData];
          }
          UserTypeDispatch(addCategoryJourneyAction(journeyCategoryList));
        }
      }
    }
    trackingService
      .outcomeCreate(outcomeState.token, outcomeCreateData)
      .then((data) => {
        setInputValue("")
        if (
          data.showMessage &&
          formatedDate === moment().format("MM-DD-YYYY")
        ) {
          trackingDispatch(
            setDayCompleteMessage({
              open: data.showMessage,
              message: data.message,
            })
          );
        }
      })
      .catch((error) => {
        setShowSnakbar(true);
        setSnakbarMsg(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message
        );
        setMsgType("error");
      });
  };
  return (
    <td
      className={clsx(
        classes.cell,
        classes.borderBottom,
        isExpand ? classes.iconExpand : ""
      )}
      style={{
        position: "relative",
        height: "inherit",
        ...style,
      }}
      key={catItem._id}
    >
      <SnackBarComp
        showSnakbar={showSnakbar}
        handleCloseSnakbar={() => {
          setShowSnakbar(false);
        }}
        snakbarMsg={snakbarMsg}
        type={msgType ? msgType : "success"}
      />
      <div
        style={
          isExpand
            ? {
              height: "100%",
            }
            : {
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }
        }
      >
        <div
          className={
            moment(calendar[calendar.length - 1]).format("MM-DD-YYYY") ===
              formatedDate
              ? classes.iconInnerWithoutAfter
              : classes.iconInner
          }
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: isExpand ? "flex-start" : "center",
            flexBasis: "100%",
          }}
          onClick={(e) => {
            if (clickCondition) {
              setItem(catItem);
              setOutcomeId(catItem._id);
            }
          }}
        >
          {showInput ? (
            <span
              className={classes.addOutlineIconInput}
              onDoubleClick={() => {
                if (clickCondition) {
                  let i = outcomeState.outcomeList.findIndex(
                    (outcome) => outcome._id === catItem?._id
                  );
                  setOutcomeIndex(i);
                  setShowInput(false);
                  setOpenTrackingEventModal(true);
                  setDisplayBorder(true);
                  setOutcomeId(catItem._id);
                  setItem(catItem);
                  setSelectedOption(parseInt(optionIndex + 1));
                }
              }}
            >
              <input
                name={catItem?._id}
                type="text"
                autoFocus
                onBlur={() => {
                  handleBlurInput(
                    item?._id,
                    { key: "kdksjdks", keyCode: "hdj" },
                    true,
                    outcomeState.isPersonal ? category.categoryId : null
                  );
                }}
                onChange={(e) => {
                  setInputValue(e.target.value);
                }}
                onKeyDown={(e) => {
                  handleBlurInput(
                    item?._id,
                    e,
                    false,
                    outcomeState.isPersonal ? category.categoryId : null
                  );
                }}
              />
            </span>
          ) : (
            <span
              className={classes.addOutlineIcon}
              onDoubleClick={() => {
                if (clickCondition) {
                  let i = outcomeState.outcomeList.findIndex(
                    (outcome) => outcome._id === catItem?._id
                  );
                  setOutcomeIndex(i);
                  setShowInput(false);
                  setOpenTrackingEventModal(true);
                  setDisplayBorder(true);
                  setOutcomeId(catItem._id);
                  setItem(catItem);
                  setSelectedOption(parseInt(optionIndex + 1));
                }
              }}
            >
              {found ? (
                <div
                  className="hello"
                  key={catItem._id}
                  id={formatedDate + "_" + catItem._id}
                  onClick={() => {
                    if (clickCondition) {
                      setShowInput(true);
                      setDisplayBorder(true);
                      setOutcomeId(catItem._id);
                      setItem(catItem);
                    }
                  }}
                >
                  <span
                    className={
                      found?.note.length > 0 ? classes.borderOutline : ""
                    }
                    style={
                      found?.note.length > 0
                        ? {
                          padding: "2px",
                          border: `1px solid ${nearValue ? nearValue : found.colorCode
                            }`,
                          borderRadius: "50%",
                        }
                        : {
                          border: `1px solid ${nearValue ? nearValue : found.colorCode
                            }`,
                          backgroundColor: found.colorCode,
                        }
                    }
                  >
                    <span
                      style={{
                        backgroundColor: nearValue
                          ? nearValue
                          : found.colorCode,
                        border: `1px solid ${nearValue ? nearValue : found.colorCode
                          }`,
                        borderRadius: "50%",
                        color: palette.common.white,
                      }}
                    >
                      {found.index}
                    </span>
                  </span>
                </div>
              ) : (
                <span
                  key={catItem._id}
                  id={formatedDate + "_" + catItem._id}
                  onClick={() => {
                    console.log("clickCondition", clickCondition)
                    if (clickCondition) {
                      setShowInput(true);
                      setDisplayBorder(true);
                      setOutcomeId(catItem._id);
                      setItem(catItem);
                    }
                  }}
                >
                  +
                </span>
              )}
            </span>
          )}
        </div>
        {outcomeState?.isExpand ? (
          <div
            style={{
              display: "flex",
              width: "176px",
              // height: length,
              padding: "12px",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "12px",
              alignSelf: "stretch",
              borderRadius: "12px",
              border: "1px solid #F3F3F3",
              background: "#FFF",
              flexBasis: "100%",
              margin: "13px 8px 0px 0",
              overflowY: "auto",
              wordWrap: "break-word",
              ...heightObj,
            }}
            onClick={() => {
              // if (!found) {
              //     if (clickCondition) {
              //         setShowInput(true);
              //         setDisplayBorder(true);
              //         setOutcomeId(catItem._id);
              //         setItem(catItem);
              //     }
              // } else {

              if (clickCondition && !found) {
                setShowInput(false);
                setOpenTrackingEventModal(true);
                setDisplayBorder(true);
                setOutcomeId(catItem._id);
                setItem(catItem);
                setSelectedOption(parseInt(optionIndex + 1));
              } else if (clickCondition && !showNotesInput) {
                setShowInput(false);
                setInputValue(found?.index);
                setShowNotesInput(true);
                setDisplayBorder(true);
                setOutcomeId(catItem._id);
                setNotesInputValue(found?.note && found?.note[0]);
                setItem(catItem);
                setSelectedOption(parseInt(optionIndex + 1));
              }
              // }
            }}
            onDoubleClick={(e) => {
              e.stopPropagation();
              if (clickCondition) {
                setShowNotesInput(false);
                setShowInput(false);
                setOpenTrackingEventModal(true);
                setDisplayBorder(true);
                setOutcomeId(catItem._id);
                setItem(catItem);
                setSelectedOption(parseInt(optionIndex + 1));
              }
            }}
          >
            {showNotesInput ? (
              <textarea
                name={catItem?._id}
                autoFocus
                ref={textareaRef}
                value={inputNotesValue}
                style={{
                  width: "100%",
                  minHeight: "10px",
                  resize: "none",
                }}
                onBlur={() => {
                  handleBlurInputArea(
                    catItem?.type,
                    { key: "kdksjdks", keyCode: "hdj" },
                    true,
                    outcomeState.isPersonal ? category.categoryId : null,
                    catItem._id
                  );
                }}
                onChange={(e) => {
                  setNotesInputValue(e.target.value);
                }}
                onKeyDown={(e) => {
                  handleBlurInputArea(
                    catItem?.type,
                    e,
                    false,
                    outcomeState.isPersonal ? category.categoryId : null,
                    catItem._id
                  );
                }}
              />
            ) : (
              <>
                {found?.note && found?.note[0] ? (
                  <Typography
                    id={`${catItem?.outcomeId}_${formatedDate}`}
                    variant="h5"
                    sx={{
                      color: palette.common.black,
                      marginBottom: "0px !important",
                    }}
                    component="span"
                    title={found?.note}
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: found?.note[0].replace(/\n/g, "<br />") || "",
                      }}
                    ></span>
                  </Typography>
                ) : null}
              </>
            )}
          </div>
        ) : null}
      </div>

      {openTrackingEventModal && (
        <AddTrackingEventModal
          setOpenTrackingEventModal={setOpenTrackingEventModal}
          index={outcomeIndex}
          setOutcomeId={setOutcomeId}
          getBackgroudColor={() => { }}
          setSelectedOption={setSelectedOption}
          selectedOption={selectedOption}
          setShowInput={setShowInput}
          outcomeId={outcomeId}
          item={catItem}
          setShowSnakbar={setShowSnakbar}
          setSnakbarMsg={setSnakbarMsg}
          setMsgType={setMsgType}
          setOptionIndex={setOptionIndex}
          optionIndex={optionIndex}
          setDisplayBorder={setDisplayBorder}
          displayBorder={displayBorder}
          callService={callservice}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          journeyData={journyList}
          headerDate={formatedDate}
          renderToNextInput={() => {
            setTimeout(() => {
              renderToNextInput(
                {
                  key: "Enter",
                },
                outcomeState.isPersonal ? category.categoryId : null,
                catItem._id
              );
            }, 200);

          }}
        />
      )}
    </td>
  );
};

export default IconPersonalOutcome;
