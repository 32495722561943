/*eslint @typescript-eslint/no-unused-vars: "off"*/
import React, { useState, useEffect, useContext } from "react";
import { Grid } from "@mui/material";
import palette from "../../theme/palette";
import LeftSideReflectiveQue from "./leftSideRefQue";
import RightSideReflectiveQues from "./rightSideRefQue";
import { reflectiveQueService } from "../../services/reflective-questions";
import { UserTypeContext } from "../../context/userStatus";
import { RefelectiveGetResponse } from "../../interfaces/outcomeModal";
import SnackBarComp from "../../components/snackBar";
import { addRefelectiveAction } from "../../store/actions/userTypeAction";
import FullpageLoader from "../../components/fullPageLoader";
import useWidth from "../../utils/useWidth";

const ReflectiveQuestion = () => {
  const [questionData, setQuestionData] = useState("");
  const { state: outcomeState, dispatch: userTypeDispatch } =
    useContext(UserTypeContext);
  const [showSnakbar, setShowSnakbar] = useState(false);
  const [showQues, setShowQues] = useState(false);
  const [snakbarMsg, setSnakbarMsg] = useState("");
  const [msgType, setMsgType] = useState("");
  const [reflectiveIdeas, setReflectiveIdeas] = useState();
  const [addQuesTracking, setAddQuesTracking] = useState(false);
  const [weekday, setWeekDay] = useState(new Map());
  const [fullpageLoader, setFullPageLoader] = useState(true);
  const width = useWidth();
  useEffect(() => {
    // GET REFLECTIVE QUSTIONS LIST
    reflectiveQueService
      .getReflectiveList(outcomeState.token)
      .then((data: RefelectiveGetResponse) => {
        setFullPageLoader(false);
        userTypeDispatch(addRefelectiveAction(data?.data));
      })
      .catch((error) => {
        setShowSnakbar(true);
        setSnakbarMsg(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message
        );
        setMsgType("error");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleCloseSnakbar = () => {
    setShowSnakbar(false);
  };
  return (
    <>
      {fullpageLoader && <FullpageLoader />}
      <Grid
        container
        columns={16}
        sx={{ backgroundColor: palette.secondary.light }}
      >
        <SnackBarComp
          showSnakbar={showSnakbar}
          handleCloseSnakbar={handleCloseSnakbar}
          snakbarMsg={snakbarMsg}
          type={msgType ? msgType : "info"}
        />
        {/* START LEFT SECTION */}
        <Grid
          sx={{ backgroundColor: palette.common.white }}
          item
          xs={9.63}
          className="leftside-menu login-screen outcome-screen outcome-left-layout"
        >
          <LeftSideReflectiveQue
            questionData={questionData}
            setQuestionData={setQuestionData}
            reflectiveIdeas={reflectiveIdeas}
            showRefQues={false}
            setAddQuesTracking={setAddQuesTracking}
            setShowQues={setShowQues}
            setWeekDay={setWeekDay}
            weekday={weekday}
            setShowSnakbar={setShowSnakbar}
            setMsgType={setMsgType}
            setSnakbarMsg={setSnakbarMsg}
            onboarding={true}
          />
        </Grid>
        {/* END LEFT SECTION */}

        {/* START RIGHT SECTION */}
        <Grid
          item
          xs={6.37}
          className="rightside-menu outcome-menu outcome-right-layout"
          sx={{ backgroundColor: palette.common.bgColor }}
        >
          {width > 768 &&
          <RightSideReflectiveQues
            setQuestionData={setQuestionData}
            setReflectiveIdeas={setReflectiveIdeas}
          />}
        </Grid>
        {/* END RIGHT SECTION */}
      </Grid>
    </>
  );
};
export default ReflectiveQuestion;
