import { IInsightAction } from "../../interfaces/insightsModal";


export const setInsightData = (data: any): IInsightAction => ({
  type: "SET_INSIGHT_DATA",
  payload: data,
});

export const setLeftMenuData = (data: any): IInsightAction => ({
  type: "SET_LEFT_MENU",
  payload: data,
});
export const setRightMenuData = (data: any): IInsightAction => ({
  type: "SET_RIGHT_MENU",
  payload: data,
});