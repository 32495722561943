import React, { useState } from "react";
import { Grid } from "@mui/material";
import RightPartRegistration from "./rightPartRegistration";
import palette from "../../theme/palette";
import LeftPartLogin from "./leftPartLogin";
import RightPartCodeVerify from "./rightPartCodeVerify";
import RightPartJoinWaitlist from "./rightPartJoinWaitlist";

const RegistrationAuth = () => {
  const [codeVerified, setCodeVerified] = useState(false)
  localStorage.removeItem("userDetails");
  localStorage.removeItem("userValues");
  localStorage.removeItem("userId");
  localStorage.removeItem("userDate");
  return (
    <Grid
      container
      alignItems="center"
      columns={16}
      sx={{ backgroundColor: palette.common.bgColor }}
    >
      {/* START LEFT SECTION */}
      <Grid item xs={9.63} className="leftside-menu login-screen">
        <LeftPartLogin />
      </Grid>
      {/* END LEFT SECTION */}

      {/* START RIGHT SECTION */}
      <Grid item xs={6.37} className="rightside-menu">
        <RightPartJoinWaitlist />
      </Grid>
      {/* END RIGHT SECTION */}
    </Grid>
  );
};
export default RegistrationAuth;
