import axios from "axios";
import { baseEndpoints } from "./config";

const headerToken = localStorage.getItem("userDetails");

const getAllWeeks = async (body: any, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.reports.getAllWeek}`, body, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const getAllWeeksReport = async (body: any, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.reports.getAllWeekReport}`, body, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};


export const reportsService = {
  getAllWeeks,
  getAllWeeksReport,
};
