import * as React from "react";

const StepperBackArrowDisable = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
    >
      <path
        d="M27 28.59L22.42 24L27 19.41L25.59 18L19.59 24L25.59 30L27 28.59Z"
        fill="#d5d7d8"
      />
      <rect
        x="47"
        y="47"
        width="46"
        height="46"
        rx="23"
        transform="rotate(-180 47 47)"
        stroke="#11734A"
        strokeOpacity="0.4"
        strokeWidth="2"
      />
    </svg>
  );
};
export default StepperBackArrowDisable;

export const StepperBackArrowDisableNew = () => {
  return <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="47" y="47" width="46" height="46" rx="23" transform="rotate(-180 47 47)" stroke="#30A35E70" stroke-opacity="0.8" strokeWidth="2"/>
  <g clip-path="url(#clip0_1169_37636)">
  <path d="M27 28.59L22.42 24L27 19.41L25.59 18L19.59 24L25.59 30L27 28.59Z" fill="#30A35E70"/>
  </g>
  <defs>
  <clipPath id="clip0_1169_37636">
  <rect width="24" height="24" fill="white" transform="matrix(-1 0 0 -1 36 36)"/>
  </clipPath>
  </defs>
  </svg>
}
