import { Box, Grid, Typography } from "@mui/material";
import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core";
import palette from "../../theme/palette";
import shape from "../../theme/shape";
import { UserTypeContext } from "../../context/userStatus";
import { OutcomeOptionList } from "../../interfaces/outcomeModal";
import clsx from "clsx";
import { pxToRem } from "../../theme/typography";
const useStyles = makeStyles((theme) => ({
  stepperNumber: {
    width: "30px",
    height: "30px",
    color: palette.common.white,
    borderRadius: shape.borderRadiusMd,
    fontSize: "16px",
    lineHeight: "30px",
    fontWeight: 500,
    textAlign: "center",
    position: "relative",
    display: "inline-block",
    zIndex: 1,
  },
  stepperLine: {
    position: "relative",
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
    "&:after": {
      content: "''",
      position: "absolute",
      display: "inline-block",
      left: "80px",
      top: "12px",
      border: "0.1px solid #DCE6E0",
      width: "630px",
    },
  },
  stepperWrapper: {
    cursor: "pointer",
    backgroundColor: "#F7F9FA",
    border: "1px solid #DCE6E0",
    width: "134px",
    height: "100px",
    overflowY: "auto",
    position: "relative",
    marginTop: "20px",
    "&:before": {
      content: "''",
      position: "absolute",
      display: "block",
      left: "41%",
      top: "-22px",
      width: 0,
      height: 0,
      borderStyle: "solid",
      borderColor: "transparent transparent transparent #DCE6E0",
      borderWidth: "11px",
      transform: "rotate(-90deg)",
    },
    "&:after": {
      content: "''",
      position: "absolute",
      display: "block",
      left: "42%",
      top: "-20px",
      width: 0,
      height: 0,
      borderStyle: "solid",
      borderColor: "transparent transparent transparent #F7F9FA",
      borderWidth: "10px",
      transform: "rotate(-90deg)",
    },
  },
  mainStepper: {
    textAlign: "center",
    marginLeft: "18px",
    "&:nth-child(1)": {
      marginLeft: 0,
    },
  },
  stepperText: {
    fontSize: "16px",
    lineHeight: "18px",
    fontWeight: 400,
    color: palette.primary.light,
    padding: "15px 8px",
    margin: 0,
    height: "96px",
  },
  mainLayout: {
    width: "100%",
    overflowX: "auto",
    overflowY: "hidden",
    maxWidth: "820px",
  },
  optionBox: {
    display: 'flex',
    minWidth: '148px',
    height: '148px',
    padding: '12px',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    borderRadius: '12px',
    background: "#FFF",
    position: "relative"
  },
  normalBorder: {
    border: '2px solid #F3F3F3',

  },
  selectedBorder: {
    border: "2px solid var(--color-new-main)"
  },
  unSelectedBox: {
    width: '20px',
    height: '20px',
    position: 'absolute',
    right: '12px',
    top: '12px',
    borderRadius: '100px',
    border: '1px solid #A0A0A1',
    background: '#FFF',

  },
  selectedBox: {
    width: '20px',
    height: '20px',
    position: 'absolute',
    right: '12px',
    top: '12px',
    background: '#FFF',
  },
  stepperTextNew: {
    fontSize: `${pxToRem(14)} !important`,
    fontWeight: 400,
    color: palette.common.black,
    lineHeight: "130%",
    position: "relative",
    bottom: 0,
  },
  stepperWrapperNew: {
    cursor: "pointer",
    width: "134px",
    height: "100px",
    overflowY: "auto",
    position: "absolute",
    bottom: 0,
  },
  matricScale: {
    display: 'flex',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    gap: '16px',
    alignSelf: 'stretch',
    flexWrap: 'nowrap',
    overflowX: 'auto'
  },
  optionBoxMobile: {
    display: 'flex',
    minWidth: '148px',
    minHeight: '148px',
    padding: '12px',
    flexDirection: 'column',
    // justifyContent: 'space-between',
    gap: "16px",
    alignItems: 'center',
    borderRadius: '12px',
    background: "#FFF",
    position: "relative"
  },
  selectedBorderIcon: {
    border: "0px "
  },
  selectedStep: {
    width: "42px !important",
    height: "42px !important",
    marginTop: "-6px",
    fontSize: `${pxToRem(28)} !important`
  },
  inputBoxSelected: {
    width: "157px!important", height: "77px!important", marginTop: "12px"
  },
  inputBox: {
    background: "red",
    borderRadius: "10px",
    width: "144px", height: "67px",
    position: "relative",
    padding: "8px",
    // "&::before": {
    //   content: '""',
    //   position: 'absolute',
    //   height: '0px',
    //   width: '0px',
    //   top: '-10px',
    //   left: '40%', /* 1px buffer for zooming problems while rendering*/
    //   // borderWidth: '10px',
    //   borderWidth: "0 10px 10px 10px",
    //   // borderColor: 'transparent  transparent red transparent',
    //   borderStyle: 'solid',
    //   zIndex: 9998
    // },
    // "&::after": {
    //   content: '""',
    //   position: 'absolute',
    //   height: '0px',
    //   width: '0px',
    //   top: '-10px',
    //   left: '40%', /* 1px buffer for zooming problems while rendering*/
    //   // borderWidth: '10px',
    //   borderWidth: "0 10px 10px 10px",
    //   // borderColor: 'transparent  transparent #fff transparent',
    //   borderStyle: 'solid',
    //   zIndex: 9999
    // },
    "&::after": {
      content: '""',
      position: "absolute",
      display: "block",
      right: "40%",
      top: "-15px",
      borderTop: "none",
      borderRight: '15px solid transparent',
      borderLeft: "15px solid transparent",

    }
  },
  pointer: {
    border: "solid 10px transparent",
    borderRightColor: "yellow",
    position: "absolute",
    margin: "-85px 0 0 -20px",
  },
  setAfter: {
    "&::before": {
      content: '""',
      position: "absolute",
      width: "165px",
      height: "2px",
      left: "40%",
      top: "28px",
      backgroundColor: "#DCE5E0",
      zIndex: 1
    }
  },
  stepperNumberMobileWithoutAfter: {
    width: "32px",
    height: "32px",
    color: palette.common.white,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "100%",
    fontSize: "16px",
    lineHeight: "30px",
    fontWeight: 500,
    textAlign: "center",
    position: "relative",
    // display: "inline-block",
    zIndex: 1,
  },
  stepperNumberMobile: {
    width: "32px",
    height: "32px",
    color: palette.common.white,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "100%",
    fontSize: "16px",
    lineHeight: "30px",
    fontWeight: 500,
    textAlign: "center",
    position: "relative",
    // display: "inline-block",
    zIndex: 5,

  },
  paddingLeft: {
    paddingLeft: "10px"
  }
}));

interface ProgressStepperModal {
  index: number;
  getBackgroudColor: any;
  setSelectedOption: (selectedOption: any) => void;
  selectedOption: any;
  setOptionIndex: (optionIndex: string) => void;
  optionIndex: string;
  setItem: any;
  setDisplayBorder: (displayBorder: boolean) => void;
  displayBorder: boolean;
  editData: any;
  journeyData: any;
  options?: any
  absenceToggle?: boolean
  isMobile?: boolean
}

const ProgressStepperInfluenceNew = (props: ProgressStepperModal) => {
  const classes = useStyles();
  const { state: outcomeState } = useContext(UserTypeContext);
  const filteredData = props?.journeyData?.filter(it => it.options && it.options.length)
  let options = outcomeState?.influenceList[props.index]?.options || props.options
  if (outcomeState?.isPersonal && filteredData[0] && filteredData[0]?.options && filteredData[0]?.options.length) {
    options = filteredData[0]?.options
  }
  if (props.isMobile) {
    let steps: any[] = [...options]
    if (props?.absenceToggle) {
      steps = [{
        colorCode: "#68D7A8",
        score: 0,
        text: "Absence",
      }, ...steps]
    }
    return <>
      <Grid className={`${classes.matricScale} ${classes.paddingLeft} hide-scrollbar`}>
        {/* <Box sx={{ minWidth: "100%", width: 'auto', overflowX: "auto" }} className="hide-scrollbar">
          <Stepper activeStep={1} alternativeLabel>
            {steps.map((label) => (
              <Step key={label.text}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>{label.text}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box> */}
        {props?.absenceToggle ? (
          <div className={clsx(classes.optionBoxMobile, 0 === parseInt(props?.optionIndex) ? classes.selectedBorderIcon : "", classes.setAfter)} onClick={() => {
            props.getBackgroudColor(0);
            props.setSelectedOption(0);
            props.setOptionIndex("0");
            props.setDisplayBorder(true);
            props.setItem({
              colorCode: "#68D7A8",
              score: 0,
              text: "Absence",
            });
          }}>
            <span
              className={`${classes.stepperNumberMobile} ${0 === parseInt(props?.optionIndex) ? classes.selectedStep : ""}`}
              style={{
                backgroundColor: "grey",
                zIndex: 2
              }}
            >
              {0}
            </span>
            <Box className={clsx(classes.inputBox, 0 === parseInt(props?.optionIndex) ? classes.inputBoxSelected : "", "hide-scrollbar")} style={{
              border: `1px solid ${"#808080"}`,
              backgroundColor: "#80808070",
              padding: "8px"
            }} sx={{
              // "&::before": {
              //   borderColor: `transparent  transparent ${option?.colorCode + "70"} transparent`,
              // },
              "&::after": {
                borderColor: `transparent  transparent ${"#80808070"} transparent`,
                borderBottom: `15px solid ${"#80808070"}`,

              },
            }} >
              <Typography sx={{
                color: "grey",
                fontSize: `${pxToRem(10)} !important`,
                textAlign: "center"
              }} fontSize={`${pxToRem(10)} !important`}>{"Absence"} </Typography>
            </Box>
          </div>
        ) : null}
        {options && options.length && options.map(
          (option: OutcomeOptionList, index: any) => {
            return <div className={clsx(classes.optionBoxMobile, parseInt(index) + 1 === parseInt(props?.optionIndex) ? classes.selectedBorderIcon : "", (index === (options.length - 1)) ? "" : classes.setAfter)} onClick={() => {
              props.getBackgroudColor(index);
              props.setSelectedOption(index);
              props.setOptionIndex(index + 1);
              props.setDisplayBorder(true);
              props.setItem(option);
            }}>
              {/* <BootstrapTooltip title={option.text}> */}
              <span
                className={`${(index === (options.length - 1)) ? classes.stepperNumberMobileWithoutAfter : classes.stepperNumberMobile} ${parseInt(index) + 1 === parseInt(props?.optionIndex) ? classes.selectedStep : ""}`}
                style={{
                  backgroundColor: option?.colorCode,
                  zIndex: 2
                }}
              >
                {index + 1}
              </span>
              <Box className={clsx(classes.inputBox, parseInt(index) + 1 === parseInt(props?.optionIndex) ? classes.inputBoxSelected : "", "hide-scrollbar")} style={{
                border: `1px solid ${option?.colorCode}`,
                backgroundColor: option?.colorCode + "70",
                padding: "8px"
              }} sx={{
                // "&::before": {
                //   borderColor: `transparent  transparent ${option?.colorCode + "70"} transparent`,
                // },
                "&::after": {
                  borderColor: `transparent  transparent ${option?.colorCode + "70"} transparent`,
                  borderBottom: `15px solid ${option?.colorCode + "70"}`,

                },
              }} >
                <Box sx={{
                  width: "100%",
                  height: "100%",
                  overflowY: "auto"
                }} className="hide-scrollbar">
                  <Typography sx={{
                    color: parseInt(index) + 1 === parseInt(props?.optionIndex) ? palette.primary.light : option?.colorCode,
                    // fontSize: `${pxToRem(10)} !important`,
                    textAlign: "center",
                    fontSize: `${pxToRem(12)} !important`,
                    lineHeight: "150% !important",
                    // textAlign: "center !important",
                    fontWeight: `400 !important`,
                    "&.MuiTypography-root": {
                        "@media  (max-width: 600px) and (min-width: 100px)": {
                            fontSize: `${pxToRem(12)} !important`,
                            lineHeight: "150% !important"
                        },
                    }
                  }} >{option?.text} </Typography>
                </Box>
              </Box>
              {/* {parseInt(index) + 1 === parseInt(props?.optionIndex) ?
                <div className={classes.selectedBox}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" fill="white" stroke="#11734A" />
                  <rect x="4" y="4" width="12" height="12" rx="6" fill="#11734A" />
                </svg></div>
                : <div className={classes.unSelectedBox}></div>}

              <div
                className={classes.stepperWrapperNew}
              >
                <p className={classes.stepperTextNew}>{option?.text}</p>
              </div> */}

            </div>
          })
        }
      </Grid>
    </>
  }
  return (
    // SCALE BASED MODAL
    <>
      <Grid className={classes.matricScale}>
        {props?.absenceToggle ? <div className={clsx(classes.optionBox, 0 === parseInt(props?.optionIndex) ? classes.selectedBorder : classes.normalBorder)} onClick={() => {
          props.getBackgroudColor(0);
          props.setSelectedOption(0);
          props.setOptionIndex("0");
          props.setDisplayBorder(true);
          props.setItem({
            colorCode: "grey",
            score: 0,
            text: "Absence",
            value: 0,
          });
        }}>
          <span
            className={classes.stepperNumber}
            style={{
              backgroundColor: 'grey',
            }}
          >
            {0}
          </span>
          {0 === parseInt(props?.optionIndex) ?
            <div className={classes.selectedBox}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" fill="white" stroke="var(--color-new-main)" />
              <rect x="4" y="4" width="12" height="12" rx="6" fill="var(--color-new-main)" />
            </svg></div>
            : <div className={classes.unSelectedBox}></div>}


          <p className={classes.stepperTextNew}>{"Absence"}</p>
        </div> : null}
        {options && options.length && options.map(
          (option: OutcomeOptionList, index: any) => {
            return <div className={clsx(classes.optionBox, parseInt(index) + 1 === parseInt(props?.optionIndex) ? classes.selectedBorder : classes.normalBorder)} onClick={() => {
              props.getBackgroudColor(index);
              props.setSelectedOption(index);
              props.setOptionIndex(index + 1);
              props.setDisplayBorder(true);
              props.setItem(option);
            }}>
              <span
                className={classes.stepperNumber}
                style={{
                  backgroundColor: option?.colorCode,
                }}
              >
                {index + 1}
              </span>
              {parseInt(index) + 1 === parseInt(props?.optionIndex) ?
                <div className={classes.selectedBox}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" fill="white" stroke="var(--color-new-main)" />
                  <rect x="4" y="4" width="12" height="12" rx="6" fill="var(--color-new-main)" />
                </svg></div>
                : <div className={classes.unSelectedBox}></div>}

              <div
                className={classes.stepperWrapperNew}
              >
                <p className={classes.stepperTextNew}>{option?.text}</p>
              </div>
            </div>
          })
        }
        {/* <Box className={classes.stepperLine}>
          {options.map(
            (option: OutcomeOptionList, index: any) => {
              return (
                <div
                  className={classes.mainStepper}
                  key={index}
                  onClick={() => {
                    props.getBackgroudColor(index);
                    props.setSelectedOption(index);
                    props.setOptionIndex(index + 1);
                    props.setDisplayBorder(true);
                    props.setItem(option);
                  }}
                >
                  <span
                    className={classes.stepperNumber}
                    style={{
                      backgroundColor: option?.colorCode,
                    }}
                  >
                    {index + 1}
                  </span>
                  <div
                    className={classes.stepperWrapper}
                    style={
                      props.displayBorder &&
                      parseInt(index) + 1 === parseInt(props?.optionIndex)
                        ? {
                            border: `2px solid`,
                          }
                        : {}
                    }
                  >
                    <p className={classes.stepperText}>{option?.text}</p>
                  </div>
                </div>
              );
            }
          )}
        </Box> */}
      </Grid>
    </>
  );
};
export default ProgressStepperInfluenceNew;
