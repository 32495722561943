import axios from "axios";
import { baseEndpoints } from "./config";

const headerToken = localStorage.getItem("userDetails");

const getInsights = async (body: any, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.insightList}`, body, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const getTrend = async (body: any, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.trendData}`, body, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const getInfuenceTrend = async (body: any, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.influencetrendData}`, body, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const getInfluenceInsights = async (body: any, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.influenceInsights}`, body, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const getCorrelationMenu = async (body: any, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.INSIGHTS.CORRELATIno_MENU}`, body, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};
const getCorrelations = async (body: any, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.INSIGHTS.CORRELATIS}`, body, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const getOutcomeCelebration = async (body: any, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.INSIGHTS.OUTCOME_CELEBRATION}`, body, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const getInfuenceCelebration = async (body: any, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.INSIGHTS.INFLUENCE_CELEBRATION}`, body, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};


export const insightsService = {
  getInsights,
  getTrend,
  getCorrelationMenu,
  getCorrelations,
  getInfuenceTrend,
  getInfluenceInsights,
  getOutcomeCelebration,
  getInfuenceCelebration
};
