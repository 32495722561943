import React, { useContext, useState } from "react"
import clsx from "clsx"
import { Tooltip, Typography } from "@mui/material";
import EditIcon from "../../assets/images/icon/EditIcon";
import useStyles from "./TrackingClasses"
import { setTrackingState } from "../../store/actions/trackerAction";
import { TrackingContext } from "../../context/userTracking";

const PersonalTrackingOutcome = ({ isExpand, item, categoryId = null }) => {
    const [showIcon, setshowIcon] = useState(false);
    const classes = useStyles()
    const { dispatch: trackingDispatch } =
        useContext(TrackingContext);
    return <td className={clsx(classes.cell, classes.stickyLeftCell)}>
        <div className={isExpand ? classes.mainDivClasExpand : classes.mainDivClas} style={{
            height: "100%",
            borderBottom: `1px solid var(--border-color)`,
        }}
            onMouseEnter={() => setshowIcon(true)}
            onMouseLeave={() => setshowIcon(false)}>
            {item.description ? (
                <Tooltip
                    title={
                        <Typography sx={{ fontSize: "14px !important", lineHeight: "1.5", whiteSpace: "pre-wrap" }}>
                            {item.description}
                        </Typography>
                    }
                >
                    <div style={{
                        width: '100%',
                        
                        height: "100%"
                    }}>
                        <Typography variant="h6" flex={7} title={item.name} className={isExpand ? classes.fullTexttextClass : classes.textClass}>
                            {" "}
                            {item.name}
                        </Typography>
                    </div>
                </Tooltip>
            ) : (
                <div style={{
                    width: '100%',
                    height: "100%"
                }}>
                    <Typography variant="h6" flex={7} title={item.name} className={isExpand ? classes.fullTexttextClass : classes.textClass}>
                        {" "}
                        {item.name}
                    </Typography>
                </div>
            )}
            {showIcon && <span
                onClick={() => trackingDispatch(setTrackingState({
                    openStartModal: true,
                    showOutcome: true,
                    outComeValue: item,
                    outcomeId: item._id,
                    categoryId: categoryId
                }))}
                style={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%"
                }}
            >
                <EditIcon />
            </span>}
        </div>
    </td>
}

export default PersonalTrackingOutcome