import { Box, Divider, Grid, IconButton, Typography } from "@mui/material";
import moment from "moment";
import { makeStyles } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { ReportsTypeContext } from "../../context/userIReports";
import palette from "../../theme/palette";
import { pxToRem } from "../../theme/typography";
import HTMLTooltip from "../../components/HTMLTooltip";
import InfoIcon from "../../assets/images/icon/infoIconYellow";
import Info from "../../assets/images/icon/Info";
import EyeIcon from "../../assets/images/icon/EyeIcon";
import CommonTypo from "../../components/CommonTypo";
import InfluenceIcon from "../../assets/images/icon/ProgressInsights/InfluenceIcon";
import { InformationBox } from "../tracker/Modals/SocialReportStepsSidePreview";

const useStyles = makeStyles((theme) => ({
  weeklyReportItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "24px 12px 24px 12px",
    gap: "32px",
    // boxShadow: '0 0 3px #ccc'
    background: "#fff",
    width: "100%",
    margin: "8px 0 !important",
  },
  titleCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    gap: "24px",

    width: "100%",
    // height: "151px",
    justifyContent: "center",
  },
  titleText: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(20),
    color: "var(--color-black)",
    overflow: "hidden",
    display: "-webkit-box",
    lineClamp: 1,
    boxOrient: "vertical",
  },
  influencesCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px 10px 0px 0px",
    gap: "0px",

    overflowY: "auto",
    width: "100%",
    height: "130px",
  },
  title: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: "0px",
    gap: "16px",

    width: "100%",
    // height: "100px",
  },
  avgDetails: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    gap: "16px",

    width: "100%",
    // minHeight: "60px",
  },
  avgDetailsInfo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px",
    gap: "5px",

    width: "100%",
    height: "50px",
  },
  currentAvgCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    gap: "8px",

    width: "50%",
    height: "50px",
  },
  avgdetailsTitle: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: pxToRem(12),
    lineHeight: pxToRem(12),
    color: "var(--color-black)",
  },
  cureentAvgInfo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px",
    gap: "8px",

    // width: '129px',
    height: "30px",
  },
  cureentAvgInfoText: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "30px",
    color: "var(--color-new-main)",
  },
  stepper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px",

    width: "100%",
    height: "13px",
    position: "relative",
  },
  stepperGrid: {
    display: "grid",
    alignItems: "flex-start",
    padding: "0px",
    gridTemplateColumns: "repeat(7, 1fr)",

    width: "100%",
    height: "13px",
    position: "relative",
  },
  dateInfoStep: {
    width: "45px",
    height: "18px",
    position: "relative",
  },
  stepperLine: {
    position: "absolute",
    height: "1px",
    left: "0%",
    right: "0%",
    top: "calc(50% - -4px/2)",

    background: "#CADFD2",
  },
  stepperLineDot: {
    // position: 'absolute',
    width: "18px",
    height: "18px",
    left: "0px",
    top: "calc(50% - 13px/2)",
    borderRadius: "50%",
    zIndex: 1,
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  stepperLineDotText: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",

    textAlign: "center",

    color: "#FFFFFF",
  },
  insightsTableRow: {
    display: "flex",
    width: "100%",
  },
  insightsRegression: {
    display: "flex",
    width: "50%",
    verticalAlign: "middle",
    padding: "5px 0",
  },
  insightsRegressionCellTitle: {
    display: "flex",
    alignItems: "center",
    verticalAlign: "middle",
    justifyContent: "flex-end",
    fontWeight: 400,
    fontSize: "10px",

    color: "#9BA19D",
  },
  insightsInfluences: {
    display: "flex",
    width: "50%",
    padding: "4px 0px",
    fontWeight: 500,
    lineHeight: "100%",
    alignItems: "center",
    textAlign: "left",
    color: "#3E4240",
    verticalAlign: "middle",
    fontStyle: "normal",
    fontSize: "10px",
  },
  insightsInfluencesTitle: {
    display: "flex",
    alignItems: "center",
    verticalAlign: "middle",
    justifyContent: "center",
    fontWeight: 400,
    fontSize: "10px",

    color: "#9BA19D",
  },
  insightsCorrelation: {
    // display: 'flex',
    width: "50%",
    verticalAlign: "middle",
    padding: "4px 0",
  },
  insightsCorrelationCell: {
    display: "flex",
    alignItems: "center",
    verticalAlign: "middle",
    justifyContent: "flex-start",
  },
  insightsCorrelationCellTitle: {
    display: "flex",
    alignItems: "center",
    verticalAlign: "middle",
    justifyContent: "flex-start",
    fontWeight: 400,
    fontSize: "16px",

    color: "#9BA19D",
  },
  insightsCorrelationPositive: {
    height: "24px",

    /* GraphicColors/DarkSky */

    background: "var(--color-new-main)",
    borderRadius: "0px 8px 8px 0px",
    marginRight: "20px",
    width: "100%",
  },
  insightsCorrelationNegative: {
    height: "24px",

    /* GraphicColors/DarkSky */

    background: "#F17F79",
    borderRadius: "0px 8px 8px 0px",
    marginRight: "20px",
    width: "100%",
  },
  insightsCorrelationPositiveText: {
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "100%",
    /* identical to box height, or 16px */

    display: "flex",
    alignItems: "center",
    textAlign: "right",

    /* GraphicColors/DarkSky */

    color: "var(--black-color)",
  },
  insightsCorrelationNegativeText: {
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "100%",
    /* identical to box height, or 16px */

    display: "flex",
    alignItems: "center",
    textAlign: "right",

    /* GraphicColors/DarkSky */

    color: "var(--black-color)",
  },
  iconRound: {
    display: "flex",
    // width: '44px',
    // height: '44px',
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "100px",
    background: "#FFF",
    "& path": {
      //      fill: theme.palette.secondary[50],
    },
  },
}));
const WeeklyCalendar = ({ dates, data, isCountcome, item }) => {
  const nearestValueOutcome = (val, influenceItem) => {
    let arr = [];
    if (
      influenceItem.outcomeId &&
      influenceItem?.options?.length &&
      influenceItem?.options[val - 1]
    ) {
      return influenceItem.options[val - 1].colorCode;
    }
    if (influenceItem?.options && influenceItem?.options.length) {
      arr = [...influenceItem?.options].map((it) => it.value);
    }
    const returnVal =
      arr.reduce(
        (p, n) => (Math.abs(p) > Math.abs(n - val) ? n - val : p),
        Infinity
      ) + val;
    if (influenceItem?.options && influenceItem?.options.length) {
      const foundIndexOfValue = influenceItem?.options.findIndex(
        (it) => it.value === returnVal
      );
      if (foundIndexOfValue > -1) {
        return influenceItem?.options[foundIndexOfValue]?.colorCode;
      }
    }
    return false;
  };
  const closest = (needle, haystack = []) => {
    if (haystack && haystack.length) {
      return haystack.reduce((a, b) => {
        let aDiff = Math.abs(a - needle);
        let bDiff = Math.abs(b - needle);

        if (aDiff == bDiff) {
          return a > b ? a : b;
        } else {
          return bDiff < aDiff ? b : a;
        }
      });
    }
    return -1;
  };

  const nearestValue = (val, influenceItem) => {
    let arr = [];
    if (influenceItem?.options && influenceItem?.options.length) {
      arr = [...influenceItem?.options].map((it) => it.value);
    }
    if (
      influenceItem.type === "TYPE2" &&
      influenceItem.options.length &&
      influenceItem.options[val - 1]
    ) {
      return influenceItem.options[val - 1].colorCode;
    }
    const returnVal = closest(val, arr);
    if (
      influenceItem?.options &&
      influenceItem?.options.length &&
      returnVal > -1
    ) {
      const foundIndexOfValue = influenceItem?.options.findIndex(
        (it) => it.value === returnVal
      );
      if (foundIndexOfValue > -1) {
        return influenceItem?.options[foundIndexOfValue]?.colorCode;
      }
    }
    return false;
  };
  const formatDate = (date) => {
    const d = new Date(date);
    return `${(d.getMonth() + 1).toString().padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}-${d.getFullYear()}`;
  };

  const dataLookup = Object.fromEntries(data.map(d => [d.date, d.index]));

  const weeks = [];
  const firstDate = new Date(dates[0]);
  const lastDate = new Date(dates[dates.length - 1]);

  // Calculate padding for start and end of weeks
  const startPadding = (firstDate.getDay() + 6) % 7; // Days to fill before first date (adjust for Monday start)
  const endPadding = 6 - ((lastDate.getDay() + 6) % 7); // Days to fill after last date

  let currentWeek = Array(startPadding).fill(null); // Start with padding days before the first date

  dates.forEach(date => {
    const dayOfWeek = (new Date(date).getDay() + 6) % 7; // Adjusting for Monday as day 0
    currentWeek[dayOfWeek] = date;

    // If the week is complete, push it to weeks array and start a new week
    if (currentWeek.length === 7) {
      weeks.push(currentWeek);
      currentWeek = [];
    }
  });

  // Fill remaining days in the last week with `null` to complete to Sunday
  currentWeek = currentWeek.concat(Array(endPadding).fill(null));
  if (currentWeek.length) weeks.push(currentWeek);
  
  return (
    <Box display="flex" gap="8px" flexDirection="column">
      {weeks.map((week, weekIndex) => (
        <Box
          key={weekIndex}
          display="flex"
          gap="8px"
          alignItems="center"
          justifyContent="space-evenly"
        >
          {week.map((day, dayIndex) => {
            if(!day) {
              return <Box
              key={dayIndex}
              sx={{
                height: "32px",
                width: "32px",
                minWidth: "32px",
                padding: "12px 4px",
                borderRadius: "25.6px",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                gap: "4px",
                background: 'transparent'
              }}
            >
           
            </Box>
            }
            let bgColor = "#E3E3E3";
            let textValue = "-";
            if (isCountcome) {
              const foundDateData = item.outcomeDataDates.findIndex(
                (ite) => ite.date === moment(day).format("MM-DD-YYYY")
              );
              if (foundDateData > -1) {
                textValue = item.outcomeDataDates[foundDateData].index[0];
                let nearValue = nearestValueOutcome(textValue, item);
                if (item?.type === "TYPE2") {
                  nearValue = null;
                }
                if (
                  item?.outcomeId &&
                  item?.options?.length &&
                  item?.options[item?.index - 1]
                ) {
                  nearValue = item.options[item?.index - 1].colorCode;
                }
                if (nearValue) {
                  bgColor = nearValue;
                }
              }
            }
            if (!isCountcome) {
              const foundDateData = item.influenceDataDates.findIndex(
                (ite) => ite.date === moment(day).format("MM-DD-YYYY")
              );
              if (foundDateData > -1) {
                textValue = item.influenceDataDates[foundDateData].index[0];
                let nearValue = nearestValue(
                  item.type === "TYPE1" || item.type === "TYPE12"
                    ? textValue
                    : textValue,
                  item
                );
                if (item.type === "TYPE2") {
                  if (
                    item?.type === "TYPE2" &&
                    item.options.length &&
                    item.options[item.index - 1]
                  ) {
                    nearValue = item.options[item.index - 1].colorCode;
                  }
                }
                if (item?.type === "TYPE3") {
                  if (item?.inverse === "true" && textValue === "1") {
                    nearValue = "var(--color-new-main)";
                  } else if (item?.inverse === "true" && textValue === "0") {
                    nearValue = "#F17F79";
                  } else if (item?.inverse === "false" && textValue === "0") {
                    nearValue = "#F17F79";
                  } else if (item?.inverse === "false" && textValue === "1") {
                    nearValue = "var(--color-new-main)";
                  }
                }

                if (nearValue) {
                  bgColor = nearValue;
                } else {
                  bgColor = "var(--color-new-main)"
                }
              }
            }
            return (
              <Box
                key={dayIndex}
                sx={{
                  height: "32px",
                  width: "32px",
                  minWidth: "32px",
                  padding: "12px 4px",
                  borderRadius: "25.6px",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: "4px",
                  background: bgColor
                }}
              >
                <CommonTypo size={11.2} lineHeight={11.2} fontWeight={600} color="var(--color-text-primary)">
                  {textValue}
                </CommonTypo>
              </Box>
            );
          })}
        </Box>
      ))}
    </Box>
  );
};
const ReportDetailsV2 = ({ isfromshare = false, andViewOnly = false }) => {
  const [dates, setDates] = useState([]);
  const classes = useStyles();
  const { state: IReportTypeContext, dispatch: reportTypeDispatch } =
    useContext(ReportsTypeContext);
  useEffect(() => {
    if (Object.keys(IReportTypeContext.selectedRow).length) {
      enumerateDaysBetweenDates(
        IReportTypeContext.selectedRow.from,
        IReportTypeContext.selectedRow.till
      );
    } else {
      setDates([]);
    }
    return () => setDates([]);
  }, [IReportTypeContext.selectedRow]);
  // const trackingEvent = () => {
  //     const day = moment(IReportTypeContext.selectedRow.from);

  //     const tempCalendar: any[][] = [];

  //     let startDayTemp = moment(IReportTypeContext.selectedRow.from)?.clone();
  //     let endDayTemp = moment(IReportTypeContext.selectedRow.from)?.clone();

  //     while (day?.isBefore(moment(IReportTypeContext.selectedRow.till), "day")) {
  //         let daysInMonth = startDayTemp
  //             .clone()
  //             .endOf("month")
  //             .diff(endDayTemp.clone(), "days");

  //         tempCalendar.push(
  //             Array(daysInMonth + 1)
  //                 .fill(0)
  //                 .map(() => {
  //                     return day.add(1, "day").clone();
  //                 })
  //         );

  //         startDayTemp = day.clone().add(1, "day");
  //         endDayTemp = moment(IReportTypeContext.selectedRow.from).clone();
  //     }

  //     setDates(tempCalendar);
  // };
  const enumerateDaysBetweenDates = (startDate, endDate) => {
    var dates = [];

    var currDate = moment(startDate).subtract(1, "day").startOf("day");
    var lastDate = moment(endDate).endOf("day");

    while (currDate.add(1, "days").diff(lastDate) < 0) {
      dates.push(currDate.clone().toDate());
    }
    setDates(dates);
  };
  const renderIcon = (icon, onClick = () => { }) => (
    <div className={classes.iconRound} onClick={onClick}>
      {icon}
    </div>
  );
  return (
    <>
      {isfromshare ? (
        <>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={"8px"}
          >
            <Typography
              letterSpacing={"0.02em"}
              sx={{
                color: palette.primary.light,
                // fontSize: `${pxToRem(10)} !important`,
                textAlign: "center",
                fontSize: `${pxToRem(20)} !important`,
                lineHeight: "120% !important",
                // textAlign: "center !important",
                fontWeight: `700 !important`,
                "&.MuiTypography-root": {
                  "@media  (max-width: 600px) and (min-width: 100px)": {
                    fontSize: `${pxToRem(20)} !important`,
                    lineHeight: "120% !important",
                  },
                },
              }}
            >
              Snapshot of your report
            </Typography>
            <IconButton>
              <HTMLTooltip
                customIcon={renderIcon(<Info />, () => { })}
                title="This preview offers a partial view of your report. Not all metrics chosen for sharing are included in this preview."
                fontProps={{
                  fontSize: `${pxToRem(12)} !important`,
                }}
                sx={{
                  "& .MuiTooltip-tooltip": {
                    maxWidth: "300px",
                    background: '#E9F8E0',
                    borderColor: "#DEF6CF",

                  },
                }}
              />
            </IconButton>
          </Box>
          {IReportTypeContext.selectedRowDetails?.outcome &&
            IReportTypeContext.selectedRowDetails?.outcome.length > 0 && (
              <>
                <Typography
                  component="span"
                  sx={{
                    color: palette.primary.light,
                    // fontSize: `${pxToRem(10)} !important`,
                    textAlign: "center",
                    fontSize: `${pxToRem(19)} !important`,
                    lineHeight: "120% !important",
                    // textAlign: "center !important",
                    fontWeight: `700 !important`,
                    "&.MuiTypography-root": {
                      "@media  (max-width: 600px) and (min-width: 100px)": {
                        fontSize: `${pxToRem(19)} !important`,
                        lineHeight: "120% !important",
                      },
                    },
                  }}
                >
                  Outcomes
                </Typography>
                <Grid
                  container
                  sx={{
                    gap: "0 16px",
                    margin: "24px 0",
                  }}
                  spacing={2} /* justifyContent={'space-between'} */
                >
                  {IReportTypeContext.selectedRowDetails?.outcome &&
                    IReportTypeContext.selectedRowDetails?.outcome.map((it) => {
                      return (
                        <Carditem
                          isOutCome={true}
                          key={it.outcomeId}
                          item={it}
                          dates={dates}
                          isfromshare={isfromshare}
                          andViewOnly={andViewOnly}
                        />
                      );
                    })}
                </Grid>
              </>
            )}

          {IReportTypeContext.selectedRowDetails?.influence &&
            IReportTypeContext.selectedRowDetails?.influence.length > 0 && (
              <>
                <Typography component="span" sx={{
                  color: palette.primary.light,
                  // fontSize: `${pxToRem(10)} !important`,
                  textAlign: "center",
                  fontSize: `${pxToRem(19)} !important`,
                  lineHeight: "120% !important",
                  // textAlign: "center !important",
                  fontWeight: `700 !important`,
                  "&.MuiTypography-root": {
                    "@media  (max-width: 600px) and (min-width: 100px)": {
                      fontSize: `${pxToRem(19)} !important`,
                      lineHeight: "120% !important",
                    },
                  },
                }}>
                  Influences
                </Typography>
                <Grid
                  container
                  sx={{
                    gap: "0 16px",
                    margin: "24px 0",
                  }}
                  spacing={2} /* justifyContent={'space-between'} */
                >
                  {IReportTypeContext.selectedRowDetails?.influence &&
                    IReportTypeContext.selectedRowDetails?.influence.map(
                      (it) => {
                        return (
                          <Carditem
                            isOutCome={false}
                            key={it.influenceId}
                            item={it}
                            dates={dates}
                            isfromshare={isfromshare}
                            andViewOnly={andViewOnly}
                          />
                        );
                      }
                    )}
                </Grid>
              </>
            )}
        </>
      ) : (
        <>
          <Box display={"flex"} flexDirection={"column"} alignSelf={"stretch"} width={"100%"} gap={"16px"}>
            <CommonTypo fontWeight={700} size={24} lineHeight={28.8} color="var(--color-text-primary)">Outcomes</CommonTypo>
            <Box display={"flex"} className="hide-scrollbar" alignSelf={"stretch"} width={"100%"} gap={"32px"} sx={{
              overflowX: "auto"
            }}>
              {IReportTypeContext.selectedRowDetails?.outcome &&
                IReportTypeContext.selectedRowDetails?.outcome.map((it) => {
                  return (
                    <Carditem
                      isOutCome={true}
                      key={it.outcomeId}
                      item={it}
                      dates={dates}
                      andViewOnly={andViewOnly}

                    />
                  );
                })}
            </Box>
          </Box>
          <Box display={"flex"} flexDirection={"column"} alignSelf={"stretch"} width={"100%"} gap={"16px"}>
            <CommonTypo fontWeight={700} size={24} lineHeight={28.8} color="var(--color-text-primary)">Influences</CommonTypo>
            <Box display={"flex"} className="hide-scrollbar" alignSelf={"stretch"} width={"100%"} gap={"32px"} sx={{
              overflowX: "auto"
            }}>
              {IReportTypeContext.selectedRowDetails?.influence &&
                IReportTypeContext.selectedRowDetails?.influence.map((it) => {
                  return (
                    <Carditem
                      isOutCome={false}
                      key={it.influenceId}
                      item={it}
                      dates={dates}
                      andViewOnly={andViewOnly}

                    />
                  );
                })}
            </Box>
          </Box>
          {/* 
          <Grid
            container
            sx={{ gap: "0 16px", margin: "24px 0" }}
            spacing={2} 
          >
            {IReportTypeContext.selectedRowDetails?.outcome &&
              IReportTypeContext.selectedRowDetails?.outcome.map((it) => {
                return (
                  <Carditem
                    isOutCome={true}
                    key={it.outcomeId}
                    item={it}
                    dates={dates}
                    andViewOnly={andViewOnly}

                  />
                );
              })}
          </Grid> */}

          {/* <Typography variant="h3" letterSpacing={"0.02em"}>
            Influences
          </Typography> */}
          {/* <Grid
            container
            sx={{ gap: "0 16px", margin: "24px 0" }}
            spacing={2}
          >
            {IReportTypeContext.selectedRowDetails?.influence &&
              IReportTypeContext.selectedRowDetails?.influence.map((it) => {
                return (
                  <Carditem
                    isOutCome={false}
                    key={it.influenceId}
                    item={it}
                    dates={dates}
                    andViewOnly={andViewOnly}

                  />
                );
              })}
          </Grid> */}
        </>
      )}
    </>
  );
};

export default ReportDetailsV2;

export const Carditem = (props) => {
  const { isOutCome = false, item, dates = [], isfromshare = false } = props;
  const classes = useStyles();
  const [isShowMore, setShowMore] = useState(false)

  const nearestValueOutcome = (val, influenceItem) => {
    let arr = [];
    if (
      influenceItem.outcomeId &&
      influenceItem?.options?.length &&
      influenceItem?.options[val - 1]
    ) {
      return influenceItem.options[val - 1].colorCode;
    }
    if (influenceItem?.options && influenceItem?.options.length) {
      arr = [...influenceItem?.options].map((it) => it.value);
    }
    const returnVal =
      arr.reduce(
        (p, n) => (Math.abs(p) > Math.abs(n - val) ? n - val : p),
        Infinity
      ) + val;
    if (influenceItem?.options && influenceItem?.options.length) {
      const foundIndexOfValue = influenceItem?.options.findIndex(
        (it) => it.value === returnVal
      );
      if (foundIndexOfValue > -1) {
        return influenceItem?.options[foundIndexOfValue]?.colorCode;
      }
    }
    return false;
  };
  const closest = (needle, haystack = []) => {
    if (haystack && haystack.length) {
      return haystack.reduce((a, b) => {
        let aDiff = Math.abs(a - needle);
        let bDiff = Math.abs(b - needle);

        if (aDiff == bDiff) {
          return a > b ? a : b;
        } else {
          return bDiff < aDiff ? b : a;
        }
      });
    }
    return -1;
  };

  const nearestValue = (val, influenceItem) => {
    let arr = [];
    if (influenceItem?.options && influenceItem?.options.length) {
      arr = [...influenceItem?.options].map((it) => it.value);
    }
    if (
      influenceItem.type === "TYPE2" &&
      influenceItem.options.length &&
      influenceItem.options[val - 1]
    ) {
      return influenceItem.options[val - 1].colorCode;
    }
    const returnVal = closest(val, arr);
    if (
      influenceItem?.options &&
      influenceItem?.options.length &&
      returnVal > -1
    ) {
      const foundIndexOfValue = influenceItem?.options.findIndex(
        (it) => it.value === returnVal
      );
      if (foundIndexOfValue > -1) {
        return influenceItem?.options[foundIndexOfValue]?.colorCode;
      }
    }
    return false;
  };
  let heighestCorrelation = 1;
  if (isOutCome && item.influence && item.influence.length) {
    heighestCorrelation = Math.max(
      ...item.influence.map((o) => Math.abs(o.correlation || 0))
    );
  }
  const top3Influe = item.influence && item.influence.length > 0 ? item.influence.slice(0, 3) : [];
  // const top5PositiveInflue = item.influence && item.influence.length > 0 && !isShowMore ? item.influence.filter(it => it.correlation > 0).slice(0, 2)
  //   : item.influence && item.influence.length > 0 && isShowMore ? item.influence.filter(it => it.correlation > 0).slice(0, 8) : [];
  // const top5NegativeInflue = item.influence && item.influence.length > 0 && !isShowMore ? item.influence.filter(it => it.correlation < 0).slice(-2) :
  //   item.influence && item.influence.length > 0 && isShowMore ? item.influence.filter(it => it.correlation < 0).slice(-8) : [];
  // const top10influence = [...top5PositiveInflue, ...top5NegativeInflue]
  const heighestPositiveCorrelation = Math.max(
    ...top3Influe.map((o) => Math.abs(o.correlation || 0))
  );
  return (
    <>
      <Box sx={{
        minWidth: "467px",
        height: "max-content",
        minHeight: "100%",
        alignSelf: "stretch",
        padding: "32px",
        gap: "32px",
        display: "flex",
        flexDirection: "column",
        borderRadius: "12px",
        border: "1px solid var(--color-border)",

      }}>
        <Box display={"flex"} alignItems={"center"} alignSelf={"stretch"} width={"calc(100% - 20px)"}>
          <Box display={"flex"} alignItems={"center"} alignSelf={"stretch"} gap={"12px"} width={"100%"}>
            <Box display={"flex"} alignItems={"center"} justifyContent={"center"} height={"36px"} width={"36px"} borderRadius={"5px"} sx={{
              background: "var(--color-new-main)",
            }} >
              {isOutCome ? <svg width="24" height="24" viewBox="0 0 24 24">
                <path d={item.icon} fill="#FFF" />
              </svg> : <InfluenceIcon color="#FFF"/>}
              {}
            </Box>
            <Box display={"flex"} alignItems={"center"} alignSelf={"stretch"} width={"calc(100% - 36px)"} justifyContent={"space-between"}>
              <Box display={"flex"} alignItems={"flex-start"} flexDirection={"column"} gap={"4px"}>
                <CommonTypo size={16} lineHeight={16} fontWeight={600} color="var(--color-text-primary)">{item.name}</CommonTypo>
                <CommonTypo size={12} lineHeight={12} fontWeight={400} color="var(--color-text-secondary)">{"Current Avg."}</CommonTypo>
              </Box>
              <Box>
                <CommonTypo size={16} lineHeight={16} fontWeight={600} color="var(--color-text-primary)">
                  {Number(item.weekAverage) ? parseFloat(item.weekAverage).toFixed(2) : "-"}
                </CommonTypo>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display={"flex"} flexDirection={"column"} gap={"8px"} sx={{
          minWidth: "403px",
          height: dates.length > 7 ? "auto" : "80px",
        }}>
          {dates.length > 7 ? (
            <> <Box display={"flex"} gap={"8px"} alignItems={"center"} justifyContent={"space-evenly"}>
              {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"].map(it => <Box sx={{
                height: "18px",
                width: "38px",
                minWidth: "38px",
                padding: "12px 4px",
                borderRadius: "8px",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                gap: "4px"
              }}>
                <CommonTypo size={10} lineHeight={10} fontWeight={400} color="var(--color-text-secondary)">{it}</CommonTypo>
              </Box>)}
            </Box>
              <Box display={"flex"} gap={"8px"} flexDirection={"column"} >
                <WeeklyCalendar data={isOutCome ? item.outcomeDataDates : item.influenceDataDates} dates={dates} isCountcome={isOutCome} item={item} />
              </Box>
            </>
          ) : <> <Box display={"flex"} gap={"8px"} alignItems={"center"} justifyContent={"space-evenly"}>
            {dates.map(it => <Box sx={{
              height: "40px",
              width: "40px",
              minWidth: "40px",
              padding: "12px 4px",
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: "4px"
            }}>
              <CommonTypo size={10} lineHeight={10} fontWeight={400} color="var(--color-text-secondary)">{moment(it).format("ddd")}</CommonTypo>
              <CommonTypo size={14} lineHeight={14} fontWeight={400} color="var(--color-text-primary)">{moment(it).format("DD")}</CommonTypo>

            </Box>)}
          </Box>
            <Box display={"flex"} gap={"8px"} alignItems={"center"} justifyContent={"space-evenly"}>
              {dates.map(it => {
                let bgColor = "#E3E3E3";
                let textValue = "-";
                if (isOutCome) {
                  const foundDateData = item.outcomeDataDates.findIndex(
                    (ite) => ite.date === moment(it).format("MM-DD-YYYY")
                  );
                  if (foundDateData > -1) {
                    textValue = item.outcomeDataDates[foundDateData].index[0];
                    let nearValue = nearestValueOutcome(textValue, item);
                    if (item?.type === "TYPE2") {
                      nearValue = null;
                    }
                    if (
                      item?.outcomeId &&
                      item?.options?.length &&
                      item?.options[item?.index - 1]
                    ) {
                      nearValue = item.options[item?.index - 1].colorCode;
                    }
                    if (nearValue) {
                      bgColor = nearValue;
                    }
                  }
                }
                if (!isOutCome) {
                  const foundDateData = item.influenceDataDates.findIndex(
                    (ite) => ite.date === moment(it).format("MM-DD-YYYY")
                  );
                  if (foundDateData > -1) {
                    textValue = item.influenceDataDates[foundDateData].index[0];
                    let nearValue = nearestValue(
                      item.type === "TYPE1" || item.type === "TYPE12"
                        ? textValue
                        : textValue,
                      item
                    );
                    if (item.type === "TYPE2") {
                      if (
                        item?.type === "TYPE2" &&
                        item.options.length &&
                        item.options[item.index - 1]
                      ) {
                        nearValue = item.options[item.index - 1].colorCode;
                      }
                    }
                    if (item?.type === "TYPE3") {
                      if (item?.inverse === "true" && textValue === "1") {
                        nearValue = "var(--color-new-main)";
                      } else if (item?.inverse === "true" && textValue === "0") {
                        nearValue = "#F17F79";
                      } else if (item?.inverse === "false" && textValue === "0") {
                        nearValue = "#F17F79";
                      } else if (item?.inverse === "false" && textValue === "1") {
                        nearValue = "var(--color-new-main)";
                      }
                    }

                    if (nearValue) {
                      bgColor = nearValue;
                    } else {
                      bgColor = "var(--color-new-main)"
                    }
                  }
                }
                return <Box sx={{
                  height: "32px",
                  width: "32px",
                  minWidth: "32px",
                  padding: "12px 4px",
                  borderRadius: "25.6px",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: "4px",
                  background: bgColor
                }}>
                  <CommonTypo size={11.2} lineHeight={11.2} fontWeight={600} color="var(--color-text-primary)">{item?.type !== "TYPE3" && textValue}</CommonTypo>
                </Box>
              })}
            </Box></>}


        </Box>
        {isOutCome && top3Influe.length > 0 && (
          <Box display={"flex"} flexDirection={"column"} gap={"16px"} width={"100%"}>
            <CommonTypo size={16} lineHeight={16} fontWeight={600} color="var(--color-text-primary)">Top influences</CommonTypo>
            {top3Influe.map((ite, index) => {
              return <InformationBox
                number={index + 1}
                title={ite.name}
                star={ite.star}
                correlation={ite.correlation}
                strongTitle={ite.correlationTitle}
                nutral={index >= 5}
                positive={ite.correlation > 0}
                dataPoints={ite?.dataPoints || 0}
                regression={ite?.regression?.slope}
                maxCorrekation={heighestPositiveCorrelation}
                isFromSocialReport={true}
              />
            })}
          </Box>
        )}
      </Box>
    </>
  );
};

