/*eslint @typescript-eslint/no-unused-vars: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint eqeqeq: "off"*/
import React, { useState, useContext, useRef } from "react";
import { Box } from "@mui/system";
import clsx from "clsx";
import palette from "../../theme/palette";
import AddTrackingEventModal from "./addTrackingEventModal";
import { UserTypeContext } from "../../context/userStatus";
import { OutcomeGetList } from "../../interfaces/outcomeModal";
import { trackingService } from "../../services/tracking";
import SnackBarComp from "../../components/snackBar";
import moment from "moment";
import { addCategoryJourneyAction } from "../../store/actions/userTypeAction";
import { Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  icon: {
    position: "relative",
    borderBottom: "1px solid #F3F3F3",
    padding: "14px 0",
    height: "65px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "wrap",
  },
  iconInner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexBasis: "100%",
    height: "35px",
    "&:after": {
      content: "''",
      position: "absolute",
      display: "flex",
      left: "28px",
      border: "1px dashed #E7E7E7",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  iconInnerWithoutAfter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexBasis: "100%",
  },
  iconLast: {
    position: "relative",
    padding: "14px 0",
    height: "65px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "wrap",

  },
  iconWithoutAfter: {
    position: "relative",
    borderBottom: "0",
    padding: "14px 0",
    height: "65px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    // flexDirection: "row-reverse"
  },
  iconExpand: {
    height: "auto",
    "&:after": {
      width: "100%",
    },
  },
  addOutlineIcon: {
    zIndex: 1,
    "& span": {
      color: "rgb(227, 227, 227)",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "28px",
      fontFamily: "Poppins",
      display: "block",
      textAlign: "center",
      width: "28.5px",
      height: "28.5px",
      border: "1px solid rgb(227, 227, 227)",
      borderRadius: "50%",
      backgroundColor: palette.common.white,
    },
  },
  addOutlineIconInput: {
    border: "1px solid rgb(227, 227, 227)",
    borderRadius: "50%",
    backgroundColor: palette.common.white,
    textAlign: "center",
    width: "28px",
    height: "28px",
    "& input": {
      width: "18px",
      height: "19px",
      fontFamily: "Poppins",
    },
  },
  borderOutline: {
    width: "34px!important",
    height: "34px!important",
  },
  notesItem: {
    margin: 0,
    listStyleType: "none",
    paddingLeft: "0",
    "& li": {
      color: palette.primary.light,
      fontSize: "12px",
      lineHeight: "15px",
      "&:before": {
        content: "''",
        backgroundColor: palette.primary.main,
        width: "4px",
        height: "4px",
        display: "inline-block",
        marginRight: "5px",
        borderRadius: "50%",
      },
    },
  },
  absoluteBorders: {
    height: '66px',
    display: 'flex',
    padding: '32px 0',
    position: 'absolute',
    alignItems: 'center',
    borderBottom: '1px solid #F3F3F3',
    justifyContent: 'center',
    width: '30px',
    left: '24px',
  },
  absoluteExpand: {
    width: '172px',

  }
}));

interface IconListModal {
  setOutcomeId: (outcomeId: string) => void;
  outcomeId: string;
  journeyData: any;
  headerDate: any;
  betweenDate: any;
  callservice: any;
  setShowLoaderPage: (showLoaderPage: boolean) => void;
  showLoaderPage: boolean;
  categoryId: string
  month: moment.Moment[];
}

const IconPersonalOutcome = (props: IconListModal) => {
  const classes = useStyles();
  const [showInput, setShowInput] = useState(false);
  const [openTrackingEventModal, setOpenTrackingEventModal] = useState(false);
  const { state: outcomeState, dispatch: UserTypeDispatch } = useContext(UserTypeContext);
  const [inputValue, setInputValue] = useState("");
  const [bgColor, setBgColor] = useState("");
  const [item, setItem] = useState<any>();
  const [selectedOption, setSelectedOption] = useState<any>();
  const [showSnakbar, setShowSnakbar] = useState(false);
  const [snakbarMsg, setSnakbarMsg] = useState("");
  const [msgType, setMsgType] = useState("");
  const [displayInput, setDisplayInput] = useState(false);
  const [optionIndex, setOptionIndex] = useState<any>("");
  const [displayBorder, setDisplayBorder] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [outcomeIndex, setOutcomeIndex] = useState(0);
  const clickCondition = moment(props.betweenDate).isBetween(
    moment().subtract(8, "year").format("yyyy-MM-DD"),
    moment().add(1, "day").format("yyyy-MM-DD")
  );

  const getBackgroudColor = (number: number) => {
    setBgColor(item.options[number].colorCode);
  };
  const handleCloseSnakbar = () => {
    setShowSnakbar(false);
  };

  const handleBlurInput = (id: any, index: any, date: any, e: any, mouseOut: boolean, categoryId) => {
    const renderToNextInput = () => {
      const foundCategory = outcomeState?.journeyCategoryList.find(it => it.categoryId === categoryId)
      if (foundCategory && foundCategory.alls && foundCategory.alls.length) {
        const findIndexOfEl = foundCategory.alls.findIndex(it => it._id === id);
        if (findIndexOfEl > -1) {
          // // down arrow handle
          if (e.keyCode == "40" && foundCategory.alls[findIndexOfEl + 1] && foundCategory.alls[findIndexOfEl + 1]._id) {
            const date = moment(props.headerDate, "mm-DD-yyyy").format("mm-DD-yyyy")
            const foundElement = document.getElementById(date + "_" + foundCategory.alls[findIndexOfEl + 1]._id)
            if (foundElement) {
              foundElement.click()

            }
          }
          // // up arrow key
          if (e.keyCode == "38" && foundCategory.alls[findIndexOfEl - 1] && foundCategory.alls[findIndexOfEl - 1]._id) {
            const date = moment(props.headerDate, "mm-DD-yyyy").format("mm-DD-yyyy")
            const foundElement = document.getElementById(date + "_" + foundCategory.alls[findIndexOfEl - 1]._id)
            if (foundElement) {
              foundElement.click()

            }
          }

          //right side arrow keys
          if (e.keyCode == "39") {
            const date = moment(props.headerDate, "mm-DD-yyyy").add(1, 'day').format("mm-DD-yyyy")
            const foundElement = document.getElementById(date + "_" + id)
            if (foundElement) {
              foundElement.click()
            }

          }
          //left side arrow keys
          if (e.keyCode == "37") {
            const date = moment(props.headerDate, "mm-DD-yyyy").subtract(1, 'day').format("mm-DD-yyyy")
            const foundElement = document.getElementById(date + "_" + id)
            if (foundElement) {
              foundElement.click()
            }
          }
        }
      }

    }
    if (mouseOut) {
      setShowInput(false);
    }
    if (
      e.key === "Enter" ||
      e.keyCode == "37" ||
      e.keyCode == "38" ||
      e.keyCode == "39" ||
      e.keyCode == "40" ||
      mouseOut
    ) {
      if (parseInt(inputValue) >= 0 && parseInt(inputValue) <= 5) {
        setShowInput(false);
        setDisplayInput(true);
        outcomeCreate();
      } else {
        setShowSnakbar(true);
        setMsgType("error");
        setSnakbarMsg("Please enter value between 1 to 5");
      }

      // down arrow key
      if (e.keyCode == "40" && index <= outcomeState?.outcomeList?.length - 1) {
        let nextId = "";
        let nextItem;
        for (let i = 0; i < outcomeState?.outcomeList?.length; i++) {
          if (outcomeState?.outcomeList[i]._id === id) {
            nextId = outcomeState?.outcomeList[i + 1]?._id;
            nextItem = outcomeState?.outcomeList[i + 1];
          }
        }
        props.setOutcomeId(nextId);
        setItem(nextItem);
        setShowInput(true);
        setInputValue("");
      }

      // up arrow key
      if (e.keyCode == "38" && index <= outcomeState?.outcomeList?.length - 1) {
        let prevId = "";
        let prevItem;
        for (let i = 0; i < outcomeState?.outcomeList?.length; i++) {
          if (outcomeState?.outcomeList[i]._id === id) {
            prevId = outcomeState?.outcomeList[i - 1]?._id;
            prevItem = outcomeState?.outcomeList[i - 1];
          }
        }
        props.setOutcomeId(prevId);
        setItem(prevItem);
        setShowInput(true);
        setInputValue("");
      }
      renderToNextInput()
    }
  };
  const outcomeCreate = () => {
    props.setShowLoaderPage(true);
    const outcomeCreateData = {
      date: props.headerDate,
      outcomeId: item?.outcomeId,
      answerId: Number(inputValue) === 0 ? null : item?.options[parseInt(inputValue) - 1].answerId,
      score: Number(inputValue) === 0 ? inputValue : item?.options[parseInt(inputValue) - 1].score,
      index: parseInt(inputValue),
      note: [],
    };
    let oldData: any = {}
    if (!outcomeState?.isPersonal) {
      const foundOutcome = (outcomeState?.outcomeList || []).find(it => it.outcomeId === outcomeCreateData.outcomeId);
      if (foundOutcome) {
        const journyList = { ...(outcomeState?.journeyList || {}) } as any
        const foundDateItemIcon = journyList.outcome_data.findIndex(it => it.outcomeId === outcomeCreateData.outcomeId && it?.date === outcomeCreateData.date)
        if (foundDateItemIcon > -1) {
          oldData = { ...journyList.outcome_data[foundDateItemIcon] }
        }
      }
    } else {
      const journeyCategoryList = [...(outcomeState?.journeyCategoryList || [])]
      const foundOutcome = (journeyCategoryList
        || []).findIndex(it => it.outcomeIds && it.outcomeIds.includes(outcomeCreateData.outcomeId));
      if (foundOutcome > -1) {
        if (journeyCategoryList[foundOutcome].alls && journeyCategoryList[foundOutcome].alls.length) {
          const foundIndex = journeyCategoryList[foundOutcome].alls.findIndex(it => it.outcomeId === outcomeCreateData.outcomeId)
          if (foundIndex > -1) {
            if (journeyCategoryList[foundOutcome].alls[foundIndex].outcomesResponses && journeyCategoryList[foundOutcome].alls[foundIndex].outcomesResponses.length) {
              const foundDateValue = journeyCategoryList[foundOutcome].alls[foundIndex].outcomesResponses.findIndex(it => it.date === outcomeCreateData.date)
              if (foundDateValue > -1) {
                oldData = { ...journeyCategoryList[foundOutcome].alls[foundIndex].outcomesResponses[foundDateValue] }
              }
            }
          }
        }
      } else {
        const foundOther = (journeyCategoryList
          || []).findIndex(it => it.name === "Other")
        if (foundOther > -1) {
          const outcomeFound = journeyCategoryList[foundOther].outcomes.findIndex(it => it.outcomeId === outcomeCreateData.outcomeId);
          if (outcomeFound > -1) {
            if (journeyCategoryList[foundOther].outcomes[outcomeFound].outcomesResponses && journeyCategoryList[foundOther].outcomes[outcomeFound].outcomesResponses.length) {
              const foundDateValue = journeyCategoryList[foundOther].outcomes[outcomeFound].outcomesResponses.findIndex(it => it.date === outcomeCreateData.date)
              if (foundDateValue > -1) {
                oldData = { ...journeyCategoryList[foundOther].outcomes[outcomeFound].outcomesResponses[foundDateValue] }
              }
            }
          }
        }
      }
    }
    if (oldData?.note && oldData?.note.length) {
      outcomeCreateData.note = oldData?.note || []
    }
    trackingService
      .outcomeCreate(outcomeState.token, outcomeCreateData)
      .then((data) => {
        const journeyCategoryList = [...(outcomeState?.journeyCategoryList || [])]
        const foundOutcome = (journeyCategoryList
          || []).findIndex(it => it.outcomeIds && it.outcomeIds.includes(outcomeCreateData.outcomeId));
        if (foundOutcome > -1) {
          if (journeyCategoryList[foundOutcome].alls && journeyCategoryList[foundOutcome].alls.length) {
            const foundIndex = journeyCategoryList[foundOutcome].alls.findIndex(it => it.outcomeId === outcomeCreateData.outcomeId)
            if (foundIndex > -1) {
              if (journeyCategoryList[foundOutcome].alls[foundIndex].outcomesResponses && journeyCategoryList[foundOutcome].alls[foundIndex].outcomesResponses.length) {
                const foundDateValue = journeyCategoryList[foundOutcome].alls[foundIndex].outcomesResponses.findIndex(it => it.date === outcomeCreateData.date)
                if (foundDateValue > -1) {
                  journeyCategoryList[foundOutcome].alls[foundIndex].outcomesResponses[foundDateValue] = {
                    ...outcomeCreateData
                  }
                } else {
                  journeyCategoryList[foundOutcome].alls[foundIndex].outcomesResponses.push(outcomeCreateData)
                }
              } else {
                journeyCategoryList[foundOutcome].alls[foundIndex].outcomesResponses = [outcomeCreateData]
              }
              UserTypeDispatch(addCategoryJourneyAction(journeyCategoryList));
            }
          }
        } else {
          const foundOther = (journeyCategoryList
            || []).findIndex(it => it.name === "Other")
          if (foundOther > -1) {
            const outcomeFound = journeyCategoryList[foundOther].outcomes.findIndex(it => it.outcomeId === outcomeCreateData.outcomeId);
            if (outcomeFound > -1) {
              if (journeyCategoryList[foundOther].outcomes[outcomeFound].outcomesResponses && journeyCategoryList[foundOther].outcomes[outcomeFound].outcomesResponses.length) {
                const foundDateValue = journeyCategoryList[foundOther].outcomes[outcomeFound].outcomesResponses.findIndex(it => it.date === outcomeCreateData.date)
                if (foundDateValue > -1) {
                  journeyCategoryList[foundOther].outcomes[outcomeFound].outcomesResponses[foundDateValue] = {
                    ...outcomeCreateData
                  }
                } else {
                  journeyCategoryList[foundOther].outcomes[outcomeFound].outcomesResponses.push(outcomeCreateData)
                }
              } else {
                journeyCategoryList[foundOther].outcomes[outcomeFound].outcomesResponses = [outcomeCreateData]
              }
              UserTypeDispatch(addCategoryJourneyAction(journeyCategoryList));
            }
          }
        }
        // setShowSnakbar(true);
        // setSnakbarMsg(data?.message);
        // props?.callservice();
        props.setShowLoaderPage(false);
        // setMsgType("success");
      })
      .catch((error) => {
        setShowSnakbar(true);
        setSnakbarMsg(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message
        );
        setMsgType("error");
        props.setShowLoaderPage(false);
      });
  };
  const nearestValueOutcome = (val, influenceItem) => {
    let arr = [];
    if (influenceItem.outcomeId && influenceItem.options.length && influenceItem.options[val - 1]) {
      return influenceItem.options[val - 1].colorCode
    }
    if (influenceItem?.options && influenceItem?.options.length) {
      arr = [...influenceItem?.options].map(it => it.score)
    }
    const returnVal = arr.reduce((p, n) => (Math.abs(p) > Math.abs(n - val) ? n - val : p), Infinity) + val
    if (influenceItem?.options && influenceItem?.options.length) {
      const foundIndexOfValue = influenceItem?.options.findIndex(it => it.score === returnVal)
      if (foundIndexOfValue > -1) {
        return influenceItem?.options[foundIndexOfValue]?.colorCode
      }
    }
    return false
  }
  return (
    <>
      <SnackBarComp
        showSnakbar={showSnakbar}
        handleCloseSnakbar={handleCloseSnakbar}
        snakbarMsg={snakbarMsg}
        type={msgType ? msgType : "success"}
      />
      <Box >
        {props.journeyData?.map((item: any, index: number) => {
          let noData = true;
          let iconObject: any = {}
          return (
            <div
              className={clsx(
                /* moment(props.month[props.month.length - 1]).format("MM-DD-yyyy") === props.headerDate ? classes.iconWithoutAfter : */(outcomeState?.outcomeList.length - 1) === index ? classes.iconLast : classes.icon,
                outcomeState?.isExpand ? classes.iconExpand : ""
              )}
              key={item._id}
              
            >
              <div className={moment(props.month[props.month.length - 1]).format("MM-DD-yyyy") === props.headerDate ? classes.iconInnerWithoutAfter : classes.iconInner} style={{
                display: "flex",
                alignItems: "center",
                justifyContent: outcomeState?.isExpand ? "flex-start" : "center",
                flexBasis: "100%"
              }} onClick={(e) => {
                setItem(item);
                props.setOutcomeId(item._id);
              }}>
                {showInput && item?._id === props?.outcomeId ? (
                  <span
                    className={classes.addOutlineIconInput}
                    onDoubleClick={() => {
                      if (clickCondition) {
                        let i = outcomeState.outcomeList.findIndex(
                          (outcome) => outcome._id === props?.outcomeId
                        );
                        setOutcomeIndex(i);
                        setShowInput(false);
                        setOpenTrackingEventModal(true);
                        setDisplayBorder(true);
                        props.setOutcomeId(item._id);
                        setItem(item);
                        setSelectedOption(parseInt(optionIndex + 1));
                      }
                    }}
                  >
                    <input
                      name={item?._id}
                      type="text"
                      autoFocus
                      onBlur={() => {
                        handleBlurInput(item?._id, index, props.headerDate, { key: "kdksjdks", keyCode: "hdj" }, true, props.categoryId);
                      }}
                      onChange={(e) => {
                        setInputValue(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        handleBlurInput(item?._id, index, props.headerDate, e, false, props.categoryId);
                      }}
                    />
                  </span>
                ) : (
                  <span
                    className={classes.addOutlineIcon}
                    onDoubleClick={() => {
                      if (clickCondition) {
                        let i = outcomeState.outcomeList.findIndex(
                          (outcome) => outcome._id === props?.outcomeId
                        );
                        setOutcomeIndex(i);
                        setShowInput(false);
                        setOpenTrackingEventModal(true);
                        setDisplayBorder(true);
                        props.setOutcomeId(item._id);
                        setItem(item);
                        setSelectedOption(parseInt(optionIndex + 1));
                      }
                    }}
                  >
                    {item.outcomesResponses?.map(
                      (journey: any, index: number) => {
                        if (
                          journey?.outcomeId === item?.outcomeId &&
                          journey?.date === props.headerDate
                        ) {
                          iconObject = journey
                          let nearValue = nearestValueOutcome(journey?.index, item)
                          if (journey?.type === "TYPE2") {
                            nearValue = null
                          }
                          if(Number(journey?.score) === 0) {
                            nearValue = 'grey'
                          }
                          noData = false;
                          return (
                            <div
                              className="hello"
                              key={index}
                              id={props.headerDate + "_" + item._id}
                              onClick={() => {
                                if (clickCondition) {
                                  setShowInput(true);
                                  setDisplayBorder(true);
                                  props.setOutcomeId(item._id);
                                  setItem(item);
                                }
                              }}
                            >
                              <span
                                className={
                                  journey?.note.length > 0
                                    ? classes.borderOutline
                                    : ""
                                }
                                style={
                                  journey?.note.length > 0
                                    ? {
                                      padding: "2px",
                                      border: `1px solid ${nearValue ? nearValue : journey.colorCode}`,
                                      borderRadius: "50%",
                                    }
                                    : {
                                      border: `1px solid ${nearValue ? nearValue : journey.colorCode}`,
                                      backgroundColor: journey.colorCode,
                                    }
                                }
                              >
                                <span
                                  style={{
                                    backgroundColor: nearValue ? nearValue : journey.colorCode,
                                    border: `1px solid ${nearValue ? nearValue : journey.colorCode}`,
                                    borderRadius: "50%",
                                    color: palette.common.white,
                                  }}
                                >
                                  {journey.index}
                                </span>
                              </span>
                            </div>
                          );
                        }
                      }
                    )}
                    {noData && (
                      <span
                        key={index}
                        id={props.headerDate + "_" + item._id}
                        onClick={() => {
                          if (clickCondition) {
                            setShowInput(true);
                            setDisplayBorder(true);
                            props.setOutcomeId(item._id);
                            setItem(item);
                          }
                        }}
                      >
                        +
                      </span>
                    )}
                  </span>
                )}
              </div>
              {outcomeState?.isExpand ? (
                <div style={{
                  display: 'flex',
                  width: '176px',
                  height: "144px",
                  padding: '12px',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: '12px',
                  alignSelf: 'stretch',
                  borderRadius: '12px',
                  border: '1px solid #F3F3F3',
                  background: '#FFF',
                  flexBasis: "100%",
                  margin: "13px 8px 0px 0",
                  overflowY: "auto",
                  wordWrap: 'break-word',
                }} onClick={() => {
                  // if(noData) {
                  //   if (clickCondition) {
                  //     setShowInput(true);
                  //     setDisplayBorder(true);
                  //     props.setOutcomeId(item._id);
                  //     setItem(item);
                  //   }
                  // } else {
                    if (clickCondition) {
                      setShowInput(false);
                      setOpenTrackingEventModal(true);
                      setDisplayBorder(true);
                      props.setOutcomeId(item._id);
                      setItem(item);
                      setSelectedOption(parseInt(optionIndex + 1));
                    }
                  // }
                }}>{!noData && iconObject?.note && iconObject?.note[0] && <Typography variant="h5" sx={{ color: palette.common.black}} component="p" title={iconObject?.note}>
                <span
                dangerouslySetInnerHTML={{
                  __html: iconObject?.note[0].replace(/\n/g, "<br />") || ""
                }}
                ></span>
              </Typography>}</div>
              ) : null}
              {/* <div className={clsx(
                 (((outcomeState?.reflectiveList && outcomeState?.reflectiveList.length -1) === index) || moment(props.month[props.month.length - 1]).format("MM-DD-yyyy") === props.headerDate ) ? null: classes.absoluteBorders,
                  outcomeState?.isExpand ? classes.absoluteExpand : ""
                )}></div> */}

              {/* SCALE BASE MODA */}
              {openTrackingEventModal && item._id === props.outcomeId && (
                <AddTrackingEventModal
                  setOpenTrackingEventModal={setOpenTrackingEventModal}
                  index={outcomeIndex}
                  setOutcomeId={props.setOutcomeId}
                  getBackgroudColor={getBackgroudColor}
                  setSelectedOption={setSelectedOption}
                  selectedOption={selectedOption}
                  setShowInput={setShowInput}
                  outcomeId={props.outcomeId}
                  item={item}
                  setShowSnakbar={setShowSnakbar}
                  setSnakbarMsg={setSnakbarMsg}
                  setMsgType={setMsgType}
                  setOptionIndex={setOptionIndex}
                  optionIndex={optionIndex}
                  setDisplayBorder={setDisplayBorder}
                  displayBorder={displayBorder}
                  callService={props?.callservice}
                  isEdit={isEdit}
                  setIsEdit={setIsEdit}
                  journeyData={props?.journeyData}
                  headerDate={props?.headerDate}
                />
              )}
            </div>
          );
        })}
      </Box>
    </>
  );
};

export default React.memo(IconPersonalOutcome);
