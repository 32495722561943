import {
  Button,
  Grid,
  MenuItem,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import BackIcon from "../../assets/images/icon/BackIcon";
import ShareIcon from "../../assets/images/icon/ShareIcon";
import { ReportsTypeContext } from "../../context/userIReports";
import { UserTypeContext } from "../../context/userStatus";
import { reportsService } from "../../services/reportsService";
import {
  setSelectedRow,
  setSelectedRowDetails,
} from "../../store/actions/reportTypeAction";
import { makeStyles } from "@material-ui/core";

import palette from "../../theme/palette";

const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "43px",
  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    marginBottom: "20px",
  },
}));

const useStyles = makeStyles((theme) => ({
  progressTitle: {
    color: palette.primary.light,
    lineHeight: "43.2px!important",
  },
  subHeading: {
    color: `${palette.secondary.contrast}!important`,
    fontSize: "16px !important",
    fontWeight: 300,
  },
  try: {
    color: "black",
    marginTop: "20px !important",
    fontSize: "2.25rem !important",
    fontWeight: 700,
  },
  subtry: {
    color: "#9BA19D",
    marginTop: "11px !important",
    fontSize: "1.25rem !important",
    fontWeight: 400,

    "& button": {
      margin: "0 24px",
      padding: "10px",
      fontSize: "1rem",
      color: "#3E4240",
      fontWeight: 400,
      gap: "4px",
      height: "40px",
    },
  },
}));

const ProgressTitleDetails = ({ setShowLoader, isFromShare = false, userData = {} as any }) => {
  const classes = useStyles();
  const { state: IUserTypeState, dispatch: userTypeDispatch } =
    useContext(UserTypeContext);
  const { state: IReportTypeContext, dispatch: reportTypeDispatch } =
    useContext(ReportsTypeContext);
  const handleClickGoBaack = (e: any) => {
    reportTypeDispatch(setSelectedRow({}));
    reportTypeDispatch(setSelectedRowDetails({}));
  };
  useEffect(() => {
    if (Object.keys(IReportTypeContext.selectedRow).length && !isFromShare) {
      getReportDetails();
    }
  }, [IReportTypeContext.selectedRow]);
  const getReportDetails = () => {
    setShowLoader(true);
    const userId = {
      ...IReportTypeContext.selectedRow,
      to: IReportTypeContext.selectedRow.till,
    };
    reportsService
      .getAllWeeksReport(userId, IUserTypeState.token)
      .then((data: any) => {
        reportTypeDispatch(setSelectedRowDetails(data));
      })
      .catch((error) => {
        console.log("User profile Error");
      })
      .finally(() => {
        setShowLoader(false);
      });
  };
  return (
    <RootStyle>
      <div>
        {isFromShare ? null : (
          <Typography
            variant="h6"
            component="h6"
            className={classes.subHeading}
          >
            <span
              style={{
                color: "#3E4240",
                marginRight: "30px",
                cursor: "pointer",
              }}
              onClick={handleClickGoBaack}
            >
              <BackIcon /> Back
            </span>{" "}
            {/* Progress / Report List /{" "}
          <span style={{ color: "#3E4240" }}>Week report #{IReportTypeContext.selectedRow.weekNumber}</span> */}
          </Typography>
        )}

        <Typography variant="h1" component="h1" className={classes.try}>
          {isFromShare
            ? "Weekly Report" + (userData && userData.first_name ? ` from ${userData.first_name}` : "")
            : `Week report #${IReportTypeContext.selectedRow.weekNumber}`}
        </Typography>
        <Typography variant="h5" component="h6" className={classes.subtry}>
          {moment(IReportTypeContext.selectedRow.from).format("MM/DD")} -{" "}
          {moment(IReportTypeContext.selectedRow.till).format("MM/DD")},{" "}
          {moment(IReportTypeContext.selectedRow.from).format("YYYY") ===
          moment(IReportTypeContext.selectedRow.till).format("YYYY")
            ? moment(IReportTypeContext.selectedRow.till).format("YYYY")
            : `${moment(IReportTypeContext.selectedRow.from)
                .format("YYYY")} - ${moment(IReportTypeContext.selectedRow.till)
                .format("YYYY")}`}
          {/* {isFromShare ? null : <Button
            variant="outlined"
            size="small"
          // sx={{ mt: pxToRem(40) }}
          // disabled={userStatus == ""}
          // onClick={updateUserLevel}
          >
            <ShareIcon /> Share
          </Button>} */}
        </Typography>
        {/* <div >
          <BackIcon />
        </div>
        <Typography variant="h1" component="h1" className={classes.pageTitle} sx={{ marginLeft: "20px", paddingBottom: '0px' }}>
          Archived metrics
        </Typography> */}
      </div>
    </RootStyle>
  );
};

export default ProgressTitleDetails;
