import {
  Autocomplete,
  Box,
  Grid,
  MenuItem,
  Paper,
  Rating,
  Select,
  styled,
  TextField,
  Typography,
} from "@mui/material";

import React, { useContext, useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import palette from "../../theme/palette";
import typography, { pxToRem } from "../../theme/typography";
import { Card, Chip, makeStyles } from "@material-ui/core";

import ArrowNextIcon from "../../assets/images/icon/arrow_next";
import { InsightTypeContext } from "../../context/userInsights";
import { insightsService } from "../../services/insightsService";
import { UserTypeContext } from "../../context/userStatus";
import NewButton from "../../components/NewButton";
import ArrowDownNew, { ArrowUpNew } from "../../assets/images/icon/ArrowDownGreenNew";
import ArrowUp from "../../assets/images/icon/arrow_up";
const useStyles = makeStyles((theme) => ({
  mainTitleFlex: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  insightsTable: {
    display: "table",
    width: "100%",
  },
  insightsTableRow: {
    display: "table-row",
  },
  insightsRegression: {
    display: "table-cell",
    width: "75px",
    verticalAlign: "middle",
    padding: "5px 0",
  },
  insightsRegressionCell: {
    display: "flex",
    alignItems: "center",
    verticalAlign: "middle",
    justifyContent: "flex-end",
    background: '#F17F7933',
    borderRadius: "8px 0px 0px 8px",
  },
  insightsRegressionCellTitle: {
    display: "flex",
    alignItems: "center",
    verticalAlign: "middle",
    justifyContent: "flex-end",
    fontWeight: 400,
    fontSize: "16px",

    color: "#9BA19D",
  },
  insightsInfluences: {
    display: "table-cell",
    width: "50%",
    padding: "5px 0",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "100%",
    alignItems: "center",
    textAlign: "center",
    color: "#3E4240",
    verticalAlign: "middle",
  },
  insightsInfluencesTitle: {
    display: "flex",
    alignItems: "center",
    verticalAlign: "middle",
    justifyContent: "center",
    fontWeight: 400,
    fontSize: "16px",

    color: "#9BA19D",
  },
  insightsCorrelation: {
    display: "table-cell",
    width: "62px",
    verticalAlign: "middle",
    padding: "5px 0",
  },
  insightsCorrelationCell: {
    display: "flex",
    alignItems: "center",
    verticalAlign: "middle",
    justifyContent: "flex-start",
    background: '#ADE8884D',
    borderRadius: "0px 8px 8px 0px",

  },
  insightsCorrelationCellTitle: {
    display: "flex",
    alignItems: "center",
    verticalAlign: "middle",
    justifyContent: "flex-start",
    fontWeight: 400,
    fontSize: "16px",

    color: "#9BA19D",
  },
  insightsRegressionPositive: {
    height: "5px",

    /* GraphicColors/DarkSky */

    background: "#4CB3FD",
    borderRadius: "8px 0px 0px 8px",
    marginLeft: "20px",
    width: "100%",
  },
  insightsRegressionPositiveText: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "100%",
    /* identical to box height, or 16px */

    display: "flex",
    alignItems: "center",
    textAlign: "right",

    /* GraphicColors/DarkSky */

    color: "#4CB3FD",
  },
  insightsRegressionNegative: {
    height: "10px",

    /* GraphicColors/DarkSky */

    background: '#F17F79',
    borderRadius: "8px 0px 0px 8px",
    marginLeft: "20px",
    width: "100%",
  },
  insightsRegressionNegativeText: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "100%",
    /* identical to box height, or 16px */

    display: "flex",
    alignItems: "center",
    textAlign: "right",

    /* GraphicColors/DarkSky */

    color: "#7572FC",
  },
  insightsCorrelationPositive: {
    height: "5px",

    /* GraphicColors/DarkSky */

    background: "#169C63",
    borderRadius: "0px 8px 8px 0px",
    marginRight: "20px",
    width: "100%",
  },
  insightsCorrelationNegative: {
    height: "10px",

    /* GraphicColors/DarkSky */

    background: '#ADE888',
    borderRadius: "0px 8px 8px 0px",
    width: "100%",
  },
  insightsCorrelationPositiveText: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "100%",
    /* identical to box height, or 16px */

    display: "flex",
    alignItems: "center",
    textAlign: "right",

    /* GraphicColors/DarkSky */

    color: "#169C63",
  },
  insightsCorrelationNegativeText: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "100%",
    /* identical to box height, or 16px */

    display: "flex",
    alignItems: "center",
    textAlign: "right",

    /* GraphicColors/DarkSky */

    color: "#F17F79",
  },
  dropDownDiv: {
    display: "flex",
  },
}));

const CorrelationsInsights = ({ selectOutcome, outcomeData }) => {
  const classes = useStyles();
  const { state: InsightState, dispatch: insightTypeDispatch } =
    useContext(InsightTypeContext);
  const { state: IUserTypeState, dispatch: userTypeDispatch } =
    useContext(UserTypeContext);
  const [open, setOpen] = useState(false);
  const [openTow, setOpenTow] = useState(false);
  const [searchOnLeft, setSearchOnLeft] = useState(null);
  const [leftSideMenu, setLeftSideMenu] = useState(InsightState.leftMenu);
  const [message, setMessage] = useState(null);
  const [selectedLeft, setSelectedLeft] = useState(null);
  const [correlationData, setCorrelationData] = useState(null);
  const [correlationStar, setCorrelationStar] = useState(0);
  const [selectedRight, setSelectedRight] = useState(null);
  const [selectedLeftEleemnt, setSelectedLeftEleemnt] = useState(null);
  const [selectedRightEleemnt, setSelectedRightEleemnt] = useState(null);
  const influenceData = [];
  if (selectOutcome) {
    const found = outcomeData.find((it) => it.outcomeId === selectOutcome);
    if (found && found.influence) {
      if (found.influence && found.influence.length) {
        found.influence.map((it) => {
          influenceData.push({
            regression: it?.regression?.slope,
            influences: it?.name,
            correlation: it?.correlation,
          });
          return it;
        });
      }
    }
  }

  const getCorrelations = () => {
    const userId = {
      from: "10-08-2021",
      to: "10-08-2023",
      idOne: selectedLeft.outcomeId || selectedLeft.influenceId,
      idSecond: selectedRight.outcomeId || selectedRight.influenceId,
      typeOne: selectedLeftEleemnt.isOutcome ? "OUTCOME" : "INFLUENCE",
      typeTwo: selectedRightEleemnt.isOutcome
        ? "OUTCOME"
        : "INFLUENCE" +
        selectedRightEleemnt.type +
        (selectedRightEleemnt.inverse === "true" ? "INVERSE" : ""),
    };
    insightsService
      .getCorrelations(userId, IUserTypeState.token)
      .then((data: any) => {
        setCorrelationData(data?.data);
        setCorrelationStar(data?.star);
        setMessage(data.message)
      })
      .catch((error) => {
        console.log("User profile Error", error);
      });
  };
  const setEls = () => {
    if (selectedLeft && selectedRight) {
      const allArray = [...InsightState.rightMenu];
      if (allArray && allArray.length) {
        const leftEl = allArray.filter(it => {
          return it.allIds.includes(selectedLeft.outcomeId || selectedLeft.influenceId)

        })
        if (leftEl && leftEl[0]) {
          if (selectedLeft.outcomeId) {
            const leftArray = leftEl[0].alls.filter(
              (it) => it.outcomeId === selectedLeft.outcomeId
            );
            if (leftArray && leftArray.length) {
              setSelectedLeftEleemnt({
                ...leftArray[0],
                isOutcome: true
              });
            }
          }
          if (selectedLeft.influenceId) {
            const leftArrayInflue = leftEl[0].alls.filter(
              (it) => it.influenceId === selectedLeft.influenceId
            );
            if (leftArrayInflue && leftArrayInflue.length) {
              setSelectedLeftEleemnt({
                ...leftArrayInflue[0],
                isOutcome: false
              });
            }
          }
        }
        const rightEl = allArray.filter(it => {
          return it.allIds.includes(selectedRight.outcomeId || selectedRight.influenceId)

        })
        if (rightEl && rightEl[0]) {
          if (selectedRight.outcomeId) {
            const rightArray = rightEl[0].alls.filter(
              (it) => it.outcomeId === selectedRight.outcomeId
            );
            if (rightArray && rightArray.length) {
              setSelectedRightEleemnt({
                ...rightArray[0],
                isOutcome: true,
              });
            }
          }
          if (selectedRight.influenceId) {
            const rightArrayInflue = rightEl[0].alls.filter(
              (it) => it.influenceId === selectedRight.influenceId
            );
            if (rightArrayInflue && rightArrayInflue.length) {
              setSelectedRightEleemnt({
                ...rightArrayInflue[0],
                isOutcome: false
              });
            }
          }
        }
      }
    }
  }
  useEffect(() => {
    setEls()
  }, [selectedLeft, selectedRight]);

  useEffect(() => {
    if (selectedLeftEleemnt && selectedRightEleemnt) {
      getCorrelations();
    }
  }, [selectedLeftEleemnt, selectedRightEleemnt]);
  const defaultProps = {
    options: InsightState.leftMenu && InsightState.leftMenu.length ? InsightState.leftMenu.sort(function (a, b) {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    }).flatMap(category =>
      category.alls.map(child => ({
        category: category.name,
        ...child,
        disabled: selectedRight && selectedRight.outcomeId && child.outcomeId ? selectedRight.outcomeId === child.outcomeId : selectedRight && selectedRight.influenceId && child.influenceId ? selectedRight.influenceId === child.influenceId : false
      }))
    ) : [],
    getOptionLabel: (option) => option.name,
  };
  const defaultPropsRight = {
    options: InsightState.rightMenu.sort(function (a, b) {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      return 0;
    }).flatMap(category =>
      category.alls.map(child => ({
        category: category.name,
        ...child,
        disabled: selectedLeft && selectedLeft.outcomeId && child.outcomeId ? selectedLeft.outcomeId === child.outcomeId : selectedLeft && selectedLeft.influenceId && child.influenceId ? selectedLeft.influenceId === child.influenceId : false
      }))
    ),
    getOptionLabel: (option) => option.name,
  };
  return (
    <>
      <div className={classes.mainTitleFlex}>
        <Box display={"flex"} gap={"28px"} alignItems={"center"} justifyContent={"space-between"} width={"100%"}>
          <Box display={"flex"} gap={"8px"} flexDirection={"column"}>
            <Typography
              variant="h3"
              letterSpacing={"0.02em"}
              sx={{
                color: palette.primary.light,
                fontSize: `${pxToRem(18)} !important`,
                lineHeight: "100% !important",
                // textAlign: "center !important",
                fontWeight: `600 !important`,
                "&.MuiTypography-root": {
                  "@media  (max-width: 600px) and (min-width: 100px)": {
                    fontSize: `${pxToRem(18)} !important`,
                    lineHeight: "100% !important",
                  },
                },
              }}
            >
              Comparision
            </Typography>
            <Typography
              variant="h5"
              letterSpacing={"0.02em"}
              sx={{
                color: palette.primary.light,
                fontSize: `${pxToRem(16)} !important`,
                lineHeight: "100% !important",
                // textAlign: "center !important",
                marginBottom: 0,
                fontWeight: `400 !important`,
                "&.MuiTypography-root": {
                  "@media  (max-width: 600px) and (min-width: 100px)": {
                    fontSize: `${pxToRem(16)} !important`,
                    lineHeight: "100% !important",
                  },
                },
              }}
            >
              Select two attributes to find the correlation between them.
            </Typography>
          </Box>
          <div className={classes.dropDownDiv}>
            <Grid
              display="flex"
              alignItems="center"
              flexWrap="wrap"
            >
              <Autocomplete
                className="fillTextInput weekSelect"
                fullWidth
                value={selectedLeft}
                sx={{
                  position: "relative",
                  "& .MuiOutlinedInput-root": {
                    padding: 0,
                  },
                }}
                renderGroup={(params) => {
                  return (
                    <li key={params.key}>
                      <Box sx={{ padding: '10px 10px' }}>
                        <Typography variant="subtitle1" sx={{
                          color: palette.primary.light,
                          fontSize: `${pxToRem(16)} !important`,
                          lineHeight: "120% !important",
                          cursor: "pointer",
                          // textAlign: "center !important",
                          fontWeight: `700 !important`,
                          "&.MuiTypography-root": {
                            "@media  (max-width: 600px) and (min-width: 100px)": {
                              fontSize: `${pxToRem(16)} !important`,
                              lineHeight: "100% !important",
                            },
                          },
                        }}>{params.group}</Typography>
                      </Box>
                      <Box sx={{ padding: '0 0px' }}>
                        {params.children}
                      </Box>
                    </li>
                  )
                }}
                groupBy={(option) => option.category}
                getOptionDisabled={(option) => option.disabled}
                autoComplete
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                popupIcon={<ArrowUpNew color="var(--color-new-main)" />}
                PaperComponent={({ children }) => (
                  <Paper
                    style={{ background: "#FFF" }}
                    sx={{
                      "&.MuiAutocomplete-option": {
                        color: "rgb(0, 0, 0)",
                        fontSize: "18px",
                      },
                      "& .MuiAutocomplete-listbox": {
                        padding: "0px",
                        "& li": {
                          padding: `10px`,
                          color: "#5E5F5E !important",
                          fontSize: "16px !important",
                          fontWeight: "400 !important",
                          lineHeight: "24px",
                        },
                      },
                    }}
                  >
                    {children}
                  </Paper>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      "&.MuiOutlinedInput-root": {
                        padding: 0,
                      },
                    }}
                    className="fillTextInput weekSelect"
                    label="Select one"
                  />
                )}
                onChange={(e, newInputValue) => {
                  console.log("newInputValue", newInputValue);

                  setSelectedLeft(newInputValue);
                }}
                {...defaultProps}
              />
            </Grid>
            <Typography
              variant="h5"
              letterSpacing={"0.02em"}
              sx={{
                color: palette.common.black,
                margin: "auto 12px",
                fontSize: `${pxToRem(16)} !important`,
                lineHeight: "150% !important",
                // textAlign: "center !important",
                fontWeight: `600 !important`,
                "&.MuiTypography-root": {
                  "@media  (max-width: 600px) and (min-width: 100px)": {
                    fontSize: `${pxToRem(16)} !important`,
                    lineHeight: "150% !important",
                  },
                },
              }}
            >
              and
            </Typography>
            <Grid
              display="flex"
              alignItems="center"
              flexWrap="wrap"
              marginTop="3px"
            >
              <Autocomplete
                className="fillTextInput weekSelect"
                fullWidth
                value={selectedRight}
                sx={{
                  position: "relative",
                  "& .MuiOutlinedInput-root": {
                    padding: 0,
                  },
                }}
                getOptionDisabled={(option) => option.disabled}
                renderGroup={(params) => {
                  return (
                    <li key={params.key}>
                      <Box sx={{ padding: '10px 10px' }}>
                        <Typography variant="subtitle1" sx={{
                          color: palette.primary.light,
                          fontSize: `${pxToRem(16)} !important`,
                          lineHeight: "120% !important",
                          cursor: "pointer",
                          // textAlign: "center !important",
                          fontWeight: `700 !important`,
                          "&.MuiTypography-root": {
                            "@media  (max-width: 600px) and (min-width: 100px)": {
                              fontSize: `${pxToRem(16)} !important`,
                              lineHeight: "100% !important",
                            },
                          },
                        }}>{params.group}</Typography>
                      </Box>
                      <Box sx={{ padding: '0 0px' }}>
                        {params.children}
                      </Box>
                    </li>
                  )
                }}
                groupBy={(option) => option.category}
                autoComplete
                onOpen={() => setOpenTow(true)}
                onClose={() => setOpenTow(false)}
                popupIcon={<ArrowUpNew color="var(--color-new-main)" />}
                PaperComponent={({ children }) => (
                  <Paper
                    style={{ background: "#FFF" }}
                    sx={{
                      "&.MuiAutocomplete-option": {
                        color: "rgb(0, 0, 0)",
                        fontSize: "18px",
                      },
                      "& .MuiAutocomplete-listbox": {
                        padding: "0px",
                        "& li": {
                          padding: `10px`,
                          color: "#5E5F5E !important",
                          fontSize: "16px !important",
                          fontWeight: "400 !important",
                          lineHeight: "24px",
                        },
                      },
                    }}
                  >
                    {children}
                  </Paper>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{
                      "&.MuiOutlinedInput-root": {
                        padding: 0,
                      },
                    }}
                    className="fillTextInput weekSelect"
                    label="Select one"
                  />
                )}
                onChange={(e, newInputValue) => {
                  setSelectedRight(newInputValue);
                }}
                {...defaultPropsRight}
              />
            </Grid>
          </div>
        </Box>


      </div>


      {/* <div style={{
        display: "flex",
        alignItems: "center",
        justifyContent: 'flex-end',
        marginBottom: "5px"
      }}>
        <NewButton buttonText={"Find a correlation"}
          padding={"10px 27px"}
          variant="contained"
          onClick={setEls}
          textColor={palette.common.black} />
      </div> */}

      {selectedLeftEleemnt && selectedRightEleemnt && (
        <Grid>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              padding: "0px",
              gap: "16px",
              width: "100%",
              flex: "none",
              marginTop: "34px",
              order: 1,
              alignSelf: "stretch",
              flexGrow: 0,
            }}
          >
            <div
              style={{
                boxSizing: "border-box",

                /* Auto layout */

                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                padding: "24px",
                gap: "16px",

                width: "100%",

                border: "1px solid #EFEFEF",
                borderRadius: "12px",

                /* Inside auto layout */

                flex: "none",
                order: 0,
                alignSelf: "stretch",
                flexGrow: 0,
              }}
            >
              <Typography
                variant="h5"
                letterSpacing={"0.02em"}
                // sx={{ marginY: "10px", color: "#3E4240", fontWeight: 500 }}
                sx={{
                  color: palette.primary.light,
                  fontSize: `${pxToRem(16)} !important`,
                  lineHeight: `${pxToRem(16)} !important`,
                  // textAlign: "center !important",
                  marginBottom: 0,
                  fontWeight: `500 !important`,
                  "&.MuiTypography-root": {
                    "@media  (max-width: 600px) and (min-width: 100px)": {
                      fontSize: `${pxToRem(16)} !important`,
                      lineHeight: `${pxToRem(16)} !important`,
                    },
                  },
                }}
              >
                {message}
              </Typography>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  alignItems: "center",
                  padding: "0px",
                  gap: "16px",
                  flex: "none",
                  order: 0,
                  flexGrow: 0,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0px",
                    gap: "16px",

                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      padding: "0px",
                      gap: "16px",


                      /* Inside auto layout */

                      order: 0,
                      flexGrow: 0,
                    }}
                  >
                    {selectedLeftEleemnt && selectedRightEleemnt && (
                      <>
                        <Chip
                          label={
                            <Typography
                              variant="h5"
                              letterSpacing={"0.02em"}
                              sx={{
                                color: "#3E4240",
                                fontWeight: 400,
                                margin: "auto 15px",
                                lineHeight: '130% !important',
                                fontSize: `${pxToRem(14)} !important`
                              }}
                            >
                              {selectedLeftEleemnt.name.slice(0, 20)}
                              {selectedLeftEleemnt.name.length > 21 && "...."}
                            </Typography>
                          }
                          variant="outlined"
                          style={{
                            borderColor: "#B8E29E"
                          }}
                        />
                        <Typography
                          variant="h5"
                          letterSpacing={"0.02em"}
                          sx={{
                            color: "#3E4240",
                            fontWeight: 500,
                            margin: "auto 15px",
                            fontSize: `${pxToRem(16)} !important`
                          }}
                        >
                          and
                        </Typography>
                        <Chip
                          label={
                            <Typography
                              variant="h5"
                              letterSpacing={"0.02em"}
                              sx={{
                                color: "#3E4240",
                                fontWeight: 400,
                                margin: "auto 15px",
                                lineHeight: '130% !important',
                                fontSize: `${pxToRem(14)} !important`
                              }}
                            >
                              {selectedRightEleemnt.name.slice(0, 20)}
                              {selectedRightEleemnt.name.length > 21 && "...."}
                            </Typography>
                          }
                          variant="outlined"
                          style={{
                            borderColor: "#D0B3F5",
                          }}
                        />
                      </>
                    )}
                  </div>
                  {correlationStar > 0 ? (
                    <div
                      style={{
                        /* Auto layout */

                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        padding: "0px",
                        // width: '50%',
                        gap: "32px",
                        /* Inside auto layout */

                        order: 1,
                        flexGrow: 0,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          alignItems: "flex-end",
                          padding: "0px",
                          gap: "8px",

                          width: correlationStar && correlationStar > 0 ? "50%" : "100%",
                          height: "35.06px",

                          /* Inside auto layout */

                          flex: "none",
                          order: 0,
                          flexGrow: 0,
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            padding: "0px",
                            gap: "4px",
                            justifyContent: "flex-end",
                            width: "100%",
                            height: "21px",
                          }}
                        >
                          <Typography
                            variant="h5"
                            letterSpacing={"0.02em"}
                            sx={{
                              marginY: "10px",
                              color: correlationData > 0 ?  "var(--color-new-main)" : "#F17F79",
                              fontWeight: 700,
                              fontSize: `${pxToRem(16)} !important`,
                              lineHeight: "100%  !important",
                            }}
                          >
                            {correlationData ? (correlationData > 0 ? "+" : "") + parseFloat(correlationData).toFixed(2).replace("0.", ".") : "N/A"}
                          </Typography>
                          <Typography
                            variant="h5"
                            letterSpacing={"0.02em"}
                            // sx={{
                            //   marginY: "10px",
                            //   color: "#3E4240",
                            //   fontWeight: 500,
                            // }}
                            sx={{
                              color: palette.common.black,
                              fontSize: `${pxToRem(14)} !important`,
                              lineHeight: `${pxToRem(21)} !important`,
                              // textAlign: "center !important",
                              marginBottom: 0,
                              fontWeight: `500 !important`,
                              "&.MuiTypography-root": {
                                "@media  (max-width: 600px) and (min-width: 100px)": {
                                  fontSize: `${pxToRem(14)} !important`,
                                  lineHeight: `${pxToRem(21)} !important`,
                                },
                              },
                            }}
                          >
                            Correlation
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: "table",
                            flexDirection: "row",
                            alignItems: "center",
                            padding: "0px",
                            gap: "4px",

                            width: "150px",
                            height: "21px",
                          }}
                        >
                          <div className={classes.insightsTableRow}>
                            <div className={classes.insightsRegression}>
                              <div className={classes.insightsRegressionCell}>
                                <div
                                  className={classes.insightsRegressionNegative}
                                  style={{
                                    width: `${correlationData < 0 ? Math.abs(correlationData) * 100 : 0}%`,
                                  }}
                                ></div>
                              </div>
                            </div>
                            <div className={classes.insightsCorrelation}>
                              <div className={classes.insightsCorrelationCell}>
                                <div
                                  className={
                                    classes.insightsCorrelationNegative
                                  }
                                  style={{
                                    width: `${correlationData > 0 ? correlationData * 100 : 0}%`,
                                  }}
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {correlationStar && correlationStar > 0 ? (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            alignItems: "flex-end",
                            padding: "0px",
                            gap: "4px",
                            width: "50%",

                            /* Inside auto layout */

                            order: 0,
                            flexGrow: 0,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              padding: "0px",
                              gap: "4px",
                              justifyContent: "flex-end",

                              width: "100%",
                              height: "21px",
                            }}
                          >
                            <Typography
                              variant="h5"
                              letterSpacing={"0.02em"}
                              // sx={{
                              //   marginY: "10px",
                              //   color: "#3E4240",
                              //   fontWeight: 500,
                              // }}
                              sx={{
                                color: palette.common.black,
                                fontSize: `${pxToRem(14)} !important`,
                                lineHeight: `${pxToRem(21)} !important`,
                                // textAlign: "center !important",
                                marginBottom: 0,
                                fontWeight: `500 !important`,
                                "&.MuiTypography-root": {
                                  "@media  (max-width: 600px) and (min-width: 100px)": {
                                    fontSize: `${pxToRem(14)} !important`,
                                    lineHeight: `${pxToRem(21)} !important`,
                                  },
                                },
                              }}
                            >
                              Certainty
                            </Typography>
                          </div>
                          <div
                            style={{
                              display: "table",
                              flexDirection: "row",
                              alignItems: "center",
                              padding: "0px",
                              gap: "4px",

                              width: "100%",
                              height: "21px",
                            }}
                          >
                            <div className={classes.insightsTableRow}>
                              <div className={classes.insightsRegression}>
                                <div className={classes.insightsRegressionCell} style={{
                                  background: "transparent"
                                }}>
                                
                                  <Rating
                                    name="read-only"
                                    value={correlationStar}
                                    readOnly
                                    color="#FFEF5A"
                                    sx={{
                                      color: "#FFEF5A",
                                      "&.MuiRating-root": {

                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null}

                    </div>
                  ) : (
                    <Typography
                      variant="h5"
                      letterSpacing={"0.02em"}
                      sx={{
                        marginY: "10px",
                        color: "#3E4240",
                        fontWeight: 600,
                      }}
                    >
                      {"No Correlation found"}
                    </Typography>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Grid>
      )}
    </>
  );
};
export default CorrelationsInsights;
