import React, { useContext } from "react"
import { Dialog, Grid, Popover } from "@mui/material";
import NotificationPopover from "./NotificationPopover";
import { UserNotificationContext } from "../../context/userNotification";
import NotificationPopoverMobile from "./NotificationPopoverMobile";
import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => {
    return {
        modal: {
            '&  .MuiDialog-paper': {
                padding: '16px !important',
                background: '#FFF !important'
            }
        },
    }
})
const HeaderPopoverMobile = (
    {
        openStartModal,
        setOpenNOtificationModal,
        notifications, setNotifications,
        unreadNotifications, setUnreadNotifications
    }
) => {
    const classes = useStyles()
    const { state: IContactTypeState, dispatch: contactsDispatch } =
        useContext(UserNotificationContext);
    const { anchorEl, yposition,
        xposition } = IContactTypeState
    return (
        <Grid>
            <Dialog
                // onClose={handleCloseStartModal}
                open={openStartModal}
                className={`${classes.modal} dialogWrapper dialogWrapper-mobileScreen`}
                fullWidth
                onClose={setOpenNOtificationModal}
            >
                <NotificationPopoverMobile notifications={notifications} setNotifications={setNotifications}
                    unreadNotifications={unreadNotifications} setUnreadNotifications={setUnreadNotifications} />
            </Dialog>
        </Grid>
    )
}

export default HeaderPopoverMobile