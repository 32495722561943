/*eslint array-callback-return: "off"*/
import React, { useContext } from "react";
import palette from "../../theme/palette";
import { Grid, IconButton, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import shape from "../../theme/shape";
import { UserTypeContext } from "../../context/userStatus";
import ModalCloseIconBlack from "../../assets/images/icon/modalCloseIconBlack";

const useStyles = makeStyles((theme) => ({
  drawerWrapper: {
    padding: "0",
    height: "100%",
  },
  closeButton: {
    width: '20px',
    height: '20px',
    position: 'absolute',
    right: '24px',
    top: '24px',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0
  },
  ideasList: {
    marginTop: "40px",
    [theme.breakpoints.down("lg")]: {
      marginTop: "30px",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "20px",
    },
  },
  ideasListItem: {
    backgroundColor: palette.common.white,
    borderRadius: "12px",
    padding: "16px",
    fontSize: "1rem",
    lineHeight: "100%",
    gap: "8px",
    color: palette.common.black,
    fontWeight: 400,
    marginBottom: "12px",
    cursor: "pointer",
    border: "1px solid #EFEFEF",
    [theme.breakpoints.down("md")]: {
      padding: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
  },
}));

interface RightSideInfluenceTrackingModal {
  reflectiveIdeas: any;
  reflectiveIdeasData?: any
  setQuestionData: (questionData: any) => void;
  setOpenRightSideSuggestion?: (questionData: any) => void;
}

const RightSideRefQuesTracking = (props: RightSideInfluenceTrackingModal) => {
  const classes = useStyles();
  const { state: outcomeState } = useContext(UserTypeContext);

  return (
    <Grid
    // sx={{ backgroundColor: palette.primary.main }}
    // className={classes.drawerWrapper}
    >
      <Grid display={"flex"} alignItems={"flex-start"} justifyContent={"space-between"} gap={4}>

        <Typography
          variant="h3"
          component="h3"
          sx={{
            color: palette.common.black,
            fontWeight: 600,
            fontSize: "1.125rem !important",
            lineHeight: "120%!important",
          }}
        >
          Here are some ideas of journaling questions you might want to consider
        </Typography>
        <span
          onClick={() => props.setOpenRightSideSuggestion(false)}
          style={{
            cursor: "pointer"
          }}
        >
          <ModalCloseIconBlack />
        </span>
      </Grid>
      <Grid className={classes.ideasList} sx={{ width: "100%" }}>
        {props?.reflectiveIdeas?.map((ideas: any) => {
          let flag = true;
          if (outcomeState?.reflectiveList?.length > 0)
            for (let outObj of outcomeState?.reflectiveList) {
              if (
                outObj.question.toLowerCase() === ideas.question.toLowerCase()
              ) {
                flag = false;
                break;
              }
            }
          if (flag) {
            return (
              <div
                className={classes.ideasListItem}
                key={ideas._id}
                onClick={() => props.setQuestionData(ideas)}
              >
                {ideas.question}
              </div>
            );
          }
        })}
        {props?.reflectiveIdeasData?.map((ideas: any) => {
          let flag = true;
          if (outcomeState?.reflectiveList?.length > 0)
            for (let outObj of outcomeState?.reflectiveList) {
              if (
                outObj.question.toLowerCase() === ideas.question.toLowerCase()
              ) {
                flag = false;
                break;
              }
            }
          if (flag) {
            return (
              <div
                className={classes.ideasListItem}
                key={ideas._id}
                onClick={() => props.setQuestionData(ideas)}
              >
                {ideas.question}
              </div>
            );
          }
        })}
      </Grid>
    </Grid>
  );
};
export default RightSideRefQuesTracking;
