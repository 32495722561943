import { Grid, MenuItem, styled, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";

import palette from "../../theme/palette";
import BackIcon from "../../assets/images/icon/BackIcon";
import { useNavigate } from "react-router-dom";

const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "43px",
  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    marginBottom: "20px",
  },
}));

const useStyles = makeStyles((theme) => ({
  progressTitle: {
    color: palette.primary.light,
    lineHeight: "43.2px!important",
  },
  subHeading: {
    color: `${palette.secondary.contrast}!important`,
    fontSize: "16px !important",
    fontWeight: 300,
  },
}));

const ProgressTitle = () => {
  const classes = useStyles();
  const [week, setWeek] = useState("This Week");

  const handleChangeWeek = (e: any) => {
    setWeek(e.target.value);
  };
  const navigate = useNavigate();
  return (
    <RootStyle>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        
        <Typography variant="h1" className={classes.progressTitle}>
          Your weekly reports
        </Typography>
      </div>

      {/* <Grid display="flex" alignItems="center" flexWrap="wrap" marginTop="3px">
        <TextField
          className="fillTextInput weekSelect"
          fullWidth
          select
          value={week}
          onChange={(e) => handleChangeWeek(e)}
        >
          <MenuItem value={"Last Week"}>Last Week</MenuItem>
          <MenuItem value={"This Week"}>This Week</MenuItem>
          <MenuItem value={"Next Week"}>Next Week</MenuItem>
        </TextField>
      </Grid> */}
    </RootStyle>
  );
};

export default ProgressTitle;
