import React, { useContext } from "react";
import { Typography, Box } from "@mui/material";
import InfoIcon from "../../assets/images/icon/infoIconYellow";
import { makeStyles } from "@material-ui/core";
import AddInfluenceLabel from "./addInfluenceLabel";
import shape from "../../theme/shape";
import { UserTypeContext } from "../../context/userStatus";
import useWidth from "../../utils/useWidth";

const useStyles = makeStyles((theme) => ({
  infoWrapper: {
    backgroundColor: "#FFF4E3",
    border: "1px solid #FF9B37",
    borderRadius: shape.borderRadius,
    padding: "18px 20px",
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
      marginBottom: "10px",
    },
  },
  infoText: {
    color: "#FF9B37",
    fontSize: "15px",
    lineHeight: "15px",
    fontWeight: 400,
    marginLeft: "12px",
  },
  infoWarning: {
    fontSize: "12px",
    lineHeight: "12px",
    fontWeight: 400,
    color: "#9BA19D",
    textAlign: "center",
  },
}));

interface NoInfluenceFoundModal {
  setInfluenceData: (influenceData: string) => void;
}

const NoInfluenceFound = (props: NoInfluenceFoundModal) => {
  const classes = useStyles();
  const width = useWidth();
  const { state: outcomeState } = useContext(UserTypeContext);
  return (
    <>
    <div
    style={{ visibility: width < 768 ? "hidden" : "visible"}}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{ mb: "20px" }}
      >
        <Typography variant="h3" component="h3">
          Your BIG 5 Influences
        </Typography>
        <Typography variant="caption" component="div">
          {outcomeState?.influenceList?.length} of 5 Added
        </Typography>
      </Box>
      {/* INFLUENCE FOUND */}
      {outcomeState?.influenceList &&
      outcomeState?.influenceList?.length > 0 ? (
        outcomeState?.influenceList?.map(
          (influenceItem: any, index: number) => {
            return (
              <AddInfluenceLabel
                influenceItem={influenceItem}
                key={index}
                setInfluenceData={props.setInfluenceData}
              />
            );
          }
        )
      ) : (
        // INFLUENCE NOT FOUND
        <Box display="flex" alignItems="center" className={classes.infoWrapper}>
          <InfoIcon />
          <span className={classes.infoText}>
            Please add at least one Influence you want to track
          </span>
        </Box>
      )}
      <p className={classes.infoWarning}>
        *More influences can be added after the setup
      </p>
      </div>
    </>
  );
};
export default NoInfluenceFound;
