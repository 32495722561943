/*eslint array-callback-return: "off"*/
import { Box, Typography } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core";
import palette from "../../theme/palette";
import { styled } from "@mui/material/styles";
import shape from "../../theme/shape";
import { UserTypeContext } from "../../context/userStatus";
import { influenceService } from "../../services/influence";
import { pxToRem } from "../../theme/typography";

const RootStyle = styled("div")(({ theme }) => ({
  padding: "0 0 60px 0",
  [theme.breakpoints.down("lg")]: {
    padding: "0 0 50px 0",
  },
  [theme.breakpoints.down("md")]: {
    padding: "0 0 40px 0",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "0 0 20px 0",
    marginBottom: "20px",
  },
}));

const useStyles = makeStyles((theme) => ({
  recommText: {
    backgroundColor: palette.common.white,
    borderRadius: shape.borderRadius,
    fontSize: "16px",
    lineHeight: "36px",
    color: palette.primary.light,
    padding: "12px 16px",
    cursor: "pointer",
    marginBottom: "12px",
    wordBreak: "break-word",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
      lineHeight: "20px",
    },
  },
  infoWarning: {
    fontSize: "12px",
    lineHeight: "12px",
    fontWeight: 400,
    color: "#9BA19D",
    textAlign: "center",
    marginTop: "8px",
  },
  recommTitle: {
    marginBottom: "46px",
    [theme.breakpoints.down("md")]: {
      marginBottom: "36px",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "20px",
    },
  },
  ideasListItem: {
    display: 'flex',
    padding: '16px',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch',
    borderRadius: '12px',
    border: '1px solid #EFEFEF',
    background: '#FFF',
    cursor: "pointer",

    // backgroundColor: palette.common.white,
    // borderRadius: shape.borderRadius,
    // padding: "24px",
    // fontSize: "16px",
    // lineHeight: "16px",
    // color: palette.primary.light,
    // fontWeight: 400,
    // marginBottom: "12px",
    // cursor: "pointer",
  },
}));

interface RecommendedListModal {
  setInfluenceData: (influenceData: string) => void;
  setInfluenceIdeas: (influenceIdeas: any) => void;
}

const RecommendedListNew = (props: RecommendedListModal) => {
  const classes = useStyles();
  const [influenceSuggestionList, setInfluenceSuggestionList] = useState([]);
  const [influenceSuggestionDataList, setInfluenceSuggestionDataList] = useState([]);
  const { state: outcomeState } = useContext(UserTypeContext);
  useEffect(() => {
    // GET INFLUENCE SUGGESTION
    influenceService
      .getInfluenceSuggestion(outcomeState.token)
      .then((res: any) => {
        setInfluenceSuggestionList(res?.data);
        setInfluenceSuggestionDataList(res?.ideas);
        props.setInfluenceIdeas(res?.ideas);
      })
      .catch((error: any) => {
        console.log("influence suggestion error", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>

      {influenceSuggestionList.length > 0 &&
        influenceSuggestionList?.map((items: any, index: number) => {
          let flag = true;
          if (outcomeState?.influenceList?.length > 0)
            for (let outObj of outcomeState?.influenceList) {
              if (outObj.name.toLowerCase() === items.name.toLowerCase()) {
                flag = false;
                break;
              }
            }
          if (flag) {
            return (
              <div
                key={index}
                className={classes.ideasListItem}
                onClick={() => props.setInfluenceData(items)}
              >
                <Typography
                  variant="h3"
                  component="span"
                  sx={{
                    color: palette.common.black,
                    fontWeight: 400,
                    fontSize: `${pxToRem(16)} !important`,
                    lineHeight: "100%!important",
                  }}
                >
                  {items.name}

                </Typography>
              </div>
            );
          }
        })}
      {influenceSuggestionDataList.length > 0 &&
        influenceSuggestionDataList?.map((items: any, index: number) => {
          let flag = true;
          if (outcomeState?.influenceList?.length > 0)
            for (let outObj of outcomeState?.influenceList) {
              if (outObj.name.toLowerCase() === items.name.toLowerCase()) {
                flag = false;
                break;
              }
            }
          if (flag) {
            return (
              <div
                key={index}
                className={classes.ideasListItem}
                onClick={() => props.setInfluenceData(items)}
              >
                <Typography
                  variant="h3"
                  component="span"
                  sx={{
                    color: palette.common.black,
                    fontWeight: 400,
                    fontSize: `${pxToRem(16)} !important`,
                    lineHeight: "100%!important",
                  }}
                >
                  {items.name}

                </Typography>
              </div>
            );
          }
        })}
    </>
  );
};
export default RecommendedListNew;
