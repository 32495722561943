import React from "react"

const Influence2Weeks = ({
    size = 16,
    color="black"
}) => {
    return <svg width={size} height={size} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M26.3027 14.9281L22.8027 18.4281C22.5561 18.6746 22.2217 18.8131 21.873 18.8131C21.5243 18.8131 21.1899 18.6746 20.9433 18.4281C20.6967 18.1815 20.5582 17.8471 20.5582 17.4984C20.5582 17.1497 20.6967 16.8153 20.9433 16.5687L22.2022 15.312H5.79596L7.05596 16.5709C7.30252 16.8174 7.44105 17.1519 7.44105 17.5006C7.44105 17.8493 7.30252 18.1837 7.05596 18.4302C6.80939 18.6768 6.47497 18.8153 6.12627 18.8153C5.77757 18.8153 5.44315 18.6768 5.19658 18.4302L1.69658 14.9302C1.57422 14.8083 1.47714 14.6634 1.41089 14.5039C1.34465 14.3443 1.31055 14.1733 1.31055 14.0006C1.31055 13.8278 1.34465 13.6568 1.41089 13.4972C1.47714 13.3377 1.57422 13.1928 1.69658 13.0709L5.19658 9.57087C5.44315 9.3243 5.77757 9.18578 6.12627 9.18578C6.29893 9.18578 6.4699 9.21979 6.62941 9.28586C6.78893 9.35194 6.93387 9.44878 7.05596 9.57087C7.17804 9.69296 7.27489 9.8379 7.34096 9.99741C7.40704 10.1569 7.44105 10.3279 7.44105 10.5006C7.44105 10.6732 7.40704 10.8442 7.34096 11.0037C7.27489 11.1632 7.17804 11.3082 7.05596 11.4302L5.79596 12.687H22.2022L20.9422 11.4281C20.8201 11.306 20.7233 11.161 20.6572 11.0015C20.5911 10.842 20.5571 10.671 20.5571 10.4984C20.5571 10.3257 20.5911 10.1547 20.6572 9.99523C20.7233 9.83571 20.8201 9.69077 20.9422 9.56868C21.1888 9.32211 21.5232 9.18359 21.8719 9.18359C22.0446 9.18359 22.2155 9.2176 22.375 9.28368C22.5346 9.34975 22.6795 9.44659 22.8016 9.56868L26.3016 13.0687C26.424 13.1905 26.5212 13.3354 26.5875 13.4949C26.6539 13.6544 26.6881 13.8254 26.6882 13.9981C26.6883 14.1709 26.6543 14.342 26.5881 14.5015C26.522 14.6611 26.425 14.8061 26.3027 14.9281Z" fill={color}/>
    </svg>
}

export default Influence2Weeks