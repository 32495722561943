import React from "react";

const SunIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="36" height="36" rx="5" fill="#68D7A8" />
      <circle
        cx="18"
        cy="18"
        r="3.5"
        fill="white"
        stroke="white"
        strokeWidth="2"
      />
      <path d="M18 11V9" stroke="white" strokeWidth="2" strokeLinecap="round" />
      <path
        d="M18 27V25"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M22.95 13.05L24.3643 11.6357"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M11.6366 24.3644L13.0508 22.9502"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M25 18L27 18"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M9 18L11 18"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M22.95 22.95L24.3643 24.3643"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M11.6366 11.6356L13.0508 13.0498"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default SunIcon;
