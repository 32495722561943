import React, { useContext, useState } from "react";
import {
    DialogTitle,
    Grid,
    Typography,
    Dialog,
    DialogContent,
    Tooltip,
    Drawer,
} from "@mui/material";
import ModalCloseIconBlack from "../assets/images/icon/modalCloseIconBlack";
import { makeStyles } from "@material-ui/core";
import palette from "../theme/palette";
import { pxToRem } from "../theme/typography";

const useStyles = makeStyles((theme) => ({
    drawerTitle: {
        display: 'flex',
        padding: '20px 24px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
        alignSelf: 'stretch',
        borderBottom: '1px solid #F3F3F3',
        background: "#FFF",
    },
    modalCloseIcon: {
        position: "absolute",
        right: 0,
        top: 0,
        cursor: "pointer",
        zIndex: 1,
    },
    modalCloseIconMobile: {
        // position: "fixed",
        right: 0,
        left: 0,
        top: 0,
        cursor: "pointer",
        zIndex: 1,
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column-reverse"
      },
    drawerTitleMobile: {
        display: 'flex',
        padding: '12px 0px',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '10px',
        alignSelf: 'stretch',
        background: "#FFF",
      },
}))
const MultiDrawerAddEditComponent = ({ handleClose, children, drawerTitle = null, secondOpen = false, ...props }) => {
    const classes = useStyles();
    return <Drawer
        anchor="right"
        sx={{
            "& .MuiPaper-root": {
                width: props?.isMobile ? "100%" : secondOpen ? "80%" : '70%'
            }
        }}
        open={true}
        onClose={handleClose}
    >
        <div className={classes.drawerTitle}>
            <Typography sx={props?.isMobile ? {
                color: palette.common.black,
                fontSize: "16px!important",
                fontWeight: 500,
                width: "80%",
                textAlign: "center"
            } : {
                color: palette.common.black,
                fontSize: "16px!important",
                fontWeight: 500
            }}>{drawerTitle}</Typography>
        </div>
        <DialogTitle className={classes.modalCloseIcon}>
            <span onClick={handleClose}>
                <ModalCloseIconBlack />
            </span>
        </DialogTitle>
        <DialogContent sx={{
            padding: 0,
            overflowX: "hidden"

        }} className={props?.isMobile ? "hide-scrollbar" : ""}>
            {children}
        </DialogContent>
    </Drawer>
}

export default MultiDrawerAddEditComponent

export const MultiDrawerAddEditComponentMobile = ({ handleClose, children, drawerTitle = null, secondOpen = false, ...props }) => {
    const classes = useStyles();
    return <Drawer
        anchor="right"
        sx={{
            "& .MuiPaper-root": {
                width: "100%"
            }
        }}
        open={true}
        onClose={handleClose}
    >
        {/* <div className={classes.drawerTitle}>
            <Typography sx={props?.isMobile ? {
                color: palette.common.black,
                fontSize: "16px!important",
                fontWeight: 500,
                width: "80%",
                textAlign: "center"
            } : {
                color: palette.common.black,
                fontSize: "16px!important",
                fontWeight: 500
            }}>{drawerTitle}</Typography>
        </div> */}
        <DialogTitle className={classes.modalCloseIconMobile}>
            <div className={classes.drawerTitleMobile}>
                <Typography sx={{
                    color: palette.common.black,
                    fontSize: `${pxToRem(24)} !important`,
                    fontWeight: 600,
                    width: "80%",
                    "&.MuiTypography-root": {
                        "@media  (max-width: 600px) and (min-width: 100px)": {
                            fontSize: `${pxToRem(24)} !important`,
                            lineHeight: "100% !important"
                        },
                    }
                    // textAlign: "center"
                }}>{drawerTitle}</Typography>
            </div>
            <span
                onClick={handleClose}
            >
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16 17.59L10.42 12L16 6.41L14.59 5L7.59 12L14.59 19L16 17.59Z" fill="#3E4240" />
                </svg>

            </span>
        </DialogTitle>
        <DialogContent sx={{
            padding: 0,
            overflowX: "hidden"

        }} className={props?.isMobile ? "hide-scrollbar" : ""}>
            {children}
        </DialogContent>
    </Drawer>
}