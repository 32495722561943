import React from "react";
import styled from "@emotion/styled";
import { TextField, Typography } from "@mui/material";
import classes from "./footer.module.css";

import ArrowRight from "../../../assets/images/icon/arrow_next";
import ArrowNextIconWhite from "../../../assets/images/icon/arrow_next_white";
import BottomStepper from "./BottomStepper";

export default function Footer() {
  const Footer = styled("div")(({ theme }) => ({
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "center",
    // gap: "15px",
    background: 'blue'
    // [theme.breakpoints.down("sm")]: {
    //   // flexDirection: "column",
    //   // marginTop: "110px",
    //   // background: "#fff",
    // },
  }));

  const LeftButton = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "48px",
    height: "48px",
    border: "2px solid rgba(17, 115, 74, 0.4)",
    borderRadius: "24px",
    transform: "rotate(-180deg)",
  }));
  const RightButton = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#E3E3E3",
    borderRadius: "30px",
    padding: "16px 13px 16px 20px",
    color: "#FFFFFF",
    gap: "9px",
  }));

  const InputField = styled(TextField)`
    & .MuiOutlinedInput-notchedOutline {
      border-radius: 4px;
      border-bottom: 4px solid #11734a;
    }
    & .MuiOutlinedInput-input {
      ::placeholder {
        // font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 100%;
        color: #11734a;
      }
    }
  `;

  const InputFieldTwo = styled(TextField)`
    & .MuiOutlinedInput-notchedOutline {
      border-radius: 4px;
      border-bottom: 4px solid #e3e3e3;
    }

    & .MuiOutlinedInput-input {
      ::placeholder {
        // font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 100%;
        color: "#e3e3e3";
      }
    }
  `;

  return (
    <>
      {/* <Footer>
        <div className={classes.backButton}>
          <div>
            <LeftButton>
              <ArrowRight />
            </LeftButton>
          </div>
        </div>
        <div className={classes.inputBar}>
          <InputField
            sx={{ width: "60vw", "& fieldset": { border: "none" } }}
            size="small"
            fullWidth
            type="text"
            placeholder="Add goals for outcome and influence"
            id="outlined-start-adornment"
            InputProps={{
              color: "success",
            }}
          />
        </div>
        <InputFieldTwo
          sx={{ width: "250px", "& fieldset": { border: "none" } }}
          size="small"
          fullWidth
          type="text"
          placeholder="Add goals for reflective question"
          id="outlined-start-adornment"
          InputProps={{
            disabled: true,
          }}
        />
        <RightButton className={classes.rightButton}>
          <Typography>Next</Typography>
          <ArrowNextIconWhite />
        </RightButton>
      </Footer> */}

        <BottomStepper
        />

    </>
  );
}
