import { makeStyles } from "@material-ui/core";
import {
    DialogContent,
    Button,
    Dialog,
    DialogActions,
    Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";

interface IDeletehistoryModal {
    open: boolean;
    setopenDeletehistoryModal: any;
    setTransformToInfluenceTracking?: any;
    setAddOutcomeTracking?: any;
    setShowOutcome?: any;
    outcomeValueInput?: any;
    SelectRow?: any;
    handleDelete?: any;
    ReverseValue?: any;
}

const useStyles = makeStyles((theme) => ({
    categoryDialog: {
        "& .MuiDialog-container": {
            "& .MuiPaper-root": {
                borderRadius: "20px",
                padding: "48px 40px",
                width: "682px",

                "& .MuiDialogContent-root": {
                    padding: "0",
                    overflowY: "unset",
                },

                "&. MuiDialogActions-root": {},
            },
        },
    },
    categoryDialogAction: {
        display: "flex !important",
        alignItems: "center !important",
        justifyContent: "end !important",
    },
}));
const DeletehistoryModal = (props: IDeletehistoryModal) => {
    const classes = useStyles();

    return (
        <Dialog
            open={props.open}
            onClose={() => props?.setopenDeletehistoryModal(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.categoryDialog}
        >
            <DialogContent id="alert-dialog-description">
                <Typography variant="h3" component="h3" lineHeight="120% !important">
                    {/* Are you sure to transform {props?.outcomeValueInput} to an influence? */}
                    Are you sure to delete the history version '{props.SelectRow.name}?#{props.ReverseValue}'
                </Typography>
                <div style={{ display: "flex" , marginTop: "20px"}}>
                    <Typography variant="caption" component="caption"  color="#9BA19D"  lineHeight="120% !important">
                        {/* Are you sure to transform {props?.outcomeValueInput} to an influence? */}
                        Metric type
                    </Typography>
                    <Typography variant="subtitle1" component="div"lineHeight="120% !important" ml="10px">
                        {/* Are you sure to transform {props?.outcomeValueInput} to an influence? */}
                        {props.SelectRow.outcomeId ? "outcome" : "influence"}
                    </Typography>
                </div>
                <div style={{ display: "flex" , marginTop: "15px"}}>
                    <Typography variant="caption" component="caption"  color="#9BA19D"  lineHeight="120% !important">
                        {/* Are you sure to transform {props?.outcomeValueInput} to an influence? */}
                        Logged time
                    </Typography>
                    <Typography variant="subtitle1" component="div"lineHeight="120% !important" ml="10px">
                        {/* Are you sure to transform {props?.outcomeValueInput} to an influence? */}
                        {new Date(props.SelectRow.createdAt).toLocaleString()}
                    </Typography>
                </div>
                <Typography variant="subtitle1" component="div" sx={{ mt: "20px", display: "flex" }}>
                    <span style={{ color: "red", marginRight: "10px", fontSize: "20px" }}>!</span>
                    You will not be able to restore this version anymore, including tracking data, goal, and completion.
                </Typography>
                <Typography variant="subtitle1" component="div" sx={{ mt: "20px", display: "flex" }}>
                    <span style={{ color: "red", marginRight: "10px", fontSize: "20px" }}>!</span>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </Typography>
                {/* <Typography variant="subtitle1" component="div" sx={{ mt: "20px", display: "flex" }}>
        <span style={{color: "red", marginRight: "10px", fontSize: "20px"}}>!</span>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </Typography> */}
            </DialogContent>
            <DialogActions
                sx={{ mt: "60px" }}
                className={classes.categoryDialogAction}
            >

                <Button
                    type="submit"
                    variant="contained"
                    className="button-after outcomeButton"
                    onClick={() => props?.setopenDeletehistoryModal(false)}
                    sx={{ width: "50% !important", mb: "0 !important", padding: "15px 30.5px !important" }}
                >
                    No, take me back
                </Button>
                {/* <Button
          type="submit"
          variant="contained"
          className="button-after outcomeButton"
          onClick={() => navigate("/add-category")}
          sx={{ width: "65% !important", mb: "0 !important" }}
        >
          Update Category
        </Button> */}
                <Button
                    variant="outlined"
                    onClick={() => {
                        // props?.setTransformToInfluenceTracking(true)
                        props?.setopenDeletehistoryModal(false)
                        // props?.setShowOutcome(false)
                        props?.handleDelete(props.SelectRow.outcomeId ?? props.SelectRow.influenceId, props.SelectRow, props.SelectRow.outcomeParentId ?? props.SelectRow.influenceParentId)
                    }}
                    sx={{ width: "25% !important", padding: "13px" }}
                >
                    <Typography variant="h4" component="h4">
                        Yes
                    </Typography>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeletehistoryModal;
