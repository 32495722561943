import React from "react"
const DailySuccess = () => {
    return <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_6198_174801)">
    <path d="M3.07201 19.4257C1.81193 19.9952 0.51789 18.7012 1.08734 17.4411L6.72012 4.97661C7.15198 4.02096 8.40614 3.79212 9.14768 4.53367L15.979 11.365C16.7205 12.1065 16.4917 13.3606 15.5361 13.7925L3.07201 19.4257Z" fill="#11734A"/>
    <mask id="mask0_6198_174801" style={{maskType:"alpha"}} maskUnits="userSpaceOnUse" x="0" y="4" width="17" height="16">
    <path d="M3.07201 19.4257C1.81193 19.9952 0.51789 18.7012 1.08734 17.4411L6.72012 4.97661C7.15198 4.02096 8.40614 3.79212 9.14768 4.53367L15.979 11.365C16.7205 12.1065 16.4917 13.3606 15.5361 13.7925L3.07201 19.4257Z" fill="#11734A"/>
    </mask>
    <g mask="url(#mask0_6198_174801)">
    <path d="M5.50195 4.8877C5.23255 11.4669 9.5 16.5 11.9465 17.7768" stroke="#E6FAF5" strokeWidth="2"/>
    <path d="M2.50195 8.8877C2.23255 15.4669 4 17.5 8.94648 21.7768" stroke="#E6FAF5" strokeWidth="2"/>
    </g>
    <path d="M15.5 6C15.5 6 15.5 5.07692 16 4.46154C16.5 3.84615 17 4.07692 18 3.53846C19 3 19 1.5 19 1.5" stroke="#A268EC" strokeWidth="2" strokeLinecap="round"/>
    <path d="M16.5 8.85156C16.5 8.85156 16.7737 8.47174 17.1941 8.38999C17.6145 8.30824 17.7151 8.69604 18.4197 8.69604C19.1242 8.69604 19.5 8.2002 19.5 8.2002" stroke="#F5B62F" strokeWidth="2" strokeLinecap="round"/>
    <path d="M12.2559 4.82715C12.2559 4.82715 12.6763 4.62126 12.8276 4.22058C12.9789 3.8199 12.7112 3.25275 12.8276 2.7478C12.944 2.24285 13.4027 1.9795 13.4027 1.9795" stroke="#F88527" strokeWidth="2" strokeLinecap="round"/>
    </g>
    <defs>
    <clipPath id="clip0_6198_174801">
    <rect width="20" height="20" fill="white" transform="translate(0.5)"/>
    </clipPath>
    </defs>
    </svg>
    
}


export default DailySuccess