import React from "react";

const UserIcon = ({viewBox = "0 0 20 26", color="#11734A", size=21}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox={viewBox}
      fill="none"
    >
      <path
        d="M17.7274 18.4471C17.2716 17.1713 16.2672 16.0439 14.8701 15.2399C13.4729 14.4358 11.7611 14 10 14C8.23893 14 6.52706 14.4358 5.12991 15.2399C3.73276 16.0439 2.72839 17.1713 2.27259 18.4471"
        stroke={color}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <circle
        cx="10"
        cy="6"
        r="4"
        stroke={color}
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UserIcon;
