import React, { useContext, useEffect, useRef } from "react"
import useStyles from "./TrackingClasses"
import { TrackingContext } from "../../context/userTracking";
import { Menu, MenuItem, Typography } from "@mui/material";
import moment from "moment";
import clsx from "clsx";
import { UserTypeContext } from "../../context/userStatus";
import palette from "../../theme/palette";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import ExpandIcon from "../../assets/images/icon/ExpandIcon";
import ThreeDotsIcon from "../../assets/images/icon/ThreeDotsIcon";
import { setTrackingState } from "../../store/actions/trackerAction";
import IconPersonalOutcome from "./IconPersonalOutcome";
import IconPersonalInfluence from "./IconPersonalInfluence";
import IconPersonalReflective from "./IconPersonalReflective";
import PersonalTrackingOutcome from "./PersonalTrackingOutcome";
import PersonalTrackingInfluence from "./PersonalTrackingInfluence";
import PersonalTrackingReflective from "./PersonalTrackingReflective";
import { addCategoryExpandAction } from "../../store/actions/userTypeAction";
import { TRACKING_CONST } from "./constants";
import { pxToRem } from "../../theme/typography";
const PersonalItemRow = ({
    category,
    index,
    journyList,
    setOutcomeId,
    outcomeId,
    setInfluenceId,
    influenceId,
    setInfluenceIdCalendar,
    setSelectedDateInfluence,
    refId,
    setRefId,
    setShowOutcomeInput,
    setShowInfluenceInput,
    callservice
}) => {
    const classes = useStyles()
    const currentDateRef = useRef(null)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget);
        trackingDispatch(setTrackingState({
            categoryId: category?.categoryId
        }))
        // setCategoryId(category?.categoryId);
    };
    const handleClose = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        setAnchorEl(null);
    };
    const { state: ITrackerState, dispatch: trackingDispatch } =
        useContext(TrackingContext);
    const { state: outcomeState, dispatch: userTypeDispatch } = useContext(UserTypeContext);
    const {
        calendar
    } = ITrackerState
    useEffect(() => {
        const todayEl = document.getElementById(moment().format(TRACKING_CONST.DATE_FORMAT))
        if (todayEl && !outcomeState?.isExpand) {
            todayEl.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
                inline: 'end'
            })
        }
        if (todayEl && outcomeState?.isExpand) {
            todayEl.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
                inline: 'end'
            })
        }
    }, [currentDateRef && currentDateRef.current, outcomeState?.isExpand])
    const arrToReturn = [<tr className={classes.row}>

        <td className={clsx(classes.cell, classes.stickyLeftCell)}>
            <div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "16px 0px",
                    gap: '8px',
                    flex: '1 0 0',
                }}
                onClick={() => {
                    const expanded = [...outcomeState?.journeyCategoryExpandList].includes(category.categoryId)
                    if (!expanded) {
                        const newArr = [...outcomeState?.journeyCategoryExpandList, category.categoryId]
                        userTypeDispatch(addCategoryExpandAction(newArr));
                    } else {
                        const newArr = [...outcomeState?.journeyCategoryExpandList].filter(it => it !== category.categoryId)
                        userTypeDispatch(addCategoryExpandAction(newArr));
                    }
                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px"

                    }}
                >
                    <MuiAccordionSummary expandIcon={<ExpandIcon />} sx={![...outcomeState?.journeyCategoryExpandList].includes(category.categoryId) ? {
                        '& .MuiAccordionSummary-expandIconWrapper': {
                            transform: "rotate(180deg)"
                        }
                    } : {}} />

                    <Typography
                        variant="h3"
                        fontWeight={600}
                        fontSize={'1.125rem !important'}
                        className={classes.listTitle}
                        color={palette.common.black}
                    >
                        {category?.name}
                    </Typography>
                    {category?.goal === "enable" && (
                        <div className={classes.boxPlan}>
                            <Typography
                                variant="h3"
                                fontWeight={700}
                                fontSize={`${pxToRem(10)} !important`}
                                lineHeight={"100% !important"}
                                color={"#000000"}
                            >
                                {"Plan"}
                            </Typography>
                        </div>
                    )}
                </div>
                {category?.name !== "Other" && (
                    <span
                        onClick={(e) => handleClick(e)}
                        id="add-other-dropdown"
                        style={{ cursor: "pointer" }}
                    >
                        <ThreeDotsIcon />
                    </span>
                )}
                <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    className={classes.dropMenu}
                    PaperProps={{
                        elevation: 0,
                    }}
                    transformOrigin={{
                        horizontal: "center",
                        vertical: "top",
                    }}
                    anchorOrigin={{
                        horizontal: "center",
                        vertical: "bottom",
                    }}
                >
                    {/* <MenuItem>Rename</MenuItem> */}
                    <MenuItem
                        onClick={(event) => {
                            event.stopPropagation()
                            let days = 0
                            const createDate = outcomeState.userDetails.createdAt
                            const diff = moment().diff(moment(createDate), 'days')
                            if (diff !== 0 && diff < 30) {
                                days = 30 - diff
                            } else if (diff === 0) {
                                days = 30 - diff
                            }
                            if (days > 0 && outcomeState?.outcomeList && outcomeState?.outcomeList?.length >= 5 && outcomeState.userDetails.level === "LOW") {
                                trackingDispatch(setTrackingState({
                                    limitPopupVisible: true,
                                    limitPopuptype: 'outcome'
                                }))
                            } else {
                                trackingDispatch(setTrackingState({
                                    showOutcome: true,
                                    openStartModal: true,
                                    addOutcomeTracking: true,
                                }))
                            }
                            setAnchorEl(null)

                            // psetShowOutcome(true);
                            // psetStartModal(true);
                            // psetAddOutcomeTracking(true);
                        }}
                    >
                        Add outcome
                    </MenuItem>
                    <MenuItem
                        onClick={(event) => {
                            event.stopPropagation()
                            let days = 0
                            const createDate = outcomeState.userDetails.createdAt
                            const diff = moment().diff(moment(createDate), 'days')
                            if (diff !== 0 && diff < 30) {
                                days = 30 - diff
                            } else if (diff === 0) {
                                days = 30 - diff
                            }
                            if (days > 0 && outcomeState?.outcomeList && outcomeState?.outcomeList?.length >= 10 && outcomeState.userDetails.level === "LOW") {
                                trackingDispatch(setTrackingState({
                                    limitPopupVisible: true,
                                    limitPopuptype: 'influence'
                                }))
                            } else {
                                trackingDispatch(setTrackingState({
                                    showInfluence: true,
                                    openStartModalInfluence: true,
                                    addInfluenceTracking: true,
                                }))
                            }
                            setAnchorEl(null)


                            // psetShowInfluence(true);
                            // psetStartModalInfluence(true);
                            // psetAddInfluenceTracking(true);
                        }}
                    >
                        Add influence
                    </MenuItem>
                    <MenuItem
                        onClick={(event) => {
                            event.stopPropagation()
                            let days = 0
                            const createDate = outcomeState.userDetails.createdAt
                            const diff = moment().diff(moment(createDate), 'days')
                            if (diff !== 0 && diff < 30) {
                                days = 30 - diff
                            } else if (diff === 0) {
                                days = 30 - diff
                            }
                            if (days > 0 && outcomeState?.reflectiveList && outcomeState?.reflectiveList?.length >= 3 && outcomeState.userDetails.level === "LOW") {
                                trackingDispatch(setTrackingState({
                                    limitPopupVisible: true,
                                    limitPopuptype: 'question'
                                }))
                            } else {
                                trackingDispatch(setTrackingState({
                                    showQues: true,
                                    openStartModalQues: true,
                                    addQuesTracking: true,
                                }))

                            }
                            setAnchorEl(null)

                            // psetShowQues(true);
                            // psetStartModalQues(true);
                            // psetAddQuesTracking(true);
                        }}
                    >
                        Add qualitative reflection
                    </MenuItem>
                </Menu>
            </div>
        </td>
        {!index && calendar[0] && calendar[0].map((date, index) => {
            const headerClass = `${moment().isSame(date, "day")
                ? clsx(
                    outcomeState?.isExpand
                        ? clsx(classes.headerBlocksExpand, classes.tableClass)
                        : classes.headerBlocks,
                    classes.background
                )
                : outcomeState?.isExpand
                    ? clsx(classes.headerBlocksExpand, classes.commonBackground, classes.tableClass)
                    : clsx(classes.headerBlocks, classes.commonBackground)
                }`;
            return <td className={clsx(classes.cell, classes.stickyCell)} id={date.format(TRACKING_CONST.DATE_FORMAT)} style={!index ? { paddingLeft: TRACKING_CONST.TRACKING_FIRST_MARGIN } : {}}>
                {<div className={headerClass} ref={moment().isSame(date, "day") ? currentDateRef : null} style={{ position: "sticky", top: "70px", alignSelf: "flex-start", zIndex: "100", padding: "0px 16px" }}>
                    <Typography
                        variant="caption"
                        component="p"
                        className={classes.weekday}
                    >
                        {moment(date).format("ddd")}
                    </Typography>
                    <Typography
                        variant="caption"
                        fontSize={"0.875rem!important"}
                        color={palette.common.black}
                        sx={{ fontWeight: 400 }}
                    >
                        {date.format("D")}{" "}
                    </Typography>
                </div>}

            </td>
        })}

    </tr>];
    if ([...outcomeState?.journeyCategoryExpandList].includes(category.categoryId)) {
        if (category.name !== "Other") {
            for (let index = 0; index < category.alls.length; index++) {
                const element = category.alls[index];
                arrToReturn.push(<tr className={classes.row} style={{
                    height: "1px",
                }}>
                    {element.outcomeId ? <PersonalTrackingOutcome item={element} isExpand={outcomeState?.isExpand} categoryId={category.categoryId} /> :
                        element.influenceId ? <PersonalTrackingInfluence item={element} isExpand={outcomeState?.isExpand} /> :
                            element.reflectiveId ? <PersonalTrackingReflective item={element} isExpand={outcomeState?.isExpand} /> :
                                <td className={clsx(classes.cell, classes.stickyLeftCell)}>{null}</td>}

                    {calendar[0] && calendar[0].map((date, i) => {
                        if (element.outcomeId) {
                            let isLast = false;
                            const foundOther = outcomeState?.journeyCategoryList.find(it => it.name === "Other")
                            if (foundOther && !foundOther.outcomes.length && !foundOther.influences.length && !foundOther.reflectives.length) {
                                if (i === (calendar[0].length - 1) && (index === category.alls.length - 1)) {
                                    isLast = true
                                }
                            }

                            return <IconPersonalOutcome
                                item={element}
                                style={!i ? { paddingLeft: TRACKING_CONST.TRACKING_FIRST_MARGIN } : {}}
                                date={date}
                                isExpand={outcomeState?.isExpand}
                                calendar={calendar[0]}
                                journyList={journyList}
                                setOutcomeId={setOutcomeId}
                                category={category}
                                outcomeId={outcomeId} callservice={callservice} isLast={isLast} />
                        }
                        if (element.influenceId) {
                            let isLast = false;
                            const foundOther = outcomeState?.journeyCategoryList.find(it => it.name === "Other")
                            if (foundOther && !foundOther.outcomes.length && !foundOther.influences.length && !foundOther.reflectives.length) {
                                if (i === (calendar[0].length - 1) && (index === category.alls.length - 1)) {
                                    isLast = true
                                }
                            }

                            return <IconPersonalInfluence
                                item={element}
                                journyList={journyList}
                                date={date}
                                style={!i ? { paddingLeft: TRACKING_CONST.TRACKING_FIRST_MARGIN } : {}}
                                isExpand={outcomeState?.isExpand}
                                calendar={calendar[0]}
                                setOutcomeId={setOutcomeId}
                                category={category}
                                setInfluenceId={setInfluenceId}
                                influenceIdInfluence={influenceId}
                                setInfluenceIdCalendar={setInfluenceIdCalendar}
                                setSelectedDateInfluence={setSelectedDateInfluence}
                                callservice={callservice}
                                isLast={isLast}
                            // refId={refId}
                            // setRefId={setRefId}
                            />
                        }
                        if (element.reflectiveId) {
                            let isLast = false;
                            const foundOther = outcomeState?.journeyCategoryList.find(it => it.name === "Other")
                            if (foundOther && !foundOther.outcomes.length && !foundOther.influences.length && !foundOther.reflectives.length) {
                                if (i === (calendar[0].length - 1) && (index === category.alls.length - 1)) {
                                    isLast = true
                                }
                            }

                            return <IconPersonalReflective
                                item={element}
                                date={date}
                                journyList={journyList}
                                category={category}
                                style={!i ? { paddingLeft: TRACKING_CONST.TRACKING_FIRST_MARGIN } : {}}
                                isExpand={outcomeState?.isExpand}
                                calendar={calendar[0]}
                                setShowOutcomeInput={setShowOutcomeInput}
                                setShowInfluenceInput={setShowInfluenceInput} setOutcomeId={setOutcomeId}
                                setInfluenceId={setInfluenceId} setRefId={setRefId}
                                refId={refId}
                                callservice={callservice}
                                isLast={isLast}
                            />
                        }
                        return null
                    })
                    }
                </tr>)
            }
        } else if (category.name === "Other") {
            if (category.outcomes && category.outcomes.length) {
                for (let index = 0; index < category.outcomes.length; index++) {
                    const element = category.outcomes[index];
                    arrToReturn.push(<tr className={classes.row} style={{
                        height: "1px",
                    }}>

                        <PersonalTrackingOutcome item={element} isExpand={outcomeState?.isExpand} categoryId={category.categoryId} />
                        {calendar[0] && calendar[0].map((date, i) => {
                            let isLast = false;

                            if (!category.reflectives.length && !category.influences.length && i === (calendar[0].length - 1) && (index === category.outcomes.length - 1)) {
                                isLast = true
                            }
                            return <IconPersonalOutcome
                                item={element}
                                date={date}
                                style={!i ? { paddingLeft: TRACKING_CONST.TRACKING_FIRST_MARGIN } : {}}
                                isExpand={outcomeState?.isExpand}
                                calendar={calendar[0]}
                                journyList={journyList}
                                category={category}
                                setOutcomeId={setOutcomeId}
                                outcomeId={outcomeId} callservice={callservice} isLast={isLast} />
                        })}
                    </tr>)
                }
            }
            if (category.influences && category.influences.length) {
                for (let index = 0; index < category.influences.length; index++) {
                    const element = category.influences[index];
                    arrToReturn.push(<tr className={classes.row} style={{
                        height: "1px",
                    }}>

                        <PersonalTrackingInfluence item={element} isExpand={outcomeState?.isExpand} />
                        {calendar[0] && calendar[0].map((date, i) => {
                            let isLast = false;
                            if (!category.reflectives.length && i === (calendar[0].length - 1) && (index === category.influences.length - 1)) {
                                isLast = true
                            }
                            return <IconPersonalInfluence item={element} date={date}
                                isExpand={outcomeState?.isExpand}
                                calendar={calendar[0]} journyList={journyList} setOutcomeId={setOutcomeId}
                                setInfluenceId={setInfluenceId}
                                influenceIdInfluence={influenceId}
                                category={category}
                                style={!i ? { paddingLeft: TRACKING_CONST.TRACKING_FIRST_MARGIN } : {}}
                                setInfluenceIdCalendar={setInfluenceIdCalendar}
                                setSelectedDateInfluence={setSelectedDateInfluence} callservice={callservice} isLast={isLast} />
                        })}
                    </tr>)
                }
            }
            if (category.reflectives && category.reflectives.length) {
                for (let index = 0; index < category.reflectives.length; index++) {
                    const element = category.reflectives[index];
                    arrToReturn.push(<tr className={classes.row} style={{
                        height: "1px",
                    }}>
                        <PersonalTrackingReflective item={element} isExpand={outcomeState?.isExpand} />
                        {calendar[0] && calendar[0].map((date, i) => {
                            let isLast = false;
                            if (i === (calendar[0].length - 1) && (index === category.reflectives.length - 1)) {
                                isLast = true
                            }
                            return <IconPersonalReflective item={element} date={date} isExpand={outcomeState?.isExpand} calendar={calendar[0]} journyList={journyList}
                                setShowOutcomeInput={setShowOutcomeInput}
                                category={category}
                                style={!i ? { paddingLeft: TRACKING_CONST.TRACKING_FIRST_MARGIN } : {}}
                                setShowInfluenceInput={setShowInfluenceInput} setOutcomeId={setOutcomeId}
                                setInfluenceId={setInfluenceId} setRefId={setRefId} refId={refId} callservice={callservice}
                                isLast={isLast} />
                        })}
                    </tr>)
                }
            }

        }
    }


    return <>{arrToReturn}</>
}

export default PersonalItemRow