import React from "react";

const MoonIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="36" height="36" rx="5" fill="#A268EC" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 8C22.8834 8 23.3251 8 23.5956 8.20966C23.8798 8.42999 24.0239 8.7862 23.9727 9.14216C23.9241 9.48089 23.4656 9.92435 22.5486 10.8113C20.6688 12.6294 19.5 15.1782 19.5 18C19.5 20.8218 20.6688 23.3706 22.5486 25.1887C23.4656 26.0757 23.9241 26.5191 23.9727 26.8578C24.0239 27.2138 23.8798 27.57 23.5956 27.7903C23.3251 28 22.8834 28 22 28C16.4772 28 12 23.5228 12 18C12 12.4772 16.4772 8 22 8Z"
        fill="white"
      />
    </svg>
  );
};

export default MoonIcon;
