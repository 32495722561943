import React, { useContext } from "react";
import { TrackingContext } from "../../context/userTracking";
import StartTrackingModal from "../tracker/startTrackingModal";
import AddTrackingPopUp from "../testAddGolas/AddTrackingPopUp/AddTrackingPopUp";
import { setOpenTracking } from "../../store/actions/trackerAction";
import { UserTypeContext } from "../../context/userStatus";

const TrackingModal = () => {
    const { state: ITrackerState, dispatch: trackingDispatch } =
        useContext(TrackingContext);
    const { state: IUserTypeState } =
        useContext(UserTypeContext);
    const { openStartTracking } = ITrackerState
    const setOpenStartTracking = (val: boolean) => {
        trackingDispatch(setOpenTracking(val))
    }
    return openStartTracking ? (
        <StartTrackingModal setOpenStartTracking={setOpenStartTracking} />
    ) : (
        <AddTrackingPopUp setOpenStartTracking={setOpenStartTracking} createDate={IUserTypeState.userDetails.createdAt} showGoal={IUserTypeState.userDetails.showGoal}/>
    )
}

export default TrackingModal