import { Paper, Snackbar, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { TrackingContext } from "../context/userTracking";
import { setDayCompleteMessage } from "../store/actions/trackerAction";
import { makeStyles } from "@material-ui/core";
import Slide, { SlideProps } from '@mui/material/Slide';
import { pxToRem } from "../theme/typography";
import DailySuccess from "../assets/images/icon/DailySuccess";

const useStyles = makeStyles((theme) => ({
    notification: {
        top: "100px",
        "@media  (min-width: 600px)": {
            top: "100px !important",
        },
        "& .MuiPaper-root": {
            boxShadow: "none",
            borderRadius: '20px !important',
            background: "#E6FAF5",
            padding: "20px",
            border: "1px solid #68D7A8",
            display: "flex",
            alignItems: "center",
            gap: "8px"
        }
    }
}))
function SlideTransition(props: SlideProps) {
    return <Slide {...props} direction="down" />;
}
const useNotifications = () => {
    const classes = useStyles()
    const { state: ITrackerState, dispatch: trackingDispatch } =
        useContext(TrackingContext);
    useEffect(() => {
        if (ITrackerState.openDayComplete) {
            setTimeout(() => {
                trackingDispatch(setDayCompleteMessage({
                    open: false,
                    message: null
                }))
            }, 3000);
        }
    }, [ITrackerState.openDayComplete])

    const Notification = (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                TransitionComponent={SlideTransition}
                open={ITrackerState.openDayComplete}
                className={classes.notification}
            // action={action}
            >
                <Paper>
                    <DailySuccess />
                    <Typography
                        variant="h1"
                        component="h1"
                        sx={{
                            fontWeight: 500,
                            fontSize: `${pxToRem(16)} !important`,
                            lineHeight: "120% !important",
                            // textAlign: "center !important",
                            "&.MuiTypography-root": {
                                "@media  (max-width: 600px) and (min-width: 100px)": {
                                    fontSize: `${pxToRem(16)} !important`,
                                    lineHeight: "120% !important"
                                },
                            }
                        }}
                    >
                        {ITrackerState.dailyCompleteMesssage || "Success"}
                    </Typography>

                </Paper>
            </Snackbar>
        </>
    )

    return { Notification }
}

export default useNotifications