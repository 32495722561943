import * as React from "react";
import { Grid } from "@mui/material";
import palette from "../../theme/palette";
import LeftPartLogin from "./leftPartLogin";
import RightPartUserIdentification from "./rightPartUserIdentification";
import { newOnboarding } from "../../services/config";
import FullpageLoader from "../../components/fullPageLoader";
import { userService } from "../../services/auth";
import { UserTypeContext } from "../../context/userStatus";
import { useNavigate } from "react-router-dom";
import useWidth from "../../utils/useWidth";
import queryParams from "../../utils/getQueryParams";

const UserIdentification = () => {
  const queryData = queryParams()
  const [loading, setLoading] = React.useState(false)
  const { state: outcomeState, dispatch: UserTypeDispatch } =
    React.useContext(UserTypeContext);
  React.useEffect(() => {
    if (newOnboarding) {
      if (!(localStorage.getItem("isFromSignup") === "true") && localStorage.getItem("userDetails")) {
        setLoading(true)
        getUserDetails();
      }
    }
  }, [])
  const navigate = useNavigate();
  const getUserDetails = () => {
    let userDetails
    if (outcomeState.userId == null && localStorage.getItem("userDetails")) {
      try {
        userDetails = JSON.parse(localStorage.getItem("userValues"));

      } catch (error) {
        userDetails = localStorage.getItem("userValues");
      }
    }
    const userId = {
      uid: outcomeState.userId
        ? outcomeState.userId
        : userDetails?.user?.uid
          ? userDetails?.user?.uid
          : userDetails?.uid ? userDetails?.uid : userDetails,
    };
    userService
      .getUserById(userId)
      .then((data: any) => {

        if (!(data?.data?.level === "NOT_FOUND")) {
          if (data?.data?.level === "LOW" && (!data?.outcome || !data?.influence)) {
            navigate("/plan-create" +  queryData.qeryString);
          } else if (
            (data?.data?.level === "HIGH" && !data?.outcome) ||
            !data?.influence ||
            !data?.reflective
          ) {
            navigate("/plan-create" + queryData.qeryString);
          } else {
            navigate("/tracking" + queryData.qeryString);
          }
        }
        setLoading(false)
        localStorage.removeItem("isFromSignup")
      })
      .catch((error) => {
        setLoading(false)
        localStorage.removeItem("isFromSignup")
        console.log("User profile Error");
      });
  };
  const width = useWidth()
  const isMobile = width < 768
  return loading ? <FullpageLoader /> : (
    <Grid
      container
      alignItems="center"
      columns={16}
      className={newOnboarding ? /* isMobile ? "bgUserIdentification": */ "bgUserIdentificationNew" : ""}
      sx={newOnboarding ? { backgroundColor: palette.common.bgColor, height: '100vh' } : { backgroundColor: palette.common.bgColor }}
    >
      {/* START LEFT SECTION */}
      {!newOnboarding && <Grid item xs={9.63} className="leftside-menu login-screen">
        <LeftPartLogin />
      </Grid>}
      {/* END LEFT SECTION */}

      {/* START RIGHT SECTION */}

      {!newOnboarding ? (
        <Grid item xs={6.37} className="rightside-menu">
          <RightPartUserIdentification />
        </Grid>
      ) : <Grid item xs={16} md={16} sm={16} xl={16} className="rightside-new-menu" sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%'
      }}>
        <RightPartUserIdentification />
      </Grid>}

      {/* END RIGHT SECTION */}
    </Grid>
  );
};
export default UserIdentification;
