/*eslint @typescript-eslint/no-unused-vars: "off"*/
import React, { useState, useContext, useEffect, useRef } from "react";
import {
  Box,
  Button,
  IconButton,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  TextareaAutosize,
} from "@mui/material";
import { makeStyles } from "@material-ui/core";
import ModalCloseIcon from "../../assets/images/icon/modalCloseIconBlack";
import palette from "../../theme/palette";
import { trackingService } from "../../services/tracking";
import { UserTypeContext } from "../../context/userStatus";
import { UpdateUserLevelModal } from "../../interfaces/outcomeModal";
import { ButtonLoader } from "../../components/buttonLoader";
import useWidth from "../../utils/useWidth";
import QuesclosePopup from "./QuesclosePopup";
import DeleteIcon from "../../assets/images/icon/DeleteIcon";
import {
  addCategoryJourneyAction,
  addJourneyAction,
} from "../../store/actions/userTypeAction";
import { AddUpdateEventModal } from "./addTrackingEventInfluenceModal";
import { pxToRem } from "../../theme/typography";
import moment from "moment";
const PlusIcon = require("../../assets/images/Add.png");

const useStyles = makeStyles((theme) => ({
  registrationHeading: {
    color: `${palette.primary.light}!important`,
    marginBottom: "20px!important",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "0px!important",
    },
  },
  pageTitle: {
    marginBottom: "16px!important",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "0px!important",
    },
  },
  dialogTitle: {
    color: `${palette.primary.light}!important`,
    fontSize: "26px!important",
    lineHeight: "26px!important",
    letterSpacing: "0.02em",
    marginBottom: "40px!important",
    padding: "0!important",
  },
  bottomButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    "& button:nth-child(2)": {
      // marginLeft: "24px",
      marginBottom: "0px !important",
    },
  },
  listItemNotes: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "16px",
    alignSelf: "stretch",
    listStyleType: "none",
    // fontSize: "14px",
    // lineHeight: "14px",
    // color: palette.primary.light,
    // margin: 0,
    paddingLeft: "0px",
    // marginBottom: "20px",
    "& li": {
      display: "flex",
      justifyContent: "space-between",
      // alignItems: "center",
      // padding: '20px',
      alignItems: "flex-start",
      alignSelf: "stretch",
    },
  },
  textarea: {
    width: "100%",
    padding: "20px",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    marginBottom: "0px !important",
    resize: "vertical",
    "&:focus-visible": {
      outline: "red",
    },
  },
  textareaMobile: {
    width: "100%",
    padding: "8px 20px",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    marginBottom: "0px !important",
    resize: "none",
    "&:focus-visible": {
      outline: "red",
    },
  },
  paddingRight: {
    paddingRight: "55px",
  },
}));

interface AddRefQues {
  setOpenRefQuesModal: (openRefQues: boolean) => void;
  refId: string;
  setShowSnakbar: (showSnakbar: boolean) => void;
  setSnakbarMsg: (snakbarMsg: string) => void;
  setMsgType: (msgType: string) => void;
  setRefId: (refId: string) => void;
  isEdit: boolean;
  setIsEdit: any;
  journeyData: any;
  headerDate: any;
  callService: any;
  item: any;
  callToGetData?: boolean;
  isMobile?: boolean;
  renderToNextInput?: any;
  pushIntoData?: (x: any) => void;
}
const AddRefQuesModal = (props: AddRefQues) => {
  const classes = useStyles();
  const inputRef = useRef(null);
  const { state: outcomeState, dispatch: UserTypeDispatch } =
    useContext(UserTypeContext);
  const [openStartModal, setStartModal] = useState(true);
  const [questionValue, setQuestionValue] = useState<any>("");
  const [questionArr, setQuestionArr] = useState([]);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [showLoadingButton, setShowLoadingButton] = useState(false);
  const [editData, setEditData] = useState<any>("");
  const [item, setItem] = useState<any>("");
  const [openQuesclosePopup, setopenQuesclosePopup] = useState(false);
  const [openQuesDeletePopup, setopenQuesDeletePopup] = useState(false);
  const { isMobile = false } = props;
  const [isBackButtonClicked, setBackbuttonPress] = useState(false);
  useEffect(() => {
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener("popstate", onBackButtonEvent);
    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionValue, questionArr, isBackButtonClicked]);
  console.log("questionValue", questionValue, "test");
  const onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!isBackButtonClicked) {
      setBackbuttonPress(true);
      checkForUpdates();
    }
  };
  const checkForUpdates = () => {
    let newQuestions = questionArr.filter((it) => it);
    if (props?.isEdit) {
      if (
        JSON.stringify(newQuestions) === JSON.stringify(editData?.answers) &&
        questionValue === ""
      ) {
        handleCloseStartModal();
      } else {
        setopenQuesclosePopup(true);
      }
    } else if (
      newQuestions?.length <= 0 &&
      (questionValue === "" || !questionValue || !questionValue.trim())
    ) {
      handleCloseStartModal();
    } else {
      setopenQuesclosePopup(true);
    }
  };
  const handleCloseStartModal = async () => {
    setStartModal(false);
    await props.setOpenRefQuesModal(false);
    props.setRefId("");
    if (!props?.isEdit && outcomeState?.isPersonal) {
      setTimeout(() => {
        props?.renderToNextInput();
      }, 500);
    }
  };

  const handleDeleteEntry = () => {
    let payload = {
      reflectiveId: editData?.reflectiveId,
      date: props?.headerDate,
    };

    trackingService
      .deleteRefQuesRes(outcomeState.token, payload)
      .then((data) => {
        if (!outcomeState?.isPersonal) {
          const foundOutcome = (outcomeState?.reflectiveList || []).find(
            (it) => it.reflectiveId === payload.reflectiveId
          );
          if (foundOutcome) {
            const journyList = { ...(outcomeState?.journeyList || {}) } as any;
            const foundDateItemIcon = journyList.reflective_data.findIndex(
              (it) =>
                it.reflectiveId === payload.reflectiveId &&
                it?.date === payload.date
            );
            if (foundDateItemIcon > -1) {
              journyList.reflective_data.splice(foundDateItemIcon, 1);
            }
            UserTypeDispatch(addJourneyAction(journyList as any));
            if (props.callToGetData) {
              props?.callService(false);
            }
          }
        } else {
          const journeyCategoryList = [
            ...(outcomeState?.journeyCategoryList || []),
          ];
          const foundOutcome = (journeyCategoryList || []).findIndex(
            (it) =>
              it.reflectiveIds &&
              it.reflectiveIds.includes(payload.reflectiveId)
          );
          if (foundOutcome > -1) {
            if (
              journeyCategoryList[foundOutcome].alls &&
              journeyCategoryList[foundOutcome].alls.length
            ) {
              const foundIndex = journeyCategoryList[
                foundOutcome
              ].alls.findIndex(
                (it) => it.reflectiveId === payload.reflectiveId
              );
              if (foundIndex > -1) {
                if (
                  journeyCategoryList[foundOutcome].alls[foundIndex]
                    .reflectiveResponses &&
                  journeyCategoryList[foundOutcome].alls[foundIndex]
                    .reflectiveResponses.length
                ) {
                  const foundDateValue = journeyCategoryList[foundOutcome].alls[
                    foundIndex
                  ].reflectiveResponses.findIndex(
                    (it) => it._id.date === payload.date
                  );
                  if (foundDateValue > -1) {
                    journeyCategoryList[foundOutcome].alls[
                      foundIndex
                    ].reflectiveResponses.splice(foundDateValue, 1);
                  }
                }
                UserTypeDispatch(addCategoryJourneyAction(journeyCategoryList));
                if (props.callToGetData) {
                  props?.callService(false);
                }
              }
            }
          } else {
            const foundOther = (journeyCategoryList || []).findIndex(
              (it) => it.name === "Other"
            );
            if (foundOther > -1) {
              const outcomeFound = journeyCategoryList[
                foundOther
              ].reflectives.findIndex(
                (it) => it.reflectiveId === payload.reflectiveId
              );
              if (outcomeFound > -1) {
                if (
                  journeyCategoryList[foundOther].reflectives[outcomeFound]
                    .reflectiveResponses &&
                  journeyCategoryList[foundOther].reflectives[outcomeFound]
                    .reflectiveResponses.length
                ) {
                  const foundDateValue = journeyCategoryList[
                    foundOther
                  ].reflectives[outcomeFound].reflectiveResponses.findIndex(
                    (it) => it._id.date === payload.date
                  );
                  if (foundDateValue > -1) {
                    journeyCategoryList[foundOther].reflectives[
                      outcomeFound
                    ].reflectiveResponses.splice(foundDateValue, 1);
                  }
                }
                UserTypeDispatch(addCategoryJourneyAction(journeyCategoryList));
                if (props.callToGetData) {
                  props?.callService(false);
                }
              }
            }
          }
        }
        // setShowLoadingBtn(false);
        props.setShowSnakbar(false);
        props.setSnakbarMsg(data?.message);
        props.setOpenRefQuesModal(false);
        // props?.callService();
        props.setMsgType("success");
      })
      .catch((error) => {
        // setShowLoadingBtn(false);
        props.setShowSnakbar(true);
        props.setSnakbarMsg(
          error?.response?.data?.description
            ? error?.response?.data?.description
            : error?.message
        );
        props.setMsgType("error");
      });
  };

  let arr2: any = [];

  const handleRemove = (index) => {
    setQuestionArr(questionArr.filter((que) => que !== questionArr[index]));
  };
  const onKeyDownHandler = (e) => {
    let keyPress = e.ctrlKey || e.altKey;
    console.log("keyPress", keyPress, e)
    if (keyPress && [65, 85].includes(e.keyCode)) {
      let disable = false;

      if (questionValue === "" || questionValue === "\n") {
        disable = questionArr.length <= 0;
      }
      if (!disable) {
        createRefResponse();
      }
    } else if(!keyPress && [13].includes(e.keyCode)) {
      if (questionValue && questionValue.length) {
        arr2 = [...questionArr];
        arr2.push(questionValue);
        setQuestionArr(arr2);
        setQuestionValue("");
      }
    } else if(keyPress && [13].includes(e.keyCode)) {
      setQuestionValue(questionValue + "\n")
    }
  };
  const createRefResponse = () => {
    setShowLoadingButton(true);
    let bodyData = {
      reflectiveId: props.refId,
      answers:
        questionValue === "" || questionValue === "\n"
          ? questionArr.filter((it) => it && it !== "\n")
          : [...questionArr, questionValue].filter((it) => it && it !== "\n"),
      date: props?.headerDate,
    };

    trackingService
      .refQuesCreate(outcomeState.token, bodyData)
      .then((data: UpdateUserLevelModal) => {
        setShowLoadingButton(false);
        // props.setShowSnakbar(false);
        // props.setSnakbarMsg(data?.message);
        props.setOpenRefQuesModal(false);
        if (props?.pushIntoData) {
          props?.pushIntoData(bodyData);
          // if (!props?.isEdit) {
          setTimeout(() => {
            props?.renderToNextInput(true);
          }, 500);
          // }
        }
        props?.callService && isMobile && props?.callService(false);
        // props?.callService();
        // props.setMsgType("success");
      })
      .catch((error) => {
        setShowLoadingButton(false);
        props.setShowSnakbar(true);
        props.setSnakbarMsg(
          error?.response?.data?.description
            ? error?.response?.data?.description
            : error?.message
        );
        props.setMsgType("error");
      });
  };
  useEffect(() => {
    if (props.item?.reflectiveId) {
      props?.setIsEdit(false);
      setQuestionArr([]);
      const outcomeGetData = {
        reflectiveId: props.item?.reflectiveId,
        date: props?.headerDate,
      };
      trackingService
        .getRefQues(outcomeState.token, outcomeGetData)
        .then((data) => {
          setItem(data?.data);
          if (!data?.data) {
            props?.setIsEdit(false);
            setQuestionArr([]);
          } else {
            props?.setIsEdit(true);
            setEditData(data?.data);
            setQuestionArr(data.data.answers);
          }
        })
        .catch((error) => {
          console.log("answer response get error");
        });
    }
    // inputRef.current && inputRef.focus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const setEditQuestionValue = (value, index) => {
    let temp = [...questionArr];
    temp[index] = value;
    setQuestionArr(temp);
  };

  const width = useWidth();
  let newQuestions = questionArr.filter((it) => it && it !== "\n");
  return (
    <Grid>
      <form onKeyDown={onKeyDownHandler}>
        <AddUpdateEventModal
          onClose={
            props?.isEdit
              ? JSON.stringify(newQuestions) ===
                  JSON.stringify(editData?.answers) && questionValue === ""
                ? handleCloseStartModal
                : () => {
                    setopenQuesclosePopup(true);
                  }
              : newQuestions?.length <= 0 &&
                (questionValue === "" ||
                  !questionValue ||
                  !questionValue.trim())
              ? handleCloseStartModal
              : () => {
                  setopenQuesclosePopup(true);
                }
          }
          onSave={createRefResponse}
          showTitle={!props.isMobile}
          isMobile={props.isMobile}
          paperProps={
            isMobile
              ? {
                  height: "50vh",
                }
              : {}
          }
          title={
            <>
              {props?.isEdit ? "Update Tracking Event" : "Add Tracking Event"}
              {editData?.reflectiveId && (
                <IconButton
                  onClick={() => {
                    setopenQuesDeletePopup(true);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              )}
            </>
          }
          showLoadingBtn={showLoadingButton}
          visible={openStartModal}
          buttonText={
            props?.isEdit ? (
              <span>
                <span style={{ textDecoration: "underline" }}>U</span>pdate
              </span>
            ) : (
              <span>
                <span style={{ textDecoration: "underline" }}>A</span>dd
              </span>
            )
          }
          saveDisabled={
            newQuestions.length === 0 &&
            (questionValue === "" || questionValue === "\n")
          }
        >
          {isMobile && editData?.reflectiveId && (
            <div
              style={{
                position: "absolute",
                right: 0,
                top: 0,
              }}
            >
              <IconButton
                onClick={() => {
                  setopenQuesDeletePopup(true);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          )}
          <div
            style={{
              display: "flex",
              padding: isMobile ? "0px" : "24px 32px 0px 32px",
              flexDirection: "column",
              alignItems: isMobile ? "flex-start" : "center",
              gap: isMobile ? "24px" : "16px",
              alignSelf: "stretch",
            }}
          >
            <Typography
              variant="body1"
              component="div"
              sx={
                isMobile
                  ? {
                      color: palette.primary.light,
                      fontSize: `${pxToRem(20)} !important`,
                      lineHeight: "125% !important",
                      maxWidth: "90%",
                      textAlign: "justify !important",
                      fontWeight: `500 !important`,
                      "&.MuiTypography-root": {
                        "@media  (max-width: 600px) and (min-width: 100px)": {
                          fontSize: `${pxToRem(20)} !important`,
                          lineHeight: "125% !important",
                        },
                      },
                    }
                  : {
                      fontWeight: 600,
                      fontSize: `${pxToRem(24)} !important`,
                      color: palette.common.black,
                      lineHeight: "135% !important",
                      textAlign: "center",
                      width: "100%",
                    }
              }
            >
              {props?.item?.question}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              padding: isMobile ? "0px" : "20px 32px 32px 32px",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "24px",
              alignSelf: "stretch",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "12px",
                alignSelf: "stretch",
              }}
            >
              <ul className={classes.listItemNotes}>
                {newQuestions?.length > 0 &&
                  newQuestions?.map((que: string, index: number) => {
                    return (
                      <li
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          position: "relative",
                        }}
                      >
                        {/* <TextField
                          variant="outlined"
                          multiline={que.length > 30 ? true : false}
                          minRows={que.length > 30 ? 4 : 1}
                          className={width > 768 ? "textarea " : "textarea"}
                          placeholder={ "Add a answers..."}
                          onChange={(e) =>
                            setEditQuestionValue(e.target.value, index)
                          }
                          sx={{
                            paddingLeft: "20px",
                            width: '100%',
                            marginBottom: '0px !important'
                          }}
                          value={que}
                          InputProps={{
                            endAdornment: <Button onClick={() => handleRemove(index)}>
                              <ModalCloseIcon />
                            </Button>
                          }}
                        /> */}
                        <TextareaAutosize
                          // variant="outlined"
                          className={
                            "textarea " +
                            (isMobile
                              ? classes.textareaMobile
                              : classes.textarea) +
                            " " +
                            classes.paddingRight
                          }
                          // fullWidth
                          placeholder="Add a answers..."
                          // multiline
                          minRows={1}
                          maxRows={3}
                          // sx={{
                          //   '& .MuiOutlinedInput-root': {
                          //     minHeight: '96px',
                          //     resize: "auto",
                          //     padding: '20px',
                          //     // gap: '12px',
                          //     // flex: '1 0 0',
                          //     borderRadius: '12px !important',
                          //     border: '1px solid #EDEDED',
                          //     background: '#FFF',
                          //     '& .MuiInputBase-input': {
                          //       // padding: `0px !important`,
                          //       resize: "auto",

                          //     }
                          //   },
                          // }}
                          onChange={(e) =>
                            setEditQuestionValue(e.target.value, index)
                          }
                          value={que}
                          // InputProps={{
                          //   endAdornment: <Button onClick={() => handleRemove(index)}>
                          //     <ModalCloseIcon />
                          //   </Button>
                          // }}
                        />
                        <Button
                          onClick={() => handleRemove(index)}
                          style={{
                            position: "absolute",
                            right: 5,
                            top: 0,
                          }}
                          sx={{
                            "&.MuiButton-root": isMobile ? {
                              padding: "8px !important",
                            } : {},
                          }}
                        >
                          <ModalCloseIcon />
                        </Button>
                      </li>
                    );
                  })}
              </ul>
              {isMobile ? (
                <Typography
                  variant="caption"
                  component="p"
                  className={classes.pageTitle}
                  sx={{
                    color: palette.primary.light,
                    fontSize: `${pxToRem(16)} !important`,
                    lineHeight: "125% !important",
                    // textAlign: "center !important",
                    fontWeight: `500 !important`,
                    "&.MuiTypography-root": {
                      "@media  (max-width: 600px) and (min-width: 100px)": {
                        fontSize: `${pxToRem(16)} !important`,
                        lineHeight: "125% !important",
                      },
                    },
                  }}
                >
                  Your answer
                </Typography>
              ) : null}

              <TextareaAutosize
                // variant="outlined"
                className={
                  "textarea " +
                  (isMobile ? classes.textareaMobile : classes.textarea)
                }
                // fullWidth
                placeholder="Add a answers..."
                // multiline
                minRows={1}
                maxRows={isMobile ? 3 : undefined}
                autoFocus
                ref={(input) => {
                  if (input && !isFocused) {
                    input.focus();
                    setQuestionValue("");
                    input.value = "";
                    setTimeout(() => {
                      setIsFocused(true);
                    }, 500);
                  }
                  // input && input.focus()
                }}
                // sx={{
                //   '& .MuiOutlinedInput-root': {
                //     minHeight: '96px',
                //     resize: "auto",
                //     padding: '20px',
                //     // gap: '12px',
                //     // flex: '1 0 0',
                //     borderRadius: '12px !important',
                //     border: '1px solid #EDEDED',
                //     background: '#FFF',
                //     '& .MuiInputBase-input': {
                //       // padding: `0px !important`,
                //       resize: "auto",

                //     }
                //   },
                // }}
                onChange={(e) => {
                  console.log("e.target.value", e.target.value)
                  if(e.target.value === '\n') e.target.value = ''
                  setQuestionValue(
                    e.target.value
                      ? e.target.value.replace(/å/g, "")
                      : e.target.value
                  );
                }}
                value={questionValue.replace(/å/g, "")}
              />
              <div
                style={
                  isMobile
                    ? {
                        display: "flex",
                        padding: "8px 12px",
                        alignItems: "center",
                        gap: "4px",
                        borderRadius: "16px",
                        background: "var(--color-new-main-shadow)",
                        cursor: "pointer",
                        marginBottom: "32px",
                      }
                    : {
                        display: "flex",
                        padding: "8px 12px",
                        alignItems: "center",
                        gap: "4px",
                        borderRadius: "16px",
                        background: "var(--color-new-main-shadow)",
                        cursor: "pointer",
                      }
                }
                onClick={() => {
                  if (questionValue && questionValue.length) {
                    arr2 = [...questionArr];
                    arr2.push(questionValue);
                    setQuestionArr(arr2);
                    setQuestionValue("");
                  }
                }}
              >
                <Typography
                  variant="body1"
                  component="div"
                  sx={{
                    fontWeight: 400,
                    fontSize: `${pxToRem(12)} !important`,
                    color: "var(--button-active-text)",
                    lineHeight: "135%",
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  Add more
                </Typography>
              </div>
            </div>
          </div>
        </AddUpdateEventModal>
      </form>
      {openQuesDeletePopup && (
        <QuesclosePopup
          open={openQuesDeletePopup}
          isDelete={openQuesDeletePopup}
          setopenQuesclosePopup={setopenQuesDeletePopup}
          handleCloseStartModal={handleDeleteEntry}
          setBackbuttonPress={setBackbuttonPress}
        />
      )}
      <QuesclosePopup
        open={openQuesclosePopup}
        setopenQuesclosePopup={setopenQuesclosePopup}
        handleCloseStartModal={handleCloseStartModal}
        setBackbuttonPress={setBackbuttonPress}
      />
    </Grid>
  );
};
export default AddRefQuesModal;
