import { Box } from "@mui/material"
import CommonTypo from "../../../components/CommonTypo"
import React from "react";

const HighestEntryOn = ({
    specificInflunceData,
    getColor = (avg: any) => ""
}) => {
    const mostEntry = [{
        day: "Mon",
        achive: 20,
        rating: 2.1,
        color: "var(--color-new-main)"
    }, {
        day: "Tue",
        achive: 20,
        rating: 4.1,
        color: "var(--color-new-main)"
    }, {
        day: "Wed",
        achive: 20,
        rating: 3.3,
        color: "var(--color-new-main)"
    }, {
        day: "Thu",
        achive: 20,
        rating: 2.0,
        color: "var(--color-new-main)"
    }, {
        day: "Fri",
        achive: 20,
        rating: 2.5,
        color: "var(--color-new-main)"
    }, {
        day: "Sat",
        achive: 20,
        rating: 1.9,
        color: "var(--color-new-main)"
    }, {
        day: "Sun",
        achive: 20,
        rating: 4.2,
        color: "var(--color-new-main)"
    }]

    for (let index = 0; index < specificInflunceData.length; index++) {
        const element = specificInflunceData[index];
        console.log("specificInflunceDataelement", element);
        
        if (element._id && element._id.day && element._id.day.slice(0, 3)) {
            const found = mostEntry.findIndex(it => it.day.toLowerCase() === element._id.day.slice(0, 3).toLowerCase())
            if (found > -1) {
                mostEntry[found].rating = element.avg ? Number(element.avg ? element.avg.replace(/%/g, "") : 0) : element.average ? Number(element.average ? element.average.replace(/%/g, "") : 0) : 0
                mostEntry[found].color = element.avg ? getColor(Number(element.avg ? element.avg.replace(/%/g, "") : 0)) : element.average ? getColor(Number(element.average ? element.average.replace(/%/g, "") : 0)) : "#EEEEEE"
            }
        }
    }
    const top = [...mostEntry]
        .map(it => it.rating)   // Extract ratings
        .sort((a, b) => b - a)   // Sort in descending order
        .slice(0, 2)
    console.log("mostEntry", mostEntry);
    
    // let successCount = top[1] ? top[1] : top[0];
    return <Box sx={{
        padding: "24px",
        border: "1px solid #EEEEEE",
        display: "flex",
        borderRadius: "12px",
        flexDirection: "column",
        gap: "8px",
        height: "184px",
        width: "563px",
        justifyContent: "space-between"
    }}>
        <CommonTypo size={24} lineHeight={24} color="#3E4240" fontWeight={700} extraSx={{
            whiteSpace: "nowrap",
        }}>{"Highest entries on"}</CommonTypo>
        <Box sx={{
            display: "flex",
            alignItems: "flex-end",
            gap: "16px",
            height: "100%"
        }}>
            {mostEntry.map(it => <DayRender rating={it.rating} color={it.color} day={it.day} top={top[0]} />)}
        </Box>
    </Box>
}

export default HighestEntryOn

export const DayRender = ({ day, rating, color, top }) => {
    console.log("rating", rating, top);
    
    const percentage = (rating * 100) / top
    return <Box sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        height: "100%",
        alignItems: "center",
        gap: "8px"
    }}>
        <Box sx={{
            height: "78px",
            display: "flex",
            alignItems: "flex-end"
        }}>
            <Box sx={{
                background: color || "#EEEEEE",
                borderRadius: "8px",
                width: "59.86px",
                height: `calc(${percentage}%)`,
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
                alignItems: "center",
                paddingBottom: "4px"
            }}>
                <CommonTypo size={16} color="#656565" fontWeight={400}>{rating}</CommonTypo>
            </Box>
        </Box>

        <CommonTypo size={16} color="#000000" fontWeight={400}>{day}</CommonTypo>
    </Box>
}