import { makeStyles } from "@material-ui/core";
import { Grid, Typography } from "@mui/material";
import React from "react";
import CloudIcon from "../../assets/images/icon/cloudIcon";
import HeartIcon from "../../assets/images/icon/heartIcon";
import LeafIcon from "../../assets/images/icon/leafIcon";
import MoonIcon from "../../assets/images/icon/moonIcon";
import SunIcon from "../../assets/images/icon/sunIcon";
import CustomeCardInsights from "./customeCardInsights";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  cards: {
    alignItems: "center",
    marginTop: "20px",

    "& .MuiGrid-item": {
      height: "100%",
      maxWidth: "238px",
      marginRight: "12px",
      borderRadius: "8px",
      padding: "20px 16px",
      marginBottom: "12px",
      [theme.breakpoints.down(1512)]: {
        maxWidth: "225px",
      },
      [theme.breakpoints.down(1450)]: {
        maxWidth: "197px",
        padding: "10px",
      },
      [theme.breakpoints.down("xs")]: {
        maxWidth: "100%",
        marginRight: "0px",
      },

      "&:last-child": {
        marginRight: "0px",
      },
    },

    [theme.breakpoints.down("lg")]: {
      display: "flex!important",
    },
    [theme.breakpoints.down("xs")]: {
      display: "block!important",
    },

    '& .wellBeingCard': {
      "&:hover": {
        '& .outcome-influ-card': {
          background: '#FFF !important'
        },
        cursor: "pointer",
      },
    },
    '& .energyCard': {
      "&:hover": {
        '& .outcome-influ-card': {
          background: '#FFF !important'
        },
        cursor: "pointer",
      },
    },
    '& .senseCard': {
      "&:hover": {
        '& .outcome-influ-card': {
          background: '#FFF !important'
        },
        cursor: "pointer",
      },
    },
    '& .improveCard': {
      "&:hover": {
        '& .outcome-influ-card': {
          background: '#FFF !important'
        },
        cursor: "pointer",
      },
    },
    '& .stressCard': {
      "&:hover": {
        '& .outcome-influ-card': {
          background: '#FFF !important'
        },
        cursor: "pointer",
      },
    },
  },
}));

interface InsightsOutcomesModal {
  setSelectOutcome: (selectOutcome: string) => void;
  selectOutcome: string;
  outcomeData: any[]
}

const InsightsOutcomes = (props: InsightsOutcomesModal) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <>
      <Typography variant="h3" letterSpacing={"0.02em"}>
        Select Outcome
      </Typography>
      <Grid container className={classes.cards} sx={{ flexWrap: 'nowrap', overflowX: 'scroll' }}>
        {props?.outcomeData?.length && props?.outcomeData.map(it => {
          const classNameGet = it.className;

          return <Grid
            item
            className={
              props.selectOutcome === it.outcomeId
                ? classNameGet + " outcomeCard active"
                : classNameGet + " outcomeCard"
            }
            sx={{ minWidth: '221px', maxWidth: '221px', width: '238px' }}
            onClick={() => {
              props.setSelectOutcome(it.outcomeId);
              navigate("/insights?id=" + it.outcomeId);
            }}
          >
            <CustomeCardInsights
              icon={it.icon}
              title={it.name}
              classNameGet={classNameGet}
              outcomeId={it.outcomeId}
              daysTracked={it.avg}
              options={it.options}
              avgRating={it?.outcomeGoals[0]?.target || 'N/A'}
              selectOutcome={props.selectOutcome}
              {...it}
            />
          </Grid>
        })}
        {/* 
        <Grid
          item
          xs={2.94}
          className={
            props.selectOutcome === "energy"
              ? "energyCard outcomeCard active"
              : "energyCard outcomeCard"
          }
          onClick={() => {
            props.setSelectOutcome("energy");
            navigate("/insights?id=energy");
          }}
        >
          <CustomeCardInsights
            icon={SunIcon}
            title="Energy"
            daysTracked={35}
            avgRating={4.3}
          />
        </Grid>
        <Grid
          item
          xs={2.94}
          className={
            props.selectOutcome === "sense-of-progress"
              ? "senseCard outcomeCard active"
              : "senseCard outcomeCard"
          }
          onClick={() => {
            props.setSelectOutcome("sense-of-progress");
            navigate("/insights?id=sense-of-progress");
          }}
        >
          <CustomeCardInsights
            icon={CloudIcon}
            title="Sense of progress"
            daysTracked={35}
            avgRating={4.3}
          />
        </Grid>
        <Grid
          item
          xs={2.94}
          className={
            props.selectOutcome === "improve-my-sense-of-happiness"
              ? "improveCard outcomeCard active"
              : "improveCard outcomeCard"
          }
          onClick={() => {
            props.setSelectOutcome("improve-my-sense-of-happiness");
            navigate("/insights?id=improve-my-sense-of-happiness");
          }}
        >
          <CustomeCardInsights
            icon={HeartIcon}
            title="Improve my sense of happiness"
            daysTracked={35}
            avgRating={4.3}
          />
        </Grid>
        <Grid
          item
          xs={2.94}
          className={
            props.selectOutcome === "perceived-stress"
              ? "stressCard outcomeCard active"
              : "stressCard outcomeCard"
          }
          onClick={() => {
            props.setSelectOutcome("perceived-stress");
            navigate("/insights?id=perceived-stress");
          }}
        >
          <CustomeCardInsights
            icon={MoonIcon}
            title="Perceived Stress"
            daysTracked={35}
            avgRating={4.3}
          />
        </Grid> */}
      </Grid>
    </>
  );
};

export default InsightsOutcomes;
