import { makeStyles } from "@material-ui/core";
import { Typography } from "@mui/material";
import React from "react";
import palette from "../../theme/palette";

const useStyles = makeStyles((theme) => ({
  outcomeTitle: {
    marginLeft: "12px!important",
    marginBottom: "0!important",
    lineHeight: "18px!important",
    color: `${palette.primary.light}!important`,
    maxWidth: "154px",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
}));

interface CustomCardModal {
  icon: any;
  title: string;
  daysTracked: number;
  avgRating: number;
}

const CustomCard = (props: CustomCardModal) => {
  const classes = useStyles();

  return (
    <>
      <div className="titlePart">
        <props.icon />
        <Typography
          variant="h5"
          fontWeight={500}
          className={classes.outcomeTitle}
        >
          {props.title}
        </Typography>
      </div>
      <div className="ratingPart">
        <div>
          <Typography variant="h3" fontWeight={600}>
            {props.daysTracked}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 400,
              fontSize: "12px!important",
              marginTop: "2px",
            }}
          >
            Days Tracked
          </Typography>
        </div>
        <div>
          <Typography variant="h3" fontWeight={600}>
            {props.avgRating}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 400,
              fontSize: "12px!important",
              marginTop: "2px",
            }}
          >
            Average Rating
          </Typography>
        </div>
      </div>
    </>
  );
};

export default CustomCard;
