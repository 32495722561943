import React, { useState, useContext, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Box, Button, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { pxToRem } from "../../theme/typography";
import GoogleIcon, { NewGoogleIcon } from "../../assets/images/icon/google-icon";
import FacebookIcon, { NewFacebookIcon } from "../../assets/images/icon/facebook-icon";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import palette from "../../theme/palette";
import { facebookProvider, googleProvider } from "../../config/authMethods";
import { userService } from "../../services/auth";
import SnackBarComp from "../../components/snackBar";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../config/firebase";
import { ButtonLoader } from "../../components/buttonLoader";
import { UserTypeContext } from "../../context/userStatus";
import {
  setUserId,
  setUserLevel,
  setUserStatus,
  setUserToken,
} from "../../store/actions/userTypeAction";
import FullpageLoader from "../../components/fullPageLoader";
import { UserLoginModal } from "../../interfaces/outcomeModal";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOffOutlined from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";
import queryParams from "../../utils/getQueryParams";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

const RootStyle = styled("div")(({ theme }) => ({
  padding: "0 100px",
  [theme.breakpoints.up("xl")]: {
    padding: "60px",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "40px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "30px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "20px",
  },
}));
const RootStyle2 = styled("div")(({ theme }) => ({
  padding: "0",

}));

const useStyles = makeStyles((theme) => ({
  registrationHeading: {
    color: palette.primary.light,
    marginBottom: "62px!important",
    [theme.breakpoints.down("lg")]: {
      marginBottom: "50px!important",
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: "40px!important",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "30px!important",
    },
  },
  socialLoginButton: {
    marginTop: pxToRem(24),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "24px"
  },
  alreadyAccountText: {
    marginTop: pxToRem(60),
    color: "#9BA19D",
    fontSize: pxToRem(16),
    lineHeight: pxToRem(16),
    fontWeight: 400,
    textAlign: "center",
  },
  alreadyAccountTextMobile: {
    marginTop: pxToRem(30),
    color: "#9BA19D",
    fontSize: pxToRem(16),
    lineHeight: pxToRem(16),
    fontWeight: 400,
    textAlign: "center",
  },
  forgetPasswordText: {
    color: "#9BA19D",
    textAlign: "right",
    fontSize: pxToRem(14),
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "145%", /* 20.3px */
    letterSpacing: "-0.14px",
    cursor: "pointer"
  },
  alreadyAccountTextSpan: {
    color: "#11734A",
    cursor: "pointer",
  },
  welcomeTitle: {
    fontSize: "26px !important",
    lineHeight: "120% !important"
  },
  welcomeSubTitle: {
    fontSize: "16px !important",
    lineHeight: "100% !important"
  },
  orLink: {
    marginTop: `${pxToRem(70)} !important`,
  },
  textInputMobile: {
    "& .MuiOutlinedInput-root": {
      border: "1px solid #B8E29E",
      '& .MuiInputBase-input': {
        '&::placeholder': {
          fontSize: `${pxToRem(14)} !important`,
          color: '#3E4240',
          opacity: 1, /* Firefox */
          lineHeight: '100%',
          fontWeight: 400,
        }
      },
    },

  }
}));

const ariaLabel = { "aria-label": "description" };

const RightPartLogin = ({ isMobile = false }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const queryData = queryParams()
  const [showSnakbar, setShowSnakbar] = useState(false);
  const [snakbarMsg, setSnakbarMsg] = useState("");
  const [msgType, setMsgType] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [showLoaderPage, setShowLoaderPage] = useState(false);
  const { dispatch: userTypeDispatch } = useContext(UserTypeContext);
  const [showPassword, setShowPassword] = useState(false);
  useEffect(() => {
    userTypeDispatch(setUserId(undefined));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // FIREBASE AUTHENTICATION
  const emailAuth = async (email: string, password: string) => {
    return signInWithEmailAndPassword(auth, email, password)
      .then((result) => {
        return result;
      })
      .catch((err) => {
        setShowLoader(false);
        setShowSnakbar(true);
        setMsgType("error");
        if (err.code === "auth/user-not-found") {
          setSnakbarMsg("User not found");
        } else if (err.code === "auth/wrong-password") {
          setSnakbarMsg("Please enter right credential");
        } else {
          setSnakbarMsg(err.message);
        }
        return err;
      });
  };
  const firebaseLogin = async (email: string, password: string) => {
    try {
      const result = await emailAuth(email, password);
      if (result?.user) {
        localStorage.setItem("userDetails", JSON.stringify(result));
        // API LOGIN
        userContinueLogin(result?.user?.uid);
      }
    } catch (error) {
      setShowLoader(false);
    }
  };
  const userContinueLogin = (uid: string) => {
    const UID = {
      uid: uid,
    };
    localStorage.setItem("userId", uid);
    userService
      .userLogin(UID.uid)
      .then((data: UserLoginModal) => {
        setMsgType("success");
        setShowSnakbar(true);
        setSnakbarMsg(data.message);
        setShowLoaderPage(false);
        setShowLoader(false);
        localStorage.setItem("userDetails", data["x-access_token"]);
        localStorage.setItem("userValues", JSON.stringify(data));
        userTypeDispatch(setUserToken(data["x-access_token"]));
        userTypeDispatch(setUserLevel(data.level));
        userTypeDispatch(setUserId(uid));
        if (data?.level === "NOT_FOUND") {
          navigate("/user-identification");
        } else {
          setTimeout(() => {
            // if (data?.level === "LOW" && (!data?.outcome || !data?.influence)) {
            //   navigate("/outcome");
            // } else if (
            //   (data?.level === "HIGH" && !data?.outcome) ||
            //   !data?.influence ||
            //   !data?.reflective
            // ) {
            //   navigate("/outcome");
            // } else {
            //   navigate("/tracking");
            //   userTypeDispatch(setUserStatus(false));
            // }
            if (data?.level === "LOW" && (!data?.outcome || !data?.influence)) {
              navigate("/plan-create");
            } else if (
              (data?.level === "HIGH" && !data?.outcome) ||
              !data?.influence ||
              !data?.reflective
            ) {
              navigate("/plan-create");
            } else {
              navigate("/tracking");
              userTypeDispatch(setUserStatus(false));
            }
          }, 2000);
        }
      })
      .catch((error: any) => {
        if (error.response?.data?.description === "User is not registered") {
          const userDetails = JSON.parse(localStorage.getItem("userDetails"));
          const userData = {
            uid: userDetails?.user?.uid
              ? userDetails?.user?.uid
              : userDetails?.uid,
            user_email: userDetails?.user?.email
              ? userDetails?.user?.email
              : userDetails?.email,
            signUpType:
              userDetails?.providerData?.[0]?.providerId === "google.com"
                ? "GMAIL"
                : userDetails?.providerData?.[0]?.providerId === "facebook.com"
                  ? "FACEBOOK"
                  : "OTHER",
            first_name: userDetails?.displayName?.split(" ")[0],
            last_name: userDetails?.displayName?.split(" ")[1],
            "ipAddress": localStorage.getItem("ipAddress")
          };
          userService.createUser(userData).then((res) => {
            localStorage.setItem("userValues", JSON.stringify(res.data));
            localStorage.setItem("userDetails", res["x-access_token"]);
            userTypeDispatch(setUserToken(res["x-access_token"]));
            if (res?.data?.level === "NOT_FOUND") {
              navigate("/user-identification");
            } else {
              navigate("/outcome");
            }
          });
        }
        setShowLoaderPage(false);
        setShowLoader(false);
      });
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setShowLoader(true);
      firebaseLogin(values.email, values.password);
    },
  });
  const handleCloseSnakbar = () => {
    setShowSnakbar(false);
  };
  const handleOnClick = async (provider: any) => {
    setShowLoaderPage(true);
    try {
      const result = await userService.socialMediaAuth(provider);
      localStorage.setItem("userDetails", JSON.stringify(result));
      if (!result.uid) {
        throw new Error(result.message);
      } else {
        userContinueLogin(result?.uid);
      }
    } catch (error) {
      setShowLoaderPage(false);
      setShowLoader(false);
      setMsgType("error");
      setShowSnakbar(!error?.message?.includes("auth/popup-closed-by-user"));
      if (
        error.message ===
        "Firebase: Error (auth/account-exists-with-different-credential)."
      ) {
        setSnakbarMsg("Account already exists with diffrent credential");
      } else if(!error?.message?.includes("auth/popup-closed-by-user")){
        console.log("error?.message", error?.message)
        setSnakbarMsg(error?.message);
      }
    }
  };
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const Root = isMobile ? RootStyle2 : RootStyle
  return (
    <Root className={isMobile ? "" : "login-right-part"}>
      {showLoaderPage && <FullpageLoader />}
      <SnackBarComp
        showSnakbar={showSnakbar}
        handleCloseSnakbar={handleCloseSnakbar}
        snakbarMsg={snakbarMsg}
        type={msgType ? msgType : "info"}
      />
      {isMobile ? (
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"} flexDirection={"column"} width={"100%"} padding={"70px 0 60px"} gap={"8px"}>

          <Typography component={"span"} variant="h1" className={classes.welcomeTitle} fontWeight={"700 !important"} color={palette.primary.light}>Welcome back</Typography>
          <Typography component={"span"} variant="h1" className={classes.welcomeSubTitle} fontWeight={"400 !important"} color={palette.primary.light}>Log in to Tenacious Tracker</Typography>
        </Box>
      ) : (
        <Typography
          variant="h1"
          component="h1"
          className={classes.registrationHeading}
        >
          Sign in and get started today!
        </Typography>
      )}

      <Grid>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            placeholder="Email"
            variant="outlined"
            fullWidth
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            className={isMobile ? classes.textInputMobile : "newInputBox"}
            inputProps={ariaLabel}
          />
          <TextField
            placeholder="Password"
            variant="outlined"
            fullWidth
            type={showPassword ? 'text' : 'password'}
            name="password"
            value={formik.values.password}
            className={isMobile ? classes.textInputMobile : "newInputBox"}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            inputProps={ariaLabel}
            InputProps={{
              endAdornment: <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                </IconButton>
              </InputAdornment>
            }}
          />
          {!isMobile && <Box display="flex" alignItems="flex-start" gap="12px" alignSelf="stretch" >
            <Box display="flex" justifyContent={"flex-end"} alignItems="center" gap="4px" flex={"1 0 0"}>
              <p className={classes.forgetPasswordText} onClick={() => navigate("/forget-password")}>
                Forgot password?
              </p>
            </Box>
          </Box>}
          {!showLoader ? (
            <Button
              type="submit"
              variant="contained"
              className={/* isMobile ? "button-after" : */"new-button"}
              fullWidth
              sx={{ mt: pxToRem(isMobile ? 20 : 24), mb: isMobile ? `${pxToRem(0)} !important` : pxToRem(57) }}
              disabled={!(formik.isValid && formik.dirty)}
            >
              {isMobile ? "Log in" : "Sign in with email"}
            </Button>
          ) : (
            <Button
              variant="contained"
              className={/* isMobile ? "button-after" : */"new-button"}
              fullWidth
              sx={{ mt: pxToRem(isMobile ? 20 : 24), mb: isMobile ? `${pxToRem(0)} !important` : pxToRem(57) }}
            >
              <ButtonLoader />
            </Button>
          )}
        </form>
        {isMobile && <Box display="flex" alignItems="center" gap="12px" alignSelf="stretch" sx={{ mt: pxToRem(24) }}>
          <Box display="flex" justifyContent={"center"} alignItems="center" gap="4px" flex={"1 0 0"}>
            <p className={classes.forgetPasswordText} onClick={() => navigate("/forget-password")}>
              Forgot password?
            </p>
          </Box>
        </Box>}
        <p className={`${isMobile ? "registation_text" : "registation_text_new"} ${isMobile ? classes.orLink : ""}`}>
          <span>or continue with</span>
        </p>
        <Grid className={classes.socialLoginButton}>
         
          <Button
            sx={{ padding: 0 }}
            onClick={() => handleOnClick(googleProvider)}
          >
            <NewGoogleIcon />
          </Button>
          <Button
            sx={{ padding: 0 }}
            onClick={() => handleOnClick(facebookProvider)}
          >
            <NewFacebookIcon />
          </Button>
        </Grid>
        <p className={isMobile ? classes.alreadyAccountTextMobile : classes.alreadyAccountText}>
          You don't have an account?{" "}
          <span
            className={classes.alreadyAccountTextSpan}
            onClick={() => navigate("/register" + queryData.qeryString)}
          >
            Sign up
          </span>
        </p>
      </Grid>
    </Root>
  );
};
export default RightPartLogin;
