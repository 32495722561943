import {
  DialogContent,
  Button,
  Dialog,
  DialogActions,
  Typography,
  Box,
} from "@mui/material";
import React from "react";
import { makeStyles } from "@material-ui/core";
import palette from "../../theme/palette";
import { pxToRem } from "../../theme/typography";
import Exclamation from "../../assets/images/icon/Exclamation";
import NewButton from "../../components/NewButton";

interface IswitchInfluence {
  open: boolean;
  setopenSwitchInfluenceModal: any;
  setTransformToInfluenceTracking?: any;
  setAddOutcomeTracking?: any;
  setShowOutcome?: any;
  outcomeValueInput?: any;
}

const useStyles = makeStyles((theme) => ({
  categoryDialog: {
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "24px",
        background: palette.common.white,
        padding: 0,

        "& .MuiDialogContent-root": {
          padding: "0",
          overflowY: "unset",
        },

        "&. MuiDialogActions-root": {},
      },
    },
  },
  categoryDialogAction: {
    display: "flex !important",
    alignItems: "center !important",
    justifyContent: "center !important",
    padding: "32px !important",
    gap: "12px",
    borderTop: "1px solid #F3F3F3",
    alignSelf: "stretch",
  },
}));
const SwitchToOutcomeModal = (props: IswitchInfluence) => {
  const classes = useStyles();

  return (
    <Dialog
      open={props.open}
      onClose={() => props?.setopenSwitchInfluenceModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.categoryDialog}
    >
      <DialogContent id="alert-dialog-description">
        <Box
          sx={{
            display: "flex",
            padding: "32px",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "16px",
            alignSelf: "stretch",
          }}
        >
          <Typography
            variant="h3"
            component="span"
            lineHeight="135% !important"
            sx={{
              color: palette.common.black,
              fontSize: `${pxToRem(24)} !important`,
              lineHeight: "120% !important",
              fontWeight: `600 !important`,
              fontFamily: "'Poppins' !important",
            }}
          >
            Are you sure you want to transform {props?.outcomeValueInput} to an
            outcome?
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "12px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                gap: "12px",
              }}
            >
              <Box>
                <Exclamation />
              </Box>

              <Typography
                variant="h3"
                component="span"
                lineHeight="150% !important"
                sx={{
                  color: palette.common.black,
                  fontSize: `${pxToRem(16)} !important`,
                  fontWeight: 400,
                }}
              >
                An automatic history version will securely log all current data,
                including tracking record, metric style, goal, and completion.
                You can restore it anytime.
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                gap: "12px",
              }}
            >
              <Box>
                <Exclamation />
              </Box>

              <Typography
                variant="h3"
                component="span"
                lineHeight="150% !important"
                sx={{
                  color: palette.common.black,
                  fontSize: `${pxToRem(16)} !important`,
                  fontWeight: 400,
                }}
              >
                You need to set a new goal
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                gap: "12px",
              }}
            >
              <Box>
                <Exclamation />
              </Box>

              <Typography
                variant="h3"
                component="span"
                lineHeight="150% !important"
                sx={{
                  color: palette.common.black,
                  fontSize: `${pxToRem(16)} !important`,
                  fontWeight: 400,
                }}
              >
                All the impact scores regarding this metric will be recalculate
              </Typography>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{ mt: "60px" }}
        className={classes.categoryDialogAction}
      >
        <NewButton
          buttonText="No, take me back"
          type="submit"
          onClick={() => props?.setopenSwitchInfluenceModal(false)}
          variant="contained"
        />
         <NewButton
          buttonText="Yes, transform"
          onClick={() => {
            props?.setTransformToInfluenceTracking(true);
            props?.setopenSwitchInfluenceModal(false);
            props?.setShowOutcome(false);
          }}
          variant="outlined"
        />
      </DialogActions>
    </Dialog>
  );
};

export default SwitchToOutcomeModal;
