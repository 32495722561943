import React from 'react'

function EnergyIcon() {
    return (
        <div>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="10" cy="10" r="3.5" fill="#68D7A8" stroke="#68D7A8" strokeWidth="2" />
                <path d="M10 3V1" stroke="#68D7A8" strokeWidth="2" strokeLinecap="round" />
                <path d="M10 19V17" stroke="#68D7A8" strokeWidth="2" strokeLinecap="round" />
                <path d="M14.95 5.04996L16.3643 3.63574" stroke="#68D7A8" strokeWidth="2" strokeLinecap="round" />
                <path d="M3.63657 16.3644L5.05078 14.9502" stroke="#68D7A8" strokeWidth="2" strokeLinecap="round" />
                <path d="M17 10L19 10" stroke="#68D7A8" strokeWidth="2" strokeLinecap="round" />
                <path d="M1 10L3 10" stroke="#68D7A8" strokeWidth="2" strokeLinecap="round" />
                <path d="M14.95 14.95L16.3643 16.3643" stroke="#68D7A8" strokeWidth="2" strokeLinecap="round" />
                <path d="M3.63657 3.63559L5.05078 5.0498" stroke="#68D7A8" strokeWidth="2" strokeLinecap="round" />
            </svg>
        </div>
    )
}

export default EnergyIcon
