import React from "react";

export default function Info() {
  return (
    <>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.6" clipPath="url(#clip0_2033_32787)">
          <circle cx="8" cy="8" r="7.25" stroke="#B7BDB9" strokeWidth="1.5" />
          <path
            d="M5.45703 6.18164H6.74609C6.75195 5.49023 7.15625 4.95117 7.95898 4.95117C8.5625 4.95117 9.13086 5.25586 9.13086 5.99414C9.13086 6.56836 8.7793 6.83203 8.23438 7.23633C7.60156 7.6875 7.12695 8.22656 7.16797 9.08789L7.17383 9.49219H8.45117V9.16992C8.45117 8.51367 8.70312 8.32031 9.38281 7.81055C9.94531 7.39453 10.5371 6.91992 10.5371 5.92383C10.5371 4.51172 9.34766 3.83203 8.0293 3.83203C6.77539 3.83203 5.42188 4.44141 5.45703 6.18164ZM7.00977 11.3379C7.00977 11.7891 7.38477 12.1172 7.83594 12.1172C8.28711 12.1172 8.65625 11.7891 8.65625 11.3379C8.65625 10.8867 8.28711 10.5586 7.83594 10.5586C7.38477 10.5586 7.00977 10.8867 7.00977 11.3379Z"
            fill="#B7BDB9"
          />
        </g>
        <defs>
          <clipPath id="clip0_2033_32787">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
}
