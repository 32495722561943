import React from "react"

const PencilIcon = ({
    size = 24,
    color = "#656565"
}) => {
    return <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.5756 6.61315L17.3869 2.42347C17.2128 2.24928 17.006 2.1111 16.7785 2.01682C16.551 1.92255 16.3071 1.87402 16.0608 1.87402C15.8145 1.87402 15.5706 1.92255 15.3431 2.01682C15.1155 2.1111 14.9088 2.24928 14.7347 2.42347L3.17438 13.9847C2.99963 14.1583 2.86109 14.3649 2.76679 14.5924C2.67248 14.82 2.62429 15.064 2.62501 15.3103V19.5C2.62501 19.9973 2.82255 20.4742 3.17418 20.8259C3.52581 21.1775 4.00273 21.375 4.50001 21.375H8.68969C8.93601 21.3757 9.18001 21.3275 9.40756 21.2332C9.63511 21.1389 9.84168 21.0004 10.0153 20.8257L21.5756 9.2644C21.9271 8.91279 22.1246 8.43596 22.1246 7.93878C22.1246 7.4416 21.9271 6.96477 21.5756 6.61315ZM8.53126 19.125H4.87501V15.4688L12.75 7.59378L16.4063 11.25L8.53126 19.125ZM18 9.65628L14.3438 6.00003L16.0631 4.28065L19.7194 7.9369L18 9.65628Z" fill={color} />
    </svg>
}

export default PencilIcon