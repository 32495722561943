import React from "react";
import { styled } from "@mui/material/styles";
import NoOutComeFound from "./noOutcomeFound";
import RecommendedList from "./recommendedList";
import useWidth from "../../utils/useWidth";

const RootStyle = styled("div")(({ theme }) => ({
  padding: "60px",
  [theme.breakpoints.down("lg")]: {
    padding: "50px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "40px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "20px",
  },
}));

interface RightPartOutComeModal {
  setOutcomeValue: (outComeValue: string) => void;
  setOutcomeIdeas: (outcomeIdeas: any) => void;
}

const RightPartOutCome = (props: RightPartOutComeModal) => {
  const width = useWidth();
  return (
    <RootStyle style={{ display: width < 768 ? "none" : "block", overflowY: 'auto', height: '100vh'}}>
      {/* NO OUTCOME FOUND */}
      <NoOutComeFound setOutcomeValue={props.setOutcomeValue} />

      {/* RECOMMENDATION LIST */}
      <RecommendedList
        setOutcomeValue={props.setOutcomeValue}
        setOutcomeIdeas={props.setOutcomeIdeas}
      />
    </RootStyle>
  );
};
export default RightPartOutCome;
