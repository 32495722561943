import * as React from "react";
import { styled } from "@mui/material/styles";
import NoQuesFound from "./noQuesFound";
import RecommendedList from "./recommendedList";
import useWidth from "../../utils/useWidth";

const RootStyle = styled("div")(({ theme }) => ({
  padding: "60px",
  [theme.breakpoints.down("lg")]: {
    padding: "50px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "40px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "20px",
  },
}));

interface RightSideReflectiveQuesModal {
  setQuestionData: (questionData: string) => void;
  setReflectiveIdeas: (reflectiveIdeas: any) => void;
}

const RightSideReflectiveQues = (props: RightSideReflectiveQuesModal) => {
  const width = useWidth();
  return (
    <RootStyle style={{ display: width < 768 ? "none" : "block", overflowY: 'auto', height: '100vh'}}>
      {/* NOT FOUND */}
      <NoQuesFound setQuestionData={props.setQuestionData} />

      {/* RECOMMENDTION LIST */}
      <RecommendedList
        setQuestionData={props.setQuestionData}
        setReflectiveIdeas={props.setReflectiveIdeas}
      />
    </RootStyle>
  );
};
export default RightSideReflectiveQues;
