import { Box, Checkbox, IconButton, InputAdornment, TextField } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import TreeView from "@material-ui/lab/TreeView";
import CommonTypo from "../../../components/CommonTypo"
import CloseButton from "../../../components/CloseButton"
import Search from "../../../assets/images/icon/Search"
import ArrowDownNew, { ArrowUpNew } from "../../../assets/images/icon/ArrowDownGreenNew";
import { styled } from "@material-ui/core";
import TreeItem from "@material-ui/lab/TreeItem";
import TabBox from "../../../components/TabBox";
import clsx from "clsx";
import InfluenceIcon from "../../../assets/images/icon/ProgressInsights/InfluenceIcon";
import OutcomeIcon from "../../../assets/images/icon/ProgressInsights/OutcomeIcon";
import { useNavigate } from "react-router-dom";
import { MonthSelection, WeekSelection } from "./SelecrtionDialogue";
import { ReportsTypeContext } from "../../../context/userIReports";
import moment from "moment";
import Star from "../../../assets/images/icon/Star";

const CustomCheckBox = styled(Checkbox)(({ theme }) => ({
    color: "#49454F",
    '& .MuiSvgIcon-root': {
        fontSize: 16, borderRadius: "2px",
    },
    '&.Mui-checked': {
        color: "#65558F",
    },
    "&.MuiCheckbox-root": {
        padding: "0px 9px"
    }
}));
const nearestValueOutcome = (val, influenceItem) => {
    let arr = [];
    if (
        influenceItem.outcomeId &&
        influenceItem?.options?.length &&
        influenceItem?.options[val - 1]
    ) {
        return influenceItem.options[val - 1].colorCode;
    }
    if (influenceItem?.options && influenceItem?.options.length) {
        arr = [...influenceItem?.options].map((it) => it.value);
    }
    const returnVal =
        arr.reduce(
            (p, n) => (Math.abs(p) > Math.abs(n - val) ? n - val : p),
            Infinity
        ) + val;
    if (influenceItem?.options && influenceItem?.options.length) {
        const foundIndexOfValue = influenceItem?.options.findIndex(
            (it) => it.value === returnVal
        );
        if (foundIndexOfValue > -1) {
            return influenceItem?.options[foundIndexOfValue]?.colorCode;
        }
    }
    return false;
};
const closest = (needle, haystack = []) => {
    if (haystack && haystack.length) {
        return haystack.reduce((a, b) => {
            let aDiff = Math.abs(a - needle);
            let bDiff = Math.abs(b - needle);

            if (aDiff == bDiff) {
                return a > b ? a : b;
            } else {
                return bDiff < aDiff ? b : a;
            }
        });
    }
    return -1;
};

const nearestValue = (val, influenceItem) => {
    let arr = [];
    if (influenceItem?.options && influenceItem?.options.length) {
        arr = [...influenceItem?.options].map((it) => it.value);
    }
    if (
        influenceItem.type === "TYPE2" &&
        influenceItem.options.length &&
        influenceItem.options[val - 1]
    ) {
        return influenceItem.options[val - 1].colorCode;
    }
    const returnVal = closest(val, arr);
    if (
        influenceItem?.options &&
        influenceItem?.options.length &&
        returnVal > -1
    ) {
        const foundIndexOfValue = influenceItem?.options.findIndex(
            (it) => it.value === returnVal
        );
        if (foundIndexOfValue > -1) {
            return influenceItem?.options[foundIndexOfValue]?.colorCode;
        }
    }
    return false;
};
const SocialReportStepsSidePreview = ({
    setSideModalVisible,
    setSelectedFocus,
    isSideModalVisible,
    isSelectedFocus,
    onClose,
    classes,
    allContacts,
    selectedNodes,
    setVisibleManageContactModal,
    handleNodeSelect,
    setVisibleAddContactModal,
    handleOutInfluSelect,
    allDefaultStates,
    allPersonalStates,
    selectedOutInflu,
    setRadioValue,
    radioValue,
    seelctedVal, setSelectedVal,
    dayToSend,
    setdayToSend,
}) => {
    const [dates, setDates] = useState([]);
    const { state: IReportTypeContext, dispatch: reportTypeDispatch } =
        useContext(ReportsTypeContext);
    useEffect(() => {
        if (Object.keys(IReportTypeContext.selectedRow).length) {
            enumerateDaysBetweenDates(
                IReportTypeContext.selectedRow.from,
                IReportTypeContext.selectedRow.till
            );
        } else {
            setDates([]);
        }
        return () => setDates([]);
    }, [IReportTypeContext.selectedRow]);
    const enumerateDaysBetweenDates = (startDate, endDate) => {
        var dates = [];

        var currDate = moment(startDate).subtract(1, "day").startOf("day");
        var lastDate = moment(endDate).endOf("day");

        while (currDate.add(1, "days").diff(lastDate) < 0) {
            dates.push(currDate.clone().toDate());
        }
        setDates(dates);
    };
    const navigate = useNavigate()
    const selectedOutcomes = allDefaultStates && allDefaultStates.length > 0 ? allDefaultStates.find(it => it.groupName === "Outcomes").items.filter(it => selectedOutInflu.includes(it._id)) : []
    const selectedInfluence = allDefaultStates && allDefaultStates.length > 0 ? allDefaultStates.find(it => it.groupName === "Influences").items.filter(it => selectedOutInflu.includes(it._id)) : []
    const [selectedTab, setSelectedTab] = useState("Personal structure")
    const [selectedTabSchedule, setSelectedTabSchedule] = useState("No repeat")
    const [selectedTabScheduleTime, setSelectedTabScheduleTime] = useState("Last 7 days")
    const [selectedTabScheduleTimeMonth, setSelectedTabScheduleTimeMonth] = useState("Last 30 days")
    const [expandedIds, setExpandedIds] = useState([]);
    const renderTree = (nodes, key, onNodeSleect, selectedNode, isParent, showCloseIcon = false, visibleIds = [], setExpandedIds = (ids: any[]) => { }) => (
        <TreeItem
            key={nodes._id}
            nodeId={nodes._id}
            // onClick={handleExpandClick}
            style={{
                display: "flex",
                flexDirection: "column",
                // gap: "16px",

                borderBottom: isParent ? "1px solid var(--color-border)" : "none"
            }}
            className={classes.labelClass}
            label={
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        // borderBottom: isParent ? "1px solid var(--color-border)" : "none",
                        gap: "8px",
                        padding: isParent ? "16px" : "16px",
                    }}
                >
                    <Box display={"flex"} alignItems={"center"} gap={"8px"}>
                        <CustomCheckBox
                            checked={selectedNode.indexOf(nodes._id) !== -1}
                            tabIndex={-1}
                            disableRipple
                            icon={<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.25 3V13C14.25 13.3315 14.1183 13.6495 13.8839 13.8839C13.6495 14.1183 13.3315 14.25 13 14.25H3C2.66848 14.25 2.35054 14.1183 2.11612 13.8839C1.8817 13.6495 1.75 13.3315 1.75 13V3C1.75 2.66848 1.8817 2.35054 2.11612 2.11612C2.35054 1.8817 2.66848 1.75 3 1.75H13C13.3315 1.75 13.6495 1.8817 13.8839 2.11612C14.1183 2.35054 14.25 2.66848 14.25 3ZM12.75 3.25H3.25V12.75H12.75V3.25Z" fill="#3E4240" />
                            </svg>
                            } checkedIcon={<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.96938 9.03063C4.89961 8.96086 4.84427 8.87804 4.80651 8.78689C4.76876 8.69573 4.74932 8.59804 4.74932 8.49938C4.74932 8.40071 4.76876 8.30302 4.80651 8.21186C4.84427 8.12071 4.89961 8.03789 4.96938 7.96812C5.03914 7.89836 5.12196 7.84302 5.21311 7.80526C5.30427 7.76751 5.40196 7.74807 5.50063 7.74807C5.59929 7.74807 5.69698 7.76751 5.78814 7.80526C5.87929 7.84302 5.96211 7.89836 6.03188 7.96812L7 8.9375L9.96937 5.9675C10.1103 5.8266 10.3014 5.74745 10.5006 5.74745C10.6999 5.74745 10.891 5.8266 11.0319 5.9675C11.1728 6.1084 11.2519 6.29949 11.2519 6.49875C11.2519 6.69801 11.1728 6.8891 11.0319 7.03L7.53188 10.53C7.4622 10.5999 7.3794 10.6554 7.28824 10.6933C7.19708 10.7311 7.09934 10.7506 7.00063 10.7506C6.90191 10.7506 6.80417 10.7311 6.71301 10.6933C6.62185 10.6554 6.53905 10.5999 6.46938 10.53L4.96938 9.03063ZM14.25 3V13C14.25 13.3315 14.1183 13.6495 13.8839 13.8839C13.6495 14.1183 13.3315 14.25 13 14.25H3C2.66848 14.25 2.35054 14.1183 2.11612 13.8839C1.8817 13.6495 1.75 13.3315 1.75 13V3C1.75 2.66848 1.8817 2.35054 2.11612 2.11612C2.35054 1.8817 2.66848 1.75 3 1.75H13C13.3315 1.75 13.6495 1.8817 13.8839 2.11612C14.1183 2.35054 14.25 2.66848 14.25 3ZM12.75 3.25H3.25V12.75H12.75V3.25Z" fill="#3E4240" />
                            </svg>
                            }
                            onClick={(event) => onNodeSleect(event, nodes)}
                            disabled={isParent && (!Array.isArray(nodes[key]) || !nodes[key].length)}
                        />
                        <CommonTypo size={14} lineHeight={18.2} fontWeight={600} color="var(--color-text-primary)">
                            {nodes.groupName || nodes.name}{" "}
                            {nodes[key] ? "(" + nodes[key].length + ")" : null}
                        </CommonTypo>
                    </Box>

                    {nodes[key] && showCloseIcon && <Box onClick={() => {
                        if (visibleIds.includes(nodes._id)) {
                            const filtered = [...visibleIds].filter(it => it !== nodes._id)
                            setExpandedIds(filtered)
                        } else {
                            const filtered = [...visibleIds]
                            filtered.push(nodes._id)
                            setExpandedIds(filtered)
                        }
                    }}>{visibleIds.includes(nodes._id) ? <ArrowDownNew color="var(--color-text-primary)" /> : <ArrowUpNew color="var(--color-text-primary)" />}</Box>}
                </Box>
            }
        >
            {showCloseIcon ? (
                Array.isArray(nodes[key]) && visibleIds.includes(nodes._id)
                    ? nodes[key].map((node) =>
                        renderTree(node, key, onNodeSleect, selectedNode, false, showCloseIcon, visibleIds, setExpandedIds)
                    )
                    : null
            ) : Array.isArray(nodes[key])
                ? nodes[key].map((node) =>
                    renderTree(node, key, onNodeSleect, selectedNode, false, showCloseIcon, visibleIds, setExpandedIds)
                )
                : null}
            { }
        </TreeItem>
    );
    console.log("IReportTypeContext.selectedRowDetails", IReportTypeContext.selectedRowDetails);

    return <>
        <Box
            display={"flex"}
            height={"100%"}
            borderLeft={"1px solid var(--color-border)"}
            flexDirection={"column"}
            gap={"16px"}
            alignItems={"flex-start"}
            alignSelf={"stretch"}
            padding={"32px 24px"}
            justifyContent={"flex-start"}
            sx={{
                width: "660px",
                height: "100%",
                overflowY: "auto"
            }} className="hide-scrollbar">
            <Box display={"flex"} alignItems={"flex-end"} alignSelf={"stretch"} justifyContent={"flex-end"}>
                <CloseButton onClick={onClose} />
            </Box>
            <Box display={"flex"} alignItems={"flex-start"} alignSelf={"stretch"}>
                <CommonTypo size={16} lineHeight={19.2} fontWeight={600}>Preview of the metrics</CommonTypo>
            </Box>
            {IReportTypeContext.selectedRowDetails?.outcome &&
                IReportTypeContext.selectedRowDetails?.outcome.length > 0 &&
                IReportTypeContext.selectedRowDetails?.outcome?.map(it => <ResultBox dates={dates} item={it} isOutcome={true} radioValue={radioValue} />)}
            {IReportTypeContext.selectedRowDetails?.influence &&
                IReportTypeContext.selectedRowDetails?.influence.map((it) => {
                    return (
                        <ResultBox
                            isOutcome={false}
                            key={it.influenceId}
                            item={it}
                            dates={dates}
                            radioValue={radioValue}
                        />
                    );
                })}
        </Box>
    </>
}

export default SocialReportStepsSidePreview

const ResultBox = ({ item, isOutcome, dates, radioValue }) => {
    const [showFullData, setShowFullData] = useState(false)
    const [isShowMore, setShowMore] = useState(false)
    console.log("dates", dates, "item", item);
    const top3Influe = item.influence && item.influence.length > 0 && !isShowMore ? item.influence.slice(0, 3)
        : item.influence && item.influence.length > 0 && isShowMore ? item.influence.filter(it => it.correlation > 0).slice(0, 8) : [];
    const top5PositiveInflue = item.influence && item.influence.length > 0 && !isShowMore ? item.influence.filter(it => it.correlation > 0).slice(0, 2)
        : item.influence && item.influence.length > 0 && isShowMore ? item.influence.filter(it => it.correlation > 0).slice(0, 8) : [];
    const top5NegativeInflue = item.influence && item.influence.length > 0 && !isShowMore ? item.influence.filter(it => it.correlation < 0).slice(-2) :
        item.influence && item.influence.length > 0 && isShowMore ? item.influence.filter(it => it.correlation < 0).slice(-8) : [];
    const top10influence = [...top5PositiveInflue, ...top5NegativeInflue]
    const heighestPositiveCorrelation = Math.max(
        ...top3Influe.map((o) => Math.abs(o.correlation || 0))
    );
    console.log("item", item);

    return <Box alignSelf={"stretch"} sx={{
        padding: "16px",
        gap: "32px",
        display: "flex",
        flexDirection: "column",
        borderRadius: "12px",

    }} className="hide-scrollbar">
        <Box display={"flex"} alignItems={"center"} alignSelf={"stretch"} gap={"16px"}>
            <Box display={"flex"} alignItems={"center"} alignSelf={"stretch"} width={"calc(100% - 20px)"}>
                <Box display={"flex"} alignItems={"center"} alignSelf={"stretch"} gap={"12px"} width={"100%"}>
                    <Box display={"flex"} alignItems={"center"} justifyContent={"center"} height={"36px"} width={"36px"} borderRadius={"5px"} sx={{
                        background: "var(--color-new-main)",
                    }} >
                        {isOutcome ? <svg width="24" height="24" viewBox="0 0 24 24">
                            <path d={item.icon} fill="#FFF" />
                        </svg> : <InfluenceIcon color={"#FFF"} />}
                    </Box>
                    <Box display={"flex"} alignItems={"center"} alignSelf={"stretch"} width={"calc(100% - 36px)"} justifyContent={"space-between"}>
                        <Box display={"flex"} alignItems={"flex-start"} flexDirection={"column"} gap={"4px"}>
                            <CommonTypo size={16} lineHeight={16} fontWeight={600} color="var(--color-text-primary)">{item.name}</CommonTypo>
                            <CommonTypo size={12} lineHeight={12} fontWeight={400} color="var(--color-text-secondary)">{"Current Avg."}</CommonTypo>
                        </Box>
                        <Box>
                            <CommonTypo size={16} lineHeight={16} fontWeight={600} color="var(--color-text-primary)">
                                {Number(item.weekAverage) ? parseFloat(item.weekAverage).toFixed(2) : "-"}
                            </CommonTypo>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box display={"flex"} alignItems={"center"} alignSelf={"stretch"} sx={{
                cursor: "pointer"
            }} width={"20px"} onClick={() => {
                setShowFullData(!showFullData)
            }}>
                {!showFullData ? <ArrowUpNew color="var(--color-text-primary)" /> : <ArrowDownNew color="var(--color-text-primary)" />}
            </Box>
        </Box>
        {showFullData && <>
            <Box display={"flex"} flexDirection={"column"} gap={"8px"} width={"100%"} sx={{
                overflowX: "auto"
            }} className="hide-scrollbar">
                <Box display={"flex"} gap={"8px"} alignItems={"center"} justifyContent={radioValue === "REGULARLY_WEEK" ? "space-evenly" : "flex-start"}>
                    {dates.map(it => <Box sx={{
                        height: "40px",
                        width: "40px",
                        minWidth: "40px",
                        padding: "12px 4px",
                        borderRadius: "8px",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        gap: "4px"
                    }}>
                        <CommonTypo size={10} lineHeight={10} fontWeight={400} color="var(--color-text-secondary)">{moment(it).format("ddd")}</CommonTypo>
                        <CommonTypo size={14} lineHeight={14} fontWeight={400} color="var(--color-text-primary)">{moment(it).format("DD")}</CommonTypo>

                    </Box>)}
                </Box>
                <Box display={"flex"} gap={"8px"} alignItems={"center"} justifyContent={radioValue === "REGULARLY_WEEK" ? "space-evenly" : "flex-start"}>
                    {dates.map(it => {
                        let bgColor = "#E3E3E3";
                        let textValue = "-";
                        if (isOutcome) {
                            const foundDateData = item.outcomeDataDates.findIndex(
                                (ite) => ite.date === moment(it).format("MM-DD-YYYY")
                            );
                            if (foundDateData > -1) {
                                textValue = item.outcomeDataDates[foundDateData].index[0];
                                let nearValue = nearestValueOutcome(textValue, item);
                                if (item?.type === "TYPE2") {
                                    nearValue = null;
                                }
                                if (
                                    item?.outcomeId &&
                                    item?.options?.length &&
                                    item?.options[item?.index - 1]
                                ) {
                                    nearValue = item.options[item?.index - 1].colorCode;
                                }
                                if (nearValue) {
                                    bgColor = nearValue;
                                }
                            }
                        }
                        if (!isOutcome) {
                            const foundDateData = item.influenceDataDates.findIndex(
                                (ite) => ite.date === moment(it).format("MM-DD-YYYY")
                            );
                            console.log("item", item);
                            
                            if (foundDateData > -1) {
                                textValue = item.influenceDataDates[foundDateData].index[0];
                                let nearValue = nearestValue(
                                    item.type === "TYPE1" || item.type === "TYPE12"
                                        ? textValue
                                        : textValue,
                                    item
                                );
                                if (item.type === "TYPE2") {
                                    if (
                                        item?.type === "TYPE2" &&
                                        item.options.length &&
                                        item.options[item.index - 1]
                                    ) {
                                        nearValue = item.options[item.index - 1].colorCode;
                                    }
                                }
                                if (item?.type === "TYPE3") {
                                    if (item?.inverse === "true" && textValue === "1") {
                                        nearValue = "var(--color-new-main)";
                                    } else if (item?.inverse === "true" && textValue === "0") {
                                        nearValue = "#F17F79";
                                    } else if (item?.inverse === "false" && textValue === "0") {
                                        nearValue = "#F17F79";
                                    } else if (item?.inverse === "false" && textValue === "1") {
                                        nearValue = "var(--color-new-main)";
                                    }
                                }

                                if (nearValue) {
                                    bgColor = nearValue;
                                } else {
                                    bgColor = "var(--color-new-main)"
                                }
                            }
                        }
                        return <Box sx={{
                            height: "40px",
                            width: "40px",
                            minWidth: "40px",
                            padding: "12px 4px",
                            borderRadius: "8px",
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            justifyContent: "center",
                            gap: "4px",
                            background: bgColor
                        }}>
                            <CommonTypo size={14} lineHeight={14} fontWeight={600} color="var(--color-text-primary)">{item?.type !== "TYPE3" && textValue}</CommonTypo>
                        </Box>
                    })}
                </Box>
            </Box>
            {isOutcome && top3Influe.length > 0 && (
                <Box display={"flex"} flexDirection={"column"} gap={"16px"} width={"100%"}>
                    <CommonTypo size={16} lineHeight={16} fontWeight={600} color="var(--color-text-primary)">Top influences</CommonTypo>
                    {top3Influe.map((ite, index) => {
                        return <InformationBox
                            number={index + 1}
                            title={ite.name}
                            star={ite.star}
                            correlation={ite.correlation}
                            strongTitle={ite.message}
                            nutral={index >= 5}
                            positive={ite.correlation > 0}
                            dataPoints={ite?.dataPoints || 0}
                            regression={ite?.regression?.slope}
                            maxCorrekation={heighestPositiveCorrelation}
                        />
                    })}
                </Box>
            )}
        </>
        }

    </Box >
}

export const InformationBox = ({
    positive = true,
    nutral = false,
    number = 1,
    title = "Meditation",
    strongTitle = "Very Strong",
    correlation = 1,
    regression = 0.45,
    star = 4,
    maxCorrekation = 1,
    dataPoints = 0,
    isFromSocialReport = false
}) => {
    const stars = [];
    // for (let index = 0; index < star; index++) {
    //     stars.push(
    //         <>
    //             <Star color={"#F9D412"} size={16} />
    //         </>
    //     );
    // }
    if (star > 0) {
        stars.push(
            <>
                <Star color={"#F9D412"} size={16} />
            </>
        );
    }
    // for (let index = star; index < 5; index++) {
    //     stars.push(
    //         <>
    //             <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    //                 <path d="M6.16432 4.44738L8 1.76891L9.83568 4.44738C10.0697 4.78889 10.4144 5.03928 10.8115 5.15635L13.9261 6.07449L11.946 8.64801C11.6935 8.97614 11.5619 9.38128 11.5733 9.79515L11.6625 13.0411L8.60309 11.9531C8.21299 11.8144 7.78701 11.8144 7.39691 11.9531L4.33746 13.0411L4.42673 9.79515C4.43811 9.38128 4.30647 8.97615 4.054 8.64801L2.07388 6.07449L5.18851 5.15635C5.58564 5.03928 5.93027 4.78889 6.16432 4.44738Z" stroke="#EEEEEE" stroke-width="2" />
    //             </svg>
    //         </>
    //     );
    // }
    const CorrelationnWidth =
        (Math.abs(correlation) * 100) / maxCorrekation;
        let widthProps = {}
        if(isFromSocialReport) {
            widthProps = {
                minWidth: "111px"
            }
        }
    return <Box sx={{
        display: "flex",
        gap: number > 9 ? "26px" : "32px",
        alignItems: "flex-start"
    }}>
        <CommonTypo size={16} color="#19442E" lineHeight={19} fontWeight={600} extraSx={{
            textAlign: "center"
        }}>
            {number}
        </CommonTypo>
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            width: "100%",
        }}>
            <Box sx={{
                width: `100%`,
                // height: "48px",
                padding: "0px",
                borderRadius: "12px",
                // background: nutral ? "#EEEEEE" : !positive ? "#FFA9A9" : "#ADE888",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
            }}>
                <Box sx={{
                    display: "flex",
                    gap: "12px",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                }}>
                    {/* <CommonTypo size={16} color="#3E4240" lineHeight={16} fontWeight={600} extraSx={{
                        // overflow: 'hidden',
                        display: '-webkit-box',
                        lineClamp: 1,
                        boxOrient: 'vertical',
                        textOverflow: 'ellipsis',
                        width: "calc(100% - 180px)"
                    }}>
                        {title}
                    </CommonTypo> */}
                    <CommonTypo size={16} color="#3E4240" lineHeight={20} title={title} fontWeight={600} extraSx={{
                        flexGrow: 1, // Allow the title to take up remaining space
                        flexShrink: 1, // Allow the title to shrink if needed
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        // width: "calc(100% - 200px)"
                    }}>{title.length > 30 ? `${title.slice(0, 47)}...` : title}</CommonTypo>
                    <Box sx={{
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                    }}>
                        <CommonTypo size={14} color="#3E4240" lineHeight={14} fontWeight={400} >
                            {strongTitle}
                        </CommonTypo>
                        <CommonTypo size={16} color="#3E4240" lineHeight={16} fontWeight={600} >
                            {correlation.toString()}
                        </CommonTypo>
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                width: `${correlation && correlation !== 0
                    ? CorrelationnWidth
                    : 0
                    }%`,
                height: "8px",
                padding: "0px",
                borderRadius: "12px",
                background: nutral ? "#EEEEEE" : !positive ? "#FFA9A9" : "#ADE888",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
            }}>

            </Box>
            <Box sx={{
                width: "100%",
                height: "48px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "8px"
            }}>
                <Box sx={{
                    height: "48px",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px"
                }}>
                    <Box sx={{
                        height: "48px",
                        padding: "14px 6px",
                        gap: "4px",
                        display: "flex",
                        flexDirection: isFromSocialReport ? "column" : "row",
                        alignItems: isFromSocialReport ? "flex-start" : "center",
                        borderRadius: "12px",
                        ...widthProps
                        // border: "1px solid #EEEEEE"
                    }}>
                        <CommonTypo size={14} color="#3E4240" lineHeight={14} fontWeight={400} >
                            {"Regression"}
                        </CommonTypo>
                        <CommonTypo size={16} color="#3E4240" lineHeight={16} fontWeight={500} >
                            {regression}
                        </CommonTypo>
                    </Box>
                    <Box sx={{
                        height: "48px",
                        padding: "14px 12px",
                        gap: "4px",
                        display: "flex",
                        flexDirection: isFromSocialReport ? "column" : "row",
                        alignItems: isFromSocialReport ? "flex-start" : "center",
                        borderRadius: "12px",
                        ...widthProps
                        // border: "1px solid #EEEEEE"
                    }}>
                        <CommonTypo size={14} color="#3E4240" lineHeight={14} fontWeight={400} >
                            {"Significance"}
                        </CommonTypo>
                        <Box sx={{
                            display: "flex",
                            gap: "4px",
                            alignItems: "center",
                        }}>
                            {stars}
                            <CommonTypo size={16} color="#3E4240" lineHeight={16} fontWeight={500} >
                                {star}
                            </CommonTypo>
                        </Box>

                    </Box>
                    <Box sx={{
                        height: "48px",
                        padding: "14px 12px",
                        gap: "4px",
                        display: "flex",
                        flexDirection: isFromSocialReport ? "column" : "row",
                        alignItems: isFromSocialReport ? "flex-start" : "center",
                        borderRadius: "12px",
                        ...widthProps
                        // border: "1px solid #EEEEEE"
                    }}>
                        <CommonTypo size={14} color="#3E4240" lineHeight={14} fontWeight={400} >
                            {"Data points"}
                        </CommonTypo>
                        <CommonTypo size={16} color="#3E4240" lineHeight={16} fontWeight={500} >
                            {dataPoints.toString()}
                        </CommonTypo>

                    </Box>
                </Box>

            </Box>

        </Box>
    </Box>
}