import React, { useContext, useState } from "react";
import clsx from "clsx";

import { UserTypeContext } from "../../context/userStatus";
import { TrackingContext } from "../../context/userTracking";
import { ContactsContext } from "../../context/userIContacts";

import {
  AddUpdateQuestion,
  InfluenceEdit,
  InfluenceToInfluence,
  TransformInfluToOutcome,
  TransfotmToInfluenceOutcome,
  UpdateOutome,
} from "./trackerAddDrawer";
import useStyles from "./TrackingClasses";
import ModeSwitchTab from "../tracker/ModeSwitchTab";
import {
  setOpenCategoryModal,
} from "../../store/actions/trackerAction";
import { setShareModalOpen } from "../../store/actions/contactsActions";
import CurrentDateTitle from "./CurrentDateTitle";
import ItemRow from "./ItemRow";

interface TrackBodyModal {
  createDate: any;
  getInfluenceList: any;
  getReflectiveList: any;
  getOutcomeList: any;
  prevMonth: any;
  nextMonth: any;
  isExpandable?: boolean;
  selectedDate: any;
  setSelectedDateInfluence: (val: any) => void;
  influenceId: any;
  setInfluenceIdCalendar: (val: any) => void;
  callservice: any;
}
const TrackBody = (props: TrackBodyModal) => {
  const classes = useStyles();
  const { state: outcomeState, dispatch: userTypeDispatch } =
    useContext(UserTypeContext);
  const { state: ITrackerState, dispatch: trackingDispatch } =
    useContext(TrackingContext);
  const { calendar } = ITrackerState;
  const { dispatch: contactsDispatch } = useContext(ContactsContext);
  const [outcomeId, setOutcomeId] = useState("");
  const [outcomeInput, setShowOutcomeInput] = useState(false);
  const [influenceInput, setShowInfluenceInput] = useState(false);
  const [influenceId, setInfluenceId] = useState("");
  const [refId, setRefId] = useState("");
  let addHeight = 0;
  const foundElement = document.getElementById("track-quote");
  if (foundElement) {
    const rect = foundElement.getBoundingClientRect();
    if (rect.height) {
      addHeight = rect.height - 10;
    }
  }
  return (
    <>
      <div className={`${classes.trackBody} commonTrackBody123`}>
        <div
          style={{
            width: "100%",
            height: "auto",
          }}
        >
          <table
            className={classes.tableClass}
            border={0}
            cellPadding={0}
            cellSpacing="0"
            id="main-table"
            style={{
              height: `calc(100vh - ${addHeight + 100}px)`,
            }}
          >
            <thead>
              <tr className={clsx(classes.row, classes.stickeyClass)}>
                <th
                  className={clsx(classes.headerSwitch, classes.stickeyClass)}
                >
                  <div
                    style={{
                      position: "sticky",
                      top: 0,
                      alignSelf: "flex-start",
                      // zIndex: 100,
                      background: "#fff",
                      zIndex: 1,

                      minWidth: "100%",
                      maxWidth: "100%",
                    }}
                  >
                    <ModeSwitchTab
                      handleClickOpen={() => {
                        trackingDispatch(setOpenCategoryModal(true));
                      }}
                      handleShareModalOpen={(val) => {
                        contactsDispatch(setShareModalOpen(val));
                      }}
                    />
                  </div>
                </th>
                <th
                  className={clsx(classes.headerDate, classes.stickeyClassDate)}
                  colSpan={calendar[0] && calendar[0].length}
                >
                  <CurrentDateTitle
                    prevMonth={props.prevMonth}
                    nextMonth={props.nextMonth}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              <ItemRow
                // index={index}
                journyList={outcomeState?.journeyList}
                setOutcomeId={setOutcomeId}
                outcomeId={outcomeId}
                setInfluenceId={setInfluenceId}
                influenceId={influenceId}
                refId={refId}
                callservice={props?.callservice}
                setRefId={setRefId}
                setShowOutcomeInput={setShowOutcomeInput}
                setShowInfluenceInput={setShowInfluenceInput}
                setInfluenceIdCalendar={props.setInfluenceIdCalendar}
                setSelectedDateInfluence={props.setSelectedDateInfluence}
              />
            </tbody>
          </table>
        </div>
        <UpdateOutome />
        <InfluenceEdit getInfluenceList={props?.getInfluenceList} />
        <TransformInfluToOutcome callservice={props.callservice}/>
        <TransfotmToInfluenceOutcome callservice={props.callservice}/>
        <InfluenceToInfluence callservice={props.callservice}/>
        <AddUpdateQuestion getReflectiveList={props?.getReflectiveList} />
      </div>
    </>
  );
};

export default TrackBody;
