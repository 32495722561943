import * as React from "react";
import { makeStyles } from "@material-ui/core";
import palette from "../../theme/palette";

const useStyles = makeStyles((theme) => ({
  stepperInput: {
    width: "144px",
    // height: "76px",
    padding: "15px 8px",
  },
  stepperField: {
    border: 9,
    width: "100%",
    padding: "5px",
    outlineColor: "blue"
  },
  stepperText: {
    fontSize: "16px",
    lineHeight: "18px",
    fontWeight: 400,
    color: palette.primary.light,
    padding: "15px 8px",
    margin: 0,
    // height: "74px",
  },
}));

interface EditOutcomeStatusModal {
  showInput: boolean;
  index: number;
  textInput: any;
  onValueChange: (e: any) => void;
  editStatus: number;
}

const EditOutcomeStatus = (props: EditOutcomeStatusModal) => {
  const classes = useStyles();
  return (
    <>
      {props.showInput && props.editStatus === props.index ? (
        <div className={classes.stepperInput}>
          <input
            autoFocus
            name={props.index + ""}
            type="text"
            className={classes.stepperField}
            value={props.textInput[props?.index]?.text}
            onChange={(e) => props.onValueChange(e)}
          />
        </div>
      ) : (
        <p className={classes.stepperText}>
          {props.textInput[props?.index]?.text}
        </p>
      )}
    </>
  );
};
export default EditOutcomeStatus;
