import axios from "axios";
import { baseEndpoints } from "./config";

const headerToken = localStorage.getItem("userDetails");

const getNotificationList = async (token: string) => {
  const { data } = await axios
    .post(
      `${baseEndpoints.notification}/get-notification`,
      {},
      {
        headers: {
          "x-access-token": token ? token : headerToken,
        },
      }
    )
    .then((response) => {
      return response;
    });
  return data;
};

const readNotification = async (influenceValue: any, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.notification}/read-notification`, influenceValue, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};


export const notificationService = {
  getNotificationList,
  readNotification,
};
