import {
  DialogContent,
  Button,
  Dialog,
  DialogActions,
  Typography,
} from "@mui/material";
import React from "react";
import { makeStyles } from "@material-ui/core";
import palette from "../../theme/palette";
import { pxToRem } from "../../theme/typography";
import NewButton from "../../components/NewButton";

interface IQuesclosePopup {
  open: boolean;
  setopenQuesclosePopup: any;
  setTransformToInfluenceTracking?: any;
  setAddOutcomeTracking?: any;
  setShowOutcome?: any;
  outcomeValueInput?: any;
  SelectRow?: any;
  handleCloseStartModal?: any;
  isDelete?: boolean;
  setBackbuttonPress?: any;
}

const useStyles = makeStyles((theme) => ({
  categoryDialog: {
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        borderRadius: "24px",
        padding: "0",
        display: "flex",
        // width: '400px',
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",

        "& .MuiDialogContent-root": {
          padding: "0",
          overflowY: "unset",
        },

        "&. MuiDialogActions-root": {},
        "@media  (max-width: 600px) and (min-width: 100px)": {
          margin: '32px 0px',
          overflow: 'hidden'
        },
      },
    },
  },
  categoryDialogAction: {
    display: "flex !important",
    alignItems: "center !important",
    justifyContent: "center !important",
    gap: "12px",
    alignSelf: "stretch !important",
    padding: "32px !important",
    borderTop: `1px solid #F3F3F3`,
  },
  paddingClassMobile: {
    "@media  (max-width: 700px) and (min-width: 100px)": {
      padding: "32px 32px 6px !important",
    },
  },
}));
const QuesclosePopup = (props: IQuesclosePopup) => {
  const classes = useStyles();

  return (
    <Dialog
      open={props.open}
      onClose={() => props?.setopenQuesclosePopup(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.categoryDialog}
    >
      <DialogContent id="alert-dialog-description">
        <div
          style={{
            display: "flex",
            padding: "32px 32px 0px 32px",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "16px",
            alignSelf: "stretch",
          }}
          className={classes.paddingClassMobile}
        >
          <Typography
            variant="h3"
            component="span"
            lineHeight="135% !important"
            sx={{
              width: "100%",
              textAlign: "center",
              color: palette.common.black,
              fontSize: `${pxToRem(24)} !important`,
              lineHeight: "120% !important",
              fontWeight: `600 !important`,
              fontFamily: "'Poppins' !important",
            }}
          >
            {/* Are you sure to transform {props?.outcomeValueInput} to an influence? */}
            {/* Are you sure to recover the history version {props.SelectRow.name}? */}
            Are you sure you want to{" "}
            {props?.isDelete
              ? "delete the entry? You will lose the data"
              : "close the window? You will lose unsaved data"}
          </Typography>
        </div>
      </DialogContent>
      <DialogActions className={classes.categoryDialogAction}>
        <NewButton
          buttonText="No, let me save"
          variant="contained"
          type="submit"
          onClick={() => {
            props?.setBackbuttonPress(false);
            props?.setopenQuesclosePopup(false);
          }}
        />
        <NewButton
          buttonText={`Yes, ${props?.isDelete ? "delete" : "close"}`}
          variant="outlined"
          onClick={props?.handleCloseStartModal}
        />
      </DialogActions>
    </Dialog>
  );
};

export default QuesclosePopup;
