import { Drawer, Grid, IconButton, Typography } from "@mui/material";
import * as React from "react";
import palette from "../../theme/palette";
import { makeStyles } from "@material-ui/core";
import ModalCloseIconWhite from "../../assets/images/icon/modalCloseIconWhite";
import shape from "../../theme/shape";

interface IdeasDrawerModal {
  openDrawer: boolean;
  setOpenDrawer: (openDrawer: boolean) => void;
  reflectiveIdeas: any;
  setQuestionData: (questionData: string) => void;
}

const useStyles = makeStyles((theme) => ({
  drawerWrapper: {
    padding: "60px 40px",
    [theme.breakpoints.down("lg")]: {
      padding: "50px 30px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "30px 20px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
    },
  },
  ideasList: {
    marginTop: "40px",
    [theme.breakpoints.down("lg")]: {
      marginTop: "30px",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "20px",
    },
  },
  ideasListItem: {
    backgroundColor: palette.common.white,
    borderRadius: shape.borderRadius,
    padding: "24px",
    fontSize: "16px",
    lineHeight: "16px",
    color: palette.primary.light,
    fontWeight: 400,
    marginBottom: "12px",
    cursor:'pointer',
    [theme.breakpoints.down("md")]: {
      padding: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
  },
}));

const IdeasDrawer = (props: IdeasDrawerModal) => {
  const classes = useStyles();
  return (
    <Drawer
      className="drawerLayout"
      open={props.openDrawer}
      onClose={() => props.setOpenDrawer(false)}
      anchor="right"
    >
      <Grid
        container
        sx={{ backgroundColor: palette.primary.main }}
        className={classes.drawerWrapper}
      >
        <Grid>
          <IconButton
            className="closeIcon"
            onClick={() => props.setOpenDrawer(false)}
          >
            <ModalCloseIconWhite />
          </IconButton>
          <Typography
            variant="h3"
            component="h3"
            sx={{
              color: palette.common.white,
              fontWeight: 600,
              lineHeight: "35px!important",
            }}
          >
            Here are some ideas of journaling questions you might want to
            consider
          </Typography>
        </Grid>
        <Grid className={classes.ideasList} sx={{ width: "100%" }}>
          {props?.reflectiveIdeas?.map((ideas: any) => {
            return (
              <div className={classes.ideasListItem} key={ideas?._id} onClick={()=>props.setQuestionData(ideas)}>
                {ideas?.question}
              </div>
            );
          })}
        </Grid>
      </Grid>
    </Drawer>
  );
};
export default IdeasDrawer;
