import {
  DialogContent,
  Button,
  Dialog,
  DialogActions,
  Typography,
} from "@mui/material";
import React from "react";
import { makeStyles } from "@material-ui/core";
import NewButton from "../../components/NewButton";

interface IRecoverModal {
  open: boolean;
  setopenRecoverModal: any;
  restoreData: any;
  actionId: any;
  RowData?: any;
}

const useStyles = makeStyles((theme) => ({
  categoryDialog: {
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        borderRadius: "20px",
        padding: "48px 40px",
        width: "682px",

        "& .MuiDialogContent-root": {
          padding: "0",
          overflowY: "unset",
        },

        "&. MuiDialogActions-root": {},
      },
    },
  },
  categoryDialogAction: {
    display: "flex !important",
    alignItems: "center !important",
    justifyContent: "end !important",
  },
}));
const RecoverModal = (props: IRecoverModal) => {
  const classes = useStyles();

  return (
    <Dialog
      open={props.open}
      onClose={() => props?.setopenRecoverModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.categoryDialog}
    >
      <DialogContent id="alert-dialog-description">
        <Typography variant="h3" component="h3" lineHeight="120% !important">
          {/* Are you sure to transform {props?.outcomeValueInput} to an influence? */}
          Are you sure to restore the metric ‘{props?.RowData?.name}’?
          {/* '{props.SelectRow.name}#{props.ReverseValue}'? */}
        </Typography>
        <Typography
          variant="subtitle1"
          component="div"
          sx={{ mt: "20px", display: "flex" }}
        >
          <span style={{ color: "red", marginRight: "10px", fontSize: "20px" }}>
            !
          </span>
          You will have access to the restored metric, including tracking data,
          goal and completion
        </Typography>
        {/* <Typography variant="subtitle1" component="div" sx={{ mt: "20px", display: "flex" }}>
                    <span style={{ color: "red", marginRight: "10px", fontSize: "20px" }}>!</span>
                    You will have access to the restored version, including tracking data, goal and completion.
                </Typography>
                <Typography variant="subtitle1" component="div" sx={{ mt: "20px", display: "flex" }}>
                    <span style={{ color: "red", marginRight: "10px", fontSize: "20px" }}>!</span>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </Typography> */}
      </DialogContent>
      <DialogActions
        sx={{ mt: "60px" }}
        className={classes.categoryDialogAction}
      >
        <NewButton
          type="submit"
          variant="contained"
          onClick={() => props?.setopenRecoverModal(false)}
          buttonText="No, take me back"
        />
         <NewButton
          variant="outlined"
          onClick={() => {
            props?.setopenRecoverModal(false);
            props?.restoreData(props?.actionId);
          }}
          buttonText="Yes"
        />
      </DialogActions>
    </Dialog>
  );
};

export default RecoverModal;
