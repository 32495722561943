import React, { useEffect, useState } from 'react';
import './ProgressBar.css';

const ProgressBar = ({ progress = 0 }) => {
  return (
    <div className="progress-container">
      <div className="progress-bar" style={{ width: `${progress}%` }}>
        <div className="progress-stripes"></div>
      </div>
    </div>
  );
};

export default ProgressBar;
