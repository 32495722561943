import React from "react";
import { makeStyles } from "@material-ui/core";
import palette from "../../theme/palette";
import InfluenceTickIcon from "../../assets/images/icon/influenceTickIcon";
import shape from "../../theme/shape";

const useStyles = makeStyles((theme) => ({
  recommText: {
    backgroundColor: palette.common.white,
    borderRadius: shape.borderRadius,
    fontSize: "16px",
    lineHeight: "36px",
    color: palette.primary.light,
    padding: "12px 16px",
    cursor: "pointer",
    marginBottom: "12px",
    wordBreak: "break-word",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
  },
  influenceIcon: {
    verticalAlign: "text-top",
    marginRight: "12px",
    display: "inline-flex",
  },
}));

interface AddInfluenceLabelModal {
  influenceItem: any;
  key: number;
  setInfluenceData: (influenceData: string) => void;
}

const AddInfluenceLabel = (props: AddInfluenceLabelModal) => {
  const classes = useStyles();
  return (
    <div
      className={classes.recommText}
      onClick={() => props.setInfluenceData(props.influenceItem)}
    >
      <span className={classes.influenceIcon}>
        <InfluenceTickIcon />
      </span>
      {props?.influenceItem.name}
    </div>
  );
};
export default AddInfluenceLabel;
