import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import queryParams from "../../utils/getQueryParams"

const TrackingConfirmation = () => {
    const navigate = useNavigate()
    const queryData = queryParams()
    useEffect(() => {
        const values = JSON.parse(localStorage.getItem("userValues"))
        console.log("window.dataLayer", values, (window as any).dataLayer);
        (window as any).dataLayer.push({
            event: 'registration_completed',
            query: queryData.qeryString,
            userEmail: values.user_email
        })
        setTimeout(() => {
            navigate("/tracking")
        }, 3000);
    }, [])
    return null
}

export default TrackingConfirmation