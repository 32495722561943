const queryParams = () => {
    var params = new URLSearchParams(window.location.search)
    let obj: any = {}
    for (let p of params) {
        if(p && p.length) {
            obj[p[0]] = p[1]
        }
    }
    return {
        queryobj: obj,
        qeryString: window.location.search
    }
}

export default queryParams