import React from "react"

const InfluenceIcon = ({
    color = "#656565"
}) => {
    return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.0369 10.6368L15.6369 13.0368C15.4678 13.2059 15.2385 13.3009 14.9994 13.3009C14.7603 13.3009 14.5309 13.2059 14.3619 13.0368C14.1928 12.8677 14.0978 12.6384 14.0978 12.3993C14.0978 12.1602 14.1928 11.9309 14.3619 11.7618L15.2251 10.9001H3.97512L4.83912 11.7633C5.0082 11.9324 5.10319 12.1617 5.10319 12.4008C5.10319 12.6399 5.0082 12.8692 4.83912 13.0383C4.67005 13.2074 4.44073 13.3024 4.20162 13.3024C3.96252 13.3024 3.7332 13.2074 3.56412 13.0383L1.16412 10.6383C1.08022 10.5547 1.01365 10.4553 0.968222 10.346C0.922797 10.2366 0.899414 10.1193 0.899414 10.0008C0.899414 9.88236 0.922797 9.76508 0.968222 9.65568C1.01365 9.54628 1.08022 9.44693 1.16412 9.36332L3.56412 6.96332C3.7332 6.79424 3.96252 6.69925 4.20162 6.69925C4.32002 6.69925 4.43725 6.72257 4.54664 6.76788C4.65602 6.81319 4.75541 6.8796 4.83912 6.96332C4.92284 7.04703 4.98925 7.14642 5.03456 7.2558C5.07987 7.36519 5.10319 7.48242 5.10319 7.60082C5.10319 7.71921 5.07987 7.83645 5.03456 7.94583C4.98925 8.05521 4.92284 8.1546 4.83912 8.23832L3.97512 9.10007H15.2251L14.3611 8.23681C14.2774 8.1531 14.211 8.05371 14.1657 7.94433C14.1204 7.83494 14.0971 7.71771 14.0971 7.59931C14.0971 7.48092 14.1204 7.36368 14.1657 7.2543C14.211 7.14492 14.2774 7.04553 14.3611 6.96181C14.5302 6.79274 14.7595 6.69775 14.9986 6.69775C15.117 6.69775 15.2343 6.72107 15.3436 6.76638C15.453 6.81169 15.5524 6.8781 15.6361 6.96181L18.0361 9.36181C18.1201 9.44538 18.1867 9.54469 18.2322 9.65406C18.2777 9.76343 18.3011 9.88071 18.3012 9.99916C18.3013 10.1176 18.278 10.2349 18.2326 10.3443C18.1872 10.4538 18.1207 10.5532 18.0369 10.6368Z" fill={color} />
    </svg>
}

export default InfluenceIcon