import React, { useState } from "react"
import palette from "../../../theme/palette"
import { Box, Button, Grid, SwipeableDrawer, TextField, Typography } from "@mui/material"
import { pxToRem } from "../../../theme/typography"
import { makeStyles } from "@material-ui/core"
import { useFormik } from "formik"
import * as yup from "yup";
import { ButtonLoader } from "../../../components/buttonLoader"
import FacebookIcon from "../../../assets/images/icon/facebook-icon"
import GoogleIcon from "../../../assets/images/icon/google-icon"
import { useNavigate } from "react-router-dom"
import { xVersion } from "../../../services/config"
import SnackBarComp from "../../../components/snackBar"
import RightPartRegistration from "../rightPartRegistration"
import RightPartCodeVerify from "../rightPartCodeVerify"
import RightPartLogin from "../rightPartLogin"
import RightPartForgetPassword from "../rightPartForgetPassword"

const validationSchema = yup.object({
    email: yup
        .string()
        .email("Enter a valid email")
        .required("Email is required"),
    password: yup
        .string()
        .min(8, "Password should be of minimum 8 characters length")
        .required("Password is required"),
});
const useStyles = makeStyles((theme) => ({
    prodcustName: {
        height: "28px",
        width: "28px",
        fontSize: '28px !important',
        color: '#11734A !important'
    },
    pageTitle: {
        fontSize: "20px !important"
    },

    alreadyAccountText: {
        marginTop: pxToRem(30),
        color: "#9BA19D",
        fontSize: pxToRem(16),
        lineHeight: pxToRem(16),
        fontWeight: 400,
        textAlign: "center",
    },
    forgetPasswordText: {
        color: "#9BA19D",
        textAlign: "right",
        fontSize: `${pxToRem(14)} !important`,
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "145%", /* 20.3px */
        letterSpacing: "-0.14px",
        cursor: "pointer"
    },
    alreadyAccountTextSpan: {
        color: "#11734A",
        cursor: "pointer",
    },
    socialLoginButton: {
        marginTop: pxToRem(24),
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
    },
    scrollBox: {
        overflowY: "auto",
        height: "90vh"
    },
    orLink: {
        marginTop: `${pxToRem(70)} !important`,
    },
    sideDrawerText: {
        fontSize: "24px !important",
        lineHeight: "125% !important",
        cursor: "pointer"
    }
}))

const CommonMobileAuthLayout = ({ type = "sign_in" }) => {
    const classes = useStyles();
    const [visibleDrawer, setVisibleDrawer] = React.useState(false);
    const navigate = useNavigate()
    const toggleDrawer =
        (open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event &&
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }

                setVisibleDrawer(open);
            };
    return <> <Grid
        alignItems="center"
        container
        columns={16}
        sx={{ backgroundColor: palette.common.white }}
    >
        <Grid item xs={16} sx={{ padding: "33px" }}>
            <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} width={"100%"}>
                <Box display={"flex"} alignItems={"center"} justifyContent={"flex-start"} gap={"8px"}>
                    <img className={classes.prodcustName} alt="logo" src={require('./../../../assets/images/tnt-logo.png')}>

                    </img>
                    <Typography component={"h1"} variant="h1" className={classes.pageTitle} fontWeight={"700 !important"} color={palette.primary.main}>Tenacious Tracker</Typography>
                </Box>
                <Box onClick={toggleDrawer(true)}>
                    <svg width="26" height="22" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22 4H4" stroke="#3E4240" strokeWidth="2" strokeLinejoin="round" />
                        <path d="M22 11H4" stroke="#3E4240" strokeWidth="2" strokeLinejoin="round" />
                        <path d="M22 18H4" stroke="#3E4240" strokeWidth="2" strokeLinejoin="round" />
                    </svg>
                </Box>
            </Box>
            {type === "sign_in" ? <SigninComponent /> : type === "sign_up" ? <SignupComponent /> : type === "forget_password" ? <ForgetPasswordComponent /> : null}
        </Grid>
    </Grid>
        <SwipeableDrawer
            anchor={"right"}
            open={visibleDrawer}
            onClose={toggleDrawer(false)}
            onOpen={toggleDrawer(true)}
            sx={{
                width: "70%",
                "& .MuiPaper-root": {
                    width: "85%",
                    background: "#F7F9FA",
                    boxShadow: "-11px 0px 30px -20px rgba(148, 153, 151, 0.23)",
                    padding: "20px",

                    paddingBottom: "60px",
                }
            }}
        >
            <Box height={"100%"} sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-end",
                flexDirection: "column",
                gap: "24px",
                position: "relative"
            }}>
                <Box onClick={toggleDrawer(false)} position={"absolute"} top={"30px"} sx={{
                    cursor: "pointer"
                }} right={"0"}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M1.50014 22.4999L22.5 1.5" stroke="#3E4240" strokeWidth="2" />
                        <path d="M22.4999 22.4999L1.5 1.5" stroke="#3E4240" strokeWidth="2" />
                    </svg>
                </Box>
                <Typography component={"span"} variant="h1" className={classes.sideDrawerText} fontWeight={"500 !important"} color={palette.primary.light}>About us</Typography>
                <Typography component={"span"} variant="h1" className={classes.sideDrawerText} fontWeight={"500 !important"} color={palette.primary.light}>Blog</Typography>
                <Typography component={"span"} variant="h1" className={classes.sideDrawerText} fontWeight={"500 !important"} color={palette.primary.light}>Contact us</Typography>
                <Typography component={"span"} variant="h1" className={classes.sideDrawerText} fontWeight={"500 !important"} color={palette.primary.main} onClick={() => {
                    navigate(type === "sign_in" ? "/register" : "/login")
                }}>
                    {type === "sign_in" ? "Sign up" : type === "sign_up" ? "Log in" : type === "forget_password" ? "Log in" : null}
                </Typography>
            </Box>

        </SwipeableDrawer>
    </>
}

export default CommonMobileAuthLayout

const SigninComponent = () => {
    const classes = useStyles();
    return <Box className={`${classes.scrollBox} hide-scrollbar`}>
        <RightPartLogin isMobile />
    </Box>
}

const ForgetPasswordComponent = () => {
    const classes = useStyles();
    return <Box className={`${classes.scrollBox} hide-scrollbar`}>
        <RightPartForgetPassword isMobile />
    </Box>
}

const SignupComponent = () => {
    const classes = useStyles();
    const [showSnakbar, setShowSnakbar] = useState(false);
    const [showLoaderPage, setShowLoaderPage] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [snakbarMsg, setSnakbarMsg] = useState("");
    const [msgType, setMsgType] = useState("");
    const [codeVerified, setCodeVerified] = useState(xVersion ? true : false)
    const handleCloseSnakbar = () => {
        setShowSnakbar(false);
    };
    return <Box className={`${classes.scrollBox} hide-scrollbar`}>
        {codeVerified && <RightPartRegistration isMobile={true} />}
        {!codeVerified && <RightPartCodeVerify setCodeVerified={setCodeVerified} isMobile={true} />}
    </Box>
}