import { makeStyles } from "@material-ui/core";
import React from "react";
import ModalCloseIconWhite from "../../assets/images/icon/modalCloseIconWhite";
import palette from "../../theme/palette";
import shape from "../../theme/shape";
import { Drawer, Grid, IconButton, Typography } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  drawerWrapper: {
    padding: "60px 40px",
    [theme.breakpoints.down("lg")]: {
      padding: "50px 30px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "30px 20px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
    },
  },
  ideasList: {
    marginTop: "50px",
    [theme.breakpoints.down("lg")]: {
      marginTop: "40px",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "30px",
    },
  },
  ideasListItem: {
    backgroundColor: palette.common.white,
    borderRadius: shape.borderRadius,
    padding: "24px",
    fontSize: "16px",
    lineHeight: "16px",
    color: palette.primary.light,
    fontWeight: 400,
    marginBottom: "12px",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      padding: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
  },
}));

interface IdeasDrawerModel {
  openDrawer: boolean;
  closeDrawer: VoidFunction;
  categoryIdeas: any;
  setCategoryInput: (category: any) => void;
}

const IdeasDrawer = (props: IdeasDrawerModel) => {
  const classes = useStyles();
  return (
    <Drawer
      className="drawerLayout"
      open={props.openDrawer}
      onClose={props.closeDrawer}
      anchor="right"
    >
      <Grid
        container
        sx={{ backgroundColor: palette.primary.main }}
        className={classes.drawerWrapper}
      >
        <Grid>
          <IconButton className="closeIcon" onClick={props.closeDrawer}>
            <ModalCloseIconWhite />
          </IconButton>
          <Typography
            variant="h3"
            component="h3"
            sx={{
              color: palette.common.white,
              fontWeight: 600,
              lineHeight: "35px!important",
              mb: 3,
            }}
          >
            Here are some ideas of categories you might want to consider
          </Typography>
          <Typography
            variant="body1"
            component="div"
            sx={{
              color: palette.common.white,
              fontWeight: 400,
              lineHeight: "30px!important",
            }}
          >
            Select an option from the list below to populate the outcome field
            where you can make changes to the description and finalise its setup
          </Typography>
        </Grid>
        <Grid className={classes.ideasList} sx={{ width: "100%" }}>
          {props?.categoryIdeas?.map((ideas: any) => {
            return (
              <div
                className={classes.ideasListItem}
                key={ideas._id}
                onClick={() => {
                  props.setCategoryInput(ideas);
                  props.closeDrawer();
                }}
              >
                {ideas.name}
              </div>
            );
          })}
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default IdeasDrawer;
