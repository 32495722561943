import { Button, Popover, Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import React from "react";
import palette from "../../../theme/palette";

export default function Header() {
  const Wrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  }));

  return (
    <>
      <Wrapper>
        <div style={{ marginBottom: "20px" }}>
          <Typography variant="h3" sx={{ color: palette.primary.light }}>
            Setting Your Goals
          </Typography>
        </div>
        <div>
          <Typography variant="heading2" component="div" sx={{ color: palette.secondary.contrast }}>
            Continue setting goals later
          </Typography>
        </div>
      </Wrapper>
    </>
  );
}
