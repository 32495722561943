import React from "react";

const ArrowUp = ({
  color = "#9BA19D",
  size="24"
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.41 15.4102L12 10.8302L16.59 15.4102L18 14.0002L12 8.00016L6 14.0002L7.41 15.4102Z"
        fill={color}
      />
    </svg>
  );
};

export default ArrowUp;
