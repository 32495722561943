import React from 'react';

const Search = ({
  size = 20,
  color = "#11734A"
}) => {
  return (
    <>
      <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="8" cy="8" r="7" stroke={color} strokeWidth="2" />
        <path d="M14.707 14.2929L18.707 18.2929" stroke={color} strokeWidth="2" />
      </svg>
    </>
  );
};

export default Search;
