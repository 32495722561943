import React, { useContext } from "react";
import { Typography, Box } from "@mui/material";
import InfoIcon from "../../assets/images/icon/infoIconYellow";
import { makeStyles } from "@material-ui/core";
import AddQuestionDataLabel from "./addQuestionDataLabel";
import shape from "../../theme/shape";
import { UserTypeContext } from "../../context/userStatus";
import { ReflectiveGetList } from "../../interfaces/outcomeModal";

const useStyles = makeStyles((theme) => ({
  infoWrapper: {
    backgroundColor: "#FFF4E3",
    border: "1px solid #FF9B37",
    borderRadius: shape.borderRadius,
    padding: "18px 20px",
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
      marginBottom: "10px",
    },
  },
  infoText: {
    color: "#FF9B37",
    fontSize: "15px",
    lineHeight: "15px",
    fontWeight: 400,
    marginLeft: "12px",
  },
  infoWarning: {
    fontSize: "12px",
    lineHeight: "12px",
    fontWeight: 400,
    color: "#9BA19D",
    textAlign: "center",
  },
}));

interface NoQuesFoundModal {
  setQuestionData: (questionData: string) => void;
}

const NoQuesFound = (props: NoQuesFoundModal) => {
  const classes = useStyles();
  const { state: outcomeState } = useContext(UserTypeContext);
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{ mb: "20px" }}
      >
        <Typography variant="h3" component="h3">
          Your BIG 3 Journaling Questions
        </Typography>
        <Typography variant="caption" component="div">
          {outcomeState?.reflectiveList?.length} of 3 Added
        </Typography>
      </Box>
      {/* QUESTIONS LIST FOUND */}
      {outcomeState?.reflectiveList &&
      outcomeState?.reflectiveList?.length > 0 ? (
        outcomeState?.reflectiveList?.map(
          (refelectiveItem: ReflectiveGetList, index: number) => {
            return (
              <AddQuestionDataLabel
                refelectiveItem={refelectiveItem}
                key={index}
                setQuestionData={props.setQuestionData}
              />
            );
          }
        )
      ) : (
        // NOT FOUND
        <Box display="flex" alignItems="center" className={classes.infoWrapper}>
          <InfoIcon />
          <span className={classes.infoText}>
            Please add at least one question you want to track
          </span>
        </Box>
      )}
      <p className={classes.infoWarning}>
        *More journaling question can be added after the setup
      </p>
    </>
  );
};
export default NoQuesFound;
