import {
  ITrackerAction,
  ITrackingTypeState
} from "../../interfaces/trakingModel";

export const setSnackBarOption = (
  payload: any
): ITrackerAction => ({
  type: "SET_SHOW_SNACKBAR",
  payload: payload,
});


export const setDayCompleteMessage = (
  payload: {
    message: string
    open: boolean
  }
): ITrackerAction => ({
  type: "SET_DAY_COMPLETE",
  payload: payload,
});

export const setShowLoader = (
  payload: any
): ITrackerAction => ({
  type: "SET_SHOW_LOADER",
  payload: payload,
});

export const setExpandView = (
  payload: boolean
): ITrackerAction => ({
  type: "SET_EXPANDED_VIEW",
  payload
})
export const setOpenTracking = (
  payload: any
): ITrackerAction => ({
  type: "SET_OPEN_TRACKING",
  payload: payload,
});

export const setSidebarExpand = (payload: any): ITrackerAction => ({
  type: "SET_SIDEBAR_EXPAND",
  payload: payload,
})

export const setArchiveMode = (payload: any): ITrackerAction => ({
  type: "SET_ARCHIVE_MODE",
  payload: payload,
})

export const setOpenCategoryModal = (payload: any): ITrackerAction => ({
  type: "SET_OPEN_CATEGORY_MODAL",
  payload: payload,
})

export const setShowOutcome = (payload: any): ITrackerAction => ({
  type: "SET_SHOW_OUTCOME",
  payload: payload,
})
export const setShowInfluence = (payload: any): ITrackerAction => ({
  type: "SET_SHOW_INFLUENCE",
  payload: payload,
})
export const setShowQues = (payload: any): ITrackerAction => ({
  type: "SET_SHOW_QUESTION",
  payload: payload,
})


export const setTrackingState = (payload: Partial<ITrackingTypeState>): ITrackerAction => ({
  type: "SET_TRACKING_STATE",
  payload: payload,
})

export const setOutcomeIdeas = (payload: any): ITrackerAction => ({
  type: "SET_OUTCOME_IDEAS",
  payload: payload,
})

export const setInfluenceIdeas = (payload: any): ITrackerAction => ({
  type: "SET_INFLUENCE_IDEAS",
  payload: payload,
})
export const setReflectiveIdeas = (payload: any): ITrackerAction => ({
  type: "SET_QUESTION_IDEAS",
  payload: payload,
})

export const setVisibleDate = (payload: any): ITrackerAction => ({
  type: "SET_VISIBLE_DATE",
  payload: payload,
})
