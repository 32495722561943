/*eslint eqeqeq: "off"*/
import React, { useState, useContext, useEffect } from "react";
import {
  Typography,
  Grid,
  TextField,
  Button,
  MenuItem,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  IconButton,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core";
import GetIdeasIcon from "../../assets/images/icon/getIdeas";
import palette from "../../theme/palette";
import BottomStepper from "../outcome/BottomStepper";
import {
  InfluenceGetResponse,
  InfluenceOption,
  OutcomeGetResponse,
} from "../../interfaces/outcomeModal";
import {
  fixOutcomeColorPalette,
  InfluenceSelectOption,
  MeasurementUnit,
} from "../../utils/staticDataJson";
import clsx from "clsx";
import ColorPickerDialog from "../outcome/colorPickerDialog";
import ProgressBarStepper from "../outcome/progressBarStepper";
// import IdeasDrawer from './ideasDrawer';
import shape from "../../theme/shape";
import { influenceService } from "../../services/influence";
import { UserTypeContext } from "../../context/userStatus";
import {
  addCategoryJourneyAction,
  addInfluenceAction,
  addJourneyAction,
  addOutcomeAction,
} from "../../store/actions/userTypeAction";
import SnackBarComp from "../../components/snackBar";
import { ButtonLoader } from "../../components/buttonLoader";
import moment from "moment";
import { trackingService } from "../../services/tracking";
import AddIcon from "@mui/icons-material/Add";
// import AddPopOver from './AddPopOver';
import { commanColorPalette } from "../../utils/staticDataJson";
import { userService } from "../../services/auth";
import Donts from "../../assets/images/icon/Dots";
import Tooltip from "@mui/material/Tooltip";
import ModalCloseIcon from "../../assets/images/icon/modalCloseIconBlack";
import DeleteIcon from "../../assets/images/icon/DeleteIcon";
import Question from "../../assets/images/icon/Question";
import { useNavigate } from "react-router-dom";
import AddPopOver from "../influence/AddPopOver";
import { outcomeService } from "../../services/outcome";
import NewButton from "../../components/NewButton";
import { pxToRem } from "../../theme/typography";
import ProgressBarStepperNew from "../outcome/progressBarStepperNew";

const RootStyle = styled("div")(({ theme }) => ({
  padding: "60px 48px 60px 80px",
  position: "relative",
  height: "100vh",
  [theme.breakpoints.down("xl")]: {
    padding: "50px 40px 50px 60px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "40px 20px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "20px",
  },
}));

const useStyles = makeStyles((theme) => ({
  registrationHeading: {
    color: palette.primary.light,
  },
  pageTitle: {
    marginTop: "40px!important",
    marginBottom: "48px!important",
    [theme.breakpoints.down("lg")]: {
      marginTop: "30px!important",
      marginBottom: "38px!important",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "20px!important",
      marginBottom: "28px!important",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "15px!important",
      marginBottom: "20px!important",
    },
  },
  needSomeIdeas: {
    fontSize: "16px",
    lineHeight: "16px",
    color: palette.primary.main,
    marginLeft: "8px",
    [theme.breakpoints.down("md")]: {
      marginTop: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "14px",
    },
  },
  selectWrapper: {
    marginTop: "49px",
    [theme.breakpoints.down("md")]: {
      marginTop: "30px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
    },
  },
  influenceCaption: {
    marginBottom: "20px!important",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "10px!important",
    },
  },
  colorBlock: {
    cursor: "pointer",
    backgroundColor: palette.secondary.light,
    border: "1px solid #DCE6E0",
    borderRadius: shape.borderRadius,
    padding: "11px 16px",
    display: "flex",
    alignItems: "center",
    marginLeft: "16px",
    [theme.breakpoints.down("lg")]: {
      marginLeft: 0,
    },
  },
  spaceBetween: {
    [theme.breakpoints.down("lg")]: {
      marginTop: "10px",
      marginBottom: "10px",
    },
  },
  colorBlockText: {
    margin: 0,
    color: palette.primary.light,
    fontSize: "16px",
    lineHeight: "16px",
    fontWeight: 500,
  },
  colorCode: {
    width: "24px",
    height: "24px",
    marginLeft: "10px",
    borderRadius: shape.borderRadiusSm,
  },
  addButton: {
    backgroundColor: "#F9D412 !important",
    borderRadius: " 30px !important",
    padding: "10px 12px !important",
    marginBottom: "0 !important",
  },
  listItemClass: {
    "& .MuiButtonBase-root": {
      display: "flex !important",
      justifyContent: "flex-start !important",
    },
  },
  swicthButtons: {
    display: "flex",
    padding: "4px",
    alignItems: "center",
    borderRadius: "12px",
    background: "#F6F6F6",
    marginLeft: "auto",
  },
  swicthButtonsMobile: {
    display: "flex",
    padding: "4px",
    alignItems: "center",
    borderRadius: "12px",
    background: "#F6F6F6",
    marginLeft: "0px",
  },
  minButton: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    "&:after": {
      content: "''",
      position: "absolute",
      display: "inline-block",
      left: "31px",
      top: "50%",
      border: "0.1px solid #CADFD2",
      width: "90%",
    },
  },
  iconButton: {
    color: "#fff",
    zIndex: 10,
    height: "30px",
    width: "30px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const ariaLabel = { "aria-label": "description" };

interface LeftSideInfluenceModal {
  //   setInfluenceData: (influenceData: any) => void;
  //   influenceData: any;
  //   influenceIdeas: any;
  //   showInfluence: boolean;
  //   setAddInfluenceTracking: (addInfluenceTracking: boolean) => void;
  //   setShowInfluence: (showInfluence: boolean) => void;
  setTextInput: any;
  textInput: any;
  outComeValue: any;
  setTransformToInfluenceTracking?: any;
  //   ispersonalMode?: boolean;
  //   handleCloseStartModal?: any;
  //   getInfluenceList?: any;
  categoryId?: any;
  isMobile?: boolean;
  callservice?: (x: boolean) => void;
}

const LeftTransformInInflu = (props: LeftSideInfluenceModal) => {
  //AddPopOverComponent States
  const [value, setValue] = useState<string>("");
  const [desc, setDesc] = useState<string>("");
  const [color, setColor] = useState<string>("#E57C73");
  const [colorPalette, setColorPalette] = useState(commanColorPalette);
  const [textFieldValues, setTextFieldValues] = useState([
    // {
    //   id: 1,
    //   colorCode: "#E57C73",
    //   text: "min",
    //   value: "",
    //   temp: true,
    // },
    // {
    //   id: 2,
    //   colorCode: "#169C63",
    //   text: "max",
    //   value: "",
    //   temp: true,
    // },
    {
      id: 1,
      colorCode: "#E57C73",
      text: "min",
      value: "",
      temp: true,
    },
    {
      id: 2,
      colorCode: "#ADE888",
      text: "max",
      value: "",
      temp: true,
    },
  ]);
  const [matchValue, setMatchValue] = useState(false);

  const classes = useStyles();
  const [influenceInput, setInfluenceInput] = useState<any>(
    props?.outComeValue?.name
  );
  const [unitValue, setUnitValue] = useState("");
  const [unitValueOption, setUnitValueOption] = useState<any>();
  const [influenceOption, setInfluenceOption] = useState<any>("");
  const [flagValue, setFlagValue] = useState("");
  const [openColor, setOpenColor] = useState(false);
  const [selectedColor, setSelectedColor] = useState<any>(
    fixOutcomeColorPalette[0]
  );
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [editStatus, setEditStatus] = useState(null);
  const [showSnakbar, setShowSnakbar] = useState(false);
  const [snakbarMsg, setSnakbarMsg] = useState("");
  const [msgType, setMsgType] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [nodeCount, setNodeCount] = useState(0);
  const [absenceToggle, setabsenceToggle] = useState(false);
  const [skipMetric, setskipMetric] = useState(false);
  //cateScaler State
  const [cateScaler, setCateScaler] = useState("NumericValue");
  const [neutralScaler, setNeutralScaler] = useState("");
  //   const [textInput, setTextInput] = useState("")
  const [openPopupinEditMode, setOpenPopupinEditMode] = useState({
    status: false,
    nodeId: "",
  });
  const [editableNode, setEditableNode] = useState({});
  const { state: outcomeState, dispatch: userTypeDispatch } =
    useContext(UserTypeContext);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);

  const navigate = useNavigate();

  const compareDate = parseInt(moment().format("D"));
  const startDay =
    compareDate < 10
      ? moment().clone().startOf("month")
      : moment().clone().subtract(10, "day");
  const endDay =
    compareDate < 10
      ? moment().clone().endOf("month")
      : moment().clone().add(10, "day");

  const NativeOutcomeStatus = [
    { text: "very low" },
    { text: "low" },
    { text: "medium" },
    { text: "high" },
    { text: "very high" },
  ];

  // const data = {
  //   id: new Date(),
  //   value: parseInt(props.value),
  //   text: props.desc,
  //   colorCode: props.color,
  //   answerId: ""
  // }

  //   useEffect(() => {
  //     setOpenDrawer(false);
  //     setInfluenceInput(props?.influenceData?.name);
  //     setInfluenceOption(
  //       props?.influenceData?.type === 'TYPE12'
  //         ? 'TYPE1'
  //         : props?.influenceData?.type
  //     );
  //     setUnitValueOption(props?.influenceData?.unitValue);
  //     setUnitValue(props?.influenceData?.unit);
  //     setFlagValue(props?.influenceData?.flag);
  //     props?.setTextInput(
  //       props.influenceData?.options?.length > 0
  //         ? props.influenceData?.options
  //         : NativeOutcomeStatus
  //     );
  //     setSelectedColor(
  //       props.influenceData?.options?.length > 0
  //         ? props.influenceData?.options
  //         : fixOutcomeColorPalette[0]
  //     );
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  //   }, [props.influenceData]);

  //   useEffect(() => {
  //     if (
  //       props?.influenceData?.options?.length !== 0 &&
  //       props.influenceData?.uid
  //     ) {
  //       const newOptionArray = props?.influenceData?.options?.map((option) => {
  //         return {
  //           id: new Date(),
  //           ...option,
  //         };
  //       });
  //       setTextFieldValues(newOptionArray);
  //     } else {
  //       setTextFieldValues([
  //         {
  //           id: 1,
  //           colorCode: '#E57C73',
  //           text: 'min',
  //           value: '',
  //           temp: true,
  //         },
  //         {
  //           id: 2,
  //           colorCode: '#169C63',
  //           text: 'max',
  //           value: '',
  //           temp: true,
  //         },
  //       ]);
  //     }
  //     if (props.influenceData?.uid && props.influenceData?.type === 'TYPE1') {
  //       setCateScaler('yes/no');
  //     } else if (
  //       props.influenceData?.uid &&
  //       props.influenceData?.type === 'TYPE12'
  //     ) {
  //       if (props.influenceData?.metric_type === 'NEUTRAL') {
  //         setNeutralScaler('NumericValue');
  //       } else {
  //         setCateScaler('NumericValue');
  //       }
  //     }
  //   }, [props.influenceData]);

  useEffect(() => {
    setSelectedColor(
      props.outComeValue?.options
        ? props.outComeValue?.options
        : fixOutcomeColorPalette[0]
    );
    if (props.outComeValue?.type === "TYPE2") {
      setSelectedColor(
        props.outComeValue?.options.length > 0
          ? props.outComeValue?.options
          : fixOutcomeColorPalette[0]
      );
    } else {
      console.log("setting native text");

      setSelectedColor(fixOutcomeColorPalette[0]);
    }

    setabsenceToggle(props.outComeValue?.absenceToggle);
    setskipMetric(props?.outComeValue?.skipMetric);
  }, [props.outComeValue]);

  const callService = async () => {
    try {
      const bodyData = {
        from: moment(startDay).format("MM-DD-YYYY"),
        to: moment(endDay).format("MM-DD-YYYY"),
      };
      // if (!props?.ispersonalMode) {
      let data = await trackingService.getJourneyList(
        outcomeState.token,
        bodyData
      );
      userTypeDispatch(addJourneyAction(data));
      // } else {
      let datap = await trackingService.getPersonalJourneyList(
        outcomeState.token,
        bodyData
      );
      userTypeDispatch(addCategoryJourneyAction(datap?.data));
      // }
    } catch (error) {
      console.log("jourey list error");
    }
  };

  const handleClickOpenColor = () => {
    setOpenColor(true);
  };

  const handleCloseColor = (value: any) => {
    setOpenColor(false);
    if (value.id === -1) {
      return;
    }
    setSelectedColor(value);
  };
  // ADD INFLUENCE
  const TransformInfluence = () => {
    setShowLoader(true);
    let influenceData;
    let influenceOptionata;

    if (influenceOption === "TYPE1") {
      // if (influenceOptionata == undefined || influenceOptionata.length == 0) {
      //   alert(influenceOptionata)
      //   setShowLoader(false);
      //   setShowSnakbar(true);
      //   setSnakbarMsg("Please enter unit value");
      //   setMsgType("error");
      //   return;
      // } else
      if (
        unitValueOption == undefined ||
        unitValueOption == "" ||
        unitValueOption.length == 0
      ) {
        setShowLoader(false);
        setShowSnakbar(true);
        setSnakbarMsg("Please select unit of measurement");
        setMsgType("error");
        return;
      } else if (flagValue == undefined || flagValue == "") {
        setShowLoader(false);
        setShowSnakbar(true);
        setSnakbarMsg("Please select enter value");
        setMsgType("error");
        return;
      }
      if (textFieldValues.length > 0) {
        influenceOptionata = textFieldValues.map((item) => {
          if (!textFieldValues[0].temp || !textFieldValues[1].temp) {
            delete item.id;
            return item;
          }
        });
      }
      if (neutralScaler === "NumericValue") {
        if (props.outComeValue?.uid) {
          influenceData = {
            name: props.outComeValue?.name,
            icon: "",
            type: "TYPE12",
            flag: flagValue,
            unitValue: unitValueOption,
            unit: null,
            options:
              flagValue === "no"
                ? textFieldValues[0].temp
                  ? []
                  : influenceOptionata
                : [],
            influenceId: props.outComeValue?.influenceId,
            metric_type: "NEUTRAL",
          };
        } else {
          influenceData = {
            name: props.outComeValue?.name,
            icon: "",
            influenceId: props.outComeValue?.influenceId,
            type: "TYPE12",
            flag: flagValue,
            unitValue: unitValueOption,
            unit: null,
            options:
              flagValue === "no"
                ? textFieldValues[0].temp
                  ? []
                  : influenceOptionata
                : [],
            metric_type: "NEUTRAL",
          };
        }
      } else {
        if (textFieldValues[0].temp) {
          alert(influenceOptionata);
          setShowLoader(false);
          setShowSnakbar(true);
          setSnakbarMsg("Please enter unit value");
          setMsgType("error");
          return;
        }
        if (props.outComeValue?.uid) {
          influenceData = {
            name: props.outComeValue?.name,
            icon: "",
            type: flagValue === "yes" ? "TYPE1" : "TYPE12",
            flag: flagValue,
            unitValue: unitValueOption,
            unit: null,
            options:
              flagValue === "no"
                ? textFieldValues[0].temp
                  ? []
                  : influenceOptionata
                : [],
            influenceId: props.outComeValue?.influenceId,
          };
        } else {
          influenceData = {
            name: props.outComeValue?.name,
            icon: "",
            influenceId: props.outComeValue?.influenceId,
            type: flagValue === "yes" ? "TYPE1" : "TYPE12",
            flag: flagValue,
            unitValue: unitValueOption,
            unit: null,
            options:
              flagValue === "no"
                ? textFieldValues[0].temp
                  ? []
                  : influenceOptionata
                : [],
          };
        }
      }
    } else if (influenceOption === "TYPE2") {
      var result: any = selectedColor;
      if (props.outComeValue?.uid && selectedColor.length > 0) {
        result = selectedColor?.map((color: any, index: number) => ({
          text: props?.textInput[index]?.text,
          colorCode: color.colorCode,
          answerId: color.answerId,
        }));
      } else {
        result = selectedColor?.color?.map((color: any, index: number) => ({
          text: props?.textInput[index]?.text,
          colorCode: color,
          answerId:
            props?.outComeValue &&
            props?.outComeValue?.options[index]?.answerId,
        }));
        if (result === undefined) {
          result = selectedColor?.map((color: any, index: number) => ({
            text: props?.textInput[index]?.text,
            colorCode: color.colorCode,
            answerId: color.answerId,
          }));
        }
      }
      if (props.outComeValue?.uid) {
        influenceData = {
          name: props.outComeValue?.name,
          icon: "",
          type: "TYPE2",
          options: result,
          influenceId: props.outComeValue?.influenceId,
        };
      } else {
        influenceData = {
          name: props.outComeValue?.name,
          icon: "",
          type: "TYPE2",
          options: result,
          influenceId: props.outComeValue?.influenceId,
        };
      }
    } else if (influenceOption == "TYPE3") {
      if (props.outComeValue?.uid) {
        influenceData = {
          name: props.outComeValue?.name,
          icon: "",
          type: "TYPE3",
          flag: flagValue,
          options: [],
          influenceId: props.outComeValue?.influenceId,
        };
      } else {
        influenceData = {
          name: props.outComeValue?.name,
          icon: "",
          type: "TYPE3",
          flag: flagValue,
          options: [],
          influenceId: props.outComeValue?.influenceId,
        };
      }
    }

    if (props?.categoryId) {
      influenceData = { ...influenceData, categoryId: props?.categoryId };
    }

    influenceService
      .TransformInfluenceToInfluence(influenceData, outcomeState.token)
      .then((response: any) => {
        setShowLoader(false);
        props?.setTransformToInfluenceTracking(false);
        setShowSnakbar(true);
        setSnakbarMsg(response.message);
        setMsgType("success");
        influenceService
          .getInfluenceList(outcomeState.token)
          .then((data: InfluenceGetResponse) => {
            userTypeDispatch(addInfluenceAction(data?.data));
            props?.callservice(false);
            // props.setShowInfluence(false);
            setInfluenceInput("");
            setInfluenceOption("");
            setUnitValueOption("");
            setUnitValue("");
            setFlagValue("");
            // props.setInfluenceData('');
            props?.setTextInput(NativeOutcomeStatus);
          });
        // outcomeService
        // .getOutcomeList(outcomeState.token)
        // .then((data: OutcomeGetResponse) => {
        //   userTypeDispatch(addOutcomeAction(data?.data));
        //   callService();
        //   // props.setShowInfluence(false);
        //   setInfluenceInput('');
        //   setInfluenceOption('');
        //   setUnitValueOption('');
        //   setUnitValue('');
        //   setFlagValue('');
        //   // props.setInfluenceData('');
        //   props?.setTextInput(NativeOutcomeStatus);
        // });
      })
      .catch((error) => {
        setShowLoader(false);
        setShowSnakbar(true);
        setSnakbarMsg(
          error?.response?.data?.description
            ? error?.response?.data?.description
            : error.message
        );
      });
    //       setMsgType('error');
    //     });
    // if (props.influenceData?.uid) {
    //   // EDIT INFLUENCE
    //   influenceService
    //     .editInfluence(influenceData, outcomeState.token)
    //     .then((response: any) => {
    //       setShowLoader(false);
    //       setShowSnakbar(true);
    //       setSnakbarMsg(response.message);
    //       setMsgType('success');
    //       influenceService
    //         .getInfluenceList(outcomeState.token)
    //         .then((data: InfluenceGetResponse) => {
    //           userTypeDispatch(addInfluenceAction(data?.data));
    //           callService();
    //           props.setShowInfluence(false);
    //           setInfluenceInput('');
    //           setInfluenceOption('');
    //           setUnitValueOption('');
    //           setUnitValue('');
    //           setFlagValue('');
    //           props.setInfluenceData('');
    //           props?.setTextInput(NativeOutcomeStatus);
    //         });
    //     })
    //     .catch((error) => {
    //       setShowLoader(false);
    //       setShowSnakbar(true);
    //       setSnakbarMsg(
    //         error?.response?.data?.description
    //           ? error?.response?.data?.description
    //           : error.message
    //       );
    //       setMsgType('error');
    //     });
    // } else {
    //   // CREATE INFLUENCE
    //   influenceService
    //     .createInfluence(
    //       { ...influenceData, categoryId: props?.categoryId },
    //       outcomeState.token
    //     )
    //     .then(async (response: any) => {
    //       setShowLoader(false);
    //       setShowSnakbar(true);
    //       setSnakbarMsg(response.message);
    //       setMsgType('success');
    //       outcomeState.influenceList.push(response?.data);
    //       const bodyData = {
    //         from: moment(startDay).format('MM-DD-YYYY'),
    //         to: moment(endDay).format('MM-DD-YYYY'),
    //       };
    //       let data: any = await trackingService.getPersonalJourneyList(
    //         outcomeState.token,
    //         bodyData
    //       );
    //       userTypeDispatch(addCategoryJourneyAction(data?.data));
    //       userTypeDispatch(addInfluenceAction(outcomeState.influenceList));
    //       setTimeout(() => {
    //         props.setAddInfluenceTracking(false);
    //         setInfluenceInput('');
    //         setInfluenceOption('');
    //         setUnitValueOption('');
    //         setUnitValue('');
    //         setFlagValue('');
    //         props.setInfluenceData('');
    //         props?.setTextInput(NativeOutcomeStatus);
    //       }, 1000);
    //     })
    //     .catch((error) => {
    //       setShowLoader(false);
    //       setShowSnakbar(true);
    //       setSnakbarMsg(
    //         error?.response?.data?.description
    //           ? error?.response?.data?.description
    //           : error.message
    //       );
    //       setMsgType('error');
    //     });
    // }
  };
  const showDrawer = () => {
    setOpenDrawer(true);
  };
  const handleCloseSnakbar = () => {
    setShowSnakbar(false);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    if (openPopupinEditMode?.status) {
      setOpenPopupinEditMode({
        status: false,
        nodeId: "",
      });
      setValue("");
      setDesc("");
      setColor("#E57C73");
      setEditableNode({});
    }
  };

  const openPopoverInEditMode = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const editCustomeScaleValue = (event, uniqeId) => {
    if (uniqeId !== 1 && uniqeId !== 2) {
      openPopoverInEditMode(event);
      setOpenPopupinEditMode({
        ...openPopupinEditMode,
        status: true,
        nodeId: uniqeId,
      });

      let editableNode = textFieldValues?.filter((node) => {
        return node?.id === uniqeId;
      });

      if (editableNode?.length > 0) {
        setValue(editableNode[0]?.value);
        setDesc(editableNode[0]?.text);
        setColor(editableNode[0]?.colorCode);
        setEditableNode(editableNode[0]);
      }
    }
  };

  const deleteCustomeScaleValue = (uniqeId) => {
    let updatedScaleNodeArray = textFieldValues?.filter((node) => {
      return node?.id !== uniqeId;
    });
    if (
      textFieldValues[0].temp === undefined ||
      textFieldValues[1].temp === undefined
    ) {
      setTextFieldValues(updatedScaleNodeArray);
    }

    if (
      textFieldValues?.length === 2 &&
      textFieldValues[0].temp === undefined &&
      textFieldValues[1].temp
    ) {
      setNodeCount(0);
      setTextFieldValues([
        {
          id: 1,
          colorCode: "#E57C73",
          text: "min",
          value: "",
          temp: true,
        },
        {
          id: 2,
          colorCode: "#ADE888",
          text: "max",
          value: "",
          temp: true,
        },
      ]);
    } else if (
      (textFieldValues?.length === 2 &&
        textFieldValues[0].temp === undefined) ||
      textFieldValues[1].temp
    ) {
      setNodeCount(1);
      let updatedScaleNodeArray = textFieldValues?.filter((node) => {
        return node?.id !== uniqeId;
      });
      updatedScaleNodeArray.push({
        id: 2,
        colorCode: "#ADE888",
        text: "max",
        value: "",
        temp: true,
      });
      setTextFieldValues(updatedScaleNodeArray);
      // setTextFieldValues([
      //   ...textFieldValues,
      //   {
      //     id: 2,
      //     colorCode: "#ADE888",
      //     text: "max",
      //     value: "",
      //     temp: true
      //   }
      // ])
    }
  };

  const ITEM_HEIGHT = 48;
  const [anchorElTwo, setAnchorElTwo] = React.useState<null | HTMLElement>(
    null
  );
  const openMenu = Boolean(anchorElTwo);
  const handleClickAnchorElTwo = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElTwo(event.currentTarget);
  };
  const handleCloseAnchorElTwo = () => {
    setAnchorElTwo(null);
  };
  const renderButton = (isLeft, title, active, onClick) => (
    <Button
      /* variant={!active ? "outlined" : "contained"}  */ sx={{
        display: "flex !important",
        padding: "8px !important",
        alignItems: "center !important",
        marginBottom: "0 !important",
        boxShadow: active ? "0px 0px 72px 0px rgba(25, 13, 26, 0.12)" : "none",
        borderRadius: "8px !important",
        background: active ? "#FFF !important" : "transparent",
      }}
      onClick={onClick}
    >
      <Typography
        variant="caption"
        sx={{
          color: !active ? palette.common.black : "var(--color-new-text)",
          fontWeight: 400,
          fontSize: "12px !important",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          lineHeight: "100% !important" /* 12px */,
          letterSpacing: "-0.12px",
          whiteSpace: "nowrap",
        }}
      >
        {title}
      </Typography>
    </Button>
  );
  const type1Render = () => {
    let sortingArr = [...colorPalette].map((it) => it.color);
    const newtextFieldValues = [...textFieldValues].sort(
      (a, b) =>
        sortingArr.indexOf(a.colorCode) - sortingArr.indexOf(b.colorCode)
    );
    return (
      <Grid
        display="flex"
        alignItems="center"
        gap="12px"
        alignSelf="stretch"
        mt={2}
        sx={
          props?.isMobile
            ? {
                flexWrap: "wrap",
              }
            : {}
        }
      >
        <div
          style={{
            display: "flex",
            paddingRight: "12px",
            flexDirection: "column",
            alignItems: "center",
            gap: "10px",
            borderRight: "1px solid #F3F3F3",
            width: "100%",
            flexBasis: props?.isMobile ? "100%" : "25%",
          }}
        >
          <TextField
            sx={{
              display: "flex",
              padding: "16px 20px",
              alignItems: "center",
              gap: "8px",
              borderRadius: "12px",
              border: "1px solid #EDEDED",
              background: "#FFF",
              marginBottom: "0 !important",
              width: "100%",
              "& .MuiOutlinedInput-root": {
                fontSize: `${pxToRem(16)} !important`,
                lineHeight: "100% !important",
                padding: "0",
                "& .MuiSelect-outlined": {
                  padding: "0 !important",
                },
              },
            }}
            className=" unitSelect"
            fullWidth
            select
            placeholder="Select unit of measurement"
            // label="Unit of measurement"
            value={unitValueOption || ""}
            onChange={(e) => {
              setUnitValueOption(e.target.value);
              if (e.target.value === "timeofday") {
                setNeutralScaler("NumericValue");
              } else {
                setNeutralScaler("");
              }
              if (cateScaler === "yes/no") {
                setFlagValue("yes");
              } else {
                setFlagValue("no");
              }
            }}
          >
            {MeasurementUnit?.map((option: any) => (
              <MenuItem key={option.id} value={option.id}>
                {option.value}
              </MenuItem>
            ))}
          </TextField>
        </div>
        {cateScaler === "NumericValue" && neutralScaler !== "NumericValue" && (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                gap: "10px",
                flex: "1 0 0",
                width: "100%",
                flexBasis: props?.isMobile ? "100%" : "60%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "10px",
                  flex: "1 0 0",
                  width: "100%",
                  flexBasis: "60%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    height: "52px",
                    alignItems: "flex-end",
                    gap: "8px",
                    alignSelf: "stretch",
                  }}
                >
                  <Grid
                    item
                    xs={16}
                    sx={{
                      display: "flex",
                      padding: "12px",
                      alignItems: "center",
                      gap: "12px",
                      flex: "1 0 0",
                      borderRadius: "12px",
                      border: "1px solid #EDEDED",
                      background: "#FFF",
                    }}
                    className={classes.minButton}
                    justifyContent="space-between"
                  >
                    {newtextFieldValues?.map((value, index) => {
                      return (
                        <>
                          <Grid
                            key={index}
                            item
                            xs={7}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              width: "auto",
                              flex: "0 0 auto !important",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              className={classes.iconButton}
                              onClick={(event) =>
                                editCustomeScaleValue(event, value?.id)
                              }
                              style={{
                                backgroundColor: newtextFieldValues[index]
                                  ?.colorCode
                                  ? newtextFieldValues[index]?.colorCode
                                  : "#E57C73",
                              }}
                            >
                              {newtextFieldValues[index]?.value}
                            </div>
                          </Grid>
                        </>
                      );
                    })}
                  </Grid>
                </div>
              </div>
              <NewButton
                variant="outlined"
                onClick={handleClick}
                padding="10px 16px"
                buttonText="Add more"
                borderRadius="10px"
                textColor="var(--color-new-text)"
                borderColor="var(--color-new-text)"
                borderPixel="1px"
              />
            </div>
          </>
        )}

        <AddPopOver
          nodeCount={nodeCount}
          setNodeCount={setNodeCount}
          editableNode={editableNode}
          open={open}
          anchorEl={anchorEl}
          value={value}
          setValue={setValue}
          desc={desc}
          setDesc={setDesc}
          color={color}
          setColor={setColor}
          colorPalette={colorPalette}
          setColorPalette={setColorPalette}
          textFieldValues={textFieldValues}
          setTextFieldValues={setTextFieldValues}
          handleClose={handleClose}
          openPopupinEditMode={openPopupinEditMode}
          deleteCustomeScaleValue={deleteCustomeScaleValue}
          setMatchValue={setMatchValue}
          matchValue={matchValue}
        />
      </Grid>
    );
  };
  const inputTextFieldw = (
    <TextField
      sx={{
        display: "flex",
        padding: "16px 20px",
        alignItems: "center",
        gap: "8px",
        borderRadius: "12px",
        border: "1px solid #EDEDED",
        background: "#FFF",
        marginBottom: "0 !important",
        width: "100%",
        "& .MuiOutlinedInput-root": {
          fontSize: `${pxToRem(16)} !important`,
          lineHeight: "100% !important",
          padding: "0",
          "& .MuiButtonBase-root": {
            display: "flex !important",
          },
          "& .MuiSelect-outlined": {
            padding: "0 !important",
          },
          "& .MuiInputBase-input": props.isMobile
            ? {
                // padding: "50px !important",
                whiteSpace: "normal",
                width: "90%",
                overflow: "visible",
                height:
                  influenceOption === "TYPE1"
                    ? "3.0375em !important"
                    : "1.4375em",
              }
            : {},
        },
      }}
      SelectProps={{
        MenuProps: {
          PaperProps: {
            className: classes.listItemClass,
          },
        },
      }}
      className=" unitSelect"
      fullWidth
      select
      InputProps={{
        endAdornment: (
          <React.Fragment>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {influenceOption === "TYPE1" &&
                influenceOption === "TYPE1" &&
                !props?.isMobile && (
                  <div
                    className={classes.swicthButtons}
                    style={{
                      marginRight: "40px",
                      display: "flex",
                    }}
                  >
                    {renderButton(
                      true,
                      "Evaluative metric",
                      neutralScaler !== "NumericValue",
                      (e) => {
                        setNeutralScaler("");
                        if (unitValueOption === "timeofday") {
                          setNeutralScaler("NumericValue");
                          setShowSnakbar(true);
                          setSnakbarMsg(
                            "You can not unChecked when unit is time Of day"
                          );
                          setMsgType("error");
                        }
                        setFlagValue("no");
                      }
                    )}
                    {renderButton(
                      false,
                      "Neutral metric",
                      neutralScaler === "NumericValue",
                      (e) => {
                        setNeutralScaler("NumericValue");
                        if (unitValueOption === "timeofday") {
                          setNeutralScaler("NumericValue");
                          setShowSnakbar(true);
                          setSnakbarMsg(
                            "You can not unChecked when unit is time Of day"
                          );
                          setMsgType("error");
                        }
                        setFlagValue("no");
                      }
                    )}
                  </div>
                )}
            </Box>
          </React.Fragment>
        ),
      }}
      placeholder="Select a method to measure this influence"
      // label="Unit of measurement"
      value={influenceOption || ""}
      onChange={(e) => setInfluenceOption(e.target.value)}
    >
      {InfluenceSelectOption.filter((it) => {
        if (props?.outComeValue?.type === "TYPE12") {
          return it.id !== "TYPE1";
        }
        return it.id !== props?.outComeValue?.type;
      })?.map((option: any) => (
        <MenuItem
          key={option.id}
          value={option.id}
          sx={{ display: "flex !imporant" }}
        >
          {option.value}
        </MenuItem>
      ))}
    </TextField>
  );
  //   const onArchiveInfuance = () => {
  //     const token = localStorage.getItem('userDetails');
  //     userService
  //       ?.archiveInfluanceData(
  //         {
  //           archive: true,
  //           influenceId: props?.influenceData?.influenceId,
  //         },
  //         token
  //       )
  //       .then((res) => {
  //         setShowSnakbar(true);
  //         setSnakbarMsg(res.message);
  //         setMsgType('success');
  //         // setShowOutcome(false);
  //         // setAddOutcomeTracking(true);
  //         let timerId: number = window.setTimeout(() => {
  //           props.handleCloseStartModal();
  //           props.getInfluenceList();
  //         }, 1000);
  //       })
  //       .catch((error) => {
  //         setShowSnakbar(true);
  //         setSnakbarMsg(
  //           error?.response?.data?.description
  //             ? error?.response?.data?.description
  //             : error.message
  //         );
  //         setMsgType('error');
  //       });
  //   };

  const toolTipText =
    "This is a metric you want to observe without setting any goals for it, and whatever you input when tracking is neither good nor bad.";

  return (
    <RootStyle
    // sx={showInfluence ? { height: '100vh' } : { height: 'auto' }}
    >
      {/* SNACK BAR */}
      <SnackBarComp
        showSnakbar={showSnakbar}
        handleCloseSnakbar={handleCloseSnakbar}
        snakbarMsg={snakbarMsg}
        type={msgType ? msgType : "info"}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h2"
          component="h2"
          className={classes.registrationHeading}
        >
          Change scale type: {props?.outComeValue?.name}
        </Typography>
        {/* {props.influenceData?.uid && (
          <div>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={openMenu ? 'long-menu' : undefined}
              aria-expanded={openMenu ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleClickAnchorElTwo}
            >
              <Donts />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
              anchorEl={anchorElTwo}
              open={openMenu}
              onClose={handleCloseAnchorElTwo}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: '20ch',
                },
              }}
            >
              <MenuItem>Change scale type</MenuItem>
              <MenuItem>Transform to influence</MenuItem>
              <MenuItem onClick={() => navigate("/version")}>History version</MenuItem>
              <MenuItem onClick={onArchiveInfuance}>
                Archive this metric
              </MenuItem>
            </Menu>
          </div>
        )} */}
      </div>
      {/* <Typography variant="h4" component="h4" className={classes.pageTitle}>
      
      </Typography> */}
      <Grid
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        sx={{ my: "26px" }}
      >
        <Typography variant="caption" component="div">
          Metric information
        </Typography>
        {/* {!props.showInfluence && (
          <Grid
            display="flex"
            alignItems="center"
            sx={{ cursor: 'pointer' }}
            onClick={() => showDrawer()}
          >
            <GetIdeasIcon />
            <span className={classes.needSomeIdeas}>Need some ideas?</span>
          </Grid>
        )} */}
        {/* RIGHT SIDE DRAWER */}
        {/* {!props.showInfluence && (
          <IdeasDrawer
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            influenceIdeas={props.influenceIdeas}
            setInfluenceData={props.setInfluenceData}
          />
        )} */}
      </Grid>

      <Grid
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <TextField
          className="fillTextInput fullWidth"
          placeholder="Add an influence you want to track"
          variant="outlined"
          fullWidth
          name="influence"
          disabled
          value={props?.outComeValue?.name}
          onChange={(e) => setInfluenceInput(e.target.value)}
          inputProps={ariaLabel}
        />
        {/* SCALE OPTION */}
        {/* {influenceOption === 'TYPE2' && (
          <div
            className={clsx(classes.colorBlock, classes.spaceBetween)}
            onClick={handleClickOpenColor}
          >
            <p className={classes.colorBlockText}>Set a color</p>
            <span
              className={classes.colorCode}
            //   style={{
            //     backgroundColor:
            //       props.influenceData?.uid && selectedColor.length === 0
            //         ? props.influenceData?.options[0]?.colorCode
            //         : selectedColor.length > 0
            //         ? selectedColor[0]?.colorCode
            //         : selectedColor?.color[0],
            //   }}
            ></span>
          </div>
        )} */}
        {/* CHOOSE COLOR PALETTE */}
        <ColorPickerDialog
          openColor={openColor}
          onCloseColor={handleCloseColor}
        />
      </Grid>
      <Grid className={classes.selectWrapper}>
        <Typography
          variant="caption"
          component="div"
          className={classes.influenceCaption}
        >
          Select a method to measure this influence
        </Typography>
        {/* SET UNIT VALUE */}
        {influenceOption === "TYPE1" ? (
          <>
            <Grid display="flex" alignItems="center" flexWrap="wrap">
              {inputTextFieldw}
            </Grid>
            {type1Render()}
          </>
        ) : influenceOption === "TYPE2" ? (
          // SCALE OPTION
          <>
            {inputTextFieldw}
            <Grid sx={{ mt: "16px" }}>
              <ProgressBarStepperNew
                selectedColor={selectedColor}
                setTextInput={props?.setTextInput}
                textInput={props?.textInput}
                setShowInput={setShowInput}
                setEditStatus={setEditStatus}
                showInput={showInput}
                editStatus={editStatus}
                absenceToggle={absenceToggle}
              />
            </Grid>
          </>
        ) : (
          // YES/NO OPTION
          inputTextFieldw
        )}
      </Grid>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        gap={"32px"}
        mt={2}
      >
        <NewButton
          buttonText="Change"
          type="submit"
          variant="contained"
          onClick={() => TransformInfluence()}
          isDisabled={
            influenceInput == undefined ||
            (influenceOption != "TYPE1" &&
              influenceOption != "TYPE2" &&
              influenceOption != "TYPE3")
          }
          isLoadong={showLoader}
        />
        <NewButton
          buttonText="Cancel"
          onClick={() => props?.setTransformToInfluenceTracking(false)}
          variant="outlined"
        />
      </Box>
    </RootStyle>
  );
};
export default LeftTransformInInflu;
