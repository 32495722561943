import { Box } from "@mui/material"
import CommonTypo from "../../components/CommonTypo"
import React from "react"

const MoreButton = ({
    onClick,
    active,
    showMoreButton = true,
    fullHeight = true
}) => {
    return <Box sx={{
        height: fullHeight ? "40px" : "2px",
        position: "relative",
        display: "flex",
        marginTop: "16px",
        alignItems: "center",
        justifyContent: "center",
    }} onClick={onClick}>
        <Box sx={{
            height: "40px",
            padding: showMoreButton ? "16px 12px 16px 16px" : "0px",
            "display": "flex",
            alignItems: "center",
            gap: "8px",
            width: "max-content",
            background: "#EEEEEE",
            borderRadius: "20px",
            zIndex: 1,
            "::after": {
                content: "''",
                display: "block",
                width: showMoreButton ? "calc(50% - 50px)" : "calc(50%)",
                height: "1px",
                backgroundColor: "#EEEEEE",
                position: "absolute",
                right: "0",
                top: "50%", /* Position below the button */
            },
            "::before": {
                content: "''",
                display: "block",
                width: showMoreButton ? "calc(50% - 50px)" : "calc(50%)",
                height: "1px",
                backgroundColor: "#EEEEEE",
                position: "absolute",
                left: "0",
                top: "50%", /* Position below the button */
            }
        }}>
            {showMoreButton && (
                <>
                    <CommonTypo size={16} color="#656565" fontWeight={600}>{active ? "Less" : "More"}</CommonTypo>
                    <div style={{
                        rotate: active ? "180deg" : "0deg",
                        "display": "flex",
                        alignItems: "center",
                    }}>
                        <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.825 0.666504L5 4.48317L1.175 0.666504L0 1.8415L5 6.8415L10 1.8415L8.825 0.666504Z" fill="#656565" />
                        </svg>
                    </div>
                </>
            )}

        </Box>



    </Box>
}

export default MoreButton