import {
  CategoryGetList,
  InfluenceGetList,
  IUserAction,
  journeyList,
  notesList,
  OutcomeGetList,
  ReflectiveGetList,
} from "../../interfaces/outcomeModal";

export const setUserType = (userType: string): IUserAction => ({
  type: "SET_USER_TYPE",
  payload: userType,
});

export const setUserObject = (userObject: any): IUserAction => ({
  type: "SET_USER_OBJECT",
  payload: userObject,
});

export const setUserStatus = (isNewUser: boolean): IUserAction => ({
  type: "SET_USER_STATUS",
  payload: isNewUser,
});

export const setUserId = (id: string): IUserAction => ({
  type: "SET_USERID",
  payload: id,
});

export const setUserToken = (token: string): IUserAction => ({
  type: "SET_TOKEN",
  payload: token,
});

export const setUserLevel = (level: string): IUserAction => ({
  type: "SET_USER_LEVEL",
  payload: level,
});

export const addOutcomeAction = (
  outcomeList: OutcomeGetList[]
): IUserAction => ({
  type: "OUTCOME_LIST",
  payload: outcomeList,
});

export const addInfluenceAction = (
  influenceList: InfluenceGetList[]
): IUserAction => ({
  type: "INFLUENCE_LIST",
  payload: influenceList,
});

export const addRefelectiveAction = (
  reflectiveList: ReflectiveGetList[]
): IUserAction => ({
  type: "REFLECTIVE_LIST",
  payload: reflectiveList,
});

export const addJourneyAction = (journeyList: journeyList): IUserAction => ({
  type: "JOURNEY_LIST",
  payload: journeyList,
});

export const addNotesAction = (notesList: notesList): IUserAction => ({
  type: "NOTES_LIST",
  payload: notesList,
});

export const addCategoryAction = (
  categoryList: CategoryGetList[]
): IUserAction => ({
  type: "CATEGORY_LIST",
  payload: categoryList,
});

export const addCategoryJourneyAction = (
  journeyCategoryList: any
): IUserAction => ({
  type: "JOURNEY_CATEGORY_LIST",
  payload: journeyCategoryList,
});

export const addCategoryExpandAction = (
  journeyCategoryExpandList: any
): IUserAction => ({
  type: "JOURNEY_CATEGORY_EXPAND_LIST",
  payload: journeyCategoryExpandList,
});

export const addCategoryExpandActionWithPrevious = (
  journeyCategoryExpandList: any
): IUserAction => ({
  type: "JOURNEY_CATEGORY_EXPAND_LIST_WUTH_PREVIOUS",
  payload: journeyCategoryExpandList,
});

export const addNotesJourneyAction = (
  journeyNotesList: any
): IUserAction => ({
  type: "JOURNEY_NOTES_LIST",
  payload: journeyNotesList,
});
export const pushCategoryJourneyAction = (
  journeyNotesList: any
): IUserAction => ({
  type: "PUSH_JOURNEY_NOTES_LIST",
  payload: journeyNotesList,
});

export const addOutcomePopup = (
  payload: any
): IUserAction => ({
  type: "ADD_OUTCOME_POPUP",
  payload: payload,
});

export const isPersonalMode = (
  payload: any
): IUserAction => ({
  type: "IS_PERSONAL",
  payload: payload,
});

export const isExpandMode = (
  payload: any
): IUserAction => ({
  type: "IS_EXPAND",
  payload: payload,
});

export const isOpenTrackModal = (
  payload: any
): IUserAction => ({
  type: "SET_IS_OPEN_MODAL",
  payload: payload,
});
export const isRequiredRefresh = (payload: boolean): IUserAction => ({
  type: "SET_REQUIRED_REFRESH",
  payload
})
export const isEmptyState = (
  payload: any
): IUserAction => ({
  type: "IS_EMPTY",
  payload: payload,
});
