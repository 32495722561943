import axios from "axios";
import { baseEndpoints } from "./config";

const headerToken = localStorage.getItem("userDetails");

const getOutcomeList = async (token: string) => {
  const { data } = await axios
    .post(
      `${baseEndpoints.outcome}/get`,
      {},
      {
        headers: {
          "x-access-token": token ? token : headerToken,
        },
      }
    )
    .then((response) => {
      return response;
    });
  return data;
};

const createOutcome = async (outcomeValue: any, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.outcome}/create`, outcomeValue, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const EditOutcome = async (outcomeValue: any, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.outcome}/update`, outcomeValue, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const getOutcomeSuggestion = async (token: string) => {
  const { data } = await axios
    .post(
      `${baseEndpoints.outcomeSuggestion}/get`,
      {},
      {
        headers: {
          "x-access-token": token ? token : headerToken,
        },
      }
    )
    .then((response) => {
      return response;
    });
  return data;
};

export const outcomeService = {
  getOutcomeList,
  createOutcome,
  EditOutcome,
  getOutcomeSuggestion,
};
