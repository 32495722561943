import { Typography } from "@mui/material";
import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import palette from "../../theme/palette";

const RatingGraph = () => {
  const options = {
    chart: {
      type: "bar",
      height: 476,
      marginLeft: 50,
      marginBottom: 20,
    },
    title: {
      text: "",
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
      title: {
        text: "",
      },

      labels: {
        align: "left",
        reserveSpace: true,

        style: {
          fontSize: "16px",
          fontWeight: 500,
          color: palette.primary.light,
          fill: palette.primary.light,
        },
      },
    },
    yAxis: {
      min: 0,
      max: 5,
      title: {
        text: "",
      },
      labels: {
        style: {
          fontSize: "12px",
          fontWeight: 400,
          color: palette.primary.light,
          fill: palette.primary.light,
        },
      },
    },
    colors: ["#F5DB87", "#68D7A8", "#4CB3FD", "#7572FC", "#C191FF"],
    series: [
      {
        data: [5, 4, 5, 3, 4, 5, 5],
      },
      {
        data: [4, 4, 5, 1, 2, 4, 5],
      },
      {
        data: [3, 3, 3, 5, 4, 3, 2],
      },
      {
        data: [5, 5, 4, 3, 4, 4, 5],
      },
      {
        data: [2, 3, 2, 3, 4, 3, 3],
      },
    ],
  };

  return (
    <>
      <Typography variant="h3" letterSpacing={"0.02em"}>
        Rating by outcome per day
      </Typography>
      <div style={{ marginTop: "20px" }}>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>
    </>
  );
};

export default RatingGraph;
