import { IContactsActions } from "../../interfaces/contactsModal";

export const setAllContacts = (
  payload: any
): IContactsActions => ({
  type: "SET_ALL_CONTACTS",
  payload: payload,
});

export const setGroupContacts = (
  payload: any
): IContactsActions => ({
  type: "SET_GROUP_CONTACTS",
  payload: payload,
});

export const setShareModalOpen = (payload: any) : IContactsActions=> ({
  type: "SET_SHARE_MODAL_OPEN",
  payload: payload,
})