import { pxToRem } from "../typography";

export default function Button(theme: any) {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          textTransform: "inherit",
          padding: "15px",
          // color: theme.palette.common.white,
          fontSize: pxToRem(20),
          lineHeight: pxToRem(30),
          fontWeight: 600,
          "&::after": {
            content: '""',
            position: "absolute",
            width: "106px",
            height: "52px",
            right: 0,
            bottom: 0,
          },
        },
      },
    },
  };
}
