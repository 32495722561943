
import React from 'react'

export default function RightGreenArrow() {
    return (
        <div>
            <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.16915 0.707482C0.844809 0.42948 0.34375 0.659934 0.34375 1.08711V10.9129C0.34375 11.3401 0.844809 11.5705 1.16915 11.2925L6.90085 6.37963C7.13366 6.18008 7.13366 5.81992 6.90085 5.62037L1.16915 0.707482Z" fill="#11734A" />
            </svg>
        </div>
    )
}
