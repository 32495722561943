import * as React from "react";
import { OutComeColorModal } from "../../interfaces/outcomeModal";
import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
} from "@mui/material";
import { fixOutcomeColorPalette } from "../../utils/staticDataJson";
import palette from "../../theme/palette";
import ModalCloseIcon from "../../assets/images/icon/modalCloseIconBlack";

interface ColorPickerDialogModal {
  openColor: boolean;
  onCloseColor: (value: OutComeColorModal) => void;
}

const ColorPickerDialog = (props: ColorPickerDialogModal) => {
  const { onCloseColor, openColor } = props;

  const handleListItemClick = (value: OutComeColorModal) => {
    onCloseColor(value);
  };
  return (
    <Dialog
      open={openColor}
      onClose={() => onCloseColor({ id: -1, color: [] })}
    >
      <DialogTitle
        sx={{
          color: palette.primary.light,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        Choose a palette
        <IconButton onClick={() => onCloseColor({ id: -1, color: [] })}>
          <ModalCloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Divider />
        {
          <List sx={{ pt: 0 }}>
            {fixOutcomeColorPalette.map(
              (palette: OutComeColorModal, index: number) => (
                <ListItem
                  button
                  onClick={() => handleListItemClick(palette)}
                  key={index}
                >
                  <ListItemText
                    primary={palette.id}
                    style={{ marginRight: "10px" }}
                  />

                  {palette.color.map((pal) => {
                    return (
                      <div
                        style={{
                          background: pal,
                          width: "100px",
                          height: "50px",
                        }}
                        key={`${pal}_${palette.id}`}
                      ></div>
                    );
                  })}
                </ListItem>
              )
            )}
          </List>
        }
      </DialogContent>
    </Dialog>
  );
};
export default ColorPickerDialog;
