import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  styled,
} from "@mui/material";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import palette from "../theme/palette";

const useStyles = makeStyles((theme) => ({
  input: {
    color: "#3E4240",
    padding: "20px",

    height: "56px",
    width: "100%",
    fontSize: "16px",

    backgroundColor: "#FFFFFF !important",
    border: "1px solid #EDEDED !important",
    borderRadius: "12px !important ",
    outline: "none",
  },
  label: {
    fontWeight: 400,
    color: "#000000",
    fontSize: "14px",
  },
  validationError: {
    "& input": {
      background: "#f5ebe8 !important",
      borderColor: "#ef8b6e !important",
      color: "#ef8b6e !important",
    },
  },
}));

interface inputInterface {
  type: any;

  name: any;
  label: any;
  onChange?: any;
  value?: any;
}

function InputGroup(props: inputInterface) {
  const classes = useStyles();
  let commonProps = {};
  if (props?.onChange) {
    commonProps = {
      ...commonProps,
      onChange: props?.onChange,
    };
  }
  if (props?.value) {
    commonProps = {
      ...commonProps,
      value: props?.value,
    };
  }
  return (
    <>
      <label className={classes.label}>{props.label}</label>
      <Box sx={{ mt: 1.2 }}>
        <input
          placeholder=""
          type={props.type}
          name={props.name}
          className={classes.input}
          {...commonProps}
        />
      </Box>
    </>
  );
}

export default InputGroup;

const InputField = styled(TextField)`
  & .MuiOutlinedInput-root {
    padding: 0;
    position: relative;
    & .MuiInputAdornment-root {
      margin-left: 0px;
    }
  }
  button {
    position: absolute;
    right: 20px;
  }
  & .MuiOutlinedInput-input {
    height: 28px;
    background: #fff;
    border: 1px solid #ededed;
    border-radius: 16px;
    // padding: 15.5px 20px;
    padding: 13px 20px;
    font-size: 16px;
    color: #190d1a;
    ::placeholder {
      color: #434243;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 100%;
    }
    :hover {
      border: 1px solid ${palette.primary.main};
    }
    :focus {
      border: 1px solid ${palette.primary.main};
    }
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  & .MuiFormHelperText-root {
    color: #ef8b6e !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    display: flex;
    margin-left: 0;

    & div {
      display: flex;
      align-items: center;
      gap: 3px;
    }
  }
`;
const ValidationMessage = styled("div")(({ theme }) => ({
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "140%",

  marginTop: "8px",
  [theme.breakpoints.up("xl")]: {},
  [theme.breakpoints.up("lg")]: {},
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.down("sm")]: {},
}));
const PasswordButton = styled(IconButton)`
  :hover {
    background: none;
  }
`;
export const Input = ({
  placeholder,
  value,
  onChange,
  errorMessage,
  setErrorMessage,
  validationFunction,
  type,
  height,
  disabled = false,
}: any) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // const handleMouseDownPassword = (event) => {
  //   event.preventDefault();
  // };
  return (
    <InputField
      type={type === "password" ? (showPassword ? "text" : "password") : type}
      error={errorMessage !== ""}
      id="outlined-basic"
      placeholder={placeholder}
      variant="outlined"
      className={errorMessage ? classes.validationError : ""}
      fullWidth
      sx={{
        height: { xl: height, lg: height, md: height },
        "& .MuiOutlinedInput-input": {
          "&:hover": {
            border: "1px solid var(--color-new-main)",
          },
          "&:focus": {
            border: "1px solid var(--color-new-main) !important",
          },
        },
      }}
      size="small"
      disabled={disabled}
      helperText={
        errorMessage ? (
          <ValidationMessage>
            {/* <CancelOutlinedIcon fontSize="inherit" /> */}
            {/* <img src={Error} alt="" /> */}
            <span>{errorMessage}</span>
          </ValidationMessage>
        ) : null
      }
      value={value}
      onBlur={(e) => {
        if (e.target.value !== "") {
          let error = validationFunction(e.target.value);
          console.log("error", error);
          setErrorMessage(error);
        }
      }}
      onChange={(e) => {
        onChange(e.target.value);
        // let error = validationFunction(e.target.value);
        // console.log(error);
        setErrorMessage("");
      }}
      InputProps={{
        endAdornment: (
          <>
            {type === "password" && (
              <InputAdornment position="end">
                <PasswordButton
                  aria-label="toggle password visibility"
                  edge="end"
                  onClick={handleClickShowPassword}
                  // onMouseDown={handleMouseDownPassword}
                  disableRipple
                >
                  {showPassword ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M2.66675 10C2.66675 10 5.33342 4.66666 10.0001 4.66666C14.6668 4.66666 17.3334 10 17.3334 10C17.3334 10 14.6668 15.3333 10.0001 15.3333C5.33342 15.3333 2.66675 10 2.66675 10Z"
                        stroke="#190D1A"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10.0002 12C11.1048 12 12.0002 11.1046 12.0002 10C12.0002 8.89543 11.1048 8 10.0002 8C8.89567 8 8.00024 8.89543 8.00024 10C8.00024 11.1046 8.89567 12 10.0002 12Z"
                        stroke="#190D1A"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M13.96 13.96C12.8204 14.8287 11.4328 15.3099 10 15.3334C5.33335 15.3334 2.66669 10 2.66669 10C3.49595 8.45463 4.64611 7.10444 6.04002 6.04004M8.60002 4.8267C9.05891 4.71929 9.52873 4.6656 10 4.6667C14.6667 4.6667 17.3334 10 17.3334 10C16.9287 10.7571 16.4461 11.4699 15.8934 12.1267M11.4134 11.4134C11.2303 11.6099 11.0095 11.7675 10.7641 11.8768C10.5188 11.9861 10.254 12.0449 9.98542 12.0496C9.71687 12.0544 9.45013 12.005 9.2011 11.9044C8.95206 11.8038 8.72584 11.6541 8.53592 11.4641C8.346 11.2742 8.19628 11.048 8.09569 10.799C7.9951 10.5499 7.94571 10.2832 7.95044 10.0146C7.95518 9.7461 8.01396 9.48127 8.12327 9.23593C8.23258 8.9906 8.39019 8.7698 8.58669 8.5867"
                        stroke="#190D1A"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M2.66669 2.66669L17.3334 17.3334"
                        stroke="#190D1A"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </PasswordButton>
              </InputAdornment>
            )}
          </>
        ),
      }}
    />
  );
};
