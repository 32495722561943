import { Box, Dialog } from "@mui/material"
import React, { useState } from "react"
import Steps from "./Steps"
import Step1 from "./step1"
import NewButton from "../../../components/NewButton"
import Step2 from "./step2"
import Step3 from "./step3"
import SnackBarComp from "../../../components/snackBar"
import { userService } from "../../../services/auth"
import dayjs from "dayjs"
const defaultMetrics: {
    name: string;
    influenceType: string;
    dates?: {
        "response"?: string | number,
        "date"?: string,
        "comment"?: string
    }[]
}[] = [
        {
            "name": undefined,
            "influenceType": undefined,
        },
        {
            "name": undefined,
            "influenceType": undefined,
        },
        {
            "name": undefined,
            "influenceType": undefined,
        }
    ]
const UploadData = ({
    visibleModal,
    setVisibleModal
}) => {
    const [currentStep, setCurrentStep] = useState(1)
    const [metrics, setMetric] = useState(defaultMetrics)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [showSnakbar, setShowSnakbar] = useState(false);
    const [snakbarMsg, setSnakbarMsg] = useState("");
    const [msgType, setMsgType] = useState("");
    let disabledButton = true
    const withnamesandtype = [...metrics].filter(it => it.name && it.influenceType)
    if (withnamesandtype.length) {
        disabledButton = false
    }
    const withnamesnotype = [...metrics].filter(it => it.name && !it.influenceType)
    if (withnamesnotype.length) {
        disabledButton = true
    }
    const closeModal = () => {
        setCurrentStep(1)
        setMetric([])
        setMetric(defaultMetrics)
        setStartDate(null)
        setEndDate(null)
        setVisibleModal(false)
    }
    const changeValueOfTable = (index, date, value) => {
        const oldCopy = [...metrics]
        console.log("value", date, oldCopy[index].influenceType, value, Number.isNaN(value));

        if (oldCopy[index].influenceType === "TYPE1" && value && Number.isNaN(Number(value))) {
            setShowSnakbar(true)
            setMsgType("error")
            setSnakbarMsg("Please enter value in number")
            return
        }
        if (oldCopy[index].influenceType === "TYPE2" && value && (Number.isNaN(Number(value)) || Number(value) < 0 || Number(value) > 5)) {
            setShowSnakbar(true)
            setMsgType("error")
            setSnakbarMsg("Please enter value in between 1 to 5")
            return
        }
        if (oldCopy[index].influenceType === "TYPE3" && value && (!["y", "n"].includes(value))) {
            setShowSnakbar(true)
            setMsgType("error")
            setSnakbarMsg("Please enter value in y or n")
            return
        }
        if (oldCopy[index].dates && oldCopy[index].dates.length) {
            const found = oldCopy[index].dates.findIndex(it => it.date === date)
            console.log("found", oldCopy[index].dates, date, found);

            if (found > -1) {
                oldCopy[index].dates[found].response = value
            } else {
                oldCopy[index].dates.push({
                    date,
                    response: value
                })
            }
        } else {
            oldCopy[index].dates = [{
                date,
                response: value
            }]
        }
        setMetric(oldCopy)
    }
    const changeCommentOfTable = (index, date, value) => {
        const oldCopy = [...metrics]
        if (oldCopy[index].dates && oldCopy[index].dates.length) {
            const found = oldCopy[index].dates.findIndex(it => it.date === date)
            if (found > -1) {
                oldCopy[index].dates[found].comment = value
            } else {
                oldCopy[index].dates.push({
                    date,
                    comment: value
                })
            }
        } else {
            oldCopy[index].dates = [{
                date,
                comment: value
            }]
        }
        setMetric(oldCopy)
    }
    const importData = () => {
        const token = localStorage.getItem("userDetails")
        const responses = [...metrics].filter(it => it.dates && it.dates.length > 0).map(it => {
            it.dates = it.dates.filter(ite => ite.response)
            if(it.influenceType === "TYPE1") {
                it.influenceType = "TYPE12"
            }
            if(it.dates.length > 0) {
                if(it.influenceType === "TYPE3") {
                    it.dates = it.dates.map(ite => {
                        if(ite.response === "y") {
                            ite.response = "yes"
                        }
                        if(ite.response === "n") {
                            ite.response = "no"
                        }
                        return ite
                    })
                }
                it.dates = it.dates.map(ite => {
                    if(it.influenceType !== "TYPE3" && ite.response) {
                        ite.response = Number(ite.response)
                    }
                    if(!ite.comment) {
                        delete ite.comment
                    }
                    ite.date = dayjs(ite.date).format("MM-DD-YYYY")
                    return ite
                })
            }

            return it
        })
        console.log("metrics", responses);
        userService.provisionRespones({
            influencesData: responses
        }, token).then((data) => {
            closeModal()
        }).catch(() => {
            // sethasSuccess(false)
            // setApiCalled(true)
        })
    }
    return <>
        <SnackBarComp
            showSnakbar={showSnakbar}
            handleCloseSnakbar={() => {
                setShowSnakbar(false);
            }}
            snakbarMsg={snakbarMsg}
            type={msgType ? msgType : "success"}
        />
        <Dialog
            disableEscapeKeyDown
            onClose={closeModal}
            open={visibleModal}
            className="dialogWrapper dialogWrapper-tracking dialogWrapper-tracking-cal"
            sx={{
                "& .MuiPaper-root": {
                    padding: '32px 40px',
                    gap: '48px',
                    borderRadius: '20px',
                    opacity: '0px',

                }
            }}
        >

            <Box display={"flex"} alignItems={"center"} position={"relative"}>
                {currentStep === 1 && <Step1 metrics={metrics} setMetric={setMetric} />}
                {currentStep === 2 && <Step2 startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />}
                {currentStep === 3 && <Step3 startDate={startDate} metrics={metrics.filter(it => it.name && it.influenceType)} endDate={endDate}
                    changeValueOfTable={changeValueOfTable} changeCommentOfTable={changeCommentOfTable} />}
                <Steps currentStep={currentStep} />
                <Box position={"absolute"} bottom={0} right={0}>
                    <NewButton
                        variant="contained"
                        buttonText={"Next"}
                        isDisabled={disabledButton}
                        onClick={() => {
                            if (currentStep === 3) {
                                importData();
                            } else {
                                setCurrentStep((prvState) => prvState + 1);
                            }
                        }}
                    />
                </Box>
            </Box>

        </Dialog>
    </>
}

export default UploadData
