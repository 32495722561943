import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material";
import Header from "./header/Header.jsx";
import Footer from "./footer/Footer.jsx";
import SideBar from "./sidebar/SideBar.jsx";

// center content
import SetupGoals from "./RightSideCom/SetupGoals.jsx";
import SetGoalInfluence from "./sidebarContentCom/setGoalInfluence/SetGoalInfluence.jsx"
import { userService } from "../../services/auth";
import moment from "moment";
import SnackBarComp from "../../components/snackBar";

export default function Goals() {
  const [isMenuSelected, setIsMenuSelected] = useState({
    outcome: true,
    influence: false,
    selectedInfluenceType: "",
    outcomeId: "",
    influenceId: "",
    isExpand: true,
    outComeData: [],
    getOutComeData: [],
    getInfluenceData: [],
    influenceData: null,
    selectedOutcomeAvgData: [],
    // avgData
    selectedInfluenceAvgData: [],
    isOtherInfluanceSelected: false,
    selectedInfluancepPercentData: {}
  })

  const [golesMenu, setGolesMenu] = useState([])
  const [otherGolesMenu, setOtherGolesMenu] = useState([])
  // snakbar
  const [showSnakbar, setShowSnakbar] = useState(false);
  const [snakbarMsg, setSnakbarMsg] = useState("");
  const [msgType, setMsgType] = useState("");
  const MainWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // height: "100vh",
    width: "100%",
    background: "#fff",
    padding: "60px",
    paddingBottom: "40px",
    [theme.breakpoints.down("lg")]: {
      padding: "21px",
    },
  }));

  const Container = styled("div")(({ theme }) => ({
    // height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",

    [theme.breakpoints.down("lg")]: {
      padding: "39px",
    },

  }));
  const CenterContainer = styled("div")(({ theme }) => ({
    minHeight: 'calc(100vh - 146px)',
    maxHeight: 'calc(100vh - 150px)',
    display: "flex",
    [theme.breakpoints.down("md")]: {
      position: 'relative',
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  }));
  const [isAPiCall, setIsAPiCall] = useState(false)

  useEffect(() => {
    const token = localStorage.getItem("userDetails")
    userService.getGoalsMenu({
      from: moment(new Date()).format("MM-DD-YYYY"),
      to: moment().subtract(1, 'months').endOf('month').format('MM-DD-YYYY'),
    }, token).then((res) => {
      setGolesMenu(res?.outcome_data)
      setOtherGolesMenu(res?.otherInfluences)
      if (!isMenuSelected?.outcomeId) {
        localStorage.setItem("selectedGoleID", JSON.stringify({ isOutcome: true, outcomeId: res?.outcome_data[0]?.outcomeId }))
        setIsMenuSelected({ ...isMenuSelected, outcome: true, outcomeId: res?.outcome_data[0]?.outcomeId })
      }
    }).catch((err) => {
      setShowSnakbar(true);
      setMsgType("error");
      setSnakbarMsg("Somthing Went wrong");
    })
  }, [])


  const handleCloseSnakbar = () => {
    setShowSnakbar(false);
  };

  useEffect(() => {
    if ((!isMenuSelected?.influenceData && isMenuSelected.influence) || isAPiCall) {
      const token = localStorage.getItem("userDetails")
      let body;
      body = {
        from: moment().subtract(1, 'months').startOf('month').format('MM-DD-YYYY'),
        to: moment().subtract(1, 'months').endOf('month').format('MM-DD-YYYY'),
        influenceId: isMenuSelected?.influenceId,
        // type: "TYPE1"
        type: "TYPE1"
      }
      userService
        .influenceGoal(body, token)
        .then((data) => {
          setIsMenuSelected({
            ...isMenuSelected,
            influenceData: data?.influence,
            getInfluenceData: data?.data[0]
          })
          // setShowSnakbar(true);
          // setMsgType("success");
          // setSnakbarMsg("Successfull");
        })
        .catch((error) => {
          setShowSnakbar(true);
          setMsgType("error");
          setSnakbarMsg(error?.response?.data?.description);
        })
    }
  }, [isMenuSelected?.influenceId, isAPiCall])

  const scrollPosition = useRef(0)
  const containerRef = useRef<HTMLDivElement | undefined>()
  useEffect(() => {
    if(!containerRef.current){
      return;
    }
    containerRef.current.scrollTo(0,scrollPosition.current)
  },[isMenuSelected]);

  return (
    <>
      <MainWrapper>
        <SnackBarComp
          showSnakbar={showSnakbar}
          handleCloseSnakbar={handleCloseSnakbar}
          snakbarMsg={snakbarMsg}
          type={msgType ? msgType : "info"}
        />
        <Container>
          <Header />
          <div>
            <CenterContainer>
              {/* <div> this is use for jumping issue (we need to remove overflow form side bar component as well ) */}
              {/* this change at afternoon */}
              <div ref={containerRef} style={{  overflow: "auto", height: "80vh" , alignSelf: "stretch", width: "21vw", position: "relative", marginInlineEnd: "10px" }}>
                <SideBar isMenuSelected={isMenuSelected} setIsMenuSelected={(newValue: typeof isMenuSelected)=>{
                  scrollPosition.current = containerRef.current!.scrollTop;
                  setIsMenuSelected(newValue) 
                }} golesMenu={golesMenu} otherGolesMenu={otherGolesMenu} />
              </div>
              {/* </div> */}
              <div style={{ overflow: 'auto', flex: 1 }}>
                {
                  isMenuSelected?.outcome && !isMenuSelected.influence ? <SetupGoals isMenuSelected={isMenuSelected} setIsMenuSelected={setIsMenuSelected} /> :
                    <SetGoalInfluence isMenuSelected={isMenuSelected} setIsMenuSelected={setIsMenuSelected} />
                }
              </div>
            </CenterContainer>
          </div>
        </Container>
      </MainWrapper>
      <Footer />
    </>
  );
}
