import React from 'react';


const MobileMenuIcon = () => {
    return (
        <>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21 6.33325H3" stroke="#3E4240" stroke-width="2" stroke-linejoin="round" />
                <path d="M21 12.3333H3" stroke="#3E4240" stroke-width="2" stroke-linejoin="round" />
                <path d="M21 18.3333H3" stroke="#3E4240" stroke-width="2" stroke-linejoin="round" />
            </svg>
        </>
    );
};

export default MobileMenuIcon;
