/*eslint eqeqeq: "off"*/
import React, { useContext } from "react";
import { Stepper, Step, StepLabel, Button, Grid } from "@mui/material";
import { ProUserStepper, UserStepper } from "../../utils/staticDataJson";
import palette from "../../theme/palette";
import { makeStyles } from "@material-ui/core";
import StepperBackArrowDisable from "../../assets/images/icon/stepperBackArrowDisable";
import StepperNextArrowDisable from "../../assets/images/icon/stepperNextArrowDisable";
import StepperNextArrow from "../../assets/images/icon/stepperNextArrow";
import StepperBackArrow from "../../assets/images/icon/stepperBackArrow";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { UserTypeContext } from "../../context/userStatus";
import { setUserStatus } from "../../store/actions/userTypeAction";
import useWidth from "../../utils/useWidth";
import { trackingService } from "../../services/tracking";

const useStyles = makeStyles((theme) => ({
  stepperWrapperBottom: {
    backgroundColor: palette.common.white,
    position: "fixed",
    width: "100%",
    bottom: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    zIndex: 1,
    padding: "20px 48px 20px 80px",
    [theme.breakpoints.down("xl")]: {
      padding: "20px 40px 20px 60px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
  },
  TopstepperWrapperBottom: {
    backgroundColor: palette.common.white,
    position: "fixed",
    width: "100%",
    top: 0,
    left: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    zIndex: 1,
    padding: "20px 48px 20px 80px",
    [theme.breakpoints.down("xl")]: {
      padding: "20px 40px 20px 60px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
  },
}));

interface BottomStepperModal {
  isOutcome: boolean;
  isInfluence: boolean;
  isRefQues: boolean;
  isTracking: boolean;
}

const BottomStepper = (props: BottomStepperModal) => {
  const userLevels = JSON.parse(localStorage.getItem("userValues"))  
  const { state: IUserTypeState, dispatch: userTypeDispatch } =
    useContext(UserTypeContext);
  const classes = useStyles();
  const width = useWidth();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(
    props?.isRefQues ? 2 : props?.isInfluence ? 1 : 0
  );
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const userType =
  userLevels?.level == "HIGH" ? ProUserStepper : UserStepper;
  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };
  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if (userLevels?.level == "HIGH") {
      if (activeStep != userType.length - 1) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    let nextStep = activeStep + 1;
    setSkipped(newSkipped);
    handleSliderNextValue(nextStep);
  };
  const handleSignincompelted = () => {
    const obj = {
      mixpanel_type: "SIGNUP_COMPLETED"
    };
    trackingService
      .signupCompletedBoarding(IUserTypeState.token, obj);
  }
  const handleSliderNextValue = (step: number) => {
    if (userLevels?.level == "HIGH") {
      if (step == 3) {
        userTypeDispatch(setUserStatus(true));
        handleSignincompelted()
        navigate("/tracking", { replace: true });
      }
      if (step == 2) {
        navigate("/reflective-questions", { replace: true });
      } else if (step == 1) {
        navigate("/influence", { replace: true });
      }
    } else {
      if (step == 1) {
        navigate("/influence", { replace: true });
      }
      if (step == 2) {
        userTypeDispatch(setUserStatus(true));
        handleSignincompelted()
        navigate("/tracking", { replace: true });
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    const backStep = activeStep - 1;
    handleSliderValue(backStep);
  };

  const handleSliderValue = (step: number) => { 
    if (userLevels?.level == "HIGH") {
      if (step == 1) {
        navigate("/influence", { replace: true });
      } else if (step == 0) {
        navigate("/outcome", { replace: true });
      }
    } else {
      if (step === 0) {
        navigate("/outcome", { replace: true });
      }
    }
  };
  return (
    <Grid
      // xs={9.63}
      item
      className={clsx( width > 768 ? classes.stepperWrapperBottom : classes.TopstepperWrapperBottom, "stepperWrapper")}
    >
      <Button
        className="no-after"
        style={{display: width < 768 ?"none" : "flex"}}
        disabled={activeStep === 0}
        onClick={handleBack}
        sx={{ mr: 1, color: palette.primary.main, p: 0 }}
      >
        {activeStep === 0 ? <StepperBackArrowDisable /> : <StepperBackArrow />}
      </Button>
      <Stepper activeStep={activeStep} alternativeLabel connector={<></>}>
        {userLevels?.level == "HIGH"
          ? userType.map((label, index) => {
              return (
                <Step key={index}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })
          : userType.map((label, index) => {
              return (
                <Step key={index}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
      </Stepper>
      <Button
        className="no-after"
        style={{display: width < 768 ?"none" : "flex"}}
        onClick={handleNext}
        sx={{ color: palette.primary.main, p: 0, cursor: "pointer" }}
        disabled={
          activeStep === userType.length - 1 ||
          (props?.isOutcome
            ? IUserTypeState?.outcomeList?.length == 0
            : props?.isInfluence
            ? IUserTypeState?.influenceList?.length == 0
            : props?.isRefQues
            ? IUserTypeState?.reflectiveList?.length == 0
            : props?.isTracking)
        }
      >
        {activeStep === userType.length - 1 ||
        (props?.isOutcome
          ? IUserTypeState?.outcomeList?.length == 0
          : props?.isInfluence
          ? IUserTypeState?.influenceList?.length == 0
          : props?.isRefQues
          ? IUserTypeState?.reflectiveList?.length == 0
          : props?.isTracking) ? (
          <StepperNextArrowDisable />
        ) : (
          <StepperNextArrow />
        )}
      </Button>
    </Grid>
  );
};
export default BottomStepper;
