import { makeStyles } from "@material-ui/core";
import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import palette from "../../theme/palette";
import { ProgressInfluencesList } from "../../utils/staticDataJson";

const RootStyle = styled("div")(({ theme }) => ({
  marginRight: "38px",

  [theme.breakpoints.down(1001)]: {
    marginRight: "0px!important",
  },
}));

const useStyles = makeStyles((theme) => ({
  secondColumn: {
    display: "flex!important",
    alignItems: "center!important",

    "& h6": {
      color: palette.secondary.contrast,
      fontSize: "12px!important",
      paddingLeft: "4px",
    },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: "13px 16px",
  border: "0",
  [`&.${tableCellClasses.body}`]: {
    fontSize: 16,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderRadius: "4px",
  margin: 0,
  display: "flex",
  "&:nth-of-type(odd)": {
    backgroundColor: palette.secondary.light,
  },
}));

const ProgressInfluences = () => {
  const classes = useStyles();

  return (
    <RootStyle>
      <Typography variant="h3" letterSpacing={"0.02em"}>
        Influences
      </Typography>
      <TableContainer
        component={Paper}
        elevation={0}
        sx={{ marginTop: "20px" }}
      >
        <Table sx={{ width: 788 }} aria-label="customized table">
          <TableBody>
            {ProgressInfluencesList.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell
                  component="th"
                  scope="row"
                  sx={{ width: "378px" }}
                >
                  {row.title}
                </StyledTableCell>

                <StyledTableCell width={138} className={classes.secondColumn}>
                  {row.daysTracked}
                  <Typography variant="h6">Days Tracked</Typography>
                </StyledTableCell>

                <StyledTableCell className={classes.secondColumn}>
                  {row.percent}
                  <Typography
                    variant="h6"
                    fontWeight={500}
                    color={`${palette.primary.light}!important`}
                  >
                    {row.unit}
                  </Typography>
                  <Typography variant="h6">{row.type}</Typography>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </RootStyle>
  );
};

export default ProgressInfluences;
