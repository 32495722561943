import React from "react";
const RightIconSwitch = ({ color = "#B7BDB9" }) => {
  return (
    <svg
      width="22"
      height="17"
      viewBox="0 0 22 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.00278 7.64566L8.29184 13.9347L19.9375 2.28906"
        stroke={color}
        strokeWidth="4"
      />
    </svg>
  );
};

export default RightIconSwitch;
