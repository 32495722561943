
export const ContactsDataJson = [
    {
        "id": "1",
        "name": "Coach",
        "children": [
            {
                "id": "2",
                "name": "Adison Vaccaro",
                "parent": "1"
            }
        ]
    },
    {
        "id": "3",
        "name": "Parter",
        "children": [
            {
                "id": "4",
                "name": "Chance Dolidis",
                "parent": "3"
            },
            {
                "id": "5",
                "name": "Marcus Press",
                "parent": "3"
            }
        ]
    },
    {
        "id": "6",
        "name": "Family",
        "children": [
            {
                "id": "7",
                "name": "Talan Bator",
                "parent": "6"
            },
            {
                "id": "8",
                "name": "Haylie Curtis",
                "parent": "6"
            },
            {
                "id": "9",
                "name": "Marcus Donin",
                "parent": "6"
            },

            {
                "id": "10",
                "name": "Zaire Calzoni",
                "parent": "6"
            },
            {
                "id": "11",
                "name": "Gretchen Passaquindici Arcand",
                "parent": "6"
            }
        ]
    }
]

export const ReviewData = (state, isCommon = false) => {
    const outComes = state?.outcomeList.length;
    if(isCommon) {
        const nonNuturalMatrix = state?.influenceList?.filter(it => it?.metric_type !== "NEUTRAL") 
        const allMetrics = [...nonNuturalMatrix, ...state?.outcomeList]
        return [
            {
                title: "Contacts",
                count: state?.contacts?.length || 0,
                description: state?.contacts?.map(it => it.name)?.join(", ")
            },
            {
                title: "Shared Metrics",
                count: allMetrics.length || 0,
                description: allMetrics?.map(it => it.name)?.join(", ")
            },
        ]
    }
    const nonNuturalMatrix = state?.influenceList?.filter(it => it?.metric_type !== "NEUTRAL")
    return [
        {
            title: "Contacts",
            count: state?.contacts?.length || 0,
            description: state?.contacts?.map(it => it.name)?.join(", ")
        },
        {
            title: "Outcomes",
            count: outComes || 0,
            description: state?.outcomeList?.map(it => it.name)?.join(", ")
        },
        {
            title: "Influences",
            count: nonNuturalMatrix.length || 0,
            description: nonNuturalMatrix?.map(it => it.name)?.join(", ")
        }
    ]
}

export const ReviewDataV2 = (state, isCommon = false) => {
    const outComes = state?.outcomeList?.length;
    if(isCommon) {
        const nonNuturalMatrix = state?.influenceList?.filter(it => it?.metric_type !== "NEUTRAL") 
        const allMetrics = [...nonNuturalMatrix, ...state?.outcomeList]
        return [
            {
                title: "Contacts",
                count: state?.contacts?.length || 0,
                description: state?.contacts?.map(it => it.name)
            },
            {
                title: "Shared Metrics",
                count: allMetrics.length || 0,
                description: allMetrics?.map(it => it.name)
            },
        ]
    }
    const nonNuturalMatrix = state?.influenceList?.filter(it => it?.metric_type !== "NEUTRAL")
    return [
        {
            title: "Contacts",
            count: state?.contacts?.length || 0,
            description: state?.contacts?.map(it => it.name)
        },
        {
            title: "Outcomes",
            count: outComes || 0,
            description: state?.outcomeList?.map(it => it.name)
        },
        {
            title: "Influences",
            count: nonNuturalMatrix.length || 0,
            description: nonNuturalMatrix?.map(it => it.name)
        }
    ]
}

export const Groups = [
    {
        group_name: 'some',
        contacts: [
            {
                name: 'name 1',
                email: 'email 1'
            }
        ]
    },

]