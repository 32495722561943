/*eslint array-callback-return: "off"*/
import React, { useState, useContext } from "react";
import { Box } from "@mui/system";
import clsx from "clsx";
import palette from "../../theme/palette";
import AddRefQuesModal from "./addRefQuesModal";
import { UserTypeContext } from "../../context/userStatus";
import SnackBarComp from "../../components/snackBar";
import moment from "moment";
import { Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  icon: {
    position: "relative",
    borderBottom: "1px solid #F3F3F3",
    padding: "14px 0",
    height: "65px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "wrap",
  },
  iconInner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexBasis: "100%",
    height: "35px",

    "&:after": {
      content: "''",
      position: "absolute",
      display: "flex",
      left: "28px",
      border: "1px dashed #E7E7E7",
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  iconInnerWithoutAfter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexBasis: "100%",
  },
  iconLast: {
    position: "relative",
    padding: "14px 0",
    height: "65px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexWrap: "wrap",

  },
  iconWithoutAfter: {
    position: "relative",
    borderBottom: "1px solid #F3F3F3",
    padding: "14px 0",
    height: "65px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    // flexDirection: "row-reverse"
  },
  absoluteBorders: {
    height: '66px',
    display: 'flex',
    padding: '32px 0',
    position: 'absolute',
    alignItems: 'center',
    borderBottom: '1px solid #F3F3F3',
    justifyContent: 'center',
    width: '30px',
    left: '24px',
  },
  absoluteExpand: {
    width: '172px',
  },
  iconExpand: {
    height: "auto",
    "&:after": {
      width: "100%",
    },
  },
  addOutlineIcon: {
    zIndex: 1,
    "& span": {
      color: "rgb(227, 227, 227)",
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "28px",
      fontFamily: "Poppins",
      display: "block",
      textAlign: "center",
      width: "28.5px",
      height: "28.5px",
      border: "1px solid rgb(227, 227, 227)",
      borderRadius: "50%",
      backgroundColor: palette.common.white,
    },
  },
  addOutlineIconInput: {
    color: "rgb(227, 227, 227)",
    border: "1px solid rgb(227, 227, 227)",
    borderRadius: "50%",
    backgroundColor: palette.common.white,
    textAlign: "center",
    width: "28.5px",
    height: "28.5px",
    "& input": {
      width: "18px",
      height: "19px",
      fontFamily: "Poppins",
    },
  },
  borderOutline: {
    width: "34px!important",
    height: "34px!important",
  },
  notesItem: {
    margin: "0px",
    padding: "0px",
    // marginLeft: '5px',
    listStyleType: "none",
    // paddingLeft: "0",
    // maxHeight: "50px",
    // overflowY: "auto",
    // maxWidth: "170px",
    listStylePosition: 'inside',
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "& li": {
      color: palette.primary.light,
      fontSize: "12px",
      lineHeight: "15px",
      position: 'relative',
      // display: 'flex',
      // alignItems: 'center',
      // justifyContent: 'flex-start',
      margin: '3px 0',
      "&:before": {
        content: "''",
        backgroundColor: palette.primary.main,
        width: "4px",
        height: "4px",
        display: "inline-block",
        marginRight: "5px",
        marginBottom: "2px",
        borderRadius: "50%",
        //     alignSelf: 'flex-start',
        // marginTop: '5px',
      },
      "&::::marker": {
        color: palette.primary.main
      }
    },
  },
}));

interface IconListQuesModal {
  setRefId: (refId: string) => void;
  refId: string;
  journeyData: any;
  headerDate: any;
  callservice: any;
  betweenDate: any;
  dayName: any;
  callAndUpdateObject: any
  month: moment.Moment[];

}

const IconListQues = (props: IconListQuesModal) => {
  const [openRefQuesModal, setOpenRefQuesModal] = useState(false);
  const classes = useStyles();
  const { state: outcomeState } = useContext(UserTypeContext);
  const [showSnakbar, setShowSnakbar] = useState(false);
  const [snakbarMsg, setSnakbarMsg] = useState("");
  const [msgType, setMsgType] = useState("");
  const [isEdit, setIsEdit] = useState(false);

  // tempory change for testing (day to year)
  const handleCloseSnakbar = () => {
    setShowSnakbar(false);
  };
  const clickCondition = moment(props.betweenDate).isBetween(
    moment().subtract(8, "year").format("yyyy-MM-DD"),
    // moment().subtract(8, "day").format("yyyy-MM-DD"),
    moment().add(1, "day").format("yyyy-MM-DD")
  );
  console.log(`moment(props.month[props.month.length - 1]).isSame(moment(props.headerDate))`, moment(props.month[props.month.length - 1]), props.headerDate, moment(props.month[props.month.length - 1]).isSame(moment(props.headerDate, "MM-DD-yyyy")))
  return (
    <>
      <SnackBarComp
        showSnakbar={showSnakbar}
        handleCloseSnakbar={handleCloseSnakbar}
        snakbarMsg={snakbarMsg}
        type={msgType ? msgType : "info"}
      />
      <Box marginBottom="56px" width={"100%"}>
        {outcomeState?.reflectiveList?.map((item: any, index: number) => {
          let noData = true;
          let iconObject: any = {}
          return (
            <div
              style={outcomeState?.isExpand ? { minHeight: "77px", } : {}}
              className={clsx(
                /* moment(props.month[props.month.length - 1]).format("MM-DD-yyyy") === props.headerDate ? classes.iconWithoutAfter : */(outcomeState?.reflectiveList.length - 1) === index ? classes.iconLast : classes.icon,
                outcomeState?.isExpand ? classes.iconExpand : ""
              )}
              key={item._id}
              
            >
              <div className={moment(props.month[props.month.length - 1]).format("MM-DD-yyyy") === props.headerDate ? classes.iconInnerWithoutAfter : classes.iconInner} style={{
                display: "flex",
                alignItems: "center",
                justifyContent: outcomeState?.isExpand ? "flex-start" : "center",
                flexBasis: "100%"
              }} onClick={() => {
                if (clickCondition) {
                  props.setRefId(item.reflectiveId);
                  setOpenRefQuesModal(true);
                }
              }}>
                <span
                  className={classes.addOutlineIcon}
                  onClick={() => {
                    clickCondition && setIsEdit(true);
                  }}
                >
                  {props?.journeyData?.map((journey: any, index: number) => {
                    if (
                      journey?.reflectiveId === item?.reflectiveId &&
                      journey?.date === props.headerDate
                    ) {
                      noData = false;
                      iconObject = journey
                      return (
                        <div key={index}>
                          <span
                            style={{
                              backgroundColor: palette.primary.main,
                              border: `1px solid ${palette.primary.main}`,
                            }}
                          >
                            <span
                              style={{
                                backgroundColor: palette.primary.main,
                                borderRadius: "50%",
                                border: `1px solid ${palette.primary.main}`,
                              }}
                            ></span>
                          </span>

                        </div>
                      );
                    }
                  })}
                  {noData &&
                    item?.weekDays?.some(
                      (day) =>
                        day?.day.toLowerCase() ===
                        moment(props.dayName).format("dddd").toLowerCase() && moment(props.dayName).isBefore(moment())
                    ) ? (
                    <span
                      key={index}
                      style={{
                        backgroundColor: "rgb(255, 152, 0)",
                        border: `1px solid rgb(255, 152, 0)`,
                      }}
                    >
                      <span
                        style={{
                          backgroundColor: "rgb(255, 152, 0)",
                          borderRadius: "50%",
                          border: `1px solid rgb(255, 152, 0)`,
                        }}
                      ></span>
                    </span>
                  ) : (
                    noData && <span key={index}>+</span>
                  )}
                </span>
              </div>
              {/* <div className={clsx(
                (((outcomeState?.reflectiveList.length -1) === index) || moment(props.month[props.month.length - 1]).format("MM-DD-yyyy") === props.headerDate ) ? null: classes.absoluteBorders,
                outcomeState?.isExpand ? classes.absoluteExpand : ""
              )}></div> */}
              {outcomeState?.isExpand ? (
                <div style={{
                  display: 'flex',
                  width: '176px',
                  height: "144px",
                  padding: '12px',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: '12px',
                  alignSelf: 'stretch',
                  borderRadius: '12px',
                  border: '1px solid #F3F3F3',
                  background: '#FFF',
                  flexBasis: "100%",
                  margin: "13px 8px 0px 0",
                  overflowY: "auto",
                  wordWrap: 'break-word',
                }} onClick={() => {
                  if (clickCondition) {
                    props.setRefId(item.reflectiveId);
                    setIsEdit(!noData)
                    setOpenRefQuesModal(true);
                  }
                }}>  {!noData && iconObject?.answers && iconObject?.answers[0] && <ul className={classes.notesItem}>
                  {iconObject?.answers?.map(
                      (anwerItem: any, index: number) => {
                        return <li key={index}><Typography variant="h5" sx={{ color: palette.common.black }} component="span" title={anwerItem}>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: anwerItem.replace(/\n/g, "<br />") || ""
                            }}
                          ></span>
                        </Typography></li>;
                      }
                    )}
                </ul>}</div>
              ) : null}
              {/* QUESTION MODAL */}
              {openRefQuesModal && item.reflectiveId === props.refId && (
                <AddRefQuesModal
                  setOpenRefQuesModal={setOpenRefQuesModal}
                  refId={props.refId}
                  setShowSnakbar={setShowSnakbar}
                  setSnakbarMsg={setSnakbarMsg}
                  setMsgType={setMsgType}
                  setRefId={props.setRefId}
                  callService={props?.callservice}
                  isEdit={isEdit}
                  setIsEdit={setIsEdit}
                  journeyData={props?.journeyData}
                  headerDate={props?.headerDate}
                  item={item}
                />
              )}
            </div>
          );
        })}
      </Box>
    </>
  );
};

export default IconListQues;
