import {
  DialogContent,
  Button,
  Dialog,
  DialogActions,
  Typography,
} from "@mui/material";
import React from "react";
import { makeStyles } from "@material-ui/core";
import NewButton from "../../components/NewButton";

interface IswitchInfluence {
  open: boolean;
  setopenSwitchInfluenceModal: any;
  setTransformToInfluenceTracking?: any;
  setAddOutcomeTracking?: any;
  setShowOutcome?: any;
  outcomeValueInput?: any;
}

const useStyles = makeStyles((theme) => ({
  categoryDialog: {
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        borderRadius: "20px",
        padding: "48px 40px",
        width: "682px",

        "& .MuiDialogContent-root": {
          padding: "0",
          overflowY: "unset",
        },

        "&. MuiDialogActions-root": {},
      },
    },
  },
  categoryDialogAction: {
    display: "flex !important",
    alignItems: "center !important",
    justifyContent: "end !important",
  },
}));
const SwitchInInfluenceModal = (props: IswitchInfluence) => {
  const classes = useStyles();

  return (
    <Dialog
      open={props.open}
      onClose={() => props?.setopenSwitchInfluenceModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.categoryDialog}
    >
      <DialogContent id="alert-dialog-description">
        <Typography variant="h3" component="h3" lineHeight="120% !important">
          Are you sure to change the scale type of {props?.outcomeValueInput}?
        </Typography>
        <Typography variant="subtitle1" component="div" sx={{ mt: "20px" }}>
          <span style={{ color: "red", marginRight: "10px", fontSize: "20px" }}>
            !
          </span>
          An automatic history version will securely log all current data,
          including tracking record, metric style, goal, and completion. You can
          restore it anytime.
        </Typography>
        <Typography variant="subtitle1" component="div" sx={{ mt: "20px" }}>
          <span style={{ color: "red", marginRight: "10px", fontSize: "20px" }}>
            !
          </span>
          You need to set a new goal.
        </Typography>
        <Typography variant="subtitle1" component="div" sx={{ mt: "20px" }}>
          <span style={{ color: "red", marginRight: "10px", fontSize: "20px" }}>
            !
          </span>
          All the impact scores regarding this metric will be recalculate.
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{ mt: "60px" }}
        className={classes.categoryDialogAction}
      >
        <NewButton
          buttonText="No, take me back"
          type="submit"
          onClick={() => props?.setopenSwitchInfluenceModal(false)}
          variant="contained"
        />
        <NewButton
          buttonText="Yes"
          onClick={() => {
            props?.setTransformToInfluenceTracking(true);
            props?.setopenSwitchInfluenceModal(false);
            props?.setShowOutcome(false);
          }}
          variant="outlined"
        />
      </DialogActions>
    </Dialog>
  );
};

export default SwitchInInfluenceModal;
