import React, { useEffect, useContext, useState } from 'react';
import {
  Grid,
  Divider,
  styled,
  Typography,
  AccordionDetails,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { makeStyles } from '@material-ui/core';
import Calendar from './calendar';
import ModeSwitchTab from './ModeSwitchTab';
import { AutoSizer, List } from 'react-virtualized';
import TrackingList from './TrackingList';
import { outcomeService } from '../../services/outcome';
import { UserTypeContext } from '../../context/userStatus';
import {
  InfluenceGetResponse,
  OutcomeGetList,
  OutcomeGetResponse,
  RefelectiveGetResponse,
  ReflectiveGetList,
} from '../../interfaces/outcomeModal';
import {
  addCategoryExpandAction,
  addInfluenceAction,
  addOutcomeAction,
  addRefelectiveAction,
} from '../../store/actions/userTypeAction';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import ArrowUp from '../../assets/images/icon/arrow_up';
import AddFilledIcon from '../../assets/images/icon/AddFilledIcon';
import ModalCloseIconBlack from '../../assets/images/icon/modalCloseIconBlack';
import LeftSideOutCome from '../outcome/leftOutcome';
import { influenceService } from '../../services/influence';
import TrackingListInfluence from './TrackingListInfluence';
import LeftSideInfluence from '../influence/leftSideInfluence';
import RightSideOutcomeTracking from './rightSideOutcomeTracking';
import palette from '../../theme/palette';
import RightSideInfluenceTracking from './rightSideInfluenceTracking';
import { reflectiveQueService } from '../../services/reflective-questions';
import TrackingListQues from './TrackingListQues';
import LeftSideReflectiveQue from '../reflective-questions/leftSideRefQue';
import RightSideRefQuesTracking from './rightSideRefQuesTracking';
import { OutcomeStatus } from '../../utils/staticDataJson';
import AddPlusIcon from '../../assets/images/icon/AddPlusIcon';
import ExpandIcon from '../../assets/images/icon/ExpandIcon';
import LimitReachedPopup from './limitReachedModal';

const useStyles = makeStyles((theme) => ({
  trackBody: {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'clip',
    overflowY: 'clip',
    background: '#fff',

    //height: "calc(100vh - 185px)",
  },
  trackBodyLeft: {
    padding: '0',
    position: 'relative',
    boxShadow: '5px 0px 40px 0px rgba(18, 18, 19, 0.05)',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 10px 10px 10px',
    },
  },
  trackItem: {
    '&:not(:last-child)': {
      marginBottom: '40px',
    },
    minWidth: "30vw",
    maxWidth: '35vw'
  },
  listTitle: {
    fontSize: '22px!important',
    lineHeight: '22px!important',
  },
  modalCloseIcon: {
    position: 'absolute',
    right: 0,
    top: 0,
    cursor: 'pointer',
    zIndex: 1,
  },
}));

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  backgroundColor: 'transparent',
  '& .MuiAccordionSummary-root': {
    minHeight: 0,
    padding: 0
  },
  '&:before': {
    display: 'none',
  },
  '& .MuiAccordionSummary-expandIconWrapper': {
    transform: 'rotate(180deg)',
  },
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0deg)',
  },
}));

interface TrackBodyModal {
  createDate: any;
  setCreateDate: (createDate: any) => void;
  handleClickOpen: () => void;
  showInfluence: any;
  showOutcome: any;
  setShowOutcome: any;
  setShowInfluence: any;
  showQues: any;
  setShowQues: any;
  openStartModal: any;
  setStartModal: any;
  openStartModalInfluence: any;
  setStartModalInfluence: any;
  openStartModalQues: any;
  setStartModalQues: any;
  addOutcomeTracking: any;
  setAddOutcomeTracking: any;
  addInfluenceTracking: any;
  setAddInfluenceTracking: any;
  addQuesTracking: any;
  setAddQuesTracking: any;
  setOutcomeIdeas: any;
  setInfluenceIdeas: any;
  setReflectiveIdeas: any;
  setCategoryId: any;
  setTransformToInfluenceTracking?: any;
  transformToInfluenceTracking?: any;
  setTransformToOutcomeTracking?: any;
  transformToOutcomeTracking?: any;
  setTransformInInfluenceTracking?: any;
  transformInInfluenceTracking?: any;
  showLoader?: any;
  setShowLoader?: any;
  startDate: any
  setStartDate: any
  endDate: any
  setEndDate: any
  handleShareModalOpen: any
}

const TrackBody = (props: TrackBodyModal) => {
  const classes = useStyles();
  const { state: outcomeState, dispatch: userTypeDispatch } =
    useContext(UserTypeContext);
  const [expanded, setExpanded] = useState<boolean>(true);
  const [limitPopupVisible, setLimitReachPopupVisible] = useState<boolean>(false);
  const [limitPopuptype, setLimitReachPopuptype] = useState<'outcome' | 'influence' | 'question'>();
  const [expandedInfluence, setExpandedInfluence] = useState<boolean>(true);
  const [expandedQues, setExpandedQues] = useState<boolean>(true);

  useEffect(() => {
    // GET OUTCOME LIST
    getOutcomeList();

    // GET OUTCOME SUGGESTION LIST
    getOutcomeSuggestion();

    // GET INFLUENCE LIST
    getInfluenceList();

    // GET INFLUENCE SUGGESTION LIST
    getInfluenceSuggestion();

    // GET REFLECTIVE QUESTIONS LIST
    getReflectiveList();

    // GET REFLECTIVE SUGGESTION LIST
    getReflectiveSuggestionList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getOutcomeList = () => {
    outcomeService
      .getOutcomeList(outcomeState.token)
      .then((data: OutcomeGetResponse) => {
        userTypeDispatch(addOutcomeAction(data?.data));
      })
      .catch((error) => {
        console.log('Outcome Error');
      });
  };
  const getOutcomeSuggestion = () => {
    outcomeService
      .getOutcomeSuggestion(outcomeState.token)
      .then((res: any) => {
        props.setOutcomeIdeas(res?.data);
      })
      .catch((error: any) => {
        console.log('Outcome suggestion error', error);
      });
  };
  const getInfluenceList = () => {
    influenceService
      .getInfluenceList(outcomeState.token)
      .then((data: InfluenceGetResponse) => {
        userTypeDispatch(addInfluenceAction(data?.data));
      })
      .catch((error) => {
        console.log('influence Error');
      });
  };
  const getInfluenceSuggestion = () => {
    influenceService
      .getInfluenceSuggestion(outcomeState.token)
      .then((res: any) => {
        props.setInfluenceIdeas(res?.data);
      })
      .catch((error: any) => {
        console.log('influence suggestion error', error);
      });
  };
  const getReflectiveList = () => {
    reflectiveQueService
      .getReflectiveList(outcomeState.token)
      .then((data: RefelectiveGetResponse) => {
        userTypeDispatch(addRefelectiveAction(data?.data));
      })
      .catch((error) => {
        console.log('Ques error');
      });
  };
  const getReflectiveSuggestionList = () => {
    reflectiveQueService
      .getRefelectiveSuggestion(outcomeState.token)
      .then((res: any) => {
        props.setReflectiveIdeas(res?.data);
      })
      .catch((error: any) => {
        console.log('Outcome suggestion error', error);
      });
  };

  // ACCORDION EXPAND
  const handleChange = (expanded: boolean) => () => {
    setExpanded(!expanded);
  };
  const handleChangeInfluence = (expandedInfluence: boolean) => () => {
    setExpandedInfluence(!expandedInfluence);
  };
  const handleChangeQues = (expandedQues: boolean) => () => {
    setExpandedQues(!expandedQues);
  };

  // CLOSE ADD MODAL

  const {
    setShowOutcome,
    setStartModal,
    setAddOutcomeTracking,
    setShowInfluence,
    setStartModalInfluence,
    setAddInfluenceTracking,
    setShowQues,
    setStartModalQues,
    setAddQuesTracking,
    setCategoryId,
  } = props;

  // CUSTOME ACCORDION SUMMARY
  const AccordionSummary = (props: AccordionSummaryProps) => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: "16px 24px",
          gap: '8px',
          flex: '1 0 0',
          background: "#FFF",
          backgroundColor: "#FFF"
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: "8px"
          }}
        >
          <MuiAccordionSummary expandIcon={<ExpandIcon />} {...props} />

          <Typography
            variant="h3"
            fontWeight={600}
            fontSize={'1.125rem !important'}
            className={classes.listTitle}
            color={palette.common.black}
          >
            Outcomes
          </Typography>
        </div>
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => {
            if(outcomeState?.outcomeList && outcomeState?.outcomeList?.length >= 5) {
              setLimitReachPopupVisible(true)
              setLimitReachPopuptype('outcome')
            } else {
              setShowOutcome(true);
              setStartModal(true);
              setAddOutcomeTracking(true);
              setCategoryId('');
            }

          }}
        >
          <AddPlusIcon />
        </span>
      </div>
    );
  };
  const AccordionSummaryInfluence = (props: AccordionSummaryProps) => {
    const classes = useStyles();
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: "16px 24px",
          gap: '8px',
          flex: '1 0 0',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: "8px"
          }}
        >
          <MuiAccordionSummary expandIcon={<ExpandIcon />} {...props} />

          <Typography
            variant="h3"
            fontWeight={600}
            fontSize={'1.125rem !important'}
            className={classes.listTitle}
            color={palette.common.black}
          >
            Influences
          </Typography>
        </div>
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => {
            if(outcomeState?.influenceList && outcomeState?.influenceList?.length >= 10) {
              setLimitReachPopupVisible(true)
              setLimitReachPopuptype('influence')
            } else {
              setShowInfluence(true);
              setStartModalInfluence(true);
              setAddInfluenceTracking(true);
              setCategoryId('');
            }
          }}
        >
          <AddPlusIcon />
        </span>
      </div>
    );
  };
  const AccordionSummaryQues = (props: AccordionSummaryProps) => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: "16px 24px",
          gap: '8px',
          flex: '1 0 0',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: "8px"
          }}
        >
          <MuiAccordionSummary expandIcon={<ExpandIcon />} {...props} />

          <Typography
            variant="h3"
            fontWeight={600}
            fontSize={'1.125rem !important'}
            className={classes.listTitle}
            color={palette.common.black}
          >
            Qualitative Reflection
          </Typography>
        </div>
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => {
            if(outcomeState?.reflectiveList && outcomeState?.reflectiveList?.length >= 3) {
              setLimitReachPopupVisible(true)
              setLimitReachPopuptype('question')
            } else {
              setShowQues(true);
              setStartModalQues(true);
              setAddQuesTracking(true);
              setCategoryId('');
            }
          }}
        >
          <AddPlusIcon />
        </span>
      </div>
    );
  };
  const ITEMS_COUNT = 98
  const ITEM_SIZE = 100
  const outcomeExpand = outcomeState?.journeyCategoryExpandList && outcomeState?.journeyCategoryExpandList.length && outcomeState?.journeyCategoryExpandList.find(it => it === "outcome")
  const influenceExpand = outcomeState?.journeyCategoryExpandList && outcomeState?.journeyCategoryExpandList.length && outcomeState?.journeyCategoryExpandList.find(it => it === "influence")
  const reflectiveExpand = outcomeState?.journeyCategoryExpandList && outcomeState?.journeyCategoryExpandList.length && outcomeState?.journeyCategoryExpandList.find(it => it === "reflective")

  const handleChangeCommonExpand = (expanded: boolean, categoryId: string) => () => {
    if (!expanded) {
      const newArr = [...outcomeState?.journeyCategoryExpandList, categoryId]
      userTypeDispatch(addCategoryExpandAction(newArr));
    } else {
      const newArr = [...outcomeState?.journeyCategoryExpandList].filter(it => it !== categoryId)
      userTypeDispatch(addCategoryExpandAction(newArr));
    }
    // const index = arr.findIndex((obj) => obj.id === categoryId);
    // setLastActionId(categoryId)
    // arr[index].expanded = !expanded;
    // arr[index].manually = true;
    // setExpandObj(arr);
    // setExpandObj2(arr);
    // setExpanded(arr, categoryId)

  };
  return (
    <>
      <div className={`${classes.trackBody} commonTrackBody`} >
        {/* <AutoSizer>
          {({ height, width }) => {
            const itemsPerRow = Math.floor(width / ITEM_SIZE);
            const rowCount = Math.ceil(ITEMS_COUNT / itemsPerRow);
            return <List
            width={width}
            height={height}
            // rowCount={rowCount}
            rowHeight={ITEM_SIZE}
              // rowHeight={10}
              rowCount={(outcomeState?.outcomeList?.length || 0) + (outcomeState?.influenceList?.length || 0) + (outcomeState?.reflectiveList?.length || 0)}
              rowRenderer={() => <> */}
        <Grid item className={`${classes.trackBodyLeft} commonTrackBodyLeft`}>
          {/* TAB VIEW */}
          <div style={{
            position: 'sticky',
            top: 0,
            alignSelf: 'flex-start',
            // zIndex: 100,
            background: '#fff',
            zIndex: 1,
           
            // height: '100px',
          }}>

            <ModeSwitchTab
              handleClickOpen={props.handleClickOpen}
              handleShareModalOpen={props.handleShareModalOpen}
            />
          </div>
          <div className={classes.trackItem}>
            {/* OUTCOME LIST */}
            <Accordion
              sx={{ background: "#FFF", backgroundColor: "#FFF"}}
              elevation={0}
              expanded={outcomeExpand ?  true : [...outcomeState?.journeyCategoryExpandList].includes("outcome")}
              // onChange={handleChange(expanded)}
              onChange={handleChangeCommonExpand([...outcomeState?.journeyCategoryExpandList].includes("outcome"), "outcome")}
            >
              <AccordionSummary />

              <AccordionDetails sx={{ padding: '0px' }}>
                {outcomeState?.outcomeList &&
                  outcomeState?.outcomeList?.length > 0 &&
                  outcomeState?.outcomeList?.map(
                    (outcomeItem: OutcomeGetList, index: number) => (
                      <TrackingList
                        outcome={outcomeItem}
                        key={index}
                        index={index}
                        outcomeList={outcomeState?.outcomeList}
                        setShowOutcome={props.setShowOutcome}
                        showOutcome={props.showOutcome}
                        openStartModal={props.openStartModal}
                        setStartModal={props.setStartModal}
                        getOutcomeList={getOutcomeList}
                        setTransformToInfluenceTracking={props?.setTransformToInfluenceTracking}
                        transformToInfluenceTracking={props?.transformToInfluenceTracking}
                        startDate={props.startDate}
                        setStartDate={props.setStartDate}
                        endDate={props.endDate}
                        setEndDate={props.setEndDate}
                        isExpand={outcomeState?.isExpand}
                        setLimitReachPopupVisible={setLimitReachPopupVisible}
                        setLimitReachPopuptype={setLimitReachPopuptype}
                      />
                    )
                  )}
              </AccordionDetails>
            </Accordion>

            {/* INFLUENCE LIST */}
            <Accordion
              sx={{ background: "#FFF", backgroundColor: "#FFF"}}
              elevation={0}
              expanded={influenceExpand ? true : [...outcomeState?.journeyCategoryExpandList].includes("influence")}
              // onChange={handleChangeInfluence(expandedInfluence)}
              onChange={handleChangeCommonExpand([...outcomeState?.journeyCategoryExpandList].includes("influence"), "influence")}
            >
              <AccordionSummaryInfluence />

              <AccordionDetails sx={{ padding: '0px' }}>
                {outcomeState?.influenceList &&
                  outcomeState?.influenceList?.length > 0 &&
                  outcomeState?.influenceList?.map(
                    (influenceItem: any, index: number) => (
                      <TrackingListInfluence
                        influenceItem={influenceItem}
                        key={index}
                        index={index}
                        influenceList={outcomeState?.influenceList}
                        setShowInfluence={props.setShowInfluence}
                        showInfluence={props.showInfluence}
                        openStartModalInfluence={props.openStartModalInfluence}
                        setStartModalInfluence={props.setStartModalInfluence}
                        getInfluenceList={getInfluenceList}
                        setTransformToOutcomeTracking={props?.setTransformToOutcomeTracking}
                        transformToOutcomeTracking={props?.transformToOutcomeTracking}
                        transformInInfluenceTracking={props?.transformInInfluenceTracking}
                        setTransformInInfluenceTracking={props?.setTransformInInfluenceTracking}
                        startDate={props.startDate}
                        setStartDate={props.setStartDate}
                        endDate={props.endDate}
                        setEndDate={props.setEndDate}
                        isExpand={outcomeState?.isExpand}
                        setLimitReachPopupVisible={setLimitReachPopupVisible}
                        setLimitReachPopuptype={setLimitReachPopuptype}
                      />
                    )
                  )}
              </AccordionDetails>
            </Accordion>

            {/* REFLECTIVE QUESTIONS LIST */}

            <Accordion
              sx={{ background: "#FFF", backgroundColor: "#FFF"}}
              elevation={0}
              expanded={reflectiveExpand ? true : [...outcomeState?.journeyCategoryExpandList].includes("reflective")}
              // onChange={handleChangeQues(expandedQues)}
              onChange={handleChangeCommonExpand([...outcomeState?.journeyCategoryExpandList].includes("reflective"), "reflective")}

            >
              <AccordionSummaryQues />

              <AccordionDetails sx={{ padding: '0px' }}>
                {outcomeState?.reflectiveList &&
                  outcomeState?.reflectiveList?.length > 0 &&
                  outcomeState?.reflectiveList?.map(
                    (refelectiveItem: ReflectiveGetList, index: number) => (
                      <TrackingListQues
                        refelectiveItem={refelectiveItem}
                        key={index}
                        index={index}
                        reflectiveList={outcomeState?.reflectiveList}
                        setShowQues={props.setShowQues}
                        showQues={props.showQues}
                        openStartModalQues={props.openStartModalQues}
                        setStartModalQues={props.setStartModalQues}
                        getReflectiveList={getReflectiveList}
                        startDate={props.startDate}
                        setStartDate={props.setStartDate}
                        endDate={props.endDate}
                        setEndDate={props.setEndDate}
                        isExpand={outcomeState?.isExpand}
                        setLimitReachPopupVisible={setLimitReachPopupVisible}
                        setLimitReachPopuptype={setLimitReachPopuptype}
                      />
                    )
                  )}
              </AccordionDetails>
            </Accordion>
          </div>
        </Grid>
        {/* <Divider orientation="vertical" flexItem /> */}
        {/* CALENDER VIEW */}
        <Grid
          item
          xs={outcomeState?.isExpand ? 15.9 : 10.3}
          sx={{ paddingLeft: '0', background: '#FFF' }}
        >
          <Calendar
            createDate={props?.createDate}
            setCreateDate={props?.setCreateDate}
            showLoader={props?.showLoader}
            setShowLoader={props?.setShowLoader}
            startDate={props.startDate}
            setStartDate={props.setStartDate}
            endDate={props.endDate}
            setEndDate={props.setEndDate}
          />
        </Grid>
        {/* </>
              }
            >

            </List>
          }}
        </AutoSizer> */}
        <LimitReachedPopup open={limitPopupVisible} setopenOutcomeclosePopup={setLimitReachPopupVisible} type={limitPopuptype}/>
      </div>
    </>
  );
};

export default TrackBody;
