import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import SnackBarComp from "../../components/snackBar";
import * as yup from "yup";
import React, { useContext, useEffect, useState } from "react";
import palette from "../../theme/palette";
import InputGroup, { Input } from "../../components/inputGroup";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import { color } from "highcharts";
import { DropzoneArea } from "material-ui-dropzone";
import { useFormik } from "formik";
import { trackingService } from "../../services/tracking";
import { UserTypeContext } from "../../context/userStatus";
import { pxToRem } from "../../theme/typography";
import { YoutubeComponent } from "./YoutubeVideo";
import {
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  updatePassword,
} from "firebase/auth";
import { ButtonLoader } from "../../components/buttonLoader";
import {
  addCategoryExpandAction,
  addCategoryJourneyAction,
  addInfluenceAction,
  addJourneyAction,
  addNotesAction,
  addNotesJourneyAction,
  addOutcomeAction,
  addRefelectiveAction,
  isPersonalMode,
} from "../../store/actions/userTypeAction";
import { makeStyles } from "@material-ui/core";
import NewButton from "../../components/NewButton";

const useStyles = makeStyles((theme) => ({
  titles: {
    color: `${palette.primary.light}!important`,
  },
  TextAreaInput: {
    // border: "2px solid #E3E3E3 !important ",
    width: "100%",
    // borderRadius: "12px !important",
    // background: '#FFFFFF',
    // border: '1px solid #EDEDED !important',
    "& .MuiOutlinedInput-root": {
      width: "100%",
      borderRadius: "12px !important",
      background: "#FFFFFF",
      border: "1px solid #EDEDED !important",
      height: "auto !important",
      "& .MuiOutlinedInput-input": {
        height: "auto !important",
        padding: "0px 0 !important",
      },
    },
  },
  TextAreaInputfirst: {
    // border: "2px solid #E3E3E3 !important ",
    // // borderBottom: "2px solid #E3E3E3 !important ",
    // height: "56px !important ",
    // borderRadius: "10px !important",
    // width: "37vw",
    // paddingLeft: "10px",
    // color: "#E3E3E3",
    // background: '#FFFFFF',
    // border: '1px solid #EDEDED !important',
    // borderRadius: '12px',
    width: "100%",
    "& .MuiOutlinedInput-root": {
      background: "#FFFFFF",
      border: "1px solid #EDEDED !important",
      borderRadius: "12px",
      width: "100%",
    },
  },
  dropzone: {
    width: "37vw",
    marginTop: "10px",
    border: `2px dashed  #E3E3E3 `,
    borderRadius: "10px !important",
    padding: theme.spacing(2),
    textAlign: "center",
    cursor: "pointer",
    color: "black",
    "&:hover": {
      backgroundColor: theme.palette.grey[200],
    },
  },
  hideDropzone: {
    display: "none",
  },
  dataDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "24px",
    gap: "24px",
    width: "100%",
  },
  tabGroup: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "4px",
    isolation: "isolate",

    height: "48px",
    margin: "0 0 25px 0",
    background: "#F6F6F6",
    borderRadius: "12px",
    width: "100%",
    overflowX: "auto",
    overflowY: "hidden",
  },
  tab: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "12px 16px",
    height: "40px",
    cursor: "pointer",
    background: "#F6F6F6",
    whiteSpace: "nowrap",
    // borderRadius: '8px',
  },
  activeTab: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "12px 16px",

    height: "40px",
    cursor: "pointer",

    background: "#FFFFFF",
    boxShadow: "0px 0px 72px rgba(25, 13, 26, 0.12)",
    borderRadius: "8px",
    whiteSpace: "nowrap",
  },
  placeholder: {
    color: "#aaa",
  },
  forgetPasswordText: {
    color: "#9BA19D",
    textAlign: "right",
    fontSize: pxToRem(14),
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "145%" /* 20.3px */,
    letterSpacing: "-0.14px",
    cursor: "pointer",
  },
  tutorialButton: {
    display: "flex !important",
    padding: "12px !important",
    justifyContent: "center !important",
    alignItems: "center !important",
    borderRadius: "8px !important",
    background: "#11734A",
  },
  // FileInput: {
  //   height: "156px !important ",
  //   width: "38.2vw",
  //   border: "1px solid black",
  //   display: "flex!important",
  //   justifyContent: "center",
  //   alignItems: "center"
  // },
}));
const ValidateEmail = (input) => {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/.test(input)) {
    return true;
  } else {
    return false;
  }
};
function AccountSettings(props) {
  const [Message, setMessage] = useState("Give feedback");
  const [currentTab, setCurrentTab] = useState("Request a feature");

  const handleChangeMessage = (e: any) => {
    setMessage(e.target.value);
  };
  const { state: outcomeState, dispatch: userTypeDispatch } =
    useContext(UserTypeContext);
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  const requestAFeature = (values) => {
    trackingService
      .requestafeature(outcomeState.token, {
        ...values,
        uid: localStorage.getItem("userDetails"),
      })
      .then((data) => {
        props.setMsgType("success");
        props.setShowSnakbar(true);
        props.setSnakbarMsg(data.description);
        setRequestFeatureState({
          featureType: "",
          description: "",
          user_email: "",
        });
        setRequestFeatureErrorState({
          featureType: "",
          description: "",
          user_email: "",
        });
      });
  };
  const reportabug = (values) => {
    const formData = new FormData();
    formData.append("photo", files[0]);
    trackingService
      .reportabug(outcomeState.token, formData, {
        ...values,
        uid: localStorage.getItem("userDetails"),
      })
      .then((data) => {
        props.setMsgType("success");
        props.setShowSnakbar(true);
        props.setSnakbarMsg(data.description);
        setReportaBugState({
          bugType: "",
          description: "",
          user_email: "",
        });
        setFiles([]);
        setReportaBugErrorState({
          bugType: "",
          description: "",
          user_email: "",
        });
        setFiles([]);
      });
  };

  const giveFeedback = (values) => {
    trackingService
      .giveFeedback(outcomeState.token, {
        ...values,
        uid: localStorage.getItem("userDetails"),
      })
      .then((data) => {
        props.setMsgType("success");
        props.setShowSnakbar(true);
        props.setSnakbarMsg(data.description);
        setFeedbackState({
          theme: "",
          description: "",
          user_email: "",
        });
        setFeedbackErrorState({
          theme: "",
          description: "",
          user_email: "",
        });
      });
  };
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [selectedFAQTab, setSelectedFAQTab] = useState("");
  const [requestFeatureState, setRequestFeatureState] = useState({
    featureType: "",
    description: "",
    user_email: "",
  });
  const [requestFeatureErrorState, setRequestFeatureErrorState] = useState({
    featureType: "",
    description: "",
    user_email: "",
  });
  const [reportaBugState, setReportaBugState] = useState({
    bugType: "",
    description: "",
    user_email: "",
  });
  const [reportaBugErrorState, setReportaBugErrorState] = useState({
    bugType: "",
    description: "",
    user_email: "",
  });
  const [feedbackState, setFeedbackState] = useState({
    theme: "",
    description: "",
    user_email: "",
  });
  const [feedbackErrorState, setFeedbackErrorState] = useState({
    theme: "",
    description: "",
    user_email: "",
  });
  const [showYoutubeModal, setShowYoutubeModal] = useState(false);
  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
  };
  const Placeholder = ({ children }) => {
    return <div className={classes.placeholder}>{children}</div>;
  };

  useEffect(() => {
    if (Object.keys(props?.FaqData) && Object.keys(props?.FaqData).length) {
      setSelectedFAQTab(Object.keys(props?.FaqData)[0]);
    }
  }, [props?.FaqData]);

  const handleDrop = (acceptedFiles) => {
    setFiles([...files, ...acceptedFiles]);
  };

  const TABS = [
    {
      value: "Request a feature",
      label: "Request a feature",

      // component: <AppSettings />,
    },
    {
      value: "Report a bug",
      label: "Report a bug",
      // component: <AccountSettings />,
    },
    {
      value: "Give feedback",
      label: "Give feedback",
      // component: <AccountSettings />,
    },
  ];

  const [selectedOption, setSelectedOption] = useState("");
  const [selectedBugOption, setSelectedBugOption] = useState("");
  const [passwordData, setPasswordData] = useState({
    Password: "",
    NewPassword: "",
  });
  const [errorMessage, setErrorMessage] = useState({
    Password: "",
    NewPassword: "",
  });
  const [loading, setLoading] = useState(false);
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setRequestFeatureState({
      ...requestFeatureState,
      featureType: event.target.value,
    });
  };
  const handleOptionBugChange = (event) => {
    setSelectedBugOption(event.target.value);
    setReportaBugState({
      ...reportaBugState,
      bugType: event.target.value,
    });
  };

  const handleSubmitData = async () => {
    setLoading(true);
    const auth = getAuth();
    const user = auth.currentUser;
    const credential = EmailAuthProvider.credential(
      user.email,
      passwordData.Password
    );
    await reauthenticateWithCredential(auth.currentUser, credential)
      .then(() => {
        updatePassword(user, passwordData.NewPassword)
          .then((res) => {
            setLoading(false);
            // Update successful.
            setshowSnakbar(true);
            setsnakbarMsg("Password changed successfully");
            setmsgType("success");
            setPasswordData({
              Password: "",
              NewPassword: "",
            });
            setErrorMessage({
              Password: "",
              NewPassword: "",
            });
          })
          .catch((error) => {
            setLoading(false);
            console.log("error = ", error.message);
            // An error ocurred
            // ...
          });
      })
      .catch((err) => {
        console.log("err", err, err.message);
        setLoading(false);
        if (err.message.includes("auth/wrong-password")) {
          setshowSnakbar(true);
          setsnakbarMsg("Current password did not match");
          setmsgType("error");
        }
        if (err.message.includes("temporarily disabled")) {
          setshowSnakbar(true);
          setsnakbarMsg(
            "Your account has been temporary disabled due to too much incorrect password. Please try after sometime"
          );
          setmsgType("error");
        }
      });
  };
  const [showSnakbar, setshowSnakbar] = useState(false);
  const [snakbarMsg, setsnakbarMsg] = useState("Success");
  const [msgType, setmsgType] = useState("error");
  const handleCloseSnakbar = () => {
    setshowSnakbar(false);
  };
  function isValidPassword(password) {
    if (password.length < 8) {
      return "Password must be at least 8 characters";
    }
  }
  return (
    <>
      <SnackBarComp
        showSnakbar={showSnakbar}
        handleCloseSnakbar={handleCloseSnakbar}
        snakbarMsg={snakbarMsg}
        type={msgType ? msgType : "success"}
      />
      {props?.selectedTab === "password" && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "24px",
            gap: "24px",
            width: "100%",
          }}
        >
          <Grid container>
            <Grid item xs={12} md={12}>
              <Box>
                <Input
                  type="password"
                  name="password"
                  value={passwordData.Password}
                  label="Current password"
                  placeholder="Current Password"
                  onChange={(e) => {
                    setPasswordData({ ...passwordData, Password: e });
                  }}
                  validationFunction={isValidPassword}
                  errorMessage={errorMessage.Password}
                  setErrorMessage={(e) => {
                    console.log("e", e);
                    setErrorMessage({ ...errorMessage, Password: e });
                  }}
                />
              </Box>
              {
                <Box
                  display="flex"
                  alignItems="flex-start"
                  gap="12px"
                  alignSelf="stretch"
                >
                  <Box
                    display="flex"
                    justifyContent={"flex-end"}
                    alignItems="center"
                    gap="4px"
                    flex={"1 0 0"}
                  >
                    <p
                      className={classes.forgetPasswordText}
                      onClick={() => {
                        localStorage.removeItem("userDetails");
                        localStorage.removeItem("userValues");
                        localStorage.removeItem("userId");
                        localStorage.removeItem("userDate");
                        localStorage.removeItem("categoryCount");
                        userTypeDispatch(addCategoryJourneyAction([]));
                        userTypeDispatch(addNotesJourneyAction([]));
                        userTypeDispatch(addOutcomeAction([]));
                        userTypeDispatch(addInfluenceAction([]));
                        userTypeDispatch(addRefelectiveAction([]));
                        userTypeDispatch(addJourneyAction([] as any));
                        userTypeDispatch(addNotesAction([] as any));
                        userTypeDispatch(addCategoryExpandAction([] as any));
                        userTypeDispatch(isPersonalMode(false));
                        navigate("/forget-password");
                      }}
                    >
                      Forgot password?
                    </p>
                  </Box>
                </Box>
              }
              <Box sx={{ my: 3 }}>
                <Input
                  type="password"
                  name="password"
                  label="New password"
                  placeholder="New Password"
                  value={passwordData.NewPassword}
                  onChange={(e) => {
                    setPasswordData({ ...passwordData, NewPassword: e });
                  }}
                  validationFunction={isValidPassword}
                  errorMessage={errorMessage.NewPassword}
                  setErrorMessage={(e) => {
                    setErrorMessage({ ...errorMessage, NewPassword: e });
                  }}
                />
              </Box>
              {/* <Box sx={{ my: 3 }}>
                <InputGroup
                  label="Confirm new password"
                  type="password"
                  name="password"
                />
              </Box> */}
              <NewButton
                buttonText={"Save"}
                variant="contained"
                onClick={handleSubmitData}
                isLoadong={loading}
                isDisabled={
                  passwordData.Password.length < 8 ||
                  passwordData.NewPassword.length < 8
                }
              />
            </Grid>
            <Grid item xs={12} md={6}></Grid>
          </Grid>
        </div>
      )}
      <div className={classes.dataDiv}>
        <Box sx={{ width: "100%" }}>
          {/* <Typography
          className={classes.titles}
          variant="h4"
          sx={{ fontWeight: 500 }}
        >
          Help center
        </Typography> */}
          <Grid container>
            {props?.selectedTab === "faq" && (
              <Grid item xs={12} md={12}>
                {Object.keys(props?.FaqData).length ? (
                  <div className={classes.tabGroup}>
                    {Object.keys(props?.FaqData).map((tab) => {
                      return (
                        props?.FaqData[tab][0] && (
                          <div
                            className={
                              selectedFAQTab === tab
                                ? classes.activeTab
                                : classes.tab
                            }
                            onClick={() => setSelectedFAQTab(tab)}
                          >
                            <Typography
                              variant="h4"
                              sx={{
                                fontWeight: 400,
                                fontSize: "16px !important",
                                color:
                                  selectedFAQTab === tab
                                    ? "var(--color-new-main)"
                                    : "#000000",
                              }}
                            >
                              {props?.FaqData[tab][0].title}
                            </Typography>
                          </div>
                        )
                      );
                    })}
                  </div>
                ) : null}
                {Object.keys(props?.FaqData).length ? (
                  <div>
                    {Object.keys(props?.FaqData).map((tab) => {
                      return (
                        selectedFAQTab === tab && (
                          <Typography
                            variant="h4"
                            sx={{
                              fontWeight: 500,
                              fontSize: "16px !important",
                              color: "#000000",
                              whiteSpace: "pre-line",
                            }}
                          >
                            {props?.FaqData[tab][0].description}
                          </Typography>
                        )
                      );
                    })}
                  </div>
                ) : null}

                <Box sx={{ mt: 2 }}>
                  {selectedFAQTab &&
                    props?.FaqData[selectedFAQTab] &&
                    props?.FaqData[selectedFAQTab].length &&
                    props?.FaqData[selectedFAQTab]?.map((faq) => {
                      return (
                        <Accordion
                          expanded={expanded === faq?.question}
                          onChange={handleChange(faq?.question)}
                          sx={{
                            "&.MuiAccordion-rounded": {
                              marginBottom: "16px",
                              background: "#FFFFFF",
                              border: "1px solid #EDEDED",
                              borderRadius: "12px",
                              padding: "14px 0 14px 14px",
                            },
                            "&.Mui-expanded": {
                              background: "#FFFFFF",
                              border: "1px solid #EDEDED",
                              borderRadius: "12px",
                              padding: "14px 0 14px 14px",
                            },
                          }}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                          >
                            <Typography
                              variant="subtitle1"
                              sx={{
                                fontWeight: 600,
                                color: "#000000",
                                fontSize: "20px",
                              }}
                            >
                              {faq?.question}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography
                              variant="subtitle1"
                              sx={{
                                fontWeight: 400,
                                color: "#000000",
                                fontSize: "16px !important",
                                whiteSpace: "pre-line",
                              }}
                            >
                              {faq?.answer}
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}
                </Box>
              </Grid>
            )}
            {props?.selectedTab === "feedback" && (
              <Grid item xs={12} md={12}>
                <div
                  className={classes.tabGroup}
                  style={{ width: "max-content" }}
                >
                  {TABS.map((tab) => (
                    <div
                      className={
                        currentTab === tab.value
                          ? classes.activeTab
                          : classes.tab
                      }
                      onClick={() => setCurrentTab(tab.value)}
                    >
                      <Typography
                        variant="h4"
                        sx={{
                          fontWeight: 400,
                          fontSize: "16px !important",
                          color:
                            currentTab === tab.value
                              ? "var(--color-new-main)"
                              : "#000000",
                        }}
                      >
                        {tab.label}
                      </Typography>
                    </div>
                  ))}
                </div>

                {currentTab === "Give feedback" && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      width: "100%",
                    }}
                  >
                    <TextField
                      className={classes.TextAreaInputfirst}
                      placeholder="Describe theme"
                      value={feedbackState.theme}
                      onChange={(e) => {
                        setFeedbackState({
                          ...feedbackState,
                          theme: e.target.value,
                        });
                        if (e.target.value) {
                          setFeedbackErrorState({
                            ...feedbackErrorState,
                            theme: "",
                          });
                        } else {
                          setFeedbackErrorState({
                            ...feedbackErrorState,
                            theme: "Theme is required",
                          });
                        }
                      }}
                      error={Boolean(feedbackErrorState.theme)}
                      helperText={feedbackErrorState.theme}
                      // variant="outlined"
                      // fullWidth
                      // sx={{ mt: 0 }}
                      // rows={5}
                    />

                    <TextField
                      className={classes.TextAreaInput}
                      // placeholder="Describe feature functionality"
                      // variant="outlined"
                      // fullWidth
                      // multiline
                      // sx={{ mt: 2 }}
                      // rows={7}
                      variant="outlined"
                      // className="textarea"
                      fullWidth
                      placeholder="Add a note..."
                      multiline
                      // sx={{ mt: 2 }}
                      rows={7}
                      value={feedbackState.description}
                      onChange={(e) => {
                        setFeedbackState({
                          ...feedbackState,
                          description: e.target.value,
                        });
                        if (e.target.value) {
                          setFeedbackErrorState({
                            ...feedbackErrorState,
                            description: "",
                          });
                        } else {
                          setFeedbackErrorState({
                            ...feedbackErrorState,
                            description: "Note is required",
                          });
                        }
                      }}
                      error={Boolean(feedbackErrorState.description)}
                      helperText={feedbackErrorState.description}
                    />
                    <TextField
                      className={classes.TextAreaInputfirst}
                      placeholder="Email address"
                      value={feedbackState.user_email}
                      onChange={(e) => {
                        setFeedbackState({
                          ...feedbackState,
                          user_email: e.target.value,
                        });
                        if (e.target.value) {
                          const validatEmail = ValidateEmail(e.target.value);
                          if (!validatEmail) {
                            setFeedbackErrorState({
                              ...feedbackErrorState,
                              user_email: "Please enter proper email",
                            });
                          } else {
                            setFeedbackErrorState({
                              ...feedbackErrorState,
                              user_email: "",
                            });
                          }
                        } else {
                          setFeedbackErrorState({
                            ...feedbackErrorState,
                            user_email: "Email required",
                          });
                        }
                      }}
                      error={Boolean(feedbackErrorState.user_email)}
                      helperText={feedbackErrorState.user_email}
                      // variant="outlined"
                      // fullWidth
                      // sx={{ mt: 0 }}
                      // rows={5}
                    />
                    {/* <Typography
                className={classes.titles}
                variant="subtitle1"
                sx={{ fontWeight: 400, mt: 2 }}
              >
                We'll reply to your email{" "}
                <span style={{ fontWeight: 500 }}>
                  flourishinghumanity@gmail.com
                </span>
              </Typography> */}
                    <NewButton
                      buttonText={"Send"}
                      variant="contained"
                      onClick={() =>
                        giveFeedback({
                          ...feedbackState,
                        })
                      }
                      isLoadong={loading}
                      isDisabled={
                        Boolean(feedbackErrorState.user_email) ||
                        Boolean(feedbackErrorState.description) ||
                        Boolean(feedbackErrorState.theme) ||
                        !Boolean(feedbackState.user_email) ||
                        !Boolean(feedbackState.description) ||
                        !Boolean(feedbackState.theme)
                      }
                    />
                  </div>
                )}
                {currentTab === "Report a bug" && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      width: "100%",
                    }}
                  >
                    <FormControl
                      variant="outlined"
                      fullWidth
                      sx={{
                        // display: 'flex',
                        // flexDirection: 'row',
                        // alignItems: 'center',
                        // gap: '8px',
                        padding: "1px",
                        width: "100%",
                        height: "56px",

                        background: "#FFFFFF",
                        border: "1px solid #EDEDED",
                        borderRadius: "12px",
                        "& .MuiOutlinedInput-input": {
                          // height: '52px !important',
                        },
                        "& .MuiInputBase-root": {
                          padding: "18px 0",
                          height: "52px",
                        },
                      }}
                    >
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Age"
                        name=""
                        placeholder="bugType"
                        // value={selectedBugOption}
                        // onChange={handleOptionBugChange}
                        value={reportaBugState.bugType}
                        onChange={handleOptionBugChange}
                        sx={{
                          "& .MuiSelect-select": {
                            display: "flex",
                            alignItems: "center",
                          },
                        }}
                        displayEmpty
                        renderValue={
                          reportaBugState.bugType
                            ? undefined
                            : () => <Placeholder>Bug Type</Placeholder>
                        }
                      >
                        <MenuItem value={10}>Option 1</MenuItem>
                        <MenuItem value={20}>Option 2</MenuItem>
                        <MenuItem value={30}>Option 3</MenuItem>
                      </Select>
                    </FormControl>

                    <TextField
                      className={classes.TextAreaInput}
                      placeholder="Describe your bug"
                      variant="outlined"
                      fullWidth
                      multiline
                      sx={{ mt: 2 }}
                      rows={7}
                      value={reportaBugState.description}
                      onChange={(e) => {
                        setReportaBugState({
                          ...reportaBugState,
                          description: e.target.value,
                        });
                        if (e.target.value) {
                          setReportaBugErrorState({
                            ...reportaBugErrorState,
                            description: "",
                          });
                        } else {
                          setReportaBugErrorState({
                            ...reportaBugErrorState,
                            description: "Description is required",
                          });
                        }
                      }}
                      error={Boolean(reportaBugErrorState.description)}
                      helperText={reportaBugErrorState.description}
                    />
                    <TextField
                      className={classes.TextAreaInputfirst}
                      placeholder="Email address"
                      value={reportaBugState.user_email}
                      onChange={(e) => {
                        setReportaBugState({
                          ...reportaBugState,
                          user_email: e.target.value,
                        });
                        if (e.target.value) {
                          const validatEmail = ValidateEmail(e.target.value);
                          if (!validatEmail) {
                            setReportaBugErrorState({
                              ...reportaBugErrorState,
                              user_email: "Please enter proper email",
                            });
                          } else {
                            setReportaBugErrorState({
                              ...reportaBugErrorState,
                              user_email: "",
                            });
                          }
                        } else {
                          setReportaBugErrorState({
                            ...reportaBugErrorState,
                            user_email: "Email required",
                          });
                        }
                      }}
                      error={Boolean(reportaBugErrorState.user_email)}
                      helperText={reportaBugErrorState.user_email}
                      // variant="outlined"
                      // fullWidth
                      // sx={{ mt: 0 }}
                      // rows={5}
                    />
                    <DropzoneArea
                      acceptedFiles={["image/*"]}
                      dropzoneClass={
                        files.length > 0
                          ? classes.hideDropzone
                          : classes.dropzone
                      }
                      dropzoneText="Drag and drop an image here or click"
                      filesLimit={1}
                      // Icon={CloudUploadIcon}
                      maxFileSize={5000000}
                      onChange={(files) => setFiles(files)}
                      onDrop={handleDrop}
                      showPreviews
                      showPreviewsInDropzone={false}
                    />
                    {files.length > 0 && (
                      <Typography variant="subtitle1">
                        {files[0].name}
                      </Typography>
                    )}
                    <Box sx={{ mt: 2 }}>
                      <NewButton
                        buttonText={"Send"}
                        variant="contained"
                        onClick={() =>
                          giveFeedback({
                            ...feedbackState,
                          })
                        }
                        isLoadong={loading}
                        isDisabled={
                          Boolean(reportaBugErrorState.user_email) ||
                          Boolean(reportaBugErrorState.description) ||
                          Boolean(reportaBugErrorState.bugType) ||
                          !Boolean(reportaBugState.user_email) ||
                          !Boolean(reportaBugState.description) ||
                          !Boolean(reportaBugState.bugType)
                        }
                      />
                    </Box>
                  </div>
                )}
                {currentTab === "Request a feature" && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      width: "100%",
                    }}
                  >
                    <FormControl
                      variant="outlined"
                      fullWidth
                      sx={{
                        // display: 'flex',
                        // flexDirection: 'row',
                        // alignItems: 'center',
                        // gap: '8px',
                        padding: "1px",
                        width: "100%",
                        height: "56px",

                        background: "#FFFFFF",
                        border: "1px solid #EDEDED",
                        borderRadius: "12px",
                        "& .MuiOutlinedInput-input": {
                          // height: '52px !important',
                        },
                        "& .MuiInputBase-root": {
                          padding: "18px 0",
                          height: "52px",
                        },
                      }}
                    >
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Age"
                        placeholder="Age"
                        value={requestFeatureState.featureType}
                        onChange={handleOptionChange}
                        displayEmpty
                        renderValue={
                          requestFeatureState.featureType
                            ? undefined
                            : () => <Placeholder>Feature Type</Placeholder>
                        }
                        sx={{
                          "& .MuiSelect-select": {
                            display: "flex",
                            alignItems: "center",
                          },
                        }}
                      >
                        <MenuItem value={10}>Option 1</MenuItem>
                        <MenuItem value={20}>Option 2</MenuItem>
                        <MenuItem value={30}>Option 3</MenuItem>
                      </Select>
                    </FormControl>

                    <TextField
                      className={classes.TextAreaInput}
                      placeholder="Describe feature functionality"
                      variant="outlined"
                      fullWidth
                      multiline
                      // maxRows={7}
                      // minRows={3}
                      sx={{ mt: 2 }}
                      rows={7}
                      value={requestFeatureState.description}
                      onChange={(e) => {
                        setRequestFeatureState({
                          ...requestFeatureState,
                          description: e.target.value,
                        });
                        if (e.target.value) {
                          setRequestFeatureErrorState({
                            ...requestFeatureErrorState,
                            description: "",
                          });
                        } else {
                          setRequestFeatureErrorState({
                            ...requestFeatureErrorState,
                            description: "Description is required",
                          });
                        }
                      }}
                      error={Boolean(requestFeatureErrorState.description)}
                      helperText={requestFeatureErrorState.description}
                    />
                    <TextField
                      className={classes.TextAreaInputfirst}
                      placeholder="Email address"
                      InputProps={{}}
                      value={requestFeatureState.user_email}
                      onChange={(e) => {
                        setRequestFeatureState({
                          ...requestFeatureState,
                          user_email: e.target.value,
                        });
                        if (e.target.value) {
                          const validatEmail = ValidateEmail(e.target.value);
                          if (!validatEmail) {
                            setRequestFeatureErrorState({
                              ...requestFeatureErrorState,
                              user_email: "Please enter proper email",
                            });
                          } else {
                            setRequestFeatureErrorState({
                              ...requestFeatureErrorState,
                              user_email: "",
                            });
                          }
                        } else {
                          setRequestFeatureErrorState({
                            ...requestFeatureErrorState,
                            user_email: "Email required",
                          });
                        }
                      }}
                      error={Boolean(requestFeatureErrorState.user_email)}
                      helperText={requestFeatureErrorState.user_email}
                      // variant="outlined"
                      // fullWidth
                      // sx={{ mt: 0 }}
                      // rows={7}
                    />

                    {/* <Typography
                className={classes.titles}
                variant="subtitle1"
                sx={{ fontWeight: 400, mt: 2 }}
              >
                We'll reply to your email{" "}
                <span style={{ fontWeight: 500 }}>
                  flourishinghumanity@gmail.com
                </span>
              </Typography> */}
                    <Box sx={{ mt: 2 }}>
                      <NewButton
                        buttonText={"Send"}
                        variant="contained"
                        onClick={() =>
                          giveFeedback({
                            ...feedbackState,
                          })
                        }
                        isLoadong={loading}
                        isDisabled={
                          Boolean(requestFeatureErrorState.user_email) ||
                          Boolean(requestFeatureErrorState.description) ||
                          Boolean(requestFeatureErrorState.featureType) ||
                          !Boolean(requestFeatureState.user_email) ||
                          !Boolean(requestFeatureState.description) ||
                          !Boolean(requestFeatureState.featureType)
                        }
                      />
                    </Box>
                  </div>
                )}
              </Grid>
            )}
            {props?.selectedTab === "tutorial" && (
              <Grid item xs={12} md={12}>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"flex-start"}
                  gap={"24px"}
                >
                  <Typography
                    fontSize={`${pxToRem(24)} !important`}
                    color={"#000 !important"}
                    fontWeight={"500 !important"}
                    lineHeight={"100% !important"}
                  >
                    Guided onboarding
                  </Typography>
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"flex-start"}
                    gap={"24px"}
                  >
                    <Box
                      display={"flex"}
                      alignItems={"flex-start"}
                      gap={"16px"}
                    >
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"flex-start"}
                        gap={"12px"}
                      >
                        <Typography
                          fontSize={`${pxToRem(14)} !important`}
                          color={"#000 !important"}
                          fontWeight={"400 !important"}
                          lineHeight={"100% !important"}
                        >
                          Your easy tour to get familiar with Tenacious Tracker,
                          whether you're new or need a quick refresher
                        </Typography>
                        <NewButton
                          buttonText="Watch onboarding"
                          onClick={() => {
                            localStorage.setItem("showTutorial", "true");
                            navigate("/tracking");
                          }}
                          padding="8px 10px 10px"
                          variant="contained"
                        />
                      </Box>
                    </Box>
                    {/* <Box
                      display={"flex"}
                      alignItems={"flex-start"}
                      gap={"16px"}
                    >
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"flex-start"}
                        gap={"12px"}
                      >
                        <Box
                          display={"flex"}
                          flexDirection={"column"}
                          alignItems={"flex-start"}
                          gap={"12px"}
                        >
                          <Typography
                            fontSize={`${pxToRem(24)} !important`}
                            color={"#000 !important"}
                            fontWeight={"500 !important"}
                            lineHeight={"100% !important"}
                          >
                            Video tutorial
                          </Typography>
                          <Typography
                            fontSize={`${pxToRem(14)} !important`}
                            color={"#000 !important"}
                            fontWeight={"400 !important"}
                            lineHeight={"100% !important"}
                          >
                            Watch ourvideo tutorial to navigate and make the
                            most of our platform's essential features.
                          </Typography>
                          <NewButton
                            buttonText="Watch tutorial"
                            onClick={() => setShowYoutubeModal(true)}
                            padding="8px 10px 10px"
                            variant="contained"
                          />
                        </Box>
                      </Box>
                    </Box> */}
                  </Box>
                </Box>
                <YoutubeComponent
                  showYoutubeModal={showYoutubeModal}
                  setShowYoutubeModal={setShowYoutubeModal}
                />
              </Grid>
            )}
          </Grid>
        </Box>
      </div>

      {/* {props?.selectedTab === "membership" &&  <Box sx={{ mt: 7 }}>
        <Typography
          className={classes.titles}
          variant="h4"
          sx={{ fontWeight: 500 }}
        >
          Handle with care
        </Typography>
        <Button
          variant="outlined"
          sx={{
            mt: 3,

            padding: "12px",
            border: "2px solid #F17F79",
          }}
        >
          <Typography variant="subtitle1" component="h4">
            Close my account
          </Typography>
        </Button>
      </Box>} */}
    </>
  );
}

export default AccountSettings;
