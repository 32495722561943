import React from 'react'

const RestoreIcon = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none">
                <path d="M14.75 10.4167H11V5" stroke="var(--color-new-main)" strokeWidth="2" />
                <path d="M11 19C15.9706 19 20 14.9706 20 10C20 5.02944 15.9706 1 11 1C6.02944 1 2 5.02944 2 10C2 12.3051 2.86656 14.4077 4.29168 16" stroke="var(--color-new-main)" strokeWidth="2" />
                <path d="M1 16.5H5.24264V12.2574" stroke="var(--color-new-main)" strokeWidth="2" />
            </svg>
        </>
    )
}

export default RestoreIcon
