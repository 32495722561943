import { Box, Button, SxProps, Theme, Typography } from "@mui/material";
import React from "react";
import palette from "../theme/palette";
import { ButtonLoader } from "./buttonLoader";

const NewButton = ({
  variant = "contained",
  type = undefined,
  isDisabled = false,
  onClick = () => {},
  buttonText = "",
  isLoadong = false,
  padding = null,
  borderRadius = "50px !important",
  textColor = null,
  textDisabledColor = null,
  borderColor = null,
  borderPixel = "2px",
  fontSize = "16px",
  fontWeight = 400,
  boxPadding = "",
  btnType = "default",
  extraSx = {},
  boxSx = {},
  typographySx = {}
}: {
  variant?: "contained" | "text" | "outlined";
  type?: "button" | "reset" | "submit" | undefined;
  isDisabled?: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  buttonText: string | React.ReactNode;
  isLoadong?: boolean;
  padding?: string;
  borderRadius?: string;
  textColor?: string;
  textDisabledColor?: string;
  borderColor?: string;
  borderPixel?: string;
  fontSize?: string;
  fontWeight?: number;
  boxPadding?: string;
  btnType?: "default" | "danger";
  extraSx?: SxProps<Theme>;
  boxSx?: SxProps<Theme>;
  typographySx?: SxProps<Theme>;
}) => {
  return (
    <Button
      type={type}
      variant={variant}
      className=""
      sx={{
        display: "flex !important",
        padding: padding
          ? padding
          : variant === "text"
          ? "3px 24px 3px 24px !important"
          : "3px 34px 3px 34px !important",
        justifyContent: "center !important",
        alignItems: "center !important",
        flexShrink: 0,
        borderRadius: borderRadius,
        background:
          variant === "contained"
            ? btnType === "danger"
              ? "#F17F79 !important"
              : `var(--color-new-main) !important`
            : "",
        boxShadow: "none",
        "&.MuiButton-contained": {
          marginBottom: "0px !important",
          border:
            "2px solid " +
            (btnType === "danger" ? "#F17F79" : `var(--color-new-main)`),

          "&.Mui-disabled": {
            background: btnType === "danger" ? "#ffbebb !important" : `#D6F5C3 !important`,
            border: "2px solid " + (btnType === "danger" ? "#ffbebb" : `#D6F5C3`),
          },
        },
        "&.MuiButton-outlined": {
          border: `${borderPixel} solid ${
            borderColor ? borderColor : "var(--border-color)"
          }`,
        },
        ...extraSx
      }}
      disabled={isDisabled}
      onClick={(e) => (isLoadong ? null : onClick(e))}
    >
      <Box
        padding={
          padding
            ? boxPadding
            : variant === "text"
            ? "15px 10px 15px 10px"
            : "15px 16px 15px 16px"
        }
        sx={{
          ...boxSx
        }}
      >
        {isLoadong ? (
          <ButtonLoader />
        ) : (
          <Typography
            variant="caption"
            component="span"
            // className={classes.pageTitle}
            sx={{
              color: isDisabled
                ? textDisabledColor
                  ? textDisabledColor
                  : "var(--svg-new-color)"
                : textColor
                ? textColor
                : variant === "text"
                ? "#000000"
                : btnType === "danger" ? "#fff" : "var(--color-black)",
              fontSize: `${fontSize} !important`,
              fontWight: fontWeight,
              lineHeight: "100%",
              ...typographySx
            }}
          >
            {buttonText}
          </Typography>
        )}
      </Box>
    </Button>
  );
};

export default NewButton;
