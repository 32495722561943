import { Box, IconButton, InputAdornment, TextField, Typography } from "@mui/material"
import React, { useState } from "react"
import { pxToRem } from "../../../theme/typography"

import { ArrowUpNew } from "../../../assets/images/icon/ArrowDownGreenNew"
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import Calendar from "../../../assets/images/icon/Calendar";
const getDatesBetween = (startDate, endDate) => {
    const dates = [];
    let currentDate = dayjs(startDate);

    while (currentDate.isBefore(dayjs(endDate).add(1, 'day'))) {
        dates.push(currentDate.format('MM/DD/YY')); // Adjust format as needed
        currentDate = currentDate.add(1, 'day');
    }

    return dates;
};
dayjs.extend(customParseFormat);
const Step3 = ({
    startDate, metrics, endDate, changeValueOfTable, changeCommentOfTable
}) => {
    const datesArray = getDatesBetween(startDate, endDate);
    return <Box display={"flex"} flexDirection={"column"} alignItems={"flex-start"} padding={"0px 20px 0px 0px"} gap={"60px"} width={"670px"}>
        <Typography sx={{
            color: "var(--black-color)",
            // fontSize: `${pxToRem(10)} !important`,
            fontSize: `${pxToRem(26)} !important`,
            lineHeight: `${pxToRem(31.2)} !important`,
            // textAlign: "center !important",
            fontWeight: `700 !important`,
            "&.MuiTypography-root": {
                "@media  (max-width: 600px) and (min-width: 100px)": {
                    fontSize: `${pxToRem(26)} !important`,
                    lineHeight: `${pxToRem(31.2)} !important`
                },
            }
        }} >
            Enter the data you want to import
        </Typography>
        <Box maxWidth={"600px"} minHeight={"352px"} maxHeight={"600px"} sx={{
            overflowX: "auto",
            overflowY: 'auto'
        }}>
            <table border={1} cellPadding={3} cellSpacing={0}>
                <thead>
                    <tr>
                        <th>
                            <TextIn name={"Metrics"} />
                        </th>
                        {datesArray.map(it => <th>
                            <TextIn name={it} />
                        </th>)}
                    </tr>
                </thead>
                <tbody>
                    {metrics.map((it, index) => {
                        return <>
                            <tr>
                                <td><TextIn name={it.name} /></td>
                                {datesArray.map(ite => {
                                    let found = null
                                    if (it?.dates && it?.dates.length) {
                                        found = it?.dates.find(it => it.date === ite)
                                    }
                                    return <td>
                                        <TextField
                                            sx={{
                                                maxWidth: '300px',
                                                "&.MuiTextField-root": {
                                                    marginBottom: "0px !important",
                                                },
                                                "& .MuiOutlinedInput-root": {
                                                    alignItems: "center",
                                                    gap: "8px",
                                                    borderRadius: "0px !important",
                                                    border: "1px solid #DCE5E0",
                                                    background: "#F7F9FA",
                                                    "& .MuiOutlinedInput-input": {
                                                        padding: "10px !important",
                                                        color: "#000!important",
                                                    },
                                                },
                                            }}
                                            placeholder="+"
                                            variant="outlined"
                                            name="influence"
                                            value={found ? found.response : ""}
                                            onChange={(e) => {
                                                changeValueOfTable(index, ite, e.target.value)
                                            }}
                                        />
                                    </td>
                                })}
                            </tr>
                            <tr>
                                <td><TextIn name={"Comment"} /></td>
                                {datesArray.map(ite => {
                                    let found = null
                                    if (it?.dates && it?.dates.length) {
                                        found = it?.dates.find(it => it.date === ite)
                                    }
                                    return <td>
                                        <TextField
                                            sx={{
                                                maxWidth: '300px',
                                                "&.MuiTextField-root": {
                                                    marginBottom: "0px !important",
                                                },
                                                "& .MuiOutlinedInput-root": {
                                                    alignItems: "center",
                                                    gap: "8px",
                                                    borderRadius: "0px !important",
                                                    border: "1px solid #DCE5E0",
                                                    background: "#F7F9FA",
                                                    "& .MuiOutlinedInput-input": {
                                                        padding: "10px !important",
                                                        color: "#000!important",
                                                    },
                                                },
                                            }}
                                            placeholder="+"
                                            variant="outlined"
                                            name="influence"
                                            value={found ? found.comment : ""}
                                            onChange={(e) => {
                                                changeCommentOfTable(index, ite, e.target.value)
                                            }}
                                        />
                                    </td>
                                })}
                            </tr>
                        </>
                    })}
                </tbody>
            </table>
        </Box>


    </Box>
}

export default Step3

const TextIn = ({ name }) => {
    return <Typography sx={{
        color: "#272727",
        // fontSize: `${pxToRem(10)} !important`,
        fontSize: `${pxToRem(10.5)} !important`,
        lineHeight: `${pxToRem(12.71)} !important`,
        // textAlign: "center !important",
        fontWeight: `700 !important`,
        "&.MuiTypography-root": {
            "@media  (max-width: 600px) and (min-width: 100px)": {
                fontSize: `${pxToRem(10.5)} !important`,
                lineHeight: `${pxToRem(12.71)} !important`
            },
        }
    }} >
        {name}
    </Typography>
}