import { Box, Button, FormLabel, MenuItem, Popover, Radio, Select, Switch, TextField } from "@mui/material";
import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import palette from "../../theme/palette";
import { styled } from "@mui/material/styles";
import useWidth from "../../utils/useWidth";
import SuccessIcon from "../../assets/images/icon/SuccuessIcon";
import { userService } from "../../services/auth";
import moment from "moment";
import { IOSSwitch } from "../reflective-questions/leftSideRefQueNew";
import NewButton from "../../components/NewButton";
import { pxToRem } from "../../theme/typography";
import UploadFile from "./uploadFile";
import UploadData from "./UploadData";

const useStyles = makeStyles((theme) => ({
  titles: {
    color: `${palette.primary.light}!important`,
    fontSize: "16px !important",
    // paddingRight: "20px",
    // paddingBottom: "20px",
  },
  titlesTop: {
    color: `${palette.primary.light}!important`,
  },
  boxFlex: {
    display: "flex",
  },
  inputBox: {
    width: " 60px",
    height: " 34px",
    background: "#F7F9FA",
    border: "1px solid #DCE6E0",
    borderRadius: "5px",
    color: `${palette.primary.light}!important`,
    textAlign: "center",
    outline: "none",
  },
  scheduleCalendar: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '0px',

    background: '#FFFFFF',
    boxShadow: '0px 10px 44px rgba(0, 0, 0, 0.08)',
    borderRadius: '12px',

  },
  actionData: {
    boxSizing: 'border-box',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '12px',
    gap: '8px',
    width: '100%',
    borderBottom: '1px solid #EDEDED',
  },
  dateSelectionDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '12px',
    gap: '12px',
  },
  frame4283: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px',
    gap: '8px',
  },
  activeText: {
    fontWeight: 400,
    fontSize: '14px !important',
    color: '#11734A !important',
  },
  nonactiveText: {
    fontWeight: 400,
    fontSize: '14px !important',
    color: '#000000 !important',
  }
}));
interface switchProps {
  onChange: any,
  checked: boolean
}

function AppSettings(props) {
  const [selectedValue, setSelectedValue] = React.useState("a");
  const [visibleModal, setVisibleModal] = React.useState(false);
  const [visibleUploadModal, setVisibleUploadModal] = React.useState(false);
  const [isFromExport, setIsFromExport] = React.useState(false);
  const [selectedScheduleTime, setSelectedScheduleTime] = useState({
    hour: null,
    minutes: null
  })
  const [checkedSwitch, setCheckedSwitch] = useState(false)
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };
  const closeModal = (isFromExport = false) => {
    setIsFromExport(isFromExport)
    setVisibleModal(!visibleModal)
    if(isFromExport) {
      handleExportFile()
    }
  }
  const getReminderList = () => {
    userService.reminderList({
      type: "APP"
    }).then((data) => {
      if (data.data && data.data._id) {
        setCheckedSwitch(data.data.status === "ACTIVE")
        const formatted = moment(data.data.time).format('hh:mm')
        setSelectedScheduleTime({
          hour: formatted.split(" ")[0].split(":")[0],
          minutes: formatted.split(" ")[0].split(":")[1]
        })
      }

    })
  }
  useEffect(() => {
    getReminderList()
  }, [])
  const reminderCreate = (active = false) => {
    userService.reminderCreate({
      // ...values,
      "time": !active ? moment().format() : moment(`${selectedScheduleTime.hour}:${selectedScheduleTime.minutes}`, 'HH:mm').format()
      ,
      "status": !active ? "NOT_ACTIVE" : "ACTIVE",
      uid: localStorage.getItem("userDetails")
    }).then((data) => {
      props.setMsgType("success");
      props.setShowSnakbar(true);
      props.setSnakbarMsg(data.description);
      handleClose()
    })
  }

  const classes = useStyles();
  const width = useWidth();
  const searchBarProps = { // make sure all required component's inputs/Props keys&types match
    onChange: e => {
      setCheckedSwitch(e.target.checked)
      if (!e.target.checked) {
        reminderCreate()

      }
    },
    checked: checkedSwitch
  }
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleExportFile = () => {
    const token = localStorage.getItem("userDetails")
    userService.exportFileFromServer({
      from: moment().subtract(7, 'day').format("MM-DD-YYYY"),
      to: moment().format("MM-DD-YYYY"),
    }, token).then((data) => {
      // let blob = new Blob([data]),
      //   downloadUrl = window.URL.createObjectURL(blob),
      //   filename = "TNT-export-file.csv";
      // let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
      //   matches = filenameRegex.exec("TNT-export-file");
      // if (matches != null && matches[1]) {
      //   filename = matches[1].replace(/['"]/g, "");
      // }

      // let a = document.createElement("a");
      // if (typeof a.download === "undefined") {
      //   window.location.href = downloadUrl;
      // } else {
      //   a.href = downloadUrl;
      //   a.download = filename;
      //   document.body.appendChild(a);
      //   a.click();
      //   document.body.removeChild(a);
      //   window.URL.revokeObjectURL(downloadUrl);
      // }
    }).catch((err) => {
    })
  }
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '24px',
      gap: '24px',
      width: '100%'
    }}>
      {props.selectedTab !== "import-export" && <Box className={classes.boxFlex} sx={{ mt: 4, width: '100%' }}>
        <Box sx={{ width: '100%' }}>
          <div
            style={{
              display: "flex",
              flexDirection: 'row',
              alignItems: 'center',
              padding: '16px 20px',
              gap: '8px',
              width: '100%',
              background: '#FFFFFF',
              border: '1px solid #EDEDED',
              borderRadius: '12px',
              justifyContent: 'space-between',
              // flexDirection: width > 768 ? "row" : "column",
            }}
          >
            <Typography className={classes.titles}>
              Send daily tracking reminder
            </Typography>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '8px',
              gap: '10px',

            }}>
              {checkedSwitch ? (
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '8px',
                  gap: '10px',

                  height: '28px',

                  background: '#FFFFFF',
                  border: '1px solid var(--color-new-main)',
                  borderRadius: '6px',
                }} onClick={handleClick}>
                  <Typography sx={{ fontWeight: 500, fontSize: '12px !important', color: 'var(--color-new-main)' }}>
                    {selectedScheduleTime.hour > 0 ? `${selectedScheduleTime.hour || ''}:${selectedScheduleTime.minutes || '00'}` : `Due Time`}
                  </Typography>

                </div>
              ) : null}
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <div className={classes.scheduleCalendar}>
                  <div className={classes.actionData}>
                    <Typography sx={{ fontWeight: 500, color: '#000000', fontSize: '14px !important' }}>Set reminder time</Typography>

                  </div>
                  <div className={classes.dateSelectionDiv}>
                    <div className={classes.frame4283}>
                      <Box sx={{ display: "flex", alignItems: 'center' }}>
                        <TextField
                          // className={`${classes.marginRight} textField`}
                          id="outlined-basic"
                          InputProps={{
                            inputProps: { min: "0", max: "23", step: "1", maxLength: 2 }, sx: {
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'center',
                              // padding: '12px',
                              gap: '10px',

                              width: '38px',
                              height: '38px',

                              background: '#FFFFFF',
                              border: '1px solid #EDEDED',
                              borderRadius: '8px',
                            }
                          }}
                          type="tel"

                          variant="outlined"
                          // size="small"
                          value={selectedScheduleTime.hour || ""}
                          onChange={(e) => {
                            if (e.target.value && Number(e.target.value) < 24 && Number(e.target.value) > -1) {
                              setSelectedScheduleTime({
                                ...selectedScheduleTime,
                                hour: e.target.value
                              })
                            } else {
                              setSelectedScheduleTime({
                                ...selectedScheduleTime,
                                hour: null
                              })
                            }

                            // setHour(e.target.value);
                          }}
                          sx={{
                            marginBottom: '0 !important',
                            '& .MuiOutlinedInput-input': {
                              padding: '10px 8px !important',
                              textAlign: 'center'
                            }
                          }}
                        />
                        <Typography
                          variant="caption"
                          component="div"
                          sx={{
                            margin: '0 8px',
                            color: '#000000',
                            fontWeight: 400,
                            fontSize: '14px !important'
                          }}
                        // className={`${classes.influenceCaption} ${classes.padding}`}
                        >
                          :
                        </Typography>
                        <TextField
                          // className={`${classes.marginLeft} textField`}
                          id="outlined-basic"
                          // type="number"
                          InputProps={{
                            inputProps: { min: "0", max: "23", step: "1", maxLength: 2 }, sx: {
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'center',
                              alignItems: 'center',
                              // padding: '12px',
                              gap: '10px',

                              width: '38px',
                              height: '38px',

                              background: '#FFFFFF',
                              border: '1px solid #EDEDED',
                              borderRadius: '8px',
                            }
                          }}
                          variant="outlined"
                          value={selectedScheduleTime.minutes || ""}
                          onChange={(e) => {
                            if (e.target.value && Number(e.target.value) < 60 && Number(e.target.value) > -1) {
                              setSelectedScheduleTime({
                                ...selectedScheduleTime,
                                minutes: e.target.value
                              })
                            } else {
                              setSelectedScheduleTime({
                                ...selectedScheduleTime,
                                minutes: null
                              })
                            }

                            // setHour(e.target.value);
                          }}
                          sx={{
                            marginBottom: '0 !important',
                            '& .MuiOutlinedInput-input': {
                              padding: '10px 8px !important',
                              textAlign: 'center'
                            }
                          }}
                        />
                      </Box>
                      {/* <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Age"
                        name=""
                        placeholder="bugType"
                        // value={selectedBugOption}
                        // onChange={handleOptionBugChange}
                        value={selectedScheduleTime.ampm}
                        onChange={(e) => {
                          setSelectedScheduleTime({
                            ...selectedScheduleTime,
                            ampm: e.target.value
                          })
                          // setHour(e.target.value);
                        }}
                        renderValue={
                          selectedScheduleTime.ampm ? () => <Typography
                            variant="caption"
                            component="div"
                            sx={{
                              color: 'var(--color-new-main)',
                              fontWeight: 400,
                              fontSize: '14px !important'
                            }}
                          // className={`${classes.influenceCaption} ${classes.padding}`}
                          >
                            {selectedScheduleTime.ampm}
                          </Typography> : () => <></>
                        }
                        // onChange={handleOptionBugChange}
                        sx={{
                          '& .MuiSelect-select': {
                            display: 'flex',
                            alignItems: "center",
                            color: 'var(--color-new-main) !important',
                          },
                          '& .MuiSvgIcon-root': {
                            color: 'var(--color-new-main) !important',

                          },
                          width: '70px',
                          height: '38px',
                          background: '#FFFFFF',
                          border: '1px solid var(--color-new-main)',
                          borderRadius: '8px',
                        }}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              boxShadow: '0px 8px 44px rgba(0, 0, 0, 0.1)',
                              borderRadius: '8px',
                              background: '#FFFFFF',
                              '& .MuiButtonBase-root': {
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                padding: '9px 12px',
                                gap: '4px',

                              }
                            }
                          }
                        }}
                      >
                        <MenuItem value={"AM"} className={selectedScheduleTime.ampm === "AM" ? classes.activeText : classes.nonactiveText}>AM {selectedScheduleTime.ampm === "AM" ? <SuccessIcon /> : ""}</MenuItem>
                        <MenuItem value={"PM"} className={selectedScheduleTime.ampm === "PM" ? classes.activeText : classes.nonactiveText}>PM {selectedScheduleTime.ampm === "PM" ? <SuccessIcon /> : ""}</MenuItem>
                      </Select> */}
                    </div>
                    <NewButton variant="contained" onClick={() => reminderCreate(true)} buttonText="Save" boxPadding="6px" padding="0px" fontSize={pxToRem(12)} typographySx={{
                       lineHeight: "100% !important",
                       "&.MuiTypography-root": {
                        "@media  (max-width: 786px) and (min-width: 100px)": {
                          fontSize: `${pxToRem(12)} !important`,
                          lineHeight: "100% !important",
                        },
                      },
                    }} boxSx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center"
                    }}/>
                  </div>
                </div>
              </Popover>
              <IOSSwitch {...searchBarProps} />
            </div>
          </div>

          {/* <div
            style={{
              display: "flex",
              flexDirection: width > 768 ? "row" : "column",
            }}
          >
            <Typography sx={{ mt: 4 }} className={classes.titles}>
              Set reminder time
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <input type="number" className={classes.inputBox} />
              <Box
                sx={{ color: `${palette.primary.light}!important`, mx: 0.4 }}
              >
                :
              </Box>
              <input type="number" className={classes.inputBox} />
              <Radio
                checked={selectedValue === "a"}
                onChange={handleChange}
                value="a"
                name="radio-buttons"
                inputProps={{ "aria-label": "A" }}
              />
              <FormLabel className={classes.titles}>AM</FormLabel>
              <Radio
                checked={selectedValue === "b"}
                onChange={handleChange}
                value="b"
                name="radio-buttons"
                inputProps={{ "aria-label": "B" }}
              />
              <FormLabel className={classes.titles}>PM</FormLabel>
            </Box>
          </div> */}
        </Box>
      </Box >}
      {props.selectedTab === "import-export" && <Box className={classes.boxFlex} sx={{ mt: 0, width: '100%' }}>
        <Box sx={{
          width: '100%', display: "flex",
          flexDirection: 'column',
          alignItems: 'flex-start',
          padding: '0px 20px 16px 0px',
          gap: '8px',
          background: '#FFFFFF',
          border: 'none',
          borderRadius: '12px',
          justifyContent: 'space-between',
        }}>
          <div
            style={{

              // flexDirection: width > 768 ? "row" : "column",
            }}
          >
            <Typography className={classes.titles}>
              Data transferring
            </Typography>

          </div>
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            padding: '8px 0px',
            gap: '10px',

          }}>
            <NewButton
              buttonText="Import .csv file"
              // onClick={props?.handleSubmit}
              // onClick={() => setVisibleUploadModal(true)}
              onClick={() => closeModal(false)}
              borderColor="var(--color-new-main)"
              padding="10px"
              borderRadius="6px"
              variant="outlined"
            />
            <NewButton
              buttonText="Export as .csv file"
              // onClick={props?.handleSubmit}
              onClick={() => closeModal(true)}
              borderRadius="6px"
              padding="10px"
              borderColor="var(--color-new-main)"
              variant="outlined"
            />
          </div>
        </Box>
      </Box >}
      <UploadFile closeModal={closeModal} visibleModal={visibleModal} isFromExport={isFromExport}/>
      <UploadData visibleModal={visibleUploadModal} setVisibleModal={setVisibleUploadModal} />
    </Box >
  );
}

export default AppSettings;
