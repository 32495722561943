/*eslint array-callback-return: "off"*/
import React, { useContext } from "react";
import palette from "../../theme/palette";
import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import shape from "../../theme/shape";
import { UserTypeContext } from "../../context/userStatus";

const useStyles = makeStyles((theme) => ({
  drawerWrapper: {
    padding: "60px 40px",
    height: "100%",
    [theme.breakpoints.down("lg")]: {
      padding: "50px 30px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "30px 20px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
    },
  },
  ideasList: {
    marginTop: "40px",
    [theme.breakpoints.down("lg")]: {
      marginTop: "30px",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "20px",
    },
  },
  ideasListItem: {
    backgroundColor: palette.common.white,
    borderRadius: shape.borderRadius,
    padding: "24px",
    fontSize: "16px",
    lineHeight: "16px",
    color: palette.primary.light,
    fontWeight: 400,
    marginBottom: "12px",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      padding: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
  },
}));

interface RightSideOutcomeTrackingModal {
  outcomeIdeas: any;
  setOutcomeValue: (outComeValue: any) => void;
}

const RightSideOutcomeTracking = (props: RightSideOutcomeTrackingModal) => {
  const classes = useStyles();
  const { state: outcomeState } = useContext(UserTypeContext);

  return (
    <Grid
      sx={{ backgroundColor: palette.primary.main }}
      className={classes.drawerWrapper}
    >
      <Grid>
        <Typography
          variant="h3"
          component="h3"
          sx={{
            color: palette.common.white,
            fontWeight: 600,
            lineHeight: "35px!important",
          }}
        >
          Here are some ideas of outcomes you might want to consider
        </Typography>
      </Grid>
      <Grid className={classes.ideasList} sx={{ width: "100%" }}>
        {props?.outcomeIdeas?.map((ideas: any) => {
          let flag = true;
          if (outcomeState?.outcomeList?.length > 0)
            for (let outObj of outcomeState?.outcomeList) {
              if (outObj.name.toLowerCase() === ideas.name.toLowerCase()) {
                flag = false;
                break;
              }
            }
          if (flag) {
            return (
              <div
                className={classes.ideasListItem}
                key={ideas._id}
                onClick={() => props.setOutcomeValue(ideas)}
              >
                {ideas.name}
              </div>
            );
          }
        })}
      </Grid>
    </Grid>
  );
};
export default RightSideOutcomeTracking;
