/*eslint @typescript-eslint/no-unused-vars: "off"*/
/*eslint eqeqeq: "off"*/
import {
  DialogTitle,
  Grid,
  Typography,
  Dialog,
  DialogContent,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import EditIcon from "../../assets/images/icon/EditIcon";
import palette from "../../theme/palette";
import ModalCloseIconBlack from "../../assets/images/icon/modalCloseIconBlack";
import LeftSideInfluence from "../influence/leftSideInfluence";
import { OutcomeStatus } from "../../utils/staticDataJson";
import LeftTransformToOut from "./LeftTransformToOut";
import LeftTransformInInflu from "./LeftTransformInInflu";
import SnackBarComp from "../../components/snackBar";
import TrueFalseIcon from "../../assets/images/icon/TrueFalseIcon";
import MeasurementIcon from "../../assets/images/icon/MeasurementIcon";
import InfluenceIcon from "./InfluenceIcon";
import MultiDrawerAddEditComponent from "../../components/MultiDrawerComponent";
import LeftSideInfluenceNew from "../influence/leftSideInfluenceNew";

const useStyles = makeStyles((theme) => ({
  // listText: {
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "space-between",
  //   padding: "0px",
  //   minHeight: "47px",
  //   gap: '10px',

  //   // "& h6": {
  //   //   color: palette.primary.light,
  //   //   fontSize: "16px",
  //   //   lineHeight: "24px",
  //   //   maxWidth: "275px",
  //   //   textOverflow: "ellipsis",
  //   //   overflow: "hidden",
  //   //   whiteSpace: "nowrap",
  //   // },
  //   "& h6": {
  //     color: palette.primary.light,
  //     fontSize: "16px",
  //     lineHeight: "24px",
  //     // maxWidth: "275px",
  //     // textOverflow: "ellipsis",
  //     // overflow: "hidden",
  //     // whiteSpace: "nowrap",
  //   },
  // },
  listText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 24px",
    gap: '12px',
    // boxShadow: '5px 0px 40px 0px rgba(18, 18, 19, 0.05)',
    // "& h6": {
    //   color: palette.primary.light,
    //   fontSize: "16px",
    //   lineHeight: "24px",
    //   maxWidth: "275px",
    //   textOverflow: "ellipsis",
    //   overflow: "hidden",
    //   whiteSpace: "nowrap",
    // },
    "& h6": {
      color: palette.common.black,
      fontSize: "16px",
      lineHeight: "24px",
      // maxWidth: "275px",
      // textOverflow: "ellipsis",
      // overflow: "hidden",
      // whiteSpace: "nowrap",
    },
  },
  modalCloseIcon: {
    position: "absolute",
    right: 0,
    top: 0,
    cursor: "pointer",
    zIndex: 1,
  },
  textClass: {
    overflow: 'hidden',
    display: '-webkit-box',
    lineClamp: 1,
    boxOrient: 'vertical',
    textOverflow: 'ellipsis',
    margin: "20px 0px !important",
  },
  fullTexttextClass: {
    overflow: 'hidden',
    display: '-webkit-box',
    lineClamp: 8,
    boxOrient: 'vertical',
    textOverflow: 'ellipsis',
    margin: "20px 0px !important",
  },
  mainDivClas: {
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: "center",
    borderBottom: '1px solid #F3F3F3',

  },
  innerDiVClass: {
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
    alignItems: "center"
  },
  mainDivClasExpand: {
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
    justifyContent: 'space-between',
    width: '100%',
    // alignItems: "center",
    alignItems: "flex-start",
    borderBottom: '1px solid #F3F3F3',
    height: "221px",
    background: '#FFF',
    flexBasis: "100%",
  }
}));

interface TrackingItemModal {
  influenceItem: any;
  setShowInfluence: (showInfluence: boolean) => void;
  showInfluence: boolean;
  openStartModalInfluence: boolean;
  setStartModalInfluence: (openStartModalInfluence: boolean) => void;
  getInfluenceList: any;
  setTransformToOutcomeTracking?: any;
  transformToOutcomeTracking?: any;
  setTransformInInfluenceTracking?: any;
  transformInInfluenceTracking?: any;
  startDate: any
  setStartDate: any
  endDate: any
  setEndDate: any
  index: number
  influenceList: any[];
  isExpand: boolean
  setLimitReachPopupVisible: any
  setLimitReachPopuptype: any
}

const TrackingItemInfluence = (props: TrackingItemModal) => {
  const classes = useStyles();
  const [influenceId, setInfluenceId] = useState(null);
  const [addInfluenceTracking, setAddInfluenceTracking] = useState(false);
  const [influenceData, setInfluenceData] = React.useState();
  const [showIcon, setshowIcon] = useState(false);
  const [influenceIdeas, setInfluenceIdeas] = useState();
  const [showSnakbar, setShowSnakbar] = useState(false);
  const [snakbarMsg, setSnakbarMsg] = useState("");
  const [msgType, setMsgType] = useState("");
  const [textInput, setTextInput] = useState(OutcomeStatus);
  const [needIdeasOpen, setNeedIdeasOpen] = useState(false)
  const handleCloseStartModal = () => {
    setInfluenceId(null);
    props.setStartModalInfluence(false);
    props.setShowInfluence(false);
  };

  const handleCloseTransformModal = () => {
    props?.setTransformToOutcomeTracking(false);
  };

  const handleCloseTransformInInfluModal = () => {
    props?.setTransformInInfluenceTracking(false);
  };

  const editOutcomeTracking = (influence: any, id: any) => {
    props.setStartModalInfluence(true);
    setInfluenceId(id);
    props.setShowInfluence(true);
    setInfluenceData(influence);
  };
  const handleCloseSnakbar = () => {
    setShowSnakbar(false);
  };
  return (
    <Grid item className={classes.listText}>
      <>
        {/* SNACK BAR */}
        <SnackBarComp
          showSnakbar={showSnakbar}
          handleCloseSnakbar={handleCloseSnakbar}
          snakbarMsg={snakbarMsg}
          type={msgType ? msgType : "info"}
        />
        {/* {props.influenceItem.name} */}
        {/* {props.influenceItem.name.length > 35 ? ( */}
        <div className={props?.isExpand ? classes.mainDivClasExpand : classes.mainDivClas} style={(props.influenceList.length - 1) === props.index ? { borderBottom: 0 } : {}}
          onMouseEnter={() => setshowIcon(true)}
          onMouseLeave={() => setshowIcon(false)}>
          <div style={{
            width: '100%'
          }}>
            {props.influenceItem.description ? (
              <Tooltip
                title={
                  <Typography sx={{ fontSize: "14px !important", lineHeight: "1.5" }}>
                    {props.influenceItem.description}
                  </Typography>
                }
              >
                <div style={{
                  flex: '1 0 0'
                }}>
                  <Typography variant="h6" flex={7} title={props.influenceItem.name} className={props?.isExpand ? classes.fullTexttextClass : classes.textClass}>
                    {props.influenceItem.name}
                  </Typography>
                </div>
              </Tooltip>
            ) : (
              <div style={{
                flex: '1 0 0'
              }}>
                <Typography variant="h6" flex={7} title={props.influenceItem.name} className={props?.isExpand ? classes.fullTexttextClass : classes.textClass}>
                  {props.influenceItem.name}
                </Typography>
              </div>
            )}

          </div>
          <div className={classes.innerDiVClass}>
            {/* <Typography variant="h6" flex={2} color="lightgrey!important">
              {props.influenceItem.type === "TYPE12"
                ? props.influenceItem.unitValue
                : null}
            </Typography> */}
            <span className={classes.textClass}>
              {<InfluenceIcon item={props.influenceItem} />}
            </span>
            {showIcon && <span
              onClick={() =>
                editOutcomeTracking(props.influenceItem, props.influenceItem._id)
              }
              className={props?.isExpand ? classes.textClass : ''}
            >
              <EditIcon />
            </span>}
          </div>

          {/* ) : (
          <Typography variant="h6" flex={7}>
            {props.influenceItem.name}
          </Typography>
        )} */}


        </div>


        {props.showInfluence && influenceId == props.influenceItem?._id && (
          <MultiDrawerAddEditComponent
            handleClose={handleCloseStartModal}
            drawerTitle={<>Edit  <span style={{ color: palette.primary.main }}>Influences</span></>}
            secondOpen={needIdeasOpen}
          >
            <LeftSideInfluenceNew
              setInfluenceData={setInfluenceData}
              influenceData={influenceData}
              influenceIdeas={influenceIdeas}
              setShowSnakbar={setShowSnakbar}
              setMsgType={setMsgType}
              setSnakbarMsg={setSnakbarMsg}
              showInfluence={props.showInfluence}
              setAddInfluenceTracking={setAddInfluenceTracking}
              setShowInfluence={props.setShowInfluence}
              setTextInput={setTextInput}
              textInput={textInput}
              handleCloseStartModal={handleCloseStartModal}
              getInfluenceList={props?.getInfluenceList}
              setTransformToOutcomeTracking={
                props?.setTransformToOutcomeTracking
              }
              transformToOutcomeTracking={props?.transformToOutcomeTracking}
              transformInInfluenceTracking={
                props?.transformInInfluenceTracking
              }
              setTransformInInfluenceTracking={
                props?.setTransformInInfluenceTracking
              }
              startDate={props.startDate}
              endDate={props.endDate}
              setNeedIdeasOpen={setNeedIdeasOpen}
              setInfluenceIdeas={setInfluenceIdeas}
              setLimitReachPopupVisible={props?.setLimitReachPopupVisible}
              setLimitReachPopuptype={props?.setLimitReachPopuptype}
            />
          </MultiDrawerAddEditComponent>
          // <Dialog
          //   disableEscapeKeyDown
          //   onClose={handleCloseStartModal}
          //   open={props.openStartModalInfluence}
          //   className="dialogWrapper dialogWrapper-outcome"
          // >
          //   <DialogTitle className={classes.modalCloseIcon}>
          //     <span onClick={handleCloseStartModal}>
          //       <ModalCloseIconBlack />
          //     </span>
          //   </DialogTitle>
          //   <DialogContent>
          //     {/* LEFT SECTION */}
          //     <LeftSideInfluence
          //       setInfluenceData={setInfluenceData}
          //       influenceData={influenceData}
          //       influenceIdeas={influenceIdeas}
          //       setShowSnakbar={setShowSnakbar}
          //       setMsgType={setMsgType}
          //       setSnakbarMsg={setSnakbarMsg}
          //       showInfluence={props.showInfluence}
          //       setAddInfluenceTracking={setAddInfluenceTracking}
          //       setShowInfluence={props.setShowInfluence}
          //       setTextInput={setTextInput}
          //       textInput={textInput}
          //       handleCloseStartModal={handleCloseStartModal}
          //       getInfluenceList={props?.getInfluenceList}
          //       setTransformToOutcomeTracking={
          //         props?.setTransformToOutcomeTracking
          //       }
          //       transformToOutcomeTracking={props?.transformToOutcomeTracking}
          //       transformInInfluenceTracking={
          //         props?.transformInInfluenceTracking
          //       }
          //       setTransformInInfluenceTracking={
          //         props?.setTransformInInfluenceTracking
          //       }
          //       startDate={props.startDate}
          //       endDate={props.endDate}
          //     />
          //   </DialogContent>
          // </Dialog>
        )}
        {props.transformToOutcomeTracking &&
          influenceId == props.influenceItem?._id && (
            <Dialog
              disableEscapeKeyDown
              onClose={props?.setTransformToOutcomeTracking}
              open={true}
              className="dialogWrapper dialogWrapper-outcome"
            >
              <DialogTitle className={classes.modalCloseIcon}>
                <span onClick={handleCloseTransformModal}>
                  <ModalCloseIconBlack />
                </span>
              </DialogTitle>
              <DialogContent>
                {/* OUTCOME LEFT SECTION */}
                {/* <LeftSideOutCome
                outComeValue={outComeValue}
                setOutcomeValue={setOutcomeValue}
                outcomeIdeas={outcomeIdeas}
                setShowOutcome={props.setShowOutcome}
                showOutcome={props.showOutcome}
                setAddOutcomeTracking={setAddOutcomeTracking}
                setTextInput={setTextInput}
                textInput={textInput}
                handleCloseStartModal={handleCloseStartModal}
                getOutcomeList={props?.getOutcomeList}
                setTransformToInfluenceTracking={props?.setTransformToInfluenceTracking}
              /> */}
                <LeftTransformToOut
                  // setInfluenceData={setInfluenceData}
                  // influenceData={influenceData}
                  // influenceIdeas={influenceIdeas}
                  // showInfluence={showInfluence}
                  // setAddInfluenceTracking={setAddInfluenceTracking}
                  // setShowInfluence={setShowInfluence}
                  setTextInput={setTextInput}
                  textInput={textInput}
                  outComeValue={influenceData}
                  setTransformToInfluenceTracking={
                    props?.setTransformToOutcomeTracking
                  }
                // categoryId={categoryId}
                />
              </DialogContent>
            </Dialog>
          )}
        {props.transformInInfluenceTracking &&
          influenceId == props.influenceItem?._id && (
            <Dialog
              disableEscapeKeyDown
              onClose={props?.setTransformToOutcomeTracking}
              open={true}
              className="dialogWrapper dialogWrapper-outcome"
            >
              <DialogTitle className={classes.modalCloseIcon}>
                <span onClick={handleCloseTransformInInfluModal}>
                  <ModalCloseIconBlack />
                </span>
              </DialogTitle>
              <DialogContent>
                {/* OUTCOME LEFT SECTION */}
                {/* <LeftSideOutCome
                outComeValue={outComeValue}
                setOutcomeValue={setOutcomeValue}
                outcomeIdeas={outcomeIdeas}
                setShowOutcome={props.setShowOutcome}
                showOutcome={props.showOutcome}
                setAddOutcomeTracking={setAddOutcomeTracking}
                setTextInput={setTextInput}
                textInput={textInput}
                handleCloseStartModal={handleCloseStartModal}
                getOutcomeList={props?.getOutcomeList}
                setTransformToInfluenceTracking={props?.setTransformToInfluenceTracking}
              /> */}
                <LeftTransformInInflu
                  // setInfluenceData={setInfluenceData}
                  // influenceData={influenceData}
                  // influenceIdeas={influenceIdeas}
                  // showInfluence={showInfluence}
                  // setAddInfluenceTracking={setAddInfluenceTracking}
                  // setShowInfluence={setShowInfluence}
                  setTextInput={setTextInput}
                  textInput={textInput}
                  outComeValue={influenceData}
                  setTransformToInfluenceTracking={
                    props?.setTransformInInfluenceTracking
                  }
                // categoryId={props?.categoryId}
                />
              </DialogContent>
            </Dialog>
          )}
      </>
    </Grid>
  );
};
export default TrackingItemInfluence;
