import React from "react";
import { OutComeIconModal } from "../../interfaces/outcomeModal";
import { fixOutcomeIcon } from "../../utils/staticDataJson";
import palette from "../../theme/palette";
import {
  Divider,
  List,
  ListItem,
  Dialog,
  DialogTitle,
  SvgIcon,
  IconButton,
  DialogContent,
} from "@mui/material";
import ModalCloseIcon from "../../assets/images/icon/modalCloseIconBlack";

interface IconPickerDialogModal {
  openIcon: boolean;
  onClose: (value: OutComeIconModal) => void;
}

const IconPickerDialog = (props: IconPickerDialogModal) => {
  const { onClose, openIcon } = props;
  const handleListItemClick = (value: OutComeIconModal) => {
    console.log("value", value)
    onClose(value);
  };
  return (
    <Dialog open={openIcon} onClose={() => onClose({ id: -1, path: "" })}>
      <DialogTitle
        sx={{
          color: palette.primary.light,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        Choose an icon
        <IconButton onClick={() => onClose({ id: -1, path: "" })}>
          <ModalCloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Divider />
        {
          <List sx={{ pt: 0, display: "flex" }}>
            {fixOutcomeIcon?.map((icon: OutComeIconModal, index: number) => {
              return (
                <ListItem
                  button
                  onClick={() => handleListItemClick(icon)}
                  key={index}
                >
                  <div>
                    <SvgIcon fontSize="large">
                      <path d={icon.path} />
                    </SvgIcon>
                  </div>
                </ListItem>
              );
            })}
          </List>
        }
      </DialogContent>
    </Dialog>
  );
};
export default IconPickerDialog;
