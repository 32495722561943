import React, { useState, useEffect } from 'react'
import { Box, Button, Checkbox, CircularProgress, Typography } from "@mui/material";
import { userService } from '../../../services/auth';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import { styled } from '@mui/system';
import { makeStyles } from "@material-ui/core";
import moment from 'moment';
//icon
import Calendar from '../../../assets/images/icon/Calendar';
import CalendarWhite from '../../../assets/images/icon/CalendarWhite';
import LeftGreenArrow from "../../../assets/images/icon/LeftGreenArrow";
import RightGreenArrow from "../../../assets/images/icon/RightGreenArrow";
import SnackBarComp from "../../../components/snackBar";


const useStyles = makeStyles((theme) => ({
    weekDayBox: {
        "& p": {
            fontSize: "18px",
            lineHeight: "36px",
            color: "#B7BDB9",
            background: "#EBF2F1",
            border: "1px solid #DCE6E0",
            width: "36px",
            height: "36px",
            textAlign: "center",
            cursor: "pointer",
            "&.active": {
                backgroundColor: "#11734A",
                color: "#F7F9FA",
                border: "1px solid #11734A",
            },
        },
    },
}));

export default function QuestionPage() {

    const [selectesReflactiveId, setSelectesReflactiveId] = useState("");
    const [reflactiveQuestions, setReflactiveQuestions] = useState([]);
    const [dayCount, setDayCount] = useState(0);
    const [targetDays, setTargetDays] = useState([])
    const [activeQuestionData, setActiveQuestionData] = useState({});
    const [isLoading, setIsLoading] = useState(false)
    const [spacificDay, setSpacificDay] = useState(false);
    const [goleData, setGoleData] = useState([])
    const [reflactiveGoleData, setReflactiveGoleData] = useState([])
    const [isAPiCall, setIsAPiCall] = useState(false)
    const classes = useStyles();

    // snakbar
    const [showSnakbar, setShowSnakbar] = useState(false);
    const [snakbarMsg, setSnakbarMsg] = useState("");
    const [msgType, setMsgType] = useState("");

    const MainWrapper = styled("div")(({ theme }) => ({
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100%",
        background: "#fff",
        padding: "60px",
        paddingBottom: "40px",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
        [theme.breakpoints.down("lg")]: {
            width: "100%",
            padding: "20px",
        },
    }));

    const Container = styled("div")(({ theme }) => ({
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    }));

    const CenterContainer = styled("div")(({ theme }) => ({
        display: "flex",
        marginTop: "40px",

        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    }));
    const LeftContent = styled("div")(({ theme }) => ({
        display: "flex",
        flexDirection: "column",
        height: "80vh",
        width: "500px"
    }));
    const CenterThreeContent = styled("div")(({ theme }) => ({
        marginTop: "32px",
        display: "flex",
        flexDirection: "column",
        gap: "12px"
    }));
    const ContentOne = styled("div")(({ theme }) => ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "15px",
        background: "#F7F9FA",
        padding: "12px",
        paddingLeft: "19px",
        borderRadius: "8px",
        width: "33.5vw",
        margin: "0px!important"
    }));
    const ContentTwo = styled("div")(({ theme }) => ({
        display: "flex",
        alignItems: "center",
        // justifyContent: "center",   
        gap: "15px",
        background: "#F7F9FA",
        padding: "12px",
        paddingLeft: "19px",
        borderRadius: "8px",
        width: "100%",

    }));
    const RightContent = styled("div")(({ theme }) => ({
        marginLeft: "60px"
    }));

    const RightSecondContent = styled("div")(({ theme }) => ({
        marginTop: "40px"
    }));
    const WeekContainer = styled(Box)(({ theme }) => ({
        display: "flex",
        flexWrap: "wrap",
        marginTop: "40px",
        alignItems: "center",
        justifyContent: "space-between",
        width: "70%",
        [theme.breakpoints.down("lg")]: {
            gap: "10px"
        }
    }));
    const WeekContainerOne = styled(Box)(({ theme }) => ({
        display: "flex",
        flexDirection: "column",
    }));
    const WeekContainertwo = styled(Box)(({ theme }) => ({
        display: "flex",
        flexDirection: "column",
        height: "112px",

    }));
    const CustomCheckBox = styled(Checkbox)(({ theme }) => ({
        color: "#DCE6E0",
        borderRadius: "4px",

        "&.Mui-checked": {
            color: "#11734A",
            borderRadius: "4px",
        },
    }));

    const SaveGoalBtn = styled(Button)(({ theme }) => ({
        [theme.breakpoints.down("xl")]: {
            marginBottom: "0px!important",
        },
        [theme.breakpoints.up("xl")]: {
            marginBottom: "0px!important",
        },
    }));


    useEffect(() => {
        const token = localStorage.getItem("userDetails")
        userService.getReflactiveQuestion(token).then((res) => {
            setReflactiveQuestions(res?.data)
            setSelectesReflactiveId(res?.data[0]?.reflectiveId)
        }).catch((err) => {
            setShowSnakbar(true);
            setMsgType("error");
            setSnakbarMsg("Somthing Went wrong");
        })
    }, [])

    useEffect(() => {
        const token = localStorage.getItem("userDetails");
        if (selectesReflactiveId || isAPiCall) {
            setIsLoading(true)
            userService.reflectiveGoal(selectesReflactiveId, token).then((data) => {
                setActiveQuestionData(data?.reflective)
                setReflactiveGoleData(data?.data[0])
                setDayCount(data?.data?.targetDaysCount ? data?.data?.targetDaysCount : 0)
                setTargetDays(data?.data?.targetDays ? data?.data?.targetDays : [])

            }).catch((error) => {
                setShowSnakbar(true);
                setMsgType("error");
                setSnakbarMsg(error.response.data.description);
            }).finally(() => {
                setIsLoading(false)
            })
        }
    }, [selectesReflactiveId, isAPiCall])

    useEffect(() => {
        const selectedIdData = JSON.parse(localStorage.getItem("selectedGoleID"))
        const selectedInfluenceType = localStorage.getItem("selectedInfluenceType")
        const token = localStorage.getItem("userDetails")
        let body;
        if (selectedIdData?.isOutcome) {
            body = {
                from: moment().subtract(1, 'months').startOf('month').format('MM-DD-YYYY'),
                to: moment().subtract(1, 'months').endOf('month').format('MM-DD-YYYY'),
                outcomeId: selectedIdData?.outcomeId
            }
            userService
                .outComeGoals(body, token)
                .then((data) => {
                    setGoleData(data?.data[0])
                    //   setShowSnakbar(true);
                    //   setMsgType("error");
                    //   setSnakbarMsg("Successfull");
                })
                .catch((error) => {
                    console.log("error", error)
                    //   setShowSnakbar(true);
                    //   setMsgType("error");
                    //   setSnakbarMsg("Somthing Went wrong");
                });
        } else {

            body = {
                from: moment().subtract(1, 'months').startOf('month').format('MM-DD-YYYY'),
                to: moment().subtract(1, 'months').endOf('month').format('MM-DD-YYYY'),
                influenceId: selectedIdData?.influenceId,
                type: selectedInfluenceType
            }

            userService
                .influenceGoal(body, token)
                .then((data) => {
                    setGoleData(data?.data[0])
                    //   setShowSnakbar(true);
                    //   setMsgType("error");
                    //   setSnakbarMsg("Successfull");
                })
                .catch((error) => {
                    console.log("outcome Data Error");
                    //   setShowSnakbar(true);
                    //   setMsgType("error");
                    //   setSnakbarMsg("Somthing Went wrong");
                });
        }
    }, [])

    useEffect(() => {
        if (reflactiveGoleData?.targetDays?.length !== 0) {
            setSpacificDay(true)
        }
        if (reflactiveGoleData?.length !== 0) {
            setDayCount(reflactiveGoleData?.targetDaysCount ? reflactiveGoleData?.targetDaysCount : 0)
            setTargetDays(reflactiveGoleData?.targetDays ? reflactiveGoleData?.targetDays : [])
        }
    }, [reflactiveGoleData])



    const onClickHandler = () => {
        let body = {
            target: goleData?.target,
            weeklyReport: goleData?.weeklyReport,
            reflectiveId: selectesReflactiveId,
            targetDaysCount: dayCount,
            targetDays: targetDays
        }
        const token = localStorage.getItem("userDetails");
        if (reflactiveGoleData?.length === 0 || reflactiveGoleData === undefined) {
            userService.createReflactiveQuestionGole(body, token).then((res) => {
            }).catch((error) => {
                setShowSnakbar(true);
                setMsgType("error");
                setSnakbarMsg(error.response.data.description);
            })
        } else {
            body = {
                ...body,
                reflectiveGoalId: reflactiveGoleData?.reflectiveGoalId
            }
            delete body.reflectiveId
            userService.updateReflactiveGole(body, token).then((res) => {
                setIsAPiCall(true)
            }).catch((error) => {
                setShowSnakbar(true);
                setMsgType("error");
                setSnakbarMsg(error.response.data.description);
            })
        }

    }

    const selectDays = (index) => {
        if (index === 0) {
            if (targetDays?.includes("MONDAY")) {
                const updatedArray = targetDays.filter((ele) => {
                    return ele !== "MONDAY"
                })
                setTargetDays(updatedArray)
            } else {
                setTargetDays([...targetDays, "MONDAY"])
            }
        } else if (index === 1) {
            if (targetDays?.includes("TUESDAY")) {
                const updatedArray = targetDays.filter((ele) => {
                    return ele !== "TUESDAY"
                })
                setTargetDays(updatedArray)
            } else {
                setTargetDays([...targetDays, "TUESDAY"])
            }
        } else if (index === 2) {
            if (targetDays?.includes("WEDNESDAY")) {
                const updatedArray = targetDays.filter((ele) => {
                    return ele !== "WEDNESDAY"
                })
                setTargetDays(updatedArray)
            } else {
                setTargetDays([...targetDays, "WEDNESDAY"])
            }
        } else if (index === 3) {
            if (targetDays?.includes("THURSDAY")) {
                const updatedArray = targetDays.filter((ele) => {
                    return ele !== "THURSDAY"
                })
                setTargetDays(updatedArray)
            } else {
                setTargetDays([...targetDays, "THURSDAY"])
            }
        } else if (index === 4) {
            if (targetDays?.includes("FRIDAY")) {
                const updatedArray = targetDays.filter((ele) => {
                    return ele !== "FRIDAY"
                })
                setTargetDays(updatedArray)
            } else {
                setTargetDays([...targetDays, "FRIDAY"])
            }
        } else if (index === 5) {
            if (targetDays?.includes("SATURDAY")) {
                const updatedArray = targetDays.filter((ele) => {
                    return ele !== "SATURDAY"
                })
                setTargetDays(updatedArray)
            } else {
                setTargetDays([...targetDays, "SATURDAY"])
            }
        } else if (index === 6) {
            if (targetDays?.includes("SUNDAY")) {
                const updatedArray = targetDays.filter((ele) => {
                    return ele !== "SUNDAY"
                })
                setTargetDays(updatedArray)
            } else {
                setTargetDays([...targetDays, "SUNDAY"])
            }
        }
    }

    const applayClassName = (index) => {
        if (index === 0) {
            if (targetDays?.includes("MONDAY")) {
                return "active"
            } else {
                return ""
            }
        } else if (index === 1) {
            if (targetDays?.includes("TUESDAY")) {
                return "active"
            } else {
                return ""
            }
        } else if (index === 2) {
            if (targetDays?.includes("WEDNESDAY")) {
                return "active"
            } else {
                return ""
            }
        } else if (index === 3) {
            if (targetDays?.includes("THURSDAY")) {
                return "active"
            } else {
                return ""
            }
        } else if (index === 4) {
            if (targetDays?.includes("FRIDAY")) {
                return "active"
            } else {
                return ""
            }
        } else if (index === 5) {
            if (targetDays?.includes("SATURDAY")) {
                return "active"
            } else {
                return ""
            }
        } else if (index === 6) {
            if (targetDays?.includes("SUNDAY")) {
                return "active"
            } else {
                return ""
            }
        }
    }

    const handleCloseSnakbar = () => {
        setShowSnakbar(false);
    };

    return (
        <>
            <MainWrapper>
                <SnackBarComp
                    showSnakbar={showSnakbar}
                    handleCloseSnakbar={handleCloseSnakbar}
                    snakbarMsg={snakbarMsg}
                    type={msgType ? msgType : "info"}
                />
                <Container>
                    <Header />
                    <div>
                        <CenterContainer>
                            <LeftContent>
                                <Typography variant="subtitle2" style={{ color: "#3E4240", width: "26vw" }}>
                                    Select a Qualitative Reflection you want to schedule
                                </Typography>
                                <CenterThreeContent>
                                    {/* <ContentTwo sx={{ background: "#11734A" }}>
                                    <Calendar color="#FFFFFF" />
                                    <Typography variant='h5' style={{ color: "#FFFFFF", marginBottom: "0px" }}>What are three good things that happened today?</Typography>
                                </ContentTwo> */}
                                    {
                                        reflactiveQuestions?.map((questionData) => {
                                            const { question, _id, reflectiveId } = questionData
                                            return (
                                                <ContentTwo key={_id} onClick={() => { setSelectesReflactiveId(reflectiveId) }} sx={{ background: reflectiveId === selectesReflactiveId ? "#11734A" : "#F7F9FA", cursor: 'pointer' }}>
                                                    {
                                                        reflectiveId === selectesReflactiveId ? <CalendarWhite /> : <Calendar />
                                                    }
                                                    <Typography variant='h5' style={{ color: reflectiveId === selectesReflactiveId ? "#FFFFFF" : '#3E4240', marginBottom: "0px" }}>{question}</Typography>
                                                </ContentTwo>
                                            )
                                        })
                                    }
                                </CenterThreeContent>
                            </LeftContent>
                            <RightContent>
                                <Typography variant='subtitle2' style={{ color: "#3E4240", width: "45vw" }}>Schedule the reflective question: {activeQuestionData?.question}</Typography>
                                <RightSecondContent>
                                    {
                                        isLoading ? <div style={{ width: '100%', height: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <CircularProgress color="success" />
                                        </div> : <WeekContainer>
                                            <WeekContainerOne>
                                                <Typography variant="caption">
                                                    Frequency of tracking this question
                                                </Typography>
                                                <div style={{ display: "flex", gap: "11.66px" }}>
                                                    {/* <div> */}
                                                    <Typography
                                                        variant="subtitle2"
                                                        sx={{ color: "#11734A", marginTop: "20px" }}
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                gap: "12px",
                                                            }}
                                                        >
                                                            <div
                                                                onClick={() => {
                                                                    if (dayCount !== 0) {
                                                                        setDayCount(prev => prev - 1)
                                                                    }
                                                                }
                                                                }
                                                            >
                                                                <LeftGreenArrow />
                                                            </div>
                                                            {dayCount}
                                                            <div
                                                                onClick={() => { setDayCount(prev => prev + 1) }}
                                                            >
                                                                <RightGreenArrow />
                                                            </div>
                                                        </div>
                                                    </Typography>
                                                    {/* </div> */}
                                                    <Typography sx={{ marginTop: "20px" }} variant="h5">
                                                        days of a week
                                                    </Typography>
                                                </div>
                                            </WeekContainerOne>

                                            <WeekContainertwo>
                                                <div>
                                                    <CustomCheckBox checked={spacificDay} onChange={(e) => { setSpacificDay(e.target.checked) }} />
                                                    <Typography variant="caption">Only specific days</Typography>
                                                </div>
                                                {
                                                    spacificDay && <Box
                                                        display="flex"
                                                        alignItems="center"
                                                        className={classes.weekDayBox}
                                                    >
                                                        {
                                                            ['M', 'T', 'W', 'T', 'F', 'S', 'S'].map((day, index) => {
                                                                return <p
                                                                    className={applayClassName(index)}
                                                                    key={index}
                                                                    onClick={() => selectDays(index)}
                                                                >
                                                                    {day}
                                                                </p>
                                                            })
                                                        }
                                                    </Box>
                                                }

                                            </WeekContainertwo>
                                        </WeekContainer>
                                    }

                                </RightSecondContent>
                                {
                                    !isLoading && <SaveGoalBtn
                                        type="submit"
                                        variant="contained"
                                        className="button-after outcomeButton"
                                        sx={{ mt: "39px", width: "240px !important" }}
                                        disabled={dayCount === 0 || targetDays?.length === 0}
                                        onClick={onClickHandler}
                                    >
                                        Save goal
                                    </SaveGoalBtn>
                                }

                            </RightContent>
                        </CenterContainer>
                    </div>
                </Container>

            </MainWrapper >
            <Footer />
        </>
    )
}
