import { makeStyles } from "@material-ui/core";
import { Grid, MenuItem, styled, TextField, Typography } from "@mui/material";
import React, { useState } from "react";

import palette from "../../theme/palette";

const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "43px",
  flexWrap: "wrap",
  [theme.breakpoints.down("md")]: {
    marginBottom: "20px",
  },
}));

const useStyles = makeStyles((theme) => ({
  progressTitle: {
    color: palette.primary.light,
    lineHeight: "43.2px!important",
  },
}));

const ProgressTitle = () => {
  const classes = useStyles();
  const [week, setWeek] = useState("This Week");

  const handleChangeWeek = (e: any) => {
    setWeek(e.target.value);
  };

  return (
    <RootStyle>
      <Typography variant="h1" className={classes.progressTitle}>
        Your Progress
      </Typography>
      <Grid display="flex" alignItems="center" flexWrap="wrap" marginTop="3px">
        <TextField
          className="fillTextInput weekSelect"
          fullWidth
          select
          value={week}
          onChange={(e) => handleChangeWeek(e)}
        >
          <MenuItem value={"Last Week"}>Last Week</MenuItem>
          <MenuItem value={"This Week"}>This Week</MenuItem>
          <MenuItem value={"Next Week"}>Next Week</MenuItem>
        </TextField>
      </Grid>
    </RootStyle>
  );
};

export default ProgressTitle;
