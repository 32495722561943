import axios from "axios";
import { baseEndpoints } from "./config";

const headerToken = localStorage.getItem("userDetails");

const getPlansList = async (token: string, body = {}) => {
  const { data } = await axios
    .post(
      `${baseEndpoints.categorySuggestion}/get-plans`,
      body,
      {
        headers: {
          "x-access-token": token ? token : headerToken,
        },
      }
    )
    .then((response) => {
      return response;
    });
  return data;
};

const setSelectedPlan = async (outcomeValue: any, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.categorySuggestion}/select-plans`, outcomeValue, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const getPlanById = async (outcomeValue: any, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.categorySuggestion}/get-plan-by-id`, outcomeValue, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};



export const onboardingPlansService = {
  getPlansList,
  setSelectedPlan,
  getPlanById
};
