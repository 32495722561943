/*eslint array-callback-return: "off"*/
import { Box, Typography } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import palette from "../../theme/palette";
import { styled } from "@mui/material/styles";
import shape from "../../theme/shape";
import { UserTypeContext } from "../../context/userStatus";
import { reflectiveQueService } from "../../services/reflective-questions";
import { makeStyles } from "@material-ui/core";

const RootStyle = styled("div")(({ theme }) => ({
  padding: "60px 0",
  [theme.breakpoints.down("lg")]: {
    padding: "50px 0",
  },
  [theme.breakpoints.down("md")]: {
    padding: "40px 0",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "20px 0",
    marginBottom: "20px",
  },
}));

const useStyles = makeStyles((theme) => ({
  recommText: {
    backgroundColor: palette.common.white,
    borderRadius: shape.borderRadius,
    fontSize: "16px",
    lineHeight: "36px",
    color: palette.primary.light,
    padding: "12px 16px",
    cursor: "pointer",
    marginBottom: "12px",
    wordBreak: "break-word",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
      lineHeight: "20px",
    },
  },
  infoWarning: {
    fontSize: "12px",
    lineHeight: "12px",
    fontWeight: 400,
    color: "#9BA19D",
    textAlign: "center",
    marginTop: "8pxS",
  },
  recommTitle: {
    marginBottom: "46px",
    [theme.breakpoints.down("md")]: {
      marginBottom: "36px",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "20px",
    },
  },
}));

interface RecommendedListModal {
  setQuestionData: (questionData: string) => void;
  setReflectiveIdeas: (reflectiveIdeas: any) => void;
}

const RecommendedList = (props: RecommendedListModal) => {
  const classes = useStyles();
  const [refelctiveSuggestionList, setRefelctiveSuggestionList] = useState([]);
  const { state: outcomeState } = useContext(UserTypeContext);
  useEffect(() => {
    reflectiveQueService
      .getRefelectiveSuggestion(outcomeState.token)
      .then((res: any) => {
        setRefelctiveSuggestionList(res?.data);
        props.setReflectiveIdeas(res?.ideas);
      })
      .catch((error: any) => {
        console.log("Outcome suggestion error", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RootStyle>
      <Box display="flex" alignItems="center" className={classes.recommTitle}>
        <Typography variant="h3" component="h3">
          Get started with our recommended Qualitative Reflection
        </Typography>
      </Box>
      <Box>
        {refelctiveSuggestionList.length > 0 &&
          refelctiveSuggestionList?.map((items: any, index: number) => {
            let flag = true;
            if (outcomeState?.reflectiveList?.length > 0)
              for (let outObj of outcomeState?.reflectiveList) {
                if (
                  outObj.question.toLowerCase() === items.question.toLowerCase()
                ) {
                  flag = false;
                  break;
                }
              }
            if (flag) {
              return (
                <div
                  key={index}
                  className={classes.recommText}
                  onClick={() => props.setQuestionData(items)}
                >
                  {items.question}
                </div>
              );
            }
          })}
      </Box>
      <p className={classes.infoWarning}>
        *You can add more question after the 30 days of baseline tracking
      </p>
    </RootStyle>
  );
};
export default RecommendedList;
