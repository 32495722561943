import React, { useState, useContext, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { pxToRem } from "../../theme/typography";
import GoogleIcon from "../../assets/images/icon/google-icon";
import FacebookIcon from "../../assets/images/icon/facebook-icon";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import palette from "../../theme/palette";
import { facebookProvider, googleProvider } from "../../config/authMethods";
import { userService } from "../../services/auth";
import SnackBarComp from "../../components/snackBar";
import { getAuth, sendPasswordResetEmail, signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../config/firebase";
import { ButtonLoader } from "../../components/buttonLoader";
import { UserTypeContext } from "../../context/userStatus";
import {
  setUserId,
  setUserLevel,
  setUserStatus,
  setUserToken,
} from "../../store/actions/userTypeAction";
import FullpageLoader from "../../components/fullPageLoader";
import { UserLoginModal } from "../../interfaces/outcomeModal";

const validationSchema = yup.object({
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
});
const newPasswordScreen = true
const RootStyle = styled("div")(({ theme }) => ({
  padding: "0 80px",
  [theme.breakpoints.up("xl")]: {
    padding: "60px",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "40px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "30px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "20px",
  },
}));
const RootStyle2 = styled("div")(({ theme }) => ({
  padding: "0",

}));
const useStyles = makeStyles((theme) => ({
  registrationHeading: {
    color: palette.primary.light,
    marginBottom: "32px!important",
    [theme.breakpoints.down("lg")]: {
      marginBottom: "20px!important",
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: "10px!important",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "5px!important",
    },
  },
  registrationSubHeading: {
    color: "#190D1A",
    marginBottom: "62px!important",

    fontSize: `${pxToRem(16)} !important`,
    [theme.breakpoints.down("lg")]: {
      marginBottom: "50px!important",
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: "40px!important",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "30px!important",
    },
  },
  registrationSubHeading2: {
    color: "#B4B0B4 !important",
    marginBottom: "62px!important",

    fontSize: `${pxToRem(16)} !important`,
    [theme.breakpoints.down("lg")]: {
      marginBottom: "50px!important",
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: "40px!important",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "30px!important",
    },
  },
  socialLoginButton: {
    marginTop: pxToRem(24),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  alreadyAccountText: {
    marginTop: pxToRem(1),
    color: "#9BA19D",
    fontSize: pxToRem(16),
    lineHeight: pxToRem(1),
    fontWeight: 400,
    textAlign: "center",
  },
  forgetPasswordText: {
    color: "#9BA19D",
    textAlign: "right",
    fontSize: pxToRem(14),
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "145%", /* 20.3px */
    letterSpacing: "-0.14px",
    cursor: "pointer"
  },
  alreadyAccountTextSpan: {
    color: "#11734A",
    cursor: "pointer",
    textDecoration: "underline"
  },
  textInputMobile: {
    "& .MuiOutlinedInput-root": {
      border: "1px solid #B8E29E",
      '& .MuiInputBase-input': {
        '&::placeholder': {
          fontSize: `${pxToRem(14)} !important`,
          color: '#3E4240',
          opacity: 1, /* Firefox */
          lineHeight: '100%',
          fontWeight: 400,
        }
      },
    },

  },
  welcomeTitle: {
    fontSize: "26px !important",
    lineHeight: "120% !important"
  },
  welcomeSubTitle: {
    fontSize: "16px !important",
    lineHeight: "100% !important"
  },
  welcomeSubTitle2: {
    fontSize: "16px !important",
    lineHeight: "100% !important",
    color: "#B4B0B4 !important",
    marginBottom: "20px!important",
    textAlign: "center"
  },
}));

const ariaLabel = { "aria-label": "description" };

const RightPartForgetPassword = ({isMobile = false}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [showSnakbar, setShowSnakbar] = useState(false);
  const [snakbarMsg, setSnakbarMsg] = useState("");
  const [msgType, setMsgType] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [showLoaderPage, setShowLoaderPage] = useState(false);
  const { dispatch: userTypeDispatch } = useContext(UserTypeContext);
  const [viewSentLink, setViewSentLink] = useState(false)
  useEffect(() => {
    userTypeDispatch(setUserId(undefined));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // FIREBASE AUTHENTICATION
  const emailAuth = async (email: string, password: string) => {
    return signInWithEmailAndPassword(auth, email, password)
      .then((result) => {
        return result;
      })
      .catch((err) => {
        setShowLoader(false);
        setShowSnakbar(true);
        setMsgType("error");
        if (err.code === "auth/user-not-found") {
          setSnakbarMsg("User not found");
        } else if (err.code === "auth/wrong-password") {
          setSnakbarMsg("Please enter right credential");
        } else {
          setSnakbarMsg(err.message);
        }
        return err;
      });
  };
  const firebaseLogin = async (email: string) => {
    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email, {
        // dynamicLinkDomain: "localhost",
        url: window.location.origin,
      });
      setShowLoader(false);
      setShowSnakbar(true)
      setMsgType("success")
      setSnakbarMsg("Reset password mail sent successfully");
      formik.resetForm()
      if (newPasswordScreen) {
        setViewSentLink(true)
      }
    } catch (error) {
      if (error && error.message && error.message.includes("auth/user-not-found")) {
        setShowSnakbar(true)
        setMsgType("error")
        setSnakbarMsg("User not found");
      }
      console.log("error", error, error.message)
      setShowLoader(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setShowLoader(true);
      firebaseLogin(values.email);
    },
  });
  const handleCloseSnakbar = () => {
    setShowSnakbar(false);
  };
  const Root = isMobile ? RootStyle2 : RootStyle
  return (
    <Root className="login-right-part">
      {showLoaderPage && <FullpageLoader />}
      <SnackBarComp
        showSnakbar={showSnakbar}
        handleCloseSnakbar={handleCloseSnakbar}
        snakbarMsg={snakbarMsg}
        type={msgType ? msgType : "info"}
      />
      {viewSentLink ? (
        <>
        {isMobile ? (
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"} flexDirection={"column"} width={"100%"} padding={"70px 0 60px"} gap={"8px"}>

          <Typography component={"span"} variant="h1" className={classes.welcomeTitle} fontWeight={"700 !important"} sx={{
            marginBottom: "32px"
          }} color={palette.primary.light}>Password reset email sent</Typography>
          <Typography component={"span"} variant="h1" className={classes.welcomeSubTitle2} fontWeight={"700 !important"} color={palette.primary.light}>We’ve sent a link to reset your password. Your reset link expires in 8 hours.</Typography>
          <Typography component={"span"} variant="h1" className={classes.welcomeSubTitle2} fontWeight={"700 !important"} color={palette.primary.light}>Didn't receive an email? Check your junk folder or request another link.</Typography>
        </Box>
      ) : (
       <>
        <Typography
            variant="h1"
            component="h1"
            className={classes.registrationHeading}
            sx={{
              textAlign: "center"
            }}
          >
            Password reset email sent
          </Typography>
          <Typography
            variant="h3"
            component="h1"
            className={classes.registrationSubHeading2}
            sx={{
              fontWeight: '400 !important',
              marginBottom: "20px !important",
              textAlign: "center"
            }}
          >
            We’ve sent a link to reset your password. Your reset link expires in 8 hours.
          </Typography>
          <Typography
            variant="h3"
            component="h1"
            className={classes.registrationSubHeading2}
            sx={{
              fontWeight: '400 !important',
              marginBottom: "20px !important",
              textAlign: "center"
            }}
          >
            Didn't receive an email? Check your junk folder or request another link.
          </Typography>
       </>
      )}
         
          <p className={classes.alreadyAccountText}>
            Back to {" "}
            <span
              className={classes.alreadyAccountTextSpan}
              onClick={() => setViewSentLink(false)}
            >
              password reset
            </span>
          </p>
        </>
      ) : (
        <>
        {isMobile ? (
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"} flexDirection={"column"} width={"100%"} padding={"70px 0 60px"} gap={"8px"}>

          <Typography component={"span"} variant="h1" className={classes.welcomeTitle} fontWeight={"700 !important"} color={palette.primary.light}>Recover password</Typography>
        </Box>
      ) : (
        <Typography
            variant="h1"
            component="h1"
            className={classes.registrationHeading}
          >
            Recover password
          </Typography>
      )}
         
          <Typography
            variant="h3"
            component="h1"
            className={classes.registrationSubHeading}
            sx={{
              fontWeight: '500 !important',
            }}
          >
            We’ll send you link to reset your password
          </Typography>
          <Grid>
            <form onSubmit={formik.handleSubmit}>
              <TextField
                placeholder="Email"
                variant="outlined"
                fullWidth
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                className={isMobile ? classes.textInputMobile : "newInputBox"}
                inputProps={ariaLabel}
              />
              {!showLoader ? (
                <Button
                  type="submit"
                  variant="contained"
                  className={isMobile ? "button-after" :"new-button"}
                  fullWidth
                  sx={{ mt: pxToRem(24), mb: pxToRem(30) }}
                  disabled={!(formik.isValid && formik.dirty)}
                >
                  Reset Password
                </Button>
              ) : (
                <Button
                  variant="contained"
                  className={isMobile ? "button-after" :"new-button"}
                  fullWidth
                  sx={{ mt: pxToRem(24), mb: pxToRem(57) }}
                >
                  <ButtonLoader />
                </Button>
              )}
            </form>
            <p className={classes.alreadyAccountText}>
              Back to {" "}
              <span
                className={classes.alreadyAccountTextSpan}
                onClick={() => navigate("/login")}
              >
                Log in
              </span>
            </p>
          </Grid>
        </>
      )}

    </Root>
  );
};
export default RightPartForgetPassword;
