import React from "react";
import { Box, SvgIcon } from "@mui/material";
import palette from "../../theme/palette";
import { makeStyles } from "@material-ui/core";
import shape from "../../theme/shape";
import InfluenceTickIcon from "../../assets/images/icon/influenceTickIcon";
import { useDrag } from "react-dnd";

const useStyles = makeStyles((theme) => ({
  recommText: {
    backgroundColor: palette.common.white,
    borderRadius: shape.borderRadius,
    fontSize: "16px",
    lineHeight: "36px",
    color: palette.primary.light,
    padding: "12px 16px",
    cursor: "pointer",
    marginBottom: "12px",
    wordBreak: "break-word",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
      lineHeight: "20px",
    },
  },
  colorCode: {
    width: "36px",
    height: "36px",
    padding: "8px",
    borderRadius: shape.borderRadiusSm,
    marginRight: "12px",
    [theme.breakpoints.down("sm")]: {
      width: "25px",
      height: "25px",
      padding: "3px",
    },
  },
  influenceIcon: {
    verticalAlign: "text-top",
    marginRight: "12px",
    display: "inline-flex",
  },
}));

interface OutcomeListItemModel {
  title: string;
  outcomeItem: any;
}

const OutcomeListItem = (props: OutcomeListItemModel) => {
  const classes = useStyles();

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: props.title,
      item: props.outcomeItem,
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [props.outcomeItem]
  );

  return (
    <Box
      className={classes.recommText}
      ref={drag}
      sx={{ opacity: isDragging ? 0.5 : 1 }}
    >
      {props.title === "The Outcomes" ? (
        <span
          className={classes.colorCode}
          style={{
            backgroundColor: props.outcomeItem?.options[0]?.colorCode,
          }}
        >
          <SvgIcon
            color="action"
            fontSize="small"
            sx={{ verticalAlign: "middle" }}
          >
            <path d={props.outcomeItem?.icon} fill={palette.common.white} />
          </SvgIcon>
        </span>
      ) : props.title === "The Influences" ? (
        <span className={classes.influenceIcon}>
          <InfluenceTickIcon />
        </span>
      ) : (
        <></>
      )}
      {props.title === "The Outcomes" || props.title === "The Influences"
        ? props.outcomeItem?.name
        : props.outcomeItem?.question}
    </Box>
  );
};

export default OutcomeListItem;
