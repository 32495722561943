import * as React from "react";

const InfoIcon = ({color = "#FF9B37", size = "24"}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
    >
      <circle cx="12" cy="12" r="11" stroke={color} strokeWidth="2" />
      <rect x="10.75" y="10.5" width="2.5" height="8" fill={color} />
      <circle cx="12" cy="7" r="1.5" fill={color} />
    </svg>
  );
};
export default InfoIcon;
