import React, { useContext, useEffect, useState } from "react";
import { Tab, Tabs, Box, Typography, Grid } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import palette from "../../theme/palette";
import SideBar from "../../components/SideBar";
import FullpageLoader from "../../components/fullPageLoader";
import AppSettings from "./appSettings";
import AccountSettings from "./accountSettings";
import useWidth from "../../utils/useWidth";
import { useNavigate, useSearchParams } from "react-router-dom";
import { trackingService } from "../../services/tracking";
import { UserTypeContext } from "../../context/userStatus";
import SnackBarComp from "../../components/snackBar";
import InfluenceclosePopup from "./DeleteAccountPopup";
import { addCategoryExpandAction, addCategoryJourneyAction, addInfluenceAction, addJourneyAction, addNotesAction, addNotesJourneyAction, addOutcomeAction, addRefelectiveAction, isPersonalMode } from "../../store/actions/userTypeAction";

const useStyles = makeStyles((theme) => ({
  settigsTitle: {
    color: "#3E4240",
    fontWeight: 700,
  },
  subHeading: {
    color: `${palette.secondary.contrast}!important`,
    fontSize: "16px",
    fontWeight: 400,
  },
  container: {
    // backgroundColor: "white",
    // width: "91%",
    borderLeft: "1px solid #DCE6E0",
    padding: "24px",
    backgroundColor: '#F7F9FA !important',
    height: 'calc(100vh - 10px)',
  },
  table: {
    marginTop: "50px",
  },
  main: {
    display: "flex",
  },
  activeTab: {
    background: "var(--color-new-main-shadow)",
    color: "var(--color-black) !important",
    // borderRadius: "8px",
  },
  conatinerSetting: {
    display: 'flex',
    width: '100%',
    gap: '4px',
    justifyContent: 'flex-start',
  },
  containerDiv: {
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '0px',
    width: '100%',
    background: '#FFFFFF',
    borderRadius: '20px',
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  settingHeader: {
    boxSizing: 'border-box',

    /* Auto layout */

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '24px',
    gap: '10px',
    width: '100%',
    background: '#FFFFFF',
    borderBottom: '1px solid #E1E1E1',

  },
  settingHeaderNoBorder: {
    boxSizing: 'border-box',

    /* Auto layout */

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '24px',
    gap: '10px',
    width: '100%',
    background: '#FFFFFF',
  },
  settingHeadertext: {
    // fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '28px !important',
    lineHeight: '100%',
    /* identical to box height, or 28px */


    color: '#000000 important',
  },
}));

function SettingsComp() {
  const classes = useStyles();
  const [showLoadingBtn, setShowLoadingBtn] = useState(false)
  const [selectedTab, setSelectedTab] = useState("password");
  const [showSnakbar, setShowSnakbar] = useState(false);
  const [snakbarMsg, setSnakbarMsg] = useState("");
  const [msgType, setMsgType] = useState("");
  const TABS = [
    {
      value: "App Settings",
      label: "App Settings",

      component: <AppSettings />,
    },
    {
      value: "Account Settings",
      label: "Account Settings",
      component: <AccountSettings />,
    },
  ];


  const [searchParams, setSearchParams] = useSearchParams();
  const [isExpandMode, setIsExpandMode] = useState(localStorage.getItem("isExpandMode") === "true");
  const [FaqData, setFaqData] = useState({})
  const { state: outcomeState } = useContext(UserTypeContext);

  const getFAQs = () => {
    trackingService.getFAQAllTitle(outcomeState.token, {}).then((data) => {
      let allObjects = {}
      for (let i = 0; i < data.data.length; i++) {
        let body = {
          "type": "APP",
          "titleId": data.data[i].titleId
        }
        // eslint-disable-next-line no-loop-func
        trackingService.getFAQs(outcomeState.token, body).then((faq) => {

          allObjects = {
            ...allObjects,
            [data.data[i].titleId]: faq.data
          }
          if (i === (data.data.length - 1)) {
            callToSetData()
          }
        })
      }
      const callToSetData = () => {
        setFaqData(allObjects)
      }
    })
  }



  useEffect(() => {
    if (searchParams.get("tab")) {
      setSelectedTab(searchParams.get("tab"));
    } else {
      setSelectedTab("password");
    }
  }, [searchParams]);

  useEffect(() => {
    getFAQs()
  }, [])

  const width = useWidth();
  const handleCloseSnakbar = () => {
    setShowSnakbar(false);
  };
  console.log("selectedTab", selectedTab);
  
  return (
    <div className={classes.main}>
      {showLoadingBtn && <FullpageLoader />}
      <SnackBarComp
        showSnakbar={showSnakbar}
        handleCloseSnakbar={handleCloseSnakbar}
        snakbarMsg={snakbarMsg}
        type={msgType ? msgType : "info"}
      />
      <Grid
        container
        columns={16}
        // height={"100vh"}
        sx={{ /* maxHeight: '100vh', */ overflow: "hidden", }}
        className="tracking_container"
      // style={{
      //   height: '100vh !important'
      // }}
      >
        <Grid
          item
          // xs={(isExpandMode) ? 0.73 :2.04}
          width={isExpandMode ? "90px" : "220px"}
          position={"relative"}
          sx={{ borderRight: "1px solid #DCE6E0", height: '100vh' }}
        >
          <SideBar isExpandMode={isExpandMode} setIsExpandMode={setIsExpandMode} />

        </Grid>
        <Grid
          item
          // xs={(isExpandMode) ? 15.27 :13.96}
          sx={{ flexDirection: "column", background: "transparent", maxHeight: '100vh',
            width: isExpandMode ?  "calc(100vw - 90px)"  :  "calc(100vw - 220px)"
           }}
        // className={classes.trackBodyGrid}
        >
          <div className={classes.conatinerSetting}>
            <Grid
              className={`${classes.container}`}
              style={{ width: "100%", display: "flex", overflowX: "auto" }}
            >
              <div className={classes.containerDiv} /* style={{ width: "100%", background: 'white' }} */>
                <div className={selectedTab === "import-export" ? classes.settingHeaderNoBorder : classes.settingHeader}>
                  <Typography variant="h4" style={{ fontWeight: 600 }} component="h4" className={classes.settingHeadertext}>
                    {selectedTab === "app-settings" ? "Reminders" : selectedTab === "import-export" ? "Import and export" : selectedTab === "password" ? "Change Password" : selectedTab === "feedback" ? "Feedback" : selectedTab === "faq" ? "FAQ" : selectedTab === "tutorial" ? "Profile settings" : ""}
                  </Typography>
                </div>
                {selectedTab === "app-settings" || selectedTab === "import-export" ? (
                  <AppSettings setShowSnakbar={setShowSnakbar} selectedTab={selectedTab}
                    setSnakbarMsg={setSnakbarMsg}
                    setMsgType={setMsgType} />
                ) : (
                  <AccountSettings selectedTab={selectedTab} FaqData={FaqData}
                    setShowSnakbar={setShowSnakbar}
                    setSnakbarMsg={setSnakbarMsg}
                    setMsgType={setMsgType} />
                )}
              </div>
            </Grid>
            <SettingSidebar />

          </div>
        </Grid>
      </Grid>




    </div>
  );
}

export default SettingsComp;


export const SettingSidebar = ({ fromProfile = false }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { state: outcomeState, dispatch: userTypeDispatch } = useContext(UserTypeContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState("password");
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false)
  const deleteAccount = () => {
    trackingService.deleteUserAccount(outcomeState.token).then((data) => {
      setVisibleDeleteModal(false)
      const ipAddress = localStorage.getItem("ipAddress")
      localStorage.clear();
      userTypeDispatch(addCategoryJourneyAction([]));
      userTypeDispatch(addNotesJourneyAction([]));
      userTypeDispatch(addOutcomeAction([]));
      userTypeDispatch(addInfluenceAction([]));
      userTypeDispatch(addRefelectiveAction([]));
      userTypeDispatch(addJourneyAction([] as any));
      userTypeDispatch(addNotesAction([] as any));
      userTypeDispatch(addCategoryExpandAction([] as any));
      userTypeDispatch(isPersonalMode(false))
      localStorage.setItem("ipAddress", ipAddress)
      navigate("/login")
    })
  }

  useEffect(() => {
    if (searchParams.get("tab")) {
      setSelectedTab(searchParams.get("tab"));
    } else {
      setSelectedTab(fromProfile ? "profile" : "password");
    }
  }, [searchParams]);
  return <>

    <div
      style={{
        color: "#11734A",
        width: "40%",
        // borderRadius: "8px",
        // background: "darkseagreen",
        height: "fit-content",
        zIndex: "99",
        // position: "fixed",
        right: "40px",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '0px',
        margin: '24px 20px 0 0',
        // width: 296px,
        // height: 240px,


        background: '#FFFFFF',
        borderRadius: '20px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%',
          padding: '16px',
          height: '48px',
          gap: '10px',
          borderTopLeftRadius: '20px',
          borderTopRightRadius: '20px',
          cursor: "pointer",
          color: "#000000",
          fontSize: '16px'
        }}
        className={selectedTab === "profile" && classes.activeTab}
        onClick={() => navigate("/profile")}
      >
        Profile
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%',
          padding: '16px',
          height: '48px',
          gap: '10px',
          cursor: "pointer",
          color: "#000000",
          fontSize: '16px'
        }}
        className={selectedTab === "password" && classes.activeTab}
        onClick={() => navigate("/settings?tab=password")}
      >
        Password
      </div>
      {/* <div
    style={{ padding: "18px", cursor: "pointer" }}
    className={selectedTab === "membership" && classes.activeTab}
    onClick={() => navigate("/settings?tab=membership")}
  >
    Membership
  </div> */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%',
          padding: '16px',
          height: '48px',
          gap: '10px',
          cursor: "pointer",
          color: "#000000",
          fontSize: '16px'
        }}
        className={selectedTab === "feedback" && classes.activeTab}
        onClick={() => navigate("/settings?tab=feedback")}
      >
        Feedback
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%',
          padding: '16px',
          height: '48px',
          gap: '10px',
          cursor: "pointer",
          color: "#000000",
          fontSize: '16px'
        }}
        className={selectedTab === "app-settings" && classes.activeTab}
        onClick={() => navigate("/settings?tab=app-settings")}
      >
        Reminders
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%',
          padding: '16px',
          height: '48px',
          gap: '10px',
          cursor: "pointer",
          color: "#000000",
          fontSize: '16px'
        }}
        className={selectedTab === "import-export" && classes.activeTab}
        onClick={() => navigate("/settings?tab=import-export")}
      >
        Import and export
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%',
          padding: '16px',
          height: '48px',
          gap: '10px',
          cursor: "pointer",
          color: "#000000",
          fontSize: '16px'
        }}
        className={selectedTab === "faq" && classes.activeTab}
        onClick={() => navigate("/settings?tab=faq")}
      >
        FAQs
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%',
          padding: '16px',
          height: '48px',
          gap: '10px',
          cursor: "pointer",
          color: "#000000",
          fontSize: '16px'
        }}
        className={selectedTab === "tutorial" && classes.activeTab}
        onClick={() => navigate("/settings?tab=tutorial")}
      >
        Tutorial
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%',
          padding: '16px',
          height: '48px',
          gap: '10px',
          cursor: "pointer", color: "red",
          fontSize: '16px'
        }}
        // className={selectedTab === "faq" && classes.activeTab}
        onClick={() => setVisibleDeleteModal(true)}
      >
        Delete account
      </div>
    </div>
    <InfluenceclosePopup
      open={visibleDeleteModal}
      setopenInfluenceclosePopup={setVisibleDeleteModal}
      handleCloseStartModal={deleteAccount}
    />
  </>
}