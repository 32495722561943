import { Grid } from "@mui/material";
import * as React from "react";
import palette from "../../theme/palette";
import LeftPartLogin from "./leftPartLogin";
import RightPartLogin from "./rightPartLogin";
import RightPartForgetPassword from "./rightPartForgetPassword";
import useWidth from "../../utils/useWidth";
import CommonMobileAuthLayout from "./mobile/commonView";

const ForgetPassword = () => {
  // localStorage.removeItem("userDetails");
  // localStorage.removeItem('userValues');
  // localStorage.removeItem('userId');
  // localStorage.removeItem('userDate');
  const width = useWidth()
  if (width < 768) {
    return <CommonMobileAuthLayout type="forget_password"/>
   }
  return (
    <Grid
      alignItems="center"
      container
      columns={16}
      sx={{ backgroundColor: palette.common.white }}
    >
      {/* START LEFT SECTION */}
      <Grid item xs={9.63} className="leftside-menu login-screen">
        <LeftPartLogin />
      </Grid>
      {/* END LEFT SECTION */}

      {/* START RIGHT SECTION */}
      <Grid item xs={6.37} className="rightside-menu">
        <RightPartForgetPassword isMobile={width < 768}/>
      </Grid>
      {/* END RIGHT SECTION */}
    </Grid>
  );
};
export default ForgetPassword;
