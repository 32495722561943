import React, { useContext, useEffect, useState } from "react";
import { Grid, styled } from "@mui/material";
import SideBar from "../../components/SideBar";
import InsightsTitle from "./insightsTitle";
import InsightsOutcomes from "./insightsOutcomes";
import { makeStyles } from "@material-ui/core";
import OutcomeInsights from "./outcomeInsights";
import clsx from "clsx";
import InfluenceInsights from "./influenceInsights";
import InfluenceOverTime from "./influenceOverTime";
import NoInsightsDataAvailable from "./noInsightsData";
import { InsightTypeContext } from "../../context/userInsights";
import { UserTypeContext } from "../../context/userStatus";
import { userService } from "../../services/auth";
import { insightsService } from "../../services/insightsService";
import { setInsightData, setLeftMenuData, setRightMenuData } from "../../store/actions/insightTypeAction";
import InsightLearnMore from "../../assets/images/icon/LearnMore";
import Correlations from "../../assets/images/icon/Correlations";
import CorrelationsInsights from "./CorrelationsInsights";
import { useNavigate } from "react-router-dom";

const RootStyle = styled("div")(({ theme }) => ({
  padding: "60px 40px 0px",

  [theme.breakpoints.down("lg")]: {
    padding: "40px 20px 0px",
  },
  [theme.breakpoints.down(1001)]: {
    padding: "20px",
  },
}));

const useStyles = makeStyles((theme) => ({
  progressInfluence: {
    [theme.breakpoints.down(1001)]: {
      maxWidth: "100%!important",
    },
  },
  insightsOutcomes: {
    // marginRight: "30px!important",
  },
  ratingGraph: {
    [theme.breakpoints.down(1001)]: {
      maxWidth: "100%!important",
      marginTop: "48px!important",
    },
  },
  tabBarGrids: {
    margin: '10px 0',
    borderBottom: '1px solid #DCE5E0',
    padding: '10px 0',
  },
  tabBarTab: {
    color: 'rgba(62, 66, 64, 0.5)',
    padding: '0px 10px',
    fill: 'rgba(62, 66, 64, 0.5)',
    display: "flex",
    alignItems: "center"
  },
  tabBarTabActive: {
    display: "flex",
    color: '#3E4240',
    fill: '#3E4240',
    padding: '0px 10px',
    alignItems: "center",
    fontWeight: 600
  },
  spanAroundSpace: {
    margin: '0px 6px'
  }
}));

const TabBar = ({ selectedTab, setSelectedTab }) => {
  const classes = useStyles();

  return (
    <Grid container direction="row" display="flex" alignItems="center" className={classes.tabBarGrids} columns={2} sx={{display: 'flex !important'}}>
      <Grid
        item
        className={selectedTab === "outcome" ? classes.tabBarTabActive : classes.tabBarTab}
        onClick={() => setSelectedTab('outcome')}>
        <InsightLearnMore /> <span className={classes.spanAroundSpace}>Outcome</span>
      </Grid>
      <Grid
        item
        className={selectedTab === "correlations" ? classes.tabBarTabActive : classes.tabBarTab}
        onClick={() => setSelectedTab('correlations')}
      >
        <Correlations />
        <span className={classes.spanAroundSpace}>Correlations</span>
      </Grid>
    </Grid>
  )
}

const InsightsView = (props) => {
  const { state: InsightState, dispatch: insightTypeDispatch } = useContext(InsightTypeContext);
  const { state: IUserTypeState, dispatch: userTypeDispatch } =
    useContext(UserTypeContext);
  let userValues = JSON.parse(localStorage.getItem("userValues"));
  const classes = useStyles();
  const navigate = useNavigate();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const outcomeId = params.get("id");
  const [selectedTime, handleChangeTime] = useState("This Week");
  const [isExpandMode, setIsExpandMode] = useState(localStorage.getItem("isExpandMode") === "true");
  const [selectedTab, setSelectedTab] = useState("outcome");
  const [selectOutcome, setSelectOutcome] = useState(outcomeId);
  const getInsightDetails = (limit = null) => {
    const userId: any = {
      uid: IUserTypeState?.userId ? IUserTypeState?.userId : userValues?.uid,
      "from": "10-08-2021",
      "to": "10-08-2023"
    };
    if(limit) {
      userId.limit = limit
    }
    const classesList = [
      "wellBeingCard",
      "energyCard",
      "senseCard",
      "improveCard",
      "stressCard"
    ]
    insightsService
      .getInsights(userId, IUserTypeState.token)
      .then((data: any) => {
        if(data?.data && data?.data.length) {
          data.data = data?.data.map(it => {
            const classNameGet = classesList[Math.floor(Math.random() * classesList.length)];
            return {
              ...it,
              className: classNameGet
            }
          })
        }
        insightTypeDispatch(setInsightData(data))
        if(!selectOutcome && data.data && data.data[0].outcomeId) {
          setSelectOutcome(data.data[0].outcomeId);
          navigate("/insights?id="+data.data[0].outcomeId);
        }
      })
      .catch((error) => {
        console.log("User profile Error", error);
      });
  }
  const getCorrelationMenu = () => {
    const userId = {
      uid: IUserTypeState?.userId ? IUserTypeState?.userId : userValues?.uid,
      "from": "10-08-2021",
      "to": "10-08-2023"
    };
    insightsService
      .getCorrelationMenu(userId, IUserTypeState.token)
      .then((data: any) => {
        insightTypeDispatch(setLeftMenuData(data?.leftMenu))
        insightTypeDispatch(setRightMenuData(data?.rightMenu))
      })
      .catch((error) => {
        console.log("User profile Error", error);
      });
  }
  useEffect(() => {
    if(selectedTab === "outcome" && !InsightState.insights.data?.length) {
      getInsightDetails();
    }
    if(selectedTab === "correlations" && !(InsightState.leftMenu.length || InsightState.rightMenu.length)) {
      getCorrelationMenu()
    } 
    
  }, [selectedTime, selectedTab])
  useEffect(() => {

  })
  return (
    <Grid
      container
      columns={16}
      height={"100vh"}
      className="tracking_container"
    >
      <Grid item sx={{ borderRight: "1px solid #DCE6E0" }} xs={isExpandMode ? 0.73 : 2.04}>
        <SideBar isExpandMode={isExpandMode} setIsExpandMode={setIsExpandMode} />
      </Grid>

      <Grid item className="track-body-grid" xs={isExpandMode ? 15.27 : 13.96}>
        <RootStyle className="track-body">
          {/* if data available */}
          <>
            <InsightsTitle handleChangeTime={handleChangeTime} />
            <TabBar selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
            {selectedTab === "outcome" ? (
              <>
                <InsightsOutcomes
                  setSelectOutcome={setSelectOutcome}
                  selectOutcome={selectOutcome}
                  outcomeData={InsightState.insights.data}
                />
                <Grid container  marginTop="28px">
                  <Grid
                    item
                    xs={12}
                    className={clsx(
                      classes.progressInfluence,
                      classes.insightsOutcomes
                    )}
                  >
                    <InfluenceInsights selectOutcome={selectOutcome} outcomeData={InsightState.insights.data} getInsightDetails={getInsightDetails}/>
                  </Grid>
                </Grid></>
            ) : <Grid container columns={16} marginTop="28px">
              <Grid
                item
                xs={24}
                className={clsx(
                  classes.progressInfluence,
                  classes.insightsOutcomes
                )}
              >
                <CorrelationsInsights selectOutcome={selectOutcome} outcomeData={InsightState.insights.data} />
              </Grid>
            </Grid>}

          </>
          {/* if no data availabel */}
          {/* <NoInsightsDataAvailable /> */}
        </RootStyle>
      </Grid>
    </Grid>
  );
};
export default InsightsView;
