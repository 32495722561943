import React from "react";
import { Box, Typography } from "@mui/material";
import {
  InfluenceGetList,
  OutcomeGetList,
  ReflectiveGetList,
} from "../../interfaces/outcomeModal";
import { makeStyles } from "@material-ui/core";
import OutcomeListItem from "./OutcomeListItem";

const useStyles = makeStyles((theme) => ({
  listBox: {
    "&.MuiBox-root:nth-child(2)": {
      marginTop: "100px",
    },
  },
}));

interface OutcomeListModel {
  title: string;
  outcomeState: OutcomeGetList[] | InfluenceGetList[] | ReflectiveGetList[];
}

const OutcomeList = (props: OutcomeListModel) => {
  const classes = useStyles();

  return (
    <Box sx={{ my: 5 }} className={classes.listBox}>
      <Typography variant="h3" component="h3" sx={{ lineHeight: "120%" }}>
        {props.title}
      </Typography>
      {props.outcomeState?.length > 0 &&
        props.outcomeState?.map((outcomeItem, index: number) => {
          return (
            <Box key={index} sx={{ mt: "20px" }}>
              <OutcomeListItem title={props.title} outcomeItem={outcomeItem} />
            </Box>
          );
        })}
    </Box>
  );
};

export default OutcomeList;
