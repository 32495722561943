import React from "react";

const CloudIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="36" height="36" rx="5" fill="#4CB3FD" />
      <path
        d="M8 19.3364C7.99969 16.8887 9.77168 14.8008 12.1868 14.403C13.5239 12.021 16.179 10.696 18.8863 11.0595C21.5937 11.423 23.8051 13.4016 24.4665 16.0519C26.6196 16.3828 28.1543 18.3164 27.9876 20.4884C27.8209 22.6603 26.0091 24.3372 23.8308 24.3356H12.9992C10.2382 24.3356 8 22.0974 8 19.3364Z"
        fill="white"
      />
    </svg>
  );
};

export default CloudIcon;
