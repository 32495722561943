import { Box, Grid } from "@mui/material";
import React from "react";
import { makeStyles } from "@material-ui/core";
import palette from "../../theme/palette";
import EditOutcomeStatus from "./editOutcomeStatus";
import shape from "../../theme/shape";
import useWidth from "../../utils/useWidth";

const useStyles = makeStyles((theme) => ({
  stepperNumber: {
    width: "30px",
    height: "30px",
    color: palette.common.white,
    borderRadius: shape.borderRadiusMd,
    fontSize: "16px",
    lineHeight: "30px",
    fontWeight: 500,
    textAlign: "center",
    position: "relative",
    display: "inline-block",
    zIndex: 1,
  },
  stepperLine: {
    position: "relative",
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
    "&:after": {
      content: "''",
      position: "absolute",
      display: "inline-block",
      left: "80px",
      top: "12px",
      border: "0.1px solid #CADFD2",
      width: "670px",
    },
  },
  stepperLineMobile: {
    position: "relative",
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    "&:after": {
      content: "''",
      position: "absolute",
      display: "inline-block",
      left: "15px",
      top: "45px",
      border: "0.1px solid #CADFD2",
      // width: "670px",
      height: "370px"
    },
  },
  stepperWrapper: {
    cursor: "pointer",
    backgroundColor: "#F7F9FA",
    border: "1px solid #DCE6E0",
    width: "134px",
    height: "100px",
    overflowY: "auto",
    position: "relative",
    marginTop: "20px",
    "&:before": {
      content: "''",
      position: "absolute",
      display: "block",
      left: "41%",
      top: "-22px",
      width: 0,
      height: 0,
      borderStyle: "solid",
      borderColor: "transparent transparent transparent #DCE6E0",
      borderWidth: "11px",
      transform: "rotate(-90deg)",
    },
    "&:after": {
      content: "''",
      position: "absolute",
      display: "block",
      left: "42%",
      top: "-20px",
      width: 0,
      height: 0,
      borderStyle: "solid",
      borderColor: "transparent transparent transparent #F7F9FA",
      borderWidth: "10px",
      transform: "rotate(-90deg)",
    },
  },
  mainStepper: {
    textAlign: "center",
    marginLeft: "18px",
  },
  mainStepperMobile: {
    textAlign: "center",
    display: 'flex',
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: "20px",
    width: "100%"
  },
  stepperWrapperMobile: {
    cursor: "pointer",
    backgroundColor: "#F7F9FA",
    border: "1px solid #DCE6E0",
    width: "-webkit-fill-available",
    height: "70px",
    overflowY: "auto",
    position: "relative",
    marginLeft: "20px",
    borderRadius: "8px"
  },
}));

interface ProgressBarStepperModal {
  selectedColor: any;
  setTextInput: (textInput: any) => void;
  textInput: any;
  setShowInput: (showInput: boolean) => void;
  setEditStatus: (editStatus: number) => void;
  showInput: boolean;
  editStatus: number;
  width?: any;
}

const ProgressBarStepper = (props: ProgressBarStepperModal) => {
  const classes = useStyles();

  const width = useWidth()

  const onValueChange = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    let temp = [...props.textInput];
    temp[name].text = value;
    props.setTextInput(temp);
  };
  const selectedColor = props.selectedColor?.color
    ? props.selectedColor?.color
    : props.selectedColor;
  return (
    <Grid sx={{ width: "100%", overflow: "auto", maxWidth: "820px" }}>
      <Box className={width > 768 ? classes.stepperLine : classes.stepperLineMobile}>
        {selectedColor?.map((colorCode: any, index: number) => {
          return (
            <div className={width > 768 ? classes.mainStepper : classes.mainStepperMobile} key={index}>
              <span
                className={classes.stepperNumber}
                style={{
                  backgroundColor: colorCode.colorCode
                    ? colorCode.colorCode
                    : colorCode,
                }}
              >
                {index + 1}
              </span>
              <div
                className={width > 768 ? classes.stepperWrapper : classes.stepperWrapperMobile}
                onClick={() => {
                  props.setShowInput(true);
                  props.setEditStatus(index);
                }}
                onBlur={() => props.setShowInput(false)}
              >
                <EditOutcomeStatus
                  showInput={props.showInput}
                  index={index}
                  textInput={props.textInput}
                  onValueChange={(e) => onValueChange(e)}
                  editStatus={props.editStatus}
                />
              </div>
            </div>
          );
        })}
      </Box>
    </Grid>
  );
};
export default ProgressBarStepper;
