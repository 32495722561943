
import {
    DialogContent,
    Dialog,
    Typography,
} from "@mui/material";
import { makeStyles } from "@material-ui/core";
import React from "react";
import palette from "../../theme/palette";
import { pxToRem } from "../../theme/typography";

interface IOutcomeclosePopup {
    open: boolean;
    setopenOutcomeclosePopup: any;
    type: 'outcome' | 'influence' | 'question'
    handleCloseStartModal?: any
    handleCloseStartModalInfluence?: any
    handleCloseStartModalQues?: any
}

const useStyles = makeStyles((theme) => ({
    categoryDialog: {
        "& .MuiDialog-container": {
            "& .MuiPaper-root": {
                borderRadius: "24px",
                padding: "0",
                display: 'flex',
                // width: '400px',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',

                "& .MuiDialogContent-root": {
                    padding: "0",
                    overflowY: "unset",
                },

                "&. MuiDialogActions-root": {},
            },
        },
    },
    categoryDialogAction: {
        display: "flex !important",
        alignItems: "center !important",
        justifyContent: 'center !important',
        gap: '12px',
        alignSelf: 'stretch !important',
        padding: '32px !important',
        borderTop: `1px solid #F3F3F3`,
    },
}));
const LimitReachedPopup = (props: IOutcomeclosePopup) => {
    const classes = useStyles();

    return (
        <Dialog
            open={props.open}
            onClose={() => {
                props?.setopenOutcomeclosePopup(false)
                if(props?.handleCloseStartModal) props?.handleCloseStartModal()
                if(props?.handleCloseStartModalInfluence) props?.handleCloseStartModalInfluence()
                if(props?.handleCloseStartModalQues) props?.handleCloseStartModalQues()
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.categoryDialog}
        >
            <DialogContent id="alert-dialog-description">
                <div style={{
                    display: 'flex',
                    padding: '32px ',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '16px',
                    alignSelf: 'stretch',

                }}>
                    <Typography variant="h3" component="span" lineHeight="135% !important" sx={{ width: "100%", textAlign: "center", color: palette.common.black, fontSize: `${pxToRem(24)} !important`, fontWeight: 600 }}>
                        {`You are in discovery phase and you reached your limit for ${props.type === "outcome" ? "outcome" : props.type === "influence" ? "influence" : "qualitative reflections"} metrics, please archive one existing metric and add new metric`}
                    </Typography>
                </div>

            </DialogContent>

        </Dialog>
    );
};

export default LimitReachedPopup;
