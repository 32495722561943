import React, { useReducer } from "react";
import { IInsightAction, IInsightsTypeState, InsightsTypeContextModal } from "../interfaces/insightsModal";


const defaultState: IInsightsTypeState = {
  insights: {
    data: [],
  },
  leftMenu: [],
  rightMenu: []
};

const reducer = (
  state: IInsightsTypeState,
  action: IInsightAction
): IInsightsTypeState => {
  switch (action.type) {
    case "SET_INSIGHT_DATA":
      return {
        ...state,
        insights: action.payload,
      };
    case "SET_LEFT_MENU":
      return {
        ...state,
        leftMenu: action.payload,
      };
      case "SET_RIGHT_MENU":
      return {
        ...state,
        rightMenu: action.payload,
      };
    default:
      return state;
  }
};

export const InsightTypeContext = React.createContext({} as InsightsTypeContextModal);
type childernType = {
  children: React.ReactNode;
};
export const Provider: React.FC = (props: childernType) => {
  const [state, dispatch] = useReducer(reducer, defaultState);

  return (
    <InsightTypeContext.Provider value={{ state, dispatch }}>
      {props.children}
    </InsightTypeContext.Provider>
  );
};
