
import React from 'react'

export default function ArrowDownGreen() {
  return (
    <>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2276_37750)">
          <path d="M16.59 8L12 12.58L7.41 8L6 9.41L12 15.41L18 9.41L16.59 8Z" fill="#B8D3C0" />
        </g>
        <defs>
          <clipPath id="clip0_2276_37750">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  )
}
