/*eslint @typescript-eslint/no-unused-vars: "off"*/
import React, { useState, useEffect, useContext } from "react";
import { Grid } from "@mui/material";
import palette from "../../theme/palette";
import LeftSideOutCome from "./leftOutcome";
import RightPartOutCome from "./rightOutCome";
import { outcomeService } from "../../services/outcome";
import { OutcomeGetResponse } from "../../interfaces/outcomeModal";
import { UserTypeContext } from "../../context/userStatus";
import { addOutcomeAction } from "../../store/actions/userTypeAction";
import SnackBarComp from "../../components/snackBar";
import { OutcomeStatus } from "../../utils/staticDataJson";
import FullpageLoader from "../../components/fullPageLoader";

const AddOutCome = () => {
  const { state: outcomeState, dispatch: userTypeDispatch } =
    useContext(UserTypeContext);
  const [outComeValue, setOutcomeValue] = useState("");
  const [addOutcomeTracking, setAddOutcomeTracking] = useState(false);
  const [showSnakbar, setShowSnakbar] = useState(false);
  const [showOutcome, setShowOutcome] = useState(false);
  const [snakbarMsg, setSnakbarMsg] = useState("");
  const [msgType, setMsgType] = useState("");
  const [outcomeIdeas, setOutcomeIdeas] = useState();
  const [textInput, setTextInput] = useState(OutcomeStatus);
  const [fullpageLoader, setFullPageLoader] = useState(true);
  useEffect(() => {
    // GET OUTCOME LIST
    outcomeService
      .getOutcomeList(outcomeState.token)
      .then((data: OutcomeGetResponse) => {
        setFullPageLoader(false);
        userTypeDispatch(addOutcomeAction(data?.data));
      })
      .catch((error) => {
        setShowSnakbar(true);
        setSnakbarMsg(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message
        );
        setMsgType("error");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleCloseSnakbar = () => {
    setShowSnakbar(false);
  };

  return (
    <>
      {fullpageLoader && <FullpageLoader />}
      <Grid
        container
        columns={16}
        sx={{ backgroundColor: palette.secondary.light }}
      >
        {/* SNACK BAR */}
        <SnackBarComp
          showSnakbar={showSnakbar}
          handleCloseSnakbar={handleCloseSnakbar}
          snakbarMsg={snakbarMsg}
          type={msgType ? msgType : "info"}
        />
        {/* START LEFT SECTION */}
        <Grid
          sx={{ backgroundColor: palette.common.white, overflowY: 'scroll' }}
          item
          xs={9.63}
          className="leftside-menu login-screen outcome-screen outcome-left-layout"
        >
          <LeftSideOutCome
            outComeValue={outComeValue}
            setOutcomeValue={setOutcomeValue}
            outcomeIdeas={outcomeIdeas}
            setShowOutcome={setShowOutcome}
            showOutcome={showOutcome}
            setShowSnakbar={setShowSnakbar}
            setMsgType={setMsgType}
            setSnakbarMsg={setSnakbarMsg}
            setAddOutcomeTracking={setAddOutcomeTracking}
            setTextInput={setTextInput}
            textInput={textInput}
            onboarding={true}
          />
        </Grid>
        {/* END LEFT SECTION */}

        {/* START RIGHT SECTION */}
        <Grid
          item
          xs={6.37}
          className="rightside-menu outcome-menu outcome-right-layout"
          sx={{ backgroundColor: palette.common.bgColor }}
        >
          <RightPartOutCome
            setOutcomeValue={setOutcomeValue}
            setOutcomeIdeas={setOutcomeIdeas}
          />
        </Grid>
        {/* END RIGHT SECTION */}
      </Grid>
    </>
  );
};
export default AddOutCome;
