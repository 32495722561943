import React, { useContext, useEffect, useRef, useState } from "react";

import {
  AccordionDetails,
  Badge,
  Box,
  ClickAwayListener,
  IconButton,
  styled,
  Button,
  Typography,
} from "@mui/material";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { makeStyles } from "@material-ui/core";
import AddFilledIcon from "../../assets/images/icon/AddFilledIcon";
import ArrowUp from "../../assets/images/icon/arrow_up";
import NormalMode from "../../assets/images/icon/NormalMode";
import UserIcon from "../../assets/images/icon/UserIcon";
import palette from "../../theme/palette";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import moment from "moment";
import clsx from "clsx";
import Calendar from "./calendar";
import FullpageLoader from "../../components/fullPageLoader";
import { trackingService } from "../../services/tracking";
import { UserTypeContext } from "../../context/userStatus";
import SnackBarComp from "../../components/snackBar";
import { outcomeService } from "../../services/outcome";
import ArrowCircleDownOutlinedIcon from '@mui/icons-material/ArrowCircleDownOutlined';
import ArrowCircleUpOutlinedIcon from '@mui/icons-material/ArrowCircleUpOutlined';
import {
  InfluenceGetResponse,
  OutcomeGetResponse,
  RefelectiveGetResponse,
} from "../../interfaces/outcomeModal";
import {
  addCategoryJourneyAction,
  addInfluenceAction,
  addJourneyAction,
  addNotesAction,
  addNotesJourneyAction,
  addOutcomeAction,
  addRefelectiveAction,
  isPersonalMode,
} from "../../store/actions/userTypeAction";
import typography, { pxToRem } from "../../theme/typography";
import { influenceService } from "../../services/influence";
import AddTrackingEventModal from "./addTrackingEventModal";
import AddHoursModal from "./addHoursModal";
import AddYesNoModal from "./addYesNoModal";
import AddTimeOfDay from "./addTimeOfDayModel";
import { reflectiveQueService } from "../../services/reflective-questions";
import AddTrackingEventInfluenceModal from "./addTrackingEventInfluenceModal";
import AddRefQuesModal from "./addRefQuesModal";
import { Navigate, useNavigate } from "react-router-dom";
import StartTrackingModal from "./startTrackingModal";
import AddTrackingPopUp from "../testAddGolas/AddTrackingPopUp/AddTrackingPopUp";
import Unavailablecategory from "./Unavailablecategory";
import { baseEndpoints } from "../../services/config";
import { userService } from "../../services/auth";
import useWidth from "../../utils/useWidth";
import MobileMailIcon from "../../assets/images/icon/MobileMailIcon";
import { notificationService } from "../../services/notifications";
import HeaderPopoverMobile from "./HeaderPopoverMobile";
import PlusIconSVG from "../../assets/images/icon/PlusIcon";
import IconYesNo from "../trackerNew/IconYesNo";
import EditIcon from "../../assets/images/icon/EditIcon";
import CrossIconSwitch from "../../assets/images/icon/CrossIconSwitch";
import ShareIcon from "../../assets/images/icon/ShareIcon";
import ArchiveIcon from "../../assets/images/icon/ArchiveIcon";
import { setDayCompleteMessage } from "../../store/actions/trackerAction";
import useNotifications from "../../components/DayCompleteSnackBar";
import { TrackingContext } from "../../context/userTracking";
import HTMLTooltip from "../../components/HTMLTooltip";
import InfoIcon from "../../assets/images/icon/infoIconYellow";
import MobileMenuIcon from "../../assets/images/icon/MobileMenuIcon";
import BellIcon from "../../assets/images/icon/BellIcon";
import StrikeIcon from "../../assets/images/icon/StrikeIcon";
const PlusIcon = require("../../assets/images/Add.png");
const DummyUser = require("../../assets/images/userDummy.png");
const LineGraph = require("../../assets/images/line.png");
const MenuIcon = require("../../assets/images/Menu.png");
let alreadyCalled = false
const useStyles = makeStyles((theme) => ({
  // mainContainer: {
  //   padding: "20px",
  //   width: ShowMenu ? "60vw" : "100vw"
  // },
  userText: {
    textAlign: "center",
    fontSize: "16px!important",
    lineHeight: "16px!important",
    color: `#9BA19D!important`,
    [theme.breakpoints.down("md")]: {
      fontSize: "12px!important",
    },
  },
  userText2: {
    textAlign: "center",
    marginLeft: "2px!important",
    marginTop: "5px!important",
    fontSize: "14px!important",
    lineHeight: "14px!important",
    color: `#FFFFFF!important`,
  },
  userContainer: {
    display: "flex",
    alignItems: "center",
    gap: "6px"
  },
  userContainer2: {
    display: "flex",
  },
  toggleContainer: {
    display: "flex",
    alignItems: "center",
    // backgroundColor: "#EBF2F1",
    borderRadius: "4px",
    // padding: "2px",
    // height: "fit-content",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  swicthButtons: {
    display: "flex",
    alignItems: "center",
    // borderRadius: "8px",
    border: "none ",
    // background: "#FFF",
  },
  toggleButton: {
    padding: "5px",
    borderRadius: "4px",
    height: "25px",
    width: "25px",
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  activeToggle: {
    "& svg": {
      "& path": {
        stroke: "var(--color-new-main)"
      },
      "& circle": {
        stroke: "var(--color-new-main)"
      }
    }
    // backgroundColor: "white",
  },
  pageTitle: {
    color: "#3E4240 !important",
    fontWeight: 600,
    fontSize: "24px !important",
    lineHeight: "24px !important",
  },
  titleContainer: {
    marginTop: "8px",
  },
  accordionContainer: {
    // marginTop: "20px",
    // height: 'calc(100vh - 300px)',
    // overflow: 'scroll',
    // height: 70vh;
  },
  cardBody: {
    backgroundColor: "#fff",
    display: "flex",
    justifyContent: "space-between",
    padding: "20px 16px",
    borderRadius: "8px",
    alignItems: "center",
    marginBottom: "10px",
    boxShadow: '0px 11px 30px -20px rgba(211, 218, 215, 0.23)'
  },
  cardBodyNew: {
    backgroundColor: "#fff",
    display: "flex",
    padding: "20px 16px",
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '20px',
    boxShadow: '0px 11px 30px -20px rgba(211, 218, 215, 0.23)',
    marginBottom: '12px',
    borderRadius: '8px',

  },
  lineGraph: {
    marginTop: "15px",
  },
  plusIcon: {
    fontSize: "42px",
    color: "#E3E3E3",
    fontFamily: "circular",
    // marginTop: "8px",
    display: "flex",
    alignItems: "center",
  },
  inputContainer: {
    // padding: "5px",
    borderRadius: "999px",
    border: "1px solid #5E5F5E",
    flexShrink: 0,
    height: "40px",
    width: "40px",
    display: "flex",
    // alignItems: 'center',
    justifyContent: "center",
    // paddingTop: "8px",
  },
  inputContainerFullWidth: {
    flexShrink: 0,
    width: "100%",
    display: "flex",
    // alignItems: 'center',
    justifyContent: "center",
    position: 'relative'
  },
  rateInput: {
    width: "25px",
    height: "25px",
    outline: "none",
    textAlign: "center",
    marginTop: "8px",
  },
  background: {
    backgroundColor: "var(--color-new-main)",
    border: "1.33px solid var(--color-svg)",
    color: "#fff !important",
    borderRadius: "8px",
    "& p": {
      color: "black !important"
    },
    "& h4": {
      color: "black !important"
    }
  },
  headerBlocks: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: "12px 4px 12px 4px",
    height: "56px",
    gap: "4px",
    width: "44px",
    minWidth: "44px",
    "& p": {
      textAlign: "center",
      verticalAlign: "midle"
      // margin: "0",
    },
    "& span": {
      // color: palette.common.white,
      fontWeight: 500,
    },
  },
  weekday: {
    color: palette.primary.light,
    fontSize: "12px!important",
    lineHeight: "15px!important",
    fontWeight: 400,
    opacity: "0.4",
  },
  calenderContainer: {
    marginTop: "30px",
    display: "flex",
    width: "100%",
    // overflow: "auto",
    paddingBottom: "5px",
    flexDirection: 'column'
  },
  monthTitle: {
    color: palette.primary.light,
    marginBottom: "0px!important",
    fontSize: "16px!important",
    letterSpacing: "0.02em",
    lineHeight: "16px!important",
    padding: "0px",
  },
  clock: {
    position: "absolute",
    margin: "auto",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    height: "20px",
    width: "20px",
  },
  hand: {
    position: "absolute",
    width: "3px",
    margin: "auto",
    left: "0",
    bottom: "0",
    right: "0",
    backgroundColor: "#fff",
    transformOrigin: "bottom",
    borderRadius: "4px",
  },
  minute: {
    height: "14px",
    top: "-60%",
  },
  hour: {
    height: "10px",
    top: "-35%",
  },
  borderOutline: {
    width: "34px!important",
    height: "34px!important",
  },
  cardBG: {
    width: '100%',
    // height: 'auto',
    flexShrink: 0,
    background: `#f7fbff`,
    filter: `drop-shadow(0px -12px 30px rgba(211, 218, 215, 0.15))`,
    borderRadius: '32px 32px 0px 0px',
    padding: "32px 0 0 0"
  },
  mainTools: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  plusIconBg: {
    display: 'inline-flex',
    padding: '2px 6px 6px 4px',
    alignItems: 'center',
    borderRadius: '50px',
    background: 'var(--color-new-main)',
    cursor: "pointer"
  },
  rectangleInput: {
    width: '26px',
    height: '26px',
    transform: 'rotate(-90deg)',
    flexShrink: 0,
    borderRadius: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'relative'
  },
  rectangleInputFull: {
    width: '100%',
    borderRadius: '12px',
    padding: '7px 12px 7px 12px',
    display: 'flex',
    background: '#F3F3F3',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '14px',
    cursor: 'pointer',
    position: 'relative'
  },
  tickMark: {
    position: 'absolute',
    right: -5,
    bottom: -5,
    borderRadius: '100%',
    height: '10px',
    width: '10px',
    background: 'green'
  },
  tickMarkNew: {
    position: 'absolute',
    right: 0,
    top: 0,
    borderRadius: '100%',
    height: '10px',
    width: '10px',
    background: 'green'
  },
  selection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '16px',
    width: '100%'

  },
  selectionExpanded: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '7px',
    width: '100%'
  },
  counterView: {
    lineHeight: "125% !important"
  },
}));
const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 5,
    top: 0,
    background: '#B8E29E',
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 0px',
    fontSize: '0.0rem'
  },
}));
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  backgroundColor: "transparent",
  "&:before": {
    display: "none",
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    transform: "rotate(180deg)",
    marginLeft: "10px",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(0deg)",
  },
}));

const AccordionSummary = (props: AccordionSummaryProps & {
  tooltipTitle?: string
}) => {
  const [open, setOpen] = useState(false)
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "5px"
        }}
      >
        <div
          // variant="h4"
          style={{ fontWeight: 500, fontSize: "20px", lineHeight: "20px" }}
        // fontWeight={600}
        // className={classes.listTitle}
        >
          {props?.title}
        </div>
        {props?.tooltipTitle && (
          <ClickAwayListener onClickAway={() => setOpen(false)}>
            <IconButton
              style={{
                padding: "0px",
                height: "20px",
                width: "20px"
              }}

              onClick={() => setOpen(!open)}
            >
              <HTMLTooltip placement={"bottom-start"} customIcon={<div><InfoIcon color={palette.primary.main} size={"14"} /></div>} title={props?.tooltipTitle} fontProps={{
                sx: {
                  fontSize: `${pxToRem(14)} !important`,
                  lineHeight: "120% !important",
                  textAlign: "justify !important",
                  "&.MuiTypography-root": {
                    "@media  (max-width: 600px) and (min-width: 100px)": {
                      fontSize: `${pxToRem(14)} !important`,
                      lineHeight: "150% !important"
                    },
                  }
                }
              }} sx={{
                '& .MuiTooltip-tooltip': {
                  maxWidth: "350px",
                  marginLeft: "20px",
                  marginRight: "20px"
                }
              }} customProps={{
                placement: "bottom-end",
                open: open
              }} />
            </IconButton>
          </ClickAwayListener>

        )}

        <MuiAccordionSummary
          expandIcon={<ArrowUp />}
          {...props}
          style={{ padding: "0px" }}
        />
      </div>
    </div>
  );
};

const MobileAccordion = (props) => {
  const [expanded, setExpanded] = useState<boolean>(true);
  const handleChange = (expanded: boolean) => () => {
    setExpanded(!expanded);
  };
  return (
    <Accordion
      sx={{ marginBottom: "40px" }}
      elevation={0}
      expanded={expanded}
      onChange={handleChange(expanded)}
    >
      <AccordionSummary title={props?.title} tooltipTitle={props?.tooltipTitle} />

      <AccordionDetails sx={{ padding: "0px" }}>
        {props?.children}
      </AccordionDetails>
    </Accordion>
  );
};
const DetailCard = (props) => {
  let noData = false
  const classes = useStyles();
  const [inputValue, setInputValue] = useState("");
  const [isInFullView, setIsInFullView] = useState(false);
  const { state: outcomeState, dispatch: UserTypeDispatch } = useContext(UserTypeContext);
  const [showInput, setShowInput] = useState(false);
  const [visibleYesnotSwitch, setVisibleSwitch] = useState(false);
  const { trackingDispatch } = props
  const clickCondition = moment(props.betweenDate).isBetween(
    moment().subtract(8, "year").format("yyyy-MM-DD"),
    moment().add(1, "day").format("yyyy-MM-DD")
  );

  const handleBlurInput = (id: any, index: any, date: any, e: any, mouseOut: boolean) => {
    if (mouseOut) {
      setShowInput(false);
      setNoData(true);
    }
    if (
      e.key === "Enter" ||
      e.keyCode == "37" ||
      e.keyCode == "38" ||
      e.keyCode == "39" ||
      e.keyCode == "40" ||
      mouseOut
    ) {
      if (props?.item?.absenceToggle) {
        if (parseInt(inputValue) >= 0 && parseInt(inputValue) <= 5) {
          setShowInput(false);
          props?.outcomeCreate(inputValue, props?.headerDate);
        } else {
          props?.setShowSnakbar(true);
          props?.setMsgType("error");
          props?.setSnakbarMsg("Please enter value between 0 to 5");
        }
      } else {
        if (parseInt(inputValue) > 0 && parseInt(inputValue) <= 5) {
          setShowInput(false);
          props?.outcomeCreate(inputValue, props?.headerDate);
        } else {
          props?.setShowSnakbar(true);
          props?.setMsgType("error");
          props?.setSnakbarMsg("Please enter value between 1 to 5");
        }
      }


      // down arrow key
      if (e.keyCode == "40" && index <= outcomeState?.outcomeList?.length - 1) {
        let nextId = "";
        let nextItem;
        for (let i = 0; i < outcomeState?.outcomeList?.length; i++) {
          if (outcomeState?.outcomeList[i]._id === id) {
            nextId = outcomeState?.outcomeList[i + 1]?._id;
            nextItem = outcomeState?.outcomeList[i + 1];
          }
        }
        props.setOutcomeId(nextId);
        props?.setItem(nextItem);
        setShowInput(true);
        setInputValue("");
      }

      // up arrow key
      if (e.keyCode == "38" && index <= outcomeState?.outcomeList?.length - 1) {
        let prevId = "";
        let prevItem;
        for (let i = 0; i < outcomeState?.outcomeList?.length; i++) {
          if (outcomeState?.outcomeList[i]._id === id) {
            prevId = outcomeState?.outcomeList[i - 1]?._id;
            prevItem = outcomeState?.outcomeList[i - 1];
          }
        }
        props.setOutcomeId(prevId);
        props?.setItem(prevItem);
        setShowInput(true);
        setInputValue("");
      }
      setNoData(true)
    }
  };

  const handleBlurInputInfluence = (type, e: any, mouseOut: boolean) => {
    if (mouseOut) {
      setShowInput(false);
      setNoData(true);
    }
    if (
      e.key === "Enter" ||
      e.keyCode == "37" ||
      e.keyCode == "38" ||
      e.keyCode == "39" ||
      e.keyCode == "40" ||
      mouseOut
    ) {
      if (type === "TYPE2" && inputValue) {
        if (props?.item?.absenceToggle) {
          if (parseInt(inputValue) >= 0 && parseInt(inputValue) <= 5) {
            setShowInput(false);
            // setDisplayInput(true);
            props?.influenceCreate(inputValue, props?.headerDate);
          } else {
            props?.setShowSnakbar(true);
            props?.setMsgType("error");
            props?.setSnakbarMsg("Please enter value between 0 to 5");
          }
        } else {
          if (parseInt(inputValue) > 0 && parseInt(inputValue) <= 5) {
            setShowInput(false);
            // setDisplayInput(true);
            props?.influenceCreate(inputValue, props?.headerDate);
          } else {
            props?.setShowSnakbar(true);
            props?.setMsgType("error");
            props?.setSnakbarMsg("Please enter value between 1 to 5");
          }
        }

      } else {
        setShowInput(false);
        // setDisplayInput(true);
        inputValue && props?.unitCreate(inputValue, props?.headerDate);
      }
    }
  };
  const journeyDataOutcome: any = outcomeState?.journeyList?.outcome_data;
  const journeyDataInfluence: any = outcomeState?.journeyList?.influence_data;
  const journeyDataReflective: any = outcomeState?.journeyList?.reflective_data;
  const [NoData, setNoData] = useState(true);
  let foundData = false
  if (props?.item?.outcomeId && journeyDataOutcome && journeyDataOutcome.length) {
    foundData = journeyDataOutcome.find(journey => journey?.outcomeId === props?.item?.outcomeId && journey?.date === props.headerDate)
  }
  if (props?.item?.influenceId && journeyDataInfluence && journeyDataInfluence.length) {
    foundData = journeyDataInfluence.find(journey => journey?.influenceId === props?.item?.influenceId &&
      journey?.date === props.headerDate)
  }
  if (props?.item?.reflectiveId && journeyDataReflective && journeyDataReflective.length) {
    foundData = journeyDataReflective.find(journey => journey?.reflectiveId === props?.item?.reflectiveId &&
      journey?.date === props.headerDate)
  }

  const nearestValueOutcome = (val, influenceItem) => {
    let arr = [];

    if (influenceItem.outcomeId && influenceItem?.options?.length && influenceItem?.options[val - 1]) {
      return influenceItem.options[val - 1].colorCode
    }
    if (influenceItem?.options && influenceItem?.options.length) {
      arr = [...influenceItem?.options].map(it => it.value)
    }
    const returnVal = arr.reduce((p, n) => (Math.abs(p) > Math.abs(n - val) ? n - val : p), Infinity) + val
    if (influenceItem?.options && influenceItem?.options.length) {
      const foundIndexOfValue = influenceItem?.options.findIndex(it => it.value === returnVal)
      if (foundIndexOfValue > -1) {
        return influenceItem?.options[foundIndexOfValue]?.colorCode
      }
    }
    return false
  }
  const closest = (needle, haystack = []) => {
    if (haystack && haystack.length) {
      return haystack.reduce((a, b) => {
        let aDiff = Math.abs(a - needle);
        let bDiff = Math.abs(b - needle);

        if (aDiff == bDiff) {
          return a > b ? a : b;
        } else {
          return bDiff < aDiff ? b : a;
        }
      });
    }
    return -1

  }

  const nearestValue = (val, influenceItem) => {

    let arr = [];
    if (influenceItem?.options && influenceItem?.options.length) {
      arr = [...influenceItem?.options].map(it => it.value)
    }
    if (influenceItem.type === "TYPE2" && influenceItem.options.length && influenceItem.options[val - 1]) {
      return influenceItem.options[val - 1].colorCode
    }
    const returnVal = closest(val, arr)
    if (influenceItem?.options && influenceItem?.options.length && returnVal > -1) {
      const foundIndexOfValue = influenceItem?.options.findIndex(it => it.value === returnVal)
      if (foundIndexOfValue > -1) {
        return influenceItem?.options[foundIndexOfValue]?.colorCode
      }
    }
    return false

  }
  const createInfluence = (isEdit, answerId, score, outcomeNotes, optionIndex, type) => {
    let outcomeCreateData
    if (isEdit) {
      outcomeCreateData = {
        date: props?.headerDate,
        influenceId: props.item?.influenceId,
        answerId: answerId,
        score: score,
        index: optionIndex,
        note:
          outcomeNotes,
        Type: type,
      };
    } else {
      outcomeCreateData = {
        date: props?.headerDate,
        influenceId: props.item?.influenceId,
        answerId: answerId,
        score: score,
        index: parseInt(optionIndex),
        note:
          outcomeNotes,
        Type: type,
      };
    }
    const foundOutcome = (outcomeState?.influenceList || []).find(it => it.influenceId === outcomeCreateData.influenceId);
    if (foundOutcome) {
      const journyList = { ...(outcomeState?.journeyList || {}) } as any
      const foundDateItemIcon = journyList.influence_data.findIndex(it => it.influenceId === outcomeCreateData.influenceId && it?.date === outcomeCreateData.date)
      if (foundDateItemIcon > -1) {
        journyList.influence_data[foundDateItemIcon] = {
          ...foundOutcome,
          ...outcomeCreateData,
        }
      } else {
        journyList.influence_data.push({
          ...foundOutcome,
          ...outcomeCreateData,
        } as any)
      }
      UserTypeDispatch(addJourneyAction(journyList as any))
      // if (props.callToGetData) {
      //   props?.callService();
      // }
    }
    trackingService
      .influenceCreate(outcomeState.token, outcomeCreateData).then((data) => {
        if (data.showMessage && props?.headerDate === moment().format("MM-DD-YYYY")) {
          trackingDispatch(setDayCompleteMessage({
            open: data.showMessage,
            message: data.message
          }))
        }
        setIsInFullView(false)
        // props?.callService();
      })
  }
  const createOutcome = (isEdit, answerId, score, outcomeNotes, optionIndex) => {
    let outcomeCreateData;
    if (isEdit) {
      outcomeCreateData = {
        date: props?.headerDate,
        outcomeId: props.item?.outcomeId,
        answerId: answerId,
        score: score,
        index: optionIndex,
        note:
          outcomeNotes,
      };
    } else {
      outcomeCreateData = {
        date: props?.headerDate,
        outcomeId: props.item?.outcomeId,
        answerId: answerId,
        score: score,
        index: parseInt(optionIndex),
        note:
          outcomeNotes,
      };
    }
    if (!outcomeState?.isPersonal) {
      const foundOutcome = (outcomeState?.outcomeList || []).find(it => it.outcomeId === outcomeCreateData.outcomeId);
      if (foundOutcome) {
        const journyList = { ...(outcomeState?.journeyList || {}) } as any
        const foundDateItemIcon = journyList.outcome_data.findIndex(it => it.outcomeId === outcomeCreateData.outcomeId && it?.date === outcomeCreateData.date)
        if (foundDateItemIcon > -1) {
          journyList.outcome_data[foundDateItemIcon] = {
            ...foundOutcome,
            ...outcomeCreateData,
          }
        } else {
          journyList.outcome_data.push({
            ...foundOutcome,
            ...outcomeCreateData,
          } as any)
        }
        UserTypeDispatch(addJourneyAction(journyList as any))
      }
    } else {
      const journeyCategoryList = [...(outcomeState?.journeyCategoryList || [])]
      const foundOutcome = (journeyCategoryList
        || []).findIndex(it => it.outcomeIds && it.outcomeIds.includes(outcomeCreateData.outcomeId));
      if (foundOutcome > -1) {

        const journyList = { ...(outcomeState?.journeyList || {}) } as any
        const foundOutcomeNew = (outcomeState?.outcomeList || []).find(it => it.outcomeId === outcomeCreateData.outcomeId);
        const foundDateItemIcon = journyList.outcome_data.findIndex(it => it.outcomeId === outcomeCreateData.outcomeId && it?.date === outcomeCreateData.date)
        if (foundDateItemIcon > -1) {
          journyList.outcome_data[foundDateItemIcon] = {
            ...foundOutcomeNew,
            ...outcomeCreateData,
          }
        } else {
          journyList.outcome_data.push({
            ...foundOutcomeNew,
            ...outcomeCreateData,
          } as any)
        }
        UserTypeDispatch(addJourneyAction(journyList as any))
      } else {
        const foundOther = (journeyCategoryList
          || []).findIndex(it => it.name === "Other")
        if (foundOther > -1) {
          const journyList = { ...(outcomeState?.journeyList || {}) } as any
          const foundOutcomeNew = (outcomeState?.outcomeList || []).find(it => it.outcomeId === outcomeCreateData.outcomeId);
          const foundDateItemIcon = journyList.outcome_data.findIndex(it => it.outcomeId === outcomeCreateData.outcomeId && it?.date === outcomeCreateData.date)
          if (foundDateItemIcon > -1) {
            journyList.outcome_data[foundDateItemIcon] = {
              ...foundOutcomeNew,
              ...outcomeCreateData,
            }
          } else {
            journyList.outcome_data.push({
              ...foundOutcomeNew,
              ...outcomeCreateData,
            } as any)
          }
          UserTypeDispatch(addJourneyAction(journyList as any))
        }
      }
    }
    trackingService
      .outcomeCreate(outcomeState.token, outcomeCreateData)
      .then((data) => {
        if (data.showMessage && props?.headerDate === moment().format("MM-DD-YYYY")) {
          trackingDispatch(setDayCompleteMessage({
            open: true,
            message: data.message
          }))
        }
        setIsInFullView(false)
        // props?.callService();
      })
  }
  const createInfluenceLevel = (inputLevel, notes) => {
    let outcomeCreateData;

    outcomeCreateData = {
      influenceId: props.item?.influenceId,
      levelReach: inputLevel,
      note: notes,
      date: props?.headerDate,
      flag: props?.item?.flag,
      type: props?.item?.type,
      inverse: props?.item?.inverse,
      colorCode: (props?.item?.type === "TYPE3" &&
        inputLevel === "No") ||
        inputLevel === "No"
        ? props?.item?.inverse === "true"
          ? palette.primary.main
          : "#F17F79"
        : props?.item?.inverse === "true"
          ? "#F17F79"
          : palette.primary.main
    };
    const foundOutcome = (outcomeState?.influenceList || []).find(it => it.influenceId === outcomeCreateData.influenceId);
    if (foundOutcome) {
      const journyList = { ...(outcomeState?.journeyList || {}) } as any
      const foundDateItemIcon = journyList.influence_data.findIndex(it => it.influenceId === outcomeCreateData.influenceId && it?.date === outcomeCreateData.date)
      if (foundDateItemIcon > -1) {
        journyList.influence_data[foundDateItemIcon] = {
          ...foundOutcome,
          ...outcomeCreateData,
        }
      } else {
        journyList.influence_data.push({
          ...foundOutcome,
          ...outcomeCreateData,
        } as any)
      }
      UserTypeDispatch(addJourneyAction(journyList as any))
      setVisibleSwitch(false)
      // if (props.callToGetData) {
      //   props?.callService();
      // }
    }
    trackingService
      .influenceCreate(outcomeState.token, outcomeCreateData)
      .then((data) => {
        if (data.showMessage && props?.headerDate === moment().format("MM-DD-YYYY")) {
          trackingDispatch(setDayCompleteMessage({
            open: data.showMessage,
            message: data.message
          }))
        }
        // if (!outcomeState?.isPersonal) {
        //   const foundOutcome = (outcomeState?.influenceList || []).find(it => it.influenceId === outcomeCreateData.influenceId);
        //   if (foundOutcome) {
        //     const journyList = { ...(outcomeState?.journeyList || {}) } as any
        //     const foundDateItemIcon = journyList.influence_data.findIndex(it => it.influenceId === outcomeCreateData.influenceId && it?.date === outcomeCreateData.date)
        //     if (foundDateItemIcon > -1) {
        //       journyList.influence_data[foundDateItemIcon] = {
        //         ...foundOutcome,
        //         ...outcomeCreateData,
        //       }
        //     } else {
        //       journyList.influence_data.push({
        //         ...foundOutcome,
        //         ...outcomeCreateData,
        //       } as any)
        //     }
        //     UserTypeDispatch(addJourneyAction(journyList as any))
        //     // props?.callService();
        //   }
        // } else {
        //   const journeyCategoryList = [...(outcomeState?.journeyCategoryList || [])]
        //   const foundOutcome = (journeyCategoryList
        //     || []).findIndex(it => it.influenceIds && it.influenceIds.includes(outcomeCreateData.influenceId));
        //   if (foundOutcome > -1) {
        //     if (journeyCategoryList[foundOutcome].alls && journeyCategoryList[foundOutcome].alls.length) {
        //       const foundIndex = journeyCategoryList[foundOutcome].alls.findIndex(it => it.influenceId === outcomeCreateData.influenceId)
        //       if (foundIndex > -1) {
        //         if (journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses && journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses.length) {
        //           const foundDateValue = journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses.findIndex(it => it.date === outcomeCreateData.date)
        //           if (foundDateValue > -1) {
        //             journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses[foundDateValue] = {
        //               ...outcomeCreateData
        //             }
        //           } else {
        //             journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses.push(outcomeCreateData)
        //           }
        //         } else {
        //           journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses = [outcomeCreateData]
        //         }
        //         UserTypeDispatch(addCategoryJourneyAction(journeyCategoryList));
        //         // props?.callService();
        //       }
        //     }
        //   } else {
        //     const foundOther = (journeyCategoryList || []).findIndex(it => it.name === "Other")
        //     if (foundOther > -1) {
        //       const outcomeFound = journeyCategoryList[foundOther].influences.findIndex(it => it.influenceId === outcomeCreateData.influenceId);
        //       if (outcomeFound > -1) {
        //         if (journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses && journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses.length) {
        //           const foundDateValue = journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses.findIndex(it => it.date === outcomeCreateData.date)
        //           if (foundDateValue > -1) {
        //             journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses[foundDateValue] = {
        //               ...outcomeCreateData
        //             }
        //           } else {
        //             journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses.push(outcomeCreateData)
        //           }
        //         } else {
        //           journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses = [outcomeCreateData]
        //         }
        //         UserTypeDispatch(addCategoryJourneyAction(journeyCategoryList));
        //         // props?.callService();
        //       }
        //     }
        //   }
        // }
        props.setShowSnakbar(false);
        props.setSnakbarMsg(data?.message);
        props.setOpenYesNoModal(false);
        // props?.callService();
        props.setMsgType("success");
      })
      .catch((error) => {
        props.setShowSnakbar(true);
        props.setSnakbarMsg(
          error?.response?.data?.description
            ? error?.response?.data?.description
            : error?.message
        );
        props.setMsgType("error");
      });

  };
  const { expandedLevelView } = props

  const levelRender = (itemObject, options) => {
    let optionsData = options?.options || []
    if (options.absenceToggle) {
      optionsData = [{
        colorCode: "grey", score: 0, text: "Absence"
      }, ...optionsData]
    }

    const tickMark = null // <div className={ expandedLevelView? classes.tickMarkNew : classes.tickMark}></div>

    const checkAndReturnFullView = (index) => {
      let fullView = false;
      if (expandedLevelView) {
        if (!isInFullView) {
          if (Number(itemObject?.index) > -1) {
            if (options.absenceToggle && itemObject?.index === parseInt(index)) {
              fullView = true
            }
            if (!options.absenceToggle && itemObject?.index === (parseInt(index) + 1)) {
              fullView = true
            }
          } else if (!itemObject?.index) {
            fullView = true
          }
        } else {
          fullView = true
        }
      }
      return fullView
    }
    return <>
      {!expandedLevelView && <div style={{
        width: '100%',
        height: '1px',
        // transform: 'rotate(-90deg)',
        flexShrink: 0,
        strokeWidth: '100%',
        background: '#DCE5E0',
        position: 'absolute',
        top: '50%'
      }}>

      </div>}
      <div className={expandedLevelView ? classes.selectionExpanded : classes.selection}>

        {expandedLevelView ? (
          <>
            <Box display={"flex"} alignItems={"center"} flexDirection={"column"} width={"100%"} gap={'7px'}>
              {optionsData.map((it, index) => checkAndReturnFullView(index) && (
                <div className={expandedLevelView ? classes.rectangleInputFull : classes.rectangleInput} style={expandedLevelView ? {} : {
                  background: '#DCE5E0'
                }}
                  onClick={(e) => {
                    e.stopPropagation()
                    if (options.influenceId) {
                      createInfluence(itemObject?.index > -1, it.score || null, it.score, itemObject?.note, options.absenceToggle ? parseInt(index) : (parseInt(index) + 1), options.type)
                    }
                    if (options?.outcomeId) {
                      createOutcome(itemObject?.index > -1, it.score || null, it.score, itemObject?.note, options.absenceToggle ? parseInt(index) : (parseInt(index) + 1))
                    }
                  }}
                >
                  {options.absenceToggle ? itemObject?.index === parseInt(index) && tickMark : itemObject?.index === (parseInt(index) + 1) && tickMark}
                  <div className={classes.rectangleInput} style={{
                    background: options.absenceToggle ? itemObject?.index === parseInt(index) ? it.colorCode : '#DCE5E0' : itemObject?.index === (parseInt(index) + 1) ? it.colorCode : '#DCE5E0'  // 
                  }}

                  >

                    <Typography className={classes.counterView} sx={{
                      color: '#FFF !important',
                      textAlign: 'center !important',
                      fontSize: '16px',
                      fontWeight: 500,
                      lineHeight: '100%', /* 16px */
                      transform: 'rotate(90deg)',
                    }}>
                      {options.absenceToggle ? index : index + 1}
                    </Typography>
                  </div>
                  <Typography className={classes.counterView} sx={{
                    color: '#000 !important',
                    textAlign: 'left !important',
                    fontSize: '16px',
                    fontWeight: 500,
                    lineHeight: '135% !important', /* 16px */
                    "&.MuiTypography-root": {
                      "@media  (max-width: 600px) and (min-width: 100px)": {
                        fontSize: `16px !important`,
                        lineHeight: "135% !important"
                      },
                    }
                  }}>
                    {it.text || ''}
                  </Typography>
                </div>
              ))}
            </Box>
            {!isInFullView && Number(itemObject?.index) > -1 && (
              <div onClick={(e) => {
                e.stopPropagation()
                setIsInFullView(true)
              }}>
                <EditIcon />
              </div>
            )}
          </>
        ) : (
          <>
            {optionsData.map((it, index) => (
              <div className={classes.rectangleInput} style={{
                background: options.absenceToggle ? Number(itemObject?.index) === parseInt(index) ? it.colorCode : '#DCE5E0' : Number(itemObject?.index) === (parseInt(index) + 1) ? it.colorCode : '#DCE5E0'  // 
              }}
                onClick={(e) => {
                  e.stopPropagation()
                  if (options.influenceId) {
                    createInfluence(itemObject?.index > -1, it.score || null, it.score, itemObject?.note, options.absenceToggle ? parseInt(index) : (parseInt(index) + 1), options.type)
                  }
                  if (options?.outcomeId) {
                    createOutcome(itemObject?.index > -1, it.score || null, it.score, itemObject?.note, options.absenceToggle ? parseInt(index) : (parseInt(index) + 1))
                  }
                }}
              >
                {options.absenceToggle ? Number(itemObject?.index) === parseInt(index) && tickMark : Number(itemObject?.index) === (parseInt(index) + 1) && tickMark}
                <Typography className={classes.counterView} sx={{
                  color: '#FFF !important',
                  textAlign: 'center !important',
                  fontSize: '16px',
                  fontWeight: 500,
                  lineHeight: '100%', /* 16px */
                  transform: 'rotate(90deg)',
                }}>
                  {options.absenceToggle ? index : index + 1}
                </Typography>
              </div>
            ))}
          </>
        )}

      </div>
    </>
  }
  let showNewView = props?.type === "outcome" || ((props?.item?.type === "TYPE2") && (
    props?.item?.unitValue !== "timeofday" &&
    props?.item?.metric_type !== "NEUTRAL"
  )) ? true : false
  const classNaaame = showNewView ? classes.cardBodyNew : classes.cardBody
  return (
    <div className={classNaaame} key={props?.index + '_' + props.headerDate} onClick={() => {
      if (clickCondition) {
        props?.setItem(props?.item);
        if (props?.type === "outcome") {
          setShowInput(false);
          props?.setOpenTrackingEventModal(true);
          props?.setDisplayBorder(true);
          props?.setItem(props?.item);
          props?.setOutcomeId(props?.item?._id);
          props?.setSelectedOption(parseInt(props?.optionIndex + 1));
          let i = outcomeState.outcomeList.findIndex(
            (outcome) => outcome._id === props?.item?._id
          );
          props?.setOutcomeIndex(i);
        } else if (props?.type === "influence") {
          if (
            (clickCondition && props?.item?.type === "TYPE3") ||
            (clickCondition &&
              props?.item?.type === "TYPE1" &&
              props?.item?.flag === "yes")
          ) {
            props?.setOpenYesNoModal(true);
            props.setInfluenceId(props?.item._id);
            return
          }
          if (
            clickCondition &&
            props?.item?.type === "TYPE12" &&
            clickCondition &&
            props?.item?.unitValue === "timeofday" &&
            props?.item?.metric_type === "NEUTRAL"
          ) {
            props?.setOpenTimeOfDayModal(true);
            props?.setCategoryId(props?.category?.categoryId)
            props.setInfluenceId(props?.item._id);
            return
          } else if (
            clickCondition &&
            props?.item?.type === "TYPE12" &&
            props?.item?.flag === "no"
          ) {
            setShowInput(false);
            props?.setOpenHoursModal(true);
            props?.setInfluenceId(props?.item._id);
            props?.setItem(props?.item);
          }
          if (showNewView) {
            setShowInput(false);
            props?.setOpenTrackingEventModal(true);
            props?.setDisplayBorder(true);
            props.setInfluenceId(props?.item._id);
            props?.setItem(props?.item);
            props?.setSelectedOption(parseInt(props?.optionIndex + 1));
            let i = outcomeState.influenceList.findIndex(
              (influence) => influence._id === props?.item?._id
            );
            props?.setOutcomeIndex(i);
          }
          if (!showNewView) {
            setShowInput(true);
          }
          setNoData(false);
          props.setInfluenceId(props?.item._id);
        } else {
          props?.setRefId(props?.item.reflectiveId);
          props?.setOpenRefQuesModal(true);
        }
      }
    }} onDoubleClick={() => {
      setNoData(true);
      if (props?.type === "outcome") {
        if (clickCondition) {
          setShowInput(false);
          props?.setOpenTrackingEventModal(true);
          props?.setDisplayBorder(true);
          props?.setItem(props?.item);
          props?.setOutcomeId(props?.item?._id);
          props?.setSelectedOption(parseInt(props?.optionIndex + 1));
          let i = outcomeState.outcomeList.findIndex(
            (outcome) => outcome._id === props?.outcomeId
          );
          props?.setOutcomeIndex(i);
        }
      } else if (props?.type == "influence") {
        if (clickCondition && props?.item?.type === "TYPE2") {
          setShowInput(false);
          props?.setOpenTrackingEventModal(true);
          props?.setDisplayBorder(true);
          props.setInfluenceId(props?.item._id);
          props?.setItem(props?.item);
          props?.setSelectedOption(parseInt(props?.optionIndex + 1));
          let i = outcomeState.influenceList.findIndex(
            (influence) => influence._id === props?.influenceId
          );
          props?.setOutcomeIndex(i);
        } else if (
          (clickCondition && props?.item?.type === "TYPE3") ||
          (clickCondition &&
            props?.item?.type === "TYPE1" &&
            props?.item?.flag === "yes")
        ) {
          props?.setOpenYesNoModal(true);
        } else if (
          clickCondition &&
          props?.item?.type === "TYPE12" &&
          props?.item?.flag === "no"
        ) {
          setShowInput(false);
          props?.setOpenHoursModal(true);
          props?.setInfluenceId(props?.item._id);
          props?.setItem(props?.item);
        }
      }
    }} style={{
      position: "relative"
    }}>
      <div style={{
        position: "absolute",
        top: 5,
        right: 5
      }} onClick={(e) => {
        e.stopPropagation()
        if (props?.type === "outcome") {
          props?.setAddOutcomeTracking(true);
          props?.setStartModal(true);
          props?.setOutcomeValue(props?.item);
        }
        if (props?.type === "influence") {
          props?.setAddInfluenceTracking(true);
          props?.setStartModalInfluence(true);
          props?.setInfluenceData(props?.item)
        }
        if (props?.type === "reflective") {
          props?.setAddQuesTracking(true);
          props?.setStartModalQues(true);
          props?.setQuestionData(props?.item)
        }
        // props.setStartModal(true);
        // setOutcomeId(id);

        // editOutcomeTracking(props.outcome, props.outcome._id)
        // props?.setopenUnavailablecategoryMessage("To edit this metric, please access the app on your desktop browser at")
        // props?.setopenUnavailablecategory(true)
      }}><EditIcon size={"15"} /></div>
      <div style={{
        paddingRight: "14px",

      }}>
        <Typography variant="h4" component="h4" style={{ fontWeight: 500, wordBreak: "break-all" }}>
          {props?.title}
        </Typography>

        {/* <img src={LineGraph} className={classes.lineGraph} /> */}
      </div>
      <div
        className={(props?.item?.type === "TYPE3") ||
          (
            props?.item?.type === "TYPE1" &&
            props?.item?.flag === "yes") ? "" : showNewView ? classes.inputContainerFullWidth : classes.inputContainer}

        key={props.headerDate}
        style={foundData ? {
          borderColor: "transparent"
        } : {}}
        id={`${props.headerDate}`}
      >
        {showInput ? (
          <input
            className={classes.rateInput}
            autoFocus
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
            onBlur={() => {
              props?.type === "outcome"
                ? handleBlurInput(
                  props?.item?._id,
                  props?.index,
                  props.headerDate,
                  { key: "kdksjdks", keyCode: "hdj" },
                  true
                )
                : handleBlurInputInfluence(props?.item?.type, { key: "kdksjdks", keyCode: "hdj" }, true)
            }}
            onKeyDown={(e) =>
              props?.type === "outcome"
                ? handleBlurInput(
                  props?.item?._id,
                  props?.index,
                  props.headerDate,
                  e,
                  false
                )
                : handleBlurInputInfluence(props?.item?.type, e, false)
            }

          />
        ) : foundData && (
          <>
            {journeyDataOutcome?.map((journey, index) => {
              if (
                journey?.outcomeId === props?.item?.outcomeId &&
                journey?.date === props.headerDate
              ) {
                let nearValue = nearestValueOutcome(journey?.index, journey)

                if (journey?.type === "TYPE2") {
                  nearValue = null
                }
                if (props?.item?.outcomeId && props?.item?.options?.length && props?.item?.options[journey?.index - 1]) {
                  nearValue = props.item.options[journey?.index - 1].colorCode
                }
                if (Number(journey?.score) === 0) {
                  nearValue = '#C7C7C7'
                }
                // return (
                //   <span
                //     style={
                //       journey?.note.length > 0
                //         ? {
                //           padding: "2px",
                //           border: `1px solid ${journey.colorCode}`,
                //           borderRadius: "50%",
                //           width: "100%",
                //           height: "100%",
                //           display: "flex",
                //         }
                //         : {
                //           border: `1px solid ${journey.colorCode}`,
                //           backgroundColor: journey.colorCode,
                //           width: "100%",
                //           height: "100%",
                //           borderRadius: "50%",
                //           display: "flex",
                //           fontWeight: 600,
                //           fontSize: "23px",
                //           lineHeight: "100%",
                //         }
                //     }
                //     key={journey?.date}
                //   >
                //     <span
                //       style={{
                //         backgroundColor: nearValue ? nearValue : journey.colorCode,
                //         border: `1px solid ${nearValue ? nearValue : journey.colorCode}`,
                //         borderRadius: "50%",
                //         color: palette.common.white,
                //         display: "flex",
                //         width: "100%",
                //         justifyContent: "center",
                //         alignItems: "center",
                //       }}
                //     >
                //       {journey?.index}
                //       {/* {"2"} */}
                //     </span>
                //   </span>
                // );
                return levelRender({
                  ...journey,
                  nearValue
                }, props?.item)
              }
            })}
            {journeyDataInfluence?.map((journey: any, index: number) => {
              if (
                journey?.influenceId === props?.item?.influenceId &&
                journey?.date === props.headerDate
              ) {
                noData = false
                NoData && setNoData(false);
                let nearValue = nearestValue(
                  journey?.type === "TYPE1" ||
                    journey?.type === "TYPE12"
                    ? journey?.score
                    : journey?.index, props?.item)
                if (journey?.type === "TYPE2") {
                  if (props?.item?.type === "TYPE2" && props?.item.options.length && props?.item.options[journey?.index - 1]) {
                    nearValue = props?.item.options[journey?.index - 1].colorCode
                  }
                }
                if ((props?.item?.type === "TYPE3") ||
                  (
                    props?.item?.type === "TYPE1" &&
                    props?.item?.flag === "yes")) {
                  if (!journey?.levelReach || !visibleYesnotSwitch) {
                    if (journey?.inverse == "true") {
                      if (journey?.levelReach === "No") {
                        return <div onClick={(e) => {
                          e.stopPropagation()
                          setVisibleSwitch(true)
                        }}>
                          <Box width={"40px"} height={"40px"} sx={{
                            backgroundColor: "var(--color-new-main)",
                            borderRadius: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                          }} >
                            <CloseIcon sx={{
                              color: "#fff",
                              strokeWidth: "10px",
                              "path": {
                                stroke: "#fff",
                                strokeWidth: "4px",
                              }
                            }} />
                          </Box>
                        </div>
                      }
                      if (journey?.levelReach === "Yes") {
                        return <div onClick={(e) => {
                          e.stopPropagation()
                          setVisibleSwitch(true)
                        }}><Box width={"40px"} height={"40px"} sx={{
                          backgroundColor: "#F17F79",
                          borderRadius: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center"
                        }}>
                            <CheckIcon sx={{
                              color: "#fff",
                              strokeWidth: "10px",
                              "path": {
                                stroke: "#fff",
                                strokeWidth: "4px",
                              }
                            }} />


                          </Box>
                        </div>
                      }
                    } else {
                      if (journey?.levelReach === "Yes") {
                        return <div onClick={(e) => {
                          e.stopPropagation()
                          setVisibleSwitch(true)
                        }}>
                          <Box width={"40px"} height={"40px"} sx={{
                            backgroundColor: "var(--color-new-main)",
                            borderRadius: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                          }} >
                            <CheckIcon sx={{
                              color: "#fff",
                              strokeWidth: "10px",
                              "path": {
                                stroke: "#fff",
                                strokeWidth: "4px",
                              }
                            }} />
                          </Box>
                        </div>
                      }
                      if (journey?.levelReach === "No") {
                        return <div onClick={(e) => {
                          e.stopPropagation()
                          setVisibleSwitch(true)
                        }}><Box width={"40px"} height={"40px"} sx={{
                          backgroundColor: "#F17F79",
                          borderRadius: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center"
                        }}>
                            <CloseIcon sx={{
                              color: "#fff",
                              strokeWidth: "10px",
                              "path": {
                                stroke: "#fff",
                                strokeWidth: "4px",
                              }
                            }} />

                          </Box>
                        </div>
                      }
                    }

                  }
                  return <IconYesNo matricValue={journey} journalItem={props?.item} onClick={createInfluenceLevel} />
                }
                if (Number(journey?.score) === 0 && journey?.type === "TYPE2") {
                  nearValue = '#C7C7C7'
                }
                if (showNewView) {
                  return levelRender({
                    ...journey,
                    nearValue
                  }, props?.item)
                }
                return (
                  <div
                    key={index}
                    style={{ width: "100%" }}
                    // onClick={() => {
                    //   if (clickCondition) {
                    //     setShowInput(true);
                    //     props?.setDisplayBorder(true);
                    //     props.setInfluenceId(item._id);
                    //     setItem(item);
                    //   } else if (
                    //     clickCondition &&
                    //     item?.type === "TYPE12" &&
                    //     item?.flag === "no"
                    //   ) {
                    //     setShowInput(true);
                    //     props.setInfluenceId(item._id);
                    //     setItem(item);
                    //   } else if (
                    //     (clickCondition && item?.type === "TYPE3") ||
                    //     (clickCondition &&
                    //       item?.type === "TYPE1" &&
                    //       item?.flag === "yes")
                    //   ) {
                    //     props.setInfluenceId(item._id);
                    //     setItem(item);
                    //     setOpenYesNoModal(true);
                    //   } else if (
                    //     (clickCondition && item?.type === "TYPE12") &&
                    //     (clickCondition && item?.unitValue === "timeofday" && item?.metric_type === "NEUTRAL")
                    //   ) {
                    //     props.setInfluenceId(item._id);
                    //     setItem(item);
                    //     setOpenTimeOfDayModal(true);
                    //   }
                    // }}
                    onClick={(e) => {
                      if (
                        clickCondition &&
                        props?.item?.type === "TYPE12" &&
                        props?.item?.flag === "no"
                      ) {
                        e.stopPropagation()
                        setShowInput(true);
                        props?.setInfluenceId(props?.item._id);
                        props?.setItem(props?.item);
                      }
                    }}
                  >
                    <span
                      style={
                        journey?.note?.length > 0
                          ? {
                            padding: "2px",
                            border: `1px solid ${nearValue ? nearValue : journey.colorCode
                              ? journey.colorCode
                              : (journey?.type === "TYPE3" &&
                                journey?.levelReach === "No") ||
                                journey?.levelReach === "No"
                                ? (journey?.inverse === "true" ? palette.primary.main : "#F17F79")
                                : (journey?.inverse === "true" ? "#F17F79" : "var(--color-new-main)")
                              }`,
                            borderRadius: "50%",
                            width: "100%",
                            display: "flex",
                            height: "100%",
                            position: "relative",
                          }
                          : {
                            border: `1px solid ${nearValue ? nearValue : journey.colorCode
                              ? journey.colorCode
                              : (journey?.type === "TYPE3" &&
                                journey?.levelReach === "No") ||
                                journey?.levelReach === "No"
                                ? (journey?.inverse === "true" ? palette.primary.main : "#F17F79")
                                : (journey?.inverse === "true" ? "#F17F79" : "var(--color-new-main)")
                              }`,
                            backgroundColor: nearValue ? nearValue : journey.colorCode
                              ? journey.colorCode
                              : (journey?.type === "TYPE3" &&
                                journey?.levelReach === "No") ||
                                journey?.levelReach === "No"
                                ? (journey?.inverse === "true" ? palette.primary.main : "#F17F79")
                                : (journey?.inverse === "true" ? "#F17F79" : "var(--color-new-main)"),
                            width: "100%",
                            borderRadius: "50%",
                            display: "flex",
                            height: "100%",
                            position: "relative",
                            fontWeight: 600,
                            fontSize: "23px",
                            lineHeight: "100%",
                          }
                      }
                    >
                      <span
                        style={{
                          backgroundColor: nearValue ? nearValue : journey.colorCode
                            ? journey.colorCode
                            : (journey?.type === "TYPE3" &&
                              journey?.levelReach === "No") ||
                              journey?.levelReach === "No"
                              ? (journey?.inverse === "true" ? palette.primary.main : "#F17F79")
                              : (journey?.inverse === "true" ? "#F17F79" : "var(--color-new-main)"),
                          borderRadius: "50%",
                          color: palette.common.white,
                          border: `1px solid ${nearValue ? nearValue : journey.colorCode
                            ? journey.colorCode
                            : (journey?.type === "TYPE3" &&
                              journey?.levelReach === "No") ||
                              journey?.levelReach === "No"
                              ? (journey?.inverse === "true" ? palette.primary.main : "#F17F79")
                              : (journey?.inverse === "true" ? "#F17F79" : "var(--color-new-main)")
                            }`,
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {journey?.timeOfDay ? (
                          <div className={classes.clock}>
                            <div
                              className={clsx(classes.hand, classes.hour)}
                              style={{
                                transform: `rotate(${journey?.timeOfDay.split(":")[0] * 30 +
                                  journey?.timeOfDay.split(":")[1] * (360 / 720)
                                  }deg)`,
                              }}
                            ></div>
                            <div
                              className={clsx(classes.hand, classes.minute)}
                              style={{
                                transform: `rotate(${journey?.timeOfDay.split(":")[1] * 6
                                  }deg)`,
                              }}
                            ></div>
                          </div>
                        ) : journey?.type === "TYPE1" ||
                          journey?.type === "TYPE12" ? (
                          journey?.score
                        ) : (
                          journey?.index
                        )}
                      </span>
                    </span>
                  </div>
                );
              }
            })}
            {journeyDataReflective?.map((journey: any, index: number) => {
              if (
                journey?.reflectiveId === props?.item?.reflectiveId &&
                journey?.date === props.headerDate
              ) {
                NoData && setNoData(false);
                return (
                  <div
                    key={index}
                    style={{
                      width: "100%",
                      borderRadius: "50%",
                      overflow: "hidden",
                    }}
                  >
                    <span
                      style={{
                        backgroundColor: "var(--color-new-main)",
                        border: `1px solid ${"var(--color-new-main)"}`,
                        display: "flex",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <span
                        style={{
                          backgroundColor: "var(--color-new-main)",
                          borderRadius: "50%",
                          border: `1px solid ${"var(--color-new-main)"}`,
                        }}
                      ></span>
                    </span>
                  </div>
                );
              }
            })}
          </>
        )}
        {!foundData && !showInput ?
          showNewView ? levelRender({}, props?.item) : (
            (props?.item?.type === "TYPE3") ||
              (
                props?.item?.type === "TYPE1" &&
                props?.item?.flag === "yes") ? (
              <IconYesNo matricValue={null} journalItem={props?.item} onClick={createInfluenceLevel} />
            ) :
              <span className={classes.plusIcon} onClick={(e) => {
                if (
                  clickCondition &&
                  props?.item?.type === "TYPE12" &&
                  props?.item?.flag === "no"
                ) {
                  e.stopPropagation()
                  setShowInput(true);
                  props?.setInfluenceId(props?.item._id);
                  props?.setItem(props?.item);
                }
              }}>
                {/* <img src={PlusIcon} /> */}
                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M21.5 12H3.5" stroke="#5E5F5E" stroke-width="1.5" stroke-linejoin="round" />
                  <path d="M12.5 3L12.5 21" stroke="#5E5F5E" stroke-width="1.5" stroke-linejoin="round" />
                </svg>

                {/* <PlusIconSVG color="#5E5F5E" size="24" /> */}
              </span>
          ) : null}
      </div>
    </div>
  );
};

const TrackerScreenMobile = (props) => {
  const classes = useStyles();
  const currentDateRef = useRef(null)
  const { state: outcomeState, dispatch: userTypeDispatch } =
    useContext(UserTypeContext);
  const { dispatch: trackingDispatch } =
    useContext(TrackingContext);
  const { Notification } = useNotifications()
  let userValues = JSON.parse(localStorage.getItem("userValues"));
  const [userDetails, setUserDetails] = useState<any>();
  const [ActiveTrackerType, setActiveTrackerType] = useState<any>(outcomeState.isPersonal ? "personal" : "Default");
  const [calendar, setCalendar] = useState([]);
  const [startDay, setStartDay] = useState<any>();
  const [endDay, setEndDay] = useState<any>();
  const [showLoaderPage, setShowLoaderPage] = useState(false);
  const [item, setItem] = useState<any>();
  const [categoryId, setCategoryId] = useState<any>();

  const [showSnakbar, setShowSnakbar] = useState(false);
  const [snakbarMsg, setSnakbarMsg] = useState("");
  const [msgType, setMsgType] = useState("");

  const [TheDay, setTheDay] = useState(moment());
  const [today, setToday] = useState(moment());
  const [isFirst, setIsFirst] = useState("");

  const [OpenTrackingEventModal, setOpenTrackingEventModal] = useState(false);

  const [outcomeId, setOutcomeId] = useState<any>();
  const [outcomeIndex, setOutcomeIndex] = useState<any>();
  const [influenceId, setInfluenceId] = useState("");
  // const [reflectiveId, setReflectiveId] = useState("");
  const [displayBorder, setDisplayBorder] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [optionIndex, setOptionIndex] = useState<any>("");
  const [selectedOption, setSelectedOption] = useState<any>();

  const [openHoursModal, setOpenHoursModal] = useState(false);
  const [openYesNoModal, setOpenYesNoModal] = useState(false);
  const [openTimeOfDayModal, setOpenTimeOfDayModal] = useState(false);
  const [openTrackingInfluenceModal, setopenTrackingInfluenceModal] =
    useState(false);
  const [openRefQuesModal, setOpenRefQuesModal] = useState(false);
  const [refId, setRefId] = useState("");
  const [activeId, setActiveId] = useState(0);
  const [BlurOn, setBlurOn] = useState(false);
  const [ShowMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  const [ImageFile, setImageFile] = useState(null);
  const [Uploaded, setUploaded] = useState(false);
  const [openUnavailablecategory, setopenUnavailablecategory] =
    useState(false);
  const [openUnavailablecategoryMessage, setopenUnavailablecategoryMessage] =
    useState("To create your own categories, please access the app on your desktop browser at");
  const hiddenFileInput = React.useRef(null);
  const width = useWidth();

  let registeredDate = moment(props.createDate);

  const influenceCreate = (inputValue, headerDate) => {
    setShowLoaderPage(false);
    const outcomeCreateData = {
      date: headerDate,
      influenceId: item?.influenceId,
      answerId: Number(inputValue) === 0 ? null : item?.options[parseInt(inputValue) - 1]?.answerId,
      score: parseInt(inputValue) === 0 ? 0 : item?.options[parseInt(inputValue) - 1].score,
      index: parseInt(inputValue),
      note: [],
      type: "TYPE2",
    };
    let oldData: any = {}
    if (!outcomeState?.isPersonal) {
      const foundOutcome = (outcomeState?.influenceList || []).find(it => it.influenceId === outcomeCreateData.influenceId);
      if (foundOutcome) {
        const journyList = { ...(outcomeState?.journeyList || {}) } as any
        const foundDateItemIcon = journyList.influence_data.findIndex(it => it.influenceId === outcomeCreateData.influenceId && it?.date === outcomeCreateData.date)
        if (foundDateItemIcon > -1) {
          oldData = { ...journyList.influence_data[foundDateItemIcon] }
        }
      }
    } else {
      const journeyCategoryList = [...(outcomeState?.journeyCategoryList || [])]
      const foundOutcome = (journeyCategoryList
        || []).findIndex(it => it.influenceIds && it.influenceIds.includes(outcomeCreateData.influenceId));
      if (foundOutcome > -1) {
        if (journeyCategoryList[foundOutcome].alls && journeyCategoryList[foundOutcome].alls.length) {
          const foundIndex = journeyCategoryList[foundOutcome].alls.findIndex(it => it.influenceId === outcomeCreateData.influenceId)
          if (foundIndex > -1) {
            if (journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses && journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses.length) {
              const foundDateValue = journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses.findIndex(it => it.date === outcomeCreateData.date)
              if (foundDateValue > -1) {
                oldData = { ...journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses[foundDateValue] }
              }
            }
          }
        }
      } else {
        const foundOther = (journeyCategoryList || []).findIndex(it => it.name === "Other")
        if (foundOther > -1) {
          const outcomeFound = journeyCategoryList[foundOther].influences.findIndex(it => it.influenceId === outcomeCreateData.influenceId);
          if (outcomeFound > -1) {
            if (journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses && journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses.length) {
              const foundDateValue = journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses.findIndex(it => it.date === outcomeCreateData.date)
              if (foundDateValue > -1) {
                oldData = { ...journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses[foundDateValue] }
              }
            }
          }
        }
      }
    }
    const foundOutcome = (outcomeState?.influenceList || []).find(it => it.influenceId === outcomeCreateData.influenceId);
    if (foundOutcome) {
      const journyList = { ...(outcomeState?.journeyList || {}) } as any
      const foundDateItemIcon = journyList.influence_data.findIndex(it => it.influenceId === outcomeCreateData.influenceId && it?.date === outcomeCreateData.date)
      if (foundDateItemIcon > -1) {
        journyList.influence_data[foundDateItemIcon] = {
          ...foundOutcome,
          ...outcomeCreateData,
        }
      } else {
        journyList.influence_data.push({
          ...foundOutcome,
          ...outcomeCreateData,
        } as any)
      }
      userTypeDispatch(addJourneyAction(journyList as any))
      // if (props.callToGetData) {
      //   props?.callService();
      // }
    }
    if (oldData?.note && oldData?.note.length) {
      outcomeCreateData.note = oldData?.note || []
    }
    trackingService
      .influenceCreate(outcomeState.token, outcomeCreateData)
      .then((data) => {
        if (data.showMessage && props?.headerDate === moment().format("MM-DD-YYYY")) {
          trackingDispatch(setDayCompleteMessage({
            open: data.showMessage,
            message: data.message
          }))
        }
        // setShowSnakbar(true);
        setSnakbarMsg(data?.message);
        // callservice();
        setShowLoaderPage(false);
        setMsgType("success");
        // setInputValue("");
      })
      .catch((error) => {
        setShowSnakbar(true);
        setSnakbarMsg(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message
        );
        setMsgType("error");
        setShowLoaderPage(false);
      });
  };
  const unitCreate = (inputValue, headerDate) => {
    // if (item?.options.filter((opt) => opt.value == inputValue).length > 0) {
    try {
      setShowLoaderPage(false);
      const outcomeCreateData = {
        influenceId: item?.influenceId,
        score: inputValue,
        note: [],
        answerId: item?.options?.filter((opt) => opt.value == inputValue)?.[0]?.answerId,
        date: headerDate,
        type: "TYPE12",
      };
      let oldData: any = {}
      if (!outcomeState?.isPersonal) {
        const foundOutcome = (outcomeState?.influenceList || []).find(it => it.influenceId === outcomeCreateData.influenceId);
        if (foundOutcome) {
          const journyList = { ...(outcomeState?.journeyList || {}) } as any
          const foundDateItemIcon = journyList.influence_data.findIndex(it => it.influenceId === outcomeCreateData.influenceId && it?.date === outcomeCreateData.date)
          if (foundDateItemIcon > -1) {
            oldData = { ...journyList.influence_data[foundDateItemIcon] }
          }
        }
      } else {
        const journeyCategoryList = [...(outcomeState?.journeyCategoryList || [])]
        const foundOutcome = (journeyCategoryList
          || []).findIndex(it => it.influenceIds && it.influenceIds.includes(outcomeCreateData.influenceId));
        if (foundOutcome > -1) {
          if (journeyCategoryList[foundOutcome].alls && journeyCategoryList[foundOutcome].alls.length) {
            const foundIndex = journeyCategoryList[foundOutcome].alls.findIndex(it => it.influenceId === outcomeCreateData.influenceId)
            if (foundIndex > -1) {
              if (journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses && journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses.length) {
                const foundDateValue = journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses.findIndex(it => it.date === outcomeCreateData.date)
                if (foundDateValue > -1) {
                  oldData = { ...journeyCategoryList[foundOutcome].alls[foundIndex].influenceResponses[foundDateValue] }
                }
              }
            }
          }
        } else {
          const foundOther = (journeyCategoryList || []).findIndex(it => it.name === "Other")
          if (foundOther > -1) {
            const outcomeFound = journeyCategoryList[foundOther].influences.findIndex(it => it.influenceId === outcomeCreateData.influenceId);
            if (outcomeFound > -1) {
              if (journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses && journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses.length) {
                const foundDateValue = journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses.findIndex(it => it.date === outcomeCreateData.date)
                if (foundDateValue > -1) {
                  oldData = { ...journeyCategoryList[foundOther].influences[outcomeFound].influenceResponses[foundDateValue] }
                }
              }
            }
          }
        }
      }
      if (oldData?.note && oldData?.note.length) {
        outcomeCreateData.note = oldData?.note || []
      }
      const foundOutcome = (outcomeState?.influenceList || []).find(it => it.influenceId === outcomeCreateData.influenceId);
      if (foundOutcome) {
        const journyList = { ...(outcomeState?.journeyList || {}) } as any
        const foundDateItemIcon = journyList.influence_data.findIndex(it => it.influenceId === outcomeCreateData.influenceId && it?.date === outcomeCreateData.date)
        if (foundDateItemIcon > -1) {
          journyList.influence_data[foundDateItemIcon] = {
            ...foundOutcome,
            ...outcomeCreateData,
          }
        } else {
          journyList.influence_data.push({
            ...foundOutcome,
            ...outcomeCreateData,
          } as any)
        }
        userTypeDispatch(addJourneyAction(journyList as any))
        // if (props.callToGetData) {
        //   props?.callService();
        // }
      }
      trackingService
        .influenceCreate(outcomeState.token, outcomeCreateData)
        .then((data) => {
          // setShowSnakbar(true);
          setSnakbarMsg(data?.message);
          // callservice();
          setShowLoaderPage(false);
          setMsgType("success");
          // setInputValue("");
        })
        .catch((error) => {
          setShowSnakbar(true);
          setSnakbarMsg(
            error?.response?.data?.message
              ? error?.response?.data?.message
              : error?.message
          );
          setMsgType("error");
          setShowLoaderPage(false);
        });
    } catch (error) {
      console.log("======== error", error);

    }

    // }
    // else {
    //   setShowSnakbar(true);
    //   setMsgType("error");
    //   setSnakbarMsg("Please enter value available on scale");
    // }
  };

  const outcomeCreate = (inputValue, headerDate) => {
    setShowLoaderPage(true);
    const outcomeCreateData = {
      date: headerDate,
      outcomeId: item?.outcomeId,
      answerId: Number(inputValue) === 0 ? null : item?.options[parseInt(inputValue) - 1].answerId,
      score: Number(inputValue) === 0 ? inputValue : item?.options[parseInt(inputValue) - 1].score,
      index: parseInt(inputValue),
      note: [],
    };
    let oldData: any = {}
    if (!outcomeState?.isPersonal) {
      const foundOutcome = (outcomeState?.outcomeList || []).find(it => it.outcomeId === outcomeCreateData.outcomeId);
      if (foundOutcome) {
        const journyList = { ...(outcomeState?.journeyList || {}) } as any
        const foundDateItemIcon = journyList.outcome_data.findIndex(it => it.outcomeId === outcomeCreateData.outcomeId && it?.date === outcomeCreateData.date)
        if (foundDateItemIcon > -1) {
          oldData = { ...journyList.outcome_data[foundDateItemIcon] }
          journyList.outcome_data[foundDateItemIcon] = {
            ...foundOutcome,
            ...outcomeCreateData,
          }
        } else {
          journyList.outcome_data.push({
            ...foundOutcome,
            ...outcomeCreateData,
          } as any)
        }
        userTypeDispatch(addJourneyAction(journyList as any))
      }
    } else {
      const journeyCategoryList = [...(outcomeState?.journeyCategoryList || [])]
      const foundOutcome = (journeyCategoryList
        || []).findIndex(it => it.outcomeIds && it.outcomeIds.includes(outcomeCreateData.outcomeId));
      if (foundOutcome > -1) {

        if (journeyCategoryList[foundOutcome].alls && journeyCategoryList[foundOutcome].alls.length) {
          const foundIndex = journeyCategoryList[foundOutcome].alls.findIndex(it => it.outcomeId === outcomeCreateData.outcomeId)
          if (foundIndex > -1) {
            if (journeyCategoryList[foundOutcome].alls[foundIndex].outcomesResponses && journeyCategoryList[foundOutcome].alls[foundIndex].outcomesResponses.length) {
              const foundDateValue = journeyCategoryList[foundOutcome].alls[foundIndex].outcomesResponses.findIndex(it => it.date === outcomeCreateData.date)
              if (foundDateValue > -1) {
                oldData = { ...journeyCategoryList[foundOutcome].alls[foundIndex].outcomesResponses[foundDateValue] }
              }
            }
          }
        }
        const journyList = { ...(outcomeState?.journeyList || {}) } as any
        const foundOutcomeNew = (outcomeState?.outcomeList || []).find(it => it.outcomeId === outcomeCreateData.outcomeId);
        const foundDateItemIcon = journyList.outcome_data.findIndex(it => it.outcomeId === outcomeCreateData.outcomeId && it?.date === outcomeCreateData.date)
        if (foundDateItemIcon > -1) {
          oldData = { ...journyList.outcome_data[foundDateItemIcon] }
          journyList.outcome_data[foundDateItemIcon] = {
            ...foundOutcomeNew,
            ...outcomeCreateData,
          }
        } else {
          journyList.outcome_data.push({
            ...foundOutcomeNew,
            ...outcomeCreateData,
          } as any)
        }
        userTypeDispatch(addJourneyAction(journyList as any))
      } else {
        const foundOther = (journeyCategoryList
          || []).findIndex(it => it.name === "Other")
        if (foundOther > -1) {
          const outcomeFound = journeyCategoryList[foundOther].outcomes.findIndex(it => it.outcomeId === outcomeCreateData.outcomeId);
          if (outcomeFound > -1) {
            if (journeyCategoryList[foundOther].outcomes[outcomeFound].outcomesResponses && journeyCategoryList[foundOther].outcomes[outcomeFound].outcomesResponses.length) {
              const foundDateValue = journeyCategoryList[foundOther].outcomes[outcomeFound].outcomesResponses.findIndex(it => it.date === outcomeCreateData.date)
              if (foundDateValue > -1) {
                oldData = { ...journeyCategoryList[foundOther].outcomes[outcomeFound].outcomesResponses[foundDateValue] }
              }
            }
          }
          const journyList = { ...(outcomeState?.journeyList || {}) } as any
          const foundOutcomeNew = (outcomeState?.outcomeList || []).find(it => it.outcomeId === outcomeCreateData.outcomeId);
          const foundDateItemIcon = journyList.outcome_data.findIndex(it => it.outcomeId === outcomeCreateData.outcomeId && it?.date === outcomeCreateData.date)
          if (foundDateItemIcon > -1) {
            oldData = { ...journyList.outcome_data[foundDateItemIcon] }
            journyList.outcome_data[foundDateItemIcon] = {
              ...foundOutcomeNew,
              ...outcomeCreateData,
            }
          } else {
            journyList.outcome_data.push({
              ...foundOutcomeNew,
              ...outcomeCreateData,
            } as any)
          }
          userTypeDispatch(addJourneyAction(journyList as any))
        }
      }
    }
    if (oldData?.note && oldData?.note.length) {
      outcomeCreateData.note = oldData?.note || []
    }
    trackingService
      .outcomeCreate(outcomeState.token, outcomeCreateData)
      .then((data) => {
        if (data.showMessage && props?.headerDate === moment().format("MM-DD-YYYY")) {
          trackingDispatch(setDayCompleteMessage({
            open: data.showMessage,
            message: data.message
          }))
        }
        setShowSnakbar(true);
        setSnakbarMsg(data?.message);
        // callservice();
        setShowLoaderPage(false);
        setMsgType("success");
      })
      .catch((error) => {
        setShowSnakbar(true);
        setSnakbarMsg(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message
        );
        setMsgType("error");
        setShowLoaderPage(false);
      });
  };

  const getOutcomeSuggestion = () => {
    outcomeService
      .getOutcomeSuggestion(outcomeState.token)
      .then((res: any) => {
        props.setOutcomeIdeas(res?.data);
      })
      .catch((error: any) => {
        console.log("Outcome suggestion error", error);
      });
  };

  const getInfluenceSuggestion = () => {
    influenceService
      .getInfluenceSuggestion(outcomeState.token)
      .then((res: any) => {
        props.setInfluenceIdeas(res?.data);
      })
      .catch((error: any) => {
        console.log("influence suggestion error", error);
      });
  };

  const getReflectiveSuggestionList = () => {
    reflectiveQueService
      .getRefelectiveSuggestion(outcomeState.token)
      .then((res: any) => {
        props.setReflectiveIdeas(res?.data);
      })
      .catch((error: any) => {
        console.log("Outcome suggestion error", error);
      });
  };

  const prevMonth = () => {

    if (isFirst === "next") {
      setIsFirst("prev");
      setToday(today.subtract(1, "month")); // today date
      setStartDay(today.clone().startOf("month")); // give start of the today date month
      props?.setMobileStartDay && props?.setMobileStartDay(today.clone().startOf("month"))
      if (today.clone().endOf("month").isBefore(registeredDate)) {
        props?.setMobileEndDay && props?.setMobileEndDay(registeredDate.clone().endOf("month"))
        setEndDay(registeredDate.clone().endOf("month"));
      } else {
        props?.setMobileEndDay && props?.setMobileEndDay(today.clone().endOf("month"))
        setEndDay(today.clone().endOf("month")); // give end of the today month
      }
    } else {
      setIsFirst("prev");
      setToday(today.subtract(1, "month"));
      setStartDay(today.clone().startOf("month"));
      props?.setMobileStartDay && props?.setMobileStartDay(today.clone().startOf("month"))
      // setEndDay(today.clone().endOf("month"));
      if (today.clone().endOf("month").isBefore(registeredDate)) {
        props?.setMobileEndDay && props?.setMobileEndDay(registeredDate.clone().endOf("month"))
        setEndDay(registeredDate.clone().endOf("month"));
      } else {
        props?.setMobileEndDay && props?.setMobileEndDay(today.clone().endOf("month"))
        setEndDay(today.clone().endOf("month")); // give end of the today month
      }
    }
  };

  const nextMonth = () => {
    if (isFirst === "prev") {
      setIsFirst("next");
      setToday(today.clone().add(1, "month"));
      props?.setMobileStartDay && props?.setMobileStartDay(today.clone().add(1, "month").startOf("month"))
      setStartDay(today.clone().add(1, "month").startOf("month"));
      props?.setMobileEndDay && props?.setMobileEndDay(today.clone().add(1, "month").endOf("month"))
      setEndDay(today.clone().add(1, "month").endOf("month"));
    } else {
      setIsFirst("next");
      setToday(today.clone().add(1, "month"));
      props?.setMobileStartDay && props?.setMobileStartDay(today.clone().add(1, "month").startOf("month"))
      setStartDay(today.clone().add(1, "month").startOf("month"));
      props?.setMobileEndDay && props?.setMobileEndDay(today.clone().add(1, "month").endOf("month"))
      setEndDay(today.clone().add(1, "month").endOf("month"));
    }
  };

  const callservice = async (showLoader = true) => {
    try {
      const bodyData = {
        from: moment(startDay).format("MM-DD-YYYY"),
        to: moment(endDay).format("MM-DD-YYYY"),
      };
      if (!outcomeState?.isPersonal) {
        let data = await trackingService.getJourneyList(
          outcomeState.token,
          bodyData
        );
        userTypeDispatch(addJourneyAction(data));
        userTypeDispatch(addNotesAction(data));
      } else {
        setShowLoaderPage(showLoader);
        props?.setShowLoader(showLoader)
        let data1 = await trackingService.getJourneyList(
          outcomeState.token,
          bodyData
        );
        userTypeDispatch(addJourneyAction(data1));
        userTypeDispatch(addNotesAction(data1));
        let data = await trackingService.getPersonalJourneyList(
          outcomeState.token,
          bodyData
        );
        userTypeDispatch(addCategoryJourneyAction(data?.data));
        userTypeDispatch(addNotesJourneyAction(data?.data));
        setShowLoaderPage(showLoader);
        props?.setShowLoader(showLoader)
      }
      setShowLoaderPage(false);
      props?.setShowLoader(false)
    } catch (error) {
      setShowLoaderPage(false);
      props?.setShowLoader(false)
    }
  };

  const trackingEvent = () => {
    const day = startDay?.clone().isBefore(registeredDate)
      ? registeredDate.clone().subtract(1, "day")
      : startDay?.clone().subtract(1, "day");

    const tempCalendar: any[][] = [];

    let startDayTemp = startDay?.clone();
    let endDayTemp = startDay?.clone();

    while (day?.isBefore(endDay, "day")) {
      let daysInMonth = startDayTemp
        .clone()
        .endOf("month")
        .diff(endDayTemp.clone(), "days");

      tempCalendar.push(
        Array(daysInMonth + 1)
          .fill(0)
          .map(() => {
            return day.add(1, "day").clone();
          })
      );

      startDayTemp = day.clone().add(1, "day");
      endDayTemp = endDay.clone();
    }
    setCalendar(tempCalendar);
  };
  const enumerateDaysBetweenDates = () => {
    if (startDay && endDay && props.createDate) {
      var dates = [];
      const currDate = startDay?.clone().isBefore(registeredDate)
        ? registeredDate.clone().subtract(1, "day")
        : startDay?.clone().subtract(1, "day");
      const lastDate = moment(endDay).add(1, "day").startOf('day');

      while (currDate?.add(1, 'days')?.diff(lastDate) < 0) {
        dates.push(currDate?.clone());
      }
      setCalendar([dates]);
      currentDateRef && currentDateRef.current && currentDateRef.current.scrollIntoView()
    }

  };
  React.useEffect(() => {
    // localStorage.setItem("showTutorial", "true")
    if (calendar.length === 0) {
      const current = moment()
      let startDate = moment().startOf('month');

      if (current?.diff(startDate, "day") < 7) {
        startDate = moment().startOf('month').subtract(7 - current?.diff(startDate, "day"), 'day').startOf('day')
      }
      const endOfMonth = moment().clone().endOf('month')
      if (endOfMonth?.diff(moment(), "day") >= 7) {
        setEndDay(moment().clone().endOf('month'));
        props?.setMobileEndDay && props?.setMobileEndDay(moment().clone().endOf('month'))
        // props?.setEndDate(moment().clone().endOf('month'))
      } else {
        // props?.setEndDate(moment().clone().endOf('month').add(7, 'day'))
        props?.setMobileEndDay && props?.setMobileEndDay(moment().clone().endOf('month').add(7, 'day'))
        setEndDay(moment().clone().endOf('month').add(7, 'day'));
      }

      props?.setMobileStartDay && props?.setMobileStartDay(startDate)
      setStartDay(startDate);
      // setStartDay(moment().clone().subtract(10, "day"));
      // setEndDay(moment().clone().add(15, "day"));
    }
    // trackingEvent();
    enumerateDaysBetweenDates()
    props.createDate && callservice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDay, endDay]);

  const handleCloseSnakbar = () => {
    setShowSnakbar(false);
  };

  const getOutcomeList = () => {
    outcomeService
      .getOutcomeList(outcomeState.token)
      .then((data: OutcomeGetResponse) => {
        userTypeDispatch(addOutcomeAction(data?.data));
      })
      .catch((error) => {
        console.log("Outcome Error");
      });
  };

  const getInfluenceList = () => {
    influenceService
      .getInfluenceList(outcomeState.token)
      .then((data: InfluenceGetResponse) => {
        console.log("outcomeState?.influenceList data?.data", data?.data);

        userTypeDispatch(addInfluenceAction(data?.data));
      })
      .catch((error) => {
        console.log("influence Error");
      });
  };

  const getReflectiveList = () => {
    reflectiveQueService
      .getReflectiveList(outcomeState.token)
      .then((data: RefelectiveGetResponse) => {
        userTypeDispatch(addRefelectiveAction(data?.data));
      })
      .catch((error) => {
        console.log("Ques error");
      });
  };

  const clickCondition = moment(props.betweenDate).isBetween(
    moment().subtract(8, "year").format("yyyy-MM-DD"),
    // moment().subtract(8, "day").format("yyyy-MM-DD"),
    moment().add(1, "day").format("yyyy-MM-DD")
  );

  const [openStartTracking, setOpenStartTracking] = useState(
    outcomeState?.isNewUser
  );

  const getUserDetails = () => {
    const userId = {
      uid: outcomeState?.userId ? outcomeState?.userId : userValues?.uid,
    };
    userService
      .getUserById(userId)
      .then((data: any) => {
        setUserDetails(data?.data);
        data?.data?.imageUrl !== "Not Updated"
          ? setImageFile(data?.data?.imageUrl)
          : setImageFile(null);
        localStorage.setItem("userValues", JSON.stringify(data?.data));
        localStorage.setItem("userDate", JSON.stringify(data?.data?.createdAt));
      })
      .catch((error) => {
        console.log("User profile Error");
      });
  };

  useEffect(() => {
    getOutcomeList();
    getUserDetails();
    getInfluenceList();
    getReflectiveList();
    getOutcomeSuggestion();
    getInfluenceSuggestion();
    getReflectiveSuggestionList();
  }, [outcomeState?.isPersonal]);
  const [notifications, setNotifications] = useState([])
  const [unreadNotifications, setUnreadNotifications] = useState([])
  useEffect(() => {
    if ((!notifications || !notifications.length) && !alreadyCalled) {
      notificationService.getNotificationList(outcomeState.token)
        .then((res) => {
          setNotifications(res?.notificationData)
          setUnreadNotifications(res?.unread)
          // notificationDispatch(updateNotificationData(res?.notificationData));
          // notificationDispatch(updateUnreadNotificationData(res?.unread));
        }).catch((err) => {
          console.log(err);
        })
        .finally(() => {
          // this.setState({
          //   buttonLoading: false
          // })
          // this.openCloseEditGoalModal(false)
        });
    }
  }, [])
  useEffect(() => {
    callservice()
  }, [outcomeState?.isPersonal])
  const renderButton = (isLeft, title, icon, active, onClick) => (
    <Button
      id={title + "_Button"}
      variant={/* !active ?  */ "outlined" /* : "contained" */}
      sx={{
        display: "flex",
        padding: "0px",
        height: "100%",
        width: "78px",
        alignItems: "center",
        gap: "8px",
        border: active ? "1px solid var(--color-svg)" : "1px solid var(--color-new-main)!important",
        borderRight: !isLeft ? active ? "1px solid var(--color-svg)" : "0px" : active ? "1px solid var(--color-svg)" : "0px",
        borderLeft: isLeft ? active ? "1px solid var(--color-svg)" : "0px" : active ? "1px solid var(--color-svg)" : "0px",
        marginBottom: "0 !important",
        boxShadow: "none",
        borderRadius: isLeft ? "40px 0 0 100px" : " 0 100px 40px 0",
        background: active ? "#fff" : "var(--color-new-main)",
        position: "relative",
        transform: "skewX(-20deg)",
        "&::after": {
          content: '""',
          position: "absolute",
          width: "0px",
          height: "52px",
          right: 0,
          bottom: 0,
        },
        "&:hover": {
          background: active ? "#fff" : "var(--color-new-main)",
        },
      }}
      onClick={onClick}
    >
      <div
        style={{
          transform: "skewX(20deg)",
          height: "100%",
          display: "flex",
          alignItems: "center"
        }}
      >
        {icon}
      </div>
    </Button>
  );
  const [openStartModal, setopenStartModal] = useState(false)
  const [expandedLevelView, setExpandedLevelView] = useState(localStorage.getItem("expandLevelView") === "true")
  return (
    <div style={{ display: "flex", overflowX: "hidden", overflowY: 'auto', background: "rgb(247, 249, 250)" }} className="hide-scrollbar">
      {showLoaderPage && <FullpageLoader />}
      <SnackBarComp
        showSnakbar={showSnakbar}
        handleCloseSnakbar={handleCloseSnakbar}
        snakbarMsg={snakbarMsg}
        type={msgType ? msgType : "success"}
      />
      {ShowMenu && (
        <div style={{ minWidth: "70vw", background: "#11734A" }}>
          <div style={{ marginTop: "40px", marginLeft: "30px" }}>
            <div className={classes.userContainer2}>
              <img
                alt="user"
                src={
                  ImageFile
                    ? Uploaded
                      ? URL.createObjectURL(ImageFile)
                      : `${baseEndpoints.getImage}${ImageFile}`
                    : DummyUser
                }
                style={{
                  height: 28,
                  width: 28,
                  marginRight: "8px",
                  borderRadius: "999px",
                }}
              />
              <input
                type="file"
                ref={hiddenFileInput}
                style={{ display: "none" }}
                onChange={(event) => {
                  setImageFile(event.target.files[0]);
                  setUploaded(true);
                }}
              />
              <Typography className={classes.userText2}>
                {userDetails?.first_name
                  ? userDetails?.first_name
                  : userValues?.first_name}{" "}
                {userDetails?.last_name
                  ? userDetails?.last_name
                  : userValues?.last_name}
              </Typography>
            </div>
          </div>
          <div
            style={{
              marginTop: "60px",
              display: "flex",
              flexDirection: "column",
              marginLeft: "30px",
              alignItems: "flex-start",
            }}
          >
            <button
              style={{
                background: "transparent",
                border: "none",
                color: "white",
              }}
            >
              <svg
                style={{ zIndex: 1, marginRight: "8px" }}
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M13.5 3H16.5V18H3.5V3H6.5" stroke="white"
                  strokeWidth="1.5" strokeLinejoin="round" />
                <path d="M6.5 14.5H13.5V13H6.5V14.5Z" fill="white" />
                <path d="M6.5 10.5H13.5V9H6.5V10.5Z" fill="white" />
                <rect x="6.5" y="1.5" width="7" height="3" stroke="white"
                  strokeWidth="1.5" strokeLinejoin="round" />
              </svg>
              Weekly report
            </button>
            <button
              style={{
                background: "transparent",
                border: "none",
                color: "white",
                marginTop: "30px",
              }}
            >
              <svg
                style={{ zIndex: 1, marginRight: "8px" }}
                width="18"
                height="10"
                viewBox="0 0 18 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path d="M14 1L17 6H13V9H5V6H1L4 1H14Z"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinejoin="round"
                />
              </svg>
              Archived metrics
            </button>
            <button
              style={{
                background: "transparent",
                border: "none",
                color: "white",
                marginTop: "30px",
              }}
            >
              <svg
                style={{ zIndex: 1, marginRight: "8px" }}
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M16.1107 5.42768C16.2227 5.31563 16.2964 5.17098 16.3211 5.01446L16.6795 2.74765L15.9387 2.63053L16.6795 2.74765C16.8123 1.90773 16.0858 1.18323 15.2462 1.31833L12.9863 1.682C12.8305 1.70706 12.6867 1.7806 12.5751 1.89214L9.61306 4.8542L3.89186 1.95052C3.60251 1.80366 3.25154 1.85954 3.0221 2.08898L2.15479 2.95629C2.01414 3.09694 1.93512 3.28771 1.93512 3.48662C1.93512 3.68553 2.01414 3.8763 2.15479 4.01695L6.34677 8.20893L3.68052 11.3844L1.79644 11.2675C1.48522 11.2482 1.19451 11.4235 1.06631 11.7077C0.938116 11.992 0.999176 12.3259 1.21967 12.5464L5.55423 16.881C5.77872 17.1054 6.12023 17.1643 6.40689 17.0278C6.69355 16.8914 6.86325 16.5893 6.83059 16.2735L6.62688 14.304L9.793 11.6552L13.9918 15.8539C14.1324 15.9946 14.3232 16.0736 14.5221 16.0736C14.721 16.0736 14.9118 15.9946 15.0524 15.8539L15.9197 14.9866C16.1492 14.7571 16.2051 14.406 16.0581 14.1166L13.1487 8.38959L16.1107 5.42768Z"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinejoin="round" />
              </svg>
              Holiday mode
            </button>
            <button
              style={{
                background: "transparent",
                border: "none",
                color: "white",
                marginTop: "30px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <svg
                style={{ zIndex: 1, marginRight: "8px" }}
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="20"
                viewBox="0 0 18 20"
                fill="none"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M17.4953 5.09513L9.00007 0.19043L0.504883 5.09513V14.9045L9.00007 19.8092L17.4953 14.9045V5.09513ZM9.00007 2.49983L15.4953 6.24983V13.7498L9.00007 17.4998L2.50488 13.7498V6.24983L9.00007 2.49983ZM6.17126 12.8278C6.92321 13.5757 7.93975 13.9968 9.00026 13.9998C10.0605 13.9966 11.0766 13.5755 11.8283 12.8278C13.3898 11.2658 13.3898 8.73382 11.8283 7.17182C11.0768 6.42384 10.0605 6.00266 9.00026 5.99982C7.3825 5.99982 5.92397 6.97417 5.30474 8.46874C4.68551 9.9633 5.0275 11.6837 6.17126 12.8278ZM7.03834 9.60925C7.22467 8.67346 8.0461 7.99961 9.00026 7.99981C9.53092 7.9986 10.04 8.20959 10.4143 8.58582C11.0889 9.26061 11.1928 10.318 10.6626 11.1112C10.1324 11.9045 9.11559 12.2127 8.23413 11.8474C7.35267 11.4821 6.85201 10.545 7.03834 9.60925Z"
                  fill="#FFF"
                />
              </svg>
              Settings
            </button>
            <button
              onClick={() => {
                localStorage.removeItem("userDetails");
                localStorage.removeItem("userValues");
                localStorage.removeItem("userId");
                localStorage.removeItem("userDate");
                localStorage.removeItem("categoryCount");
                navigate("/login");
              }}
              style={{
                background: "transparent",
                border: "none",
                color: "white",
                display: "flex",
                alignItems: "center",
                marginTop: "30px"
              }}
            >
              <svg
                style={{ zIndex: 1, marginRight: "8px" }}
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M14.7023 4C16.0691 4.99302 17.0864 6.39325 17.6085 8C18.1305 9.60675 18.1305 11.3375 17.6085 12.9443C17.0864 14.551 16.0691 15.9512 14.7023 16.9443C13.3355 17.9373 11.6894 18.4721 10 18.4721C8.31057 18.4721 6.6645 17.9373 5.29772 16.9443C3.93094 15.9512 2.91361 14.551 2.39155 12.9443C1.86948 11.3375 1.86948 9.60675 2.39155 8C2.91361 6.39326 3.93093 4.99303 5.29771 4"
                  stroke="#FFF"
                  strokeWidth="2"
                />
                <path d="M10 0.5V8.5" stroke="#FFF" strokeWidth="2" />
              </svg>
              Logout
            </button>
          </div>
        </div>
      )}
      <div style={{ minWidth: "100vw", height: '100vh', overflowY: 'auto' }} className="hide-scrollbar">
        <div style={{
          position: 'sticky', background: '#F7F9FA', top: '00px', zIndex: 1,

        }}>
          <div style={{ padding: "20px 20px 6px", marginBottom: "10px", height: "70px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Box onClick={() => setShowMenu(!ShowMenu)}>
              <MobileMenuIcon />
            </Box>
            {/*  */}
            <Box display={"flex"} alignItems={"center"} sx={{
              gap: "8px"
            }}>
              <div style={{
                display: 'flex',
                // width: '30px',
                // height: '30px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '6px',
              }} >
                <StrikeIcon isMobile />
                {userDetails?.streakDays && Number(userDetails?.streakDays) >= 0 && (
                  <Typography
                    sx={{
                      color: "#5E5F5E !important",
                      fontSize: "16px !important",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "100%",
                      "&.MuiTypography-root": {
                        "@media  (max-width: 600px) and (min-width: 100px)": {
                          fontSize: `${pxToRem(16)} !important`,
                          lineHeight: "100% !important"
                        },
                      }
                    }}
                  >
                    {userDetails?.streakDays}
                  </Typography>
                )}
              </div>
              <StyledBadge color="primary" badgeContent={unreadNotifications.length} max={99} ><div onClick={() => { setopenStartModal(true) }}><BellIcon isMobile /></div></StyledBadge>
            </Box>
          </div>
        </div>

        {openStartTracking && (
          <StartTrackingModal setOpenStartTracking={setOpenStartTracking} />
        )}
        <div className={classes.headerContainer} style={{ padding: "0px 20px 0px", }}>
          <div className={classes.userContainer}>
            <img
              alt="user"
              src={
                ImageFile
                  ? Uploaded
                    ? URL.createObjectURL(ImageFile)
                    : `${baseEndpoints.getImage}${ImageFile}`
                  : DummyUser
              }
              style={{
                height: 28,
                width: 28,
                marginRight: "12px",
                borderRadius: "999px",
              }}
            />
            <input
              type="file"
              ref={hiddenFileInput}
              style={{ display: "none" }}
              onChange={(event) => {
                setImageFile(event.target.files[0]);
                setUploaded(true);
              }}
            />
            <Typography className={classes.userText}>
              {userDetails?.first_name
                ? userDetails?.first_name
                : userValues?.first_name}{" "}
              {userDetails?.last_name
                ? userDetails?.last_name
                : userValues?.last_name}
            </Typography>
          </div>

        </div>
        <div className={classes.titleContainer} style={{ padding: "0px 20px 0px", }}>
          <Typography variant="h1" component="h1" className={classes.pageTitle} sx={{
            "&.MuiTypography-root": {
              "@media  (max-width: 600px) and (min-width: 100px)": {
                fontSize: `${pxToRem(24)} !important`,
                fontWeight: 600,
                lineHeight: "100% !important"
              },
            }
          }}>
            Your daily tracking
          </Typography>
        </div>
        <div style={{
          position: 'sticky', background: '#F7F9FA', top: '70px', zIndex: 1, padding: '0px 20px'
        }}>
          {calendar.map((month) => (
            <div className={`${classes.calenderContainer} hide-scrollbar`}>
              <Box
                display="flex"
                alignItems="center"
                sx={{ marginBottom: "7px" }}
              >
                <span
                  // className={classes.iconButtonCalender}
                  onClick={prevMonth}
                  // disabled={
                  //   !moment(userValues?.createdAt).isBefore(moment(startDay))
                  // }
                  style={{
                    color: !moment(userValues?.createdAt).isBefore(
                      moment(startDay)
                    )
                      ? "gray"
                      : "black",
                    marginRight: "5px",
                    cursor: "pointer",
                  }}
                >
                  &lt;
                </span>
                <Typography
                  variant="h2"
                  component="h2"
                  className={classes.monthTitle}
                  sx={{
                    "&.MuiTypography-root": {
                      "@media  (max-width: 600px) and (min-width: 100px)": {
                        fontSize: `${pxToRem(16)} !important`,
                        fontWeight: 500,
                        lineHeight: "100% !important"
                      },
                    }
                  }}
                  style={{ fontWeight: 500 }}
                >
                  {month[0].format("MMMM")}
                </Typography>
                <span
                  // className={classes.iconButtonCalender}
                  onClick={nextMonth}
                  // disabled={moment(props?.endDay).month() === moment().month()}
                  style={{
                    color: "gray",
                    marginLeft: "5px",
                    cursor: "pointer",
                  }}
                >
                  &gt;
                </span>
              </Box>
              <Box width={"100%"} sx={{
                overflowY: "auto"
              }} className="hide-scrollbar">
                <InsideMonth
                  month={month}
                  TheDay={TheDay}
                  setTheDay={setTheDay}
                  currentDateRef={currentDateRef}
                />
              </Box>

            </div>
          ))}
        </div>
        {/* <Box display={"flex"} alignItems={"center"} alignSelf={"stretch"} justifyContent={"center"} marginTop={"18px"} marginBottom={"18px"} >
          <Box display={"inline-flex"} alignItems={"center"} sx={{
            background: "#EBF2F1",
            gap: "22px"
          }} >

            <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"} id={"mode-switch-tab"} sx={{
              height: "32px",
              padding: "4px 0px 4px 4px",
              borderRadius: "6px"
            }} onClick={() => {
              localStorage.setItem("expandLevelView", "false")

              setExpandedLevelView(false)

            }}>
              <Box sx={{
                height: "24px",
                width: "71px",
                borderRadius: "4px",
                backgroundColor: !expandedLevelView ? palette.primary.main : "transparent"
              }}>
                <Box sx={{
                  height: "22px",
                  width: "71px",
                  borderRadius: "6px",
                  padding: "0px 23px 0px 24px",

                }} alignItems={"center"} justifyContent={"center"}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <rect x="4" y="5" width="16" height="5" rx="1" stroke={!expandedLevelView ? "#F7F9FA" : "#222222"} strokeLinejoin="round" />
                    <rect x="4" y="14" width="16" height="5" rx="1" stroke={!expandedLevelView ? "#F7F9FA" : "#222222"} strokeLinejoin="round" />
                  </svg>
                </Box>
              </Box>
            </Box>
            <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"} sx={{
              height: "32px",
              padding: "4px 0px 4px 4px",
              borderRadius: "6px"
            }} onClick={() => {
              localStorage.setItem("expandLevelView", "true")
              setExpandedLevelView(true)
            }}>
              <Box sx={{
                height: "24px",
                width: "71px",
                borderRadius: "4px",
                backgroundColor: expandedLevelView ? palette.primary.main : "transparent"
              }}>
                <Box sx={{
                  height: "22px",
                  width: "71px",
                  borderRadius: "6px",
                  padding: "0px 23px 0px 24px",

                }} alignItems={"center"} justifyContent={"center"}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <rect x="2" y="3" width="20" height="9" rx="2" stroke={expandedLevelView ? "#F7F9FA" : "#222222"} />
                    <path d="M16 18V18C16 19.1046 15.1046 20 14 20H10C8.89543 20 8 19.1046 8 18V18" stroke={expandedLevelView ? "#F7F9FA" : "#222222"} />
                    <path d="M19 14V14C19 15.1046 18.1046 16 17 16H7C5.89543 16 5 15.1046 5 14V14" stroke={expandedLevelView ? "#F7F9FA" : "#222222"} />
                  </svg>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box> */}
        <Box display={"flex"} alignItems={"center"} alignSelf={"stretch"} justifyContent={"center"} marginTop={"8px"} marginBottom={"18px"} height={"29px"}>
          <div className={classes.swicthButtons}>
            <HTMLTooltip
              customIcon={renderButton(
                true,
                "Compact",
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <rect x="4" y="5" width="16" height="5" rx="1" stroke={"#000000"} strokeLinejoin="round" />
                  <rect x="4" y="14" width="16" height="5" rx="1" stroke={"#000000"} strokeLinejoin="round" />
                </svg>,
                expandedLevelView === true,
                () => {
                  localStorage.setItem("expandLevelView", "false")
                  setExpandedLevelView(false)
                }
              )}
              title="View only numbers and icons."
              sx={{
                "& .MuiTooltip-tooltip": {
                  maxWidth: "300px",
                },
              }}
              fontProps={{
                fontSize: `${pxToRem(12)} !important`,
              }}
            />
            <HTMLTooltip
              customIcon={renderButton(
                false,
                "Expanded",
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <rect x="2" y="3" width="20" height="9" rx="2" stroke={"#222222"} />
                  <path d="M16 18V18C16 19.1046 15.1046 20 14 20H10C8.89543 20 8 19.1046 8 18V18" stroke={"#222222"} />
                  <path d="M19 14V14C19 15.1046 18.1046 16 17 16H7C5.89543 16 5 15.1046 5 14V14" stroke={"#222222"} />
                </svg>,
                expandedLevelView === false,
                () => {
                  localStorage.setItem("expandLevelView", "true")
                  setExpandedLevelView(true)
                }
              )}
              title="View comments to understand entries."
              sx={{
                "& .MuiTooltip-tooltip": {
                  maxWidth: "300px",
                },
              }}
              fontProps={{
                fontSize: `${pxToRem(12)} !important`,
              }}
            />
          </div>
        </Box>

        <div className={classes.cardBG} style={{ padding: "32px 20px 0", }}>
          <div className={classes.accordionContainer} >
            <div className={classes.mainTools}>
              <div className={classes.toggleContainer}>
                <div
                  className={`${classes.toggleButton} ${ActiveTrackerType === "personal" && classes.activeToggle
                    }`}
                  onClick={() => {
                    localStorage.setItem("mode", "personal")
                    setActiveTrackerType("personal")
                    userTypeDispatch(isPersonalMode(true))
                  }}
                  id={"mobile-personal-mode"}
                >
                  <UserIcon size={16} color="#5E5F5E" />
                </div>
                <div
                  className={`${classes.toggleButton} ${ActiveTrackerType === "Default" && classes.activeToggle
                    }`}
                  onClick={() => {
                    localStorage.setItem("mode", "Default")
                    setActiveTrackerType("Default")
                    userTypeDispatch(isPersonalMode(false))
                  }}
                >
                  <NormalMode size={16} color="#5E5F5E" />
                </div>
              </div>
              <Box display={"flex"} alignItems={"center"} gap={"12px"}>
                {/* {!expandedLevelView && <ArrowCircleDownOutlinedIcon sx={{ color: "#11734A" }} onClick={() => setExpandedLevelView(true)} />}
                {expandedLevelView && <ArrowCircleUpOutlinedIcon sx={{
                  color: "#11734A"
                }} onClick={() => setExpandedLevelView(false)} />} */}
                <IconButton sx={{}} onClick={() => {
                  setopenUnavailablecategoryMessage("To share your reports, please access the app on your desktop browser at")
                  setopenUnavailablecategory(true)
                }} /* onClick={() => props.handleShareModalOpen(true)} */ id="share-button">
                  <ShareIcon color="#3E4240" size={16} />
                </IconButton>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  onClick={() => {
                    setopenUnavailablecategory(true)
                  }}
                  id={"mobile-add-category"}
                >
                  <path
                    d="M22 16H14"
                    stroke="#3E4240"
                    strokeWidth="2"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M18 12V20"
                    stroke="#3E4240"
                    strokeWidth="2"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M22 5H2"
                    stroke="#3E4240"
                    strokeWidth="2"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14 12H2"
                    stroke="#3E4240"
                    strokeWidth="2"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 19H2"
                    stroke="#3E4240"
                    strokeWidth="2"
                    strokeLinejoin="round"
                  />
                </svg>
                <div className={classes.plusIconBg} id={"mobile-add-title"} onClick={() => setBlurOn(true)}>
                  <PlusIconSVG color={"#fff"} size="20" />
                  {/* <img src={PlusIcon} alt="add-category" /> */}

                </div>
              </Box>

            </div>
            {openUnavailablecategory && (
              <Unavailablecategory
                // open={openUnavailablecategory}
                openUnavailablecategoryMessage={openUnavailablecategoryMessage}
                setopenUnavailablecategory={setopenUnavailablecategory}
              />)}

            {!outcomeState?.isPersonal ? (
              <>
                <MobileAccordion title="Outcomes" tooltipTitle={"Outcomes refer to the psychological states or tangible results that are influenced by your daily habits and actions. These can include your overall well-being, sense of progress, energy levels, and sense of connection. Outcomes are what you track to see the impact of various influences over time. Think of outcomes as the 'results' of your personal experiments."}>
                  {outcomeState?.outcomeList?.map((item, index) => (
                    <div key={index} onClick={() => setActiveId(index)}>
                      <DetailCard
                        key={index}
                        title={item?.name}
                        item={item}
                        setItem={setItem}
                        index={index}
                        setSnakbarMsg={setSnakbarMsg}
                        setShowSnakbar={setShowSnakbar}
                        setMsgType={setMsgType}
                        outcomeCreate={outcomeCreate}
                        setOpenTrackingEventModal={setOpenTrackingEventModal}
                        headerDate={moment(TheDay).format("MM-DD-yyyy")}
                        setOutcomeId={setOutcomeId}
                        setDisplayBorder={setDisplayBorder}
                        optionIndex={optionIndex}
                        setSelectedOption={setSelectedOption}
                        type="outcome"
                        TheDay={TheDay}
                        callService={callservice}
                        setOutcomeIndex={setOutcomeIndex}
                        setopenUnavailablecategoryMessage={setopenUnavailablecategoryMessage}
                        setopenUnavailablecategory={setopenUnavailablecategory}
                        expandedLevelView={expandedLevelView}
                        setOutcomeValue={props?.setOutcomeValue}
                        setAddOutcomeTracking={props?.setAddOutcomeTracking}
                        setStartModal={props?.setStartModal}
                        trackingDispatch={trackingDispatch}
                      // style={{ fontWeight: 500, fontSize: "14px" }}
                      />
                    </div>
                  ))}
                  {OpenTrackingEventModal && (
                    <AddTrackingEventModal
                      setOpenTrackingEventModal={setOpenTrackingEventModal}
                      index={activeId}
                      setOutcomeId={setOutcomeId}
                      getBackgroudColor={setOutcomeId}
                      setSelectedOption={setSelectedOption}
                      selectedOption={selectedOption}
                      outcomeId={outcomeId}
                      item={item}
                      setShowSnakbar={setShowSnakbar}
                      setSnakbarMsg={setSnakbarMsg}
                      setMsgType={setMsgType}
                      setOptionIndex={setOptionIndex}
                      optionIndex={optionIndex}
                      setDisplayBorder={setDisplayBorder}
                      displayBorder={displayBorder}
                      callService={callservice}
                      isEdit={isEdit}
                      setIsEdit={setIsEdit}
                      journeyData={outcomeState?.journeyList?.outcome_data}
                      headerDate={moment(TheDay).format("MM-DD-yyyy")}
                      isMobile={true}
                    />
                  )}
                </MobileAccordion>
                <MobileAccordion title="Influences" tooltipTitle={"Influences are the behaviors, activities, and external factors that can affect your Outcomes. This includes anything from the amount of sleep you get, to your exercise routine, to the time you spend with loved ones. By tracking Influences, you identify what factors have the most significant positive or negative impact on your Outcomes."}>
                  {outcomeState?.influenceList?.map((item, index) => (
                    <>
                      <DetailCard
                        key={index}
                        title={item?.name}
                        item={item}
                        setItem={setItem}
                        index={index}
                        setSnakbarMsg={setSnakbarMsg}
                        setShowSnakbar={setShowSnakbar}
                        setMsgType={setMsgType}
                        setInfluenceId={setInfluenceId}
                        influenceCreate={influenceCreate}
                        setopenUnavailablecategoryMessage={setopenUnavailablecategoryMessage}
                        setopenUnavailablecategory={setopenUnavailablecategory}
                        unitCreate={unitCreate}
                        setOpenTrackingEventModal={setopenTrackingInfluenceModal}
                        headerDate={moment(TheDay).format("MM-DD-yyyy")}
                        type="influence"
                        callService={callservice}
                        setOpenYesNoModal={setOpenYesNoModal}
                        setOpenTimeOfDayModal={setOpenTimeOfDayModal}
                        setOutcomeIndex={setOutcomeIndex}
                        setDisplayBorder={setDisplayBorder}
                        setSelectedOption={setSelectedOption}
                        setOpenHoursModal={setOpenHoursModal}
                        expandedLevelView={expandedLevelView}
                        setInfluenceData={props?.setInfluenceData}
                        setAddInfluenceTracking={props?.setAddInfluenceTracking}
                        setStartModalInfluence={props?.setStartModalInfluence}
                        trackingDispatch={trackingDispatch}
                      // isMobile={true}
                      />
                      {/* {OpenTrackingEventModal &&
                  outcomeState?.outcomePopup?.renderOutcomePopup(index)} */}
                      {/* UNIT LEVEL MODAL */}
                      {openHoursModal &&
                        item._id === influenceId &&
                        item?.type === "TYPE12" &&
                        item?.flag === "no" && (
                          <AddHoursModal
                            setOpenHoursModal={setOpenHoursModal}
                            item={item}
                            isEdit={isEdit}
                            setIsEdit={setIsEdit}
                            journeyData={
                              outcomeState?.journeyList?.influence_data
                            }
                            headerDate={moment(TheDay).format("MM-DD-yyyy")}
                            setShowSnakbar={setShowSnakbar}
                            setSnakbarMsg={setSnakbarMsg}
                            setMsgType={setMsgType}
                            callService={callservice}
                            setInfluenceId={props.setInfluenceId}
                            isMobile={true}
                          />
                        )}

                      {/* YES/NO MODAL */}
                      {openYesNoModal &&
                        item._id === influenceId &&
                        (item?.type === "TYPE3" ||
                          (item?.type === "TYPE1" && item?.flag === "yes")) && (
                          <AddYesNoModal
                            setOpenYesNoModal={setOpenYesNoModal}
                            setOpenTimeOfDayModal={setOpenTimeOfDayModal}
                            item={item}
                            isEdit={isEdit}
                            setIsEdit={setIsEdit}
                            journeyData={
                              outcomeState?.journeyList?.influence_data
                            }
                            headerDate={moment(TheDay).format("MM-DD-yyyy")}
                            setShowSnakbar={setShowSnakbar}
                            setSnakbarMsg={setSnakbarMsg}
                            setMsgType={setMsgType}
                            callService={callservice}
                            setInfluenceId={setInfluenceId}
                            isMobile={true}
                          />
                        )}

                      {openTimeOfDayModal &&
                        item._id === influenceId &&
                        ((clickCondition && item?.type === "TYPE12") ||
                          (clickCondition &&
                            item?.unitValue === "timeofday" &&
                            item?.metric_type === "NEUTRAL")) && (
                          <AddTimeOfDay
                            setOpenTimeOfDayModal={setOpenTimeOfDayModal}
                            item={item}
                            isEdit={isEdit}
                            setIsEdit={setIsEdit}
                            journeyData={
                              outcomeState?.journeyList?.influence_data
                            }
                            headerDate={moment(TheDay).format("MM-DD-yyyy")}
                            setShowSnakbar={setShowSnakbar}
                            setSnakbarMsg={setSnakbarMsg}
                            setMsgType={setMsgType}
                            callService={callservice}
                            setInfluenceId={setInfluenceId}
                            isMobile={true}
                          />
                        )}
                      {openTrackingInfluenceModal && item._id === influenceId && (
                        <AddTrackingEventInfluenceModal
                          setOpenTrackingEventModal={
                            setopenTrackingInfluenceModal
                          }
                          index={index}
                          setOutcomeId={setInfluenceId}
                          getBackgroudColor={() => console.log("get bg")}
                          setSelectedOption={setSelectedOption}
                          selectedOption={selectedOption}
                          setShowInput={() => console.log("close input")}
                          influenceId={influenceId}
                          item={item}
                          setShowSnakbar={setShowSnakbar}
                          setSnakbarMsg={setSnakbarMsg}
                          setMsgType={setMsgType}
                          setOptionIndex={setOptionIndex}
                          optionIndex={optionIndex}
                          setDisplayBorder={setDisplayBorder}
                          displayBorder={displayBorder}
                          callService={callservice}
                          isEdit={isEdit}
                          setIsEdit={setIsEdit}
                          journeyData={outcomeState?.journeyList?.outcome_data}
                          headerDate={moment(TheDay).format("MM-DD-yyyy")}
                          isMobile={true}
                        />
                      )}
                    </>
                  ))}
                </MobileAccordion>
                <MobileAccordion title="Qualitative Reflection" tooltipTitle={"Reflective Questions are prompts that encourage you to think deeper about your day, your reactions to certain events, and the reasons behind your tracked outcomes and influences. They help integrate a journaling aspect into your tracking, allowing for richer insights and a better understanding of your personal development journey."}>
                  {outcomeState?.reflectiveList?.map((item, index) => (
                    <>
                      <DetailCard
                        key={index}
                        title={item?.question}
                        item={item}
                        setItem={setItem}
                        index={activeId}
                        setSnakbarMsg={setSnakbarMsg}
                        setShowSnakbar={setShowSnakbar}
                        setMsgType={setMsgType}
                        setReflectiveId={setRefId}
                        outcomeCreate={outcomeCreate}
                        setOpenTrackingEventModal={setOpenTrackingEventModal}
                        headerDate={moment(TheDay).format("MM-DD-yyyy")}
                        type="reflective"
                        setOpenYesNoModal={setOpenYesNoModal}
                        setOpenTimeOfDayModal={setOpenTimeOfDayModal}
                        setopenUnavailablecategoryMessage={setopenUnavailablecategoryMessage}
                        setopenUnavailablecategory={setopenUnavailablecategory}
                        setOpenRefQuesModal={setOpenRefQuesModal}
                        setRefId={setRefId}
                        setOutcomeIndex={setOutcomeIndex}
                        expandedLevelView={expandedLevelView}
                        setQuestionData={props?.setQuestionData}
                        setAddQuesTracking={props?.setAddQuesTracking}
                        setStartModalQues={props?.setStartModalQues}
                        trackingDispatch={trackingDispatch}
                      />
                      {/* QUESTION MODAL */}
                      {openRefQuesModal && item.reflectiveId === refId && (
                        <AddRefQuesModal
                          setOpenRefQuesModal={setOpenRefQuesModal}
                          refId={refId}
                          setShowSnakbar={setShowSnakbar}
                          setSnakbarMsg={setSnakbarMsg}
                          setMsgType={setMsgType}
                          setRefId={setRefId}
                          callService={callservice}
                          isEdit={isEdit}
                          setIsEdit={setIsEdit}
                          journeyData={outcomeState?.journeyList?.reflective_data}
                          headerDate={moment(TheDay).format("MM-DD-yyyy")}
                          item={item}
                          isMobile={true}
                        />
                      )}
                    </>
                  ))}
                </MobileAccordion>
              </>
            ) : (
              <>
                {outcomeState?.journeyCategoryList && outcomeState?.journeyCategoryList.length > 0 ? (
                  outcomeState?.journeyCategoryList?.map((cat) => {
                    return (
                      <MobileAccordion title={cat?.name}>
                        {cat?.name === "Other" ? (
                          <>
                            {cat?.outcomes?.map((item, index) => (
                              <div onClick={() => setActiveId(index)}>
                                <DetailCard
                                  key={index}
                                  title={item?.name || item?.question}
                                  item={item}
                                  setItem={setItem}
                                  index={index}
                                  setSnakbarMsg={setSnakbarMsg}
                                  setShowSnakbar={setShowSnakbar}
                                  setMsgType={setMsgType}
                                  setReflectiveId={setRefId}
                                  outcomeCreate={outcomeCreate}
                                  setOpenTrackingEventModal={
                                    item?.outcomeId
                                      ? setOpenTrackingEventModal
                                      : setopenTrackingInfluenceModal
                                  }
                                  headerDate={moment(TheDay).format("MM-DD-yyyy")}
                                  setopenUnavailablecategoryMessage={setopenUnavailablecategoryMessage}
                                  setopenUnavailablecategory={setopenUnavailablecategory}
                                  type={
                                    item?.outcomeId
                                      ? "outcome"
                                      : item?.influenceId
                                        ? "influence"
                                        : "reflective"
                                  }
                                  setSelectedOption={setSelectedOption}
                                  setOutcomeId={setOutcomeId}
                                  setDisplayBorder={setDisplayBorder}
                                  setOpenYesNoModal={setOpenYesNoModal}
                                  setOpenTimeOfDayModal={setOpenTimeOfDayModal}
                                  setOpenRefQuesModal={setOpenRefQuesModal}
                                  setRefId={setRefId}
                                  setOutcomeIndex={setOutcomeIndex}
                                  outcomeId={outcomeId}
                                  influenceId={influenceId}
                                  setInfluenceId={setInfluenceId}
                                  setOpenHoursModal={setOpenHoursModal}
                                  expandedLevelView={expandedLevelView}
                                  setOutcomeValue={props?.setOutcomeValue}
                                  setAddOutcomeTracking={props?.setAddOutcomeTracking}
                                  setStartModal={props?.setStartModal}
                                  trackingDispatch={trackingDispatch}
                                />
                              </div>
                            ))}
                            {cat?.influences?.map((item, index) => (
                              <div onClick={() => setActiveId(index)}>
                                <DetailCard
                                  key={index}
                                  title={item?.name || item?.question}
                                  item={item}
                                  setItem={setItem}
                                  category={cat}
                                  index={index}
                                  influenceCreate={influenceCreate}
                                  setSnakbarMsg={setSnakbarMsg}
                                  setShowSnakbar={setShowSnakbar}
                                  setMsgType={setMsgType}
                                  unitCreate={unitCreate}
                                  setReflectiveId={setRefId}
                                  setopenUnavailablecategoryMessage={setopenUnavailablecategoryMessage}
                                  setopenUnavailablecategory={setopenUnavailablecategory}
                                  outcomeCreate={outcomeCreate}
                                  setOpenTrackingEventModal={
                                    item?.outcomeId
                                      ? setOpenTrackingEventModal
                                      : setopenTrackingInfluenceModal
                                  }
                                  callService={callservice}
                                  headerDate={moment(TheDay).format("MM-DD-yyyy")}
                                  type={
                                    item?.outcomeId
                                      ? "outcome"
                                      : item?.influenceId
                                        ? "influence"
                                        : "reflective"
                                  }
                                  setCategoryId={setCategoryId}
                                  setSelectedOption={setSelectedOption}
                                  setOutcomeId={setOutcomeId}
                                  setDisplayBorder={setDisplayBorder}
                                  setOpenYesNoModal={setOpenYesNoModal}
                                  setOpenTimeOfDayModal={setOpenTimeOfDayModal}
                                  setOpenRefQuesModal={setOpenRefQuesModal}
                                  setRefId={setRefId}
                                  setOutcomeIndex={setOutcomeIndex}
                                  outcomeId={outcomeId}
                                  influenceId={influenceId}
                                  setInfluenceId={setInfluenceId}
                                  setOpenHoursModal={setOpenHoursModal}
                                  expandedLevelView={expandedLevelView}
                                  setInfluenceData={props?.setInfluenceData}
                                  setAddInfluenceTracking={props?.setAddInfluenceTracking}
                                  setStartModalInfluence={props?.setStartModalInfluence}
                                  trackingDispatch={trackingDispatch}
                                />
                              </div>
                            ))}
                            {cat?.reflectives?.map((item, index) => (
                              <div onClick={() => setActiveId(index)}>
                                <DetailCard
                                  key={index}
                                  title={item?.name || item?.question}
                                  item={item}
                                  setItem={setItem}
                                  index={index}
                                  setSnakbarMsg={setSnakbarMsg}
                                  setShowSnakbar={setShowSnakbar}
                                  setMsgType={setMsgType}
                                  setReflectiveId={setRefId}
                                  outcomeCreate={outcomeCreate}
                                  setopenUnavailablecategoryMessage={setopenUnavailablecategoryMessage}
                                  setopenUnavailablecategory={setopenUnavailablecategory}
                                  setOpenTrackingEventModal={
                                    item?.outcomeId
                                      ? setOpenTrackingEventModal
                                      : setopenTrackingInfluenceModal
                                  }
                                  headerDate={moment(TheDay).format("MM-DD-yyyy")}
                                  type={
                                    item?.outcomeId
                                      ? "outcome"
                                      : item?.influenceId
                                        ? "influence"
                                        : "reflective"
                                  }
                                  setSelectedOption={setSelectedOption}
                                  setOutcomeId={setOutcomeId}
                                  setDisplayBorder={setDisplayBorder}
                                  setOpenYesNoModal={setOpenYesNoModal}
                                  setOpenTimeOfDayModal={setOpenTimeOfDayModal}
                                  setOpenRefQuesModal={setOpenRefQuesModal}
                                  setRefId={setRefId}
                                  setOutcomeIndex={setOutcomeIndex}
                                  outcomeId={outcomeId}
                                  influenceId={influenceId}
                                  setInfluenceId={setInfluenceId}
                                  setOpenHoursModal={setOpenHoursModal}
                                  expandedLevelView={expandedLevelView}
                                  setQuestionData={props?.setQuestionData}
                                  setAddQuesTracking={props?.setAddQuesTracking}
                                  setStartModalQues={props?.setStartModalQues}
                                  trackingDispatch={trackingDispatch}
                                />
                              </div>
                            ))}
                          </>
                        ) : (
                          cat?.alls?.map((item, index) => (
                            <div onClick={() => setActiveId(index)}>
                              <DetailCard
                                key={index}
                                title={item?.name || item?.question}
                                item={item}
                                setItem={setItem}
                                index={index}
                                setSnakbarMsg={setSnakbarMsg}
                                setShowSnakbar={setShowSnakbar}
                                setMsgType={setMsgType}
                                setReflectiveId={setRefId}
                                outcomeCreate={outcomeCreate}
                                unitCreate={unitCreate}
                                setCategoryId={setCategoryId}
                                category={cat}
                                setopenUnavailablecategoryMessage={setopenUnavailablecategoryMessage}
                                setopenUnavailablecategory={setopenUnavailablecategory}
                                setOpenTrackingEventModal={
                                  item?.outcomeId
                                    ? setOpenTrackingEventModal
                                    : setopenTrackingInfluenceModal
                                }
                                headerDate={moment(TheDay).format("MM-DD-yyyy")}
                                type={
                                  item?.outcomeId
                                    ? "outcome"
                                    : item?.influenceId
                                      ? "influence"
                                      : "reflective"
                                }
                                callService={callservice}
                                influenceCreate={influenceCreate}
                                setSelectedOption={setSelectedOption}
                                setOutcomeId={setOutcomeId}
                                setDisplayBorder={setDisplayBorder}
                                setOpenYesNoModal={setOpenYesNoModal}
                                setOpenTimeOfDayModal={setOpenTimeOfDayModal}
                                setOpenRefQuesModal={setOpenRefQuesModal}
                                setRefId={setRefId}
                                setOutcomeIndex={setOutcomeIndex}
                                outcomeId={outcomeId}
                                influenceId={influenceId}
                                setInfluenceId={setInfluenceId}
                                setOpenHoursModal={setOpenHoursModal}
                                expandedLevelView={expandedLevelView}
                                setOutcomeValue={props?.setOutcomeValue}
                                setAddOutcomeTracking={props?.setAddOutcomeTracking}
                                setStartModal={props?.setStartModal}
                                setInfluenceData={props?.setInfluenceData}
                                setAddInfluenceTracking={props?.setAddInfluenceTracking}
                                setStartModalInfluence={props?.setStartModalInfluence}
                                setQuestionData={props?.setQuestionData}
                                setAddQuesTracking={props?.setAddQuesTracking}
                                setStartModalQues={props?.setStartModalQues}
                                trackingDispatch={trackingDispatch}
                              />
                            </div>
                          ))
                        )}
                      </MobileAccordion>
                    );
                  })
                ) : (
                  <div
                    style={{
                      marginTop: "50px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <h4 style={{ color: "#3e4240" }}>
                      {!showLoaderPage && "You don’t have personal data. Start adding it"}
                    </h4>
                  </div>
                )}
                {OpenTrackingEventModal && (
                  <AddTrackingEventModal
                    setOpenTrackingEventModal={setOpenTrackingEventModal}
                    index={outcomeIndex}
                    setOutcomeId={setOutcomeId}
                    getBackgroudColor={setOutcomeId}
                    setSelectedOption={setSelectedOption}
                    selectedOption={selectedOption}
                    outcomeId={outcomeId}
                    item={item}
                    setShowSnakbar={setShowSnakbar}
                    setSnakbarMsg={setSnakbarMsg}
                    setMsgType={setMsgType}
                    setOptionIndex={setOptionIndex}
                    optionIndex={optionIndex}
                    setDisplayBorder={setDisplayBorder}
                    displayBorder={displayBorder}
                    callService={callservice}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    journeyData={outcomeState?.journeyList?.outcome_data}
                    headerDate={moment(TheDay).format("MM-DD-yyyy")}
                    callToGetData={true}
                    isMobile={true}
                  />
                )}
                {openTrackingInfluenceModal && item._id === influenceId && (
                  <AddTrackingEventInfluenceModal
                    setOpenTrackingEventModal={setopenTrackingInfluenceModal}
                    index={outcomeIndex}
                    setOutcomeId={setInfluenceId}
                    getBackgroudColor={() => console.log("get bg")}
                    setSelectedOption={setSelectedOption}
                    selectedOption={selectedOption}
                    setShowInput={() => console.log("close input")}
                    influenceId={influenceId}
                    item={item}
                    setShowSnakbar={setShowSnakbar}
                    setSnakbarMsg={setSnakbarMsg}
                    setMsgType={setMsgType}
                    setOptionIndex={setOptionIndex}
                    optionIndex={optionIndex}
                    setDisplayBorder={setDisplayBorder}
                    displayBorder={displayBorder}
                    callService={callservice}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    journeyData={outcomeState?.journeyList?.influence_data}
                    headerDate={moment(TheDay).format("MM-DD-yyyy")}
                    callToGetData={true}
                    isMobile={true}
                  />
                )}
                {openHoursModal && (
                  <AddHoursModal
                    setOpenHoursModal={setOpenHoursModal}
                    item={item}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    journeyData={outcomeState?.journeyList?.influence_data}
                    headerDate={moment(TheDay).format("MM-DD-yyyy")}
                    setShowSnakbar={setShowSnakbar}
                    setSnakbarMsg={setSnakbarMsg}
                    setMsgType={setMsgType}
                    callService={callservice}
                    setInfluenceId={setInfluenceId}
                    callToGetData={true}
                    isMobile={true}
                  />
                )}
                {openYesNoModal && (
                  // (item?.type === "TYPE3" ||
                  //   (item?.type === "TYPE1" && item?.flag === "yes")) && (
                  <AddYesNoModal
                    setOpenYesNoModal={setOpenYesNoModal}
                    item={item}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    journeyData={outcomeState?.journeyList?.influence_data}
                    headerDate={moment(TheDay).format("MM-DD-yyyy")}
                    setShowSnakbar={setShowSnakbar}
                    setSnakbarMsg={setSnakbarMsg}
                    setMsgType={setMsgType}
                    callService={callservice}
                    setInfluenceId={setInfluenceId}
                    callToGetData={true}
                    isMobile={true}

                  />
                )}
                {openTimeOfDayModal &&
                  item._id === influenceId &&
                  ((clickCondition && item?.type === "TYPE12") ||
                    (clickCondition &&
                      item?.unitValue === "timeofday" &&
                      item?.metric_type === "NEUTRAL")) && (
                    <AddTimeOfDay
                      setOpenTimeOfDayModal={setOpenTimeOfDayModal}
                      item={item}
                      isEdit={isEdit}
                      setIsEdit={setIsEdit}
                      journeyData={outcomeState?.journeyList?.influence_data}
                      headerDate={moment(TheDay).format("MM-DD-yyyy")}
                      setShowSnakbar={setShowSnakbar}
                      setSnakbarMsg={setSnakbarMsg}
                      setMsgType={setMsgType}
                      callService={callservice}
                      setInfluenceId={setInfluenceId}
                      callToGetData={true}
                      isMobile={true}
                      categoryId={categoryId}
                    />
                  )}
                {openRefQuesModal && item.reflectiveId === refId && (
                  <AddRefQuesModal
                    setOpenRefQuesModal={setOpenRefQuesModal}
                    refId={refId}
                    setShowSnakbar={setShowSnakbar}
                    setSnakbarMsg={setSnakbarMsg}
                    setMsgType={setMsgType}
                    setRefId={setRefId}
                    callService={callservice}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    journeyData={outcomeState?.journeyList?.influence_data}
                    headerDate={moment(TheDay).format("MM-DD-yyyy")}
                    item={item}
                    callToGetData={true}
                    isMobile={true}

                  />
                )}
              </>
            )}
          </div>
        </div>
        <HeaderPopoverMobile openStartModal={openStartModal} setOpenNOtificationModal={() => {
          setopenStartModal(false)
        }} notifications={notifications} setNotifications={setNotifications}
          unreadNotifications={unreadNotifications} setUnreadNotifications={setUnreadNotifications} />
        {/* <div
          style={{
            position: "fixed",
            background: "#11734A",
            color: "#fff",
            bottom: "6%",
            boxShadow: "1px 2px 5px grey",
            right: "6%",
            padding: "20px",
            cursor: "pointer",
            borderRadius: "290px",
          }}
          onClick={() => setBlurOn(true)}
        >
          <img src={PlusIcon} />
        </div> */}
        {BlurOn && (
          <div
            style={{
              position: "fixed",
              width: "100%",
              height: "100%",
              background: "rgba( 255, 255, 255, 0.25 )",
              top: "0",
              left: "0",
              backdropFilter: "blur(5px)",
              zIndex: 2
            }}
          >
            <div
              style={{
                position: "fixed",
                color: "black",
                bottom: "12%",
                right: "8%",
                textAlign: "right",
              }}
            >
              <p
                style={{ cursor: "pointer" }}
                onClick={() => {
                  let days = 0
                  const createDate = outcomeState.userDetails.createdAt
                  const diff = moment().diff(moment(createDate), 'days')
                  if (diff !== 0 && diff < 30) {
                    days = 30 - diff
                  } else if (diff === 0) {
                    days = 30 - diff
                  }
                  if (days > 0 && outcomeState?.outcomeList && outcomeState?.outcomeList?.length >= 5) {
                    props?.setLimitReachPopupVisible(true)
                    props?.setLimitReachPopuptype('outcome')
                  } else {
                    props?.setAddOutcomeTracking(true);
                    props?.setStartModal(true);
                    setBlurOn(false)
                  }

                }}
              >
                Add outcome
              </p>
              <p
                style={{ cursor: "pointer" }}
                onClick={() => {
                  let days = 0
                  const createDate = outcomeState.userDetails.createdAt
                  const diff = moment().diff(moment(createDate), 'days')
                  if (diff !== 0 && diff < 30) {
                    days = 30 - diff
                  } else if (diff === 0) {
                    days = 30 - diff
                  }
                  if (days > 0 && outcomeState?.influenceList && outcomeState?.influenceList?.length >= 10) {
                    props?.setLimitReachPopupVisible(true)
                    props?.setLimitReachPopuptype('influence')
                  } else {
                    props?.setAddInfluenceTracking(true);
                    props?.setStartModalInfluence(true);
                    setBlurOn(false)
                  }

                }}
              >
                Add influence
              </p>
              <p
                style={{ cursor: "pointer" }}
                onClick={() => {
                  let days = 0
                  const createDate = outcomeState.userDetails.createdAt
                  const diff = moment().diff(moment(createDate), 'days')
                  if (diff !== 0 && diff < 30) {
                    days = 30 - diff
                  } else if (diff === 0) {
                    days = 30 - diff
                  }
                  if (days > 0 && outcomeState?.reflectiveList && outcomeState?.reflectiveList?.length >= 3) {
                    props?.setLimitReachPopupVisible(true)
                    props?.setLimitReachPopuptype('question')
                  } else {
                    props?.setAddQuesTracking(true);
                    props?.setStartModalQues(true);
                    setBlurOn(false)

                  }

                }}
              >
                Add reflective questions
              </p>
            </div>
            <div
              style={{
                position: "fixed",
                background: "var(--color-new-main)",
                color: "#fff",
                bottom: "6%",
                // boxShadow: "1px 2px 5px grey",
                right: "6%",
                padding: "8px 10px 8px 8px",
                cursor: "pointer",
                borderRadius: "50%",
                transform: "rotate(45deg)",
              }}
              onClick={() => setBlurOn(false)}
            >
              <PlusIconSVG color="#fff"/>
            </div>
          </div>
        )}
      </div>
      {Notification}
    </div>
  );
};

const InsideMonth = ({ month, TheDay, setTheDay, currentDateRef }) => {
  const classes = useStyles();
  return (
    <div style={{ display: "flex", gap: "3px" }}>
      {month.map((day) => {
        return (
          <div
            className={`${moment().isSame(day, "day")
              ? clsx(classes.headerBlocks, classes.background)
              : classes.headerBlocks
              }`}
            style={
              moment(TheDay).isSame(day, "day")
                ? { border: "1.33px solid var(--color-svg)", borderRadius: "8px" }
                : {}
            }
            ref={moment(TheDay).isSame(day, "day") ? currentDateRef : null}
            onClick={() => {
              setTheDay(day)
            }}
          >
            <Typography
              variant="caption"
              component="p"
              className={classes.weekday}
            >
              {moment(day).format("ddd")}
            </Typography>
            <Typography
              variant="h4"
              color={"#5E5F5E"}
              sx={{
                fontWeight: 400,
                textAlign: "center",
                verticalAlign: "middle",
                "&.MuiTypography-root": {
                  "@media  (max-width: 600px) and (min-width: 100px)": {
                    fontSize: `${pxToRem(14)} !important`,
                    lineHeight: "100% !important"
                  },
                }
              }}
            >
              {moment(day).format("D")}{" "}
            </Typography>
          </div>
        );
      })}
    </div>
  );
};

export default TrackerScreenMobile;
