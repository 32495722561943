import axios from "axios";
import { baseEndpoints } from "./config";

const headerToken = localStorage.getItem("userDetails");

const getContactsList = async (token: string) => {
  const { data } = await axios
    .post(
      `${baseEndpoints.GROUP_CONTACTS.LIST_GROUP}`,
      {},
      {
        headers: {
          "x-access-token": token ? token : headerToken,
        },
      }
    )
    .then((response) => {
      return response;
    });
  return data;
};

const createUpdateContact = async (values, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.GROUP_CONTACTS.CONTACT_CREATE}`, values, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const updateContact = async (values, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.GROUP_CONTACTS.CONTACT_UPDATE}`, values, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const deleteContact = async (values, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.GROUP_CONTACTS.CONTACT_DELETE}`, values, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const createUpdateGroup = async (values, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.GROUP_CONTACTS.GROUP_CREATE}`, values, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const updateGroup = async (values, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.GROUP_CONTACTS.GROUP_UPDATE}`, values, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const deleteGroup = async (values, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.GROUP_CONTACTS.GROUP_DELETE}`, values, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const shareReport = async (values, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.GROUP_CONTACTS.SHARE_REPORT}`, values, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const viewshareReport = async (values) => {
  const { data } = await axios
    .post(`${baseEndpoints.GROUP_CONTACTS.VIEW_SHARE_REPORT}`, values, {})
    .then((response) => {
      return response;
    });
  return data;
};

const addFeedback = async (values) => {
  const { data } = await axios
    .post(`${baseEndpoints.GROUP_CONTACTS.ADD_FEEDBACK}`, values, {})
    .then((response) => {
      return response;
    });
  return data;
};

const getAllSchedulesReports = async (token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.GROUP_CONTACTS.GET_REPORTS}`, {}, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const getReportDetails = async (values) => {
  const { data } = await axios
    .post(`${baseEndpoints.GROUP_CONTACTS.GET_REPORT_DETAILS}`, values, {})
    .then((response) => {
      return response;
    });
  return data;
};

const updateReportStatus = async (values, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.GROUP_CONTACTS.UPDATE_RECORDS}`, values, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

export const contactsService = {
  getContactsList,
  createUpdateContact,
  createUpdateGroup,
  updateGroup,
  updateContact,
  deleteContact,
  deleteGroup,
  shareReport,
  viewshareReport,
  getReportDetails,
  getAllSchedulesReports,
  updateReportStatus,
  addFeedback
};
