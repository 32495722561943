import React, { useContext } from "react";
import * as dateFns from "date-fns";
import "./calender.css";
import moment from "moment/moment";
import { TrackingContext } from "../../context/userTracking";
class CustomCalender extends React.Component {
  state = {
    currentMonth: new Date(),
    selectedDate: new Date(),
  };
  static contextType = TrackingContext

  componentDidMount() {
    const { state = {} } = this.context
    const {startDate = new Date()} = state
    this.setState({
      currentMonth: new Date(startDate)
    })
    console.log("this.props", this.props, this.context, startDate)
  }
  renderHeader() {
    const dateFormat = "MMMM yyyy";

    return (
      <div className="header row flex-middle">
        <div className="col col-start">
          <div className="icon" onClick={this.prevMonth}>
            &lt;
          </div>
        </div>
        <div className="col col-center">
          <span>{dateFns.format(this.state.currentMonth, dateFormat)}</span>
        </div>
        <div className="col col-end" onClick={this.nextMonth}>
          <div className="icon">&gt;</div>
        </div>
      </div>
    );
  }

  renderDays() {
    const dateFormat = "EEE";
    const days = [];

    let startDate = dateFns.startOfWeek(this.state.currentMonth);

    for (let i = 0; i < 7; i++) {
      days.push(
        <div
          style={{
            width: "172px",
            height: "32px",
            backgroundColor: "#F7F9FA",
            display: "flex",
            alignItems: "center",
            fontSize: "16px",
            fontWeight: "500",
            paddingLeft: "8px",
          }}
          key={i}
        >
          {dateFns.format(dateFns.addDays(startDate, i), dateFormat)}
        </div>
      );
    }

    return <div style={{ display: "flex", width: "fit-content" }}>{days}</div>;
  }

  renderCells() {
    const { currentMonth, selectedDate } = this.state;
    const monthStart = dateFns.startOfMonth(currentMonth);
    const monthEnd = dateFns.endOfMonth(monthStart);
    const startDate = dateFns.startOfWeek(monthStart);
    const endDate = dateFns.endOfWeek(monthEnd);

    const dateFormat = "d";
    const rows = [];

    let days = [];
    let day = startDate;
    let formattedDate = "";

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = dateFns.format(day, dateFormat);
        const cloneDay = day;
        let dayData = this.props?.journeyData?.filter(
          (data) =>
            data?.date == moment(day).format("MM-DD-YYYY") &&
            (data?.name === this.props?.name ||
              data?.question === this.props?.name)
        )?.[0];
        days.push(
          <div
            className={`col cell ${
              !dateFns.isSameMonth(day, monthStart)
                ? "disabled"
                : dateFns.isSameDay(day, selectedDate)
                ? "selected"
                : ""
            }`}
            style={{
              minWidth: "172px",
              height: "182px",
              border: "1px solid #DCE5E0",
            }}
            key={day}
            onClick={() => this.onDateClick(dateFns.parse(cloneDay))}
          >
            <span className="number">{formattedDate}</span>
            {dayData && (
              <span
                className="score"
                style={{ backgroundColor: dayData?.colorCode || "#11734A" }}
              >
                {dayData?.index || dayData?.score || ""}
              </span>
            )}
            {dayData && (
              <span className="note">
                {dayData?.note?.[0] ||
                  dayData?.answers?.map((ans) => <li>{ans}</li>)}
              </span>
            )}
            <span className="bg">{formattedDate}</span>
          </div>
        );
        day = dateFns.addDays(day, 1);
      }
      rows.push(
        <div className="row" key={day}>
          {days}
        </div>
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  }

  onDateClick = (day) => {
    this.setState({
      selectedDate: day,
    });
  };

  nextMonth = () => {
    this.setState({
      currentMonth: dateFns.addMonths(this.state.currentMonth, 1),
    });
    this.props?.callService(
      moment(dateFns.addMonths(this.state.currentMonth, 1))
        .startOf("month")
        .format("YYYY-MM-DD hh:mm"),
      moment(dateFns.addMonths(this.state.currentMonth, 1))
        .endOf("month")
        .format("YYYY-MM-DD hh:mm")
    );
  };

  prevMonth = () => {
    this.setState({
      currentMonth: dateFns.subMonths(this.state.currentMonth, 1),
    });
    this.props?.callService(
      moment(dateFns.subMonths(this.state.currentMonth, 1))
        .startOf("month")
        .format("YYYY-MM-DD hh:mm"),
      moment(dateFns.subMonths(this.state.currentMonth, 1))
        .endOf("month")
        .format("YYYY-MM-DD hh:mm")
    );
  };

  render() {
    return (
      <div className="calendar">
        {this.renderHeader()}
        {this.renderDays()}
        {this.renderCells()}
      </div>
    );
  }
}

export default CustomCalender;

CustomCalender.contextType = TrackingContext