/*eslint eqeqeq: "off"*/
import React, { useState, useContext, useEffect, useCallback } from "react";
import { styled } from "@mui/material/styles";

import {
  Typography,
  Grid,
  TextField,
  Button,
  Box,
  Dialog,
} from "@mui/material";
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
} from "@material-ui/core";
import palette from "../../theme/palette";
import GetIdeasIcon from "../../assets/images/icon/getIdeas";
import BottomStepper from "../outcome/BottomStepper";
import IdeasDrawer from "./ideasDrawer";
import { UserTypeContext } from "../../context/userStatus";
import { reflectiveQueService } from "../../services/reflective-questions";
import {
  addCategoryJourneyAction,
  addRefelectiveAction,
  setUserStatus,
} from "../../store/actions/userTypeAction";
import SnackBarComp from "../../components/snackBar";
import { ButtonLoader } from "../../components/buttonLoader";
import { RefelectiveGetResponse } from "../../interfaces/outcomeModal";
import { ProUserStepper, UserStepper, weekDays } from "../../utils/staticDataJson";
import moment from "moment";
import { trackingService } from "../../services/tracking";
import { userService } from "../../services/auth";
import Donts from "../../assets/images/icon/Dots";
import shape from "../../theme/shape";
import useWidth from "../../utils/useWidth";
import _debounce from "lodash/debounce";
import ViewNote from "../../assets/images/icon/ViewNote";
import Wellbeing from "../outcome/Wellbeing";
import { useNavigate } from "react-router-dom";
import StepperBackArrowDisable from "../../assets/images/icon/stepperBackArrowDisable";
import StepperBackArrow from "../../assets/images/icon/stepperBackArrow";
import StepperNextArrowDisable from "../../assets/images/icon/stepperNextArrowDisable";
import StepperNextArrow from "../../assets/images/icon/stepperNextArrow";

const RootStyle = styled("div")(({ theme }) => ({
  padding: "60px 48px 60px 80px",
  position: "relative",
  height: "100vh",
  [theme.breakpoints.down("xl")]: {
    padding: "50px 40px 50px 60px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "40px 20px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "20px",
  },
}));

const useStyles = makeStyles((theme) => ({
  registrationHeading: {
    color: palette.primary.light,
  },
  pageTitle: {
    marginTop: "40px!important",
    marginBottom: "48px!important",
    [theme.breakpoints.down("lg")]: {
      marginTop: "30px!important",
      marginBottom: "38px!important",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "20px!important",
      marginBottom: "28px!important",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "15px!important",
      marginBottom: "20px!important",
    },
  },
  needSomeIdeas: {
    fontSize: "16px",
    lineHeight: "16px",
    color: palette.primary.main,
    marginLeft: "8px",
    [theme.breakpoints.down("md")]: {
      marginTop: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "14px",
    },
  },
  weekDayBox: {
    "& p": {
      fontSize: "18px",
      lineHeight: "36px",
      color: "#3E4240",
      backgroundColor: "#F7F9FA",
      border: "1px solid #DCE6E0",
      width: "36px",
      height: "36px",
      textAlign: "center",
      cursor: "pointer",
      "&.active": {
        backgroundColor: "#11734A",
        color: "#F7F9FA",
      },
    },
  },
  ideasListItem: {
    backgroundColor: palette.common.white,
    borderRadius: shape.borderRadius,
    padding: "24px",
    fontSize: "16px",
    lineHeight: "16px",
    color: palette.primary.light,
    fontWeight: 400,
    marginBottom: "12px",
    cursor: "pointer",
  },
}));

const ariaLabel = { "aria-label": "description" };

interface LeftSideReflectiveQueModal {
  setQuestionData: (questionData: any) => void;
  questionData: any;
  reflectiveIdeas: any;
  showRefQues: boolean;
  setAddQuesTracking: (addQuesTracking: boolean) => void;
  setShowQues: (showQues: boolean) => void;
  setWeekDay: any;
  weekday;
  handleCloseStartModal?: any;
  getReflectiveList?: any;
  categoryId?: any;
  width?: any;
  setShowSnakbar?: any;
  setSnakbarMsg?: any;
  setMsgType?: any;
  startDate?: any
  endDate?: any 
  onboarding?: boolean
}

const LeftSideReflectiveQue = (props: LeftSideReflectiveQueModal) => {
  const classes = useStyles();
  const [question, setQuestion] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);
  // const [showSnakbar, setShowSnakbar] = useState(false);
  // const [snakbarMsg, setSnakbarMsg] = useState('');
  // const [msgType, setMsgType] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [activeWeek, setActiveWeek] = useState([]);
  const [showDay, setShowDay] = useState(false);
  const [outcomeDesc, setOutcomeDesc] = useState("");
  const [showIdeasMobile, setShowIdeasMobile] = useState(false);
  const userLevels = JSON.parse(localStorage.getItem("userValues"))
  const [activeStep, setActiveStep] = React.useState(
    true ? 2 : false ? 1 : 0
  );
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };
  const userType =
    userLevels?.level == "HIGH" ? ProUserStepper : UserStepper;
  const { state: outcomeState, state: IUserTypeState, dispatch: userTypeDispatch } =
    useContext(UserTypeContext);
  const tempWeekArr = [];
  const compareDate = parseInt(moment().format("D"));
  const startDay =
    props.startDate ? moment(props.startDate) : compareDate < 10
      ? moment().clone().startOf("month")
      : moment().clone().subtract(10, "day");
  const endDay =
    props.endDate ? moment(props.endDate) :compareDate < 10
      ? moment().clone().endOf("month")
      : moment().clone().add(10, "day");

  useEffect(() => {
    setOpenDrawer(false);
    setQuestion(props.questionData?.question);
    setOutcomeDesc(props?.questionData?.description);
    setWeekDayArr();
    setActiveWeek(
      props.questionData?.weekDays ? props.questionData?.weekDays : []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.questionData]);

  const [suggestions, setSuggestions] = useState([]);
  const [openWellbeing, setopenWellbeing] = useState(false);

  const navigate = useNavigate();

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if (userLevels?.level == "HIGH") {
      if (activeStep != userType.length - 1) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    let nextStep = activeStep + 1;
    setSkipped(newSkipped);
    handleSliderNextValue(nextStep);
  };

  const handleSignincompelted = () => {
    const obj = {
      mixpanel_type: "SIGNUP_COMPLETED"
    };
    trackingService
      .signupCompletedBoarding(outcomeState.token, obj);
  }

  const handleSliderNextValue = (step: number) => {
    if (userLevels?.level == "HIGH") {
      if (step == 3) {
        userTypeDispatch(setUserStatus(true));
        handleSignincompelted()
        navigate("/tracking", { replace: true });
      }
      if (step == 2) {
        navigate("/reflective-questions", { replace: true });
      } else if (step == 1) {
        navigate("/influence", { replace: true });
      }
    } else {
      if (step == 1) {
        navigate("/influence", { replace: true });
      }
      if (step == 2) {
        userTypeDispatch(setUserStatus(true));
        handleSignincompelted()
        navigate("/tracking", { replace: true });
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    const backStep = activeStep - 1;
    handleSliderValue(backStep);
  };

  const handleSliderValue = (step: number) => {
    if (userLevels?.level == "HIGH") {
      if (step == 1) {
        navigate("/influence", { replace: true });
      } else if (step == 0) {
        navigate("/outcome", { replace: true });
      }
    } else {
      if (step === 0) {
        navigate("/outcome", { replace: true });
      }
    }
  };

  const handleChangeWithLib = (value) => {
    let bodyData = {
      skip: 0,
      limit: 20,
      search: value,
    };
    trackingService
      .resSearch(outcomeState.token, bodyData)
      .then((res) => setSuggestions(res.data));
  };
  
  const debounceFn = useCallback(_debounce(handleChangeWithLib, 500), []);

  const handleCloseStartModal = () => {
    setShowIdeasMobile(false);
  };

  const handleChangeQuestion = (e: any) => {
    setQuestion(e.target.value);
    debounceFn(e.target.value);
  };

  const setWeekDayArr = () => {
    props?.questionData?.weekDays?.map((day) => {
      return props?.setWeekDay(props?.weekday.set(day?.day, day?.day));
    });
  };
  const setWeekDayArrClick = (day) => {
    if (props?.weekday.has(day?.day)) {
      props?.weekday.delete(day?.day);
    } else {
      props?.setWeekDay(props?.weekday.set(day?.day, day?.day));
    }
  };
  const callService = async () => {
    try {
      const bodyData = {
        from: moment(startDay).format("MM-DD-YYYY"),
        to: moment(endDay).format("MM-DD-YYYY"),
      };
      if (outcomeState?.isPersonal) {
        let data = await trackingService.getPersonalJourneyList(
          outcomeState.token,
          bodyData
        );
        userTypeDispatch(addCategoryJourneyAction(data?.data));
      }
    } catch (error) {
      console.log("jourey list error");
    }
  };

  // ADD REFLECTIVE QUESTION
  const AddQuestion = () => {
    for (const [value] of props?.weekday) {
      tempWeekArr.push({ day: value });
    }
    setShowLoader(true);
    let refelctiveRes;
    if (props.questionData?.uid) {
      refelctiveRes = {
        question: question,
        description: outcomeDesc,
        reflectiveId: props?.questionData?.reflectiveId,
        weekDays: tempWeekArr,
        mixpanel_type: "ON_BOARDING"
      };
    } else {
      refelctiveRes = {
        question: question,
        description: outcomeDesc,
        weekDays: tempWeekArr,
        categoryId: props?.categoryId,
        mixpanel_type: "ON_BOARDING"
      };
    }
    if (props.questionData?.uid) {
      // EDIT QUESTION
      reflectiveQueService
        .editRefelective(refelctiveRes, outcomeState.token)
        .then((response: any) => {
          setShowLoader(false);
          reflectiveQueService
            .getReflectiveList(outcomeState.token)
            .then((data: RefelectiveGetResponse) => {
              // userTypeDispatch(addRefelectiveAction(data?.data));
              // callService();
              if (IUserTypeState.isPersonal) {
                const journeyList = [...outcomeState?.journeyCategoryList];
                const foudnInJourney = journeyList.findIndex(it => it.reflectiveIds.includes(props?.questionData?.reflectiveId))
                if (foudnInJourney > -1) {
                  const foundInOutcomes = journeyList[foudnInJourney].alls.findIndex(it => it.reflectiveId === props?.questionData?.reflectiveId)
                  if (foundInOutcomes > -1) {
                    journeyList[foudnInJourney].alls[foundInOutcomes] = {
                      ...journeyList[foudnInJourney].alls[foundInOutcomes],
                      ...refelctiveRes
                    }
                    userTypeDispatch(addCategoryJourneyAction(journeyList));
                  }
                } else {
                  const foudnOtherJourney = journeyList.findIndex(it => it.name === "Other")
                  if (foudnOtherJourney > -1) {
                    const foundInOutcomes = journeyList[foudnOtherJourney].outcomes.findIndex(it => it.reflectiveId === props?.questionData?.reflectiveId)
                    if (foundInOutcomes > -1) {
                      journeyList[foudnOtherJourney].alls[foundInOutcomes] = {
                        ...journeyList[foudnOtherJourney].alls[foundInOutcomes],
                        ...refelctiveRes
                      }
                      userTypeDispatch(addCategoryJourneyAction(journeyList));
                    }
                  }
                }
              } else {
                const allOutcomes = [...outcomeState?.reflectiveList];
                const foundOutcomeIndex = allOutcomes.findIndex(it => it.reflectiveId === props?.questionData?.reflectiveId);
                if (foundOutcomeIndex > -1) {
                  allOutcomes[foundOutcomeIndex] = {
                    ...allOutcomes[foundOutcomeIndex],
                    ...refelctiveRes
                  }
                  userTypeDispatch(addRefelectiveAction(allOutcomes));
    
                }
              }
              props.setShowQues(false);
              setQuestion("");
              setOutcomeDesc("")
              props.setQuestionData("");
              setActiveWeek([]);
              props?.setWeekDay(new Map());
              props?.setShowSnakbar(true);
              props?.setSnakbarMsg(response.message);
              props?.setMsgType("success");
            });
        })
        .catch((error) => {
          setShowLoader(false);
          props?.setShowSnakbar(true);
          props?.setSnakbarMsg(
            error?.response?.data?.description
              ? error?.response?.data?.description
              : error.message
          );
          props?.setMsgType("error");
        });
    } else {
      // CREATE QUESTION
      reflectiveQueService
        .createRefelective(refelctiveRes, outcomeState.token)
        .then((response: any) => {
          setShowLoader(false);
          outcomeState.reflectiveList.push(response?.data);
          const bodyData = {
            from: moment(startDay).format("MM-DD-YYYY"),
            to: moment(endDay).format("MM-DD-YYYY"),
          };
          trackingService
            .getPersonalJourneyList(outcomeState.token, bodyData)
            .then((data) => {
              userTypeDispatch(addCategoryJourneyAction(data?.data));
              userTypeDispatch(
                addRefelectiveAction(outcomeState.reflectiveList)
              );
              props.setAddQuesTracking(false);
              setQuestion("");
              setOutcomeDesc("")
              props.setQuestionData("");
              setActiveWeek([]);
              props?.setWeekDay(new Map());
              props?.setShowSnakbar(true);
              props?.setSnakbarMsg(response.message);
              props?.setMsgType("success");
            });
          // setTimeout(() => {

          // }, 1000);
        })
        .catch((error) => {
          setShowLoader(false);
          props?.setShowSnakbar(true);
          props?.setSnakbarMsg(
            error?.response?.data?.description
              ? error?.response?.data?.description
              : error.message
          );
          props?.setMsgType("error");
        });
    }
  };
  const activeWeekDay = (argDay) => {
    if (activeWeek?.some((day) => day?.day == argDay.day)) {
      const tempArray = [
        ...activeWeek.filter((item) => item.day != argDay?.day),
      ];
      setActiveWeek(tempArray);
    } else {
      setActiveWeek([...activeWeek, { day: argDay?.day }]);
    }
  };
  const showDrawer = () => {
    setOpenDrawer(true);
  };
  const handleCloseSnakbar = () => {
    props?.setShowSnakbar(false);
  };

  const ITEM_HEIGHT = 48;
  const [anchorElTwo, setAnchorElTwo] = React.useState<null | HTMLElement>(
    null
  );
  const openMenu = Boolean(anchorElTwo);
  const handleClickAnchorElTwo = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElTwo(event.currentTarget);
  };
  const handleCloseAnchorElTwo = () => {
    setAnchorElTwo(null);
  };

  const onArchiveReflactive = () => {
    const token = localStorage.getItem("userDetails");
    userService
      ?.archiveReflectiveData(
        {
          archive: true,
          reflectiveId: props?.questionData?.reflectiveId,
        },
        token
      )
      .then((res) => {
        props?.setShowSnakbar(true);
        props?.setSnakbarMsg(res.message);
        props?.setMsgType("success");
        // setShowOutcome(false);
        // setAddOutcomeTracking(true);
        let timerId: number = window.setTimeout(() => {
          props.handleCloseStartModal();
          props.getReflectiveList();
        }, 1000);
      })
      .catch((error) => {
        props?.setShowSnakbar(true);
        props?.setSnakbarMsg(
          error?.response?.data?.description
            ? error?.response?.data?.description
            : error.message
        );
        props?.setMsgType("error");
      });
  };

  const width = useWidth();

  return (
    <RootStyle
      sx={{ height: "100vh", overflowY: 'auto',paddingBottom: '100px !important' }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {width > 768 ? (
          <Typography
            variant="h1"
            component="h1"
            className={classes.registrationHeading}
          >
            Qualitative Reflection
          </Typography>
        ) : (
          <Typography
            variant="h2"
            component="h2"
            fontWeight={600}
            marginTop="45px"
            color="black"
          // className={classes.registrationHeading}
          >
            {"Add a new reflective question"}
          </Typography>
        )}
        {props.questionData?.uid && (
          <div>
            <IconButton
              // aria-label="more"
              // id="long-button"
              // aria-controls={open ? "long-menu" : undefined}
              // aria-expanded={open ? "true" : undefined}
              // aria-haspopup="true"
              // onClick={handleClick}
              onClick={() => {
                setopenWellbeing(true);
              }}
            >
              <ViewNote />
            </IconButton>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={openMenu ? "long-menu" : undefined}
              aria-expanded={openMenu ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClickAnchorElTwo}
            >
              <Donts />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorElTwo}
              open={openMenu}
              onClose={handleCloseAnchorElTwo}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: "20ch",
                },
              }}
            >
              <MenuItem onClick={onArchiveReflactive}>
                Archive this metric
              </MenuItem>
            </Menu>
          </div>
        )}
      </div>
      {width > 768 && (
        <Typography variant="h4" component="h4" className={classes.pageTitle}>
          Add a journaling question that will help you.
        </Typography>
      )}
      <Grid
        display="flex"
        // alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        sx={{
          mb: "16px",
          flexDirection: width > 768 ? "row" : "column",
          alignItems: width > 768 ? "center" : "left",
        }}
      >
        {width > 768 ? (
          <Typography variant="caption" component="div">
            Define reflect journaling questions to help assess your journey
          </Typography>
        ) : (
          <div
            style={{
              fontSize: "16px!important",
              fontWeight: "500",
              lineHeight: "20px",
              color: "black"
            }}
          >
            Define reflect journaling questions to help assess your journey
          </div>
        )}
        {/* <Typography variant="caption" component="div">
          Define reflect journaling questions to help assess your journey
        </Typography> */}
        {!props.showRefQues && (
          <Grid
            display="flex"
            alignItems="center"
            sx={{
              cursor: "pointer",
              marginTop: width > 768 ? "auto" : "10px",
            }}
            onClick={() => {
              showDrawer();
              setShowIdeasMobile(true);
            }}
          >
            <GetIdeasIcon />
            <span
              className={classes.needSomeIdeas}
              style={{ marginTop: width > 768 ? "10px" : "0px" }}
            >
              Need some ideas?
            </span>
          </Grid>
        )}
        {/* RIGHT SIDE DRAWER */}
        {!props.showRefQues || width > 768 && (
          <IdeasDrawer
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            reflectiveIdeas={props.reflectiveIdeas}
            setQuestionData={props.setQuestionData}
          />
        )}
        {showIdeasMobile && width < 769 && (
          <Dialog
            fullScreen
            disableEscapeKeyDown
            onClose={handleCloseStartModal}
            open={showIdeasMobile}
            style={{
              height: "60%",
              bottom: 0,
              top: "auto",
              borderRadius: "35px 35px 0px 0px",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                height: "100%",
                width: "100%",
                backgroundColor: "#11734A",
                padding: "20px",
                paddingTop: "60px",
                color: "#fff",
                overflow: "auto",
              }}
            >
              <Grid
                container
                sx={{ backgroundColor: palette.primary.main }}
              // className={classes.drawerWrapper}
              >
                <Grid>
                  <Typography
                    variant="h2"
                    component="h2"
                    sx={{
                      color: palette.common.white,
                      fontWeight: 500,
                      lineHeight: "35px!important",
                    }}
                  >
                    Our ideas of reflective questions
                  </Typography>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      color: palette.common.white,
                      fontWeight: 400,
                      lineHeight: "35px!important",
                    }}
                  >
                    Choose one of our recommended reflective questions
                  </Typography>
                </Grid>
                <Grid
                  // className={classes.ideasList}
                  sx={{ width: "100%", mt: "40px" }}
                >
                  {props?.reflectiveIdeas?.map((ideas: any) => {
                    return (
                      <div
                        className={classes.ideasListItem}
                        key={ideas._id}
                        onClick={() => {
                          props.setQuestionData(ideas);
                          setShowIdeasMobile(false);
                        }}
                      >
                        {ideas.question}
                      </div>
                    );
                  })}
                </Grid>
              </Grid>
            </div>
          </Dialog>
        )}
      </Grid>
      <Grid>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
            width: width > 768 ? "auto" : "100%"
          }}
        >
          <TextField
            sx={
              !props.showRefQues ? { width: "" } : { width: "100%!important" }
            }
            className="fillTextInput fullWidth"
            placeholder="Add a self Qualitative Reflection"
            variant="outlined"
            fullWidth
            name="influence"
            value={question || ""}
            onChange={(e) => handleChangeQuestion(e)}
            inputProps={ariaLabel}
          />
          <div
            style={{
              backgroundColor: "aliceblue",
              borderRadius: "8px",
              fontSize: "16px",
              maxHeight: "120px",
              overflow: "auto",
              position: "absolute",
              width: "100%",
              top: "55px",
              zIndex: 10,
              color: "black",
            }}
          >
            {question?.length !== 0 &&
              suggestions?.map((suggestion) => {
                return (
                  <div
                    style={{ padding: "8px", cursor: "pointer" }}
                    onClick={() => {
                      props.setQuestionData(suggestion);
                      setSuggestions([]);
                    }}
                  >
                    {suggestion.question}
                  </div>
                );
              })}
          </div>
        </div>
      </Grid>
      <Box mt={4}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              className="checkbox-reflective"
              onChange={(e) => setShowDay(!showDay)}
              value={showDay}
            />
          }
          label="Schedule this action"
        />
        {showDay && (
          <>
            <Typography
              variant="caption"
              component="div"
              sx={{ marginTop: "16px" }}
            >
              Select the days on which you intend to complete this action
            </Typography>
            <Box
              display="flex"
              alignItems="center"
              className={classes.weekDayBox}
            >
              {weekDays?.map((day, index: number) => {
                return (
                  <p
                    onClick={() => {
                      setWeekDayArrClick(day);
                      activeWeekDay(day);
                    }}
                    className={
                      activeWeek?.some((day1) => day1?.day === day?.day)
                        ? "active"
                        : ""
                    }
                    key={index}
                  >
                    {day?.day?.charAt(0)}
                  </p>
                );
              })}
              <Typography
                variant="caption"
                component="div"
                sx={{ marginLeft: "16px" }}
              >
                {props?.weekday?.size > 0 ? (
                  <>= {props?.weekday?.size} Times per week</>
                ) : (
                  ""
                )}
              </Typography>
            </Box>
          </>
        )}
      </Box>
      <div style={{ marginTop: "40px" }}>
        <Typography
          variant="caption"
          component="p"
          className={classes.pageTitle}
        >
          Description
        </Typography>
        <TextField
          variant="outlined"
          className="textarea"
          fullWidth
          placeholder="Add a description..."
          multiline
          minRows={4}
          onChange={(e) => setOutcomeDesc(e.target.value)}
          value={outcomeDesc}
        />
      </div>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center!impoetant" }}>
        {!props.showRefQues && <Button
          className="no-after"
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mr: 1, color: palette.primary.main, p: 0 }}
          style={{ display: width > 768 ? "none" : "flex" }}
        >
          {activeStep === 0 ? <StepperBackArrowDisable /> : <StepperBackArrow />}
        </Button>}
        {!showLoader ? (
          <Button
            type="submit"
            variant="contained"
            className="button-after outcomeButton"
            fullWidth
            sx={{
              mt: "54px",
              width: width > 768 ? "auto" : !props.showRefQues ? "50% !important" : "100% !important",
              borderRadius: width > 768 ? "auto" : "20px",
            }}
            disabled={question === undefined && tempWeekArr.length === 0}
            onClick={() => AddQuestion()}
          >
            {props.questionData?.uid ? "Update Question" : "Add Question"}
          </Button>
        ) : (
          <Button
            variant="contained"
            className="button-after outcomeButton"
            fullWidth
            sx={{
              mt: "54px",
              width: width > 768 ? "auto" : !props.showRefQues ? "50% !important" : "100% !important",
              borderRadius: width > 768 ? "auto" : "20px",
            }}
          >
            <ButtonLoader />
          </Button>
        )}
        {!props.showRefQues && <Button
          className="no-after"
          onClick={handleNext}
          sx={{ color: palette.primary.main, p: 0, cursor: "pointer" }}
          style={{ display: width > 768 ? "none" : "flex" }}
          disabled={
            activeStep === userType.length - 1 ||
            (false
              ? IUserTypeState?.outcomeList?.length == 0
              : false
                ? IUserTypeState?.influenceList?.length == 0
                : true
                  ? IUserTypeState?.reflectiveList?.length == 0
                  : false)
          }
        >
          {activeStep === userType.length - 1 ||
            (false
              ? IUserTypeState?.outcomeList?.length == 0
              : false
                ? IUserTypeState?.influenceList?.length == 0
                : true
                  ? IUserTypeState?.reflectiveList?.length == 0
                  : false) ? (
            <StepperNextArrowDisable />
          ) : (
            <StepperNextArrow />
          )}
        </Button>}
      </div>

      <Wellbeing
        open={openWellbeing}
        journeyData={outcomeState?.notesList?.reflective_data}
        setopenWellbeing={setopenWellbeing}
        outcomeValueInput={question}
      />

      {/* BOTTOM STEPPER */}
      {!props.showRefQues && (
        <BottomStepper
          isOutcome={false}
          isInfluence={false}
          isRefQues={true}
          isTracking={false}
        />
      )}
    </RootStyle>
  );
};
export default LeftSideReflectiveQue;
