import React from "react";

const HeartIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="36" height="36" rx="5" fill="#7572FC" />
      <path
        d="M11.0124 19.4782L17.4266 25.4648L17.4266 25.4649C17.6451 25.6688 17.7544 25.7707 17.8813 25.8018C17.9593 25.8208 18.0407 25.8208 18.1187 25.8018C18.2456 25.7707 18.3549 25.6688 18.5734 25.4649L18.5734 25.4648L24.9876 19.4782C26.7233 17.8582 27.0255 15.2175 25.7007 13.2472L25.4445 12.8662C23.7015 10.274 19.8006 10.5606 18.4552 13.3797C18.2725 13.7625 17.7275 13.7625 17.5448 13.3797C16.1994 10.5606 12.2985 10.274 10.5555 12.8662L10.2993 13.2472C8.97445 15.2175 9.27667 17.8582 11.0124 19.4782Z"
        fill="white"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  );
};

export default HeartIcon;
