import React, { useContext, useEffect } from "react";
import { Box, Typography, SvgIcon } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import palette from "../../theme/palette";
import { styled } from "@mui/material/styles";
import shape from "../../theme/shape";
import { UserTypeContext } from "../../context/userStatus";
import { OutcomeGetList } from "../../interfaces/outcomeModal";
import InfoIcon from "../../assets/images/icon/infoIconYellow";
import { outcomeService } from "../../services/outcome";
import { addOutcomeAction } from "../../store/actions/userTypeAction";

const RootStyle = styled("div")(({ theme }) => ({
  padding: "60px 0",
  [theme.breakpoints.down("lg")]: {
    padding: "50px 0",
  },
  [theme.breakpoints.down("md")]: {
    padding: "40px 0",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "20px 0",
    marginBottom: "20px",
  },
}));

const useStyles = makeStyles((theme) => ({
  recommText: {
    backgroundColor: palette.common.white,
    borderRadius: shape.borderRadius,
    fontSize: "16px",
    lineHeight: "36px",
    color: palette.primary.light,
    padding: "12px 16px",
    cursor: "pointer",
    marginBottom: "12px",
    wordBreak: "break-word",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
      lineHeight: "20px",
    },
  },
  recommTitle: {
    marginBottom: "46px",
    [theme.breakpoints.down("md")]: {
      marginBottom: "36px",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "20px",
    },
  },
  colorCode: {
    width: "36px",
    height: "36px",
    padding: "8px",
    borderRadius: shape.borderRadiusSm,
    marginRight: "12px",
    [theme.breakpoints.down("sm")]: {
      width: "25px",
      height: "25px",
      padding: "3px",
    },
  },
  infoWrapper: {
    backgroundColor: "#FFF4E3",
    border: "1px solid #FF9B37",
    borderRadius: shape.borderRadius,
    padding: "18px 20px",
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
      marginBottom: "10px",
    },
  },
  infoText: {
    color: "#FF9B37",
    fontSize: "16px",
    lineHeight: "16px",
    fontWeight: 400,
    marginLeft: "12px",
  },
  infoWarning: {
    fontSize: "12px",
    lineHeight: "12px",
    fontWeight: 400,
    color: "#9BA19D",
    textAlign: "center",
    marginTop: "8px",
  },
}));

const OutcomeSuggestionList = () => {
  const classes = useStyles();
  const { state: outcomeState, dispatch: userTypeDispatch } =
    useContext(UserTypeContext);

  useEffect(() => {
    // GET OUTCOME LIST
    if (!outcomeState?.outcomeList) {
      outcomeService
        .getOutcomeList(outcomeState.token)
        .then((data: any) => {
          userTypeDispatch(addOutcomeAction(data?.data));
        })
        .catch((error) => {
          console.log("get outcome error");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RootStyle>
      <Box display="flex" alignItems="center" className={classes.recommTitle}>
        <Typography variant="h3" component="h3">
          The Outcome you have chosen to start with
        </Typography>
      </Box>
      {outcomeState?.outcomeList?.length > 0 ? (
        outcomeState?.outcomeList?.map(
          (outcomeItem: OutcomeGetList, index: number) => {
            return (
              <Box key={index}>
                <div className={classes.recommText}>
                  <span
                    className={classes.colorCode}
                    style={{
                      backgroundColor: outcomeItem?.options[0]?.colorCode,
                    }}
                  >
                    <SvgIcon
                      color="action"
                      fontSize="small"
                      sx={{ verticalAlign: "middle" }}
                    >
                      <path d={outcomeItem?.icon} fill={palette.common.white} />
                    </SvgIcon>
                  </span>
                  {outcomeItem?.name}
                </div>
              </Box>
            );
          }
        )
      ) : (
        <Box display="flex" alignItems="center" className={classes.infoWrapper}>
          <InfoIcon />
          <span className={classes.infoText}>
            Please add at least one outcome
          </span>
        </Box>
      )}
      <p className={classes.infoWarning}>
        *You can add more outcomes after the 30 days of baseline tracking
      </p>
    </RootStyle>
  );
};
export default OutcomeSuggestionList;
