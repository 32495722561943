/*eslint @typescript-eslint/no-unused-vars: "off"*/
import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  DialogActions,
} from "@mui/material";
import TextField from "@material-ui/core/TextField";
import clsx from "clsx";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
} from "@material-ui/core";

import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import { styled } from "@mui/material/styles";

// import palette from "../../theme/palette";
// import ProgressStepper from "./progressStepper";
import ModalCloseIcon from "../../../assets/images/icon/modalCloseIconBlack";
import { ContactsDataJson as contacts, ReviewData } from "./contactsData";
import { ButtonLoader } from "../../../components/buttonLoader";
import palette from "../../../theme/palette";
import NewButton from "../../../components/NewButton";
// import { trackingService } from "../../services/tracking";
// import { UserTypeContext } from "../../context/userStatus";
// import { ButtonLoader } from "../../components/buttonLoader";
// import OutcomeclosePopup from "./OutcomeclosePopup";
// import DeleteIcon from "../../assets/images/icon/DeleteIcon";
// import { addCategoryJourneyAction, addJourneyAction } from "../../store/actions/userTypeAction";
const CustomCheckBox = styled(Checkbox)(({ theme }) => ({
  color: "#DCE6E0",
  borderRadius: "4px",

  "&.Mui-checked": {
    color: "#11734A",
    borderRadius: "4px",
  },
}));
const CustomRadio = styled(Radio)(({ theme }) => ({
  color: "#DCE6E0",
  borderRadius: "4px",

  "&.Mui-checked": {
    color: "#11734A",
    borderRadius: "4px",
  },
}));
const useStyles = makeStyles((theme) => ({
  pageTitle: {
    marginBottom: "16px!important",
  },
  raioOptionInputs: {
    height: "48px",

    /* BackgroundColors/GreenyAreaBG */

    /* BackgroundColors/Dividers */

    border: "1px solid #DCE6E0",
    borderRadius: "8px",
    padding: "0 4px",
    "& .MuiOutlinedInput-root": {
      margin: "auto",
      "& input": {
        padding: "2px !important",
      },
    },
  },
  bottomButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "& button:nth-child(2)": {
      marginLeft: "24px",
    },
  },
}));
interface AddContact {
  visibleAddGroupModal: boolean;
  setVisibleAddGroupModal: (val: boolean) => void;
  handleAddGroup: (val: any, update: any) => void;
  showLoadingBtn: boolean;
  setShowLoadingBtn: (val: boolean) => void;
  updateGroupInfo?: any;
}

const AddGroupModal = (props: AddContact) => {
  const classes = useStyles();

  const [groupnName, setGroupname] = useState(
    props?.updateGroupInfo?.groupName
  );
  const [radioValue, setRadioValue] = useState("immediatly");
  // const { state: outcomeState, dispatch: UserTypeDispatch } = useContext(UserTypeContext);
  const [openStartModal, setStartModal] = useState(true);
  const { showLoadingBtn, setShowLoadingBtn } = props;
  const [outcomeNotes, setOutcomeNotes] = useState<any>("");
  const [item, setItem] = useState<any>();
  const [editData, setEditData] = useState<any>();
  const [openOutcomeclosePopup, setopenOutcomeclosePopup] = useState(false);
  const [openOutcomeDeletePopup, setopenOutcomeDeletePopup] = useState(false);

  useEffect(() => {}, []);

  return (
    <Grid>
      <Dialog
        onClose={() => props.setVisibleAddGroupModal(false)}
        open={props.visibleAddGroupModal}
        className="dialogWrapper dialogWrapper-addContact dialogWrapper-mobileScreen"
        fullWidth
        sx={{
          "& .MuiPaper-root": {
            paddingBottom: "10px",
          },
        }}
      >
        <DialogTitle>
          <Typography variant="h6" component="h6">
            {props?.updateGroupInfo &&
            Object.keys(props?.updateGroupInfo).length
              ? "Rename"
              : "Add"}{" "}
            Group
          </Typography>

          {/* <IconButton
            sx={{ position: "absolute", top: 0, right: 0 }}
            // onClick={handleCloseStartModal}
            onClick={() => props.handleShareModalOpen(false)}
          >
            <ModalCloseIcon />
          </IconButton> */}
        </DialogTitle>
        <DialogContent>
          <div style={{ marginTop: "20px" }}>
            <Typography
              variant="caption"
              component="p"
              className={classes.pageTitle}
            >
              Group Name
            </Typography>
            <TextField
              variant="outlined"
              fullWidth
              placeholder="Add Group name"
              className={classes.raioOptionInputs}
              onChange={(e) => setGroupname(e.target.value)}
              value={groupnName}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Box className={classes.bottomButton}>
            <NewButton
              buttonText="Cancel"
              variant="outlined"
              onClick={() => props.setVisibleAddGroupModal(false)}
            />
            <NewButton
              buttonText={
                props?.updateGroupInfo &&
                Object.keys(props?.updateGroupInfo).length
                  ? "Rename"
                  : "Add"
              }
              isDisabled={!groupnName}
              variant="contained"
              isLoadong={showLoadingBtn}
              type="submit"
              onClick={() =>
                props.handleAddGroup(
                  {
                    ...(props?.updateGroupInfo || {}),
                    groupName: groupnName,
                    id: { ...(props?.updateGroupInfo || {}) }?._id,
                    contacts:
                      { ...(props?.updateGroupInfo || {}) }?.contacts || [],
                  },
                  { ...(props?.updateGroupInfo || {}) }?.uid
                )
              }
            />
          </Box>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
export default AddGroupModal;
