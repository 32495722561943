import {
  DialogContent,
  Button,
  Dialog,
  DialogActions,
  Typography,
} from "@mui/material";
import { makeStyles } from "@material-ui/core";
import React from "react";
import { useNavigate } from "react-router";

interface AddCategoryProps {
  open: boolean;
  handleClose: any;
}

const useStyles = makeStyles((theme) => ({
  categoryDialog: {
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        borderRadius: "20px",
        padding: "48px 40px",
        width: "501px",

        "& .MuiDialogContent-root": {
          padding: "0",
          overflowY: "unset",
        },

        "&. MuiDialogActions-root": {},
      },
    },
  },
  categoryDialogAction: {
    display: "flex !important",
    alignItems: "center !important",
    justifyContent: "start !important",
  },
}));
const AddCategoryModal = (props: AddCategoryProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const isCategoryPresent = localStorage.getItem("categoryCount");

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.categoryDialog}
    >
      <DialogContent id="alert-dialog-description">
        <Typography variant="h3" component="h3" lineHeight="120% !important">
          Add categories to personalize your tracking page
        </Typography>
        <Typography variant="subtitle1" component="div" sx={{ mt: "20px" }}>
          Group your outcomes, influences and qualitative reflection in your own
          way.
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{ mt: "60px" }}
        className={classes.categoryDialogAction}
      >
        
        <Button
          type="submit"
          variant="contained"
          className="button-after outcomeButton"
          onClick={() => navigate("/add-category")}
          sx={{ width: "65% !important", mb: "0 !important", padding: "15px 30.5px !important" }}
        >
          {isCategoryPresent ? (isCategoryPresent !== "0") ? "Update Category" : "Add Category" : "Add Category"}
        </Button>
        <Button
          variant="outlined"
          onClick={props.handleClose}
          sx={{ width: "35% !important", padding: "13px" }}
        >
          <Typography variant="h4" component="h4">
            Later
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCategoryModal;
