import { SxProps, Theme, Typography } from "@mui/material"
import { pxToRem } from "../theme/typography"
import React from "react"
import palette from "../theme/palette"
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from "@material-ui/core";
// Define custom prop types for Tooltip
interface CustomTooltipProps {
    color?: string;
    size?: number;
    fontWeight?: number
    lineHeight?: number
}

// Custom styled Tooltip accepting color and size props
const HtmlTooltip = styled(({ className, ...props }: CustomTooltipProps & any) => (
    <Tooltip {...props} classes={{ popper: className }} />
))<CustomTooltipProps>(({ color, size, lineHeight, fontWeight } : any) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#EEEEEE',
        maxWidth: 350,
        fontWeight: fontWeight,
        lineHeight: `${lineHeight ? pxToRem(lineHeight) : "120%"} !important`,
        color: color || '#000', // Apply custom color or default
        fontSize: `${pxToRem(size)} !important`, // Apply custom size or default
        border: '1px solid #dadde9',
    },
}));

const CommonTypo = ({
    color = palette.common.black,
    size = 16,
    mobileSize,
    fontWeight = 400,
    lineHeight = 16,
    extraSx = {},
    title = "",
    children
}: {
    color?: string;
    size?: number;
    mobileSize?: number
    lineHeight?: number
    fontWeight?: number;
    extraSx?: SxProps<Theme>;
    boxSx?: SxProps<Theme>;
    title?: string;
    typographySx?: SxProps<Theme>;
    children: React.ReactNode
}) => {
    const typoe = <Typography component={"span"} variant="inherit" title={title} sx={{
        color: color,
        fontSize: `${pxToRem(size)} !important`,
        lineHeight: `${lineHeight ? pxToRem(lineHeight) : "120%"} !important`,
        fontWeight: `${fontWeight} !important`,
        "&.MuiTypography-root": {
            "@media  (max-width: 600px) and (min-width: 100px)": {
                fontSize: `${pxToRem(mobileSize || size)} !important`,
                lineHeight: `${lineHeight ? pxToRem(lineHeight) : "100%"} !important`,
                fontWeight: `${fontWeight} !important`,
            },
        },
        ...extraSx
    }}>{children}</Typography>
    return title ? <HtmlTooltip
        title={title}
        color={color}
        fontWeight={fontWeight}
        lineHeight={lineHeight}
        size={size} // Pass the color and size as props
        placement="bottom"
    >
        {typoe}
    </HtmlTooltip> : typoe
}

export default CommonTypo