import React, { useEffect, useState } from "react"
import { Box, Grid } from "@mui/material"
import insightsProgressStyles from "./insightsClasses"
import CommonTypo from "../../components/CommonTypo"
import MoreButton from "./MoreButton"
import Star from "../../assets/images/icon/Star";
const PositiveNegative = ({ selectOutcome, outcomeData, getInsightDetails }) => {
    const classesV2 = insightsProgressStyles()
    const [showMore, setShowMore] = useState(false)
    const [maxCount, setMaxCount] = useState(0)
    const [showCount, setShowCount] = useState(5)
    const [influenceData, setinfluenceData] = useState([])
    const [positiveData, setpositiveData] = useState([])
    const [negativeData, setnegativeData] = useState([])
    const [foundOutcome, setFoundOutcome] = useState<{
        [x: string]: any
    }>({})

    useEffect(() => {
        if (selectOutcome) {
            const found = outcomeData.find((it) => it.outcomeId === selectOutcome);
            if (found && found.influence) {
                setMaxCount(found?.influenceLength)
                setFoundOutcome(found)
                setinfluenceData(found.influence)
                setpositiveData(found.influence && found.influence.length > 0 ? found.influence.filter(it => it.correlation > 0).slice(0, showCount) : []);
                setnegativeData(found.influence && found.influence.length > 0 ? found.influence.filter(it => it.correlation < 0).slice(-showCount).reverse() : []);
            }
        }
    }, [showCount, outcomeData, selectOutcome])
    const heighestPositiveCorrelation = Math.max(
        ...positiveData.map((o) => Math.abs(o.correlation || 0))
    );
    const heighestNegativeCorrelation = Math.max(
        ...negativeData.map((o) => Math.abs(o.correlation || 0))
    );

    return <>
        <Box sx={{ flexGrow: 1,  /* position: "relative" */ }}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
                <Grid item xs={1} sm={2} md={16} lg={6} xl={6}>
                    <Box className={classesV2.positiveNegativeBox} >
                        <Box sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-start",

                        }}>
                            <Box sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "column",
                                gap: "8px"
                            }}>
                                <CommonTypo size={24} color="#3E4240" fontWeight={700} extraSx={{
                                    whiteSpace: "nowrap",
                                }}>{"Positive influences"}</CommonTypo>
                            </Box>
                        </Box>
                        <Box sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-start",

                        }}>
                            <Box sx={{
                                width: "100%",
                                padding: "0px",
                                gap: "8px",
                                borderRadius: "12px",
                                // border: "1px solid #EEEEEE",
                                display: "flex",
                                flexDirection: "column",
                            }}>
                                <CommonTypo size={16} color="#3E4240" fontWeight={600} lineHeight={24} extraSx={{
                                    whiteSpace: "nowrap",
                                }}>{"Summary"}</CommonTypo>
                                <CommonTypo size={16} color="#656565" lineHeight={24} fontWeight={400} >
                                    <div dangerouslySetInnerHTML={{
                                        __html: foundOutcome?.positiveInfluence
                                    }}></div>
                                    {/* Increased meditation time and increased frequency of Journal
                                    <CommonTypo size={16} color="#656565" fontWeight={700} >
                                        strongly enhance
                                    </CommonTypo>
                                    Sense of progress */}
                                </CommonTypo>
                                {/* <CommonTypo size={14} color="#656565" lineHeight={14} fontWeight={700} extraSx={{
                                    textDecoration: "underline"
                                }} >
                                    More &gt;
                                </CommonTypo> */}
                            </Box>
                        </Box>

                        {positiveData && positiveData.length > 0 && positiveData.map((it, index) => {
                            return <Box sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "flex-start",
                            }}><Box sx={{
                                width: "100%",
                                padding: "12px 0px 0px",
                                gap: "8px",
                                display: "flex",
                                flexDirection: "column",
                            }}>
                                    <InformationBox
                                        number={index + 1}
                                        title={it.name}
                                        star={it.star}
                                        correlation={it.correlation}
                                        strongTitle={it.correlationTitle}
                                        nutral={index >= 5}
                                        positive={true}
                                        dataPoints={it?.dataPoints}
                                        regression={it?.regression?.slope}
                                        maxCorrekation={heighestPositiveCorrelation}
                                    />
                                </Box>
                            </Box>
                        })}


                    </Box>
                </Grid>
                <Grid item xs={1} sm={2} md={16} lg={6} xl={6}>
                    <Box className={classesV2.positiveNegativeBox} >
                        <Box sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-start",

                        }}>
                            <Box sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                flexDirection: "column",
                                gap: "8px"
                            }}>
                                <CommonTypo size={24} color="#3E4240" fontWeight={700} extraSx={{
                                    whiteSpace: "nowrap",
                                }}>{"Negative influences"}</CommonTypo>
                            </Box>
                        </Box>
                        <Box sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-start",

                        }}>
                            <Box sx={{
                                width: "100%",
                                padding: "0px",
                                gap: "8px",
                                borderRadius: "12px",
                                // border: "1px solid #EEEEEE",
                                display: "flex",
                                flexDirection: "column",
                            }}>
                                <CommonTypo size={16} color="#3E4240" lineHeight={24} fontWeight={600} extraSx={{
                                    whiteSpace: "nowrap",
                                }}>{"Summary"}</CommonTypo>
                                <CommonTypo size={16} color="#656565" lineHeight={24} fontWeight={400} >
                                    <div dangerouslySetInnerHTML={{
                                        __html: foundOutcome?.negativeInfluence
                                    }}></div>
                                    {/*  Dating apps on Monday, Tuesday and Wednesday
                                    <CommonTypo size={16} color="#656565" fontWeight={700} >
                                        strongly diminish
                                    </CommonTypo>
                                    Sense of progress */}
                                </CommonTypo>
                                {/* <CommonTypo size={14} color="#656565" lineHeight={14} fontWeight={700} extraSx={{
                                    textDecoration: "underline"
                                }}>
                                    More &gt;
                                </CommonTypo> */}
                            </Box>
                        </Box>
                        {negativeData && negativeData.length > 0 && negativeData.map((it, index) => {
                            return <Box sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "flex-start",
                            }}><Box sx={{
                                width: "100%",
                                padding: "12px 0px 0px",
                                gap: "8px",
                                display: "flex",
                                flexDirection: "column",
                            }}>
                                    <InformationBox
                                        number={index + 1}
                                        title={it.name}
                                        star={it.star}
                                        correlation={it.correlation}
                                        strongTitle={it.correlationTitle}
                                        nutral={index >= 5}
                                        positive={false}
                                        regression={it?.regression?.slope}
                                        dataPoints={it?.dataPoints}
                                        maxCorrekation={heighestNegativeCorrelation}
                                    />
                                </Box>
                            </Box>
                        })}
                    </Box>
                </Grid>
            </Grid>
            <MoreButton active={showCount < 10 ? false : true} onClick={() => {
                if (showCount < 10) {
                    getInsightDetails(influenceData.length + 10)
                }
                setShowCount(showCount < 10 ? showCount + 5 : showCount - 5)
            }} />
        </Box>
    </>
}

export default PositiveNegative

const InformationBox = ({
    positive = true,
    nutral = false,
    number = 1,
    title = "Meditation",
    strongTitle = "Very Strong",
    correlation = 1,
    regression = 0.45,
    star = 4,
    maxCorrekation = 1,
    dataPoints = 0
}) => {
    const stars = [];
    for (let index = 0; index < star; index++) {
        stars.push(
            <>
                <Star color={"#F9D412"} size={16} />
            </>
        );
    }
    for (let index = star; index < 5; index++) {
        stars.push(
            <>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.16432 4.44738L8 1.76891L9.83568 4.44738C10.0697 4.78889 10.4144 5.03928 10.8115 5.15635L13.9261 6.07449L11.946 8.64801C11.6935 8.97614 11.5619 9.38128 11.5733 9.79515L11.6625 13.0411L8.60309 11.9531C8.21299 11.8144 7.78701 11.8144 7.39691 11.9531L4.33746 13.0411L4.42673 9.79515C4.43811 9.38128 4.30647 8.97615 4.054 8.64801L2.07388 6.07449L5.18851 5.15635C5.58564 5.03928 5.93027 4.78889 6.16432 4.44738Z" stroke="#EEEEEE" stroke-width="2" />
                </svg>

            </>
        );
    }
    const CorrelationnWidth =
        (Math.abs(correlation) * 100) / maxCorrekation;
    return <Box sx={{
        display: "flex",
        gap: number > 9 ? "26px" : "32px",
        alignItems: "flex-start"
    }}>
        <CommonTypo size={nutral ? 16 : 32} color="#19442E" lineHeight={nutral ? 24 : 48} fontWeight={600} extraSx={{
            textAlign: "center"
        }}>
            {number}
        </CommonTypo>
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            width: "100%",
        }}>
            <Box sx={{
                width: `100%`,
                // height: "48px",
                padding: "0px",
                borderRadius: "12px",
                // background: nutral ? "#EEEEEE" : !positive ? "#FFA9A9" : "#ADE888",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
            }}>
                <Box sx={{
                    display: "flex",
                    gap: "12px",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                }}>
                    {/* <CommonTypo size={16} color="#3E4240" lineHeight={16} fontWeight={600} extraSx={{
                        // overflow: 'hidden',
                        display: '-webkit-box',
                        lineClamp: 1,
                        boxOrient: 'vertical',
                        textOverflow: 'ellipsis',
                        width: "calc(100% - 180px)"
                    }}>
                        {title}
                    </CommonTypo> */}
                    <CommonTypo size={16} color="#3E4240" lineHeight={20} title={title} fontWeight={600} extraSx={{
                        flexGrow: 1, // Allow the title to take up remaining space
                        flexShrink: 1, // Allow the title to shrink if needed
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        // width: "calc(100% - 200px)"
                    }}>{title.length > 30 ? `${title.slice(0, 47)}...` : title}</CommonTypo>
                    <Box sx={{
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                    }}>
                        <CommonTypo size={14} color="#3E4240" lineHeight={14} fontWeight={400} >
                            {strongTitle}
                        </CommonTypo>
                        <CommonTypo size={16} color="#3E4240" lineHeight={16} fontWeight={600} >
                            {correlation.toString()}
                        </CommonTypo>
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                width: `${correlation && correlation !== 0
                    ? CorrelationnWidth
                    : 0
                    }%`,
                height: "8px",
                padding: "0px",
                borderRadius: "12px",
                background: nutral ? "#EEEEEE" : !positive ? "#FFA9A9" : "#ADE888",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
            }}>

            </Box>
            <Box sx={{
                width: "100%",
                height: "48px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "8px"
            }}>
                <Box sx={{
                    height: "48px",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px"
                }}>
                    <Box sx={{
                        height: "48px",
                        padding: "14px 6px",
                        gap: "4px",
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "12px",
                        // border: "1px solid #EEEEEE"
                    }}>
                        <CommonTypo size={14} color="#3E4240" lineHeight={14} fontWeight={400} >
                            {"Regression"}
                        </CommonTypo>
                        <CommonTypo size={16} color="#3E4240" lineHeight={16} fontWeight={500} >
                            {regression.toString()}
                        </CommonTypo>
                    </Box>
                    <Box sx={{
                        height: "48px",
                        padding: "14px 12px",
                        gap: "4px",
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "12px",
                        // border: "1px solid #EEEEEE"
                    }}>
                        <CommonTypo size={14} color="#3E4240" lineHeight={14} fontWeight={400} >
                            {"Significance"}
                        </CommonTypo>
                        <Box sx={{
                            display: "flex",
                            gap: "4px",
                            alignItems: "center",
                        }}>
                            {stars}
                            <CommonTypo size={16} color="#3E4240" lineHeight={16} fontWeight={500} >
                                {star}
                            </CommonTypo>
                        </Box>

                    </Box>
                </Box>
                <Box sx={{
                    height: "48px",
                    padding: "14px 12px",
                    gap: "4px",
                    display: "flex",
                    alignItems: "center",
                    borderRadius: "12px",
                    // border: "1px solid #EEEEEE"
                }}>
                    <CommonTypo size={14} color="#3E4240" lineHeight={14} fontWeight={400} >
                        {"Data points"}
                    </CommonTypo>
                    <CommonTypo size={16} color="#3E4240" lineHeight={16} fontWeight={500} >
                        {dataPoints.toString()}
                    </CommonTypo>

                </Box>
            </Box>

        </Box>
    </Box>
}