import React, { useReducer } from "react";
import {
    INotificationState, INotificationActions,
    UserTypeContextModal,
} from "../interfaces/notificationModal";

const defaultState: INotificationState = {
    anchorEl: null,
    xposition: 0,
    yposition: 0,
    alreadyCalled: false,
    notifications: [],
    unreadnotifications: []
};
/*  if ((!outcomeState?.journeyCategoryList || outcomeState?.journeyCategoryList.length <= 0) && !props?.showLoader) {
      setShowEmpty(true)
    } else {
      setShowEmpty(false)
    } */
const reducer = (
    state: INotificationState,
    action: INotificationActions
): INotificationState => {
    switch (action.type) {
        case "SET_ANCHOR":
            return {
                ...state,
                anchorEl: action.payload,
            };
        case "SET_X_POS":
            return {
                ...state,
                xposition: action.payload,
            };
        case "SET_Y_POS":
            return {
                ...state,
                yposition: action.payload,
            };
        case "SET_NOTIFICATIN_DATA":
            return {
                ...state,
                notifications: action.payload,
                alreadyCalled: true
            }
        case "SET_UR_NOTIFICATIN_DATA":
            return {
                ...state,
                unreadnotifications: action.payload,
                alreadyCalled: true
            }
        case "UPDATE_UR_NOTIFICATIN_DATA":
            if (action.payload && action.payload.length) {
                let newState = [...state.unreadnotifications]
                let newNOts = [...state.notifications]
                newState = [...newState].filter(it => !action.payload.includes(it._id))
                newNOts = newNOts.map(it => {
                    if (action.payload.includes(it._id)) {
                        it.read = true
                    }
                    return it
                })
                return {
                    ...state,
                    notifications: newNOts,
                    unreadnotifications: newState
                }
            } else {
                return state
            }
        default:
            return state;
    }
};

export const UserNotificationContext = React.createContext({} as UserTypeContextModal);
type childernType = {
    children: React.ReactNode;
};
export const Provider: React.FC = (props: childernType) => {
    const [state, dispatch] = useReducer(reducer, defaultState);

    return (
        <UserNotificationContext.Provider value={{ state, dispatch }}>
            {props.children}
        </UserNotificationContext.Provider>
    );
};
