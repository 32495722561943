import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  Typography,
  styled,
  AccordionDetails,
  Divider,
  Grid,
  Menu,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  MenuItem,
} from "@mui/material";
import { FixedSizeList as List } from "react-virtualized";
import ArrowUp from "../../assets/images/icon/arrow_up";
import { UserTypeContext } from "../../context/userStatus";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import clsx from "clsx";
// import Calendar from "./Calendar";
// import ModeSwitchTab from "./../tracker/ModeSwitchTab";
// import PersonalTrackingOutcome from "./PersonalTrackingOutcome";
// import MuiAccordionSummary from "@mui/material/AccordionSummary";
// import ThreeDotsIcon from "../../assets/images/icon/ThreeDotsIcon";
// import PersonalTrackingInfluence from "./PersonalTrackingInfluence";
// import PersonalTrackingReflective from "./PersonalTrackingReflective";
import { makeStyles } from "@material-ui/core";

import { addCategoryExpandAction } from "../../store/actions/userTypeAction";
import ExpandIcon from "../../assets/images/icon/ExpandIcon";
import palette from "../../theme/palette";
import { pxToRem } from "../../theme/typography";
import { useNavigate } from "react-router-dom";
import LimitReachedPopup from "./limitReachedModal";
import { TrackingContext } from "../../context/userTracking";
import {
  setExpandView,
  setOpenCategoryModal,
  setTrackingState,
} from "../../store/actions/trackerAction";
import { ContactsContext } from "../../context/userIContacts";
import { setShareModalOpen } from "../../store/actions/contactsActions";
import { debounce } from "lodash";
import {
  AddUpdateQuestion,
  InfluenceEdit,
  InfluenceToInfluence,
  TransformInfluToOutcome,
  TransfotmToInfluenceOutcome,
  UpdateOutome,
} from "./trackerAddDrawer";
import moment from "moment";
import useOnScreen from "../../components/checkIfElemtInViewport";
import useStyles from "./TrackingClasses";
import ModeSwitchTab from "../tracker/ModeSwitchTab";
import CurrentDateTitle from "./CurrentDateTitle";
import PersonalItemRow from "./ItemPeronslRow";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  backgroundColor: "transparent",
  "&:before": {
    display: "none",
  },
  "& .MuiAccordionSummary-root": {
    minHeight: 0,
    padding: 0,
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(0deg)",
  },
}));

interface PersonalTrackBodyModal {
  createDate: any;
  prevMonth: any;
  nextMonth: any;
  isExpandable?: boolean;
  selectedDate: any;
  setSelectedDateInfluence: (val: any) => void;
  influenceId: any;
  setInfluenceIdCalendar: (val: any) => void;
  callservice: any;
  // viewPortCategory: any;
  // setviewPortCategory: any
}
const PersonalTrackBody = (props: PersonalTrackBodyModal) => {
  const classes = useStyles();

  const { state: outcomeState, dispatch: userTypeDispatch } =
    useContext(UserTypeContext);
  const { state: ITrackerState, dispatch: trackingDispatch } =
    useContext(TrackingContext);
  const { state: IContactTypeState, dispatch: contactsDispatch } =
    useContext(ContactsContext);
  const { calendar } = ITrackerState;

  const [outcomeId, setOutcomeId] = useState("");
  const [outcomeInput, setShowOutcomeInput] = useState(false);
  const [influenceInput, setShowInfluenceInput] = useState(false);
  const [influenceId, setInfluenceId] = useState("");
  const [refId, setRefId] = useState("");

  let newOutcomes = [];
  if (
    outcomeState?.journeyCategoryList &&
    outcomeState?.journeyCategoryList?.length > 0
  ) {
    newOutcomes = [...outcomeState?.journeyCategoryList].filter(
      (it) => !["outcome", "influence", "reflective"].includes(it.categoryId)
    );
  }
  let addHeight = 0;
  const foundElement = document.getElementById("track-quote");
  if (foundElement) {
    const rect = foundElement.getBoundingClientRect();
    if (rect.height) {
      addHeight = rect.height - 10;
    }
  }
  return (
    <>
      <div
        className={`${classes.trackBody} commonTrackBody`}
        style={{
          position: "relative",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "auto",
          }}
        >
          <table
            className={classes.tableClass}
            border={0}
            cellPadding={0}
            cellSpacing="0"
            id="main-personal-table"
            style={{
              height: `calc(100vh - ${addHeight + 100}px)`,
            }}
          >
            <thead>
              <tr className={clsx(classes.row, classes.stickeyClass)}>
                <th
                  className={clsx(classes.headerSwitch, classes.stickeyClass)}
                >
                  <div
                    style={{
                      position: "sticky",
                      top: 0,
                      alignSelf: "flex-start",
                      // zIndex: 100,
                      background: "#fff",
                      zIndex: 1,

                      minWidth: "100%",
                      maxWidth: "100%",
                    }}
                  >
                    <ModeSwitchTab
                      handleClickOpen={() => {
                        trackingDispatch(setOpenCategoryModal(true));
                      }}
                      handleShareModalOpen={(val) => {
                        contactsDispatch(setShareModalOpen(val));
                      }}
                    />
                  </div>
                </th>
                <th
                  className={clsx(classes.headerDate, classes.stickeyClassDate)}
                  colSpan={calendar[0] && calendar[0].length}
                >
                  <CurrentDateTitle
                    prevMonth={props.prevMonth}
                    nextMonth={props.nextMonth}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {outcomeState?.journeyCategoryList &&
                outcomeState?.journeyCategoryList?.length &&
                outcomeState?.journeyCategoryList.map((category, index) => {
                  return (
                    <PersonalItemRow
                      category={category}
                      index={index}
                      journyList={outcomeState?.journeyCategoryList}
                      setOutcomeId={setOutcomeId}
                      outcomeId={outcomeId}
                      setInfluenceId={setInfluenceId}
                      influenceId={influenceId}
                      refId={refId}
                      callservice={props?.callservice}
                      setRefId={setRefId}
                      setShowOutcomeInput={setShowOutcomeInput}
                      setShowInfluenceInput={setShowInfluenceInput}
                      setInfluenceIdCalendar={props.setInfluenceIdCalendar}
                      setSelectedDateInfluence={props.setSelectedDateInfluence}
                    />
                  );
                })}
            </tbody>
          </table>
        </div>

        <EmptyBoxPersonal />
        <UpdateOutome />
        <InfluenceEdit getInfluenceList={() => {}} />
        <TransformInfluToOutcome callservice={props.callservice}/>
        <InfluenceToInfluence callservice={props.callservice}/>
        <TransfotmToInfluenceOutcome callservice={props.callservice}/>
        <TransformInfluToOutcome callservice={props.callservice}/>
        <AddUpdateQuestion getReflectiveList={() => {}} />
      </div>
    </>
  );
};

export default PersonalTrackBody;

const EmptyBoxPersonal = () => {
  const navigate = useNavigate();
  const { state: outcomeState, dispatch: userTypeDispatch } =
    useContext(UserTypeContext);
  const { state: ITrackerState, dispatch: trackingDispatch } =
    useContext(TrackingContext);
  const { showLoader } = ITrackerState;
  return (
    outcomeState?.showEmpty &&
    !showLoader && (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
          position: "absolute",
          width: "100%",
          flex: "1 0 0",
          alignSelf: "stretch",
          height: "calc(100vh - 350px)",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "12px",
            width: "360px",
            zIndex: 103,
          }}
        >
          <Typography
            variant="h3"
            component="span"
            lineHeight="130% !important"
            sx={{
              width: "100%",
              textAlign: "center",
              color: palette.common.black,
              fontSize: `${pxToRem(24)} !important`,
              fontWeight: 600,
            }}
          >
            You haven’t added any categories for your personal structure
          </Typography>
          <Typography
            variant="h3"
            component="span"
            lineHeight="150% !important"
            sx={{
              width: "100%",
              textAlign: "center",
              color: `#A0A0A1`,
              fontSize: `${pxToRem(16)} !important`,
              fontWeight: 400,
            }}
          >
            Start adding categories to structure the metrics your way
          </Typography>
        </div>
        <Button
          variant="contained"
          // onClick={() => onClick={() => navigate("/add-category")}}
          onClick={() => navigate("/add-category")}
          sx={{
            display: "flex",
            padding: "16px 20px !important",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "100px",
            border: "8px solid #DFECE7",
            boxShadow: "none",
          }}
        >
          <Typography
            variant="h3"
            component="span"
            lineHeight="100% !important"
            sx={{
              color: palette.common.white,
              fontSize: `${pxToRem(16)} !important`,
              fontWeight: 400,
            }}
          >
            Add a personal category
          </Typography>
        </Button>
      </div>
    )
  );
};
