/*eslint @typescript-eslint/no-unused-vars: "off"*/
/*eslint eqeqeq: "off"*/
import {
  DialogTitle,
  Grid,
  Typography,
  Dialog,
  DialogContent,
  Tooltip,
  Drawer,
} from "@mui/material";
import { makeStyles } from "@material-ui/core";
import React, { useContext, useState } from "react";
import EditIcon from "../../assets/images/icon/EditIcon";
import palette from "../../theme/palette";
import LeftSideOutCome from "../outcome/leftOutcome";
import ModalCloseIconBlack from "../../assets/images/icon/modalCloseIconBlack";
import { OutcomeStatus } from "../../utils/staticDataJson";
import LeftSideInfluence from "../influence/leftSideInfluence";
import LeftTransformToInflu from "./LeftTransformToInflu";
import SnackBarComp from "../../components/snackBar";
import LeftSideOutComeNew from "../outcome/leftOutcomeNew";
import GetIdeasIcon from "../../assets/images/icon/getIdeas";
import RightSideOutcomeTrackingNew from "./rightSideOutcomeTrackingNew";
import { UserTypeContext } from "../../context/userStatus";
import MultiDrawerAddEditComponent from "../../components/MultiDrawerComponent";

const useStyles = makeStyles((theme) => ({
  // listText: {
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "space-between",
  //   padding: "0px 0px",
  //   minHeight: "47px",
  //   "& h6": {
  //     color: palette.primary.light,
  //     fontSize: "16px",
  //     lineHeight: "24px",
  //     maxWidth: "275px",
  //     textOverflow: "ellipsis",
  //     overflow: "hidden",
  //     whiteSpace: "nowrap",
  //   },
  // },
  drawerTitle: {
    display: 'flex',
    padding: '20px 24px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    alignSelf: 'stretch',
    borderBottom: '1px solid #F3F3F3',
    background: "#FFF"
  },
  textClass: {
    overflow: 'hidden',
    display: '-webkit-box',
    lineClamp: 1,
    boxOrient: 'vertical',
    textOverflow: 'ellipsis',
    margin: "20px 0px !important",
  },
  fullTexttextClass: {
    overflow: 'hidden',
    display: '-webkit-box',
    lineClamp: 8,
    boxOrient: 'vertical',
    textOverflow: 'ellipsis',
    margin: "20px 0px !important",
  },
  listText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 24px",
    gap: '12px',
    // boxShadow: '5px 0px 40px 0px rgba(18, 18, 19, 0.05)',
    // "& h6": {
    //   color: palette.primary.light,
    //   fontSize: "16px",
    //   lineHeight: "24px",
    //   maxWidth: "275px",
    //   textOverflow: "ellipsis",
    //   overflow: "hidden",
    //   whiteSpace: "nowrap",
    // },
    "& h6": {
      color: palette.common.black,
      fontSize: "16px",
      lineHeight: "24px",
      // maxWidth: "275px",
      // textOverflow: "ellipsis",
      // overflow: "hidden",
      // whiteSpace: "nowrap",
    },
  },
  modalCloseIcon: {
    position: "absolute",
    right: 0,
    top: 0,
    cursor: "pointer",
    zIndex: 1,
  },
  mainDivClas: {
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: "center",
    borderBottom: '1px solid #F3F3F3',
    "&:hover": {
      '& .hideElem': {
        display: "visible",
      }
    },
  },
  mainDivClasExpand: {
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: "flex-start",
    borderBottom: '1px solid #F3F3F3',
    height: "221px",
    background: '#FFF',
    flexBasis: "100%",
  },
  hideElem: {
    display: "none"
  }
}));

interface TrackingItemModal {
  outcome: any;
  setShowOutcome: (showOutcome: boolean) => void;
  showOutcome: boolean;
  openStartModal: boolean;
  setStartModal: (openStartModal: boolean) => void;
  getOutcomeList?: any;
  setTransformToInfluenceTracking?: any;
  transformToInfluenceTracking?: any;
  startDate: any
  setStartDate: any
  endDate: any
  setEndDate: any
  index: number
  outcomeList: any[];
  isExpand: boolean
  setLimitReachPopupVisible: any
setLimitReachPopuptype: any
}

const TrackingItem = (props: TrackingItemModal) => {
  const classes = useStyles();
  const [outComeValue, setOutcomeValue] = useState("");
  const [outComeId, setOutcomeId] = useState(null);
  const [addOutcomeTracking, setAddOutcomeTracking] = useState(false);
  const [textInput, setTextInput] = useState(OutcomeStatus);
  const [outcomeIdeas, setOutcomeIdeas] = useState();
  const [showSnakbar, setShowSnakbar] = useState(false);
  const [snakbarMsg, setSnakbarMsg] = useState("");
  const [msgType, setMsgType] = useState("");
  const [showIcon, setshowIcon] = useState(false);
  const [needIdeasOpen, setNeedIdeasOpen] = useState(false)
  const handleCloseSnakbar = () => {
    setShowSnakbar(false);
  };

  const handleCloseStartModal = () => {
    setOutcomeId(null);
    props.setStartModal(false);
    props.setShowOutcome(false);
  };

  const handleCloseTransformModal = () => {
    props?.setTransformToInfluenceTracking(false);
  };

  const editOutcomeTracking = (outcome: any, id: any) => {
    props.setStartModal(true);
    setOutcomeId(id);
    props.setShowOutcome(true);
    setOutcomeValue(outcome);
  };
  return (
    <Grid item className={classes.listText}>
      <>
        {/* SNACK BAR */}
        <SnackBarComp
          showSnakbar={showSnakbar}
          handleCloseSnakbar={handleCloseSnakbar}
          snakbarMsg={snakbarMsg}
          type={msgType ? msgType : "info"}
        />
        <div
          className={props?.isExpand ? classes.mainDivClasExpand : classes.mainDivClas}
          style={(props.outcomeList.length - 1) === props.index ? { borderBottom: 0 } : {}}
          onMouseEnter={() => setshowIcon(true)}
          onMouseLeave={() => setshowIcon(false)}
        >
          {props.outcome.description ? (
            <Tooltip
              title={
                <Typography sx={{ fontSize: "14px !important", lineHeight: "1.5" }}>
                  {props.outcome.description}
                </Typography>
              }
            >
              <div style={{
                flex: '1 0 0'
              }}>
                <Typography variant="h6" flex={7} title={props.outcome.name} className={props?.isExpand ? classes.fullTexttextClass : classes.textClass}>
                  {" "}
                  {props.outcome.name}
                </Typography>
              </div>
            </Tooltip>
          ) : (
            <div style={{
              flex: '1 0 0'
            }}>
              <Typography variant="h6" flex={7} title={props.outcome.name} className={props?.isExpand ? classes.fullTexttextClass : classes.textClass}>
                {" "}
                {props.outcome.name}
              </Typography>
            </div>
          )}
          {showIcon && (
            <span
              onClick={() => editOutcomeTracking(props.outcome, props.outcome._id)}
              className={props?.isExpand ? classes.textClass : ''}
            >
              <EditIcon />
            </span>
          )}


          {/* ) : (
          <Typography variant="h6" flex={7}>
            {props.influenceItem.name}
          </Typography>
        )} */}


        </div>
        {/* <Typography variant="h6">{props.outcome.name}</Typography> */}
        {/* {props.outcome.name.length > 35 ? ( */}
        {/* <Tooltip
            title={
              <Typography
                sx={{ fontSize: "14px !important", lineHeight: "1.5" }}
              >
                {props.outcome.name}
              </Typography>
            }
          >
            <div>
              <Typography variant="h6">
                {" "}
                {props.outcome.name.slice(0, 34)}....
              </Typography>
            </div>
          </Tooltip> */}

        {/* ) : (
          <Typography variant="h6" flex={7}>
            {props.outcome.name}
          </Typography>
        )} */}



        {props.showOutcome && outComeId == props.outcome?._id && (
          <MultiDrawerAddEditComponent
            handleClose={handleCloseStartModal}
            drawerTitle={<>Edit  <span style={{ color: palette.primary.main }}>Outcomes</span></>}
            secondOpen={needIdeasOpen}
          >
            <LeftSideOutComeNew
              outComeValue={outComeValue}
              setOutcomeValue={setOutcomeValue}
              outcomeIdeas={outcomeIdeas}
              setShowOutcome={props.setShowOutcome}
              showOutcome={props.showOutcome}
              setAddOutcomeTracking={setAddOutcomeTracking}
              setTextInput={setTextInput}
              textInput={textInput}
              setShowSnakbar={setShowSnakbar}
              setMsgType={setMsgType}
              setSnakbarMsg={setSnakbarMsg}
              handleCloseStartModal={handleCloseStartModal}
              getOutcomeList={props?.getOutcomeList}
              setTransformToInfluenceTracking={
                props?.setTransformToInfluenceTracking
              }
              startDate={props.startDate}
              endDate={props.endDate}
              setNeedIdeasOpen={setNeedIdeasOpen}
              setLimitReachPopupVisible={props?.setLimitReachPopupVisible}
              setLimitReachPopuptype={props?.setLimitReachPopuptype}
            />
          </MultiDrawerAddEditComponent>

        )}
        {props.transformToInfluenceTracking &&
          outComeId == props.outcome?._id && (
            <Dialog
              disableEscapeKeyDown
              onClose={props?.setTransformToInfluenceTracking}
              open={props.openStartModal}
              className="dialogWrapper dialogWrapper-outcome"
            >
              <DialogTitle className={classes.modalCloseIcon}>
                <span onClick={handleCloseTransformModal}>
                  <ModalCloseIconBlack />
                </span>
              </DialogTitle>
              <DialogContent>
                {/* OUTCOME LEFT SECTION */}
                <LeftTransformToInflu
                  setTextInput={setTextInput}
                  textInput={textInput}
                  outComeValue={outComeValue}
                  setTransformToInfluenceTracking={
                    props?.setTransformToInfluenceTracking
                  }
                // categoryId={categoryId}
                />
              </DialogContent>
            </Dialog>
          )}
      </>
    </Grid>
  );
};
export default TrackingItem;
