import { Box, MenuItem, TextField, Typography } from "@mui/material"
import React from "react"
import { pxToRem } from "../../../theme/typography"
import { InfluenceSelectOption } from "../../../utils/staticDataJson"
import { InfluenceOption } from "../../../interfaces/insightsModal"
import { ArrowUpNew } from "../../../assets/images/icon/ArrowDownGreenNew"

const Step1 = ({
    metrics, setMetric
}) => {
    const onChangeInput = (index, name, value) => {
        const oldCopy = [...metrics]
        oldCopy[index][name] = value
        setMetric(oldCopy)
    }
    const allData = [...InfluenceSelectOption].map(it => {
        if(it.id === "TYPE1") {
            it.value = "Self-defined scale (Personalized)"
        }
        return it
    })
    return <Box display={"flex"} flexDirection={"column"} alignItems={"flex-start"} padding={"0px 20px 0px 0px"} gap={"60px"}>
        <Typography sx={{
            color: "var(--black-color)",
            // fontSize: `${pxToRem(10)} !important`,
            textAlign: "center",
            fontSize: `${pxToRem(26)} !important`,
            lineHeight: `${pxToRem(31.2)} !important`,
            // textAlign: "center !important",
            fontWeight: `700 !important`,
            "&.MuiTypography-root": {
                "@media  (max-width: 600px) and (min-width: 100px)": {
                    fontSize: `${pxToRem(26)} !important`,
                    lineHeight: `${pxToRem(31.2)} !important`
                },
            }
        }} >
            What metrics do you want to import?
        </Typography>
        <Box display={"flex"} flexDirection={"column"} gap={"20px"} alignItems={"flex-start"}  minHeight={"352px"}>
            {metrics.map((it, index) => {
                return <Box key={index} display={"flex"} alignItems={"center"} justifyContent={"space-evenly"} gap={"40px"}>
                    <TextField
                        sx={{
                            maxWidth: '300px',
                            "&.MuiTextField-root": {
                                marginBottom: "0px !important",
                            },
                            "& .MuiOutlinedInput-root": {
                                alignItems: "center",
                                gap: "8px",
                                borderRadius: "12px",
                                border: "1px solid #DCE5E0",
                                background: "#F7F9FA",
                                "& .MuiOutlinedInput-input": {
                                    padding: "16px 12px !important",
                                    color: "#000!important",
                                },
                            },
                        }}
                        placeholder="Add metric title"
                        variant="outlined"
                        name="influence"
                        fullWidth
                        value={it.name || ""}
                        onChange={(e) => {
                            onChangeInput(index, "name", e.target.value)
                        }}
                    />
                    <TextField
                        className="fillTextInput influenceSelect"
                        fullWidth
                        select
                        SelectProps={{
                            IconComponent: ArrowUpNew, // Custom icon for the dropdown arrow
                        }}
                        // popupIcon={<ArrowUpNew color="var(--color-new-main)" />}
                        sx={{
                            maxWidth: '300px',
                            "&.MuiTextField-root": {
                                marginBottom: "0px !important",
                            },
                            "& .MuiInputLabel-root": {
                                paddingRight: "15px",
                            },
                            "& .MuiOutlinedInput-root": {
                                alignItems: "center",
                                paddingRight: "10px",
                                gap: "0px",
                                borderRadius: "12px",
                                border: "1px solid #DCE5E0",
                                background: "#F7F9FA",
                                "& .MuiOutlinedInput-input": {
                                    color: "#000!important",
                                },
                            },
                        }}
                        // PaperComponent={({ children }) => (
                        //     <Paper
                        //       style={{ background: "#FFF" }}
                        //       sx={{
                        //         "&.MuiAutocomplete-option": {
                        //           color: "rgb(0, 0, 0)",
                        //           fontSize: "18px",
                        //         },
                        //         "& .MuiAutocomplete-listbox": {
                        //           padding: "0px",
                        //           "& li": {
                        //             padding: `10px`,
                        //             color: "#5E5F5E !important",
                        //             fontSize: "16px !important",
                        //             fontWeight: "400 !important",
                        //             lineHeight: "24px",
                        //           },
                        //         },
                        //       }}
                        //     >
                        //       {children}
                        //     </Paper>
                        //   )}
                        label="Select a method to measure this influence"
                        value={it.influenceType || ""}
                        onChange={(e) => {
                            onChangeInput(index, "influenceType", e.target.value)
                        }}
                    // disabled={!!props.influenceData?.uid}
                    >
                        {allData?.map((option: InfluenceOption) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.value}
                            </MenuItem>
                        ))}
                    </TextField>
                </Box>
            })}
            <Box display={"flex"} alignItems={"center"} justifyContent={"space-evenly"} gap={"40px"}>
                <Typography sx={{
                    cursor: "pointer",
                    color: "var(--svg-new-color)",
                    // fontSize: `${pxToRem(10)} !important`,
                    textAlign: "center",
                    fontSize: `${pxToRem(16)} !important`,
                    lineHeight: `${pxToRem(24)} !important`,
                    // textAlign: "center !important",
                    fontWeight: `400 !important`,
                    "&.MuiTypography-root": {
                        "@media  (max-width: 600px) and (min-width: 100px)": {
                            fontSize: `${pxToRem(16)} !important`,
                            lineHeight: `${pxToRem(24)} !important`
                        },
                    }
                }} onClick={() => {
                    const oldCopy = [...metrics]
                    oldCopy.push({
                        name: "",
                        influenceType: ""
                    })
                    setMetric(oldCopy)
                }}>
                    + Add More
                </Typography>
            </Box>
        </Box>
    </Box>
}

export default Step1