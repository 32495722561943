import React, { useEffect, useRef, useState } from "react"
import { Box, Grid, Popover } from "@mui/material"
import CommonTypo from "../../../components/CommonTypo"
import Chart from 'react-apexcharts'
import Star from "../../../assets/images/icon/Star"
import ReactApexChart from "react-apexcharts"
import ModalCloseIconBlack from "../../../assets/images/icon/modalCloseIconBlack"
const PositiveNegativeInfluences = ({ influenceResponse }) => {
    const responseData = influenceResponse.data && influenceResponse.data[0] && influenceResponse.data[0].influence?.length > 0 ? influenceResponse.data[0].influence : []
    const positive = responseData.length > 0 ? responseData.slice(0, 5) : [];
    const negative = responseData.length > 0 ? responseData.slice(-5).reverse() : [];
    console.log(JSON.stringify(positive[0]));
    if (responseData && responseData.length <= 0) {
        return null
    }
    return (
        <>
            <Box sx={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                gap: "16px"
            }}>
                <Box sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    gap: "8px"
                }}>
                    <CommonTypo size={24} color="#3E4240" fontWeight={700} extraSx={{
                        whiteSpace: "nowrap",
                    }}>{"Positive 5 Correlations"}</CommonTypo>
                </Box>
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    // justifyContent: "space-evenly",
                    gap: "16px"
                }}>
                    {positive.map(it => <PositiveNegativeBox positive name={it.name} avg={it.avg} correlation={it.correlation} data1={it.data1} data2={it.data2} mainItem={it} influenceResponse={influenceResponse} />)}
                </Box>
            </Box>
            <Box sx={{
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column",
                gap: "16px"
            }}>
                <Box sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    gap: "8px"
                }}>
                    <CommonTypo size={24} color="#3E4240" fontWeight={700} extraSx={{
                        whiteSpace: "nowrap",
                    }}>{"Negative 5 Correlations"}</CommonTypo>
                </Box>
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    // justifyContent: "space-evenly",
                    gap: "16px"
                }}>
                    {negative.map(it => <PositiveNegativeBox positive={false} avg={it.avg} name={it.name} correlation={it.correlation} data1={it.data1} data2={it.data2} mainItem={it} influenceResponse={influenceResponse} />)}
                </Box>
            </Box>
        </>
    )
}

export default PositiveNegativeInfluences

const PositiveNegativeBox = ({
    positive = true,
    name,
    correlation,
    avg,
    data1,
    data2,
    mainItem,
    influenceResponse
}) => {
    console.log("mainItem", influenceResponse, mainItem);

    const selectRef = useRef(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClose = () => {
        setAnchorEl(null);
    };
    const stars = [];
    for (let index = 0; index < mainItem.star; index++) {
        stars.push(
            <>
                <Star color={"#F9D412"} size={16} />
            </>
        );
    }
    for (let index = mainItem.star; index < 5; index++) {
        stars.push(
            <>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.16432 4.44738L8 1.76891L9.83568 4.44738C10.0697 4.78889 10.4144 5.03928 10.8115 5.15635L13.9261 6.07449L11.946 8.64801C11.6935 8.97614 11.5619 9.38128 11.5733 9.79515L11.6625 13.0411L8.60309 11.9531C8.21299 11.8144 7.78701 11.8144 7.39691 11.9531L4.33746 13.0411L4.42673 9.79515C4.43811 9.38128 4.30647 8.97615 4.054 8.64801L2.07388 6.07449L5.18851 5.15635C5.58564 5.03928 5.93027 4.78889 6.16432 4.44738Z" stroke="#EEEEEE" stroke-width="2" />
                </svg>

            </>
        );
    }
    return <>
        <Box sx={{
            width: "calc((100% - 4 * 16px) / 5)"
            // flexBasis: "10%", flexGrow: 1   // 20% for 5 boxes
        }} ref={selectRef} onClick={(event) => {
            setAnchorEl(selectRef.current)
        }}>
            <Box sx={{
                padding: "16px",
                border: "1px solid #EEEEEE",
                display: "flex",
                borderRadius: "8px",
                flexDirection: "column",
                gap: "8px",
                width: "100%"
            }} >
                <Box sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "8px"
                }}>
                    <CommonTypo size={16} title={name} color="#3E4240" lineHeight={16} fontWeight={600} extraSx={{
                        flexGrow: 1, // Allow the title to take up remaining space
                        flexShrink: 1, // Allow the title to shrink if needed
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        // width: "calc(100% - 200px)"
                    }}>{name.length > 30 ? `${name.slice(0, 47)}...` : name}</CommonTypo>
                    <CommonTypo size={26} fontWeight={500} lineHeight={26}>{(correlation || 0).toString()}</CommonTypo>
                </Box>
                {positive ? (
                    // <DynamicSVG positive={true} data1={data1} data2={data2} />
                    <ChartForPositiveAndNegative positive={true} data1={data1} data2={data2} />
                ) : (
                    // <DynamicSVG positive={false} data1={data1} data2={data2} />
                    <ChartForPositiveAndNegative positive={false} data1={data1} data2={data2} />
                )}


            </Box>
        </Box>
        <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            sx={{
                '& .MuiPaper-root': {
                    background: "#FFFFFF",
                    boxShadow: "0px 0px 64px 0px #190D1A1A",
                    borderRadius: "12px",
                    display: "flex",
                    flexDirection: "column",
                    padding: "32px",
                    gap: "32px",
                    maxWidth: "calc(100% - 600px)",
                    position: "relative"
                }
            }}
        >
            <span style={{
                position: "absolute",
                top: 10,
                right: 10,
                cursor: "pointer"
            }} onClick={handleClose}>
                <ModalCloseIconBlack color="#000000" />
            </span>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                gap: "8px"
            }}>
                <CommonTypo fontWeight={700} size={24} lineHeight={24}>Comparison</CommonTypo>
                <CommonTypo fontWeight={400} size={16} lineHeight={16} color="#656565">Explore how different metrics are connected. See how changes in one may influence the other</CommonTypo>
            </Box>
            <Box sx={{
                display: "flex",
                gap: "24px",
                alignItems: "center",
            }}>
                <Box sx={{
                    width: "300px",
                    height: "48px",
                    borderRadius: "8px",
                    border: "2px solid " + (positive ? "#ADE888" : "#FFA9A9"),
                    display: "flex",
                    alignItems: "center",
                    padding: "12px"
                }}>
                    <CommonTypo fontWeight={400} size={16} lineHeight={24}>{influenceResponse.data[0].name}</CommonTypo>
                </Box>
                <CommonTypo fontWeight={500} size={16} lineHeight={24}>and</CommonTypo>
                <Box sx={{
                    width: "300px",
                    height: "48px",
                    borderRadius: "8px",
                    border: "2px solid #EEEEEE" ,
                    display: "flex",
                    alignItems: "center",
                    padding: "12px"
                }}>
                    <CommonTypo fontWeight={400} size={16} lineHeight={24}>{mainItem.name}</CommonTypo>
                </Box>
            </Box>
            <Box sx={{
                display: "flex",
                gap: "16px",
                alignItems: "center",
            }}>
                <ChartForPositiveAndNegative positive={positive} name1={influenceResponse.data[0].name} name2={mainItem.name} data1={data1} data2={data2} isFull />
                <Box sx={{
                    display: "flex",
                    gap: "8px",
                    flexDirection: "column",
                }}>
                    <Box sx={{
                        padding: "16px 24px",
                        display: "flex",
                        gap: "16px",
                        flexDirection: "column",
                        borderRadius: "12px",
                        border: "1px solid #EEEEEE",
                    }}>
                        <CommonTypo fontWeight={700} size={24} lineHeight={24}>Insight</CommonTypo>
                        <CommonTypo fontWeight={400} size={16} lineHeight={24} color="#656565">{mainItem.message}</CommonTypo>
                    </Box>
                    <Box sx={{
                        display: "flex",
                        gap: "8px",
                        flexDirection: "column",
                    }}>
                        <Box sx={{
                            padding: "14px 16px",
                            display: "flex",
                            gap: "4px",
                            borderRadius: "12px",
                            border: "1px solid #EEEEEE",
                            alignItems: "center",
                            justifyContent: "space-between"
                        }}>
                            <CommonTypo fontWeight={400} size={14} lineHeight={14} color="#3E4240">Correlation</CommonTypo>
                            <CommonTypo fontWeight={500} size={16} lineHeight={16} color="#3E4240">{mainItem.correlation}</CommonTypo>
                        </Box>
                        <Box sx={{
                            padding: "14px 16px",
                            display: "flex",
                            gap: "4px",
                            borderRadius: "12px",
                            border: "1px solid #EEEEEE",
                            alignItems: "center",
                            justifyContent: "space-between"
                        }}>
                            <CommonTypo fontWeight={400} size={14} lineHeight={14} color="#3E4240">Significance</CommonTypo>
                            <Box sx={{
                                display: "flex",
                                gap: "4px",
                                alignItems: "center",
                            }}>
                                {stars}
                                <CommonTypo fontWeight={500} size={16} lineHeight={16} color="#3E4240">{mainItem.star}</CommonTypo>
                            </Box>
                        </Box>
                        <Box sx={{
                            padding: "14px 16px",
                            display: "flex",
                            gap: "4px",
                            borderRadius: "12px",
                            border: "1px solid #EEEEEE",
                            alignItems: "center",
                            justifyContent: "space-between"
                        }}>
                            <CommonTypo fontWeight={400} size={14} lineHeight={14} color="#3E4240">Days tracked both</CommonTypo>
                            <CommonTypo fontWeight={500} size={16} lineHeight={16} color="#3E4240">{mainItem.data1.length} days</CommonTypo>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Popover>
    </>
}

const DynamicSVG = ({ data1, data2, positive = false, isFull = false }) => {
    const categories = []
    const data1Index = [...data1].map((it, index) => {
        categories.push(index + 1)
        return [it._id.index]
    })

    const data2Index = [...data2].map(it => it._id.index)
    if (positive) {
        console.log("data1Index", data1Index, data2Index);
    }
    const maxDataValue = Math.max(
        Math.max(...data1Index.map(it => it[1])),
        Math.max(...data2Index)
    );

    const normalizeValue = (value) => (value / maxDataValue) * 100;

    const generatePath = (data) => {
        let path = '';
        const xOffset = 10; // Example horizontal spacing
        const controlPointOffset = 5; // Control point offset for smooth curves

        // Move to the start point
        path += `M ${0} ${100 - normalizeValue(data[0])} `;

        // Create smooth cubic Bezier curves
        for (let i = 1; i < data.length; i++) {
            const x = i * xOffset;
            const y = 100 - normalizeValue(data[i]); // Adjust Y position

            // Control points for smooth curve
            const prevX = (i - 1) * xOffset;
            const prevY = 100 - normalizeValue(data[i - 1]);
            const cp1x = prevX + controlPointOffset;
            const cp1y = prevY;
            const cp2x = x - controlPointOffset;
            const cp2y = y;

            path += `C ${cp1x} ${cp1y}, ${cp2x} ${cp2y}, ${x} ${y} `;
        }

        // Close the path
        path += `L ${data.length * xOffset} 100 L 0 100 Z`; // Close path for filling

        return path;
    };

    const path1 = generatePath(data1Index);
    const path2 = generatePath(data2Index);

    return (
        <svg width="100%" height={isFull ? "362" : "60"} viewBox={`0 0 300 ${isFull ? "362" : "60"}`} xmlns="http://www.w3.org/2000/svg">
            <defs>
                <linearGradient id="gradient1" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" stopColor={positive ? "var(--color-new-main)" : "#FFA9A9"} stopOpacity="0.4" />
                    <stop offset="100%" stopColor={positive ? "var(--color-new-main)" : "#FFA9A9"} stopOpacity="0" />
                </linearGradient>
                <linearGradient id="gradient3" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" stopColor={"#FFA9A9"} stopOpacity="0.4" />
                    <stop offset="100%" stopColor={"#FFA9A9"} stopOpacity="0" />
                </linearGradient>
                <linearGradient id="gradient2" x1="0%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" stopColor="#EEEEEE" stopOpacity="0.4" />
                    <stop offset="100%" stopColor="#EEEEEE" stopOpacity="0" />
                </linearGradient>
            </defs>
            <g opacity="0.7">
                <path d={path1} fill={positive ? "url(#gradient1)" : "url(#gradient3)"} stroke={positive ? "var(--color-new-main)" : "#FFA9A9"} strokeWidth="2" strokeLinecap="round" />
                <path d={path2} fill="url(#gradient2)" stroke="#EEEEEE" strokeWidth="2" strokeLinecap="round" />
            </g>
        </svg>
    );
};


export const ChartForPositiveAndNegative = ({ data1, data2, name1 = 'series1', name2 = 'series2', positive = false, isFull = false }) => {
    const categories = []
    const [elementWidth, setElementWidth] = useState(0);
    const elementRef = useRef(null);
    const data1Index = [...data1].map((it, index) => {
        categories.push(it._id.date)
        return [it._id.date, it._id.index]
    })

    const data2Index = [...data2].map(it => {
        if (!categories.includes(it._id.date)) {
            categories.push(it._id.date)
        }
        return [it._id.date, it._id.index]
    })
    console.log("data1Index", data1, data2, data1Index, data2Index);

    useEffect(() => {
        // Update the element width after the component has mounted
        if (elementRef.current) {
            setElementWidth(elementRef.current.offsetWidth);
        }

        // Optionally, you can add an event listener for window resize to track changes dynamically.
        const handleResize = () => {
            if (elementRef.current) {
                setElementWidth(elementRef.current.offsetWidth);
            }
        };

        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    console.log("elementWidth", elementWidth);

    return (
        <div ref={elementRef} style={{
            width: "calc(100% - 100px)"
        }}>
            <div id="chart" style={{
                width: "calc(100%)"
            }}>
                <ReactApexChart options={{
                    chart: {
                        // height: 220,
                        type: 'area',
                        toolbar: {
                            show: false
                        },
                        zoom: {
                            enabled: false
                        },
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    yaxis: {
                        axisBorder: {
                            show: false
                        },
                        axisTicks: {
                            show: false
                        },
                        labels: {
                            show: false
                        },
                    },
                    xaxis: {
                        axisBorder: {
                            show: false
                        },
                        axisTicks: {
                            show: false
                        },
                        labels: {
                            show: false
                        },
                        tooltip: {
                            enabled: false
                        },
                        type: 'datetime',
                        categories: categories
                    },
                    tooltip: {
                        x: {
                            show: false
                        },
                        shared: false,
                        custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                            console.log("series[seriesIndex] ---", series[seriesIndex]);

                            const value = series[seriesIndex][dataPointIndex];
                            return `
                              <div class="custom-tooltip">
                                <span class="tooltip-value">${value === 0.6 ? 0 : value}</span>
                              </div>
                            `;
                        },
                    },
                    legend: {
                        show: isFull ? true : false,
                        position: "bottom",
                        horizontalAlign: "left",
                        itemMargin: {
                            vertical: 16,
                            horizontal: 4
                        }
                    },
                    grid: {
                        show: false
                    },
                    fill: {
                        type: "gradient",
                        gradient: {
                            shadeIntensity: 1,
                            opacityFrom: 0.7,
                            opacityTo: 0.3,
                            stops: [0, 100],
                        },
                    },
                }} series={[{
                    name: name1,
                    data: data1Index,
                    color: isFull ? positive ? "#ADE888" : "#FFA9A9" : positive ? "#ADE888" : "#FFA9A9"
                }, {
                    name: name2,
                    data: data2Index,
                    color: "#EEEEEE"

                }]} type="area" width={isFull ? "100%" : "166"} height={isFull ? 362 : 70} />
            </div>
        </div>
    );
}