/*eslint @typescript-eslint/no-unused-vars: "off"*/
import { Grid } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import CalendarItem from "./CalendarItem";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import { UserTypeContext } from "../../context/userStatus";

const useStyles = makeStyles((theme) => ({
  calendar: {
    display: "inline-flex",
    flexShrink: 0,
    flexGrow: 0
  },
}));

interface CalendarModal {
  createDate: any;
  setCreateDate: (createDate: any) => void;
  showLoader?: any;
  setShowLoader?: any;
  startDate: any
  setStartDate: any
  endDate: any
  setEndDate: any
}

const Calendar = (props: CalendarModal) => {
  const classes = useStyles();
  const { state: outcomeState, dispatch: userTypeDispatch } =
  useContext(UserTypeContext);
  const [startDay, setStartDay] = useState<any>();
  const [endDay, setEndDay] = useState<any>();
  const [calendar, setCalendar] = useState([]);
  const compareDate = parseInt(moment().format("D"));
  const [today, setToday] = useState(moment());
  const [isFirst, setIsFirst] = useState("");
  const { createdAt } = JSON.parse(localStorage.getItem("userValues"))
  let registeredDate = moment(createdAt);

  const prevMonth = () => {
    if (isFirst === "next") {
      setIsFirst("prev");
      setToday(today.subtract(1, "month")); // today date
      const startDate = today.clone().startOf("month")
      setStartDay(startDate); // give start of the today date month
      let endDate = today.clone().endOf("month")
      if (today.clone().endOf("month").isBefore(registeredDate)) {
        endDate = registeredDate.clone().endOf("month")
      }
      setEndDay(endDate);

      props?.setStartDate(startDate)
      props?.setEndDate(endDate)
    } else {
      setIsFirst("prev");
      setToday(today.subtract(1, "month"));
      const startDate = today.clone().startOf("month")
      
      setStartDay(startDate);
      // setEndDay(today.clone().endOf("month"));
      let endDate = today.clone().endOf("month")
      if (today.clone().endOf("month").isBefore(registeredDate)) {
        endDate = registeredDate.clone().endOf("month")
      }
      setEndDay(endDate);
      props?.setStartDate(startDate)
      props?.setEndDate(endDate)
    }
    props?.setShowLoader(true)
  };
  const nextMonth = () => {
    if (isFirst === "prev") {
      setIsFirst("next");
      setToday(today.clone().add(1, "month"));
      const startDate = today.clone().add(1, "month").startOf("month")
      setStartDay(startDate);
      const endDate = today.clone().add(1, "month").endOf("month")
      setEndDay(endDate);
      props?.setStartDate(startDate)
      props?.setEndDate(endDate)
    } else {
      setIsFirst("next");
      setToday(today.clone().add(1, "month"));
      const startDate = today.clone().add(1, "month").startOf("month")
      setStartDay(startDate);
      const endDate = today.clone().add(1, "month").endOf("month")
      setEndDay(endDate);
      props?.setStartDate(startDate)
      props?.setEndDate(endDate)
    }
    props?.setShowLoader(true)
  };
  useEffect(() => {
    let startDate = isFirst ? moment(startDay).startOf('month') : moment().startOf('month');
    const current = moment()
    if (!isFirst && current?.diff(startDate, "day") < 7) {
      startDate = moment().startOf('month').subtract(7 - current?.diff(startDate, "day"), 'day').startOf('day')
    }
    if (calendar.length === 0) {
      // setStartDay(moment().clone().subtract(7, "day"));
      // setEndDay(moment().clone().add(20, "day"));
      // props?.setStartDate(moment().clone().subtract(7, "day"))
      // props?.setEndDate(moment().clone().add(20, "day"))
      // const endOfMonth = moment().clone().endOf('month')
      // if(endOfMonth?.diff(moment(), "day") >= 7) {
      //   setEndDay(moment().clone().endOf('month'));
      // } else {
      //   setEndDay(moment().clone().endOf('month').add(7, 'day'));
      // }
    
      const endOfMonth = moment().clone().endOf('month')
      if (endOfMonth?.diff(moment(), "day") >= 7) {
        setEndDay(moment().clone().endOf('month'));
        props?.setEndDate(moment().clone().endOf('month'))
      } else {
        const currDate = moment(startDate)?.clone().isBefore(registeredDate)
        // if(currDate) {
        //   if(endOfMonth?.diff(registeredDate.clone().subtract(1, "day"), "day") < 7) {
        //     props?.setEndDate(moment().clone().add(1, 'month').endOf('month'))
        //     setEndDay(moment().clone().add(1, 'month').endOf('month'));
        //   }else {
        //     props?.setEndDate(moment().clone().add(7, 'day'))
        //     setEndDay(moment().clone().add(7, 'day'));
        //   }
          
        // } else {
          props?.setEndDate(moment().clone().add(7, 'day'))
          setEndDay(moment().clone().add(7, 'day'));
        // }
        // props?.setEndDate(moment().clone().add(7, 'day'))
        // setEndDay(moment().clone().add(7, 'day'));
      }
      
      setStartDay(startDate);
      // props?.setStartDate(startDate)
      // props?.setEndDate(moment().clone().add(20, "day"))
      // setEndDay(moment().clone().add(20, "day"));
    }
    // trackingEvent();
    enumerateDaysBetweenDates(startDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDay, endDay]);

  const trackingEvent = () => {
    const day = startDay?.clone().isBefore(registeredDate)
      ? registeredDate.clone().subtract(1, "day")
      : startDay?.clone().subtract(1, "day");

    const tempCalendar: any[][] = [];

    let startDayTemp = startDay?.clone();
    let endDayTemp = startDay?.clone();

    while (day?.isBefore(endDay, "day")) {
      let daysInMonth = startDayTemp
        .clone()
        .endOf("month")
        .diff(endDayTemp.clone(), "days");

      tempCalendar.push(
        Array(daysInMonth + 1)
          .fill(0)
          .map(() => {
            return day.add(1, "day").clone();
          })
      );

      startDayTemp = day.clone().add(1, "day");
      endDayTemp = endDay.clone();
    }

    setCalendar(tempCalendar);
  };
  const enumerateDaysBetweenDates = (startDate) => {
    if ( endDay) {
      var dates = [];
      const currDate = moment(startDate)?.clone().isBefore(registeredDate)
        ? registeredDate.clone().subtract(1, "day")
        : moment(startDate)?.clone().subtract(1, "day");
      const lastDate = moment(endDay).endOf('day');

      while (currDate?.add(1, 'days')?.diff(lastDate) < 0) {
        dates.push(currDate?.clone());
      }
      setCalendar([dates]);
    }

  };
  
  return (
    <Grid className={clsx(classes.calendar, "calendar")} /* style={!outcomeState?.isPersonal ? { width: "100%" } : {}} */>
      {calendar.map((month, index) => {
        return (
          <CalendarItem
            month={month}
            key={index}
            today={moment()}
            startDay={startDay}
            endDay={endDay}
            prevMonth={prevMonth}
            nextMonth={nextMonth}
            showLoader={props?.showLoader}
            setShowLoader={props?.setShowLoader}
          />
        );
      })}
    </Grid>
  );
};

export default React.memo(Calendar);
