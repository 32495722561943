import { Grid, Box, Typography } from "@mui/material";
import * as React from "react";
import palette from "../../theme/palette";
import Timer from "../../assets/images/icon/timer";
const recomImage = require('../../assets/images/recomm_img.png')

const RecommendedListItem = () => {
  return (
    <Grid display="flex" alignItems="flex-start" sx={{overflowY:'auto'}}>
      <Box>
        <Grid sx={{ width: "324px", height: "180px", marginBottom: "16px" }}>
          <img src={recomImage} alt="recom_image" width='100%' height="100%" style={{objectFit:'cover'}}  />
        </Grid>
        <Grid marginLeft="20px">
          <Typography
            variant="h6"
            sx={{
              color: palette.primary.light,
              fontWeight: 600,
              marginBottom: "8px",
            }}
          >
            Defining what matters most
          </Typography>
          <p className="timerIcon">
            <span>
              <Timer />
            </span>
            1.5h
          </p>
          <Typography
            variant="subtitle1"
            className="recom_desc"
            sx={{ color: palette.secondary.contrast, marginTop: "8px" }}
          >
            Getting to wher you want to be can be tough, especially if you are
            not sure where "There" is Getting to wher you want to be can be tough, especially if you are not sure where "There" is Getting to wher you want to be can be tough, especially if you are not sure where "There" is Getting to wher you want to be can be tough, especially if you are not sure where "There" is
          </Typography>
        </Grid>
      </Box>
      <Box>
        <Grid sx={{ width: "324px", height: "180px", marginBottom: "16px" }}>
          <img src={recomImage} alt="recom_image" width='100%' height="100%" style={{objectFit:'cover'}}  />
        </Grid>
        <Grid marginLeft="20px">
          <Typography
            variant="h6"
            sx={{
              color: palette.primary.light,
              fontWeight: 600,
              marginBottom: "8px",
            }}
          >
            Defining what matters most
          </Typography>
          <p className="timerIcon">
            <span>
              <Timer />
            </span>
            1.5h
          </p>
          <Typography
            variant="subtitle1"
            className="recom_desc"
            sx={{ color: palette.secondary.contrast, marginTop: "8px" }}
          >
            Getting to wher you want to be can be tough, especially if you are
            not sure where "There" is...
          </Typography>
        </Grid>
      </Box>
      <Box>
        <Grid sx={{ width: "324px", height: "180px", marginBottom: "16px" }}>
          <img src={recomImage} alt="recom_image" width='100%' height="100%" style={{objectFit:'cover'}}  />
        </Grid>
        <Grid marginLeft="20px">
          <Typography
            variant="h6"
            sx={{
              color: palette.primary.light,
              fontWeight: 600,
              marginBottom: "8px",
            }}
          >
            Defining what matters most
          </Typography>
          <p className="timerIcon">
            <span>
              <Timer />
            </span>
            1.5h
          </p>
          <Typography
            variant="subtitle1"
            className="recom_desc"
            sx={{ color: palette.secondary.contrast, marginTop: "8px" }}
          >
            Getting to wher you want to be can be tough, especially if you are
            not sure where "There" is...
          </Typography>
        </Grid>
      </Box>
      <Box>
        <Grid sx={{ width: "324px", height: "180px", marginBottom: "16px" }}>
          <img src={recomImage} alt="recom_image" width='100%' height="100%" style={{objectFit:'cover'}}  />
        </Grid>
        <Grid marginLeft="20px">
          <Typography
            variant="h6"
            sx={{
              color: palette.primary.light,
              fontWeight: 600,
              marginBottom: "8px",
            }}
          >
            Defining what matters most
          </Typography>
          <p className="timerIcon">
            <span>
              <Timer />
            </span>
            1.5h
          </p>
          <Typography
            variant="subtitle1"
            className="recom_desc"
            sx={{ color: palette.secondary.contrast, marginTop: "8px" }}
          >
            Getting to wher you want to be can be tough, especially if you are
            not sure where "There" is...
          </Typography>
        </Grid>
      </Box>
      <Box>
        <Grid sx={{ width: "324px", height: "180px", marginBottom: "16px" }}>
          <img src={recomImage} alt="recom_image" width='100%' height="100%" style={{objectFit:'cover'}}  />
        </Grid>
        <Grid marginLeft="20px">
          <Typography
            variant="h6"
            sx={{
              color: palette.primary.light,
              fontWeight: 600,
              marginBottom: "8px",
            }}
          >
            Defining what matters most
          </Typography>
          <p className="timerIcon">
            <span>
              <Timer />
            </span>
            1.5h
          </p>
          <Typography
            variant="subtitle1"
            sx={{ color: palette.secondary.contrast, marginTop: "8px" }}
          >
            Getting to wher you want to be can be tough, especially if you are
            not sure where "There" is...
          </Typography>
        </Grid>
      </Box>
    </Grid>
  );
};
export default RecommendedListItem;
