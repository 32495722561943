import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";
import { Typography, Grid, TextField, Button, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core";
import GetIdeasIcon from "../../assets/images/icon/getIdeas";
import palette from "../../theme/palette";
import IdeasDrawer from "./IdeasDrawer";
// import CategoryItem from "./CategoryItem";
import { categoryService } from "../../services/category";
import { UserTypeContext } from "../../context/userStatus";
import SnackBarComp from "../../components/snackBar";
import { ButtonLoader } from "../../components/buttonLoader";
import { CategoryUpdate } from "../../interfaces/categoryModel";
import { OutcomesList } from "./AddCategoryIndex";
// import { useDrag, useDrop } from 'react-dnd';
import CategoryItemWrapper from "./CategoryItem";
import { pxToRem } from "../../theme/typography";
import NewButton from "../../components/NewButton";

const RootStyle = styled("div")(({ theme }) => ({
  padding: "50px 48px 50px 80px",
  position: "relative",
  overflowY: "scroll",
  height: "100vh",
  [theme.breakpoints.down("xl")]: {
    padding: "50px 40px 50px 60px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "40px 20px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "20px",
  },
}));

const useStyles = makeStyles((theme) => ({
  registrationHeading: {
    color: palette.primary.light,
    lineHeight: "120%!important",
  },
  pageTitle: {
    marginTop: "40px!important",
    marginBottom: "84px!important",
    [theme.breakpoints.down("lg")]: {
      marginTop: "40px!important",
      marginBottom: "84px!important",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "20px!important",
      marginBottom: "28px!important",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "15px!important",
      marginBottom: "20px!important",
    },
  },
  needSomeIdeas: {
    fontSize: "16px",
    lineHeight: "16px",
    color: "var(--color-new-text)",
    marginLeft: "8px",
    [theme.breakpoints.down("md")]: {
      marginTop: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "14px",
    },
  },
  compactExpandColumntab: {
    color: palette.primary.light,
    lineHeight: "150%!important",
    fontSize: `${pxToRem(16)} !important`,
  },
}));

interface LeftSideCategoryModel {
  categoryIdeas: any;
  handleFinish: (finalData: CategoryUpdate[]) => void;
  outcomesList: OutcomesList;
  handleOutcomeListRemove: any;
  handleOutcomeListAdd: any;
  setIsApiCall?: any;
  isApiCall: any;
  addCategoryList: any;
  setAddCategoryList: any;
  expandedList: any;
  setExpandList: any;
  expanded: any;
  setEpanded: any;
}

const LeftSideCategory = (props: LeftSideCategoryModel) => {
  const classes = useStyles();
  const { expandedList, setExpandList, expanded, setEpanded } = props;
  const { state: outcomeState } = useContext(UserTypeContext);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [categoryInput, setCategoryInput] = useState("");
  const { addCategoryList, setAddCategoryList } = props;
  const [showSnakbar, setShowSnakbar] = useState(false);
  // const [expandedView, setexpandedView] = useState(false)
  const [snakbarMsg, setSnakbarMsg] = useState("");
  const [msgType, setMsgType] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const setexpandedView = setEpanded;
  const expandedView = expanded;
  // console.log("addCategoryList =====>",addCategoryList)

  useEffect(() => {
    setOpenDrawer(false);
    if (outcomeState.categoryList) {
      // const newArray = outcomeState.categoryList?.map((item, index) => {
      //   return {
      //     ...item,
      //     arrayIndex: index
      //   }
      // })
      setAddCategoryList(outcomeState.categoryList);
      localStorage.setItem(
        "categoryCount",
        `${outcomeState.categoryList.length}`
      );
    }
  }, [outcomeState.categoryList]);

  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const closeDrawer = () => {
    setOpenDrawer(false);
  };

  const handleCloseSnakbar = () => {
    setShowSnakbar(false);
  };

  const handleChangeCategory = (e: any) => {
    setCategoryInput(e.target.value);
  };

  const handleCreate = () => {
    setShowLoader(true);
    const body = {
      name: categoryInput,
      outcomeIds: [],
      reflectiveIds: [],
      order: addCategoryList?.length + 1,
      influenceIds: [],
      allId: [],
    };
    categoryService
      .createCategory(outcomeState.token, body)
      .then((response) => {
        response.data.arrayIndex = addCategoryList.length;
        setCategoryInput("");
        setAddCategoryList((prevState) => [
          ...prevState,
          {
            ...response.data,
            outcomes: [],
            influences: [],
            reflectives: [],
            alls: [],
          },
        ]);
        setCategoryInput("");
        setShowLoader(false);
        setSnakbarMsg(response.message);
      })
      .catch((error) => {
        setShowLoader(false);
        setShowSnakbar(true);
        setSnakbarMsg(
          error?.response?.data?.description
            ? error?.response?.data?.description
            : error.message
        );
        setMsgType("error");
        console.log("Add category error", error);
      });
  };

  const handleAdd = (categoryId, item) => {
    let arr = [...addCategoryList];
    const index = arr.findIndex(
      (category) => category.categoryId === categoryId
    );

    if (item.outcomeId && !arr[index].outcomeIds.includes(item.outcomeId)) {
      // arr[index].outcomeIds.push(item.outcomeId);
      arr[index].outcomeIds = [...arr[index].outcomeIds, item.outcomeId];
      // arr[index].outcomes.push(item);
      arr[index].outcomes = [...arr[index].outcomes, item];
      // arr[index].allIds.push(item.outcomeId);
      arr[index].allIds = arr[index].allIds
        ? [...arr[index].allIds, item.outcomeId]
        : [item.outcomeId];
      // arr[index].alls.push(item);
      arr[index].alls = arr[index].alls ? [...arr[index].alls, item] : [item];
      props.handleOutcomeListRemove("OUTCOME", item._id);
    } else if (
      item.influenceId &&
      !arr[index].influenceIds.includes(item.influenceId)
    ) {
      arr[index].influenceIds.push(item.influenceId);
      arr[index].influences.push(item);
      arr[index].allIds.push(item.influenceId);
      arr[index].alls.push(item);
      props.handleOutcomeListRemove("INFLUENCE", item._id);
    } else if (
      item.reflectiveId &&
      !arr[index].reflectiveIds.includes(item.reflectiveId)
    ) {
      arr[index].reflectiveIds.push(item.reflectiveId);
      arr[index].reflectives.push(item);
      arr[index].allIds.push(item.reflectiveId);
      arr[index].alls.push(item);
      props.handleOutcomeListRemove("REFLECTIVE", item._id);
    }

    const otherCat = arr?.map((element, index) => {
      if (element?.categoryId !== categoryId) {
        if (Boolean(item?.outcomeId)) {
          let newOutcomeIdsList, newOutcomesList;
          if (element?.outcomeIds?.includes(item?.outcomeId)) {
            newOutcomeIdsList = element?.outcomeIds?.filter((outcomeId) => {
              return outcomeId !== item?.outcomeId;
            });
            newOutcomesList = element?.outcomes?.filter((outcome) => {
              return outcome?.outcomeId !== item?.outcomeId;
            });
          } else {
            newOutcomeIdsList = element?.outcomeIds;
            newOutcomesList = element?.outcomes;
          }
          return {
            ...element,
            outcomeIds: newOutcomeIdsList,
            outcomes: newOutcomesList,
          };
        } else if (Boolean(item?.influenceId)) {
          let newInfluenceIdsList, newInfluenceList;
          if (element?.influenceIds?.includes(item?.influenceId)) {
            newInfluenceIdsList = element?.influenceIds?.filter(
              (influenceId) => {
                return influenceId !== item?.influenceId;
              }
            );
            newInfluenceList = element?.influences?.filter((influences) => {
              return influences?.influenceId !== item?.influenceId;
            });
          } else {
            newInfluenceIdsList = element?.influenceIds;
            newInfluenceList = element?.influences;
          }
          return {
            ...element,
            influenceIds: newInfluenceIdsList,
            influences: newInfluenceList,
          };
        } else if (Boolean(item?.reflectiveId)) {
          let newReflectiveIdsList, newReflectiveList;
          if (element?.reflectiveIds?.includes(item?.reflectiveId)) {
            newReflectiveIdsList = element?.reflectiveIds?.filter(
              (reflectiveId) => {
                return reflectiveId !== item?.reflectiveId;
              }
            );
            newReflectiveList = element?.reflectives?.filter((reflectives) => {
              return reflectives?.reflectiveId !== item?.reflectiveId;
            });
          } else {
            newReflectiveIdsList = element?.reflectiveIds;
            newReflectiveList = element?.reflectives;
          }
          return {
            ...element,
            reflectiveIds: newReflectiveIdsList,
            reflectives: newReflectiveList,
          };
        } else {
          return element;
        }
      } else {
        return element;
      }
    });
    // console.log(">>>>>>",otherCat)
    setAddCategoryList(otherCat);
  };

  const handleRename = (newValue: string, categoryId: string) => {
    if (newValue.trim() !== "")
      setAddCategoryList((prevState) => {
        const index = prevState.findIndex(
          (category) => category.categoryId === categoryId
        );
        prevState[index].name = newValue;
        return prevState;
      });
  };

  const handleDelete = (id: string) => {
    categoryService
      .deleteCategory(outcomeState.token, id)
      .then((res) => {
        let arr = [...addCategoryList];
        const index = arr.findIndex((category) => category._id === id);
        arr.splice(index, 1);
        setAddCategoryList(arr);
        setShowSnakbar(true);
        setSnakbarMsg(res?.description);
        props.setIsApiCall(!props.isApiCall);
      })
      .catch((error) => {
        setShowLoader(false);
        setShowSnakbar(true);
        setSnakbarMsg(
          error?.response?.data?.description
            ? error?.response?.data?.description
            : error.message
        );
        setMsgType("error");
        console.log("Delete category error", error);
      });
  };

  const handleDeleteItem = (categoryId: string, id: string, type: string) => {
    let arr = [...addCategoryList];
    let category = arr.find((obj) => obj.categoryId === categoryId);

    if (type === "OUTCOME") {
      const item = category.alls.find((obj) => obj.outcomeId === id);
      category.allIds = category.allIds.filter((e) => e !== id);
      category.alls = category.alls.filter((e) => e.outcomeId !== id);
      category.outcomeIds = category.outcomeIds.filter((e) => e !== id);
      category.outcomes = category.outcomes.filter((e) => e.outcomeId !== id);
      props.handleOutcomeListAdd("OUTCOME", item);
    } else if (type === "INFLUENCE") {
      const item = category.alls.find((obj) => obj.influenceId === id);
      category.allIds = category.allIds.filter((e) => e !== id);
      category.alls = category.alls.filter((e) => e.influenceId !== id);
      category.influenceIds = category.influenceIds.filter((e) => e !== id);
      category.influences = category.influences.filter(
        (e) => e.influenceId !== id
      );
      props.handleOutcomeListAdd("INFLUENCE", item);
    } else if (type === "REFLECTIVE") {
      const item = category.alls.find((obj) => obj.reflectiveId === id);
      category.allIds = category.allIds.filter((e) => e !== id);
      category.alls = category.alls.filter((e) => e.reflectiveId !== id);
      category.reflectiveIds = category.reflectiveIds.filter((e) => e !== id);
      category.reflectives = category.reflectives.filter(
        (e) => e.reflectiveId !== id
      );
      props.handleOutcomeListAdd("REFLECTIVE", item);
    }

    setAddCategoryList(arr);
  };

  const moveCategoryItem = (dragIndex, hoverIndex) => {
    if (dragIndex !== undefined && hoverIndex !== undefined) {
      // const dragEle = addCategoryList.filter((ele)=> {
      //   return ele.order === dragIndex
      // })
      // console.log("dragEle,dragEle",dragEle)
      let dragItem = addCategoryList[dragIndex];
      let hoverItem = addCategoryList[hoverIndex];
      const updatedCategoryArray = [...addCategoryList].filter(
        (ele) => ele !== undefined
      );
      if (dragIndex !== hoverIndex) {
        dragItem = {
          ...dragItem,
          order: hoverIndex,
          arrayIndex: hoverIndex,
        };
        hoverItem = {
          ...hoverItem,
          order: dragIndex,
          arrayIndex: dragIndex,
        };
        updatedCategoryArray[dragIndex] = hoverItem;
        updatedCategoryArray[hoverIndex] = dragItem;
      }
      setAddCategoryList(updatedCategoryArray);
    }
  };
  return (
    <RootStyle>
      {/* SNACK BAR */}
      <SnackBarComp
        showSnakbar={showSnakbar}
        handleCloseSnakbar={handleCloseSnakbar}
        snakbarMsg={snakbarMsg}
        type={msgType ? msgType : "info"}
      />
      <Typography
        variant="h1"
        component="h1"
        className={classes.registrationHeading}
      >
        Organize your metrics in your own way
      </Typography>
      <Typography variant="h4" component="h4" className={classes.pageTitle}>
        Create a structure for your metrics that mirrors how you think about
        your life and the different areas you are working on.
      </Typography>
      <Grid display="flex" alignItems="center" flexWrap="wrap">
        <Typography variant="caption" component="div">
          Create Category
        </Typography>
        <Grid
          display="flex"
          alignItems="center"
          sx={{ cursor: "pointer", ml: "32px" }}
          onClick={() => showDrawer()}
        >
          <GetIdeasIcon />
          <span className={classes.needSomeIdeas}>Need some ideas?</span>
        </Grid>
        {/* RIGHT SIDE DRAWER */}
        <IdeasDrawer
          openDrawer={openDrawer}
          closeDrawer={closeDrawer}
          categoryIdeas={props.categoryIdeas}
          setCategoryInput={(idea) => {
            setCategoryInput(idea.name);
          }}
        />
      </Grid>

      <Grid
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="nowrap"
        gap={"12px"}
      >
        <TextField
          className="fillTextInput fullWidth"
          placeholder="Add an category to group your metrics"
          variant="outlined"
          fullWidth
          name="influence"
          value={categoryInput || ""}
          onChange={(e) => handleChangeCategory(e)}
          sx={{ mt: 2.5, flex: "auto"  }}
        />
        <Box sx={{ mt: 2.5, flex: "auto" }}>
          <NewButton
            variant="contained"
            buttonText={"Create"}
            onClick={handleCreate}
            isLoadong={showLoader}
            isDisabled={!categoryInput || categoryInput.trim() === ""}
            padding="0px 34px"
            boxPadding="14px 16px"
          />
        </Box>
      </Grid>

      {!!addCategoryList.length && (
        <Grid>
          <Box
            display={"flex"}
            alignItems={"center"}
            alignSelf={"stretch"}
            justifyContent={"center"}
            sx={{ mt: 6 }}
          >
            <Box
              display={"inline-flex"}
              alignItems={"center"}
              sx={{
                // background: "#EBF2F1",
                gap: "6px",
              }}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-end"}
                id={"mode-switch-tab"}
                sx={{
                  borderRadius: "6px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setexpandedView(true);
                  setExpandList([]);
                  // trackingDispatch(setExpandView(true))
                  // setExpandedLevelView(false)
                  // userTypeDispatch(addCategoryExpandAction([]))
                }}
              >
                <Box
                  sx={{
                    borderRadius: "4px",
                    backgroundColor:
                      /* expandedView ? palette.primary.main : */ "transparent",
                    borderBottom: expandedView
                      ? `2px solid ${palette.primary.main}`
                      : "none",
                    borderBottomLeftRadius: "0px",
                    borderBottomRightRadius: "0px",
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: "6px",
                      padding: "0px 12px",
                    }}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Typography
                      variant="body2"
                      component="span"
                      className={classes.compactExpandColumntab}
                      sx={{
                        fontWeight: expandedView
                          ? "700 !important"
                          : "400 !important",
                      }}
                    >
                      Compact
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-end"}
                sx={{
                  borderRadius: "6px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setexpandedView(false);
                  if (addCategoryList && addCategoryList.length) {
                    let arr = [];
                    for (
                      let index = 0;
                      index < addCategoryList.length;
                      index++
                    ) {
                      const element = addCategoryList[index];
                      arr.push(element.categoryId);
                    }
                    setExpandList(arr);
                  }
                  // setExpandedLevelView(false)
                  // trackingDispatch(setExpandView(false))
                }}
              >
                <Box
                  sx={{
                    borderRadius: "4px",
                    backgroundColor:
                      /* !expandedView ? palette.primary.main : */ "transparent",
                    borderBottom: !expandedView
                      ? `2px solid ${palette.primary.main}`
                      : "none",
                    borderBottomLeftRadius: "0px",
                    borderBottomRightRadius: "0px",
                  }}
                >
                  <Box
                    sx={{
                      borderRadius: "6px",
                      padding: "0px 12px",
                    }}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Typography
                      variant="body2"
                      component="span"
                      className={classes.compactExpandColumntab}
                      sx={{
                        fontWeight: !expandedView
                          ? "700 !important"
                          : "400 !important",
                      }}
                    >
                      Expanded
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            display={"flex"}
            alignItems={"center"}
            alignSelf={"stretch"}
            justifyContent={"space-between"}
            sx={{ mt: 6 }}
          >
            <Typography variant="caption" component="div">
              Drag from the right panel and drop here
            </Typography>
          </Box>

          <Grid container sx={{ mt: 2 }} justifyContent="space-between">
            {addCategoryList.map((category, index) => {
              return (
                <CategoryItemWrapper
                  index={index}
                  key={category?.categoryId}
                  category={category}
                  addItem={(item) => handleAdd(category?.categoryId, item)}
                  onRenameCategory={handleRename}
                  onDelete={handleDelete}
                  onDeleteItem={handleDeleteItem}
                  // movePetListItem={movePetListItem}
                  setAddCategoryList={setAddCategoryList}
                  addCategoryList={addCategoryList}
                  moveCategoryItem={moveCategoryItem}
                  expandedList={expandedList}
                  expanded={expanded}
                />
              );
            })}
          </Grid>
        </Grid>
      )}
    </RootStyle>
  );
};

export default LeftSideCategory;
