import React, { useState, useEffect, useContext, useCallback } from "react";
import {
    Button,
    Grid,
    TextField,
    Typography,
    SvgIcon,
    Dialog,
    Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import palette from "../../theme/palette";
import {  makeStyles } from "@material-ui/core";
import CircularProgress, {
    circularProgressClasses,
    CircularProgressProps,
} from '@mui/material/CircularProgress';
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { trackingService } from "../../services/tracking";
import { UserTypeContext } from "../../context/userStatus";
import { addCategoryExpandAction, addCategoryJourneyAction, addNotesJourneyAction, isEmptyState, isPersonalMode } from "../../store/actions/userTypeAction";
import { TrackingContext } from "../../context/userTracking";
import { setTrackingState } from "../../store/actions/trackerAction";
import useWidth from "../../utils/useWidth";
import { pxToRem } from "../../theme/typography";
import LoaderAnimation from "../../assets/images/icon/LoaderAnimation";

const RootStyle = styled("div")(({ theme }) => ({
    padding: "40px 80px",
    display: 'flex',
    alignItems: "center",
    flexDirection: 'column',
    gap: '32px',
    [theme.breakpoints.up("xl")]: {
        padding: "60px",
    },
    [theme.breakpoints.down("lg")]: {
        padding: "40px",
    },
    [theme.breakpoints.down("md")]: {
        padding: "30px",
    },
    [theme.breakpoints.down("sm")]: {
        padding: "20px",
    },
}));
const useStyles = makeStyles((theme) => ({
    registrationHeading: {
        color: palette.primary.light,
        fontSize: '24px !important',
        fontWeight: 400,
        lineHeight: '175% !important',
    },
    registrationHeadingInner: {
        color: `${palette.primary.light} !important`,
        fontSize: '20px !important',
        fontWeight: 400,
        lineHeight: '175% !important',
    },
    miHeight: {
        minHeight: `calc(100vh - 30%)`
    }
}))
const animationTime = 2500
let isCalled = false
// let names = []
const FinalAnimation = ({ dataLoading, setShowAnimation = null/* names, setShowNames */ }) => {
    const classes = useStyles();
    const planName = localStorage.getItem("planName")
    const navigate = useNavigate();
    const { state: outcomeState, dispatch: userTypeDispatch } =
        useContext(UserTypeContext);
    const { state: ITrackerState, dispatch: trackingDispatch } =
        useContext(TrackingContext);
    const [names, setShowNames] = useState<any>([])
    const [personalDataLoaded, setPersonalDataLoaded] = useState<any>([])
    const [allAnimationCompleted, setAllAnimationCompleted] = useState<boolean>(false)
    useEffect(() => {
        setTimeout(runAndShowValues, animationTime)
    }, [])
    useEffect(() => {
        if (dataLoading) {
            callAndSetPersonalMode()
        }
    }, [dataLoading])
    const callAndSetPersonalMode = async () => {
        let endDate = moment()
        const endOfMonth = moment().clone().endOf('month')
        if (endOfMonth?.diff(moment(), "day") >= 7) {
            endDate = endOfMonth
            // props?.setEndDate(moment().clone().endOf('month'))
        } else {
            endDate = moment().clone().add(1, 'month').endOf('month')
        }
        const bodyData = {
            from: moment().format("MM-DD-YYYY"),
            to: moment(endDate).format("MM-DD-YYYY"),
        };
        await getPeronsltrackData(bodyData)
    }
    const getPeronsltrackData = async (bodyData) => {
        localStorage.setItem("mode", "personal")
        userTypeDispatch(isPersonalMode(true))
        let data = await trackingService.getPersonalJourneyList(
            outcomeState.token,
            bodyData
        );
        localStorage.setItem("personalDataLoaded", "true")
        if (data.data.length <= 0) {
            userTypeDispatch(isEmptyState(true));
        } else {
            userTypeDispatch(isEmptyState(false));
        }
        trackingDispatch(setTrackingState({
            showLoader: false
        }))
        const newArr = [...outcomeState?.journeyCategoryExpandList]
        for (let index = 0; index < data?.data.length; index++) {
            const element = data?.data[index];
            newArr.push(element.categoryId)
        }
        userTypeDispatch(addCategoryExpandAction(newArr));
        userTypeDispatch(addCategoryJourneyAction(data?.data));
        userTypeDispatch(addNotesJourneyAction(data?.data));
        setPersonalDataLoaded(true)
        setDataLoadedAndRender(true)
        // setShowAnimation(false)
    }
    // const setShowNames = (nam) => {
    //     names = nam
    // }
    const runAndShowValues = () => {
        const outcomeFound = [...names].findIndex(it => it === "Outcomes")


        if (outcomeFound < 0 && !isCalled) {
            setShowNames(oldArray => [...oldArray, "Outcomes"]);
            isCalled = false
            // setShowNames("Outcomes")
            setTimeout(setInfluence, animationTime)
        }
    }
    const setInfluence = () => {
        const influ = [...names].findIndex(it => it === "Influences")
        if (influ < 0) {
            setShowNames(oldArray => [...oldArray, "Influences"]);

            // setShowNames("Influences")
            setTimeout(setReflective, animationTime)
        }
    }
    const setReflective = () => {
        const reflective = [...names].findIndex(it => it === "Reflective questions")
        if (reflective < 0) {
            setShowNames(oldArray => [...oldArray, "Reflective questions"]);
            // setShowNames("Reflective questions")
            setTimeout(navigateTonew, animationTime)
        }
    }
    const navigateTonew = () => {
        localStorage.setItem("showTutorial", "true")
        setAllAnimationCompleted(true)
        // navigate("/tracking", { replace: true });
    }
    const [dataLoadedAndRender, setDataLoadedAndRender] = useState(false)
    useEffect(() => {
        if (personalDataLoaded && dataLoadedAndRender && allAnimationCompleted) {
            localStorage.removeItem("showAnimation")
            setShowAnimation && setShowAnimation(false)
            trackingDispatch(setTrackingState({
                showLoader: false
            }))
        }
    }, [dataLoadedAndRender, personalDataLoaded, allAnimationCompleted])
    let filteredNames = []
    if ([...names].includes("Outcomes") && !filteredNames.includes("Outcomes")) {
        filteredNames.push("Outcomes")
    }
    if ([...names].includes("Influences") && !filteredNames.includes("Influences")) {
        filteredNames.push("Influences")
    }
    if ([...names].includes("Reflective questions") && !filteredNames.includes("Reflective questions")) {
        filteredNames.push("Reflective questions")
    }
    const width = useWidth()
    const isMobile = width < 768
    return <RootStyle
        // sx={!props.showOutcome ? { height: "100vh", overflowY: 'auto', paddingBottom: '100px !important' } : { height: "100vh", overflowY: 'auto', }}
        className={`login-right-part ${isMobile ? "right-side-new-bg-mobile" : "right-side-new-bg"} ` + classes.miHeight}
        sx={{
            background: `rgba(255, 255, 255, 0.4) !important`,
            minWidth: "30%"
        }}
    >
        <Typography
            variant="h1"
            component="h1"
            className={classes.registrationHeading}
            sx={isMobile ? {
                fontSize: `${pxToRem(22)} !important`,
                lineHeight: "120% !important",
                // textAlign: "center !important",
                fontWeight: 700,
                color: `${palette.primary.newLight} !important`,
                paddingTop: "50px",
                "&.MuiTypography-root": {
                    "@media  (max-width: 600px) and (min-width: 100px)": {
                        fontSize: `${pxToRem(22)} !important`,
                        lineHeight: "120% !important"
                    },
                }
            } : {
                fontSize: `${pxToRem(20)} !important`,
                lineHeight: `${pxToRem(29)} !important`,
                color: `${palette.primary.newLight} !important`,
                // textAlign: "center !important",
                fontWeight: 700,
            }}
        >
            {(planName || '') + " Plan"}
        </Typography>
        <Typography
            variant="h1"
            component="h1"
            className={classes.registrationHeading}
            sx={isMobile ? {
                fontSize: `${pxToRem(22)} !important`,
                lineHeight: "120% !important",
                // textAlign: "center !important",
                fontWeight: 400,
                paddingTop: "50px",
                "&.MuiTypography-root": {
                    "@media  (max-width: 600px) and (min-width: 100px)": {
                        fontSize: `${pxToRem(22)} !important`,
                        lineHeight: "120% !important"
                    },
                }
            } : {
                fontSize: `${pxToRem(16)} !important`,
                lineHeight: `${pxToRem(28)} !important`,
                color: `${palette.primary.newLight} !important`,
                // textAlign: "center !important",
                fontWeight: 400,
            }}
        >
            {"We are preparing your plan"}
        </Typography>
        <FacebookCircularProgress isMobile={isMobile} />
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"} padding={isMobile ? "0px": "0px  0px 0px 15%"} gap={"12px"} width={isMobile ? "80%" : "100%"}>
            <Box display={"flex"} flexDirection={"column"} alignSelf={"flex-start"} margin={"0px "} gap={"12px"} width={isMobile ? "100%" : "100%"}>
                {filteredNames.length > 0 && filteredNames.map(it => <InputValueShow name={it} isMobile={isMobile} />)}

            </Box>
        </Box>

    </RootStyle>
}

export default FinalAnimation

const InputValueShow = ({ name, isMobile = false }) => {
    const classes = useStyles();
    return <Box display={"flex"} gap={"12px"} alignItems={"center"}>
        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
            <circle cx="13.5" cy="13.5" r="13.5" fill={"#ADE888"} />
            <path d="M9.08273 12.6438L12.8372 16.3983L19.1529 10.0826" stroke="white" strokeWidth="4" />
        </svg>
        <Typography
            variant="h1"
            component="h1"
            className={classes.registrationHeadingInner}
            sx={isMobile ? {
                fontSize: `${pxToRem(20)} !important`,
                lineHeight: "175% !important",
                // textAlign: "center !important",
                fontWeight: 400,
                "&.MuiTypography-root": {
                    "@media  (max-width: 600px) and (min-width: 100px)": {
                        fontSize: `${pxToRem(20)} !important`,
                        lineHeight: "175% !important"
                    },
                }
            } : {
                fontWeight: 400
            }}
        >
            {name}
        </Typography>
    </Box>
}

function FacebookCircularProgress(props: CircularProgressProps & {
    isMobile?: boolean
}) {
    return (
        <Box sx={{ position: 'relative', margin: props?.isMobile && "50px 0" }}>
            {/* props.isMobile ?  */<LoaderAnimation color={"var(--color-new-main)"}/> /* : (
                <>
                    <CircularProgress
                        variant="determinate"
                        sx={{
                            color: " #11734A00"
                        }}
                        size={props.isMobile ? 200 : 140}
                        thickness={4}
                        {...props}
                        value={100}
                    />
                    <CircularProgress
                        variant="indeterminate"
                        disableShrink
                        sx={{
                            color: (theme) => palette.primary.main,
                            animationDuration: '550ms',
                            position: 'absolute',
                            left: 0,
                            [`& .${circularProgressClasses.circle}`]: {
                                strokeLinecap: 'round',
                            },
                        }}
                        size={props.isMobile ? 200 : 140}
                        thickness={4}
                        {...props}
                    />
                </>
            ) */}

        </Box>
    );
}
