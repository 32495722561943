/*eslint eqeqeq: "off"*/
import React, { useState, useContext } from "react";
import { styled } from "@mui/material/styles";
import { Box, Button, Typography } from "@mui/material";
import { pxToRem } from "../../theme/typography";
import { makeStyles } from "@material-ui/core";
import palette from "../../theme/palette";
// import OnboardingBG from "./../../assets/images/onboarding-bg.svg"
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { UserTypeContext } from "../../context/userStatus";
import {
  setUserLevel,
  setUserToken,
  setUserType,
} from "../../store/actions/userTypeAction";
import shape from "../../theme/shape";
import { userService } from "../../services/auth";
import SnackBarComp from "../../components/snackBar";
import { ButtonLoader } from "../../components/buttonLoader";
import {
  UpdateUserLevelModal,
  UserLoginModal,
} from "../../interfaces/outcomeModal";
import { newOnboarding } from "../../services/config";
import useWidth from "../../utils/useWidth";
import queryParams from "../../utils/getQueryParams";

const RootStyle = styled("div")(({ theme }) => ({
  padding: "40px 80px",

  [theme.breakpoints.up("xl")]: {
    padding: "60px",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "40px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "30px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "20px",
  },
}));
const useStyles = makeStyles((theme) => ({
  registrationHeading: {
    color: palette.primary.light,
    marginBottom: "62px!important",
    [theme.breakpoints.down("lg")]: {
      marginBottom: "50px!important",
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: "40px!important",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "30px!important",
    },
  },
  registrationHeadingMobile: {
    fontSize: `${pxToRem(22)} !important`,
    lineHeight: "120% !important",
    "&.MuiTypography-root": {
      "@media  (max-width: 600px) and (min-width: 100px)": {
        fontSize: `${pxToRem(22)} !important`,
        lineHeight: "120% !important"
      },
    }

  },
  registrationHeadingNew: {
    color: palette.primary.light,
    textAlign: "center",
    lineHeight: '120% !important'
  },
  customeRadioButton: {
    cursor: "pointer",
    color: palette.primary.newLight,
    backgroundColor: palette.common.white,
    borderRadius: shape.newBorderRadius,
    padding: "22px 25px 20px 25px",
    fontSize: pxToRem(18),
    lineHeight: pxToRem(27),
    fontWeight: 400,
    marginTop: pxToRem(16),
    border: "1px solid #fff",
    "&:hover": {
      border: "1px solid #D6F5C3",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "10px 12px",
      fontSize: pxToRem(16),
      lineHeight: pxToRem(16),
    },
  },
  customeRadioButtonMobile: {
    cursor: "pointer",
    color: palette.primary.light,
    backgroundColor: palette.common.white,
    borderRadius: shape.borderRadius,
    padding: "20px 16px 17px 16px",
    fontSize: pxToRem(16),
    lineHeight: pxToRem(17),
    fontWeight: 400,
    marginTop: pxToRem(16),
    border: "1px solid #fff",
  },
  customeRadioButtonActive: {
    backgroundColor: `${palette.primary.newmain}80`,
    color: palette.primary.newLight,
    border: "1px solid #D6F5C3"
  },
  customeRadioButtonActiveMobile: {
    backgroundColor: `${palette.primary.newmain}80`,
    color: palette.primary.newLight,
    border: "1px solid #D6F5C3"
  }
}));

const RightPartUserIdentification = () => {
  const { state: outcomeState, dispatch: UserTypeDispatch } =
    useContext(UserTypeContext);
  const queryData = queryParams()
  const classes = useStyles();
  const navigate = useNavigate();
  const [userStatus, setUserStatus] = useState("");
  const [activeStatus, setActiveStatus] = useState(false);
  const [activeStatusNo, setActiveStatusNo] = useState(false);
  const [showSnakbar, setShowSnakbar] = useState(false);
  const { state: UserState } = useContext(UserTypeContext);
  const [snakbarMsg, setSnakbarMsg] = useState("");
  const [msgType, setMsgType] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  let userDetails: any;
  const handleCloseSnakbar = () => {
    setShowSnakbar(false);
  };

  // SET USER LEVEL
  const getUserStatus = (status: string) => {
    if (status == "yes") {
      setActiveStatus(true);
      setActiveStatusNo(false);
      UserTypeDispatch(setUserType("yes"));
    } else {
      setActiveStatus(false);
      setActiveStatusNo(true);
      UserTypeDispatch(setUserType("no"));
    }
    setUserStatus(status);
  };

  // UPDATE USER LEVEL
  const updateUserLevel = () => {
    if (newOnboarding) {
      setTimeout(() => {
        navigate(!newOnboarding ? "/outcome" : "/plan-create" + queryData.qeryString);
      }, 200);
    }
    setShowLoader(true);
    if (outcomeState.userId == null) {
      try {
        userDetails = JSON.parse(localStorage.getItem("userDetails"));
      } catch (error) {
        userDetails = JSON.parse(localStorage.getItem("userValues"));
      }
    }
    const userData = {
      uid: outcomeState.userId
        ? outcomeState.userId
        : userDetails?.user?.uid
          ? userDetails?.user?.uid
          : userDetails?.uid,
      level: UserState?.userType == "yes" ? "HIGH" : "LOW",
    };

    let userValues = {}
    try {
      userValues = JSON.parse(localStorage.getItem("userValues"));
    } catch (error) {
      userValues = JSON.parse(localStorage.getItem("userDetails"));
    }

    const updatedUserValues = {
      ...userValues,
      level: activeStatus ? "HIGH" : "LOW",
    };

    userService
      .updateUserLevel(userData)
      .then((data: UpdateUserLevelModal) => {
        userService
          .userLogin(
            outcomeState.userId
              ? outcomeState.userId
              : userDetails?.user?.uid
                ? userDetails?.user?.uid
                : userDetails?.uid
          )
          .then((data: UserLoginModal) => {
            setShowSnakbar(true);
            setShowLoader(false);
            if (data.status === true) {
              setSnakbarMsg("Register successfully");
            } else {
              setSnakbarMsg(data.message);
            }
            setMsgType("success");
            localStorage.setItem("userDetails", data["x-access_token"]);
            localStorage.setItem(
              "userValues",
              JSON.stringify(updatedUserValues)
            );
            UserTypeDispatch(setUserToken(data["x-access_token"]));
            UserTypeDispatch(setUserLevel(data.level));
            if (!newOnboarding) {
              setTimeout(() => {
                navigate(!newOnboarding ? "/outcome" : "/plan-create" + queryData.qeryString);
              }, 2000);
            }

          });
      })
      .catch((error) => {
        setShowLoader(false);
        setMsgType("error");
        setShowSnakbar(true);
        setSnakbarMsg(error?.response?.data?.description);
      });
  };
  const width = useWidth()
  const isMobile = width < 768
  return (
    <RootStyle className={"login-right-part " + (newOnboarding ? `${isMobile ? "right-side-improvement-bg-mobile" : "right-side-improvement-bg"} flexColumnCenter` : "")}>
      <SnackBarComp
        showSnakbar={showSnakbar}
        handleCloseSnakbar={handleCloseSnakbar}
        snakbarMsg={snakbarMsg}
        type={msgType ? msgType : "info"}
      />
      {isMobile && <Typography
        variant="h1"
        component="h1"
        className={`${!newOnboarding ? classes.registrationHeading : classes.registrationHeadingNew} ${isMobile ? classes.registrationHeadingMobile : ""}`}
      >
        Let's get you on the right track
      </Typography>}

      {!newOnboarding ? (
        <>
          <Box>
            <Typography variant="h5" component="h5" color={"#5E5F5E !important"}>
              To help better facilitate your user experience we have designed
              seperate onboarding flows based on your level of experience with
              outcome tracking.
            </Typography>
            <Typography variant="h5" component="h5">
              Answer the following question so that we get you started on the right
              track.
            </Typography>
          </Box>
          <Box sx={{ mt: "5.85vw" }}>
            <Typography
              variant="subtitle1"
              component="p"
              sx={{ maxWidth: pxToRem(379) }}
            >
              Have you done any elaborate tracking before? (e.g. in excel)
            </Typography>
            <Box>
              <div
                className={`${activeStatus
                  ? clsx(
                    classes.customeRadioButton,
                    classes.customeRadioButtonActive
                  )
                  : classes.customeRadioButton
                  }`}
                onClick={() => getUserStatus("yes")}
              >
                <strong>Yes</strong>, I have tracked various aspects of my life
                simultaneously
              </div>
              <div
                className={`${activeStatusNo
                  ? clsx(
                    classes.customeRadioButton,
                    classes.customeRadioButtonActive
                  )
                  : classes.customeRadioButton
                  }`}
                onClick={() => getUserStatus("no")}
              >
                <strong>No</strong>, I have not really done extensive tracking and
                want to get started now
              </div>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Box sx={isMobile? {

          }: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "24px"
          }}>
            <Typography variant="h5" component="h5" textAlign={"center"} sx={isMobile ? {
              fontSize: `${pxToRem(16)} !important`,
              lineHeight: "120% !important",
              "&.MuiTypography-root": {
                "@media  (max-width: 600px) and (min-width: 100px)": {
                  fontSize: `${pxToRem(16)} !important`,
                  lineHeight: "120% !important"
                },
              }
            } : {}} color={`#5E5F5E !important`} fontSize={`${pxToRem(30)} !important`} fontWeight={500} lineHeight={`${pxToRem(36)} !important`}>
              Have you done any elaborate tracking before? (e.g.,  in excel)
            </Typography>
            <Typography variant="h5" component="h5" textAlign={"center"} sx={isMobile ? {
              fontSize: `${pxToRem(14)} !important`,
              lineHeight: "120% !important",
              "&.MuiTypography-root": {
                "@media  (max-width: 600px) and (min-width: 100px)": {
                  fontSize: `${pxToRem(14)} !important`,
                  lineHeight: "120% !important"
                },
              }
            } : {
              maxWidth: "60%",
            }} color={`#5E5F5E !important`} fontSize={`${pxToRem(16)} !important`} fontWeight={500} lineHeight={`${pxToRem(19.2)} !important`}>
              Select an answer to help us tailor your onboarding experience
            </Typography>
          </Box>
          <Box sx={{ mt: "1.00vw" }}>
            <Box>
              <div
                className={`${activeStatus
                  ? clsx(
                    isMobile ? classes.customeRadioButtonMobile : classes.customeRadioButton,
                    isMobile ? classes.customeRadioButtonActiveMobile : classes.customeRadioButtonActive
                  )
                  : isMobile ? classes.customeRadioButtonMobile : classes.customeRadioButton
                  }`}
                onClick={() => getUserStatus("yes")}
              >
                {isMobile ? <><strong>Yes</strong>, I have tracked various aspects of my life
                simultaneously</> : <>
                Yes, I have tracked various aspects of my life
                simultaneously
                </>}
              </div>
              <div
                className={`${activeStatusNo
                  ? clsx(
                    isMobile ? classes.customeRadioButtonMobile : classes.customeRadioButton,
                    isMobile ? classes.customeRadioButtonActiveMobile :classes.customeRadioButtonActive
                  )
                  : isMobile ? classes.customeRadioButtonMobile : classes.customeRadioButton
                  }`}
                onClick={() => getUserStatus("no")}
              >
                {isMobile ? <><strong>No</strong>, I have not really done extensive tracking and
                want to get started now</> : <>
                No, I have not really done extensive tracking and
                want to get started now
                </>}
              </div>
            </Box>
          </Box>
        </>
      )}

      {!showLoader ? (
        <Button
          variant="contained"
          className={/* isMobile ? "button-after" : */"new-button"}
          fullWidth
          sx={{ mt: pxToRem(!newOnboarding ? 40 : 15) }}
          disabled={userStatus == ""}
          onClick={updateUserLevel}
        >
          Continue
        </Button>
      ) : (
        <Button
          variant="contained"
          className={/* isMobile ? "button-after" : */"new-button"}
          fullWidth
          sx={{ mt: pxToRem(!newOnboarding ? 40 : 15) }}
        >
          <ButtonLoader />
        </Button>
      )}
    </RootStyle>
  );
};
export default RightPartUserIdentification;
