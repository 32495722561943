import React from "react";

const GetIdeasIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <circle cx="8" cy="8" r="8" fill="#F9D412" />
      <path
        d="M17.4159 13.4353C16.441 14.6241 15.2681 16.0742 14.8719 17.8757L14.6246 19H9.3754L9.12814 17.8757C8.73193 16.0742 7.55903 14.6241 6.58411 13.4352C5.59366 12.2274 5 10.6844 5 9C5 5.13401 8.13401 2 12 2C15.866 2 19 5.13401 19 9C19 10.6844 18.4063 12.2274 17.4159 13.4353Z"
        stroke="#11734A"
        strokeWidth="2"
      />
      <path
        d="M14.8287 8.99981L12.0002 11.8282L9.17182 8.99981"
        stroke="#11734A"
        strokeWidth="2"
      />
      <line
        x1="12.0002"
        y1="12"
        x2="12.0002"
        y2="16"
        stroke="#11734A"
        strokeWidth="2"
      />
      <rect x="8.5" y="21" width="7" height="2" fill="#11734A" />
    </svg>
  );
};
export default GetIdeasIcon;
