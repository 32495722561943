import React from "react";

const ThreeDotsIconVertical = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 3.75C7 3.55222 7.05865 3.35888 7.16853 3.19443C7.27841 3.02998 7.43459 2.90181 7.61732 2.82612C7.80004 2.75043 8.00111 2.73063 8.19509 2.76922C8.38907 2.8078 8.56725 2.90304 8.70711 3.04289C8.84696 3.18275 8.9422 3.36093 8.98079 3.55491C9.01937 3.74889 8.99957 3.94996 8.92388 4.13268C8.84819 4.31541 8.72002 4.47159 8.55557 4.58147C8.39112 4.69135 8.19778 4.75 8 4.75C7.73478 4.75 7.48043 4.64464 7.29289 4.45711C7.10536 4.26957 7 4.01522 7 3.75ZM8 7C7.80222 7 7.60888 7.05865 7.44443 7.16853C7.27998 7.27841 7.15181 7.43459 7.07612 7.61732C7.00043 7.80004 6.98063 8.00111 7.01922 8.19509C7.0578 8.38907 7.15304 8.56726 7.29289 8.70711C7.43275 8.84696 7.61093 8.9422 7.80491 8.98079C7.99889 9.01937 8.19996 8.99957 8.38268 8.92388C8.56541 8.84819 8.72159 8.72002 8.83147 8.55557C8.94135 8.39112 9 8.19778 9 8C9 7.73478 8.89464 7.48043 8.70711 7.29289C8.51957 7.10536 8.26522 7 8 7ZM8 11.25C7.80222 11.25 7.60888 11.3087 7.44443 11.4185C7.27998 11.5284 7.15181 11.6846 7.07612 11.8673C7.00043 12.05 6.98063 12.2511 7.01922 12.4451C7.0578 12.6391 7.15304 12.8173 7.29289 12.9571C7.43275 13.097 7.61093 13.1922 7.80491 13.2308C7.99889 13.2694 8.19996 13.2496 8.38268 13.1739C8.56541 13.0982 8.72159 12.97 8.83147 12.8056C8.94135 12.6411 9 12.4478 9 12.25C9 11.9848 8.89464 11.7304 8.70711 11.5429C8.51957 11.3554 8.26522 11.25 8 11.25Z" fill="#656565" />
    </svg>
  );
};

export default ThreeDotsIconVertical;
