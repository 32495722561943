import React, { useContext } from "react";
import SnackBarComp from "../../components/snackBar"
import { TrackingContext } from "../../context/userTracking";
import { setSnackBarOption } from "../../store/actions/trackerAction";

const TrackerSnackbar = () => {
    const { state: ITrackerState, dispatch: trackingDispatch } =
        useContext(TrackingContext);
    const { showSnakbar, snakbarMsg, msgType = 'success' } = ITrackerState
    const handleCloseSnakbar = () => {
        trackingDispatch(setSnackBarOption({
            showSnakbar: false,
            snakbarMsg: "",
            msgType: 'success'
        }))
    };
    return <SnackBarComp
        showSnakbar={showSnakbar}
        handleCloseSnakbar={handleCloseSnakbar}
        snakbarMsg={snakbarMsg}
        type={msgType ? msgType : "success"}
    />
}
export default TrackerSnackbar