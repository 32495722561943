import * as React from "react";

const Timer = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clipPath="url(#clip0_799_18794)">
        <circle cx="8" cy="8" r="7.25" stroke="#B7BDB9" strokeWidth="1.5" />
        <rect x="7.25" y="3" width="1.5" height="5" fill="#B7BDB9" />
        <rect x="7.25" y="7.25" width="4" height="1.5" fill="#B7BDB9" />
      </g>
      <defs>
        <clipPath id="clip0_799_18794">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default Timer;
