import React, { useContext, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import useStyles from "./TrackingClasses";
import moment from "moment";
import palette from "../../theme/palette";
import SnackBarComp from "../../components/snackBar";
import AddRefQuesModal from "../tracker/addRefQuesModal";
import { Typography } from "@mui/material";
import { UserTypeContext } from "../../context/userStatus";
import { TRACKING_CONST } from "./constants";
import { setTrackingState } from "../../store/actions/trackerAction";
import { TrackingContext } from "../../context/userTracking";
import { trackingService } from "../../services/tracking";
import { UpdateUserLevelModal } from "../../interfaces/outcomeModal";
import {
  addCategoryJourneyAction,
  addJourneyAction,
} from "../../store/actions/userTypeAction";
const IconPersonalReflective = ({
  item,
  date,
  callservice,
  style = {},
  isExpand,
  category = {} as any,
  calendar,
  journyList,
  setShowOutcomeInput,
  setShowInfluenceInput,
  setOutcomeId,
  setInfluenceId,
  setRefId,
  refId,
  isLast = false,
}) => {
  const classes = useStyles();
  const textareaRef = useRef<HTMLTextAreaElement>();
  const { state: outcomeState, dispatch: UserTypeDispatch } =
    useContext(UserTypeContext);
  const { state: ITrackerState, dispatch: trackingDispatch } =
    useContext(TrackingContext);
  const formatedDate = moment(date).format("MM-DD-YYYY");
  const clickCondition = moment(date).isBetween(
    moment().subtract(8, "year"),
    moment().add(1, 'day').startOf('day')
  );
  useEffect(() => {
    if (isLast && ITrackerState.showLoader) {
      trackingDispatch(
        setTrackingState({
          showLoader: false,
          isLastAlreadyLoaded: true,
        })
      );
    }
  }, [isLast, ITrackerState.showLoader]);

  const [showSnakbar, setShowSnakbar] = useState(false);
  const [snakbarMsg, setSnakbarMsg] = useState("");
  const [msgType, setMsgType] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [openRefQuesModal, setOpenRefQuesModal] = useState(false);
  const [showNotesInput, setShowNotesInput] = useState(false);
  const [questionValue, setQuestionValue] = useState<any>("");
  const [questionArr, setQuestionArr] = useState([]);
  const { reflectiveResponses = [] } = item;
  const heightObj = TRACKING_CONST.EXPAND_HEIGHT;
  const renderToNextInput = (e, categoryId, id, isAddCase = false) => {
    if (!outcomeState.isPersonal) {
      const foundIndex = outcomeState?.reflectiveList.findIndex(
        (it) => it._id === id
      );
      if (foundIndex > -1) {
        if (outcomeState?.reflectiveList[foundIndex + 1]?.skipMetric) {
          renderToNextInput(
            e,
            categoryId,
            outcomeState?.reflectiveList[foundIndex + 1]?._id
          );
        } else {
          const foundZeroInfluence =
            outcomeState?.reflectiveList[foundIndex + 1]?._id;
          if (foundZeroInfluence) {
            const date = moment(formatedDate, "mm-DD-yyyy").format(
              "mm-DD-yyyy"
            );
            const foundElement = document.getElementById(
              date + "_" + foundZeroInfluence
            );
            if (foundElement) {
              setRefId(
                outcomeState?.reflectiveList[foundIndex + 1]?.reflectiveId
              );
              foundElement.click();
            }
          }
        }
      }
    } else {
      if (item.categoryName !== "Other") {
        const foundCategory = journyList.find(
          (it) => it.categoryId === categoryId
        );
        if (foundCategory && foundCategory.alls && foundCategory.alls.length) {
          const findIndexOfEl = foundCategory.alls.findIndex(
            (it) => it._id === id
          );
          let isLast = false
          if(findIndexOfEl === foundCategory.alls.length - 1) {
            isLast = true
          }
          if(isLast) {
            const foundCategoryIndex = journyList.findIndex(
              (it) => it.categoryId === categoryId
            )
            if(foundCategoryIndex > -1 && journyList[foundCategoryIndex + 1]?.categoryId && journyList[foundCategoryIndex + 1]?.alls && journyList[foundCategoryIndex + 1]?.alls?.length) {
              if(journyList[foundCategoryIndex + 1]?.alls[0].skipMetric) {
                renderToNextInput(
                  e,
                  journyList[foundCategoryIndex + 1]?.categoryId,
                  journyList[foundCategoryIndex + 1]?.alls[0]._id,
                  isAddCase
                );
              } else {
                const date = moment(formatedDate, "mm-DD-yyyy").format(
                  "mm-DD-yyyy"
                );
                const foundElement = document.getElementById(
                  date + "_" + journyList[foundCategoryIndex + 1]?.alls[0]._id
                );
                if (foundElement) {
                  if (journyList[foundCategoryIndex + 1]?.alls[0].outcomeId) {
                    setShowOutcomeInput(true);
                    setOutcomeId(
                      journyList[foundCategoryIndex + 1]?.alls[0].outcomeId
                    );
                    foundElement.click();
                  }
                  if (journyList[foundCategoryIndex + 1]?.alls[0].influenceId) {
                    setShowInfluenceInput(true);
                    setInfluenceId(
                      journyList[foundCategoryIndex + 1]?.alls[0].influenceId
                    );
                    foundElement.click();
                  }
                  if (
                    journyList[foundCategoryIndex + 1]?.alls[0].reflectiveId &&
                    isAddCase
                  ) {
                    setRefId(
                      journyList[foundCategoryIndex + 1]?.alls[0].reflectiveId
                    );
                    foundElement.click();
                  }
                }
              }
              
              
            }
          } else if (findIndexOfEl > -1) {
            // // down arrow handle
            if (
              (e.key === "Enter" || Number(e.keyCode) === 40) &&
              foundCategory.alls[findIndexOfEl + 1] &&
              foundCategory.alls[findIndexOfEl + 1]._id
            ) {
              if (foundCategory.alls[findIndexOfEl + 1]?.skipMetric) {
                renderToNextInput(
                  e,
                  categoryId,
                  foundCategory.alls[findIndexOfEl + 1]._id
                );
              } else {
                const date = moment(formatedDate, "mm-DD-yyyy").format(
                  "mm-DD-yyyy"
                );
                const foundElement = document.getElementById(
                  date + "_" + foundCategory.alls[findIndexOfEl + 1]._id
                );
                if (foundElement) {
                  if (foundCategory.alls[findIndexOfEl + 1].outcomeId) {
                    setShowOutcomeInput(true);
                    setOutcomeId(
                      foundCategory.alls[findIndexOfEl + 1].outcomeId
                    );
                    foundElement.click();
                  }
                  if (foundCategory.alls[findIndexOfEl + 1].influenceId) {
                    setShowInfluenceInput(true);
                    setInfluenceId(
                      foundCategory.alls[findIndexOfEl + 1].influenceId
                    );
                    foundElement.click();
                  }
                  if (
                    foundCategory.alls[findIndexOfEl + 1].reflectiveId &&
                    isAddCase
                  ) {
                    setRefId(
                      foundCategory.alls[findIndexOfEl + 1].reflectiveId
                    );
                    foundElement.click();
                  }
                }
              }
            } else if (e.key === "Enter" || Number(e.keyCode) == 40) {
              const foundCategoryIndex = journyList.findIndex(
                (it) => it.categoryId === categoryId
              );
              if (foundCategoryIndex > -1) {
                if (journyList[foundCategoryIndex + 1].name === "Other") {
                  if (
                    journyList[foundCategoryIndex + 1].outcomes &&
                    journyList[foundCategoryIndex + 1].outcomes.length
                  ) {
                    if (
                      journyList[foundCategoryIndex + 1].outcomes[0]?.skipMetric
                    ) {
                      renderToNextInput(
                        e,
                        categoryId,
                        journyList[foundCategoryIndex + 1].outcomes[0]._id
                      );
                    } else {
                      const date = moment(formatedDate, "mm-DD-yyyy").format(
                        "mm-DD-yyyy"
                      );
                      const foundElement = document.getElementById(
                        date +
                          "_" +
                          journyList[foundCategoryIndex + 1].outcomes[0]._id
                      );
                      if (foundElement) {
                        foundElement.click();
                      }
                    }
                  } else if (
                    journyList[foundCategoryIndex + 1].influences &&
                    journyList[foundCategoryIndex + 1].influences.length
                  ) {
                    if (
                      journyList[foundCategoryIndex + 1].influences[0]
                        ?.skipMetric
                    ) {
                      renderToNextInput(
                        e,
                        categoryId,
                        journyList[foundCategoryIndex + 1].influences[0]._id
                      );
                    } else {
                      const date = moment(formatedDate, "mm-DD-yyyy").format(
                        "mm-DD-yyyy"
                      );
                      const foundElement = document.getElementById(
                        date +
                          "_" +
                          journyList[foundCategoryIndex + 1].influences[0]._id
                      );
                      if (foundElement) {
                        foundElement.click();
                      }
                    }
                  }
                } else {
                  if (
                    journyList[foundCategoryIndex + 1] &&
                    journyList[foundCategoryIndex + 1].alls &&
                    journyList[foundCategoryIndex + 1].alls.length
                  ) {
                    if (
                      journyList[foundCategoryIndex + 1].alls[0]?.skipMetric
                    ) {
                      renderToNextInput(
                        e,
                        categoryId,
                        journyList[foundCategoryIndex + 1].alls[0]._id
                      );
                    } else {
                      const date = moment(formatedDate, "mm-DD-yyyy").format(
                        "mm-DD-yyyy"
                      );
                      const foundElement = document.getElementById(
                        date +
                          "_" +
                          journyList[foundCategoryIndex + 1].alls[0]._id
                      );
                      if (foundElement) {
                        if (foundCategory.alls[findIndexOfEl + 1].outcomeId) {
                          setOutcomeId(
                            foundCategory.alls[findIndexOfEl + 1].outcomeId
                          );
                        }
                        if (foundCategory.alls[findIndexOfEl + 1].influenceId) {
                          setInfluenceId(
                            foundCategory.alls[findIndexOfEl + 1].influenceId
                          );
                        }
                        foundElement.click();
                      }
                    }
                  }
                }
              }
            }
            // // up arrow key
            if (
              Number(e.keyCode) === 38 &&
              foundCategory.alls[findIndexOfEl - 1] &&
              foundCategory.alls[findIndexOfEl - 1]._id
            ) {
              if (foundCategory.alls[findIndexOfEl - 1]?.skipMetric) {
                renderToNextInput(
                  e,
                  categoryId,
                  foundCategory.alls[findIndexOfEl - 1]._id
                );
              } else {
                const date = moment(formatedDate, "mm-DD-yyyy").format(
                  "mm-DD-yyyy"
                );
                const foundElement = document.getElementById(
                  date + "_" + foundCategory.alls[findIndexOfEl - 1]._id
                );
                if (foundElement) {
                  foundElement.click();
                }
              }
            }
            //right side arrow keys
            if (Number(e.keyCode) === 39) {
              const date = moment(formatedDate, "mm-DD-yyyy")
                .add(1, "day")
                .format("mm-DD-yyyy");
              const foundElement = document.getElementById(date + "_" + id);
              if (foundElement) {
                foundElement.click();
              }
            }
            //left side arrow keys
            if (Number(e.keyCode) === 37) {
              const date = moment(formatedDate, "mm-DD-yyyy")
                .subtract(1, "day")
                .format("mm-DD-yyyy");
              const foundElement = document.getElementById(date + "_" + id);
              if (foundElement) {
                foundElement.click();
              }
            }
          }
        }
      } else {
        const foundOther = (journyList || []).findIndex(
          (it) => it.name === "Other"
        );
        if (foundOther > -1) {
          const outcomeFound = journyList[foundOther].reflectives.findIndex(
            (it) => it._id === id
          );
          if (outcomeFound > -1) {
            // // down arrow handle
            if (
              (e.key === "Enter" || Number(e.keyCode) === 40) &&
              journyList[foundOther].reflectives[outcomeFound + 1] &&
              journyList[foundOther].reflectives[outcomeFound + 1]._id
            ) {
              if (
                journyList[foundOther].reflectives[outcomeFound + 1]?.skipMetric
              ) {
                // renderToNextInput(
                //   e,
                //   categoryId,
                //   journyList[foundOther].reflectives[outcomeFound + 1]._id
                // );
              } else {
                const date = moment(formatedDate, "mm-DD-yyyy").format(
                  "mm-DD-yyyy"
                );
                const foundElement = document.getElementById(
                  date +
                    "_" +
                    journyList[foundOther].reflectives[outcomeFound + 1]._id
                );
                if (foundElement) {
                  // foundElement.click()
                }
              }
            } else if (e.key === "Enter" || Number(e.keyCode) === 40) {
              if (journyList[foundOther].reflectives[0]?.skipMetric) {
                // renderToNextInput(
                //   e,
                //   categoryId,
                //   journyList[foundOther].reflectives[0]._id
                // );
              } else {
                const foundZeroInfluence =
                  journyList[foundOther].reflectives[0]?._id;
                if (foundZeroInfluence) {
                  const date = moment(formatedDate, "mm-DD-yyyy").format(
                    "mm-DD-yyyy"
                  );
                  const foundElement = document.getElementById(
                    date + "_" + journyList[foundOther].reflectives[0]._id
                  );
                  if (foundElement) {
                    // foundElement.click()
                  }
                }
              }
            }
            // // up arrow key
            if (
              Number(e.keyCode) === 38 &&
              journyList[foundOther].outcomes[outcomeFound - 1] &&
              journyList[foundOther].outcomes[outcomeFound - 1]._id
            ) {
              if (
                journyList[foundOther].outcomes[outcomeFound - 1]?.skipMetric
              ) {
                renderToNextInput(
                  e,
                  categoryId,
                  journyList[foundOther].outcomes[outcomeFound - 1]._id
                );
              } else {
                const date = moment(formatedDate, "mm-DD-yyyy").format(
                  "mm-DD-yyyy"
                );
                const foundElement = document.getElementById(
                  date +
                    "_" +
                    journyList[foundOther].outcomes[outcomeFound - 1]._id
                );
                if (foundElement) {
                  foundElement.click();
                }
              }
            } else if (Number(e.keyCode) === 38) {
              const foundOther = (journyList || []).findIndex(
                (it) => it.name === "Other"
              );
              if (foundOther > -1) {
                if (
                  journyList[foundOther - 1] &&
                  journyList[foundOther - 1].alls &&
                  journyList[foundOther - 1].alls.length
                ) {
                  if (
                    journyList[foundOther - 1].alls[
                      journyList[foundOther - 1].alls.length - 1
                    ]?.skipMetric
                  ) {
                    renderToNextInput(
                      e,
                      categoryId,
                      journyList[foundOther - 1].alls[
                        journyList[foundOther - 1].alls.length - 1
                      ]._id
                    );
                  } else {
                    const date = moment(formatedDate, "mm-DD-yyyy").format(
                      "mm-DD-yyyy"
                    );
                    const foundElement = document.getElementById(
                      date +
                        "_" +
                        journyList[foundOther - 1].alls[
                          journyList[foundOther - 1].alls.length - 1
                        ]._id
                    );
                    if (foundElement) {
                      foundElement.click();
                    }
                  }
                }
              }
            }

            //right side arrow keys
            if (Number(e.keyCode) === 39) {
              const date = moment(formatedDate, "mm-DD-yyyy")
                .add(1, "day")
                .format("mm-DD-yyyy");
              const foundElement = document.getElementById(date + "_" + id);
              if (foundElement) {
                foundElement.click();
              }
            }
            //left side arrow keys
            if (Number(e.keyCode) === 37) {
              const date = moment(formatedDate, "mm-DD-yyyy")
                .subtract(1, "day")
                .format("mm-DD-yyyy");
              const foundElement = document.getElementById(date + "_" + id);
              if (foundElement) {
                foundElement.click();
              }
            }
          } else {
          }
        }
      }
    }
  };
  React.useLayoutEffect(() => {
    if (!textareaRef.current) return;
    if (textareaRef.current) {
      // Reset height - important to shrink on delete
      textareaRef.current.style.height = "inherit";
      // Set height
      textareaRef.current.style.height = `${Math.max(
        textareaRef.current.scrollHeight,
        32
      )}px`;
    }
  }, [showNotesInput, questionValue]);
  React.useLayoutEffect(() => {
    if (!textareaRef.current) return;
    if (textareaRef.current) {
      textareaRef.current.focus();
      textareaRef.current.setSelectionRange(
        textareaRef.current.value.length,
        textareaRef.current.value.length
      );
    }
  }, [showNotesInput]);
  const handleBlurInputArea = (type, e: any, mouseOut, id) => {
    if (mouseOut) {
      setShowNotesInput(false);
    }
    if (
      Number(e.keyCode) === 37 ||
      Number(e.keyCode) === 38 ||
      Number(e.keyCode) === 39 ||
      Number(e.keyCode) === 40 ||
      mouseOut
    ) {
      createRefResponse();
    }
  };
  const pushIntoData = (outcomeCreateData) => {
    const journeyCategoryList = [...(outcomeState?.journeyCategoryList || [])];
    const foundOutcome = (journeyCategoryList || []).findIndex(
      (it) =>
        it.reflectiveIds &&
        it.reflectiveIds.includes(outcomeCreateData.reflectiveId)
    );
    if (foundOutcome > -1) {
      if (
        journeyCategoryList[foundOutcome].alls &&
        journeyCategoryList[foundOutcome].alls.length
      ) {
        const foundIndex = journeyCategoryList[foundOutcome].alls.findIndex(
          (it) => it.reflectiveId === outcomeCreateData.reflectiveId
        );
        if (foundIndex > -1) {
          if (
            journeyCategoryList[foundOutcome].alls[foundIndex]
              .reflectiveResponses &&
            journeyCategoryList[foundOutcome].alls[foundIndex]
              .reflectiveResponses.length
          ) {
            const foundDateValue = journeyCategoryList[foundOutcome].alls[
              foundIndex
            ].reflectiveResponses.findIndex(
              (it) => it._id.date === outcomeCreateData.date
            );
            if (foundDateValue > -1) {
              journeyCategoryList[foundOutcome].alls[
                foundIndex
              ].reflectiveResponses[foundDateValue] = {
                _id: {
                  ...outcomeCreateData,
                },
              };
            } else {
              journeyCategoryList[foundOutcome].alls[
                foundIndex
              ].reflectiveResponses.push({
                _id: {
                  ...outcomeCreateData,
                },
              });
            }
          } else {
            journeyCategoryList[foundOutcome].alls[
              foundIndex
            ].reflectiveResponses = [
              {
                _id: {
                  ...outcomeCreateData,
                },
              },
            ];
          }
          UserTypeDispatch(addCategoryJourneyAction(journeyCategoryList));
        }
      }
    } else {
      const foundOther = (journeyCategoryList || []).findIndex(
        (it) => it.name === "Other"
      );
      if (foundOther > -1) {
        const outcomeFound = journeyCategoryList[
          foundOther
        ].reflectives.findIndex(
          (it) => it.reflectiveId === outcomeCreateData.reflectiveId
        );
        if (outcomeFound > -1) {
          if (
            journeyCategoryList[foundOther].reflectives[outcomeFound]
              .reflectiveResponses &&
            journeyCategoryList[foundOther].reflectives[outcomeFound]
              .reflectiveResponses.length
          ) {
            const foundDateValue = journeyCategoryList[foundOther].reflectives[
              outcomeFound
            ].reflectiveResponses.findIndex(
              (it) => it._id.date === outcomeCreateData.date
            );
            if (foundDateValue > -1) {
              journeyCategoryList[foundOther].reflectives[
                outcomeFound
              ].reflectiveResponses[foundDateValue] = {
                _id: {
                  ...outcomeCreateData,
                },
              };
            } else {
              journeyCategoryList[foundOther].reflectives[
                outcomeFound
              ].reflectiveResponses.push({
                _id: {
                  ...outcomeCreateData,
                },
              });
            }
          } else {
            journeyCategoryList[foundOther].reflectives[
              outcomeFound
            ].reflectiveResponses = [
              {
                _id: {
                  ...outcomeCreateData,
                },
              },
            ];
          }
          UserTypeDispatch(addCategoryJourneyAction(journeyCategoryList));
        }
      }
    }
    const foundOutcomeDef = (outcomeState?.reflectiveList || []).find(
      (it) => it.reflectiveId === outcomeCreateData.reflectiveId
    );
    if (foundOutcomeDef) {
      const journyList = { ...(outcomeState?.journeyList || {}) } as any;
      const foundDateItemIcon = journyList.reflective_data.findIndex(
        (it) =>
          it.reflectiveId === outcomeCreateData.reflectiveId &&
          it?.date === outcomeCreateData.date
      );
      if (foundDateItemIcon > -1) {
        journyList.reflective_data[foundDateItemIcon] = {
          ...foundOutcomeDef,
          ...outcomeCreateData,
        };
      } else {
        journyList.reflective_data.push({
          ...foundOutcomeDef,
          ...outcomeCreateData,
        } as any);
      }
      UserTypeDispatch(addJourneyAction(journyList as any));
    }
  };
  const createRefResponse = () => {
    let bodyData = {
      reflectiveId: refId,
      answers:
        questionValue === "" || questionValue === "\n"
          ? questionArr
              .filter((it) => it && it !== "\n")
              .join("\n")
              .split("\n")
          : [...questionArr, questionValue]
              .filter((it) => it && it !== "\n")
              .join("\n")
              .split("\n"),
      date: formatedDate,
    };
    bodyData.answers = bodyData.answers.filter((it) => it && it !== "\n");
    pushIntoData(bodyData);
    trackingService
      .refQuesCreate(outcomeState.token, bodyData)
      .then((data: UpdateUserLevelModal) => {})
      .catch((error) => {
        setShowSnakbar(true);
        setSnakbarMsg(
          error?.response?.data?.description
            ? error?.response?.data?.description
            : error?.message
        );
        setMsgType("error");
      });
  };
  const found =
    reflectiveResponses && reflectiveResponses.length > 0
      ? reflectiveResponses.find((journey) =>
          outcomeState.isPersonal
            ? journey?._id?.date === formatedDate && journey?._id?.answers?.length > 0
            : journey?.date === formatedDate && journey?.answers?.length > 0
        )
      : undefined;
  return (
    <td
      className={clsx(
        classes.cell,
        classes.borderBottom,
        isExpand ? classes.iconExpand : ""
      )}
      style={{
        position: "relative",
        height: "inherit",
        ...style,
      }}
      key={item._id}
    >
      <SnackBarComp
        showSnakbar={showSnakbar}
        handleCloseSnakbar={() => {
          setShowSnakbar(false);
        }}
        snakbarMsg={snakbarMsg}
        type={msgType ? msgType : "info"}
      />
      <div
        style={
          isExpand
            ? {
                height: "100%",
              }
            : {
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }
        }
      >
        <div
          className={
            moment(calendar[calendar.length - 1]).format("MM-DD-YYYY") ===
            formatedDate
              ? classes.iconInnerWithoutAfter
              : classes.iconInner
          }
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: isExpand ? "flex-start" : "center",
            flexBasis: "100%",
          }}
          onClick={(e) => {
            if (clickCondition) {
              setRefId(item.reflectiveId);
              setOpenRefQuesModal(true);
            }
          }}
        >
          <span className={classes.addOutlineIcon}>
            {found ? (
              <div
                className="hello"
                key={item._id}
                id={formatedDate + "_" + item._id}
                onClick={() => {
                  if (clickCondition) setIsEdit(true);
                }}
              >
                <span
                  style={{
                    backgroundColor: "var(--color-new-main)",
                    border: `1px solid ${"var(--color-new-main)"}`,
                  }}
                >
                  <span
                    style={{
                      backgroundColor: "var(--color-new-main)",
                      borderRadius: "50%",
                      border: `1px solid ${"var(--color-new-main)"}`,
                    }}
                  ></span>
                </span>
              </div>
            ) : item?.weekDays?.some(
                (day) =>
                  day?.day.toLowerCase() ===
                    moment(date).format("dddd").toLowerCase() &&
                  moment(date).isBefore(moment())
              ) ? (
              <span
                style={{
                  backgroundColor: "rgb(255, 152, 0)",
                  border: `1px solid rgb(255, 152, 0)`,
                }}
                id={formatedDate + "_" + item._id}
              >
                <span
                  style={{
                    backgroundColor: "rgb(255, 152, 0)",
                    borderRadius: "50%",
                    border: `1px solid rgb(255, 152, 0)`,
                  }}
                ></span>
              </span>
            ) : (
              <span key={item._id} id={formatedDate + "_" + item._id}>
                +
              </span>
            )}
          </span>
        </div>
        {isExpand ? (
          <div
            style={{
              display: "flex",
              width: "176px",
              // height: length,
              padding: "12px",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "12px",
              alignSelf: "stretch",
              borderRadius: "12px",
              border: "1px solid #F3F3F3",
              background: "#FFF",
              flexBasis: "100%",
              margin: "13px 8px 0px 0",
              overflowY: "auto",
              wordWrap: "break-word",
              ...heightObj,
            }}
            onClick={() => {
              if (clickCondition && !showNotesInput) {
                let notes = [];
                if (
                  outcomeState.isPersonal &&
                  found?._id &&
                  found?._id?.answers &&
                  found?._id?.answers[0]
                ) {
                  notes = found?._id?.answers;
                } else if (
                  !outcomeState.isPersonal &&
                  found &&
                  found?.answers &&
                  found?.answers[0]
                ) {
                  notes = found?.answers;
                }
                setQuestionValue(notes.join("\n"));
                setShowNotesInput(true);
                setRefId(item.reflectiveId);
              }
            }}
            onDoubleClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (clickCondition) {
                setRefId(item.reflectiveId);
                setIsEdit(!found);
                setOpenRefQuesModal(true);
              }
            }}
          >
            {showNotesInput ? (
              <textarea
                name={item?._id}
                autoFocus
                ref={textareaRef}
                value={questionValue}
                style={{
                  width: "100%",
                  minHeight: "10px",
                  resize: "none",
                }}
                // defaultValue={found?.note && found?.note[0]}
                onBlur={() => {
                  handleBlurInputArea(
                    item?.type,
                    { key: "kdksjdks", keyCode: "hdj" },
                    true,
                    item._id
                  );
                }}
                onChange={(e) => {
                  setQuestionValue(e.target.value);
                }}
                onKeyDown={(e) => {
                  handleBlurInputArea(item?.type, e, false, item._id);
                }}
              />
            ) : (
              <>
                {outcomeState.isPersonal
                  ? found?._id &&
                    found?._id?.answers &&
                    found?._id?.answers[0] && (
                      <ul
                        id={
                          outcomeState.isPersonal && item._id
                            ? `${item?._id?.reflectiveId}_${formatedDate}`
                            : `${item?.reflectiveId}_${formatedDate}`
                        }
                        className={classes.notesItem}
                      >
                        {found?._id?.answers?.map(
                          (anwerItem: any, index: number) => {
                            return (
                              <li key={index}>
                                <Typography
                                  variant="h5"
                                  sx={{ color: palette.common.black }}
                                  component="span"
                                  title={anwerItem}
                                >
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        anwerItem.replace(/\n/g, "<br />") ||
                                        "",
                                    }}
                                  ></span>
                                </Typography>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    )
                  : found &&
                    found?.answers &&
                    found?.answers[0] && (
                      <ul
                        id={`${item?.reflectiveId}_${formatedDate}`}
                        className={classes.notesItem}
                      >
                        {found?.answers?.map(
                          (anwerItem: any, index: number) => {
                            return (
                              <li key={index}>
                                <Typography
                                  variant="h5"
                                  sx={{ color: palette.common.black }}
                                  component="span"
                                  title={anwerItem}
                                >
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        anwerItem.replace(/\n/g, "<br />") ||
                                        "",
                                    }}
                                  ></span>
                                </Typography>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    )}
              </>
            )}
          </div>
        ) : null}
      </div>

      {openRefQuesModal && (
        <AddRefQuesModal
          setOpenRefQuesModal={setOpenRefQuesModal}
          refId={refId}
          setShowSnakbar={setShowSnakbar}
          setSnakbarMsg={setSnakbarMsg}
          setMsgType={setMsgType}
          setRefId={setRefId}
          callService={callservice}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          journeyData={journyList}
          headerDate={formatedDate}
          pushIntoData={pushIntoData}
          item={item}
          renderToNextInput={(isAddCase = false) => {
            renderToNextInput(
              {
                key: "Enter",
              },
              category?.categoryId,
              item._id,
              isAddCase
            );
          }}
        />
      )}
    </td>
  );
};

export default IconPersonalReflective;
