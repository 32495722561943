import * as React from 'react';
import Goals from "./goals"

const TestGoals = () => {
  return (
    <>
      <Goals />
    </>
  )
}
export default TestGoals