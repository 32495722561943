import React from 'react'

const ArrowDownSmall = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
        <path d="M5.35355 6.64645C5.15829 6.84171 4.84171 6.84171 4.64645 6.64645L1.85355 3.85355C1.53857 3.53857 1.76165 3 2.20711 3L7.79289 3C8.23835 3 8.46143 3.53857 8.14645 3.85355L5.35355 6.64645Z" fill="#3E4240" />
      </svg>
    </>
  )
}

export const ArrowUpSmall = () => {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
        <path d="M4.64645 3.35355C4.84171 3.15829 5.15829 3.15829 5.35355 3.35355L8.14645 6.14645C8.46143 6.46143 8.23835 7 7.79289 7L2.20711 7C1.76165 7 1.53857 6.46143 1.85355 6.14645L4.64645 3.35355Z" fill="#3E4240" />
      </svg>
    </>
  )
}

export default ArrowDownSmall
