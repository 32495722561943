import React, { Component } from 'react';
type DoubleClickHandlerProp = {
children: React.ReactNode;
onDoubleClick: Function;
onClick: Function;
id: string
};
  
export default class DoubleClickHandler extends Component <DoubleClickHandlerProp> {
    timeout: any;
    constructor(props: any) {
        super(props);
        this.onClick = this.onClick.bind(this);
        this.onDoubleClick = this.onDoubleClick.bind(this);
        this.timeout = null;
    }

    onClick(e) {
        e.preventDefault();

        if (this.timeout === null) {
            this.timeout = window.setTimeout(() => {
                this.timeout = null;
                const { onClick } = this.props as any
                onClick();
            }, 300);
        }
    }

    onDoubleClick(e) {
        e.preventDefault();
        window.clearTimeout(this.timeout);
        this.timeout = null;
        const { onDoubleClick } = this.props as any
        onDoubleClick();
    }

    render() {
        const { onClick, onDoubleClick, children, id, ...childProps  } = this.props as any;
        const props = Object.assign(childProps, { id: id, onClick: this.onClick, onDoubleClick: this.onDoubleClick });
        return React.cloneElement(children, props);
    }
}
