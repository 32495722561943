import React from "react"
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography, useMediaQuery, } from "@mui/material";
import { makeStyles, useTheme } from "@material-ui/core";
import ModalCloseIcon from "../../assets/images/icon/modalCloseIconBlack";
import palette from "../../theme/palette";

const regressions = [
    {
        title: 'Definition',
        text: 'The regression coefficient is a number that indicates the effect an increase in one metric has on another metric.'
    },
    {
        title: 'Interpretation',
        text: 'A regression coefficient of 1.3 between daily meditation and focus means that on days when you do meditation, you will on average report 1.3 more focus compared to days without meditation.'
    },
    {
        title: 'Model',
        text: 'TNT is currently only running univariate linear regressions, which can lead to imperfect estimations. For example, it could overestimate or underestimate the effect of one metric because of the influence of a third or fourth metric that wasn\'t accounted for.'
    },
]
const correlations = [
    {
        title: 'Definition',
        text: `The correlation coefficient is a number between -1 and 1. The sign tells you if the relationship between metrics is positive or negative. The number tells you about the strength of the relationship or, more specifically, how likely it is that you input a low or high value for both on the same day. Heuristics for correlations are 
        <br /> .00 - .10 no/negligible 
        <br /> .11 - .30 small 
        <br /> .31 - .60 moderate
        <br /> .61 - .80 strong
        <br /> .81 - 1 very strong`
    },
    {
        title: 'Interpretation',
        text: 'A correlation of .70 between daily meditation and focus means that on days when you do meditation, you are very likely to report a high value for focus.'
    },
    {
        title: 'Limitation',
        text: 'Because it is a relative number, it tells you little about the absolute size of the effect, which makes it impossible to compare with the effects of other influences. It important to also remember that "correlation does not imply causation". '
    },
    {
        title: 'Model',
        text: 'TNT is currently only running univariate linear correlations, which can lead to imperfect estimations. For example, it could overestimate or underestimate the correlation because of the influence of a third or fourth metric that wasn\'t accounted for.'
    },
]

const useStyles = makeStyles((theme) => ({
    registrationHeading: {
        color: `${palette.primary.light}!important`,
        marginBottom: "20px!important",
    },
    registrationTitle: {
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '1.375rem',
        lineHeight: '100%',
        /* identical to box height, or 22px */


        /* TextColors/DarkGrey */

        color: '#3E4240',
    },
    registrationTextTitle: {
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '1rem !important',
        lineHeight: '1.2em',
        marginTop: '24px',
        marginBottom: '4px',
        /* identical to box height, or 22px */


        /* TextColors/DarkGrey */

        color: '#3E4240 !important',
    },
    registrationTextdescription: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '1rem !important',
        lineHeight: '1.2em',
        marginTop: '4px',
        lineBreak: 'auto',
        /* identical to box height, or 22px */


        /* TextColors/DarkGrey */

        color: '#3E4240 !important',
    },
    pageTitle: {
        marginBottom: "16px!important",
    },
    dialogTitle: {
        color: `${palette.primary.light}!important`,
        fontSize: "26px!important",
        lineHeight: "26px!important",
        letterSpacing: "0.02em",
        marginBottom: "40px!important",
        padding: "0!important",
    },
    bottomButton: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        "& button:nth-child(2)": {
            marginLeft: "24px",
        },
    },
    dialogWrapper: {
        // width: '75%',
        '& .MuiDialog-paper': {
            backgroundColor: '#fff',
            borderRadius: '20px',
            padding: '16px',
            maxWidth: '80%',
            overflow: 'unset',
        }
    }
}));
const InsightsInformatinsModal = (props) => {
    const classes = useStyles();
    const theme = useTheme();

    const {
        visibleInightInformation,
        setVisibleInsightInformation
    } = props
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            onClose={() => setVisibleInsightInformation(false)}
            open={visibleInightInformation}
            className={classes.dialogWrapper}
            fullWidth
            fullScreen={fullScreen}
        >
            <DialogTitle className={classes.dialogTitle} sx={{ fontWeight: 700 }}>

                <IconButton
                    sx={{ position: "absolute", top: 0, right: 0 }}
                    // onClick={handleCloseStartModal}
                    onClick={() => {
                        setVisibleInsightInformation(false)
                    }}
                >
                    <ModalCloseIcon />
                </IconButton>
            </DialogTitle>
            <Grid container direction="row" alignItems="flex-start" justifyContent={"space-between"} overflow={"auto"}>
                <Grid item xs={12} md={5.9} sx={{  padding: '12px', margin: '2px' }}>
                    <p
                        
                        className={classes.registrationTitle}
                    >
                        Regression
                    </p>
                    {regressions.map(it => (
                        <>
                            <p
                                className={classes.registrationTextTitle}
                            >
                                {it.title}
                            </p>
                            <p
                                className={classes.registrationTextdescription}
                                dangerouslySetInnerHTML={{
                                    __html: it.text
                                }}
                            >
                            </p>
                        </>
                    ))}
                </Grid>
                <Grid item xs={12} md={5.9} sx={{  padding: '12px', margin: '2px' }}>
                    <p
                        
                        className={classes.registrationTitle}
                    >
                        Correlation
                    </p>
                    {correlations.map(it => (
                        <>
                            <p
                                className={classes.registrationTextTitle}
                            >
                                {it.title}
                            </p>
                            <p
                             
                                className={classes.registrationTextdescription}
                                dangerouslySetInnerHTML={{
                                    __html: it.text
                                }}
                            >
                            </p>
                        </>
                    ))}
                </Grid>
            </Grid>
            <DialogContent>
            </DialogContent>
        </Dialog>
    )
}
export default InsightsInformatinsModal