import { ReportsAction } from "../../interfaces/reportsModal";


export const setWeekReport = (data: any): ReportsAction => ({
  type: "SET_WEEK_MODE",
  payload: data,
});

export const setReportWeekData = (data): ReportsAction => ({
  type: "SET_WEEK_TABLE_DATA",
  payload: data,
})
export const setReportWeekDataTotalCount = (data): ReportsAction => ({
  type: "SET_WEEK_TABLE_DATA_TOTAL",
  payload: data,
})

export const setReportWeekDataCurrentPage = (data): ReportsAction => ({
  type: "SET_WEEK_TABLE_DATA_CUREENT_PAGE",
  payload: data,
})

export const setSelectedRow = (data) : ReportsAction => ({
  type: "SET_WEEK_TABLE_SELECTED_ROW",
  payload: data,
})

export const setSelectedRowDetails = (data) : ReportsAction => ({
  type: "SET_WEEK_TABLE_SELECTED_ROW_DETAILS",
  payload: data,
})