/*eslint @typescript-eslint/no-unused-vars: "off"*/
import React, { useState, useContext, useEffect, useRef } from "react";
import {
  Box,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Menu,
  MenuItem,
  TextField,
} from "@mui/material";
import clsx from "clsx";

import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import { styled } from "@mui/material/styles";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  makeStyles,

  Radio,
  RadioGroup,
} from "@material-ui/core";

// import palette from "../../theme/palette";
// import ProgressStepper from "./progressStepper";
import ModalCloseIcon from "../../../assets/images/icon/modalCloseIconBlack";
import { ContactsDataJson as contacts, ReviewData } from "./contactsData";
import { ButtonLoader } from "../../../components/buttonLoader";
import palette from "../../../theme/palette";
import AddGroupModal from "./AddGroupModal";
import ThreeDotsIcon from "../../../assets/images/icon/ThreeDotsIcon";
import { ContactsContext } from "../../../context/userIContacts";
import { contactsService } from "../../../services/contactsService";
import { UserTypeContext } from "../../../context/userStatus";
import AddContactModal from "./AddContactModal";
import NewButton from "../../../components/NewButton";
import CommonTypo from "../../../components/CommonTypo";
import ThreeDotsIconVertical from "../../../assets/images/icon/ThreeDotsIconVertical";
import PencilIcon from "../../../assets/images/icon/PencilIcon";
import TrashIcon from "../../../assets/images/icon/TrachIcon";
import FolderOpnenIcon from "../../../assets/images/icon/FolderOpenIcon";
import { ArrowUpNew } from "../../../assets/images/icon/ArrowDownGreenNew";
// import { trackingService } from "../../services/tracking";
// import { UserTypeContext } from "../../context/userStatus";
// import { ButtonLoader } from "../../components/buttonLoader";
// import OutcomeclosePopup from "./OutcomeclosePopup";
// import DeleteIcon from "../../assets/images/icon/DeleteIcon";
// import { addCategoryJourneyAction, addJourneyAction } from "../../store/actions/userTypeAction";
const CustomCheckBox = styled(Checkbox)(({ theme }) => ({
  color: "#DCE6E0",
  borderRadius: "4px",

  "&.Mui-checked": {
    color: "#11734A",
    borderRadius: "4px",
  },
}));
const CustomRadio = styled(Radio)(({ theme }) => ({
  color: "#DCE6E0",
  borderRadius: "4px",

  "&.Mui-checked": {
    color: "#11734A",
    borderRadius: "4px",
  },
}));
const MenuSx = {
  "& .MuiPaper-root": {
    background: "var(--white-background)",
    borderRadius: "12px",
    border: "1px solid var(--color-border)",
    boxShadow: "0px 4px 8px 0px #0000001A",
    "& .MuiList-root": {
      background: "var(--white-background)",
      borderRadius: "12px",
      "& .MuiMenuItem-root": {
        "color": "#3e4240 !important",
        backgroundColor: "transparent !important",
        padding: "12px !important",
      }
    }
  }

}
const useStyles = makeStyles((theme) => ({
  contactButton: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "16px !important",
    gap: "10px",

    height: "40px",

    /* BackgroundColors/PureWhite */

    background: "var(--color-border) !important",
    /* BackgroundColors/GhostButtonBorder */
    border: "none !important",
    borderRadius: "20px !important",
    color: "#3E4240 !important",
    fontWeight: 400,
    fontSize: "16px!important",
    lineHeight: "100%!important",
  },
  groupDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "12px",
    border: "1px solid var(--color-border)",
    height: "50px",
    padding: "16px",
    cursor: "pointer",
    alignSelf: "stretch"
  },
  namegroupDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    alignSelf: "stretch",
    padding: "16px",
    borderBottom: "1px solid var(--color-border)"
  },
  groupDivNameText: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "18px",
    /* identical to box height, or 16px */

    display: "flex",
    alignItems: "center",

    /* TextColors/DarkGrey */

    color: "var(--color-text-primary)",
  },
  groupDivEmailText: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "18px",
    /* identical to box height, or 16px */

    display: "flex",
    alignItems: "center",

    /* TextColors/DarkGrey */

    color: "var(--color-text-secondary)",
  },
  groupDivText: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "18px",
    /* identical to box height, or 16px */

    display: "flex",
    alignItems: "center",

    /* PrimaryColors/PineGreen */

    color: "var(--color-text-secondary)",
  },
  selectedTaxtColor: {
    color: "var(--color-text-primary)",
    fontWeight: 600,

  },
  pageTitle: {
    marginBottom: "16px!important",
  },
  raioOptionInputs: {
    height: "48px",

    /* BackgroundColors/GreenyAreaBG */

    /* BackgroundColors/Dividers */

    border: "1px solid #DCE6E0",
    borderRadius: "8px",
    padding: "0 4px",
    "& .MuiOutlinedInput-root": {
      margin: "auto",
      "& input": {
        padding: "2px !important",
      },
    },
  },
  bottomButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "& button:nth-child(2)": {
      marginLeft: "24px",
    },
  },
  nameEmailDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "4px",
    width: "100%"
  },
}));
interface ManageContact {
  visibleManageContactModal: boolean;
  setVisibleManageContactModal: (val: boolean) => void;
  showLoadingBtn: boolean;
  setShowLoadingBtn: (val: boolean) => void;
  setShowSnakbar: (val: boolean) => void;
  setMsgType: (val: string) => void;
  setSnakbarMsg: (val: string) => void;
  getAllContacts: () => void;
}

const ManageContactModal = (props: ManageContact) => {
  const classes = useStyles();
  const [visibleAddGroupModal, setVisibleAddGroupModal] = useState(false);
  const { state: IContactTypeState, dispatch: contactsDispatch } =
    useContext(ContactsContext);
  const { state: IUserTypeState, dispatch: userTypeDispatch } =
    useContext(UserTypeContext);
  const [visibleAddContactModal, setVisibleAddContactModal] = useState(false);
  const [isAddInGroup, setIsAddInGroup] = useState(false);
  const [radioValue, setRadioValue] = useState("immediatly");
  const [updateGroupInfo, setUpdateGroupInfo] = useState(null);
  const [selectedAnchor, setSelectedAnchor] = useState(null);
  // const { state: outcomeState, dispatch: UserTypeDispatch } = useContext(UserTypeContext);
  const [openStartModal, setStartModal] = useState(true);
  const { showLoadingBtn, setShowLoadingBtn } = props;
  const [outcomeNotes, setOutcomeNotes] = useState<any>("");
  const [item, setItem] = useState<any>();
  const [editData, setEditData] = useState<any>();
  const [openOutcomeclosePopup, setopenOutcomeclosePopup] = useState(false);
  const [openOutcomeDeletePopup, setopenOutcomeDeletePopup] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState("all");
  const [editRecord, setEditRecord] = useState(null);
  const [moveToRecord, setMoveToRecord] = useState(null);
  const [editRecordGroup, setEditRecordGroup] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    category?: any,
    all = false
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedAnchor(all ? "all" : category._id);
    setUpdateGroupInfo(all ? null : category);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [anchorElName, setAnchorElName] = React.useState<null | HTMLElement>(
    null
  );
  const textFieldRef = useRef(null);
  const openName = Boolean(anchorElName);
  const [contacts, setContacts] = useState([]);
  const handleClickName = (
    event: React.MouseEvent<HTMLElement>,
    category: any
  ) => {
    setAnchorElName(event.currentTarget);
    setEditData(category);
  };
  const handleCloseName = (e) => {
    setAnchorElName(null);
  };

  const [anchorElMoveTo, setAnchorElMoveTo] = React.useState<null | HTMLElement>(
    null
  );
  const openMoveTo = Boolean(anchorElMoveTo);

  const handleClickMoveTo = (
    event: React.MouseEvent<HTMLElement>,
  ) => {
    setAnchorElMoveTo(event.currentTarget);
    // set groups
  };
  const handleCloseMoveTo = () => {
    setAnchorElMoveTo(null);
  };
  // useEffect(() => {
  //   console.log("textFieldRef.current editRecord", editRecord);
    
  //   if (editRecord && editRecord._id) {
  //     textFieldRef.current.focus();
  //     const timer = setTimeout(() => {
  //       if (textFieldRef.current) {
  //         console.log("textFieldRef.current", textFieldRef.current);
  //         textFieldRef.current.focus();
  //       }
  //     }, 10);
  //     return () => clearTimeout(timer);
  //   }
  // }, [editRecord]);
  const { setShowSnakbar, setMsgType, setSnakbarMsg } = props;
  const handleAddGroup = (values, update) => {
    setShowLoadingBtn(true);
    const foundObject = [...IContactTypeState.groupContacts].find(
      (it) => it.groupName === values.groupName
    );

    if (foundObject && foundObject._id !== values.id) {
      setShowLoadingBtn(false);
      setShowSnakbar(true);
      setMsgType("error");
      setSnakbarMsg("Group is already added");
      return;
    }
    contactsService[update ? "updateGroup" : "createUpdateGroup"](
      values,
      IUserTypeState.token
    )
      .then((data) => {
        setVisibleAddGroupModal(false);
        setVisibleAddContactModal(false);
        props.getAllContacts();
        setEditData({});
        setUpdateGroupInfo({});
        setShowSnakbar(true);
        setMsgType("success");
        setSnakbarMsg(data?.description);
        let filteredContacts = [];
        if (selectedGroup === "all") {
          filteredContacts = [...IContactTypeState.allContacts];
        } else {
          filteredContacts = [...IContactTypeState.groupContacts].find(
            (it) => it._id === selectedGroup
          )?.contacts;
        }
        setContacts(filteredContacts);
      })
      .catch((err) => {
        if (err?.description) {
          setShowSnakbar(true);
          setMsgType("error");
          setSnakbarMsg(err?.description);
        }
      })
      .finally(() => {
        setShowLoadingBtn(false);
      });
  };
  const handleAddContact = (values, update) => {
    setShowLoadingBtn(true);
    if (isAddInGroup) {
      const foundObject = [...updateGroupInfo?.contacts].find(
        (it) => it.email === values.email
      );
      if (foundObject && foundObject._id !== values.id) {
        setShowLoadingBtn(false);
        setShowSnakbar(true);
        setMsgType("error");
        setSnakbarMsg("Contact is already added");
        return;
      }
      const objToUpdate = { ...updateGroupInfo };
      objToUpdate.id = objToUpdate?._id;
      objToUpdate.contacts = objToUpdate?.contacts || [];
      objToUpdate.contacts.push(values);
      contactsService
        .updateGroup(objToUpdate, IUserTypeState.token)
        .then((data) => {
          setVisibleAddContactModal(false);
          setIsAddInGroup(false);
          setEditData({});
          setUpdateGroupInfo({});
          props.getAllContacts();
          setShowSnakbar(true);
          setMsgType("success");
          setSnakbarMsg(data?.description);
        })
        .catch((err) => {
          if (err?.description) {
            setShowSnakbar(true);
            setMsgType("error");
            setSnakbarMsg(err?.description);
          }
        })
        .finally(() => {
          setShowLoadingBtn(false);
        });
    } else {
      if (!selectedGroup || selectedGroup === "all") {
        const foundObject = [...IContactTypeState?.allContacts].find(
          (it) => it.email === values.email
        );
        if (foundObject && foundObject._id !== values.id) {
          setShowLoadingBtn(false);
          setShowSnakbar(true);
          setMsgType("error");
          setSnakbarMsg("Contact is already added");
          return;
        }
        contactsService[update ? "updateContact" : "createUpdateContact"](
          values,
          IUserTypeState.token
        )
          .then((data) => {
            setVisibleAddContactModal(false);
            props.getAllContacts();
            setShowSnakbar(true);
            setEditData({});
            setUpdateGroupInfo({});
            setMsgType("success");
            setSnakbarMsg(data?.description);
          })
          .catch((err) => {
            if (err?.description) {
              setShowSnakbar(true);
              setMsgType("error");
              setSnakbarMsg(err?.description);
            }
          })
          .finally(() => {
            setShowLoadingBtn(false);
          });
      } else {
        const foundGroup = [...IContactTypeState.groupContacts].find(
          (it) => it._id === selectedGroup
        );
        if (values.id) {
          const foundObject = [...foundGroup.contacts].find(
            (it) => it.email === values.email
          );
          if (foundObject && foundObject._id !== values.id) {
            setShowLoadingBtn(false);
            setShowSnakbar(true);
            setMsgType("error");
            setSnakbarMsg("Contact is already added");
            return;
          }
          const foundIndex = foundGroup.contacts.findIndex(
            (it) => it._id === values.id
          );
          if (foundIndex > -1) {
            foundGroup.contacts[foundIndex] = {
              ...foundGroup.contacts[foundIndex],
              ...values,
            };
          } else {
            foundGroup.contacts.push(values);
          }
        } else {
          foundGroup.contacts.push(values);
        }
        handleAddGroup(
          {
            ...foundGroup,
            id: { ...(foundGroup || {}) }?._id,
          },
          { ...(foundGroup || {}) }?._id
        );
      }
    }
  };
  const handleDeleteContact = (values) => {
    if (!selectedGroup || selectedGroup === "all") {
      contactsService
        .deleteContact(values, IUserTypeState.token)
        .then((data) => {
          props.getAllContacts();
          setShowSnakbar(true);
          setEditData({});
          setUpdateGroupInfo({});
          setMsgType("success");
          setSnakbarMsg(data?.description);
        })
        .catch((err) => {
          if (err?.description) {
            setShowSnakbar(true);
            setMsgType("error");
            setSnakbarMsg(err?.description);
          }
        })
        .finally(() => { });
    } else {
      const foundGroup = [...IContactTypeState.groupContacts].find(
        (it) => it._id === selectedGroup
      );
      if (values.id) {
        foundGroup.contacts = foundGroup.contacts.filter(
          (it) => it._id !== values.id
        );
      }
      handleAddGroup(
        {
          ...foundGroup,
          id: { ...(foundGroup || {}) }?._id,
        },
        { ...(foundGroup || {}) }?._id
      );
    }
  };

  const handleMoveCOntact = (groupInfo) => {
    handleAddGroup(
      {
        ...groupInfo,
        contacts: [
          ...groupInfo?.contacts,
          {
            name: editData.name,
            email: editData.email,
          }
        ],
        id: { ...(groupInfo || {}) }?._id,
      },
      { ...(groupInfo || {}) }?._id
    );
    handleCloseName(null)
  }
  const handleDeleteGroup = (values) => {
    contactsService
      .deleteGroup(values, IUserTypeState.token)
      .then((data) => {
        props.getAllContacts();
        setShowSnakbar(true);
        setMsgType("success");
        setSnakbarMsg(data?.description);
      })
      .catch((err) => {
        if (err?.description) {
          setShowSnakbar(true);
          setMsgType("error");
          setSnakbarMsg(err?.description);
        }
      })
      .finally(() => { });
  };
  useEffect(() => {
    let filteredContacts = [];
    if (selectedGroup === "all") {
      filteredContacts = [...IContactTypeState.allContacts];
    } else {
      filteredContacts = [...IContactTypeState.groupContacts].find(
        (it) => it._id === selectedGroup
      )?.contacts;
    }
    setContacts(filteredContacts);
  }, [
    selectedGroup,
    IContactTypeState.allContacts,
    IContactTypeState.groupContacts,
  ]);
  console.log("anchorElName", anchorElName)
  return (
    <Grid>
      <Dialog
        onClose={() => props.setVisibleManageContactModal(false)}
        open={props.visibleManageContactModal}
        className="dialogWrapper dialogWrapper-addContact dialogWrapper-mobileScreen"
        fullWidth
        sx={{
          "& .MuiPaper-root": {
            padding: "0px",
            flexDirection: "row",
            width: "auto",
            maxWidth: "80%",
            minHeight: "450px"
          },
        }}
      >
        <Box
          display={"flex"}
          padding={"32px 24px"}
          height={"inherit"}
          flexDirection={"column"}
          gap={"16px"}
          alignItems={"flex-start"}
          alignSelf={"stretch"}
          justifyContent={"flex-start"}
          sx={{
            width: "360px",
            minWidth: "360px"
          }}>
          <Box display={"flex"} justifyContent={"space-between"} height={"100%"} alignSelf={"stretch"} flexDirection={"column"}>
            <Box display={"flex"} alignSelf={"stretch"} width={"100%"} flexDirection={"column"} gap={"16px"}>
              <Box sx={{
                cursor: "pointer"
              }} onClick={() => props.setVisibleManageContactModal(false)}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.796 18.704C16.0073 18.9154 16.1261 19.202 16.1261 19.5009C16.1261 19.7998 16.0073 20.0864 15.796 20.2978C15.5846 20.5091 15.298 20.6279 14.9991 20.6279C14.7002 20.6279 14.4136 20.5091 14.2022 20.2978L6.70222 12.7978C6.59734 12.6933 6.51413 12.5691 6.45735 12.4323C6.40057 12.2956 6.37134 12.149 6.37134 12.0009C6.37134 11.8528 6.40057 11.7062 6.45735 11.5695C6.51413 11.4327 6.59734 11.3086 6.70222 11.204L14.2022 3.70404C14.4136 3.49269 14.7002 3.37396 14.9991 3.37396C15.298 3.37396 15.5846 3.49269 15.796 3.70404C16.0073 3.91538 16.1261 4.20203 16.1261 4.50091C16.1261 4.7998 16.0073 5.08644 15.796 5.29779L9.09379 12L15.796 18.704Z" fill="black" />
                </svg>

              </Box>
              <CommonTypo size={24} lineHeight={29} color="var(--color-text-primary)" fontWeight={700}>Manage accounts</CommonTypo>
              <div className={classes.groupDiv} style={selectedGroup === "all" ? {
                background: "var(--color-border)"
              } : {}} onClick={() => {
                setSelectedGroup("all")
                setEditRecordGroup(null)
              }}>
                <span

                  className={clsx(
                    classes.groupDivText,
                    selectedGroup === "all" ? classes.selectedTaxtColor : ""
                  )}
                >
                  All
                </span>
                {/* <span
                  onClick={(e) => handleClick(e, null, true)}
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ThreeDotsIconVertical />
                </span> */}
              </div>
              {[...IContactTypeState.groupContacts].map((it) => {
                let isEditMode = false
                if (editRecordGroup && it._id === editRecordGroup._id) {
                  isEditMode = true
                }
                return (
                  <div className={classes.groupDiv} onClick={() => {
                    setSelectedGroup(it._id)
                    setEditRecordGroup(it)
                  }}
                    style={selectedGroup === it._id ? {
                      background: "var(--color-border)"
                    } : {}}>
                    {isEditMode ? <TextField
                      variant="outlined"
                      fullWidth
                      name="groupName"
                      placeholder="Group Name"
                      autoFocus
                      InputProps={{
                        autoFocus: true
                      }}
                      sx={{
                        margin: 0,
                        marginBottom: `0px !important`,
                        height: "auto",
                        "& .MuiOutlinedInput-root": {
                          margin: 0,
                          "& .MuiInputBase-input": {
                            padding: `0px !important`,
                            fontWeight: `600 !important`,
                            fontSize: "14px !important",
                            lineHeight: "18px !important",
                            color: "var(--color-text-primary)",
                            background: "var(--color-border)"
                          }
                        }
                      }}
                      className={classes.raioOptionInputs}
                      onChange={(e) => {
                        setEditRecordGroup({
                          ...editRecordGroup,
                          groupName: e.target.value,
                        })
                      }}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          handleAddGroup(
                            {
                              ...(editRecordGroup || {}),
                              id: { ...(editRecordGroup || {}) }?._id,
                              contacts:
                                { ...(editRecordGroup || {}) }?.contacts || [],
                            },
                            true
                          )
                        }
                      }}
                      error={Boolean(!editRecordGroup.groupName)}
                      helperText={Boolean(!editRecordGroup.groupName) && "Group Name is required"}
                      value={editRecordGroup.groupName}
                    /> : <span
                      className={clsx(
                        classes.groupDivText,
                        selectedGroup === it._id
                          ? classes.selectedTaxtColor
                          : ""
                      )}
                    >
                      {it.groupName}
                    </span>}

                    {/* <span
                      onClick={(e) => handleClick(e, it)}
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ThreeDotsIconVertical />
                    </span> */}
                  </div>
                );
              })}
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                // open={true}
                onClose={handleClose}
                onClick={handleClose}
                // className={classes.dropMenu}
                PaperProps={{
                  elevation: 0,
                  style: {
                    background: "var(--white-background)"
                  }
                }}
                sx={MenuSx}
                transformOrigin={{
                  horizontal: "center",
                  vertical: "top",
                }}
                anchorOrigin={{
                  horizontal: "center",
                  vertical: "bottom",
                }}
              >
                {selectedAnchor === "all"
                  ? [
                    <MenuItem
                      key="add"
                      onClick={() => {
                        setIsAddInGroup(false);

                        setVisibleAddContactModal(true);
                        // setUpdateGroupInfo()
                        // psetShowOutcome(true);
                        // psetStartModal(true);
                        // psetAddOutcomeTracking(true);
                      }}
                    >
                      <Box display={"flex"} alignItems={"center"} gap={"8px"}>
                        <CommonTypo color="var(--color-text-primary)" size={14} lineHeight={16}>
                          Add Contact
                        </CommonTypo>
                      </Box>

                    </MenuItem>,
                  ]
                  : [
                    <MenuItem
                      onClick={() => {
                        setVisibleAddGroupModal(true);
                        // setUpdateGroupInfo()
                        // psetShowOutcome(true);
                        // psetStartModal(true);
                        // psetAddOutcomeTracking(true);
                      }}
                      key="rename"
                    >
                      <Box display={"flex"} alignItems={"center"} gap={"8px"}>
                        <CommonTypo color="var(--color-text-primary)" size={14} lineHeight={16}>
                          Edit
                        </CommonTypo>
                      </Box>

                    </MenuItem>,
                    <MenuItem
                      onClick={() => {
                        setIsAddInGroup(true);
                        setVisibleAddContactModal(true);
                        // setUpdateGroupInfo()
                        // psetShowOutcome(true);
                        // psetStartModal(true);
                        // psetAddOutcomeTracking(true);
                      }}
                      key="add"
                    >
                      <Box display={"flex"} alignItems={"center"} gap={"8px"}>
                        <CommonTypo color="var(--color-text-primary)" size={14} lineHeight={16}>
                          Add Contact
                        </CommonTypo>
                      </Box>
                    </MenuItem>,
                    <MenuItem
                      onClick={() => {
                        handleDeleteGroup({
                          id: updateGroupInfo?._id,
                        });
                        // setUpdateGroupInfo()
                        // psetShowOutcome(true);
                        // psetStartModal(true);
                        // psetAddOutcomeTracking(true);
                      }}
                      key="delete"
                    >
                      <Box display={"flex"} alignItems={"center"} gap={"8px"}>
                        <CommonTypo color="var(--color-text-primary)" size={14} lineHeight={16}>
                          Delete Group
                        </CommonTypo>
                      </Box>

                    </MenuItem>,
                  ]}
              </Menu>
            </Box>
            <Button
              variant="outlined"
              className={classes.contactButton}
              onClick={() => {
                setSelectedAnchor("all");
                setUpdateGroupInfo(null);
                // setVisibleAddGroupModal(true);
                handleAddGroup(
                  {
                    groupName: "Group " + ([...IContactTypeState.groupContacts].length + 1),
                    contacts: []
                  },
                  false
                )
              }}
            >
              Add Group
            </Button>
          </Box>
        </Box>
        <Box display={"flex"}
          height={"100%"}
          minHeight={"inherit"}
          flexDirection={"column"}
          gap={"16px"}
          alignItems={"flex-start"}
          alignSelf={"stretch"}
          padding={"32px 24px"}
          borderLeft={"1px solid var(--color-border)"}
          justifyContent={"space-between"}
          sx={{
            width: "660px",
            height: "100%"
          }}>
          <Box display={"flex"}
            height={"100%"}
            minHeight={"inherit"}
            flexDirection={"column"}
            gap={"16px"}
            alignItems={"flex-start"}
            alignSelf={"stretch"}
            padding={"0px"}
            justifyContent={"flex-start"}
            sx={{
              width: "100%",
              height: "100%"
            }}>
            {[...(contacts || [])].map((it) => {
              let isEditMode = false
              if (editRecord && editRecord._id === it._id) {
                isEditMode = true
              }
              return (
                <div className={classes.namegroupDiv} key={it._id}>
                  <div className={classes.nameEmailDiv} >
                    {isEditMode ? <TextField
                      variant="outlined"
                      fullWidth
                      inputRef={(input) => {
                        if (isEditMode && input) {
                          setTimeout(() => {
                            input.focus();
                          }, 20);
                        }
                      }}
                      autoFocus
                      name="name"
                      placeholder="Name"
                      sx={{
                        margin: `0px !important`,
                        marginBottom: `0px !important`,
                        height: "auto !important",
                        border: "none !important",
                        "& .MuiOutlinedInput-root": {
                          margin: `0px !important`,
                          "& .MuiInputBase-input": {
                            padding: `0px !important`,
                            fontWeight: `400 !important`,
                            fontSize: "14px !important",
                            lineHeight: "18px !important",
                            color: "var(--color-text-primary)",
                          }
                        }
                      }}
                      className={classes.raioOptionInputs}
                      onChange={(e) => {
                        setEditRecord({
                          ...editRecord,
                          name: e.target.value,
                        })
                      }}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          const tempObj = {
                            ...editRecord,
                            id: editRecord._id
                          }
                          delete tempObj.uid
                          handleAddContact(tempObj, true)
                          setEditRecord(null)
                        }
                      }}
                      error={Boolean(!editRecord.name)}
                      helperText={Boolean(!editRecord.name) && "Name is required"}
                      value={editRecord.name}
                    /> : <span className={clsx(classes.groupDivNameText)}>
                      {it.name}
                    </span>}
                    {isEditMode ? <TextField
                      variant="outlined"
                      fullWidth
                      name="email"
                      placeholder="Email"
                      sx={{
                        margin: `0px !important`,
                        marginBottom: `0px !important`,
                        height: "auto !important",
                        border: "none !important",
                        "& .MuiOutlinedInput-root": {
                          margin: `0px !important`,
                          "& .MuiInputBase-input": {
                            padding: `0px !important`,
                            fontWeight: `400 !important`,
                            fontSize: "14px !important",
                            lineHeight: "18px !important",
                            color: "var(--color-text-primary)",
                          }
                        }
                      }}
                      className={classes.raioOptionInputs}
                      onChange={(e) => {
                        setEditRecord({
                          ...editRecord,
                          email: e.target.value,
                        })
                      }}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) {
                          const tempObj = {
                            ...editRecord,
                            id: editRecord._id
                          }
                          delete tempObj.uid
                          handleAddContact(tempObj, true)
                          setEditRecord(null)
                        }
                      }}
                      error={Boolean(!editRecord.email)}
                      helperText={Boolean(!editRecord.email) && "Email is required"}
                      value={editRecord.email}
                    /> : <span className={clsx(classes.groupDivEmailText)}>
                      {it.email}
                    </span>}

                  </div>

                  {!isEditMode && <span
                    onClick={(e) => handleClickName(e, it)}
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <ThreeDotsIconVertical />
                  </span>}
                </div>
              );
            })}
            <Menu
              anchorEl={anchorElName}
              id="account-menu"
              open={openName}
              onClose={handleCloseName}
              onChange={(e) => {
              }}
              // onClick={(e) => handleCloseName(e)}
              // className={classes.dropMenu}
              PaperProps={{
                elevation: 0,
                sx: {
                  minWidth: "168px"
                }
              }}
              transformOrigin={{
                horizontal: "right",
                vertical: "bottom",
              }}
              sx={MenuSx}
              anchorOrigin={{
                horizontal: "left",
                vertical: "center",
              }}
            >
              <MenuItem
                onClick={(e) => {
                  setEditRecord(editData)
                  handleCloseName(e)
                  // setVisibleAddContactModal(true);
                  // psetShowOutcome(true);
                  // psetStartModal(true);
                  // psetAddOutcomeTracking(true);
                }}
                sx={{
                  width: "100%"
                }}
              >
                <Box display={"flex"} alignItems={"center"} gap={"8px"}>
                  <PencilIcon size={20} color="var(--color-text-primary)" />
                  <CommonTypo size={12} lineHeight={12} color="var(--color-text-primary)">Edit</CommonTypo>
                </Box>
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  setMoveToRecord(editData)
                  handleClickMoveTo(e)
                  // setVisibleAddContactModal(true);
                  // psetShowOutcome(true);
                  // psetStartModal(true);
                  // psetAddOutcomeTracking(true);
                }}
                sx={{
                  width: "100%"
                }}
              >
                <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} gap={"8px"} width={"100%"} alignSelf={"stretch"}>
                  <Box display={"flex"} alignItems={"center"} gap={"8px"}>
                    <FolderOpnenIcon size={20} color="var(--color-text-primary)" />
                    <CommonTypo size={12} lineHeight={12} color="var(--color-text-primary)">Move to...</CommonTypo>
                  </Box>
                  <Box sx={{
                    transform: "rotate(-90deg)"
                  }}>
                    <ArrowUpNew width="20" height="20" color="var(--color-text-primary)" />
                  </Box>
                </Box>
              </MenuItem>
              <MenuItem
                onClick={(e) => {
                  handleDeleteContact({
                    id: editData?._id,
                  });
                  handleCloseName(e)
                  // psetShowOutcome(true);
                  // psetStartModal(true);
                  // psetAddOutcomeTracking(true);
                }}
              >
                <Box display={"flex"} alignItems={"center"} gap={"8px"}>
                  <TrashIcon size={20} color="var(--color-text-primary)" />
                  <CommonTypo size={12} lineHeight={12} color="var(--color-text-primary)">Delete</CommonTypo>
                </Box>

              </MenuItem>
            </Menu>
            <Menu
              anchorEl={anchorElMoveTo}
              id="account-menu-2"
              open={openMoveTo}
              onClose={handleCloseMoveTo}
              onClick={handleCloseMoveTo}
              // onClick={(e) => handleCloseName(e)}
              // className={classes.dropMenu}
              PaperProps={{
                elevation: 0,
                sx: {
                  minWidth: "168px"
                }
              }}
              transformOrigin={{
                horizontal: "left",
                vertical: "center",
              }}
              sx={MenuSx}
              anchorOrigin={{
                horizontal: "right",
                vertical: "bottom",
              }}
            >
              {[...IContactTypeState.groupContacts].map((it) => {
                return <MenuItem
                  onClick={(e) => {
                    handleMoveCOntact(it)
                    // setEditRecord(editData)
                    // handleCloseName(e)
                    // setVisibleAddContactModal(true);
                    // psetShowOutcome(true);
                    // psetStartModal(true);
                    // psetAddOutcomeTracking(true);
                  }}
                  sx={{
                    width: "100%"
                  }}
                >
                  <Box display={"flex"} alignItems={"center"} gap={"8px"}>
                    <CommonTypo size={12} lineHeight={12} color="var(--color-text-primary)">{it.groupName}</CommonTypo>
                  </Box>
                </MenuItem>
              })}

            </Menu>
          </Box>

          {(editRecord || editRecordGroup) && (
            <Box display={"flex"} alignItems={"center"} gap={"8px"}>
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.70406 13.5459C6.59918 13.4414 6.51597 13.3172 6.45918 13.1805C6.4024 13.0437 6.37318 12.8971 6.37318 12.7491C6.37318 12.601 6.4024 12.4544 6.45918 12.3176C6.51597 12.1809 6.59918 12.0567 6.70406 11.9522L8.95406 9.70219C9.16541 9.49084 9.45205 9.37211 9.75094 9.37211C10.0498 9.37211 10.3365 9.49084 10.5478 9.70219C10.7592 9.91353 10.8779 10.2002 10.8779 10.4991C10.8779 10.7979 10.7592 11.0846 10.5478 11.2959L10.2188 11.625H15.375V9.75C15.375 9.45163 15.4935 9.16548 15.7045 8.9545C15.9155 8.74353 16.2016 8.625 16.5 8.625C16.7984 8.625 17.0845 8.74353 17.2955 8.9545C17.5065 9.16548 17.625 9.45163 17.625 9.75V12.75C17.625 13.0484 17.5065 13.3345 17.2955 13.5455C17.0845 13.7565 16.7984 13.875 16.5 13.875H10.2188L10.5487 14.2041C10.7601 14.4154 10.8788 14.7021 10.8788 15.0009C10.8788 15.2998 10.7601 15.5865 10.5487 15.7978C10.3374 16.0092 10.0508 16.1279 9.75187 16.1279C9.45299 16.1279 9.16634 16.0092 8.955 15.7978L6.70406 13.5459ZM22.125 5.25V18.75C22.125 19.2473 21.9275 19.7242 21.5758 20.0758C21.2242 20.4275 20.7473 20.625 20.25 20.625H3.75C3.25272 20.625 2.77581 20.4275 2.42417 20.0758C2.07254 19.7242 1.875 19.2473 1.875 18.75V5.25C1.875 4.75272 2.07254 4.27581 2.42417 3.92417C2.77581 3.57254 3.25272 3.375 3.75 3.375H20.25C20.7473 3.375 21.2242 3.57254 21.5758 3.92417C21.9275 4.27581 22.125 4.75272 22.125 5.25ZM19.875 5.625H4.125V18.375H19.875V5.625Z" fill="#656565" />
              </svg>
              <CommonTypo size={16} lineHeight={19} color="var(--color-text-secondary)">
                Enter to save the changes
              </CommonTypo>
            </Box>
          )}
        </Box>
        {/* <DialogContent>
          <Grid container>
            <Grid item xs={4}>
              <Typography variant="h6" component="h6">
                Contact Groups
              </Typography>
              <div className={classes.groupDiv}>
                <span
                  onClick={() => setSelectedGroup("all")}
                  className={clsx(
                    classes.groupDivText,
                    selectedGroup === "all" ? classes.selectedTaxtColor : ""
                  )}
                >
                  {"All "}{" "}
                  {IContactTypeState.allContacts
                    ? "(" + IContactTypeState.allContacts.length + ")"
                    : null}
                </span>
                <span
                  onClick={(e) => handleClick(e, null, true)}
                  style={{
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ThreeDotsIcon />
                </span>
              </div>
              {[...IContactTypeState.groupContacts].map((it) => {
                return (
                  <div className={classes.groupDiv}>
                    <span
                      onClick={() => setSelectedGroup(it._id)}
                      className={clsx(
                        classes.groupDivText,
                        selectedGroup === it._id
                          ? classes.selectedTaxtColor
                          : ""
                      )}
                    >
                      {it.groupName}{" "}
                      {it.contacts ? "(" + it.contacts.length + ")" : null}
                    </span>
                    <span
                      onClick={(e) => handleClick(e, it)}
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ThreeDotsIcon />
                    </span>
                  </div>
                );
              })}
              
              
            </Grid>
            <Divider
              orientation="vertical"
              flexItem
              style={{
                margin: "0 30px",
              }}
            />

            <Grid item xs={6}>
              <Typography variant="h6" component="h6">
                Name
              </Typography>
              {[...(contacts || [])].map((it) => {
                return (
                  <div className={classes.namegroupDiv}>
                    <div className={classes.nameEmailDiv}>
                      <span className={clsx(classes.groupDivNameText)}>
                        {it.name}
                      </span>
                      <span className={clsx(classes.groupDivEmailText)}>
                        {it.email}
                      </span>
                    </div>

                    <span
                      onClick={(e) => handleClickName(e, it)}
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ThreeDotsIcon />
                    </span>
                  </div>
                );
              })}
              
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box className={classes.bottomButton}>
            <NewButton
              buttonText="Cancel"
              variant="outlined"
              onClick={() => props.setVisibleManageContactModal(false)}
            />
            <NewButton
              buttonText="Save"
              isLoadong={showLoadingBtn}
              variant="contained"
              type="submit"
              onClick={() => props.setVisibleManageContactModal(false)}
            />
          </Box>
        </DialogActions> */}

        {visibleAddGroupModal && (
          <AddGroupModal
            visibleAddGroupModal={visibleAddGroupModal}
            setVisibleAddGroupModal={(val) => {
              setVisibleAddGroupModal(val);
              setUpdateGroupInfo({});
            }}
            handleAddGroup={handleAddGroup}
            showLoadingBtn={props.showLoadingBtn}
            setShowLoadingBtn={props.setShowLoadingBtn}
            updateGroupInfo={updateGroupInfo}
          />
        )}
        {visibleAddContactModal && (
          <AddContactModal
            visibleAddContactModal={visibleAddContactModal}
            setVisibleAddContactModal={(val) => {
              setVisibleAddContactModal(val);
              setEditData({});
            }}
            handleAddContact={handleAddContact}
            showLoadingBtn={showLoadingBtn}
            setShowLoadingBtn={setShowLoadingBtn}
            editData={editData}
            handleAddGroup={handleAddGroup}
            updateGroupInfo={updateGroupInfo}
            selectedAnchor={selectedAnchor}
            isAddInGroup={isAddInGroup}
          />
        )}
      </Dialog>
    </Grid>
  );
};
export default ManageContactModal;
