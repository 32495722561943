import * as React from "react";
import { makeStyles } from "@material-ui/core";
import palette from "../../theme/palette";

const useStyles = makeStyles((theme) => ({
  stepperInput: {
    width: "144px",
    // height: "76px",
    padding: "15px 8px",
    "@media  (max-width: 786px) and (min-width: 100px)": {
      width: "90%",
    },
  },
  stepperField: {
    border: 9,
    width: "100%",
    padding: "5px",
    outlineColor: "blue"
  },
  stepperText: {
    fontSize: "16px",
    lineHeight: "18px",
    fontWeight: 400,
    color: palette.primary.light,
    padding: "0",
    margin: 0,
    width: '98px',
    wordWrap: "break-word",
    // height: "74px",
    "@media  (max-width: 786px) and (min-width: 100px)": {
      width: "80%",
    },
  },
}));

interface EditOutcomeStatusModal {
  showInput: boolean;
  index: number;
  textInput: any;
  onValueChange: (e: any) => void;
  editStatus: number;
  isMobile?: boolean
  absenceToggle?: boolean
}

const EditOutcomeStatusNew = (props: EditOutcomeStatusModal) => {
  const classes = useStyles();
  return (
    <>
      {props.showInput && props.editStatus === props.index ? (
        <div className={classes.stepperInput + "hide-scrollbar"}>
          <textarea
            autoFocus
            name={props.absenceToggle ? (props.index - 1 + "") : props.index + ""}
            // type="text"
            rows={props?.isMobile ? 2 : 4}
            className={classes.stepperField}
            value={props.textInput[props?.index]?.text}
            onChange={(e) => props.onValueChange(e)}
          />
        </div>
      ) : (
        <p className={classes.stepperText}>
          {props.textInput[props?.index]?.text}
        </p>
      )}
    </>
  );
};
export default EditOutcomeStatusNew;
