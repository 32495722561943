import { makeStyles } from "@material-ui/core";
import palette from "../../theme/palette";
import { pxToRem } from "../../theme/typography";

const insightsProgressStyles = makeStyles((theme) => ({
  trackBodyGrid: {
    display: "flex",
    backgroundColor: "white",
    zIndex: 1,
    flexShrink: 0,
    height: "100vh",
    overflow: "auto",
  },
  tabClasses: {
    padding: "6px 12px",
    borderRadius: "20px",
    display: "flex",
    gap: "8px",
    alignItems: "center",
    cursor: "pointer"
  },
  tabBox: {
    padding: "4px",
    borderRadius: "20px",
    background: "#EEEEEE",
    width: "max-content",
    display: "flex",
    alignItems: "center"
  },
  calendarClass: {
    // "& .MuiCalendarOrClockPicker-root": {
    //   "& div": {
    //     width: "100%"
    //   }
    // },
    "& .MuiPickerStaticWrapper-content": {
      width: "280px", // Override width of the calendar picker
      minWidth: "280px", // Override min-width of the calendar picker
      "& .MuiCalendarOrClockPicker-root": {
        width: "280px", // Override width of the calendar picker
        minWidth: "280px", // Override min-width of the calendar picker
        "& > div": {
          width: "280px", // Override the width of the first div inside the root
          minWidth: "280px", // Ensure the min-width is also overridden
          "& .MuiCalendarPicker-root": {
            width: "280px", // Override the width of the first div inside the root
            minWidth: "280px", // Ensure the min-width is also overridden
          }
        },
        "& .PrivatePickersSlideTransition-root": {
          minHeight: "155px",
          "& .MuiYearPicker-root": {
            "& .PrivatePickersYear-root": {
              "& .PrivatePickersYear-button": {
                backgroundColor: "red"
              },
            },
          },
        }
      },

    },
    "& .MuiDayPicker-weekContainer > .MuiPickersDay-today": {
      borderColor: "var(--color-new-main)"
    },
    "& .MuiPickersFadeTransitionGroup-root": {
      "& .MuiYearPicker-root": {
        "& .PrivatePickersYear-root": {
          "& .PrivatePickersYear-yearButton.Mui-selected": {
            backgroundColor: "var(--color-new-main)",
          },
        },
      },
    },
    "& .MuiPickersCalendarHeader-label": {
      color: "#000",
      fontSize: `${pxToRem(14)} !important`
    },
    "& .MuiPickersCalendarHeader-switchViewButton": {
      display: "none"
    },
    "& .MuiPickersArrowSwitcher-button": {
      height: "20px",
      width: "20px",
      border: "0.5px solid #EEEEEE",
      borderRadius: "4px"
    }, "& .MuiPickersArrowSwitcher-spacer": {
      width: "8px"
    },
    "& .MuiPickersDay-root.Mui-selected": {
      backgroundColor: "var(--color-new-main)",
      "&:hover": {
        willChange: "background-color",
        backgroundColor: "var(--color-new-main)",
      },
      "&:focus": {
        willChange: "background-color",
        backgroundColor: "var(--color-new-main)",
      }
    },
    "& .MuiPickersDay-root, .MuiDayPicker-weekDayLabel": {
      height: "28px",
      width: "28px",
      borderRadius: "8px"
    }
  },
  insightsOutcomes: {
    // marginRight: "30px!important",
  },
  progressInfluence: {
    [theme.breakpoints.down(1001)]: {
      maxWidth: "100%!important",
    },
  },
  outcomeSelectionBox: {
    height: "68px",
    borderRadius: "8px",
    border: "1px solid #EEEEEE",
    padding: "16px",
    cursor: "pointer"
  },
  outcomeSelectionBoxContent: {
    height: "36px",
    gap: "12px",
    display: "flex",
    alignItems: "center"
  },
  positiveNegativeBox: {
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    gap: "32px"
  },
}));

export default insightsProgressStyles;
