import axios from "axios";
import { CategoryCreate, CategoryUpdate, CategoryUpdateBulk } from "../interfaces/categoryModel";
import { baseEndpoints } from "./config";

const headerToken = localStorage.getItem("userDetails");

const createCategory = async (token: string, body: CategoryCreate) => {
  const { data } = await axios.post(`${baseEndpoints.category}/create`, body, {
    headers: {
      "x-access-token": token ? token : headerToken,
    },
  });
  return data;
};

const getCategorySuggestion = async (token: string) => {
  const { data } = await axios.post(
    `${baseEndpoints.categorySuggestion}/get`,
    {},
    {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    }
  );
  return data;
};

const getCategories = async (token: string) => {
  const { data } = await axios.post(
    `${baseEndpoints.category}/get`,
    {},
    {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    }
  );
  return data;
};

const updateCategory = async (token: string, body: CategoryUpdate) => {
  const { data } = await axios.post(`${baseEndpoints.category}/update`, body, {
    headers: {
      "x-access-token": token ? token : headerToken,
    },
  });
  return data;
};
const updateCategoryBulk = async (token: string, body: CategoryUpdateBulk) => {
  const { data } = await axios.post(`${baseEndpoints.category}/update-bulk`, body, {
    headers: {
      "x-access-token": token ? token : headerToken,
    },
  });
  return data;
};

const deleteCategory = async (token: string, id: string) => {
  const { data } = await axios.post(
    `${baseEndpoints.category}/delete`,
    { id: id },
    {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    }
  );
  return data;
};

export const categoryService = {
  getCategorySuggestion,
  createCategory,
  getCategories,
  updateCategory,
  updateCategoryBulk,
  deleteCategory,
};
