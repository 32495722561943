import { Box, Divider, Grid, IconButton, Typography } from "@mui/material";
import moment from "moment";
import { makeStyles } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { ReportsTypeContext } from "../../context/userIReports";
import palette from "../../theme/palette";
import { pxToRem } from "../../theme/typography";
import HTMLTooltip from "../../components/HTMLTooltip";
import InfoIcon from "../../assets/images/icon/infoIconYellow";
import Info from "../../assets/images/icon/Info";
import EyeIcon from "../../assets/images/icon/EyeIcon";

const useStyles = makeStyles((theme) => ({
  weeklyReportItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "24px 12px 24px 12px",
    gap: "32px",
    // boxShadow: '0 0 3px #ccc'
    background: "#fff",
    width: "100%",
    margin: "8px 0 !important",
  },
  titleCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    gap: "24px",

    width: "100%",
    // height: "151px",
    justifyContent: "center",
  },
  titleText: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(20),
    color: "var(--color-black)",
    overflow: "hidden",
    display: "-webkit-box",
    lineClamp: 1,
    boxOrient: "vertical",
  },
  influencesCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px 10px 0px 0px",
    gap: "0px",

    overflowY: "auto",
    width: "100%",
    height: "130px",
  },
  title: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: "0px",
    gap: "16px",

    width: "100%",
    // height: "100px",
  },
  avgDetails: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    gap: "16px",

    width: "100%",
    // minHeight: "60px",
  },
  avgDetailsInfo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px",
    gap: "5px",

    width: "100%",
    height: "50px",
  },
  currentAvgCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    gap: "8px",

    width: "50%",
    height: "50px",
  },
  avgdetailsTitle: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: pxToRem(12),
    lineHeight: pxToRem(12),
    color: "var(--color-black)",
  },
  cureentAvgInfo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px",
    gap: "8px",

    // width: '129px',
    height: "30px",
  },
  cureentAvgInfoText: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "30px",
    color: "var(--color-new-main)",
  },
  stepper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px",

    width: "100%",
    height: "13px",
    position: "relative",
  },
  stepperGrid: {
    display: "grid",
    alignItems: "flex-start",
    padding: "0px",
    gridTemplateColumns: "repeat(7, 1fr)",

    width: "100%",
    height: "13px",
    position: "relative",
  },
  dateInfoStep: {
    width: "45px",
    height: "18px",
    position: "relative",
  },
  stepperLine: {
    position: "absolute",
    height: "1px",
    left: "0%",
    right: "0%",
    top: "calc(50% - -4px/2)",

    background: "#CADFD2",
  },
  stepperLineDot: {
    // position: 'absolute',
    width: "18px",
    height: "18px",
    left: "0px",
    top: "calc(50% - 13px/2)",
    borderRadius: "50%",
    zIndex: 1,
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  stepperLineDotText: {
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "12px",

    textAlign: "center",

    color: "#FFFFFF",
  },
  insightsTableRow: {
    display: "flex",
    width: "100%",
  },
  insightsRegression: {
    display: "flex",
    width: "50%",
    verticalAlign: "middle",
    padding: "5px 0",
  },
  insightsRegressionCellTitle: {
    display: "flex",
    alignItems: "center",
    verticalAlign: "middle",
    justifyContent: "flex-end",
    fontWeight: 400,
    fontSize: "10px",

    color: "#9BA19D",
  },
  insightsInfluences: {
    display: "flex",
    width: "50%",
    padding: "4px 0px",
    fontWeight: 500,
    lineHeight: "100%",
    alignItems: "center",
    textAlign: "left",
    color: "#3E4240",
    verticalAlign: "middle",
    fontStyle: "normal",
    fontSize: "10px",
  },
  insightsInfluencesTitle: {
    display: "flex",
    alignItems: "center",
    verticalAlign: "middle",
    justifyContent: "center",
    fontWeight: 400,
    fontSize: "10px",

    color: "#9BA19D",
  },
  insightsCorrelation: {
    // display: 'flex',
    width: "50%",
    verticalAlign: "middle",
    padding: "4px 0",
  },
  insightsCorrelationCell: {
    display: "flex",
    alignItems: "center",
    verticalAlign: "middle",
    justifyContent: "flex-start",
  },
  insightsCorrelationCellTitle: {
    display: "flex",
    alignItems: "center",
    verticalAlign: "middle",
    justifyContent: "flex-start",
    fontWeight: 400,
    fontSize: "16px",

    color: "#9BA19D",
  },
  insightsCorrelationPositive: {
    height: "24px",

    /* GraphicColors/DarkSky */

    background: "var(--color-new-main)",
    borderRadius: "0px 8px 8px 0px",
    marginRight: "20px",
    width: "100%",
  },
  insightsCorrelationNegative: {
    height: "24px",

    /* GraphicColors/DarkSky */

    background: "#F17F79",
    borderRadius: "0px 8px 8px 0px",
    marginRight: "20px",
    width: "100%",
  },
  insightsCorrelationPositiveText: {
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "100%",
    /* identical to box height, or 16px */

    display: "flex",
    alignItems: "center",
    textAlign: "right",

    /* GraphicColors/DarkSky */

    color: "var(--black-color)",
  },
  insightsCorrelationNegativeText: {
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "100%",
    /* identical to box height, or 16px */

    display: "flex",
    alignItems: "center",
    textAlign: "right",

    /* GraphicColors/DarkSky */

    color: "var(--black-color)",
  },
  iconRound: {
    display: "flex",
    // width: '44px',
    // height: '44px',
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "100px",
    background: "#FFF",
    "& path": {
      //      fill: theme.palette.secondary[50],
    },
  },
}));

const ReportDetails = ({ isfromshare = false, andViewOnly = false }) => {
  const [dates, setDates] = useState([]);
  const classes = useStyles();
  const { state: IReportTypeContext, dispatch: reportTypeDispatch } =
    useContext(ReportsTypeContext);
  console.log("IReportTypeContext", IReportTypeContext.selectedRow);

  useEffect(() => {
    if (Object.keys(IReportTypeContext.selectedRow).length) {
      enumerateDaysBetweenDates(
        IReportTypeContext.selectedRow.from,
        IReportTypeContext.selectedRow.till
      );
    } else {
      setDates([]);
    }
    return () => setDates([]);
  }, [IReportTypeContext.selectedRow]);
  // const trackingEvent = () => {
  //     const day = moment(IReportTypeContext.selectedRow.from);

  //     const tempCalendar: any[][] = [];

  //     let startDayTemp = moment(IReportTypeContext.selectedRow.from)?.clone();
  //     let endDayTemp = moment(IReportTypeContext.selectedRow.from)?.clone();

  //     while (day?.isBefore(moment(IReportTypeContext.selectedRow.till), "day")) {
  //         let daysInMonth = startDayTemp
  //             .clone()
  //             .endOf("month")
  //             .diff(endDayTemp.clone(), "days");

  //         tempCalendar.push(
  //             Array(daysInMonth + 1)
  //                 .fill(0)
  //                 .map(() => {
  //                     return day.add(1, "day").clone();
  //                 })
  //         );

  //         startDayTemp = day.clone().add(1, "day");
  //         endDayTemp = moment(IReportTypeContext.selectedRow.from).clone();
  //     }

  //     setDates(tempCalendar);
  // };
  const enumerateDaysBetweenDates = (startDate, endDate) => {
    var dates = [];

    var currDate = moment(startDate).subtract(1, "day").startOf("day");
    var lastDate = moment(endDate).endOf("day");

    while (currDate.add(1, "days").diff(lastDate) < 0) {
      dates.push(currDate.clone().toDate());
    }
    setDates(dates);
  };
  const renderIcon = (icon, onClick = () => { }) => (
    <div className={classes.iconRound} onClick={onClick}>
      {icon}
    </div>
  );
  console.log("dates", dates);

  return (
    <>
      {isfromshare ? (
        <>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={"8px"}
          >
            <Typography
              letterSpacing={"0.02em"}
              sx={{
                color: palette.primary.light,
                // fontSize: `${pxToRem(10)} !important`,
                textAlign: "center",
                fontSize: `${pxToRem(20)} !important`,
                lineHeight: "120% !important",
                // textAlign: "center !important",
                fontWeight: `700 !important`,
                "&.MuiTypography-root": {
                  "@media  (max-width: 600px) and (min-width: 100px)": {
                    fontSize: `${pxToRem(20)} !important`,
                    lineHeight: "120% !important",
                  },
                },
              }}
            >
              Snapshot of your report
            </Typography>
            <IconButton>
              <HTMLTooltip
                customIcon={renderIcon(<Info />, () => { })}
                title="This preview offers a partial view of your report. Not all metrics chosen for sharing are included in this preview."
                fontProps={{
                  fontSize: `${pxToRem(12)} !important`,
                }}
                sx={{
                  "& .MuiTooltip-tooltip": {
                    maxWidth: "300px",
                    background: '#E9F8E0',
                    borderColor: "#DEF6CF",

                  },
                }}
              />
            </IconButton>
          </Box>
          {IReportTypeContext.selectedRowDetails?.outcome &&
            IReportTypeContext.selectedRowDetails?.outcome.length > 0 && (
              <>
                <Typography
                  component="span"
                  sx={{
                    color: palette.primary.light,
                    // fontSize: `${pxToRem(10)} !important`,
                    textAlign: "center",
                    fontSize: `${pxToRem(19)} !important`,
                    lineHeight: "120% !important",
                    // textAlign: "center !important",
                    fontWeight: `700 !important`,
                    "&.MuiTypography-root": {
                      "@media  (max-width: 600px) and (min-width: 100px)": {
                        fontSize: `${pxToRem(19)} !important`,
                        lineHeight: "120% !important",
                      },
                    },
                  }}
                >
                  Outcomes
                </Typography>
                <Grid
                  container
                  sx={{
                    gap: "0 16px",
                    margin: "24px 0",
                  }}
                  spacing={2} /* justifyContent={'space-between'} */
                >
                  {IReportTypeContext.selectedRowDetails?.outcome &&
                    IReportTypeContext.selectedRowDetails?.outcome.map((it) => {
                      return (
                        <Carditem
                          isOutCome={true}
                          key={it.outcomeId}
                          item={it}
                          dates={dates}
                          isfromshare={isfromshare}
                          andViewOnly={andViewOnly}
                        />
                      );
                    })}
                </Grid>
              </>
            )}

          {IReportTypeContext.selectedRowDetails?.influence &&
            IReportTypeContext.selectedRowDetails?.influence.length > 0 && (
              <>
                <Typography component="span" sx={{
                  color: palette.primary.light,
                  // fontSize: `${pxToRem(10)} !important`,
                  textAlign: "center",
                  fontSize: `${pxToRem(19)} !important`,
                  lineHeight: "120% !important",
                  // textAlign: "center !important",
                  fontWeight: `700 !important`,
                  "&.MuiTypography-root": {
                    "@media  (max-width: 600px) and (min-width: 100px)": {
                      fontSize: `${pxToRem(19)} !important`,
                      lineHeight: "120% !important",
                    },
                  },
                }}>
                  Influences
                </Typography>
                <Grid
                  container
                  sx={{
                    gap: "0 16px",
                    margin: "24px 0",
                  }}
                  spacing={2} /* justifyContent={'space-between'} */
                >
                  {IReportTypeContext.selectedRowDetails?.influence &&
                    IReportTypeContext.selectedRowDetails?.influence.map(
                      (it) => {
                        return (
                          <Carditem
                            isOutCome={false}
                            key={it.influenceId}
                            item={it}
                            dates={dates}
                            isfromshare={isfromshare}
                            andViewOnly={andViewOnly}
                          />
                        );
                      }
                    )}
                </Grid>
              </>
            )}
        </>
      ) : (
        <>
          <Typography variant="h3" letterSpacing={"0.02em"}>
            Outcomes
          </Typography>
          <Grid
            container
            sx={{ gap: "0 16px", margin: "24px 0" }}
            spacing={2} /* justifyContent={'space-between'} */
          >
            {IReportTypeContext.selectedRowDetails?.outcome &&
              IReportTypeContext.selectedRowDetails?.outcome.map((it) => {
                return (
                  <Carditem
                    isOutCome={true}
                    key={it.outcomeId}
                    item={it}
                    dates={dates}
                    andViewOnly={andViewOnly}

                  />
                );
              })}
          </Grid>

          <Typography variant="h3" letterSpacing={"0.02em"}>
            Influences
          </Typography>
          <Grid
            container
            sx={{ gap: "0 16px", margin: "24px 0" }}
            spacing={2} /* justifyContent={'space-between'} */
          >
            {IReportTypeContext.selectedRowDetails?.influence &&
              IReportTypeContext.selectedRowDetails?.influence.map((it) => {
                return (
                  <Carditem
                    isOutCome={false}
                    key={it.influenceId}
                    item={it}
                    dates={dates}
                    andViewOnly={andViewOnly}

                  />
                );
              })}
          </Grid>
        </>
      )}
    </>
  );
};

export default ReportDetails;

export const Carditem = (props) => {
  const { isOutCome = false, item, dates = [], isfromshare = false } = props;
  const classes = useStyles();
  console.log("dates", dates);
  const [isShowMore, setShowMore] = useState(false)

  const nearestValueOutcome = (val, influenceItem) => {
    let arr = [];
    if (
      influenceItem.outcomeId &&
      influenceItem?.options?.length &&
      influenceItem?.options[val - 1]
    ) {
      return influenceItem.options[val - 1].colorCode;
    }
    if (influenceItem?.options && influenceItem?.options.length) {
      arr = [...influenceItem?.options].map((it) => it.value);
    }
    const returnVal =
      arr.reduce(
        (p, n) => (Math.abs(p) > Math.abs(n - val) ? n - val : p),
        Infinity
      ) + val;
    if (influenceItem?.options && influenceItem?.options.length) {
      const foundIndexOfValue = influenceItem?.options.findIndex(
        (it) => it.value === returnVal
      );
      if (foundIndexOfValue > -1) {
        return influenceItem?.options[foundIndexOfValue]?.colorCode;
      }
    }
    return false;
  };
  const closest = (needle, haystack = []) => {
    if (haystack && haystack.length) {
      return haystack.reduce((a, b) => {
        let aDiff = Math.abs(a - needle);
        let bDiff = Math.abs(b - needle);

        if (aDiff == bDiff) {
          return a > b ? a : b;
        } else {
          return bDiff < aDiff ? b : a;
        }
      });
    }
    return -1;
  };

  const nearestValue = (val, influenceItem) => {
    let arr = [];
    if (influenceItem?.options && influenceItem?.options.length) {
      arr = [...influenceItem?.options].map((it) => it.value);
    }
    if (
      influenceItem.type === "TYPE2" &&
      influenceItem.options.length &&
      influenceItem.options[val - 1]
    ) {
      return influenceItem.options[val - 1].colorCode;
    }
    const returnVal = closest(val, arr);
    if (
      influenceItem?.options &&
      influenceItem?.options.length &&
      returnVal > -1
    ) {
      const foundIndexOfValue = influenceItem?.options.findIndex(
        (it) => it.value === returnVal
      );
      if (foundIndexOfValue > -1) {
        return influenceItem?.options[foundIndexOfValue]?.colorCode;
      }
    }
    return false;
  };
  let heighestCorrelation = 1;
  if (isOutCome && item.influence && item.influence.length) {
    heighestCorrelation = Math.max(
      ...item.influence.map((o) => Math.abs(o.correlation || 0))
    );
  }
  console.log("dates", dates);
  const renderIcon = (icon, onClick = () => { }) => (
    <div className={classes.iconRound} onClick={onClick}>
      {icon}
    </div>
  );
  const chunkArray = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const rebnderDateBasedMonth = () => {
    const returnArr = []
    if (dates && dates.length) {
      const dateChunks = chunkArray(dates, 7);
      for (let index = 0; index < dateChunks.length; index++) {
        const ele = dateChunks[index];
        const rowArr = []
        for (let j = 0; j < ele.length; j++) {
          const element = ele[j];
          const isLast = j === (ele.length - 1)
          let bgColor = "#E3E3E3";
          let textValue = "-";
          if (isOutCome) {
            const foundDateData = item.outcomeDataDates.findIndex(
              (ite) => ite.date === moment(element).format("MM-DD-YYYY")
            );
            if (foundDateData > -1) {
              textValue = item.outcomeDataDates[foundDateData].index[0];
              let nearValue = nearestValueOutcome(textValue, item);
              if (item?.type === "TYPE2") {
                nearValue = null;
              }
              if (
                props?.item?.outcomeId &&
                props?.item?.options?.length &&
                props?.item?.options[item?.index - 1]
              ) {
                nearValue = props.item.options[item?.index - 1].colorCode;
              }
              if (nearValue) {
                bgColor = nearValue;
              }
            }
          }
          if (!isOutCome) {
            const foundDateData = item.influenceDataDates.findIndex(
              (ite) => ite.date === moment(element).format("MM-DD-YYYY")
            );
            if (foundDateData > -1) {
              textValue = item.influenceDataDates[foundDateData].index[0];
              let nearValue = nearestValue(
                item.type === "TYPE1" || item.type === "TYPE12"
                  ? textValue
                  : textValue,
                props?.item
              );
              if (item.type === "TYPE2") {
                if (
                  props?.item?.type === "TYPE2" &&
                  props?.item.options.length &&
                  props?.item.options[item.index - 1]
                ) {
                  nearValue = props?.item.options[item.index - 1].colorCode;
                }
              }
              if (item?.type === "TYPE3") {
                if (item?.inverse === "true" && textValue === "1") {
                  nearValue = "var(--color-new-main)";
                } else if (item?.inverse === "true" && textValue === "0") {
                  nearValue = "#F17F79";
                } else if (item?.inverse === "false" && textValue === "0") {
                  nearValue = "#F17F79";
                } else if (item?.inverse === "false" && textValue === "1") {
                  nearValue = "var(--color-new-main)";
                }
              }

              if (nearValue) {
                bgColor = nearValue;
              }
            }
          }
          rowArr.push(<div className={classes.dateInfoStep} key={element} style={{
            width: "36.26px"
          }}>
            <div
              className={classes.stepperLineDot}
              style={{
                background: bgColor,
              }}
            >
              {item?.type !== "TYPE3" && (
                <span className={classes.stepperLineDotText}>
                  {textValue}
                </span>
              )}
            </div>
            {dates.length === index + 1 || isLast ? null : (
              <Divider
                orientation="horizontal"
                flexItem
                className={classes.stepperLine}
              />
            )}
          </div>)
        }
        returnArr.push(<div style={{
          display: "flex",
          alignItems: "center",
          width: "100%"
        }} key={index}>{rowArr}</div>)

      }
    }
    return <div style={{
      display: "flex",
      alignItems: "flex-start",
      flexDirection: "column",
      gap: "14px",
      width: "100%",
      marginTop: "14px"
    }}>{returnArr}</div>
  }
  console.log("item.influence", item.influence);
  
  const top5PositiveInflue = item.influence && item.influence.length > 0 && !isShowMore ? item.influence.filter(it => it.correlation > 0).slice(0, 5) 
    : item.influence && item.influence.length > 0 && isShowMore ? item.influence.filter(it => it.correlation > 0).slice(0, 8) : [];
  const top5NegativeInflue = item.influence && item.influence.length > 0 && !isShowMore ? item.influence.filter(it => it.correlation < 0).slice(-5) : 
  item.influence && item.influence.length > 0 && isShowMore ? item.influence.filter(it => it.correlation < 0).slice(-8) :[];
  const top10influence = [...top5PositiveInflue, ...top5NegativeInflue]
  return (
    <Grid
      xs={12}
      sm={12}
      md={isfromshare ? 5.8 : 3.8}
      lg={isfromshare ? 5.8 : 2.8}
      xl={2.9}
      item
      sx={{
        background: "rgba(255, 255, 255, 1) !important",
        boxShadow: "0px 11px 70px 0px #D3DAD73B",
      }}
      direction={"column"}
      key={item.outcomeId}
      className={classes.weeklyReportItem}
    >
      <div className={classes.titleCard}>
        <div className={classes.title}>
          <span className={classes.titleText}> {item.name}</span>
          <div>
            <HTMLTooltip
              customIcon={renderIcon(<EyeIcon />, () => { })}
              title={item.name}
              showArrow={false}
              placement="left-start"
              fontProps={{
                fontSize: `${pxToRem(12)} !important`,
              }}
              sx={{
                "& .MuiTooltip-tooltip": {
                  maxWidth: "200px",
                },
              }}
            />
          </div>


        </div>
        <div className={classes.avgDetails}>
          <div className={classes.avgDetailsInfo}>
            <div className={classes.currentAvgCard}>
              <span className={classes.avgdetailsTitle}>Current Average</span>
              <div className={classes.cureentAvgInfo}>
                <span className={classes.cureentAvgInfoText}>
                  {Number(item.weekAverage) ? parseFloat(item.weekAverage).toFixed(2) : "-"}
                </span>
              </div>
            </div>
          </div>
        </div>
        {dates.length > 8 ? rebnderDateBasedMonth() : <div className={dates.length > 8 ? classes.stepperGrid : classes.stepper}>
          {dates.map((it, index) => {
            let bgColor = "#E3E3E3";
            let textValue = "-";
            if (isOutCome) {
              const foundDateData = item.outcomeDataDates.findIndex(
                (ite) => ite.date === moment(it).format("MM-DD-YYYY")
              );
              if (foundDateData > -1) {
                textValue = item.outcomeDataDates[foundDateData].index[0];
                let nearValue = nearestValueOutcome(textValue, item);
                if (item?.type === "TYPE2") {
                  nearValue = null;
                }
                if (
                  props?.item?.outcomeId &&
                  props?.item?.options?.length &&
                  props?.item?.options[item?.index - 1]
                ) {
                  nearValue = props.item.options[item?.index - 1].colorCode;
                }
                if (nearValue) {
                  bgColor = nearValue;
                }
              }
            }
            if (!isOutCome) {
              const foundDateData = item.influenceDataDates.findIndex(
                (ite) => ite.date === moment(it).format("MM-DD-YYYY")
              );
              if (foundDateData > -1) {
                textValue = item.influenceDataDates[foundDateData].index[0];
                let nearValue = nearestValue(
                  item.type === "TYPE1" || item.type === "TYPE12"
                    ? textValue
                    : textValue,
                  props?.item
                );
                if (item.type === "TYPE2") {
                  if (
                    props?.item?.type === "TYPE2" &&
                    props?.item.options.length &&
                    props?.item.options[item.index - 1]
                  ) {
                    nearValue = props?.item.options[item.index - 1].colorCode;
                  }
                }
                if (item?.type === "TYPE3") {
                  if (item?.inverse === "true" && textValue === "1") {
                    nearValue = "var(--color-new-main)";
                  } else if (item?.inverse === "true" && textValue === "0") {
                    nearValue = "#F17F79";
                  } else if (item?.inverse === "false" && textValue === "0") {
                    nearValue = "#F17F79";
                  } else if (item?.inverse === "false" && textValue === "1") {
                    nearValue = "var(--color-new-main)";
                  }
                }

                if (nearValue) {
                  bgColor = nearValue;
                } else {
                  bgColor = "var(--color-new-main)"
                }
              }
            }

            return (
              <div className={classes.dateInfoStep} key={index}>
                <div
                  className={classes.stepperLineDot}
                  style={{
                    background: bgColor,
                  }}
                >
                  {item?.type !== "TYPE3" && (
                    <span className={classes.stepperLineDotText}>
                      {textValue}
                    </span>
                  )}
                </div>
                {dates.length === index + 1 ? null : (
                  <Divider
                    orientation="horizontal"
                    flexItem
                    className={classes.stepperLine}
                  />
                )}
              </div>
            );
          })}
        </div>}

      </div>
      {isOutCome && (
        <>
          {item.influence.length > 0 && (
            <>
              <div className={classes.influencesCard}>
                <div className={classes.insightsTableRow}>
                  <div className={classes.insightsRegression}>
                    <div className={classes.insightsRegressionCellTitle}>
                      Top Influences
                    </div>
                  </div>
                  <div className={classes.insightsInfluences}>
                    <div className={classes.insightsInfluencesTitle}>Score</div>
                  </div>
                </div>
                {top10influence.map((it) => (
                  <CustomCard
                    heighestCorrelation={heighestCorrelation}
                    item={it}
                    key={Math.random()}
                  />
                ))}
                {!isShowMore && item.influence && item.influence.length > top10influence.length && <div className={classes.insightsTableRow}>
                  
                  <div  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    cursor: "pointer",
                    width: "95%"
                  }} onClick={() => setShowMore(true)}>
                    <div className={classes.insightsInfluencesTitle}>See more</div>
                  </div>
                </div>}
                {isShowMore && <div className={classes.insightsTableRow}>
                  
                  <div  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    cursor: "pointer",
                    width: "95%"
                  }} onClick={() => setShowMore(false)}>
                    <div className={classes.insightsInfluencesTitle}>See less</div>
                  </div>
                </div>}
              </div>
            </>
          )}
        </>
      )}
    </Grid>
  );
};
const CustomCard = ({ item, heighestCorrelation }) => {
  const classes = useStyles();
  const CorrelationnWidth =
    (Math.abs(item.correlation) * 100) / heighestCorrelation;

  return (
    <div className={classes.insightsTableRow}>
      <div className={classes.insightsInfluences}>{item?.name}</div>
      <div className={classes.insightsCorrelation}>
        <div className={classes.insightsCorrelationCell}>
          <div
            className={
              item?.correlation === 0
                ? null
                : item?.correlation > 0
                  ? classes.insightsCorrelationPositive
                  : classes.insightsCorrelationNegative
            }
            style={{
              width: `${item?.correlation && item?.correlation !== 0
                ? CorrelationnWidth
                : 0
                }%`,
            }}
          ></div>
          <span
            className={
              item?.correlation === 0
                ? null
                : item?.correlation > 0
                  ? classes.insightsCorrelationPositiveText
                  : classes.insightsCorrelationNegativeText
            }
          >
            {item?.correlation && item?.correlation !== 0
              ? parseFloat(item?.correlation).toFixed(2)
              : item?.correlation === 0
                ? item?.correlation
                : "N/A"}
          </span>
        </div>
      </div>
    </div>
  );
};
