import React from "react"

const CorrelationIcon = ({
      size = 20,
    color="#656565"
}) => {
    return <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.9 16.0001C17.9 16.2388 17.8051 16.4677 17.6363 16.6365C17.4676 16.8053 17.2386 16.9001 17 16.9001H2.59995C2.36126 16.9001 2.13234 16.8053 1.96356 16.6365C1.79477 16.4677 1.69995 16.2388 1.69995 16.0001V4.0001C1.69995 3.7614 1.79477 3.53248 1.96356 3.3637C2.13234 3.19492 2.36126 3.1001 2.59995 3.1001C2.83865 3.1001 3.06756 3.19492 3.23635 3.3637C3.40513 3.53248 3.49995 3.7614 3.49995 4.0001V10.4163L6.80745 7.5251C6.96272 7.38925 7.15996 7.31086 7.36613 7.30308C7.5723 7.2953 7.77488 7.35859 7.93995 7.48235L12.1565 10.6443L16.4075 6.9251C16.4945 6.83818 16.5985 6.77003 16.713 6.72488C16.8274 6.67973 16.9499 6.65853 17.0729 6.66258C17.1959 6.66664 17.3167 6.69587 17.428 6.74846C17.5392 6.80106 17.6385 6.87591 17.7196 6.96838C17.8008 7.06085 17.8621 7.16898 17.8999 7.28609C17.9376 7.40321 17.9509 7.52682 17.939 7.64928C17.9271 7.77174 17.8902 7.89046 17.8306 7.9981C17.771 8.10574 17.6899 8.20001 17.5925 8.2751L12.7925 12.4751C12.6372 12.611 12.4399 12.6893 12.2338 12.6971C12.0276 12.7049 11.825 12.6416 11.66 12.5178L7.44345 9.3581L3.49995 12.8088V15.1001H17C17.2386 15.1001 17.4676 15.1949 17.6363 15.3637C17.8051 15.5325 17.9 15.7614 17.9 16.0001Z" fill={color} />
    </svg>
}

export default CorrelationIcon