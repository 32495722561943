import { MenuItem, TextField, Typography } from '@mui/material';
import React from 'react'
import styled from 'styled-components';

const ReminderContent = ({ reminderState, setReminderState }) => {

    const ReminderContainer = styled("div")(({ theme }) => ({
        display: "flex",
        alignItems: "center",
        gap: "8px",
    }));

    const ariaLabel = { "aria-label": "description" };
    return (
        <div>
            <ReminderContainer>
                <Typography variant="caption">Every</Typography>
                <TextField
                    sx={{ width: '95px !important' }}
                    className="fillTextInput"
                    placeholder="1"
                    type="number"
                    variant="outlined"
                    name="dayCountReminderBox"
                    value={reminderState?.dayCount}
                    onChange={(e) => {
                        setReminderState({
                            ...reminderState,
                            dayCount: e.target.value
                        })
                    }}
                    inputProps={{ ariaLabel, min: 0 }}
                />

                {/* <CalenderButtonReminderTwo> */}
                <TextField
                    className="fillTextInput influenceSelect"
                    sx={{ width: "152px !important", marginBottom: '0px !important' }}
                    select
                    value={reminderState?.every}
                    onChange={(e) => {
                        setReminderState({
                            ...reminderState,
                            every: e.target.value
                        })
                    }}
                //  disabled={props.influenceData?.uid}
                >
                    <MenuItem key="WEEK" value="WEEK">
                        Weeks
                    </MenuItem>
                    <MenuItem key="MONTH" value="MONTH">
                        Month
                    </MenuItem>
                    <MenuItem key="DAY" value="DAY">
                        Day
                    </MenuItem>
                    <MenuItem key="YEAR" value="YEAR">
                        Year
                    </MenuItem>

                </TextField>
                {/* </CalenderButtonReminderTwo> */}

                <Typography variant="caption">, On</Typography>
                {/* <CalenderButtonReminderTwo> */}
                <TextField
                    className="fillTextInput influenceSelect"
                    sx={{ width: "152px !important", marginBottom: '0px !important' }}
                    select
                    value={reminderState?.days[0]}
                    // value={reminderState?.day}
                    onChange={(e) => {
                        setReminderState({
                            ...reminderState,
                            days: [e.target.value]
                        })
                    }}
                >
                    {/* <MenuItem key="WEEKS" value="WEEKS">
                  Weeks
                </MenuItem> */}
                    <MenuItem key="MONDAY" value="MONDAY">
                        Monday
                    </MenuItem>
                    <MenuItem key="TUESDAY" value="TUESDAY">
                        Tuesday
                    </MenuItem>
                    <MenuItem key="WEDNESDAY" value="WEDNESDAY">
                        Wednesday
                    </MenuItem>
                    <MenuItem key="THURSDAY" value="THURSDAY">
                        Thursday
                    </MenuItem>
                    <MenuItem key="FRIDAY" value="FRIDAY">
                        Friday
                    </MenuItem>
                    <MenuItem key="SATURDAY" value="SATURDAY">
                        Saturday
                    </MenuItem>
                    <MenuItem key="SUNDAY" value="SUNDAY">
                        Sunday
                    </MenuItem>

                </TextField>
                {/* </CalenderButtonReminderTwo> */}
            </ReminderContainer>
        </div>
    )
}

export default ReminderContent
