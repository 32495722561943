import { Box } from "@mui/material"
import React from "react"
import CommonTypo from "../../../components/CommonTypo"

const Entries = ({
    currentWeekcount,
    lastWeekCount,
    weekCurrent,
    weekPrevious
}) => {
    return  <Box sx={{
        padding: "24px",
        border: "1px solid #EEEEEE",
        display: "flex",
        borderRadius: "12px",
        flexDirection: "column",
        gap: "8px",
        height: "184px",
        width: "371px",
        justifyContent: "space-between"
    }}>
        <CommonTypo size={24} lineHeight={24} color="#3E4240" fontWeight={700} extraSx={{
            whiteSpace: "nowrap",
        }}>{"Entries"}</CommonTypo>
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
        }}>
            <Box sx={{
                display: "flex",
                justifyContent: "space-between"
            }}>
                <CommonTypo size={16} lineHeight={16} color="#656565" fontWeight={600} extraSx={{
                    whiteSpace: "nowrap",
                }}>{"This week"}</CommonTypo>
                <CommonTypo size={16} lineHeight={16} color="#656565" fontWeight={400} extraSx={{
                    whiteSpace: "nowrap",
                }}>{currentWeekcount + " times"}</CommonTypo>
            </Box>
            <Progress achive={100} total={weekCurrent} />
        </Box>
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
        }}>
            <Box sx={{
                display: "flex",
                justifyContent: "space-between"
            }}>
                <CommonTypo size={16} lineHeight={16} color="#656565" fontWeight={600} extraSx={{
                    whiteSpace: "nowrap",
                }}>{"Previous week"}</CommonTypo>
                <CommonTypo size={16} lineHeight={16} color="#656565" fontWeight={400} extraSx={{
                    whiteSpace: "nowrap",
                }}>{lastWeekCount + " times"}</CommonTypo>
            </Box>
            <Progress achive={0} total={weekPrevious} />
        </Box>
    </Box>
}

export default Entries

const Progress = ({ total = 100, achive = 0 }) => {
    return <Box sx={{
        background: "#EEEEEE",
        width: total + "%",
        height: "16px",
        borderRadius: "8px"
    }}>
        <Box sx={{
            background: "var(--color-new-main)",
            width: `calc(${achive}%)`,
            height: "16px",
            borderRadius: "8px"
        }} />
    </Box>
}