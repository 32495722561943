import { Grid, Box, Typography } from "@mui/material";
import * as React from "react";
import palette from "../../theme/palette";
const CoachImage = require("../../assets/images/recomm_img.png");

const CoachItem = () => {
  return (
    <Grid container>
      <Grid item xs display="flex" alignItems="center">
        <Box
          display="flex"
          alignItems="center"
          sx={{ border: "1px solid #DCE6E0", borderRadius: "8px",marginBottom:'20px',marginRight:'20px' }}
        >
          <Grid sx={{ width: "130px", height: "170px" }}>
            <img src={CoachImage} alt="coach_img" style={{width: "130px", height: "170px",objectFit:'fill'}} />
          </Grid>
          <Grid margin="24px">
            <Typography variant="h6" sx={{ color: palette.primary.light }}>
              Amily Rice
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ color: palette.secondary.contrast, marginTop: "8px" }}
            >
              A short piece describing the coach and some ther abilities, styles
              and past experience.
            </Typography>
          </Grid>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          sx={{ border: "1px solid #DCE6E0", borderRadius: "8px",marginBottom:'20px',marginRight:'20px' }}
        >
          <Grid sx={{ width: "130px", height: "170px" }}>
            <img src={CoachImage} alt="coach_img" style={{width: "130px", height: "170px",objectFit:'fill'}} />
          </Grid>
          <Grid margin="24px">
            <Typography variant="h6" sx={{ color: palette.primary.light }}>
              Amily Rice
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ color: palette.secondary.contrast, marginTop: "8px" }}
            >
              A short piece describing the coach and some ther abilities, styles
              and past experience.
            </Typography>
          </Grid>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          sx={{ border: "1px solid #DCE6E0", borderRadius: "8px",marginBottom:'20px',marginRight:'20px' }}
        >
          <Grid sx={{ width: "130px", height: "170px" }}>
            <img src={CoachImage} alt="coach_img" style={{width: "130px", height: "170px",objectFit:'fill'}} />
          </Grid>
          <Grid margin="24px">
            <Typography variant="h6" sx={{ color: palette.primary.light }}>
              Amily Rice
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ color: palette.secondary.contrast, marginTop: "8px" }}
            >
              A short piece describing the coach and some ther abilities, styles
              and past experience.
            </Typography>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs display="flex" alignItems="center">
        <Box
          display="flex"
          alignItems="center"
          sx={{ border: "1px solid #DCE6E0", borderRadius: "8px",marginBottom:'20px',marginRight:'20px' }}
        >
          <Grid sx={{ width: "130px", height: "170px" }}>
            <img src={CoachImage} alt="coach_img" style={{width: "130px", height: "170px",objectFit:'fill'}} />
          </Grid>
          <Grid margin="24px">
            <Typography variant="h6" sx={{ color: palette.primary.light }}>
              Amily Rice
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ color: palette.secondary.contrast, marginTop: "8px" }}
            >
              A short piece describing the coach and some ther abilities, styles
              and past experience.
            </Typography>
          </Grid>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          sx={{ border: "1px solid #DCE6E0", borderRadius: "8px",marginBottom:'20px',marginRight:'20px' }}
        >
          <Grid sx={{ width: "130px", height: "170px" }}>
            <img src={CoachImage} alt="coach_img" style={{width: "130px", height: "170px",objectFit:'fill'}} />
          </Grid>
          <Grid margin="24px">
            <Typography variant="h6" sx={{ color: palette.primary.light }}>
              Amily Rice
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ color: palette.secondary.contrast, marginTop: "8px" }}
            >
              A short piece describing the coach and some ther abilities, styles
              and past experience.
            </Typography>
          </Grid>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          sx={{ border: "1px solid #DCE6E0", borderRadius: "8px",marginBottom:'20px',marginRight:'20px' }}
        >
          <Grid sx={{ width: "130px", height: "170px" }}>
            <img src={CoachImage} alt="coach_img" style={{width: "130px", height: "170px",objectFit:'fill'}} />
          </Grid>
          <Grid margin="24px">
            <Typography variant="h6" sx={{ color: palette.primary.light }}>
              Amily Rice
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ color: palette.secondary.contrast, marginTop: "8px" }}
            >
              A short piece describing the coach and some ther abilities, styles
              and past experience.
            </Typography>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};
export default CoachItem;
