import React, { useContext, useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    Typography,
    // Grid,
    // DialogTitle,
    // DialogContent,
    // Typography,
    // ListItem,
    // Button,
    // List,
} from "@mui/material";
import { pxToRem } from '../../theme/typography';
import palette from '../../theme/palette';
import ThreeDotsIcon from '../../assets/images/icon/ThreeDotsIcon';
import PlusIconSVG from '../../assets/images/icon/PlusIcon';
import ShareIcon from '../../assets/images/icon/ShareIcon';
import AddCategoryIcon from '../../assets/images/icon/AddCategoryIcon';
import UserIcon from '../../assets/images/icon/UserIcon';
import useStyles from './TrackingClasses';
import { setTrackingState } from '../../store/actions/trackerAction';
import { TrackingContext } from '../../context/userTracking';


const TutorialModal = ({ visibleTutorial, setVisibleTutorial, isMobile = false }) => {
    const [currentStep, setCurrentStep] = useState(0)
    var stickyContainer = document.getElementById("add-other-dropdown");
    var shareButton = document.getElementById("share-button");
    var categoryButton = document.getElementById("add-category");
    var personalButton = document.getElementById("Personal_Button");
    var mobileAddItemButton = document.getElementById("mobile-add-title");
    var mobileAddCategoryButton = document.getElementById("mobile-add-category");
    var mobilePersonalModeButton = document.getElementById("mobile-personal-mode");
    var mobileModeSwitchTab = document.getElementById("mode-switch-tab");
    let paperStyles: any = {
        display: 'flex !important',
        padding: '20px',
        flexDirection: 'column !important',
        alignItems: 'flex-start !important',
        gap: '16px !important',
        minWidth: "560px"
    }
    if (currentStep === 6) {
        paperStyles = {
            ...paperStyles,
            marginTop: '160px'
        }
    }
    let paperStylesMobile: any = {
        display: 'flex !important',
        padding: '20px',
        flexDirection: 'column !important',
        alignItems: 'flex-start !important',
        gap: '16px !important',
        minWidth: "90%",
        borderRadius: "20px"
    }
    if (currentStep === 1) {
        paperStylesMobile = {
            ...paperStylesMobile,
            marginTop: '280px'
        }
    }
    if ([4].includes(currentStep)) {
        paperStylesMobile = {
            ...paperStylesMobile,
            marginTop: '140px'
        }
    }
    if ([5].includes(currentStep)) {
        paperStylesMobile = {
            ...paperStylesMobile,
            marginTop: '300px'
        }
    }
    if ([7].includes(currentStep)) {
        paperStylesMobile = {
            ...paperStylesMobile,
            marginTop: '300px'
        }
    }
    return <>
        <Dialog
            onClose={() => { }}
            open={visibleTutorial}
            className={!isMobile && "dialogWrapper"}
            disableEscapeKeyDown
            sx={{
                "& .MuiDialog-paper": isMobile ? paperStylesMobile : paperStyles
            }}
        >
            <Counter currentStep={currentStep} isMobile={isMobile} />
            <TutorialContent currentStep={currentStep} setCurrentStep={setCurrentStep} setVisibleTutorial={setVisibleTutorial} isMobile={isMobile} />
        </Dialog>
        {isMobile && currentStep === 4 && (
            <Box sx={{
                width: '50.862px',
                height: '50.979px',
                transform: 'rotate(-0.077deg)',
                position: 'absolute',
                background: '#fff',
                zIndex: 1400,
                borderRadius: '100%',
                top: mobileAddItemButton && mobileAddItemButton.getBoundingClientRect().top ? mobileAddItemButton.getBoundingClientRect().top - 5 : 0,
                left: mobileAddItemButton && mobileAddItemButton.getBoundingClientRect().left ? mobileAddItemButton.getBoundingClientRect().left - 15 : 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }} id="highlight-box">
                <PlusIconSVG />
            </Box>
        )}
        {isMobile && currentStep === 1 && (
            <Box sx={{
                width: '182px',
                height: '37px',
                // transform: 'rotate(-0.077deg)',
                position: 'absolute',
                background: '#fff',
                zIndex: 1400,
                borderRadius: '6px',
                top: mobileModeSwitchTab && mobileModeSwitchTab.getBoundingClientRect().top ? mobileModeSwitchTab.getBoundingClientRect().top - 0 : 0,
                left: mobileModeSwitchTab && mobileModeSwitchTab.getBoundingClientRect().left ? mobileModeSwitchTab.getBoundingClientRect().left - 0 : 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }} id="highlight-box">
                <Box display={"flex"} alignItems={"center"} alignSelf={"stretch"} justifyContent={"center"} marginTop={"18px"} marginBottom={"18px"} >
                    <Box display={"inline-flex"} alignItems={"center"} sx={{
                        background: "#EBF2F1",
                        gap: "22px"
                    }}>
                        <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"} sx={{
                            height: "32px",
                            padding: "4px 0px 4px 4px",
                            borderRadius: "6px"
                        }} >
                            <Box sx={{
                                height: "24px",
                                width: "71px",
                                borderRadius: "4px",
                                backgroundColor: palette.primary.main
                            }}>
                                <Box sx={{
                                    height: "22px",
                                    width: "71px",
                                    borderRadius: "6px",
                                    padding: "0px 23px 0px 24px",

                                }} alignItems={"center"} justifyContent={"center"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <rect x="4" y="5" width="16" height="5" rx="1" stroke={"#F7F9FA"} strokeLinejoin="round" />
                                        <rect x="4" y="14" width="16" height="5" rx="1" stroke={"#F7F9FA"} strokeLinejoin="round" />
                                    </svg>
                                </Box>
                            </Box>
                        </Box>
                        <Box display={"flex"} alignItems={"center"} justifyContent={"flex-end"} sx={{
                            height: "32px",
                            padding: "4px 0px 4px 4px",
                            borderRadius: "6px"
                        }} >
                            <Box sx={{
                                height: "24px",
                                width: "71px",
                                borderRadius: "4px",
                                backgroundColor: "transparent"
                            }}>
                                <Box sx={{
                                    height: "22px",
                                    width: "71px",
                                    borderRadius: "6px",
                                    padding: "0px 23px 0px 24px",

                                }} alignItems={"center"} justifyContent={"center"}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <rect x="2" y="3" width="20" height="9" rx="2" stroke={"#222222"} />
                                        <path d="M16 18V18C16 19.1046 15.1046 20 14 20H10C8.89543 20 8 19.1046 8 18V18" stroke={"#222222"} />
                                        <path d="M19 14V14C19 15.1046 18.1046 16 17 16H7C5.89543 16 5 15.1046 5 14V14" stroke={"#222222"} />
                                    </svg>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        )}
        {isMobile && currentStep === 6 && (
            <Box sx={{
                width: '50.862px',
                height: '50.979px',
                transform: 'rotate(-0.077deg)',
                position: 'absolute',
                background: '#fff',
                zIndex: 1400,
                borderRadius: '100%',
                top: mobilePersonalModeButton && mobilePersonalModeButton.getBoundingClientRect().top ? mobilePersonalModeButton.getBoundingClientRect().top - 0 : 0,
                left: mobilePersonalModeButton && mobilePersonalModeButton.getBoundingClientRect().left ? mobilePersonalModeButton.getBoundingClientRect().left - 10 : 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }} id="highlight-box">
                <UserIcon />
            </Box>
        )}
        {/* {isMobile && currentStep === 7 && (
            <Box sx={{
                width: '50.862px',
                height: '50.979px',
                transform: 'rotate(-0.077deg)',
                position: 'absolute',
                background: '#fff',
                zIndex: 1400,
                borderRadius: '100%',
                top: mobileShareButton && mobileShareButton.getBoundingClientRect().top ? mobileShareButton.getBoundingClientRect().top - 0 : 0,
                left: mobileShareButton && mobileShareButton.getBoundingClientRect().left ? mobileShareButton.getBoundingClientRect().left - 10 : 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }} id="highlight-box">
                <UserIcon />
            </Box>
        )} */}
        {isMobile && currentStep === 5 && (
            <Box sx={{
                width: '50.862px',
                height: '50.979px',
                transform: 'rotate(-0.077deg)',
                position: 'absolute',
                background: '#fff',
                zIndex: 1400,
                borderRadius: '100%',
                top: mobileAddCategoryButton && mobileAddCategoryButton.getBoundingClientRect().top ? mobileAddCategoryButton.getBoundingClientRect().top - 5 : 0,
                left: mobileAddCategoryButton && mobileAddCategoryButton.getBoundingClientRect().left ? mobileAddCategoryButton.getBoundingClientRect().left - 15 : 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }} id="highlight-box">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                >
                    <path
                        d="M22 16H14"
                        stroke="#11734A"
                        strokeWidth="2"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M18 12V20"
                        stroke="#11734A"
                        strokeWidth="2"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M22 5H2"
                        stroke="#11734A"
                        strokeWidth="2"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M14 12H2"
                        stroke="#11734A"
                        strokeWidth="2"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M12 19H2"
                        stroke="#11734A"
                        strokeWidth="2"
                        strokeLinejoin="round"
                    />
                </svg>
            </Box>
        )}
        {!isMobile && currentStep === 3 && (
            <Box sx={{
                width: '50.862px',
                height: '50.979px',
                transform: 'rotate(-0.077deg)',
                position: 'absolute',
                background: '#fff',
                zIndex: 1400,
                borderRadius: '100%',
                top: stickyContainer && stickyContainer.getBoundingClientRect().top ? stickyContainer.getBoundingClientRect().top - 18 : 0,
                left: stickyContainer && stickyContainer.getBoundingClientRect().left ? stickyContainer.getBoundingClientRect().left - 20 : 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }} id="highlight-box">
                <span
                    // onClick={(e) => handleClick(e, category)}
                    id="add-other-dropdown"
                    style={{ cursor: "pointer" }}
                >
                    <ThreeDotsIcon />
                </span>
            </Box>
        )}
        {!isMobile && currentStep === 8 && (
            <Box sx={{
                width: '54px',
                height: '54px',
                transform: 'rotate(-0.077deg)',
                position: 'absolute',
                background: '#fff',
                zIndex: 1400,
                borderRadius: '100%',
                top: shareButton && shareButton.getBoundingClientRect().top ? shareButton.getBoundingClientRect().top - 10 : 0,
                left: shareButton && shareButton.getBoundingClientRect().left ? shareButton.getBoundingClientRect().left - 10 : 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }} id="highlight-box">
                <ShareIcon />
            </Box>
        )}
        {isMobile && currentStep === 7 && (
            <Box sx={{
                width: '54px',
                height: '54px',
                transform: 'rotate(-0.077deg)',
                position: 'absolute',
                background: '#fff',
                zIndex: 1400,
                borderRadius: '100%',
                top: shareButton && shareButton.getBoundingClientRect().top ? shareButton.getBoundingClientRect().top - 5 : 0,
                left: shareButton && shareButton.getBoundingClientRect().left ? shareButton.getBoundingClientRect().left - 5 : 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }} id="highlight-box">
                <ShareIcon />
            </Box>
        )}
        {!isMobile && currentStep === 7 && (
            <Box sx={{
                width: '78.052px',
                height: '40px',
                transform: 'rotate(-0.077deg)',
                position: 'absolute',
                background: '#fff',
                zIndex: 1400,
                top: personalButton && personalButton.getBoundingClientRect().top ? personalButton.getBoundingClientRect().top : 0,
                left: personalButton && personalButton.getBoundingClientRect().left ? personalButton.getBoundingClientRect().left : 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }} id="highlight-box">
                <Button variant={"contained"} sx={{
                    display: 'flex',
                    padding: '12px',
                    alignItems: 'center',
                    gap: '8px',
                    border: '1px solid #F3F3F3',
                    marginBottom: '0 !important',
                    boxShadow: "none",
                    borderRadius: '0 8px 8px 0',
                    "&::after": {
                        content: '""',
                        position: "absolute",
                        width: "0px",
                        height: "52px",
                        right: 0,
                        bottom: 0,
                    },
                }} >
                    <Typography
                        variant="caption"
                        sx={{
                            color: palette.common.white,
                            fontWeight: 400,
                            fontSize: "12px !important",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            lineHeight: '100% !important', /* 12px */
                            letterSpacing: '-0.12px',
                        }}
                    >
                        {"Personal"}
                    </Typography>
                </Button>
            </Box>
        )}
        {!isMobile && currentStep === 6 && (
            <Box sx={{
                width: '54px',
                height: '54px',
                transform: 'rotate(-0.077deg)',
                position: 'absolute',
                background: '#fff',
                zIndex: 1400,
                borderRadius: '100%',
                top: categoryButton && categoryButton.getBoundingClientRect().top ? categoryButton.getBoundingClientRect().top - 10 : 0,
                left: categoryButton && categoryButton.getBoundingClientRect().left ? categoryButton.getBoundingClientRect().left - 10 : 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }} id="highlight-box">
                <AddCategoryIcon />
            </Box>
        )}
    </>
}

export default TutorialModal

const TutorialContent = ({ currentStep, setCurrentStep, setVisibleTutorial, isMobile = false }) => {
    const classes = useStyles()
    const { dispatch: trackingDispatch } =
        useContext(TrackingContext);
    const slideContent = isMobile ? SlideDataMobile[currentStep] : SlideData[currentStep]
    return <Box className={classes.commentBody}>
        <Box className={isMobile ? classes.contentBoxMobile : classes.contentBox}>
            <Typography fontSize={`${pxToRem(26)} !important`}
                sx={isMobile ? {
                    fontSize: `${pxToRem(18)} !important`,
                    lineHeight: "150% !important",
                    // textAlign: "center !important",
                    fontWeight: `700 !important`,
                    "&.MuiTypography-root": {
                        "@media  (max-width: 600px) and (min-width: 100px)": {
                            fontSize: `${pxToRem(18)} !important`,
                            lineHeight: "150% !important"
                        },
                    }
                } : {

                }} color={`${"var(--color-new-text)"} !important`} fontWeight={"700 !important"} letterSpacing={"-0.26px"} lineHeight={"145% !important"}>
                {slideContent?.title}
            </Typography>
            <Typography fontSize={`${pxToRem(18)} !important`}
                sx={isMobile ? {
                    fontSize: `${pxToRem(16)} !important`,
                    lineHeight: "150% !important",
                    // textAlign: "center !important",
                    fontWeight: `400 !important`,
                    "&.MuiTypography-root": {
                        "@media  (max-width: 600px) and (min-width: 100px)": {
                            fontSize: `${pxToRem(16)} !important`,
                            lineHeight: "150% !important"
                        },
                    }
                } : {

                }} alignSelf={"stretch"} color={`#190D1A !important`} fontWeight={"400 !important"} letterSpacing={"-0.18px"} lineHeight={"150% !important"}>
                {slideContent?.description}
            </Typography>
            {slideContent?.videoUrl && (
                <div style={{
                    width: '100%',
                    display: 'flex',
                    position: 'relative',
                    alignItems: "center",
                    justifyContent: "center"
                }}>
                    <img src={slideContent?.videoUrl} style={isMobile && [1, 2].includes(currentStep) ? {
                        width: '195px',
                        height: '245px',
                        margin: '21px auto',
                        borderRadius: '10px'
                    } : isMobile && [5].includes(currentStep) ? {
                        width: '164px',
                        height: '235px',
                        margin: '21px auto',
                        borderRadius: '10px'
                    } : currentStep === 4 ? {
                        width: '391px',
                        height: '220px',
                        margin: '21px auto',
                        borderRadius: '20%'
                    } : currentStep === 5 ? {
                        width: '180px',
                        height: '158px',
                        margin: '22px auto',
                        borderRadius: '20%'
                    } : currentStep === 6 ? {
                        width: '361px',
                        height: '284px',
                        margin: '12px auto',
                        borderRadius: '20%'
                    } : {
                        width: '188px',
                        height: '107px',
                        margin: '20px auto',
                        borderRadius: '20%'
                    }} alt={slideContent?.title} />
                    {currentStep === 4 && (<Box sx={{
                        width: '409px',
                        height: '231px',
                        flexShrink: '0',
                        borderRadius: '20px',
                        position: 'absolute',
                        background: 'rgba(25, 13, 26, 0.30)',
                    }}></Box>)}
                    {currentStep === 4 && (<Box sx={{
                        width: '109.067px',
                        height: '109.067px',
                        borderRadius: '100%',
                        flexShrink: '0',
                        top: '-30px',
                        right: '20px',
                        position: 'absolute',
                        background: 'rgba(255, 255, 255, 1)',
                        display: 'flex',
                        alignItems: "center",
                        justifyContent: "center"
                    }}>

                        <svg width="16" height="16" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21 12H3" stroke="black" strokeWidth="3" strokeLinejoin="round" />
                            <path d="M12 3L12 21" stroke="black" strokeWidth="3" strokeLinejoin="round" />
                        </svg>

                    </Box>)}
                    {currentStep === 4 && <Box sx={{
                        top: '35px',
                        right: '28px',
                        position: 'absolute',

                    }}> <svg xmlns="http://www.w3.org/2000/svg" width="39" height="63" viewBox="0 0 39 63" fill="none">
                            <g filter="url(#filter0_d_652_207602)">
                                <path d="M3.84375 42.618C3.84375 51.7733 11.2674 59.197 20.4227 59.197C29.578 59.197 37.0016 51.7733 37.0016 42.618C37.0016 33.4609 29.578 26.0391 20.4227 26.0391C11.2674 26.0391 3.84375 33.4609 3.84375 42.618Z" fill="url(#paint0_linear_652_207602)" />
                                <path d="M27.7899 40.7747H22.2636V35.2484C22.2636 34.2315 21.4383 33.4062 20.4215 33.4062C19.4046 33.4062 18.5794 34.2315 18.5794 35.2484V40.7747H13.053C12.0362 40.7747 11.2109 41.5999 11.2109 42.6168C11.2109 43.6336 12.0362 44.4589 13.053 44.4589H18.5794V49.9852C18.5794 51.002 19.4046 51.8273 20.4215 51.8273C21.4383 51.8273 22.2636 51.002 22.2636 49.9852V44.4589H27.7899C28.8067 44.4589 29.632 43.6336 29.632 42.6168C29.632 41.5999 28.8067 40.7747 27.7899 40.7747Z" fill="white" />
                                <path d="M2 30.5013V1L23.3518 22.4034H10.3816L10.1034 22.6318L2 30.5013Z" fill="white" />
                                <path d="M3.84375 5.43359V26.0431L9.31296 20.7636L9.6077 20.5075H18.8845L3.84375 5.43359Z" fill="black" />
                            </g>
                            <defs>
                                <filter id="filter0_d_652_207602" x="0" y="0" width="39" height="62.1953" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset dy="1" />
                                    <feGaussianBlur stdDeviation="1" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4049 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_652_207602" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_652_207602" result="shape" />
                                </filter>
                                <linearGradient id="paint0_linear_652_207602" x1="3.84375" y1="26.0391" x2="3.84375" y2="59.197" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#5BD230" />
                                    <stop offset="1" stop-color="#068B03" />
                                </linearGradient>
                            </defs>
                        </svg></Box>}
                </div>


            )}
        </Box>
        {isMobile ? (
            <Box display={"flex"} width={"100%"} alignItems={"center"} justifyContent={"flex-end"}>
                <Button variant='contained' sx={isMobile ? {
                    display: 'flex !important',
                    padding: "8px 12px",
                    alignItems: "center",
                    gap: '4px',
                    borderRadius: '16px !important',
                    backgroundColor: "#DAFFDC",
                    boxShadow: 'none',
                    // width: "100%",
                    justifySelf: "flex-end",
                    marginBottom: "0px !important",
                    "&:hover": {
                        display: 'flex !important',
                        padding: "8px 12px",
                        alignItems: "center",
                        gap: '4px',
                        borderRadius: '16px !important',
                        backgroundColor: "#DAFFDC",
                        boxShadow: 'none',
                    }
                } : {
                    display: 'flex !important',
                    padding: "8px 12px",
                    alignItems: "center",
                    gap: '4px',
                    borderRadius: '16px !important',
                    backgroundColor: "#DAFFDC",
                    boxShadow: 'none',
                    "&:hover": {
                        display: 'flex !important',
                        padding: "8px 12px",
                        alignItems: "center",
                        gap: '4px',
                        borderRadius: '16px !important',
                        backgroundColor: "#DAFFDC",
                        boxShadow: 'none',
                    }
                }} onClick={() => {
                    if (currentStep === 7) {
                        trackingDispatch(setTrackingState({
                            showLoader: false
                        }))
                        localStorage.removeItem("showTutorial")
                        setVisibleTutorial(false)
                    } else {
                        setCurrentStep(currentStep + 1)
                    }
                }}>
                    <Typography fontSize={`${pxToRem(14)} !important`} color={`${palette.primary.main} !important`} fontWeight={"400 !important"} letterSpacing={"-0.14px"} lineHeight={"100% !important"}>
                        {currentStep === 7 ? "Start tracking" : "Next"}
                    </Typography>
                </Button>
            </Box>

        ) : (
            <Button variant='contained' sx={{
                display: 'flex !important',
                padding: "8px 12px",
                alignItems: "center",
                gap: '4px',
                borderRadius: '16px !important',
                backgroundColor: "#DAFFDC",
                boxShadow: 'none',
                "&:hover": {
                    display: 'flex !important',
                    padding: "8px 12px",
                    alignItems: "center",
                    gap: '4px',
                    borderRadius: '16px !important',
                    backgroundColor: "#DAFFDC",
                    boxShadow: 'none',
                }
            }} onClick={() => {
                if (currentStep === 8) {
                    trackingDispatch(setTrackingState({
                        showLoader: false
                    }))
                    localStorage.removeItem("showTutorial")
                    setVisibleTutorial(false)
                } else {
                    setCurrentStep(currentStep + 1)
                }
            }}>
                <Typography fontSize={`${pxToRem(14)} !important`} color={`${palette.primary.main} !important`} fontWeight={"400 !important"} letterSpacing={"-0.14px"} lineHeight={"100% !important"}>
                    {currentStep === 8 ? "Start tracking" : "Next"}
                </Typography>
            </Button>
        )}

    </Box >
}

const Counter = ({ currentStep, isMobile = false }) => {
    return <Box sx={isMobile ? {
        display: 'inline-flex',
        alignItems: "flex-end",
        gap: '3px',
        position: "relative",
        right: '20px',
        top: '10px',
        width: "100%",
        justifyContent: "flex-end"
    } : {
        display: 'inline-flex',
        alignItems: 'flex-start',
        gap: '3px',
        position: 'absolute',
        right: '20px',
        top: '10px',
    }}>
        <Typography fontSize={`${pxToRem(12)} !important`} alignSelf={"stretch"} color={`#B4B0B4 !important`} fontWeight={"400 !important"} letterSpacing={"-0.18px"} lineHeight={"150% !important"}>
            {isMobile ? currentStep + 1 : currentStep}
        </Typography>
        <Typography fontSize={`${pxToRem(12)} !important`} alignSelf={"stretch"} color={`#B4B0B4 !important`} fontWeight={"400 !important"} letterSpacing={"-0.18px"} lineHeight={"150% !important"}>
            {"of"}
        </Typography>
        <Typography fontSize={`${pxToRem(12)} !important`} alignSelf={"stretch"} color={`#B4B0B4 !important`} fontWeight={"400 !important"} letterSpacing={"-0.18px"} lineHeight={"150% !important"}>
            {"8"}
        </Typography>
    </Box>
}

const SlideData = {
    0: {
        title: "Welcome, Tenacious Tracker!",
        description: "We are here to guide you trough the platform to start your journey with confidence."
    },
    1: {
        title: "How to enter your tracking data",
        description: "Tap once to add tracking data about outcomes and influences.",
        videoUrl: "/tutorialslide2.gif"
    },
    2: {
        title: "How to enter your tracking data",
        description: "Tap twice to enter your answers to the reflective questions."
    },
    3: {
        title: "Add outcomes and influences",
        description: "Tap here to add more outcomes you would like to measure and keep track of."
    },
    4: {
        title: "Add qualitative reflections",
        description: "You can also add qualitative reflections to your tracking to gather qualitative insights.",
        videoUrl: "/reflective-question-tutorial.svg"
    },
    5: {
        title: "How to add the data",
        description: "Tap twice to add your qualitative data.",
        videoUrl: "/tutorialslide5.svg"
    },
    6: {
        title: "Add categories",
        description: "Add categories to personalize your tracking page and group your outcomes and influences in your own way.",
        videoUrl: "/tutorialslide6.svg"

    },
    7: {
        title: "Personal mode",
        description: "Here you can see your influences and outcomes organized by categories."
    },
    8: {
        title: "Share social report",
        description: "Share your report with your contacts."
    }
}

const SlideDataMobile = {
    0: {
        title: "Welcome, Tenacious Tracker!",
        description: "We are here to guide you trough the platform to start your journey with confidence."
    },
    1: {
        title: "Tracking modes",
        description: "Experiment with both modes to find what works best for you. It's like having two tracking experiences in one place",
        videoUrl: "/tutorialslide2Mobile.gif"
    },
    2: {
        title: "How to enter your tracking data",
        description: "Tap once to add tracking data about outcomes and influences.",
        videoUrl: "/tutorialslide3Mobile.gif"
    },
    3: {
        title: "How to enter your tracking data",
        description: "Tap twice to enter your answers to the reflective questions."
    },
    4: {
        title: "Add outcomes, influences and reflective questions",
        description: "Tap here to add more metrics you would like to measure and keep track of and add qualitative reflections to your tracking to gather qualitative insights.",
        // videoUrl: "/reflective-question-tutorial.svg"
    },
    5: {
        title: "Add categories",
        description: "Here you can also add categories to personalize your tracking page and group your outcomes and influences in your own way.",
        videoUrl: "/tutorialslide6Mobile.svg"
    },
    6: {
        title: "Personal mode",
        description: "Here you can see your influences and outcomes organized by categories.",
        // videoUrl: "/tutorialslide6.svg"

    },
    7: {
        title: "Share social report",
        description: "Share your report with your contacts."
    },
}