import React from "react"
import { Box, Dialog, IconButton, useMediaQuery } from "@mui/material";
import { makeStyles, useTheme } from "@material-ui/core";
import CommonTypo from "../../../components/CommonTypo";
import ModalCloseIconBlack from "../../../assets/images/icon/modalCloseIconBlack";
import SparkleIcon from "../../../assets/images/icon/ProgressInsights/SparkleIcon";
import ConfettiTNT from "./confetti";

const useStyles = makeStyles((theme) => ({
    dialogWrapper: {
        // width: '75%',
        '& .MuiDialog-paper': {
            backgroundColor: '#fff',
            borderRadius: '20px',
            padding: '16px',
            maxWidth: '80%',
            overflow: 'unset',
        }
    }
}))

const CongratulationsModal = ({
    type = "outcome",
    visible = false,
    onClose = () => { },
    data = []
}) => {
    console.log("congratsodal", data, visible);

    const classes = useStyles()
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    return <>


        <Dialog
            onClose={onClose}
            open={visible}
            className={classes.dialogWrapper}
            fullWidth
            fullScreen={fullScreen}
            sx={{
                "& .MuiDialog-paper": {
                    maxWidth: "calc(100vw - 40%)",
                    maxHeight: "calc(100vh - 10%)",
                    padding: "32px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    borderRadius: "12px",
                    boxShadow: "0px 0px 64px 0px #190D1A1A",

                }
            }}
        >
            <Box display={"flex"} /* flexDirection={"column"} */ alignSelf={"stretch"} gap={"32px"}>
                <Box display={"flex"} alignItems={"flex-start"} flexDirection={"column"} gap={"16px"} flexBasis={"95%"}>
                    <CommonTypo size={24} lineHeight={28.8} fontWeight={700}>Congratulations!</CommonTypo>
                    <Box height={"88px"} borderRadius={"12px"} display={"flex"} alignItems={"center"} padding={"16px"} alignSelf={"stretch"} gap={"16px"} sx={{
                        background: "#F6F6F6"
                    }}>
                        <Box width={"56px"} height={"56px"} gap={"14px"} display={"flex"} alignItems={"center"} justifyContent={"center"} borderRadius={"100%"} sx={{
                            background: "var(--color-new-main)",

                        }}>
                            <SparkleIcon size={26.25} color="#3E4240" />
                        </Box>
                        <Box display={"flex"} flexDirection={"column"} gap={"8px"}>
                            <CommonTypo size={16} lineHeight={19.2} fontWeight={600} color="var(--color-text-primary)">{type === "outcome" ? "Outcome" : "Influence"} data is more accurate</CommonTypo>
                            <CommonTypo size={16} lineHeight={16} fontWeight={400} color="#656565">Keep tracking for ongoing insights</CommonTypo>
                        </Box>
                    </Box>
                    <CommonTypo size={16} lineHeight={19.2} fontWeight={600} color="var(--color-text-primary)">You've reached new levels for your {type === "outcome" ? "outcomes" : "influences"}:</CommonTypo>
                    <Box display={"flex"} alignSelf={"stretch"} alignItems={"flex-start"} flexDirection={"column"} sx={{
                        maxHeight: "420px",
                        overflowY: "auto"
                    }} className="hide-scrollbar" gap={"16px"} flexBasis={"95%"}>
                        {data.map(it => {
                            return <CardForInformation key={it._id} type={type} item={type === "outcome" ? {
                                name: it.name,
                                daysCount: it.outcomeData.count
                            } : {
                                name: it.name,
                                daysCount: it.influenceData.count
                            }} responseCount={type === "outcome" ? it.outcomeData.count : it.influenceData.count} />
                        })}
                    </Box>

                </Box>
                <Box display={"flex"} alignItems={"flex-start"} flexDirection={"column"} gap={"16px"} flexBasis={"5%"}>
                    <IconButton
                        sx={{
                            padding: "0px"
                        }}
                        // onClick={handleCloseStartModal}
                        onClick={onClose}
                    >
                        <ModalCloseIconBlack color={"#000000"} />
                    </IconButton>
                </Box>

            </Box>
        </Dialog>
        {visible && <ConfettiTNT />}
    </>
}

export default CongratulationsModal

const CardForInformation = ({
    item,
    type = "outcome",
    responseCount = 8
}) => {
    let text = "";
    if (responseCount >= 28) {
        if(type === "outcome") {
            text = "Level 4: Analysis is fully accurate"
        } else {
            text = "Level 4: Analysis is fully accurate, correlations unlocked" 
        }
    } else if (responseCount >= 21) {
        text = "Level 3: Data analysis is more accurate now"
    } else if (responseCount >= 14) {
        text = "Level 2: Data analysis unlocked"
    } else {
        text = "Level 1: Collected some data"
    }
    return <Box sx={{
        background: "var(--white-background)",
        border: "1px solid #EEEEEE"
    }} borderRadius={"12px"} padding={"16px"} display={"flex"} flexDirection={"column"} alignSelf={"stretch"} gap={"16px"}>
        <Box display={"flex"} flexDirection={"column"} gap={"8px"} alignSelf={"stretch"}>
            <CommonTypo size={16} lineHeight={19.2} fontWeight={600} color="var(--color-text-primary)">{item.name}</CommonTypo>
            <CommonTypo size={16} lineHeight={16} fontWeight={400} color="#656565">{text}</CommonTypo>
        </Box>
        <Box style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            marginTop: "8px"
        }}>
            <EntryCard title="1" completed={responseCount > 0} />
            <EntryCard title="2" completed={responseCount >= 14} />
            <EntryCard title="3" completed={responseCount >= 21} />
            <EntryCard title="4" completed={responseCount >= 28} />
        </Box>

    </Box>
}

const EntryCard = ({
    completed = false,
    nextCompleted = false,
    title = "1",
}) => {
    return <Box sx={{
        width: "calc(100% / 4)",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        marginTop: "16px",
        alignItems: "center",
        justifyContent: "center",
        "::after": {
            content: "''",
            display: "block",
            width: "calc(50% - 15px)",
            height: "8px",
            backgroundColor: "#EEEEEE",
            position: "absolute",
            right: "0",
            bottom: "calc(11px - 4px)", /* Position below the button */
            borderRadius: title === "4" && "0px 4px 4px 0px" /* Position below the button */
        },
        "::before": {
            content: "''",
            display: "block",
            width: "calc(50% - 15px)",
            height: "8px",
            backgroundColor: "#EEEEEE",
            position: "absolute",
            left: "0",
            bottom: "calc(11px - 4px)",
            borderRadius: title === "1" && "4px 0px 0px 4px" /* Position below the button */
        }
    }} onClick={() => {

    }}>
        <Box sx={{
            height: "24px",
            width: "24px",
            padding: "8px",
            "display": "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "8px",
            // width: "max-content",
            background: completed ? "var(--color-new-main)" : "#EEEEEE",
            borderRadius: "8px",
            zIndex: 1,
            "::after": {
                content: "''",
                display: "block",
                width: "calc(50% - 15px)",
                height: "8px",
                backgroundColor: completed ? "var(--color-new-main)" : "#EEEEEE",
                position: "absolute",
                right: "-2px",
                bottom: "calc(11px - 4px)", /* Position below the button */
                borderRadius: title === "4" ? "0px 4px 4px 0px" : completed && !nextCompleted ? "0px 4px 4px 0px" : "0px" /* Position below the button */
            },
            "::before": {
                content: "''",
                display: "block",
                width: "calc(50% - 15px)",
                height: "8px",
                backgroundColor: completed ? "var(--color-new-main)" : "#EEEEEE",
                position: "absolute",
                left: "-2px",
                bottom: "calc(11px - 4px)", /* Position below the button */
                borderRadius: title === "1" && "4px 0px 0px 4px" /* Position below the button */
            }
        }}>
            <CommonTypo size={12} color="var(--color-text-primary)" fontWeight={600}>{title}</CommonTypo>
        </Box>
    </Box>
}