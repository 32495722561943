import React from "react";
import TrackingItem from "./TrackingItem";

interface TrackingListModal {
  outcome: any;
  key: number;
  setShowOutcome: (showOutcome: boolean) => void;
  showOutcome: boolean;
  openStartModal: boolean;
  setStartModal: (openStartModal: boolean) => void;
  getOutcomeList?: any;
  setTransformToInfluenceTracking?: any;
  transformToInfluenceTracking?: any;
  startDate: any
  setStartDate: any
  endDate: any
  setEndDate: any
  index: number
  outcomeList: any[];
  isExpand: boolean
  setLimitReachPopupVisible: any
setLimitReachPopuptype: any
}

const TrackingList = (props: TrackingListModal) => {
  return (
    <TrackingItem
      outcome={props.outcome}
      setShowOutcome={props.setShowOutcome}
      showOutcome={props.showOutcome}
      openStartModal={props.openStartModal}
      setStartModal={props.setStartModal}
      getOutcomeList={props?.getOutcomeList}
      setTransformToInfluenceTracking={props?.setTransformToInfluenceTracking}
      transformToInfluenceTracking={props?.transformToInfluenceTracking}
      startDate={props.startDate}
      setStartDate={props.setStartDate}
      endDate={props.endDate}
      setEndDate={props.setEndDate}
      index={props.index}
      outcomeList={props?.outcomeList}
      isExpand={props?.isExpand}
      setLimitReachPopupVisible={props?.setLimitReachPopupVisible}
      setLimitReachPopuptype={props?.setLimitReachPopuptype}
    />
  );
};

export default TrackingList;
