import { makeStyles } from "@material-ui/core";
import { Grid, Typography } from "@mui/material";
import React from "react";
import CloudIcon from "../../assets/images/icon/cloudIcon";
import HeartIcon from "../../assets/images/icon/heartIcon";
import LeafIcon from "../../assets/images/icon/leafIcon";
import MoonIcon from "../../assets/images/icon/moonIcon";
import SunIcon from "../../assets/images/icon/sunIcon";
import CustomCard from "./customCard";

const useStyles = makeStyles((theme) => ({
  cards: {
    alignItems: "center",
    marginTop: "20px",

    "& .MuiGrid-item": {
      height: "100%",
      maxWidth: "238px",
      marginRight: "12px",
      borderRadius: "8px",
      padding: "20px 16px 20px 20px",
      marginBottom: "12px",
      [theme.breakpoints.down(1512)]: {
        maxWidth: "225px",
      },
      [theme.breakpoints.down(1450)]: {
        maxWidth: "197px",
        padding: "10px",
      },
      [theme.breakpoints.down("xs")]: {
        maxWidth: "100%",
        marginRight: "0px",
      },

      "&:last-child": {
        marginRight: "0px",
      },
    },

    [theme.breakpoints.down("lg")]: {
      display: "flex!important",
    },
    [theme.breakpoints.down("xs")]: {
      display: "block!important",
    },
  },
}));

const ProgressOutcomes = () => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h3" letterSpacing={"0.02em"}>
        Outcomes
      </Typography>
      <Grid container className={classes.cards} columns={15}>
        <Grid item xs={2.94} className="wellBeingCard">
          <CustomCard
            icon={LeafIcon}
            title="Well-being"
            daysTracked={35}
            avgRating={4.3}
          />
        </Grid>
        <Grid item xs={2.94} className="energyCard">
          <CustomCard
            icon={SunIcon}
            title="Energy"
            daysTracked={35}
            avgRating={4.3}
          />
        </Grid>
        <Grid item xs={2.94} className="senseCard">
          <CustomCard
            icon={CloudIcon}
            title="Sense of progress"
            daysTracked={35}
            avgRating={4.3}
          />
        </Grid>
        <Grid item xs={2.94} className="improveCard">
          <CustomCard
            icon={HeartIcon}
            title="Improve my sense of happiness"
            daysTracked={35}
            avgRating={4.3}
          />
        </Grid>
        <Grid item xs={2.94} className="stressCard">
          <CustomCard
            icon={MoonIcon}
            title="Perceived Stress"
            daysTracked={35}
            avgRating={4.3}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ProgressOutcomes;
