import React from "react";
import { ButtonLoader } from "./buttonLoader";

const FullpageLoader = () => {
  return (
    <div className="full-loader">
      <div className="loader-spinner">
        <ButtonLoader />
      </div>
    </div>
  );
};
export default FullpageLoader;
