import { makeStyles } from "@material-ui/core"
import React from "react"
import {
    Button,
    Grid,
    TextField,
    Typography,
    SvgIcon,
    Dialog,
    Box,
    Stepper,
    Step,
    StepButton,
    StepLabel,
} from "@mui/material";
import palette from "../../theme/palette";
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

import { styled } from '@mui/material/styles';

import { pxToRem } from "../../theme/typography";
const useStyles = makeStyles((theme) => ({
    stepper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'relative',
        width: '90%',
        margin: "0px 0px 0px 60px",
        // position: "relative"
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        gap: '24px'
    },
    lineConnector: {
        // height: '1px',
        // width: '100%',
        // background: '#A0A0A1',
        // position: 'absolute',
        // zIndex: 0
        content: '" "',
        borderTop: '1px solid #A0A0A1',
        position: 'absolute',
        width: '100%',
        top: '50%',
        left: 0,
        zIndex: 0,
    },
    afterLine: {
        marginLeft: "40px"
        // display: "inline-block",
        // background: "#FFFFFF90"
        // "&:after": {
        //     content: "''",
        //     position: "absolute",
        //     display: "inline-block",
        //     top: "50%",
        //     zIndex: 1,
        //     border: "0.1px solid #A0A0A1",
        //     width: "200%",
        //     left: '120%',
        // },
        // "@media  (max-width: 575px) and (min-width: 100px)": {
        //     "&:after": {
        //         width: "140%",
        //     },
        // },
        // "@media (max-width: 768px) and (min-width: 575px)": {
        //     "&:after": {
        //         width: "160%",
        //     },
        // },
        // "@media (max-width: 1000px) and (min-width: 768px)": {
        //     "&:after": {
        //         width: "260%",
        //     },
        // },
        // "@media  (max-width: 1300px) and (min-width: 1000px)": {
        //     "&:after": {
        //         width: "270%",
        //     },
        // },
        // "@media (max-width: 1500px) and (min-width: 1300px)": {
        //     "&:after": {
        //         width: "290%",
        //     },
        // },
        // "@media (max-width: 2000px) and (min-width: 1500px)": {
        //     "&:after": {
        //         width: "180%",
        //     },
        // },
        // "@media (max-width: 2300px) and (min-width: 2000px)": {
        //     "&:after": {
        //         width: "490%",
        //     },
        // },
        // "@media (max-width: 2600px) and (min-width: 2300px)": {
        //     "&:after": {
        //         width: "550%",
        //     },
        // },

    }
}))
const steps = ['Outcomes', 'Influences', 'Reflective questions', "just 4"];
const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
    ({ theme, ownerState }) => ({
        color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
        ...(ownerState.active && {
            color: '#784af4',
        }),
        '& .QontoStepIcon-completedIcon': {
            color: '#784af4',
            zIndex: 1,
            fontSize: 18,
        },
        '& .QontoStepIcon-circle': {
            width: 8,
            height: 8,
            borderRadius: '50%',
            backgroundColor: 'currentColor',
        },
    }),
);
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        background:
          '#ADE888',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        background:
          '#ADE888',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 8,
      border: 0,
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#BADACA',
      borderRadius: 50,
    },
  }));
  

const PlanSelectionHeader = ({ currentStep = 1, selectedPlan, isMobile = false }) => {
    const classes = useStyles()
    return <div className={classes.header}>
        {isMobile ? (
            <>
                <Typography
                    variant="h3"
                    component="h3"
                    color={palette.primary.light}
                    fontSize={`${pxToRem(16)} !important`}
                    fontWeight={500}
                    lineHeight={'145% !important'}
                    sx={isMobile ? {
                        fontSize: `${pxToRem(16)} !important`,
                        lineHeight: "120% !important",
                        textAlign: "center !important",
                        "&.MuiTypography-root": {
                            "@media  (max-width: 600px) and (min-width: 100px)": {
                                fontSize: `${pxToRem(16)} !important`,
                                lineHeight: "120% !important"
                            },
                        }
                    } : {}}
                // className={classes.registrationHeading}
                >
                    {currentStep} / 3

                </Typography>
            </>
        ) : <>
            {/* <Typography
                variant="h3"
                component="h3"
                color={palette.primary.main}
                fontSize={`${pxToRem(20)} !important`}
                fontWeight={700}
                lineHeight={'145% !important'}
                sx={{
                    // marginRight: "7%"
                }}
            // className={classes.registrationHeading}
            >
                {selectedPlan?.name} Plan

            </Typography> */}
            <Stepper className={isMobile ? "" : "new-stepper"} sx={{
                width: "50%"
            }} activeStep={currentStep} connector={<ColorlibConnector />}>
                {steps.map((label, index) => {
                    return (
                        <Step key={index} sx={{
                            '&.MuiStep-root': {
                                // marginLeft: "10px"
                            },
                            '&.MuiStep-horizontal:last-child': {
                                display: "block"
                            },

                        }}>
                            <StepLabel sx={{
                                "& .MuiStepLabel-label": {
                                    display: "none"
                                }
                            }}>{!isMobile && ""}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {/* <Stepper  sx={{
                marginLeft: "50px"
            }}>
                {steps.map((label, index) => (
                    <Step key={label}  sx={{
                        '&.MuiStep-root': {
                            // marginLeft: "10px"
                        },
                        '&.MuiStep-horizontal:last-child': {
                            display: "block"
                        },

                    }}>
                        <StepButton color="inherit" sx={{
                            '& .MuiStepLabel-labelContainer::after': {
                                // display: "none"
                            }
                        }} >
                            <Typography
                                variant="h3"
                                component="h3"
                                color={currentStep === 1 && label === "Outcomes" ? palette.primary.main
                                    : currentStep === 2 && ["Outcomes", "Influences"].includes(label) ? palette.primary.main
                                        : currentStep === 3 && ["Outcomes", "Influences", "Reflective questions"].includes(label) ? palette.primary.main : "#A0A0A1"}
                                fontSize={`${pxToRem(14)} !important`}
                                fontWeight={400}
                                lineHeight={'145% !important'}
                                zIndex={2}
                                className={classes.afterLine}
                                position={"relative"}
                            // className={classes.registrationHeading}
                            >
                                {label}

                            </Typography>
                        </StepButton>
                    </Step>
                ))}
            </Stepper> */}
            {/* <div className={classes.stepper}>
                <div className={classes.lineConnector}>

                </div>
                <Typography
                    variant="h3"
                    component="h3"
                    color={palette.primary.main}
                    fontSize={`${pxToRem(14)} !important`}
                    fontWeight={400}
                    lineHeight={'145% !important'}
                    zIndex={2}
                    className={classes.afterLine}
                    position={"relative"}
                // className={classes.registrationHeading}
                >
                    {"Outcomes"}

                </Typography>


                <Typography
                    variant="h3"
                    component="h3"
                    color={[2, 3].includes(currentStep) ? palette.primary.main : "#A0A0A1"}
                    fontSize={`${pxToRem(14)} !important`}
                    fontWeight={400}
                    lineHeight={'145% !important'}
                    zIndex={2}
                    className={classes.afterLine}
                    position={"relative"}

                // className={classes.registrationHeading}
                >
                    {"Influences"}

                </Typography>
                <Typography
                    variant="h3"
                    component="h3"
                    color={[3].includes(currentStep) ? palette.primary.main : "#A0A0A1"}
                    fontSize={`${pxToRem(14)} !important`}
                    fontWeight={400}
                    lineHeight={'145% !important'}
                    zIndex={2}
                // className={classes.registrationHeading}
                >
                    {"Reflective questions"}

                </Typography>

            </div> */}
        </>}

    </div>
}

export default PlanSelectionHeader