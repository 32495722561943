import React, { useContext } from "react";
import FullpageLoader from "../../components/fullPageLoader";
import { TrackingContext } from "../../context/userTracking";

const TracingLoader = () => {
  const { state: ITrackerState } = useContext(TrackingContext);
  const { showLoader } = ITrackerState;
  return showLoader && <FullpageLoader />;
};

export default TracingLoader;
