/*eslint eqeqeq: "off"*/
import React, { useEffect } from "react";
import { Stepper, Step, StepLabel, Button } from "@mui/material";
import { GoalStepper } from "../../../utils/staticDataJson";
import palette from "../../../theme/palette";
import StepperBackArrowDisable from "../../../assets/images/icon/stepperBackArrowDisable";
import StepperNextArrow from "../../../assets/images/icon/stepperNextArrow";
import StepperBackArrow from "../../../assets/images/icon/stepperBackArrow";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import StepperFinishedIcon from "../../../assets/images/icon/StepperFinishedIcon";



const FooterWrapper = styled("div")(({ theme }) => ({
  backgroundColor: '#FFF',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'fixed',
  bottom: 0,
  width: '100%',
  padding: "10px 0",
  zIndex: "9"
}));

interface BottomStepperModal {
  // isOutcome: boolean;
  // isInfluence: boolean;
  // isRefQues: boolean;
  // isTracking: boolean;
}

const BottomStepper = ({ onClickHandler }) => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const userType = GoalStepper;
  const location = useLocation()

  useEffect(() => {
    if (location.pathname === "/test-goals") {
      setActiveStep(0)
    } else if (location.pathname === "/question-page") {
      setActiveStep(1)
    }
  }, [])


  const handleNext = () => {
    if (activeStep === 1) {
      navigate("/tracking", { replace: true })
    } else {
      handleSliderNextValue(1);
    }
  };
  const handleSliderNextValue = (step: number) => {
    if (step == 0) {
      setActiveStep(0)
      navigate("/test-goals", { replace: true });
    }
    if (step == 1) {
      setActiveStep(1)
      navigate("/question-page");
    }

  };

  const handleBack = () => {
    handleSliderValue(0);
  };

  const handleSliderValue = (step: number) => {
    if (step === 0) {
      setActiveStep(0)
      navigate("/test-goals", { replace: true });
    }
  };


  return (
    <FooterWrapper>
      <div style={{ display: 'flex', width: '100%', padding: '0 60px' }}>
        <Button
          className="no-after"
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mr: 1, color: palette.primary.main, p: 0 }}
        >
          {activeStep === 0 ? <StepperBackArrowDisable /> : <StepperBackArrow />}
        </Button>
        <Stepper activeStep={activeStep} alternativeLabel connector={<></>}>
          {userType.map((label, index) => {
            return (
              <Step key={index}>
                <StepLabel>{label}</StepLabel>

              </Step>
            );
          })}
        </Stepper>
        <Button
          className="no-after"
          onClick={handleNext}
          sx={{ color: palette.primary.main, p: 0, cursor: "pointer" }}
        >
          {activeStep === 1 ? <StepperFinishedIcon /> : <StepperNextArrow />}
        </Button>
      </div>

    </FooterWrapper >
  );
};
export default BottomStepper;
