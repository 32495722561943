import React, { useContext, useEffect, useState } from "react";

import palette from "../../theme/palette";
import SideBar from "../../components/SideBar";
import { UserTypeContext } from "../../context/userStatus";
import FullpageLoader from "../../components/fullPageLoader";
import SnackBarComp from "../../components/snackBar";
import useWidth from "../../utils/useWidth";
import { Typography } from "@mui/material";
import BackIcon from "../../assets/images/icon/BackIcon";
import { useNavigate } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@material-ui/core";
import { FaSearch } from "react-icons/fa";
import { trackingService } from "../../services/tracking";

const useStyles = makeStyles((theme) => ({
  tryHeader: {
    color: "#3E4240",
    marginTop: "30px !important",
    fontWeight: 700,
    fontSize: "36px !important",
  },
  try: {
    color: "black",
    marginTop: "20px !important",
    fontWeight: 700,
    fontSize: "26px !important",
  },
  subHeading: {
    color: `${palette.secondary.contrast}!important`,
    fontSize: "16px",
    fontWeight: 400,
    display: "flex",
  },
  container: {
    backgroundColor: "white",
    borderLeft: "1px solid #DCE6E0",
    padding: "60px 40px",
  },
  table: {
    marginTop: "50px",
  },
  main: {
    display: "flex",
  },
  inactiveTab: {
    color: "#000",
    fontSize: "16px",
    fontWeight: 500,
    padding: "20px",
    paddingLeft: 0,
    cursor: "pointer",
  },
  activeTab: {
    color: "var(--color-new-main)",
    fontSize: "16px",
    fontWeight: 500,
    padding: "20px",
    paddingLeft: 0,
    cursor: "pointer",
  },
}));

function FAQComp() {
  const classes = useStyles();
  const { state: outcomeState } = useContext(UserTypeContext);
  const [showLoadingBtn, setShowLoadingBtn] = useState(false);
  const [showSnakbar, setShowSnakbar] = useState(false);
  const [snakbarMsg, setSnakbarMsg] = useState("");
  const [msgType, setMsgType] = useState("");
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [isExpandMode, setIsExpandMode] = useState(localStorage.getItem("isExpandMode") === "true");

  const [FaqData, setFaqData] = useState([]);
  const [ActiveTab, setActiveTab] = useState(FaqData?.[0]?.title);

  const handleCloseSnakbar = () => {
    setShowSnakbar(false);
  };

  const getFAQs = () => {
    setShowLoadingBtn(true);
    trackingService.getFAQAllTitle(outcomeState.token, {}).then((data) => {
      var FAQs = [];
      for (let i = 0; i < data.data.length; i++) {
        let body = {
          type: "APP",
          title: data.data[i],
        };
        trackingService.getFAQs(outcomeState.token, body).then((faq) => {
          FAQs = [
            ...FAQs,
            {
              title: data.data[i],
              questionAns: faq.data,
            },
          ];
          if (i == data.data.length - 1) {
            setShowLoadingBtn(false);
            setActiveTab(data.data[0]);
            setFaqData(FAQs);
          }
        });
      }
    }).finally(() => {
      setShowLoadingBtn(false);
    });
  };

  useEffect(() => {
    getFAQs();
  }, []);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const width = useWidth();

  const navigate = useNavigate();

  return (
    <div className={classes.main}>
      {showLoadingBtn && <FullpageLoader />}
      <SnackBarComp
        showSnakbar={showSnakbar}
        handleCloseSnakbar={handleCloseSnakbar}
        snakbarMsg={snakbarMsg}
        type={msgType ? msgType : "info"}
      />
      <SideBar isExpandMode={isExpandMode} setIsExpandMode={setIsExpandMode} />
      <Grid
        className={`track-title ${classes.container}`}
        style={{ width: isExpandMode ? "98%" : "91%" }}
      >
        <Typography
          variant="caption"
          component="caption"
          className={classes.subHeading}
        >
          <span
            style={{ color: "#3E4240", marginRight: "30px", cursor: "pointer" }}
            onClick={() => navigate("/settings")}
          >
            <BackIcon /> Back
          </span>{" "}
          Settings / <span style={{ color: "#3E4240" }}>FAQ</span>
        </Typography>
        <Typography
          variant="caption"
          component="div"
          className={classes.tryHeader}
        >
          FAQ
        </Typography>
        {FaqData && FaqData.length ? (
          <div
            style={{
              display: "flex",
              marginTop: "30px",
              flexDirection: width > 768 ? "row" : "column",
            }}
          >
            <div style={{ width: "40%" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingTop: "30px",
                }}
              >
                <input
                  type="text"
                  //   value={query}
                  //   onChange={(event) => setQuery(event.target.value)}
                  placeholder="Search for answers"
                  style={{
                    padding: "12px",
                    width: "80%",
                    border: "1px solid #ccc",
                    borderRadius: "8px",
                    fontSize: "16px",
                  }}
                />
                <button
                  type="submit"
                  style={{
                    border: "none",
                    background: "none",
                    marginLeft: "-40px",
                    cursor: "pointer",
                  }}
                >
                  <FaSearch color="#11734A" />
                </button>
              </div>
              <div style={{ marginTop: "20px" }}>
                {FaqData.map((faqParent) => {
                  return (
                    <div
                      className={
                        ActiveTab === faqParent.title
                          ? classes.activeTab
                          : classes.inactiveTab
                      }
                      onClick={() => setActiveTab(faqParent.title)}
                    >
                      {faqParent.title}
                    </div>
                  );
                })}
              </div>
            </div>
            <div style={{ width: "60%" }}>
              {FaqData?.map((faqParent) => {
                return (
                  <Box
                    style={{
                      display: faqParent.title === ActiveTab ? "block" : "none",
                      minWidth: "60% !important",
                    }}
                  >
                    <Typography
                      sx={{ marginBottom: "20px", paddingTop: "20px" }}
                      variant="caption"
                      component="div"
                      className={classes.try}
                    >
                      {faqParent.title}
                    </Typography>
                    {faqParent?.questionAns?.map((faq) => {
                      return (
                        <Accordion
                          expanded={expanded === faq?._id}
                          onChange={handleChange(faq?._id)}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <Typography
                              variant="subtitle1"
                              sx={{
                                fontWeight: 400,
                                color: palette.primary.light,
                              }}
                            >
                              {faq?.question}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography
                              variant="subtitle1"
                              sx={{
                                fontWeight: 400,
                                color: palette.primary.light,
                                display: "flex",
                              }}
                            >
                              <div
                                style={{
                                  height: "8px",
                                  minWidth: "8px",
                                  backgroundColor: "#169C63",
                                  borderRadius: "999px",
                                  marginTop: "5px",
                                  marginRight: "5px",
                                }}
                              ></div>
                              {faq?.answer}
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}
                  </Box>
                );
              })}
            </div>
          </div>
        ) : <div
          className={classes.inactiveTab}
        >
          No FAQ Found
        </div>}

      </Grid>
    </div>
  );
}

export default FAQComp;
