import * as React from 'react';
import AddGoals from './addGoals';
import GoalTrackingModal from './goalTrackingModal';

const GoalsView=()=>{
    return(
    //    <GoalTrackingModal/>
    <AddGoals/>
    )
}
export default GoalsView