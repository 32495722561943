import React, { useContext, useEffect, useState } from "react"
import { Autocomplete, Box, Paper, TextField } from "@mui/material";
import CommonTypo from "../../../components/CommonTypo";
import { pxToRem } from "../../../theme/typography";
import palette from "../../../theme/palette";
import { InsightTypeContext } from "../../../context/userInsights";
import DataEntryInfluence from "./DataEntry";
import InfluenceChart from "./Chart";
import PositiveNegativeInfluences from "./PositiveNegativeInfluence";
import MoreButton from "../MoreButton";
import GoalsInfluences from "./Goals";
import { influenceService } from "../../../services/influence";
import { UserTypeContext } from "../../../context/userStatus";
import { InfluenceGetResponse } from "../../../interfaces/insightsModal";
import { addInfluenceAction } from "../../../store/actions/userTypeAction";
import { insightsService } from "../../../services/insightsService";
import moment from "moment";
import CongratulationsModal from "../common/congratulations";

const ArrowIcon = () => <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1090_701)">
        <path d="M13.825 6.6665L10 10.4832L6.175 6.6665L5 7.8415L10 12.8415L15 7.8415L13.825 6.6665Z" fill="#656565" />
    </g>
    <defs>
        <clipPath id="clip0_1090_701">
            <rect width="20" height="20" fill="white" />
        </clipPath>
    </defs>
</svg>

const InfluenceInsights = () => {
    const { state: InsightState, dispatch: insightTypeDispatch } =
        useContext(InsightTypeContext);
    const { state: IUserTypeState, dispatch: userTypeDispatch } =
        useContext(UserTypeContext);
    const [open, setOpen] = useState(false);
    const [loadingResponse, setLoadingResponse] = useState(false);
    const [selectedInfluenceId, setSelectedInfluenceId] = useState(null);
    const [influenceResponse, setInfluenceResponse] = useState<any>({});
    const [influenceCongratsModalVisible, setInfluenceCongratsModal] = useState(false);
    const [influenceCongratsModalData, setInfluenceCongratsModalData] = useState([]);
    useEffect(() => {
        getInfluenceList()
    }, [])
    useEffect(() => {
        const userId = {};
        insightsService
            .getInfuenceCelebration(userId, IUserTypeState.token)
            .then((data: any) => {
                console.log("data insightsService", data);
                
                if (data.response && data.response.length) {
                    setInfluenceCongratsModalData(data.response)
                    setInfluenceCongratsModal(true)
                }
            })
            .catch((error) => {
                console.log("User profile Error", error);
            });
    }, [])
    useEffect(() => {
        console.log("selectedInfluenceId", selectedInfluenceId);

        if (selectedInfluenceId && selectedInfluenceId.influenceId) getInfluenceResposne()
    }, [selectedInfluenceId])

    const getInfluenceResposne = () => {
        console.log("selectedInfluenceId", selectedInfluenceId);
        setLoadingResponse(true)
        const userId: any = {
            "influenceId": selectedInfluenceId.influenceId,
            // "dateType": selectedRange,
            "from": moment().subtract(5, 'year').startOf("year").format(),
            "to": moment().endOf("year").format(),
            influenceType: selectedInfluenceId.type === "TYPE3" && [true, "true"].includes(selectedInfluenceId.inverse)
                ? "INFLUENCETYPE3INVERSE"
                : selectedInfluenceId.type === "TYPE3"
                    ? "INFLUENCETYPE3"
                    : selectedInfluenceId.type
        };
        insightsService
            .getInfluenceInsights(userId, IUserTypeState.token)
            .then((data: any) => {
                setInfluenceResponse(data)
                setLoadingResponse(false)
            })
            .catch((error) => {
                console.log("User profile Error", error);
                setLoadingResponse(false)
            });
    }

    const getInfluenceList = () => {
        influenceService
            .getInfluenceList(IUserTypeState.token)
            .then((data: InfluenceGetResponse) => {
                userTypeDispatch(addInfluenceAction(data?.data));
            })
            .catch((error) => {
                console.log("influence Error");
            });
    };
    const defaultProps = {
        options: IUserTypeState.influenceList && IUserTypeState.influenceList.length > 0 ? IUserTypeState.influenceList.sort(function (a, b) {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
                return 1;
            }
            return 0;
        }) : [],
        getOptionLabel: (option) => option.name,
    };
    let responseCount = 0
    if (influenceResponse && influenceResponse.data) {
        const { data = [] } = influenceResponse
        responseCount = data[0]?.responseCount
    }

    return (
        <>
            <Box sx={{ flexGrow: 1, }}>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "32px"
                }}>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "16px"
                    }}>
                        <CommonTypo size={16} color="#656565" fontWeight={400} extraSx={{
                        }}>{"Choose the influence to see it data"}</CommonTypo>
                        <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} gap={"4px"}>
                            <Autocomplete
                                className="fillTextInput weekSelect"
                                fullWidth
                                // value={selectedLeft}
                                sx={{
                                    position: "relative",
                                    "& .MuiOutlinedInput-root": {
                                        padding: 0,
                                    },
                                }}
                                renderGroup={(params) => {
                                    return (
                                        <li key={params.key}>
                                            <Box sx={{ padding: '10px 10px' }}>
                                                <CommonTypo color={palette.primary.light} fontWeight={700} size={16} lineHeight={20} extraSx={{
                                                    cursor: "pointer",
                                                    // textAlign: "center !important",
                                                    fontWeight: `700 !important`,
                                                    "&.MuiTypography-root": {
                                                        "@media  (max-width: 600px) and (min-width: 100px)": {
                                                            fontSize: `${pxToRem(16)} !important`,
                                                            lineHeight: "100% !important",
                                                        },
                                                    },
                                                }}>{params.group}</CommonTypo>
                                            </Box>
                                            <Box sx={{ padding: '0 0px' }}>
                                                {params.children}
                                            </Box>
                                        </li>
                                    )
                                }}
                                groupBy={(option) => option.category}
                                getOptionDisabled={(option) => option.disabled}
                                autoComplete
                                onOpen={() => setOpen(true)}
                                onClose={() => setOpen(false)}
                                popupIcon={<ArrowIcon />}
                                PaperComponent={({ children }) => (
                                    <Paper
                                        style={{ background: "#FFF" }}
                                        sx={{
                                            "&.MuiAutocomplete-option": {
                                                color: "rgb(0, 0, 0)",
                                                fontSize: "18px",
                                            },
                                            "& .MuiAutocomplete-listbox": {
                                                padding: "0px",
                                                "& li": {
                                                    padding: `10px`,
                                                    color: "#5E5F5E !important",
                                                    fontSize: "16px !important",
                                                    fontWeight: "400 !important",
                                                    lineHeight: "24px",
                                                },
                                            },
                                        }}
                                    >
                                        {children}
                                    </Paper>
                                )}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        sx={{
                                            border: "1px solid #EEEEEE",
                                            borderRadius: "8px",
                                            "&.MuiOutlinedInput-root": {
                                                padding: 0,

                                            },
                                            "& .MuiOutlinedInput-root": {
                                                background: "#FFF",
                                                border: "none"
                                            }
                                        }}
                                        className="fillTextInput weekSelect"
                                        label="Select one"
                                    />
                                )}
                                onChange={(e, newInputValue) => {
                                    console.log("newInputValue", newInputValue);
                                    setSelectedInfluenceId(newInputValue);
                                }}
                                {...defaultProps}
                            />
                            {responseCount >= 35 && <Box sx={{
                                border: "1px solid #EEEEEE",
                                padding: "16px",
                                borderRadius: "12px",
                                display: "flex",
                                alignItems: "center",
                                gap: "4px"
                            }}>
                                <CommonTypo fontWeight={600} size={16} color="#3E4240">{responseCount} days</CommonTypo>
                                <CommonTypo fontWeight={500} size={16} color="#3E4240">tracked</CommonTypo>
                            </Box>}

                        </Box>

                        <MoreButton showMoreButton={false} active={false} onClick={() => { }} fullHeight={false} />

                    </Box>
                    {selectedInfluenceId && selectedInfluenceId.influenceId && !loadingResponse && (
                        <>
                            {responseCount < 35 && <DataEntryInfluence
                                setSelectOutcome={() => { }}
                                selectOutcome={selectedInfluenceId}
                                outcomeData={InsightState.insights.data}
                                influenceResponse={influenceResponse}
                            />}
                            {responseCount >= 14 && (
                                <>
                                    <InfluenceChart selectOutcome={selectedInfluenceId} outcomeData={InsightState.insights.data} />
                                    {responseCount >= 28 && <PositiveNegativeInfluences influenceResponse={influenceResponse} />}
                                    <GoalsInfluences influenceResponse={influenceResponse} selectOutcome={selectedInfluenceId} />
                                </>
                            )}

                        </>
                    )}

                </Box>
                <CongratulationsModal type="influence" visible={influenceCongratsModalVisible} onClose={() => {
                    setInfluenceCongratsModal(false)
                }} data={influenceCongratsModalData} />
            </Box>
        </>
    )
}

export default InfluenceInsights