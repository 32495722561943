import React from "react";
import { tooltipClasses, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Info from "../assets/images/icon/Info";
import palette from "../theme/palette";
const HtmlTooltip = styled(({ ...props }: any) => (
    <Tooltip children={undefined} title={""} {...props} classes={{ popper: props.className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#FFF',
        maxWidth: 550,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid var(--color-new-main)',
        borderRadius: '6px',
        padding: '12px',
        color: palette.common.black,
    },
    
}));

const HTMLTooltip = ({ title = "" as string | React.ReactNode, placement = "bottom", showArrow = true, sx = {}, customIcon = null, fontProps = {}, customProps = {} }) => {
    return title ? <HtmlTooltip
        title={
            <Typography color="inherit" {...fontProps}>{title}</Typography>
        }
        // open={true}
        {...customProps}
        placement={placement}
        arrow={showArrow}
        sx={sx && Object.keys(sx).length ? {
            ...sx,
            '& .MuiTooltip-arrow': {
                color: "#FFF",
                // borderTop: '1px solid #9747FF',
                // borderLeft: '1px solid #9747FF',
                // transform: 'translate3d(4px, 33px, 11px) rotate(-38deg)!important'
                '&:before': {
                    border: "1px solid var(--color-new-main)"
                }
            }
        } : {
            '& .MuiTooltip-arrow': {
                color: "#FFF",
                // borderTop: '1px solid #9747FF',
                // borderLeft: '1px solid #9747FF',
                // transform: 'translate3d(4px, 33px, 11px) rotate(-38deg)!important'
                '&:before': {
                    border: "1px solid var(--color-new-main)"
                }
            }
        }}
        disableInteractive
    >{customIcon ? customIcon : <Info />}</HtmlTooltip> : customIcon ? customIcon : null
}

export default HTMLTooltip