import { Grid, styled, Typography, Box, Button } from "@mui/material";
import React, { useState } from "react";
import SideBar from "../../components/SideBar";
import { makeStyles } from "@material-ui/core";
import palette from "../../theme/palette";
import RightArrowIcon from "../../assets/images/icon/right_arrow";
import CoachItem from "./coachItem";

const RootStyle = styled("div")(({ theme }) => ({
  padding: "60px 40px 0px",

  [theme.breakpoints.down("lg")]: {
    padding: "40px 20px 0px",
  },
  [theme.breakpoints.down(1001)]: {
    padding: "20px",
  },
}));

const useStyles = makeStyles((theme) => ({
  progressTitle: {
    color: palette.primary.light,
    lineHeight: "43.2px!important",
  },
  blogTitle: {
    color: palette.primary.light,
  },
}));

const GetCoachView = () => {
  const classes = useStyles();
  const [isExpandMode, setIsExpandMode] = useState(localStorage.getItem("isExpandMode") === "true");
  return (
    <Grid
      container
      columns={16}
      height={"100vh"}
      className="tracking_container"
    >
      <Grid
        item
        sx={{ borderRight: "1px solid #DCE6E0" }}
        xs={isExpandMode ? 0.73 : 2.04}
      >
        <SideBar
          isExpandMode={isExpandMode}
          setIsExpandMode={setIsExpandMode}
        />
      </Grid>

      <Grid item className="track-body-grid" xs={isExpandMode ? 15.27 : 13.96}>
        <RootStyle className="track-body">
          <Typography variant="h1" className={classes.progressTitle}>
            Get a coach
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            marginTop="40px"
            marginBottom="24px"
          >
            <Typography variant="h3" className={classes.blogTitle}>
              Coaches in your area
            </Typography>
          </Box>
          <CoachItem />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            marginTop="40px"
            marginBottom="24px"
          >
            <Typography variant="h3" className={classes.blogTitle}>
              Top coaches
            </Typography>
          </Box>
          <CoachItem />
        </RootStyle>
      </Grid>
    </Grid>
  );
};
export default GetCoachView;
