/*eslint array-callback-return: "off"*/
import { Box, Typography } from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core";
import palette from "../../theme/palette";
import { styled } from "@mui/material/styles";
import shape from "../../theme/shape";
import { outcomeService } from "../../services/outcome";
import { UserTypeContext } from "../../context/userStatus";
import { SvgIcon } from "@mui/material";
import useWidth from "../../utils/useWidth";

const RootStyle = styled("div")(({ theme }) => ({
  padding: "60px 0",
  [theme.breakpoints.down("lg")]: {
    padding: "50px 0",
  },
  [theme.breakpoints.down("md")]: {
    padding: "40px 0",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "20px 0",
    marginBottom: "20px",
  },
}));

const useStyles = makeStyles((theme) => ({
  recommText: {
    backgroundColor: palette.common.white,
    borderRadius: shape.borderRadius,
    fontSize: "16px",
    lineHeight: "36px",
    color: palette.primary.light,
    padding: "12px 16px",
    cursor: "pointer",
    marginBottom: "12px",
    wordBreak: "break-word",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
      lineHeight: "20px",
    },
  },
  infoWarning: {
    fontSize: "12px",
    lineHeight: "12px",
    fontWeight: 400,
    color: "#9BA19D",
    textAlign: "center",
    marginTop: "8pxS",
  },
  recommTitle: {
    marginBottom: "46px",
    [theme.breakpoints.down("md")]: {
      marginBottom: "36px",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "20px",
    },
  },
  colorCode: {
    width: "36px",
    height: "36px",
    padding: "8px",
    borderRadius: shape.borderRadiusSm,
    marginRight: "12px",
    [theme.breakpoints.down("sm")]: {
      width: "25px",
      height: "25px",
      padding: "3px",
    },
  },
}));

interface RecommendedListModal {
  setOutcomeValue: (outComeValue: string) => void;
  setOutcomeIdeas: (outcomeIDeas: any) => void;
}

const RecommendedList = (props: RecommendedListModal) => {
  const classes = useStyles();
  const width = useWidth();
  const [outcomeSuggestionList, setOutcomeSuggestionList] = useState([]);
  const { state: outcomeState } = useContext(UserTypeContext);
  useEffect(() => {
    // GET OUTCOME SUGGESTION LIST
    outcomeService
      .getOutcomeSuggestion(outcomeState.token)
      .then((res: any) => {
        setOutcomeSuggestionList(res?.data);
        props.setOutcomeIdeas(res?.ideas);
      })
      .catch((error: any) => {
        console.log("Outcome suggestion error", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RootStyle
    style={{ visibility: width < 768 ? "hidden" : "visible"}}
    >
      <Box display="flex" alignItems="center" className={classes.recommTitle}>
        <Typography variant="h3" component="h3">
          Get started with our recommended outcomes
        </Typography>
      </Box>
      <Box>
        {outcomeSuggestionList?.map((items: any, index: number) => {
          let flag = true;
          if (outcomeState?.outcomeList?.length > 0)
            for (let outObj of outcomeState?.outcomeList) {
              if (outObj.name.toLowerCase() === items.name.toLowerCase()) {
                flag = false;
                break;
              }
            }
          if (flag) {
            return (
              <div
                key={index}
                className={classes.recommText}
                onClick={() => props.setOutcomeValue(items)}
              >
                <span
                  className={classes.colorCode}
                  style={{
                    backgroundColor: items?.options[0]?.colorCode,
                  }}
                >
                  <SvgIcon
                    color="action"
                    fontSize="small"
                    sx={{ verticalAlign: "middle" }}
                  >
                    <path d={items?.icon} fill={palette.common.white} />
                  </SvgIcon>
                </span>
                {items.name}
              </div>
            );
          }
        })}
      </Box>
      <p className={classes.infoWarning}>
        *You can add more outcomes after the 30 days of baseline tracking
      </p>
    </RootStyle>
  );
};
export default RecommendedList;
