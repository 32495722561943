import React from 'react'

const Dots = () => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <circle cx="6" cy="12" r="1.5" fill="var(--color-new-main)" />
                <circle cx="12" cy="12" r="1.5" fill="var(--color-new-main)" />
                <circle cx="18" cy="12" r="1.5" fill="var(--color-new-main)" />
            </svg>
        </>
    )
}

export default Dots
