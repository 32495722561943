import React from "react";

const ViewNote = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 21.5H6C5.44772 21.5 5 21.0523 5 20.5V4.5C5 3.94772 5.44772 3.5 6 3.5H18C18.5523 3.5 19 3.94772 19 4.5V20.5C19 21.0523 18.5523 21.5 18 21.5Z"
        stroke="var(--color-new-main)"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <line
        x1="8"
        y1="8.25"
        x2="16"
        y2="8.25"
        stroke="var(--color-new-main)"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <line
        x1="8"
        y1="12.75"
        x2="16"
        y2="12.75"
        stroke="var(--color-new-main)"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <line
        x1="8"
        y1="17.25"
        x2="16"
        y2="17.25"
        stroke="var(--color-new-main)"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <line
        x1="9"
        y1="1.5"
        x2="9"
        y2="5.5"
        stroke="var(--color-new-main)"
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <line
        x1="15"
        y1="1.5"
        x2="15"
        y2="5.5"
        stroke="var(--color-new-main)"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ViewNote;
