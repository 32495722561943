import {
  DialogContent,
  Button,
  Dialog,
  DialogActions,
  Typography,
} from "@mui/material";
import React from "react";
import { makeStyles } from "@material-ui/core";
import NewButton from "../../components/NewButton";

interface IDeleteModal {
  open: boolean;
  setopenDeleteModal: any;
  deleteData?: any;
  actionId?: any;
  RowData?: any;
}

const useStyles = makeStyles((theme) => ({
  categoryDialog: {
    "& .MuiDialog-container": {
      "& .MuiPaper-root": {
        borderRadius: "20px",
        padding: "48px 40px",
        width: "682px",

        "& .MuiDialogContent-root": {
          padding: "0",
          overflowY: "unset",
        },

        "&. MuiDialogActions-root": {},
      },
    },
  },
  categoryDialogAction: {
    display: "flex !important",
    alignItems: "center !important",
    justifyContent: "end !important",
  },
}));
const DeleteModal = (props: IDeleteModal) => {
  const classes = useStyles();

  return (
    <Dialog
      open={props.open}
      onClose={() => props?.setopenDeleteModal(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.categoryDialog}
    >
      <DialogContent id="alert-dialog-description">
        <Typography variant="h3" component="h3" lineHeight="120% !important">
          {/* Are you sure to transform {props?.outcomeValueInput} to an influence? */}
          Are you sure to delete the metric ‘{props?.RowData?.name}’?
          {/* '{props.SelectRow.name}?#{props.ReverseValue}' */}
        </Typography>

        <Typography
          variant="subtitle1"
          component="div"
          sx={{ mt: "20px", display: "flex" }}
        >
          <span style={{ color: "red", marginRight: "10px", fontSize: "20px" }}>
            !
          </span>
          You have track {props?.RowData?.times_tracked} times of it, Once you
          delete it, you’ll never be able to find it.
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{ mt: "60px" }}
        className={classes.categoryDialogAction}
      >
        <NewButton
          type="submit"
          variant="contained"
          onClick={() => props?.setopenDeleteModal(false)}
          buttonText="No, take me back"
        />
        <NewButton
          variant="outlined"
          onClick={() => {
            props?.setopenDeleteModal(false);
            props?.deleteData(props?.actionId);
          }}
          buttonText="Yes"
        />
      </DialogActions>
    </Dialog>
  );
};

export default DeleteModal;
