import React, { useState, useContext, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { pxToRem } from "../../theme/typography";
import GoogleIcon from "../../assets/images/icon/google-icon";
import FacebookIcon from "../../assets/images/icon/facebook-icon";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import palette from "../../theme/palette";
import { facebookProvider, googleProvider } from "../../config/authMethods";
import { userService } from "../../services/auth";
import SnackBarComp from "../../components/snackBar";
import { confirmPasswordReset, getAuth, sendPasswordResetEmail, signInWithEmailAndPassword, verifyPasswordResetCode } from "firebase/auth";
import { auth } from "../../config/firebase";
import { ButtonLoader } from "../../components/buttonLoader";
import { UserTypeContext } from "../../context/userStatus";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import {
  setUserId,
  setUserLevel,
  setUserStatus,
  setUserToken,
} from "../../store/actions/userTypeAction";
import FullpageLoader from "../../components/fullPageLoader";
import { UserLoginModal } from "../../interfaces/outcomeModal";

const validationSchema = yup.object({
  new_password: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
  confirm_password: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required")
    .oneOf([yup.ref('new_password'), null], 'New password and confirm password did not match'),
});
const newPasswordScreen = true
const RootStyle = styled("div")(({ theme }) => ({
  padding: "0 80px",
  [theme.breakpoints.up("xl")]: {
    padding: "60px",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "40px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "30px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "20px",
  },
}));

const useStyles = makeStyles((theme) => ({
  registrationHeading: {
    color: palette.primary.light,
    marginBottom: "32px!important",
    [theme.breakpoints.down("lg")]: {
      marginBottom: "20px!important",
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: "10px!important",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "5px!important",
    },
  },
  registrationSubHeading: {
    color: "#190D1A",
    marginBottom: "62px!important",

    fontSize: `${pxToRem(16)} !important`,
    [theme.breakpoints.down("lg")]: {
      marginBottom: "50px!important",
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: "40px!important",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "30px!important",
    },
  },
  registrationSubHeading2: {
    color: "#B4B0B4 !important",
    marginBottom: "62px!important",

    fontSize: `${pxToRem(16)} !important`,
    [theme.breakpoints.down("lg")]: {
      marginBottom: "50px!important",
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: "40px!important",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "30px!important",
    },
  },
  socialLoginButton: {
    marginTop: pxToRem(24),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  alreadyAccountText: {
    marginTop: pxToRem(1),
    color: "#9BA19D",
    fontSize: pxToRem(16),
    lineHeight: pxToRem(1),
    fontWeight: 400,
    textAlign: "center",
  },
  forgetPasswordText: {
    color: "#9BA19D",
    textAlign: "right",
    fontSize: pxToRem(14),
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "145%", /* 20.3px */
    letterSpacing: "-0.14px",
    cursor: "pointer"
  },
  alreadyAccountTextSpan: {
    color: "#11734A",
    cursor: "pointer",
  },
}));

const ariaLabel = { "aria-label": "description" };

const RightPartResetPassword = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [showSnakbar, setShowSnakbar] = useState(false);
  const [snakbarMsg, setSnakbarMsg] = useState("");
  const [msgType, setMsgType] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [viewNewPassword, setViewNewpassword] = useState(false)
  const [viewConfirmPassword, setViewConfirmpassword] = useState(false)
  const [showLoaderPage, setShowLoaderPage] = useState(false);
  const { dispatch: userTypeDispatch } = useContext(UserTypeContext);
  const [viewSentLink, setViewSentLink] = useState(false)
  const params = useParams();
  const search = useLocation().search;
  const mode = new URLSearchParams(search).get('mode');
  const oobCode = new URLSearchParams(search).get('oobCode');
  const apiKey = new URLSearchParams(search).get('apiKey');
  const continueUrl = new URLSearchParams(search).get('continueUrl');

  useEffect(() => {
    userTypeDispatch(setUserId(undefined));
    setShowLoaderPage(true)
    verifyPasswordResetCode(auth, oobCode).then((email) => {
      if(continueUrl) {
        window.location.replace(`${continueUrl}/reset-password?mode=${mode}&oobCode=${oobCode}`)
      }
      setShowLoaderPage(false)

    }).catch(err => {
      setShowLoaderPage(false)
      if(err.message.includes("auth/invalid-action-code")) {
        setShowSnakbar(true)
        setMsgType("error")
        setSnakbarMsg("Password already reset for this account or link expired");
        setTimeout(() => {
          navigate("/login")
        }, 500);
      }
      console.log("err", err)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // FIREBASE AUTHENTICATION
  const emailAuth = async (email: string, password: string) => {
    return signInWithEmailAndPassword(auth, email, password)
      .then((result) => {
        return result;
      })
      .catch((err) => {
        setShowLoader(false);
        setShowSnakbar(true);
        setMsgType("error");
        if (err.code === "auth/user-not-found") {
          setSnakbarMsg("User not found");
        } else if (err.code === "auth/wrong-password") {
          setSnakbarMsg("Please enter right credential");
        } else {
          setSnakbarMsg(err.message);
        }
        return err;
      });
  };
  const firebaseResetPassword = async (newPassword: string) => {
    try {
      const auth = getAuth();
      await confirmPasswordReset(auth,oobCode, newPassword);
      setShowLoader(false);
      setShowSnakbar(true)
      setMsgType("success")
      setSnakbarMsg("Password Reset successfully, Please login");
      formik.resetForm()
      setTimeout(() => {
        navigate("/login")
      }, 2000);
    } catch (error) {
      if (error && error.message && error.message.includes("auth/user-not-found")) {
        setShowSnakbar(true)
        setMsgType("error")
        setSnakbarMsg("User not found");
      }
      console.log("error", error, error.message)
      setShowLoader(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      confirm_password: "",
      new_password: ""
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setShowLoader(true);
      firebaseResetPassword(values.confirm_password);
    },
  });
  const handleCloseSnakbar = () => {
    setShowSnakbar(false);
  };

  return (
    <RootStyle className="login-right-part">
      {showLoaderPage && <FullpageLoader />}
      <SnackBarComp
        showSnakbar={showSnakbar}
        handleCloseSnakbar={handleCloseSnakbar}
        snakbarMsg={snakbarMsg}
        type={msgType ? msgType : "info"}
      />

      <>
        <Typography
          variant="h1"
          component="h1"
          className={classes.registrationHeading}
        >
          Reset your password
        </Typography>
        <Grid>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              placeholder="New password"
              variant="outlined"
              fullWidth
              type={viewNewPassword ? "text" : "password"}
              name="new_password"
              value={formik.values.new_password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.new_password && Boolean(formik.errors.new_password)}
              helperText={formik.touched.new_password && formik.errors.new_password}
              inputProps={ariaLabel}
              InputProps={{
                endAdornment: viewNewPassword ? <Box onClick={() => {
                  setViewNewpassword(false)
                }}>

                  <VisibilityOffOutlinedIcon sx={{
                    opacity: 0.3
                  }} />
                </Box> : <Box onClick={() => {
                  setViewNewpassword(true)
                }}><VisibilityOutlinedIcon sx={{
                  opacity: 0.3
                }} /></Box>,
              }}

            />
            <TextField
              placeholder="Confirm Password"
              variant="outlined"
              fullWidth
              type={viewConfirmPassword ? "text" : "password"}
              name="confirm_password"
              value={formik.values.confirm_password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.confirm_password && Boolean(formik.errors.confirm_password)}
              helperText={formik.touched.confirm_password && formik.errors.confirm_password}
              inputProps={ariaLabel}
              InputProps={{
                endAdornment: viewConfirmPassword ? <Box onClick={() => {
                  setViewConfirmpassword(false)
                }}>

                  <VisibilityOffOutlinedIcon sx={{
                    opacity: 0.3
                  }} />
                </Box> : <Box onClick={() => {
                  setViewConfirmpassword(true)
                }}><VisibilityOutlinedIcon sx={{
                  opacity: 0.3
                }} /></Box>,
              }}
            />
            {!showLoader ? (
              <Button
                type="submit"
                variant="contained"
                className="button-after"
                fullWidth
                sx={{ mt: pxToRem(24), mb: pxToRem(30) }}
                disabled={!(formik.isValid && formik.dirty)}
              >
                Change Password
              </Button>
            ) : (
              <Button
                variant="contained"
                className="button-after"
                fullWidth
                sx={{ mt: pxToRem(24), mb: pxToRem(57) }}
              >
                <ButtonLoader />
              </Button>
            )}
          </form>
          {/* <p className={classes.alreadyAccountText}>
            Back to {" "}
            <span
              className={classes.alreadyAccountTextSpan}
              onClick={() => navigate("/login")}
            >
              Log in
            </span>
          </p> */}
        </Grid>
      </>

    </RootStyle>
  );
};
export default RightPartResetPassword;
