/*eslint @typescript-eslint/no-unused-vars: "off"*/
import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import palette from "../../theme/palette";
import LeftSideInfluence from "./leftSideInfluence";
import RightSideInfluence from "./rightSideInfluence";
import { UserTypeContext } from "../../context/userStatus";
import SnackBarComp from "../../components/snackBar";
import { influenceService } from "../../services/influence";
import { InfluenceGetResponse } from "../../interfaces/outcomeModal";
import { addInfluenceAction } from "../../store/actions/userTypeAction";
import { OutcomeStatus } from "../../utils/staticDataJson";
import FullpageLoader from "../../components/fullPageLoader";
import useWidth from "../../utils/useWidth";

const AddInfluences = () => {
  const { state: outcomeState, dispatch: userTypeDispatch } =
    useContext(UserTypeContext);
  const [influenceData, setInfluenceData] = React.useState();
  const [textInput, setTextInput] = useState(OutcomeStatus);
  const [showInfluence, setShowInfluence] = useState(false);
  const [showSnakbar, setShowSnakbar] = useState(false);
  const [snakbarMsg, setSnakbarMsg] = useState("");
  const [msgType, setMsgType] = useState("");
  const [influenceIdeas, setInfluenceIdeas] = useState();
  const [addInfluenceTracking, setAddInfluenceTracking] = useState(false);
  const [fullpageLoader, setFullPageLoader] = useState(true);
  const width = useWidth();
  useEffect(() => {
    // GET INFLUENCE LIST
    influenceService
      .getInfluenceList(outcomeState.token)
      .then((data: InfluenceGetResponse) => {
        setFullPageLoader(false);
        userTypeDispatch(addInfluenceAction(data?.data));
      })
      .catch((error) => {
        setShowSnakbar(true);
        setSnakbarMsg(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message
        );
        setMsgType("error");
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleCloseSnakbar = () => {
    setShowSnakbar(false);
  };
  return (
    <>
      {fullpageLoader && <FullpageLoader />}
      <Grid
        container
        columns={16}
        sx={{ backgroundColor: palette.secondary.light }}
      >
        {/* SNACK BAR */}
        <SnackBarComp
          showSnakbar={showSnakbar}
          handleCloseSnakbar={handleCloseSnakbar}
          snakbarMsg={snakbarMsg}
          type={msgType ? msgType : "info"}
        />
        {/* START LEFT SECTION */}
        <Grid
          sx={{ backgroundColor: palette.common.white }}
          item
          xs={9.63}
          className="leftside-menu login-screen outcome-screen outcome-left-layout"
        >
          <LeftSideInfluence
            setInfluenceData={setInfluenceData}
            influenceData={influenceData}
            influenceIdeas={influenceIdeas}
            setShowSnakbar={setShowSnakbar}
            setMsgType={setMsgType}
            setSnakbarMsg={setSnakbarMsg}
            showInfluence={showInfluence}
            setAddInfluenceTracking={setAddInfluenceTracking}
            setShowInfluence={setShowInfluence}
            setTextInput={setTextInput}
            textInput={textInput}
            onboarding={true}
          />
        </Grid>
        {/* END LEFT SECTION */}

        {/* START RIGHT SECTION */}
        <Grid
          item
          xs={6.37}
          className="rightside-menu outcome-menu outcome-right-layout"
          sx={{ backgroundColor: palette.common.bgColor }}
        >
         {width > 768 && <RightSideInfluence
            setInfluenceData={setInfluenceData}
            setInfluenceIdeas={setInfluenceIdeas}
          />}
        </Grid>
        {/* END RIGHT SECTION */}
      </Grid>
    </>
  );
};
export default AddInfluences;
