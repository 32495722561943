import { Grid, styled } from "@mui/material";
import React, { useState } from "react";
import SideBar from "../../components/SideBar";
import ProgressTitle from "./progressTitle";
import ProgressOutcomes from "./progressOutcomes";
import ProgressInfluences from "./progressInfluences";
import RatingGraph from "./ratingGraph";
import { makeStyles } from "@material-ui/core";

const RootStyle = styled("div")(({ theme }) => ({
  padding: "60px 40px 0px",

  [theme.breakpoints.down("lg")]: {
    padding: "40px 20px 0px",
  },
  [theme.breakpoints.down(1001)]: {
    padding: "20px",
  },
}));

const useStyles = makeStyles((theme) => ({
  progressInfluence: {
    [theme.breakpoints.down(1001)]: {
      maxWidth: "100%!important",
    },
  },
  ratingGraph: {
    [theme.breakpoints.down(1001)]: {
      maxWidth: "100%!important",
      marginTop: "48px!important",
    },
  },
}));

const Progress = () => {
  const classes = useStyles();
  const [isExpandMode, setIsExpandMode] = useState(localStorage.getItem("isExpandMode") === "true");
  return (
    <Grid
      container
      columns={16}
      height={"100vh"}
      className="tracking_container"
    >
      <Grid item sx={{ borderRight: "1px solid #DCE6E0" }} xs={isExpandMode ? 0.73 : 2.04}>
        <SideBar isExpandMode={isExpandMode} setIsExpandMode={setIsExpandMode} />
      </Grid>

      <Grid item className="track-body-grid" xs={isExpandMode ? 15.27 : 13.96}>
        <RootStyle className="track-body">
          <ProgressTitle />
          <ProgressOutcomes />
          <Grid container columns={16} marginTop="28px">
            <Grid item xs={10.7} className={classes.progressInfluence}>
              <ProgressInfluences />
            </Grid>
            <Grid item xs={5.3} className={classes.ratingGraph}>
              <RatingGraph />
            </Grid>
          </Grid>
        </RootStyle>
      </Grid>
    </Grid>
  );
};

export default Progress;
