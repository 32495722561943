import { Box, Grid } from "@mui/material";
import React from "react";
import { makeStyles } from "@material-ui/core";
import palette from "../../theme/palette";
import EditOutcomeStatus from "./editOutcomeStatus";
import shape from "../../theme/shape";
import useWidth from "../../utils/useWidth";
import EditOutcomeStatusNew from "./editOutcomeStatusNew";

const useStyles = makeStyles((theme) => ({
  stepperNumber: {
    width: "30px",
    height: "30px",
    color: palette.common.white,
    borderRadius: shape.borderRadiusMd,
    fontSize: "16px",
    lineHeight: "30px",
    fontWeight: 500,
    textAlign: "center",
    position: "relative",
    display: "inline-block",
    zIndex: 1,
  },
  stepperLine: {
    position: "relative",
    justifyContent: "space-between",
    display: "flex",
    alignItems: "center",
    gap: '16px',
    "&:after": {
      content: "''",
      position: "absolute",
      display: "inline-block",
      left: "80px",
      top: "12px",
      border: "0.1px solid #CADFD2",
      width: "85%",
    },
  },
  stepperRectangle: {
    width: '12px',
    height: '12px',
    transform: 'rotate(45deg)',
    position: 'absolute',
    left: '65.515px',
    top: '-8px',
    borderRadius: '2px 0px 0px 0px',
    borderTop: '2px solid #F3F3F3',
    borderLeft: '2px solid #F3F3F3',
    background: "#fff"
  },
  stepperLineMobile: {
    position: "relative",
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    "&:after": {
      content: "''",
      position: "absolute",
      display: "inline-block",
      left: "15px",
      top: "45px",
      border: "0.1px solid #CADFD2",
      // width: "670px",
      height: "360px"
    },
  },
  stepperWrapper: {
    cursor: "pointer",
    display: 'flex',
    gap: '10px',
    borderRadius: '12px',
    border: '2px solid #F3F3F3',
    padding: '12px',
    alignItems: 'flex-start',
    justifyContent: "space-between",
    backgroundColor: "#FFF",
    width: "148px",
    height: "114px",
    overflowY: "auto",
    overflowX: "hidden",
    position: "relative",
    "&:before": {
      content: "''",
      position: "absolute",
      display: "block",
      left: "41%",
      top: "-22px",
      width: 0,
      height: 0,
      borderStyle: "solid",
      borderColor: "transparent transparent transparent #DCE6E0",
      borderWidth: "11px",
      transform: "rotate(-90deg)",
    },
    "&:after": {
      content: "''",
      position: "absolute",
      display: "block",
      left: "42%",
      top: "-20px",
      width: 0,
      height: 0,
      borderStyle: "solid",
      borderColor: "transparent transparent transparent #F7F9FA",
      borderWidth: "10px",
      transform: "rotate(-90deg)",
    },
  },
  mainStepper: {
    textAlign: "center",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',

  },
  editIconMobile: {
    position: "absolute",
    right: "10px",
    top: "10px"
  },
  mainStepperMobile: {
    textAlign: "center",
    display: 'flex',
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginTop: "20px",
    width: "100%"
  },
  stepperWrapperMobile: {
    cursor: "pointer",
    backgroundColor: "#FFF",
    border: "1px solid var(--color-svg)",
    width: "-webkit-fill-available",
    height: "70px",
    overflowY: "auto",
    position: "relative",
    marginLeft: "20px",
    borderRadius: "8px",
    "display": "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    padding: "10px 0"
  },
}));

interface ProgressBarStepperModal {
  selectedColor: any;
  setTextInput: (textInput: any) => void;
  textInput: any;
  setShowInput: (showInput: boolean) => void;
  setEditStatus: (editStatus: number) => void;
  showInput: boolean;
  editStatus: number;
  width?: any;
  absenceToggle?: boolean
}

const ProgressBarStepperNew = (props: ProgressBarStepperModal) => {
  const classes = useStyles();

  const width = useWidth()

  const onValueChange = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;
    let temp = [...props.textInput];
    if(!temp[name]) {
      temp[name] = {}
    }
    temp[name].text = value;
    props.setTextInput(temp);
  };
  let selectedColor = props.selectedColor?.color
    ? props.selectedColor?.color
    : props.selectedColor;
  console.log("selectedColor", props.selectedColor, props.selectedColor?.color, selectedColor)
  if (props?.absenceToggle) {
    selectedColor = ["#C7C7C7", ...selectedColor]
  }

  return (
    <Grid sx={{
      width: "100%", overflow: "auto",

    }}>
      <Box className={width > 768 ? classes.stepperLine : classes.stepperLineMobile}>
        {selectedColor?.map((colorCode: any, index: number) => {
          return (
            <div className={width > 768 ? classes.mainStepper : classes.mainStepperMobile} key={index}>
              <span
                className={classes.stepperNumber}
                style={{
                  backgroundColor: colorCode.colorCode
                    ? colorCode.colorCode
                    : colorCode,
                }}
              >
                {props.absenceToggle ? index : index + 1}
              </span>
              <div
                className={width > 768 ? classes.stepperWrapper + " hide-scrollbar" : classes.stepperWrapperMobile + " hide-scrollbar"}

                onBlur={() => {
                  props.setShowInput(false)
                  props.setEditStatus(null)
                }}
              >
                <EditOutcomeStatusNew
                  showInput={props.showInput}
                  index={index}
                  textInput={props.absenceToggle ? [{ text: "Absence" }, ...props.textInput] : props.textInput}
                  onValueChange={(e) => onValueChange(e)}
                  editStatus={props.editStatus}
                  isMobile={width <= 768}
                  absenceToggle={props.absenceToggle}
                />
                {(props.editStatus === index && props.showInput) || (props.absenceToggle && !index) ? null : (
                  <div className={width > 768 ? "" : classes.editIconMobile} onClick={() => {
                    props.setShowInput(true);
                    props.setEditStatus(index);
                  }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path d="M3 10.4334V12.9998L5.5664 12.9998L12.5397 6.0265L12.5403 6.02595C12.794 5.77223 12.9209 5.64532 12.9685 5.49893C13.0103 5.37007 13.0103 5.23126 12.9685 5.1024C12.9209 4.95591 12.7938 4.82881 12.5397 4.57472L11.4234 3.45847C11.1704 3.20547 11.0437 3.07872 10.8975 3.03122C10.7686 2.98935 10.6298 2.98935 10.501 3.03122C10.3547 3.07875 10.2278 3.20565 9.97441 3.45901L9.9733 3.4601L3 10.4334Z" stroke="#999999" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </div>
                )}
                <div className={classes.stepperRectangle}></div>

              </div>
            </div>
          );
        })}
      </Box>
    </Grid>
  );
};
export default ProgressBarStepperNew;
