import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Collapse, makeStyles } from '@material-ui/core';
import { userService } from '../../services/auth';
import moment from 'moment';
import RestoreIcon from '../../assets/images/icon/Restore';
import DeleteIcon from '../../assets/images/icon/DeleteIcon';
import ArrowDownSmall, { ArrowUpSmall } from '../../assets/images/icon/ArrowUpAndDownSmall';
import SnackBarComp from '../../components/snackBar';
import DeleteModal from './DeleteModal';
import RecoverModal from './RecoverModal';
import InfluenceIcon from './InfluenceIcon';



const useStyles = makeStyles((theme) => ({
  tableWrapper: {
    // background: "blue",
    /* margin-top: 30px; */
    minHeight: "calc(100vh - 220px)"
    // height: "100%",
  },
  weekReportTable: {
    boxShadow: 'none !important',
    marginBottom: '0px',
    height: 'calc(100vh - 210px)',
    overflowY: 'auto',
    '& .MuiTableCell-root': {
      borderBottom: 'none'
    },
    '& .MuiTableHead-root': {
      '& th': {
        fontWeight: 400,
        fontSize: '1rem',
        color: '#9BA19D',
        '& span': {

          display: 'flex',
          alignItems: 'center',
        }

      }
    }
  },
  moreItemButton: {
    background: "#F7F9FA",
    border: "1px solid #DCE6E0",
    borderRadius: "18px",
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "2px 4px 2px 4px",
    gap: '8px'
  }
}));

export function CollapseComponent({ row }: any) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <div style={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '8px' }}>
          {row?.relatedMetrics?.length === 0 ? "-" : row?.relatedMetrics[0]?.name} 
          {
            row?.relatedMetrics?.length > 1 &&  <div> 
            {!open ? <div className={classes.moreItemButton} onClick={() => setOpen(true)}>{row?.relatedMetrics?.length - 1} more <ArrowDownSmall /></div> :
            <div className={classes.moreItemButton} onClick={() => setOpen(false)}>
              <ArrowUpSmall />
            </div>}
          </div>
          }
        </div>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center',flexDirection: 'column' }}>
            {
              row?.relatedMetrics?.map((ele,index)=>{
                if(index !== 0){
                  return <div key={index}>{ele?.name}</div>
                }else {
                  return
                }
              })
            }
          </div>
        </Collapse>
      </div>
    </>
  )
}

export default function ArchiveItemTable() {
  const classes = useStyles();

  const [archiveData, setArchiveData] = React.useState([])
  const [isApiCall, setIsApiCall] = React.useState(false)
  const [showSnakbar, setShowSnakbar] = React.useState(false);
  const [snakbarMsg, setSnakbarMsg] = React.useState("");
  const [msgType, setMsgType] = React.useState("");
  const [actionId, setactionId] = React.useState("");
  const [openDeleteModal, setopenDeleteModal] =
  React.useState(false);
  const [openRecoverModal, setopenRecoverModal] =
  React.useState(false);
  const [RowData, setRowData] = React.useState<any>("")

  React.useEffect(() => {
    const token = localStorage.getItem("userDetails")
    userService.getArchiveData({
      from: moment().subtract(1, 'months').endOf('month').format('MM-DD-YYYY'),
      to: moment(new Date()).format("MM-DD-YYYY")
    }, token).then((res) => {
      const { data, reflectiveData } = res
      setArchiveData([...data, ...reflectiveData])
    }).catch((err) => {
      console.log("err", err)
    })
  }, [isApiCall])

  const handleCloseSnakbar = () => {
    setShowSnakbar(false);
  };


  const restoreData = ({ id, type }) => {
    const token = localStorage.getItem("userDetails")
    if (type === "Outcome") {
      userService?.archiveOutComeData({
        archive: false,
        outcomeId: id
      }, token).then((res) => {
        setShowSnakbar(true);
        setSnakbarMsg(res.message);
        setMsgType("success");
        setIsApiCall(!isApiCall)
      }).catch((error) => {
        setShowSnakbar(true);
        setSnakbarMsg(error?.response?.data?.description
          ? error?.response?.data?.description
          : error.message);
        setMsgType("error");
      })
    } else if (type === "Influence") {
      userService?.archiveInfluanceData({
        archive: false,
        influenceId: id
      }, token).then((res) => {
        setShowSnakbar(true);
        setSnakbarMsg(res.message);
        setMsgType("success");
        setIsApiCall(!isApiCall)
      }).catch((error) => {
        setShowSnakbar(true);
        setSnakbarMsg(error?.response?.data?.description
          ? error?.response?.data?.description
          : error.message);
        setMsgType("error");
      })
    } else if (type === "Reflective") {
      userService?.archiveReflectiveData({
        archive: false,
        reflectiveId: id
      }, token).then((res) => {
        setShowSnakbar(true);
        setSnakbarMsg(res.message);
        setMsgType("success");
        setIsApiCall(!isApiCall)
      }).catch((error) => {
        setShowSnakbar(true);
        setSnakbarMsg(error?.response?.data?.description
          ? error?.response?.data?.description
          : error.message);
        setMsgType("error");
      })
    }
  }

  const deleteData = ({ id, type }) => {
    const token = localStorage.getItem("userDetails")
    if (type === "Outcome") {
      userService?.deleteArchiveOutComeData({
        outcomeId: id
      }, token).then((res) => {
        setShowSnakbar(true);
        setSnakbarMsg(res.description);
        setMsgType("success");
        setIsApiCall(!isApiCall)
      }).catch((error) => {
        setShowSnakbar(true);
        setSnakbarMsg(error?.response?.data?.description
          ? error?.response?.data?.description
          : error.message);
        setMsgType("error");
      })
    } else if (type === "Influence") {
      userService?.deleteArchiveInfluanceData({
        influenceId: id
      }, token).then((res) => {
        setShowSnakbar(true);
        setSnakbarMsg(res.description);
        setMsgType("success");
        setIsApiCall(!isApiCall)
      }).catch((error) => {
        setShowSnakbar(true);
        setSnakbarMsg(error?.response?.data?.description
          ? error?.response?.data?.description
          : error.message);
        setMsgType("error");
      })
    } else if (type === "Reflective") {
      userService?.deleteArchiveReflectiveData({
        reflectiveId: id
      }, token).then((res) => {
        setShowSnakbar(true);
        setSnakbarMsg(res.description);
        setMsgType("success");
        setIsApiCall(!isApiCall)
      }).catch((error) => {
        setShowSnakbar(true);
        setSnakbarMsg(error?.response?.data?.description
          ? error?.response?.data?.description
          : error.message);
        setMsgType("error");
      })
    }
  }

  const Actions = (id) => {
    return (
      <div style={{ display: "flex", alignItems: 'center', justifyContent: 'flex-end', gap: '30px', cursor: "pointer" }}>
        <div onClick={() =>{
          //  restoreData(id)
          setopenRecoverModal(true)
          setactionId(id)
        }}>
          <RestoreIcon />
        </div>
        <div onClick={() =>{ 
          setopenDeleteModal(true)
          setactionId(id)
          }}>
          <DeleteIcon />
        </div>
      </div>
    )
  }

  function createData(
    name: string,
    archived_time: any,
    metric_type: any,
    relatedMetrics: any,
    goal: any,
    times_tracked: any,
    actions: any,
    type,
  ) {
    return { name, archived_time, metric_type, relatedMetrics, goal, times_tracked, actions, type };
  }

  const rows = archiveData.map((element) => {
    const { question, name, relatedMetrics, outcomeGoals, influenceResponseCount, influenceGoals, reflectiveGoals, timesTracked, updatedAt, reflectiveResponseCount, outcomeId, reflectiveId, influenceId, type } = element
    if (Boolean(outcomeGoals)) {
      return createData(name, moment(updatedAt).format("HH:mm, MM/DD/YYYY"), "Outcome", relatedMetrics, outcomeGoals?.length !== 0 ? outcomeGoals[0]?.target : "-", timesTracked, <Actions id={outcomeId} type="Outcome" />, type)
    } else if (Boolean(influenceGoals)) {
      return createData(name, moment(updatedAt).format("HH:mm, MM/DD/YYYY"), "influence", relatedMetrics, influenceGoals?.length !== 0 ? influenceGoals[0]?.target : "-", influenceResponseCount, <Actions id={influenceId} type="Influence" />, type)
    } else if (Boolean(reflectiveGoals)) {
      return createData(question, moment(updatedAt).format("HH:mm, MM/DD/YYYY"), "reflective", [], reflectiveGoals?.length !== 0 ? reflectiveGoals[0]?.target : "-", reflectiveResponseCount, <Actions id={reflectiveId} type="Reflective" />, type)
    }
  })


  return (
    // think and change table
    <div >
      <SnackBarComp
        showSnakbar={showSnakbar}
        handleCloseSnakbar={handleCloseSnakbar}
        snakbarMsg={snakbarMsg}
        type={msgType ? msgType : "info"}
      />
      <TableContainer component={Paper} className={classes.weekReportTable}>
        <Table sx={{ minWidth: 650, borderColor: 'transparent' }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell align="center">Archived time</TableCell>
              <TableCell align="center">Metric type</TableCell>
              <TableCell align="center">Related metrics(g)</TableCell>
              <TableCell align="center">Goal</TableCell>
              <TableCell align="center">Times tracked</TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <>
                <TableRow
                  key={row.name}
                  onClick={() => setRowData(row)}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="center">{row.archived_time}</TableCell>
                  <TableCell align="center">
                    <div style={{ display: 'flex', alignItems: "center", justifyContent: "center", gap: '4px' }}>
                    <span>{row.metric_type}</span> {<span ><InfluenceIcon item={row}/></span>}
                    </div></TableCell>
                  <TableCell align="center">
                    <CollapseComponent row={row} />
                  </TableCell>
                  <TableCell align="center">{row.goal}</TableCell>
                  <TableCell align="center">{row.times_tracked}</TableCell>
                  <TableCell align="center">{row.actions}</TableCell>
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <DeleteModal
       open={openDeleteModal}
       setopenDeleteModal={ setopenDeleteModal}
       deleteData={deleteData}
       RowData={RowData}
       actionId={actionId}
      />
     <RecoverModal
     open={openRecoverModal}
     setopenRecoverModal={ setopenRecoverModal}
     restoreData={restoreData}
     RowData={RowData}
     actionId={actionId}
     />
    </div>
  );
}