import { Box, Grid, Skeleton } from "@mui/material";
import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import insightsProgressStyles from "./insightsClasses";
import CommonTypo from "../../components/CommonTypo";
import MoreButton from "./MoreButton";



interface InsightsOutcomesModal {
  setSelectOutcome: (selectOutcome: string) => void;
  selectOutcome: string;
  outcomeData: any[]
  loadingOutcomes: boolean
}

const InsightsOutcomes = (props: InsightsOutcomesModal) => {
  const classesV2 = insightsProgressStyles()
  const navigate = useNavigate();
  const [showMore, setShowMore] = useState(false)
  let allOutcomes = []
  if(props?.outcomeData && props?.outcomeData.length) {
    allOutcomes = showMore ? [...props?.outcomeData] : [...props?.outcomeData].slice(0, 8)
  }
  return (
    <>
      <Box sx={{ flexGrow: 1,  /* position: "relative" */ }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 0.5, sm: 0.5, md: 1 }}>
          {props.loadingOutcomes && [1,2,3,4].map(it => <Grid key={it} item xs={16} sm={16} md={6} lg={3} xl={3}><Skeleton variant="rounded" width={"100%"} height={60} /></Grid>)}
          {!props.loadingOutcomes && allOutcomes.map(it => {
            return <Grid key={it.outcomeId} item xs={16} sm={16} md={6} lg={3} xl={3}>
              <Box className={classesV2.outcomeSelectionBox} sx={{
                border: props.selectOutcome === it.outcomeId ? "2px solid var(--color-new-main)" : "1px solid #EEEEEE",
              }} onClick={() => {
                props.setSelectOutcome(it.outcomeId);
                navigate("/insight-progress?id=" + it.outcomeId);
              }}>
                <div className={classesV2.outcomeSelectionBoxContent}>
                  {(
                    
                    <div className="outcome-influ-card" style={{ background: "var(--color-new-main)", width: "36px", height: "36px", borderRadius: "5px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <svg width="24" height="24" viewBox="0 0 24 24" style={{
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                      }}>
                        <path d={it.icon} fill="#fff" />
                      </svg>
                    </div>
                  )}
                  <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "nowrap",
                    gap: "4px",
                    width: "calc(100% - 120px)"
                  }}>
                    <CommonTypo size={16} color="#3E4240" title={it.name} lineHeight={22} fontWeight={600} extraSx={{
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      width: "calc(100% - 10px)"
                    }}>{it.name}</CommonTypo>
                    <CommonTypo size={12} color="#656565" lineHeight={12} fontWeight={400}>{"Current Avg."}</CommonTypo>
                  </Box>
                  <Box sx={{
                    display: "grid",
                    justifySelf: "flex-end",
                    right: 0
                  }}>
                    <CommonTypo size={26} color="#3E4240" fontWeight={600}>{it.avg}</CommonTypo>
                  </Box>
                </div>
              </Box>
            </Grid>
          })}
        </Grid>
        <MoreButton showMoreButton={allOutcomes.length > 8} active={showMore} onClick={() => setShowMore(!showMore)}/>
      </Box>
    </>
  );
};

export default InsightsOutcomes;
