import React from "react";
import TrackingItemQues from "./TrackingItemQues";

interface TrackingListModal {
  refelectiveItem: any;
  key: number;
  index: number;
  setShowQues: (showQues: boolean) => void;
  showQues: boolean;
  openStartModalQues: boolean;
  setStartModalQues: (openStartModalQues: boolean) => void;
  getReflectiveList: any
  startDate: any
  setStartDate: any
  endDate: any 
  setEndDate: any
  reflectiveList: any[];
  isExpand: boolean
  setLimitReachPopupVisible: any
  setLimitReachPopuptype: any
}

const TrackingListQues = (props: TrackingListModal) => {
  return (
    <>
      <TrackingItemQues
        refelectiveItem={props.refelectiveItem}
        setShowQues={props.setShowQues}
        showQues={props.showQues}
        openStartModalQues={props.openStartModalQues}
        setStartModalQues={props.setStartModalQues}
        getReflectiveList={props.getReflectiveList}
        startDate={props.startDate}
        setStartDate={props.setStartDate}
        endDate={props.endDate} 
        setEndDate={props.setEndDate}
        index={props.index}
        reflectiveList={props.reflectiveList}
      isExpand={props?.isExpand}
      setLimitReachPopupVisible={props?.setLimitReachPopupVisible}
      setLimitReachPopuptype={props?.setLimitReachPopuptype}
      />
    </>
  );
};

export default TrackingListQues;
