import React, { useRef, useState } from "react";
import classes from "./SideBar.module.css";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";
import { Box } from "@mui/material";
import palette from "../../../theme/palette";
// svg
import WellBeing from "../../../assets/images/icon/WellBeing";
import Measurement from "../../../assets/images/icon/Measurement";
import WellbeingYellow from "../../../assets/images/icon/WellbeingYellow";
import ArrowDownGreen from "../../../assets/images/icon/ArrowDownGreen";
import ArrowUpWhite from "../../../assets/images/icon/ArrowUpWhite";

function SideBarContent({ golesMenu, isMenuSelected, setIsMenuSelected, otherGolesMenu }) {

  const ImgSideBar = styled(Box)(({ theme }) => ({
    width: "36px",
    height: "36px",

    [theme.breakpoints.down("sm")]: {
      width: "30x",
      height: "30px",
    },
  }));
  const MainBox = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: "12px",

    // this change at afternoon
    // overflow: "auto",
    
    alignSelf: 'stretch',
    width: '100%',
    position: 'absolute',
    bottom: '45px',
    top: 0,
    left: 0,
    [theme.breakpoints.down("md")]: {
      bottom: '0px',
    },
  }));

  const AccordionSummaryWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "12px",
    width: "17vw",

    [theme.breakpoints.down("lg")]: {
      width: "16vw",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  }));
  const DetailsWithLabel = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "85%",
  }));


  const AccordionDetailsContainer = styled(AccordionDetails)(({ theme }) => ({
    width: "17vw",
    backgroundColor: "#f7f9fa",
    borderRadius: "8px",
    gap: "12px",
    padding: "16px !important",

    [theme.breakpoints.down("sm")]: {
      width: "182px",
    },
    [theme.breakpoints.down("md")]: {
      width: "182px",
    },
  }));
  const onAccordinExpand = (e, expand, outcomeId) => {
    localStorage.setItem("selectedGoleID", JSON.stringify({ isOutcome: true, outcomeId }))
    if (expand) {
      if (isMenuSelected?.outcomeId === outcomeId) {
        setIsMenuSelected({
          ...isMenuSelected,
          isExpand: true,
          influence: false,
          outcome: true,
          outcomeId: outcomeId,
          influenceData: null,
          isOtherInfluanceSelected: false
        })
      } else {
        setIsMenuSelected({
          ...isMenuSelected,
          isExpand: true,
          influence: false,
          outcome: true,
          outcomeId: outcomeId,
          outComeData: [],
          influenceData: null,
          isOtherInfluanceSelected: false
        })
      }
    } else {
      if (isMenuSelected?.outcomeId === outcomeId) {
        setIsMenuSelected({
          ...isMenuSelected,
          isExpand: false,
          influence: false,
          outcome: true,
          outcomeId: outcomeId,
          influenceData: null,
          isOtherInfluanceSelected: false
        })
      } else {
        setIsMenuSelected({
          ...isMenuSelected,
          isExpand: false,
          influence: false,
          outcome: true,
          outcomeId: outcomeId,
          outComeData: [],
          influenceData: null,
          isOtherInfluanceSelected: false
        })
      }
    }
  }

  const selectInfluence = (influenceId, type, other = false) => {
    if (isMenuSelected?.influenceId === influenceId && !other) {
      setIsMenuSelected({
        ...isMenuSelected,
        outcome: false,
        influence: true,
        influenceId: influenceId,
        selectedInfluenceType: type,
        isOtherInfluanceSelected: false,
        selectedInfluancepPercentData: {}
      })
    } else if (isMenuSelected?.influenceId === influenceId && other) {
      setIsMenuSelected({
        ...isMenuSelected,
        outcome: false,
        influence: true,
        influenceId: influenceId,
        selectedInfluenceType: type,
        isOtherInfluanceSelected: true,
        selectedInfluancepPercentData: {}
      })
    } else {
      setIsMenuSelected({
        ...isMenuSelected,
        outcome: false,
        influence: true,
        influenceId: influenceId,
        influenceData: null,
        selectedInfluenceType: type,
        selectedInfluancepPercentData: {}
      })
    }
  }
  const selectOtherInfluance = (e, expand, influenceId, type) => {
    setIsMenuSelected({
      ...isMenuSelected,
      outcome: false,
      influence: true,
      influenceId: influenceId,
      selectedInfluenceType: type,
      isOtherInfluanceSelected: expand
    })
  }
  return (
    <>
      <MainBox>
        {/* first */}
        {golesMenu?.map((menu, i) => {
          const { outcomeId, influence, name, icon, options } = menu;
          return (
            <Accordion
              key={i}
              expanded={isMenuSelected?.isExpand && outcomeId === isMenuSelected?.outcomeId}
              onChange={(e, expand) => onAccordinExpand(e, expand, outcomeId)}
              disableGutters
              sx={{
                "&:before": {
                  display: "none",
                },
                width: "20w",
                borderRadius: "8px !important",
                marginRight: '8px',
              }}
              className={classes.accordionOne}
            >

              <AccordionSummary

                className={classes.accordionSummaryTest}
                sx={{
                  background:
                    isMenuSelected?.outcome && outcomeId === isMenuSelected?.outcomeId
                      ? palette.primary.main
                      : palette.secondary.light,
                  borderRadius: "8px !important",
                }}

                expandIcon={
                  isMenuSelected?.outcome && outcomeId === isMenuSelected?.outcomeId ? (
                    <ArrowUpWhite />
                  ) : (
                    <ArrowDownGreen />
                  )
                }
              >
                <AccordionSummaryWrapper>

                  <ImgSideBar>
                    {isMenuSelected?.outcome && outcomeId === isMenuSelected?.outcomeId ? (
                      <div style={{ width: "36px", height: "36px", background: "#fff", borderRadius: "5px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <svg width="24" height="24" viewBox="0 0 24 24">
                          <path d={icon} fill="green" />
                        </svg>
                      </div>
                    ) : (
                      <div style={{ background: options && options[0] ? options[0].colorCode : null, width: "36px", height: "36px", borderRadius: "5px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <svg width="24" height="24" viewBox="0 0 24 24">
                          <path d={icon} fill="#fff" />
                        </svg>
                      </div>
                    )}

                  </ImgSideBar>
                  <DetailsWithLabel>
                    {/* <div style={{ width: "100%" }}> */}
                    <Typography
                      variant="caption"
                      sx={{
                        wordBreak: "break-all",
                        color:
                          isMenuSelected?.outcome && outcomeId === isMenuSelected?.outcomeId
                            //text color
                            ? "#ffffff"
                            : "#3E4240",
                      }}
                    >
                      {name}
                    </Typography>
                    {/* </div> */}
                    <div>
                      <Typography variant="heading2" sx={{ color: "#B7BDB9" }}>
                        ({influence?.length})
                      </Typography>
                    </div>
                  </DetailsWithLabel>
                </AccordionSummaryWrapper>

              </AccordionSummary>

              {influence?.map((influence, i) => {
                const { correlation, name, regression, influenceId, avg, type, influenceGoals } = influence;
                return (
                  <div className={classes.mainAcordianDetails} onClick={() => {
                    localStorage.setItem("selectedGoleID", JSON.stringify({ isOutcome: false, influenceId }))
                    localStorage.setItem("selectedInfluenceType", type)
                    selectInfluence(influenceId, type)
                  }} key={i}>
                    <AccordionDetailsContainer sx={{
                      background: isMenuSelected?.influence && influenceId === isMenuSelected?.influenceId
                        ? `${palette.primary.main} !important`
                        : `${palette.secondary.light} !important`
                    }}>
                      <Typography
                        className={classes.accordionDetailsHeading}
                      >
                        <div className={classes.MeasurementIcon}>
                          <Measurement />
                        </div>
                        <Typography
                          variant="caption"
                          sx={{ color: isMenuSelected?.influence && influenceId === isMenuSelected?.influenceId ? "#ffffff !important" : "#3E4240 !important", wordBreak: "break-all" }}
                        >
                          {name}
                        </Typography>
                      </Typography>
                      <div className={classes.detailsContainer}>
                        <div className={classes.subDetailsContainer}>
                          <Typography
                            variant="heading2"
                            sx={{
                              color: isMenuSelected?.influence && influenceId === isMenuSelected?.influenceId ? "#ffffff !important" : "#9BA19D !important",
                              display: "flex",
                              gap: "4px",
                            }}
                          >
                            Correlation{" "}
                            <Typography
                              variant="heading2"
                              sx={{ color: isMenuSelected?.influence && influenceId === isMenuSelected?.influenceId ? "#ffffff !important" : "#3E4240 !important" }}
                            >
                              {correlation !== "N/A" ? correlation?.toFixed(1) : "N/A"}
                            </Typography>
                          </Typography>
                          <Typography
                            variant="heading2"
                            sx={{
                              color: isMenuSelected?.influence && influenceId === isMenuSelected?.influenceId ? "#ffffff !important" : "#9BA19D !important",
                              display: "flex",
                              gap: "4px",
                            }}
                          >
                            Regression
                            <Typography
                              variant="heading2"
                              sx={{ color: isMenuSelected?.influence && influenceId === isMenuSelected?.influenceId ? "#ffffff !important" : "#3E4240 !important" }}
                            >
                              {regression?.slope || "N/A"}
                            </Typography>
                          </Typography>
                        </div>
                        <div className={classes.subDetailsContainer}>
                          <Typography
                            variant="heading2"
                            sx={{
                              color: isMenuSelected?.influence && influenceId === isMenuSelected?.influenceId ? "#ffffff !important" : "#9BA19D !important",
                              display: "flex",
                              gap: "4px",
                            }}
                          >
                            Target Avg.{" "}
                            <Typography
                              variant="heading2"
                              sx={{ color: isMenuSelected?.influence && influenceId === isMenuSelected?.influenceId ? "#ffffff !important" : "#3E4240 !important" }}
                            >
                              {(influenceId === isMenuSelected?.influenceId ? isMenuSelected?.getInfluenceData?.target : influenceGoals[0]?.target) || "N/A"}
                            </Typography>
                          </Typography>
                          <Typography
                            variant="heading2"
                            sx={{
                              color: isMenuSelected?.influence && influenceId === isMenuSelected?.influenceId ? "#ffffff !important" : "#9BA19D !important",
                              display: "flex",
                              gap: "4px",
                            }}
                          >
                            Current Avg.{" "}
                            <Typography
                              variant="heading2"
                              sx={{ color: isMenuSelected?.influence && influenceId === isMenuSelected?.influenceId ? "#ffffff !important" : "#3E4240 !important" }}
                            >
                              {avg?.length === 0 ? "N/A" : avg?.toFixed(1)}
                            </Typography>
                          </Typography>
                        </div>
                      </div>
                    </AccordionDetailsContainer>
                  </div>

                );
              })}
            </Accordion>

          );
        })}

        {otherGolesMenu?.length > 0 &&
          <Accordion
            expanded={isMenuSelected?.isOtherInfluanceSelected}
            onChange={(e, expand) => {
              const influenceId = otherGolesMenu[0]?.influenceId
              const type = otherGolesMenu[0]?.type
              selectOtherInfluance(e, expand, influenceId, type)
            }}
            disableGutters
            sx={{
              "&:before": {
                display: "none",
              },
              width: "20w",
              borderRadius: "8px !important",
              marginRight: '8px',
            }}
            className={classes.accordionOne}
          >

            <AccordionSummary

              className={classes.accordionSummaryTest}
              sx={{
                background: palette.secondary.light,
                borderRadius: "8px !important",
              }}

              expandIcon={
                <ArrowDownGreen />
              }
            >
              <AccordionSummaryWrapper>

                <ImgSideBar>

                  <div style={{ background: palette.secondary.main, width: "36px", height: "36px", borderRadius: "5px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <svg width="24" height="24" viewBox="0 0 24 24">
                      <path d="M12 20C16.4 20 20 16.4 20 12S16.4 4 12 4 4 7.6 4 12 7.6 20 12 20M12 2C17.5 2 22 6.5 22 12S17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2M12.5 7V12.2L9.8 17L8.5 16.2L11 11.8V7H12.5Z" fill="#fff" />
                    </svg>
                  </div>


                </ImgSideBar>
                <DetailsWithLabel>
                  {/* <div style={{ width: "100%" }}> */}
                  <Typography
                    variant="caption"
                    sx={{
                      wordBreak: "break-all",
                      color: '#3E4240',
                    }}
                  >
                    Other Influences
                  </Typography>
                  {/* </div> */}
                  <div>
                    <Typography variant="heading2" sx={{ color: "#B7BDB9" }}>
                      ({otherGolesMenu?.length})
                    </Typography>
                  </div>
                </DetailsWithLabel>
              </AccordionSummaryWrapper>

            </AccordionSummary>

            {otherGolesMenu?.map((influence, i) => {
              const { correlation, name, regression, influenceId, avg, type, influenceGoals } = influence;
              return (
                <div className={classes.mainAcordianDetails} onClick={(e) => {
                  localStorage.setItem("selectedGoleID", JSON.stringify({ isOutcome: false, influenceId }))
                  localStorage.setItem("selectedInfluenceType", type)
                  selectInfluence(influenceId, type, true)
                }} key={i}>
                  <AccordionDetailsContainer sx={{
                    background: isMenuSelected?.influence && influenceId === isMenuSelected?.influenceId
                      ? `${palette.primary.main} !important`
                      : `${palette.secondary.light} !important`
                  }}>
                    <Typography
                      className={classes.accordionDetailsHeading}
                    >
                      <div className={classes.MeasurementIcon}>
                        <Measurement />
                      </div>
                      <Typography
                        variant="caption"
                        sx={{ color: isMenuSelected?.influence && influenceId === isMenuSelected?.influenceId ? "#ffffff !important" : "#3E4240 !important", wordBreak: "break-all" }}
                      >
                        {name}
                      </Typography>
                    </Typography>
                    {/* <div className={classes.detailsContainer}>
                      <div className={classes.subDetailsContainer}>
                        <Typography
                          variant="heading2"
                          sx={{
                            color: isMenuSelected?.influence && influenceId === isMenuSelected?.influenceId ? "#ffffff !important" : "#9BA19D !important",
                            display: "flex",
                            gap: "4px",
                          }}
                        >
                          Correlation{" "}
                          <Typography
                            variant="heading2"
                            sx={{ color: isMenuSelected?.influence && influenceId === isMenuSelected?.influenceId ? "#ffffff !important" : "#3E4240 !important" }}
                          >
                            {correlation !== "N/A" ? correlation?.toFixed(1) : "N/A"}
                          </Typography>
                        </Typography>
                        <Typography
                          variant="heading2"
                          sx={{
                            color: isMenuSelected?.influence && influenceId === isMenuSelected?.influenceId ? "#ffffff !important" : "#9BA19D !important",
                            display: "flex",
                            gap: "4px",
                          }}
                        >
                          Regression
                          <Typography
                            variant="heading2"
                            sx={{ color: isMenuSelected?.influence && influenceId === isMenuSelected?.influenceId ? "#ffffff !important" : "#3E4240 !important" }}
                          >
                            {regression?.slope || "N/A"}
                          </Typography>
                        </Typography>
                      </div>
                      <div className={classes.subDetailsContainer}>
                        <Typography
                          variant="heading2"
                          sx={{
                            color: isMenuSelected?.influence && influenceId === isMenuSelected?.influenceId ? "#ffffff !important" : "#9BA19D !important",
                            display: "flex",
                            gap: "4px",
                          }}
                        >
                          Target Avg.{" "}
                          <Typography
                            variant="heading2"
                            sx={{ color: isMenuSelected?.influence && influenceId === isMenuSelected?.influenceId ? "#ffffff !important" : "#3E4240 !important" }}
                          >
                            {(influenceId === isMenuSelected?.influenceId ? isMenuSelected?.getInfluenceData?.target : influenceGoals[0]?.target) || "N/A"}
                          </Typography>
                        </Typography>
                        <Typography
                          variant="heading2"
                          sx={{
                            color: isMenuSelected?.influence && influenceId === isMenuSelected?.influenceId ? "#ffffff !important" : "#9BA19D !important",
                            display: "flex",
                            gap: "4px",
                          }}
                        >
                          Current Avg.{" "}
                          <Typography
                            variant="heading2"
                            sx={{ color: isMenuSelected?.influence && influenceId === isMenuSelected?.influenceId ? "#ffffff !important" : "#3E4240 !important" }}
                          >
                            {avg?.length === 0 ? "N/A" : avg?.toFixed(1)}
                          </Typography>
                        </Typography>
                      </div>
                    </div> */}
                  </AccordionDetailsContainer>
                </div>
              )
            })
            }
          </Accordion>
        }
      </MainBox>
    </>
  );
}

export default React.memo(SideBarContent)