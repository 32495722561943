import React, { useState, useEffect, useContext, useCallback } from "react";
import {
    Button,
    Grid,
    TextField,
    Typography,
    SvgIcon,
    Dialog,
    Box,
    Autocomplete, Paper
} from "@mui/material";
import { styled } from "@mui/material/styles";
import SnackBarComp from "../../components/snackBar";
import { makeStyles } from "@material-ui/core";
import palette from "../../theme/palette";
import shape from "../../theme/shape";
import BottomStepper from "./BottomStepper";
import _debounce from "lodash/debounce";
import { onboardingPlansService } from "../../services/onboardingPlans";
import { UserTypeContext } from "../../context/userStatus";
import { pxToRem } from "../../theme/typography";
import useWidth from "../../utils/useWidth";

interface LeftSideOutComeModal {
    outComeValue: any;
    setOutcomeValue: (outComeValue: string) => void;
    outcomeIdeas: any;
    setShowOutcome: (showOutcome: boolean) => void;
    showOutcome: boolean;
    setAddOutcomeTracking: (addOutcomeTracking: boolean) => void;
    setTextInput: any;
    textInput: any;
    ispersonalMode?: boolean;
    handleCloseStartModal?: any;
    getOutcomeList?: any;
    categoryId?: any;
    setTransformToInfluenceTracking?: any;
    width?: any;
    setShowSnakbar?: any;
    setSnakbarMsg?: any;
    setMsgType?: any;
    startDate?: any
    endDate?: any
    onboarding?: boolean
    listOfImprovements?: any[]
    selectedPlans?: any[]
    setSelectedPlans?: any
    handleClickStep?: any
    curretStep?: number
    setListOfImprovements?: any
    secondselectedPlans?: any[]
    setSecondSelectedPlans: (x: any[]) => void;
    setSelectedPlanOutcomes: (x: any[]) => void;
    setSelectedPlanInfluences: (x: any[]) => void;
    setSelectedPlanReflectives: (x: any[]) => void;
}
const RootStyle = styled("div")(({ theme }) => ({
    padding: "40px 80px",
    display: 'flex',
    alignItems: "center",
    flexDirection: 'column',
    gap: '42px',
    [theme.breakpoints.up("xl")]: {
        padding: "60px",
    },
    [theme.breakpoints.down("lg")]: {
        padding: "40px",
    },
    [theme.breakpoints.down("md")]: {
        padding: "30px",
    },
    [theme.breakpoints.down("sm")]: {
        padding: "20px",
    },
}));
const useStyles = makeStyles((theme) => ({
    registrationHeading: {
        color: palette.primary.newLight,
        fontSize: `${pxToRem(32)} !important`,
        fontWeight: 700,
        lineHeight: `${pxToRem(46.4)} !important`,
    },
    registrationSubHeading: {
        color: `${palette.primary.newLight} !important`,
        fontSize: `${pxToRem(16)} !important`,
        fontWeight: 400,
        lineHeight: `${pxToRem(23)} !important`,
    },
    improvementTitle: {
        color: `${palette.primary.light} !important`,
        fontSize: '20px !important',
        lineHeight: '100%',
    },
    improvementSelectedTitle: {
        color: `${palette.primary.contrastText} !important`,
        fontSize: '20px !important',
        fontWeight: 700,
        lineHeight: '100%',
    },
    improvementTitleNew: {
        color: `${palette.primary.newLight} !important`,
        fontSize: '16px !important',
        fontWeight: 400,
        lineHeight: '100%',
    },
    improvementSelectedTitleNew: {
        color: `${palette.primary.light} !important`,
        fontSize: '16px !important',
        fontWeight: 400,
        lineHeight: '100%',
    },
    pageTitle: {
        marginTop: "40px!important",
        marginBottom: "48px!important",
        [theme.breakpoints.down("lg")]: {
            marginTop: "30px!important",
            marginBottom: "38px!important",
        },
        [theme.breakpoints.down("md")]: {
            marginTop: "20px!important",
            marginBottom: "28px!important",
        },
        [theme.breakpoints.down("sm")]: {
            marginTop: "15px!important",
            marginBottom: "20px!important",
        },
    },
    needSomeIdeas: {
        fontSize: "16px",
        lineHeight: "16px",
        color: palette.primary.main,
        marginLeft: "8px",
        [theme.breakpoints.down("lg")]: {
            marginTop: "10px",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "14px",
            lineHeight: "14px",
        },
    },
    colorBlock: {
        cursor: "pointer",
        backgroundColor: palette.secondary.light,
        border: "1px solid #DCE6E0",
        borderRadius: shape.borderRadius,
        padding: "11px 16px",
        display: "flex",
        alignItems: "center",
        marginLeft: "16px",
        [theme.breakpoints.down("lg")]: {
            marginLeft: 0,
        },
    },
    spaceBetween: {
        [theme.breakpoints.down("lg")]: {
            marginTop: "10px",
            marginBottom: "10px",
        },
    },
    colorBlockText: {
        margin: 0,
        color: palette.primary.light,
        fontSize: "16px",
        lineHeight: "16px",
        fontWeight: 500,
    },
    colorCode: {
        width: "24px",
        height: "24px",
        marginLeft: "10px",
        borderRadius: shape.borderRadiusSm,
    },
    outcomeTitle: {
        marginTop: "48px!important",
        marginBottom: "20px!important",
        [theme.breakpoints.down("lg")]: {
            marginTop: "40px!important",
        },
        [theme.breakpoints.down("md")]: {
            marginTop: "30px!important",
        },
        [theme.breakpoints.down("sm")]: {
            marginTop: "20px!important",
        },
    },
    ideasListItem: {
        backgroundColor: palette.common.white,
        borderRadius: shape.borderRadius,
        padding: "24px",
        fontSize: "16px",
        lineHeight: "16px",
        color: palette.primary.light,
        fontWeight: 400,
        marginBottom: "12px",
        cursor: "pointer",
    },
    countryCodeBoxMobile: {
        padding: "0px",
        // borderRadius: "12px",
        borderBottom: "1px solid #3E4240",
        backgroundColor: "transparent",
        minWidth: "90%",
        maxWidth: "90%",
        outline: "none",
        marginBottom: `0px !important`,
        '& .MuiInputBase-root': {
            backgroundColor: "transparent",

        },
        '& .MuiTextField-root': {
            marginBottom: `0px !important`,
            borderRadius: '12px',
            '& .MuiOutlinedInput-root': {
                // padding: '0px'

                borderRadius: '12px !important'
            },
            '& .MuiOutlinedInput-input': {
                padding: '0px !important',
                paddingBottom: "5px !important",
                fontSize: '18px !important',
                fontWeight: `500 !important`,
                // padding: '7px 13px 0px!important'
            },
        },

        '& .MuiAutocomplete-option': {
            fontSize: '12px'
        }
    },
    countryCodeBox: {
        padding: "0px",
        // borderRadius: "12px",
        borderBottom: "1px solid #3E4240",
        backgroundColor: "transparent",
        minWidth: "43%",
        maxWidth: "50%",
        outline: "none",
        marginBottom: `0px !important`,
        '& .MuiInputBase-root': {
            backgroundColor: "transparent",

        },
        '& .MuiTextField-root': {
            marginBottom: `0px !important`,
            borderRadius: '12px',
            '& .MuiOutlinedInput-root': {
                // padding: '0px'

                borderRadius: '12px !important'
            },
            '& .MuiOutlinedInput-input': {
                padding: '11px 13px !important',
                // fontSize: '12px !important',
                fontWeight: `500 !important`,
                // padding: '7px 13px 0px!important'
            },
        },

        '& .MuiAutocomplete-option': {
            fontSize: '12px'
        }
    },
    addButton: {
        padding: '12px 20px !important',
        justifyContent: 'center !important',
        alignItems: 'center !important',
        borderRadius: '30px !important',
        background: 'rgba(17, 115, 74, 1)',
        marginBottom: "0px !important"


    },
    addButtonNew: {
        height: "35px",
        borderRadius: "10px !important",
        border: "1px solid #30A35E !important",
        marginBottom: "0px !important",
        padding: "15px 16px 15px 16px !important",
        background: 'transparent',
        fontSize: `${pxToRem(16)} !important`,
        fontWeight: 400,
        color: "#30A35E !important",
        "&.Mui-disabled": {
            border: "1px solid #A0A0A1 !important",
            color: "#A0A0A1 !important",
        }
    },
    selectionBox: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        flexShrink: 0,
        border: "1px solid #FCFFFC",
        background: '#FCFFFC',
        cursor: "pointer",
        borderRadius: "50px",
        padding: "14px 25px 14px 25px",
        "&:hover": {
            border: "1px solid #D6F5C3",
        },
    }
}));
const LeftSideImprovement = (props: LeftSideOutComeModal) => {
    const classes = useStyles();
    const { state: outcomeState, dispatch: userTypeDispatch } =
        useContext(UserTypeContext);
    const [showSnakbar, setShowSnakbar] = useState(false);
    const [snakbarMsg, setSnakbarMsg] = useState("");
    const [msgType, setMsgType] = useState("");
    const [addDisabled, setAddDisabled] = useState(true);
    const [selectedValueNew, setSelectedValueNew] = useState(null);
    const [searchValue, setSearchValue] = useState("");
    const [fetchedCategory, setFetchedCategory] = useState([]);
    const [prefetchedCategory, setPreFetchedCategory] = useState([]);
    const handleCloseSnakbar = () => {
        setShowSnakbar(false);
    };
    const { listOfImprovements } = props
    const alreadyAddedList = [...listOfImprovements].map(it => it.name)
    const handleChangeWithLib = (value) => {
        if (value) {
            onboardingPlansService
                .getPlansList(outcomeState.token, {
                    search: value
                })
                .then((data) => {
                    // setData(data?.data)
                    // console.log("alreadyAddedList New", alreadyAddedList, listOfImprovements)
                    // if (data?.data && data?.data?.length) {
                    //     setPreFetchedCategory(data?.data.filter(it => !alreadyAddedList.includes(it.name)))
                    // } else {
                    setPreFetchedCategory(data?.data || [])
                    // }
                })
        }

    };
    useEffect(() => {
        if (prefetchedCategory && prefetchedCategory.length) {
            setFetchedCategory([...prefetchedCategory].filter(it => !alreadyAddedList.includes(it.name)))
        } else {
            setFetchedCategory([])
        }
    }, [prefetchedCategory])
    const setData = (data) => {

    }
    const debounceFn = useCallback(_debounce(handleChangeWithLib, 500), []);

    const handleChangeInputValue = (e, newInputValue) => {
        setSearchValue(newInputValue);
        debounceFn(newInputValue);
    }
    useEffect(() => {
        if (searchValue) {
            if (selectedValueNew) {
                setAddDisabled(false)
            } else {
                setAddDisabled(true)
            }
        } else {
            setAddDisabled(true)
        }
    }, [searchValue])
    useEffect(() => {
        if (searchValue) {
            if (selectedValueNew) {
                setAddDisabled(false)
            } else {
                setAddDisabled(true)
            }
        } else {
            setAddDisabled(true)
        }
    }, [searchValue])
    const selectCategory = (e, newInputValue) => {
        if (newInputValue) {
            setSelectedValueNew(newInputValue)
            setAddDisabled(false)
            setFetchedCategory([])
        }
    }
    const width = useWidth()
    const isMobile = width < 768
    const bottomStepper = <BottomStepper
        isOutcome={true}
        isInfluence={false}
        isRefQues={false}
        isTracking={false}
        handleClickStep={props?.handleClickStep}
        curretStep={props?.curretStep}
        nextDisabled={props.selectedPlans.length <= 0}
        prevDisabled={true}
        isMobile={isMobile}
    />
    return <RootStyle
        // sx={!props.showOutcome ? { height: "100vh", overflowY: 'auto', paddingBottom: '100px !important' } : { height: "100vh", overflowY: 'auto', }}
        className={`login-right-part ${isMobile ? "right-side-new-bg-mobile" : "right-side-new-bg"} `}
        sx={{
            background: `rgba(255, 255, 255, 0.3) !important`,
        }}
    >
        <SnackBarComp
            showSnakbar={showSnakbar}
            handleCloseSnakbar={handleCloseSnakbar}
            snakbarMsg={snakbarMsg}
            type={msgType ? msgType : "info"}
        />
        {isMobile && bottomStepper}
        <Typography
            variant="h1"
            component="h1"
            className={classes.registrationHeading}
            sx={isMobile ? {
                fontSize: `${pxToRem(22)} !important`,
                lineHeight: "120% !important",
                textAlign: "center !important",
                "&.MuiTypography-root": {
                    "@media  (max-width: 600px) and (min-width: 100px)": {
                        fontSize: `${pxToRem(22)} !important`,
                        lineHeight: "120% !important"
                    },
                }
            } : {}}
        >
            What would you like to improve?
        </Typography>
        <Typography
            component="h1"
            className={classes.registrationSubHeading}
            sx={isMobile ? {
                fontSize: `${pxToRem(16)} !important`,
                lineHeight: "120% !important",
                "&.MuiTypography-root": {
                    "@media  (max-width: 600px) and (min-width: 100px)": {
                        fontSize: `${pxToRem(16)} !important`,
                        lineHeight: "120% !important"
                    },
                }
            } : {}}
        >
            Select up to three goals
        </Typography>
        {isMobile ? <Grid
            container
            columns={16}
            rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3, lg: 2 }}
            sx={isMobile ? {
                maxHeight: '32vh',
                overflowY: 'auto'
            } : {
                maxHeight: '38vh',
                overflowY: 'auto'
            }}
            className={isMobile ? "hide-scrollbar" : ""}
        >
            {listOfImprovements.map(it => {
                let selectedPlans = [...(props.selectedPlans || [])]
                const found = selectedPlans.find(ite => ite.categoryId === it.categoryId)
                return <Grid item xs={16} sm={16} md={7} lg={5.33} >
                    <Box sx={{
                        display: 'flex',
                        padding: '18px 21px 17px 20px',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        flexShrink: 0,
                        borderRadius: '5px',
                        border: found ? '2px solid #D6F5C3' : '2px solid #BADACA',
                        background: found ? `#D6F5C3 !important` : 'rgba(252, 255, 250, 0.50)',
                        cursor: "pointer"
                    }} onClick={() => {
                        if (found) {
                            selectedPlans = selectedPlans.filter(ite => ite.categoryId !== it.categoryId)
                            props?.setSelectedPlans(selectedPlans)
                            const foundAlreadySelected = props.secondselectedPlans.find(ite => ite.categoryId === it.categoryId)
                            console.log("foundAlreadySelected", foundAlreadySelected)
                            if (foundAlreadySelected) {
                                props?.setSecondSelectedPlans([])
                                props?.setSelectedPlanOutcomes([])
                                props?.setSelectedPlanInfluences([])
                                props?.setSelectedPlanReflectives([])
                            }
                        } else {
                            if (selectedPlans.length < 3) {
                                selectedPlans.push(it)
                                props?.setSelectedPlans(selectedPlans)
                            }

                        }
                    }}>
                        <Typography
                            component="span"
                            className={found ? classes.improvementSelectedTitleNew : classes.improvementTitleNew}
                            sx={isMobile ? {
                                fontSize: `${pxToRem(20)} !important`,
                                lineHeight: "normal !important",
                                textAlign: "center !important",
                                // fontWeight: `400 !important`,
                                fontWeight: found ? `400 !important` : `400 !important`,
                                "&.MuiTypography-root": {
                                    "@media  (max-width: 600px) and (min-width: 100px)": {
                                        fontSize: `${pxToRem(20)} !important`,
                                        lineHeight: "normal !important"
                                    },
                                }
                            } : {
                                fontWeight: found ? `400 !important` : `400 !important`
                            }}
                        >
                            {it.name}
                        </Typography>

                    </Box>
                </Grid>
            })}


        </Grid> : <Box display={"flex"} alignItems={"center"} justifyContent={"center"} flexWrap={"wrap"} sx={{
            maxHeight: '38vh',
            width: "80%",
            overflowY: 'auto',
            gap: "12px"
        }}>{listOfImprovements.map(it => {
            let selectedPlans = [...(props.selectedPlans || [])]
            const found = selectedPlans.find(ite => ite.categoryId === it.categoryId)
            return <Box className={classes.selectionBox} sx={{

                background: found ? `#D6F5C3 !important` : 'rgba(252, 255, 250, 0.50)',
            }} onClick={() => {
                if (found) {
                    selectedPlans = selectedPlans.filter(ite => ite.categoryId !== it.categoryId)
                    props?.setSelectedPlans(selectedPlans)
                    const foundAlreadySelected = props.secondselectedPlans.find(ite => ite.categoryId === it.categoryId)
                    if (foundAlreadySelected) {
                        props?.setSecondSelectedPlans([])
                        props?.setSelectedPlanOutcomes([])
                        props?.setSelectedPlanInfluences([])
                        props?.setSelectedPlanReflectives([])
                    }
                } else {
                    if (selectedPlans.length < 3) {
                        selectedPlans.push(it)
                        props?.setSelectedPlans(selectedPlans)
                    }

                }
            }}>

                <Typography
                    component="span"
                    className={found ? classes.improvementSelectedTitleNew : classes.improvementTitleNew}
                    sx={isMobile ? {
                        fontSize: `${pxToRem(20)} !important`,
                        lineHeight: "normal !important",
                        textAlign: "center !important",
                        // fontWeight: `400 !important`,
                        fontWeight: found ? `400 !important` : `400 !important`,
                        "&.MuiTypography-root": {
                            "@media  (max-width: 600px) and (min-width: 100px)": {
                                fontSize: `${pxToRem(20)} !important`,
                                lineHeight: "normal !important"
                            },
                        }
                    } : {
                        fontWeight: found ? `400 !important` : `400 !important`
                    }}
                >
                    {it.name}
                </Typography>
            </Box>
        })}</Box>}
        <Box display={"flex"} marginTop={isMobile ? "0px" : "40px"} gap={"20px"} flexDirection={isMobile ? "column" : "row"} alignItems={"center"} justifyContent={"center"} width={"100%"}>
            <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={fetchedCategory.sort(function (a, b) {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) { return -1; }
                    if (a.name.toLowerCase() > b.name.toLowerCase()) { return 1; }
                    return 0;
                }).map(it => ({
                    ...it,
                    label: it.name,
                }))}
                value={searchValue}
                onChange={selectCategory}
                onInputChange={handleChangeInputValue}
                sx={{

                }}
                className={isMobile ? classes.countryCodeBoxMobile : classes.countryCodeBox}
                PaperComponent={({ children }) => (
                    <Paper style={{ background: "#FFF" }} sx={{
                        '&.MuiAutocomplete-option': {
                            color: "rgb(0, 0, 0)",
                            fontSize: '18px',


                        },
                        '& .MuiAutocomplete-listbox': {
                            padding: "0px",
                            '& li': {
                                padding: `16px`,
                                color: "#5E5F5E !important",
                                fontSize: "16px !important",
                                fontWeight: "400 !important",
                                lineHeight: "24px"
                            }
                        },
                    }}>{children}</Paper>
                )}
                disableClearable
                renderInput={(params) => <TextField placeholder="Add your custom metric" {...params} sx={{
                    marginBottom: 0,
                    fontSize: `${pxToRem(isMobile ? 18 : 20)} !important`,
                    '.MuiTextField-root': {
                        marginBottom: 0,
                        fontSize: `${pxToRem(isMobile ? 18 : 20)} !important`,
                        color: '#5E5F5E',
                        opacity: 1, /* Firefox */
                        lineHeight: '145%',
                        fontWeight: 400,

                    },
                    '& .MuiInputBase-root': {
                        padding: `0 !important`,
                    },
                    '& .MuiInputBase-input': {
                        '::placeholder': {
                            fontSize: `${pxToRem(isMobile ? 18 : 20)} !important`,
                            color: '#5E5F5E',
                            opacity: 1, /* Firefox */
                            lineHeight: '145%',
                            fontWeight: 400,
                        },
                        fontSize: `${pxToRem(isMobile ? 18 : 20)} !important`,
                        color: '#5E5F5E',
                        opacity: 1, /* Firefox */
                        lineHeight: '145%',
                        fontWeight: 400,
                    },
                    '.MuiAutocomplete-endAdornment': {
                        display: 'none'
                    }

                }} />}
            />
            <Button variant={isMobile ? "contained" : "outlined"} className={isMobile ? classes.addButton : classes.addButtonNew} disabled={addDisabled} onClick={() => {
                if (selectedValueNew) {
                    try {
                        props.setListOfImprovements([...listOfImprovements, selectedValueNew])
                        handleChangeInputValue({}, null)
                        setFetchedCategory([])
                        setAddDisabled(false)
                        setSelectedValueNew(null)
                    } catch (error) {
                        setFetchedCategory([])
                    }

                }
            }} sx={!isMobile && {
                fontWeight: `400 !important`,
            }}>Add</Button>
        </Box>
        {!isMobile && bottomStepper}
        {isMobile && <Button
            variant="contained"
            className="new-button"
            fullWidth
            sx={{ mb: `0px !important`, height: "60px" }}
            disabled={props.selectedPlans.length <= 0}
            onClick={() => {
                props?.handleClickStep(props.curretStep + 1)
            }}
        >
            <Typography
                variant="h1"
                component="span"
                color={props.selectedPlans.length <= 0 ? "#A0A0A1" : "#124743"}
                sx={{
                    fontSize: `${pxToRem(16)} !important`,
                    lineHeight: "normal !important",
                    textAlign: "center !important",
                    fontWeight: `400 !important`,
                    "&.MuiTypography-root": {
                        "@media  (max-width: 600px) and (min-width: 100px)": {
                            fontSize: `${pxToRem(16)} !important`,
                            lineHeight: "normal !important"
                        },
                    }
                }}
            >
                Continue

            </Typography>
        </Button>}
    </RootStyle>
}

export default LeftSideImprovement