import * as React from "react";

const FacebookIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
    >
      <rect width="60" height="60" rx="8" fill="#1877F2" />
      <path
        d="M37.4463 32L38.335 26.2088H32.7782V22.4506C32.7782 20.8663 33.5544 19.3219 36.0432 19.3219H38.5694V14.3913C38.5694 14.3913 36.2769 14 34.085 14C29.5088 14 26.5175 16.7738 26.5175 21.795V26.2088H21.4307V32H26.5175V46H32.7782V32H37.4463Z"
        fill="white"
      />
    </svg>
  );
};
export default FacebookIcon;

export const NewFacebookIcon = () => {
  return <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="56" height="56" rx="12" fill="#F9F9F9"/>
  <path d="M38.6775 39.9978C39.4133 39.9978 40 39.4018 40 38.6755V17.3223C40 16.5867 39.404 16 38.6775 16H17.3225C16.596 16 16 16.596 16 17.3223V38.6755C16 39.4111 16.596 39.9978 17.3225 39.9978H38.6775Z" fill="#4868AD"/>
  <path d="M32.5591 39.9998V30.7062H35.679L36.1447 27.0837H32.5591V24.7742C32.5591 23.7219 32.8478 23.0142 34.3565 23.0142H36.2751V19.7642C35.9398 19.7176 34.8036 19.6245 33.4811 19.6245C30.7151 19.6245 28.8245 21.31 28.8245 24.411V27.0837H25.6953V30.7062H28.8245V39.9998H32.5591Z" fill="white"/>
  </svg>
  
}