import * as React from "react";
import { styled } from "@mui/material/styles";
import NoInfluenceFound from "./noInfluenceFound";
import OutcomeSuggestionList from "./outcomeSuggestionList";
import RecommendedList from "./recommendedList";
import useWidth from "../../utils/useWidth";

const RootStyle = styled("div")(({ theme }) => ({
  padding: "60px",
  [theme.breakpoints.down("lg")]: {
    padding: "50px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "40px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "20px",
  },
}));

interface RightSideInfluenceModal {
  setInfluenceData: (influenceData: any) => void;
  setInfluenceIdeas: (influenceIdeas: any) => void;
}

const RightSideInfluence = (props: RightSideInfluenceModal) => {
  const width = useWidth();
  return (
    <RootStyle style={{ display: width < 768 ? "none" : "block", overflowY: 'auto', height: '100vh'}}>
      {/* NO INFLUENCE FOUND */}
      <NoInfluenceFound setInfluenceData={props.setInfluenceData} />

      {/* OUTCOME LIST */}
      <OutcomeSuggestionList />

      {/* INFLUENCE SUGGESTION LIST */}
      <RecommendedList
        setInfluenceData={props.setInfluenceData}
        setInfluenceIdeas={props.setInfluenceIdeas}
      />
    </RootStyle>
  );
};
export default RightSideInfluence;
