import React from "react"

const EyeIcon = () => {
    return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_130_1169)">
            <path d="M8.0001 3C3.80909 3 1.74644 5.80047 0.973803 7.2097C0.794464 7.5368 0.704794 7.70035 0.717682 7.98082C0.73057 8.26129 0.839196 8.42235 1.05645 8.74448C1.99615 10.1378 4.36844 13 8.0001 13C11.6318 13 14.004 10.1378 14.9437 8.74448C15.161 8.42235 15.2696 8.26129 15.2825 7.98082C15.2954 7.70035 15.2057 7.5368 15.0264 7.2097C14.2538 5.80047 12.1911 3 8.0001 3Z" stroke="#B7BDB9" stroke-width="1.5" />
            <circle cx="8.0001" cy="8.00001" r="2.4" stroke="#B7BDB9" stroke-width="1.5" />
        </g>
        <defs>
            <clipPath id="clip0_130_1169">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>

}

export default EyeIcon