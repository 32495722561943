/*eslint @typescript-eslint/no-unused-vars: "off"*/
import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  DialogActions,
  FormControl,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { useFormik } from "formik";
import clsx from "clsx";
import * as yup from "yup";

import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import { styled } from "@mui/material/styles";

// import palette from "../../theme/palette";
// import ProgressStepper from "./progressStepper";
import ModalCloseIcon from "../../../assets/images/icon/modalCloseIconBlack";
import { ContactsDataJson as contacts, ReviewData } from "./contactsData";
import { ButtonLoader } from "../../../components/buttonLoader";
import palette from "../../../theme/palette";
import { ContactsContext } from "../../../context/userIContacts";
import NewButton from "../../../components/NewButton";
import CommonTypo from "../../../components/CommonTypo";
// import { trackingService } from "../../services/tracking";
// import { UserTypeContext } from "../../context/userStatus";
// import { ButtonLoader } from "../../components/buttonLoader";
// import OutcomeclosePopup from "./OutcomeclosePopup";
// import DeleteIcon from "../../assets/images/icon/DeleteIcon";
// import { addCategoryJourneyAction, addJourneyAction } from "../../store/actions/userTypeAction";
const validationSchema = yup.object({
  name: yup.string().required("Name is required"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
});
const CustomCheckBox = styled(Checkbox)(({ theme }) => ({
  color: "#DCE6E0",
  borderRadius: "4px",

  "&.Mui-checked": {
    color: "#11734A",
    borderRadius: "4px",
  },
}));
const CustomRadio = styled(Radio)(({ theme }) => ({
  color: "#DCE6E0",
  borderRadius: "4px",

  "&.Mui-checked": {
    color: "#11734A",
    borderRadius: "4px",
  },
}));
const useStyles = makeStyles((theme) => ({
  pageTitle: {
    marginBottom: "16px!important",
  },
  raioOptionInputs: {
    marginBottom: "0px !important",
    "& .MuiOutlinedInput-root": {
      height: "48px",

      /* BackgroundColors/GreenyAreaBG */

      /* BackgroundColors/Dividers */

      border: "1px solid #DCE6E0",
      borderRadius: "8px",
      padding: "0 4px",
      margin: "auto",
      "& input": {
        padding: "2px !important",
      },
    },
  },
  bottomButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "& button:nth-child(2)": {
      marginLeft: "24px",
    },
  },
  namegroupDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "16px 0",
  },
  nameEmailDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  groupDivNameText: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "100%",
    /* identical to box height, or 16px */

    display: "flex",
    alignItems: "center",

    /* TextColors/DarkGrey */

    color: "#3E4240",
    margin: "4px 0",
  },
  nameSelection: {
    display: "flex",
  },
  groupDivEmailText: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "100%",
    /* identical to box height, or 16px */

    display: "flex",
    alignItems: "center",

    /* TextColors/DarkGrey */

    color: "#9BA19D",
    margin: "4px 0",
  },
  radioButton: {
    "& .Mui-checked": {
      color: palette.primary.main,
    },
  },
}));
interface AddContact {
  editData?: any;
  visibleAddContactModal: boolean;
  setVisibleAddContactModal: (val: boolean) => void;
  handleAddContact: (val: any, update: any) => void;
  handleAddGroup?: (val: any, update: any) => void;
  showLoadingBtn: boolean;
  setShowLoadingBtn: (val: boolean) => void;
  updateGroupInfo?: any;
  selectedAnchor?: any;
  isAddInGroup?: boolean;
}

const AddContactModal = (props: AddContact) => {
  const classes = useStyles();
  const { state: IContactTypeState, dispatch: contactsDispatch } =
    useContext(ContactsContext);
  const [isOwn, setIsOwn] = useState("own");
  const [allContacts, setAllContacts] = useState(
    IContactTypeState?.allContacts
  );
  const [name, setName] = useState(props.editData?.name);
  const [email, setEmail] = useState(props.editData?.email);
  const [contactsData, setContactsData] = useState(contacts);
  const [radioValue, setRadioValue] = useState("immediatly");
  // const { state: outcomeState, dispatch: UserTypeDispatch } = useContext(UserTypeContext);
  const [openStartModal, setStartModal] = useState(true);
  const { showLoadingBtn, setShowLoadingBtn } = props;
  const [outcomeNotes, setOutcomeNotes] = useState<any>("");
  const [item, setItem] = useState<any>();
  const [openOutcomeclosePopup, setopenOutcomeclosePopup] = useState(false);
  const [openOutcomeDeletePopup, setopenOutcomeDeletePopup] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [isValidEmail, setIsValidemail] = useState(false);

  const setContactChecked = (checked, contact) => {
    const allContactData = [...allContacts];
    const foundContact = allContactData.findIndex(
      (it) => it._id === contact._id
    );
    if (foundContact > -1) {
      allContactData[foundContact].checked = checked;
      setAllContacts(allContactData);
    }
  };

  useEffect(() => {
    if (
      props?.updateGroupInfo &&
      props?.updateGroupInfo?.contacts &&
      props?.updateGroupInfo?.contacts?.length
    ) {
      const filteredArr = [...allContacts].filter((it) => {
        const foundContact = [...props?.updateGroupInfo?.contacts].find(
          (ite) => ite.email === it.email
        );
        return !foundContact;
      });
      setAllContacts(filteredArr);
    }
    // if(props.editData?.name) {
    //   formik.setFieldValue('name', props.editData?.name, true)
    //   formik.setFieldValue('email', props.editData?.email, true)
    //   formik.validateOnMount = true
    // }
    // if(props.editData?.email) {
    // }
    return () => {
      setShowLoadingBtn(false);
      setIsOwn("own");
    };
  }, []);
  const ValidateEmail = (input) => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(input)) {
      return true;
    } else {
      return false;
    }
  };
  const formik = useFormik({
    initialValues: {
      name: (props.editData?.name as string) || "",
      email: (props.editData?.email as string) || "",
    },
    isInitialValid:
      props.editData && Object.keys(props.editData).length ? true : false,
    validateOnMount: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // setShowLoader(true);
      // handleJoinWaitlist({
      //   "user_email": values.email,
      //   "name": values.name
      // })
    },
  });
  // useEffect(() => {
  //   if (email) {
  //     const validatEmail = ValidateEmail(email)
  //     console.log("email.length", validatEmail);
  //     if (!validatEmail) {
  //       setIsValidemail(false)
  //       setErrors({
  //         ...errors,
  //         email: "Please enter proper email"
  //       })
  //     } else {
  //       setIsValidemail(true)
  //       setErrors({
  //         ...errors,
  //         email: ""
  //       })
  //     }
  //   } else {
  //     setIsValidemail(false)
  //     setErrors({
  //       ...errors,
  //       email: ""
  //     })
  //   }
  // }, [email])

  return (
    <Grid>
      <Dialog
        onClose={() => props.setVisibleAddContactModal(false)}
        open={props.visibleAddContactModal}
        className="dialogWrapper dialogWrapper-addContact dialogWrapper-mobileScreen"
        fullWidth
        sx={{
          ".MuiPaper-root": {
            minHeight: "492px",
            minWidth: "360px",
            width: "360px",
            padding: "0px !important",
            borderRadius: "12px",
            display: "flex",
            gap: "16px",
            flexDirection: "column",
          },
        }}
      >
        <Box height={"100%"} minHeight={"inherit"} padding={"32px 24px"} display={"flex"} flexDirection={"column"} width={"100%"} justifyContent={"space-between"}>
          <Box display={"flex"} flexDirection={"column"} gap={"16px"}>
            <Box display={"flex"} alignSelf={"stretch"} width={"100%"} flexDirection={"column"} gap={"16px"}>
              <Box sx={{
                cursor: "pointer"
              }} onClick={() => props.setVisibleAddContactModal(false)}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.796 18.704C16.0073 18.9154 16.1261 19.202 16.1261 19.5009C16.1261 19.7998 16.0073 20.0864 15.796 20.2978C15.5846 20.5091 15.298 20.6279 14.9991 20.6279C14.7002 20.6279 14.4136 20.5091 14.2022 20.2978L6.70222 12.7978C6.59734 12.6933 6.51413 12.5691 6.45735 12.4323C6.40057 12.2956 6.37134 12.149 6.37134 12.0009C6.37134 11.8528 6.40057 11.7062 6.45735 11.5695C6.51413 11.4327 6.59734 11.3086 6.70222 11.204L14.2022 3.70404C14.4136 3.49269 14.7002 3.37396 14.9991 3.37396C15.298 3.37396 15.5846 3.49269 15.796 3.70404C16.0073 3.91538 16.1261 4.20203 16.1261 4.50091C16.1261 4.7998 16.0073 5.08644 15.796 5.29779L9.09379 12L15.796 18.704Z" fill="black" />
                </svg>

              </Box>
              <CommonTypo size={24} lineHeight={29} color="var(--color-text-primary)" fontWeight={700}>Adding new contact</CommonTypo>
              {props?.isAddInGroup &&
                props?.updateGroupInfo &&
                props?.updateGroupInfo?.groupName &&
                !Object.keys(props?.editData || {}).length ? (
                <FormControl>
                  <RadioGroup
                    className="radio-wrapper"
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                    onChange={(e) => setIsOwn(e.target.value)}
                    value={isOwn || ""}
                  >
                    <FormControlLabel
                      value="all"
                      control={<Radio />}
                      className={classes.radioButton}
                      label={"From All Contacts"}
                    />
                    <FormControlLabel
                      value="own"
                      control={<Radio />}
                      className={classes.radioButton}
                      label={"Add Custom"}
                    />
                  </RadioGroup>
                </FormControl>
              ) : null}
              {isOwn === "all" ? (
                <div style={{ marginTop: "20px" }}>
                  {allContacts.map((it) => {
                    return (
                      <div className={classes.nameSelection}>
                        <CustomCheckBox
                          checked={it.checked}
                          onChange={(e) => {
                            setContactChecked(e.target.checked, it);
                          }}
                        />
                        <div className={classes.namegroupDiv}>
                          <div className={classes.nameEmailDiv}>
                            <span className={clsx(classes.groupDivNameText)}>
                              {it.name}
                            </span>
                            <span className={clsx(classes.groupDivEmailText)}>
                              {it.email}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <>
                  <div>
                    <TextField
                      variant="outlined"
                      fullWidth
                      name="name"
                      placeholder="Name"
                      // className={classes.raioOptionInputs}
                      onChange={(e) => {
                        setName(e.target.value);
                        formik.handleChange(e);
                      }}
                      sx={{
                        alignSelf: "stretch",
                        borderRadius: "12px",
                        height: "50px",
                        marginBottom: "0px !important",
                        color: "var(--color-text-primary) !important",
                        "& .MuiOutlinedInput-input": {
                          color: "var(--color-text-primary) !important",
                        },
                        "& .MuiInputBase-root": {
                            border: "1px solid var(--color-border) !important",
                            borderRadius: "12px !important",
                            height: "50px",
                            "& .MuiInputBase-input": {
                                borderRadius: "12px",
                                height: "50px",
                                padding: "16px !important",
                                "&::placeholder": {
                                    color: "var(--color-text-primary) !important",
                                    fontSize: "14px",
                                    opacity: 1,
                                    fontWeight: "400 !important"
                                }
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                                display: "none"
                            }
                        }
                    }}
                      error={formik.touched.name && Boolean(formik.errors.name)}
                      helperText={formik.touched.name && formik.errors.name}
                      value={formik.values.name}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  <div>
                    <TextField
                      variant="outlined"
                      fullWidth
                      name="email"
                      placeholder="Email"
                      // className={classes.raioOptionInputs}
                      sx={{
                        alignSelf: "stretch",
                        borderRadius: "12px",
                        height: "50px",
                        marginBottom: "0px !important",
                        "& .MuiInputBase-root": {
                            border: "1px solid var(--color-border) !important",
                            borderRadius: "12px !important",
                            height: "50px",
                            "& .MuiInputBase-input": {
                                borderRadius: "12px",
                                height: "50px",
                                padding: "16px !important",
                                "&::placeholder": {
                                    color: "var(--color-text-primary) !important",
                                    fontSize: "14px",
                                    opacity: 1,
                                    fontWeight: "400 !important"
                                }
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                                display: "none"
                            }
                        }
                    }}
                      error={formik.touched.email && Boolean(formik.errors.email)}
                      helperText={formik.touched.email && formik.errors.email}
                      value={formik.values.email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                </>
              )}
            </Box>
          </Box>
          <NewButton
            buttonText={
              props?.editData && Object.keys(props?.editData).length
                ? "Update"
                : "Save"
            }
            extraSx={{
              height: "40px"
            }}
            typographySx={{
              fontSize: "16px",
              fontWeight: 600,
              lineHeight: "16px",
              letterSpacing: "0.02em",
              textAlign: "left",
            }}
            borderRadius="20px"
            padding="16px"
            isLoadong={showLoadingBtn}
            onClick={() => {
              setShowLoadingBtn(true);
              if (isOwn === "all") {
                props.handleAddGroup(
                  {
                    ...props?.updateGroupInfo,
                    contacts: [
                      ...props?.updateGroupInfo?.contacts,
                      ...allContacts
                        .filter((it) => it.checked)
                        .map((it) => ({
                          name: it.name,
                          email: it.email,
                        })),
                    ],
                    id: { ...(props?.updateGroupInfo || {}) }?._id,
                  },
                  { ...(props?.updateGroupInfo || {}) }?._id
                );
              } else {
                props.handleAddContact(
                  {
                    ...formik.values,
                    id: props.editData?._id,
                  },
                  props.editData?._id
                );
              }
            }}
            variant="contained"
            type="submit"
            isDisabled={
              isOwn === "all"
                ? !(allContacts.filter((it) => it.checked).length > 0)
                : !formik.isValid
            }
          />
        </Box>

        {/* <DialogActions>
          <Box className={classes.bottomButton}>
            <NewButton
              buttonText="Cancel"
              onClick={() => props.setVisibleAddContactModal(false)}
              variant="outlined"
            />

          </Box>
        </DialogActions> */}
      </Dialog>
    </Grid>
  );
};
export default AddContactModal;
