import React, { useReducer } from "react";
import { IContactsActions, ContactsContextModal, IContactsState } from "../interfaces/contactsModal";


const defaultState: IContactsState = {
  allContacts: [],
  groupContacts: [],
  shareModalOpen: false
};

const reducer = (
  state: IContactsState,
  action: IContactsActions
): IContactsState => {
  switch (action.type) {
    case "SET_ALL_CONTACTS":
      return {
        ...state,
        allContacts: action.payload
      }
    case "SET_GROUP_CONTACTS":
      return {
        ...state,
        groupContacts: action.payload
      }
    case "SET_SHARE_MODAL_OPEN":
      return {
        ...state,
        shareModalOpen: action.payload
      }
    default:
      return state;
  }
};

export const ContactsContext = React.createContext({} as ContactsContextModal);
type childernType = {
  children: React.ReactNode;
};
export const Provider: React.FC = (props: childernType) => {
  const [state, dispatch] = useReducer(reducer, defaultState);

  return (
    <ContactsContext.Provider value={{ state, dispatch }}>
      {props.children}
    </ContactsContext.Provider>
  );
};
