/*eslint eqeqeq: "off"*/
import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  Button,
  Grid,
  TextField,
  Typography,
  SvgIcon,
  Dialog,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { IconButton, makeStyles, Menu, MenuItem } from "@material-ui/core";
import palette from "../../theme/palette";
import GetIdeasIcon from "../../assets/images/icon/getIdeas";
import ProgressBarStepper from "./progressBarStepper";
import {
  fixOutcomeColorPalette,
  fixOutcomeIcon,
  ProUserStepper,
  UserStepper,
} from "../../utils/staticDataJson";
import IconPickerDialog from "./iconPickerDialog";
import {
  OutcomeGetResponse,
  OutComeIconModal,
} from "../../interfaces/outcomeModal";
import ColorPickerDialog from "./colorPickerDialog";
import BottomStepper from "./BottomStepper";
import clsx from "clsx";
import IdeasDrawer from "./ideasDrawer";
import shape from "../../theme/shape";
import { outcomeService } from "../../services/outcome";
import SnackBarComp from "../../components/snackBar";
import { ButtonLoader } from "../../components/buttonLoader";
import { UserTypeContext } from "../../context/userStatus";
import {
  addCategoryJourneyAction,
  addJourneyAction,
  addNotesAction,
  addNotesJourneyAction,
  addOutcomeAction,
  setUserStatus,
} from "../../store/actions/userTypeAction";
import moment from "moment";
import { trackingService } from "../../services/tracking";
import Donts from "../../assets/images/icon/Dots";
import { userService } from "../../services/auth";
import { setTimeout } from "timers/promises";
import { useNavigate } from "react-router-dom";
import SwitchInfluenceModal from "../tracker/switchInfluenceModal";
import useWidth from "../../utils/useWidth";
import _debounce from "lodash/debounce";
import ViewNote from "../../assets/images/icon/ViewNote";
import Wellbeing from "./Wellbeing";
import StepperBackArrowDisable from "../../assets/images/icon/stepperBackArrowDisable";
import StepperNextArrow from "../../assets/images/icon/stepperNextArrow";
import StepperBackArrow from "../../assets/images/icon/stepperBackArrow";
import StepperNextArrowDisable from "../../assets/images/icon/stepperNextArrowDisable";

const RootStyle = styled("div")(({ theme }) => ({
  padding: "60px 48px 60px 80px",
  position: "relative",
  height: "100vh",
  [theme.breakpoints.down("xl")]: {
    padding: "50px 40px 50px 60px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "40px 20px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "20px",
  },
}));

const useStyles = makeStyles((theme) => ({
  registrationHeading: {
    color: palette.primary.light,
  },
  pageTitle: {
    marginTop: "40px!important",
    marginBottom: "48px!important",
    [theme.breakpoints.down("lg")]: {
      marginTop: "30px!important",
      marginBottom: "38px!important",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "20px!important",
      marginBottom: "28px!important",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "15px!important",
      marginBottom: "20px!important",
    },
  },
  needSomeIdeas: {
    fontSize: "16px",
    lineHeight: "16px",
    color: palette.primary.main,
    marginLeft: "8px",
    [theme.breakpoints.down("lg")]: {
      marginTop: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "14px",
    },
  },
  colorBlock: {
    cursor: "pointer",
    backgroundColor: palette.secondary.light,
    border: "1px solid #DCE6E0",
    borderRadius: shape.borderRadius,
    padding: "11px 16px",
    display: "flex",
    alignItems: "center",
    marginLeft: "16px",
    [theme.breakpoints.down("lg")]: {
      marginLeft: 0,
    },
  },
  spaceBetween: {
    [theme.breakpoints.down("lg")]: {
      marginTop: "10px",
      marginBottom: "10px",
    },
  },
  colorBlockText: {
    margin: 0,
    color: palette.primary.light,
    fontSize: "16px",
    lineHeight: "16px",
    fontWeight: 500,
  },
  colorCode: {
    width: "24px",
    height: "24px",
    marginLeft: "10px",
    borderRadius: shape.borderRadiusSm,
  },
  outcomeTitle: {
    marginTop: "48px!important",
    marginBottom: "20px!important",
    [theme.breakpoints.down("lg")]: {
      marginTop: "40px!important",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "30px!important",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px!important",
    },
  },
  ideasListItem: {
    backgroundColor: palette.common.white,
    borderRadius: shape.borderRadius,
    padding: "24px",
    fontSize: "16px",
    lineHeight: "16px",
    color: palette.primary.light,
    fontWeight: 400,
    marginBottom: "12px",
    cursor: "pointer",
  },
}));

const ariaLabel = { "aria-label": "description" };

interface LeftSideOutComeModal {
  outComeValue: any;
  setOutcomeValue: (outComeValue: string) => void;
  outcomeIdeas: any;
  setShowOutcome: (showOutcome: boolean) => void;
  showOutcome: boolean;
  setAddOutcomeTracking: (addOutcomeTracking: boolean) => void;
  setTextInput: any;
  textInput: any;
  ispersonalMode?: boolean;
  handleCloseStartModal?: any;
  getOutcomeList?: any;
  categoryId?: any;
  setTransformToInfluenceTracking?: any;
  width?: any;
  setShowSnakbar?: any;
  setSnakbarMsg?: any;
  setMsgType?: any;
  startDate?: any
  endDate?: any
  onboarding?: boolean
}

const LeftSideOutCome = (props: LeftSideOutComeModal) => {
  const classes = useStyles();
  const [openIcon, setOpenIcon] = useState(false);
  const [openWellbeing, setopenWellbeing] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState<any>(fixOutcomeIcon[0].path);
  const [openColor, setOpenColor] = useState(false);
  const [outcomeDesc, setOutcomeDesc] = useState("");
  const [selectedColor, setSelectedColor] = useState<any>(
    fixOutcomeColorPalette[0]
  );
  const [outcomeValueInput, setOutComeValueInput] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [editStatus, setEditStatus] = useState(null);
  // const [showSnakbar, setShowSnakbar] = useState(false);
  // const [snakbarMsg, setSnakbarMsg] = useState("");
  // const [msgType, setMsgType] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [showIdeasMobile, setShowIdeasMobile] = useState(false);
  const [openSwitchInfluenceModal, setopenSwitchInfluenceModal] =
    useState(false);
  // const { state: IUserTypeState, } =
  //   useContext(UserTypeContext);
  const userLevels = JSON.parse(localStorage.getItem("userValues"))
  const [activeStep, setActiveStep] = React.useState(
    false ? 2 : false ? 1 : 0
  );
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };
  const userType =
    userLevels?.level == "HIGH" ? ProUserStepper : UserStepper;
  const { state: outcomeState, state: IUserTypeState, dispatch: userTypeDispatch } =
    useContext(UserTypeContext);
  const compareDate = parseInt(moment().format("D"));
  const startDay =
    props.startDate ? moment(props.startDate) : moment().clone().subtract(10, "day");
  const endDay =
    props.endDate ? moment(props.endDate) : moment().clone().add(10, "day");
  const navigate = useNavigate();

  const [suggestions, setSuggestions] = useState([]);

  const handleChangeWithLib = (value) => {
    let bodyData = {
      skip: 0,
      limit: 20,
      search: value,
    };
    trackingService
      .outcomeSearch(outcomeState.token, bodyData)
      .then((res) => setSuggestions(res.data));
  };

  const debounceFn = useCallback(_debounce(handleChangeWithLib, 500), []);

  const NativeOutcomeStatus = [
    { text: "very low" },
    { text: "low" },
    { text: "medium" },
    { text: "high" },
    { text: "very high" },
  ];

  const handleCloseStartModal = () => {
    setShowIdeasMobile(false);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if (userLevels?.level == "HIGH") {
      if (activeStep != userType.length - 1) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    let nextStep = activeStep + 1;
    setSkipped(newSkipped);
    handleSliderNextValue(nextStep);
  };

  const handleSliderNextValue = (step: number) => {
    if (userLevels?.level == "HIGH") {
      if (step == 3) {
        userTypeDispatch(setUserStatus(true));
        navigate("/tracking", { replace: true });
      }
      if (step == 2) {
        navigate("/reflective-questions", { replace: true });
      } else if (step == 1) {
        navigate("/influence", { replace: true });
      }
    } else {
      if (step == 1) {
        navigate("/influence", { replace: true });
      }
      if (step == 2) {
        userTypeDispatch(setUserStatus(true));
        navigate("/tracking", { replace: true });
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    const backStep = activeStep - 1;
    handleSliderValue(backStep);
  };

  const handleSliderValue = (step: number) => {
    if (userLevels?.level == "HIGH") {
      if (step == 1) {
        navigate("/influence", { replace: true });
      } else if (step == 0) {
        navigate("/outcome", { replace: true });
      }
    } else {
      if (step === 0) {
        navigate("/outcome", { replace: true });
      }
    }
  };

  useEffect(() => {
    setOpenDrawer(false);
    setOutComeValueInput(props.outComeValue?.name);
    props?.setTextInput(
      props.outComeValue?.options?.length > 0
        ? props.outComeValue?.options
        : NativeOutcomeStatus
    );
    setSelectedColor(
      props.outComeValue?.options
        ? props.outComeValue?.options
        : fixOutcomeColorPalette[0]
    );
    setOutcomeDesc(props?.outComeValue?.description);
    setSelectedIcon(
      props.outComeValue?.icon
        ? props.outComeValue?.icon
        : fixOutcomeIcon[0].path
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.outComeValue]);

  const handleClickOpenIcon = () => {
    setOpenIcon(true);
  };
  const callService = async () => {
    try {
      const bodyData = {
        from: moment(startDay).format("MM-DD-YYYY"),
        to: moment(endDay).format("MM-DD-YYYY"),
      };
      if (!props?.ispersonalMode) {
        let data = await trackingService.getJourneyList(
          outcomeState.token,
          bodyData
        );
        userTypeDispatch(addJourneyAction(data));
        userTypeDispatch(addNotesAction(data));
      } else {
        let data = await trackingService.getPersonalJourneyList(
          outcomeState.token,
          bodyData
        );
        userTypeDispatch(addCategoryJourneyAction(data?.data));
        userTypeDispatch(addNotesJourneyAction(data?.data));
      }
    } catch (error) {
      console.log("jourey list error");
    }
  };
  const handleCloseIcon = (value: OutComeIconModal) => {
    setOpenIcon(false);
    if (value.id === -1) {
      return;
    }
    setSelectedIcon(value.path);
  };
  const handleClickOpenColor = () => {
    setOpenColor(true);
  };

  const handleCloseColor = (value: any) => {
    setOpenColor(false);
    if (value.id === -1) {
      return;
    }
    setSelectedColor(value);
  };

  const handleChangeOutcome = (e: any) => {
    setOutComeValueInput(e.target.value);
    debounceFn(e.target.value);
  };
  const addOutCome = async (values: string) => {
    setShowLoader(true);
    var result: any = selectedColor;
    if (props.outComeValue?.uid && selectedColor.length > 0) {
      result = selectedColor?.map((color: any, index: number) => ({
        text: props?.textInput[index]?.text,
        colorCode: color.colorCode,
        answerId: color?.answerId,
      }));
    } else {
      result = selectedColor?.color?.map((color: any, index: number) => ({
        text: props?.textInput[index]?.text,
        colorCode: color,
        answerId:
          props?.outComeValue && props?.outComeValue?.options[index]?.answerId,
      }));
    }
    let outcomeRes;
    if (props.outComeValue?.uid) {
      outcomeRes = {
        name: values,
        description: outcomeDesc,
        options: result ? result : selectedColor,
        icon: selectedIcon,
        outcomeId: props.outComeValue.outcomeId,
        mixpanel_type: "ON_BOARDING"
      };
    } else {
      outcomeRes = {
        name: values,
        description: outcomeDesc,
        options: result ? result : selectedColor,
        icon: selectedIcon,
        categoryId: props?.categoryId,
        mixpanel_type: "ON_BOARDING"
      };
    }
    // EDIT COUTCOME
    if (props.outComeValue?.uid) {
      outcomeService
        .EditOutcome(outcomeRes, outcomeState.token)
        .then((response: any) => {
          setShowLoader(false);
          props?.setShowSnakbar(true);
          props?.setSnakbarMsg(response.message);
          props?.setMsgType("success");
          if (IUserTypeState.isPersonal) {
            const journeyList = [...outcomeState?.journeyCategoryList];
            const foudnInJourney = journeyList.findIndex(it => it.outcomeIds.includes(props.outComeValue.outcomeId))
            if (foudnInJourney > -1) {
              const foundInOutcomes = journeyList[foudnInJourney].alls.findIndex(it => it.outcomeId === props.outComeValue.outcomeId)
              if (foundInOutcomes > -1) {
                journeyList[foudnInJourney].alls[foundInOutcomes] = {
                  ...journeyList[foudnInJourney].alls[foundInOutcomes],
                  ...outcomeRes
                }
                userTypeDispatch(addCategoryJourneyAction(journeyList));
              }
            } else {
              const foudnOtherJourney = journeyList.findIndex(it => it.name === "Other")
              if (foudnOtherJourney > -1) {
                const foundInOutcomes = journeyList[foudnOtherJourney].outcomes.findIndex(it => it.outcomeId === props.outComeValue.outcomeId)
                if (foundInOutcomes > -1) {
                  journeyList[foudnOtherJourney].alls[foundInOutcomes] = {
                    ...journeyList[foudnOtherJourney].alls[foundInOutcomes],
                    ...outcomeRes
                  }
                  userTypeDispatch(addCategoryJourneyAction(journeyList));
                }
              }
            }
          } else {
            const allOutcomes = [...outcomeState?.outcomeList];
            const foundOutcomeIndex = allOutcomes.findIndex(it => it.outcomeId === props.outComeValue.outcomeId);
            if (foundOutcomeIndex > -1) {
              allOutcomes[foundOutcomeIndex] = {
                ...allOutcomes[foundOutcomeIndex],
                ...outcomeRes
              }
              userTypeDispatch(addOutcomeAction(allOutcomes));

            }
          }
          props.setShowOutcome(false);
          setOutComeValueInput("");
          props.setOutcomeValue("");
          setOutcomeDesc("")
          props?.setTextInput(NativeOutcomeStatus);
        })
        .catch((error) => {
          setShowLoader(false);
          props?.setShowSnakbar(true);
          props?.setSnakbarMsg(
            error?.response?.data?.description
              ? error?.response?.data?.description
              : error.message
          );
          props?.setMsgType("error");
        });

    } else {
      // ADD OUTCOME
      outcomeService
        .createOutcome(outcomeRes, outcomeState.token)
        .then((response: any) => {
          outcomeState.outcomeList.push(response?.data);
          const bodyData = {
            from: moment(startDay).format("MM-DD-YYYY"),
            to: moment(endDay).format("MM-DD-YYYY"),
          };
          trackingService
            .getPersonalJourneyList(outcomeState.token, bodyData)
            .then((data) => {
              userTypeDispatch(addCategoryJourneyAction(data?.data));
              userTypeDispatch(addOutcomeAction(outcomeState.outcomeList));
              props.setAddOutcomeTracking(false);
              setOutComeValueInput("");
              props.setOutcomeValue("");
              setOutcomeDesc("")
              props?.setTextInput(NativeOutcomeStatus);
              setShowLoader(false);
              props?.setShowSnakbar(true);
              props?.setSnakbarMsg(response.message);
              props?.setMsgType("success");
            });
        })
        .catch((error) => {
          setShowLoader(false);
          props?.setShowSnakbar(true);
          props?.setSnakbarMsg(
            error?.response?.data?.description
              ? error?.response?.data?.description
              : error.message
          );
          props?.setMsgType("error");
        });
    }
  };
  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const ITEM_HEIGHT = 48;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onArchiveOutcome = () => {
    const token = localStorage.getItem("userDetails");
    userService
      ?.archiveOutComeData(
        {
          archive: true,
          outcomeId: props?.outComeValue?.outcomeId,
        },
        token
      )
      .then((res) => {
        props?.setShowSnakbar(true);
        props?.setSnakbarMsg(res.message);
        props?.setMsgType("success");
        // setShowOutcome(false);
        // setAddOutcomeTracking(true);
        let timerId: number = window.setTimeout(() => {
          props.handleCloseStartModal();
          props.getOutcomeList();
        }, 1000);
      })
      .catch((error) => {
        props?.setShowSnakbar(true);
        props?.setSnakbarMsg(
          error?.response?.data?.description
            ? error?.response?.data?.description
            : error.message
        );
        props?.setMsgType("error");
      });
  };

  const width = useWidth();
  
  return (
    <RootStyle
    className="hide-scrollbar" 
      sx={!props.showOutcome ? { height: "100vh", overflowY: 'auto', paddingBottom: '100px !important' } : { height: "100vh", overflowY: 'auto',   }}
    >
      {/* <SnackBarComp
        showSnakbar={showSnakbar}
        handleCloseSnakbar={handleCloseSnakbar}
        snakbarMsg={snakbarMsg}
        type={msgType ? msgType : "info"}
      /> */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {width > 768 ? (
          <Typography
            variant="h1"
            component="h1"
            className={classes.registrationHeading}
          >
            {"Outcome"}
          </Typography>
        ) : (
          <Typography
            variant="h2"
            component="h2"
            fontWeight={600}
            marginBottom="40px"
            marginTop="45px"
            // className={classes.registrationHeading}
            color="black"
          >
            {"Add a new outcome"}
          </Typography>
        )}
        {props.outComeValue?.uid && (
          <div>
            <IconButton
              onClick={() => {
                setopenWellbeing(true);
              }}
            >
              <ViewNote />
            </IconButton>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <Donts />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: "20ch",
                },
              }}
            >
              <MenuItem onClick={() => setopenSwitchInfluenceModal(true)}>
                Transform to influence
              </MenuItem>
              <MenuItem
                onClick={() =>
                  navigate(
                    `/version?id=${props?.outComeValue?.outcomeParentId ??
                    props?.outComeValue?.influenceParentId
                    }`
                  )
                }
              >
                History version
              </MenuItem>
              <MenuItem onClick={onArchiveOutcome}>
                Archive this metric
              </MenuItem>
            </Menu>
          </div>
        )}
      </div>
      {width > 768 && (
        <Typography variant="h4" component="h4" className={classes.pageTitle}>
          Awesome! An outcomes is something you care about like your well-being
          and want to understand better.
        </Typography>
      )}
      <Grid
        display="flex"
        // alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        className="hide-scrollbar"
        sx={{
          mb: "16px",
          flexDirection: width > 768 ? "row" : "column",
          alignItems: width > 768 ? "center" : "left",
        }}
      >
        {width > 768 ? (
          <Typography variant="caption" component="div">
            Which outcomes do you care about and want to understand better?
          </Typography>
        ) : (
          <div
            style={{
              fontSize: "16px!important",
              fontWeight: "500",
              lineHeight: "20px",
              color: "black"
            }}
          >
            Which outcomes do you care about and want to understand better?
          </div>
        )}
        {!props.showOutcome && (
          <Grid
            display="flex"
            alignItems="center"
            sx={{
              cursor: "pointer",
              marginTop: width > 768 ? "auto" : "10px",
            }}
            onClick={() => {
              showDrawer();
              setShowIdeasMobile(true);
            }}
          >
            <GetIdeasIcon />
            <span
              className={classes.needSomeIdeas}
              style={{ marginTop: width > 768 ? "10px" : "0px" }}
            >
              Need some ideas?
            </span>
          </Grid>
        )}
        {/* RIGHT SIDE DRAWER */}
        {!props.showOutcome || width > 768 && (
          <IdeasDrawer
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            outcomeIdeas={props.outcomeIdeas}
            setOutcomeValue={props.setOutcomeValue}
          />
        )}
        {showIdeasMobile && width < 769 && (
          <Dialog
            fullScreen
            disableEscapeKeyDown
            onClose={handleCloseStartModal}
            open={showIdeasMobile}
            style={{
              height: "60%",
              bottom: 0,
              top: "auto",
              borderRadius: "35px 35px 0px 0px",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                height: "100%",
                width: "100%",
                backgroundColor: "#11734A",
                padding: "20px",
                paddingTop: "60px",
                color: "#fff",
                overflow: "auto",
              }}
            >
              <Grid
                container
                sx={{ backgroundColor: palette.primary.main }}
              // className={classes.drawerWrapper}
              >
                <Grid>
                  <Typography
                    variant="h2"
                    component="h2"
                    sx={{
                      color: palette.common.white,
                      fontWeight: 500,
                      lineHeight: "35px!important",
                    }}
                  >
                    Our ideas of outcomes
                  </Typography>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      color: palette.common.white,
                      fontWeight: 400,
                      lineHeight: "35px!important",
                    }}
                  >
                    Choose one of our recommended outcomes, and personalize it
                  </Typography>
                </Grid>
                <Grid
                  // className={classes.ideasList}
                  sx={{ width: "100%", mt: "40px" }}
                >
                  {props?.outcomeIdeas?.map((ideas: any) => {
                    return (
                      <div
                        className={classes.ideasListItem}
                        key={ideas._id}
                        onClick={() => {
                          props.setOutcomeValue(ideas);
                          setShowIdeasMobile(false);
                        }}
                      >
                        {ideas.name}
                      </div>
                    );
                  })}
                </Grid>
              </Grid>
            </div>
          </Dialog>
        )}
      </Grid>

      <Grid
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
            width: width > 768 ? "auto" : "100%"
          }}
        >
          <TextField
            className="fillTextInput"
            // style={{width: "18rem",
            //         marginBottom: "0" ,
            //         background: "#f7f9fa",
            //         border: "1px solid  #dce6e0",
            //         fontSize: "16px",
            //         lineHeight: "16px",
            //         fontWeight: 500,
            //         padding: "16px 13px"
            //       }}
            placeholder="The outcome you care about"
            variant="outlined"
            fullWidth
            name="outcome"
            value={outcomeValueInput || ""}
            onChange={(e) => handleChangeOutcome(e)}
            inputProps={ariaLabel}
          />
          <div
            style={{
              backgroundColor: "aliceblue",
              borderRadius: "8px",
              fontSize: "16px",
              maxHeight: "120px",
              overflow: "auto",
              position: "absolute",
              width: "100%",
              top: "55px",
              zIndex: 10,
              color: "black",
            }}
          >
            {outcomeValueInput?.length !== 0 &&
              suggestions?.map((suggestion) => {
                return (
                  <div
                    style={{ padding: "8px", cursor: "pointer" }}
                    onClick={() => {
                      props.setOutcomeValue(suggestion);
                      setSuggestions([]);
                    }}
                  >
                    {suggestion.name}
                  </div>
                );
              })}
          </div>
        </div>

        {true && (
          <div
            className={clsx(classes.colorBlock, classes.spaceBetween)}
            onClick={handleClickOpenColor}
          >
            <p className={classes.colorBlockText}>Set a color</p>
            <span
              className={classes.colorCode}
              style={{
                backgroundColor:
                  props.outComeValue?.uid && selectedColor.length === 0
                    ? props.outComeValue?.options[0]?.colorCode
                    : selectedColor.length > 0
                      ? selectedColor[0]?.colorCode
                      : selectedColor?.color[0],
              }}
            ></span>
          </div>
        )}
        {/* CHOOSE COLOR PALETTSE */}
        {true && (
          <>
            <ColorPickerDialog
              openColor={openColor}
              onCloseColor={handleCloseColor}
            />
            <div className={classes.colorBlock} onClick={handleClickOpenIcon}>
              <p className={classes.colorBlockText}>Choose an icon</p>
              <span className={classes.colorCode}>
                <SvgIcon color="action">
                  <path d={selectedIcon} />
                </SvgIcon>
              </span>
            </div>
            {/* CHOOSE ICON PALETTE */}
            <IconPickerDialog openIcon={openIcon} onClose={handleCloseIcon} />
          </>
        )}
      </Grid>
      {width > 768 ? (
        <Typography
          variant="caption"
          component="div"
          className={classes.outcomeTitle}
        >
          Define your Scale for evaluating your progress
        </Typography>
      ) : (
        <Typography
          component="h2"
          variant="h4"
          // className={classes.outcomeTitle}
          style={{ marginTop: "40px" }}
        >
          Define the scale points
        </Typography>
      )}
      {/* SCALBAR */}
      <ProgressBarStepper
        selectedColor={selectedColor}
        setTextInput={props?.setTextInput}
        textInput={props?.textInput}
        setShowInput={setShowInput}
        setEditStatus={setEditStatus}
        showInput={showInput}
        editStatus={editStatus}
        width={width}
      />
      <div style={{ marginTop: "40px" }}>
        <Typography
          variant="caption"
          component="p"
          className={classes.pageTitle}
        >
          Description
        </Typography>
        <TextField
          variant="outlined"
          className="textarea"
          fullWidth
          placeholder="Add a description..."
          multiline
          minRows={4}
          onChange={(e) => setOutcomeDesc(e.target.value)}
          value={outcomeDesc}
        />
      </div>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        {!props.showOutcome && <Button
          className="no-after"
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mr: 1, color: palette.primary.main, p: 0 }}
          style={{ display: width > 768 ? "none" : "flex" }}
        >
          {activeStep === 0 ? <StepperBackArrowDisable /> : <StepperBackArrow />}
        </Button>}
        {!showLoader ? (
          <Button
            type="submit"
            variant="contained"
            className="button-after outcomeButton"
            fullWidth
            sx={{
              mt: "52px",
              width: width > 768 ? "auto" : !props.showOutcome ? "50% !important" : "100% !important",
              borderRadius: width > 768 ? "auto" : "20px",
            }}
            disabled={outcomeValueInput == "" || outcomeValueInput == undefined}
            onClick={() => addOutCome(outcomeValueInput)}
          >
            {props.outComeValue?.uid ? "Update Outcome" : "Add Outcome"}
          </Button>
        ) : (
          <Button
            variant="contained"
            className="button-after outcomeButton"
            fullWidth
            sx={{
              mt: "52px",
              width: width > 768 ? "auto" : !props.showOutcome ? "50% !important" : "100% !important",
              borderRadius: width > 768 ? "auto" : "20px",
            }}
          >
            <ButtonLoader />
          </Button>

        )}
        {!props.showOutcome && <Button
          className="no-after"
          onClick={handleNext}
          sx={{ color: palette.primary.main, p: 0, cursor: "pointer" }}
          style={{ display: width > 768 ? "none" : "flex" }}
          disabled={
            activeStep === userType.length - 1 ||
            (true
              ? IUserTypeState?.outcomeList?.length == 0
              : false
                ? IUserTypeState?.influenceList?.length == 0
                : false
                  ? IUserTypeState?.reflectiveList?.length == 0
                  : false)
          }
        >
          {activeStep === userType.length - 1 ||
            (true
              ? IUserTypeState?.outcomeList?.length == 0
              : false
                ? IUserTypeState?.influenceList?.length == 0
                : false
                  ? IUserTypeState?.reflectiveList?.length == 0
                  : false) ? (
            <StepperNextArrowDisable />
          ) : (
            <StepperNextArrow />
          )}
        </Button>}
      </div>
      <Wellbeing
        open={openWellbeing}
        journeyData={outcomeState?.notesList?.outcome_data}
        setopenWellbeing={setopenWellbeing}
        outcomeValueInput={outcomeValueInput}
      />
      {/* BOTTOM STEPPER */}
      {!props.showOutcome && (
        <BottomStepper
          isOutcome={true}
          isInfluence={false}
          isRefQues={false}
          isTracking={false}
        />
      )}
      <SwitchInfluenceModal
        open={openSwitchInfluenceModal}
        setopenSwitchInfluenceModal={setopenSwitchInfluenceModal}
        setTransformToInfluenceTracking={props?.setTransformToInfluenceTracking}
        setAddOutcomeTracking={props?.setAddOutcomeTracking}
        setShowOutcome={props?.setShowOutcome}
        outcomeValueInput={outcomeValueInput}
      />
    </RootStyle>
  );
};
export default LeftSideOutCome;
