import React from 'react';
import { styled, Typography } from '@mui/material';
import palette from '../../theme/palette';
import OutcomeList from './OutcomeList';
import { OutcomesList } from './AddCategoryIndex';
import { Button } from '@mui/material';
import { CategoryUpdate } from '../../interfaces/categoryModel';
import NewButton from '../../components/NewButton';

const RootStyle = styled('div')(({ theme }) => ({
  padding: '60px',
  [theme.breakpoints.down('lg')]: {
    padding: '50px',
  },
  [theme.breakpoints.down('md')]: {
    padding: '40px',
  },
  [theme.breakpoints.down('sm')]: {
    padding: '20px',
  },
}));

interface RightSideCategoryModel {
  outcomesList: OutcomesList;
  handleFinish: (finalData: CategoryUpdate[]) => void;
  addCategoryList: any;
}

const RightSideCategory = (props: RightSideCategoryModel) => {
  const modifyCategoryList = () => {
    let finalData = [];
    props.addCategoryList.forEach((category) => {
      let obj = {
        name: category.name,
        categoryId: category.categoryId,
        outcomeIds: category.outcomeIds,
        order: category?.order,
        reflectiveIds: category.reflectiveIds,
        influenceIds: category.influenceIds,
        allIds: category.allIds ?? [...category.outcomeIds, ...category.influenceIds, ...category.reflectiveIds]
      };
      finalData.push(obj);
    });

    return finalData;
  };

  return (
    <RootStyle>
      <Typography
        variant="caption"
        sx={{
          color: palette.secondary.contrast,
          fontWeight: 400,
          position: 'absolute',
          // right: 60,
          width: '80%',
        }}
      >
        {/* Skip Setup */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            backgroundColor: '#F7F9FA',
          }}
        >
          <NewButton
            variant="contained"
            buttonText={"Finish"}
            onClick={() => {
              const finalData = modifyCategoryList();
              localStorage.setItem(
                'categoryCount',
                `${props.addCategoryList?.length}`
              );
              props.handleFinish(finalData);
            }}
            padding="0px 34px"
            boxPadding="14px 16px"
          />
        </div>
      </Typography>
      <div style={{ height: '80vh', overflow: 'auto', marginTop: '80px' }}>
        {!!props.outcomesList.outcomes?.length && (
          <OutcomeList
            title="The Outcomes"
            outcomeState={props.outcomesList.outcomes}
          />
        )}
        {!!props.outcomesList.influences?.length && (
          <OutcomeList
            title="The Influences"
            outcomeState={props.outcomesList.influences}
          />
        )}
        {!!props.outcomesList.reflectives?.length && (
          <OutcomeList
            title="Your Journaling Questions"
            outcomeState={props.outcomesList.reflectives}
          />
        )}
      </div>
    </RootStyle>
  );
};

export default RightSideCategory;
