import React from "react";
import PropTypes from "prop-types";
import { TextField, Tooltip, Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import Slider, { SliderThumb } from "@mui/material/Slider";


function ValueLabelComponent(props) {
  const { children, value } = props;

  return (
    <Tooltip enterTouchDelay={0} title={value}>
      {children}
    </Tooltip>
  );
}

ValueLabelComponent.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.number.isRequired
};

function AirbnbThumbComponent(props) {
  const { children, ...other } = props;
  return (
    <SliderThumb {...other}>
      {children}
    </SliderThumb>
  );
}
AirbnbThumbComponent.propTypes = {
  children: PropTypes.node
};
export default function ActivitySleepQuality({ isMenuSelected, setIsMenuSelected, scalerValue, setScalerValue }) {
  const handleChange = (event, newValue) => {
    setScalerValue(newValue);
  };

  const CustomInputContainerMainwrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "32px",
    textAlign: "center",
    // background: "green",
    width: "700px",
    [theme.breakpoints.down("lg")]: {
      width: '100%'
    },
    [theme.breakpoints.down("md")]: {
      width: "80vw",
    },
    [theme.breakpoints.down("sm")]: {
      width: "59vw",
      left: "1px",
    },
  }));
  const ScalerBox = styled(Box)(({ theme }) => ({
    display: "flex",
    position: 'relative',
    // gap: "10px",
    // background: "blue",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "inherit",

    [theme.breakpoints.up("lg")]: {
      // gap: "35px"
    },
    [theme.breakpoints.down("lg")]: {
      // gap: "20px"
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      // left: "0",
      // gap: "0px"
    },
  }));

  const CustomInputContainerMain = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "15px",
    textAlign: "center",
    height: "150px",
    // background: "red"
  }));


  const BorderFirst = styled("div")(({ theme }) => ({
    borderLeft: "3px solid #11734A",
    borderRadius: "3px 0px 0px 3px",
    paddingLeft: "10px",
  }));

  const BorderLast = styled(Box)(({ theme }) => ({
    borderRight: "3px solid #11734A",
    borderRadius: "70px 7px 8px 0px;",
    paddingRight: "10px",
  }));

  const CustomInputContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "30px",
    height: "30px",
    background: "#F17F79",
    borderRadius: "50%",
    position: "relative",
    zIndex: "2",
  }));


  const CustomInput = styled(TextField)`
    textalign: "center";
    border-radius: 16px;
    textalign: center;
    height: "20px";
    width: "20px";
    color: "white";
    outline: "none";
    border: "none";
    fontweight: "700";
    borderradius: "50%";
    padding: "0px !important";
    & .MuiOutlinedInput-input {
      ::placeholder {
        fontweight: 900;
        font-size: 16px;
        opacity: 1;
      }
    }
  `;

  const TypographyText = styled("div")(({ theme }) => ({
    color: "#3E4240",
    lineHeight: "115%",
    fontSize: "16px",
    width: "100px",
    height: "30px",
    wordBreak: "break-all",

    [theme.breakpoints.down("lg")]: {
    fontSize: "16px",
  },
    [theme.breakpoints.down("md")]: {
    fontSize: "12px",
    height: "40px",
  },
    [theme.breakpoints.down("sm")]: {
    fontSize: "10px",
    height: "15px",
  },
  }));

const AirbnbSlider = styled(Slider)(({ theme }) => ({
  color: "#11734A",
  height: 3,
  width: "calc(100% - 160px)",
  position: "absolute",
  top: "50px",
  padding: "0px!important",
  left: "80px",


  [theme.breakpoints.down("md")]: {
    top: "44px",
  },
  [theme.breakpoints.down("sm")]: {
    width: "68vw",
    top: "58px",
    left: "74px"
  },

  "& .MuiSlider-thumb": {
    height: "28px",
    width: 0,
    border: "1.5px solid #11734A",
    borderRadius: "3px",
  },

  "& .MuiSlider-rail": {
    color: "#DCE5E0",
    height: 3
  },

  "& .MuiSlider-track": {
    color: "#DCE5E0",
    height: "1px",
  }
}));


let activity = isMenuSelected?.outcome ? isMenuSelected?.outComeData?.options : isMenuSelected?.influenceData?.options
return (

  <CustomInputContainerMainwrapper>
    <ScalerBox>
      {activity?.map((item, index) => {
        if (index === 0) {
          return (
            <CustomInputContainerMain>
              <BorderFirst>
                <CustomInputContainer style={{ background: isMenuSelected?.influenceData?.options[index]?.colorCode }}>
                  <Typography variant="caption" sx={{ color: "#fff" }}>1</Typography>
                  {/* <CustomInput
                      sx={{
                        input: {
                          border: "none",
                          color: "#fff",
                          textAlign: "center",
                          padding: "0px",
                          marginTop: "20px",
                          fontSize: "16px",
                        },
                      }}
                      InputProps={{ disableUnderline: true, placeholder: [index + 1] }}
                      type="text"
                      variant="standard"
                    ></CustomInput> */}
                </CustomInputContainer>
              </BorderFirst>
              <TypographyText>{isMenuSelected?.influenceData?.options[index]?.text}</TypographyText>

              <AirbnbSlider
                min={10}
                max={activity?.length * 10}
                value={scalerValue}
                onChange={handleChange}

              />
            </CustomInputContainerMain>)
        } else if (index === 4) {
          return (
            <CustomInputContainerMain>
              <BorderLast>
                <CustomInputContainer style={{ background: isMenuSelected?.influenceData?.options[index]?.colorCode }}>
                  <Typography variant="caption" sx={{ color: "#fff" }}>5</Typography>
                  {/* <CustomInput
                      sx={{
                        input: {
                          border: "none",
                          color: "#fff",
                          textAlign: "center",
                          padding: "0px",
                          marginTop: "20px",
                          fontSize: "16px",
                        },
                      }}
                      InputProps={{ disableUnderline: true, placeholder: [index + 1] }}
                      type="text"
                      variant="standard"
                    ></CustomInput> */}
                </CustomInputContainer>
              </BorderLast>
              <TypographyText>{isMenuSelected?.influenceData?.options[index]?.text}</TypographyText>
            </CustomInputContainerMain>
          )
        } else {
          if(activity?.length < 5 && index === activity?.length - 1){
            return (<CustomInputContainerMain>
              <BorderLast>
              <CustomInputContainer style={{ background: isMenuSelected?.influenceData?.options[index]?.colorCode }}>
                <Typography variant="caption" sx={{ color: "#fff" }}>{index + 1} </Typography>
                {/* <CustomInput
                    sx={{
                      input: {
                        border: "none",
                        color: "#fff",
                        textAlign: "center",
                        padding: "0px",
                        marginTop: "20px",
                        fontSize: "16px",
                      },
                    }}
                    InputProps={{ disableUnderline: true, placeholder: [index + 1] }}
                    type="text"
                    variant="standard"
                  ></CustomInput> */}
              </CustomInputContainer>
              </BorderLast>
              <TypographyText>{isMenuSelected?.influenceData?.options[index]?.text}</TypographyText>
            </CustomInputContainerMain>)
          } else {
            return (
              <CustomInputContainerMain>
                <CustomInputContainer style={{ background: isMenuSelected?.influenceData?.options[index]?.colorCode }}>
                  <Typography variant="caption" sx={{ color: "#fff" }}>{index + 1} </Typography>
                  {/* <CustomInput
                      sx={{
                        input: {
                          border: "none",
                          color: "#fff",
                          textAlign: "center",
                          padding: "0px",
                          marginTop: "20px",
                          fontSize: "16px",
                        },
                      }}
                      InputProps={{ disableUnderline: true, placeholder: [index + 1] }}
                      type="text"
                      variant="standard"
                    ></CustomInput> */}
                </CustomInputContainer>
                <TypographyText>{isMenuSelected?.influenceData?.options[index]?.text}</TypographyText>
              </CustomInputContainerMain>
            )
          }
          
        }
      })}
    </ScalerBox>
  </CustomInputContainerMainwrapper>

);
}
