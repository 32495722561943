import React, { useContext, useState } from "react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { TrackingContext } from "../../context/userTracking";
import { setTrackingState } from "../../store/actions/trackerAction";
import { makeStyles } from "@material-ui/core";
import ModalCloseIconBlack from "../../assets/images/icon/modalCloseIconBlack";
import LeftSideOutComeNew from "../outcome/leftOutcomeNew";
import MultiDrawerAddEditComponent from "../../components/MultiDrawerComponent";
import LeftSideInfluenceNew from "../influence/leftSideInfluenceNew";
import { influenceService } from "../../services/influence";
import { UserTypeContext } from "../../context/userStatus";
import {
  addCategoryJourneyAction,
  addInfluenceAction,
  addNotesJourneyAction,
  addOutcomeAction,
  addRefelectiveAction,
  isEmptyState,
} from "../../store/actions/userTypeAction";
import {
  InfluenceGetResponse,
  OutcomeGetResponse,
  RefelectiveGetResponse,
} from "../../interfaces/outcomeModal";
import { outcomeService } from "../../services/outcome";
import LeftTransformToInflu from "./LeftTransformToInflu";
import LeftTransformToOut from "./LeftTransformToOut";
import LeftTransformInInflu from "./LeftTransformInInflu";
import LeftSideReflectiveQueNew from "../reflective-questions/leftSideRefQueNew";
import { reflectiveQueService } from "../../services/reflective-questions";
import moment from "moment";
import { trackingService } from "../../services/tracking";
import useWidth from "../../utils/useWidth";
const useStyles = makeStyles((theme) => ({
  trackBodyGrid: {
    display: "flex",
    backgroundColor: "white",
    zIndex: 1,
    flexShrink: 0,
    height: "100vh",
    overflow: "auto",
  },
  modalCloseIcon: {
    position: "absolute",
    right: 0,
    top: 0,
    cursor: "pointer",
    zIndex: 1,
  },
  drawerTitle: {
    display: "flex",
    padding: "20px 24px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    alignSelf: "stretch",
    borderBottom: "1px solid #F3F3F3",
    background: "#FFF",
  },
  drawerBody: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
  },
  headerTitle: {
    display: "flex",
    padding: "24px 32px",
    alignItems: "center",
    alignSelf: "stretch",
    borderBottom: "1px solid #F3F3F3",
    background: "#FFF",
    justifyContent: "space-between",
  },
}));

export const UpdateOutome = () => {
  const [needIdeasOpen, setNeedIdeasOpen] = useState(false);
  const { state: ITrackerState, dispatch: trackingDispatch } =
    useContext(TrackingContext);
  const { state: IUserTypeState, dispatch: userTypeDispatch } =
    useContext(UserTypeContext);
  const {
    showOutcome,
    outcomeIdeas,
    outcomeData,
    startDate,
    categoryId,
    endDate,
    outComeValue,
    textInput,
  } = ITrackerState;
  const handleCloseStartModal = () => {
    trackingDispatch(
      setTrackingState({
        openStartModal: false,
        showOutcome: false,
        outcomeId: null,
        outComeValue: null,
        categoryId: null,
      })
    );
  };
  return (
    showOutcome && (
      <MultiDrawerAddEditComponent
        handleClose={handleCloseStartModal}
        drawerTitle={
          <>
            {outComeValue && !outComeValue?.suggested
              ? "Edit"
              : "Add new metric to"}{" "}
            <span style={{ color: "var(--color-new-text)" }}>Outcomes</span>
          </>
        }
        secondOpen={needIdeasOpen}
      >
        <LeftSideOutComeNew
          outComeValue={outComeValue}
          setOutcomeValue={(val) => {
            trackingDispatch(
              setTrackingState({
                outComeValue: val,
              })
            );
          }}
          outcomeIdeas={outcomeIdeas}
          outcomeData={outcomeData}
          setShowOutcome={(val) => {
            trackingDispatch(
              setTrackingState({
                showOutcome: val,
              })
            );
          }}
          categoryId={IUserTypeState.isPersonal ? categoryId : null}
          showOutcome={showOutcome}
          setAddOutcomeTracking={(val) => {
            trackingDispatch(
              setTrackingState({
                addOutcomeTracking: val,
              })
            );
          }}
          setTextInput={(val) => {
            trackingDispatch(
              setTrackingState({
                textInput: val,
              })
            );
          }}
          textInput={textInput}
          setShowSnakbar={(val) => {
            trackingDispatch(
              setTrackingState({
                showSnakbar: val,
              })
            );
          }}
          setMsgType={(val) => {
            trackingDispatch(
              setTrackingState({
                msgType: val,
              })
            );
          }}
          setSnakbarMsg={(val) => {
            trackingDispatch(
              setTrackingState({
                snakbarMsg: val,
              })
            );
          }}
          handleCloseStartModal={handleCloseStartModal}
          getOutcomeList={async () => {
            // if (IUserTypeState.isPersonal) {
            const bodyData = {
              from: moment(startDate).format("MM-DD-YYYY"),
              to: moment(endDate).format("MM-DD-YYYY"),
            };
            let data = await trackingService.getPersonalJourneyList(
              IUserTypeState.token,
              bodyData
            );
            if (data.data.length <= 0) {
              userTypeDispatch(isEmptyState(true));
            } else {
              userTypeDispatch(isEmptyState(false));
            }
            trackingDispatch(
              setTrackingState({
                showLoader: false,
              })
            );
            userTypeDispatch(addCategoryJourneyAction(data?.data));
            userTypeDispatch(addNotesJourneyAction(data?.data));
            // } else {
            outcomeService
              .getOutcomeList(IUserTypeState.token)
              .then((data: OutcomeGetResponse) => {
                userTypeDispatch(addOutcomeAction(data?.data));
              })
              .catch((error) => {
                console.log("Outcome Error");
              });
            // }
          }}
          setTransformToInfluenceTracking={(val) => {
            trackingDispatch(
              setTrackingState({
                transformToInfluenceTracking: val,
              })
            );
          }}
          startDate={startDate}
          endDate={endDate}
          setNeedIdeasOpen={setNeedIdeasOpen}
          setLimitReachPopupVisible={(val) => {
            trackingDispatch(
              setTrackingState({
                limitPopupVisible: val,
              })
            );
          }}
          setLimitReachPopuptype={(val) => {
            trackingDispatch(
              setTrackingState({
                limitPopuptype: val,
              })
            );
          }}
        />
      </MultiDrawerAddEditComponent>
    )
  );
};

export const TransfotmToInfluenceOutcome = ({ callservice }) => {
  const { state: ITrackerState, dispatch: trackingDispatch } =
    useContext(TrackingContext);
  const {
    openStartModal,
    transformToInfluenceTracking,
    textInput,
    outComeValue,
  } = ITrackerState;

  const handleCloseTransformModal = () => {
    trackingDispatch(
      setTrackingState({
        transformToInfluenceTracking: false,
        openStartModal: false,
        showOutcome: false,
        outcomeId: null,
        outComeValue: null,
        categoryId: null,
      })
    );
  };
  const classes = useStyles();
  const width = useWidth();
  const isMobile = width <= 768;
  return (
    transformToInfluenceTracking && (
      <Dialog
        disableEscapeKeyDown
        onClose={(val) => {
          trackingDispatch(
            setTrackingState({
              transformToInfluenceTracking: false,
              openStartModal: false,
              showOutcome: false,
              outcomeId: null,
              outComeValue: null,
              categoryId: null,
            })
          );
        }}
        open={openStartModal}
        className="dialogWrapper dialogWrapper-outcome"
      >
        <DialogTitle className={classes.modalCloseIcon}>
          <span onClick={handleCloseTransformModal}>
            <ModalCloseIconBlack />
          </span>
        </DialogTitle>
        <DialogContent>
          {/* OUTCOME LEFT SECTION */}
          <LeftTransformToInflu
            setTextInput={(val) => {
              trackingDispatch(
                setTrackingState({
                  textInput: val,
                })
              );
            }}
            callservice={callservice}
            textInput={textInput}
            outComeValue={outComeValue}
            setTransformToInfluenceTracking={(val) => {
              if (!val) {
                trackingDispatch(
                  setTrackingState({
                    openStartModal: false,
                    showOutcome: false,
                    outcomeId: null,
                    outComeValue: null,
                    categoryId: null,
                  })
                );
              }
              trackingDispatch(
                setTrackingState({
                  transformToInfluenceTracking: val,
                })
              );
            }}
            isMobile={isMobile}
            // categoryId={categoryId}
          />
        </DialogContent>
      </Dialog>
    )
  );
};

export const InfluenceEdit = ({ getInfluenceList }) => {
  const [needIdeasOpen, setNeedIdeasOpen] = useState(false);
  const { state: ITrackerState, dispatch: trackingDispatch } =
    useContext(TrackingContext);
  const { state: IUserTypeState, dispatch: userTypeDispatch } =
    useContext(UserTypeContext);
  const {
    showInfluence,
    categoryId,
    textInput,
    influenceIdeas,
    influenceIdeasData,
    transformToOutcomeTracking,
    transformInInfluenceTracking,
    startDate,
    endDate,
    influenceData,
  } = ITrackerState;
  const handleCloseStartModal = () => {
    trackingDispatch(
      setTrackingState({
        influenceId: null,
        openStartModalInfluence: false,
        showInfluence: false,
        influenceData: null,
      })
    );
  };

  return (
    showInfluence && (
      <MultiDrawerAddEditComponent
        handleClose={handleCloseStartModal}
        drawerTitle={
          <>
            {influenceData ? "Edit" : "Add new metric to"}{" "}
            <span style={{ color: "var(--color-new-text)" }}>Influences</span>
          </>
        }
        secondOpen={needIdeasOpen}
      >
        <LeftSideInfluenceNew
          setInfluenceData={(val) => {
            trackingDispatch(
              setTrackingState({
                influenceData: val,
              })
            );
          }}
          influenceData={influenceData}
          influenceIdeas={influenceIdeas}
          influenceIdeasData={influenceIdeasData}
          setShowSnakbar={(val) => {
            trackingDispatch(
              setTrackingState({
                showSnakbar: val,
              })
            );
          }}
          setMsgType={(val) => {
            trackingDispatch(
              setTrackingState({
                msgType: val,
              })
            );
          }}
          setSnakbarMsg={(val) => {
            trackingDispatch(
              setTrackingState({
                snakbarMsg: val,
              })
            );
          }}
          showInfluence={showInfluence}
          setAddInfluenceTracking={(val) => {
            trackingDispatch(
              setTrackingState({
                addInfluenceTracking: val,
              })
            );
          }}
          setShowInfluence={(val) => {
            trackingDispatch(
              setTrackingState({
                showInfluence: val,
              })
            );
          }}
          setTextInput={(val) => {
            trackingDispatch(
              setTrackingState({
                textInput: val,
              })
            );
          }}
          textInput={textInput}
          handleCloseStartModal={handleCloseStartModal}
          getInfluenceList={async () => {
            // if (IUserTypeState.isPersonal) {
            const bodyData = {
              from: moment(startDate).format("MM-DD-YYYY"),
              to: moment(endDate).format("MM-DD-YYYY"),
            };
            let data = await trackingService.getPersonalJourneyList(
              IUserTypeState.token,
              bodyData
            );
            if (data.data.length <= 0) {
              userTypeDispatch(isEmptyState(true));
            } else {
              userTypeDispatch(isEmptyState(false));
            }
            trackingDispatch(
              setTrackingState({
                showLoader: false,
              })
            );
            userTypeDispatch(addCategoryJourneyAction(data?.data));
            userTypeDispatch(addNotesJourneyAction(data?.data));
            // } else {
            influenceService
              .getInfluenceList(IUserTypeState.token)
              .then((data: InfluenceGetResponse) => {
                userTypeDispatch(addInfluenceAction(data?.data));
              })
              .catch((error) => {
                console.log("influence Error");
              });
            // }
          }}
          setTransformToOutcomeTracking={(val) => {
            trackingDispatch(
              setTrackingState({
                transformToOutcomeTracking: val,
              })
            );
          }}
          transformToOutcomeTracking={transformToOutcomeTracking}
          transformInInfluenceTracking={transformInInfluenceTracking}
          setTransformInInfluenceTracking={(val) => {
            trackingDispatch(
              setTrackingState({
                transformInInfluenceTracking: val,
              })
            );
          }}
          startDate={startDate}
          endDate={endDate}
          setInfluenceIdeas={(val) => {
            trackingDispatch(
              setTrackingState({
                influenceIdeas: val,
              })
            );
          }}
          setNeedIdeasOpen={setNeedIdeasOpen}
          setLimitReachPopupVisible={(val) => {
            trackingDispatch(
              setTrackingState({
                limitPopupVisible: val,
              })
            );
          }}
          categoryId={IUserTypeState.isPersonal ? categoryId : null}
          setLimitReachPopuptype={(val) => {
            trackingDispatch(
              setTrackingState({
                limitPopuptype: val,
              })
            );
          }}
        />
      </MultiDrawerAddEditComponent>
    )
  );
};

export const TransformInfluToOutcome = ({ callservice }) => {
  const classes = useStyles();
  const { state: ITrackerState, dispatch: trackingDispatch } =
    useContext(TrackingContext);
  const { transformToOutcomeTracking, influenceData, textInput } =
    ITrackerState;
  const width = useWidth();
  const isMobile = width <= 768;
  return (
    transformToOutcomeTracking && (
      <Dialog
        disableEscapeKeyDown
        onClose={() =>
          trackingDispatch(
            setTrackingState({
              transformToOutcomeTracking: false,
              influenceId: null,
              openStartModalInfluence: false,
              showInfluence: false,
              influenceData: null,
            })
          )
        }
        open={true}
        className="dialogWrapper dialogWrapper-outcome"
      >
        <DialogTitle className={classes.modalCloseIcon}>
          <span
            onClick={() =>
              trackingDispatch(
                setTrackingState({
                  transformToOutcomeTracking: false,
                  influenceId: null,
                  openStartModalInfluence: false,
                  showInfluence: false,
                  influenceData: null,
                })
              )
            }
          >
            <ModalCloseIconBlack />
          </span>
        </DialogTitle>
        <DialogContent>
          {/* OUTCOME LEFT SECTION */}

          <LeftTransformToOut
            // setInfluenceData={setInfluenceData}
            // influenceData={influenceData}
            // influenceIdeas={influenceIdeas}
            // showInfluence={showInfluence}
            // setAddInfluenceTracking={setAddInfluenceTracking}
            // setShowInfluence={setShowInfluence}
            setTextInput={(val) => {
              trackingDispatch(
                setTrackingState({
                  textInput: val,
                })
              );
            }}
            callservice={callservice}
            textInput={textInput}
            outComeValue={influenceData}
            setTransformToInfluenceTracking={(val) => {
              if (!val) {
                trackingDispatch(
                  setTrackingState({
                    influenceId: null,
                    openStartModalInfluence: false,
                    showInfluence: false,
                    influenceData: null,
                  })
                );
              }
              trackingDispatch(
                setTrackingState({
                  transformToOutcomeTracking: val,
                })
              );
            }}
            isMobile={isMobile}

            // categoryId={categoryId}
          />
        </DialogContent>
      </Dialog>
    )
  );
};

export const InfluenceToInfluence = ({ callservice }) => {
  const classes = useStyles();
  const { state: ITrackerState, dispatch: trackingDispatch } =
    useContext(TrackingContext);
  const { transformInInfluenceTracking, influenceData, textInput } =
    ITrackerState;
  const width = useWidth();
  const isMobile = width <= 768;
  return (
    transformInInfluenceTracking && (
      <Dialog
        disableEscapeKeyDown
        onClose={() =>
          trackingDispatch(
            setTrackingState({
              transformInInfluenceTracking: false,
              influenceId: null,
              openStartModalInfluence: false,
              showInfluence: false,
              influenceData: null,
            })
          )
        }
        open={true}
        className="dialogWrapper dialogWrapper-outcome"
      >
        <DialogTitle className={classes.modalCloseIcon}>
          <span
            onClick={() =>
              trackingDispatch(
                setTrackingState({
                  transformInInfluenceTracking: false,
                  influenceId: null,
                  openStartModalInfluence: false,
                  showInfluence: false,
                  influenceData: null,
                })
              )
            }
          >
            <ModalCloseIconBlack />
          </span>
        </DialogTitle>
        <DialogContent>
          {/* OUTCOME LEFT SECTION */}
          {/* <LeftSideOutCome
          outComeValue={outComeValue}
          setOutcomeValue={setOutcomeValue}
          outcomeIdeas={outcomeIdeas}
          setShowOutcome={props.setShowOutcome}
          showOutcome={props.showOutcome}
          setAddOutcomeTracking={setAddOutcomeTracking}
          setTextInput={setTextInput}
          textInput={textInput}
          handleCloseStartModal={handleCloseStartModal}
          getOutcomeList={props?.getOutcomeList}
          setTransformToInfluenceTracking={props?.setTransformToInfluenceTracking}
        /> */}
          <LeftTransformInInflu
            // setInfluenceData={setInfluenceData}
            // influenceData={influenceData}
            // influenceIdeas={influenceIdeas}
            // showInfluence={showInfluence}
            // setAddInfluenceTracking={setAddInfluenceTracking}
            // setShowInfluence={setShowInfluence}
            setTextInput={(val) => {
              trackingDispatch(
                setTrackingState({
                  textInput: val,
                })
              );
            }}
            callservice={callservice}
            isMobile={isMobile}
            textInput={textInput}
            outComeValue={influenceData}
            setTransformToInfluenceTracking={(val) => {
              if (!val) {
                trackingDispatch(
                  setTrackingState({
                    influenceId: null,
                    openStartModalInfluence: false,
                    showInfluence: false,
                    influenceData: null,
                  })
                );
              }
              trackingDispatch(
                setTrackingState({
                  transformInInfluenceTracking: val,
                })
              );
            }}
            // categoryId={props?.categoryId}
          />
        </DialogContent>
      </Dialog>
    )
  );
};

export const AddUpdateQuestion = ({ getReflectiveList }) => {
  const [needIdeasOpen, setNeedIdeasOpen] = useState(false);
  const { state: ITrackerState, dispatch: trackingDispatch } =
    useContext(TrackingContext);
  const { state: IUserTypeState, dispatch: userTypeDispatch } =
    useContext(UserTypeContext);
  const {
    showQues,
    questionData,
    categoryId,
    weekday,
    startDate,
    endDate,
    reflectiveIdeas,
    reflectiveIdeasData,
  } = ITrackerState;
  const handleCloseStartModal = () => {
    trackingDispatch(
      setTrackingState({
        influenceId: null,
        openStartModalInfluence: false,
        showQues: false,
        questionData: null,
        weekday: new Map(),
      })
    );
  };

  return (
    showQues && (
      <MultiDrawerAddEditComponent
        handleClose={handleCloseStartModal}
        drawerTitle={
          <>
            {questionData ? "Edit" : "Add new metric to"}{" "}
            <span style={{ color: "var(--color-new-text)" }}>
              Qualitative Reflection
            </span>
          </>
        }
        secondOpen={needIdeasOpen}
      >
        <LeftSideReflectiveQueNew
          setQuestionData={(val) => {
            trackingDispatch(
              setTrackingState({
                questionData: val,
              })
            );
          }}
          questionData={questionData}
          reflectiveIdeas={reflectiveIdeas}
          reflectiveIdeasData={reflectiveIdeasData}
          showRefQues={showQues}
          setAddQuesTracking={() => {}}
          setShowQues={(val) =>
            trackingDispatch(
              setTrackingState({
                showQues: val,
              })
            )
          }
          setWeekDay={(val) =>
            trackingDispatch(
              setTrackingState({
                weekday: val,
              })
            )
          }
          setShowSnakbar={(val) => {
            trackingDispatch(
              setTrackingState({
                showSnakbar: val,
              })
            );
          }}
          setMsgType={(val) => {
            trackingDispatch(
              setTrackingState({
                msgType: val,
              })
            );
          }}
          categoryId={IUserTypeState.isPersonal ? categoryId : null}
          setSnakbarMsg={(val) => {
            trackingDispatch(
              setTrackingState({
                snakbarMsg: val,
              })
            );
          }}
          weekday={weekday}
          handleCloseStartModalQues={handleCloseStartModal}
          handleCloseStartModal={handleCloseStartModal}
          getReflectiveList={async () => {
            // if (IUserTypeState.isPersonal) {
            const bodyData = {
              from: moment(startDate).format("MM-DD-YYYY"),
              to: moment(endDate).format("MM-DD-YYYY"),
            };
            let data = await trackingService.getPersonalJourneyList(
              IUserTypeState.token,
              bodyData
            );
            if (data.data.length <= 0) {
              userTypeDispatch(isEmptyState(true));
            } else {
              userTypeDispatch(isEmptyState(false));
            }
            trackingDispatch(
              setTrackingState({
                showLoader: false,
              })
            );
            userTypeDispatch(addCategoryJourneyAction(data?.data));
            userTypeDispatch(addNotesJourneyAction(data?.data));
            // } else {
            reflectiveQueService
              .getReflectiveList(IUserTypeState.token)
              .then((data: RefelectiveGetResponse) => {
                userTypeDispatch(addRefelectiveAction(data?.data));
              })
              .catch((error) => {
                console.log("Ques error");
              });
            // }
          }}
          startDate={startDate}
          endDate={endDate}
          setNeedIdeasOpen={setNeedIdeasOpen}
          setLimitReachPopupVisible={(val) => {
            trackingDispatch(
              setTrackingState({
                limitPopupVisible: val,
              })
            );
          }}
          setLimitReachPopuptype={(val) => {
            trackingDispatch(
              setTrackingState({
                limitPopuptype: val,
              })
            );
          }}
        />
      </MultiDrawerAddEditComponent>
      // <Dialog
      //   disableEscapeKeyDown
      //   onClose={handleCloseStartModal}
      //   open={props.openStartModalQues}
      //   className="dialogWrapper dialogWrapper-outcome"
      // >
      //   <DialogTitle className={classes.modalCloseIcon}>
      //     <span onClick={handleCloseStartModal}>
      //       <ModalCloseIconBlack />
      //     </span>
      //   </DialogTitle>
      //   <DialogContent>
      //     <LeftSideReflectiveQue
      //       setQuestionData={setQuestionData}
      //       questionData={questionData}
      //       reflectiveIdeas={quesIdeas}
      //       showRefQues={props.showQues}
      //       setAddQuesTracking={setAddQuesTracking}
      //       setShowQues={props.setShowQues}
      //       setWeekDay={setWeekDay}
      //       setShowSnakbar={setShowSnakbar}
      //       setMsgType={setMsgType}
      //       setSnakbarMsg={setSnakbarMsg}
      //       weekday={weekday}
      //       handleCloseStartModal={handleCloseStartModal}
      //       getReflectiveList={props.getReflectiveList}
      //       startDate={props.startDate}
      //       endDate={props.endDate}
      //     />
      //   </DialogContent>
      // </Dialog>
    )
  );
};
