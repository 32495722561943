/*eslint eqeqeq: "off"*/
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Button, Grid, TextField, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { pxToRem } from "../../theme/typography";
import GoogleIcon from "../../assets/images/icon/google-icon";
import FacebookIcon from "../../assets/images/icon/facebook-icon";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import palette from "../../theme/palette";
import SnackBarComp from "../../components/snackBar";
import { userService } from "../../services/auth";
import { facebookProvider, googleProvider } from "../../config/authMethods";
import FullpageLoader from "../../components/fullPageLoader";
import { ButtonLoader } from "../../components/buttonLoader";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../config/firebase";
import {
  CreateUserModal,
  USerContinueRegister,
} from "../../interfaces/outcomeModal";

const validationSchema = yup.object({
  name: yup.string().required("Name is required"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
});

const RootStyle = styled("div")(({ theme }) => ({
  padding: "0 80px",
  [theme.breakpoints.up("xl")]: {
    padding: "60px",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "40px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "30px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "20px",
  },
}));

const useStyles = makeStyles((theme) => ({
  registrationHeading: {
    color: palette.primary.light,
    marginBottom: "62px!important",
    [theme.breakpoints.down("xl")]: {
      marginBottom: "50px!important",
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: "40px!important",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "30px!important",
    },
  },
  socialLoginButton: {
    marginTop: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  alreadyAccountText: {
    marginTop: "60px",
    color: "#9BA19D",
    fontSize: pxToRem(16),
    lineHeight: pxToRem(16),
    fontWeight: 400,
    textAlign: "center",
    [theme.breakpoints.down("xl")]: {
      marginBottom: "0!important",
      marginTop: "24px",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "20px",
    },
  },
  alreadyAccountTextSpan: {
    color: "#11734A",
    cursor: "pointer",
  },
}));

const ariaLabel = { "aria-label": "description" };

const RightPartJoinWaitlist = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [showSnakbar, setShowSnakbar] = useState(false);
  const [showLoaderPage, setShowLoaderPage] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [snakbarMsg, setSnakbarMsg] = useState("");
  const [msgType, setMsgType] = useState("");

  // FIREBASE AUTHENTICATION
  const emailAuth = async (email: string, password: string) => {
    return createUserWithEmailAndPassword(auth, email, password)
      .then((result) => {
        localStorage.setItem("userDetails", JSON.stringify(result));
        return result;
      })
      .catch((err) => {
        setShowLoader(false);
        setShowSnakbar(true);
        setMsgType("error");
        if (err.code == "auth/email-already-in-use") {
          setSnakbarMsg("User with this email already exist");
        } else {
          setSnakbarMsg(err.message);
        }
        return err;
      });
  };

  // REGISTER API
  const userContinueRegister = (values: USerContinueRegister) => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const userData = {
      uid: userDetails?.user?.uid ? userDetails?.user?.uid : userDetails?.uid,
      user_email: userDetails?.user?.email
        ? userDetails?.user?.email
        : userDetails?.email,
      signUpType:
        userDetails?.providerData?.[0]?.providerId == "google.com"
          ? "GMAIL"
          : userDetails?.providerData?.[0]?.providerId == "facebook.com"
          ? "FACEBOOK"
          : "OTHER",
      first_name: values.fname,
      last_name: values.lname,
    };
    userService
      .createUser(userData)
      .then((data: CreateUserModal) => {
        localStorage.setItem("userValues", JSON.stringify(data.data));
        navigate("/user-identification");
      })
      .catch((error) => {
        setShowLoader(false);
        setShowLoaderPage(false);
        setMsgType("error");
        setShowSnakbar(true);
        setSnakbarMsg(error?.response?.data?.description);
      });
  };

  const firebaseLogin = async (values: USerContinueRegister) => {
    setShowLoader(true);
    try {
      const result = await emailAuth(values.email, values.password);
      if (result.user) {
        userContinueRegister(values);
      }
    } catch (error) {
      setShowLoader(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setShowLoader(true);
      handleJoinWaitlist({
        "user_email": values.email,
        "name": values.name
      })
    },
  });
  const handleJoinWaitlist = async (values) => {
      try {
      const result = await userService.joinwaitlist(values);
      
      setMsgType("success");
      setShowSnakbar(true);
      setSnakbarMsg(result.description);
      setTimeout(() => {
        navigate("/");
      }, 1000);
    } catch (error) {
      console.log("error", error.response);
      
      setMsgType("error");
      setShowSnakbar(true);
      if(error.response?.data?.description) {
        setSnakbarMsg(error.response?.data?.description);

      } else {
        setSnakbarMsg(error.message);

      }
    } finally {
      setShowLoader(false);

    }
  }
  const handleCloseSnakbar = () => {
    setShowSnakbar(false);
  };
  const handleOnClick = async (provider: any) => {
    setShowLoaderPage(true);
    try {
      const result = await userService.socialMediaAuth(provider);
      localStorage.setItem("userDetails", JSON.stringify(result));
      if (!result.uid) {
        setShowLoaderPage(false);
        throw new Error(result.message);
      } else {
        userContinueRegister(result);
      }
    } catch (error) {
      setShowLoaderPage(false);
      setMsgType("error");
      setShowSnakbar(true);
      setSnakbarMsg(error.message);
    }
  };
  return (
    <RootStyle className="login-right-part">
      {showLoaderPage && <FullpageLoader />}
      <SnackBarComp
        showSnakbar={showSnakbar}
        handleCloseSnakbar={handleCloseSnakbar}
        snakbarMsg={snakbarMsg}
        type={msgType ? msgType : "info"}
      />
      <Typography
        variant="h1"
        component="h1"
        className={classes.registrationHeading}
      >
        Join our waitlist and be among the first to try
      </Typography>
      <Grid>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            placeholder="Name"
            variant="outlined"
            fullWidth
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            inputProps={ariaLabel}
          />
         
          <TextField
            placeholder="Email"
            variant="outlined"
            fullWidth
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            inputProps={ariaLabel}
          />
          
          {!showLoader ? (
            <Button
              type="submit"
              variant="contained"
              className="button-after"
              fullWidth
              sx={{ mt: "24px", mb: "57px" }}
              disabled={!(formik.isValid && formik.dirty)}
            >
              Join Waitlist
            </Button>
          ) : (
            <Button
              variant="contained"
              className="button-after"
              fullWidth
              sx={{ mt: "24px", mb: "57px" }}
            >
              <ButtonLoader />
            </Button>
          )}
        </form>
        <p className="registation_text">
          <span>OR </span>
        </p>
        
        <p className={classes.alreadyAccountText}>
          Already have an account?{" "}
          <span
            className={classes.alreadyAccountTextSpan}
            onClick={() => navigate("/login")}
          >
            Sign in
          </span>
        </p>
      </Grid>
    </RootStyle>
  );
};
export default RightPartJoinWaitlist;
