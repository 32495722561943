import React, { useContext } from "react"
import { Box, IconButton, Typography } from "@mui/material"
import useStyles from "./TrackingClasses"
import LeftCalendarIcon from "../../assets/images/icon/LeftCalendarIcon"
import RightCalendarIcon from "../../assets/images/icon/RightCalendarIcon"
import { TrackingContext } from "../../context/userTracking"
import moment from "moment"

const CurrentDateTitle = ({
    nextMonth,
    prevMonth,
}) => {
    const { state: ITrackerState, dispatch: trackingDispatch } =
        useContext(TrackingContext);
    const {
        startDate,
        calendar,
        isExpandable
    } = ITrackerState
    const classes = useStyles()
    let addHeight = 0
    let userValues = JSON.parse(localStorage.getItem("userValues"));
    const foundElement = document.getElementById("track-quote")
    if(foundElement) {
        const rect = foundElement.getBoundingClientRect();
        if(rect.height) {
            addHeight = rect.height - 10
        }
    }
    return foundElement && <Box
        display="flex"
        alignItems="center"
        position={"relative"}
        height={"70px"}
        sx={{
            justifyContent: "space-between",
            background: "#FFF",
            width: "100%",
            // padding: "60px 24px 27px 0",
            position: "sticky",
            top: "0px",
            alignSelf: "flex-start",
            zIndex: "100",
        }}>
        <Box
            display="flex"
            alignItems="center"
            position={"fixed"}
            // height={"100%"}
            top={`${120 + addHeight}px`}
            justifyContent={"space-between"}
            width={isExpandable ? "66%" : "58%"}
            paddingRight={"40px"}
        >
            <Typography
                variant="h2"
                component="h2"
                className={classes.monthTitle}
            >
                {calendar[0] && calendar[0][0] && calendar[0][0].format("MMMM")}
            </Typography>
            <div className={classes.monthControls}>
                <IconButton
                    className={classes.iconButtonCalender}
                    onClick={() => {
                        prevMonth()
                    }}
                    disabled={
                        !moment(userValues?.createdAt).isBefore(moment(startDate))
                    }
                >
                    <LeftCalendarIcon />
                </IconButton>

                <IconButton
                    className={classes.iconButtonCalender}
                    onClick={() => {
                        nextMonth()
                    }}
                >
                    <RightCalendarIcon />
                </IconButton>
            </div>
        </Box>
    </Box>
}

export default CurrentDateTitle