import { makeStyles } from "@material-ui/core";
import { Typography } from "@mui/material";
import React from "react";
import palette from "../../theme/palette";
import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { pxToRem } from "../../theme/typography";
const useStyles = makeStyles((theme) => ({
  outcomeTitle: {
    marginLeft: "12px!important",
    marginBottom: "0!important",
    lineHeight: "18px!important",
    color: `${palette.primary.light}!important`,
    maxWidth: "70%",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    lineClamp: 1,
  },
  flexBox: {
    display: "flex",
    alignItems: "flex-end",
    gap: "4px"
  }
}));

interface CustomCardInsightsModal {
  icon: any;
  title: string;
  daysTracked: number;
  avgRating: number;
  selectOutcome: string
  outcomeId: string
  options: any[]
  classNameGet: string
}
const ImgSideBar = styled(Box)(({ theme }) => ({
  width: "36px",
  height: "36px",

}));
const allBGColors = [
  '#68D7A8',
  '#4CB3FD',
  '#7572FC',
  '#A268EC'
]
const foundAndGetBGColor = (className) => {
  switch (className) {
    case "wellBeingCard":
      return "#ffc500"
    case "energyCard":
      return "#68D7A8"

    case "senseCard":
      return "#4CB3FD"

    case "improveCard":
      return "#7572FC"

    case "stressCard":
      return "#A268EC"

    default:
      break;
  }
}
const CustomCardInsights = (props: CustomCardInsightsModal) => {
  const classes = useStyles();
  const bdColor = foundAndGetBGColor(props.classNameGet)
  return (
    <>
      <div className="titlePart" style={{
        maxWidth: '100%'
      }}>
        <ImgSideBar>
          {props.selectOutcome && props.outcomeId === props.selectOutcome ? (
            <div style={{ width: "36px", height: "36px", background: "#fff", borderRadius: "5px", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <svg width="24" height="24" viewBox="0 0 24 24">
                <path d={props.icon} fill="red" />
              </svg>
            </div>
          ) : (
            <div className="outcome-influ-card" style={{ background: bdColor, width: "36px", height: "36px", borderRadius: "5px", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <svg width="24" height="24" viewBox="0 0 24 24">
                <path d={props.icon} fill="#fff" />
              </svg>
            </div>
          )}

        </ImgSideBar>

        <Typography
          variant="h5"
          fontWeight={500}
          className={classes.outcomeTitle}

        >
          {props.title}
        </Typography>
      </div>
      <div className={props.selectOutcome && props.outcomeId === props.selectOutcome ? "ratingPartSelected" : "ratingPart"}>
        {(
          <div className={classes.flexBox}>
            <Typography fontWeight={600} sx={{
              color: props.selectOutcome && props.outcomeId === props.selectOutcome ? palette.common.white : 'var(--color-black)',
              fontSize: `${pxToRem(26)} !important`,
              lineHeight: "100% !important",
              // textAlign: "center !important",
              fontWeight: `600 !important`,
              "&.MuiTypography-root": {
                "@media  (max-width: 600px) and (min-width: 100px)": {
                  fontSize: `${pxToRem(26)} !important`,
                  lineHeight: "100% !important"
                },
              },
            }}>
              {props.daysTracked}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                color: `${props.selectOutcome && props.outcomeId === props.selectOutcome ? palette.common.white : 'var(--color-black)'} !important`,
                fontSize: `${pxToRem(12)} !important`,
                lineHeight: "100% !important",
                // textAlign: "center !important",
                fontWeight: `400 !important`,
                "&.MuiTypography-root": {
                  "@media  (max-width: 600px) and (min-width: 100px)": {
                    fontSize: `${pxToRem(12)} !important`,
                    lineHeight: "100% !important"
                  },
                }
              }}
            >
              Current Avg.
            </Typography>
          </div>
        )/*  : (
          <>
            <div>
              <Typography variant="h3" fontWeight={600}>
                {props.daysTracked}
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 400,
                  fontSize: "12px!important",
                  marginTop: "2px",
                }}
              >
                Current Avg.
              </Typography>
            </div>
            <div>
              <Typography variant="h3" fontWeight={600}>
                {props.avgRating}
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 400,
                  fontSize: "12px!important",
                  marginTop: "2px",
                }}
              >
                Target Avg.
              </Typography>
            </div>
          </>
        ) */}

        {/* */}
        {/* <div>
          <Typography variant="h3" fontWeight={600}>
            {props.avgRating}
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 400,
              fontSize: "12px!important",
              marginTop: "2px",
            }}
          >
            Target Avg.
          </Typography>
        </div> */}
      </div>
    </>
  );
};

export default CustomCardInsights;
