/*eslint eqeqeq: "off"*/
import React, { useState, useContext, useEffect, useCallback } from "react";
import {
  Typography,
  Grid,
  TextField,
  Button,
  MenuItem,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Dialog,
  SvgIcon,
  Popover,
  List,
  ListItem,
  Drawer,
  Box,
  TextareaAutosize,
  IconButton,
  Menu,
  Slider,
  ClickAwayListener,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core";
import GetIdeasIcon from "../../assets/images/icon/getIdeas";
import palette from "../../theme/palette";
import BottomStepper from "../outcome/BottomStepper";
import {
  InfluenceGetResponse,
  InfluenceOption,
  OutComeColorModal,
} from "../../interfaces/outcomeModal";
import {
  fixOutcomeColorPalette,
  InfluenceSelectOption,
  InfluenceSelectOptionMobile,
  MeasurementUnit,
  ProUserStepper,
  UserStepper,
} from "../../utils/staticDataJson";
import clsx from "clsx";
import ColorPickerDialog from "../outcome/colorPickerDialog";
import ProgressBarStepper from "../outcome/progressBarStepper";
import IdeasDrawer from "./ideasDrawer";
import shape from "../../theme/shape";
import { influenceService } from "../../services/influence";
import { UserTypeContext } from "../../context/userStatus";
import {
  addCategoryJourneyAction,
  addInfluenceAction,
  addJourneyAction,
  setUserStatus,
} from "../../store/actions/userTypeAction";
import SnackBarComp from "../../components/snackBar";
import { ButtonLoader } from "../../components/buttonLoader";
import moment from "moment";
import { trackingService } from "../../services/tracking";
import AddIcon from "@mui/icons-material/Add";
import AddPopOver from "./AddPopOver";
import { commanColorPalette } from "../../utils/staticDataJson";
import { userService } from "../../services/auth";
import Donts from "../../assets/images/icon/Dots";
import Tooltip from "@mui/material/Tooltip";
import ModalCloseIcon from "../../assets/images/icon/modalCloseIconBlack";
import DeleteIcon from "../../assets/images/icon/DeleteIcon";
import Question from "../../assets/images/icon/Question";
import { useNavigate } from "react-router-dom";
import SwitchToOutcomeModal from "../tracker/switchToOutcomeModal";
import SwitchInInfluenceModal from "../tracker/switchInInfluenceModal";
import useWidth from "../../utils/useWidth";
import _debounce from "lodash/debounce";
import ViewNote from "../../assets/images/icon/ViewNote";
import Wellbeing from "../outcome/Wellbeing";
import StepperNextArrowDisable from "../../assets/images/icon/stepperNextArrowDisable";
import StepperNextArrow from "../../assets/images/icon/stepperNextArrow";
import StepperBackArrowDisable from "../../assets/images/icon/stepperBackArrowDisable";
import StepperBackArrow from "../../assets/images/icon/stepperBackArrow";
import IconPickerDialog from "../outcome/iconPickerDialog";
import ProgressBarStepperNew from "../outcome/progressBarStepperNew";
import { pxToRem } from "../../theme/typography";
import ArchiveModal from "../tracker/archiveConfirmationModal";
import RecommendedListNew from "./recommendedListNew";
import HTMLTooltip from "../../components/HTMLTooltip";
import ArchiveIcon from "../../assets/images/icon/ArchiveIcon";
import NewButton from "../../components/NewButton";
import Info from "../../assets/images/icon/Info";
import InfoIcon from "../../assets/images/icon/infoIconYellow";

const RootStyle = styled("div")(({ theme }) => ({
  padding: "32px",
  display: "flex",
  position: "relative",
  flexDirection: "column",
  alignItems: "flex-start",
}));

const useStyles = makeStyles((theme) => ({
  registrationHeading: {
    color: palette.primary.light,
  },
  drawerBody: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
  },
  headerTitle: {
    display: "flex",
    padding: "24px 32px",
    alignItems: "center",
    alignSelf: "stretch",
    borderBottom: "1px solid #F3F3F3",
    background: "#FFF",
    justifyContent: "space-between",
  },
  pageTitle: {
    marginTop: "40px!important",
    marginBottom: "48px!important",
    [theme.breakpoints.down("lg")]: {
      marginTop: "30px!important",
      marginBottom: "38px!important",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "20px!important",
      marginBottom: "28px!important",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "15px!important",
      marginBottom: "20px!important",
    },
  },
  needSomeIdeas: {
    fontSize: "16px",
    lineHeight: "16px",
    color: palette.primary.main,
    marginLeft: "8px",
    [theme.breakpoints.down("md")]: {
      marginTop: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "14px",
    },
  },
  selectWrapper: {
    marginTop: "49px",
    [theme.breakpoints.down("md")]: {
      marginTop: "30px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
    },
  },
  influenceCaption: {
    marginBottom: "20px!important",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "10px!important",
    },
  },
  colorBlock: {
    cursor: "pointer",
    backgroundColor: "transparent",
    border: "1px solid #DCE6E0",
    borderRadius: shape.borderRadius,
    padding: "11px 16px",
    display: "flex",
    alignItems: "center",
    marginLeft: "16px",
    justifyContent: "space-between",
    [theme.breakpoints.down("lg")]: {
      marginLeft: 0,
    },
  },
  spaceBetween: {
    [theme.breakpoints.down("lg")]: {
      marginTop: "10px",
      marginBottom: "10px",
    },
  },
  colorBlockText: {
    margin: 0,
    color: palette.primary.light,
    fontSize: "16px",
    lineHeight: "16px",
    fontWeight: 500,
  },
  colorCode: {
    width: "24px",
    height: "24px",
    marginLeft: "10px",
    borderRadius: shape.borderRadiusSm,
  },
  addButton: {
    display: "flex",
    padding: "12px 16px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "100px !important",
    background: palette.primary.main,
    marginBottom: "0 !important",
  },
  listItemClass: {
    "& .MuiButtonBase-root": {
      display: "flex !important",
      justifyContent: "flex-start !important",
      "@media  (max-width: 786px) and (min-width: 100px)": {
        height: "48px",
        opacity: "0px",
        display: "flex",
        alignItems: "center",
        padding: "8px",
        backgroundColor: `${palette.common.white} !important`
      },
    },
  },
  minButton: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    "&:after": {
      content: "''",
      position: "absolute",
      display: "flex",
      alignItems: "center",
      // left: "31px",
      // top: "39px",
      border: "1px solid #F3F3F3",
      width: "90%",
    },
  },
  widthFull: {
    width: "100%",
  },
  iconButton: {
    color: "#fff",
    zIndex: 10,
    height: "30px",
    width: "30px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  ideasListItem: {
    display: "flex",
    padding: "16px",
    alignItems: "center",
    gap: "8px",
    alignSelf: "stretch",
    borderRadius: "12px",
    border: "1px solid #EFEFEF",
    background: "#FFF",
    cursor: "pointer",

    // backgroundColor: palette.common.white,
    // borderRadius: shape.borderRadius,
    // padding: "24px",
    // fontSize: "16px",
    // lineHeight: "16px",
    // color: palette.primary.light,
    // fontWeight: 400,
    // marginBottom: "12px",
    // cursor: "pointer",
  },
  popoverTitleGroup: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    gap: "12px",
    alignSelf: "stretch",
    justifyContent: "space-between",
  },
  swicthButtons: {
    display: "flex",
    padding: "4px",
    alignItems: "center",
    borderRadius: "12px",
    background: "#F6F6F6",
    marginLeft: "auto",
  },
  swicthButtonsMobile: {
    display: "flex",
    padding: "4px",
    alignItems: "center",
    borderRadius: "12px",
    background: "#F6F6F6",
    marginLeft: "0px",
  },
  drawerWrapper: {
    padding: "60px 40px",
    [theme.breakpoints.down("lg")]: {
      padding: "50px 30px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "30px 20px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
    },
  },
  ideasList: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "12px",

    // marginTop: "40px",
    // [theme.breakpoints.down("lg")]: {
    //   marginTop: "30px",
    // },
    // [theme.breakpoints.down("md")]: {
    //   marginTop: "20px",
    // },
  },
  closeButton: {
    width: "20px",
    height: "20px",
    position: "absolute",
    right: "24px",
    top: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
  },
  iconRound: {
    display: "flex",
    // width: '44px',
    // height: '44px',
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "100px",
    background: "#FFF",
    "& path": {
      stroke: "var(--color-new-main)",
      strokeWidth: 2,
      //      fill: theme.palette.secondary[50],
    },
  },
  textarea: {
    width: "100%",
    padding: "20px",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    "&:focus-visible": {
      outline: "red",
    },
  },
}));

const ariaLabel = { "aria-label": "description" };

const InfluenceOptions = [
  {
    value: "1",
    label: "Numeric value based on unit of measurement",
  },
  {
    value: "2",
    label: "Scale based on values of 1 to 5",
  },
  {
    value: "3",
    label: "Simply based on a 'Yes' or 'No' basis",
  },
];

interface LeftSideInfluenceModal {
  setInfluenceData: (influenceData: any) => void;
  influenceData: any;
  influenceIdeas: any;
  influenceIdeasData?: any;
  showInfluence: boolean;
  setAddInfluenceTracking: (addInfluenceTracking: boolean) => void;
  setShowInfluence: (showInfluence: boolean) => void;
  setTextInput: any;
  textInput: any;
  handleCloseStartModal?: any;
  getInfluenceList?: any;
  categoryId?: any;
  setTransformToOutcomeTracking?: any;
  transformToOutcomeTracking?: any;
  setTransformInInfluenceTracking?: any;
  transformInInfluenceTracking?: any;
  width?: any;
  setShowSnakbar?: any;
  setSnakbarMsg?: any;
  setMsgType?: any;
  startDate?: any;
  endDate?: any;
  setNeedIdeasOpen: any;
  setInfluenceIdeas: any;
  setLimitReachPopupVisible: any;
  setLimitReachPopuptype: any;
  isMobile?: boolean;
}
const defaultValues = [
  {
    id: 1,
    colorCode: "#E57C73",
    text: "min",
    value: "",
    temp: true,
  },
  {
    id: 2,
    colorCode: "#ADE888",
    text: "max",
    value: "",
    temp: true,
  },
]
const LeftSideInfluenceNew = (props: LeftSideInfluenceModal) => {
  //AddPopOverComponent States
  const [value, setValue] = useState<string>("");
  const [desc, setDesc] = useState<string>("");
  const [color, setColor] = useState<string>("#E57C73");
  const [colorPalette, setColorPalette] = useState(commanColorPalette);
  const [textFieldValues, setTextFieldValues] = useState(defaultValues);
  const [matchValue, setMatchValue] = useState(false);

  const classes = useStyles();
  const [influenceInput, setInfluenceInput] = useState<any>();
  const [unitValue, setUnitValue] = useState("");
  const [unitValueOption, setUnitValueOption] = useState<any>();
  const [influenceOption, setInfluenceOption] = useState<any>("");
  const [flagValue, setFlagValue] = useState("");
  const [openColor, setOpenColor] = useState(false);
  const [selectedColor, setSelectedColor] = useState<any>(
    fixOutcomeColorPalette[0]
  );
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [IsInverse, setIsInverse] = useState("false");
  const [inverseCorrelation, setInverseCorrelation] = useState(false);
  const [editStatus, setEditStatus] = useState(null);
  // const [showSnakbar, setShowSnakbar] = useState(false);
  // const [snakbarMsg, setSnakbarMsg] = useState('');
  // const [msgType, setMsgType] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [nodeCount, setNodeCount] = useState(0);
  const [showIdeasMobile, setShowIdeasMobile] = useState(false);
  //cateScaler State
  const [cateScaler, setCateScaler] = useState("NumericValue");
  const [neutralScaler, setNeutralScaler] = useState("");
  const [openTooltip, setOpenTooltip] = useState(true)
  const [skipMetric, setSkipMetric] = useState(false);
  const [openPopupinEditMode, setOpenPopupinEditMode] = useState({
    status: false,
    nodeId: "",
  });
  const [absenceToggle, setAbsenceToggle] = useState(false);
  const [openRightSideSuggestion, setOpenRightSideSuggestion] = useState(false);
  const [editableNode, setEditableNode] = useState({});
  const [openSwitchOutcomeModal, setopenSwitchOutcomeModal] = useState(false);
  const [openSwitchInInfluenceModal, setopenSwitchInInfluenceModal] =
    useState(false);
  const [archiveConfirmationModal, setArchiveConfirmationModal] =
    useState(false);
  const userLevels = JSON.parse(localStorage.getItem("userValues"));
  const [activeStep, setActiveStep] = React.useState(false ? 2 : true ? 1 : 0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };
  const userType = userLevels?.level == "HIGH" ? ProUserStepper : UserStepper;

  const {
    state: outcomeState,
    state: IUserTypeState,
    dispatch: userTypeDispatch,
  } = useContext(UserTypeContext);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const [outcomeDesc, setOutcomeDesc] = useState("");

  const navigate = useNavigate();
  const [openWellbeing, setopenWellbeing] = useState(false);

  const compareDate = parseInt(moment().format("D"));
  const startDay = props.startDate
    ? moment(props.startDate)
    : moment().clone().subtract(10, "day");
  const endDay = props.endDate
    ? moment(props.endDate)
    : moment().clone().add(10, "day");
  const [suggestions, setSuggestions] = useState([]);

  const handleChangeWithLib = (value) => {
    let bodyData = {
      skip: 0,
      limit: 20,
      search: value,
    };
    trackingService
      .influenceSearch(outcomeState.token, bodyData)
      .then((res) => setSuggestions(res.data));
  };

  const debounceFn = useCallback(_debounce(handleChangeWithLib, 500), []);

  const NativeOutcomeStatus = [
    { text: "very low" },
    { text: "low" },
    { text: "medium" },
    { text: "high" },
    { text: "very high" },
  ];
  const NativeOutcomeStatusAbsense = [
    { text: "Absence" },
    { text: "very low" },
    { text: "low" },
    { text: "medium" },
    { text: "high" },
    { text: "very high" },
  ];
  // const data = {
  //   id: new Date(),
  //   value: parseInt(props.value),
  //   text: props.desc,
  //   colorCode: props.color,
  //   answerId: ""
  // }

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if (userLevels?.level == "HIGH") {
      if (activeStep != userType.length - 1) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    let nextStep = activeStep + 1;
    setSkipped(newSkipped);
    handleSliderNextValue(nextStep);
  };

  const handleSliderNextValue = (step: number) => {
    if (userLevels?.level == "HIGH") {
      if (step == 3) {
        userTypeDispatch(setUserStatus(true));
        navigate("/tracking", { replace: true });
      }
      if (step == 2) {
        navigate("/reflective-questions", { replace: true });
      } else if (step == 1) {
        navigate("/influence", { replace: true });
      }
    } else {
      if (step == 1) {
        navigate("/influence", { replace: true });
      }
      if (step == 2) {
        userTypeDispatch(setUserStatus(true));
        navigate("/tracking", { replace: true });
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    const backStep = activeStep - 1;
    handleSliderValue(backStep);
  };

  const handleSliderValue = (step: number) => {
    if (userLevels?.level == "HIGH") {
      if (step == 1) {
        navigate("/influence", { replace: true });
      } else if (step == 0) {
        navigate("/outcome", { replace: true });
      }
    } else {
      if (step === 0) {
        navigate("/outcome", { replace: true });
      }
    }
  };

  const handleCloseStartModal = () => {
    setShowIdeasMobile(false);
  };

  const handleChangeInfluence = (e: any) => {
    setInfluenceInput(e.target.value);
    debounceFn(e.target.value);
  };

  useEffect(() => {
    console.log("props?.influenceData", props?.influenceData);
    setOpenDrawer(false);
    setInfluenceInput(props?.influenceData?.name);
    setInfluenceOption(
      props?.influenceData?.type === "TYPE12"
        ? "TYPE1"
        : props?.influenceData?.type
    );
    // setNodeCount(0)
    setOutcomeDesc(props?.influenceData?.description);
    setUnitValueOption(props?.influenceData?.unitValue);
    setUnitValue(props?.influenceData?.unit);
    setSkipMetric(props?.influenceData?.skipMetric);
    setFlagValue(props?.influenceData?.flag);
    setIsInverse(props?.influenceData?.inverse);
    setInverseCorrelation(props?.influenceData?.inverseCorrelation);
    props?.setTextInput(
      props.influenceData?.options?.length > 0
        ? props.influenceData?.options
        : NativeOutcomeStatus
    );
    setSelectedColor(
      props.influenceData?.options?.length > 0
        ? props.influenceData?.options
        : fixOutcomeColorPalette[0]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.influenceData]);

  useEffect(() => {
    if (
      props?.influenceData?.options?.length !== 0 &&
      props.influenceData?.uid
    ) {
      const newOptionArray = props?.influenceData?.options?.map((option) => {
        return {
          id: new Date(),
          ...option,
        };
      });
      setTextFieldValues(newOptionArray);
      setNodeCount(props?.influenceData?.options?.length);
    } else {
      setTextFieldValues(defaultValues);
    }
    if (props.influenceData?.uid && props.influenceData?.type === "TYPE1") {
      setCateScaler("yes/no");
    } else if (
      props.influenceData?.uid &&
      props.influenceData?.type === "TYPE12"
    ) {
      if (props.influenceData?.metric_type === "NEUTRAL") {
        setNeutralScaler("NumericValue");
      } else {
        setCateScaler("NumericValue");
      }
    } else if (
      props.influenceData?.uid &&
      props.influenceData?.type === "TYPE2"
    ) {
      setAbsenceToggle(props.influenceData?.absenceToggle);
      setToogleSwitchAction(props.influenceData?.absenceToggle);
    }
    setSkipMetric(props?.influenceData?.skipMetric);
  }, [props.influenceData]);
  const setToogleSwitchAction = (checked) => {
    // if (checked) {
    //   props?.setTextInput(
    //     props.influenceData?.options?.length > 0
    //       ? props.influenceData?.options?.length > 5 ? [...props.influenceData?.options] : [{
    //         text: "Absence"
    //       }, ...props.influenceData?.options]
    //       : NativeOutcomeStatusAbsense
    //   );
    // } else {
    //   props?.setTextInput(
    //     props.influenceData?.options?.length > 0
    //       ? [...props.influenceData?.options]
    //       : NativeOutcomeStatus
    //   );
    // }
  };
  const [influenceSuggestionList, setInfluenceSuggestionList] = useState([]);

  const callService = async () => {
    try {
      const bodyData = {
        from: moment(startDay).format("MM-DD-YYYY"),
        to: moment(endDay).format("MM-DD-YYYY"),
      };
      if (!outcomeState?.isPersonal) {
        let data = await trackingService.getJourneyList(
          outcomeState.token,
          bodyData
        );
        userTypeDispatch(addJourneyAction(data));
      } else {
        let data = await trackingService.getPersonalJourneyList(
          outcomeState.token,
          bodyData
        );
        userTypeDispatch(addCategoryJourneyAction(data?.data));
      }
    } catch (error) {
      console.log("jourey list error");
    }
  };

  const handleClickOpenColor = () => {
    setOpenColor(true);
  };

  const handleCloseColor = (value: any) => {
    setOpenColor(false);
    if (value.id === -1) {
      return;
    }
    handleCloseColorNew();
    setSelectedColor(value);
  };
  // ADD INFLUENCE
  const AddInfluences = () => {
    setShowLoader(true);
    let influenceData;
    let influenceOptionata;

    if (influenceOption === "TYPE1") {
      // if (unitValue == undefined || unitValue == "") {
      //   setShowLoader(false);
      //   setShowSnakbar(true);
      //   setSnakbarMsg("Please enter unit value");
      //   setMsgType("error");
      //   return;
      // } else
      if (unitValueOption == undefined || unitValueOption == "") {
        setShowLoader(false);
        props?.setShowSnakbar(true);
        props?.setSnakbarMsg("Please select unit of measurement");
        props?.setMsgType("error");
        return;
      } else if (unitValueOption && unitValueOption == "SCALE_NOT_DEFINED") {
        setShowLoader(false);
        props?.setShowSnakbar(true);
        props?.setSnakbarMsg("Please select appropriate unit of measurement");
        props?.setMsgType("error");
        return;
      } else if (flagValue == undefined || flagValue == "") {
        setShowLoader(false);
        props?.setShowSnakbar(true);
        props?.setSnakbarMsg("Please select enter value");
        props?.setMsgType("error");
        return;
      }
      if (textFieldValues.length > 0) {
        influenceOptionata = textFieldValues.map((item) => {
          if (!textFieldValues[0].temp || !textFieldValues[1].temp) {
            delete item.id;
            return item;
          }
        });
      }
      if (neutralScaler === "NumericValue") {
        if (props.influenceData?.uid) {
          influenceData = {
            name: influenceInput,
            icon: "",
            type: "TYPE12",
            flag: flagValue,
            description: outcomeDesc,
            unitValue: unitValueOption,
            unit: null,
            options:
              flagValue === "no"
                ? textFieldValues[0].temp
                  ? []
                  : influenceOptionata
                : [],
            influenceId: props.influenceData?.influenceId,
            metric_type: "NEUTRAL",
            skipMetric: skipMetric,
          };
        } else {
          influenceData = {
            name: influenceInput,
            icon: "",
            type: "TYPE12",
            flag: flagValue,
            description: outcomeDesc,
            unitValue: unitValueOption,
            unit: null,
            options:
              flagValue === "no"
                ? textFieldValues[0].temp
                  ? []
                  : influenceOptionata
                : [],
            metric_type: "NEUTRAL",
            skipMetric: skipMetric,
          };
        }
      } else {
        if (props.influenceData?.uid) {
          influenceData = {
            name: influenceInput,
            description: outcomeDesc,
            icon: "",
            type: flagValue === "yes" ? "TYPE1" : "TYPE12",
            flag: flagValue,
            unitValue: unitValueOption,
            unit: null,
            options:
              flagValue === "no"
                ? textFieldValues[0].temp
                  ? []
                  : influenceOptionata
                : [],
            influenceId: props.influenceData?.influenceId,
            skipMetric: skipMetric,
          };
        } else {
          influenceData = {
            name: influenceInput,
            description: outcomeDesc,
            icon: "",
            type: flagValue === "yes" ? "TYPE1" : "TYPE12",
            flag: flagValue,
            unitValue: unitValueOption,
            unit: null,
            options:
              flagValue === "no"
                ? textFieldValues[0].temp
                  ? []
                  : influenceOptionata
                : [],
            skipMetric: skipMetric,
          };
        }
      }
    } else if (influenceOption === "TYPE2") {
      var result: any = selectedColor;
      if (props.influenceData?.uid && selectedColor.length > 0) {
        result = selectedColor?.map((color: any, index: number) => ({
          text: props?.textInput[index]?.text,
          colorCode: color.colorCode,
          answerId: color.answerId,
          score: color?.score,
        }));
      } else {
        result = selectedColor?.color?.map((color: any, index: number) => ({
          text: props?.textInput[index]?.text,
          colorCode: color,
          answerId:
            props?.influenceData &&
            props?.influenceData?.options[index]?.answerId,
          score: color?.score,
        }));
        if (result === undefined) {
          result = selectedColor?.map((color: any, index: number) => ({
            text: props?.textInput[index]?.text,
            colorCode: color.colorCode,
            answerId: color.answerId,
            score: color?.score,
          }));
        }
      }

      result = result.filter((it) => it);

      if (props.influenceData?.uid) {
        influenceData = {
          name: influenceInput,
          description: outcomeDesc,
          icon: "",
          type: "TYPE2",
          options: result,
          influenceId: props.influenceData?.influenceId,
          absenceToggle,
          skipMetric: skipMetric,
        };
      } else {
        influenceData = {
          name: influenceInput,
          description: outcomeDesc,
          icon: "",
          type: "TYPE2",
          options: result,
          absenceToggle,
          skipMetric: skipMetric,
        };
      }
    } else if (influenceOption == "TYPE3") {
      if (props.influenceData?.uid) {
        influenceData = {
          name: influenceInput,
          description: outcomeDesc,
          icon: "",
          type: "TYPE3",
          flag: flagValue,
          inverse: IsInverse,
          inverseCorrelation: [true, "true"].includes(IsInverse) ? inverseCorrelation : false,
          options: [],
          influenceId: props.influenceData?.influenceId,
          skipMetric: skipMetric,
        };
      } else {
        influenceData = {
          name: influenceInput,
          description: outcomeDesc,
          icon: "",
          type: "TYPE3",
          inverse: IsInverse,
          inverseCorrelation: [true, "true"].includes(IsInverse) ? inverseCorrelation : false,
          flag: flagValue,
          options: [],
          skipMetric: skipMetric,
        };
      }
    }
    if (props.influenceData?.uid) {
      // EDIT INFLUENCE
      influenceService
        .editInfluence(
          {
            ...influenceData,
          },
          outcomeState.token
        )
        .then((response: any) => {
          influenceService
            .getInfluenceList(outcomeState.token)
            .then((data: InfluenceGetResponse) => {
              if (IUserTypeState.isPersonal) {
                const journeyList = [...outcomeState?.journeyCategoryList];
                const foudnInJourney = journeyList.findIndex((it) =>
                  it.influenceIds.includes(props.influenceData?.influenceId)
                );
                if (foudnInJourney > -1) {
                  const foundInOutcomes = journeyList[
                    foudnInJourney
                  ].alls.findIndex(
                    (it) => it.influenceId === props.influenceData?.influenceId
                  );
                  if (foundInOutcomes > -1) {
                    journeyList[foudnInJourney].alls[foundInOutcomes] = {
                      ...journeyList[foudnInJourney].alls[foundInOutcomes],
                      ...influenceData,
                    };
                    if (influenceData.type === "TYPE3") {
                      if (
                        journeyList[foudnInJourney].alls[foundInOutcomes]
                          .influenceResponses &&
                        journeyList[foudnInJourney].alls[foundInOutcomes]
                          .influenceResponses.length
                      ) {
                        journeyList[foudnInJourney].alls[
                          foundInOutcomes
                        ].influenceResponses = journeyList[foudnInJourney].alls[
                          foundInOutcomes
                        ].influenceResponses.map((it) => {
                          return {
                            ...it,
                            flag: flagValue,
                            inverse: IsInverse,
                            inverseCorrelation: inverseCorrelation,
                          };
                        });
                        journeyList[foudnInJourney].alls[
                          foundInOutcomes
                        ].influenceResponses = journeyList[foudnInJourney].alls[
                          foundInOutcomes
                        ].influenceResponses.map((it) => {
                          const newIt = { ...it };
                          delete newIt.colorCode;
                          return newIt;
                        });
                      }
                    }

                    userTypeDispatch(addCategoryJourneyAction(journeyList));
                  }
                } else {
                  const foudnOtherJourney = journeyList.findIndex(
                    (it) => it.name === "Other"
                  );
                  if (foudnOtherJourney > -1) {
                    const foundInOutcomes = journeyList[
                      foudnOtherJourney
                    ].influences.findIndex(
                      (it) =>
                        it.influenceId === props.influenceData?.influenceId
                    );
                    if (foundInOutcomes > -1) {
                      journeyList[foudnOtherJourney].influences[
                        foundInOutcomes
                      ] = {
                        ...journeyList[foudnOtherJourney].influences[
                        foundInOutcomes
                        ],
                        ...influenceData,
                      };
                      if (influenceData.type === "TYPE3") {
                        if (
                          journeyList[foudnOtherJourney].influences[
                            foundInOutcomes
                          ].influenceResponses &&
                          journeyList[foudnOtherJourney].influences[
                            foundInOutcomes
                          ].influenceResponses.length
                        ) {
                          journeyList[foudnOtherJourney].influences[
                            foundInOutcomes
                          ].influenceResponses = journeyList[
                            foudnOtherJourney
                          ].influences[foundInOutcomes].influenceResponses.map(
                            (it) => {
                              return {
                                ...it,
                                flag: flagValue,
                                inverse: IsInverse,
                                inverseCorrelation: inverseCorrelation,
                              };
                            }
                          );
                          journeyList[foudnOtherJourney].influences[
                            foundInOutcomes
                          ].influenceResponses = journeyList[
                            foudnOtherJourney
                          ].influences[foundInOutcomes].influenceResponses.map(
                            (it) => {
                              const newIt = { ...it };
                              delete newIt.colorCode;
                              return newIt;
                            }
                          );
                        }
                      }

                      userTypeDispatch(addCategoryJourneyAction(journeyList));
                    }
                  }
                }
              } else {
                const allOutcomes = [...outcomeState?.influenceList];
                const foundOutcomeIndex = allOutcomes.findIndex(
                  (it) => it.influenceId === props.influenceData?.influenceId
                );
                if (foundOutcomeIndex > -1) {
                  allOutcomes[foundOutcomeIndex] = {
                    ...allOutcomes[foundOutcomeIndex],
                    ...influenceData,
                  };
                  userTypeDispatch(addInfluenceAction(allOutcomes));
                }
                console.log("influenceData", influenceData);
                if (influenceData.type === "TYPE3") {
                  console.log(
                    "outcomeState?.journeyList",
                    outcomeState?.journeyList
                  );
                  const newJourneyList = { ...outcomeState?.journeyList };
                  newJourneyList.influence_data =
                    newJourneyList.influence_data.map((it: any) => {
                      if (props.influenceData?.influenceId === it.influenceId) {
                        return {
                          ...it,
                          flag: flagValue,
                          inverse: IsInverse,
                          inverseCorrelation: inverseCorrelation
                        };
                      }
                      return it;
                    });
                  newJourneyList.influence_data =
                    newJourneyList.influence_data.map((it: any) => {
                      const newIt = { ...it };
                      delete newIt.colorCode;
                      return newIt;
                    });
                  console.log("newJourneyList", newJourneyList);
                  userTypeDispatch(addJourneyAction(newJourneyList));
                }
              }
              setNodeCount(0);
              setTextFieldValues(defaultValues);
              props.setShowInfluence(false);
              setInfluenceInput("");
              setInfluenceOption("");
              setUnitValueOption("");
              setOutcomeDesc("");
              setUnitValue("");
              setFlagValue("");
              if (props?.isMobile) {
                props?.handleCloseStartModal();
              }
              props.setInfluenceData("");
              props?.setTextInput(NativeOutcomeStatus);
              setShowLoader(false);
              props?.setShowSnakbar(false);
              props?.setSnakbarMsg(response.message);
              props?.setMsgType("success");
            });
        })
        .catch((error) => {
          setShowLoader(false);
          props?.setShowSnakbar(true);
          props?.setSnakbarMsg(
            error?.response?.data?.description
              ? error?.response?.data?.description
              : error.message
          );
          props?.setMsgType("error");
        });
    } else {
      // CREATE INFLUENCE
      influenceService
        .createInfluence(
          {
            ...influenceData,
            categoryId: props?.categoryId,
            mixpanel_type: "INSIDE_TRACKING",
          },
          outcomeState.token
        )
        .then((response: any) => {
          outcomeState.influenceList.push(response?.data);
          const bodyData = {
            from: moment(startDay).format("MM-DD-YYYY"),
            to: moment(endDay).format("MM-DD-YYYY"),
          };
          trackingService
            .getPersonalJourneyList(outcomeState.token, bodyData)
            .then((data) => {
              userTypeDispatch(addCategoryJourneyAction(data?.data));
              userTypeDispatch(addInfluenceAction(outcomeState.influenceList));
              let days = 0;
              const createDate = outcomeState.userDetails.createdAt;
              const diff = moment().diff(moment(createDate), "days");
              if (diff !== 0 && diff < 30) {
                days = 30 - diff;
              } else if (diff === 0) {
                days = 30 - diff;
              }
              if (
                days > 0 &&
                outcomeState?.influenceList &&
                outcomeState?.influenceList?.length >= 10 &&
                outcomeState.userDetails.level === "LOW"
              ) {
                props?.setLimitReachPopupVisible(true);
                props?.setLimitReachPopuptype("influence");
              }
              setShowLoader(false);
              props?.setShowSnakbar(true);
              props?.setSnakbarMsg(response.message);
              props?.setMsgType("success");
              setNodeCount(0);
              setTextFieldValues(defaultValues);
              // props.setAddInfluenceTracking(false);
              setInfluenceInput("");
              setInfluenceOption("");
              setOutcomeDesc("");
              setUnitValueOption("");
              setUnitValue("");
              setFlagValue("");
              props.setInfluenceData("");
              props?.setTextInput(NativeOutcomeStatus);
            });
          // setTimeout(() => {

          // }, 1000);
        })
        .catch((error) => {
          setShowLoader(false);
          props?.setShowSnakbar(true);
          props?.setSnakbarMsg(
            error?.response?.data?.description
              ? error?.response?.data?.description
              : error.message
          );
          props?.setMsgType("error");
        });
    }
  };
  const showDrawer = () => {
    setOpenDrawer(true);
  };
  const handleCloseSnakbar = () => {
    props?.setShowSnakbar(false);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    if (openPopupinEditMode?.status) {
      setOpenPopupinEditMode({
        status: false,
        nodeId: "",
      });
      setValue("");
      setDesc("");
      setColor("#E57C73");
      setEditableNode({});
    }
  };

  const openPopoverInEditMode = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const editCustomeScaleValue = (event, uniqeId) => {
    if (uniqeId !== 1 && uniqeId !== 2) {
      openPopoverInEditMode(event);
      setOpenPopupinEditMode({
        ...openPopupinEditMode,
        status: true,
        nodeId: uniqeId,
      });

      let editableNode = textFieldValues?.filter((node) => {
        return node?.id === uniqeId;
      });

      if (editableNode?.length > 0) {
        setValue(editableNode[0]?.value);
        setDesc(editableNode[0]?.text);
        setColor(editableNode[0]?.colorCode);
        setEditableNode(editableNode[0]);
      }
    }
  };
  useEffect(() => {
    return () => setOpenTooltip(false)
  }, [])
  const deleteCustomeScaleValue = (uniqeId) => {
    let updatedScaleNodeArray = textFieldValues?.filter((node) => {
      return node?.id !== uniqeId;
    });
    if (
      textFieldValues[0].temp === undefined ||
      textFieldValues[1].temp === undefined
    ) {
      setTextFieldValues(updatedScaleNodeArray);
    }

    if (
      textFieldValues?.length === 2 &&
      textFieldValues[0].temp === undefined &&
      textFieldValues[1].temp
    ) {
      setNodeCount(0);
      setTextFieldValues(defaultValues);
    } else if (
      (textFieldValues?.length === 2 &&
        textFieldValues[0].temp === undefined) ||
      textFieldValues[1].temp
    ) {
      setNodeCount(1);
      let updatedScaleNodeArray = textFieldValues?.filter((node) => {
        return node?.id !== uniqeId;
      });
      updatedScaleNodeArray.push({
        id: 2,
        colorCode: "#169C63",
        text: "max",
        value: "",
        temp: true,
      });
      setTextFieldValues(updatedScaleNodeArray);
      // setTextFieldValues([
      //   ...textFieldValues,
      //   {
      //     id: 2,
      //     colorCode: "#169C63",
      //     text: "max",
      //     value: "",
      //     temp: true
      //   }
      // ])
    }
  };

  const ITEM_HEIGHT = 48;
  const [anchorElTwo, setAnchorElTwo] = React.useState<null | HTMLElement>(
    null
  );
  const openMenu = Boolean(anchorElTwo);
  const handleClickAnchorElTwo = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElTwo(event.currentTarget);
  };
  const handleCloseAnchorElTwo = () => {
    setAnchorElTwo(null);
  };

  const width = useWidth();

  const onArchiveInfuance = () => {
    const token = localStorage.getItem("userDetails");
    userService
      ?.archiveInfluanceData(
        {
          archive: true,
          influenceId: props?.influenceData?.influenceId,
        },
        token
      )
      .then((res) => {
        props?.setShowSnakbar(true);
        props?.setSnakbarMsg(res.message);
        props?.setMsgType("success");
        // setShowOutcome(false);
        // setAddOutcomeTracking(true);
        let timerId: number = window.setTimeout(() => {
          props.handleCloseStartModal();
          props.getInfluenceList();
        }, 1000);
      })
      .catch((error) => {
        props?.setShowSnakbar(true);
        props?.setSnakbarMsg(
          error?.response?.data?.description
            ? error?.response?.data?.description
            : error.message
        );
        props?.setMsgType("error");
      });
  };
  const [openSwitchInfluenceModal, setopenSwitchInfluenceModal] =
    useState(false);
  const [viewIconPopoverMenu, setVisibleIconPopoverMenu] = useState(false);
  const spanRefMenu = React.useRef();
  const [anchorElMenu, setAnchorElMenu] = useState(spanRefMenu.current);
  const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElMenu(spanRefMenu.current);
    setVisibleIconPopoverMenu(true);
  };
  const handleCloseMenu = () => {
    setAnchorElMenu(null);
    setVisibleIconPopoverMenu(false);
  };
  const [viewPopover, setVisiblePopover] = useState(false);
  const spanRef = React.useRef();
  const [anchorElColor, setAnchorElColor] = useState(spanRef.current);
  const handleClickColor = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElColor(spanRef.current);
    setVisiblePopover(true);
  };
  const handleCloseColorNew = () => {
    setVisiblePopover(false);
    setAnchorElColor(null);
  };
  // const [anchorEl, setAnchorEl] = useState(spanRef.current);
  // const handleClick = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorEl(spanRef.current)
  //   setVisiblePopover(true);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  //   setVisiblePopover(false);

  // };
  const renderButton = (isLeft, title, active, onClick) => (
    <Button
      /* variant={!active ? "outlined" : "contained"}  */ sx={{
        display: "flex !important",
        padding: "8px !important",
        alignItems: "center !important",
        marginBottom: "0 !important",
        boxShadow: active ? "0px 0px 72px 0px rgba(25, 13, 26, 0.12)" : "none",
        borderRadius: "8px !important",
        background: active ? "#FFF !important" : "transparent",
      }}
      onClick={onClick}
    >
      <Typography
        variant="caption"
        sx={{
          color: !active ? palette.common.black : "var(--color-new-text)",
          fontWeight: 400,
          fontSize: "12px !important",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          lineHeight: "100% !important" /* 12px */,
          letterSpacing: "-0.12px",
          whiteSpace: "nowrap",
        }}
      >
        {title}
      </Typography>
    </Button>
  );
  const toolTipText =
    "This is a metric you want to observe without setting any goals for it, and whatever you input when tracking is neither good nor bad.";
  const type1Render = () => {
    let sortingArr = [...colorPalette].map((it) => it.color);
    const newtextFieldValues = [...textFieldValues].sort(
      (a, b) =>
        sortingArr.indexOf(a.colorCode) - sortingArr.indexOf(b.colorCode)
    );
    let measurementUnit = [...MeasurementUnit]
    console.log("props?.influenceData =======", props?.influenceData);
    if (props?.influenceData && props?.influenceData?.unitValue && props?.influenceData?.unitValue === "SCALE_NOT_DEFINED") {
      measurementUnit = [{ value: "define unit", id: "SCALE_NOT_DEFINED" }, ...MeasurementUnit]
    }
    return (
      <Grid
        display="flex"
        alignItems="center"
        gap="12px"
        alignSelf="stretch"
        sx={
          props?.isMobile
            ? {
              flexWrap: "wrap",
            }
            : {}
        }
      >
        <div
          style={{
            display: "flex",
            paddingRight: "12px",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "10px",
            borderRight: "1px solid #F3F3F3",
            width: "100%",
            flexBasis: props?.isMobile ? "100%" : "25%",
          }}
        >
          <TextField
            sx={{
              display: "flex",
              padding: "16px 20px",
              alignItems: "center",
              gap: "8px",
              borderRadius: "12px",
              border: "1px solid #EDEDED",
              background: "#FFF",
              marginBottom: "0 !important",
              width: "100%",
              "& .MuiOutlinedInput-root": {
                fontSize: `${pxToRem(16)} !important`,
                lineHeight: "100% !important",
                padding: "0",
                "& .MuiSelect-outlined": {
                  padding: "0 !important",
                },
              },
            }}
            className=" unitSelect"
            fullWidth
            select
            placeholder="Select unit of measurement"
            // label="Unit of measurement"
            value={unitValueOption || ""}
            onChange={(e) => {
              setUnitValueOption(e.target.value);
              if (e.target.value === "timeofday") {
                setNeutralScaler("NumericValue");
              } else {
                setNeutralScaler("");
              }
              if (cateScaler === "yes/no") {
                setFlagValue("yes");
              } else {
                setFlagValue("no");
              }
            }}
          >
            {measurementUnit?.map((option: any) => (
              <MenuItem key={option.id} value={option.id} disabled={option.id === "SCALE_NOT_DEFINED"}>
                {option.value}
              </MenuItem>
            ))}
          </TextField>
        </div>
        {cateScaler === "NumericValue" && neutralScaler !== "NumericValue" && (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                gap: "10px",
                flex: "1 0 0",
                width: "100%",
                flexBasis: props?.isMobile ? "100%" : "60%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "10px",
                  flex: "1 0 0",
                  width: "100%",
                  flexBasis: "60%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    height: "52px",
                    alignItems: "flex-end",
                    gap: "8px",
                    alignSelf: "stretch",
                  }}
                >
                  <Grid
                    item
                    xs={16}
                    sx={{
                      display: "flex",
                      padding: "12px",
                      alignItems: "center",
                      gap: "12px",
                      flex: "1 0 0",
                      borderRadius: "12px",
                      border: "1px solid #EDEDED",
                      background: "#FFF",
                    }}
                    className={classes.minButton}
                    justifyContent="space-between"
                  >
                    {newtextFieldValues?.map((value, index) => {
                      return (
                        <>
                          <Grid
                            key={index}
                            item
                            xs={7}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              width: "auto",
                              flex: "0 0 auto !important",
                              justifyContent: "center",
                            }}
                          >
                            <div
                              className={classes.iconButton}
                              onClick={(event) =>
                                editCustomeScaleValue(event, value?.id)
                              }
                              style={{
                                backgroundColor: newtextFieldValues[index]
                                  ?.colorCode
                                  ? newtextFieldValues[index]?.colorCode
                                  : "#F17F79",
                              }}
                            >
                              {newtextFieldValues[index]?.value}
                            </div>
                          </Grid>
                        </>
                      );
                    })}
                  </Grid>
                </div>
              </div>
              <NewButton
                variant="outlined"
                onClick={handleClick}
                padding="15px 16px"
                buttonText="Add more"
                borderRadius="10px"
                textColor="var(--color-new-text)"
                borderColor="var(--color-new-text)"
                borderPixel="1px"
              />
            </div>
          </>
        )}

        <AddPopOver
          nodeCount={nodeCount}
          setNodeCount={setNodeCount}
          editableNode={editableNode}
          open={open}
          anchorEl={anchorEl}
          value={value}
          setValue={setValue}
          desc={desc}
          setDesc={setDesc}
          color={color}
          setColor={setColor}
          colorPalette={colorPalette}
          setColorPalette={setColorPalette}
          textFieldValues={textFieldValues}
          setTextFieldValues={setTextFieldValues}
          handleClose={handleClose}
          openPopupinEditMode={openPopupinEditMode}
          deleteCustomeScaleValue={deleteCustomeScaleValue}
          setMatchValue={setMatchValue}
          matchValue={matchValue}
        />
      </Grid>
    );
  };
  const type2Render = () => (
    <>
      <ProgressBarStepperNew
        selectedColor={selectedColor}
        setTextInput={props?.setTextInput}
        textInput={props?.textInput}
        setShowInput={setShowInput}
        setEditStatus={setEditStatus}
        showInput={showInput}
        editStatus={editStatus}
        width={width}
        absenceToggle={absenceToggle}
      />
    </>
  );
  const type3Render = () => <div></div>;
  const renderIcon = (icon, onClick = () => { }) => (
    <div className={classes.iconRound} onClick={onClick}>
      {icon}
    </div>
  );
  return (
    <Grid
      container
      columns={16}
      sx={{ backgroundColor: palette.secondary.light }}
    >
      <Grid
        sx={{ backgroundColor: palette.common.white }}
        item
        xs={16}
        className="leftside-menu  outcome-screen "
      >
        {!props?.isMobile && (
          <div className={classes.drawerBody}>
            <div className={classes.headerTitle}>
              <Typography
                sx={{
                  color: "#121213",
                  fontSize: "28px!important",
                  fontWeight: 700,
                }}
              >
                {props.influenceData?.uid
                  ? "Edit influence"
                  : "Add new influence"}
              </Typography>
              {!openRightSideSuggestion && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "6px",
                    cursor: "pointer",
                  }}
                >
                  {props.influenceData?.uid ? null : (
                    <div
                      style={{ display: "flex", alignItems: "center" }}
                      onClick={() => {
                        setOpenRightSideSuggestion(true);
                        props.setNeedIdeasOpen && props.setNeedIdeasOpen(true);
                      }}
                    >
                      <GetIdeasIcon />
                      <Typography
                        sx={{
                          color: "var(--color-new-text)",
                          fontSize: "16px!important",
                          fontWeight: 500,
                        }}
                      >
                        Need some ideas?
                      </Typography>
                    </div>
                  )}

                  {props.influenceData?.uid && (
                    <div>
                      <IconButton
                        // aria-label="more"
                        // id="long-button"
                        // aria-controls={open ? "long-menu" : undefined}
                        // aria-expanded={open ? "true" : undefined}
                        // aria-haspopup="true"
                        // onClick={handleClick}
                        onClick={() => {
                          setopenWellbeing(true);
                        }}
                      >
                        <ViewNote />
                      </IconButton>
                      <IconButton
                        onClick={() => setArchiveConfirmationModal(true)}
                      >
                        <HTMLTooltip
                          customIcon={renderIcon(<ArchiveIcon />, () => { })}
                          title="Archive"
                          fontProps={{
                            fontSize: `${pxToRem(12)} !important`,
                          }}
                          sx={{
                            "& .MuiTooltip-tooltip": {
                              maxWidth: "300px",
                            },
                          }}
                        />
                      </IconButton>
                      <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-controls={
                          viewIconPopoverMenu ? "long-menu" : undefined
                        }
                        aria-expanded={viewIconPopoverMenu ? "true" : undefined}
                        aria-haspopup="true"
                        ref={spanRefMenu}
                        onClick={handleClickMenu}
                      >
                        <Donts />
                      </IconButton>
                      <Menu
                        id="long-menu"
                        MenuListProps={{
                          "aria-labelledby": "long-button",
                        }}
                        anchorEl={anchorElMenu}
                        open={viewIconPopoverMenu}
                        onClose={handleCloseMenu}
                        PaperProps={{
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: "20ch",
                          },
                        }}
                      >
                        <MenuItem
                          onClick={() => setopenSwitchInInfluenceModal(true)}
                        >
                          Change scale type
                        </MenuItem>
                        <MenuItem
                          onClick={() => setopenSwitchOutcomeModal(true)}
                        >
                          Transform to outcome
                        </MenuItem>
                        <MenuItem
                          onClick={() =>
                            navigate(
                              `/version?id=${props?.influenceData?.outcomeParentId ??
                              props?.influenceData?.influenceParentId
                              }`
                            )
                          }
                        >
                          Version history
                        </MenuItem>
                      </Menu>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
        <RootStyle
          style={{
            width: `${openRightSideSuggestion ? "calc(100% - 280px)" : "100%"}`,
          }}
        >
          <Grid
            display={"flex"}
            flexDirection={"column"}
            gap={"16px"}
            paddingBottom={props?.isMobile ? "0px" : `24px`}
            alignItems={"flex-start"}
            width={"100%"}
            sx={{
              borderBottom: props?.isMobile ? "none" :
                influenceOption === "TYPE2" ? "none" : "1px solid #F3F3F3",
              // position: "relative"
            }}
          >
            <Typography
              variant="caption"
              component="div"
              sx={{
                color: palette.common.black,
                fontSize: "16px!important",
                fontWight: 500,
                lineHeight: "100%",
                width: "100%",
                "&.MuiTypography-root": {
                  "@media  (max-width: 786px) and (min-width: 100px)": {
                    fontSize: `${pxToRem(16)} !important`,
                    lineHeight: "135% !important",
                  },
                },
              }}
            >
              Describe the influence you want to track
            </Typography>
            {props?.isMobile && props.influenceData?.uid ? (
              <IconButton
                style={{
                  position: "absolute",
                  top: "8px",
                  right: "30px",
                  padding: "0px",
                }}
                onClick={() => setArchiveConfirmationModal(true)}
              >
                <HTMLTooltip
                  customIcon={renderIcon(<ArchiveIcon />, () => { })}
                  title="Archive"
                  fontProps={{
                    fontSize: `${pxToRem(12)} !important`,
                  }}
                  sx={{
                    "& .MuiTooltip-tooltip": {
                      maxWidth: "300px",
                    },
                  }}
                />
              </IconButton>
            ) : null}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
                width: "100%",
                paddingBottom: "8px",
              }}
            >
              <TextField
                sx={{
                  "& .MuiTextField-root": {
                    marginBottom: "24px !important",
                  },
                  "& .MuiOutlinedInput-root": {
                    alignItems: "center",
                    gap: "8px",
                    borderRadius: "12px",
                    border: "1px solid #EDEDED",
                    background: "#FFF",
                    "& .MuiOutlinedInput-input": props?.isMobile ? {
                      color: "#000!important",
                      border: "1px solid var(--color-svg)",
                      borderRadius: "8px",
                      "&::placeholder": {
                        fontSize: `${pxToRem(14)}`,
                        // color: "#B7BDB9"
                      }
                    } : {
                      color: "#000!important",
                    },
                  },
                }}
                placeholder={props?.isMobile ? "The Influence you care about" : "Add an influence you want to track"}
                variant="outlined"
                name="influence"
                fullWidth
                value={influenceInput || ""}
                onChange={(e) => handleChangeInfluence(e)}
                inputProps={ariaLabel}
              />
              <div
                style={{
                  backgroundColor: props?.isMobile ? palette.common.white : "aliceblue",
                  borderRadius: props?.isMobile && influenceInput?.length !== 0 && suggestions && suggestions.length > 0 ? "0px 0px 8px 8px" : "8px",
                  border: props?.isMobile && influenceInput?.length !== 0 && suggestions && suggestions.length > 0 && "1px solid #DCE5E0",
                  fontSize: "16px",
                  maxHeight: "120px",
                  overflow: "auto",
                  position: "absolute",
                  width: "100%",
                  top: "55px",
                  zIndex: 10,
                  color: "black",
                }}
              >
                {influenceInput?.length !== 0 &&
                  suggestions?.map((suggestion) => {
                    let name = suggestion.name
                    if (props?.isMobile && influenceInput?.length > 0 && name.includes(influenceInput)) {
                      name = name.replace(influenceInput, `<span class="suggested-text">${influenceInput}</span>`)
                    }
                    if (props?.isMobile && influenceInput?.length > 0 && name.toLowerCase().includes(influenceInput.toLowerCase())) {
                      name = name.replace(influenceInput.toLowerCase(), `<span class="suggested-text">${influenceInput.toLowerCase()}</span>`)
                    }
                    delete suggestion._id
                    return (
                      <div
                        style={props?.isMobile ? {
                          height: "48px",
                          opacity: "0px",
                          display: "flex",
                          alignItems: "center",
                          padding: "8px",
                          border: "1px solid #F3F3F3",
                        } : { padding: "8px", cursor: "pointer" }}
                        onClick={() => {
                          props.setInfluenceData(suggestion);
                          setSuggestions([]);
                          // setInfluenceOption(suggestion?>)
                        }}
                      >
                        <div dangerouslySetInnerHTML={{
                          __html: name
                        }} />
                      </div>
                    );
                  })}
              </div>
            </div>
          </Grid>

          {/* SCALE OPTION */}
          {influenceOption === "TYPE2" && (
            <Grid
              display={"flex"}
              flexDirection={"column"}
              gap={"16px"}
              paddingBottom={0}
              alignItems={"flex-start"}
              width={"100%"}
              sx={{
                borderBottom: "1px solid #F3F3F3",
              }}
            >
              <div
                className={clsx(
                  classes.colorBlock,
                  classes.spaceBetween,
                  classes.widthFull
                )}
                style={props?.isMobile ? {
                  border: "1px solid var(--color-svg)",
                } : {}}
                onClick={handleClickColor}
              >
                <p className={classes.colorBlockText}>{props.isMobile ? "Define color pallet" : "Set colour pallet"}</p>

                <span
                  className={classes.colorCode}
                  ref={spanRef}
                  style={{
                    backgroundColor:
                      props.influenceData?.uid && selectedColor.length === 0
                        ? props.influenceData?.options[0]?.colorCode
                        : selectedColor.length > 0
                          ? selectedColor[0]?.colorCode
                          : selectedColor?.color[0],
                  }}
                ></span>
              </div>
            </Grid>
          )}
          {/* CHOOSE COLOR PALETTE */}
          {/* <ColorPickerDialog
              openColor={openColor}
              onCloseColor={handleCloseColor}
            /> */}

          <Popover
            id={"color-pallete"}
            open={viewPopover}
            anchorEl={anchorElColor}
            onClose={handleCloseColorNew}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            sx={{
              "& .MuiPaper-root": {
                display: "flex",
                alignItems: "flex-start",
                gap: "12px",
                flexDirection: "column",
                padding: "20px",
                borderRadius: "12px",
                border: "1px solid #F3F3F3",
              },
            }}
          >
            <div className={classes.popoverTitleGroup}>
              <Typography
                sx={{
                  fontSize: "0.875rem !important",
                  lineHeight: "100%",
                  color: palette.common.black,
                  fontWeight: 500,
                }}
              >
                Select colour pallet
              </Typography>
              <div onClick={handleCloseColorNew} style={{ cursor: "pointer" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M12 12L8.00001 8.00001M8.00001 8.00001L4 4M8.00001 8.00001L12 4M8.00001 8.00001L4 12"
                    stroke="black"
                    strokeWidth="1.33333"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>
            <List
              sx={{
                pt: 0,
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "12px",
              }}
            >
              {fixOutcomeColorPalette.map(
                (palette: OutComeColorModal, index: number) => (
                  <ListItem
                    button
                    sx={{
                      display: "flex",
                      padding: "16px 20px",
                      alignItems: "flex-start",
                      gap: "12px",
                      borderRadius: "12px",
                      border:
                        palette === selectedColor
                          ? "1px solid #11734A"
                          : "1px solid #F3F3F3",
                      background: "#FFF",
                    }}
                    onClick={() => handleCloseColor(palette)}
                    key={index}
                  >
                    {/* <ListItemText
                           primary={palette.id}
                           style={{ marginRight: "0" }}
                         /> */}

                    {palette.color.map((pal) => {
                      return (
                        <div
                          style={{
                            background: pal,
                            width: "32px",
                            height: "32px",
                            borderRadius: "12px",
                          }}
                          key={`${pal}_${palette.id}`}
                        ></div>
                      );
                    })}
                  </ListItem>
                )
              )}
            </List>
          </Popover>
          <Grid
            display={"flex"}
            flexDirection={"column"}
            gap={"16px"}
            paddingBottom={0}
            alignItems={"flex-start"}
            width={"100%"}
            sx={{
              borderBottom: "1px solid #F3F3F3",
              width: "100%",
              padding: "24px 0",
            }}
          >
            <Typography
              variant="caption"
              component="div"
              sx={{
                color: palette.common.black,
                fontSize: "16px!important",
                fontWight: 500,
                lineHeight: "100%",
                "&.MuiTypography-root": {
                  "@media  (max-width: 786px) and (min-width: 100px)": {
                    fontSize: `${pxToRem(16)} !important`,
                    lineHeight: "135% !important",
                  },
                },
              }}
            >
              How do you want to measure it?
            </Typography>

            {props.influenceData?.uid ? (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "12px",
                }}
              >
                {InfluenceSelectOption?.map((option: InfluenceOption) => {
                  return (
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        paddingBottom: "0px",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: "12px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          padding: "16px 20px",
                          alignItems: "center",
                          gap: "12px",
                          alignSelf: "stretch",
                          borderRadius: "12px",
                          border: `1px solid ${influenceOption === option.id
                            ? "var(--color-new-text)"
                            : "#EDEDED"
                            } `,
                          background: "#FFF",
                          cursor: "not-allowed",
                        }}
                      >
                        {influenceOption === option.id ? (
                          <span
                            style={{
                              width: "20px",
                              height: "20px",
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                            >
                              <rect
                                x="0.5"
                                y="0.5"
                                width="19"
                                height="19"
                                rx="9.5"
                                fill="white"
                                stroke="var(--color-new-text)"
                              />
                              <rect
                                x="4"
                                y="4"
                                width="12"
                                height="12"
                                rx="6"
                                fill="var(--color-new-text)"
                              />
                            </svg>
                          </span>
                        ) : (
                          <span
                            style={{
                              width: "20px",
                              height: "20px",
                              borderRadius: "100px",
                              border: "1px solid #A0A0A1",
                              background: "#FFF",
                            }}
                          ></span>
                        )}

                        <Typography
                          variant="caption"
                          component="span"
                          sx={{
                            color:
                              influenceOption === option.id
                                ? "var(--color-new-text)"
                                : palette.common.disabled,
                            fontSize: "16px!important",
                            fontWight: 500,
                            lineHeight: "100%",
                            display: "",
                          }}
                        >
                          {option.value}
                        </Typography>
                        {influenceOption === option.id &&
                          influenceOption === "TYPE1" &&
                          !props?.isMobile && (
                            <div className={classes.swicthButtons}>
                              {renderButton(
                                true,
                                "Evaluative metric",
                                neutralScaler !== "NumericValue",
                                (e) => {
                                  setNeutralScaler("");
                                  if (unitValueOption === "timeofday") {
                                    setNeutralScaler("NumericValue");
                                    props?.setShowSnakbar(true);
                                    props?.setSnakbarMsg(
                                      "You can not unChecked when unit is time Of day"
                                    );
                                    props?.setMsgType("error");
                                  }
                                  setFlagValue("no");
                                }
                              )}
                              {renderButton(
                                false,
                                "Neutral metric",
                                neutralScaler === "NumericValue",
                                (e) => {
                                  setNeutralScaler("NumericValue");
                                  if (unitValueOption === "timeofday") {
                                    setNeutralScaler("NumericValue");
                                    props?.setShowSnakbar(true);
                                    props?.setSnakbarMsg(
                                      "You can not unChecked when unit is time Of day"
                                    );
                                    props?.setMsgType("error");
                                  }
                                  setFlagValue("no");
                                }
                              )}
                            </div>
                          )}
                      </div>
                      {influenceOption === option.id &&
                        influenceOption === "TYPE1" &&
                        props?.isMobile && (
                          <div
                            className={classes.swicthButtonsMobile}
                            style={{
                              // marginRight: "40px",
                              display: "flex",
                            }}
                          >
                            {renderButton(
                              true,
                              "Evaluative metric",
                              neutralScaler !== "NumericValue",
                              (e) => {
                                setNeutralScaler("");
                                if (unitValueOption === "timeofday") {
                                  setNeutralScaler("NumericValue");
                                  props?.setShowSnakbar(true);
                                  props?.setSnakbarMsg(
                                    "You can not unChecked when unit is time Of day"
                                  );
                                  props?.setMsgType("error");
                                }
                                setFlagValue("no");
                              }
                            )}
                            {renderButton(
                              false,
                              "Neutral metric",
                              neutralScaler === "NumericValue",
                              (e) => {
                                setNeutralScaler("NumericValue");
                                if (unitValueOption === "timeofday") {
                                  setNeutralScaler("NumericValue");
                                  props?.setShowSnakbar(true);
                                  props?.setSnakbarMsg(
                                    "You can not unChecked when unit is time Of day"
                                  );
                                  props?.setMsgType("error");
                                }
                                setFlagValue("no");
                              }
                            )}
                          </div>
                        )}
                      {influenceOption === "TYPE1" &&
                        influenceOption === option.id &&
                        type1Render()}
                      {influenceOption === "TYPE2" &&
                        influenceOption === option.id &&
                        type2Render()}
                      {influenceOption === "TYPE3" &&
                        influenceOption === option.id &&
                        type3Render()}
                    </div>
                  );
                })}
              </div>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    paddingRight: "12px",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "10px",
                    width: "100%",
                    flexBasis: "25%",
                  }}
                >
                  <TextField
                    sx={{
                      display: "flex",
                      padding: "16px 20px",
                      alignItems: "center",
                      gap: "8px",
                      borderRadius: "12px",
                      border: props.isMobile ? "1px solid var(--color-svg)" : "1px solid #EDEDED",
                      background: "#FFF",
                      marginBottom: "0 !important",
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        fontSize: `${pxToRem(16)} !important`,
                        lineHeight: "100% !important",
                        padding: "0",
                        "& .MuiButtonBase-root": {
                          display: "flex !important",
                        },
                        "& .MuiSelect-outlined": {
                          padding: "0 !important",
                        },
                        "& .MuiInputBase-input": props.isMobile
                          ? {
                            // padding: "50px !important",
                            whiteSpace: "normal",
                            width: "90%",
                            overflow: "visible",
                            // height:
                            //   influenceOption === "TYPE1"
                            //     ? "3.0375em !important"
                            //     : "1.4375em",
                          }
                          : {},
                      },
                    }}
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          className: classes.listItemClass,
                        },
                      },
                    }}
                    className=" unitSelect"
                    fullWidth
                    select
                    InputProps={{
                      endAdornment: (
                        <React.Fragment>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            {influenceOption === "TYPE1" &&
                              influenceOption === "TYPE1" &&
                              !props?.isMobile && (
                                <div
                                  className={classes.swicthButtons}
                                  style={{
                                    marginRight: "40px",
                                    display: "flex",
                                  }}
                                >
                                  {renderButton(
                                    true,
                                    "Evaluative metric",
                                    neutralScaler !== "NumericValue",
                                    (e) => {
                                      setNeutralScaler("");
                                      if (unitValueOption === "timeofday") {
                                        setNeutralScaler("NumericValue");
                                        props?.setShowSnakbar(true);
                                        props?.setSnakbarMsg(
                                          "You can not unChecked when unit is time Of day"
                                        );
                                        props?.setMsgType("error");
                                      }
                                      setFlagValue("no");
                                    }
                                  )}
                                  {renderButton(
                                    false,
                                    "Neutral metric",
                                    neutralScaler === "NumericValue",
                                    (e) => {
                                      setNeutralScaler("NumericValue");
                                      if (unitValueOption === "timeofday") {
                                        setNeutralScaler("NumericValue");
                                        props?.setShowSnakbar(true);
                                        props?.setSnakbarMsg(
                                          "You can not unChecked when unit is time Of day"
                                        );
                                        props?.setMsgType("error");
                                      }
                                      setFlagValue("no");
                                    }
                                  )}
                                </div>
                              )}
                          </Box>
                        </React.Fragment>
                      ),
                    }}
                    placeholder="Select a method to measure this influence"
                    // label="Unit of measurement"
                    value={influenceOption || ""}
                    onChange={(e) => setInfluenceOption(e.target.value)}
                  >
                    {(props.isMobile ? InfluenceSelectOptionMobile : InfluenceSelectOption)?.map((option: any) => (
                      <MenuItem
                        key={option.id}
                        value={option.id}
                        sx={{ display: "flex !imporant" }}
                      >
                        {option.value}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                {influenceOption === "TYPE1" &&
                  influenceOption === "TYPE1" &&
                  props?.isMobile && (
                    <div
                      className={classes.swicthButtonsMobile}
                      style={{
                        // marginRight: "40px",
                        display: "flex",
                      }}
                    >
                      {renderButton(
                        true,
                        "Evaluative metric",
                        neutralScaler !== "NumericValue",
                        (e) => {
                          setNeutralScaler("");
                          if (unitValueOption === "timeofday") {
                            setNeutralScaler("NumericValue");
                            props?.setShowSnakbar(true);
                            props?.setSnakbarMsg(
                              "You can not unChecked when unit is time Of day"
                            );
                            props?.setMsgType("error");
                          }
                          setFlagValue("no");
                        }
                      )}
                      {renderButton(
                        false,
                        "Neutral metric",
                        neutralScaler === "NumericValue",
                        (e) => {
                          setNeutralScaler("NumericValue");
                          if (unitValueOption === "timeofday") {
                            setNeutralScaler("NumericValue");
                            props?.setShowSnakbar(true);
                            props?.setSnakbarMsg(
                              "You can not unChecked when unit is time Of day"
                            );
                            props?.setMsgType("error");
                          }
                          setFlagValue("no");
                        }
                      )}
                    </div>
                  )}
                {influenceOption === "TYPE1" && type1Render()}
                {influenceOption === "TYPE2" && type2Render()}
                {influenceOption === "TYPE3" && type3Render()}
              </>
            )}
          </Grid>
          <br />
          {influenceOption === "TYPE2" && (
            <FormControlLabel
              value={absenceToggle}
              // disabled={
              //   props.influenceData?.uid &&
              //   props.influenceData?.type === 'TYPE1'
              // }
              sx={{
                "& .MuiFormControlLabel-label": {
                  color: "#3E4240",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "100%" /* 16px */,
                },
                "& .Mui-checked": {
                  color: "var(--color-new-main) !important",
                },
                "& .MuiCheckbox-root": {
                  padding: "9px !important",
                },
                "&.MuiFormControlLabel-root": props?.isMobile
                  ? {
                    marginTop: "10px !important",
                  }
                  : {},
              }}
              control={
                <Checkbox
                  // disabled={props.influenceData?.uid}
                  sx={props.isMobile ? {
                    "& svg": {
                      "& path": {
                        stroke: "#DCE5E0"
                      }
                    }
                  } : {}}
                  checked={[true, "true"].includes(absenceToggle)}
                />
              }
              label="Include absence in the scale"
              onChange={(e: any) => {
                setAbsenceToggle(e.target.checked);
                setToogleSwitchAction(e.target.checked);
              }}
            />
          )}
          {influenceOption === "TYPE3" && (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={props.isMobile ? {
                      "& svg": {
                        "& path": {
                          stroke: "#DCE5E0"
                        }
                      }
                    } : {}}
                    // disabled={props.influenceData?.uid}
                    checked={IsInverse == "true" ? true : false}
                  />
                }
                sx={{
                  "& .Mui-checked": {
                    color: "var(--color-new-main) !important",
                  },
                }}
                label="Reverse color"
                onChange={(e) => {
                  IsInverse == "true"
                    ? setIsInverse("false")
                    : setIsInverse("true");
                }}
              />
              {IsInverse === "true" && (
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={props.isMobile ? {
                        "& svg": {
                          "& path": {
                            stroke: "#DCE5E0"
                          }
                        }
                      } : {}}
                      // disabled={props.influenceData?.uid}
                      checked={[true, "true"].includes(inverseCorrelation)}
                    />
                  }
                  sx={{
                    "& .Mui-checked": {
                      color: "var(--color-new-main) !important",
                    },
                  }}
                  label={
                    <Box display={"flex"} alignItems={"center"} gap={"8px"}>
                      <span>Inverse correlation</span>{" "}
                      {/* <HTMLTooltip
                      customIcon={
                        <div>
                          <Info />
                        </div>
                      }
                      title="Activate to automatically move to the next metric when you press enter"
                      fontProps={{
                        fontSize: `${pxToRem(12)} !important`,
                      }}
                      placement="right"
                      sx={{
                        "& .MuiTooltip-tooltip": {
                          maxWidth: "300px",
                        },
                      }}
                    /> */}
                      <IconButton
                        style={{
                          padding: "0px",
                          height: "20px",
                          width: "20px",
                        }}
                      //   onClick={() => setArchiveConfirmationModal(true)}
                      >
                        <HTMLTooltip
                          customIcon={
                            <div>
                              <InfoIcon color={"var(--color-new-main)"} size={"14"} />
                            </div>
                          }
                          title="This is useful when the metric is phrased negatively, like “overate.” Suppose you want to understand the relationship between overeating and well-being, then you need to invert the correlation to interpret it properly"
                          fontProps={{
                            fontSize: `${pxToRem(12)} !important`,
                          }}
                          sx={{
                            "& .MuiTooltip-tooltip": {
                              maxWidth: "300px",
                            },
                          }}
                        />
                      </IconButton>
                    </Box>}
                  onChange={(e) => {
                    [true, "true"].includes(inverseCorrelation)
                      ? setInverseCorrelation(false)
                      : setInverseCorrelation(true);
                  }}
                />
              )}
            </>

          )}
          <FormControlLabel
            value={skipMetric}
            // disabled={
            //   props.influenceData?.uid &&
            //   props.influenceData?.type === 'TYPE1'
            // }
            control={
              <Checkbox
                // disabled={props.influenceData?.uid}
                sx={props.isMobile ? {
                  "& svg": {
                    "& path": {
                      stroke: "#DCE5E0"
                    }
                  }
                } : {}}
                checked={[true, "true"].includes(skipMetric)}
              />
            }
            sx={{
              "& .MuiFormControlLabel-label": {
                color: "#3E4240",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "100%" /* 16px */,
              },
              "& .Mui-checked": {
                color: "var(--color-new-main) !important",
              },
              "& .MuiCheckbox-root": {
                padding: "9px !important",
              },
              "&.MuiFormControlLabel-root": props?.isMobile
                ? {
                  marginTop: "10px !important",
                }
                : {},
            }}
            label={
              <Box display={"flex"} alignItems={"center"} gap={"8px"}>
                <span>Skip metric</span>{" "}
                {width < 786 ? (
                  <ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
                    <IconButton
                      style={{
                        padding: "0px",
                        height: "20px",
                        width: "20px"
                      }}

                      onClick={() => setOpenTooltip(!openTooltip)}
                    >
                      <HTMLTooltip customIcon={<div>
                        <InfoIcon color={"var(--color-new-main)"} size={"14"} />
                      </div>} title={"Activate to automatically move to the next metric when you press enter"} fontProps={{
                        fontSize: `${pxToRem(12)} !important`,
                      }} sx={{
                        "& .MuiTooltip-tooltip": {
                          maxWidth: "300px",
                        },
                      }} customProps={{
                        placement: "bottom-end",
                        open: openTooltip
                      }} />
                    </IconButton>
                  </ClickAwayListener>
                ) : <IconButton
                  style={{
                    padding: "0px",
                    height: "20px",
                    width: "20px",
                  }}
                //   onClick={() => setArchiveConfirmationModal(true)}
                >
                  <HTMLTooltip
                    customIcon={
                      <div>
                        <InfoIcon color={"var(--color-new-main)"} size={"14"} />
                      </div>
                    }
                    title="Activate to automatically move to the next metric when you press enter"
                    fontProps={{
                      fontSize: `${pxToRem(12)} !important`,
                    }}
                    sx={{
                      "& .MuiTooltip-tooltip": {
                        maxWidth: "300px",
                      },
                    }}
                  />
                </IconButton>}
              </Box>
            }
            onChange={(e: any) => {
              setSkipMetric(e.target.checked);
              setToogleSwitchAction(e.target.checked);
            }}
          />
          <Grid
            display={"flex"}
            flexDirection={"column"}
            gap={"16px"}
            paddingTop={`24px`}
            alignItems={"flex-start"}
            width={"100%"}
            sx={props.isMobile ? { marginTop: "0", marginBottom: "40px", width: "100%" } : {}}
          >
            <Typography
              variant="caption"
              component="div"
              // className={classes.pageTitle}
              sx={{
                color: palette.common.black,
                fontSize: "16px!important",
                fontWight: 500,
                lineHeight: "100%",
              }}
            >
              Please, describe the influence
            </Typography>
            <TextareaAutosize
              // variant="outlined"
              className={"textarea " + classes.textarea}
              // fullWidth
              placeholder="Add a description..."
              // multiline
              style={{}}
              minRows={4}
              maxRows={10}
              // sx={{
              //   '& .MuiOutlinedInput-root': {
              //     minHeight: '96px',
              //     resize: "auto",
              //     padding: '0px !important',
              //     // gap: '12px',
              //     // flex: '1 0 0',
              //     borderRadius: '12px !important',
              //     border: '1px solid #EDEDED',
              //     background: '#FFF',
              //     '& .MuiInputBase-input': {
              //       // padding: `0px !important`,
              //       resize: "auto",

              //     }
              //   },
              // }}
              onChange={(e) => setOutcomeDesc(e.target.value)}
              value={outcomeDesc}
            />
          </Grid>
          {!props.isMobile ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                padding: props?.isMobile
                  ? "32px 32px 0px 0px"
                  : "32px 32px 32px 0px",
                alignItems: "flex-end",
                gap: "12px",
              }}
            >
              {!props.showInfluence && (
                <Button
                  className="no-after"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1, color: palette.primary.main, p: 0 }}
                  style={{ display: width > 768 ? "none" : "flex" }}
                >
                  {activeStep === 0 ? (
                    <StepperBackArrowDisable />
                  ) : (
                    <StepperBackArrow />
                  )}
                </Button>
              )}
              <NewButton
                type="submit"
                isDisabled={
                  influenceInput == undefined ||
                  (influenceOption != "TYPE1" &&
                    influenceOption != "TYPE2" &&
                    influenceOption != "TYPE3")
                }
                buttonText={props.influenceData?.uid ? "Update" : "Add"}
                onClick={() => AddInfluences()}
                isLoadong={showLoader}
              />
              {!showLoader ? (
                <NewButton
                  buttonText={"Cancel"}
                  variant="text"
                  onClick={() => props.handleCloseStartModal()}
                />
              ) : null}
              {!props.showInfluence && (
                <Button
                  className="no-after"
                  onClick={handleNext}
                  sx={{ color: palette.primary.main, p: 0, cursor: "pointer" }}
                  style={{ display: width > 768 ? "none" : "flex" }}
                  disabled={
                    activeStep === userType.length - 1 ||
                    (false
                      ? IUserTypeState?.outcomeList?.length == 0
                      : true
                        ? IUserTypeState?.influenceList?.length == 0
                        : false
                          ? IUserTypeState?.reflectiveList?.length == 0
                          : false)
                  }
                >
                  {activeStep === userType.length - 1 ||
                    (false
                      ? IUserTypeState?.outcomeList?.length == 0
                      : true
                        ? IUserTypeState?.influenceList?.length == 0
                        : false
                          ? IUserTypeState?.reflectiveList?.length == 0
                          : false) ? (
                    <StepperNextArrowDisable />
                  ) : (
                    <StepperNextArrow />
                  )}
                </Button>
              )}
            </div>
          ) : (
            <div style={{
              display: "flex",
              position: "fixed",
              bottom: 0,
              left: 0,
              padding: "10px 20px 20px",
              width: "100%",
              background: palette.common.white,
              zIndex: 1
            }}>
              <NewButton
                type="submit"
                isDisabled={
                  influenceInput == undefined ||
                  (influenceOption != "TYPE1" &&
                    influenceOption != "TYPE2" &&
                    influenceOption != "TYPE3")
                }
                buttonText={(props.influenceData?.uid ? "Update" : "Add") + " influence"}
                onClick={() => AddInfluences()}
                isLoadong={showLoader}
                boxSx={{
                  display: 'flex',
                  alignSelf: "stretch",
                  width: "100%",
                  justifyContent: "center",
                }}
                extraSx={{
                  display: 'flex',
                  alignSelf: "stretch",
                  width: "100%",
                }}
                typographySx={{
                  "&.MuiTypography-root": {
                    "@media  (max-width: 786px) and (min-width: 100px)": {
                      fontSize: `${pxToRem(18)} !important`,
                      lineHeight: "100% !important",
                      fontWeight: 500,
                      color: palette.common.black
                    },
                  },
                }}
                fontSize="18px"
              />
            </div>
          )}

          <Wellbeing
            open={openWellbeing}
            journeyData={outcomeState?.notesList?.influence_data}
            setopenWellbeing={setopenWellbeing}
            outcomeValueInput={influenceInput}
          />
          {/* BOTTOM STEPPER */}
          {!props.showInfluence && (
            <BottomStepper
              isOutcome={false}
              isInfluence={true}
              isRefQues={false}
              isTracking={false}
            />
          )}
          <SwitchToOutcomeModal
            open={openSwitchOutcomeModal}
            setopenSwitchInfluenceModal={setopenSwitchOutcomeModal}
            setTransformToInfluenceTracking={
              props?.setTransformToOutcomeTracking
            }
            // setAddOutcomeTracking={props?.setAddOutcomeTracking}
            setShowOutcome={props?.setShowInfluence}
            outcomeValueInput={influenceInput}
          />
          <SwitchInInfluenceModal
            open={openSwitchInInfluenceModal}
            setopenSwitchInfluenceModal={setopenSwitchInInfluenceModal}
            setTransformToInfluenceTracking={
              props?.setTransformInInfluenceTracking
            }
            // setAddOutcomeTracking={props?.setAddOutcomeTracking}
            setShowOutcome={props?.setShowInfluence}
            outcomeValueInput={influenceInput}
          />
          <ArchiveModal
            open={archiveConfirmationModal}
            handleClose={() => setArchiveConfirmationModal(false)}
            handleSubmit={() => {
              onArchiveInfuance();
              setArchiveConfirmationModal(false);
            }}
            outcomeValueInput={influenceInput}
          />
        </RootStyle>
      </Grid>
      {!props?.isMobile && (
        <Drawer
          anchor="right"
          variant="persistent"
          sx={{
            position: "relative",
            // marginLeft: "auto",
            // width: 200,
            zIndex: 1300,
            "& .MuiBackdrop-root": {
              display: "none",
            },
            "& .MuiPaper-root": {
              width: "300px !important",
              position: "absolute",
              height: "100%",
              transition: "none !important",
              display: "flex",
              padding: "24px",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "20px",
              alignSelf: "stretch",
              background: "#FAFAFA",
              borderLeft: "1.5px dashed var(--color-new-text)",
            },
          }}
          open={openRightSideSuggestion}
          onClose={() => {
            setOpenRightSideSuggestion(false);
            props.setNeedIdeasOpen && props.setNeedIdeasOpen(false);
          }}
        >
          {/* <RightSideOutcomeTrackingNew
          outcomeIdeas={props.outcomeIdeas}
          setOutcomeValue={props.setOutcomeValue}
          setOpenRightSideSuggestion={(val) => {
            setOpenRightSideSuggestion(val)
            props.setNeedIdeasOpen && props.setNeedIdeasOpen(false)
          }}
        /> */}
          <Grid
            container
          // sx={{ backgroundColor: palette.primary.main }}
          // className={classes.drawerWrapper}
          >
            <Grid>
              <IconButton
                className={classes.closeButton}
                onClick={() => {
                  setOpenRightSideSuggestion(false);
                  props.setNeedIdeasOpen && props.setNeedIdeasOpen(false);
                }}
              >
                <ModalCloseIcon />
              </IconButton>
              <Typography
                variant="h3"
                component="h3"
                sx={{
                  color: palette.common.black,
                  fontWeight: 600,
                  fontSize: `${pxToRem(18)} !important`,
                  lineHeight: "120%!important",
                  width: "85%",
                }}
              >
                Here are some ideas of influences you might want to consider
              </Typography>
            </Grid>
            <Grid
              className={classes.ideasList}
              sx={{ width: "100%" }}
              flexDirection={"column"}
            >
              {/* {props?.influenceIdeas?.map((ideas: any) => {
              return (
                <div className={classes.ideasListItem} key={ideas._id} onClick={() => props.setInfluenceData(ideas)}>
                  <Typography
                    variant="h3"
                    component="span"
                    sx={{
                      color: palette.common.black,
                      fontWeight: 400,
                      fontSize: `${pxToRem(16)} !important`,
                      lineHeight: "100%!important",
                    }}
                  >
                    {ideas.name}
                  </Typography>

                </div>
              );
            })} */}
              <RecommendedListNew
                setInfluenceData={props.setInfluenceData}
                setInfluenceIdeas={props.setInfluenceIdeas}
              />
            </Grid>
          </Grid>
        </Drawer>
      )}
    </Grid>
  );
};
export default LeftSideInfluenceNew;
