import React from "react";

const LeafIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="36" height="36" rx="5" fill="#F5DB87" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.2234 10.2144C9.18666 10.3261 9.16422 10.4322 9.14944 10.526C9.11533 10.7426 9.10915 10.994 9.104 11.2034L9.07471 12.3645C8.99441 15.546 8.93125 18.0482 9.14861 20.0002C9.37146 22.0013 9.90446 23.5986 11.1529 24.8471C12.4014 26.0955 13.9987 26.6285 15.9998 26.8514C17.9518 27.0688 20.454 27.0056 23.6355 26.9253L24.7965 26.896C25.0059 26.8909 25.2574 26.8847 25.474 26.8506C25.7365 26.8092 26.0951 26.7079 26.4015 26.4015C26.7079 26.0951 26.8092 25.7365 26.8506 25.474C26.8847 25.2574 26.8909 25.0059 26.896 24.7965L26.9253 23.6355C27.0056 20.454 27.0688 17.9518 26.8514 15.9998C26.6285 13.9987 26.0955 12.4014 24.8471 11.1529C23.5986 9.90446 22.0013 9.37146 20.0002 9.14861C18.0482 8.93125 15.546 8.99441 12.3646 9.07471L11.271 9.10229C11.535 9.26777 11.8038 9.4425 12.0748 9.62455C13.8326 10.8055 15.7598 12.3456 17.2071 13.7929C17.2469 13.8327 17.2865 13.8726 17.3259 13.9126L20.6049 13.034C21.1384 12.891 21.6867 13.2076 21.8296 13.7411C21.9726 14.2745 21.656 14.8229 21.1225 14.9658L18.8241 15.5817C19.5616 16.4771 20.1901 17.3517 20.6863 18.0918C21.1017 18.7114 21.428 19.2421 21.6514 19.6197C21.7632 19.8087 21.8495 19.9596 21.9084 20.0646C21.9379 20.1171 21.9606 20.1582 21.9763 20.1868L21.9945 20.2202L21.9997 20.2297L22.0012 20.2326L22.0017 20.2335C22.2627 20.7203 22.08 21.3271 21.5933 21.588C21.1067 21.8489 20.5007 21.6661 20.2396 21.1796L20.2363 21.1734L20.2227 21.1485C20.2102 21.1256 20.1908 21.0905 20.1647 21.0441C20.1125 20.9512 20.0338 20.8133 19.9301 20.6381C19.7228 20.2876 19.4165 19.7893 19.0252 19.2056C18.3586 18.2114 17.4553 16.9849 16.3833 15.8225C16.3025 15.7592 16.231 15.6833 16.1723 15.5967C16.0479 15.4656 15.9214 15.3356 15.7929 15.2071C15.5439 14.9581 15.2782 14.705 15 14.4506V18.5C15 19.0523 14.5523 19.5 14 19.5C13.4477 19.5 13 19.0523 13 18.5V12.767C12.3226 12.2382 11.6288 11.7343 10.9595 11.2847C10.3388 10.8677 9.74969 10.5045 9.2234 10.2144Z"
        fill="white"
      />
    </svg>
  );
};

export default LeafIcon;
