import React from "react";
import TrackingItemInfluence from "./TrackingItemInfluence";

interface TrackingListModal {
  influenceItem: any;
  key: number;
  setShowInfluence: (showInfluence: boolean) => void;
  showInfluence: boolean;
  openStartModalInfluence: boolean;
  setStartModalInfluence: (openStartModalInfluence: boolean) => void;
  getInfluenceList: any;
  setTransformToOutcomeTracking?: any;
  transformToOutcomeTracking?: any;
  setTransformInInfluenceTracking?: any;
  transformInInfluenceTracking?: any;
  startDate: any
  setStartDate: any
  endDate: any 
  setEndDate: any
  index: number
                        influenceList: any[];
  isExpand: boolean
  setLimitReachPopupVisible: any
  setLimitReachPopuptype: any
}

const TrackingListInfluence = (props: TrackingListModal) => {
  return (
      <TrackingItemInfluence
        influenceItem={props.influenceItem}
        setShowInfluence={props.setShowInfluence}
        showInfluence={props.showInfluence}
        openStartModalInfluence={props.openStartModalInfluence}
        setStartModalInfluence={props.setStartModalInfluence}
        getInfluenceList={props.getInfluenceList}
        setTransformToOutcomeTracking={props?.setTransformToOutcomeTracking}
        transformToOutcomeTracking={props?.transformToOutcomeTracking}
        transformInInfluenceTracking={props?.transformInInfluenceTracking}
        setTransformInInfluenceTracking={props?.setTransformInInfluenceTracking}
        startDate={props.startDate}
        setStartDate={props.setStartDate}
        endDate={props.endDate} 
        setEndDate={props.setEndDate}
        index={props.index}
        influenceList={props?.influenceList}
      isExpand={props?.isExpand}
      setLimitReachPopupVisible={props?.setLimitReachPopupVisible}
      setLimitReachPopuptype={props?.setLimitReachPopuptype}

      />
  );
};

export default TrackingListInfluence;
