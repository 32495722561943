import { Typography, Box } from "@mui/material";
import React, { useContext } from "react";
import InfoIcon from "../../assets/images/icon/infoIconYellow";
import { makeStyles } from "@material-ui/core";
import AddOutComeLabel from "./addOutComeLabel";
import shape from "../../theme/shape";
import { OutcomeGetList } from "../../interfaces/outcomeModal";
import { UserTypeContext } from "../../context/userStatus";
import useWidth from "../../utils/useWidth";

const useStyles = makeStyles((theme) => ({
  infoWrapper: {
    backgroundColor: "#FFF4E3",
    border: "1px solid #FF9B37",
    borderRadius: shape.borderRadius,
    padding: "18px 20px",
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
      marginBottom: "10px",
    },
  },
  infoText: {
    color: "#FF9B37",
    fontSize: "16px",
    lineHeight: "16px",
    fontWeight: 400,
    marginLeft: "12px",
  },
  infoWarning: {
    fontSize: "12px",
    lineHeight: "12px",
    fontWeight: 400,
    color: "#9BA19D",
    textAlign: "center",
  },
}));

interface NoOutComeFoundModal {
  setOutcomeValue: (outComeValue: string) => void;
}
const NoOutComeFound = (props: NoOutComeFoundModal) => {
  const classes = useStyles();
  const { state: outcomeState } = useContext(UserTypeContext);
  const width = useWidth();

  return (
    <>
      <div
      style={{ display: width < 768 ? "none" : "block"}}
      >
        <Box
          // display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: "20px" }}

        >
          <Typography variant="h3" component="h3">
            Your BIG 5 Outcomes
          </Typography>
          <Typography variant="caption" component="div">
            {outcomeState?.outcomeList?.length} of 5 Added
          </Typography>
        </Box>
        {/* OUTCOME FOUND */}
        {outcomeState?.outcomeList && outcomeState?.outcomeList?.length > 0 ? (
          outcomeState?.outcomeList?.map(
            (outcomeItem: OutcomeGetList, index: number) => {
              return (
                <AddOutComeLabel
                  outcomeItem={outcomeItem}
                  key={index}
                  setOutcomeValue={props.setOutcomeValue}
                />
              );
            }
          )
        ) : (
          // OUTCOME NOT FOUND
          <Box display="flex" alignItems="center" className={classes.infoWrapper}>
            <InfoIcon />
            <span className={classes.infoText}>
              Please add at least one outcome
            </span>
          </Box>
        )}
        <p className={classes.infoWarning}>
          *You can add more outcomes after the 30 days of baseline trackin
        </p>
        </div>
      </>
  );
};
export default NoOutComeFound;
