import React from "react";

const ShareIcon = ({ color = "#11734A", size= 24 }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="5" cy="12" r="3" stroke={color} strokeWidth="2" strokeLinejoin="round" />
            <circle cx="19" cy="5" r="3" stroke={color} strokeWidth="2" strokeLinejoin="round" />
            <path d="M7.5 10.999L16.5 6.49898" stroke={color} strokeWidth="2" strokeLinejoin="round" />
            <circle cx="3" cy="3" r="3" transform="matrix(1 0 0 -1 16 22)" stroke={color} strokeWidth="2" strokeLinejoin="round" />
            <path d="M7.5 13.001L16.5 17.501" stroke={color} strokeWidth="2" strokeLinejoin="round" />
        </svg>

    );
};

export default ShareIcon;
