import React, { useState } from "react"
import { Box, Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography, useMediaQuery, } from "@mui/material";
import { makeStyles, useTheme } from "@material-ui/core";
import ModalCloseIcon from "../../assets/images/icon/modalCloseIconBlack";
import palette from "../../theme/palette";
import CommonTypo from "../../components/CommonTypo";
import insightsProgressStyles from "./insightsClasses";
import CorrelationInfo from "../../assets/images/icon/CorrelationInfo";
import RegressionInfo from "../../assets/images/icon/RegressionInfo";

const regressions = [
    {
        title: 'Definition',
        text: 'The regression coefficient is a number that indicates the effect an increase in one metric has on another metric.'
    },
    {
        title: 'Interpretation',
        text: 'A regression coefficient of 1.3 between daily meditation and focus means that on days when you do meditation, you will on average report 1.3 more focus compared to days without meditation.'
    },
    {
        title: 'Model',
        text: 'TNT is currently only running univariate linear regressions, which can lead to imperfect estimations. For example, it could overestimate or underestimate the effect of one metric because of the influence of a third or fourth metric that wasn\'t accounted for.'
    },
]
const correlations = [
    {
        title: 'Definition',
        text: `The correlation coefficient is a number between -1 and 1. The sign tells you if the relationship between metrics is positive or negative. The number tells you about the strength of the relationship or, more specifically, how likely it is that you input a low or high value for both on the same day. Heuristics for correlations are 
        <br /> .00 - .10 no/negligible 
        <br /> .11 - .30 small 
        <br /> .31 - .60 moderate
        <br /> .61 - .80 strong
        <br /> .81 - 1 very strong`
    },
    {
        title: 'Interpretation',
        text: 'A correlation of .70 between daily meditation and focus means that on days when you do meditation, you are very likely to report a high value for focus.'
    },
    {
        title: 'Limitation',
        text: 'Because it is a relative number, it tells you little about the absolute size of the effect, which makes it impossible to compare with the effects of other influences. It important to also remember that "correlation does not imply causation". '
    },
    {
        title: 'Model',
        text: 'TNT is currently only running univariate linear correlations, which can lead to imperfect estimations. For example, it could overestimate or underestimate the correlation because of the influence of a third or fourth metric that wasn\'t accounted for.'
    },
]

const useStyles = makeStyles((theme) => ({
    registrationHeading: {
        color: `${palette.primary.light}!important`,
        marginBottom: "20px!important",
    },
    liClass: {
        "&::marker": {
            color: "#656565"
        }
    },
    registrationTitle: {
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '1.375rem',
        lineHeight: '100%',
        /* identical to box height, or 22px */


        /* TextColors/DarkGrey */

        color: '#3E4240',
    },
    registrationTextTitle: {
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '1rem !important',
        lineHeight: '1.2em',
        marginTop: '24px',
        marginBottom: '4px',
        /* identical to box height, or 22px */


        /* TextColors/DarkGrey */

        color: '#3E4240 !important',
    },
    registrationTextdescription: {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '1rem !important',
        lineHeight: '1.2em',
        marginTop: '4px',
        lineBreak: 'auto',
        /* identical to box height, or 22px */


        /* TextColors/DarkGrey */

        color: '#3E4240 !important',
    },
    pageTitle: {
        marginBottom: "16px!important",
    },
    dialogTitle: {
        color: `${palette.primary.light}!important`,
        fontSize: "26px!important",
        lineHeight: "26px!important",
        letterSpacing: "0.02em",
        marginBottom: "40px!important",
        padding: "0!important",
    },
    bottomButton: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        "& button:nth-child(2)": {
            marginLeft: "24px",
        },
    },
    dialogWrapper: {
        // width: '75%',
        '& .MuiDialog-paper': {
            backgroundColor: '#fff',
            borderRadius: '20px',
            padding: '16px',
            maxWidth: '80%',
            overflow: 'unset',
        }
    }
}));
const InsightsInformatinsModal = (props) => {
    const classes = useStyles();
    const theme = useTheme();
    const [selectedTab, setSelectedTab] = useState("correlation");
    const {
        visibleInightInformation,
        setVisibleInsightInformation
    } = props
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const classes1 = insightsProgressStyles()
    return (
        <Dialog
            onClose={() => setVisibleInsightInformation(false)}
            open={visibleInightInformation}
            className={classes.dialogWrapper}
            fullWidth
            fullScreen={fullScreen}
            sx={{
                "& .MuiDialog-paper": {
                    maxWidth: "calc(100vw - 30%)",
                    maxHeight: "calc(100vh - 10%)",
                    padding: "32px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px"
                }
            }}
        >
            <IconButton
                sx={{ position: "absolute", top: 16, right: 16 }}
                // onClick={handleCloseStartModal}
                onClick={() => {
                    setVisibleInsightInformation(false)
                }}
            >
                <ModalCloseIcon color={"#000000"} />
            </IconButton>
            <CommonTypo size={24} lineHeight={24} fontWeight={700}>How does it work?</CommonTypo>
            <div className={classes1.tabBox}>
                <Box className={classes1.tabClasses} onClick={() => {
                    setSelectedTab("correlation")
                }} sx={{
                    background: selectedTab === "correlation" ? palette.common.white : "transparent",

                }}>
                    <CommonTypo size={16} color={"var(--color-text-primary)"} fontWeight={selectedTab === "correlation" ? 600 : 400}>Correlation</CommonTypo>
                </Box>
                <Box className={classes1.tabClasses} onClick={() => {
                    setSelectedTab("regression")
                }} sx={{
                    background: selectedTab === "regression" ? palette.common.white : "transparent"
                }}>
                    <CommonTypo size={16} color={"var(--color-text-primary)"} fontWeight={selectedTab === "regression" ? 600 : 400}>Regression</CommonTypo>
                </Box>
            </div>
            {selectedTab === "correlation" ? (
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                    maxHeight: "calc(100% - 200px)",
                    overflowY: "auto"
                }}>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "4px", maxWidth: "calc(100% - 80px)" }}>
                        <CommonTypo size={16} color={"var(--color-text-primary)"} lineHeight={24} fontWeight={700}>Correlation</CommonTypo>
                        <CommonTypo size={16} color={"#656565"} lineHeight={24} fontWeight={400}>It measures how two things move together, giving a number between -1 and 1:</CommonTypo>
                        <ul style={{
                            margin: 0,
                            marginBlockStart: "0px",
                            paddingInlineStart: "25px"
                        }}>
                            <RenderLI fontWeight={700}>
                                <CommonTypo size={16} color={"#656565"} lineHeight={24} fontWeight={700} extraSx={{
                                    letterSpacing: "-0.2px"
                                }}>Positive</CommonTypo> &nbsp;
                                <CommonTypo size={16} color={"#656565"} lineHeight={24} fontWeight={400} extraSx={{
                                    letterSpacing: "-0.2px"
                                }}>values (closer to 1) mean that when one thing increases, the other tends to increase too.</CommonTypo>
                                <br />
                                <CommonTypo size={16} color={"#656565"} lineHeight={24} fontWeight={400} extraSx={{
                                    letterSpacing: "-0.2px"
                                }}>Example: If there’s a positive correlation between coffee consumption and sleep hours, it means that on days you drink more coffee, you also tend to sleep more.</CommonTypo>
                            </RenderLI>
                            <RenderLI fontWeight={700}>
                                <CommonTypo size={16} color={"#656565"} lineHeight={24} fontWeight={700} extraSx={{
                                    letterSpacing: "-0.2px"
                                }}>Negative</CommonTypo> &nbsp;
                                <CommonTypo size={16} color={"#656565"} lineHeight={24} fontWeight={400} extraSx={{
                                    letterSpacing: "-0.2px"
                                }}>values (closer to -1) mean that when one thing increases, the other tends to decrease.</CommonTypo>
                                <br />
                                <CommonTypo size={16} color={"#656565"} lineHeight={24} fontWeight={400} extraSx={{
                                    letterSpacing: "-0.2px"
                                }}>Example: If there’s a negative correlation between coffee consumption and sleep hours, it means that on days you drink more coffee, you tend to sleep less.</CommonTypo>
                            </RenderLI>
                            <RenderLI fontWeight={700}>
                                <CommonTypo size={16} color={"#656565"} lineHeight={10} fontWeight={400} extraSx={{
                                    letterSpacing: "-0.2px"
                                }}>The closer the number is to 0, the &nbsp;<CommonTypo size={16} color={"#656565"} lineHeight={10} fontWeight={700} extraSx={{
                                    letterSpacing: "-0.2px"
                                }}>weaker</CommonTypo>&nbsp; the relationship.</CommonTypo>
                            </RenderLI>
                        </ul>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "4px", maxWidth: "calc(100% - 80px)" }}>
                        <CommonTypo size={16} color={"var(--color-text-primary)"} lineHeight={24} fontWeight={600}>Simple guide:</CommonTypo>
                        <ul style={{
                            margin: 0,
                            marginBlockStart: "0px",
                            paddingInlineStart: "25px"
                        }}>
                            <RenderLI>0 to 0.10: No or negligible correlation.</RenderLI>
                            <RenderLI>0.11 to 0.30: Small correlation.</RenderLI>
                            <RenderLI>0.31 to 0.60: Moderate correlation.</RenderLI>
                            <RenderLI>0.61 to 0.80: Strong correlation.</RenderLI>
                            <RenderLI>0.81 to 1: Very strong correlation.</RenderLI>
                        </ul>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "4px", maxWidth: "calc(100% - 20px)" }}>
                        <CorrelationInfo />
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: "4px", maxWidth: "calc(100% - 80px)" }}>
                        <CommonTypo size={16} color={"var(--color-text-primary)"} lineHeight={24} fontWeight={600}>Limitations:</CommonTypo>
                        <CommonTypo size={16} color={"#656565"} lineHeight={24} fontWeight={400} extraSx={{
                            letterSpacing: "-0.2px"
                        }}>Correlation doesn’t show cause and effect, and being a relative number, it doesn’t tell you the absolute size of the effect. TNT currently only runs univariate correlations, meaning other factors (like stress) might not be considered, leading to imperfect estimations.</CommonTypo>
                    </Box>
                </Box>
            ) : <Box sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                maxHeight: "calc(100% - 200px)",
                overflowY: "auto"
            }}>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "4px", maxWidth: "calc(100% - 80px)" }}>
                    <CommonTypo size={16} color={"var(--color-text-primary)"} lineHeight={24} fontWeight={700}>Correlation</CommonTypo>
                    <CommonTypo size={16} color={"#656565"} lineHeight={24} fontWeight={400}>The regression coefficient is a number that indicates the effect an increase in one metric has on another metric.</CommonTypo>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "4px", maxWidth: "calc(100% - 80px)" }}>
                    <CommonTypo size={16} color={"var(--color-text-primary)"} lineHeight={24} fontWeight={600}>Interpretation</CommonTypo>
                    <CommonTypo size={16} color={"#656565"} lineHeight={24} fontWeight={400} extraSx={{
                        letterSpacing: "-0.2px"
                    }}>A regression coefficient of -1.3 between coffee consumption and sleep hours means that on days when you drink one more coffee, you will on average sleep 1.3 fewer hours compared to days with less or no coffee.</CommonTypo>
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "4px", maxWidth: "calc(100% - 20px)" }}>
                    <RegressionInfo />
                </Box>
                <Box sx={{ display: "flex", flexDirection: "column", gap: "4px", maxWidth: "calc(100% - 80px)" }}>
                    <CommonTypo size={16} color={"var(--color-text-primary)"} lineHeight={24} fontWeight={600}>Model</CommonTypo>
                    <CommonTypo size={16} color={"#656565"} lineHeight={24} fontWeight={400} extraSx={{
                        letterSpacing: "-0.2px"
                    }}>TNT is currently only running univariate linear regressions, which can lead to imperfect estimations. For example, it could overestimate or underestimate the effect of one metric because of the influence of a third or fourth metric that wasn't accounted for.</CommonTypo>
                </Box>
            </Box>}

            {/* <Grid container direction="row" alignItems="flex-start" justifyContent={"space-between"} overflow={"auto"}>
                <Grid item xs={12} md={5.9} sx={{ padding: '12px', margin: '2px' }}>
                    <p

                        className={classes.registrationTitle}
                    >
                        Regression
                    </p>
                    {regressions.map(it => (
                        <>
                            <p
                                className={classes.registrationTextTitle}
                            >
                                {it.title}
                            </p>
                            <p
                                className={classes.registrationTextdescription}
                                dangerouslySetInnerHTML={{
                                    __html: it.text
                                }}
                            >
                            </p>
                        </>
                    ))}
                </Grid>
                <Grid item xs={12} md={5.9} sx={{ padding: '12px', margin: '2px' }}>
                    <p

                        className={classes.registrationTitle}
                    >
                        Correlation
                    </p>
                    {correlations.map(it => (
                        <>
                            <p
                                className={classes.registrationTextTitle}
                            >
                                {it.title}
                            </p>
                            <p

                                className={classes.registrationTextdescription}
                                dangerouslySetInnerHTML={{
                                    __html: it.text
                                }}
                            >
                            </p>
                        </>
                    ))}
                </Grid>
            </Grid> */}
        </Dialog>
    )
}
export default InsightsInformatinsModal

const RenderLI = ({ fontWeight = 400, children }) => {
    const classes = useStyles();
    return <li className={classes.liClass}>
        <CommonTypo size={16} color={"#656565"} lineHeight={24} fontWeight={fontWeight} extraSx={{
            letterSpacing: "-0.2px"
        }}>{children}</CommonTypo>
    </li>
}