
import { Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
} from "@material-ui/core";
import EditIcon from "../../assets/images/icon/EditIcon";
import ModalCloseIconBlack from "../../assets/images/icon/modalCloseIconBlack";
import SnackBarComp from "../../components/snackBar";
import palette from "../../theme/palette";
import { OutcomeStatus } from "../../utils/staticDataJson";
import LeftSideOutCome from "../outcome/leftOutcome";
import LeftTransformToInflu from "./LeftTransformToInflu";
import MultiDrawerAddEditComponent from "../../components/MultiDrawerComponent";
import LeftSideOutComeNew from "../outcome/leftOutcomeNew";

const useStyles = makeStyles((theme) => ({
  // listText: {
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "space-between",
  //   padding: "0px 0px",
  //   minHeight: "47px",
  //   // maxWidth: '100%',
  //   maxWidth: "322px",
  //   gap: '20px',
  //   "& h6": {
  //     color: palette.primary.light,
  //     fontSize: "16px",
  //     lineHeight: "24px",
  //     textOverflow: "ellipsis",
  //     overflow: "hidden",
  //     whiteSpace: "nowrap",
  //     fontWeight: 400,
  //     fontFamily: "Poppins",
  //   },
  // },
  listText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 24px",
    gap: '12px',
    // boxShadow: '5px 0px 40px 0px rgba(18, 18, 19, 0.05)',
    // "& h6": {
    //   color: palette.primary.light,
    //   fontSize: "16px",
    //   lineHeight: "24px",
    //   maxWidth: "275px",
    //   textOverflow: "ellipsis",
    //   overflow: "hidden",
    //   whiteSpace: "nowrap",
    // },
    "& h6": {
      color: palette.common.black,
      fontSize: "16px",
      lineHeight: "24px",
      // maxWidth: "275px",
      // textOverflow: "ellipsis",
      // overflow: "hidden",
      // whiteSpace: "nowrap",
    },
  },
  textClass: {
    overflow: 'hidden',
    display: '-webkit-box',
    lineClamp: 1,
    boxOrient: 'vertical',
    textOverflow: 'ellipsis',
    margin: "20px 0px !important",
  },
  modalCloseIcon: {
    position: "absolute",
    right: 0,
    top: 0,
    cursor: "pointer",
    zIndex: 1,
  },
  mainDivClas: {
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: "center",
    borderBottom: '1px solid #F3F3F3',

  },
  fullTexttextClass: {
    overflow: 'hidden',
    display: '-webkit-box',
    lineClamp: 8,
    boxOrient: 'vertical',
    textOverflow: 'ellipsis',
    margin: "20px 0px !important",
  },
  mainDivClasExpand: {
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: "flex-start",
    borderBottom: '1px solid #F3F3F3',
    height: "221px",
    background: '#FFF',
    flexBasis: "100%",
  }
}));
interface PersonalTrackingItemModal {
  outcome: any;
  setShowOutcome: (showOutcome: boolean) => void;
  showOutcome: boolean;
  openStartModal: boolean;
  setStartModal: (openStartModal: boolean) => void;
  setTransformToInfluenceTracking?: any;
  transformToInfluenceTracking?: any;
  categoryId?: any;
  startDate: any
  endDate: any
  index: number
  dataList: any[];
  isExpand: boolean
  setLimitReachPopupVisible: any
setLimitReachPopuptype: any
}

const PersonalTrackingOutcome = (props: PersonalTrackingItemModal) => {
  const classes = useStyles();
  const [outComeValue, setOutcomeValue] = useState("");
  const [outComeId, setOutcomeId] = useState(null);
  const [addOutcomeTracking, setAddOutcomeTracking] = useState(false);
  const [textInput, setTextInput] = useState(OutcomeStatus);
  const [outcomeIdeas, setOutcomeIdeas] = useState();
  const [showIcon, setshowIcon] = useState(false);
  const [showSnakbar, setShowSnakbar] = useState(false);
  const [snakbarMsg, setSnakbarMsg] = useState("");
  const [msgType, setMsgType] = useState("");
  const [needIdeasOpen, setNeedIdeasOpen] = useState(false)
  const handleCloseStartModal = () => {
    setOutcomeId(null);
    props.setStartModal(false);
    props.setShowOutcome(false);
  };
  const handleCloseSnakbar = () => {
    setShowSnakbar(false);
  };
  const editOutcomeTracking = (outcome: any, id: any) => {
    props.setStartModal(true);
    setOutcomeId(id);
    props.setShowOutcome(true);
    setOutcomeValue(outcome);
  };

  const handleCloseTransformModal = () => {
    props?.setTransformToInfluenceTracking(false);
  };

  return (
    <Grid item className={classes.listText}>
      <>
        {/* <Typography variant="h6">
          {props.outcome.name || props.outcome.question}
        </Typography> */}
        <SnackBarComp
          showSnakbar={showSnakbar}
          handleCloseSnakbar={handleCloseSnakbar}
          snakbarMsg={snakbarMsg}
          type={msgType ? msgType : "info"}
        />
        <div className={props?.isExpand ? classes.mainDivClasExpand : classes.mainDivClas} style={(props.dataList.length - 1) === props.index ? { borderBottom: 0 } : {}}
          onMouseEnter={() => setshowIcon(true)}
          onMouseLeave={() => setshowIcon(false)}>
          {props.outcome.description ? (
            <Tooltip
              title={
                <Typography sx={{ fontSize: "14px !important", lineHeight: "1.5" }}>
                  {props.outcome.description}
                </Typography>
              }
            >
              <div style={{
                width: '100%'
              }}>
                <Typography variant="h6" flex={7} title={props.outcome.name} className={props?.isExpand ? classes.fullTexttextClass :classes.textClass}>
                  {" "}
                  {props.outcome.name}
                </Typography>
              </div>
            </Tooltip>
          ) : (
            <div style={{
              width: '100%'
            }}>
              <Typography variant="h6" flex={7} title={props.outcome.name} className={props?.isExpand ? classes.fullTexttextClass :classes.textClass}>
                {" "}
                {props.outcome.name}
              </Typography>
            </div>
          )}
          {showIcon && <span
            onClick={() => editOutcomeTracking(props.outcome, props.outcome._id)}
            className={props?.isExpand ? classes.textClass : ''}
          >
            <EditIcon />
          </span>}
        </div>



        {props.showOutcome && outComeId === props.outcome?._id && (
          <MultiDrawerAddEditComponent
            handleClose={handleCloseStartModal}
            drawerTitle={<>Edit  <span style={{ color: palette.primary.main }}>Outcomes</span></>}
            secondOpen={needIdeasOpen}
          >
            <LeftSideOutComeNew
              outComeValue={outComeValue}
              setOutcomeValue={setOutcomeValue}
              outcomeIdeas={outcomeIdeas}
              setShowOutcome={props.setShowOutcome}
              showOutcome={props.showOutcome}
              setAddOutcomeTracking={setAddOutcomeTracking}
              setTextInput={setTextInput}
              textInput={textInput}
              setShowSnakbar={setShowSnakbar}
              setMsgType={setMsgType}
              setSnakbarMsg={setSnakbarMsg}
              handleCloseStartModal={handleCloseStartModal}
              getOutcomeList={[]}
              setTransformToInfluenceTracking={
                props?.setTransformToInfluenceTracking
              }
              startDate={props.startDate}
              endDate={props.endDate}
              setNeedIdeasOpen={setNeedIdeasOpen}
              setLimitReachPopupVisible={props?.setLimitReachPopupVisible}
              setLimitReachPopuptype={props?.setLimitReachPopuptype}
            />
          </MultiDrawerAddEditComponent>
          // <Dialog
          //   disableEscapeKeyDown
          //   onClose={handleCloseStartModal}
          //   open={props.openStartModal}
          //   className="dialogWrapper dialogWrapper-outcome"
          // >
          //   <DialogTitle className={classes.modalCloseIcon}>
          //     <span onClick={handleCloseStartModal}>
          //       <ModalCloseIconBlack />
          //     </span>
          //   </DialogTitle>
          //   <DialogContent>
          //     {/* OUTCOME LEFT SECTION */}
          //     <LeftSideOutCome
          //       outComeValue={outComeValue}
          //       setOutcomeValue={setOutcomeValue}
          //       outcomeIdeas={outcomeIdeas}
          //       setShowOutcome={props.setShowOutcome}
          //       showOutcome={props.showOutcome}
          //       setAddOutcomeTracking={setAddOutcomeTracking}
          //       setTextInput={setTextInput}
          //       setShowSnakbar={setShowSnakbar}
          //       setMsgType={setMsgType}
          //       setSnakbarMsg={setSnakbarMsg}
          //       textInput={textInput}
          //       setTransformToInfluenceTracking={
          //         props?.setTransformToInfluenceTracking
          //       }
          //       startDate={props.startDate}
          //       endDate={props.endDate}
          //     />
          //   </DialogContent>
          // </Dialog>
        )}
        {props?.transformToInfluenceTracking &&
          outComeId === props.outcome?._id && (
            <Dialog
              disableEscapeKeyDown
              onClose={props?.setTransformToInfluenceTracking}
              open={props.openStartModal}
              className="dialogWrapper dialogWrapper-outcome"
            >
              <DialogTitle className={classes.modalCloseIcon}>
                <span onClick={handleCloseTransformModal}>
                  <ModalCloseIconBlack />
                </span>
              </DialogTitle>
              <DialogContent>
                {/* OUTCOME LEFT SECTION */}
                {/* <LeftSideOutCome
                outComeValue={outComeValue}
                setOutcomeValue={setOutcomeValue}
                outcomeIdeas={outcomeIdeas}
                setShowOutcome={props.setShowOutcome}
                showOutcome={props.showOutcome}
                setAddOutcomeTracking={setAddOutcomeTracking}
                setTextInput={setTextInput}
                textInput={textInput}
                handleCloseStartModal={handleCloseStartModal}
                getOutcomeList={props?.getOutcomeList}
                setTransformToInfluenceTracking={props?.setTransformToInfluenceTracking}
              /> */}
                <LeftTransformToInflu
                  // setInfluenceData={setInfluenceData}
                  // influenceData={influenceData}
                  // influenceIdeas={influenceIdeas}
                  // showInfluence={showInfluence}
                  // setAddInfluenceTracking={setAddInfluenceTracking}
                  // setShowInfluence={setShowInfluence}
                  setTextInput={setTextInput}
                  handleCloseTransformModal={handleCloseTransformModal}
                  textInput={textInput}
                  outComeValue={outComeValue}
                  setTransformToInfluenceTracking={
                    props?.setTransformToInfluenceTracking
                  }
                  categoryId={props?.categoryId}
                />
              </DialogContent>
            </Dialog>
          )}
      </>
    </Grid>
  );
};

export default React.memo(PersonalTrackingOutcome);
