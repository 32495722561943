import React, { useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { CalendarPicker } from "@mui/x-date-pickers/CalendarPicker";
import { Box, styled } from "@mui/system";
import { Checkbox, Input, Radio, TextField, Typography } from "@mui/material";
import moment from "moment";
import { StaticDatePicker } from "@mui/x-date-pickers";
import dayjs from 'dayjs';


// import * as React from 'react';
// import TextField from '@mui/material/TextField';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';


export default function DatePickerDetails({ currentDate, setCurrentDate }) {
  const [value, setValue] = React.useState(dayjs('2022-04-07'));
  const TestDatePicker = styled(CalendarPicker)`

    background: "blue"
    & .MuiPickersCalendarHeader-label {
      color: #000;
    }
  
  `;

  // const MainContainer = styled(Box)(({ theme }) => ({
  //   width: "100%",
  //   display: "flex",
  //   alignItems: "center",
  //   flexDirection: "column",
  //   gap: "5px",
  //   width: "388px",
  //   padding: "18px 16px 18px 16px",
  //   background: " #FFFFFF",
  //   border: "1px solid #DCE6E0",
  //   // backgroundColor: 'blue'
  // }));

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {/* <MainContainer> */}
      {/* <TestDatePicker
          components={{}}
          date={currentDate}
          onChange={(newDate) => setCurrentDate(newDate)}
        ></TestDatePicker> */}
      <StaticDatePicker
        displayStaticWrapperAs="desktop"
        openTo="day"
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        renderInput={(params) => <TextField {...params} />}
      />
      {/* </MainContainer> */}
    </LocalizationProvider>
  );
}
