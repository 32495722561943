import React from "react";

const Correlations = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20"  xmlns="http://www.w3.org/2000/svg">
            <path d="M19 19H2C1.44772 19 1 18.5523 1 18V1H3V17H19V19ZM6.373 14L5 12.656L9.856 7.9C10.2383 7.52848 10.8467 7.52848 11.229 7.9L13.456 10.081L17.627 6L19 7.344L14.144 12.1C13.7617 12.4715 13.1533 12.4715 12.771 12.1L10.543 9.918L6.374 14H6.373Z"  /* fill-opacity="0.5" */ />
        </svg>
    );
};

export default Correlations;
