import axios from "axios";
import { baseEndpoints } from "./config";

const headerToken = localStorage.getItem("userDetails");

const getInfluenceList = async (token: string) => {
  const { data } = await axios
    .post(
      `${baseEndpoints.influence}/get`,
      {},
      {
        headers: {
          "x-access-token": token ? token : headerToken,
        },
      }
    )
    .then((response) => {
      return response;
    });
  return data;
};

const getAreas = async (areaData: any, token: string) => {
  const { data } = await axios
    .post(
      `${baseEndpoints.INSIGHTS.Category_Dates}`,
      areaData,
      {
        headers: {
          "x-access-token": token ? token : headerToken,
        },
      }
    )
    .then((response) => {
      return response;
    });
  return data;
};

const createInfluence = async (influenceValue: any, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.influence}/create`, influenceValue, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const editInfluence = async (influenceValue: any, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.influence}/update`, influenceValue, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const getInfluenceSuggestion = async (token: string) => {
  const { data } = await axios
    .post(
      `${baseEndpoints.influenceSuggestion}/get`,
      {},
      {
        headers: {
          "x-access-token": token ? token : headerToken,
        },
      }
    )
    .then((response) => {
      return response;
    });
  return data;
};

const TransformOutcomeToInfluence = async (influenceValue: any, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.outcomeToInfluece}`, influenceValue, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const TransformInfluenceToOutcome = async (influenceValue: any, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.InflueceToOutcome}`, influenceValue, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const TransformInfluenceToInfluence = async (influenceValue: any, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.InflueceToInfluence}`, influenceValue, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

export const influenceService = {
  getInfluenceList,
  getAreas,
  createInfluence,
  editInfluence,
  getInfluenceSuggestion,
  TransformOutcomeToInfluence,
  TransformInfluenceToOutcome,
  TransformInfluenceToInfluence
};
