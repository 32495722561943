
import React from 'react'

export default function NumericScaleIcon() {
  return (
    <>
      <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24 21H0" stroke="#B7BDB9" strokeWidth="2"/>
        <line x1="1" y1="18" x2="1" y2="24" stroke="#B7BDB9" strokeWidth="2"/>
        <line x1="23" y1="18" x2="23" y2="24" stroke="#B7BDB9" strokeWidth="2"/>
        <line x1="12" y1="18" x2="12" y2="24" stroke="#B7BDB9" strokeWidth="2"/>
        <path d="M12 14.5C12.3787 14.5 12.731 14.3431 12.9666 14.2194C13.237 14.0775 13.5238 13.886 13.8099 13.6676C14.3844 13.229 15.0257 12.6287 15.6261 11.9458C16.2262 11.2631 16.8074 10.4727 17.2434 9.64801C17.6735 8.83449 18 7.9169 18 7C18 3.68629 15.3137 1 12 1C8.68629 1 6 3.68629 6 7C6 7.9169 6.32646 8.83449 6.75658 9.64801C7.19262 10.4727 7.77381 11.2631 8.37392 11.9458C8.97429 12.6287 9.61563 13.229 10.1901 13.6676C10.4762 13.886 10.763 14.0775 11.0334 14.2194C11.269 14.3431 11.6213 14.5 12 14.5Z" stroke="#B7BDB9" strokeWidth="2"/>
        <path d="M12.5 11V5L10 7.5" stroke="#B7BDB9" strokeWidth="2" strokeLinejoin="round"/>
      </svg>

    </>
  )
}
