import React from 'react'

const DeleteIcon = ({ color = 'var(--color-new-main)'}) => {
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M4 4.5H16V14.5C16 16.7091 14.2091 18.5 12 18.5H8C5.79086 18.5 4 16.7091 4 14.5V4.5Z" stroke={color} strokeWidth="2" strokeLinejoin="round" />
                <line x1="12" y1="15" x2="12" y2="8.5" stroke={color} strokeWidth="2" strokeLinejoin="round" />
                <line x1="8" y1="15" x2="8" y2="8.5" stroke={color} strokeWidth="2" strokeLinejoin="round" />
                <line x1="6.66797" y1="1.5" x2="13.3346" y2="1.5" stroke={color} strokeWidth="2" strokeLinejoin="round" />
                <line x1="1" y1="4.5" x2="19" y2="4.5" stroke={color} strokeWidth="2" strokeLinejoin="round" />
            </svg>
        </>
    )
}

export default DeleteIcon
