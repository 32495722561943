import { Grid, Button, Pagination } from "@mui/material";
import React, { useContext } from "react";
import CloudIcon from "../../assets/images/icon/cloudIcon";
import HeartIcon from "../../assets/images/icon/heartIcon";
import LeafIcon from "../../assets/images/icon/leafIcon";
import MoonIcon from "../../assets/images/icon/moonIcon";
import SunIcon from "../../assets/images/icon/sunIcon";
import CustomCard from "./customCard";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { ReportsTypeContext } from "../../context/userIReports";
import moment from "moment";
import ShareIcon from "../../assets/images/icon/ShareIcon";
import DeleteIcon from "../../assets/images/icon/DeleteIcon";
import EditIcon from "../../assets/images/icon/EditIcon";
import { setReportWeekDataCurrentPage, setSelectedRow } from "../../store/actions/reportTypeAction";
import SortDownIcon from "../../assets/images/icon/SortDown";
import SortUpIcon from "../../assets/images/icon/SortUp";
import palette from "../../theme/palette";
import { makeStyles } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
  cards: {
    alignItems: "center",
    marginTop: "20px",

    "& .MuiGrid-item": {
      height: "100%",
      maxWidth: "238px",
      marginRight: "12px",
      borderRadius: "8px",
      padding: "20px 16px 20px 20px",
      marginBottom: "12px",
      [theme.breakpoints.down(1512)]: {
        maxWidth: "225px",
      },
      [theme.breakpoints.down(1450)]: {
        maxWidth: "197px",
        padding: "10px",
      },
      [theme.breakpoints.down("xs")]: {
        maxWidth: "100%",
        marginRight: "0px",
      },

      "&:last-child": {
        marginRight: "0px",
      },
    },

    [theme.breakpoints.down("lg")]: {
      display: "flex!important",
    },
    [theme.breakpoints.down("xs")]: {
      display: "block!important",
    },
  },
  weekReportTable: {
    boxShadow: 'none !important',
    marginBottom: '0px',
    height: 'calc(100vh - 275px)',
    overflowY: 'auto',
    '& .MuiTableCell-root': {
      borderBottom: 'none'
    },
    '& .MuiTableHead-root': {
      '& th': {
        fontWeight: 400,
        fontSize: '1rem',
        color: '#9BA19D',
        '& span': {

          display: 'flex',
          alignItems: 'center',
        }

      }
    }
  },
  buttonCell: {
    display: 'flex !important',
    alignItems: 'center',
    '& button': {
      margin: '0 5px',
      padding: '10px',
      fontSize: '1rem',
      color: '#3E4240',
      fontWeight: 400,
      gap: '4px',
      height: '40px'
    }
  },
}));

const ProgressOutcomes = () => {
  const classes = useStyles();
  const { state: IReportTypeContext, dispatch: reportTypeDispatch } =
    useContext(ReportsTypeContext);
  const handleChangePage = (e, val) => {
    reportTypeDispatch(setReportWeekDataCurrentPage(val))
  }
  const rowSeelcted = (row) => {
    reportTypeDispatch(setSelectedRow(row))
  }
  return (
    <div>
      <TableContainer component={Paper} className={classes.weekReportTable}>
        <Table sx={{ minWidth: 650, borderColor: 'transparent' }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell ><span>Duration  <SortDownIcon /> <SortUpIcon /></span></TableCell>
              <TableCell ><span>Outcomes  <SortDownIcon /> <SortUpIcon /></span></TableCell>
              <TableCell ><span>Influences  <SortDownIcon /> <SortUpIcon /></span></TableCell>
              {/* <TableCell ></TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {IReportTypeContext.weekReportData.map((row) => (
              <>
                <TableRow
                  key={row.name}
                  onClick={() => rowSeelcted({
                    ...row,
                    from: row.weekStart,
                    till: row.weekEnd
                  })}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: "pointer" }}
                >
                  <TableCell component="th" scope="row" sx={{
                    color: "var(--color-new-main)",
                    textDecoration: 'underline'
                  }}>
                    Week report #{row.weekNumber}
                  </TableCell>
                  <TableCell >{moment(row.weekStart).format("MM/DD")} - {moment(row.weekEnd).format("MM/DD")}</TableCell>
                  <TableCell >{row.outcomeCount}
                  </TableCell>

                  <TableCell >{row.influenceCount}</TableCell>
                  {/* <TableCell className={classes.buttonCell}>
                    <Button
                      variant="outlined"
                      size="small"
                      // sx={{ mt: pxToRem(40) }}
                      // disabled={userStatus == ""}
                      // onClick={updateUserLevel}
                    >
                      <EditIcon /> Rename
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      // sx={{ mt: pxToRem(40) }}
                      // disabled={userStatus == ""}
                      // onClick={updateUserLevel}
                    >
                      <ShareIcon /> Share
                    </Button>
                    <Button
                      variant="outlined"
                      size="small"
                      // sx={{ mt: pxToRem(40) }}
                      // disabled={userStatus == ""}
                      // onClick={updateUserLevel}
                    >
                      <DeleteIcon /> Delete
                    </Button>
                  </TableCell> */}
                </TableRow>
              </>
            ))}
          </TableBody>
        </Table>
        {Math.ceil(IReportTypeContext.total / 10) > 1 && <Pagination count={Math.ceil(IReportTypeContext.total / 10)} variant="outlined" color="primary" page={IReportTypeContext.currentPage} onChange={handleChangePage} />}
      </TableContainer>
    </div>
  );
};

export default ProgressOutcomes;
