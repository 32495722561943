import React from "react";
import ModalCloseIconBlack from "../assets/images/icon/modalCloseIconBlack";
import { Box, SxProps, Theme } from "@mui/material";

const CloseButton = ({
    onClick,
    boxStyle = {}
}: {
    onClick?: () => void,
    boxStyle?: SxProps<Theme>;
}) => {
    return <Box sx={{
        cursor: "pointer",
        ...boxStyle
    }} onClick={onClick ? onClick : () => { }}>
        <ModalCloseIconBlack color="#000000" />
    </Box>
}

export default CloseButton