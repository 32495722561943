/*eslint eqeqeq: "off"*/
import React, { useState, useContext, useEffect, useCallback } from "react";
import { styled } from "@mui/material/styles";

import {
  Typography,
  Grid,
  TextField,
  Button,
  Box,
  Dialog,
  Switch,
  Drawer,
  TextareaAutosize,
  FormControlLabel,
  ClickAwayListener,
} from "@mui/material";
import {
  Checkbox,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
} from "@material-ui/core";
import palette from "../../theme/palette";
import GetIdeasIcon from "../../assets/images/icon/getIdeas";
import BottomStepper from "../outcome/BottomStepper";
import IdeasDrawer from "./ideasDrawer";
import { UserTypeContext } from "../../context/userStatus";
import { reflectiveQueService } from "../../services/reflective-questions";
import ModalCloseIcon from "../../assets/images/icon/modalCloseIconBlack";

import {
  addCategoryJourneyAction,
  addRefelectiveAction,
  setUserStatus,
} from "../../store/actions/userTypeAction";
import SnackBarComp from "../../components/snackBar";
import { ButtonLoader } from "../../components/buttonLoader";
import { RefelectiveGetResponse } from "../../interfaces/outcomeModal";
import {
  ProUserStepper,
  UserStepper,
  weekDays,
} from "../../utils/staticDataJson";
import moment from "moment";
import { trackingService } from "../../services/tracking";
import { userService } from "../../services/auth";
import Donts from "../../assets/images/icon/Dots";
import shape from "../../theme/shape";
import useWidth from "../../utils/useWidth";
import _debounce from "lodash/debounce";
import ViewNote from "../../assets/images/icon/ViewNote";
import Wellbeing from "../outcome/Wellbeing";
import { useNavigate } from "react-router-dom";
import StepperBackArrowDisable from "../../assets/images/icon/stepperBackArrowDisable";
import StepperBackArrow from "../../assets/images/icon/stepperBackArrow";
import StepperNextArrowDisable from "../../assets/images/icon/stepperNextArrowDisable";
import StepperNextArrow from "../../assets/images/icon/stepperNextArrow";
import { pxToRem } from "../../theme/typography";
import clsx from "clsx";
import RightSideRefQuesTracking from "../tracker/rightSideRefQuesTracking";
import ArchiveModal from "../tracker/archiveConfirmationModal";
import HTMLTooltip from "../../components/HTMLTooltip";
import ArchiveIcon from "../../assets/images/icon/ArchiveIcon";
import NewButton from "../../components/NewButton";
import Info from "../../assets/images/icon/Info";
import InfoIcon from "../../assets/images/icon/infoIconYellow";
const RootStyle = styled("div")(({ theme }) => ({
  padding: "32px",
  display: "flex",
  position: "relative",
  flexDirection: "column",
  alignItems: "flex-start",
}));
interface switchProps {
  onChange: any;
  checked: boolean;
}
export const IOSSwitch = styled((props: switchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 44,
  height: 24,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    position: "absolute !important",
    // margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor:
          theme.palette.mode === "dark" ? "#2ECA45" : "var(--color-new-main)",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
      "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 16,
        height: 16,
        backgroundColor: "#fff",
        margin: "4px",
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 16,
    height: 16,
    backgroundColor: "var(--color-new-main)",
    margin: "4px",
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor:
      theme.palette.mode === "light" ? palette.common.white : "#39393D",
    border: `1px solid var(--color-new-main)`,
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
const useStyles = makeStyles((theme) => ({
  registrationHeading: {
    color: palette.primary.light,
  },
  headerTitleDrawer: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    width: "100%",
    borderBottom: "1px solid #F3F3F3",
    background: "#FFF",
    padding: "24px 32px",
    gap: "16px",
    alignSelf: "stretch",
  },
  drawerBody: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
  },
  headerTitle: {
    display: "flex",
    alignItems: "center",
    alignSelf: "stretch",

    justifyContent: "space-between",
  },
  pageTitle: {
    marginTop: "40px!important",
    marginBottom: "48px!important",
    [theme.breakpoints.down("lg")]: {
      marginTop: "30px!important",
      marginBottom: "38px!important",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "20px!important",
      marginBottom: "28px!important",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "15px!important",
      marginBottom: "20px!important",
    },
  },
  needSomeIdeas: {
    fontSize: "16px",
    lineHeight: "16px",
    color: palette.primary.main,
    marginLeft: "8px",
    [theme.breakpoints.down("md")]: {
      marginTop: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "14px",
    },
  },
  weekDayBox: {
    borderRadius: "8px",
    border: "1px solid #F3F3F3",
    background: "#FFF",

    "& p": {
      fontSize: "18px",
      lineHeight: "36px",
      color: "#3E4240",
      backgroundColor: "#F7F9FA",
      border: "1px solid #DCE6E0",
      width: "36px",
      height: "36px",
      textAlign: "center",
      cursor: "pointer",
      "&.active": {
        backgroundColor: "#11734A",
        color: "#F7F9FA",
      },
    },
  },
  ideasListItem: {
    backgroundColor: palette.common.white,
    borderRadius: shape.borderRadius,
    padding: "24px",
    fontSize: "16px",
    lineHeight: "16px",
    color: palette.primary.light,
    fontWeight: 400,
    marginBottom: "12px",
    cursor: "pointer",
  },
  iconRound: {
    display: "flex",
    // width: '44px',
    // height: '44px',
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "100px",
    background: "#FFF",
    "& path": {
      stroke: "var(--color-new-main)",
      strokeWidth: 2,
      //      fill: theme.palette.secondary[50],
    },
  },
  weekBox: {
    display: "flex",
    width: "44px",
    height: "44px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRight: "1px solid #F3F3F3",
    background: "#FFF",
    textTransform: "capitalize",
    fontSize: `${pxToRem(12)}`,
    color: "#999999",
    "&.active": {
      background: "var(--color-new-main)",
      color: "var(--button-active-text)",
      borderRight: `1px solid var(--color-new-main)`,
    },
  },
  closeButton: {
    width: "20px",
    height: "20px",
    position: "absolute",
    right: "24px",
    top: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: 0,
  },
  textarea: {
    width: "100%",
    padding: "20px",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 400,
    "&:focus-visible": {
      outline: "red",
    },
  },
}));

const ariaLabel = { "aria-label": "description" };

interface LeftSideReflectiveQueModal {
  setQuestionData: (questionData: any) => void;
  questionData: any;
  reflectiveIdeas: any;
  reflectiveIdeasData?: any;
  showRefQues: boolean;
  setAddQuesTracking: (addQuesTracking: boolean) => void;
  setShowQues: (showQues: boolean) => void;
  setWeekDay: any;
  weekday;
  handleCloseStartModal?: any;
  getReflectiveList?: any;
  categoryId?: any;
  width?: any;
  setShowSnakbar?: any;
  setSnakbarMsg?: any;
  setMsgType?: any;
  startDate?: any;
  endDate?: any;
  setNeedIdeasOpen: any;
  handleCloseStartModalQues: (addQuesTracking: boolean) => void;
  setLimitReachPopupVisible: any;
  setLimitReachPopuptype: any;
  isMobile?: boolean;
}

const LeftSideReflectiveQueNew = (props: LeftSideReflectiveQueModal) => {
  const classes = useStyles();
  const [question, setQuestion] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);
  // const [showSnakbar, setShowSnakbar] = useState(false);
  // const [snakbarMsg, setSnakbarMsg] = useState('');
  // const [msgType, setMsgType] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [activeWeek, setActiveWeek] = useState(
    Array.from(props?.weekday, ([day, dayVal]) => ({ day }))
  );
  const [showDay, setShowDay] = useState(false);
  const [outcomeDesc, setOutcomeDesc] = useState("");
  const [openTooltip, setOpenTooltip] = useState(true)
  const [archiveConfirmationModal, setArchiveConfirmationModal] =
    useState(false);
  const [showIdeasMobile, setShowIdeasMobile] = useState(false);
  const [openRightSideSuggestion, setOpenRightSideSuggestion] = useState(false);
  const userLevels = JSON.parse(localStorage.getItem("userValues"));
  const [activeStep, setActiveStep] = React.useState(true ? 2 : false ? 1 : 0);
  const [skipMetric, setSkipMetric] = useState(false);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };
  useEffect(() => {
    return () => setOpenTooltip(false)
  }, [])
  const userType = userLevels?.level == "HIGH" ? ProUserStepper : UserStepper;
  const {
    state: outcomeState,
    state: IUserTypeState,
    dispatch: userTypeDispatch,
  } = useContext(UserTypeContext);
  const tempWeekArr = [];
  const compareDate = parseInt(moment().format("D"));
  const startDay = props.startDate
    ? moment(props.startDate)
    : compareDate < 10
      ? moment().clone().startOf("month")
      : moment().clone().subtract(10, "day");
  const endDay = props.endDate
    ? moment(props.endDate)
    : compareDate < 10
      ? moment().clone().endOf("month")
      : moment().clone().add(10, "day");

  useEffect(() => {
    setOpenDrawer(false);
    setQuestion(props.questionData?.question);
    setOutcomeDesc(props?.questionData?.description);
    setSkipMetric(props?.questionData?.skipMetric)
    setWeekDayArr();
    setActiveWeek(
      props.questionData?.weekDays ? props.questionData?.weekDays : []
    );
    props.questionData?.weekDays &&
      props.questionData?.weekDays?.length > 0 &&
      setShowDay(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.questionData]);

  const [suggestions, setSuggestions] = useState([]);
  const [openWellbeing, setopenWellbeing] = useState(false);

  const navigate = useNavigate();

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if (userLevels?.level == "HIGH") {
      if (activeStep != userType.length - 1) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    let nextStep = activeStep + 1;
    setSkipped(newSkipped);
    handleSliderNextValue(nextStep);
  };

  const handleSliderNextValue = (step: number) => {
    if (userLevels?.level == "HIGH") {
      if (step == 3) {
        userTypeDispatch(setUserStatus(true));
        navigate("/tracking", { replace: true });
      }
      if (step == 2) {
        navigate("/reflective-questions", { replace: true });
      } else if (step == 1) {
        navigate("/influence", { replace: true });
      }
    } else {
      if (step == 1) {
        navigate("/influence", { replace: true });
      }
      if (step == 2) {
        userTypeDispatch(setUserStatus(true));
        navigate("/tracking", { replace: true });
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    const backStep = activeStep - 1;
    handleSliderValue(backStep);
  };

  const handleSliderValue = (step: number) => {
    if (userLevels?.level == "HIGH") {
      if (step == 1) {
        navigate("/influence", { replace: true });
      } else if (step == 0) {
        navigate("/outcome", { replace: true });
      }
    } else {
      if (step === 0) {
        navigate("/outcome", { replace: true });
      }
    }
  };

  const handleChangeWithLib = (value) => {
    let bodyData = {
      skip: 0,
      limit: 20,
      search: value,
    };
    trackingService
      .resSearch(outcomeState.token, bodyData)
      .then((res) => setSuggestions(res.data));
  };

  const debounceFn = useCallback(_debounce(handleChangeWithLib, 500), []);

  const handleCloseStartModal = () => {
    setShowIdeasMobile(false);
  };

  const handleChangeQuestion = (e: any) => {
    setQuestion(e.target.value);
    debounceFn(e.target.value);
  };

  const setWeekDayArr = () => {
    props?.questionData?.weekDays?.map((day) => {
      return props?.setWeekDay(props?.weekday.set(day?.day, day?.day));
    });
  };
  const setWeekDayArrClick = (day) => {
    if (props?.weekday.has(day?.day)) {
      props?.weekday.delete(day?.day);
    } else {
      props?.setWeekDay(props?.weekday.set(day?.day, day?.day));
    }
  };
  const callService = async () => {
    try {
      const bodyData = {
        from: moment(startDay).format("MM-DD-YYYY"),
        to: moment(endDay).format("MM-DD-YYYY"),
      };
      if (outcomeState?.isPersonal) {
        let data = await trackingService.getPersonalJourneyList(
          outcomeState.token,
          bodyData
        );
        userTypeDispatch(addCategoryJourneyAction(data?.data));
      }
    } catch (error) {
      console.log("jourey list error");
    }
  };

  // ADD REFLECTIVE QUESTION
  const AddQuestion = () => {
    for (const [value] of props?.weekday) {
      if (showDay) {
        tempWeekArr.push({ day: value });
      }
    }
    setShowLoader(true);
    let refelctiveRes;
    if (props.questionData?.uid) {
      refelctiveRes = {
        question: question,
        description: outcomeDesc,
        reflectiveId: props?.questionData?.reflectiveId,
        weekDays: tempWeekArr,
        skipMetric: skipMetric,
        // mixpanel_type: "INSIDE_TRACKING"
      };
    } else {
      refelctiveRes = {
        question: question,
        description: outcomeDesc,
        weekDays: tempWeekArr,
        categoryId: props?.categoryId,
        mixpanel_type: "INSIDE_TRACKING",
        skipMetric: skipMetric,
      };
    }
    if (props.questionData?.uid) {
      // EDIT QUESTION
      reflectiveQueService
        .editRefelective(refelctiveRes, outcomeState.token)
        .then((response: any) => {
          reflectiveQueService
            .getReflectiveList(outcomeState.token)
            .then((data: RefelectiveGetResponse) => {
              // userTypeDispatch(addRefelectiveAction(data?.data));
              // callService();
              if (IUserTypeState.isPersonal) {
                const journeyList = [...outcomeState?.journeyCategoryList];
                const foudnInJourney = journeyList.findIndex((it) =>
                  it.reflectiveIds.includes(props?.questionData?.reflectiveId)
                );
                if (foudnInJourney > -1) {
                  const foundInOutcomes = journeyList[
                    foudnInJourney
                  ].alls.findIndex(
                    (it) =>
                      it.reflectiveId === props?.questionData?.reflectiveId
                  );
                  if (foundInOutcomes > -1) {
                    journeyList[foudnInJourney].alls[foundInOutcomes] = {
                      ...journeyList[foudnInJourney].alls[foundInOutcomes],
                      ...refelctiveRes,
                    };
                    userTypeDispatch(addCategoryJourneyAction(journeyList));
                  }
                } else {
                  const foudnOtherJourney = journeyList.findIndex(
                    (it) => it.name === "Other"
                  );
                  if (foudnOtherJourney > -1) {
                    const foundInOutcomes = journeyList[
                      foudnOtherJourney
                    ].reflectives.findIndex(
                      (it) =>
                        it.reflectiveId === props?.questionData?.reflectiveId
                    );
                    if (foundInOutcomes > -1) {
                      journeyList[foudnOtherJourney].reflectives[
                        foundInOutcomes
                      ] = {
                        ...journeyList[foudnOtherJourney].reflectives[
                        foundInOutcomes
                        ],
                        ...refelctiveRes,
                      };
                      userTypeDispatch(addCategoryJourneyAction(journeyList));
                    }
                  }
                }
              } else {
                const allOutcomes = [...outcomeState?.reflectiveList];
                const foundOutcomeIndex = allOutcomes.findIndex(
                  (it) => it.reflectiveId === props?.questionData?.reflectiveId
                );
                if (foundOutcomeIndex > -1) {
                  allOutcomes[foundOutcomeIndex] = {
                    ...allOutcomes[foundOutcomeIndex],
                    ...refelctiveRes,
                  };
                  userTypeDispatch(addRefelectiveAction(allOutcomes));
                }
              }
              props.setShowQues(false);
              setQuestion("");
              if (props?.isMobile) {
                props?.handleCloseStartModalQues(false);
              }
              setOutcomeDesc("");
              props.setQuestionData("");
              setActiveWeek([]);
              props?.setWeekDay(new Map());
              setShowLoader(false);
              props?.setShowSnakbar(false);
              props?.setSnakbarMsg(response.message);
              props?.setMsgType("success");
            });
        })
        .catch((error) => {
          setShowLoader(false);
          props?.setShowSnakbar(true);
          props?.setSnakbarMsg(
            error?.response?.data?.description
              ? error?.response?.data?.description
              : error.message
          );
          props?.setMsgType("error");
        });
    } else {
      // CREATE QUESTION
      reflectiveQueService
        .createRefelective(refelctiveRes, outcomeState.token)
        .then((response: any) => {
          outcomeState.reflectiveList.push(response?.data);
          const bodyData = {
            from: moment(startDay).format("MM-DD-YYYY"),
            to: moment(endDay).format("MM-DD-YYYY"),
          };
          trackingService
            .getPersonalJourneyList(outcomeState.token, bodyData)
            .then((data) => {
              userTypeDispatch(addCategoryJourneyAction(data?.data));
              userTypeDispatch(
                addRefelectiveAction(outcomeState.reflectiveList)
              );
              let days = 0;
              const createDate = outcomeState.userDetails.createdAt;
              const diff = moment().diff(moment(createDate), "days");
              if (diff !== 0 && diff < 30) {
                days = 30 - diff;
              } else if (diff === 0) {
                days = 30 - diff;
              }
              if (
                days > 0 &&
                outcomeState.reflectiveList &&
                outcomeState.reflectiveList.length >= 3 &&
                outcomeState.userDetails.level === "LOW"
              ) {
                props?.setLimitReachPopupVisible(true);
                props?.setLimitReachPopuptype("question");
              }
              // props.setAddQuesTracking(false);
              setQuestion("");
              setOutcomeDesc("");
              props.setQuestionData("");
              setActiveWeek([]);
              props?.setWeekDay(new Map());
              setShowLoader(false);
              props?.setShowSnakbar(true);
              props?.setSnakbarMsg(response.message);
              props?.setMsgType("success");
            });
          // setTimeout(() => {

          // }, 1000);
        })
        .catch((error) => {
          setShowLoader(false);
          props?.setShowSnakbar(true);
          props?.setSnakbarMsg(
            error?.response?.data?.description
              ? error?.response?.data?.description
              : error.message
          );
          props?.setMsgType("error");
        });
    }
  };
  const activeWeekDay = (argDay) => {
    if (activeWeek?.some((day) => day?.day == argDay.day)) {
      const tempArray = [
        ...activeWeek.filter((item) => item.day != argDay?.day),
      ];
      setActiveWeek(tempArray);
    } else {
      setActiveWeek([...activeWeek, { day: argDay?.day }]);
    }
  };
  const showDrawer = () => {
    setOpenDrawer(true);
  };
  const handleCloseSnakbar = () => {
    props?.setShowSnakbar(false);
  };

  const ITEM_HEIGHT = 48;
  const [anchorElTwo, setAnchorElTwo] = React.useState<null | HTMLElement>(
    null
  );
  const openMenu = Boolean(anchorElTwo);
  const handleClickAnchorElTwo = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElTwo(event.currentTarget);
  };
  const handleCloseAnchorElTwo = () => {
    setAnchorElTwo(null);
  };

  const onArchiveReflactive = () => {
    const token = localStorage.getItem("userDetails");
    userService
      ?.archiveReflectiveData(
        {
          archive: true,
          reflectiveId: props?.questionData?.reflectiveId,
        },
        token
      )
      .then((res) => {
        props?.setShowSnakbar(true);
        props?.setSnakbarMsg(res.message);
        props?.setMsgType("success");
        // setShowOutcome(false);
        // setAddOutcomeTracking(true);
        window.setTimeout(() => {
          props?.handleCloseStartModal && props?.handleCloseStartModal();
          props?.handleCloseStartModalQues &&
            props?.handleCloseStartModalQues(false);
          props.getReflectiveList && props.getReflectiveList();
        }, 1000);
      })
      .catch((error) => {
        props?.setShowSnakbar(true);
        props?.setSnakbarMsg(
          error?.response?.data?.description
            ? error?.response?.data?.description
            : error.message
        );
        props?.setMsgType("error");
      });
  };

  const width = useWidth();
  const searchBarProps = {
    // make sure all required component's inputs/Props keys&types match
    onChange: (e) => {
      setShowDay(!showDay);
      // setCheckedSwitch(e.target.checked)
      // if(!e.target.checked) {
      //   reminderCreate()

      // }
    },
    checked: showDay,
  };
  const renderIcon = (icon, onClick = () => { }) => (
    <div className={classes.iconRound} onClick={onClick}>
      {icon}
    </div>
  );
  return (
    <Grid
      container
      columns={16}
      sx={{ backgroundColor: palette.secondary.light }}
    >
      <Grid
        sx={{ backgroundColor: palette.common.white }}
        item
        xs={16}
        className="leftside-menu  outcome-screen "
      >
        {!props?.isMobile && (
          <div className={classes.drawerBody}>
            <div className={classes.headerTitleDrawer}>
              <div className={classes.headerTitle}>
                <Typography
                  sx={{
                    color: "#121213",
                    fontSize: "28px!important",
                    fontWeight: 700,
                  }}
                >
                  {props.questionData?.uid
                    ? "Edit qualitative reflection"
                    : "Add new qualitative reflection"}
                </Typography>
                {!openRightSideSuggestion && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "6px",
                      cursor: "pointer",
                    }}
                  >
                    {props.questionData?.uid ? null : (
                      <div
                        style={{ display: "flex", alignItems: "center" }}
                        onClick={() => {
                          setOpenRightSideSuggestion(true);
                          props.setNeedIdeasOpen &&
                            props.setNeedIdeasOpen(true);
                        }}
                      >
                        <GetIdeasIcon />
                        <Typography
                          sx={{
                            color: "var(--color-new-text)",
                            fontSize: "16px!important",
                            fontWeight: 500,
                          }}
                        >
                          Need some ideas?
                        </Typography>
                      </div>
                    )}

                    {props.questionData?.uid && (
                      <div>
                        <IconButton
                          // aria-label="more"
                          // id="long-button"
                          // aria-controls={open ? "long-menu" : undefined}
                          // aria-expanded={open ? "true" : undefined}
                          // aria-haspopup="true"
                          // onClick={handleClick}
                          onClick={() => {
                            setopenWellbeing(true);
                          }}
                        >
                          <HTMLTooltip
                            customIcon={renderIcon(<ViewNote />, () => { })}
                            title="View all answers compactly"
                            fontProps={{
                              fontSize: `${pxToRem(12)} !important`,
                            }}
                            sx={{
                              "& .MuiTooltip-tooltip": {
                                maxWidth: "300px",
                              },
                            }}
                          />
                        </IconButton>
                        <IconButton
                          aria-label="more"
                          id="long-button"
                          aria-controls={openMenu ? "long-menu" : undefined}
                          aria-expanded={openMenu ? "true" : undefined}
                          aria-haspopup="true"
                          onClick={() => setArchiveConfirmationModal(true)}
                        >
                          <HTMLTooltip
                            customIcon={renderIcon(<ArchiveIcon />, () => { })}
                            title="Archive"
                            fontProps={{
                              fontSize: `${pxToRem(12)} !important`,
                            }}
                            sx={{
                              "& .MuiTooltip-tooltip": {
                                maxWidth: "300px",
                              },
                            }}
                          />
                        </IconButton>
                        <Menu
                          id="long-menu"
                          MenuListProps={{
                            "aria-labelledby": "long-button",
                          }}
                          anchorEl={anchorElTwo}
                          open={openMenu}
                          onClose={handleCloseAnchorElTwo}
                          PaperProps={{
                            style: {
                              maxHeight: ITEM_HEIGHT * 4.5,
                              width: "20ch",
                            },
                          }}
                        >
                          <MenuItem
                            /* onClick={onArchiveReflactive} */ onClick={() =>
                              setArchiveConfirmationModal(true)
                            }
                          >
                            Archive this metric
                          </MenuItem>
                        </Menu>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <Typography
                sx={{
                  color: palette.common.black,
                  fontSize: `${pxToRem(16)}!important`,
                  fontWeight: 400,
                }}
              >
                {
                  "Select up to 3* reflective journaling questions that you think will illuminate the numbers."
                }
              </Typography>
            </div>
          </div>
        )}
        <RootStyle
          style={{
            width: `${openRightSideSuggestion ? "calc(100% - 280px)" : "100%"}`,
          }}
        >
          <Grid
            display={"flex"}
            flexDirection={"column"}
            gap={"16px"}
            paddingBottom={props.isMobile ? "0px" : `24px`}
            alignItems={"flex-start"}
            width={"100%"}
            sx={{
              borderBottom: props.isMobile ? "none" : "1px solid #F3F3F3",
              // position: "relative"
            }}
          >
            <Typography
              variant="caption"
              component="div"
              sx={{
                color: palette.common.black,
                fontSize: "16px!important",
                fontWight: 500,
                lineHeight: "100%",
                width: "100%",
                "&.MuiTypography-root": {
                  "@media  (max-width: 786px) and (min-width: 100px)": {
                    fontSize: `${pxToRem(16)} !important`,
                    lineHeight: "135% !important",
                  },
                },
              }}
            >
              {props.isMobile ? "Define reflective journaling question" : "Define reflect journaling questions to help assess your journey"}
            </Typography>
            {props?.isMobile && props.questionData?.uid ? (
              <IconButton
                style={{
                  position: "absolute",
                  top: "8px",
                  right: "30px",
                  padding: "0px",
                }}
                onClick={() => setArchiveConfirmationModal(true)}
              >
                <HTMLTooltip
                  customIcon={renderIcon(<ArchiveIcon />, () => { })}
                  title="Archive"
                  fontProps={{
                    fontSize: `${pxToRem(12)} !important`,
                  }}
                  sx={{
                    "& .MuiTooltip-tooltip": {
                      maxWidth: "300px",
                    },
                  }}
                />
              </IconButton>
            ) : null}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
                width: "100%",
                paddingBottom: "8px",
              }}
            >
              <TextField
                sx={{
                  "& .MuiTextField-root": {
                    marginBottom: "24px !important",
                  },
                  "& .MuiOutlinedInput-root": {
                    alignItems: "center",
                    gap: "8px",
                    borderRadius: "12px",
                    border: "1px solid #EDEDED",
                    background: "#FFF",
                    "& .MuiOutlinedInput-input": props?.isMobile ? {
                      color: "#000!important",
                      border: "1px solid var(--color-svg)",
                      borderRadius: "8px",
                      "&::placeholder": {
                        fontSize: `${pxToRem(14)}`,
                        // color: "#B7BDB9"
                      }
                    } : {
                      color: "#000!important",
                    },
                  },
                }}
                placeholder="Add a self qualitative reflection"
                variant="outlined"
                name="influence"
                fullWidth
                value={question || ""}
                onChange={(e) => handleChangeQuestion(e)}
                inputProps={ariaLabel}
              />
              <div
                style={{
                  backgroundColor: "aliceblue",
                  borderRadius: "8px",
                  fontSize: "16px",
                  maxHeight: "120px",
                  overflow: "auto",
                  position: "absolute",
                  width: "100%",
                  top: "55px",
                  zIndex: 10,
                  color: "black",
                }}
              >
                {question?.length !== 0 &&
                  suggestions?.map((suggestion) => {
                    return (
                      <div
                        style={{ padding: "8px", cursor: "pointer" }}
                        onClick={() => {
                          props.setQuestionData(suggestion);
                          setSuggestions([]);
                        }}
                      >
                        {suggestion.question}
                      </div>
                    );
                  })}
              </div>
            </div>
          </Grid>
          <Grid
            display={"flex"}
            flexDirection={"column"}
            gap={"16px"}
            padding={`24px 0`}
            alignItems={"flex-start"}
            justifyContent={"space-between"}
            width={"100%"}
            sx={{
              borderBottom: props.isMobile ? "none" : "1px solid #F3F3F3",
            }}
          >
            <Grid
              display={"flex"}
              flexDirection={"row"}
              gap={"16px"}
              alignItems={"flex-start"}
              justifyContent={"space-between"}
              width={"100%"}
            >
              <Typography
                variant="caption"
                component="span"
                sx={{
                  color: palette.common.black,
                  fontSize: "16px!important",
                  fontWight: 500,
                  lineHeight: "100%",
                }}
              >
                Schedule this reflection
              </Typography>
              <IOSSwitch {...searchBarProps} />
            </Grid>

            {showDay && (
              <>
                <Box
                  display="flex"
                  alignItems="flex-start"
                  padding={`20px`}
                  flexDirection={"column"}
                  gap={"8px"}
                  borderRadius="12px"
                  border={`1px solid #EDEDED`}
                  width={"100%"}
                  sx={{
                    overflowX: "auto",
                  }}
                  className="hide-scrollbar"
                // background='#FFF'
                >
                  <Box
                    display="flex"
                    alignItems="flex-start"
                    className={classes.weekDayBox}
                  >
                    {weekDays?.map((day, index: number) => {
                      return (
                        <span
                          onClick={() => {
                            setWeekDayArrClick(day);
                            activeWeekDay(day);
                          }}
                          className={clsx(
                            classes.weekBox,
                            Array.from(props?.weekday, ([day]) => ({
                              day,
                            }))?.some((day1) => day1?.day === day?.day)
                              ? "active"
                              : ""
                          )}
                          key={index}
                        >
                          {day?.day?.split("").splice(0, 3).join("")}
                        </span>
                      );
                    })}
                  </Box>

                  <Typography variant="caption" component="div">
                    {props?.weekday?.size > 0 ? (
                      <>
                        = {props?.weekday?.size} Times per week every{" "}
                        {Array.from(props?.weekday, ([day]) => ({ day }))
                          .map(
                            (it) =>
                              it.day.charAt(0).toUpperCase() +
                              it.day.slice(1, it.day.length).toLowerCase()
                          )
                          .join(", ")}
                      </>
                    ) : (
                      ""
                    )}
                  </Typography>
                </Box>
              </>
            )}
          </Grid>
          <br />
          <FormControlLabel
            value={skipMetric}
            // disabled={
            //   props.influenceData?.uid &&
            //   props.influenceData?.type === 'TYPE1'
            // }
            control={
              <Checkbox
                // disabled={props.influenceData?.uid}
                checked={[true, "true"].includes(skipMetric)}
              />
            }
            sx={{
              "& .MuiFormControlLabel-label": {
                color: "#3E4240",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "100%" /* 16px */,
              },
              "& .Mui-checked": {
                color: "var(--color-new-main) !important",
              },
              "& .MuiCheckbox-root": {
                padding: "9px !important",
              },
              "&.MuiFormControlLabel-root": props?.isMobile
                ? {
                  marginTop: "10px !important",
                }
                : {},
            }}
            label={
              <Box display={"flex"} alignItems={"center"} gap={"8px"}>
                <span>Skip metric</span>{" "}
                {width < 786 ? (
                  <ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
                    <IconButton
                      style={{
                        padding: "0px",
                        height: "20px",
                        width: "20px"
                      }}

                      onClick={() => setOpenTooltip(!openTooltip)}
                    >
                      <HTMLTooltip customIcon={<div>
                        <InfoIcon color={"var(--color-new-main)"} size={"14"} />
                      </div>} title={"Activate to automatically move to the next metric when you press enter"} fontProps={{
                        fontSize: `${pxToRem(12)} !important`,
                      }} sx={{
                        "& .MuiTooltip-tooltip": {
                          maxWidth: "300px",
                        },
                      }} customProps={{
                        placement: "bottom-end",
                        open: openTooltip
                      }} />
                    </IconButton>
                  </ClickAwayListener>
                ) : <IconButton
                  style={{
                    padding: "0px",
                    height: "20px",
                    width: "20px",
                  }}
                //   onClick={() => setArchiveConfirmationModal(true)}
                >
                  <HTMLTooltip
                    customIcon={
                      <div>
                        <InfoIcon color={"var(--color-new-main)"} size={"14"} />
                      </div>
                    }
                    title="Activate to automatically move to the next metric when you press enter"
                    fontProps={{
                      fontSize: `${pxToRem(12)} !important`,
                    }}
                    sx={{
                      "& .MuiTooltip-tooltip": {
                        maxWidth: "300px",
                      },
                    }}
                  />
                </IconButton>}
              </Box>
            }
            onChange={(e: any) => {
              setSkipMetric(e.target.checked);
            }}
          />
          <Grid
            display={"flex"}
            flexDirection={"column"}
            gap={"16px"}
            paddingTop={`24px`}
            alignItems={"flex-start"}
            width={"100%"}
          >
            <Typography
              variant="caption"
              component="div"
              // className={classes.pageTitle}
              sx={{
                color: palette.common.black,
                fontSize: "16px!important",
                fontWight: 500,
                lineHeight: "100%",
              }}
            >
              Please, describe why you want to reflect on this question
            </Typography>
            <TextareaAutosize
              // variant="outlined"
              className={"textarea " + classes.textarea}
              // fullWidth
              placeholder="Add a description..."
              // multiline
              minRows={4}
              // sx={{
              //   '& .MuiOutlinedInput-root': {
              //     minHeight: '96px',
              //     resize: "auto",
              //     padding: '20px',
              //     // gap: '12px',
              //     // flex: '1 0 0',
              //     borderRadius: '12px !important',
              //     border: '1px solid #EDEDED',
              //     background: '#FFF',
              //     '& .MuiInputBase-input': {
              //       // padding: `0px !important`,
              //       resize: "auto",

              //     }
              //   },
              // }}
              onChange={(e) => setOutcomeDesc(e.target.value)}
              value={outcomeDesc}
            />
          </Grid>
          <Grid
            display="flex"
            // alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
            sx={{
              flexDirection: width > 768 ? "row" : "column",
              alignItems: width > 768 ? "center" : "left",
            }}
          >
            {/* <Typography variant="caption" component="div">
          Define reflect journaling questions to help assess your journey
        </Typography> */}
            {!props.showRefQues && (
              <Grid
                display="flex"
                alignItems="center"
                sx={{
                  cursor: "pointer",
                  marginTop: width > 768 ? "auto" : "10px",
                }}
                onClick={() => {
                  showDrawer();
                  setShowIdeasMobile(true);
                }}
              >
                <GetIdeasIcon />
                <span
                  className={classes.needSomeIdeas}
                  style={{ marginTop: width > 768 ? "10px" : "0px" }}
                >
                  Need some ideas?
                </span>
              </Grid>
            )}
            {/* RIGHT SIDE DRAWER */}
            {!props.showRefQues ||
              (width > 768 && (
                <IdeasDrawer
                  openDrawer={openDrawer}
                  setOpenDrawer={setOpenDrawer}
                  reflectiveIdeas={props.reflectiveIdeas}
                  setQuestionData={props.setQuestionData}
                />
              ))}
            {showIdeasMobile && width < 769 && (
              <Dialog
                fullScreen
                disableEscapeKeyDown
                onClose={handleCloseStartModal}
                open={showIdeasMobile}
                style={{
                  height: "60%",
                  bottom: 0,
                  top: "auto",
                  borderRadius: "35px 35px 0px 0px",
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    backgroundColor: "#11734A",
                    padding: "20px",
                    paddingTop: "60px",
                    color: "#fff",
                    overflow: "auto",
                  }}
                >
                  <Grid
                    container
                    sx={{ backgroundColor: palette.primary.main }}
                  // className={classes.drawerWrapper}
                  >
                    <Grid>
                      <Typography
                        variant="h2"
                        component="h2"
                        sx={{
                          color: palette.common.white,
                          fontWeight: 500,
                          lineHeight: "35px!important",
                        }}
                      >
                        Our ideas of reflective questions
                      </Typography>
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{
                          color: palette.common.white,
                          fontWeight: 400,
                          lineHeight: "35px!important",
                        }}
                      >
                        Choose one of our recommended reflective questions
                      </Typography>
                    </Grid>
                    <Grid
                      // className={classes.ideasList}
                      sx={{ width: "100%", mt: "40px" }}
                    >
                      {props?.reflectiveIdeas?.map((ideas: any) => {
                        return (
                          <div
                            className={classes.ideasListItem}
                            key={ideas._id}
                            onClick={() => {
                              props.setQuestionData(ideas);
                              setShowIdeasMobile(false);
                            }}
                          >
                            {ideas.question}
                          </div>
                        );
                      })}
                    </Grid>
                  </Grid>
                </div>
              </Dialog>
            )}
          </Grid>
          {!props.isMobile ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                padding: "32px 32px 32px 0px",
                alignItems: "flex-end",
                gap: "12px",
              }}
            >
              {!props.showRefQues && (
                <Button
                  className="no-after"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1, color: palette.primary.main, p: 0 }}
                  style={{ display: width > 768 ? "none" : "flex" }}
                >
                  {activeStep === 0 ? (
                    <StepperBackArrowDisable />
                  ) : (
                    <StepperBackArrow />
                  )}
                </Button>
              )}
              <NewButton
                type="submit"
                isDisabled={question === undefined && tempWeekArr.length === 0}
                buttonText={props.questionData?.uid ? "Update" : "Add"}
                onClick={() => AddQuestion()}
                isLoadong={showLoader}
              />
              {!showLoader ? (
                <NewButton
                  buttonText={"Cancel"}
                  variant="text"
                  onClick={() => props.handleCloseStartModalQues(false)}
                />
              ) : null}
              {!props.showRefQues && (
                <Button
                  className="no-after"
                  onClick={handleNext}
                  sx={{ color: palette.primary.main, p: 0, cursor: "pointer" }}
                  style={{ display: width > 768 ? "none" : "flex" }}
                  disabled={
                    activeStep === userType.length - 1 ||
                    (false
                      ? IUserTypeState?.outcomeList?.length == 0
                      : false
                        ? IUserTypeState?.influenceList?.length == 0
                        : true
                          ? IUserTypeState?.reflectiveList?.length == 0
                          : false)
                  }
                >
                  {activeStep === userType.length - 1 ||
                    (false
                      ? IUserTypeState?.outcomeList?.length == 0
                      : false
                        ? IUserTypeState?.influenceList?.length == 0
                        : true
                          ? IUserTypeState?.reflectiveList?.length == 0
                          : false) ? (
                    <StepperNextArrowDisable />
                  ) : (
                    <StepperNextArrow />
                  )}
                </Button>
              )}
            </div>
          ) : (
            <div style={{
              display: "flex",
              position: "fixed",
              bottom: 0,
              left: 0,
              padding: "10px 20px 20px",
              width: "100%",
              background: palette.common.white,
              zIndex: 1
            }}>
              <NewButton
                type="submit"
                isDisabled={question === undefined && tempWeekArr.length === 0}
                buttonText={(props.questionData?.uid ? "Update" : "Add") + " reflective question"}
                onClick={() => AddQuestion()}
                isLoadong={showLoader}
                boxSx={{
                  display: 'flex',
                  alignSelf: "stretch",
                  width: "100%",
                  justifyContent: "center",
                }}
                extraSx={{
                  display: 'flex',
                  alignSelf: "stretch",
                  width: "100%",
                }}
                typographySx={{
                  "&.MuiTypography-root": {
                    "@media  (max-width: 786px) and (min-width: 100px)": {
                      fontSize: `${pxToRem(18)} !important`,
                      lineHeight: "100% !important",
                      fontWeight: 500,
                      color: palette.common.black
                    },
                  },
                }}
                fontSize="18px"
              />
            </div>
          )}


          <Wellbeing
            open={openWellbeing}
            journeyData={outcomeState?.notesList?.reflective_data}
            setopenWellbeing={setopenWellbeing}
            outcomeValueInput={question}
          />

          {/* BOTTOM STEPPER */}
          {!props.showRefQues && (
            <BottomStepper
              isOutcome={false}
              isInfluence={false}
              isRefQues={true}
              isTracking={false}
            />
          )}
          <ArchiveModal
            open={archiveConfirmationModal}
            handleClose={() => setArchiveConfirmationModal(false)}
            handleSubmit={() => {
              onArchiveReflactive();
              setArchiveConfirmationModal(false);
            }}
            outcomeValueInput={question}
          />
        </RootStyle>
      </Grid>
      <Drawer
        anchor="right"
        variant="persistent"
        sx={{
          position: "relative",
          // marginLeft: "auto",
          // width: 200,
          zIndex: 1300,
          "& .MuiBackdrop-root": {
            display: "none",
          },
          "& .MuiPaper-root": {
            width: "300px !important",
            position: "absolute",
            height: "100%",
            transition: "none !important",
            display: "flex",
            padding: "24px",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "20px",
            alignSelf: "stretch",
            background: "#FAFAFA",
            borderLeft: "1.5px dashed var(--color-new-text)",
          },
        }}
        open={openRightSideSuggestion}
        onClose={() => {
          setOpenRightSideSuggestion(false);
          props.setNeedIdeasOpen && props.setNeedIdeasOpen(false);
        }}
      >
        <RightSideRefQuesTracking
          reflectiveIdeas={props.reflectiveIdeas}
          reflectiveIdeasData={props.reflectiveIdeasData}
          setQuestionData={props.setQuestionData}
          // outcomeIdeas={props.outcomeIdeas}
          // setOutcomeValue={props.setOutcomeValue}
          setOpenRightSideSuggestion={(val) => {
            setOpenRightSideSuggestion(val);
            props.setNeedIdeasOpen && props.setNeedIdeasOpen(false);
          }}
        />
      </Drawer>
    </Grid>
  );
};
export default LeftSideReflectiveQueNew;
