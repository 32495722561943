import React from "react"
import clsx from "clsx";
import { FormControlLabel, makeStyles } from "@material-ui/core";
import CrossIconSwitch from "../../assets/images/icon/CrossIconSwitch";
import RightIconSwitch from "../../assets/images/icon/RightIconSwitch";
import palette from "../../theme/palette";
import PlusIconSVG from "../../assets/images/icon/PlusIcon";


const useStyles = makeStyles((theme) => ({
    container: {
        display: 'inline-flex',
        padding: '2px 4px 4px 4px',
        alignItems: 'center',
        gap: '6px',
        borderRadius: '50px',
        border: "1px solid #3E4240",
        // background: '#EBF2F1',
        height: '40px',
        width: '80px',
        cursor: "pointer"
    },
    background: {
        background: '#fff',
        width: '34px',
        height: '32px',
        // border: "1px solid #3E4240",
        borderRadius: '50px',

        display: 'flex',
        alignItems: "center",
        justifyContent: 'center',
        // borderRadius: '5px'
    },
    emptyIcon: {
        width: '34px',
        height: '32px',
        display: 'flex',
        alignItems: "center",
        justifyContent: 'center'
    },
    corssActive: {
        "& svg": {
            "& path": {
                stroke: `#F17F79 !important`
            }
        }
    },
    corssActiveInverse: {
        "& svg": {
            "& path": {
                stroke: `#A7DE85 !important`
            }
        }
    },
    rightActive: {
        "& svg": {
            "& path": {
                stroke: `#A7DE85 !important`
            }
        }
    },
    rightActiveInverse: {
        "& svg": {
            "& path": {
                stroke: `red !important`
            }
        }
    }

}));
const IconYesNo = ({ matricValue, journalItem, onClick }) => {
    const classes = useStyles()
    if (journalItem.name === "GratitudePracticeGratitudePracticeGratitudePracticeGratitudePractice") {
        console.log('matricValue, journalItem', matricValue, journalItem)

    }
    console.log("journey?.inverse === true", matricValue, matricValue?.inverse)
    return (
        // <FormControlLabel
        //     control={<Android12Switch defaultChecked />}
        //     label=""
        //     style={{
        //         marginRight: 0
        //     }}
        // />
        <div className={classes.container} role="button" onClick={(e) => {
            e.stopPropagation();
            // if (matricValue && Object.keys(matricValue).length > 0) {
            //     onClick(matricValue.levelReach === "No" ? "Yes" : "No", matricValue?.note);
            // } else {
            //     onClick("Yes", []);
            // }
        }}>
            {matricValue && Object.keys(matricValue).length && matricValue.levelReach === "No" ? (
                <div className={clsx(classes.background,matricValue?.inverse ==="true" ? classes.corssActiveInverse : classes.corssActive)} onClick={e => {
                    e.stopPropagation();
                    if (matricValue && Object.keys(matricValue).length > 0) {
                        onClick("No", matricValue?.note);
                    } else {
                        onClick("No", []);
                    }
                }} style={{
                    rotate: "45deg"
                }}>
                    <PlusIconSVG color={"#B7BDB9"}/>
                </div>
            ) : (
                <div className={classes.emptyIcon} onClick={e => {
                    e.stopPropagation();
                    if (matricValue && Object.keys(matricValue).length > 0) {
                        onClick("No", matricValue?.note);
                    } else {
                        onClick("No", []);
                    }
                }} style={{
                    rotate: "45deg"
                }}>
                    <PlusIconSVG color={"#B7BDB9"}/>
                </div>
            )}
            {matricValue?.levelReach !== "No" ? (
                <div className={clsx(classes.background, matricValue?.levelReach === "Yes" ?  matricValue?.inverse ==="true" ? classes.rightActiveInverse :classes.rightActive : classes.emptyIcon)} onClick={e => {
                    e.stopPropagation();
                    if (matricValue && Object.keys(matricValue).length > 0) {
                        onClick("Yes", matricValue?.note);
                    } else {
                        onClick("Yes", []);
                    }
                }}>
                    <RightIconSwitch />
                </div>
            ) : (
                <div className={classes.emptyIcon} onClick={e => {
                    e.stopPropagation();
                    if (matricValue && Object.keys(matricValue).length > 0) {
                        onClick("Yes", matricValue?.note);
                    } else {
                        onClick("Yes", []);
                    }
                }}>
                    <RightIconSwitch />
                </div>
            )}

        </div>
    )
}

export default IconYesNo