import {
  Typography,
  styled,
  AccordionDetails,
  Divider,
  Grid,
  Menu,
  Button,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { UserTypeContext } from "../../context/userStatus";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";

import Calendar from "./calendar";
import ModeSwitchTab from "./ModeSwitchTab";
import PersonalTrackingOutcome from "./PersonalTrackingOutcome";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import ThreeDotsIcon from "../../assets/images/icon/ThreeDotsIcon";
import PersonalTrackingInfluence from "./PersonalTrackingInfluence";
import PersonalTrackingReflective from "./PersonalTrackingReflective";
import { addCategoryExpandAction } from "../../store/actions/userTypeAction";
import ExpandIcon from "../../assets/images/icon/ExpandIcon";
import palette from "../../theme/palette";
import { pxToRem } from "../../theme/typography";
import { useNavigate } from "react-router-dom";
import LimitReachedPopup from "./limitReachedModal";
import { makeStyles, MenuItem } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  trackBody: {
    display: "flex",
    flexDirection: "row",
    // height: "calc(100vh - 20vh)",
    overflowX: "hidden",
    overflowY: "auto",
    background: '#fff',

    // height: "calc(100vh - 185px)",
  },
  trackBodyLeft: {
    padding: "0",
    position: "relative",
    boxShadow: '5px 0px 40px 0px rgba(18, 18, 19, 0.05)',
    // overflow: "auto",
    zIndex: 102,
    [theme.breakpoints.down("sm")]: {
      padding: "0px 10px 10px 10px",
    },
  },
  trackItem: {
    "&:not(:last-child)": {
      marginBottom: "40px",
    },
    minWidth: "30vw",
    maxWidth: '35vw'
  },
  listTitle: {
    fontSize: "22px!important",
    lineHeight: "22px!important",
  },
  modalCloseIcon: {
    position: "absolute",
    right: 0,
    top: 0,
    cursor: "pointer",
    zIndex: 1,
  },
  dropMenu: {
    "& .MuiPaper-root": {
      border: "1px solid #DCE6E0",
      overflow: "visible",
      marginTop: "4px",
      "&:before": {
        content: '""',
        display: "block",
        position: "absolute",
        top: 0,
        right: "42%",
        width: 15,
        height: 15,
        backgroundColor: "#f7f9fa",
        transform: "translateY(-50%) rotate(45deg)",
        zIndex: 1,
        borderTop: "1px solid #DCE6E0",
        borderLeft: "1px solid #DCE6E0",
      },
    },
  },
}));

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  backgroundColor: "transparent",
  "&:before": {
    display: "none",
  },
  '& .MuiAccordionSummary-root': {
    minHeight: 0,
    padding: 0
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(0deg)",
  },
}));

interface PersonalTrackBodyModal {
  createDate: any;
  setCreateDate: (createDate: any) => void;
  handleClickOpen: () => void;
  pshowInfluence: any;
  pshowOutcome: any;
  psetShowOutcome: any;
  psetShowInfluence: any;
  pshowQues: any;
  psetShowQues: any;
  popenStartModal: any;
  psetStartModal: any;
  popenStartModalInfluence: any;
  psetStartModalInfluence: any;
  popenStartModalQues: any;
  psetStartModalQues: any;
  paddOutcomeTracking: any;
  psetAddOutcomeTracking: any;
  paddInfluenceTracking: any;
  psetAddInfluenceTracking: any;
  paddQuesTracking: any;
  psetAddQuesTracking: any;
  setCategoryId: any;
  setTransformToInfluenceTracking?: any;
  transformToInfluenceTracking?: any;
  setTransformToOutcomeTracking?: any;
  transformToOutcomeTracking?: any;
  setTransformInInfluenceTracking?: any;
  transformInInfluenceTracking?: any;
  showLoader?: any;
  setShowLoader?: any;
  startDate: any
  setStartDate: any
  endDate: any
  setEndDate: any
  handleShareModalOpen: any

}
const PersonalTrackBody = (props: PersonalTrackBodyModal) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { state: outcomeState, dispatch: userTypeDispatch } = useContext(UserTypeContext);
  const [showOutcome, setShowOutcome] = useState<boolean>(false);
  const [showInfluence, setShowInfluence] = useState<boolean>(false);
  const [showQues, setShowQues] = useState<boolean>(false);
  const [openStartModal, setStartModal] = useState(false);
  const [openStartModalInfluence, setStartModalInfluence] = useState(false);
  const [openStartModalQues, setStartModalQues] = useState(false);

  const [expandObj, setExpandObj] = useState([]);
  const [expansionStarted, setExpansionStarted] = useState(false);
  const [expandObj2, setExpandObj2] = useState([]);
  const [lastActionId, setLastActionId] = useState(null);
  const [limitPopupVisible, setLimitReachPopupVisible] = useState<boolean>(false);
  const [limitPopuptype, setLimitReachPopuptype] = useState<'outcome' | 'influence' | 'question'>();
  useEffect(() => {
    let arr = [];
    if (outcomeState?.journeyCategoryList && !expandObj.length) {
      outcomeState?.journeyCategoryList?.map((category, index) => {

        arr.push({ id: category.categoryId, expanded: category.expanded });
        return category
      });
      localStorage.setItem(
        "categoryCount",
        `${outcomeState?.journeyCategoryList?.length}`
      );
      setExpandObj(arr);
      setExpandObj2(arr);
      const ids = arr.filter(it => it.expanded).map(it => it.id);
      // runLoopAndExpand([...arr], ids, startDate, endDate)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [outcomeState?.journeyCategoryList]);
  useEffect(() => {
    setExpanded([...expandObj], lastActionId, true)
    // runLoopAndExpand([...expandObj])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandObj])
  const handleChange = (expanded: boolean, categoryId: string) => () => {
    if (!expanded) {
      const newArr = [...outcomeState?.journeyCategoryExpandList, categoryId]
      userTypeDispatch(addCategoryExpandAction(newArr));
    } else {
      const newArr = [...outcomeState?.journeyCategoryExpandList].filter(it => it !== categoryId)
      userTypeDispatch(addCategoryExpandAction(newArr));
    }
    // const index = arr.findIndex((obj) => obj.id === categoryId);
    // setLastActionId(categoryId)
    // arr[index].expanded = !expanded;
    // arr[index].manually = true;
    // setExpandObj(arr);
    // setExpandObj2(arr);
    // setExpanded(arr, categoryId)

  };
  const runLoopAndExpand = (arr, expanded = [], startDate, endDate) => {
    setExpansionStarted(true)
    let allNotExpanded = [...arr].filter(it => !it?.expanded && !it.manually);

    if (expanded.length === arr?.length) {
    }
    if (allNotExpanded[0]?.id) {

      const oldArr = [...(expanded || [])]
      oldArr.push(allNotExpanded[0]?.id)
      expanded = oldArr
      let foundExpanded = arr.findIndex((obj) => !obj.expanded && !obj.manually);
      if (foundExpanded > -1) {
        arr[foundExpanded].expanded = true;
      }
      setTimeout(() => {
        if (startDate === props.startDate && endDate === props.endDate) {
          runLoopAndExpand(arr, oldArr, startDate, endDate)
          userTypeDispatch(addCategoryExpandAction(oldArr));
        }

      }, 800);
    }

  }
  const setExpanded = (arr, categoryId = null, runLoop = false) => {
    let allExpanded = [...arr].filter(it => it?.expanded);
    if (categoryId) {
      allExpanded = allExpanded.filter(it => it.id !== categoryId)
    }

    // if (allExpanded.length > 3) {
    //   let foundExpanded = arr.findIndex((obj) => obj.expanded);
    //   if (categoryId) {
    //     foundExpanded = arr.findIndex((obj) => obj.expanded && obj.id !== categoryId);
    //   }
    //   if (foundExpanded > -1) {
    //     arr[foundExpanded].expanded = false;
    //     setExpandObj(arr);
    //     setExpandObj2(arr);
    //   }
    //   setExpanded([...expandObj], categoryId)
    // } else {

    const ids = arr.filter(it => it.expanded).map(it => it.id);

    // userTypeDispatch(addCategoryExpandAction(ids));
    // userTypeDispatch(addCategoryExpandAction(ids));
    // runLoopAndExpand([...expandObj2], ids)
    // }
  }

  const {
    psetShowOutcome,
    psetStartModal,
    psetAddOutcomeTracking,
    psetShowInfluence,
    psetStartModalInfluence,
    psetAddInfluenceTracking,
    psetShowQues,
    psetStartModalQues,
    psetAddQuesTracking,
    setCategoryId,
  } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>, category: any) => {
    setAnchorEl(event.currentTarget);
    setCategoryId(category?.categoryId);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  return (
    <>
      <div className={`${classes.trackBody} commonTrackBody`} style={{
        position: 'relative'
      }}>
        {/* <List
          height={150}
          itemCount={200}
          itemSize={35}
          width={300}
        > */}
        <Grid item className={`${classes.trackBodyLeft} commonTrackBodyLeft`} sx={{
          boxShadow: outcomeState?.showEmpty && 'none',
          // zIndex: 99,
          zIndex: '102 !important'
        }}  xs={outcomeState?.isExpand ? 0.1 : 5.7}>
          <div style={{
            position: 'sticky',
            top: 0,
            alignSelf: 'flex-start',
            // zIndex: 100,
            background: '#fff',
            zIndex: 1,

            minWidth: /* outcomeState?.showEmpty ? "calc(100% - 760px)" : */ '100%',
            maxWidth: /* outcomeState?.showEmpty ? "calc(100% - 760px)" : */ '100%',
            // height: '100px',
          }}>
            <ModeSwitchTab
              handleClickOpen={props.handleClickOpen}
              handleShareModalOpen={props.handleShareModalOpen}

            />

          </div>
          {!outcomeState?.showEmpty && outcomeState?.journeyCategoryList &&
            outcomeState?.journeyCategoryList?.length > 0 ? (
            <div className={classes.trackItem}>
              {outcomeState?.journeyCategoryList &&
                outcomeState?.journeyCategoryList?.length > 0 &&
                outcomeState?.journeyCategoryList?.map(
                  (category: any, index: number) => {
                    let expand = category?.expanded;

                    return (
                      <Accordion
                        key={index}
                        sx={{ background: "#FFF", backgroundColor: "#FFF" }}
                        elevation={0}
                        expanded={expand === undefined ? true : [...outcomeState?.journeyCategoryExpandList].includes(category.categoryId)}
                        onChange={handleChange([...outcomeState?.journeyCategoryExpandList].includes(category.categoryId), category.categoryId)}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "16px 24px",
                            gap: '8px',
                            flex: '1 0 0',
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px"

                            }}
                          >
                            <MuiAccordionSummary expandIcon={<ExpandIcon />} />

                            <Typography
                              variant="h3"
                              fontWeight={600}
                              fontSize={'1.125rem !important'}
                              className={classes.listTitle}
                              color={palette.common.black}
                            >
                              {category?.name}
                            </Typography>
                          </div>
                          {category?.name !== "Other" && (
                            <span
                              onClick={(e) => handleClick(e, category)}
                              style={{ cursor: "pointer" }}
                            >
                              <ThreeDotsIcon />
                            </span>
                          )}
                          <Menu
                            anchorEl={anchorEl}
                            id="account-menu"
                            open={open}
                            onClose={handleClose}
                            onClick={handleClose}
                            className={classes.dropMenu}
                            PaperProps={{
                              elevation: 0,
                            }}
                            transformOrigin={{
                              horizontal: "center",
                              vertical: "top",
                            }}
                            anchorOrigin={{
                              horizontal: "center",
                              vertical: "bottom",
                            }}
                          >
                            {/* <MenuItem>Rename</MenuItem> */}
                            <MenuItem
                              onClick={() => {
                                psetShowOutcome(true);
                                psetStartModal(true);
                                psetAddOutcomeTracking(true);
                              }}
                            >
                              Add Outcomes
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                psetShowInfluence(true);
                                psetStartModalInfluence(true);
                                psetAddInfluenceTracking(true);
                              }}
                            >
                              Add Influences
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                psetShowQues(true);
                                psetStartModalQues(true);
                                psetAddQuesTracking(true);
                              }}
                            >
                              Add Qualitative Reflection
                            </MenuItem>
                          </Menu>
                        </div>

                        <AccordionDetails sx={{ padding: "0px" }}>
                          {category?.name === "Other" ? (
                            <>
                              {category?.outcomes?.map((outcome, index) => {
                                return (
                                  <PersonalTrackingOutcome
                                    outcome={outcome}
                                    key={index}
                                    setShowOutcome={setShowOutcome}
                                    showOutcome={showOutcome}
                                    openStartModal={openStartModal}
                                    setStartModal={setStartModal}
                                    setTransformToInfluenceTracking={props?.setTransformToInfluenceTracking}
                                    transformToInfluenceTracking={props?.transformToInfluenceTracking}
                                    categoryId={category.categoryId}
                                    startDate={props.startDate}
                                    endDate={props.endDate}
                                    index={index}
                                    dataList={category?.outcomes}
                                    isExpand={outcomeState?.isExpand}
                                    setLimitReachPopupVisible={setLimitReachPopupVisible}
                                  setLimitReachPopuptype={setLimitReachPopuptype}
                                  />
                                );
                              })}
                              {category?.influences?.map((influ, index) => {
                                return (
                                  <PersonalTrackingInfluence
                                    influenceItem={influ}
                                    key={index}
                                    setShowInfluence={setShowInfluence}
                                    showInfluence={showInfluence}
                                    openStartModalInfluence={
                                      openStartModalInfluence
                                    }
                                    setStartModalInfluence={
                                      setStartModalInfluence
                                    }
                                    setTransformToOutcomeTracking={props?.setTransformToOutcomeTracking}
                                    transformToOutcomeTracking={props?.transformToOutcomeTracking}
                                    categoryId={category.categoryId}
                                    transformInInfluenceTracking={props?.transformInInfluenceTracking}
                                    setTransformInInfluenceTracking={props?.setTransformInInfluenceTracking}
                                    startDate={props.startDate}
                                    endDate={props.endDate}
                                    index={index}
                                    dataList={category?.influences}
                                    isExpand={outcomeState?.isExpand}
                                    setLimitReachPopupVisible={setLimitReachPopupVisible}
                                    setLimitReachPopuptype={setLimitReachPopuptype}
                                  />
                                );
                              })}
                              {category?.reflectives?.map((reflective, index) => {
                                return (
                                  <PersonalTrackingReflective
                                    refelectiveItem={reflective}
                                    key={index}
                                    setShowQues={setShowQues}
                                    showQues={showQues}
                                    openStartModalQues={openStartModalQues}
                                    setStartModalQues={setStartModalQues}
                                    startDate={props.startDate}
                                    endDate={props.endDate}
                                    index={index}
                                    isExpand={outcomeState?.isExpand}
                                    dataList={category?.reflectives}
                                    setLimitReachPopupVisible={setLimitReachPopupVisible}
                                  setLimitReachPopuptype={setLimitReachPopuptype}
                                  />
                                );
                              })}
                            </>
                          ) : (
                            category?.alls?.map((all, index) => {
                              return all?.outcomeId ? (
                                <PersonalTrackingOutcome
                                  outcome={all}
                                  key={index}
                                  setShowOutcome={setShowOutcome}
                                  showOutcome={showOutcome}
                                  openStartModal={openStartModal}
                                  setStartModal={setStartModal}
                                  setTransformToInfluenceTracking={props?.setTransformToInfluenceTracking}
                                  transformToInfluenceTracking={props?.transformToInfluenceTracking}
                                  categoryId={category.categoryId}
                                  startDate={props.startDate}
                                  endDate={props.endDate}
                                  index={index}
                                  dataList={category?.alls}
                                  isExpand={outcomeState?.isExpand}
                                  setLimitReachPopupVisible={setLimitReachPopupVisible}
                                  setLimitReachPopuptype={setLimitReachPopuptype}
                                />
                              ) : all?.influenceId ? (
                                <PersonalTrackingInfluence
                                  influenceItem={all}
                                  key={index}
                                  setShowInfluence={setShowInfluence}
                                  showInfluence={showInfluence}
                                  openStartModalInfluence={
                                    openStartModalInfluence
                                  }
                                  setStartModalInfluence={setStartModalInfluence}
                                  setTransformToOutcomeTracking={props?.setTransformToOutcomeTracking}
                                  transformToOutcomeTracking={props?.transformToOutcomeTracking}
                                  categoryId={category.categoryId}
                                  transformInInfluenceTracking={props?.transformInInfluenceTracking}
                                  setTransformInInfluenceTracking={props?.setTransformInInfluenceTracking}
                                  startDate={props.startDate}
                                  endDate={props.endDate}
                                  index={index}
                                  dataList={category?.alls}
                                  isExpand={outcomeState?.isExpand}
                                  setLimitReachPopupVisible={setLimitReachPopupVisible}
                                  setLimitReachPopuptype={setLimitReachPopuptype}
                                />
                              ) : (
                                <PersonalTrackingReflective
                                  refelectiveItem={all}
                                  key={index}
                                  setShowQues={setShowQues}
                                  showQues={showQues}
                                  openStartModalQues={openStartModalQues}
                                  setStartModalQues={setStartModalQues}
                                  startDate={props.startDate}
                                  endDate={props.endDate}
                                  dataList={category?.alls}
                                  index={index}
                                  isExpand={outcomeState?.isExpand}
                                  setLimitReachPopupVisible={setLimitReachPopupVisible}
                                  setLimitReachPopuptype={setLimitReachPopuptype}
                                />
                              );
                            })
                          )}
                        </AccordionDetails>
                      </Accordion>
                    );
                  }
                )}
            </div>
          ) : null}

        </Grid>
        {!outcomeState?.showEmpty && (
          <> <Divider orientation="vertical" flexItem />
            <Grid
              item
              xs={outcomeState?.isExpand ? 15.9 : 10.3}
              sx={{ paddingLeft: '0', background: '#FFF' }}
            >
              <Calendar
                createDate={props?.createDate}
                setCreateDate={props?.setCreateDate}
                showLoader={props?.showLoader}
                setShowLoader={props?.setShowLoader}
                setStartDate={props.setStartDate}
                startDate={props.startDate}
                endDate={props.endDate}
                setEndDate={props.setEndDate}
              />
            </Grid></>
        )}
        {outcomeState?.showEmpty && (
          <>
            <Grid
              item
              xs={outcomeState?.isExpand ? 15.9 : 10.3}
              sx={{ paddingLeft: '0', background: '#FFF' }}
            >

            </Grid></>
        )}

        {outcomeState?.showEmpty && !props?.showLoader && <div style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '20px',
          position: "absolute",
          width: "100%",
          flex: '1 0 0',
          alignSelf: 'stretch',
          height: 'calc(100vh - 350px)',
        }}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '12px',
            width: '360px',
            zIndex: 103

          }}>
            <Typography variant="h3" component="span" lineHeight="130% !important" sx={{ width: '100%', textAlign: "center", color: palette.common.black, fontSize: `${pxToRem(24)} !important`, fontWeight: 600 }}>
              You haven’t added any categories for your personal structure
            </Typography>
            <Typography variant="h3" component="span" lineHeight="150% !important" sx={{ width: '100%', textAlign: "center", color: `#A0A0A1`, fontSize: `${pxToRem(16)} !important`, fontWeight: 400 }}>
              Start adding categories to structure the metrics  your way
            </Typography>
          </div>
          <Button
            variant="contained"
            // onClick={() => onClick={() => navigate("/add-category")}}
            onClick={() => navigate("/add-category")}
            sx={{
              display: 'flex',
              padding: '16px 20px !important',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '100px',
              border: '8px solid #DFECE7',
              boxShadow: "none"
            }}
          >
            <Typography variant="h3" component="span" lineHeight="100% !important" sx={{ color: palette.common.white, fontSize: `${pxToRem(16)} !important`, fontWeight: 400 }}>
              Add a personal category
            </Typography>
          </Button>
        </div>}
        {/* </List> */}
        <LimitReachedPopup open={limitPopupVisible} setopenOutcomeclosePopup={setLimitReachPopupVisible} type={limitPopuptype}/>

      </div>
    </>
  );
};

export default PersonalTrackBody;
