
import React from 'react'

export default function FilterButton() {
  return (
    <>
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 10C0 4.47715 4.47715 0 10 0H30C35.5228 0 40 4.47715 40 10V30C40 35.5228 35.5228 40 30 40H10C4.47715 40 0 35.5228 0 30V10Z" fill="#F9D412" />
        <rect x="10" y="9" width="4" height="22" rx="1" stroke="#3E4240" strokeWidth="2" strokeLinejoin="round" />
        <rect x="26" y="9" width="4" height="22" rx="1" stroke="#3E4240" strokeWidth="2" strokeLinejoin="round" />
        <line x1="14" y1="11" x2="26" y2="11" stroke="#3E4240" strokeWidth="2" strokeLinejoin="round" strokeDasharray="2 2" />
        <line x1="14" y1="29" x2="26" y2="29" stroke="#3E4240" strokeWidth="2" strokeLinejoin="round" strokeDasharray="2 2" />
      </svg></>
  )
}
