import React, { useEffect, useState } from 'react'
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
import { createPortal } from 'react-dom';

const ConfettiTNT = () => {
    const [running, setRunning] = useState(false)
    const [completed, setCompleted] = useState(false)
    useEffect(() => {
        setRunning(true)
        setCompleted(false)
        setTimeout(() => {
            setRunning(false)
        }, 6000);
    }, [])
    const { width, height } = useWindowSize()
    // Create the confetti component using a portal to render it at the root level
    return createPortal(
        <div
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
                zIndex: 9999, // Ensure it's above other elements
                pointerEvents: 'none' // Prevent interactions from blocking other elements
            }}
        >
            {!completed && (
                <Confetti width={width} height={height} numberOfPieces={400} tweenDuration={5000} onConfettiComplete={() => {
                    setCompleted(true)
                }} run={running} />
            )}

        </div>,
        document.body // Render the confetti at the body level
    );
}

export default ConfettiTNT