import { INotificationActions } from "../../interfaces/notificationModal";

export const setAnchor = (
  payload: any
): INotificationActions => ({
  type: "SET_ANCHOR",
  payload: payload,
});

export const setXPos = (
  payload: any
): INotificationActions => ({
  type: "SET_X_POS",
  payload: payload,
});

export const setYPos = (payload: any): INotificationActions => ({
  type: "SET_Y_POS",
  payload: payload,
})

export const updateNotificationData = (payload: any): INotificationActions => ({
  type: "SET_NOTIFICATIN_DATA",
  payload: payload,
})

export const updateUnreadNotificationData = (payload: any): INotificationActions => ({
  type: "SET_UR_NOTIFICATIN_DATA",
  payload: payload,
})

export const unreadSpecificaNotifications = (payload: any): INotificationActions => ({
  type: "UPDATE_UR_NOTIFICATIN_DATA",
  payload: payload,
})