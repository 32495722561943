import React, { useContext, useEffect, useRef, useState } from "react";
import { contactsService } from "../../../services/contactsService";
import { UserTypeContext } from "../../../context/userStatus";
import { Box, Button, Dialog, Grid, Typography } from "@mui/material";
import { pxToRem } from "../../../theme/typography";
import palette from "../../../theme/palette";
import NewButton from "../../../components/NewButton";
import { ReviewData, ReviewDataV2 } from "./contactsData";
import moment from "moment";
import RightIconSwitch from "../../../assets/images/icon/RightIconSwitch";
import CommonTypo from "../../../components/CommonTypo";
import PencilIcon from "../../../assets/images/icon/PencilIcon";
import PauseIcon from "../../../assets/images/icon/PauseIcon";
import TrashIcon from "../../../assets/images/icon/TrachIcon";
import PlayIcon from "../../../assets/images/icon/PlayIcon";
import HTMLTooltip from "../../../components/HTMLTooltip";
interface SocialReportSchedulesProps {
  socialReportsScedules: any[];
  viewSocialReports: React.Dispatch<React.SetStateAction<boolean>>;
  viewSceduleReports: boolean;
  allContacts?: any[];
  allDefaultStates: any[];
  classes: any;
  editRecord: any;
  setEditRecord: (any) => void;
  setSideModalVisible: (any) => void
  setSelectedFocus: (any) => void
  setShowSnakbar?: (any) => void;
  setSnakbarMsg?: (any) => void;
  setMsgType?: (any) => void;
}
const SocialReportSchedules = (props: SocialReportSchedulesProps) => {
  const { classes, editRecord, setEditRecord, setSideModalVisible, setSelectedFocus } = props;
  const [socialReportsList, setSocialReportsList] = useState([]);
  const [actionMode, setActionMode] = useState(null);
  const [actionSuccess, setActionSuccess] = useState(false);
  const [dialogueActionVisible, setDialogueActionVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { state: IUserTypeState, dispatch: userTypeDispatch } =
    useContext(UserTypeContext);
  useEffect(() => {
    getReports(true);
  }, []);
  const getReports = (firstTimeLoad = false) => {
    if (firstTimeLoad) setIsLoading(true);
    contactsService
      .getAllSchedulesReports(IUserTypeState.token)
      .then((data) => {
        console.log("data", data);
        setIsLoading(false);
        if (data.body.length) {
          data.body = data.body.reverse()
          data.body = data.body.map((it, index) => {
            it.socialReportname = `Social Report #${data.body.length - index}`;
            return it;
          });
        }

        setSocialReportsList([...data.body]);
        // setShowSnakbar(true);
        // setMsgType("success");
        // setSnakbarMsg(data?.description);
      })
      .catch((err) => {
        if (err?.description) {
          //   setShowSnakbar(true);
          //   setMsgType("error");
          //   setSnakbarMsg(err?.description);
        }
      })
      .finally(() => {
        // setShowLoadingBtn(false)
      });
  };
  const handleUpdateStatus = (actionObject) => {
    contactsService
      .updateReportStatus(
        {
          shareReportId: editRecord.shareReportId,
          ...actionObject,
        },
        IUserTypeState.token
      )
      .then(() => {
        props.setShowSnakbar(true);
        props.setMsgType("success");
        if (actionMode === "delete") {
          props.setSnakbarMsg(`${editRecord.socialReportname} ${actionMode} successfully`);
        } else {
          props.setSnakbarMsg(`${editRecord.socialReportname} ${actionMode} successfully`);
        }
        setDialogueActionVisible(false)
        setActionSuccess(true);
        getReports();
      });
  };
  if (isLoading) {
    return null;
  }
  if (socialReportsList.length === 0) {
    return (
      <Grid container>
        <Grid item xs={12}>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{
              height: "365px",
              overflowY: "auto",
            }}
            className="hide-scrollbar"
          >
            <Typography
              sx={{
                color: palette.primary.light,
                fontSize: `${pxToRem(16)} !important`,
                lineHeight: "120% !important",
                // textAlign: "center !important",
                fontWeight: `400 !important`,
                "&.MuiTypography-root": {
                  "@media  (max-width: 600px) and (min-width: 100px)": {
                    fontSize: `${pxToRem(16)} !important`,
                    lineHeight: "100% !important",
                  },
                },
                margin: "0px 0px 12px",
              }}
            >
              There are no scheduled social reports
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "6px",
              }}
            >
              <NewButton
                variant="text"
                buttonText={"Go Back"}
                textColor="var(--button-active-text)"
                onClick={() => {
                  props.viewSocialReports(!props.viewSceduleReports);
                }}
              />
              <NewButton
                variant="contained"
                buttonText={"Schedule"}
                onClick={() => {
                  props.viewSocialReports(!props.viewSceduleReports);
                }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    );
  }


  return (
    <Grid container sx={{
      height: "100%"
    }}>
      <Grid item xs={12}>
        <Box
          display={"flex"}
          sx={{
            height: "100%",
            gap: "16px"
          }}
          flexDirection={"column"}
          className="hide-scrollbar"
        >
          {socialReportsList.length > 0 &&
            socialReportsList.map((it, index) => {
              return <BoxForReportDetails it={it} allDefaultStates={props?.allDefaultStates} classes={classes}
                setEditRecord={setEditRecord} setSideModalVisible={setSideModalVisible} setSelectedFocus={setSelectedFocus} viewSocialReports={props.viewSocialReports}
                viewSceduleReports={props.viewSceduleReports}
                setActionMode={setActionMode} setDialogueActionVisible={setDialogueActionVisible} allContacts={props.allContacts}
              />;
            })}
          {/* {actionMode && editRecord && (
            <>
              {actionSuccess ? (
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  flexDirection={"column"}
                  gap={"16px"}
                >
                  <Typography
                    sx={{
                      color: palette.primary.light,
                      fontSize: `${pxToRem(22)} !important`,
                      lineHeight: "120% !important",
                      // textAlign: "center !important",
                      fontWeight: `500 !important`,
                      "&.MuiTypography-root": {
                        "@media  (max-width: 600px) and (min-width: 100px)": {
                          fontSize: `${pxToRem(22)} !important`,
                          lineHeight: "100% !important",
                        },
                      },
                    }}
                  >
                    <RightIconSwitch color="var(--primary-color)" /> Social
                    report sucessfuly{" "}
                    {actionMode === "pause"
                      ? "paused"
                      : actionMode === "resume"
                        ? "resumed"
                        : "deleted"}
                    .
                  </Typography>
                  {boxForReportDetails(editRecord, false)}
                </Box>
              ) : (
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  flexDirection={"column"}
                  gap={"6px"}
                >
                  <Typography
                    sx={{
                      color: palette.primary.light,
                      fontSize: `${pxToRem(22)} !important`,
                      lineHeight: "120% !important",
                      // textAlign: "center !important",
                      fontWeight: `500 !important`,
                      "&.MuiTypography-root": {
                        "@media  (max-width: 600px) and (min-width: 100px)": {
                          fontSize: `${pxToRem(22)} !important`,
                          lineHeight: "100% !important",
                        },
                      },
                    }}
                  >
                    Are you sure you want to {actionMode} this report?
                  </Typography>
                  <Typography
                    sx={{
                      color: "#9BA19D",
                      fontSize: `${pxToRem(16)} !important`,
                      lineHeight: "120% !important",
                      // textAlign: "center !important",
                      fontWeight: `400 !important`,
                      "&.MuiTypography-root": {
                        "@media  (max-width: 600px) and (min-width: 100px)": {
                          fontSize: `${pxToRem(16)} !important`,
                          lineHeight: "100% !important",
                        },
                      },
                    }}
                  >
                    {actionMode === "pause"
                      ? "It will be paused and can resume it again "
                      : actionMode === "resume"
                        ? "It will be resumed and can pause it again "
                        : "It will be permanently removed and can not be undone "}
                  </Typography>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                    sx={{
                      width: "100%",
                      marginTop: "10px",
                    }}
                  >
                    {boxForReportDetails(editRecord, false)}
                  </Box>
                </Box>
              )}
            </>
          )} */}
        </Box>

      </Grid>
      {dialogueActionVisible && <Dialog
        onClose={() => setDialogueActionVisible(false)}
        open={dialogueActionVisible}
        className="dialogWrapper dialogWrapper-share dialogWrapper-mobileScreen"
        sx={{
          ".MuiPaper-root": {
            minHeight: "max-content",
            minWidth: "300px",
            padding: "16px !important",
            borderRadius: "12px",
            display: "flex",
            gap: "8px",
            flexDirection: "column"
          },
        }}
      >
        <CommonTypo size={14} lineHeight={16.8} fontWeight={600} color="var(--color-text-primary)">Are you sure you want to {actionMode} {editRecord.socialReportname}?</CommonTypo>
        <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} gap={"8px"}>
          <Button variant="contained" onClick={() => {
            if (actionMode === "delete") {
              handleUpdateStatus({
                deleteStatus: "ARCHIVE",
              })
            } else {
              handleUpdateStatus({
                sendStatus:
                  actionMode === "resume" ? "REGULARLY" : "STOP",
              })
            }

          }} sx={{
            background: "var(--color-new-main) !important",
            boxShadow: "none !important",
            height: "34px",
            padding: "8px 24px",
            gap: "4px",
            display: "flex",
            borderRadius: "20px",
            width: "100%",
            marginBottom: "0px !important"
          }}>
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.0306 5.53032L7.0306 13.5303C6.96092 13.6002 6.87813 13.6557 6.78696 13.6936C6.6958 13.7314 6.59806 13.7509 6.49935 13.7509C6.40064 13.7509 6.3029 13.7314 6.21173 13.6936C6.12057 13.6557 6.03778 13.6002 5.9681 13.5303L2.4681 10.0303C2.39833 9.96056 2.34299 9.87774 2.30524 9.78659C2.26748 9.69543 2.24805 9.59774 2.24805 9.49908C2.24805 9.40041 2.26748 9.30272 2.30524 9.21156C2.34299 9.12041 2.39833 9.03759 2.4681 8.96783C2.53786 8.89806 2.62069 8.84272 2.71184 8.80496C2.80299 8.76721 2.90069 8.74777 2.99935 8.74777C3.09801 8.74777 3.19571 8.76721 3.28686 8.80496C3.37801 8.84272 3.46083 8.89806 3.5306 8.96783L6.49997 11.9372L13.9693 4.46907C14.1102 4.32818 14.3013 4.24902 14.5006 4.24902C14.6999 4.24902 14.891 4.32818 15.0318 4.46907C15.1727 4.60997 15.2519 4.80107 15.2519 5.00032C15.2519 5.19958 15.1727 5.39068 15.0318 5.53157L15.0306 5.53032Z" fill="#3E4240" />
            </svg>
            <CommonTypo size={14} lineHeight={18.2} fontWeight={600} color="var(--color-text-primary)">Yes, {actionMode}</CommonTypo>
          </Button>
          <Button variant="contained" sx={{
            background: "var(--color-border) !important",
            boxShadow: "none !important",
            height: "34px",
            padding: "8px 24px",
            gap: "4px",
            display: "flex",
            borderRadius: "20px",
            width: "100%",
            marginBottom: "0px !important"
          }} onClick={() => setDialogueActionVisible(false)}>
            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.5306 11.969C13.6715 12.1099 13.7506 12.301 13.7506 12.5002C13.7506 12.6995 13.6715 12.8906 13.5306 13.0315C13.3897 13.1724 13.1986 13.2515 12.9993 13.2515C12.8001 13.2515 12.609 13.1724 12.4681 13.0315L8.49997 9.06212L4.5306 13.0302C4.3897 13.1711 4.19861 13.2503 3.99935 13.2503C3.80009 13.2503 3.60899 13.1711 3.4681 13.0302C3.3272 12.8894 3.24805 12.6983 3.24805 12.499C3.24805 12.2997 3.3272 12.1086 3.4681 11.9677L7.43747 7.99962L3.46935 4.03025C3.32845 3.88935 3.2493 3.69825 3.2493 3.499C3.2493 3.29974 3.32845 3.10864 3.46935 2.96775C3.61024 2.82685 3.80134 2.7477 4.0006 2.7477C4.19986 2.7477 4.39095 2.82685 4.53185 2.96775L8.49997 6.93712L12.4693 2.96712C12.6102 2.82622 12.8013 2.74707 13.0006 2.74707C13.1999 2.74707 13.3909 2.82622 13.5318 2.96712C13.6727 3.10802 13.7519 3.29911 13.7519 3.49837C13.7519 3.69763 13.6727 3.88873 13.5318 4.02962L9.56247 7.99962L13.5306 11.969Z" fill="#3E4240" />
            </svg>
            <CommonTypo size={14} lineHeight={18.2} fontWeight={600} color="var(--color-text-primary)">No</CommonTypo>
          </Button>
        </Box>
      </Dialog>}
    </Grid >
  );
};

export default SocialReportSchedules;

const BoxForReportDetails = ({ it, allDefaultStates, setEditRecord, setSideModalVisible, setSelectedFocus, viewSocialReports, viewSceduleReports,
  setActionMode, setDialogueActionVisible, allContacts, classes
}) => {
  const isMonthly = ["LAST_THIRTY_DAYS", "LAST_MONTH"].includes(it.scheduleType)
  const boxRefMatric = useRef(null);  // Reference for the box container
  const [visibleTags, setVisibleTags] = useState([]); // Visible tags
  const [hiddenTags, setHiddenTags] = useState([]);   // Hidden tags
  console.log("isMonthly", isMonthly, it.scheduleType, it);
  
  const [shouldTruncatePreview, setShouldTruncatePreview] = useState(false);  // State to decide if truncation is needed
  // Combine the outcomes and influences into one array
  const allsMetrics = [...ReviewDataV2(
    {
      contacts: [],
      outcomeList: allDefaultStates[0].items.filter((ite) =>
        [...(it.outcomeIds || [])].includes(ite.outcomeId)
      ),
      influenceList: allDefaultStates[1].items.filter((ite) =>
        [...(it.influenceIds || [])].includes(ite.influenceId)
      ),
    },
    true
  )];
  const updateTagDisplay = () => {
    if (boxRefMatric.current) {
      const containerWidth = boxRefMatric.current.offsetWidth; // Get the container width
      let totalWidth = 0; // To accumulate total width of tags
      const visible = [];
      const hidden = [];
      console.log("allsMetrics", allsMetrics, containerWidth);
      const allDescription = [...allsMetrics].map(it => it.description).flat()
      console.log("allDescription", allDescription);

      allDescription.forEach(tag => {
        // Create a temporary element to measure tag width
        const tagElement = document.createElement("div");
        tagElement.style.visibility = "hidden";
        tagElement.style.position = "absolute";
        tagElement.style.height = "34px";
        tagElement.style.padding = "8px 24px";
        tagElement.style.display = "inline-flex";
        tagElement.style.border = "1px solid var(--color-border)";
        tagElement.style.borderRadius = "20px";
        tagElement.innerText = tag;
        document.body.appendChild(tagElement);

        const tagWidth = tagElement.offsetWidth; // Measure the tag width
        document.body.removeChild(tagElement); // Remove the temporary element
        console.log("totalWidth", totalWidth, "containerWidth", containerWidth);

        if (totalWidth + tagWidth < containerWidth) {
          totalWidth += tagWidth; // Add width if it fits
          visible.push(tag);
        } else {
          hidden.push(tag); // Otherwise, add to hidden tags
        }
      });
      console.log("visible", allsMetrics, visible, hidden);

      setVisibleTags(visible);
      setHiddenTags(hidden);
      setShouldTruncatePreview(hidden.length > 0);
    }
  };
  useEffect(() => {
    updateTagDisplay()
  }, [it])

  // const isWeekly = ["LAST_SEVEN_DAYS", "LAST_WEEK"].includes(it.scheduleType)
  return (
    <Box
      key={it.shareReportId}
      display={"flex"}
      alignItems={"flex-start"}
      flexDirection={"column"}
      gap={"16px"}
      width={"100%"}
      paddingBottom={"32px"}
    borderBottom={"1px solid var(--color-border)"}
    >
      <Box display={"flex"}
        alignItems={"flex-start"}
        flexDirection={"column"} gap={"8px"}>
        <CommonTypo size={24} lineHeight={29} color="var(--color-text-primary)" fontWeight={700}>{it.socialReportname}</CommonTypo>
        <CommonTypo size={16} lineHeight={20} color="var(--color-text-primary)" fontWeight={400}>{"Ongoing"}</CommonTypo>
      </Box>
      <Box display={"flex"}
        alignItems={"center"} gap={"8px"}>
        <Box sx={{
          cursor: "pointer"
        }} onClick={() => {
          // edit
          setEditRecord(it);
          setSideModalVisible(true)
          setSelectedFocus("contact")
          viewSocialReports(!viewSceduleReports);
        }}>
          <PencilIcon />
        </Box>
        <Box sx={{
          cursor: "pointer"
        }} onClick={() => {
          // pause / Resume
          setActionMode(it.sendStatus === "STOP" ? "resume" : "pause");
          setDialogueActionVisible(true)
          setEditRecord(it);
        }}>
          {it.sendStatus === "STOP" ? <PlayIcon /> : <PauseIcon />}

        </Box>
        <Box sx={{
          cursor: "pointer"
        }} onClick={() => {
          // delete
          setActionMode("delete");
          setDialogueActionVisible(true)
          setEditRecord(it);
        }}>
          <TrashIcon />
        </Box>
      </Box>
      <Box display={"flex"}
        alignItems={"flex-start"}
        flexDirection={"column"} gap={"8px"}>
        <CommonTypo size={16} lineHeight={20} color="var(--color-text-primary)" fontWeight={600}>{"Who do you want to share with?"}</CommonTypo>
        <Box display={"flex"} alignItems={"center"} gap={"8px"} flexWrap={"wrap"}>
          {ReviewDataV2(
            {
              contacts: allContacts[0].contacts.filter((ite) =>
                [...(it.contacts || [])]
                  .map((ite1) => ite1._id)
                  .includes(ite._id)
              ),
              outcomeList: [],
              influenceList: [],
            },
            true
          ).map((ite) => ite.description.map(it1 => <span className={classes.reviewDescriptionV2}>
            <CommonTypo size={14} lineHeight={18} color="var(--color-text-secondary)">{it1}</CommonTypo>
          </span>))}
        </Box>

      </Box>
      <Box display={"flex"}
        alignItems={"flex-start"}
        flexDirection={"column"} gap={"8px"}>
        <CommonTypo size={16} lineHeight={20} color="var(--color-text-primary)" fontWeight={600}>{"What do you want to share?"}</CommonTypo>
        <Box ref={boxRefMatric} display={"flex"} alignItems={"center"} gap={"8px"} flexWrap={"wrap"} width={"100%"}>
          {/* {ReviewDataV2(
            {
              contacts: [],
              outcomeList: props.allDefaultStates[0].items.filter((ite) =>
                [...(it.outcomeIds || [])].includes(ite.outcomeId)
              ),
              influenceList: props.allDefaultStates[1].items.filter((ite) =>
                [...(it.influenceIds || [])].includes(ite.influenceId)
              ),
            },
            true
          ).map((ite) => ite.description.map(it1 => <span className={classes.reviewDescriptionV2}>
            <CommonTypo size={14} lineHeight={18} color="var(--color-text-secondary)">{it1}</CommonTypo>
          </span>))} */}
          {visibleTags.map((it, index) => (
            <span className={classes.reviewDescriptionV2}>
              <CommonTypo size={14} lineHeight={18} color="var(--color-text-secondary)">{it}</CommonTypo>
            </span>
          ))}
          {/* If there are hidden tags, show "and X more" with a tooltip */}
          {shouldTruncatePreview && (
            <HTMLTooltip
              customIcon={<div><span className={classes.reviewDescriptionV2}>
                <CommonTypo size={14} lineHeight={18} color="var(--color-text-secondary)">{`+ ${hiddenTags.length}`}</CommonTypo>
              </span></div>}
              title={<>{hiddenTags.map((it, index) => <CommonTypo fontWeight={600} size={16} lineHeight={16} color="var(--color-text-primary)">{it}{index !== (hiddenTags.length - 1) && ", "}</CommonTypo>)}</>}
              fontProps={{
                fontSize: `${pxToRem(12)} !important`,
              }}
              placement="right-start"
              sx={{
                "& .MuiTooltip-tooltip": {
                  maxWidth: "300px",
                  background: '#F6F6F6',
                  borderColor: "#F6F6F6",
                  '& .MuiTooltip-arrow': {
                    color: "#F6F6F6",
                    // borderTop: '1px solid #9747FF',
                    // borderLeft: '1px solid #9747FF',
                    // transform: 'translate3d(4px, 33px, 11px) rotate(-38deg)!important'
                    '&:before': {
                      border: "1px solid #F6F6F6 !important"
                    }
                  }
                },

              }}
            />

          )}
        </Box>

      </Box>
      <Box display={"flex"}
        alignItems={"flex-start"}
        flexDirection={"column"} gap={"8px"}>
        <CommonTypo size={16} lineHeight={20} color="var(--color-text-primary)" fontWeight={600}>{"How often do you want to share?"}</CommonTypo>
        <Box display={"flex"} alignItems={"center"} gap={"8px"} flexWrap={"wrap"}>
          {isMonthly ? <span className={classes.reviewDescriptionV2}>
            <CommonTypo size={14} lineHeight={18} color="var(--color-text-secondary)">{"Monthly"}</CommonTypo>
          </span> : <span className={classes.reviewDescriptionV2}>
            <CommonTypo size={14} lineHeight={18} color="var(--color-text-secondary)">{"Weekly"}</CommonTypo>
          </span>}
          {isMonthly ? <span className={classes.reviewDescriptionV2}>
            <CommonTypo size={14} lineHeight={18} color="var(--color-text-secondary)">{`on ${it.dateToSend}`}</CommonTypo>
          </span> : <span className={classes.reviewDescriptionV2}>
            <CommonTypo size={14} lineHeight={18} color="var(--color-text-secondary)">{`on ${it.dayToSend ? it.dayToSend.toLowerCase() : ""}`}</CommonTypo>
          </span>}
          {/* {<span className={classes.reviewDescriptionV2}>
            <CommonTypo size={14} lineHeight={18} color="var(--color-text-secondary)">{`at ${moment(it.timeToSend).format("HH:mm")}`}</CommonTypo>
          </span>} */}
        </Box>
      </Box>
    </Box>
  );
};