/*eslint eqeqeq: "off"*/
import React, { useState, useEffect, useContext } from "react";
import { Button, Grid, TextField, Typography, SvgIcon, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import {  makeStyles } from "@material-ui/core";
import palette from "../../theme/palette";
import GetIdeasIcon from "../../assets/images/icon/getIdeas";
// import ProgressBarStepper from "./progressBarStepper";
import {
  fixOutcomeColorPalette,
  fixOutcomeIcon,
} from "../../utils/staticDataJson";
// import IconPickerDialog from "./iconPickerDialog";
import {
    InfluenceGetResponse,
  OutcomeGetResponse,
  OutComeIconModal,
} from "../../interfaces/outcomeModal";
// import ColorPickerDialog from "./colorPickerDialog";
// import BottomStepper from "./BottomStepper";
import clsx from "clsx";
// import IdeasDrawer from "./ideasDrawer";
import shape from "../../theme/shape";
import { outcomeService } from "../../services/outcome";
import SnackBarComp from "../../components/snackBar";
import { ButtonLoader } from "../../components/buttonLoader";
import { UserTypeContext } from "../../context/userStatus";
import {
  addCategoryJourneyAction,
  addInfluenceAction,
  addJourneyAction,
  addOutcomeAction,
} from "../../store/actions/userTypeAction";
import moment from "moment";
import { trackingService } from "../../services/tracking";
import Donts from "../../assets/images/icon/Dots";
import { userService } from "../../services/auth";
import { setTimeout } from "timers/promises";
import { useNavigate } from "react-router-dom";
import SwitchInfluenceModal from "../tracker/switchInfluenceModal";
import IdeasDrawer from "../outcome/ideasDrawer";
import ColorPickerDialog from "../outcome/colorPickerDialog";
import IconPickerDialog from "../outcome/iconPickerDialog";
import ProgressBarStepper from "../outcome/progressBarStepper";
import { influenceService } from "../../services/influence";
import NewButton from "../../components/NewButton";
import ProgressBarStepperNew from "../outcome/progressBarStepperNew";

const RootStyle = styled("div")(({ theme }) => ({
  padding: "60px 48px 60px 80px",
  position: "relative",
  height: "100vh",
  [theme.breakpoints.down("xl")]: {
    padding: "50px 40px 50px 60px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "40px 20px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "20px",
  },
}));

const useStyles = makeStyles((theme) => ({
  registrationHeading: {
    color: palette.primary.light,
  },
  pageTitle: {
    marginTop: "40px!important",
    marginBottom: "48px!important",
    [theme.breakpoints.down("lg")]: {
      marginTop: "30px!important",
      marginBottom: "38px!important",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "20px!important",
      marginBottom: "28px!important",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "15px!important",
      marginBottom: "20px!important",
    },
  },
  needSomeIdeas: {
    fontSize: "16px",
    lineHeight: "16px",
    color: palette.primary.main,
    marginLeft: "8px",
    [theme.breakpoints.down("lg")]: {
      marginTop: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "14px",
    },
  },
  colorBlock: {
    cursor: "pointer",
    backgroundColor: palette.secondary.light,
    border: "1px solid #DCE6E0",
    borderRadius: shape.borderRadius,
    padding: "11px 16px",
    display: "flex",
    alignItems: "center",
    marginLeft: "16px",
    [theme.breakpoints.down("lg")]: {
      marginLeft: 0,
    },
  },
  spaceBetween: {
    [theme.breakpoints.down("lg")]: {
      marginTop: "10px",
      marginBottom: "10px",
    },
  },
  colorBlockText: {
    margin: 0,
    color: palette.primary.light,
    fontSize: "16px",
    lineHeight: "16px",
    fontWeight: 500,
  },
  colorCode: {
    width: "24px",
    height: "24px",
    marginLeft: "10px",
    borderRadius: shape.borderRadiusSm,
  },
  outcomeTitle: {
    marginTop: "48px!important",
    marginBottom: "20px!important",
    [theme.breakpoints.down("lg")]: {
      marginTop: "40px!important",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "30px!important",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px!important",
    },
  },
}));

const ariaLabel = { "aria-label": "description" };

interface LeftSideOutComeModal {
  outComeValue: any;
//   setOutcomeValue: (outComeValue: string) => void;
//   outcomeIdeas: any;
//   setShowOutcome: (showOutcome: boolean) => void;
//   showOutcome: boolean;
//   setAddOutcomeTracking: (addOutcomeTracking: boolean) => void;
  setTextInput: any;
  textInput: any;
//   ispersonalMode?: boolean;
//   handleCloseStartModal?: any;
//   getOutcomeList?: any;
  categoryId?: any;
//   onPersonalHandler?: any;
  setTransformToInfluenceTracking?: any;
  isMobile?: boolean
  callservice?: (x: boolean) => void;
}

const LeftTransformToOut = (props: LeftSideOutComeModal) => {
  const classes = useStyles();
  const [openIcon, setOpenIcon] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState<any>(fixOutcomeIcon[0].path);
  const [openColor, setOpenColor] = useState(false);
  const [selectedColor, setSelectedColor] = useState<any>(
    fixOutcomeColorPalette[0]
  );
  const [outcomeValueInput, setOutComeValueInput] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [editStatus, setEditStatus] = useState(null);
  const [showSnakbar, setShowSnakbar] = useState(false);
  const [snakbarMsg, setSnakbarMsg] = useState("");
  const [msgType, setMsgType] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [absenceToggle, setabsenceToggle] = useState(false);
  const [skipMetric, setskipMetric] = useState(false);
  const [openSwitchInfluenceModal, setopenSwitchInfluenceModal] =
    useState(false);
  const { state: outcomeState, dispatch: userTypeDispatch } =
    useContext(UserTypeContext);
  const compareDate = parseInt(moment().format("D"));
  const startDay =
    compareDate < 10
      ? moment().clone().startOf("month")
      : moment().clone().subtract(10, "day");
  const endDay =
    compareDate < 10
      ? moment().clone().endOf("month")
      : moment().clone().add(10, "day");

  const navigate = useNavigate();

  const NativeOutcomeStatus = [
    { text: "very low" },
    { text: "low" },
    { text: "medium" },
    { text: "high" },
    { text: "very high" },
  ];

  useEffect(() => {
    setOpenDrawer(false);
    setOutComeValueInput(props.outComeValue?.name);
    if(props.outComeValue?.type === "TYPE2") {
     
      setSelectedColor(
        props.outComeValue?.options.length > 0
          ? props.outComeValue?.options
          : fixOutcomeColorPalette[0]
      );
    } else {
      console.log("setting native text");
      
      setSelectedColor(fixOutcomeColorPalette[0]);
    }
    props?.setTextInput(
      props.outComeValue?.options?.length > 0
        ? props.outComeValue?.options
        : NativeOutcomeStatus
    );
    setSelectedIcon(
      props.outComeValue?.icon
        ? props.outComeValue?.icon
        : fixOutcomeIcon[0].path
    );
    setabsenceToggle(props.outComeValue?.absenceToggle);
    setskipMetric(props?.outComeValue?.skipMetric);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.outComeValue]);

  const handleClickOpenIcon = () => {
    setOpenIcon(true);
  };

  const handleCloseIcon = (value: OutComeIconModal) => {
    setOpenIcon(false);
    if (value.id === -1) {
      return;
    }
    setSelectedIcon(value.path);
  };
  const handleClickOpenColor = () => {
    setOpenColor(true);
  };

  const callService = async () => {
    try {
      const bodyData = {
        from: moment(startDay).format('MM-DD-YYYY'),
        to: moment(endDay).format('MM-DD-YYYY'),
      };
      // if (!props?.ispersonalMode) {
        let data = await trackingService.getJourneyList(
          outcomeState.token,
          bodyData
        );
        userTypeDispatch(addJourneyAction(data));
      // } else {
        let datap = await trackingService.getPersonalJourneyList(
          outcomeState.token,
          bodyData
        );
        userTypeDispatch(addCategoryJourneyAction(datap?.data));
      // }
    } catch (error) {
      console.log('jourey list error');
    }
  };

  const handleCloseColor = (value: any) => {
    setOpenColor(false);
    if (value.id === -1) {
      return;
    }
    setSelectedColor(value);
  };

  const handleChangeOutcome = (e: any) => {
    setOutComeValueInput(e.target.value);
  };
  const addOutCome = async (values: string) => {
    setShowLoader(true);
    var result: any = selectedColor;
    if (props.outComeValue?.uid && selectedColor.length > 0) {
      result = selectedColor?.map((color: any, index: number) => ({
        text: props?.textInput[index]?.text,
        colorCode: color.colorCode,
        answerId: color?.answerId,
      }));
    } else {
      result = selectedColor?.color?.map((color: any, index: number) => ({
        text: props?.textInput[index]?.text,
        colorCode: color,
        answerId:
          props?.outComeValue && props?.outComeValue?.options[index]?.answerId,
      }));
    }
    let outcomeRes;
    if (props.outComeValue?.uid) {
      outcomeRes = {
        "type": props.outComeValue.type,
        name: values,
        options: result ? result : selectedColor,
        icon: selectedIcon,
        influenceId: props.outComeValue.influenceId,
        categoryId: props?.categoryId,
        skipMetric
      };
    } else {
      outcomeRes = {
        "type": props.outComeValue.type,
        name: values,
        options: result ? result : selectedColor,
        icon: selectedIcon,
        categoryId: props?.categoryId,
        skipMetric
      };
    }
    influenceService
    .TransformInfluenceToOutcome(outcomeRes, outcomeState.token)
    .then((response: any) => {
      setShowLoader(false);
      props?.setTransformToInfluenceTracking(false)
      setShowSnakbar(true);
      setSnakbarMsg(response.message);
      setMsgType('success');
      props?.callservice(false)
      influenceService
        .getInfluenceList(outcomeState.token)
        .then((data: InfluenceGetResponse) => {
          userTypeDispatch(addInfluenceAction(data?.data));
          // props.setShowInfluence(false);
        //   setInfluenceInput('');
        //   setInfluenceOption('');
        //   setUnitValueOption('');
        //   setUnitValue('');
        //   setFlagValue('');
          // props.setInfluenceData('');
          props?.setTextInput(NativeOutcomeStatus);
        });
        outcomeService
        .getOutcomeList(outcomeState.token)
        .then((data: OutcomeGetResponse) => {
          userTypeDispatch(addOutcomeAction(data?.data));
          // props.setShowInfluence(false);
        //   setInfluenceInput('');
        //   setInfluenceOption('');
        //   setUnitValueOption('');
        //   setUnitValue('');
        //   setFlagValue('');
          // props.setInfluenceData('');
          props?.setTextInput(NativeOutcomeStatus);
        });
    })
    .catch((error) => {
      setShowLoader(false);
      setShowSnakbar(true);
      setSnakbarMsg(
        error?.response?.data?.description
          ? error?.response?.data?.description
          : error.message
      );})
    // EDIT COUTCOME
    if (props.outComeValue?.uid) {
    //   outcomeService
    //     .EditOutcome(outcomeRes, outcomeState.token)
    //     .then((response: any) => {
    //       setShowLoader(false);
    //       setShowSnakbar(true);
    //       setSnakbarMsg(response.message);
    //       setMsgType("success");
    //       outcomeService
    //         .getOutcomeList(outcomeState.token)
    //         .then((data: OutcomeGetResponse) => {
    //           userTypeDispatch(addOutcomeAction(data?.data));
    //         //   callService();
    //           props.setShowOutcome(false);
    //           setOutComeValueInput("");
    //           props.setOutcomeValue("");
    //           props?.setTextInput(NativeOutcomeStatus);
    //         });
    //     })
    //     .catch((error) => {
    //       setShowLoader(false);
    //       setShowSnakbar(true);
    //       setSnakbarMsg(
    //         error?.response?.data?.description
    //           ? error?.response?.data?.description
    //           : error.message
    //       );
    //       setMsgType("error");
    //     });
    } else {
      // ADD OUTCOME
    //   outcomeService
    //     .createOutcome(outcomeRes, outcomeState.token)
    //     .then(async (response: any) => {
    //       setShowLoader(false);
    //       setShowSnakbar(true);
    //       setSnakbarMsg(response.message);
    //       setMsgType("success");
    //       outcomeState.outcomeList.push(response?.data);
    //       const bodyData = {
    //         from: moment(startDay).format("MM-DD-YYYY"),
    //         to: moment(endDay).format("MM-DD-YYYY"),
    //       };
    //       let data: any = await trackingService.getPersonalJourneyList(
    //         outcomeState.token,
    //         bodyData
    //       );
    //       userTypeDispatch(addCategoryJourneyAction(data?.data));
    //       userTypeDispatch(addOutcomeAction(outcomeState.outcomeList));
    //       let timerId: number = window.setTimeout(() => {
    //         props.setAddOutcomeTracking(false);
    //         setOutComeValueInput("");
    //         props.setOutcomeValue("");
    //         props?.setTextInput(NativeOutcomeStatus);
    //       }, 1000);
    //     })
    //     .catch((error) => {
    //       setShowLoader(false);
    //       setShowSnakbar(true);
    //       setSnakbarMsg(
    //         error?.response?.data?.description
    //           ? error?.response?.data?.description
    //           : error.message
    //       );
    //       setMsgType("error");
    //     });
    }
  };
  const showDrawer = () => {
    setOpenDrawer(true);
  };
  const handleCloseSnakbar = () => {
    setShowSnakbar(false);
  };

  const ITEM_HEIGHT = 48;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <RootStyle
    >
      <SnackBarComp
        showSnakbar={showSnakbar}
        handleCloseSnakbar={handleCloseSnakbar}
        snakbarMsg={snakbarMsg}
        type={msgType ? msgType : "info"}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h2"
          component="h2"
          className={classes.registrationHeading}
        >
          Transform  {props?.outComeValue?.name} to an outcome
        </Typography>
      </div>
      {/* <Typography variant="h4" component="h4" className={classes.pageTitle}>
        Awesome! An outcomes is something you care about like your well-being
        and want to understand better.
      </Typography> */}
      <Grid
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        sx={{ mb: "16px", mt: "20px" }}
      >
        <Typography variant="caption" component="div">
          Metric Information
        </Typography>
      </Grid>

      <Grid
        display="flex"
        alignItems="center"
        // justifyContent="space-between"
        flexWrap="wrap"
        style={{width: "782!important"}}
      >
        <TextField
          className="fillTextInput fullWidth"
          style={{width: "full!important"}}
          placeholder="The outcome you care about"
          variant="outlined"
          fullWidth
          name="outcome"
          value={outcomeValueInput || ""}
          onChange={(e) => handleChangeOutcome(e)}
          inputProps={ariaLabel}
          disabled
        />

        {/* <div
          className={clsx(classes.colorBlock, classes.spaceBetween)}
          onClick={handleClickOpenColor}
        >
          <p className={classes.colorBlockText}>Set a color</p>
          <span
            className={classes.colorCode}
            style={{
              backgroundColor:
                props.outComeValue?.uid && selectedColor.length === 0
                  ? props.outComeValue?.options[0]?.colorCode
                  : selectedColor.length > 0
                  ? selectedColor[0]?.colorCode
                  : selectedColor?.color[0],
            }}
          ></span>
        </div> */}
        {/* CHOOSE COLOR PALETTSE */}
        {/* <ColorPickerDialog
          openColor={openColor}
          onCloseColor={handleCloseColor}
        /> */}
        {/* <div className={classes.colorBlock} onClick={handleClickOpenIcon}>
          <p className={classes.colorBlockText}>Choose an icon</p>
          <span className={classes.colorCode}>
            <SvgIcon color="action">
              <path d={selectedIcon} />
            </SvgIcon>
          </span>
        </div> */}
        {/* CHOOSE ICON PALETTE */}
        {/* <IconPickerDialog openIcon={openIcon} onClose={handleCloseIcon} /> */}
      </Grid>
      <Typography
        variant="caption"
        component="div"
        className={classes.outcomeTitle}
      >
        Define your Scale for evaluating your progress
      </Typography>
      {/* SCALBAR */}
      <ProgressBarStepperNew
        selectedColor={selectedColor}
        setTextInput={props?.setTextInput}
        textInput={props?.textInput}
        setShowInput={setShowInput}
        setEditStatus={setEditStatus}
        showInput={showInput}
        editStatus={editStatus}
      />
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        gap={"32px"}
        mt={2}
      >
        <NewButton
          buttonText="Transform"
          type="submit"
          variant="contained"
          onClick={() => addOutCome(outcomeValueInput)}
          isDisabled={
            outcomeValueInput == "" || selectedColor.length === 0
          }
          isLoadong={showLoader}
        />
        <NewButton
          buttonText="Cancel"
          onClick={() => props?.setTransformToInfluenceTracking(false)}
          variant="outlined"
        />
      </Box>
    </RootStyle>
  );
};
export default LeftTransformToOut;
