import React from "react";

const InsightLearnMore = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20"  xmlns="http://www.w3.org/2000/svg">
            <path d="M16 20H4C2.89543 20 2 19.1046 2 18V2C2 0.89543 2.89543 0 4 0H13C13.0109 0.000472319 13.0217 0.00249256 13.032 0.006C13.0418 0.00901724 13.0518 0.0110277 13.062 0.012C13.1502 0.0176532 13.2373 0.0347982 13.321 0.063L13.349 0.072C13.3717 0.079682 13.3937 0.0890412 13.415 0.1C13.5239 0.148424 13.6232 0.216185 13.708 0.3L17.708 4.3C17.7918 4.38479 17.8596 4.48406 17.908 4.593C17.918 4.615 17.925 4.638 17.933 4.661L17.942 4.687C17.9699 4.77039 17.9864 4.85718 17.991 4.945C17.9926 4.95418 17.9949 4.96322 17.998 4.972C17.9998 4.98122 18.0004 4.99062 18.0001 5V18C18.0001 19.1046 17.1046 20 16 20ZM4 2V18H16V7H12C11.4477 7 11 6.55228 11 6V2H4ZM13 2.414V5H15.586L13 2.414Z"  />
            <path d="M14 15H6V13H14V15Z"  />
            <path d="M11 11H6V9H11V11Z"  />
        </svg>

    );
};

export default InsightLearnMore;
