import * as React from "react";
import { Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import palette from "../../theme/palette";
import { Divider } from "@material-ui/core";
import LeftSideGoals from "./leftsideGoals";

const RootStyle = styled("div")(({ theme }) => ({
  padding: "60px",
  backgroundColor: palette.common.white,
  [theme.breakpoints.down("sm")]: {
    padding: "20px",
  },
}));

const AddGoals = () => {
  return (
    <RootStyle>
      <Typography variant="h3" component="h3">
        Start Setting your goals
      </Typography>
      <Grid container columns={16} sx={{ marginTop: "40px" }}>
        <Grid item xs={7}>
          <LeftSideGoals />
        </Grid>
        <Divider orientation="vertical" flexItem />
        <Grid item xs={9}></Grid>
      </Grid>
    </RootStyle>
  );
};
export default AddGoals;
