import { Grid } from "@mui/material";
import * as React from "react";
import palette from "../../theme/palette";
import LeftPartLogin from "./leftPartLogin";
import RightPartLogin from "./rightPartLogin";
import RightPartForgetPassword from "./rightPartForgetPassword";
import RightPartResetPassword from "./rightPartResetPassword";

const ResetPassword = () => {
  // localStorage.removeItem("userDetails");
  // localStorage.removeItem('userValues');
  // localStorage.removeItem('userId');
  // localStorage.removeItem('userDate');
  return (
    <Grid
      alignItems="center"
      container
      columns={16}
      sx={{ backgroundColor: palette.common.bgColor }}
    >
      {/* START LEFT SECTION */}
      <Grid item xs={9.63} className="leftside-menu login-screen">
        <LeftPartLogin />
      </Grid>
      {/* END LEFT SECTION */}

      {/* START RIGHT SECTION */}
      <Grid item xs={6.37} className="rightside-menu">
        <RightPartResetPassword />
      </Grid>
      {/* END RIGHT SECTION */}
    </Grid>
  );
};
export default ResetPassword;
