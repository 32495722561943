/*eslint @typescript-eslint/no-unused-vars: "off"*/
import React, { useState, useContext, useEffect } from "react";
import {
  Box,
  Button,
  IconButton,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import ModalCloseIcon from "../../assets/images/icon/modalCloseIconBlack";
import { makeStyles, Typography } from "@material-ui/core";
import palette from "../../theme/palette";
import { trackingService } from "../../services/tracking";
import { UserTypeContext } from "../../context/userStatus";
import { UpdateUserLevelModal } from "../../interfaces/outcomeModal";
import { ButtonLoader } from "../../components/buttonLoader";
import useWidth from "../../utils/useWidth";
import { useNavigate } from "react-router-dom";
const PlusIcon = require("../../assets/images/Add.png");

const useStyles = makeStyles((theme) => ({
  registrationHeading: {
    color: `${palette.primary.light}!important`,
    marginBottom: "20px!important",
  },
  pageTitle: {
    marginBottom: "16px!important",
  },
  dialogTitle: {
    color: `${palette.primary.light}!important`,
    fontSize: "26px!important",
    lineHeight: "26px!important",
    letterSpacing: "0.02em",
    marginBottom: "20px!important",
    padding: "0!important",
  },
  bottomButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    "& button:nth-child(2)": {
      marginLeft: "24px",
    },
  },
  listItemNotes: {
    listStyleType: "none",
    fontSize: "14px",
    lineHeight: "14px",
    color: palette.primary.light,
    margin: 0,
    paddingLeft: "0px",
    marginBottom: "20px",
    "& li": {
      marginBottom: "8px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
}));

interface AddRefQues {
    setopenUnavailablecategory: (openUnavailablecategory: boolean) => void;
    openUnavailablecategoryMessage?: string
//   refId: string;
//   setShowSnakbar: (showSnakbar: boolean) => void;
//   setSnakbarMsg: (snakbarMsg: string) => void;
//   setMsgType: (msgType: string) => void;
//   setRefId: (refId: string) => void;
//   isEdit: boolean;
//   setIsEdit: any;
//   journeyData: any;
//   headerDate: any;
//   callService: any;
//   item: any;
}
const AddRefQuesModal = (props: AddRefQues) => {
  const classes = useStyles();
  const { state: outcomeState } = useContext(UserTypeContext);
  const [openStartModal, setStartModal] = useState(true);
  const [questionValue, setQuestionValue] = useState<any>();
  const [questionArr, setQuestionArr] = useState([]);
  const [showLoadingButton, setShowLoadingButton] = useState(false);
  const [editData, setEditData] = useState<any>();
  const [item, setItem] = useState<any>();
  const navigate = useNavigate();

  const handleCloseStartModal = async () => {
    setStartModal(false);
    await props.setopenUnavailablecategory(false);
    // props.setRefId("");
  };
  let arr2: any = [];
  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      arr2 = questionArr;
      arr2.push(questionValue);
      setQuestionArr(arr2);
      setQuestionValue("");
    }
  };
  const handleSave = () => {
    arr2 = questionArr;
    arr2.push(questionValue);
    setQuestionArr(arr2);
    setQuestionValue("");
  };

  const handleRemove = (index) => {
    setQuestionArr(questionArr.filter((que)=>que!== questionArr[index]))
  };
//   const createRefResponse = () => {
//     setShowLoadingButton(true);
//     let bodyData = {
//       reflectiveId: props.refId,
//       answers: questionArr,
//       date: props?.headerDate,
//     };

//     trackingService
//       .refQuesCreate(outcomeState.token, bodyData)
//       .then((data: UpdateUserLevelModal) => {
//         setShowLoadingButton(false);
//         props.setShowSnakbar(true);
//         props.setSnakbarMsg(data?.message);
//         props.setOpenRefQuesModal(false);
//         props?.callService();
//         props.setMsgType("success");
//       })
//       .catch((error) => {
//         setShowLoadingButton(false);
//         props.setShowSnakbar(true);
//         props.setSnakbarMsg(
//           error?.response?.data?.description
//             ? error?.response?.data?.description
//             : error?.message
//         );
//         props.setMsgType("error");
//       });
//   };
//   useEffect(() => {
//     if (props?.isEdit) {
//       const outcomeGetData = {
//         reflectiveId: props.item?.reflectiveId,
//         date: props?.headerDate,
//       };
//       trackingService
//         .getRefQues(outcomeState.token, outcomeGetData)
//         .then((data) => {
//           setEditData(data?.data);
//           if (data?.data == null) {
//             props?.setIsEdit(false);
//           }
//           setQuestionArr(data.data.answers);
//           // setQuestionValue(data?.data?.answers)
//           setItem(data?.data);
//         })
//         .catch((error) => {
//           console.log("answer response get error");
//         });
//     }
    // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);
//   const setEditQuestionValue = (value, index) => {
//     let temp = [...questionArr];
//     temp[index] = value;
//     setQuestionArr(temp);
//   };

  const width = useWidth();

  return (
    <Grid>
      <Dialog
        // onClose={handleCloseStartModal}
        open={openStartModal}
        className="dialogWrapper dialogWrapper-mobileScreen"
        fullWidth
      >
        <DialogTitle className={classes.dialogTitle} sx={{ fontWeight: 500 }}>
          <span style={{fontSize: "20px"}}>Not accessible in mobile yet</span>
          <IconButton
            sx={{ position: "absolute", top: 0, right: 0 }}
            onClick={handleCloseStartModal}
            // onClick={() => navigate("/tracking")}
          >
            <ModalCloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {/* <div>
            <ul className={classes.listItemNotes}>
              {questionArr?.length > 0 &&
                questionArr?.map((que: string, index: number) => {
                  return (
                    <li key={index}>
                      <TextField
                        variant="outlined"
                        multiline={que.length > 30 ? true : false}
                        minRows={que.length > 30 ? 4 : 1}
                        className={width > 768 ? "fillTextInput" : ""}
                        placeholder="Add a answers..."
                        // onChange={(e) =>
                        //   setEditQuestionValue(e.target.value, index)
                        // }
                        value={que}
                      />
                       <Button onClick={() => handleRemove(index)}>
                       <ModalCloseIcon/>
                       </Button>
                    </li>
                  );
                })}
            </ul>
            <div style={{ display: "flex" }}>
              <TextField
                variant="outlined"
                className="textarea"
                fullWidth
                placeholder="Add a answers..."
                multiline={width > 768}
                minRows={4}
                onChange={(e) => setQuestionValue(e.target.value)}
                onKeyPress={(e) => handleKeyPress(e)}
                value={questionValue}
                style={{ paddingLeft: "10px" }}
              />
              {!(width > 768) && (
                <Button
                  style={{ marginBottom: "40px", marginLeft: "10px" }}
                  onClick={() => handleSave()}
                >
                  <span style={{transform: "rotate(-45deg)"}}> <ModalCloseIcon/></span>
                </Button>
              )}
            </div>
            <Box className={classes.bottomButton}>
              {!showLoadingButton ? (
                <Button
                  disabled={questionArr.length === 0}
                //   onClick={createRefResponse}
                  variant="contained"
                  className="button-after tracking-button"
                >
                  Add
                </Button>
              ) : (
                <Button
                  variant="contained"
                  className="button-after tracking-button"
                >
                  <ButtonLoader />
                </Button>
              )}
              <Button
                type="submit"
                variant="outlined"
                className="button-after tracking-button"
                // onClick={handleCloseStartModal}
                sx={{ color: palette.primary.light }}
              >
                Cancel
              </Button>
            </Box>
          </div> */}
          <Typography>
           {props.openUnavailablecategoryMessage}
          </Typography>
          <div style={{display: "flex",justifyContent: "center"}}>
          <div style={{width: "", height: "",backgroundColor: "#EBF2F1",marginTop: "20px", padding: "10px 20px", justifyContent: "center" }}>
             <Typography>
             {window.location.href}
             </Typography>
          </div>
          </div>
          <Typography style={{marginTop: "20px"}}>
          We apologize for any inconvenience this may cause and appreciate your understanding. Thank you for choosing TNT to support your personal growth journey!
          </Typography>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};
export default AddRefQuesModal;
