import React from "react"
const PlusIconSVG = ({ color = "#169C63", size="24" }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21 12H3" stroke={color} strokeWidth="3" strokeLinejoin="round"/>
        <path d="M12 3L12 21" stroke={color} strokeWidth="3" strokeLinejoin="round"/>
        </svg>
        
    )
}

export default PlusIconSVG