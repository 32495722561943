import { Grid, Typography, Box } from "@mui/material";
import * as React from "react";
import palette from "../../theme/palette";
const blogImage = require('../../assets/images/recomm_img.png')

const BlogListItem = () => {
  return (
    <Grid container>
      <Grid item xs={6}>
        <Box display="flex" alignItems="center">
          <Grid sx={{ width: "136px", height: "102px",marginBottom:'28px' }}>
            <img src={blogImage} alt="blog_image"/>
          </Grid>
          <Grid marginLeft="20px">
            <Typography variant="h6" sx={{ color: palette.primary.light }}>
              Keeping focus in extraordinary circumstances
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ color: palette.secondary.contrast, marginTop: "8px" }}
            >
              Sometimes the world throws us a fex curve balls and our ability to
              stay on task can be tested. In this post ...
            </Typography>
          </Grid>
        </Box>
        <Box display="flex" alignItems="center">
          <Grid sx={{ width: "136px", height: "102px",marginBottom:'28px' }}>
            <img src={blogImage} alt="blog_image" />
          </Grid>
          <Grid marginLeft="20px">
            <Typography variant="h6" sx={{ color: palette.primary.light }}>
              Keeping focus in extraordinary circumstances
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ color: palette.secondary.contrast, marginTop: "8px" }}
            >
              Sometimes the world throws us a fex curve balls and our ability to
              stay on task can be tested. In this post ...
            </Typography>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box display="flex" alignItems="center">
          <Grid sx={{ width: "136px", height: "102px",marginBottom:'28px' }}>
            <img src={blogImage} alt="blog_image" />
          </Grid>
          <Grid marginLeft="20px">
            <Typography variant="h6" sx={{ color: palette.primary.light }}>
              Keeping focus in extraordinary circumstances
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ color: palette.secondary.contrast, marginTop: "8px" }}
            >
              Sometimes the world throws us a fex curve balls and our ability to
              stay on task can be tested. In this post ...
            </Typography>
          </Grid>
        </Box>
        <Box display="flex" alignItems="center">
          <Grid sx={{ width: "136px", height: "102px",marginBottom:'28px' }}>
            <img src={blogImage} alt="blog_image" />
          </Grid>
          <Grid marginLeft="20px">
            <Typography variant="h6" sx={{ color: palette.primary.light }}>
              Keeping focus in extraordinary circumstances
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ color: palette.secondary.contrast, marginTop: "8px" }}
            >
              Sometimes the world throws us a fex curve balls and our ability to
              stay on task can be tested. In this post ...
            </Typography>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};
export default BlogListItem;
