import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { Button, Checkbox, CircularProgress, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { makeStyles } from "@material-ui/core";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DatePicker from "../Date/DatePicker";
import Activity from "../Scaler/Scaler";
import palette from "../../../theme/palette";
import Popover from "@mui/material/Popover";
import { userService } from "../../../services/auth";
import ReminderContent from "../ReminderContent/ReminderContent";
import moment from "moment";
import SnackBarComp from "../../../components/snackBar";

const useStyles = makeStyles((theme) => ({
  CenterContentOne: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
    width: '700px',

    // [theme.breakpoints.up("sm")]: {
    //   width: "295px",
    // },
  },

  subHeadingCenterContent: {
    display: "flex",
    alignItem: "center",
    justifyContent: "center",
    alignItems: "baseline",
    gap: "5px",
  },

  [theme.breakpoints.down("sm")]: {
    activity: {
      marginLeft: "0px",
    },
  },
}));

export default function SetupGoals({ isMenuSelected, setIsMenuSelected, goleData, setGoleData }) {
  const classes = useStyles();

  const popRef = useRef()
  const [xposition, setXposition] = useState(0);
  const [yposition, setYposition] = useState(0);
  const [isLoading, setIsLoading] = useState(false)

  //for get a value
  const [targetValue, setTargetValue] = useState(isMenuSelected?.getOutComeData?.target);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [weaklyReport, setWeaklyReport] = useState(isMenuSelected?.getOutComeData?.weeklyReport === "YES" ? true : false);
  const [reminderState, setReminderState] = useState({
    reminder: false,
    dayCount: 1,
    every: "WEEK",
    days: ["MONDAY"]
  });
  const [isAPiCall, setIsAPiCall] = useState(false)

  // snackbar
  const [showSnakbar, setShowSnakbar] = useState(false);
  const [snakbarMsg, setSnakbarMsg] = useState("");
  const [msgType, setMsgType] = useState("");


  const MainContent = styled(Box)(({ theme }) => ({
    margin: "40px 0 0 36px",
    display: "flex",
    flexDirection: "column",
    // height: "75vh",
    position: "relative",
    flex: 1,
    overflow: "auto",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: "0px",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginLeft: "0px",
      alignItems: "center",
    },
  }));

  const CenterContent = styled(Box)(({ theme }) => ({
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginTop: "40px",
    marginBottom: "40px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginLeft: "0px",
      alignItems: "center",
    },
  }));

  const TypographyHeading = styled(Typography)(({ theme }) => ({
    color: "#3E4240",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
      position: "relative",
    },
  }));

  const CalenderButton = styled(Button)(({ theme }) => ({
    color: "#3E4240",
    fontFamily: "Poppins",
    fontWeight: "400",
    fontSize: "16px",
    backgroung: "#DCE5E0",
    borderRadius: " 8px",
    border: "1px solid #DCE6E0",
    textTransform: "none",
    height: "40px",
    padding: "12px",
    width: '200px'
  }));
  const AvgCounterMainContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "24px",

    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
    },
  }));
  const AvgCounterContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  }));

  const SetGoalMainContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: "54px",

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      alignItems: "center",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginLeft: "0px",
      alignItems: "center",
    },
  }));
  const SetAvgGoalMainContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: "15px",
    marginTop: "24px",
  }));

  const ReminderMainContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    marginTop: "18px",
  }));
  const WeeklyReportMainContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    marginTop: "25px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px",
    },
  }));

  const SaveGoalBtn = styled(Button)(({ theme }) => ({
    [theme.breakpoints.down("xl")]: {
      marginBottom: "0px!important",
    },
    [theme.breakpoints.up("xl")]: {
      marginBottom: "0px!important",
    },
  }));

  const CustomCheckBox = styled(Checkbox)(({ theme }) => ({
    color: "#DCE6E0",
    borderRadius: "4px",
    paddingLeft: '0px',
    "&.Mui-checked": {
      color: "#11734A",
      borderRadius: "4px",
    },
  }));

  const handleClick = () => {
    const token = localStorage.getItem("userDetails");
    let body;

    if (reminderState.reminder) {
      body = {
        target: parseInt(targetValue),
        weeklyReport: weaklyReport ? "YES" : "NO",
        outcomeId: isMenuSelected?.outcomeId,
        reminder: [
          {
            every: reminderState?.every,
            count: parseInt(reminderState?.dayCount),
            days: reminderState?.days
          }
        ]
      }
    } else {
      body = {
        target: parseInt(targetValue),
        weeklyReport: weaklyReport ? "YES" : "NO",
        outcomeId: isMenuSelected?.outcomeId,
      }
    }

    if (isMenuSelected?.getOutComeData?.length === 0 || isMenuSelected?.getOutComeData === undefined) {
      userService
        .outComeGoalsCreate(body, token)
        .then((data) => {
          setShowSnakbar(true);
          setMsgType("success");
          setSnakbarMsg(data?.message);
          setTimeout(() => {
            setIsMenuSelected({
              ...isMenuSelected,
              getOutComeData: data?.data,
            })
          }, 3000)

        })
        .catch((error) => {
          setShowSnakbar(true);
          setMsgType("error");
          setSnakbarMsg(error?.response?.data?.description);
        });
    } else {
      body = {
        ...body,
        outcomeGoalId: isMenuSelected?.getOutComeData?.outcomeGoalId
      }
      delete body.outcomeId
      userService
        .outComeGoalsUpdate(body, token)
        .then((data) => {
          setIsAPiCall(true)
          setShowSnakbar(true);
          setMsgType("success");
          setSnakbarMsg(data?.message);

        })
        .catch((error) => {
          setShowSnakbar(true);
          setMsgType("error");
          setSnakbarMsg(error?.response?.data?.description);
        });
    }
  }

  const handleCloseSnakbar = () => {
    setShowSnakbar(false);
  };

  useEffect(() => {
    if ((isMenuSelected?.outComeData?.length === 0 && isMenuSelected.outcome) || isAPiCall) {
      const token = localStorage.getItem("userDetails")
      let body = {
        from: moment().subtract(1, 'months').startOf('month').format('MM-DD-YYYY'),
        to: moment().subtract(1, 'months').endOf('month').format('MM-DD-YYYY'),
        outcomeId: isMenuSelected?.outcomeId
      }
      setIsLoading(true)
      userService
        .outComeGoals(body, token)
        .then((data) => {
          setIsMenuSelected({
            ...isMenuSelected,
            outComeData: data?.outcome,
            getOutComeData: data?.data[0],
            selectedOutcomeAvgData: data?.average
          })
          setShowSnakbar(true);
          setMsgType("error");
          setSnakbarMsg(data?.message);
        })
        .catch((error) => {
          setShowSnakbar(true);
          setMsgType("error");
          setSnakbarMsg(error?.response?.data?.description);
        }).finally(() => {
          setIsLoading(false)
        })
    }
  }, [isMenuSelected?.outcomeId, isAPiCall])

  useEffect(() => {
    if (!!isMenuSelected?.getOutComeData?.reminder?.length) {
      setReminderState({
        ...reminderState,
        reminder: isMenuSelected?.getOutComeData?.reminder?.length !== 0,
        dayCount: isMenuSelected?.getOutComeData?.reminder?.length !== 0 ? isMenuSelected?.getOutComeData?.reminder[0]?.count : 1,
        every: isMenuSelected?.getOutComeData?.reminder?.length !== 0 ? isMenuSelected?.getOutComeData?.reminder[0]?.every : "WEEK",
        days: isMenuSelected?.getOutComeData?.reminder?.length !== 0 ? isMenuSelected?.getOutComeData?.reminder[0]?.days : ["MONDAY"]
      })
    }
  }, [isMenuSelected])

  const handleClickPopover = (event) => {
    setXposition(popRef.current.getBoundingClientRect().left)
    setYposition(popRef.current.getBoundingClientRect().top + 40)
    setAnchorEl(event.currentTarget);
    setOpenPopover(true)
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpenPopover(false)
  }

  const [openPopover, setOpenPopover] = useState(false)
  return (
    <>
      {
        isLoading ? <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "calc(100vw - 30vw)", height: '100%' }}>
          <CircularProgress color="success" />
        </div> : <MainContent>
          <TypographyHeading variant="h3">
            Set a Goal: {isMenuSelected?.outComeData?.name}
          </TypographyHeading>
          <SnackBarComp
            showSnakbar={showSnakbar}
            autoHideDuration={6000}
            handleCloseSnakbar={handleCloseSnakbar}
            snakbarMsg={snakbarMsg}
            type={msgType ? msgType : "info"}
          />
          <CenterContent>
            <div className={classes.CenterContentOne}>
              <Typography variant="body1" sx={{ color: palette.primary.light }}>
                Current state
              </Typography>
              <CalenderButton onClick={handleClickPopover} ref={popRef}>
                <Typography
                  variant="caption"
                  sx={{ color: palette.primary.light }}
                >
                  {localStorage.getItem("selectedRadio") !== null ? localStorage.getItem("selectedRadio") === "customRange" ? "Custom Range" : `Last ${localStorage.getItem("selectedRadio")}` : "Last week"}
                </Typography>
                <KeyboardArrowDownIcon sx={{ color: "#11734A" }} />
              </CalenderButton>

              <Popover
                sx={{ maxHeight: "calc(100vh - 190px)", }}
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorReference="anchorPosition"
                anchorPosition={{ top: yposition, left: xposition }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <DatePicker
                  outcomeId={isMenuSelected?.outcomeId}
                  isMenuSelected={isMenuSelected}
                  setIsMenuSelected={setIsMenuSelected}
                />
              </Popover>

            </div>

            <AvgCounterMainContainer>
              <AvgCounterContainer>
                <div>
                  <Typography
                    variant="h3"
                    sx={{ fontWeight: "600", color: palette.primary.light }}
                  >
                    {isMenuSelected?.selectedOutcomeAvgData?.length > 0 ? isMenuSelected?.selectedOutcomeAvgData[0]?.avg?.toFixed(1) : "N/A"}
                  </Typography>
                </div>

                <Typography
                  variant="subtitle1"
                  sx={{
                    color: palette.secondary.contrast,
                    lineHeight: "115%",
                    marginTop: "15px",
                    width: "108px",
                  }}
                >
                  Your avg.in last month{" "}
                </Typography>
              </AvgCounterContainer>

              <Activity isMenuSelected={isMenuSelected} />
            </AvgCounterMainContainer>

            <SetGoalMainContainer>
              <Typography variant="body1" sx={{ color: palette.primary.light }}>
                Set a goal
              </Typography>

              <SetAvgGoalMainContainer>
                <Typography
                  variant="caption"
                  sx={{ color: palette.primary.light }}
                >
                  Set your target avg.
                </Typography>
                <TextField
                  sx={{ width: '95px !important' }}
                  className="fillTextInput"
                  placeholder="1-5"
                  type="number"
                  variant="outlined"
                  name="dayCount"
                  value={targetValue}
                  onChange={e => (Number(e.target.value) > 5 || Number(e.target.value) < 0) ? '' : setTargetValue(e.target.value)}
                  InputProps={{
                    inputProps: {
                      max: 5, min: 1
                    }
                  }}
                />

              </SetAvgGoalMainContainer>

              <ReminderMainContainer>
                <CustomCheckBox
                  checked={reminderState?.reminder}
                  onChange={e => setReminderState({
                    ...reminderState,
                    reminder: e.target.checked
                  })}
                />
                <Typography
                  variant="caption"
                  sx={{ color: palette.primary.light }}
                >
                  Reminder
                </Typography>
              </ReminderMainContainer>

              {
                reminderState?.reminder &&
                <ReminderContent reminderState={reminderState} setReminderState={setReminderState} />
              }

              <WeeklyReportMainContainer>
                <CustomCheckBox
                  checked={weaklyReport}
                  onChange={e => setWeaklyReport(e.target.checked)} />
                <Typography
                  variant="caption"
                  sx={{ color: palette.primary.light }}
                >
                  Included in weekly report
                </Typography>
              </WeeklyReportMainContainer>
            </SetGoalMainContainer>

            <SaveGoalBtn
              type="submit"
              variant="contained"
              className="button-after outcomeButton"
              sx={{ mt: "39px", width: "240px !important" }}
              disabled={!targetValue}
              onClick={handleClick}
            >
              Save goal
            </SaveGoalBtn>
          </CenterContent>
        </MainContent>
      }


    </>
  );
}
