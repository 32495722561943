import { Alert, Snackbar, SxProps, Theme } from "@mui/material";
import * as React from "react";

interface SnackBarCompModal {
  showSnakbar: boolean;
  handleCloseSnakbar: any;
  snakbarMsg: string | React.ReactNode;
  type: any;
  sx?: SxProps<Theme>;
  alertSx?: SxProps<Theme>;
  icon?: React.ReactNode
}

const SnackBarComp = (props: SnackBarCompModal) => {
  return (
    <Snackbar
      open={props.showSnakbar}
      autoHideDuration={3000}
      onClose={props.handleCloseSnakbar}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert severity={props.type}>{props.snakbarMsg}</Alert>
    </Snackbar>
  );
};
export default SnackBarComp;

export const SnackBarCompV2 = (props: SnackBarCompModal) => {
  const extraProps: any = {}
  if(props.icon) {
    extraProps.icon = props.icon
  }
  return (
    <Snackbar
      open={props.showSnakbar}
      autoHideDuration={3000}
      onClose={props.handleCloseSnakbar}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      sx={props.sx ? props.sx : {}}
    >
      <Alert severity={props.type} {...extraProps} sx={props.alertSx ? props.alertSx : {}}>{props.snakbarMsg}</Alert>
    </Snackbar>
  );
};
