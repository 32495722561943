import React from 'react'

export default function ArrowUpWhite() {
  return (
    <>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2276_37691)">
          <path d="M7.41 15.4102L12 10.8302L16.59 15.4102L18 14.0002L12 8.00016L6 14.0002L7.41 15.4102Z" fill="white" />
        </g>
        <defs>
          <clipPath id="clip0_2276_37691">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>


    </>
  )
}