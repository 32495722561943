import React from "react"

const BellIcon = ({ isMobile = false }) => {
  if (isMobile) {
    return <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.333 8.88542C20.333 7.11731 19.6307 5.42161 18.3804 4.17137C17.1302 2.92113 15.4345 2.21875 13.6664 2.21875C11.8983 2.21875 10.2026 2.92113 8.95234 4.17137C7.70209 5.42161 6.99972 7.11731 6.99972 8.88542C6.99972 16.6632 3.66638 18.8854 3.66638 18.8854H23.6664C23.6664 18.8854 20.333 16.6632 20.333 8.88542Z" stroke="#5E5F5E" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M15.5948 23.3335C15.3995 23.6702 15.1191 23.9498 14.7817 24.1441C14.4444 24.3384 14.0619 24.4407 13.6726 24.4407C13.2833 24.4407 12.9008 24.3384 12.5634 24.1441C12.2261 23.9498 11.9457 23.6702 11.7504 23.3335" stroke="#5E5F5E" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

  }
  return <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.1202 16.0559C20.3065 14.6562 19.8743 12.6472 19.8743 10.25C19.8743 8.16142 19.0446 6.15838 17.5678 4.68153C16.0909 3.20469 14.0879 2.375 11.9993 2.375C9.91071 2.375 7.90768 3.20469 6.43083 4.68153C4.95398 6.15838 4.12429 8.16142 4.12429 10.25C4.12429 12.6481 3.69398 14.6562 2.88023 16.0559C2.71418 16.3414 2.62616 16.6656 2.62501 16.9958C2.62386 17.3261 2.70962 17.6509 2.87367 17.9375C3.03668 18.2243 3.27329 18.4624 3.55906 18.6272C3.84484 18.792 4.16942 18.8775 4.49929 18.875H7.89117C7.98419 19.9003 8.45724 20.8537 9.21738 21.548C9.97752 22.2423 10.9698 22.6273 11.9993 22.6273C13.0288 22.6273 14.0211 22.2423 14.7812 21.548C15.5413 20.8537 16.0144 19.9003 16.1074 18.875H19.4993C19.8287 18.877 20.1527 18.7913 20.4379 18.6265C20.7232 18.4617 20.9593 18.2239 21.1221 17.9375C21.2869 17.6513 21.3734 17.3267 21.3731 16.9965C21.3728 16.6662 21.2856 16.3418 21.1202 16.0559ZM11.9993 20.375C11.567 20.3751 11.148 20.2258 10.8132 19.9525C10.4784 19.6791 10.2482 19.2985 10.1618 18.875H13.8368C13.7503 19.2985 13.5202 19.6791 13.1854 19.9525C12.8505 20.2258 12.4315 20.3751 11.9993 20.375ZM5.12367 16.625C5.95336 14.9375 6.37429 12.7944 6.37429 10.25C6.37429 8.75816 6.96693 7.32742 8.02182 6.27252C9.07671 5.21763 10.5075 4.625 11.9993 4.625C13.4911 4.625 14.9219 5.21763 15.9768 6.27252C17.0317 7.32742 17.6243 8.75816 17.6243 10.25C17.6243 12.7934 18.0443 14.9375 18.874 16.625H5.12367Z" fill="#656565" />
  </svg>

}

export default BellIcon