/*eslint eqeqeq: "off"*/
import React, { useState, useContext, useEffect, useCallback } from "react";
import {
  Typography,
  Grid,
  TextField,
  Button,
  MenuItem,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  Dialog,
  Menu,
  IconButton
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core";
import GetIdeasIcon from "../../assets/images/icon/getIdeas";
import palette from "../../theme/palette";
import BottomStepper from "../outcome/BottomStepper";
import {
  InfluenceGetResponse,
  InfluenceOption,
} from "../../interfaces/outcomeModal";
import {
  fixOutcomeColorPalette,
  InfluenceSelectOption,
  MeasurementUnit,
  ProUserStepper,
  UserStepper,
} from "../../utils/staticDataJson";
import clsx from "clsx";
import ColorPickerDialog from "../outcome/colorPickerDialog";
import ProgressBarStepper from "../outcome/progressBarStepper";
import IdeasDrawer from "./ideasDrawer";
import shape from "../../theme/shape";
import { influenceService } from "../../services/influence";
import { UserTypeContext } from "../../context/userStatus";
import {
  addCategoryJourneyAction,
  addInfluenceAction,
  addJourneyAction,
  setUserStatus,
} from "../../store/actions/userTypeAction";
import SnackBarComp from "../../components/snackBar";
import { ButtonLoader } from "../../components/buttonLoader";
import moment from "moment";
import { trackingService } from "../../services/tracking";
import AddIcon from "@mui/icons-material/Add";
import AddPopOver from "./AddPopOver";
import { commanColorPalette } from "../../utils/staticDataJson";
import { userService } from "../../services/auth";
import Donts from "../../assets/images/icon/Dots";
import Tooltip from "@mui/material/Tooltip";
import ModalCloseIcon from "../../assets/images/icon/modalCloseIconBlack";
import DeleteIcon from "../../assets/images/icon/DeleteIcon";
import Question from "../../assets/images/icon/Question";
import { useNavigate } from "react-router-dom";
import SwitchToOutcomeModal from "../tracker/switchToOutcomeModal";
import SwitchInInfluenceModal from "../tracker/switchInInfluenceModal";
import useWidth from "../../utils/useWidth";
import _debounce from "lodash/debounce";
import ViewNote from "../../assets/images/icon/ViewNote";
import Wellbeing from "../outcome/Wellbeing";
import StepperNextArrowDisable from "../../assets/images/icon/stepperNextArrowDisable";
import StepperNextArrow from "../../assets/images/icon/stepperNextArrow";
import StepperBackArrowDisable from "../../assets/images/icon/stepperBackArrowDisable";
import StepperBackArrow from "../../assets/images/icon/stepperBackArrow";

const RootStyle = styled("div")(({ theme }) => ({
  padding: "60px 48px 60px 80px",
  position: "relative",
  height: "100vh",
  [theme.breakpoints.down("xl")]: {
    padding: "50px 40px 50px 60px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "40px 20px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "20px",
  },
}));

const useStyles = makeStyles((theme) => ({
  registrationHeading: {
    color: palette.primary.light,
  },
  pageTitle: {
    marginTop: "40px!important",
    marginBottom: "48px!important",
    [theme.breakpoints.down("lg")]: {
      marginTop: "30px!important",
      marginBottom: "38px!important",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "20px!important",
      marginBottom: "28px!important",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "15px!important",
      marginBottom: "20px!important",
    },
  },
  needSomeIdeas: {
    fontSize: "16px",
    lineHeight: "16px",
    color: palette.primary.main,
    marginLeft: "8px",
    [theme.breakpoints.down("md")]: {
      marginTop: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "14px",
    },
  },
  selectWrapper: {
    marginTop: "49px",
    [theme.breakpoints.down("md")]: {
      marginTop: "30px",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
    },
  },
  influenceCaption: {
    marginBottom: "20px!important",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "10px!important",
    },
  },
  colorBlock: {
    cursor: "pointer",
    backgroundColor: palette.secondary.light,
    border: "1px solid #DCE6E0",
    borderRadius: shape.borderRadius,
    padding: "11px 16px",
    display: "flex",
    alignItems: "center",
    marginLeft: "16px",
    [theme.breakpoints.down("lg")]: {
      marginLeft: 0,
    },
  },
  spaceBetween: {
    [theme.breakpoints.down("lg")]: {
      marginTop: "10px",
      marginBottom: "10px",
    },
  },
  colorBlockText: {
    margin: 0,
    color: palette.primary.light,
    fontSize: "16px",
    lineHeight: "16px",
    fontWeight: 500,
  },
  colorCode: {
    width: "24px",
    height: "24px",
    marginLeft: "10px",
    borderRadius: shape.borderRadiusSm,
  },
  addButton: {
    backgroundColor: "#F9D412 !important",
    borderRadius: " 30px !important",
    padding: "10px 12px !important",
    marginBottom: "0 !important",
  },
  minButton: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    "&:after": {
      content: "''",
      position: "absolute",
      display: "inline-block",
      left: "31px",
      top: "39px",
      border: "0.1px solid #CADFD2",
      width: "90%",
    },
  },
  iconButton: {
    color: "#fff",
    zIndex: 10,
    height: "30px",
    width: "30px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  ideasListItem: {
    backgroundColor: palette.common.white,
    borderRadius: shape.borderRadius,
    padding: "24px",
    fontSize: "16px",
    lineHeight: "16px",
    color: palette.primary.light,
    fontWeight: 400,
    marginBottom: "12px",
    cursor: "pointer",
  },
}));

const ariaLabel = { "aria-label": "description" };

interface LeftSideInfluenceModal {
  setInfluenceData: (influenceData: any) => void;
  influenceData: any;
  influenceIdeas: any;
  showInfluence: boolean;
  setAddInfluenceTracking: (addInfluenceTracking: boolean) => void;
  setShowInfluence: (showInfluence: boolean) => void;
  setTextInput: any;
  textInput: any;
  handleCloseStartModal?: any;
  getInfluenceList?: any;
  categoryId?: any;
  setTransformToOutcomeTracking?: any;
  transformToOutcomeTracking?: any;
  setTransformInInfluenceTracking?: any;
  transformInInfluenceTracking?: any;
  width?: any;
  setShowSnakbar?: any;
  setSnakbarMsg?: any;
  setMsgType?: any;
  startDate?: any
  endDate?: any 
  onboarding?: boolean
}

const LeftSideInfluence = (props: LeftSideInfluenceModal) => {
  //AddPopOverComponent States
  const [value, setValue] = useState<string>("");
  const [desc, setDesc] = useState<string>("");
  const [color, setColor] = useState<string>("#F17F79");
  const [colorPalette, setColorPalette] = useState(commanColorPalette);
  const [textFieldValues, setTextFieldValues] = useState([
    {
      id: 1,
      colorCode: "#F17F79",
      text: "min",
      value: "",
      temp: true,
    },
    {
      id: 2,
      colorCode: "#169C63",
      text: "max",
      value: "",
      temp: true,
    },
  ]);
  const [matchValue, setMatchValue] = useState(false);

  const classes = useStyles();
  const [influenceInput, setInfluenceInput] = useState<any>();
  const [unitValue, setUnitValue] = useState("");
  const [unitValueOption, setUnitValueOption] = useState<any>();
  const [influenceOption, setInfluenceOption] = useState<any>("");
  const [flagValue, setFlagValue] = useState("");
  const [openColor, setOpenColor] = useState(false);
  const [selectedColor, setSelectedColor] = useState<any>(
    fixOutcomeColorPalette[0]
  );
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [IsInverse, setIsInverse] = useState("false");
  const [editStatus, setEditStatus] = useState(null);
  // const [showSnakbar, setShowSnakbar] = useState(false);
  // const [snakbarMsg, setSnakbarMsg] = useState('');
  // const [msgType, setMsgType] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [nodeCount, setNodeCount] = useState(0);
  const [showIdeasMobile, setShowIdeasMobile] = useState(false);
  //cateScaler State
  const [cateScaler, setCateScaler] = useState("NumericValue");
  const [neutralScaler, setNeutralScaler] = useState("");
  const [openPopupinEditMode, setOpenPopupinEditMode] = useState({
    status: false,
    nodeId: "",
  });
  const [editableNode, setEditableNode] = useState({});
  const [openSwitchOutcomeModal, setopenSwitchOutcomeModal] = useState(false);
  const [openSwitchInInfluenceModal, setopenSwitchInInfluenceModal] =
    useState(false);
  const userLevels = JSON.parse(localStorage.getItem("userValues"))
  const [activeStep, setActiveStep] = React.useState(
    false ? 2 : true ? 1 : 0
  );
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };
  const userType =
    userLevels?.level == "HIGH" ? ProUserStepper : UserStepper;

  const { state: outcomeState, state: IUserTypeState, dispatch: userTypeDispatch } =
    useContext(UserTypeContext);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);
  const [outcomeDesc, setOutcomeDesc] = useState("");

  const navigate = useNavigate();
  const [openWellbeing, setopenWellbeing] = useState(false);

  const compareDate = parseInt(moment().format("D"));
  const startDay =
    props.startDate ? moment(props.startDate) : moment().clone().subtract(10, "day");
  const endDay =
    props.endDate ? moment(props.endDate) : moment().clone().add(10, "day");
  const [suggestions, setSuggestions] = useState([]);

  const handleChangeWithLib = (value) => {
    let bodyData = {
      skip: 0,
      limit: 20,
      search: value,
    };
    trackingService
      .influenceSearch(outcomeState.token, bodyData)
      .then((res) => setSuggestions(res.data));
  };

  const debounceFn = useCallback(_debounce(handleChangeWithLib, 500), []);

  const NativeOutcomeStatus = [
    { text: "very low" },
    { text: "low" },
    { text: "medium" },
    { text: "high" },
    { text: "very high" },
  ];

  // const data = {
  //   id: new Date(),
  //   value: parseInt(props.value),
  //   text: props.desc,
  //   colorCode: props.color,
  //   answerId: ""
  // }

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if (userLevels?.level == "HIGH") {
      if (activeStep != userType.length - 1) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    let nextStep = activeStep + 1;
    setSkipped(newSkipped);
    handleSliderNextValue(nextStep);
  };
  const handleSignincompelted = () => {
    const obj = {
      mixpanel_type: "SIGNUP_COMPLETED"
    };
    trackingService
      .signupCompletedBoarding(outcomeState.token, obj);
  }
  const handleSliderNextValue = (step: number) => {
    if (userLevels?.level == "HIGH") {
      if (step == 3) {
        userTypeDispatch(setUserStatus(true));
        handleSignincompelted()
        navigate("/tracking", { replace: true });
      }
      if (step == 2) {
        navigate("/reflective-questions", { replace: true });
      } else if (step == 1) {
        navigate("/influence", { replace: true });
      }
    } else {
      if (step == 1) {
        navigate("/influence", { replace: true });
      }
      if (step == 2) {
        userTypeDispatch(setUserStatus(true));
        handleSignincompelted()
        navigate("/tracking", { replace: true });
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    const backStep = activeStep - 1;
    handleSliderValue(backStep);
  };

  const handleSliderValue = (step: number) => {
    if (userLevels?.level == "HIGH") {
      if (step == 1) {
        navigate("/influence", { replace: true });
      } else if (step == 0) {
        navigate("/outcome", { replace: true });
      }
    } else {
      if (step === 0) {
        navigate("/outcome", { replace: true });
      }
    }
  };

  const handleCloseStartModal = () => {
    setShowIdeasMobile(false);
  };

  const handleChangeInfluence = (e: any) => {
    setInfluenceInput(e.target.value)
    debounceFn(e.target.value);
  };

  useEffect(() => {
    setOpenDrawer(false);
    setInfluenceInput(props?.influenceData?.name);
    setInfluenceOption(
      props?.influenceData?.type === "TYPE12"
        ? "TYPE1"
        : props?.influenceData?.type
    );
    setOutcomeDesc(props?.influenceData?.description);
    setUnitValueOption(props?.influenceData?.unitValue);
    setUnitValue(props?.influenceData?.unit);
    setFlagValue(props?.influenceData?.flag);
    setIsInverse(props?.influenceData?.inverse);
    props?.setTextInput(
      props.influenceData?.options?.length > 0
        ? props.influenceData?.options
        : NativeOutcomeStatus
    );
    setSelectedColor(
      props.influenceData?.options?.length > 0
        ? props.influenceData?.options
        : fixOutcomeColorPalette[0]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.influenceData]);

  useEffect(() => {
    if (
      props?.influenceData?.options?.length !== 0 &&
      props.influenceData?.uid
    ) {
      const newOptionArray = props?.influenceData?.options?.map((option) => {
        return {
          id: new Date(),
          ...option,
        };
      });
      setTextFieldValues(newOptionArray);
      setNodeCount(props?.influenceData?.options?.length)
    } else {
      setTextFieldValues([
        {
          id: 1,
          colorCode: "#F17F79",
          text: "min",
          value: "",
          temp: true,
        },
        {
          id: 2,
          colorCode: "#169C63",
          text: "max",
          value: "",
          temp: true,
        },
      ]);
    }
    if (props.influenceData?.uid && props.influenceData?.type === "TYPE1") {
      setCateScaler("yes/no");
    } else if (
      props.influenceData?.uid &&
      props.influenceData?.type === "TYPE12"
    ) {
      if (props.influenceData?.metric_type === "NEUTRAL") {
        setNeutralScaler("NumericValue");
      } else {
        setCateScaler("NumericValue");
      }
    }
  }, [props.influenceData]);

  const callService = async () => {
    try {
      const bodyData = {
        from: moment(startDay).format("MM-DD-YYYY"),
        to: moment(endDay).format("MM-DD-YYYY"),
      };
      if (!outcomeState?.isPersonal) {
        let data = await trackingService.getJourneyList(
          outcomeState.token,
          bodyData
        );
        userTypeDispatch(addJourneyAction(data));
      } else {
        let data = await trackingService.getPersonalJourneyList(
          outcomeState.token,
          bodyData
        );
        userTypeDispatch(addCategoryJourneyAction(data?.data));
      }
    } catch (error) {
      console.log("jourey list error");
    }
  };

  const handleClickOpenColor = () => {
    setOpenColor(true);
  };

  const handleCloseColor = (value: any) => {
    setOpenColor(false);
    if (value.id === -1) {
      return;
    }
    setSelectedColor(value);
  };
  // ADD INFLUENCE
  const AddInfluences = () => {
    setShowLoader(true);
    let influenceData;
    let influenceOptionata;

    if (influenceOption === "TYPE1") {
      // if (unitValue == undefined || unitValue == "") {
      //   setShowLoader(false);
      //   setShowSnakbar(true);
      //   setSnakbarMsg("Please enter unit value");
      //   setMsgType("error");
      //   return;
      // } else
      if (unitValueOption == undefined || unitValueOption == "") {
        setShowLoader(false);
        props?.setShowSnakbar(true);
        props?.setSnakbarMsg("Please select unit of measurement");
        props?.setMsgType("error");
        return;
      } else if (flagValue == undefined || flagValue == "") {
        setShowLoader(false);
        props?.setShowSnakbar(true);
        props?.setSnakbarMsg("Please select enter value");
        props?.setMsgType("error");
        return;
      }
      if (textFieldValues.length > 0) {
        influenceOptionata = textFieldValues.map((item) => {
          if (!textFieldValues[0].temp || !textFieldValues[1].temp) {
            delete item.id;
            return item;
          }
        });
      }
      if (neutralScaler === "NumericValue") {
        if (props.influenceData?.uid) {
          influenceData = {
            name: influenceInput,
            icon: "",
            type: "TYPE12",
            flag: flagValue,
            description: outcomeDesc,
            unitValue: unitValueOption,
            unit: null,
            options:
              flagValue === "no"
                ? textFieldValues[0].temp
                  ? []
                  : influenceOptionata
                : [],
            influenceId: props.influenceData?.influenceId,
            metric_type: "NEUTRAL",
          };
        } else {
          influenceData = {
            name: influenceInput,
            icon: "",
            type: "TYPE12",
            flag: flagValue,
            description: outcomeDesc,
            unitValue: unitValueOption,
            unit: null,
            options:
              flagValue === "no"
                ? textFieldValues[0].temp
                  ? []
                  : influenceOptionata
                : [],
            metric_type: "NEUTRAL",
          };
        }
      } else {
        if (props.influenceData?.uid) {
          influenceData = {
            name: influenceInput,
            description: outcomeDesc,
            icon: "",
            type: flagValue === "yes" ? "TYPE1" : "TYPE12",
            flag: flagValue,
            unitValue: unitValueOption,
            unit: null,
            options:
              flagValue === "no"
                ? textFieldValues[0].temp
                  ? []
                  : influenceOptionata
                : [],
            influenceId: props.influenceData?.influenceId,
          };
        } else {
          influenceData = {
            name: influenceInput,
            description: outcomeDesc,
            icon: "",
            type: flagValue === "yes" ? "TYPE1" : "TYPE12",
            flag: flagValue,
            unitValue: unitValueOption,
            unit: null,
            options:
              flagValue === "no"
                ? textFieldValues[0].temp
                  ? []
                  : influenceOptionata
                : [],
          };
        }
      }
    } else if (influenceOption === "TYPE2") {
      var result: any = selectedColor;
      if (props.influenceData?.uid && selectedColor.length > 0) {
        result = selectedColor?.map((color: any, index: number) => ({
          text: props?.textInput[index]?.text,
          colorCode: color.colorCode,
          answerId: color.answerId,
        }));
      } else {
        result = selectedColor?.color?.map((color: any, index: number) => ({
          text: props?.textInput[index]?.text,
          colorCode: color,
          answerId:
            props?.influenceData &&
            props?.influenceData?.options[index]?.answerId,
        }));
        if (result === undefined) {
          result = selectedColor?.map((color: any, index: number) => ({
            text: props?.textInput[index]?.text,
            colorCode: color.colorCode,
            answerId: color.answerId,
          }));
        }
      }
      if (props.influenceData?.uid) {
        influenceData = {
          name: influenceInput,
          description: outcomeDesc,
          icon: "",
          type: "TYPE2",
          options: result,
          influenceId: props.influenceData?.influenceId,
        };
      } else {
        influenceData = {
          name: influenceInput,
          description: outcomeDesc,
          icon: "",
          type: "TYPE2",
          options: result,
        };
      }
    } else if (influenceOption == "TYPE3") {
      if (props.influenceData?.uid) {
        influenceData = {
          name: influenceInput,
          description: outcomeDesc,
          icon: "",
          type: "TYPE3",
          flag: flagValue,
          inverse: IsInverse,
          options: [],
          influenceId: props.influenceData?.influenceId,
        };
      } else {
        influenceData = {
          name: influenceInput,
          description: outcomeDesc,
          icon: "",
          type: "TYPE3",
          inverse: IsInverse,
          flag: flagValue,
          options: [],
        };
      }
    }
    if (props.influenceData?.uid) {
      // EDIT INFLUENCE
      influenceService
        .editInfluence({
          ...influenceData,
          mixpanel_type: "ON_BOARDING"
        }, outcomeState.token)
        .then((response: any) => {
          influenceService
            .getInfluenceList(outcomeState.token)
            .then((data: InfluenceGetResponse) => {
              if (IUserTypeState.isPersonal) {
                const journeyList = [...outcomeState?.journeyCategoryList];
                const foudnInJourney = journeyList.findIndex(it => it.influenceIds.includes(props.influenceData?.influenceId))
                if (foudnInJourney > -1) {
                  const foundInOutcomes = journeyList[foudnInJourney].alls.findIndex(it => it.influenceId === props.influenceData?.influenceId)
                  if (foundInOutcomes > -1) {
                    journeyList[foudnInJourney].alls[foundInOutcomes] = {
                      ...journeyList[foudnInJourney].alls[foundInOutcomes],
                      ...influenceData
                    }
                    userTypeDispatch(addCategoryJourneyAction(journeyList));
                  }
                } else {
                  const foudnOtherJourney = journeyList.findIndex(it => it.name === "Other")
                  if (foudnOtherJourney > -1) {
                    const foundInOutcomes = journeyList[foudnOtherJourney].influences.findIndex(it => it.influenceId === props.influenceData?.influenceId)
                    if (foundInOutcomes > -1) {
                      journeyList[foudnOtherJourney].alls[foundInOutcomes] = {
                        ...journeyList[foudnOtherJourney].alls[foundInOutcomes],
                        ...influenceData
                      }
                      userTypeDispatch(addCategoryJourneyAction(journeyList));
                    }
                  }
                }
              } else {
                const allOutcomes = [...outcomeState?.influenceList];
                const foundOutcomeIndex = allOutcomes.findIndex(it => it.influenceId === props.influenceData?.influenceId);
                if (foundOutcomeIndex > -1) {
                  allOutcomes[foundOutcomeIndex] = {
                    ...allOutcomes[foundOutcomeIndex],
                    ...influenceData
                  }
                  userTypeDispatch(addInfluenceAction(allOutcomes));
    
                }
              }
              props.setShowInfluence(false);
              setInfluenceInput("");
              setInfluenceOption("");
              setUnitValueOption("");
              setOutcomeDesc("");
              setUnitValue("");
              setFlagValue("");
              props.setInfluenceData("");
              props?.setTextInput(NativeOutcomeStatus);
              props?.setShowSnakbar(true);
              props?.setSnakbarMsg(response.message);
              props?.setMsgType("success");
            });
        })
        .catch((error) => {
          setShowLoader(false);
          props?.setShowSnakbar(true);
          props?.setSnakbarMsg(
            error?.response?.data?.description
              ? error?.response?.data?.description
              : error.message
          );
          props?.setMsgType("error");
        });
    } else {
      // CREATE INFLUENCE
      influenceService
        .createInfluence(
          { ...influenceData, categoryId: props?.categoryId,
            mixpanel_type: "ON_BOARDING" },
          outcomeState.token
        )
        .then((response: any) => {
          setShowLoader(false);
          outcomeState.influenceList.push(response?.data);
          const bodyData = {
            from: moment(startDay).format("MM-DD-YYYY"),
            to: moment(endDay).format("MM-DD-YYYY"),
          };
          trackingService
            .getPersonalJourneyList(outcomeState.token, bodyData)
            .then((data) => {
              userTypeDispatch(addCategoryJourneyAction(data?.data));
              userTypeDispatch(addInfluenceAction(outcomeState.influenceList));
              props?.setShowSnakbar(true);
              props?.setSnakbarMsg(response.message);
              props?.setMsgType("success");
              props.setAddInfluenceTracking(false);
              setInfluenceInput("");
              setInfluenceOption("");
              setOutcomeDesc("");
              setUnitValueOption("");
              setUnitValue("");
              setFlagValue("");
              props.setInfluenceData("");
              props?.setTextInput(NativeOutcomeStatus);
            });
          // setTimeout(() => {

          // }, 1000);
        })
        .catch((error) => {
          setShowLoader(false);
          props?.setShowSnakbar(true);
          props?.setSnakbarMsg(
            error?.response?.data?.description
              ? error?.response?.data?.description
              : error.message
          );
          props?.setMsgType("error");
        });
    }
  };
  const showDrawer = () => {
    setOpenDrawer(true);
  };
  const handleCloseSnakbar = () => {
    props?.setShowSnakbar(false);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    if (openPopupinEditMode?.status) {
      setOpenPopupinEditMode({
        status: false,
        nodeId: "",
      });
      setValue("");
      setDesc("");
      setColor("#F17F79");
      setEditableNode({});
    }
  };

  const openPopoverInEditMode = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const editCustomeScaleValue = (event, uniqeId) => {
    if (uniqeId !== 1 && uniqeId !== 2) {
      openPopoverInEditMode(event);
      setOpenPopupinEditMode({
        ...openPopupinEditMode,
        status: true,
        nodeId: uniqeId,
      });

      let editableNode = textFieldValues?.filter((node) => {
        return node?.id === uniqeId;
      });

      if (editableNode?.length > 0) {
        setValue(editableNode[0]?.value);
        setDesc(editableNode[0]?.text);
        setColor(editableNode[0]?.colorCode);
        setEditableNode(editableNode[0]);
      }
    }
  };

  const deleteCustomeScaleValue = (uniqeId) => {
    let updatedScaleNodeArray = textFieldValues?.filter((node) => {
      return node?.id !== uniqeId;
    });
    if (
      textFieldValues[0].temp === undefined ||
      textFieldValues[1].temp === undefined
    ) {
      setTextFieldValues(updatedScaleNodeArray);
    }


    if (
      textFieldValues?.length === 2 &&
      textFieldValues[0].temp === undefined &&
      textFieldValues[1].temp
    ) {
      setNodeCount(0);
      setTextFieldValues([
        {
          id: 1,
          colorCode: "#F17F79",
          text: "min",
          value: "",
          temp: true,
        },
        {
          id: 2,
          colorCode: "#169C63",
          text: "max",
          value: "",
          temp: true,
        },
      ]);
    } else if (
      (textFieldValues?.length === 2 &&
        textFieldValues[0].temp === undefined) ||
      textFieldValues[1].temp
    ) {
      setNodeCount(1);
      let updatedScaleNodeArray = textFieldValues?.filter((node) => {
        return node?.id !== uniqeId;
      });
      updatedScaleNodeArray.push({
        id: 2,
        colorCode: "#169C63",
        text: "max",
        value: "",
        temp: true,
      });
      setTextFieldValues(updatedScaleNodeArray);
      // setTextFieldValues([
      //   ...textFieldValues,
      //   {
      //     id: 2,
      //     colorCode: "#169C63",
      //     text: "max",
      //     value: "",
      //     temp: true
      //   }
      // ])
    }
  };

  const ITEM_HEIGHT = 48;
  const [anchorElTwo, setAnchorElTwo] = React.useState<null | HTMLElement>(
    null
  );
  const openMenu = Boolean(anchorElTwo);
  const handleClickAnchorElTwo = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElTwo(event.currentTarget);
  };
  const handleCloseAnchorElTwo = () => {
    setAnchorElTwo(null);
  };

  const width = useWidth();

  const onArchiveInfuance = () => {
    const token = localStorage.getItem("userDetails");
    userService
      ?.archiveInfluanceData(
        {
          archive: true,
          influenceId: props?.influenceData?.influenceId,
        },
        token
      )
      .then((res) => {
        props?.setShowSnakbar(true);
        props?.setSnakbarMsg(res.message);
        props?.setMsgType("success");
        // setShowOutcome(false);
        // setAddOutcomeTracking(true);
        let timerId: number = window.setTimeout(() => {
          props.handleCloseStartModal();
          props.getInfluenceList();
        }, 1000);
      })
      .catch((error) => {
        props?.setShowSnakbar(true);
        props?.setSnakbarMsg(
          error?.response?.data?.description
            ? error?.response?.data?.description
            : error.message
        );
        props?.setMsgType("error");
      });
  };

  const toolTipText =
    "This is a metric you want to observe without setting any goals for it, and whatever you input when tracking is neither good nor bad.";

  return (
    <RootStyle
      sx={{ height: "100vh", overflowY: 'auto', }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {width > 768 ? (
          <Typography
            variant="h1"
            component="h1"
            className={classes.registrationHeading}
          >
            Influences
          </Typography>
        ) : ( 
          <Typography
            variant="h2"
            component="h2"
            fontWeight={600}
            marginTop="45px"
            color="black"
          // className={classes.registrationHeading}
          >
            {"Add a new influence"}
          </Typography>
        )}
        
        {props.influenceData?.uid && (
          <div>
            <IconButton
              // aria-label="more"
              // id="long-button"
              // aria-controls={open ? "long-menu" : undefined}
              // aria-expanded={open ? "true" : undefined}
              // aria-haspopup="true"
              // onClick={handleClick}
              onClick={() => {
                setopenWellbeing(true);
              }}
            >
              <ViewNote />
            </IconButton>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={openMenu ? "long-menu" : undefined}
              aria-expanded={openMenu ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClickAnchorElTwo}
            >
              <Donts />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorElTwo}
              open={openMenu}
              onClose={handleCloseAnchorElTwo}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: "20ch",
                },
              }}
            >
              <MenuItem onClick={() => setopenSwitchInInfluenceModal(true)}>
                Change scale type
              </MenuItem>
              <MenuItem onClick={() => setopenSwitchOutcomeModal(true)}>
                Transform to outcome
              </MenuItem>
              <MenuItem
                onClick={() =>
                  navigate(
                    `/version?id=${props?.influenceData?.outcomeParentId ??
                    props?.influenceData?.influenceParentId
                    }`
                  )
                }
              >
                History version
              </MenuItem>
              <MenuItem onClick={onArchiveInfuance}>
                Archive this metric
              </MenuItem>
            </Menu>
          </div>
        )}
      </div>
      {width > 768 && (
        <Typography variant="h4" component="h4" className={classes.pageTitle}>
          An influence is something you expect to impact an outcome.
        </Typography>
      )}
      <Grid
        display="flex"
        // alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        sx={{
          mb: "16px",
          flexDirection: width > 768 ? "row" : "column",
          alignItems: width > 768 ? "center" : "left",
        }}
      >
        {width > 768 && (
          <Typography variant="caption" component="div">
            Describe the influence you want to track
          </Typography>
        )}
        {width < 768 && (<div
          style={{
            fontSize: "16px!important",
            fontWeight: "500",
            lineHeight: "20px",
            color: "black"
          }}
        >
          Describe the influence you want to track
        </div>)}

        {/* <Typography variant="caption" component="div">
          Describe the influence you want to track
        </Typography> */}
        {!props.showInfluence && (
          <Grid
            display="flex"
            alignItems="center"
            sx={{
              cursor: "pointer",
              marginTop: width > 768 ? "auto" : "10px",
            }}
            onClick={() => {
              showDrawer();
              setShowIdeasMobile(true);
            }}
          >
            <GetIdeasIcon />
            <span
              className={classes.needSomeIdeas}
              style={{ marginTop: width > 768 ? "10px" : "0px" }}
            >
              Need some ideas?
            </span>
          </Grid>
        )}
        {/* RIGHT SIDE DRAWER */}
        {!props.showInfluence || width > 768 && (
          <IdeasDrawer
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            influenceIdeas={props.influenceIdeas}
            setInfluenceData={props.setInfluenceData}
          />
        )}
        {showIdeasMobile && width < 769 && (
          <Dialog
            fullScreen
            disableEscapeKeyDown
            onClose={handleCloseStartModal}
            open={showIdeasMobile}
            style={{
              height: "60%",
              bottom: 0,
              top: "auto",
              borderRadius: "35px 35px 0px 0px",
              overflow: "hidden",
            }}
          >
            <div
              style={{
                height: "100%",
                width: "100%",
                backgroundColor: "#11734A",
                padding: "20px",
                paddingTop: "60px",
                color: "#fff",
                overflow: "auto",
              }}
            >
              <Grid
                container
                sx={{ backgroundColor: palette.primary.main }}
              // className={classes.drawerWrapper}
              >
                <Grid>
                  <Typography
                    variant="h2"
                    component="h2"
                    sx={{
                      color: palette.common.white,
                      fontWeight: 500,
                      lineHeight: "35px!important",
                    }}
                  >
                    Our ideas of Influences
                  </Typography>
                  <Typography
                    variant="h6"
                    component="div"
                    sx={{
                      color: palette.common.white,
                      fontWeight: 400,
                      lineHeight: "35px!important",
                    }}
                  >
                    Choose one of our recommended influences, and personalize it
                  </Typography>
                </Grid>
                <Grid
                  // className={classes.ideasList}
                  sx={{ width: "100%", mt: "40px" }}
                >
                  {props?.influenceIdeas?.map((ideas: any) => {
                    return (
                      <div
                        className={classes.ideasListItem}
                        key={ideas._id}
                        onClick={() => {
                          props.setInfluenceData(ideas);
                          setShowIdeasMobile(false);
                        }}
                      >
                        {ideas.name}
                      </div>
                    );
                  })}
                </Grid>
              </Grid>
            </div>
          </Dialog>
        )}
      </Grid>

      <Grid
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            position: "relative",
            width: width > 768 ? "auto" : "100%"
          }}
        >
          <TextField
            className="fillTextInput fullWidth"
            placeholder="Add an influence you want to track"
            variant="outlined"
            fullWidth
            name="influence"
            value={influenceInput || ""}
            onChange={(e) => handleChangeInfluence(e)}
            inputProps={ariaLabel}
          />
          <div
            style={{
              backgroundColor: "aliceblue",
              borderRadius: "8px",
              fontSize: "16px",
              maxHeight: "120px",
              overflow: "auto",
              position: "absolute",
              width: "100%",
              top: "55px",
              zIndex: 10,
              color: "black"
            }}
          >
            {influenceInput?.length !== 0 &&
              suggestions?.map((suggestion) => {
                return (
                  <div
                    style={{ padding: "8px", cursor: "pointer" }}
                    onClick={() => {
                      props.setInfluenceData(suggestion)
                      setSuggestions([])
                      // setInfluenceOption(suggestion?>)
                    }}
                  >
                    {suggestion.name}
                  </div>
                );
              })}
          </div>
        </div>
        {/* SCALE OPTION */}
        {influenceOption === "TYPE2" && (
          <div
            className={clsx(classes.colorBlock, classes.spaceBetween)}
            onClick={handleClickOpenColor}
          >
            <p className={classes.colorBlockText}>Set a color</p>
            <span
              className={classes.colorCode}
              style={{
                backgroundColor:
                  props.influenceData?.uid && selectedColor.length === 0
                    ? props.influenceData?.options[0]?.colorCode
                    : selectedColor.length > 0
                      ? selectedColor[0]?.colorCode
                      : selectedColor?.color[0],
              }}
            ></span>
          </div>
        )}
        {/* CHOOSE COLOR PALETTE */}
        <ColorPickerDialog
          openColor={openColor}
          onCloseColor={handleCloseColor}
        />
      </Grid>
      <Grid className={classes.selectWrapper}>
        <Typography
          variant="caption"
          component="div"
          className={classes.influenceCaption}
        >
          How do you want to measure it?
        </Typography>
        {/* SET UNIT VALUE */}
        {influenceOption === "TYPE1" ? (
          <>
            <Grid display="flex" alignItems="center" flexWrap="wrap">
              <TextField
                className="fillTextInput influenceSelect"
                fullWidth
                select
                label="Select a method to measure this influence"
                value={influenceOption || ""}
                onChange={(e) => setInfluenceOption(e.target.value)}
                disabled={!!props.influenceData?.uid}
              >
                {InfluenceSelectOption?.map((option: InfluenceOption) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid
              display="flex"
              alignItems="center"
              flexWrap="wrap"
              justifyContent="space-between"
            >
              {influenceOption !== "TYPE1" && (
                <TextField
                  sx={{ marginBottom: "16px!important" }}
                  className="fillTextInput unitValue"
                  placeholder="Unit value"
                  variant="outlined"
                  fullWidth
                  name="outcome"
                  value={unitValue || ""}
                  onChange={(e) => setUnitValue(e.target.value)}
                  inputProps={ariaLabel}
                />
              )}
              <TextField
                sx={
                  props.influenceData?.uid
                    ? {
                      marginTop: "0!important",
                      marginBottom: "16px!important",
                      marginLeft: "0!important",
                    }
                    : {
                      marginLeft: "0!important",
                      marginBottom: "16px!important",
                    }
                }
                className="fillTextInput secondSelect unitSelect"
                fullWidth
                select
                label="Unit of measurement"
                value={unitValueOption || ""}
                onChange={(e) => {
                  setUnitValueOption(e.target.value);
                  if (e.target.value === "timeofday") {
                    setNeutralScaler("NumericValue");
                  } else {
                    setNeutralScaler("");
                  }
                  if (cateScaler === "yes/no") {
                    setFlagValue("yes");
                  } else {
                    setFlagValue("no");
                  }
                }}
              >
                {MeasurementUnit?.map((option: any) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.value}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid container direction="row" alignItems="center">
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={flagValue || ""}
                  name="radio-buttons-group"
                  onChange={(e) => setFlagValue(e.target.value)}
                >
                  <FormControlLabel
                    value="no"
                    disabled={
                      props.influenceData?.uid &&
                      props.influenceData?.type === "TYPE1"
                    }
                    control={
                      <Checkbox
                        // disabled={props.influenceData?.uid}
                        checked={
                          neutralScaler === "NumericValue" ? true : false
                        }
                      />
                    }
                    label="Neutral metric"
                    onChange={(e) => {
                      if (neutralScaler === "NumericValue") {
                        setNeutralScaler("");
                      } else {
                        setNeutralScaler("NumericValue");
                      }
                      if (unitValueOption === "timeofday") {
                        setNeutralScaler("NumericValue");
                        props?.setShowSnakbar(true);
                        props?.setSnakbarMsg(
                          "You can not unChecked when unit is time Of day"
                        );
                        props?.setMsgType("error");
                      }
                      setFlagValue("no");
                    }}
                  />
                </RadioGroup>
              </FormControl>
              {/* <div> */}
              <Tooltip
                title={toolTipText}
                placement="top"
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: "#EBF2F1",
                      color: "#3E4240",
                    },
                  },
                }}
              >
                <IconButton style={{ padding: "0px" }}>
                  <Question />
                </IconButton>
              </Tooltip>
              {/* </div> */}
            </Grid>
            {neutralScaler !== "NumericValue" && (
              <Grid sx={{ mt: "30px" }}>
                {/* <Grid item>
                  <Typography
                    variant="caption"
                    component="div"
                    className={classes.influenceCaption}
                  >
                    How you want to enter value?
                  </Typography>
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={flagValue || ''}
                      name="radio-buttons-group"
                      onChange={(e) => setFlagValue(e.target.value)}
                    >
                      <FormControlLabel
                        value="yes"
                        disabled={
                          props.influenceData?.uid &&
                          props.influenceData?.type === 'TYPE12'
                        }
                        control={
                          <Radio
                            // disabled={props.influenceData?.uid}
                            checked={flagValue === 'yes' ? true : false}
                          />
                        }
                        label="Yes/No"
                        onChange={(e) => {
                          setCateScaler('yes/no');
                        }}
                      />
                      <FormControlLabel
                        value="no"
                        disabled={
                          props.influenceData?.uid &&
                          props.influenceData?.type === 'TYPE1'
                        }
                        control={
                          <Radio
                            // disabled={props.influenceData?.uid}
                            checked={
                              cateScaler === 'NumericValue' ? true : false
                            }
                          />
                        }
                        label="Numeric value"
                        onChange={(e) => {
                          setCateScaler('NumericValue');
                        }}
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid> */}
                {cateScaler === "NumericValue" && (
                  <Grid
                    item
                    sx={{ mt: "30px", display: "flex", alignItems: "end" }}
                  >
                    <Button
                      variant="contained"
                      endIcon={<AddIcon sx={{ color: "#3E4240" }} />}
                      className={classes.addButton}
                      sx={{ mr: "40px" }}
                      onClick={handleClick}
                    >
                      <Typography variant="subtitle1">Add</Typography>
                    </Button>

                    <Grid
                      item
                      xs={14}
                      className={classes.minButton}
                      justifyContent="space-between"
                    >
                      {textFieldValues?.map((value, index) => {
                        return (
                          <>
                            {/* <Slider
                          aria-label="Temperature"
                          defaultValue={50}
                          // getAriaValueText={valuetext}
                          valueLabelDisplay="auto"
                          step={10}
                          marks
                          min={1}
                          max={10}
                        /> */}

                            <Grid
                              key={index}
                              item
                              xs={7}
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                width: "auto",
                                flex: "0 0 auto !important",
                                justifyContent: "center",
                              }}
                            >
                              <Typography
                                variant="subtitle1"
                                sx={{
                                  color: textFieldValues[index]?.colorCode
                                    ? textFieldValues[index]?.colorCode
                                    : "#F17F79",
                                  display: "inline-block",
                                  mb: "6px",
                                }}
                              >
                                {textFieldValues[index]?.text
                                  ? textFieldValues[index]?.text
                                  : "Max"}
                              </Typography>

                              <div
                                className={classes.iconButton}
                                onClick={(event) =>
                                  editCustomeScaleValue(event, value?.id)
                                }
                                style={{
                                  backgroundColor: textFieldValues[index]
                                    ?.colorCode
                                    ? textFieldValues[index]?.colorCode
                                    : "#F17F79",
                                }}
                              >
                                {textFieldValues[index]?.value}
                              </div>
                            </Grid>
                          </>
                        );
                      })}
                      {/* <Grid
                    item
                    xs={7}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "baseline",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{
                        color: textFieldValues[0]?.colorCode ? textFieldValues[0]?.colorCode : "#F17F79",
                        display: "inline-block",
                        mb: "6px",
                      }}
                    >
                      {textFieldValues[0]?.text ? textFieldValues[0]?.text : "Min"}
                    </Typography>

                    <div
                      className={classes.iconButton}
                      style={{ backgroundColor: textFieldValues[0]?.colorCode ? textFieldValues[0]?.colorCode : "#F17F79" }}>
                      {textFieldValues[0]?.value}
                    </div>
                  </Grid>

                  <Grid
                    item
                    xs={7}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{
                        color: textFieldValues[1]?.colorCode ? textFieldValues[1]?.colorCode : "#169C63",
                        display: "inline-block",
                        mb: "6px",
                      }}
                    >
                      {textFieldValues[1]?.text ? textFieldValues[1]?.text : "Max"}
                    </Typography>

                    <div
                      className={classes.iconButton}
                      style={{ backgroundColor: textFieldValues[1]?.colorCode ? textFieldValues[1]?.colorCode : "#169C63" }}>
                      {textFieldValues[1]?.value}
                    </div>
                  </Grid> */}
                    </Grid>
                  </Grid>
                )}
                <AddPopOver
                  nodeCount={nodeCount}
                  setNodeCount={setNodeCount}
                  editableNode={editableNode}
                  open={open}
                  anchorEl={anchorEl}
                  value={value}
                  setValue={setValue}
                  desc={desc}
                  setDesc={setDesc}
                  color={color}
                  setColor={setColor}
                  colorPalette={colorPalette}
                  setColorPalette={setColorPalette}
                  textFieldValues={textFieldValues}
                  setTextFieldValues={setTextFieldValues}
                  handleClose={handleClose}
                  openPopupinEditMode={openPopupinEditMode}
                  deleteCustomeScaleValue={deleteCustomeScaleValue}
                  setMatchValue={setMatchValue}
                  matchValue={matchValue}
                />
              </Grid>
            )}
          </>
        ) : influenceOption === "TYPE2" ? (
          // SCALE OPTION
          <>
            <TextField
              className="fillTextInput influenceSelect"
              fullWidth
              select
              label="Select a method to measure this influence"
              value={influenceOption || ""}
              onChange={(e) => setInfluenceOption(e.target.value)}
              disabled={props.influenceData?.uid}
            >
              {InfluenceSelectOption?.map((option: InfluenceOption) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.value}
                </MenuItem>
              ))}
            </TextField>
            <Grid sx={{ mt: "16px" }}>
              <ProgressBarStepper
                selectedColor={selectedColor}
                setTextInput={props?.setTextInput}
                textInput={props?.textInput}
                setShowInput={setShowInput}
                setEditStatus={setEditStatus}
                showInput={showInput}
                editStatus={editStatus}
              />
            </Grid>
          </>
        ) : (
          // YES/NO OPTION
          <>
            <TextField
              className="fillTextInput influenceSelect"
              fullWidth
              select
              label="Select a method to measure this influence"
              value={influenceOption || ""}
              onChange={(e) => setInfluenceOption(e.target.value)}
              disabled={props.influenceData?.uid}
            >
              {InfluenceSelectOption?.map((option: InfluenceOption) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.value}
                </MenuItem>
              ))}
            </TextField>
          </>
        )}
      </Grid>
      {influenceOption === "TYPE3" && (
        <div>
          <FormControlLabel
            control={
              <Checkbox
                // disabled={props.influenceData?.uid}
                checked={IsInverse == "true" ? true : false}
              />
            }
            label="Reverse color"
            onChange={(e) => {
              IsInverse == "true"
                ? setIsInverse("false")
                : setIsInverse("true");
            }}
          />
        </div>
      )}
      <div style={{ marginTop: "40px" }}>
        <Typography
          variant="caption"
          component="p"
          className={classes.pageTitle}
        >
          Description
        </Typography>
        <TextField
          variant="outlined"
          className="textarea"
          fullWidth
          placeholder="Add a description..."
          multiline
          minRows={4}
          onChange={(e) => setOutcomeDesc(e.target.value)}
          value={outcomeDesc}
        />
      </div>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        {!props.showInfluence && <Button
          className="no-after"
          disabled={activeStep === 0}
          onClick={handleBack}
          sx={{ mr: 1, color: palette.primary.main, p: 0 }}
          style={{ display: width > 768 ? "none" : "flex" }}
        >
          {activeStep === 0 ? <StepperBackArrowDisable /> : <StepperBackArrow />}
        </Button>}
        {!showLoader ? (
          <Button
            type="submit"
            variant="contained"
            className={`button-after outcomeButton ${!props.showInfluence ? "showBottonStepper" : ""
              }`}
            fullWidth
            sx={{
              mt: "98px",
              width: width > 768 ? "auto" : !props.showInfluence ? "50% !important" : "100% !important",
              borderRadius: width > 768 ? "auto" : "20px",
            }}
            disabled={
              influenceInput == undefined ||
              (influenceOption != "TYPE1" &&
                influenceOption != "TYPE2" &&
                influenceOption != "TYPE3")
            }
            onClick={() => AddInfluences()}
          >
            {props.influenceData?.uid ? "Update Influence" : "Add Influence"}
          </Button>
        ) : (
          <Button
            variant="contained"
            className="button-after outcomeButton"
            fullWidth
            sx={{
              mt: "58px",
              width: width > 768 ? "auto" : !props.showInfluence ? "50% !important" : "100% !important",
              borderRadius: width > 768 ? "auto" : "20px",
            }}
          >
            <ButtonLoader />
          </Button>
        )}
        {!props.showInfluence && <Button
          className="no-after"
          onClick={handleNext}
          sx={{ color: palette.primary.main, p: 0, cursor: "pointer" }}
          style={{ display: width > 768 ? "none" : "flex" }}
          disabled={
            activeStep === userType.length - 1 ||
            (false
              ? IUserTypeState?.outcomeList?.length == 0
              : true
                ? IUserTypeState?.influenceList?.length == 0
                : false
                  ? IUserTypeState?.reflectiveList?.length == 0
                  : false)
          }
        >
          {activeStep === userType.length - 1 ||
            (false
              ? IUserTypeState?.outcomeList?.length == 0
              : true
                ? IUserTypeState?.influenceList?.length == 0
                : false
                  ? IUserTypeState?.reflectiveList?.length == 0
                  : false) ? (
            <StepperNextArrowDisable />
          ) : (
            <StepperNextArrow />
          )}
        </Button>}
      </div>
      <Wellbeing
        open={openWellbeing}
        journeyData={outcomeState?.notesList?.influence_data}
        setopenWellbeing={setopenWellbeing}
        outcomeValueInput={influenceInput}
      />
      {/* BOTTOM STEPPER */}
      {!props.showInfluence && (
        <BottomStepper
          isOutcome={false}
          isInfluence={true}
          isRefQues={false}
          isTracking={false}
        />
      )}
      <SwitchToOutcomeModal
        open={openSwitchOutcomeModal}
        setopenSwitchInfluenceModal={setopenSwitchOutcomeModal}
        setTransformToInfluenceTracking={props?.setTransformToOutcomeTracking}
        // setAddOutcomeTracking={props?.setAddOutcomeTracking}
        setShowOutcome={props?.setShowInfluence}
        outcomeValueInput={influenceInput}
      />
      <SwitchInInfluenceModal
        open={openSwitchInInfluenceModal}
        setopenSwitchInfluenceModal={setopenSwitchInInfluenceModal}
        setTransformToInfluenceTracking={props?.setTransformInInfluenceTracking}
        // setAddOutcomeTracking={props?.setAddOutcomeTracking}
        setShowOutcome={props?.setShowInfluence}
        outcomeValueInput={influenceInput}
      />
    </RootStyle>
  );
};
export default LeftSideInfluence;
