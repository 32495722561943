import React from "react";

const RightCalendarIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M7.49988 5.00012L12.4999 10.0001L7.49988 15.0001" stroke="var(--sidebar-active-color)" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default RightCalendarIcon;
