import { Grid } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FullpageLoader from "../../components/fullPageLoader";
import SnackBarComp from "../../components/snackBar";
import { UserTypeContext } from "../../context/userStatus";
import { CategoryUpdate } from "../../interfaces/categoryModel";
import {
  CategoryGetList,
  InfluenceGetList,
  OutcomeGetList,
  ReflectiveGetList,
} from "../../interfaces/outcomeModal";
import { categoryService } from "../../services/category";
import { influenceService } from "../../services/influence";
import { outcomeService } from "../../services/outcome";
import { reflectiveQueService } from "../../services/reflective-questions";
import {
  addCategoryAction,
  addInfluenceAction,
  addOutcomeAction,
  addRefelectiveAction,
  isEmptyState,
} from "../../store/actions/userTypeAction";
import palette from "../../theme/palette";
import LeftSideCategory from "./LeftSideCategory";
import RightSideCategory from "./RightSideCategory";
import { DragDropContext } from "react-beautiful-dnd";

export type OutcomesList = {
  outcomes: OutcomeGetList[];
  influences: InfluenceGetList[];
  reflectives: ReflectiveGetList[];
};

const AddCategoryIndex = () => {
  const { state: outcomeState, dispatch: userTypeDispatch } =
    useContext(UserTypeContext);
  const [categoryIdeas, setCategoryIdeas] = useState();
  const [fullpageLoader, setFullPageLoader] = useState(true);
  const [showSnakbar, setShowSnakbar] = useState(false);
  const [snakbarMsg, setSnakbarMsg] = useState("");
  const [msgType, setMsgType] = useState("");
  const [expandedList, setExpandList] = useState([])
  const [outcomesList, setOutcomesList] = useState<OutcomesList>();
  const [isApiCall, setIsApiCall] = useState(false);
  const [addCategoryList, setAddCategoryList] = useState<CategoryGetList[]>([]);
  const [expanded, setEpanded] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    getSuggestions();
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isApiCall]);

  const getOutcomeList = async (categoryList) => {
    const outcomes = await outcomeService.getOutcomeList(outcomeState.token);
    userTypeDispatch(addOutcomeAction(outcomes?.data));
    let updatedOutcome = [];
    outcomes?.data?.forEach((outcome) => {
      let flag = false;

      categoryList.forEach((category) => {
        if (category.outcomeIds.includes(outcome.outcomeId)) {
          flag = true;
        }
      });

      if (!flag) {
        updatedOutcome.push(outcome);
      }
    });

    const influences = await influenceService.getInfluenceList(
      outcomeState.token
    );
    userTypeDispatch(addInfluenceAction(influences?.data));
    let updatedInfluence = [];
    influences?.data?.forEach((influence) => {
      let flag = false;

      categoryList.forEach((category) => {
        if (category.influenceIds.includes(influence.influenceId)) {
          flag = true;
        }
      });

      if (!flag) {
        updatedInfluence.push(influence);
      }
    });

    const reflectives = await reflectiveQueService.getReflectiveList(
      outcomeState.token
    );
    userTypeDispatch(addRefelectiveAction(reflectives?.data));
    let updatedReflective = [];
    reflectives?.data?.forEach((reflective) => {
      let flag = false;

      categoryList.forEach((category) => {
        if (category.reflectiveIds.includes(reflective.reflectiveId)) {
          flag = true;
        }
      });

      if (!flag) {
        updatedReflective.push(reflective);
      }
    });

    setOutcomesList({
      outcomes: updatedOutcome,
      influences: updatedInfluence,
      reflectives: updatedReflective,
    });
    setFullPageLoader(false);
  };

  const getSuggestions = () => {
    setFullPageLoader(true);
    categoryService
      .getCategorySuggestion(outcomeState.token)
      .then((data: any) => {
        setFullPageLoader(false);
        setCategoryIdeas(data?.ideas);
      })
      .catch((error) => {
        setFullPageLoader(false);
        setShowSnakbar(true);
        setSnakbarMsg(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message
        );
        setMsgType("error");
      });
  };

  const getCategories = () => {
    setFullPageLoader(true);
    categoryService
      .getCategories(outcomeState.token)
      .then((data: any) => {
        const newArry = data?.data?.map((ele, index) => {
          return {
            ...ele,
            arrayIndex: index,
          };
        });
        userTypeDispatch(addCategoryAction(newArry));
        getOutcomeList(newArry);
        setFullPageLoader(false);
      })
      .catch((error) => {
        setFullPageLoader(false);
        setShowSnakbar(true);
        setSnakbarMsg(
          error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message
        );
        setMsgType("error");
      });
  };

  const handleFinish = async (finalData: CategoryUpdate[]) => {
    setFullPageLoader(true);

    try {
      // for (const data of finalData) {
      //   let temp = await categoryService.updateCategory(
      //     outcomeState.token,
      //     data
      //   );
      // }
      await categoryService.updateCategoryBulk(
          outcomeState.token,
          {
            inputData: finalData
          }
        );
      setFullPageLoader(false);
      setShowSnakbar(true);
      setSnakbarMsg("Category Updated Successfully");
      userTypeDispatch(isEmptyState(false))
      navigate("/tracking");
    } catch (error) {
      setFullPageLoader(false);
      setShowSnakbar(true);
      setSnakbarMsg(
        error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message
      );
      setMsgType("error");
    }
  };

  const handleCloseSnakbar = () => {
    setShowSnakbar(false);
  };

  const handleOutcomeListRemove = (type: string, id: string) => {
    setOutcomesList((prevState) => {
      if (type === "OUTCOME") {
        prevState = {
          ...prevState,
          outcomes: prevState.outcomes.filter((outcome) => outcome._id !== id),
        };
      } else if (type === "INFLUENCE") {
        prevState = {
          ...prevState,
          influences: prevState.influences.filter(
            (influence) => influence._id !== id
          ),
        };
      } else if (type === "REFLECTIVE") {
        prevState = {
          ...prevState,
          reflectives: prevState.reflectives.filter(
            (reflective) => reflective._id !== id
          ),
        };
      }
      return prevState;
    });
  };

  const handleOutcomeListAdd = (type: string, item: any) => {
    let arr = { ...outcomesList };
    if (type === "OUTCOME") {
      arr.outcomes.push(item);
    } else if (type === "INFLUENCE") {
      arr.influences.push(item);
    } else if (type === "REFLECTIVE") {
      arr.reflectives.push(item);
    }
    setOutcomesList(arr);
  };

  const movePetListItem = (result) => {
    if (!result.destination) {
      return;
    }
    if (
      result.destination.droppableId === result.source.droppableId &&
      result.destination.index === result.source.index
    ) {
      return;
    }
    const dragItem = result.source.index;
    const hoverItem = result.destination.index;
    if (result.source.droppableId !== result.destination.droppableId) {
      setAddCategoryList((prevState) => {
        let newState = prevState;
        let newSource = prevState?.filter(
          (cat) => cat.categoryId === result.source.droppableId
        )?.[0].alls;
        let newDestination = prevState?.filter(
          (cat) => cat.categoryId === result.destination.droppableId
        )?.[0].alls;
        const [recordedItem] = newSource.splice(dragItem, 1);
        // newSource.splice(dragItem, 0);
        newDestination.splice(hoverItem, 0, recordedItem);
        let sourceIndex = newState.findIndex(
          (obj) => obj.categoryId == result.source.droppableId
        );
        let destinationIndex = newState.findIndex(
          (obj) => obj.categoryId == result.destination.droppableId
        );
        newState[sourceIndex].alls = newSource;
        newState[destinationIndex].alls = newDestination;
        newState[sourceIndex].allIds = newSource
          ?.filter((array) => array !== undefined)
          .map(
            (element) =>
              element?.outcomeId ??
              element?.influenceId ??
              element?.reflectiveId
          );
        newState[destinationIndex].allIds = newDestination
          ?.filter((array) => array !== undefined)
          .map(
            (element) =>
              element?.outcomeId ??
              element?.influenceId ??
              element?.reflectiveId
          );
        newState[sourceIndex].outcomeIds = newSource
          ?.filter((array) => array !== undefined)
          ?.filter((array) => array.outcomeId)
          .map((element) => element?.outcomeId);
        newState[destinationIndex].outcomeIds = newDestination
          ?.filter((array) => array !== undefined)
          ?.filter((array) => array.outcomeId)
          .map((element) => element?.outcomeId);
        newState[sourceIndex].influenceIds = newSource
          ?.filter((array) => array !== undefined)
          ?.filter((array) => array.influenceId)
          .map((element) => element?.influenceId);
        newState[destinationIndex].influenceIds = newDestination
          ?.filter((array) => array !== undefined)
          ?.filter((array) => array.influenceId)
          .map((element) => element?.influenceId);
        newState[sourceIndex].reflectiveIds = newSource
          ?.filter((array) => array !== undefined)
          ?.filter((array) => array.reflectiveId)
          .map((element) => element?.reflectiveId);
        newState[destinationIndex].reflectiveIds = newDestination
          ?.filter((array) => array !== undefined)
          ?.filter((array) => array.reflectiveId)
          .map((element) => element?.reflectiveId);
        return newState;
      });
    } else {
      setAddCategoryList((prevState) => {
        const newUpdatedAlls = prevState?.map((item) => {
          if (
            item?.categoryId === result.destination.droppableId &&
            item?.categoryId === result.source.droppableId
          ) {
            const items = Array.from(item?.alls);
            const [recordedItem] = items.splice(dragItem, 1);
            items.splice(hoverItem, 0, recordedItem);
            const updatedAlls: any = items;
            const updatedAllIds = updatedAlls
              ?.filter((array) => array !== undefined)
              .map(
                (element) =>
                  element?.outcomeId ??
                  element?.influenceId ??
                  element?.reflectiveId
              );
            const updatedOutcomeIds = updatedAlls
              ?.filter((array) => array !== undefined)
              ?.filter((array) => array.outcomeId)
              .map((element) => element?.outcomeId);
            const updatedInfluenceIds = updatedAlls
              ?.filter((array) => array !== undefined)
              ?.filter((array) => array.influenceId)
              .map((element) => element?.influenceId);
            const updatedReflectiveIds = updatedAlls
              ?.filter((array) => array !== undefined)
              ?.filter((array) => array.reflectiveId)
              .map((element) => element?.reflectiveId);
            return {
              ...item,
              allIds: updatedAllIds,
              alls: updatedAlls?.filter(
                (array) => array !== undefined && array !== ""
              ),
              outcomeIds: updatedOutcomeIds,
              influenceIds: updatedInfluenceIds,
              reflectiveIds: updatedReflectiveIds,
            };
          } else {
            return item;
          }
        });
        return newUpdatedAlls;
      });
    }
  };
  useEffect(() => {
    if(addCategoryList && addCategoryList.length && !expanded) {
      let arr = []
      for (let index = 0; index < addCategoryList.length; index++) {
        const element = addCategoryList[index];
        arr.push(element.categoryId)
      }
      setExpandList(arr)
    }
  }, [addCategoryList, expanded])
  return (
    <DragDropContext onDragEnd={movePetListItem}>
      <>
        {fullpageLoader && <FullpageLoader />}
        <Grid
          container
          columns={16}
          sx={{
            backgroundColor: palette.secondary.light,
            height: "100vh",
            overflow: "visible",
            position: "relative",
          }}
        >
          {/* SNACK BAR */}
          <SnackBarComp
            showSnakbar={showSnakbar}
            handleCloseSnakbar={handleCloseSnakbar}
            snakbarMsg={snakbarMsg}
            type={msgType ? msgType : "info"}
          />
          {/* START LEFT SECTION */}
          <Grid
            sx={{
              backgroundColor: palette.common.white,
              height: "fit-content",
              overflow: "visible",
            }}
            item
            xs={9.63}
            className="leftside-menu login-screen outcome-screen outcome-left-layout"
          >
            <LeftSideCategory
              categoryIdeas={categoryIdeas}
              handleFinish={(finalData) => handleFinish(finalData)}
              outcomesList={outcomesList}
              handleOutcomeListRemove={handleOutcomeListRemove}
              handleOutcomeListAdd={handleOutcomeListAdd}
              setIsApiCall={setIsApiCall}
              isApiCall={isApiCall}
              addCategoryList={addCategoryList}
              setAddCategoryList={setAddCategoryList}
              expandedList={expandedList} setExpandList={setExpandList}
              expanded={expanded} setEpanded={setEpanded}
            />
          </Grid>
          {/* END LEFT SECTION */}

          {/* START RIGHT SECTION */}
          <Grid
            item
            xs={6.37}
            className="rightside-menu outcome-menu outcome-right-layout"
            sx={{
              backgroundColor: palette.common.bgColor,
              position: "fixed",
              right: "0",
              width: "46%",
            }}
          >
            {outcomesList && (
              <RightSideCategory
                outcomesList={outcomesList}
                addCategoryList={addCategoryList}
                handleFinish={(finalData) => handleFinish(finalData)}
              />
            )}
          </Grid>
          {/* END RIGHT SECTION */}
        </Grid>
      </>
    </DragDropContext>
  );
};

export default AddCategoryIndex;
