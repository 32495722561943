import React, { useContext, useState } from "react"
import clsx from "clsx"
import { Tooltip, Typography } from "@mui/material";
import EditIcon from "../../assets/images/icon/EditIcon";
import useStyles from "./TrackingClasses"
import InfluenceIcon from "../trackerNew/InfluenceIcon";
import { TrackingContext } from "../../context/userTracking";
import { setTrackingState } from "../../store/actions/trackerAction";

const PersonalTrackingInfluence = ({ isExpand, item }) => {
    const [showIcon, setshowIcon] = useState(false);
    const { dispatch: trackingDispatch } =
    useContext(TrackingContext);
    const classes = useStyles()
    return <td className={clsx(classes.cell, classes.stickyLeftCell)}><div className={isExpand ? classes.mainDivClasExpand : classes.mainDivClas} style={{
        height: "100%",
        borderBottom: `1px solid var(--border-color)`,
    }}
        onMouseEnter={() => setshowIcon(true)}
        onMouseLeave={() => setshowIcon(false)}>
        {item.description ? (
            <Tooltip
                title={
                    <Typography sx={{ fontSize: "14px !important", lineHeight: "1.5", whiteSpace: "pre-wrap" }}>
                        {item.description}
                    </Typography>
                }
            >
                <div style={{
                    width: '100%',
                    height: "100%"
                }}>
                    <Typography variant="h6" flex={7} title={item.name} className={isExpand ? classes.fullTexttextClass : classes.textClass}>
                        {" "}
                        {item.name}
                    </Typography>
                </div>
            </Tooltip>
        ) : (
            <div style={{
                width: '100%',
                height: "100%"
            }}>
                <Typography variant="h6" flex={7} title={item.name} className={isExpand ? classes.fullTexttextClass : classes.textClass}>
                    {" "}
                    {item.name}
                </Typography>
            </div>
        )}
        <div className={classes.innerDiVClass}>

            <span className={classes.textClass}>
                {<InfluenceIcon item={item} />}
            </span>
            {showIcon && <span
                onClick={() =>
                    trackingDispatch(setTrackingState({
                        openStartModalInfluence: true,
                        showInfluence: true,
                        influenceData: item,
                        influenceId: item._id
                      }))
                }
            >
                <EditIcon />
            </span>}
        </div>
    </div>
    </td>
}

export default PersonalTrackingInfluence