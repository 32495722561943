import { Box, TextField, Tooltip } from "@mui/material"
import React, { useEffect, useRef, useState } from "react"
import CommonTypo from "../../../components/CommonTypo"
import { Input } from "../../../components/inputGroup"
import OutcomeIcon from "../../../assets/images/icon/ProgressInsights/OutcomeIcon"
import InfluenceIcon from "../../../assets/images/icon/ProgressInsights/InfluenceIcon"
import HTMLTooltip from "../../../components/HTMLTooltip"
import { pxToRem } from "../../../theme/typography"

const SocialReportShareStep1 = ({
    setSideModalVisible,
    setSelectedFocus,
    isSideModalVisible,
    isSelectedFocus,
    selectedNodes,
    allContacts,
    viewSchedule,
    selectedOutInflu,
    allDefaultStates,
    dayToSend,
    seelctedVal,
    sendStatus,
    isInPreviewMode, setIsInPreviewMode,
    viewShareReport,
    shareReport,
    editRecord
}) => {
    console.log("seelctedVal", seelctedVal);
    const [shouldTruncate, setShouldTruncate] = useState(false);  // State to decide if truncation is needed
    const [firstNames, setFirstNames] = useState([]);
    const [hiddenNames, setHiddenNames] = useState([]);
    const [firstMetrics, setFirstMetrics] = useState([]);
    const [hiddenMetrics, setHiddenMetrics] = useState([]);
    const [visibleTags, setVisibleTags] = useState([]); // Visible tags
    const [hiddenTags, setHiddenTags] = useState([]);   // Hidden tags
    const [shouldTruncatePreview, setShouldTruncatePreview] = useState(false);  // State to decide if truncation is needed
    let contactNames = []
    if (selectedNodes && selectedNodes.length) {
        const fdun = allContacts.filter(it => selectedNodes.includes(it._id))
        console.log("fdun", fdun);

        if (fdun && fdun.length) {
            for (let index = 0; index < fdun.length; index++) {
                const element = fdun[index];
                if (element.contacts && element.contacts.length) {
                    const filteredData = element.contacts.filter(it => selectedNodes.includes(it._id))
                    contactNames.push(...filteredData)
                }
            }
        }
        if (!fdun.length) {
            const found = allContacts.filter(it => ["all"].includes(it._id))
            if (found && found.length) {
                for (let index = 0; index < found.length; index++) {
                    const element = found[index];
                    if (element.contacts && element.contacts.length) {
                        const filteredData = element.contacts.filter(it => selectedNodes.includes(it._id))
                        contactNames.push(...filteredData)
                    }
                }
            }
        }
    }
    console.log("selectedNodes", selectedNodes, allContacts, contactNames)
    // Step 1: Take the first 2 elements as they are.
    const firstTwo = contactNames.slice(0, 2);

    // Step 2: For the remaining elements, increment `a` by 2.
    const remaining = contactNames.slice(2);
    const selectedOutcomes = allDefaultStates && allDefaultStates.length > 0 ? allDefaultStates.find(it => it.groupName === "Outcomes").items.filter(it => selectedOutInflu.includes(it._id)) : []
    const selectedInfluence = allDefaultStates && allDefaultStates.length > 0 ? allDefaultStates.find(it => it.groupName === "Influences").items.filter(it => selectedOutInflu.includes(it._id)) : []
    const alls = [...selectedOutcomes, ...selectedInfluence]
    // Step 1: Take the first 2 elements as they are.
    const firstTwoMetrics = alls.slice(0, 2);

    const boxRef = useRef(null);  // Reference for the box container
    const boxRefMetric = useRef(null);  // Reference for the box container
    const textRefMetric = useRef(null);  // Reference for the box container
    let enabledButton = false
    if (contactNames && contactNames.length && alls && alls.length) {
        if(sendStatus === "INSTANT" || (sendStatus !== "INSTANT"&& dayToSend)) {
            enabledButton = true
        }
    }
    // Function to decide how many metrics to display
    const updateNamesDisplay = () => {
        const metrics = contactNames.map(it => it.name);
        const fullText = metrics.join(", ");

        if (boxRef.current) {
            const boxWidth = boxRef.current.offsetWidth;  // Get the box width
            const textWidth = boxRef.current.scrollWidth + 50;  // Get the full text width

            // If text overflows, show "and X more", otherwise show all metrics
            if (textWidth > boxWidth) {
                const numMetricsToDisplay = 2;  // Display only 1 metric if text overflows
                setFirstNames(metrics.slice(0, numMetricsToDisplay));
                setHiddenNames(metrics.slice(numMetricsToDisplay));
                setShouldTruncate(true);
            } else {
                setFirstNames(metrics);  // Display all metrics if they fit
                setHiddenNames([]);
                setShouldTruncate(false);
            }
        }
    };
    // Function to decide how many metrics to display
    const updateMetricsDisplay = () => {
        const metrics = alls.map(it => it.name);
        const fullText = metrics.join(", ");

        if (boxRefMetric.current) {
            const boxWidth = boxRefMetric.current.offsetWidth;  // Get the box width
            const textWidth = boxRefMetric.current.scrollWidth + 100;  // Get the full text width
            console.log("textWidth", boxRefMetric.current, textWidth, boxWidth)
            // If text overflows, show "and X more", otherwise show all metrics
            if (textWidth > boxWidth) {
                const numMetricsToDisplay = 1;  // Display only 1 metric if text overflows
                setFirstMetrics(metrics.slice(0, numMetricsToDisplay));
                setHiddenMetrics(metrics.slice(numMetricsToDisplay));
                setShouldTruncate(true);
            } else {
                setFirstMetrics(metrics);  // Display all metrics if they fit
                setHiddenMetrics([]);
                setShouldTruncate(false);
            }
        }
    };
    useEffect(() => {
        updateNamesDisplay()
        updateMetricsDisplay()
    }, [selectedOutInflu, selectedNodes])
    const displayMetrics = () => {
        // Take 1 metric if there are remaining metrics, otherwise take 2
        const numMetricsToDisplay = alls.length > 0 ? 1 : 2;
        const firstMetrics = alls.slice(0, numMetricsToDisplay);
        // Step 2: For the remaining elements, increment `a` by 2.
        const remainingMetrics = alls.slice(numMetricsToDisplay);
        return {
            firstMetrics: firstMetrics.map(it => it.name).join(", "),
            hiddenMetrics: remainingMetrics.map(it => it.name)  // Hidden metrics for tooltip
        };
    };
    console.log("firstMetrics", firstMetrics);

    const boxRefMatric = useRef(null);  // Reference for the box container
    // Combine the outcomes and influences into one array
    const allsMetrics = [...selectedOutcomes.map(it => ({ ...it, isOutcome: true })), ...selectedInfluence.map(it => ({ ...it, isOutcome: false }))];
    const updateTagDisplay = () => {
        if (boxRefMatric.current) {
            const containerWidth = boxRefMatric.current.offsetWidth; // Get the container width
            let totalWidth = 0; // To accumulate total width of tags
            const visible = [];
            const hidden = [];
            console.log("allsMetrics", allsMetrics, containerWidth);

            allsMetrics.forEach(tag => {
                // Create a temporary element to measure tag width
                const tagElement = document.createElement("div");
                tagElement.style.visibility = "hidden";
                tagElement.style.position = "absolute";
                tagElement.style.height = "34px";
                tagElement.style.padding = "8px 24px";
                tagElement.style.display = "inline-flex";
                tagElement.style.border = "1px solid var(--color-border)";
                tagElement.style.borderRadius = "20px";
                tagElement.innerText = tag.name;
                document.body.appendChild(tagElement);

                const tagWidth = tagElement.offsetWidth; // Measure the tag width
                document.body.removeChild(tagElement); // Remove the temporary element

                if (totalWidth + tagWidth < containerWidth) {
                    totalWidth += tagWidth; // Add width if it fits
                    visible.push(tag);
                } else {
                    hidden.push(tag); // Otherwise, add to hidden tags
                }
            });

            setVisibleTags(visible);
            setHiddenTags(hidden);
            setShouldTruncatePreview(hidden.length > 0);
        }
    };
    useEffect(() => {
        if (isInPreviewMode) {
            updateTagDisplay()
        }
    }, [isInPreviewMode, selectedOutInflu,])
    console.log("editRecord", editRecord);

    return <>
        <Box display={"flex"} className="hide-scrollbar" padding={isSideModalVisible ? "32px 24px" : "0px"} height={"100%"} flexDirection={"column"} gap={"16px"} alignItems={"flex-start"} alignSelf={"stretch"} justifyContent={isSideModalVisible ? "space-between" : "flex-start"}
            sx={isSideModalVisible ? {
                width: "360px",
                overflowY: "auto"
            } : {
                width: "360px"
            }}>
            <Box display={"flex"} flexDirection={"column"} gap={"16px"} alignItems={"flex-start"} alignSelf={"stretch"} justifyContent={"flex-start"}>
                {isInPreviewMode ? <Box display={"flex"} flexDirection={"column"} gap={"8px"}>
                    <CommonTypo fontWeight={700} size={24} lineHeight={29} color="var(--color-text-primary)">{editRecord ? "Edit social report" : "Share social report"}</CommonTypo>
                    <CommonTypo fontWeight={600} size={16} lineHeight={19.2} color="var(--color-text-primary)">Please review the information</CommonTypo>
                </Box> : <CommonTypo fontWeight={700} typographySx={{
                    fontWeight: "700 !important"
                }} size={24} lineHeight={29} color="var(--color-text-primary)">{editRecord ? "Edit social report" : "Share social report"}</CommonTypo>}

                {isInPreviewMode ?
                    <>
                        <Box display={"flex"} flexDirection={"column"} gap={"8px"} alignItems={"flex-start"} alignSelf={"stretch"} justifyContent={"flex-start"}>
                            <CommonTypo fontWeight={600} size={16} lineHeight={19} color="var(--color-text-primary)">Who do you want to share with?</CommonTypo>
                            <Box sx={{
                                display: "flex",
                                alignContent: "center",
                                flexWrap: "wrap",
                                alignSelf: "stretch",
                                borderRadius: "12px",
                                gap: "8px"
                                // height: "50px",
                                // background: isSelectedFocus === "contact" ? "var(--color-border)" : "var(----white-background)"
                            }} onClick={() => {
                                // setSideModalVisible(true)
                                // setSelectedFocus("contact")
                            }} >
                                {contactNames && contactNames.length
                                    ? contactNames.map(ir => <OutInflueTag isNormal={true} name={ir.name} isOutcome={false} />)
                                    : null}

                            </Box>
                        </Box>
                        <Box display={"flex"} flexDirection={"column"} gap={"8px"} alignItems={"flex-start"} alignSelf={"stretch"} justifyContent={"flex-start"}>
                            <CommonTypo fontWeight={600} size={16} lineHeight={19} color="var(--color-text-primary)">What do you want to share?</CommonTypo>
                            <Box ref={boxRefMatric} sx={{
                                display: "flex",
                                alignContent: "center",
                                flexWrap: "wrap",
                                alignSelf: "stretch",
                                borderRadius: "12px",
                                gap: "8px"
                                // height: "50px",
                                // background: isSelectedFocus === "metric" ? "var(--color-border)" : "var(----white-background)"
                            }} onClick={() => {
                                // setSideModalVisible(true)
                                // setSelectedFocus("metric")
                            }} >
                                {/* {selectedOutcomes.map(it => <OutInflueTag isOutcome={true} name={it.name} isNormal={false} />)}
                                {selectedInfluence.map(it => <OutInflueTag isOutcome={false} name={it.name} isNormal={false} />)} */}
                                {/* Render visible tags */}
                                {visibleTags.map((it, index) => (
                                    <OutInflueTag
                                        key={index}
                                        name={it.name}
                                        isOutcome={it.isOutcome}
                                        isNormal={false}
                                    />
                                ))}

                                {/* If there are hidden tags, show "and X more" with a tooltip */}
                                {shouldTruncatePreview && (
                                    <HTMLTooltip
                                        customIcon={<div><OutInflueTag
                                            name={` + ${hiddenTags.length}`}
                                            isOutcome={false}
                                            isNormal={true}
                                            pointer
                                        /></div>}
                                        title={<>{hiddenTags.map((it, index) => <CommonTypo fontWeight={600} size={16} lineHeight={16} color="var(--color-text-primary)">{it.name}{index !== (hiddenNames.length - 1) && ", "}</CommonTypo>)}</>}
                                        fontProps={{
                                            fontSize: `${pxToRem(12)} !important`,
                                        }}
                                        placement="right-start"
                                        sx={{
                                            "& .MuiTooltip-tooltip": {
                                                maxWidth: "300px",
                                                background: '#F6F6F6',
                                                borderColor: "#F6F6F6",
                                                '& .MuiTooltip-arrow': {
                                                    color: "#F6F6F6",
                                                    // borderTop: '1px solid #9747FF',
                                                    // borderLeft: '1px solid #9747FF',
                                                    // transform: 'translate3d(4px, 33px, 11px) rotate(-38deg)!important'
                                                    '&:before': {
                                                        border: "1px solid #F6F6F6 !important"
                                                    }
                                                }
                                            },

                                        }}
                                    />

                                )}

                            </Box>
                        </Box>
                        <Box display={"flex"} flexDirection={"column"} gap={"8px"} alignItems={"flex-start"} alignSelf={"stretch"} justifyContent={"flex-start"}>
                            <CommonTypo fontWeight={600} size={16} lineHeight={19} color="var(--color-text-primary)">How often do you want to share?</CommonTypo>
                            <Box sx={{
                                display: "flex",
                                alignContent: "center",
                                flexWrap: "wrap",
                                // border: "1px solid var(--color-border)",
                                alignSelf: "stretch",
                                borderRadius: "12px",
                                gap: "8px"
                                // height: "50px",
                                // background: isSelectedFocus === "schedule" ? "var(--color-border)" : "var(----white-background)"
                            }} onClick={() => {
                                // setSideModalVisible(true)
                                // setSelectedFocus("schedule")
                            }} >
                                <OutInflueTag isOutcome={true} name={sendStatus === "REGULARLY_WEEK" ? "Weekly" : sendStatus === "REGULARLY_MONTH" ? "Month" : "No repeat"} isNormal={true} />
                                {["REGULARLY_WEEK", "REGULARLY_MONTH"].includes(sendStatus) && (
                                    <OutInflueTag isOutcome={true} name={sendStatus === "REGULARLY_WEEK" ? `on ${dayToSend.toString().toLowerCase()}` : sendStatus === "REGULARLY_MONTH" ? `on ${dayToSend}` : "No repeat"} isNormal={true} />
                                )}
                            </Box>
                        </Box>
                        {!isSideModalVisible && (
                            <Box sx={{
                                height: "30px",
                                alignSelf: "stretch",
                                cursor: "pointer"
                            }} onClick={viewSchedule}>
                                <CommonTypo fontWeight={400} size={12} lineHeight={15} color="var(--color-text-primary)" >View scheduled reports</CommonTypo>
                            </Box>
                        )}
                    </>
                    : (
                        <>
                            <Box display={"flex"} flexDirection={"column"} gap={"8px"} alignItems={"flex-start"} alignSelf={"stretch"} justifyContent={"flex-start"}>
                                <CommonTypo fontWeight={600} size={16} lineHeight={19} color="var(--color-text-primary)">Who do you want to share with?</CommonTypo>
                                <Box ref={boxRef} sx={{
                                    display: "flex",
                                    alignContent: "center",
                                    alignItems: "center",
                                    border: "1px solid var(--color-border)",
                                    alignSelf: "stretch",
                                    padding: "16px !important",
                                    borderRadius: "12px",
                                    height: "50px",
                                    background: isSelectedFocus === "contact" ? "var(--color-border)" : "var(----white-background)",
                                    whiteSpace: "nowrap", // Prevents wrapping to a new line
                                    overflow: "hidden",   // Hides the overflow
                                    textOverflow: "ellipsis"  // Adds ellipsis when the text overflows
                                }} onClick={() => {
                                    setSideModalVisible(true)
                                    setSelectedFocus("contact")
                                }} >
                                    {contactNames && contactNames.length
                                        ? <CommonTypo size={14} lineHeight={18.2} color="var(--color-text-secondary)" fontWeight={600} extraSx={{
                                            fontWeight: `600 !important`
                                        }}>
                                            {firstNames.join(", ")}
                                            {hiddenNames.length > 0 && (
                                                <>
                                                    &nbsp;
                                                    <HTMLTooltip
                                                        customIcon={<div style={{ cursor: 'pointer', display: "inline-flex" }}>
                                                            + {hiddenNames.length} more
                                                        </div>}
                                                        title={<>{hiddenNames.map((it, index) => <CommonTypo fontWeight={600} size={16} lineHeight={16} color="var(--color-text-primary)">{it}{index !== (hiddenNames.length - 1) && ", "}</CommonTypo>)}</>}
                                                        fontProps={{
                                                            fontSize: `${pxToRem(12)} !important`,
                                                        }}
                                                        placement="right-start"
                                                        sx={{
                                                            "& .MuiTooltip-tooltip": {
                                                                maxWidth: "300px",
                                                                background: '#F6F6F6',
                                                                borderColor: "#F6F6F6",
                                                                '& .MuiTooltip-arrow': {
                                                                    color: "#F6F6F6",
                                                                    // borderTop: '1px solid #9747FF',
                                                                    // borderLeft: '1px solid #9747FF',
                                                                    // transform: 'translate3d(4px, 33px, 11px) rotate(-38deg)!important'
                                                                    '&:before': {
                                                                        border: "1px solid #F6F6F6 !important"
                                                                    }
                                                                }
                                                            },

                                                        }}
                                                    />
                                                </>
                                            )}
                                        </CommonTypo>
                                        : <CommonTypo size={14} lineHeight={18.2} color="var(--color-text-secondary)">Choose recipients</CommonTypo>}

                                </Box>
                            </Box>
                            <Box display={"flex"} flexDirection={"column"} gap={"8px"} alignItems={"flex-start"} alignSelf={"stretch"} justifyContent={"flex-start"}>
                                <CommonTypo fontWeight={600} size={16} lineHeight={19} color="var(--color-text-primary)">What do you want to share?</CommonTypo>
                                <Box ref={boxRefMetric} sx={{
                                    display: "flex",
                                    alignContent: "center",
                                    alignItems: "center",
                                    border: "1px solid var(--color-border)",
                                    alignSelf: "stretch",
                                    padding: "16px !important",
                                    borderRadius: "12px",
                                    height: "50px",
                                    background: isSelectedFocus === "metric" ? "var(--color-border)" : "var(----white-background)",
                                    whiteSpace: "nowrap", // Prevents wrapping to a new line
                                    overflow: "hidden",   // Hides the overflow
                                    textOverflow: "ellipsis"  // Adds ellipsis when the text overflows
                                }} onClick={() => {
                                    setSideModalVisible(true)
                                    setSelectedFocus("metric")
                                }} >
                                    {alls && alls.length
                                        ? <CommonTypo size={14} lineHeight={18.2} color="var(--color-text-secondary)" extraSx={{
                                            fontWeight: `600 !important`
                                        }}>
                                            <span ref={textRefMetric}>{firstMetrics}</span>
                                            {hiddenMetrics.length > 0 && (
                                                <>
                                                    &nbsp;and&nbsp;
                                                    <HTMLTooltip
                                                        customIcon={<div style={{ cursor: 'pointer', display: "inline-flex" }}>
                                                            {hiddenMetrics.length} more
                                                        </div>}
                                                        title={<>{hiddenMetrics.map((it, index) => <CommonTypo fontWeight={600} size={16} lineHeight={16} color="var(--color-text-primary)">{it}{index !== (hiddenMetrics.length - 1) && ", "}</CommonTypo>)}</>}
                                                        fontProps={{
                                                            fontSize: `${pxToRem(12)} !important`,
                                                        }}
                                                        placement="right-start"
                                                        sx={{
                                                            "& .MuiTooltip-tooltip": {
                                                                maxWidth: "300px",
                                                                background: '#F6F6F6',
                                                                borderColor: "#F6F6F6",
                                                                '& .MuiTooltip-arrow': {
                                                                    color: "#F6F6F6",
                                                                    // borderTop: '1px solid #9747FF',
                                                                    // borderLeft: '1px solid #9747FF',
                                                                    // transform: 'translate3d(4px, 33px, 11px) rotate(-38deg)!important'
                                                                    '&:before': {
                                                                        border: "1px solid #F6F6F6 !important"
                                                                    }
                                                                }
                                                            },

                                                        }}
                                                    />
                                                </>
                                            )}
                                        </CommonTypo>
                                        : <CommonTypo size={14} lineHeight={18.2} color="var(--color-text-secondary)">Choose metrics</CommonTypo>}

                                </Box>
                            </Box>
                            <Box display={"flex"} flexDirection={"column"} gap={"8px"} alignItems={"flex-start"} alignSelf={"stretch"} justifyContent={"flex-start"}>
                                <CommonTypo fontWeight={600} size={16} lineHeight={19} color="var(--color-text-primary)">How often do you want to share?</CommonTypo>
                                <Box sx={{
                                    display: "flex",
                                    alignContent: "center",
                                    border: "1px solid var(--color-border)",
                                    alignSelf: "stretch",
                                    padding: "16px !important",
                                    borderRadius: "12px",
                                    height: "50px",
                                    background: isSelectedFocus === "schedule" ? "var(--color-border)" : "var(----white-background)"
                                }} onClick={() => {
                                    setSideModalVisible(true)
                                    setSelectedFocus("schedule")
                                }} >

                                    {dayToSend
                                        ? <CommonTypo size={14} lineHeight={18.2} color="var(--color-text-secondary)" fontWeight={600}>Every {sendStatus === "REGULARLY_WEEK" && typeof dayToSend === "string" ? dayToSend.toString().toLowerCase() : typeof dayToSend === "number" && (dayToSend + "th")}</CommonTypo>
                                        : <CommonTypo size={14} lineHeight={18.2} color="var(--color-text-secondary)">Choose frequency</CommonTypo>}
                                </Box>
                            </Box>
                            {!isSideModalVisible && (
                                <Box sx={{
                                    height: "30px",
                                    alignSelf: "stretch",
                                    cursor: "pointer"
                                }} onClick={viewSchedule}>
                                    <CommonTypo fontWeight={400} size={12} lineHeight={15} color="var(--color-text-primary)" >View scheduled reports</CommonTypo>
                                </Box>
                            )}
                        </>
                    )}

            </Box>

            <Box sx={{
                alignSelf: "stretch",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between"
            }}>
                <Box display={"flex"} alignItems={"center"} gap={"8px"}>
                    <Box width={"76px"} height={"8px"} borderRadius={"50px"} sx={{
                        backgroundColor: isInPreviewMode ? "var(--color-border)" : "var(--color-new-main)"
                    }}></Box>
                    <Box width={"76px"} height={"8px"} borderRadius={"50px"} sx={{
                        backgroundColor: isInPreviewMode ? "var(--color-new-main)" : "var(--color-border)"
                    }}></Box>
                </Box>
                <Box display={"flex"} alignItems={"center"} justifyContent={"center"} gap={"8px"} height={"40px"}>
                    <Box padding={"16px"} borderRadius={"20px"} gap={"8px"} height={"40px"} display={"flex"} alignItems={"center"} justifyContent={"center"} width={isInPreviewMode ? "auto" : "52px"} sx={{
                        backgroundColor: enabledButton ? "var(--color-new-main)" : "var(--color-border)",
                        cursor: enabledButton ? "pointer" : "not-allowed"
                    }} onClick={() => {
                        if (isInPreviewMode) {
                            shareReport()
                            return
                        }
                        if (enabledButton) {
                            viewShareReport()
                            setIsInPreviewMode(true)
                        }
                    }}>
                        {isInPreviewMode && <CommonTypo size={16} lineHeight={16} fontWeight={400} color="var(--color-text-primary)">Share</CommonTypo>}

                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_1116_5961)">
                                <path d="M7.5 6.175L11.3167 10L7.5 13.825L8.675 15L13.675 10L8.675 5L7.5 6.175Z" fill="#656565" />
                            </g>
                            <defs>
                                <clipPath id="clip0_1116_5961">
                                    <rect width="20" height="20" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </Box>
                </Box>
            </Box>

        </Box>
    </>
}

export default SocialReportShareStep1

const OutInflueTag = ({ name, isOutcome, isNormal, pointer = false }) => {
    return <Box sx={{
        height: "34px",
        padding: "8px 24px",
        gap: "4px",
        display: "flex",
        alignItems: "center",
        borderRadius: "20px",
        border: "1px solid var(--color-border)",
        cursor: pointer ? "pointer" : "default"
    }}>
        {isNormal ? null : isOutcome ? <OutcomeIcon /> : <InfluenceIcon />}
        <CommonTypo size={14} lineHeight={18.2} color="var(--color-text-secondary)">{name}</CommonTypo>
    </Box>
}