
import {
    DialogContent,
    Dialog,
    Typography,
} from "@mui/material";
import React, { useContext } from "react";
import palette from "../../theme/palette";
import { pxToRem } from "../../theme/typography";
import { TrackingContext } from "../../context/userTracking";
import { setTrackingState } from "../../store/actions/trackerAction";
import useStyles from "./TrackingClasses";



const LimitReachedPopup = () => {
    const classes = useStyles();
    const { state: ITrackerState, dispatch: trackingDispatch } =
        useContext(TrackingContext);
    const { limitPopupVisible, limitPopuptype } = ITrackerState
    return (
        <Dialog
            open={limitPopupVisible}
            onClose={() => trackingDispatch(setTrackingState({
                limitPopupVisible: false,
                limitPopuptype: 'outcome',
                showOutcome: false,
                openStartModal: false,
                addOutcomeTracking: false,
                categoryId: "",
                showQues: false,
                openStartModalQues: false,
                addQuesTracking: false,
                showInfluence: false,
                openStartModalInfluence: false,
                addInfluenceTracking: false,
            }))}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.categoryDialog}
        >
            <DialogContent id="alert-dialog-description">
                <div style={{
                    display: 'flex',
                    padding: '32px ',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '16px',
                    alignSelf: 'stretch',

                }}>
                    <Typography variant="h3" component="span" lineHeight="135% !important" sx={{ width: "100%", textAlign: "center", color: palette.common.black, fontSize: `${pxToRem(24)} !important`, fontWeight: 600 }}>
                        {`You are in discovery phase and you reached your limit for ${limitPopuptype === "outcome" ? "outcome" : limitPopuptype === "influence" ? "influence" : "qualitative reflections"} metrics, please archive one existing metric and add new metric`}
                    </Typography>
                </div>

            </DialogContent>

        </Dialog>
    );
};

export default LimitReachedPopup;
