import * as React from "react";

const StepperNextArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="86"
      height="48"
      viewBox="0 0 86 48"
      fill="none"
    >
      <path
        d="M0 24C0 10.7452 10.7452 0 24 0H62C75.2548 0 86 10.7452 86 24C86 37.2548 75.2548 48 62 48H24C10.7452 48 0 37.2548 0 24Z"
        fill="#11734A"
      />
      <path
        d="M30.336 30H28.512L23.024 21.696V30H21.2V18.864H23.024L28.512 27.152V18.864H30.336V30ZM40.8113 25.376C40.8113 25.7067 40.7899 26.0053 40.7473 26.272H34.0113C34.0646 26.976 34.3259 27.5413 34.7953 27.968C35.2646 28.3947 35.8406 28.608 36.5233 28.608C37.5046 28.608 38.1979 28.1973 38.6033 27.376H40.5712C40.3046 28.1867 39.8193 28.8533 39.1153 29.376C38.4219 29.888 37.5579 30.144 36.5233 30.144C35.6806 30.144 34.9233 29.9573 34.2513 29.584C33.5899 29.2 33.0673 28.6667 32.6833 27.984C32.3099 27.2907 32.1233 26.4907 32.1233 25.584C32.1233 24.6773 32.3046 23.8827 32.6673 23.2C33.0406 22.5067 33.5579 21.9733 34.2193 21.6C34.8913 21.2267 35.6593 21.04 36.5233 21.04C37.3553 21.04 38.0966 21.2213 38.7473 21.584C39.3979 21.9467 39.9046 22.4587 40.2673 23.12C40.6299 23.7707 40.8113 24.5227 40.8113 25.376ZM38.9073 24.8C38.8966 24.128 38.6566 23.5893 38.1873 23.184C37.7179 22.7787 37.1366 22.576 36.4433 22.576C35.8139 22.576 35.2753 22.7787 34.8273 23.184C34.3793 23.5787 34.1126 24.1173 34.0273 24.8H38.9073ZM46.3823 25.536L49.2303 30H47.1663L45.2623 27.008L43.4703 30H41.5663L44.4143 25.664L41.5663 21.184H43.6303L45.5343 24.176L47.3263 21.184H49.2303L46.3823 25.536ZM52.7186 22.672V27.552C52.7186 27.8827 52.7933 28.1227 52.9426 28.272C53.1026 28.4107 53.3693 28.48 53.7426 28.48H54.8626V30H53.4226C52.6013 30 51.972 29.808 51.5346 29.424C51.0973 29.04 50.8786 28.416 50.8786 27.552V22.672H49.8386V21.184H50.8786V18.992H52.7186V21.184H54.8626V22.672H52.7186Z"
        fill="#fff"
      />
      <path
        d="M65 19.41L69.58 24L65 28.59L66.41 30L72.41 24L66.41 18L65 19.41Z"
        fill="#fff"
      />
    </svg>
  );
};
export default StepperNextArrow;

export const StepperNextArrowNew = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="86"
      height="48"
      viewBox="0 0 86 48"
      fill="none"
    >
      <path
        d="M0 24C0 10.7452 10.7452 0 24 0H62C75.2548 0 86 10.7452 86 24C86 37.2548 75.2548 48 62 48H24C10.7452 48 0 37.2548 0 24Z"
        fill="#ADE888"
      />
      <path
        d="M30.336 30H28.512L23.024 21.696V30H21.2V18.864H23.024L28.512 27.152V18.864H30.336V30ZM40.8113 25.376C40.8113 25.7067 40.7899 26.0053 40.7473 26.272H34.0113C34.0646 26.976 34.3259 27.5413 34.7953 27.968C35.2646 28.3947 35.8406 28.608 36.5233 28.608C37.5046 28.608 38.1979 28.1973 38.6033 27.376H40.5712C40.3046 28.1867 39.8193 28.8533 39.1153 29.376C38.4219 29.888 37.5579 30.144 36.5233 30.144C35.6806 30.144 34.9233 29.9573 34.2513 29.584C33.5899 29.2 33.0673 28.6667 32.6833 27.984C32.3099 27.2907 32.1233 26.4907 32.1233 25.584C32.1233 24.6773 32.3046 23.8827 32.6673 23.2C33.0406 22.5067 33.5579 21.9733 34.2193 21.6C34.8913 21.2267 35.6593 21.04 36.5233 21.04C37.3553 21.04 38.0966 21.2213 38.7473 21.584C39.3979 21.9467 39.9046 22.4587 40.2673 23.12C40.6299 23.7707 40.8113 24.5227 40.8113 25.376ZM38.9073 24.8C38.8966 24.128 38.6566 23.5893 38.1873 23.184C37.7179 22.7787 37.1366 22.576 36.4433 22.576C35.8139 22.576 35.2753 22.7787 34.8273 23.184C34.3793 23.5787 34.1126 24.1173 34.0273 24.8H38.9073ZM46.3823 25.536L49.2303 30H47.1663L45.2623 27.008L43.4703 30H41.5663L44.4143 25.664L41.5663 21.184H43.6303L45.5343 24.176L47.3263 21.184H49.2303L46.3823 25.536ZM52.7186 22.672V27.552C52.7186 27.8827 52.7933 28.1227 52.9426 28.272C53.1026 28.4107 53.3693 28.48 53.7426 28.48H54.8626V30H53.4226C52.6013 30 51.972 29.808 51.5346 29.424C51.0973 29.04 50.8786 28.416 50.8786 27.552V22.672H49.8386V21.184H50.8786V18.992H52.7186V21.184H54.8626V22.672H52.7186Z"
        fill="var(--color-black)"
      />
      <path
        d="M65 19.41L69.58 24L65 28.59L66.41 30L72.41 24L66.41 18L65 19.41Z"
        fill="var(--color-black)"
      />
    </svg>
  );
};
