import {
  Dialog,
  Grid,
  DialogTitle,
  DialogContent,
  Typography,
  ListItem,
  Button,
  List,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core";
import palette from "../../theme/palette";
import { UserTypeContext } from "../../context/userStatus";
import { setUserStatus } from "../../store/actions/userTypeAction";
import useWidth from "../../utils/useWidth";

const Illustration_Popup = require("../../assets/images/Illustration_Popup.png");

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    padding: "0!important",
    fontSize: "26px!important",
    lineHeight: "31px!important",
    color: `${palette.primary.light}!important`,
    marginBottom: "20px!important",
  },
  modalSubTitle: {
    color: `${palette.secondary.contrast}!important`,
    marginBottom: "40px!important",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "20px!important",
    },
  },
  listModalTitle: {
    marginBottom: "20px!important",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "10px!important",
    },
  },
  listItem: {
    padding: "0!important",
  },
  listItemItem: {
    padding: "0!important",
    color: palette.primary.light,
    fontSize: "16px",
    lineHeight: "19px",
    fontWeight: 400,
    "&:last-child": {
      marginTop: "35px",
      marginBottom: "60px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "15px",
        marginBottom: "20px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "17px",
    },
  },
  listItemItemMobile: {
    padding: "0!important",
    color: palette.primary.light,
    fontSize: "16px",
    lineHeight: "19px",
    fontWeight: 400,
    "&:last-child": {
      marginTop: "35px",
      marginBottom: "60px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "15px",
        marginBottom: "20px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "17px",
    },
    display: "flex",
    alignItems: "center"
  },
  Illustration_Popup: {
    position: "absolute",
    top: "-75px",
    left: 0,
    right: 0,
    margin: "auto",
    width: "130px",
    height: "130px",
    [theme.breakpoints.down(575)]: {
      width: "120px",
      top: "-110px",
    },
  },
}));

interface StartTrackingModalInt {
  setOpenStartTracking: (openStartTracking: boolean) => void;
}

const StartTrackingModal = (props: StartTrackingModalInt) => {
  const classes = useStyles();
  const { dispatch: IUserTypeDispatch } = useContext(UserTypeContext);
  const [openStartModal, setStartModal] = useState(true);
  const handleCloseStartModal = () => {
    setStartModal(false);
  };
  const width = useWidth()
  let isWeb = width > 768
  return (
    <Grid>
      <Dialog
        onClose={handleCloseStartModal}
        open={openStartModal}
        className="dialogWrapper"
        style={{margin: "0px !important"}}
        disableEscapeKeyDown
      >
        <img
          src={Illustration_Popup}
          alt="Illustration_Popup"
          className={classes.Illustration_Popup}
        />
        {isWeb ? <DialogTitle className={classes.dialogTitle} sx={{ fontWeight: isWeb? "700 !important" : "500 !important", fontSize: isWeb ? "26px !important" : "20px !important"}}>
          Congratulations, you are on your way to start your journey to
          greatness!
        </DialogTitle> : <h3 style={{fontSize: "20px", lineHeight: "21px", fontWeight: 500}}>Congratulations for starting your journey to greatness!</h3>}
        <DialogContent>
          {isWeb ? <Typography
            variant="subtitle1"
            component="div"
            className={classes.modalSubTitle}
            sx={{ fontWeight: 400 }}
          >
            Start by tracking your normal daily activity and progress regarding
            your outcomes and influences to get a baseline of how you are doing
          </Typography> : <p style={{color: "#9BA19D", fontWeight: 400, fontSize: "14px"}}>
            Start by tracking your normal daily activity and progress regarding
            your outcomes and influences to get a baseline of how you are doing
          </p>}
          <Grid>
            {isWeb ?  <Typography
              variant="subtitle1"
              component="div"
              className={classes.listModalTitle}
            >
              After your first 30 day of tracking your will be able to:
            </Typography> : <h3 style={{fontSize: "16px", fontWeight: 500, color: "#3E4240", lineHeight: "20px", marginTop: "40px"}}
            >
              After your first 30 day of tracking your will be able to:
            </h3>}
            <List className={classes.listItem}>
              <ListItem className={isWeb ? classes.listItemItem : classes.listItemItemMobile}>
                {!isWeb && <span style={{backgroundColor: "#169C63", height: "6px", minWidth: "6px", borderRadius: "90px", alignSelf:"flex-start", marginRight: "4px", marginTop: "5px"}}></span>}
                Get insights into how influences affect your outcomes
              </ListItem>
              <ListItem className={isWeb ? classes.listItemItem : classes.listItemItemMobile}>
              {!isWeb && <span style={{backgroundColor: "#169C63", height: "6px", minWidth: "6px", borderRadius: "90px", alignSelf:"flex-start", marginRight: "4px", marginTop: "5px"}}></span>}
                Set goals for your influences to help you grow in the areas that
                matter
              </ListItem>
            </List>
          </Grid>
          <Button
            variant="contained"
            className="button-after tracking-btn"
            fullWidth
            style={{borderRadius: "30px"}}
            onClick={() => {
              props.setOpenStartTracking(false);
              IUserTypeDispatch(setUserStatus(false));
            }}
          >
            Start Tracking
          </Button>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};
export default StartTrackingModal;
