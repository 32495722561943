import React, { useState } from "react";
import './customDate.css'
import { FormControl, FormControlLabel, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { Box, styled } from "@mui/system";
import moment from "moment";
import { LocalizationProvider, StaticDateRangePicker } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';

import { userService } from "../../../services/auth";
import SnackBarComp from "../../../components/snackBar";

export default function CustomDate({ radioBtnSelected, setRadioBtnSelected, customRange,
  setCustomRange, rangeCount, setRangeCount, isMenuSelected, setIsMenuSelected }) {

  const [showSnakbar, setShowSnakbar] = useState(false);
  const [snakbarMsg, setSnakbarMsg] = useState("");
  const [msgType, setMsgType] = useState("");

  const handleChange = async (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setRangeCount({
      ...rangeCount,
      [name]: parseInt(value),
    })
  }
  const handleCloseSnakbar = () => {
    setShowSnakbar(false);
  };

  const CustomCalendarContainer = styled(Box)(({ theme }) => ({
    background: "#fff",
    display: "flex",
    alignItems: "center",
    gap: "8px",
  }));
  const LastWeekContainer = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: "8px",
    marginLeft: "-20px"

  }));
  const CustomCalendarContainerLast = styled(Box)(({ theme }) => ({
    background: "#fff",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "8px",

  }));


  const CustomInput = styled(TextField)(({ theme }) => ({
    width: "40px",
    height: "32px",
    overflow: "hidden",
    background: "#F7F9FA",
    border: "1px solid #DCE6E0",
    borderRadius: " 8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "green",
    marginBottom: '0px !important',

    "& input": {
      background: "#F7F9FA",
    }
  }));
  const CustomInputDate = styled(TextField)(({ theme }) => ({
    width: "174px !important",
    background: "#F7F9FA",
    border: "1px solid #DCE6E0",
    borderRadius: " 8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));
  const callCustomeRange = (dateValue) => {
    if (dateValue[1] != null) {
      const token = localStorage.getItem("userDetails")
      if (isMenuSelected?.outcome) {
        let bodyValue = {
          from: moment(dateValue[0]?.$d).format("MM-DD-YYYY"),
          to: moment(dateValue[1]?.$d).format("MM-DD-YYYY"),
          outcomeId: isMenuSelected?.outcomeId
        }
        let body = bodyValue
        userService
          .outComeGoals(body, token)
          .then((data) => {
            setIsMenuSelected({
              ...isMenuSelected,
              outComeData: data?.outcome,
              getOutComeData: data?.data[0],
              selectedOutcomeAvgData: data?.average
            })
            setShowSnakbar(true);
            setMsgType("error");
            setSnakbarMsg("Successful");
          })
          .catch((error) => {
            setShowSnakbar(true);
            setMsgType("error");
            setSnakbarMsg("Something Went wrong");
          })
      } else if (isMenuSelected?.influence) {
        let body;
        body = {
          from: moment(dateValue[0]?.$d).format("MM-DD-YYYY"),
          to: moment(dateValue[1]?.$d).format("MM-DD-YYYY"),
          influenceId: isMenuSelected?.influenceId,
          // type: "TYPE1"
          type: isMenuSelected?.selectedInfluenceType
        }
        userService
          .influenceGoal(body, token)
          .then((data) => {
            setIsMenuSelected({
              ...isMenuSelected,
              influenceData: data?.influence,
              getInfluenceData: data?.data[0],
              selectedInfluenceAvgData: data?.average,
              selectedInfluancepPercentData: data?.percent
            })
            setShowSnakbar(true);
            setMsgType("success");
            setSnakbarMsg("Successfull");
          })
          .catch((error) => {
            setShowSnakbar(true);
            setMsgType("error");
            setSnakbarMsg("Somthing Went wrong");
          })
      }

    }
  }

  const CallOutcomeFunction = (bodyValue) => {
    const token = localStorage.getItem("userDetails")
    let body = bodyValue
    userService
      .outComeGoals(body, token)
      .then((data) => {
        setIsMenuSelected({
          ...isMenuSelected,
          outComeData: data?.outcome,
          getOutComeData: data?.data[0],
          selectedOutcomeAvgData: data?.average
        })
        setShowSnakbar(true);
        setMsgType("error");
        setSnakbarMsg("Successfull");
      })
      .catch((error) => {
        setShowSnakbar(true);
        setMsgType("error");
        setSnakbarMsg("Somthing Went wrong");
      })
  }

  const InfluenceFunction = (bodyValue) => {
    const token = localStorage.getItem("userDetails")
    let body = bodyValue
    userService
      .influenceGoal(body, token)
      .then((data) => {
        setIsMenuSelected({
          ...isMenuSelected,
          influenceData: data?.influence,
          getInfluenceData: data?.data[0],
          selectedInfluenceAvgData: data?.average,
          selectedInfluancepPercentData: data?.percent
        })
        setShowSnakbar(true);
        setMsgType("success");
        setSnakbarMsg("Successfull");
      })
      .catch((error) => {
        setShowSnakbar(true);
        setMsgType("error");
        setSnakbarMsg("Somthing Went wrong");
      })
  }

  const callApi = (value) => {
    let body;
    if (isMenuSelected?.outcome) {
      if (radioBtnSelected === "week") {
        body = {
          from: moment().subtract(parseInt(value), 'week').startOf('week').format('MM-DD-YYYY'),
          to: moment(moment().subtract(parseInt(value), 'week').startOf('week')).add(parseInt(value), 'week').format('MM-DD-YYYY'),
          outcomeId: isMenuSelected?.outcomeId
        }
        CallOutcomeFunction(body)
      }
      else if (radioBtnSelected === "month") {
        body = {
          from: moment().subtract(parseInt(value), 'month').startOf('month').format('MM-DD-YYYY'),
          to: moment(moment().subtract(parseInt(value), 'month').startOf('month')).add(parseInt(value), 'month').format('MM-DD-YYYY'),
          outcomeId: isMenuSelected?.outcomeId
        }
        CallOutcomeFunction(body)
      } else if (radioBtnSelected === "quarter") {
        body = {
          from: moment().subtract(parseInt(value), 'quarter').startOf('quarter').format('MM-DD-YYYY'),
          to: moment(moment().subtract(parseInt(value), 'quarter').startOf('quarter')).add(parseInt(value), 'quarter').format('MM-DD-YYYY'),
          outcomeId: isMenuSelected?.outcomeId
        }
        CallOutcomeFunction(body)
      } else if (radioBtnSelected === "year") {
        body = {
          from: moment().subtract(parseInt(value), 'year').startOf('year').format('MM-DD-YYYY'),
          to: moment(moment().subtract(parseInt(value), 'year').startOf('year')).add(parseInt(value), 'year').format('MM-DD-YYYY'),
          outcomeId: isMenuSelected?.outcomeId
        }
        CallOutcomeFunction(body)
      }
    } else if (isMenuSelected?.influence) {
      if (radioBtnSelected === "week") {
        body = {
          from: moment().subtract(parseInt(value), 'week').startOf('week').format('MM-DD-YYYY'),
          to: moment(moment().subtract(parseInt(value), 'week').startOf('week')).add(parseInt(value), 'week').format('MM-DD-YYYY'),
          influenceId: isMenuSelected?.influenceId,
          // type: "TYPE1"
          type: isMenuSelected?.selectedInfluenceType
        }
        InfluenceFunction(body)
      }
      else if (radioBtnSelected === "month") {
        body = {
          from: moment().subtract(parseInt(value), 'month').startOf('month').format('MM-DD-YYYY'),
          to: moment(moment().subtract(parseInt(value), 'month').startOf('month')).add(parseInt(value), 'month').format('MM-DD-YYYY'),
          influenceId: isMenuSelected?.influenceId,
          // type: "TYPE1"
          type: isMenuSelected?.selectedInfluenceType
        }
        InfluenceFunction(body)
      } else if (radioBtnSelected === "quarter") {
        body = {
          from: moment().subtract(parseInt(value), 'quarter').startOf('quarter').format('MM-DD-YYYY'),
          to: moment(moment().subtract(parseInt(value), 'quarter').startOf('quarter')).add(parseInt(value), 'quarter').format('MM-DD-YYYY'),
          influenceId: isMenuSelected?.influenceId,
          // type: "TYPE1"
          type: isMenuSelected?.selectedInfluenceType
        }
        InfluenceFunction(body)
      } else if (radioBtnSelected === "year") {
        body = {
          from: moment().subtract(parseInt(value), 'year').startOf('year').format('MM-DD-YYYY'),
          to: moment(moment().subtract(parseInt(value), 'year').startOf('year')).add(parseInt(value), 'year').format('MM-DD-YYYY'),
          influenceId: isMenuSelected?.influenceId,
          // type: "TYPE1"
          type: isMenuSelected?.selectedInfluenceType
        }
        InfluenceFunction(body)
      }
    }

  }
  return (
    <>
      <SnackBarComp
        showSnakbar={showSnakbar}
        handleCloseSnakbar={handleCloseSnakbar}
        snakbarMsg={snakbarMsg}
        type={msgType ? msgType : "info"}
      />
      <FormControl sx={{ width: "100%" }}>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="female"
          name="radio-buttons-group"

        >
          <CustomCalendarContainer>
            <FormControlLabel checked={radioBtnSelected === "week" ? true : false} control={<Radio />}
              onChange={(e) => {
                setRadioBtnSelected("week")
                localStorage.setItem("selectedRadio","week")
                setRangeCount({
                  week: 0,
                  month: 0,
                  quarter: 0,
                  year: 0
                })
              }}
            />
            <LastWeekContainer>
              <Typography variant="caption">Last</Typography>
              <CustomInput
                InputProps={{
                  disabled: radioBtnSelected === "week" ? false : true,
                  name: "week",
                  style: { textAlign: "center" },
                  underline: {
                    "&&&:before": {
                      borderBottom: "none",
                    },
                    "&&:after": {
                      borderBottom: "none",
                    },
                  },
                }}
                value={rangeCount.week}
                onChange={(e) => {
                  handleChange(e)
                  callApi(parseInt(e.target.value))
                }
                }
              ></CustomInput>
              <Typography variant="caption">week</Typography>
            </LastWeekContainer>
          </CustomCalendarContainer>

          <CustomCalendarContainer>
            <FormControlLabel value="checkBoxTwo" checked={radioBtnSelected === "month" ? true : false} control={<Radio />}
              onChange={(e) => {
                setRadioBtnSelected("month")
                localStorage.setItem("selectedRadio","month")
                setRangeCount({
                  week: 0,
                  month: 0,
                  quarter: 0,
                  year: 0
                })
              }} />
            <LastWeekContainer>
              <Typography variant="caption">Last</Typography>
              <CustomInput
                InputProps={{
                  disabled: radioBtnSelected === "month" ? false : true,
                  name: "month",
                  style: { textAlign: "center" },
                  underline: {
                    "&&&:before": {
                      borderBottom: "none",
                    },
                    "&&:after": {
                      borderBottom: "none",
                    },
                  },
                }}
                value={rangeCount.month}
                onChange={(e) => {
                  handleChange(e)
                  callApi(e.target.value)
                }}
              ></CustomInput>
              <Typography variant="caption">month</Typography>
            </LastWeekContainer>
          </CustomCalendarContainer>

          <CustomCalendarContainer>
            <FormControlLabel value="checkBoxThree" checked={radioBtnSelected === "quarter" ? true : false} control={<Radio />}
              onChange={(e) => {
                setRadioBtnSelected("quarter")
                localStorage.setItem("selectedRadio","quarter")
                setRangeCount({
                  week: 0,
                  month: 0,
                  quarter: 0,
                  year: 0
                })
              }} />
            <LastWeekContainer>
              <Typography variant="caption">Last</Typography>
              <CustomInput
                InputProps={{
                  disabled: radioBtnSelected === "quarter" ? false : true,
                  name: "quarter",
                  style: { textAlign: "center" },
                  underline: {
                    "&&&:before": {
                      borderBottom: "none",
                    },
                    "&&:after": {
                      borderBottom: "none",
                    },
                  },
                }}
                value={rangeCount.quarter}
                onChange={(e) => {
                  handleChange(e)
                  callApi(parseInt(e.target.value))
                }}
              ></CustomInput>
              <Typography variant="caption">quarter</Typography>
            </LastWeekContainer>
          </CustomCalendarContainer>

          <CustomCalendarContainer>
            <FormControlLabel value="checkBoxFour" checked={radioBtnSelected === "year" ? true : false}
              control={<Radio />}
              onChange={(e) => {
                setRadioBtnSelected("year")
                localStorage.setItem("selectedRadio","year")
                setRangeCount({
                  week: 0,
                  month: 0,
                  quarter: 0,
                  year: 0
                })
              }}
            />
            <LastWeekContainer>

              <Typography variant="caption">Last</Typography>
              <CustomInput
                InputProps={{
                  disabled: radioBtnSelected === "year" ? false : true,
                  name: "year",
                  style: { textAlign: "center" },
                  underline: {
                    "&&&:before": {
                      borderBottom: "none",
                    },
                    "&&:after": {
                      borderBottom: "none",
                    },
                  },
                }}
                value={rangeCount.year}
                onChange={(e) => {
                  handleChange(e)
                  callApi(e.target.value)
                }}
              ></CustomInput>
              <Typography variant="caption">year</Typography>
            </LastWeekContainer>
          </CustomCalendarContainer>


          <CustomCalendarContainer>
            <FormControlLabel value="checkBoxFive"
              checked={radioBtnSelected === "customRange" ? true : false}
              control={<Radio />}
              onChange={(e) => {
                setRadioBtnSelected("customRange")
                localStorage.setItem("selectedRadio","customRange")
                setRangeCount({
                  week: 0,
                  month: 0,
                  quarter: 0,
                  year: 0
                })
              }}

            />
            <LastWeekContainer>
              <Typography variant="caption">Custom range</Typography>
            </LastWeekContainer>
          </CustomCalendarContainer>

          {
            radioBtnSelected == 'customRange' &&
            <CustomCalendarContainerLast>
              <div style={{ display: "flex", gap: "15px" }}>
                <CustomInputDate
                  type="text"
                  onChange={(e) => {
                    handleChange(e)
                  }}
                  value={moment(customRange[0]?.$d).format("DD-MM-YYYY")}
                  InputProps={{
                    disabled: radioBtnSelected === "customRange" ? false : true,
                    name: "customRange",
                    style: { textAlign: "center", height: "48px", background: "#F7F9FA" },
                    underline: {
                      "&&&:before": {
                        borderBottom: "none",
                      },
                      "&&:after": {
                        borderBottom: "none",
                      },
                    },
                  }}
                />

                <CustomInputDate
                  type="text"
                  onChange={(e) => {
                    handleChange(e)
                  }}
                  value={moment(customRange[1]?.$d).format("DD-MM-YYYY")}
                  InputProps={{
                    disabled: radioBtnSelected === "customRange" ? false : true,
                    name: "customRange",
                    style: { textAlign: "center", height: "48px", background: "#F7F9FA" },
                    underline: {
                      "&&&:before": {
                        borderBottom: "none",
                      },
                      "&&:after": {
                        borderBottom: "none",
                      },
                    },
                  }}
                />
              </div>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StaticDateRangePicker className="staticDateRangePicker"
                  disabled={radioBtnSelected === "customRange" ? false : true}
                  displayStaticWrapperAs="desktop"
                  calendars={1}
                  name="customRange"
                  showToolbar={false}
                  toolbarTitle={""}
                  value={customRange}
                  onChange={(newValue) => {
                    if(newValue[1] !== null){
                      callCustomeRange(newValue)
                      setCustomRange(newValue)
                    }
                  }}
                  componentsProps={{
                    actionBar: {
                      actions: [],
                    },
                  }}
                />
              </LocalizationProvider>
            </CustomCalendarContainerLast>
          }
        </RadioGroup>
      </FormControl>

    </>
  );
}
