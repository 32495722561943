
import React from 'react'

export default function MeasurementIcon() {
  return (
    <>
      <svg width="20" height="20" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_809_11174)">
        <path d="M5.59099 15.1472C5.49336 15.0496 5.49336 14.8913 5.59099 14.7937L18.7644 1.62029C18.862 1.52266 19.0203 1.52266 19.118 1.62029L26.409 8.91134C26.5066 9.00897 26.5066 9.16726 26.409 9.2649L13.2356 22.4383C13.138 22.5359 12.9797 22.5359 12.882 22.4383L5.59099 15.1472Z" stroke="#B7BDB9" strokeWidth="2"/>
        <line x1="15.7276" y1="4.64446" x2="19.9703" y2="8.8871" stroke="#B7BDB9" strokeWidth="2"/>
        <line x1="12.1925" y1="8.1801" x2="16.4351" y2="12.4227" stroke="#B7BDB9" strokeWidth="2"/>
        <line x1="8.65633" y1="11.7157" x2="12.899" y2="15.9584" stroke="#B7BDB9" strokeWidth="2"/>
        </g>
        <defs>
        <filter id="filter0_d_809_11174" x="0" y="0" width="32" height="32" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="2"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_809_11174"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_809_11174" result="shape"/>
        </filter>
        </defs>
      </svg>
    </>
  )
}
