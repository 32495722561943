import React, { useEffect, useState } from "react";
import classes from "./SideBar.module.css";
import styled from "@emotion/styled";
import { Box } from "@mui/material";
import palette from "../../../theme/palette";

import ListIcon from "@mui/icons-material/List";
import SideBarContent from "./SideBarContent";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

function SideBar({ isMenuSelected, setIsMenuSelected, golesMenu, otherGolesMenu }) {
  const [toggleSideMenu, setToggleSideMenu] = useState(false);

  const ListIconMenu = styled("div")(({ theme }) => ({
    position: "absolute",
    top: "75px",
    left: "15px",
    zIndex: "5",
    color: palette.primary.main,
    [theme.breakpoints.down("xl")]: {
      display: "none",
    },
    [theme.breakpoints.up("xl")]: {
      display: "none",
    },
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
    [theme.breakpoints.down("md")]: {
      display: "block",
      // width: "182px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
      // width: "182px",
    },
  }));

  const Wrapper = styled(Box)(({ theme }) => ({
    marginTop: "34px",
    display: "flex",
    flexDirection: "row",

    // this change at afternoon
    // alignSelf: 'stretch',
    // width: "21vw",
    // position: 'relative',
    
    background: "#ffffff",
    [theme.breakpoints.down("lg")]: {
      marginTop: "40px"
    },
    [theme.breakpoints.down("md")]: {
      display: !toggleSideMenu ? "none" : "block",
      width: "225px",
      height: 'calc(100% - 128px)',
      position: 'absolute',
    },
    [theme.breakpoints.down("sm")]: {
      display: !toggleSideMenu ? "none" : "block",
      width: "200px",
      // background: "skyblue",
    },
  }));

  const ListIconBox = styled(Box)(({ theme }) => ({
    position: "fixed",
    zIndex: "6",
    left: "20px",
    top: "80px",
  }));
  const HighlightOffIconBox = styled(Box)(({ theme }) => ({
    position: "fixed",
    zIndex: "6",
    left: "236px",
    top: "80px",
    [theme.breakpoints.down("sm")]: {
      left: "200px",
    }
  }));
  return (
    <>
      <ListIconMenu style={{ left: toggleSideMenu ? "11rem" : "20px" }}>
        {toggleSideMenu ? (
          <HighlightOffIconBox>
            <HighlightOffIcon
              onClick={() => setToggleSideMenu(!toggleSideMenu)}
            />
          </HighlightOffIconBox>
        ) : (
          <ListIconBox>
            <ListIcon onClick={() => setToggleSideMenu(!toggleSideMenu)} />
          </ListIconBox>
        )}
      </ListIconMenu>
      <Wrapper className={toggleSideMenu ? classes.sideBarToggle : ""}>
        <SideBarContent sx={{ width: "150px" }} golesMenu={golesMenu} otherGolesMenu={otherGolesMenu} isMenuSelected={isMenuSelected} setIsMenuSelected={setIsMenuSelected} />
      </Wrapper>
    </>
  );
}

export default React.memo(SideBar/* , (prevState, nextState) => {
  return prevState.isMenuSelected === nextState.isMenuSelected || prevState.setIsMenuSelected === nextState.setIsMenuSelected || 
  prevState.golesMenu === nextState.golesMenu || prevState.otherGolesMenu === nextState.golesMenu
} */)