import React from "react";

const GoogleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
    >
      <rect
        x="0.5"
        y="0.5"
        width="59"
        height="59"
        rx="7.5"
        fill="white"
        stroke="#DCE6E0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.6404 30.4094C47.6404 29.133 47.5258 27.9057 47.3131 26.7275H30.3604V33.6903H40.0476C39.6304 35.9403 38.3622 37.8466 36.4558 39.123V43.6394H42.2731C45.6767 40.5057 47.6404 35.8912 47.6404 30.4094Z"
        fill="#4285F4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.3599 47.9999C35.2199 47.9999 39.2944 46.3881 42.2726 43.639L36.4553 39.1226C34.8435 40.2026 32.7817 40.8408 30.3599 40.8408C25.6717 40.8408 21.7035 37.6745 20.2881 33.4199H14.2744V38.0836C17.2362 43.9663 23.3235 47.9999 30.3599 47.9999Z"
        fill="#34A853"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.2885 33.4201C19.9285 32.3401 19.724 31.1865 19.724 30.0001C19.724 28.8138 19.9285 27.6601 20.2885 26.5801V21.9165H14.2749C13.0558 24.3465 12.3604 27.0956 12.3604 30.0001C12.3604 32.9047 13.0558 35.6538 14.2749 38.0838L20.2885 33.4201Z"
        fill="#FBBC05"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.3599 19.1591C33.0026 19.1591 35.3753 20.0673 37.2408 21.8509L42.4035 16.6882C39.2862 13.7836 35.2117 12 30.3599 12C23.3235 12 17.2362 16.0336 14.2744 21.9164L20.2881 26.58C21.7035 22.3255 25.6717 19.1591 30.3599 19.1591Z"
        fill="#EA4335"
      />
    </svg>
  );
};
export default GoogleIcon;


export const NewGoogleIcon = () => {
  return <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="56" height="56" rx="12" fill="#F9F9F9"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M39.52 28.2617C39.52 27.4108 39.4436 26.5926 39.3018 25.8071H28V30.4489H34.4582C34.18 31.9489 33.3345 33.2199 32.0636 34.0708V37.0817H35.9418C38.2109 34.9926 39.52 31.9162 39.52 28.2617Z" fill="#4285F4"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M28 39.9944C31.24 39.9944 33.9564 38.9199 35.9418 37.0871L32.0636 34.0762C30.9891 34.7962 29.6145 35.2217 28 35.2217C24.8745 35.2217 22.2291 33.1108 21.2855 30.2744H17.2764V33.3835C19.2509 37.3053 23.3091 39.9944 28 39.9944Z" fill="#34A853"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M21.2855 30.2721C21.0455 29.5521 20.9091 28.783 20.9091 27.9921C20.9091 27.2012 21.0455 26.4321 21.2855 25.7121V22.603H17.2764C16.4636 24.223 16 26.0558 16 27.9921C16 29.9285 16.4636 31.7612 17.2764 33.3812L21.2855 30.2721Z" fill="#FBBC05"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M28 20.7727C29.7618 20.7727 31.3436 21.3782 32.5873 22.5673L36.0291 19.1255C33.9509 17.1891 31.2346 16 28 16C23.3091 16 19.2509 18.6891 17.2764 22.6109L21.2855 25.72C22.2291 22.8836 24.8746 20.7727 28 20.7727Z" fill="#EA4335"/>
  </svg>
  
}