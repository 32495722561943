import {
  DialogContent,
  Button,
  Dialog,
  DialogActions,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { useNavigate } from "react-router";
import { TrackingContext } from "../../context/userTracking";
import { setOpenCategoryModal } from "../../store/actions/trackerAction";
import { makeStyles } from "@material-ui/core";
import useStyles from "./TrackingClasses";
import NewButton from "../../components/NewButton";




const AddCategoryModal = () => {
  const { state: ITrackerState, dispatch: trackingDispatch } =
    useContext(TrackingContext);
  const { openCategoryModal } = ITrackerState
  const classes = useStyles();
  const navigate = useNavigate();
  const isCategoryPresent = localStorage.getItem("categoryCount");

  return (
    <Dialog
      open={openCategoryModal}
      onClose={() => {
        trackingDispatch(setOpenCategoryModal(false))
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.categoryDialogAdd}
    >
      <DialogContent id="alert-dialog-description">
        <Typography variant="h3" component="h3" lineHeight="120% !important">
          Add categories to personalize your tracking page
        </Typography>
        <Typography variant="subtitle1" component="div" sx={{ mt: "20px" }}>
          Group your outcomes, influences and qualitative reflection in your own
          way.
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{ mt: "60px", display: "flex", alignItems: "center", justifyContent:"center" }}
        className={classes.categoryDialogActionAdd}
      >
        <NewButton
          type="submit"
          variant="contained"
          onClick={() => {
            trackingDispatch(setOpenCategoryModal(false))
            navigate("/add-category")
          }}
          buttonText={isCategoryPresent ? (isCategoryPresent !== "0") ? "Update" : "Add Category" : "Add Category"}
        />
        <NewButton
          variant="outlined"
          onClick={() => {
            trackingDispatch(setOpenCategoryModal(false))
          }}
          buttonText={"Later"}
        />
      </DialogActions>
    </Dialog>
  );
};

export default AddCategoryModal;
