import React, { useEffect, useState } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { CalendarPicker } from "@mui/x-date-pickers/CalendarPicker";
import { Box, styled } from "@mui/system";
import CustomDate from "./CustomDate";


import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
export default function DatePicker({ outcomeId, isMenuSelected, setIsMenuSelected, customSelectDate, setCustomSelectDate }) {

  const [radioBtnSelected, setRadioBtnSelected] = useState("week")
  const [currentDate, setCurrentDate] = useState(new Date());
  const [customRange, setCustomRange] = React.useState([null, null]);
  const [rangeCount, setRangeCount] = useState({
    week: 0,
    month: 0,
    quarter: 0,
    year: 0
  })

  useEffect(() => {
    const selectedRadio = localStorage.getItem("selectedRadio")
    if(selectedRadio !== null){
      setRadioBtnSelected(selectedRadio)
    }else{
      setRadioBtnSelected("week")
    }
  }, [])
  
  const TestDatePicker = styled(CalendarPicker)`
    margin: 0px;
    width: 100%;

    & .css-1n2mv2k {
      justify-content: space-between;
    }

    & .css-mvmu1r {
      justify-content: space-between;
    }
    & .css-1v994a0 {
      color: #190d1a;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 100%;
    }

    & .css-1dozdou {
      padding-left: 12px;
      padding-right: 6px;
    }

    & .PrivatePickersSlideTransition-root {
      min-height: 190px;
    }

    & .css-1dozdou {
      margin-top: 0;
    }

    & .css-1ufzhmb-MuiButtonBase-root-MuiPickersDay-root:not(.Mui-selected) {
      border: none;
      color: #9747ff;
    }

    & .css-1fqdhv1-MuiButtonBase-root-MuiPickersDay-root {
      :hover {
        border: 1px solid #9747ff;
        border-radius: 4px;
        background: unset;
      }
    }

    & .Mui-selected {
      border-radius: 4px;
    }

    & .MuiPickersArrowSwitcher-root {
      width: 48px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      & button {
        padding: 0px;
      }
      & svg {
        border: 0.5px solid #e5d5fc;
        border-radius: 4px;
        width: 20px;
        height: 20px;
      }
    }
  `;

  const MainContainer = styled(Box)(({ theme }) => ({
    width: "100%",
    // height: "auto",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    gap: "5px",
    width: "388px",
    padding: "18px 16px 18px 16px",
    background: "#fff",
    border: "1px solid #DCE6E0",
    // overflow: "auto"
  }));

  return (
    // <LocalizationProvider dateAdapter={AdapterDayjs}>

    <MainContainer>
      <CustomDate
        customSelectDate={customSelectDate}
        setCustomSelectDate={setCustomSelectDate}
        radioBtnSelected={radioBtnSelected}
        setRadioBtnSelected={setRadioBtnSelected}
        currentDate={currentDate}
        setCurrentDate={setCurrentDate}
        rangeCount={rangeCount}
        setRangeCount={setRangeCount}
        customRange={customRange}
        setCustomRange={setCustomRange}
        outcomeId={outcomeId}
        isMenuSelected={isMenuSelected}
        setIsMenuSelected={setIsMenuSelected}
      />
    </MainContainer>
    // </LocalizationProvider>
  );
}
