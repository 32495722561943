
import React from 'react'

export default function Measurement() {
  return (
    <>
      <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.41421 14.9705L14.9412 1.44351L22.5858 9.08812L9.05882 22.6151L1.41421 14.9705Z" stroke="#B7BDB9" strokeWidth="2" />
        <line x1="11.7266" y1="4.64446" x2="15.9693" y2="8.8871" stroke="#B7BDB9" strokeWidth="2" />
        <line x1="8.19148" y1="8.17961" x2="12.4341" y2="12.4223" stroke="#B7BDB9" strokeWidth="2" />
        <line x1="4.65633" y1="11.7157" x2="8.89897" y2="15.9584" stroke="#B7BDB9" strokeWidth="2" />
      </svg>
    </>

  )
}
