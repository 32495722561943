/*eslint eqeqeq: "off"*/
import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { Button, Grid, TextField, Typography } from "@mui/material";
import OtpInput from "react-otp-input";
import { makeStyles } from "@material-ui/core";
import { pxToRem } from "../../theme/typography";
import GoogleIcon from "../../assets/images/icon/google-icon";
import FacebookIcon from "../../assets/images/icon/facebook-icon";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import palette from "../../theme/palette";
import SnackBarComp from "../../components/snackBar";
import { userService } from "../../services/auth";
import { facebookProvider, googleProvider } from "../../config/authMethods";
import FullpageLoader from "../../components/fullPageLoader";
import { ButtonLoader } from "../../components/buttonLoader";

import {
  CreateUserModal,
  USerContinueRegister,
} from "../../interfaces/outcomeModal";

const validationSchema = yup.object({
  fname: yup.string().required("First name is required"),
  lname: yup.string().required("Last name is required"),
  email: yup
    .string()
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string()
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

const RootStyle = styled("div")(({ theme }) => ({
  padding: "0 80px",
  [theme.breakpoints.up("xl")]: {
    padding: "60px",
  },
  [theme.breakpoints.down("lg")]: {
    padding: "40px",
  },
  [theme.breakpoints.down("md")]: {
    padding: "30px",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "20px",
  },
}));

const useStyles = makeStyles((theme) => ({
  registartionBox: {
    width: "456px !important",
    height: "266px !important",
  },
  registrationHeading: {
    width: "456px !important",
    height: "138px !important",
    lineHeight: "115% !important",
    letterSpacing: "-0.01em !important",
    color: "#190D1A !important",
    flex: "none",
    textAlign: "center",
    order: 0,
    fontWeight: 400,
    fontSize: '40px !important',
    [theme.breakpoints.down("xl")]: {
      marginBottom: "50px!important",
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: "40px!important",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "30px!important",
    },
  },
  registrationHeadingSmallText: {
    width: "456px !important",
    height: "17px !important",
    lineHeight: "100% !important",
    letterSpacing: "-0.01em !important",
    flex: "none",
    textAlign: "center",
    order: 0,
    color: '#B4B0B4 !important',
    fontWeight: 400,
    fontSize: '16px !important',
    [theme.breakpoints.down("xl")]: {
      marginBottom: "50px!important",
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: "40px!important",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "30px!important",
    },
  },
  registrationHeadingSmallTextMobile: {
    width: "100% !important",
    height: "17px !important",
    lineHeight: "100% !important",
    letterSpacing: "-0.01em !important",
    flex: "none",
    textAlign: "center",
    order: 0,
    color: '#B4B0B4 !important',
    fontWeight: 400,
    fontSize: '16px !important',
    [theme.breakpoints.down("xl")]: {
      marginBottom: "50px!important",
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: "40px!important",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "30px!important",
    },
  },
  formInputFields: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  classInputBox: {
    display: 'inline-block',
    width: '50px',
    height: '50px',
    textAlign: 'center',
    margin: '0 3px',
    fontSize: '22px',
    borderRadius: '10px',
    border: '1px solid #bbb',
    '&:focus-visible': {
      outline: 'none !important',
      border: '2px solid #489859',
      // boxShadow: '0 0 10px #719ECE',
      // backgroundColor: theme.palette.grey[200],
    },
    '&:focus': {
      outline: 'none !important',
      border: '2px solid #489859',
      // boxShadow: '0 0 10px #719ECE',
      // backgroundColor: theme.palette.grey[200],
    }
  },
  classErrorInputBox: {
    display: 'inline-block',
    width: '50px',
    color: '#EF8B6E',
    height: '50px',
    textAlign: 'center',
    margin: '0 3px',
    fontSize: '22px',
    borderRadius: '10px',
    border: '1px solid #EF8B6E',
    '&:focus-visible': {
      outline: 'none !important',
      border: '2px solid #EF8B6E',
      // boxShadow: '0 0 10px #719ECE',
      // backgroundColor: theme.palette.grey[200],
    },
    '&:focus': {
      outline: 'none !important',
      border: '2px solid #EF8B6E',
      // boxShadow: '0 0 10px #719ECE',
      // backgroundColor: theme.palette.grey[200],
    }
  },
  errorClassName: {
    // fontWeight: '400',
    fontSize: '14px',
    lineHeight: '145%',
    alignItems: 'center',
    letterSpacing: '-0.01em',
    color: '#EF8B6E',
    flex: 'none',
    order: 1,
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    margin: '5px 0',
  },
  socialLoginButton: {
    marginTop: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  alreadyAccountText: {
    marginTop: "60px",
    color: "#9BA19D",
    fontSize: pxToRem(16),
    lineHeight: pxToRem(16),
    fontWeight: 400,
    textAlign: "center",
    [theme.breakpoints.down("xl")]: {
      marginBottom: "0!important",
      marginTop: "24px",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "20px",
    },
  },
  alreadyAccountTextWaitlist: {
    marginTop: "60px",
    color: "#9BA19D",
    fontSize: pxToRem(16),
    lineHeight: pxToRem(16),
    fontWeight: 400,
    textAlign: "center",
    [theme.breakpoints.down("xl")]: {
      marginTop: "24px",
    },
    [theme.breakpoints.down("md")]: {
      marginTop: "20px",
    },
  },
  alreadyAccountTextSpan: {
    color: "#11734A",
    cursor: "pointer",
  },
}));


const RightPartCodeVerify = ({ setCodeVerified, isMobile = false }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [showSnakbar, setShowSnakbar] = useState(false);
  const [showLoaderPage, setShowLoaderPage] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [snakbarMsg, setSnakbarMsg] = useState("");
  const [msgType, setMsgType] = useState("");
  const [disableButton, setDisableButton] = useState(true);
  const [showError, setShowError] = useState(false);
  // REGISTER API
  const codeVerify = (values: string) => {
    const userData = {
      passcode: values,
    };
    userService
      .passcodeVerify(userData)
      .then((data: CreateUserModal) => {
        setCodeVerified(true)

        // localStorage.setItem("userValues", JSON.stringify(data.data));
        // navigate("/user-identification");
      })
      .catch((error) => {
        setShowError(true)
        setCodeVerified(false)
        setShowLoader(false);
        setShowLoaderPage(false);
        setMsgType("error");
        setShowSnakbar(true);
        setSnakbarMsg(error?.response?.data?.description);
      });
  };
  const handleSubmit = () => {
    const allValues = [];
    if (document.querySelectorAll('input') && document.querySelectorAll('input').length) {
      for (let index = 0; index < document.querySelectorAll('input').length; index++) {
        const element = document.querySelectorAll('input')[index];
        element.value && allValues.push(element.value)
      }
    }
    const value = allValues.join("")
    codeVerify(value)
  }

  const formik = useFormik({
    initialValues: {
      fname: "",
      lname: "",
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {


      // setShowLoader(true);
      // firebaseLogin(values);
    },
  });
  let digitValidate = (ele) => {
    // ele.target.value = ele.target.value.replace(/[^0-9]/g, '');
    const allValues = [];
    if (document.querySelectorAll('input') && document.querySelectorAll('input').length) {
      for (let index = 0; index < document.querySelectorAll('input').length; index++) {
        const element = document.querySelectorAll('input')[index];
        element.value && allValues.push(element.value)
      }
    }
    if (allValues.length >= 6) {
      setDisableButton(false)
    } else {
      setDisableButton(true)
    }
    setShowError(false)
  }
  let tabChange = (val) => {
    let ele = document.querySelectorAll('input');
    if (ele[val - 1].value != '') {
      ele[val]?.focus()
    } else if (ele[val - 1].value == '') {
      ele[val - 2]?.focus()
    }
  }

  const handleCloseSnakbar = () => {
    setShowSnakbar(false);
  };

  return (
    <RootStyle className="login-right-part">
      {showLoaderPage && <FullpageLoader />}
      <SnackBarComp
        showSnakbar={showSnakbar}
        handleCloseSnakbar={handleCloseSnakbar}
        snakbarMsg={snakbarMsg}
        type={msgType ? msgType : "info"}
      />

      {!isMobile && (
        <Typography
          variant="h1"
          component="h1"
          className={classes.registrationHeading}
        >
          To get access to the app, enter your code or join waitlist
        </Typography>
      )}

      <Typography
        variant="h6"
        component="h6"
        className={isMobile ? classes.registrationHeadingSmallTextMobile : classes.registrationHeadingSmallText}
      >
        Enter membership ID number attached sent to your email
      </Typography>
      <Grid>
        <form onSubmit={e => {
          e.preventDefault()
          handleSubmit()
        }}>
          <div className={classes.formInputFields}>
            {[1, 2, 3, 4, 5, 6].map(it => (
              <input
                className={showError ? classes.classErrorInputBox : classes.classInputBox}
                type="text"
                onChange={digitValidate}
                onKeyUp={() => tabChange(it)}
                maxLength={1}
              />
            ))}
          </div>
          {showError && <span className={classes.errorClassName}>
            Oops, this is not the code associated with your email
          </span>}
          {!showLoader ? (
            <Button
              type="submit"
              variant="contained"
              className="button-after"
              fullWidth
              sx={{ mt: "24px", mb: "57px" }}
              disabled={disableButton}
            >
              Check Code
            </Button>
          ) : (
            <Button
              variant="contained"
              className="button-after"
              fullWidth
              sx={{ mt: "24px", mb: "57px" }}
            >
              <ButtonLoader />
            </Button>
          )}
        </form>
        <p className={classes.alreadyAccountTextWaitlist}>
          Don’t have a code?{" "}
          <span
            className={classes.alreadyAccountTextSpan}
            onClick={() => navigate("/join-waitlist")}
          >
            Join waitlist
          </span>
        </p>
        <p className="registation_text">
          <span>or continue with</span>
        </p>
        <p className={classes.alreadyAccountText}>
          Already have an account?{" "}
          <span
            className={classes.alreadyAccountTextSpan}
            onClick={() => navigate("/login")}
          >
            Sign in
          </span>
        </p>
      </Grid>



    </RootStyle>
  );
};
export default RightPartCodeVerify;
