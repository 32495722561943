import moment from "moment";
import { Box } from "@mui/system";
import React, { useState, useContext, Ref } from "react";
import { Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
import palette from "../../theme/palette";
import FullpageLoader from "../../components/fullPageLoader";
import { UserTypeContext } from "../../context/userStatus";
import IconPersonalOutcome from "./IconPersonalOutcome";
import IconPersonalReflective from "./IconPersonalReflective";
import IconPersonalInfluence from "./IconPersonalInfluence";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    "& .MuiBox-root:last-child": {
      marginBottom: "8px!important",
    },
  },

  headerExpand: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "100%",
    "& .MuiBox-root:last-child": {
      marginBottom: "8px!important",
    },
  },

  headerBlocks: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "8px 8px",
    height: "56px",
    width: "100%",
    minWidth: "48px",
    gap: "8px",
    "& p": {
      margin: "0",
    },
    "&:before": {
      content: '""',
      display: "flex",
      position: "absolute",

    }
  },

  headerBlocksExpand: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "center",
    padding: "8px 0",
    height: "56px",
    width: "100%",
    // marginBottom: "11px",
    "& p": {
      height: "15px",
      width: "30px",
      textAlign: "center",
      margin: "0",
      opacity: "1",
    },
    "& span": {
      padding: "0px 5px",
    },
  },

  weekday: {
    color: "#D9D9D9",
    fontSize: "10px!important",
    lineHeight: "100%!important",
    fontWeight: 400,
    opacity: "0.4",
  },
  blankIcon: {
    height: '0px',
    display: 'flex',
    padding: '4px 0px',
    position: 'relative',
    alignItems: 'center',
    marginBottom: '0px',
  },
  background: {
    backgroundColor: "#11734A",
    borderRadius: "8px",
    color: palette.common.white,
    "& p": {
      color: palette.common.white,
      fontWeight: 500,
      opacity: "1",
    },
    "& span": {
      color: palette.common.white,
      fontWeight: 500,
    },
  },

  commonBackground: {
    backgroundColor: "white"
  }
}));

interface MonthModal {
  month: moment.Moment[]; 
  day: moment.Moment;
  today: moment.Moment;
  callservice: any;
  count: any;
  callAndUpdateObject: any
  currentDateRef: any
}

const PersonalMonthItem = (props: MonthModal) => {
  const classes = useStyles();
  const { state: outcomeState } = useContext(UserTypeContext);
  const [outcomeId, setOutcomeId] = useState("");
  const [influenceId, setInfluenceId] = useState("");
  const [refId, setRefId] = useState("");
  const [showLoaderPage, setShowLoaderPage] = useState(false);

  const headerClass = `${
    props.today.isSame(props.day, "day")
      ? clsx(
          outcomeState?.isExpand
            ? classes.headerBlocksExpand
            : classes.headerBlocks,
          classes.background
        )
      : outcomeState?.isExpand
      ? clsx(classes.headerBlocksExpand, classes.commonBackground)
      : clsx(classes.headerBlocks, classes.commonBackground)
  }`;
  return (
    <>
      {showLoaderPage && <FullpageLoader />}
      <Box
        className={outcomeState?.isExpand ? classes.headerExpand : classes.header}
      >
        <div className={headerClass} ref={props.today.isSame(props.day, "day") ? props.currentDateRef : null} style={{position: "sticky", top: "70px", alignSelf: "flex-start", zIndex: "100", padding: "0px 16px" }}>
          <Typography
            variant="caption"
            component="p"
            className={classes.weekday}
          >
            {moment(props.day).format("ddd")}
          </Typography>
          <Typography
            variant="caption"
            fontSize={"0.875rem!important"}
            color={palette.common.black}
            sx={{ fontWeight: 400 }}
          >
            {props.day.format("D")}{" "}
          </Typography>
        </div>
        {outcomeState?.journeyCategoryList?.map((category) => {
          return (
            <Box marginBottom="46.6px" key={category._id} width={"100%"}>
              {outcomeState?.journeyCategoryExpandList?.includes(category.categoryId) ? category?.name === "Other" ? (
                <>
                  {/* { */}
                    <IconPersonalOutcome
                      setOutcomeId={setOutcomeId}
                      outcomeId={outcomeId}
                      journeyData={category?.outcomes}
                      headerDate={moment(props.day).format("MM-DD-yyyy")}
                      betweenDate={moment(props.day).format("yyyy-MM-DD")}
                      callservice={props?.callservice}
                      setShowLoaderPage={setShowLoaderPage}
                      showLoaderPage={showLoaderPage}
                      categoryId={category.categoryId}
                      key={category.categoryId}
                      month={props.month}
                    />
                  {/* // ) : category?.influenceId ? ( */}
                    <IconPersonalInfluence
                      setInfluenceId={setInfluenceId}
                      influenceId={influenceId}
                      journeyData={category?.influences}
                      headerDate={moment(props.day).format("MM-DD-yyyy")}
                      betweenDate={moment(props.day).format("yyyy-MM-DD")}
                      callservice={props?.callservice}
                      categoryId={category.categoryId}
                      key={category.categoryId}
                      month={props.month}

                    />
                  {/* // ) : ( */}
                    <IconPersonalReflective
                      setRefId={setRefId}
                      refId={refId}
                      journeyData={category?.reflectives}
                      headerDate={moment(props.day).format("MM-DD-yyyy")}
                      betweenDate={moment(props.day).format("yyyy-MM-DD")}
                      callservice={props?.callservice}
                      dayName={props.day}
                      key={category.categoryId}
                      month={props.month}
                    />
                  {/* // )} */}
                </>
              ) : (
                outcomeState?.journeyCategoryExpandList?.includes(category.categoryId) ? category?.alls && category?.alls.length > 0 ? category?.alls?.map((all) =>
                  < >
                    {all?.outcomeId ? (
                    <IconPersonalOutcome
                      setOutcomeId={setOutcomeId}
                      outcomeId={outcomeId}
                      journeyData={[all]}
                      headerDate={moment(props.day).format("MM-DD-yyyy")}
                      betweenDate={moment(props.day).format("yyyy-MM-DD")}
                      callservice={props?.callservice}
                      setShowLoaderPage={setShowLoaderPage}
                      showLoaderPage={showLoaderPage}
                      categoryId={category.categoryId}
                      key={category.categoryId}
                      month={props.month}
                    />
                  ) : all?.influenceId ? (
                    <IconPersonalInfluence
                      setInfluenceId={setInfluenceId}
                      influenceId={influenceId}
                      journeyData={[all]}
                      headerDate={moment(props.day).format("MM-DD-yyyy")}
                      betweenDate={moment(props.day).format("yyyy-MM-DD")}
                      callservice={props?.callservice}
                      categoryId={category.categoryId}
                      key={category.categoryId}
                      month={props.month}
                    />
                  ) : (
                    <IconPersonalReflective
                      setRefId={setRefId}
                      refId={refId}
                      journeyData={[all]}
                      headerDate={moment(props.day).format("MM-DD-yyyy")}
                      betweenDate={moment(props.day).format("yyyy-MM-DD")}
                      callservice={props?.callservice}
                      dayName={props.day}
                      key={category.categoryId}
                      month={props.month}
                    />
                  )}
                  </>
                ) : <div className={classes.blankIcon} style={{
                  marginBottom: '2px'
                }}></div> : <div className={classes.blankIcon}></div>
              ) : <div className={classes.blankIcon} style={{
                marginBottom: '1px'
              }}></div> }
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export default React.memo(PersonalMonthItem);
