import { Box, Dialog, DialogContent, DialogTitle, Drawer, Grid, SwipeableDrawer, Typography } from "@mui/material";
import React, { useState, useContext, useEffect } from "react";
import TrackBody from "./TrackBody";
import TrackTitle, { TrackArchiveTitle } from "./TrackTitle";
import { makeStyles } from "@material-ui/core";
import SideBar from "../../components/SideBar";
import StartTrackingModal from "./startTrackingModal";
import AddTrackingPopUp from "../testAddGolas/AddTrackingPopUp/AddTrackingPopUp";
import { UserTypeContext } from "../../context/userStatus";
import { userService } from "../../services/auth";
import FullpageLoader from "../../components/fullPageLoader";
import PersonalTrackBody from "./PersonalTrackBody";
import AddCategoryModal from "./AddCategoryModal";
import moment from "moment";
import { trackingService } from "../../services/tracking";
import { addCategoryJourneyAction, addInfluenceAction, addNotesJourneyAction, addOutcomeAction, addRefelectiveAction, isExpandMode } from "../../store/actions/userTypeAction";
import ArchiveItemTable from "./ArchiveItemTable";
import ModalCloseIconBlack from "../../assets/images/icon/modalCloseIconBlack";
import LeftSideOutCome from "../outcome/leftOutcome";
import RightSideOutcomeTracking from "./rightSideOutcomeTracking";
import palette from "../../theme/palette";
import { OutcomeStatus } from "../../utils/staticDataJson";
import LeftSideInfluence from "../influence/leftSideInfluence";
import RightSideInfluenceTracking from "./rightSideInfluenceTracking";
import LeftSideReflectiveQue from "../reflective-questions/leftSideRefQue";
import RightSideRefQuesTracking from "./rightSideRefQuesTracking";
import TrackerScreenMobile from "./TrackerScreenMobile";
import useWidth from "../../utils/useWidth";
import SnackBarComp from "../../components/snackBar";
import ShareSocialReportModal from "./Modals/ShareSocialReportModal";
import { contactsService } from "../../services/contactsService";
import { ContactsContext } from "../../context/userIContacts";
import { setAllContacts, setGroupContacts, setShareModalOpen } from "../../store/actions/contactsActions";
import TrackHeader from "./TrackHeader";
import GetIdeasIcon from "../../assets/images/icon/getIdeas";
import LeftSideOutComeNew from "../outcome/leftOutcomeNew";
import RightSideOutcomeTrackingNew from "./rightSideOutcomeTrackingNew";
import LeftSideInfluenceNew from "../influence/leftSideInfluenceNew";
import MultiDrawerAddEditComponent, { MultiDrawerAddEditComponentMobile } from "../../components/MultiDrawerComponent";
import { influenceService } from "../../services/influence";
import { InfluenceGetResponse } from "../../interfaces/outcomeModal";
import LeftSideReflectiveQueNew from "../reflective-questions/leftSideRefQueNew";
import { useLocation } from "react-router-dom";
import LimitReachedPopup from "./limitReachedModal";
import TrackingViewNew from "../trackerNew";
import { outcomeService } from "../../services/outcome";
import { reflectiveQueService } from "../../services/reflective-questions";
import BackIcon from "../../assets/images/icon/BackIcon";
import { pxToRem } from "../../theme/typography";

const useStyles = makeStyles((theme) => ({
  trackBodyGrid: {
    display: "flex",
    backgroundColor: "white",
    zIndex: 1,
    flexShrink: 0,
    height: '100vh',
    overflow: 'auto'
  },
  modalCloseIcon: {
    position: "absolute",
    right: 0,
    top: 0,
    cursor: "pointer",
    zIndex: 1,
  },
  modalCloseIconMobile: {
    // position: "fixed",
    right: 0,
    left: 0,
    top: 0,
    cursor: "pointer",
    zIndex: 1,
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column-reverse"
  },
  drawerTitle: {
    display: 'flex',
    padding: '20px 24px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    alignSelf: 'stretch',
    borderBottom: '1px solid #F3F3F3',
    background: "#FFF",
  },
  drawerTitleMobile: {
    display: 'flex',
    padding: '12px 0px',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '10px',
    alignSelf: 'stretch',
    background: "#FFF",
  },
  drawerBody: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: "column"

  },
  headerTitle: {
    display: 'flex',
    padding: '24px 32px',
    alignItems: 'center',
    alignSelf: 'stretch',
    borderBottom: '1px solid #F3F3F3',
    background: '#FFF',
    justifyContent: "space-between"

  }
}));

const TrackingView = () => {
  const classes = useStyles();
  const { state: IUserTypeState, dispatch: userTypeDispatch } =
    useContext(UserTypeContext);
  // const [isExpandMode, setIsExpandMode] = useState(false);
  const [isExpandable, setIsExpandable] = useState(localStorage.getItem("isExpandMode") === "true");
  // const [ispersonalMode, setIsPersonalMode] = useState(false);

  const { state: IContactTypeState, dispatch: contactsDispatch } =
    useContext(ContactsContext);

  const [openStartTracking, setOpenStartTracking] = useState(
    IUserTypeState?.isNewUser
  );
  const [createDate, setCreateDate] = useState<any>();
  const [showLoader, setShowLoader] = useState(true);
  let userValues = JSON.parse(localStorage.getItem("userValues"));
  const [open, setOpen] = useState(false);
  const [isArchiveMode, setIsArchiveMode] = useState(false);
  const [mobileStartDay, setMobileStartDay] = useState();
  const [mobileEndDay, setMobileEndDay] = useState();
  let location = useLocation();
  const [showOutcome, setShowOutcome] = useState<boolean>(false);
  const [showInfluence, setShowInfluence] = useState<boolean>(false);
  const [showQues, setShowQues] = useState<boolean>(false);
  const [openStartModal, setStartModal] = useState(false);
  const [openStartModalInfluence, setStartModalInfluence] = useState(false);
  const [openStartModalQues, setStartModalQues] = useState(false);
  const [addOutcomeTracking, setAddOutcomeTracking] = useState(false);
  const [addInfluenceTracking, setAddInfluenceTracking] = useState(false);
  const [transformToInfluenceTracking, setTransformToInfluenceTracking] = useState(false)
  const [transformToOutcomeTracking, setTransformToOutcomeTracking] = useState(false)
  const [transformInInfluenceTracking, setTransformInInfluenceTracking] = useState(false)
  const [addQuesTracking, setAddQuesTracking] = useState(false);

  const [showSnakbar, setShowSnakbar] = useState(false);
  const [snakbarMsg, setSnakbarMsg] = useState("");
  const [msgType, setMsgType] = useState("");
  const [limitPopupVisible, setLimitReachPopupVisible] = useState<boolean>(false);
  const [limitPopuptype, setLimitReachPopuptype] = useState<'outcome' | 'influence' | 'question'>();
  const handleShareModalOpen = (val) => {
    contactsDispatch(setShareModalOpen(val))
  }
  useEffect(() => {
    setIsArchiveMode((location.state as any)?.direct || false)
  }, [(location.state as any)?.direct])
  const [outComeValue, setOutcomeValue] = useState<any>("");
  const [textInput, setTextInput] = useState(OutcomeStatus);
  const [influenceData, setInfluenceData] = useState<any>();
  const [questionData, setQuestionData] = useState<any>();
  const [outcomeIdeas, setOutcomeIdeas] = useState();
  const [influenceIdeas, setInfluenceIdeas] = useState();
  const [reflectiveIdeas, setReflectiveIdeas] = useState();
  const [weekday, setWeekDay] = useState(new Map());
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [needIdeasOpen, setNeedIdeasOpen] = useState(false)
  const [openRightSideSuggestion, setOpenRightSideSuggestion] = useState(false)
  const width = useWidth()
  const [categoryId, setCategoryId] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getUserDetails();
    getAllContacts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    // IUserTypeState?.isPersonal && getPersonalData()
    setShowLoader(true);
  }, [IUserTypeState?.isPersonal])
  const getAllContacts = () => {
    contactsService
      .getContactsList(IUserTypeState.token)
      .then((data: any) => {
        const { allContact = [], groupData = [] } = data;
        contactsDispatch(setAllContacts(allContact))
        contactsDispatch(setGroupContacts(groupData.map(it => {
          if (it.contacts && it.contacts.length) {
            it.contacts = it.contacts.map(ite => ({
              ...ite,
              parent: it._id
            }))
          }

          return it
        })))
      })
      .catch((error) => {
        console.log("User profile Error");
      });
  }
  const getUserDetails = () => {
    const userId = {
      uid: IUserTypeState?.userId ? IUserTypeState?.userId : userValues?.uid,
    };
    userService
      .getUserById(userId)
      .then((data: any) => {
        setCreateDate(data?.data?.createdAt);
        localStorage.setItem("userValues", JSON.stringify(data?.data));
        setShowLoader(false);
      })
      .catch((error) => {
        console.log("User profile Error");
      });
  };

  const getPersonalData = async () => {
    setShowLoader(true);
    let data
    const startDay = moment().clone().subtract(10, "day");
    const endDay = moment().clone().add(15, "day");

    const bodyData = {
      from: moment(startDay).format("MM-DD-YYYY"),
      to: moment(endDay).format("MM-DD-YYYY"),
    };

    data = await trackingService.getPersonalJourneyList(
      IUserTypeState.token,
      bodyData
    );
    userTypeDispatch(addCategoryJourneyAction(data?.data));
    if (
      !data?.data?.length ||
      (data?.data?.length === 1 &&
        data?.data[0]?.name?.toUpperCase() === "OTHER")
    ) {
      handleClickOpen();
    }
  }
  const getInfluenceList = async () => {
    if (IUserTypeState.isPersonal) {
      const bodyData = {
        from: moment(mobileStartDay).format("MM-DD-YYYY"),
        to: moment(mobileEndDay).format("MM-DD-YYYY"),
      };
      let data = await trackingService.getPersonalJourneyList(
        IUserTypeState.token,
        bodyData
      );
      userTypeDispatch(addCategoryJourneyAction(data?.data));
      userTypeDispatch(addNotesJourneyAction(data?.data));
    }
    influenceService
      .getInfluenceList(IUserTypeState.token)
      .then((data: InfluenceGetResponse) => {
        userTypeDispatch(addInfluenceAction(data?.data));
      })
      .catch((error) => {
        console.log('influence Error');
      });
  };
  const handleCloseStartModal = () => {
    setOutcomeValue("");
    setTextInput(OutcomeStatus);
    setAddOutcomeTracking(false);
  };

  const handleCloseStartModalInfluence = () => {
    setInfluenceData("");
    setTextInput(OutcomeStatus);
    setAddInfluenceTracking(false);
  };

  const handleCloseStartModalQues = () => {
    setQuestionData("");
    setWeekDay(new Map());
    setAddQuesTracking(false);
  };

  const handleCloseSnakbar = () => {
    setShowSnakbar(false);
  };
  const isMobile = width <= 768
  return (
    <>
      {showLoader && <FullpageLoader />}
      <SnackBarComp
        showSnakbar={showSnakbar}
        handleCloseSnakbar={handleCloseSnakbar}
        snakbarMsg={snakbarMsg}
        type={msgType ? msgType : "success"}
      />
      {width > 768 ? (
        <Grid
          container
          columns={16}
          // height={"100vh"}
          sx={{ /* maxHeight: '100vh', */ background: "#F7F9FA", overflow: "hidden", }}
          className="tracking_container"
        // style={{
        //   height: '100vh !important'
        // }}
        >
          {/* FIRST TIME TRACKING MODAL */}

          {openStartTracking ? (
            <StartTrackingModal setOpenStartTracking={setOpenStartTracking} />
          ) : (
            <AddTrackingPopUp setOpenStartTracking={setOpenStartTracking} createDate={createDate} />
          )}
          {/* START LEFT SIDEBAR */}
          <Grid
            item
            xs={(/* IUserTypeState?.isExpand ||  */isExpandable) ? 0.72 : 2.04}
            sx={{ borderRight: "1px solid #DCE6E0", height: '100vh' }}
          >
            <SideBar isExpandMode={isExpandable} setIsExpandMode={val => {
              setIsExpandable(val)
              // userTypeDispatch(isExpandMode(val))
            }} />
          </Grid>
          {/* END SIDEBAR */}

          {/* START CONTENT SECTION */}
          <Grid
            item
            xs={(isExpandable) ? 15.27 : 13.96}
            sx={{ flexDirection: "column", background: "transparent", maxHeight: '100vh', }}
            className={classes.trackBodyGrid}
          >
            <TrackHeader setIsArchiveMode={setIsArchiveMode} />
            <Box sx={{
              borderRadius: '20px',
              background: '#FFF',
              margin: '32px 20px 0 24px',
              height: "calc(100vh - 187px)",
              position: "relative"
            }}>
              {isArchiveMode ? (
                <TrackArchiveTitle
                  setIsArchiveMode={setIsArchiveMode}
                  isArchiveMode={isArchiveMode}
                />
              ) : (
                <TrackTitle
                  setIsArchiveMode={setIsArchiveMode}
                  isArchiveMode={isArchiveMode}
                  createDate={createDate}
                />
              )}
              {/* CALENDER VIEW */}

              {!isArchiveMode ? (
                !IUserTypeState?.isPersonal && createDate ? (
                  // default view
                  <div style={{
                    height: 'calc(100% - 20px)',
                    overflow: 'auto',
                  }}>
                    <TrackBody
                      createDate={createDate}
                      setCreateDate={setCreateDate}
                      handleClickOpen={handleClickOpen}
                      showOutcome={showOutcome}
                      setShowOutcome={setShowOutcome}
                      showInfluence={showInfluence}
                      setShowInfluence={setShowInfluence}
                      showQues={showQues}
                      setShowQues={setShowQues}
                      openStartModal={openStartModal}
                      setStartModal={setStartModal}
                      openStartModalInfluence={openStartModalInfluence}
                      setStartModalInfluence={setStartModalInfluence}
                      openStartModalQues={openStartModalQues}
                      setStartModalQues={setStartModalQues}
                      addOutcomeTracking={addOutcomeTracking}
                      setAddOutcomeTracking={setAddOutcomeTracking}
                      addInfluenceTracking={addInfluenceTracking}
                      setAddInfluenceTracking={setAddInfluenceTracking}
                      addQuesTracking={addQuesTracking}
                      setAddQuesTracking={setAddQuesTracking}
                      setOutcomeIdeas={setOutcomeIdeas}
                      setInfluenceIdeas={setInfluenceIdeas}
                      setReflectiveIdeas={setReflectiveIdeas}
                      setCategoryId={setCategoryId}
                      setTransformToInfluenceTracking={setTransformToInfluenceTracking}
                      transformToInfluenceTracking={transformToInfluenceTracking}
                      setTransformToOutcomeTracking={setTransformToOutcomeTracking}
                      transformToOutcomeTracking={transformToOutcomeTracking}
                      transformInInfluenceTracking={transformInInfluenceTracking}
                      setTransformInInfluenceTracking={setTransformInInfluenceTracking}
                      showLoader={showLoader}
                      setShowLoader={setShowLoader}
                      startDate={startDate}
                      setStartDate={setStartDate}
                      endDate={endDate}
                      setEndDate={setEndDate}
                      handleShareModalOpen={handleShareModalOpen}
                    />
                  </div>
                ) : (
                  // persona; view
                  <div style={{
                    height: 'calc(100% - 20px)',
                    overflow: 'auto',
                  }}>
                    <PersonalTrackBody
                      createDate={createDate}
                      setCreateDate={setCreateDate}
                      handleClickOpen={handleClickOpen}
                      pshowOutcome={showOutcome}
                      psetShowOutcome={setShowOutcome}
                      pshowInfluence={showInfluence}
                      psetShowInfluence={setShowInfluence}
                      pshowQues={showQues}
                      psetShowQues={setShowQues}
                      popenStartModal={openStartModal}
                      psetStartModal={setStartModal}
                      popenStartModalInfluence={openStartModalInfluence}
                      psetStartModalInfluence={setStartModalInfluence}
                      popenStartModalQues={openStartModalQues}
                      psetStartModalQues={setStartModalQues}
                      paddOutcomeTracking={addOutcomeTracking}
                      psetAddOutcomeTracking={setAddOutcomeTracking}
                      paddInfluenceTracking={addInfluenceTracking}
                      psetAddInfluenceTracking={setAddInfluenceTracking}
                      paddQuesTracking={addQuesTracking}
                      psetAddQuesTracking={setAddQuesTracking}
                      setCategoryId={setCategoryId}
                      setTransformToInfluenceTracking={setTransformToInfluenceTracking}
                      transformToInfluenceTracking={transformToInfluenceTracking}
                      setTransformToOutcomeTracking={setTransformToOutcomeTracking}
                      transformToOutcomeTracking={transformToOutcomeTracking}
                      transformInInfluenceTracking={transformInInfluenceTracking}
                      setTransformInInfluenceTracking={setTransformInInfluenceTracking}
                      showLoader={showLoader}
                      setShowLoader={setShowLoader}
                      startDate={startDate}
                      setStartDate={setStartDate}
                      endDate={endDate}
                      setEndDate={setEndDate}
                      handleShareModalOpen={handleShareModalOpen}
                    />
                  </div>
                )
              ) : (
                <ArchiveItemTable />
              )}
            </Box>
            {/* PAGE HEADING */}

            {/* <ArchiveItemTable /> */}
            <AddCategoryModal open={open} handleClose={handleClose} />
          </Grid>
          {/* END CONTENT SECTION */}
        </Grid>
        // <TrackingViewNew />
      ) : (
        <TrackerScreenMobile
          createDate={createDate}
          setCreateDate={setCreateDate}
          addOutcomeTracking={addOutcomeTracking}
          setAddOutcomeTracking={setAddOutcomeTracking}
          setStartModal={setStartModal}
          setStartModalInfluence={setStartModalInfluence}
          setStartModalQues={setStartModalQues}
          setAddInfluenceTracking={setAddInfluenceTracking}
          setAddQuesTracking={setAddQuesTracking}
          setOutcomeIdeas={setOutcomeIdeas}
          setInfluenceIdeas={setInfluenceIdeas}
          setReflectiveIdeas={setReflectiveIdeas}
          setShowLoader={setShowLoader}
          setOutcomeValue={setOutcomeValue}
          setInfluenceData={setInfluenceData}
          setQuestionData={setQuestionData}
          setMobileEndDay={setMobileEndDay}
          setMobileStartDay={setMobileStartDay}
          setLimitReachPopupVisible={setLimitReachPopupVisible}
          setLimitReachPopuptype={setLimitReachPopuptype}
        />
      )}
      {/* ADD OUTCOME MODAL */}
      {addOutcomeTracking && /* width <= 768 */false && (
        <Dialog
          fullScreen
          disableEscapeKeyDown
          onClose={handleCloseStartModal}
          open={openStartModal}
          className="dialogWrapper dialogWrapper-outcome"
        >
          <DialogTitle className={classes.modalCloseIcon}>
            <span
              onClick={handleCloseStartModal}
            >
              <ModalCloseIconBlack />
            </span>
          </DialogTitle>
          <DialogContent>
            <Grid
              container
              columns={16}
              sx={{ backgroundColor: palette.secondary.light }}
            >
              <Grid
                sx={{ backgroundColor: palette.common.white }}
                item
                xs={9.63}
                className="leftside-menu login-screen outcome-screen outcome-left-layout"
              >
                {/* OUTCOME LEFT SECTION */}
                <LeftSideOutCome
                  outComeValue={outComeValue}
                  setOutcomeValue={setOutcomeValue}
                  outcomeIdeas={outcomeIdeas}
                  setShowOutcome={setShowOutcome}
                  showOutcome={true}
                  setAddOutcomeTracking={setAddOutcomeTracking}
                  setTextInput={setTextInput}
                  setShowSnakbar={setShowSnakbar}
                  setMsgType={setMsgType}
                  setSnakbarMsg={setSnakbarMsg}
                  textInput={textInput}
                  categoryId={categoryId}
                  setTransformToInfluenceTracking={setTransformToInfluenceTracking}
                  width={width}
                  startDate={startDate}
                  endDate={endDate}
                />
              </Grid>
              {
                width > 768 &&
                <Grid
                  item
                  xs={6.37}
                  className="rightside-menu outcome-menu outcome-right-layout"
                  sx={{ backgroundColor: palette.common.bgColor }}
                >
                  {/* OUTCOME SUGGESTION LIST */}
                  <RightSideOutcomeTracking
                    outcomeIdeas={outcomeIdeas}
                    setOutcomeValue={setOutcomeValue}
                  />
                </Grid>
              }
            </Grid>
          </DialogContent>
        </Dialog>
      )}
      {addOutcomeTracking && width <= 768 && (
        <AddTrackingViewmobile
          classes={classes}
          open={openStartModal}
          handleClose={handleCloseStartModal}
          restComponent={
            <LeftSideOutComeNew
              outComeValue={outComeValue}
              setOutcomeValue={setOutcomeValue}
              outcomeIdeas={outcomeIdeas}
              setShowOutcome={setShowOutcome}
              showOutcome={true}
              setAddOutcomeTracking={setAddOutcomeTracking}
              setTextInput={setTextInput}
              setShowSnakbar={setShowSnakbar}
              setMsgType={setMsgType}
              setSnakbarMsg={setSnakbarMsg}
              textInput={textInput}
              categoryId={categoryId}
              setTransformToInfluenceTracking={setTransformToInfluenceTracking}
              width={width}
              startDate={startDate}
              endDate={endDate}
              handleCloseStartModal={handleCloseStartModal}
              setNeedIdeasOpen={setNeedIdeasOpen}
              setLimitReachPopupVisible={setLimitReachPopupVisible}
              setLimitReachPopuptype={setLimitReachPopuptype}
              isMobile={isMobile}
              getOutcomeList={async () => {
                if (IUserTypeState.isPersonal) {
                  const bodyData = {
                    from: moment(mobileStartDay).format("MM-DD-YYYY"),
                    to: moment(mobileEndDay).format("MM-DD-YYYY"),
                  };
                  let data = await trackingService.getPersonalJourneyList(
                    IUserTypeState.token,
                    bodyData
                  );
                  userTypeDispatch(addCategoryJourneyAction(data?.data));
                  userTypeDispatch(addNotesJourneyAction(data?.data));
                }
                outcomeService
                  .getOutcomeList(IUserTypeState.token)
                  .then((data: any) => {
                    userTypeDispatch(addOutcomeAction(data?.data));
                  })
                  .catch((error) => {
                    console.log("Outcome Error");
                  });
              }}
            />
          }
          title={<>{outComeValue?._id ? "Edit outcome" : "Add a new outcome"}</>}
        />
      )}
      {
        addOutcomeTracking && width > 768 && (
          <Drawer
            anchor="right"
            sx={{
              "& .MuiPaper-root": {
                width: isMobile ? "100%" : needIdeasOpen ? "80%" : '70%'
              }
            }}
            open={openStartModal}
            onClose={handleCloseStartModal}
          >
            <div className={classes.drawerTitle}>
              <Typography sx={isMobile ? {
                color: palette.common.black,
                fontSize: "16px!important",
                fontWeight: 500,
                width: "80%",
                textAlign: "center"
              } : {
                color: palette.common.black,
                fontSize: "16px!important",
                fontWeight: 500
              }}>{outComeValue?._id ? "Edit" : "Add new metric to"} <span style={{ color: palette.primary.main }}>Outcomes</span></Typography>
            </div>

            <DialogTitle className={classes.modalCloseIcon}>
              <span
                onClick={handleCloseStartModal}
              >
                <ModalCloseIconBlack />
              </span>
            </DialogTitle>
            <DialogContent sx={{
              padding: 0,
              overflowX: "hidden"

            }} className={isMobile ? "hide-scrollbar" : ""}>

              {/* OUTCOME LEFT SECTION */}
              <LeftSideOutComeNew
                outComeValue={outComeValue}
                setOutcomeValue={setOutcomeValue}
                outcomeIdeas={outcomeIdeas}
                setShowOutcome={setShowOutcome}
                showOutcome={true}
                setAddOutcomeTracking={setAddOutcomeTracking}
                setTextInput={setTextInput}
                setShowSnakbar={setShowSnakbar}
                setMsgType={setMsgType}
                setSnakbarMsg={setSnakbarMsg}
                textInput={textInput}
                categoryId={categoryId}
                setTransformToInfluenceTracking={setTransformToInfluenceTracking}
                width={width}
                startDate={startDate}
                endDate={endDate}
                handleCloseStartModal={handleCloseStartModal}
                setNeedIdeasOpen={setNeedIdeasOpen}
                setLimitReachPopupVisible={setLimitReachPopupVisible}
                setLimitReachPopuptype={setLimitReachPopuptype}
                isMobile={isMobile}
                getOutcomeList={async () => {
                  if (IUserTypeState.isPersonal) {
                    const bodyData = {
                      from: moment(mobileStartDay).format("MM-DD-YYYY"),
                      to: moment(mobileEndDay).format("MM-DD-YYYY"),
                    };
                    let data = await trackingService.getPersonalJourneyList(
                      IUserTypeState.token,
                      bodyData
                    );
                    userTypeDispatch(addCategoryJourneyAction(data?.data));
                    userTypeDispatch(addNotesJourneyAction(data?.data));
                  }
                  outcomeService
                    .getOutcomeList(IUserTypeState.token)
                    .then((data: any) => {
                      userTypeDispatch(addOutcomeAction(data?.data));
                    })
                    .catch((error) => {
                      console.log("Outcome Error");
                    });
                }}
              />
            </DialogContent>

          </Drawer>
        )
      }

      {/* ADD INFLUENCE MODAL */}
      {
        addInfluenceTracking && width > 768 && (
          <MultiDrawerAddEditComponent
            handleClose={handleCloseStartModalInfluence}
            drawerTitle={<>{influenceData?.uid ? "Edit" : "Add new metric to"} <span style={{ color: palette.primary.main }}>Influences</span></>}
            secondOpen={needIdeasOpen}
            isMobile={isMobile}
          >
            <LeftSideInfluenceNew
              setInfluenceData={setInfluenceData}
              influenceData={influenceData}
              influenceIdeas={influenceIdeas}
              setShowSnakbar={setShowSnakbar}
              setMsgType={setMsgType}
              setSnakbarMsg={setSnakbarMsg}
              showInfluence={true}
              setAddInfluenceTracking={setAddInfluenceTracking}
              setShowInfluence={setShowInfluence}
              setTextInput={setTextInput}
              textInput={textInput}
              handleCloseStartModal={handleCloseStartModalInfluence}
              getInfluenceList={getInfluenceList}
              setInfluenceIdeas={setInfluenceIdeas}
              // transformToOutcomeTracking={props?.transformToOutcomeTracking}
              // transformInInfluenceTracking={
              //   props?.transformInInfluenceTracking
              // }
              // setTransformInInfluenceTracking={
              //   props?.setTransformInInfluenceTracking
              // }
              // startDate={props.startDate}
              // endDate={props.endDate}
              setNeedIdeasOpen={setNeedIdeasOpen}
              setLimitReachPopupVisible={setLimitReachPopupVisible}
              setLimitReachPopuptype={setLimitReachPopuptype}
              isMobile={isMobile}
            />
          </MultiDrawerAddEditComponent>
          // <Dialog
          //   fullScreen
          //   disableEscapeKeyDown
          //   onClose={handleCloseStartModalInfluence}
          //   open={openStartModalInfluence}
          //   className="dialogWrapper dialogWrapper-outcome"
          // >
          //   <DialogTitle className={classes.modalCloseIcon}>
          //     <span
          //       onClick={handleCloseStartModalInfluence}
          //     >
          //       <ModalCloseIconBlack />
          //     </span>
          //   </DialogTitle>
          //   <DialogContent>
          //     <Grid
          //       container
          //       columns={16}
          //       sx={{ backgroundColor: palette.secondary.light }}
          //     >
          //       <Grid
          //         sx={{ backgroundColor: palette.common.white }}
          //         item
          //         xs={9.63}
          //         className="leftside-menu login-screen outcome-screen outcome-left-layout"
          //       >
          //         {/* INFLUENCE SECTION */}
          //         <LeftSideInfluence
          //           setInfluenceData={setInfluenceData}
          //           influenceData={influenceData}
          //           influenceIdeas={influenceIdeas}
          //           showInfluence={true}
          //           setAddInfluenceTracking={setAddInfluenceTracking}
          //           setShowInfluence={setShowInfluence}
          //           setShowSnakbar={setShowSnakbar}
          //           setMsgType={setMsgType}
          //           setSnakbarMsg={setSnakbarMsg}
          //           setTextInput={setTextInput}
          //           textInput={textInput}
          //           categoryId={categoryId}
          //           width={width}
          //           startDate={startDate}
          //           endDate={endDate}
          //         />
          //       </Grid>
          //       {width > 768 && <Grid
          //         item
          //         xs={6.37}
          //         className="rightside-menu outcome-menu outcome-right-layout"
          //         sx={{ backgroundColor: palette.common.bgColor }}
          //       >
          //         {/* INFLUENCE SUGGESTION LIST */}
          //         <RightSideInfluenceTracking
          //           influenceIdeas={influenceIdeas}
          //           setInfluenceData={setInfluenceData}
          //         />
          //       </Grid>}
          //     </Grid>
          //   </DialogContent>
          // </Dialog>
        )
      }
      {
        addInfluenceTracking && width <= 768 && (
          <MultiDrawerAddEditComponentMobile
            handleClose={handleCloseStartModalInfluence}
            drawerTitle={<>{influenceData?.uid ? "Edit " : "Add a new "} influence</>}
            secondOpen={needIdeasOpen}
            isMobile={isMobile}
          >
            <LeftSideInfluenceNew
              setInfluenceData={setInfluenceData}
              influenceData={influenceData}
              influenceIdeas={influenceIdeas}
              setShowSnakbar={setShowSnakbar}
              setMsgType={setMsgType}
              setSnakbarMsg={setSnakbarMsg}
              showInfluence={true}
              setAddInfluenceTracking={setAddInfluenceTracking}
              setShowInfluence={setShowInfluence}
              setTextInput={setTextInput}
              textInput={textInput}
              handleCloseStartModal={handleCloseStartModalInfluence}
              getInfluenceList={getInfluenceList}
              setInfluenceIdeas={setInfluenceIdeas}
              // transformToOutcomeTracking={props?.transformToOutcomeTracking}
              // transformInInfluenceTracking={
              //   props?.transformInInfluenceTracking
              // }
              // setTransformInInfluenceTracking={
              //   props?.setTransformInInfluenceTracking
              // }
              // startDate={props.startDate}
              // endDate={props.endDate}
              setNeedIdeasOpen={setNeedIdeasOpen}
              setLimitReachPopupVisible={setLimitReachPopupVisible}
              setLimitReachPopuptype={setLimitReachPopuptype}
              isMobile={isMobile}
            />
          </MultiDrawerAddEditComponentMobile>
        )
      }

      {/* ADD REFLECTIVE QUESTION MODAL */}
      {
        addQuesTracking && width > 768 && (
          <MultiDrawerAddEditComponent
            handleClose={handleCloseStartModalQues}
            drawerTitle={<>{questionData?.uid ? "Edit" : "Add new metric to"} <span style={{ color: palette.primary.main }}>Qualitative Reflection</span></>}
            secondOpen={needIdeasOpen}
            isMobile={isMobile}
          >
            <LeftSideReflectiveQueNew
              questionData={questionData}
              setQuestionData={setQuestionData}
              reflectiveIdeas={reflectiveIdeas}
              showRefQues={true}
              setAddQuesTracking={setAddQuesTracking}
              setShowQues={setShowQues}
              setWeekDay={setWeekDay}
              weekday={weekday}
              categoryId={categoryId}
              width={width}
              setShowSnakbar={setShowSnakbar}
              setMsgType={setMsgType}
              handleCloseStartModalQues={handleCloseStartModalQues}
              setSnakbarMsg={setSnakbarMsg}
              startDate={startDate}
              endDate={endDate}
              setNeedIdeasOpen={setNeedIdeasOpen}
              setLimitReachPopupVisible={setLimitReachPopupVisible}
              setLimitReachPopuptype={setLimitReachPopuptype}
              isMobile={isMobile}
              getReflectiveList={async () => {
                if (IUserTypeState.isPersonal) {
                  const bodyData = {
                    from: moment(mobileStartDay).format("MM-DD-YYYY"),
                    to: moment(mobileEndDay).format("MM-DD-YYYY"),
                  };
                  let data = await trackingService.getPersonalJourneyList(
                    IUserTypeState.token,
                    bodyData
                  );
                  userTypeDispatch(addCategoryJourneyAction(data?.data));
                  userTypeDispatch(addNotesJourneyAction(data?.data));
                }
                reflectiveQueService
                  .getReflectiveList(IUserTypeState.token)
                  .then((data) => {
                    userTypeDispatch(addRefelectiveAction(data?.data));
                  })
                  .catch((error) => {
                    console.log('Ques error');
                  });
              }}
            />
          </MultiDrawerAddEditComponent>
          // <Dialog
          //   fullScreen
          //   disableEscapeKeyDown
          //   onClose={handleCloseStartModalQues}
          //   open={openStartModalQues}
          //   className="dialogWrapper dialogWrapper-outcome"
          // >
          //   <DialogTitle className={classes.modalCloseIcon}>
          //     <span
          //       onClick={handleCloseStartModalQues}
          //     >
          //       <ModalCloseIconBlack />
          //     </span>
          //   </DialogTitle>
          //   <DialogContent>
          //     <Grid
          //       container
          //       columns={16}
          //       sx={{ backgroundColor: palette.secondary.light }}
          //     >
          //       <Grid
          //         sx={{ backgroundColor: palette.common.white }}
          //         item
          //         xs={9.63}
          //         className="leftside-menu login-screen outcome-screen outcome-left-layout"
          //       >
          //         {/* REFLECTIVE QUESTION SECTION */}
          //         <LeftSideReflectiveQue
          //           questionData={questionData}
          //           setQuestionData={setQuestionData}
          //           reflectiveIdeas={reflectiveIdeas}
          //           showRefQues={true}
          //           setAddQuesTracking={setAddQuesTracking}
          //           setShowQues={setShowQues}
          //           setWeekDay={setWeekDay}
          //           weekday={weekday}
          //           categoryId={categoryId}
          //           width={width}
          //           setShowSnakbar={setShowSnakbar}
          //           setMsgType={setMsgType}
          //           setSnakbarMsg={setSnakbarMsg}
          //           startDate={startDate}
          //           endDate={endDate}
          //         />
          //       </Grid>
          //       {width > 768 && <Grid
          //         item
          //         xs={6.37}
          //         className="rightside-menu outcome-menu outcome-right-layout"
          //         sx={{ backgroundColor: palette.common.bgColor }}
          //       >
          //         {/* REFLECTIVE QUESTION SUGGSESTION LIST */}
          //         <RightSideRefQuesTracking
          //           reflectiveIdeas={reflectiveIdeas}
          //           setQuestionData={setQuestionData}
          //         />
          //       </Grid>}
          //     </Grid>
          //   </DialogContent>
          // </Dialog>
        )
      }
      {
        addQuesTracking && width <= 768 && (
          <MultiDrawerAddEditComponentMobile
            handleClose={handleCloseStartModalQues}
            drawerTitle={<>{questionData?.uid ? "Edit" : "Add a new "} <>reflective question</></>}
            secondOpen={needIdeasOpen}
            isMobile={isMobile}
          >
            <LeftSideReflectiveQueNew
              questionData={questionData}
              setQuestionData={setQuestionData}
              reflectiveIdeas={reflectiveIdeas}
              showRefQues={true}
              setAddQuesTracking={setAddQuesTracking}
              setShowQues={setShowQues}
              setWeekDay={setWeekDay}
              weekday={weekday}
              categoryId={categoryId}
              width={width}
              setShowSnakbar={setShowSnakbar}
              setMsgType={setMsgType}
              handleCloseStartModalQues={handleCloseStartModalQues}
              setSnakbarMsg={setSnakbarMsg}
              startDate={startDate}
              endDate={endDate}
              setNeedIdeasOpen={setNeedIdeasOpen}
              setLimitReachPopupVisible={setLimitReachPopupVisible}
              setLimitReachPopuptype={setLimitReachPopuptype}
              isMobile={isMobile}
              getReflectiveList={async () => {
                if (IUserTypeState.isPersonal) {
                  const bodyData = {
                    from: moment(mobileStartDay).format("MM-DD-YYYY"),
                    to: moment(mobileEndDay).format("MM-DD-YYYY"),
                  };
                  let data = await trackingService.getPersonalJourneyList(
                    IUserTypeState.token,
                    bodyData
                  );
                  userTypeDispatch(addCategoryJourneyAction(data?.data));
                  userTypeDispatch(addNotesJourneyAction(data?.data));
                }
                reflectiveQueService
                  .getReflectiveList(IUserTypeState.token)
                  .then((data) => {
                    userTypeDispatch(addRefelectiveAction(data?.data));
                  })
                  .catch((error) => {
                    console.log('Ques error');
                  });
              }}
            />
          </MultiDrawerAddEditComponentMobile>
        )
      }
      <LimitReachedPopup open={limitPopupVisible} setopenOutcomeclosePopup={setLimitReachPopupVisible}
        handleCloseStartModal={handleCloseStartModal}
        handleCloseStartModalInfluence={handleCloseStartModalInfluence}
        handleCloseStartModalQues={handleCloseStartModalQues}
        type={limitPopuptype} />
      {/* {shareModalOpen && (
        <ShareSocialReportModal
          shareModalOpen={shareModalOpen}
          handleShareModalOpen={handleShareModalOpen}
          getAllContacts={getAllContacts}
        />

      )} */}
    </>
  );
};

export default TrackingView;


const AddTrackingViewmobile = ({ title, open, handleClose, classes, restComponent }) => {
  return <Drawer
    anchor="right"
    sx={{
      "& .MuiPaper-root": {
        width: "100%"
      }
    }}
    open={open}
    onClose={handleClose}
  >


    <DialogTitle className={classes.modalCloseIconMobile}>
      <div className={classes.drawerTitleMobile}>
        <Typography sx={{
          color: palette.common.black,
          fontSize: `${pxToRem(24)} !important`,
          fontWeight: 600,
          width: "80%",
          "&.MuiTypography-root": {
            "@media  (max-width: 600px) and (min-width: 100px)": {
              fontSize: `${pxToRem(24)} !important`,
              lineHeight: "100% !important"
            },
          }
          // textAlign: "center"
        }}>{title}</Typography>
      </div>
      <span
        onClick={handleClose}
      >
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16 17.59L10.42 12L16 6.41L14.59 5L7.59 12L14.59 19L16 17.59Z" fill="#3E4240" />
        </svg>

      </span>
    </DialogTitle>
    <DialogContent sx={{
      padding: 0,
      overflowX: "hidden"

    }} className={"hide-scrollbar"}>
      {restComponent}
    </DialogContent>

  </Drawer>
}