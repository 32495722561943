import React from "react"

const FolderOpnenIcon = ({
    color = "#3E4240",
    size = 20
}) => {
    return <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.393 8.77422C19.2482 8.57325 19.0577 8.40958 18.8373 8.29671C18.6168 8.18383 18.3727 8.12497 18.125 8.125H17.1875V6.875C17.1875 6.4606 17.0229 6.06317 16.7299 5.77015C16.4368 5.47712 16.0394 5.3125 15.625 5.3125H10.3125L8.22969 3.75C7.95891 3.54776 7.63016 3.43817 7.29219 3.4375H3.125C2.7106 3.4375 2.31317 3.60212 2.02015 3.89515C1.72712 4.18817 1.5625 4.5856 1.5625 5V16.25C1.5625 16.4986 1.66127 16.7371 1.83709 16.9129C2.0129 17.0887 2.25136 17.1875 2.5 17.1875H16.4922C16.6863 17.1878 16.8757 17.1278 17.0342 17.0158C17.1928 16.9039 17.3126 16.7455 17.3773 16.5625L19.6031 10.1977L19.6078 10.1844C19.6865 9.94915 19.7081 9.69856 19.6707 9.45335C19.6333 9.20814 19.5381 8.97534 19.393 8.77422ZM7.1875 5.3125L9.4375 7C9.59978 7.12171 9.79715 7.1875 10 7.1875H15.3125V8.125H5.45078C5.1242 8.12503 4.80584 8.22738 4.54044 8.41767C4.27503 8.60797 4.07593 8.87664 3.97109 9.18594L3.4375 10.7148V5.3125H7.1875ZM15.8273 15.3125H3.81953L5.67266 10H17.6852L15.8273 15.3125Z" fill={color} />
    </svg>
}

export default FolderOpnenIcon