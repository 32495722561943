import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import CommonTypo from "../../components/CommonTypo";
import { Box } from "@mui/material";
import NewButton from "../../components/NewButton";

const useStyles = makeStyles({
    tableContainer: {
        width: '100%',         // Full width of the parent container
        overflowX: 'auto',     // Enable horizontal scrolling
        maxHeight: "30vh",
        overflowY: "auto"
    },
    table: {
        borderCollapse: 'collapse', // Collapse borders for a cleaner look
        width: '100%',              // Take full width of the container
    },
    cell: {
        textAlign: 'center',       // Horizontal centering
        verticalAlign: 'middle',   // Vertical centering
        width: 'auto',
        minWidth: "125px",            // Optional: set cell width
        padding: "16px"
    }
});
function generateExcelHeaders(count) {
    const headers = [];
    while (headers.length < count) {
        let header = '';
        let n = headers.length;

        // Convert the number to base-26 like Excel headers
        while (n >= 0) {
            header = String.fromCharCode((n % 26) + 65) + header;
            n = Math.floor(n / 26) - 1;
        }

        headers.push(header);
    }

    return headers;
}

// Example: generate 30 headers
console.log(generateExcelHeaders(30));
const ImportFailedBox = ({ failedData, setFailedData, setApiCalled, sethasSuccess, setUploadedFile }) => {
    const classes = useStyles();
    return <>
        <Box display={"flex"} flexDirection={"column"} gap={"16px"}>
            <Box display={"flex"} gap={"8px"}>

            </Box>
        </Box>
        <div className={classes.tableContainer}>
            <table className={classes.table} border={1} style={{
                borderCollapse: "collapse",
                borderColor: "#EEEEEE"
            }} >
                <tr>
                    <td className={classes.cell}></td>
                    {generateExcelHeaders(failedData[0].length).map(it => <td className={classes.cell}><CommonTypo size={16} fontWeight={500} color="#3E4240">{it}</CommonTypo></td>)}
                </tr>
                {failedData.map((it, index) => {
                    return <tr>
                        <td className={classes.cell}><CommonTypo size={16} fontWeight={500} color="#3E4240">{index + 1}</CommonTypo></td>
                        {it.map(ite => <td className={classes.cell} style={ite && ite.toString().includes("RED") ? {
                            border: "1px solid red"
                        } : {}}><CommonTypo size={16} fontWeight={500} color="#3E4240">{ite && ite.toString().includes("RED") ? ite && ite.toString().replace("RED", "") : ite}</CommonTypo></td>)}
                    </tr>
                })}
            </table >

        </div>
        <Box display={"flex"} gap={"16px"} alignItems={"center"} justifyContent={"flex-end"} width={"100%"}>
            <NewButton extraSx={{
                height: "40px",
                display: 'flex',
                backgroundColor: "#EEEEEE !important",
                border: "2px solid #EEEEEE !important"
            }} buttonText="Try again" onClick={() => {
                setFailedData([])
                setApiCalled(false)
                sethasSuccess(false)
                setUploadedFile(false)
            }} boxSx={{
            }}
                padding="16px" borderRadius="20px !important" fontWeight={600} typographySx={{
                    fontWeight: 600
                }} />
        </Box>
    </>
}

export default ImportFailedBox