// SETUP COLORS
const PRIMARY = {
  main: "#11734A",
  newmain: "#ADE888",
  contrast: "#AFE8AE",
  contrastText: "#fff",
  light: "#3E4240",
  newLight: "#5E5F5E"
};
const SECONDARY = {
  main: "#3366FF",
  contrastText: "#fff",
  contrast: "#9BA19D",
  light: "#F7F9FA",
};

const palette = {
  common: { black: "var(--black-color)", white: "var(--white-background)", bgColor: "#F7F9FA", disabled: "var(--disabled-color)" },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
};

export default palette;
