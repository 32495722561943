import { Typography, Button, Grid, Box } from "@mui/material";
import React, { useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import palette from "../../theme/palette";
import typography from "../../theme/typography";
import { makeStyles } from "@material-ui/core";

import ArrowNextIcon from "../../assets/images/icon/arrow_next";
import SortDownIcon from "../../assets/images/icon/SortDown";
import SortUpIcon from "../../assets/images/icon/SortUp";
import InsightinformationModal from "./insightInformationModal";
import Star from "../../assets/images/icon/Star";
const useStyles = makeStyles((theme) => ({
  mainTitleFlex: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  insightsTable: {
    display: "table",
    width: "100%",
  },
  insightsTableRow: {
    display: "table-row",
  },
  insightsRegression: {
    display: "table-cell",
    width: "33.33%",
    verticalAlign: "middle",
    padding: "5px 0",
  },
  insightsRegressionCell: {
    display: "flex",
    alignItems: "center",
    verticalAlign: "middle",
    justifyContent: "flex-end",
  },
  insightsRegressionCellTitle: {
    display: "flex",
    alignItems: "center",
    verticalAlign: "middle",
    justifyContent: "flex-end",
    fontWeight: 400,
    fontSize: "16px",

    color: "#9BA19D",
  },
  insightsInfluences: {
    display: "table-cell",
    width: "33.33%",
    padding: "5px 8px",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "100%",
    alignItems: "center",
    textAlign: "center",
    color: "#3E4240",
    verticalAlign: "middle",
  },
  insightsInfluencesTitle: {
    display: "flex",
    alignItems: "center",
    verticalAlign: "middle",
    justifyContent: "center",
    fontWeight: 400,
    fontSize: "16px",

    color: "#9BA19D",
  },
  insightsCorrelation: {
    display: "table-cell",
    width: "33.33%",
    verticalAlign: "middle",
    padding: "5px 0",
  },
  insightsCorrelationCell: {
    display: "flex",
    alignItems: "center",
    verticalAlign: "middle",
    justifyContent: "flex-start",
  },
  insightsCorrelationCellTitle: {
    display: "flex",
    alignItems: "center",
    verticalAlign: "middle",
    justifyContent: "flex-start",
    fontWeight: 400,
    fontSize: "16px",

    color: "#9BA19D",
  },
  insightsRegressionPositive: {
    height: "36px",

    /* GraphicColors/DarkSky */

    background: "#4CB3FD",
    borderRadius: "8px 0px 0px 8px",
    marginLeft: "20px",
    width: "100%",
  },
  insightsRegressionPositiveText: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "100%",
    /* identical to box height, or 16px */

    display: "flex",
    alignItems: "center",
    textAlign: "right",

    /* GraphicColors/DarkSky */

    color: "#4CB3FD",
  },
  insightsRegressionNegative: {
    height: "36px",

    /* GraphicColors/DarkSky */

    background: "#7572FC",
    borderRadius: "8px 0px 0px 8px",
    marginLeft: "20px",
    width: "100%",
  },
  insightsRegressionNegativeText: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "100%",
    /* identical to box height, or 16px */

    display: "flex",
    alignItems: "center",
    textAlign: "right",

    /* GraphicColors/DarkSky */

    color: "#7572FC",
  },
  insightsCorrelationPositive: {
    height: "36px",

    /* GraphicColors/DarkSky */

    background: "#169C63",
    borderRadius: "0px 8px 8px 0px",
    marginRight: "20px",
    width: "100%",
  },
  insightsCorrelationNegative: {
    height: "36px",

    /* GraphicColors/DarkSky */

    background: "#F17F79",
    borderRadius: "0px 8px 8px 0px",
    marginRight: "20px",
    width: "100%",
  },
  insightsCorrelationPositiveText: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "100%",
    /* identical to box height, or 16px */

    display: "flex",
    alignItems: "center",
    textAlign: "right",

    /* GraphicColors/DarkSky */

    color: "#169C63",
  },
  insightsCorrelationNegativeText: {
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "100%",
    /* identical to box height, or 16px */

    display: "flex",
    alignItems: "center",
    textAlign: "right",

    /* GraphicColors/DarkSky */

    color: "#F17F79",
  },
}));
const CustomCard = ({ item, heighestRegression, heighestCorrelation, heighestRegressionType12 }) => {
  console.log("item", item);
  const classes = useStyles();
  if(item.type === "TYPE12") {
    console.log(item, Math.abs(item.regression) > heighestCorrelation);
  }

  
  const RegressionWidth =item.type === "TYPE12" ? 
    Math.abs(item.regression) > heighestCorrelation ? 
      (Math.abs(item.regression) * 100) / heighestRegressionType12 : 
      (Math.abs(item.regression) * 75) / heighestRegression :
    (Math.abs(item.regression) * 75) / heighestRegression;
  const CorrelationnWidth =
    (Math.abs(item.correlation) * 100) / heighestCorrelation;
  const stars = [];
  for (let index = 0; index < (item.star || 0); index++) {
    stars.push(
      <>
        <Star />
      </>
    );
  }
  return (
    <div
      className={classes.insightsTableRow}
    >
      <div
        className={classes.insightsRegression}
        style={{
          borderBottom: "1px solid #F3F3F3",
          paddingBottom: "20px"
        }}
      >
        <div className={classes.insightsRegressionCell}>
          <span
            className={
              item?.regression === 0
                ? null
                : item?.regression > 0
                ? classes.insightsRegressionPositiveText
                : classes.insightsRegressionNegativeText
            }
          >
            {item?.regression && item?.regression !== 0
              ? parseFloat(item?.regression).toFixed(2).replace("0.", ".")
              : item?.regression === 0
              ? item?.regression
              : "N/A"}
          </span>
          <div
            className={
              item?.regression === 0
                ? null
                : item?.regression > 0
                ? classes.insightsRegressionPositive
                : classes.insightsRegressionNegative
            }
            style={{
              width: `${
                item?.regression && item?.regression !== 0 ? RegressionWidth : 0
              }%`,
            }}
          ></div>
        </div>
      </div>
      <div
        className={classes.insightsInfluences}
        style={{
          borderBottom: "1px solid #F3F3F3",
          paddingBottom: "20px"
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: 12,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 4,
            }}
          >
            {stars}
          </div>
          {item?.influences}
        </div>
      </div>
      <div
        className={classes.insightsCorrelation}
        style={{
          borderBottom: "1px solid #F3F3F3",
          paddingBottom: "20px"
        }}
      >
        <div className={classes.insightsCorrelationCell}>
          <div
            className={
              item?.correlation === 0
                ? null
                : item?.correlation > 0
                ? classes.insightsCorrelationPositive
                : classes.insightsCorrelationNegative
            }
            style={{
              width: `${
                item?.correlation && item?.correlation !== 0
                  ? CorrelationnWidth
                  : 0
              }%`,
            }}
          ></div>
          <span
            className={
              item?.correlation === 0
                ? null
                : item?.correlation > 0
                ? classes.insightsCorrelationPositiveText
                : classes.insightsCorrelationNegativeText
            }
          >
            {item?.correlation && item?.correlation !== 0
              ? parseFloat(item?.correlation).toFixed(2).replace("0.", ".")
              : item?.correlation === 0
              ? item?.correlation
              : "N/A"}
          </span>
        </div>
      </div>
    </div>
  );
};

const InfluenceInsights = ({
  selectOutcome,
  outcomeData,
  getInsightDetails,
}) => {
  const classes = useStyles();
  const [visibleInightInformation, setVisibleInsightInformation] =
    useState(false);
  const influenceData = [];
  let maxCount = 0;
  if (selectOutcome) {
    const found = outcomeData.find((it) => it.outcomeId === selectOutcome);
    if (found && found.influence) {
      maxCount = found?.influenceLength;
      if (found.influence && found.influence.length) {
        found.influence.map((it) => {
          influenceData.push({
            regression: it?.regression?.slope,
            influences: it?.name,
            correlation: it?.correlation,
            star: it.star || 0,
            type: it.type
          });
          return it;
        });
      }
    }
  }
  const foundOutcome =
    outcomeData.length &&
    outcomeData.find((it) => it.outcomeId === selectOutcome);
  
  const heighestRegression = Math.max(
    ...influenceData.filter(it => it.type !== "TYPE12").map((o) => Math.abs(o.regression || 0))
  );
  const heighestRegressionType12 = Math.max(
    ...influenceData.filter(it => it.type === "TYPE12").map((o) => Math.abs(o.regression || 0))
  );
  const heighestCorrelation = Math.max(
    ...influenceData.map((o) => Math.abs(o.correlation || 0))
  );
  return (
    <>
      <div className={classes.mainTitleFlex}>
        <Typography variant="h3" letterSpacing={"0.02em"}>
          Top Influences for your{" "}
          {foundOutcome && foundOutcome.name
            ? foundOutcome.name.toLowerCase()
            : ""}
        </Typography>
      </div>

      <Typography
        variant="h5"
        letterSpacing={"0.02em"}
        sx={{ margin: "20px 0", color: "#9BA19D", cursor: "pointer" }}
        onClick={() => setVisibleInsightInformation(true)}
      >
        <u>How does regression and correlation works?</u>
      </Typography>
      <Grid marginBottom={"24px"}>
        {influenceData.length ? (
          <div className={classes.insightsTable}>
            <div className={classes.insightsTableRow}>
              <div className={classes.insightsRegression}>
                <div className={classes.insightsRegressionCellTitle}>
                  Regression
                  {/* <SortDownIcon /> <SortUpIcon /> */}
                </div>
              </div>
              <div className={classes.insightsInfluences}>
                <div className={classes.insightsInfluencesTitle}>
                  Influences
                </div>
              </div>
              <div className={classes.insightsCorrelation}>
                <div className={classes.insightsCorrelationCellTitle}>
                  Correlation {/* <SortDownIcon /> <SortUpIcon /> */}
                </div>
              </div>
            </div>
            {/* <HighchartsReact highcharts={Highcharts} options={options} /> */}
            {influenceData.map((it) => (
              <CustomCard
                heighestRegression={heighestRegression}
                heighestCorrelation={heighestCorrelation}
                heighestRegressionType12={heighestRegressionType12}
                item={it}
                key={Math.random()}
              />
            ))}
          </div>
        ) : (
          <Typography
            variant="h5"
            letterSpacing={"0.02em"}
            sx={{ marginBottom: "20px", color: "#9BA19D", textAlign: "center" }}
          >
            <u>No Influence Data found for this outcome?</u>
          </Typography>
        )}
        {maxCount > influenceData.length && (
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            width={"100%"}
            sx={{
              verticalAlign: "middle",
              textAlign: "center",
              marginTop: "16px",
            }}
          >
            <Button
              variant="outlined"
              // className="button-after tracking-button"
              sx={{
                color: palette.primary.light,
                fontWeight: 500,
                padding: "5px 15px",
                margin: "0px auto",
              }}
              onClick={() => getInsightDetails(influenceData.length + 10)}
            >
              Discover more{" "}
            </Button>
          </Box>
        )}
        <InsightinformationModal
          visibleInightInformation={visibleInightInformation}
          setVisibleInsightInformation={setVisibleInsightInformation}
        />
        {/* <HighchartsReact highcharts={Highcharts} options={options} /> */}
      </Grid>
    </>
  );
};
export default InfluenceInsights;
