import {
  Dialog,
  Grid,
  DialogContent,
  Typography,
  ListItem,
  Button,
  List,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import palette from "../../../theme/palette";

//icon & img
import GreenPoint from "../../../assets/images/icon/green_Point"
import { useNavigate } from "react-router-dom";
import { UserTypeContext } from "../../../context/userStatus";
import { isOpenTrackModal } from "../../../store/actions/userTypeAction";
import moment from "moment";

const Illustration_Popup_goals = require("../../../assets/images/Illustration_Popup_goals.png");


const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    padding: "0!important",
    fontSize: "26px!important",
    lineHeight: "31px!important",
    color: `${palette.primary.light}!important`,
    marginBottom: "20px!important",
    width: "28vw !important",
    [theme.breakpoints.down("md")]: {
      width: "45vw",
    },
    [theme.breakpoints.down("sm")]: {
      width: "70vw",
    },
    [theme.breakpoints.up("xl")]: {
      width: "20vw",
    },
  },
  modalSubTitle: {
    color: `${palette.primary.light}!important`,
    marginTop: "48px!important",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "20px!important",
    },
  },
  listModalTitle: {
    marginBottom: "20px!important",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "10px!important",
    },
  },
  listItem: {
    padding: "0!important",
  },
  listItemItem: {
    gap: "8px",
    padding: "0!important",
    color: palette.primary.light,
    fontSize: "16px",
    lineHeight: "19px",
    fontWeight: 400,
    marginTop: "20px",

    "&:last-child": {
      marginTop: "16px",
      marginBottom: "60px",

      [theme.breakpoints.down("sm")]: {
        marginTop: "15px",
        marginBottom: "20px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "17px",
    },
  },
  Illustration_Popup_goals: {
    position: "absolute",
    top: "-75px",
    left: 0,
    right: 0,
    margin: "auto",
    width: "130px",
    height: "130px",
    [theme.breakpoints.down(575)]: {
      width: "120px",
      top: "-110px",
    },
  },

  ButtonBox: {
    display: "flex",
    justifyContent: "space-between",
    gap: "10px",
    height: "52px",
    marginTop: "60px"

  },

  BtnLater: {
    background: "#FFFFFF !important",
    color: "#3E4240 !important",
    border: "2px solid rgba(17, 115, 74, 0.4) !important",
    borderRadius: "10px !important",
    Padding: "15px, 10px, 15px, 10px !important",
    width: "200px !important",
    [theme.breakpoints.down("sm")]: {
      width: "35vw",
    },
  }
}));

interface StartTrackingModalInt {
  setOpenStartTracking: (openStartTracking: boolean) => void;
  createDate: any
  showGoal?: boolean
}

const AddTrackingPopUp = (props: StartTrackingModalInt) => {
  const { state: IUserTypeState, dispatch: userTypeDispatch } =
    useContext(UserTypeContext);
  const navigate = useNavigate();
  const classes = useStyles();
  const [openStartModal, setStartModal] = useState(IUserTypeState.isOneTimeOpenTracking ? false : false);
  const handleCloseStartModal = () => {
    setStartModal(false);
    userTypeDispatch(isOpenTrackModal(true))

  };
  useEffect(() => {
    const diff = moment().diff(moment(props.createDate), 'days')
    if (diff>=0 && diff < 30) {
      setStartModal(false)
    } else if(diff === 0) {
      setStartModal(false)
    } else {
      if(!IUserTypeState.isOneTimeOpenTracking) {
        if(props.showGoal) {
          setStartModal(true)
        }
      }
    }
  }, [props.createDate])
  const handleAddingGoals = () => {
    navigate("/test-goals")
  }
  const handleLaterBtn = () => {
    setStartModal(false);
    userTypeDispatch(isOpenTrackModal(true))
  }
  return (
    <Grid>
      <Dialog
        onClose={handleCloseStartModal}
        open={openStartModal}
        className="dialogWrapper"
        disableEscapeKeyDown
      >

        <img
          src={Illustration_Popup_goals}
          alt="Illustration_Popup_goals"
          className={classes.Illustration_Popup_goals}
        />
        <Typography variant="h3" sx={{ lineHeight: "31px !important", letterSpacing: "0.02em" }}>
          Congratulations, you have completed 30 days of discovery tracking!
        </Typography>
        <DialogContent>
          <Typography
            variant="subtitle1"
            component="div"
            className={classes.modalSubTitle}
          >
            You now have the ability to:
          </Typography>
          <Grid>

            <List className={classes.listItem}>
              <ListItem className={classes.listItemItem}>
                <GreenPoint />
                Access the insights analytics
              </ListItem>
              <ListItem className={classes.listItemItem}>
                <GreenPoint />
                Add goals to your outcomes and influences
              </ListItem>
            </List>
          </Grid>

          <div className={classes.ButtonBox}>

            <Button
              fullWidth
              variant="contained"
              className="button-after tracking-btn"
              onClick={handleAddingGoals}
            >
              <Typography variant="h6" sx={{ color: "#ffffff" }}>Start adding goals</Typography>
            </Button>

            <Button className={classes.BtnLater} onClick={handleLaterBtn}>
              Later
            </Button>

          </div>
        </DialogContent>
      </Dialog>
    </Grid >
  );
};
export default AddTrackingPopUp;
