import * as React from "react";
import { makeStyles } from "@material-ui/core";
import palette from "../../theme/palette";
import shape from "../../theme/shape";

interface AddQuestionDataLabelModal {
  refelectiveItem: any;
  key: number;
  setQuestionData: (questionData: string) => void;
}

const useStyles = makeStyles((theme) => ({
  recommText: {
    backgroundColor: palette.common.white,
    borderRadius: shape.borderRadius,
    fontSize: "16px",
    lineHeight: "36px",
    color: palette.primary.light,
    padding: "12px 16px",
    cursor: "pointer",
    marginBottom: "12px",
    wordBreak: "break-word",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
  },
}));

const AddQuestionDataLabel = (props: AddQuestionDataLabelModal) => {
  const classes = useStyles();
  return (
    <div
      className={classes.recommText}
      onClick={() => props.setQuestionData(props.refelectiveItem)}
    >
      {props.refelectiveItem.question}
    </div>
  );
};
export default AddQuestionDataLabel;
