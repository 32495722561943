import * as React from "react";

const InfluenceTickIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <circle cx="12" cy="12" r="11" stroke="#B7BDB9" strokeWidth="2" />
      <path
        d="M10.643 13.4069L7.8146 10.5784L6.40039 11.9926L10.643 16.2353L17.7141 9.16421L16.2999 7.75L10.643 13.4069Z"
        fill="#B7BDB9"
      />
    </svg>
  );
};
export default InfluenceTickIcon;
