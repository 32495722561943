import React, { useContext } from "react"
import { Popover } from "@mui/material";
import NotificationPopover from "./NotificationPopover";
import { UserNotificationContext } from "../../context/userNotification";
const HeaderPopover = (
    {
        handleClose,

    }
) => {
    const { state: IContactTypeState, dispatch: contactsDispatch } =
        useContext(UserNotificationContext);
    const { anchorEl, yposition,
        xposition } = IContactTypeState
    return (
        <Popover
            sx={{ maxHeight: "calc(100vh - 190px)", }}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={{ top: yposition, left: xposition }}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}

            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <NotificationPopover />
        </Popover>
    )
}

export default HeaderPopover