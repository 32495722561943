
import React from 'react'

export default function BlueSky() {
  return (
    <>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.94813e-08 11.3364C-0.000305403 8.88872 1.77168 6.80077 4.18683 6.40302C5.52395 4.02105 8.17904 2.69598 10.8864 3.05951C13.5937 3.42304 15.8051 5.40159 16.4665 8.05192C18.6196 8.38275 20.1543 10.3164 19.9876 12.4884C19.8209 14.6603 18.0091 16.3372 15.8308 16.3356H4.9992C2.23822 16.3356 3.94813e-08 14.0974 3.94813e-08 11.3364Z" fill="#4CB3FD" />
      </svg>
    </>
  )
}
