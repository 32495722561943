import React, { useReducer } from "react";
import { IReportsTypeState, ReportsAction, ReportsTypeContextModal } from "../interfaces/reportsModal";


const defaultState: IReportsTypeState = {
  isWeekReport: false,
  weekReportData: [],
  total: 0,
  currentPage: 1,
  selectedRow: {},
  selectedRowDetails: {}
};

const reducer = (
  state: IReportsTypeState,
  action: ReportsAction
): IReportsTypeState => {
  switch (action.type) {
    case "SET_WEEK_MODE":
      return {
        ...state,
        isWeekReport: action.payload,
      };
    case "SET_WEEK_TABLE_DATA":
      return {
        ...state,
        weekReportData: action.payload,
      };
    case 'SET_WEEK_TABLE_DATA_TOTAL':
      return {
        ...state,
        total: action.payload,
      };
    case 'SET_WEEK_TABLE_DATA_CUREENT_PAGE':
      return {
        ...state,
        currentPage: action.payload,
      };
    case 'SET_WEEK_TABLE_SELECTED_ROW':
      return {
        ...state,
        selectedRow: action.payload,
      };
    case "SET_WEEK_TABLE_SELECTED_ROW_DETAILS":
      return {
        ...state,
        selectedRowDetails: action.payload,
      };
    default:
      return state;
  }
};

export const ReportsTypeContext = React.createContext({} as ReportsTypeContextModal);
type childernType = {
  children: React.ReactNode;
};
export const Provider: React.FC = (props: childernType) => {
  const [state, dispatch] = useReducer(reducer, defaultState);

  return (
    <ReportsTypeContext.Provider value={{ state, dispatch }}>
      {props.children}
    </ReportsTypeContext.Provider>
  );
};
