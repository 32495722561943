import { Grid, styled, Typography } from "@mui/material";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import FullpageLoader from "../../components/fullPageLoader";
import { ReportsTypeContext } from "../../context/userIReports";
import { UserTypeContext } from "../../context/userStatus";
import { contactsService } from "../../services/contactsService";
import {
  setSelectedRow,
  setSelectedRowDetails,
} from "../../store/actions/reportTypeAction";
import ProgressTitleDetails from "../progressWeek/progressTitleDetails";
import ReportDetails from "../progressWeek/ReportDetails";
import palette from "../../theme/palette";
import { pxToRem } from "../../theme/typography";
import NewButton from "../../components/NewButton";

const RootStyle = styled("div")(({ theme }) => ({
  padding: "60px 40px 0px",

  [theme.breakpoints.down("lg")]: {
    padding: "40px 20px 0px",
  },
  [theme.breakpoints.down(1001)]: {
    padding: "20px",
  },
}));

const ShareViewReport = () => {
  const { state: IReportTypeContext, dispatch: reportTypeDispatch } =
    useContext(ReportsTypeContext);
  const { end, start, uid, shareReportId } = useParams();
  const [dates, setDates] = useState([]);
  const [userData, setUserData] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  useEffect(() => {
    if (shareReportId) {
      contactsService
        .getReportDetails({ shareReportId })
        .then((data) => {
          console.log("data", data);
          if (data.body.from && data.body.to && data.body.uid) {
            setUserData(data.userData);
            const startDate = moment(data.body.from).format("YYYY-MM-DD");
            const endDate = moment(data.body.to).format("YYYY-MM-DD");
            reportTypeDispatch(
              setSelectedRow({
                till: endDate,
                from: startDate,
                uid: data.body.uid,
              })
            );
            contactsService
              .viewshareReport({
                to: data.body.to,
                from: data.body.from,
                uid: data.body.uid,
                outcomeIds: data.body.outcomeIds,
                influenceIds: data.body.influenceIds,
              })
              .then((data) => {
                reportTypeDispatch(setSelectedRowDetails(data));
                // setShowSnakbar(true);
                // setMsgType("success");
                // setSnakbarMsg(data?.description);
              })
              .catch((err) => {
                if (err?.description) {
                  //   setShowSnakbar(true);
                  //   setMsgType("error");
                  //   setSnakbarMsg(err?.description);
                }
              });
          }
        })
        .finally(() => {
          // setShowLoadingBtn(false)
        });
    }
    // if (end && start && uid) {
    //     const startDate = moment(start).add(1, 'day').format("YYYY-MM-DD");
    //     const endDate = moment(end).add(1, 'day').format("YYYY-MM-DD");
    //     reportTypeDispatch(setSelectedRow({ till: endDate, from: startDate, uid }))
    //     contactsService.viewshareReport({ to: end, from: start, uid }).then(data => {
    //         reportTypeDispatch(setSelectedRowDetails(data))
    //         // setShowSnakbar(true);
    //         // setMsgType("success");
    //         // setSnakbarMsg(data?.description);
    //     }).catch(err => {
    //         if (err?.description) {
    //             //   setShowSnakbar(true);
    //             //   setMsgType("error");
    //             //   setSnakbarMsg(err?.description);
    //         }

    //     }).finally(() => {
    //         // setShowLoadingBtn(false)
    //     })
    // }
  }, [end, start, uid, shareReportId]);
  const enumerateDaysBetweenDates = (startDate, endDate) => {
    var dates = [];
    var currDate = moment(startDate, "MM-DD-YYYY")
      .subtract(1, "day")
      .startOf("day");
    var lastDate = moment(endDate, "MM-DD-YYYY").endOf("day");

    while (currDate.add(1, "days").diff(lastDate) < 0) {
      dates.push(currDate.clone().toDate());
    }
    setDates(dates);
  };
  useEffect(() => {
    if (Object.keys(IReportTypeContext.selectedRow).length) {
      enumerateDaysBetweenDates(
        IReportTypeContext.selectedRow.from,
        IReportTypeContext.selectedRow.till
      );
    } else {
      setDates([]);
    }
    return () => setDates([]);
  }, [IReportTypeContext.selectedRow]);
  return (
    <Grid
      container
      columns={16}
      height={"100vh"}
      overflow={"auto"}
      className="tracking_container"
      sx={{ boxSizing: "border-box" }}
    >
      {showLoader && <FullpageLoader />}

      <Grid item className="track-body-grid" width={"100%"}>
        <RootStyle
          // className="track-body"
          sx={{
            background: "#fff",
          }}
        >
          <ProgressTitleDetails
            setShowLoader={setShowLoader}
            isFromShare={true}
            userData={userData}
          />
          <ReportDetails isFromShare={true} />
          {userData && userData.user_email && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                margin: "70px 0px 30px",
              }}
            >
              <Typography
                sx={{
                  width: "100%",
                  color: palette.common.black,
                  fontSize: `${pxToRem(18)} !important`,
                  fontWeight: 400,
                }}
              >
                Got feedback or some encouragement for{" "}
                {userData && userData.first_name
                  ? ` ${userData.first_name}`
                  : ""}
                ? Just hit reply and share your thoughts!
              </Typography>
              <div>
                <NewButton
                  buttonText="Reply"
                  padding="15px 16px 15px 16px"
                  boxPadding="0px 16px 0px 16px"
                  onClick={() => {
                    window.open(
                      `https://mail.google.com/mail/?compose=new&to=${userData.user_email}`,
                      "__blank"
                    );
                  }}
                />
              </div>
            </div>
          )}
        </RootStyle>
      </Grid>
    </Grid>
  );
};

export default ShareViewReport;
