import React from "react"

import { useDispatch, useSelector } from "react-redux";
import { Dialog, DialogContent, Typography } from '@mui/material';
import { useState } from 'react';
import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
    mainModal: {},
    modalContent: {
        padding: '0 !important',
        display: 'inline-flex',
        flexDirection: 'column',
        alignItems: 'center',
        background: '#fff',
        width: '100%',
        height: '40rem',
    },

    'video': {
        display: 'flex',
        width: '80rem',
        height: '40rem',
    },

    'videoContent': {
        display: 'flex',
        // padding: '24px 32px 32px 32px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'stretch',
        position: 'absolute',
        borderRadius: '1.3125rem',
        bottom: '5.5rem',
        gap: '0.75rem',
        left: '1.5rem',
        padding: '2rem',
        background: '#FFF',
        width: '27rem',

    },
    "closeIconeWrapper": {
        position: 'absolute',
        top: '-15px',
        right: 0,
        cursor: 'pointer',
    }
}))
export const YoutubeComponent = ({ showYoutubeModal, setShowYoutubeModal }) => {
    const classes = useStyles()
    const [showContent, setShowContent] = useState(true)
    return showYoutubeModal ? (
        <Dialog open={showYoutubeModal}
            keepMounted
            sx={{
                '& .MuiPaper-root': {
                    borderRadius: '24px',
                    width: '90%',
                    maxWidth: '80rem',
                    height: 'calc(100vh - 140px)',
                    maxHeight: '40rem',
                    position: "relative"
                }
            }}
            className={classes.mainModal}
            onClose={() => {
                setShowYoutubeModal(false)
                setShowContent(true)
            }}
            aria-describedby="alert-dialog-slide-description">
            <DialogContent className={classes.modalContent}>
                {(
                    <div className={classes.video}>
                        <iframe
                            width="100%"
                            height="100%"
                            style={{
                                borderRadius: '24px'
                            }}
                            src={`https://www.youtube-nocookie.com/embed/wv7XUzlVi3Y?mode=opaque&amp;rel=0&amp;autohide=1&amp;showinfo=0&amp;wmode=transparent`}
                            title="YouTube video player"
                            frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen >

                        </iframe>
                    </div>
                )}

                {showContent && <div className={classes.videoContent}>
                    <div style={{
                        position: "relative"
                    }}>
                        <div className={classes.closeIconeWrapper} onClick={() => setShowContent(false)}>
                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13 1L1 13" stroke="#190D1A" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M1 1L13 13" stroke="#190D1A" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>

                        </div>
                        <Typography
                            sx={{
                                fontSize: '1.5rem !important',
                                fontWeight: 600,
                                color: '#190D1A',
                                lineHeight: '135% !important',
                                letterSpacing: '-0.24px !important',
                                textAlign: 'center',
                            }}>Watch our introduction video</Typography>
                        <Typography
                            sx={{
                                fontSize: '1rem !important',
                                fontWeight: 400,
                                color: '#190D1A',
                                lineHeight: '135% !important',
                                letterSpacing: '-0.16px !important',
                                textAlign: 'center'

                            }}>We have filmed a video where we explain how to use the app and make the most out of it. </Typography>
                    </div>

                </div>}

            </DialogContent>
        </Dialog>
    ) : null
}