import React, { useContext, useEffect, useState } from "react"
import ShareSocialReportModal from "./view/tracker/Modals/ShareSocialReportModal"
import { contactsService } from "./services/contactsService";
import { UserTypeContext } from "./context/userStatus";
import { ContactsContext } from "./context/userIContacts";
import { setAllContacts, setGroupContacts, setShareModalOpen } from "./store/actions/contactsActions";
import { ReportsTypeContext } from "./context/userIReports";
import { setSelectedRow, setSelectedRowDetails } from "./store/actions/reportTypeAction";

const SocialReports = () => {
    const { state: IUserTypeState, dispatch: userTypeDispatch } =
        useContext(UserTypeContext);
    const { dispatch: reportTypeDispatch } =
        useContext(ReportsTypeContext);
    const { state: IContactTypeState, dispatch: contactsDispatch } =
        useContext(ContactsContext);
    useEffect(() => {
        if (IContactTypeState.shareModalOpen) {
            getAllContacts()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [IContactTypeState.shareModalOpen]);
    const getAllContacts = () => {
        contactsService
            .getContactsList(IUserTypeState.token)
            .then((data: any) => {
                const { allContact = [], groupData = [] } = data;
                contactsDispatch(setAllContacts(allContact))
                contactsDispatch(setGroupContacts(groupData.map(it => {
                    if (it.contacts && it.contacts.length) {
                        it.contacts = it.contacts.map(ite => ({
                            ...ite,
                            parent: it._id
                        }))
                    }

                    return it
                })))
            })
            .catch((error) => {
                console.log("User profile Error");
            });
    }
    const handleShareModalOpen = () => {
        contactsDispatch(setShareModalOpen(false))
        reportTypeDispatch(setSelectedRow({}));
        reportTypeDispatch(setSelectedRowDetails({}));
    }
    return (
        <ShareSocialReportModal
            shareModalOpen={IContactTypeState.shareModalOpen}
            handleShareModalOpen={handleShareModalOpen}
            getAllContacts={getAllContacts}
        />
    )
}

export default SocialReports