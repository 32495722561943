
import React from 'react'

export default function LeftGreenArrow() {
    return (
        <>
            <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.51835 0.707482C6.84269 0.42948 7.34375 0.659934 7.34375 1.08711V10.9129C7.34375 11.3401 6.84269 11.5705 6.51835 11.2925L0.786649 6.37963C0.553844 6.18008 0.553844 5.81992 0.78665 5.62037L6.51835 0.707482Z" fill="#11734A" />
            </svg></>
    )
}
