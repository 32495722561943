import React from "react";
import { TextField, Skeleton } from "@mui/material";
import { Box, styled } from "@mui/system";
import { Typography } from "@material-ui/core";

export default function Activity({ isMenuSelected, setIsMenuSelected }) {
  const CustomInputContainerMainwrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "32px",
    textAlign: "center",

    [theme.breakpoints.down("md")]: {
      width: "80vw",
    },
  }));

  const CustomInputContainerMain = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: "15px",
    textAlign: "center",
    height: "150px",
  }));

  const CustomInputContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "30px",
    height: "30px",
    background: "#F17F79",
    borderRadius: "50%",
    position: "relative",
    "&:before": {
      content: '""',
      display: " block",
      width: "10vw",
      height: "3px",
      background: "#DCE5E0",
      top: " 50%",
      left: "100%",
      position: "absolute",
    },
  }));
  const LastCustomInputContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "30px",
    height: "30px",
    background: "#F17F79",
    borderRadius: "50%",
    position: "relative",
  }));

  const TypographyText = styled("div")(({ theme }) => ({
    color: "#3E4240",
    lineHeight: "115%",
    fontSize: "16px",
    width: "9vw",
    height: "30px",
    wordBreak: "break-all",

    [theme.breakpoints.down("lg")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
      height: "40px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      height: "15px",
    },
  }));

  const activity = isMenuSelected?.outComeData?.options

  return (

    <CustomInputContainerMainwrapper>
      {isMenuSelected?.outComeData?.options ?
        <CustomInputContainerMainwrapper>
          {activity?.map((item, index) => {
            if (index <= 3) {
              return (
                <CustomInputContainerMain key={index}>
                  <CustomInputContainer style={{ background: isMenuSelected?.outComeData?.options[index].colorCode }}>
                    <Typography sx={{ color: "#fff", fontSize: "18px" }}>{index + 1} </Typography>
                  </CustomInputContainer>

                  <TypographyText variant="h5">{isMenuSelected?.outComeData?.options[index].text}</TypographyText>

                </CustomInputContainerMain>)
            } else {
              return (
                <CustomInputContainerMain key={index} >
                  <LastCustomInputContainer style={{ background: isMenuSelected?.outComeData?.options[index].colorCode }}>
                    <Typography sx={{ color: "#fff", fontSize: "18px" }}>5 </Typography>
                  </LastCustomInputContainer>
                  <TypographyText variant="h5">{isMenuSelected?.outComeData?.options[index].text}</TypographyText>
                </CustomInputContainerMain>
              )
            }
          })}
        </CustomInputContainerMainwrapper>
        : <Box sx={{ width: "50vw" }}><Skeleton /></Box>}
    </CustomInputContainerMainwrapper>

  );
}
