import { Box, Typography } from "@mui/material"
import React from "react"
import { pxToRem } from "../../../theme/typography"

const Steps = ({
    currentStep
}) => {
    return <Box display={"flex"} flexDirection={"column"} >
        <BOxView step={1} name={"Select metrics"} active={currentStep >= 1} />
        <BOxView step={2} name={"Date range"} active={currentStep >= 2} />
        <BOxView step={3} name={"Import"} active={currentStep === 3} />
    </Box>
}

export default Steps

const BOxView = ({ step, name, active }) => {
    return <Box padding={"12px"} display={"flex"} gap={"8px"} alignItems={"center"} width={"240px"}>
        <Box borderRadius={"100%"} border={"1px solid"} bgcolor={active ? "var(--color-new-main)" : "transparent"} borderColor={active ? "var(--color-new-main)" : "var(--svg-new-color)"} height={"30px"} width={"30px"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <Typography sx={{
                color: active ? "var(--white-background)" : "var(--svg-new-color)",
                // fontSize: `${pxToRem(10)} !important`,
                textAlign: "center",
                fontSize: `${pxToRem(16)} !important`,
                lineHeight: `${pxToRem(16)} !important`,
                // textAlign: "center !important",
                fontWeight: `500 !important`,
                "&.MuiTypography-root": {
                    "@media  (max-width: 600px) and (min-width: 100px)": {
                        fontSize: `${pxToRem(16)} !important`,
                        lineHeight: `${pxToRem(16)} !important`
                    },
                }
            }} >{step} </Typography>
        </Box>
        <Typography sx={{
            color: "#3E4240",
            // fontSize: `${pxToRem(10)} !important`,
            textAlign: "center",
            fontSize: `${pxToRem(14)} !important`,
            lineHeight: `${pxToRem(17.5)} !important`,
            // textAlign: "center !important",
            fontWeight: `${active ? 700 : 400} !important`,
            "&.MuiTypography-root": {
                "@media  (max-width: 600px) and (min-width: 100px)": {
                    fontSize: `${pxToRem(14)} !important`,
                    lineHeight: `${pxToRem(17.5)} !important`
                },
            }
        }} >{name} </Typography>
    </Box>
}