import React from "react";
import MeasurementIcon from "../../assets/images/icon/MeasurementIcon"
import NumericScaleIcon from "../../assets/images/icon/NumericScaleIcon";
import TrueFalseIcon from "../../assets/images/icon/TrueFalseIcon"

const InfluenceIcon = ({item}) => {
    return item.type === "TYPE3"
            ? <TrueFalseIcon />
            : item.type === "TYPE12" 
            ? <MeasurementIcon /> : item.type === "TYPE2" ? <NumericScaleIcon /> : null
}

export default InfluenceIcon