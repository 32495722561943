import axios from "axios";
import { baseEndpoints } from "./config";

const headerToken = localStorage.getItem("userDetails");

const getReflectiveList = async (token: string) => {
  const { data } = await axios
    .post(
      `${baseEndpoints.reflective}/get`,
      {},
      {
        headers: {
          "x-access-token": token ? token : headerToken,
        },
      }
    )
    .then((response) => {
      return response;
    });
  return data;
};

const createRefelective = async (refelectiveValue: any, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.reflective}/create`, refelectiveValue, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const editRefelective = async (refelectiveValue: any, token: string) => {
  const { data } = await axios
    .post(`${baseEndpoints.reflective}/update`, refelectiveValue, {
      headers: {
        "x-access-token": token ? token : headerToken,
      },
    })
    .then((response) => {
      return response;
    });
  return data;
};

const getRefelectiveSuggestion = async (token: string) => {
  const { data } = await axios
    .post(
      `${baseEndpoints.reflectiveSuggestion}/get`,
      {},
      {
        headers: {
          "x-access-token": token ? token : headerToken,
        },
      }
    )
    .then((response) => {
      return response;
    });
  return data;
};

export const reflectiveQueService = {
  getReflectiveList,
  createRefelective,
  editRefelective,
  getRefelectiveSuggestion,
};
