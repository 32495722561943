/*eslint @typescript-eslint/no-unused-vars: "off"*/
/*eslint eqeqeq: "off"*/
import {
  DialogTitle,
  Grid,
  Typography,
  Dialog,
  DialogContent,
  Tooltip,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core";
import EditIcon from "../../assets/images/icon/EditIcon";
import palette from "../../theme/palette";
import ModalCloseIconBlack from "../../assets/images/icon/modalCloseIconBlack";
import LeftSideReflectiveQue from "../reflective-questions/leftSideRefQue";
import SnackBarComp from "../../components/snackBar";
import { UserTypeContext } from "../../context/userStatus";
import LeftSideReflectiveQueNew from "../reflective-questions/leftSideRefQueNew";
import MultiDrawerAddEditComponent from "../../components/MultiDrawerComponent";

const useStyles = makeStyles((theme) => ({
  // listText: {
  //   display: "flex",
  //   alignItems: "center",
  //   justifyContent: "space-between",
  //   padding: "0px 0px",
  //   minHeight: '47px',
  //   "& h6": {
  //     color: palette.primary.light,
  //     fontSize: "16px",
  //     lineHeight: "24px",
  //     // maxWidth: "275px",
  //     // textOverflow: "ellipsis",
  //     // overflow: "hidden",
  //     // whiteSpace: "nowrap",
  //   },
  // },
  listText: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 24px",
    gap: '12px',
    // boxShadow: '5px 0px 40px 0px rgba(18, 18, 19, 0.05)',
    // "& h6": {
    //   color: palette.primary.light,
    //   fontSize: "16px",
    //   lineHeight: "24px",
    //   maxWidth: "275px",
    //   textOverflow: "ellipsis",
    //   overflow: "hidden",
    //   whiteSpace: "nowrap",
    // },
    "& h6": {
      color: palette.common.black,
      fontSize: "16px",
      lineHeight: "24px",
      // maxWidth: "275px",
      // textOverflow: "ellipsis",
      // overflow: "hidden",
      // whiteSpace: "nowrap",
    },
  },
  modalCloseIcon: {
    position: "absolute",
    right: 0,
    top: 0,
    cursor: "pointer",
    zIndex: 1,
  },
  textClass: {
    overflow: 'hidden',
    display: '-webkit-box',
    lineClamp: 1,
    boxOrient: 'vertical',
    textOverflow: 'ellipsis',
    margin: "20px 0px !important",
  },
  fullTexttextClass: {
    overflow: 'hidden',
    display: '-webkit-box',
    lineClamp: 8,
    boxOrient: 'vertical',
    textOverflow: 'ellipsis',
    margin: "20px 0px !important",
  },
  mainDivClas: {
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: "center",
    borderBottom: '1px solid #F3F3F3',

  },
  mainDivClasExpand: {
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: "flex-start",
    borderBottom: '1px solid #F3F3F3',
    height: "221px",
    background: '#FFF',
    flexBasis: "100%",
  },
  innerDiVClass: {
    display: 'flex',
    flexDirection: 'row',
    gap: '4px',
    alignItems: "center"
  },
}));

interface TrackingItemModal {
  refelectiveItem: any;
  setShowQues: (showQues: boolean) => void;
  showQues: boolean;
  openStartModalQues: boolean;
  setStartModalQues: (openStartModalQues: boolean) => void;
  startDate: any
  endDate: any
  index: number
  dataList: any[];
  isExpand: boolean
  setLimitReachPopupVisible: any
  setLimitReachPopuptype: any
}

const PersonalTrackingReflective = (props: TrackingItemModal) => {
  const classes = useStyles();
  const [influenceId, setInfluenceId] = useState(null);
  const [addQuesTracking, setAddQuesTracking] = useState(false);
  const [questionData, setQuestionData] = React.useState();
  const [quesIdeas, setQuesIdeas] = useState();
  const [snakbarMsg, setSnakbarMsg] = useState("");
  const [showIcon, setshowIcon] = useState(false);
  const [msgType, setMsgType] = useState("");
  const [showSnakbar, setShowSnakbar] = useState(false);
  const { state: outcomeState } = useContext(UserTypeContext);
  const [weekday, setWeekDay] = useState(new Map());
  const [needIdeasOpen, setNeedIdeasOpen] = useState(false)
  const handleCloseStartModal = () => {
    setInfluenceId(null);
    props.setStartModalQues(false);
    props.setShowQues(false);
  };

  const editOutcomeTracking = (ques: any, id: any) => {
    props.setStartModalQues(true);
    setInfluenceId(id);
    props.setShowQues(true);
    setQuestionData(ques);
  };
  const handleCloseSnakbar = () => {
    setShowSnakbar(false);
  };

  return (
    <Grid
      item
      className={classes.listText}
    >
      <>
        <SnackBarComp
          showSnakbar={showSnakbar}
          handleCloseSnakbar={handleCloseSnakbar}
          snakbarMsg={snakbarMsg}
          type={msgType ? msgType : "info"}
        />
        <div className={props?.isExpand ? classes.mainDivClasExpand : classes.mainDivClas} style={(props.dataList.length - 1) === props.index ? { borderBottom: 0 } : {}}
        onMouseEnter={() => setshowIcon(true)} 
        onMouseLeave={() => setshowIcon(false)}>
           <div style={{
            width: "100%"
          }}>
{props.refelectiveItem.description ? (
            <Tooltip
              title={
                <Typography sx={{ fontSize: "14px !important", lineHeight: "1.5" }}>
                  {props.refelectiveItem.description}
                </Typography>
              }
            >
              <div style={{
                width: '100%'
              }}>
                <Typography variant="h6" flex={7} title={props.refelectiveItem.question} className={props?.isExpand ? classes.fullTexttextClass :classes.textClass}>
                  {" "}
                  {props.refelectiveItem.question}
                </Typography>
              </div>
            </Tooltip>
          ) : (
            <div style={{
              width: '100%'
            }}>
              <Typography variant="h6" flex={7} title={props.refelectiveItem.question} className={props?.isExpand ? classes.fullTexttextClass :classes.textClass}>
                {" "}
                {props.refelectiveItem.question}
              </Typography>
            </div>
          )}
          </div>
          <div className={classes.innerDiVClass}>
            <span className={classes.textClass}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 14 14" fill="none">
                <path d="M5.09766 5.04908C5.21152 4.69822 5.4201 4.38597 5.70052 4.14632C5.98094 3.90667 6.32253 3.74919 6.68685 3.69139C7.05117 3.63358 7.42415 3.6776 7.76497 3.81868C8.1058 3.95977 8.40097 4.1925 8.61784 4.49089C8.83471 4.78927 8.96459 5.14171 8.99358 5.50944C9.02257 5.87717 8.94923 6.24586 8.78179 6.57454C8.61436 6.90323 8.35958 7.17899 8.04507 7.37174C7.73056 7.5645 7.36888 7.66651 7 7.66651V8.33349M7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7C13 10.3137 10.3137 13 7 13ZM7.0332 10.3333V10.4L6.9668 10.4001V10.3333H7.0332Z" stroke="#A0A0A1" strokeWidth="0.67" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </span>
            {showIcon && <span
            onClick={() =>
              editOutcomeTracking(
                props.refelectiveItem,
                props.refelectiveItem._id
              )
            }
            className={props?.isExpand ? classes.textClass : ''}
          >
            <EditIcon />
          </span>}
          </div>
          
        </div>

        {props.showQues && influenceId == props.refelectiveItem?._id && (
          // <Dialog
          //   disableEscapeKeyDown
          //   onClose={handleCloseStartModal}
          //   open={props.openStartModalQues}
          //   className="dialogWrapper dialogWrapper-outcome"
          // >
          //   <DialogTitle className={classes.modalCloseIcon}>
          //     <span onClick={handleCloseStartModal}>
          //       <ModalCloseIconBlack />
          //     </span>
          //   </DialogTitle>
          //   <DialogContent>
          //     {/* LEFT SECTION */}
          //     <LeftSideReflectiveQue
          //       setQuestionData={setQuestionData}
          //       questionData={questionData}
          //       reflectiveIdeas={quesIdeas}
          //       showRefQues={props.showQues}
          //       setAddQuesTracking={setAddQuesTracking}
          //       setShowQues={props.setShowQues}
          //       setWeekDay={setWeekDay}
          //       setShowSnakbar={setShowSnakbar}
          //       setMsgType={setMsgType}
          //       setSnakbarMsg={setSnakbarMsg}
          //       weekday={weekday}
          //       startDate={props.startDate}
          //       endDate={props.endDate}
          //     />
          //   </DialogContent>
          // </Dialog>
          <MultiDrawerAddEditComponent
            handleClose={handleCloseStartModal}
            drawerTitle={<>Edit  <span style={{ color: palette.primary.main }}>Qualitative Reflection</span></>}
            secondOpen={needIdeasOpen}
          >
            <LeftSideReflectiveQueNew
              setQuestionData={setQuestionData}
              questionData={questionData}
              reflectiveIdeas={quesIdeas}
              showRefQues={props.showQues}
              setAddQuesTracking={setAddQuesTracking}
              setShowQues={props.setShowQues}
              setWeekDay={setWeekDay}
              setShowSnakbar={setShowSnakbar}
              setMsgType={setMsgType}
              setSnakbarMsg={setSnakbarMsg}
              weekday={weekday}
              handleCloseStartModalQues={handleCloseStartModal}
              // getReflectiveList={props.getReflectiveList}
              startDate={props.startDate}
              endDate={props.endDate}
              setNeedIdeasOpen={setNeedIdeasOpen}
              setLimitReachPopupVisible={props?.setLimitReachPopupVisible}
              setLimitReachPopuptype={props?.setLimitReachPopuptype}
            />
          </MultiDrawerAddEditComponent>
          // <Dialog
        )}
      </>
    </Grid>
  );
};
export default React.memo(PersonalTrackingReflective);
