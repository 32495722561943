import React, { useReducer } from "react";
import {
  IUserTypeState,
  IUserAction,
  UserTypeContextModal,
} from "../interfaces/outcomeModal";

const defaultState: IUserTypeState = {
  userType: null,
  token: null,
  userLevel: null,
  outcomeList: null,
  influenceList: null,
  reflectiveList: null,
  userId: null,
  isNewUser: false,
  journeyList: null,
  journeyNotesList: null,
  notesList: null,
  categoryList: null,
  journeyCategoryList: null,
  journeyCategoryExpandList: [],
  outcomePopup: null,
  isPersonal: localStorage.getItem("mode") === "personal",
  isExpand: localStorage.getItem("viewType") === "expand" ? true : false,
  showEmpty: false,
  userDetails: {},
  isOneTimeOpenTracking: false,
  requiredRefresh: false,
};
/*  if ((!outcomeState?.journeyCategoryList || outcomeState?.journeyCategoryList.length <= 0) && !props?.showLoader) {
      setShowEmpty(true)
    } else {
      setShowEmpty(false)
    } */
const reducer = (
  state: IUserTypeState,
  action: IUserAction
): IUserTypeState => {
  switch (action.type) {
    case "SET_USER_TYPE":
      return {
        ...state,
        userType: action.payload,
      };
    case "SET_USER_OBJECT":
      return {
        ...state,
        userDetails: action.payload,
      };
    case "SET_USER_STATUS":
      return {
        ...state,
        isNewUser: action.payload,
      };
    case "SET_USERID":
      return {
        ...state,
        userId: action.payload,
      };
    case "SET_TOKEN":
      return {
        ...state,
        token: action.payload,
      };
    case "SET_USER_LEVEL":
      return {
        ...state,
        userLevel: action.payload,
      };
    case "OUTCOME_LIST":
      return {
        ...state,
        outcomeList: action.payload,
      };
    case "INFLUENCE_LIST":
      return {
        ...state,
        influenceList: action.payload,
      };
    case "REFLECTIVE_LIST":
      return {
        ...state,
        reflectiveList: action.payload,
      };
    case "JOURNEY_LIST":
      return {
        ...state,
        journeyList: action.payload,
      };
    case "NOTES_LIST":
      return {
        ...state,
        notesList: action.payload,
      };
    case "CATEGORY_LIST":
      return {
        ...state,
        categoryList: action.payload,
      };
    case "JOURNEY_CATEGORY_LIST":
      return {
        ...state,
        journeyCategoryList: [...action.payload],
      };
    case "JOURNEY_CATEGORY_EXPAND_LIST":
      return {
        ...state,
        journeyCategoryExpandList: [...action.payload],
      };
    case "JOURNEY_CATEGORY_EXPAND_LIST_WUTH_PREVIOUS":
      return {
        ...state,
        journeyCategoryExpandList: [...state.journeyCategoryExpandList, action.payload],
      };
    case "JOURNEY_NOTES_LIST":
      return {
        ...state,
        journeyNotesList: [...action.payload],
      };
    case "PUSH_JOURNEY_NOTES_LIST":
      return {
        ...state,
        journeyCategoryList: [...(state.journeyCategoryList || []), ...action.payload],
        journeyCategoryExpandList: [...(state.journeyCategoryExpandList || []), action.payload[0].categoryId],
      }
    case "ADD_OUTCOME_POPUP":
      return {
        ...state,
        outcomePopup: action.payload,
      };
    case "IS_PERSONAL":
      return {
        ...state,
        isPersonal: action.payload,
      };
    case "IS_EXPAND":
      return {
        ...state,
        isExpand: action.payload,
      };
    case "IS_EMPTY":
      return {
        ...state,
        showEmpty: action.payload,
      };
    case "SET_IS_OPEN_MODAL":
      return {
        ...state,
        isOneTimeOpenTracking: action.payload,
      };
    case "SET_REQUIRED_REFRESH":
      if(action.payload !== state.requiredRefresh) {
        return {
          ...state,
          requiredRefresh: action.payload,
        };
      }
      return state
    default:
      return state;
  }
};

export const UserTypeContext = React.createContext({} as UserTypeContextModal);
type childernType = {
  children: React.ReactNode;
};
export const Provider: React.FC = (props: childernType) => {
  const [state, dispatch] = useReducer(reducer, defaultState);

  return (
    <UserTypeContext.Provider value={{ state, dispatch }}>
      {props.children}
    </UserTypeContext.Provider>
  );
};
